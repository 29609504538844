import React, { useState, useEffect } from "react";
import { Input, Space, Select, message, Button } from "antd";
import { getModelHistory, getModelHistoryExcel } from "@/service/apis/LMS/LabelManage";
import useAPI from "@/hooks/useAPI";
import { IconExcelDownload } from "@/components/Icon/Action";
import exportExcel from "@/util/exportExcel";

import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";

export default function LabelSearch() {
  const call_getModelHistory = useAPI(getModelHistory);
  const call_getModelHistoryExcel = useAPI(getModelHistoryExcel);

  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 10,
    SN: undefined,
    woN: undefined,
    bindclass: undefined,
    barcode: undefined,
    PN: undefined,
    custPN: undefined,
  });

  const [s_tableData, set_s_tableData] = useState([]);

  const tableColumns = [
    {
      title: "流水號",
      dataIndex: "SN",
      width: "5%",
      isExcel: true,
      render: (text) => <div className="align-l">{text}</div>,
    },
    {
      title: "條碼",
      dataIndex: "barcode",
      width: "15%",
      isExcel: true,
      render: (text) => <div className="align-l">{text}</div>,
    },
    {
      title: "工單號",
      dataIndex: "woN",
      width: "10%",
      isExcel: true,
      render: (text) => <div className="align-l">{text}</div>,
    },
    {
      title: "標籤ID",
      dataIndex: "modelID",
      isExcel: true,
      render: (text) => <div className="flex-start">{text}</div>,
    },
    {
      title: "版本",
      dataIndex: "ver",
      isExcel: true,
    },
    {
      title: "料號",
      dataIndex: "PN",
      isExcel: true,
      render: (text) => <div className="flex-start">{text}</div>,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      isExcel: true,
      render: (text) => <div className="flex-start">{text}</div>,
    },
    {
      title: "客戶料號",
      dataIndex: "custPN",
      isExcel: true,
      render: (text) => <div className="flex-start">{text}</div>,
    },
    {
      title: "綁定型態",
      dataIndex: "bindclass",
      isExcel: true,
      render: (text) => <div className="flex-center">{text}</div>,
    },
    {
      title: "列印次數",
      dataIndex: "printC",
      isExcel: true,
      render: (text) => <div className="flex justify-end">{text}</div>,
    },
    {
      title: "產生時間",
      dataIndex: "updtT",
      isExcel: true,
      render: (text) => <div className="flex-center">{text}</div>,
    },
    {
      title: "備註",
      dataIndex: "note",
      isExcel: true,
      render: (text) => <div className="align-l">{text}</div>,
    },
  ];

  const downloadExcel = () => {
    call_getModelHistoryExcel.request(s_searchData);
  };

  const handleSetSearchValue = (key, value) => {
    set_s_searchData((prev) => {
      prev[key] = value;
      return { ...prev, page: 1, pageSize: 10 };
    });
  };

  useEffect(() => {
    call_getModelHistory.request(s_searchData);
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (call_getModelHistory.status === "suc") {
      set_s_tableData(
        call_getModelHistory.data.tableData.map((x, i) => {
          return {
            ...x,
            key: x.barcode + i,
            bindclass: x.bindclass === "U" ? "單綁" : "批綁",
            updtT: dayjs(x.updtT).format("YYYY-MM-DD HH:mm:ss"),
          };
        })
      );
      set_s_pageInfo(call_getModelHistory.data.pageInfo);
      message.success(call_getModelHistory.msg);
    } else if (call_getModelHistory.status === "err") {
      set_s_tableData([]);
      message.error(call_getModelHistory.msg);
    }
  }, [call_getModelHistory.status]);

  useEffect(() => {
    if (call_getModelHistoryExcel.status === "suc") {
      const excelData = call_getModelHistoryExcel.data.map((x, i) => ({
        ...x,
        key: x.barcode + i,
        bindclass: x.bindclass === "U" ? "單綁" : "批綁",
        updtT: dayjs(x.updtT).format("YYYY-MM-DD HH:mm:ss"),
      }));
      exportExcel(tableColumns, excelData, `${dayjs().format("YYYY-MM-DD")}列印標籤查詢.xlsx`);
      message.success(call_getModelHistoryExcel.msg);
    } else if (call_getModelHistoryExcel.status === "err") {
      message.error(call_getModelHistoryExcel.msg);
    }
  }, [call_getModelHistoryExcel.status]);

  return (
    <>
      <PageHeader
        title="列印標籤查詢"
        extra={
          <Space className="flex justify-end">
            <Input
              style={{ width: "200px" }}
              placeholder="請輸入流水號"
              value={s_searchData.SN}
              onChange={(e) => handleSetSearchValue("SN", e.target.value)}
            />
            <Input
              style={{ width: "200px" }}
              placeholder="請輸入工單號"
              value={s_searchData.woN}
              onChange={(e) => handleSetSearchValue("woN", e.target.value)}
            />
            <Input
              style={{ width: "200px" }}
              placeholder="請輸入標籤條碼"
              value={s_searchData.barcode}
              onChange={(e) => handleSetSearchValue("barcode", e.target.value)}
            />
            <Input
              style={{ width: "200px" }}
              placeholder="請輸入料號"
              value={s_searchData.PN}
              onChange={(e) => handleSetSearchValue("PN", e.target.value)}
            />
            <Input
              style={{ width: "200px" }}
              placeholder="請輸入客戶料號"
              value={s_searchData.custPN}
              onChange={(e) => handleSetSearchValue("custPN", e.target.value)}
            />
            <Select
              placeholder="請選擇綁定型態"
              style={{ width: "200px" }}
              allowClear
              value={s_searchData.bindclass}
              onChange={(e) => handleSetSearchValue("bindclass", e)}
              options={[
                {
                  label: "單綁",
                  value: "U",
                },
                {
                  label: "批綁",
                  value: "B",
                },
              ]}
            />
            <Button type="primary" onClick={() => call_getModelHistory.request(s_searchData)}>
              查詢
            </Button>
            <IconExcelDownload className="text-[40px]" onClick={downloadExcel} />
          </Space>
        }
      />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getModelHistory.status === "load"}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
    </>
  );
}
