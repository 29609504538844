const zh_TW = {
  util: {
    "itemID": "項目ID",
    "itemno": "項序",
    "sign_out": "登出",
    "add": "新增",
    "edit": "編輯",
    "delete": "刪除",
    "search": "查詢",
    "cancel": "取消",
    "close": "關閉",
    "ok": "確認",
    "render": "選擇",
    "check": "覆核",
    "success": "成功",
    "all": "全部",
    "yes": "是",
    "no": "否",
    "name": "名稱",
    "total": "總數",
    "SN": "序",
    "detail": "詳情",
    "action": "操作",
    "size_big": "特大",
    "size_large": "大尺寸",
    "size_middle": "中尺寸",
    "size_small": "小尺寸",
    "hide_detail": "隱藏明細檔",
    "del_confirm": "刪除確認",
    "del_confirm_msg": "請先確認資料無誤，再刪除",
    "note": "註記",
    "no_data": "無此資料",
    "data": "資料",
    "header_cancel_create": "取消據此創建",
    "header_create": "據此創建條目",
    "placeholder_select": "請選擇",
    "placeholder_input": "請輸入",
    "or": "或",
    "order": "單",
    "showTotal": "共 {{total}} 條 ， 當前顯示 {{rangeFirst}} - {{rangeEnd}} 條",
    "itemNM": "項目名稱",
    "excluding_tax": "(未稅)",
    "start": "起",
    "end": "迄",
    "wl": "工線",
    "ws": "工站",
    "woN": "工單號",
    "linemix": "混線",
    "situF": "狀態",
    "PN": "項目編號",
    "whID": "倉別",
    "idmtype": "過帳型態",
    "custID": "客戶ID",
    "custPN": "客戶料號",
    "pdtNM": " 检测项目",
    "pdtspec": "检测标准/测试方法",
    "planqty": "計畫數量",
    "inqty": "生產數量",
    "tqty": "當前總數",
    "sqty": "人工輸入",
    "dqty": "條碼掃入",
    "batchno": "批號",
    "okC": "良品數量",
    "inC": "產出數量",
    "ngC": "不良數量",
    "lifeF": "歷程",
    "isvalid": "有效",
    "isfocus": "關注",
    "dept": "部門",
    "updtT": "更新時間",
    "pdtclass": "商品",
    "mtype": "機種",
    "ver": "版碼",
    "qty": "組數",
    "attribute": "屬性",
    "unitNM": "計量單位",
    "opunitNM": "操作單位",
    "unitwt": "計數單重",
    mgmtcat: "授權分類",
    querycat: "客戶分類",
    drawno: "工程圖號",
    matl: "原料材質",
    moldID: "模具ID",
    heirPN: "繼承料號",
    heirdealID: "繼承對象",
    moldC: "合模次數",
    moldcavity: "模具穴數",
    searchPN: "搜尋料號",
    "edit_detect_msg1": "系統偵測到您編輯了以下欄位",
    "edit_detect_msg2": "請先儲存系統資料再編輯明細",
    "msg_chose_data": "請選擇要引用的資料",
    "msg_chose_whichType_toThrow": "請選擇要拋轉的單別",
    "msg_chose_atleast_one_Detail": "請至少選擇一筆明細",
    // -------------專業詞彙Map------------------
    linemix_0: "不混線",
    linemix_1: "右",
    linemix_2: "左",
    idmtype_s: "人工過帳",
    idmtype_d: "條碼過帳",
    idmtype_m: "混合過帳",
    attribute_0: "0 原料",
    attribute_1: "1 物料",
    attribute_2: "2 非標零件",
    attribute_3: "3 標準零件",
    attribute_4: "4 半成品",
    attribute_5: "5 製成品",
    attribute_6: "6 客供品",
    pdtclass_X: "N/A",
    pdtclass_M: "主件",
    pdtclass_A: "附件",
    pdtclass_P: "包材",
    packtype_0: "操作包裝_操作單位使用",
    packtype_1: "出貨包裝_打包出貨使用",
    packtype_2: "週轉包裝_產線週轉使用",
    assetkind: "資產分類",
    assetkind_TI: "射出",
    assetkind_TS: "沖壓",
    assetkind_TD: "壓鑄",
    assetkind_TF: "鍛造",
    fileType_SOP: "作業標準 SOP",
    fileType_SIP: "檢驗標準 SIP",
    fileType_PACK: "包裝標準 PACK",
    fileType_DRAW: "工程圖面 DWG",
    fileType_PIC: "照片圖檔 PIC",
    //------------------Navbar導覽列----------------------
    "nav_pds_dashboard": "數位生產看板",
    "nav_pds_abc-board": "異常管理看板",
    "nav_pds_overview-lines": "線體稼動總表",
    "nav_BDM": "物料資料管理",
    "nav_bdm_pn-manage": "檢驗項目維護",
    "nav_bdm_edm": "技術資料維護",
    "nav_EDM": "模具資料管理",
    "nav_edm_mold": "模具資料維護",
    "nav_TDM": "廠區資料管理",
    "nav_tdm_plant-manage": "廠別主檔設定",
    "nav_tdm_procedure-manage": "製程規劃維護",
    "nav_tdm_production-line-manage": "產線規劃維護",
    "nav_tdm_warehouse-manage": "庫房規劃維護",
    "nav_tdm_string-manage": "功能字串管理",
    "nav_tdm_plant-params-manage": "廠別參數設定",
    "nav_SDM": "系統資料管理",
    "nav_sdm_user-manage": "員工資料維護",
    "nav_sdm_company": "廠商資料維護",
    "nav_sdm_client": "客戶資料維護",
    "nav_sdm_category-manage": "查詢分類設定",
    "nav_sdm_data-auth-manage": "功能授權設定",
    "nav_sdm_trading-pattern": "項目字串設定",
    "nav_sdm_function-params-manage": "系統參數設定",
    "nav_WMS": "庫存管理系統",
    "nav_wms_plan-in": "庫房入庫作業",
    "nav_wms_plan-out": "庫房出庫作業",
    "nav_wms_stock-search": "庫存狀況查詢",
    "nav_wms_inv-history": "每月庫存紀錄",
    "nav_wms_pro-instock": "代理入庫作業",
    "nav_PMS": "採購管理系統",
    "nav_pms_plan": "詢價計畫",
    "nav_pms_req": "請購作業",
    "nav_pms_pro": "採購作業",
    "nav_pms_instock": "進料作業",
    "nav_pms_gostock": "進料檢驗查詢",
    "nav_pms_return": "進料退出作業",
    "nav_pms_ap-manage": "應付帳款管理",
    "nav_pms_report": "報表查詢",
    "nav_pms_report-unpurchase": "採購未進貨明細表",
    "nav_pms_report-diff": "採購價格調幅表",
    "nav_pms_report-instocksummary": "庫存彙總表",
    "nav_pms_report-inventorydiff": "庫存異動明細帳",
    "nav_pms_report-psisummary": "進銷存彙總表",
    "nav_pms_report-purchasesummary": "進退貨彙總表",
    "nav_pms_report-purchaselist": "進退貨明細表",
    "nav_pms_report-purchasestatistics": "進退貨統計表",
    "nav_OMS": "銷貨管理系統",
    "nav_oms_quo": "報價作業",
    "nav_oms_fod": "預訂作業",
    "nav_oms_ord": "銷貨作業",
    "nav_oms_outstock": "發貨作業",
    "nav_oms_check": "銷貨檢驗查詢",
    "nav_oms_return": "銷貨退回作業",
    "nav_oms_ap-manage": "應收帳款管理",
    "nav_oms_report": "報表查詢",
    "nav_oms_report-unshipped": "訂單未出明細表",
    "nav_oms_report-salesummary": "銷退貨彙總表",
    "nav_oms_report-salelist": "銷退貨明細表",
  },
  errorCode: {
    "403": "無此權限",
    "404": "查無資料",
    "409": "資料重複",
    "500": "操作失敗",
  },
  WOS: {
    "WOC": "生產控制",
    "MC": "物料作業",
    "QC": "質控作業",
    "TK": "生產看板",
    "partsBinding": "部品掃碼",
    "ppidp": "成品綁定",
    "wpcSearch": "途單查詢",
    "dispachManage": "派單管理",
    "mold": "模具管理",
    "whm": "工時管理",
    "batch": "批號管理",
    "oem": "委外報工",
  },
  WMS: {
    "pageHeader": "庫存管理系統",
    "util": {
      "lifeF_0": "已創建",
      "lifeF_1": "執行中",
      "lifeF_2": "已提交",
      "lifeF_A": "全部",
      "lifeF_W": "待規劃",
      "lifeF_R": "已歸還",
      "lifeF_T": "已異動",
      "opcode_4010": "開帳盤點",
      "opcode_4020": "週期盤點",
      "opcode_4030": "專案盤點",
      "opcode_4110": "生產入庫",
      "opcode_4120": "採購入庫",
      "opcode_4130": "銷售退回",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4141": "庫房調入",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4143": "借出歸還",
      "opcode_4142": "庫房借入",
      "opcode_4243": "借入歸還",
      "opcode_4210": "生產領用",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "opcode_4160": "生產退料",
      "opcode_4260": "生產超領",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "來源單號",
      "preN_4120": "進料單號",
      "preN_4130": "來源單號",
      "preN_4220": "來源單號",
      "preN_4230": "來源單號",
      "preN_4141": "來源單號",
      "preN_4241": "來源單號",
      "preN_4242": "來源單號",
      "preN_4143": "來源單號",
      "preN_4142": "來源單號",
      "preN_4243": "來源單號",
      "preN_4210": "來源單號",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "preN_4160": "來源單號",
      "preN_4260": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4299": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "whID_4010": "盤點單號",
      "whID_4020": "盤點單號",
      "whID_4030": "盤點單號",
      "whID_4110": "單號",
      "whID_4120": "採購單號",
      "whID_4130": "銷售單號",
      "whID_4220": "銷售單號",
      "whID_4230": "退出單號",
      "whID_4141": "本倉",
      "whID_4241": "本倉",
      "whID_4242": "倉別",
      "whID_4143": "倉別",
      "whID_4142": "倉別",
      "whID_4243": "倉別",
      "whID_4160": "倉別",
      "whID_4260": "倉別",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4299": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4299": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "異動提交確認",
      "txn_confirm_msg": "請先確認資料無誤，再異動",
      "jobN": "作業單號",
      "lifeF": "歷程",
      "opcode": "作業單別",
      "plantxnqty": "規劃異動量",
      "realtxnqty": "實際異動量",
      "planqty": "計畫數量",
      "realqty": "實際數量",
      "totalqty": "總數",
      "planN": "來源單號碼",
      "updtT": "更新時間",
      "whID": "倉別",
      "pdtNM": "品名",
      "bal": "帳差",
    },
    "plan-in": {
      "pageHeader": "庫房入庫作業",
    },
    "plan-out": {
      "pageHeader": "庫房出庫作業",
    },
    "stock-search": {
      "searchBatchno": "批號查詢",
      "pageHeader": "庫存狀況查詢",
      "searchPN": "料號查詢",
      "searchWh": "各倉查詢",
      "whDetail": "各倉明細",
      "changeDetail": "異動明細",
      "borrow": "借",
      "inTransit": "在途量",
      "loan": "貸",
      "lockqty": "封控數",
      "onhandqty": "有效庫存數",
      "planin": "入庫規劃",
      "planout": "出庫規劃",
      "posted": "已過帳",
      "scanin": "點料入庫",
      "scanout": "檢料出庫",
      "timeSeries": "時間序",
      "total": "預計可用數",
      "totalIn": "總入庫",
      "totalOut": "總出庫",
      "tqty": "庫存數",
      "whNM": "倉別",
    },
    "inv-history": {
      "pageHeader": "每月庫存紀錄",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "厚",
      "inCBM": "進\n(CBM)",
      "l": "長",
      "level": "等級",
      "suppalias": "供應商",
      "unit": "計量單位",
      "w": "寬",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "代理入庫作業",
      "lifeF_T": "已規劃",
      "wmsopUUID": "庫儲作業單號",
      "inchargeID": "負責人員",
    },
  },
  ADM: {
    "oem-manage": {
      "type": "外包類別",
      "itemID": "項目ID",
      "oem_item_manage": "委外項目設定",
      "oem_item": "委外項目",
      "type_A": "人力",
      "type_B": "表處",
      "type_C": "機工",
      "oem_supp_manage": "委外供應維護",
      "oem_supp": "委外供應",
      "vendorcode": "供應代碼",
      "pfNM": "製程名稱",
      "oemitemNM": "外包項目名稱",
    },
    "function-params-manage": {
      "pageHeader": "系統參數",
      "searchPlaceholder": "查詢ID，單位，代號，功能碼，層級名稱",
      "unitSetting": "計量單位設定",
      "opUnitSetting": "操作單位設定",
      "currencySetting": "交易幣別設定",
      "functions": "推播群組設定",
      "pushlvl": "推播層級設定",
      "notify": "推播事件設定",
      "unit": "計量單位",
      "unitNM1": "單位名稱",
      "type": "計量型態",
      "category": "計量類別",
      "countType": "計數型",
      "quantitative": "計量型",
      "count": "計數",
      "weight": "重量",
      "length": "長度",
      "capacity": "容積",
      "area": "面積",
      "volume": "體積",
      "opunit": "操作單位",
      "sort": "排序",
      "currencyitem": "幣別代號",
      "itemNM": "幣別名稱",
      "currency": "交易幣別",
      "ismycurrency": "是否本幣",
      "funcode": "組織功能碼",
      "funNM": "功能名稱",
      "function": "推播群組",
      "lvlcode": "層級碼",
      "lvlNM": "層級名稱",
      "pushlayer": "推播層級",
      "team": "1(班組)",
      "class": "2(課級)",
      "management": "3(理級)",
      "total": "4(總級)",
      "eventID": "事件ID",
      "eventNM": "事件名稱",
      "rptcode": "提報類別",
      "pusher": "發報系統",
      "situF": "警訊燈號",
      "audience": "推播受群",
      "startlvl": "起報層級",
      "setlvl": "推播級數",
      "cruisebot": "監控程式",
      "msgmodel": "推播訊息模版",
      "pushEvent": "推播事件",
      "rptcode_A0": "人員提報",
      "rptcode_B0": "系統提報(WOS)",
      "rptcode_C0": "設備提報",
      "rptcode_D0": "管理提報_遠端",
      "rptcode_E0": "管理提報_雲端",
      "situF_Y": "黃",
      "situF_R": "紅",
      "startlvl_0": "全員",
      "startlvl_1": "班組幹部",
      "startlvl_2": "課級幹部",
      "startlvl_3": "理級主管",
      "startlvl_4": "總級主管",
      "add_string": "新增字串",
      "add_number": "新增數字",
      "add_point": "新增小數點",
      "1_decimal_place": "小數點後1位",
      "2_decimal_place": "小數點後2位",
    },
    "procedure-manage": {
      "pageHeader": "製程規劃維護",
      "pccID": "成本中心ID",
      "pccNM": "成本中心名稱",
      "pf": "製程",
      "pfID": "製程ID",
      "pfNM": "製程名稱",
      "pwc": "工作中心",
      "pwcID": "工作中心ID",
      "pwcNM": "工作中心名稱",
      "tabCost": "成本中心設定",
      "tabFlow": "製程主檔設定",
      "tabWork": "工作中心設定",
    },
    "user-manage": {
      "dashboard": "數位生產看板",
      "abc-board": "異常管理看板",
      "BDM": "物料資料管理",
      "SDM": "系統資料管理",
      "TDM": "廠區資料管理",
      "EDM": "模具資料管理",
      "WMS": "庫存管理系統",
      "OMS": "銷售管理系統",
      "PMS": "採購管理系統",
      "WOC": "生產控制",
      "MC": "物料作業",
      "QC": "質控作業",
      "TK": "生產看板",
      "wpcSearch": "途單查詢",
      "dispachManage": "派單管理",
      "ppidp": "成品綁定",
      "partsBinding": "部品掃碼",
      "account": "員工帳號",
      "pwd": "密碼",
      "role": "角色權限",
      "roleID": "角色ID",
      "roleNM": "角色名稱",
      "systemAuth": "系統登入權限",
      "autoReport": "接收自動報表",
      "APP": "行動APP",
      "PDA": "PDA權限",
      "dealmgmt": "對象授權分類",
      "pdtmgmt": "物料授權分類",
      "whmgmt": "倉庫授權分類",
      "cardID": "卡勤識別碼",
      "function": "組織功能",
      "level": "推播層級",
      "mail": "郵址",
      "pageHeader": "員工資料維護",
      "peopleNM": "員工姓名",
      "personalID": "身份證號",
      "postID": "職稱",
      "proxyNM": "聯絡人姓名",
      "proxytel": "聯絡人電話",
      "staffAuth": "角色權限設定",
      "staffData": "員工資料管理",
      "staffID": "員工工號",
      "tel": "電話",
    },
    "trading-pattern": {
      "OMS": "訂單系統",
      "PMS": "採購系統",
      "adda": "條款註記",
      "addb": "加扣款項",
      "addc": "預付作業",
      "addd": "付款方式",
      "adde": "貿易條件",
      "addf": "退貨作業",
      "addg": "取消訂量",
      "addh": "付款條件",
      "addi": "收款條件",
      "additem": "項目ID",
      "addr": "聯絡住址",
      "addtype": "交易條件",
      "contact": "聯絡人",
      "contactitem": "聯絡窗口代號",
      "department": "部門",
      "itemNM": "聯絡窗口名稱",
      "mail": "郵址",
      "mobile": "行動電話",
      "pageHeader": "項目字串設定",
      "tabAdded": "附加項目設定",
      "tabWindow": "對外窗口設定",
      "tabPMSAdded": "採購附加條件",
      "tabOMSAdded": "銷貨附加條件",
      "tel": "聯絡電話",
    },
    "data-auth-manage": {
      "mgmtitem": "分類項目",
      "pageHeader": "功能授權設定",
      "tabCust": "客資授權分組",
      "tabPdt": "物料授權分組",
      "tabSupp": "廠資授權分組",
      "tabWh": "庫房授權分組",
    },
    "category-manage": {
      "mgmtitem": "項目ID",
      "pageHeader": "查詢分類設定",
      "tabCust": "客戶分類",
      "tabQuery": "物料查詢分類",
      "tabSupp": "廠商查詢分類",
      "tabMtype": "標準分類 ",
    },
    "warehouse-manage": {
      "cancelClose": "取消關帳",
      "close": "關帳",
      "isClose": "是否關帳",
      "mgmtcat": "庫房授權",
      "pageHeader": "庫房規劃維護",
      "pdtClass_0": "原料/耗材",
      "pdtClass_1": "物料",
      "pdtClass_2": "非標零件",
      "pdtClass_3": "通用零件",
      "pdtClass_4": "半成品",
      "pdtClass_5": "成品",
      "pdtclass": "料階",
      "ps": "車間",
      "tabWareHouse": "倉別資料設定",
      "whID": "倉別ID",
      "whNM": "倉別名稱",
      "psNM": "車間名稱",
    },
    "string-manage": {
      "pageHeader": "功能字串管理",
      "tabPause": "停工項目",
      "tabOkItem": "良品項目",
      "tabNgItem": "不良品項目",
      "tabDri": "責任單位",
      "tabOffLine": "下線項目",
      "driItemID": "單位ID",
      "driItemNM": "單位名稱",
    },
    "client": {
      "groupalias": "集團簡稱",
      "location": "市場區域",
      "account": "銀行帳號",
      "addCustomerInformation": "新增客戶資料",
      "addr": "公司地址",
      "address": "連絡住址",
      "bankAddrOS": "銀行地址",
      "bankCodeOS": "分行代號",
      "branchOS": "分行名稱",
      "classificationOfRestrictions": "限權分類",
      "contact": "聯絡人",
      "country": "國別",
      "custfax": "客户传真",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "部門",
      "domesticBankName": "國內銀行名稱",
      "domestic_OverseasBankInformation": "國內/海外銀行資料",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10條/頁",
      "itemNM": "窗口功能",
      "itemno": "項序",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "郵件",
      "maincurrency": "幣別",
      "maxcredit": "信用條件",
      "mobile": "行動電話",
      "modifyContactWindowInformation": "修改聯絡窗口資料",
      "modifyCustomerInformation": "修改客戶資料",
      "no": "否",
      "ordertype": "訂貨類別",
      "overseasBankName": "海外銀行名稱",
      "pageHeader": "客戶資料維護",
      "pageHeader2": "客戶資料管理",
      "payterm": "付款條件",
      "postID": "職稱",
      "showTotal": "共 1 項 ， 正在顯示第1到1項",
      "swiftCode": "SWIFT CODE",
      "taxID": "統一編號",
      "tel": "公司電話",
      "username": "戶名",
      "yes": "是",
    },
    "company": {
      "manufacturerBasicInformation": "廠商基本資料",
      "manufacturerContactInformation": "廠商聯絡資料",
      "pageHeader": "廠商資料維護",
      "suppID": "廠商ID",
      "suppNM": "廠商名稱",
      "suppfax": "公司传真",
      "suppalias": "廠商簡稱",
      "groupalias": "集團簡稱",
      "location": "市場區域",
      "account": "銀行帳號",
      "addCustomerInformation": "新增客戶資料",
      "addr": "公司地址",
      "address": "連絡住址",
      "bankAddrOS": "銀行地址",
      "bankCodeOS": "分行代號",
      "branchOS": "分行名稱",
      "classificationOfRestrictions": "限權分類",
      "contact": "聯絡人",
      "country": "國別",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "部門",
      "domesticBankName": "國內銀行名稱",
      "domestic_OverseasBankInformation": "國內/海外銀行資料",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10條/頁",
      "itemNM": "窗口功能",
      "itemno": "項序",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "郵件",
      "maincurrency": "幣別",
      "maxcredit": "信用條件",
      "mobile": "行動電話",
      "modifyContactWindowInformation": "編輯聯絡窗口資料",
      "modifyCustomerInformation": "編輯客戶資料",
      "no": "否",
      "ordertype": "訂貨類別",
      "overseasBankName": "海外銀行名稱",
      "payterm": "付款條件",
      "postID": "職稱",
      "showTotal": "共 1 項 ， 正在顯示第1到1項",
      "swiftCode": "SWIFT CODE",
      "taxID": "統一編號",
      "tel": "公司電話",
      "username": "戶名",
      "yes": "是",
    },
    "edm": {
      "alertspeed": "產速報警",
      "alertstop": "逾停報警",
      "warnyield": "良率黃燈",
      "alertyield": "良率紅燈",
      "attribute": "物料屬性",
      "bom": "物料清單維護",
      "errorPleaseFull": "請填寫完整資料",
      "ieUUID": "匯算單號",
      "isrework": "重工",
      "level": "料階",
      "nextSN": "次途程號",
      "pPN": "父件料號",
      "pageHeader": "技術資料維護",
      "pdtclass": "商品類別",
      "pfNM": "製程",
      "planhead": "計劃人數",
      "ppdtNM": "父件品名",
      "productionScheduleDetails": "途程明細",
      "pwcNM": "工作中心",
      "qty": "組數",
      "stdlt": "備產時間",
      "stdmh": "標準機時",
      "stdwh": "標準人時",
      "batchbom": "批號滿包數",
      "unitNM": "計量單位",
      "updtT": "更新時間",
      "ver": "版碼",
      "wpcManage": "生產途程維護",
      "wpc": "生產途程",
      "wpcSN": "途程號",
      "wpcSetting": "途程號設定",
      "wpcset": "途程組",
      "wpcsetNM": "途程名稱",
      "whID": "入庫倉別",
      "alternative_wpc": "替代途程",
      "main_wpc": "主途程",
      "edit_all_wpc": "途程全局編輯",
      "Pfc": "工程文件版控",
      "doc": "標準文件發行",
      "ECN": "工程變更通知",
      "pdftype": "文件類別",
      "docUpload": "文件上傳",
      "Upload": "上傳",
      "docName": "文件名稱",
      "docVer": "文件版本",
    },
    "pn-manage": {
      "basicProduct": "檢驗項目",
      "dealPNSearch": "交易料號查詢",
      "boxH": "包裝尺寸 (高)",
      "boxL": "包裝尺寸 (長)",
      "boxW": "包裝尺寸 (寬)",
      "dealID": "對象ID",
      "dealNM": "交易品名",
      "dealPN": "交易料號/SKU",
      "dealalias": "對象簡稱",
      "dealpid": "條碼",
      "idkw": "包裝條碼關字",
      "inititalcost": "期初程本",
      "isfocus": "是否關注",
      "isvalid": "是否有效",
      "maxcost": "庫存金額上限",
      "maxstock": "庫存上限",
      "minstock": "庫存下限",
      "moq": "最小起購量",
      "newPN": "新版料號",
      "nextpacksn": "外層包裝層數",
      "opunit": "操作單位",
      "pack": "包裝單位",
      "packsn": "包裝層數",
      "packtype": "包裝用途",
      "pageHeader": "檢驗項目維護",
      "palH": "棧板 (高)",
      "palL": "棧板 (長)",
      "palW": "棧板 (寬)",
      "pbom": "包裝內層組數",
      "pdtH": "產品尺寸 (高)",
      "pdtL": "產品尺寸 (長)",
      "pdtW": "產品尺寸 (寬)",
      "punit": "內層使用單位",
      "purchasecost": "本期進貨成本",
      "purchaseqty": "本期進料量",
      "replacePN": "替代料號",
      "safestock": "安全庫存",
      "salecurrency": "幣別",
      "setbatch": "批號帳管",
      "setidm": "條碼設定",
      "setpack": "包裝設定",
      "settlemonth": "變動結算月份",
      "shipV": "包裝材積CUFT",
      "shipW": "運裝重量",
      "sizeunit": "尺寸單位",
      "stdcost": "標準成本",
      "stdsale": "標準價格",
      "stdstock": "標準庫存",
      "stocklife": "庫存週期",
      "tabCost": "標準成本設定",
      "tabDeal": "交易料號設定",
      "tabExtra": "產品尺寸設定",
      "tabIdkw": "生產綁定設定",
      "tabPack": "包裝綁定設定",
      "tabPrice": "標準價格設定",
      "tabStock": "庫存條件設定",
      "unitwt": "計數單重",
      "varcost": "變動成本",
      "ver": "包裝標準版本",
      "weightunit": "重量單位",
      "mtype": "引用标准",
    },
    "mold": {
      "pageHeader": "模具資料維護",
      "mold": "模具資料",
      "assetkind": "模具類型",
      "assetID": "模具ID",
      "assetNM": "資產名稱",
      "pfID": "製程ID",
      "moldtype": "共模",
      "cavity": "實體穴數",
      "stdcavity": "標準穴數",
      "validcavity": "有效穴數",
      "wsID": "工站",
      "property": "財產歸屬",
      "custID": "客戶ID",
      "company": "公司",
      "gatesystem": "澆口型式",
      "moldlife": "設計壽命",
      "draw": "模具圖面",
      "pdtpic": "成品照片",
      "malepic": "公模照片",
      "femalepic": "母模照片",
      "whloepic": "合模照片",
      "advance": "進階資料",
      "length": "模具總長",
      "width": "模具總寬",
      "height": "模具總高",
      "weight": "模具重量",
      "ismrplan": "",
      "ismrcycle": "",
      "setmrcycle": "",
      "ismrperiod": "",
      "setmrperiod": "",
      "premrT": "",
      "premrC": "",
      "nextmrT": "",
      "nextmrC": "",
      "openC": "開帳模次",
      "openT": "開帳日期",
      "cummoldC": "累計模次",
      "precummoldC": "上次累計生產模數",
      "status": "狀況燈號",
      "note": "模具註記",
    },
    "plant-manage": {
      "pageHeader": "廠別主檔設定",
      "plantID": "廠別",
      "deptID": "部門別",
      "postID": "職稱ID",
      "level": "推播層級",
      "push1": "一級推播時距(分)",
      "push2": "二級推播時距(分)",
      "push3": "三級推播時距(分)",
      "tabDept": "部門主檔",
      "tabPlant": "廠區主檔",
      "tabPost": "職稱主檔",
    },
    "production-line-manage": {
      "MC": "物料作業",
      "QC": "質控作業",
      "TK": "生產看板",
      "WOC": "生產控制",
      "auth_setting": "權限設置",
      "batch": "批號管理",
      "dispachManage": "派單管理",
      "mold": "模具管理",
      "oem": "委外報工",
      "pageHeader": "產線規劃維護",
      "partsBinding": "部品掃碼",
      "pcc": "成本中心",
      "pf": "製程",
      "ppidp": "成品綁定",
      "ps": "車間",
      "psID": "車間ID",
      "psNM": "車間名稱",
      "pwc": "工作中心",
      "tabLine": "工線資料設定",
      "tabPS": "車間資料設定",
      "tabStation": "工站資料設定",
      "tbHeader_PS": "工站資料",
      "tbHeader_Station": "工線資料",
      "whm": "工時管理",
      "wlID": "工線ID",
      "wlNM": "工線名稱",
      "wpcSearch": "途單查詢",
      "ws": "工站",
      "wsID": "工站ID",
      "wsNM": "工站名稱",
    },
  },
  APS: {
    "util": {
      "opcode": "作業單別",
      "opcode_3310": "正常工單",
      "opcode_3320": "重工工單",
      "opcode_3330": "試產工單",
      "opcode_3340": "工程工單",
      "duestart": "預計完成起日",
      "dueend": "預計完成迄日",
      "realqty": "實際數量",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "生管派單作業",
      "wo": "工單",
      "tabWo": "工單主檔",
      "mo": "料單",
      "jobN": "工單號",
      "tabMo": "料單主檔",
      "orderN": "客戶訂購單號",
      "ETDstart": "預計交貨起日",
      "ETDend": "預計交貨迄日",
      "issueID": "發佈人員",
      "issueday": "發佈日期",
    },
  },
  PMS: {
    "pageHeader": "採購管理系統",
    "util": {
      "opcode_2010": "詢價計畫",
      "opcode_2210": "國內請購",
      "opcode_2220": "國外請購",
      "opcode_2230": "委外申請",
      "opcode_2310": "國內採購",
      "opcode_2320": "國外採購",
      "opcode_2330": "委外加工",
      "opcode_2410": "國內進料",
      "opcode_2420": "國外進料",
      "opcode_2430": "委外進料",
      "opcode_2610": "庫房退出",
      "opcode_2620": "品保退出",
      "opcode_2710": "採購進料",
      "opcode_2810": "應付沖帳",
      "opcode_4120": "驗收單",
      "opcode_4230": "驗退單",
      "plan": "詢價單",
      "req": "請購單",
      "pro": "採購單",
      "instock": "進料單",
      "gostock": "進料檢驗單",
      "return": "進料退出",
      "pay": "應付帳款查詢",
      "ap": "對帳單",
      "strike": "付款沖帳",
      "advanceSearch": "進階查詢",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "table_suppCheckNum": "廠商確認數量",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "submit": "確定",
      "cancel": "取消",
      "sysMsg": "系統訊息",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N22": "請購單號",
      "N23": "採購單號",
      "N24": "進料單號",
      "N27": "對帳單號",
      "N28": "沖帳單號",
      "outN": "驗退單號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "itemsum": "單項金額(未稅)",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "delete_confirm": "刪除確認",
      "reqSearch": "請購紀錄查詢",
      "proSearch": "採購紀錄查詢",
      "instockSearch": "進料紀錄查詢",
      "stockSearch": "驗收紀錄查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "apSearch": "對帳資料查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "currencytype_placeholder": "請選擇幣別",
      "suppID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入税率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "currencytype": "交易幣別",
      "taxtype": "交易稅別",
      "taxrate": "稅率",
      "suppID": "廠商ID",
      "suppalias": "廠商簡稱",
      "suppNM": "廠商名稱",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "EDT": "預計交貨日",
      "demansDate": "需求日期",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmID": "覆核人員",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "updtID": "更新人員",
      "updtT": "更新時間",
      "batchno": "批號",
      "batchdate": "批號日期",
      "stockinT": "移轉時間",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "idmtype": "過帳型態",
      "drawno": "圖號",
      "pbom": "組合數量",
      "utype": "單位類別",
      "punit": "使用單位",
      "unit": "計量單位",
      "opunit": "操作單位",
      "unitprice": "採購單價",
      "localprice": "本幣單價",
      "tax": "總稅額",
      "balqty": "剩餘數量",
    },
    "plan": {
      "pageHeader": "詢價計畫",
      "hd_delete_confirm_text": "請先確認要刪除的詢價單資料無誤，再進行刪除",
      "demandqty": "需求數量",
      "tb": "計畫明細",
      "tb_drawer_title": "詢價計畫明細",
      "rec_drawer_title": "規格評估",
    },
    "req": {
      "pageHeader": "請購作業",
      "create_header": "新建請購主檔",
      "edit_header": "編輯請購主檔",
      "hd_note": "請購註記",
      "driID": "負責請購",
      "punit": "請購單位",
      "unitprice": "請購單價(未稅)",
      "demandqty": "請購數量",
      "demandDate": "需求日期",
      "itemsum": "單項金額(未稅)",
      "itemsumtax": "單項稅額",
      "itemtotal": "未稅總金額",
      "planSearch": "詢價紀錄查詢",
      "local_subtotal": "本幣含稅總金額",
      "subtotal": "單項含稅小計",
      "total": "含稅總金額(交易幣別)",
      "lifeF_0": "發佈",
      "lifeF_2": "請購覆核",
      "lifeF_7": "審核送簽完成",
      "lifeF_T": "結案",
      "del_hd_confirm_text": "請先確認要刪除的請購單資料無誤，再進行刪除",
      "audit_start": "審核正式送簽",
      "audit_finish": "已完成審查",
      "reqCheck": "請購單覆核",
      "cancel_reqCheck": "取消請購覆核",
      "check_confirm": "請購覆核確認",
      "check_confirm_text": "請先確認要覆核的資料無誤，再進行覆核",
      "cancel_check_confirm": "取消請購覆核確認",
      "cancel_check_confirm_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "reqCheck_lifeF_0_okText": "確定覆核",
      "reqCheck_lifeF_2_okText": "取消覆核",
      "audit_confirm": "審查簽核確認",
      "audit_confirm_text": "審查簽核後此請購單將無法編輯，確定要完成審查嗎",
      "audit_okText": "確定審查",
      "driID_placeholder": "請選擇請購人員",
      "demandqty_placeholder": "請填寫請購數量",
      "unitprice_placeholder": "請填寫未稅單價",
      "tb_delete_confirm": "請購明細刪除確認",
      "tb_title": "請購明細",
      "create_tb_title": "新增請購明細",
      "edit_tb_title": "編輯請購明細",
      "throw_modal_submit": "確定拋轉【採購單】",
    },
    "pro": {
      "pageHeader": "採購作業",
      "create_header": "新建採購主檔",
      "edit_header": "編輯採購主檔",
      "hd_drawer_title": "採購訂單主檔",
      "driID": "負責採購",
      "EDT": "預計交貨日",
      "startEDT": "預計交貨日(起)",
      "endEDT": "預計交貨日(迄)",
      "sampleID": "抽樣人員",
      "supp_history": "廠商交易歷史查詢",
      "trade": "貿易條件",
      "dealaddr": "廠商地址",
      "contact": "廠商交貨窗口",
      "dealcontact": "廠商業務窗口",
      "payment": "付款方式",
      "sumtotal": "未稅總金額",
      "local_subtotal": "本幣含稅總金額",
      "total": "含稅總金額",
      "local_total": "含稅總金額(交易幣別)",
      "note": "採購註記",
      "demandqty": "採購數量",
      "punit": "採購單位",
      "unitprice": "採購單價(未稅)",
      "confirmqty": "廠商確認數量",
      "dealN": "廠商單號",
      "cancelqty": "取消數量",
      "returnqty": "退貨數量",
      "itemsum": "未稅單項金額",
      "itemsumtax": "單項稅額",
      "subtotal": "含稅小計",
      "approveT": "核准日期",
      "charge": "總加扣款項",
      "ReviewProcess": "審核流程(3)",
      "tb_drawer_title": "採購訂單明細",
      "lifeF_0": "發佈",
      "lifeF_2": "採購覆核",
      "lifeF_7": "廠商簽回",
      "lifeF_T": "结案",
      "del_hd_confirm_text": "請先確認要刪除的採購訂單資料無誤，再進行刪除",
      "hd_close_confirm_text": "請先確認要結令的採購單資料無誤，再進行強制結令",
      "proT": "採購日期",
      "dealN_placeholder": "請輸入廠商單號",
      "payment_placeholder": "請選擇付款方式",
      "trade_placeholder": "請選擇貿易條件",
      "dealcontact_placeholder": "選擇廠商業務窗口",
      "contact_placeholder": "選擇廠商交貨窗口",
      "unitprice_placeholder": "請填寫未稅單價",
      "demandqty_placeholder": "請填寫採購數量",
      "note_placeholder": "請輸入採購註記",
      "statement_placeholder": "請輸入項目說明",
      "amount_placeholder": "請輸入金額",
      "proCheck": "採購覆核(1)",
      "cancel_proCheck": "取消覆核",
      "suppCheck": "廠商簽回(2)",
      "cancel_suppCheck": "取消簽回",
      "proCheck_confirm": "採購覆核確認",
      "proCheck_confirm_text": "請先確認要採購覆核的資料無誤，再進行採購覆核",
      "cancel_proCheck_confirm_text": "請先確認要取消採購覆核的資料無誤，再進行取消採購覆核",
      "suppCheck_confirm": "廠商簽回確認",
      "suppCheck_confirm_text": "請先確認要簽回的資料無誤，再進行簽回",
      "cancel_suppCheck_confirm": "取消廠商簽回確認",
      "cancel_suppCheck_confirm_text": "請先確認要取消簽回的資料無誤，再進行取消簽回",
      "tb": "採購明細",
      "adda": "條款註記",
      "adda_delete_confirm_text": "請先確認要刪除的條款資料無誤，再進行刪除",
      "addb": "加扣款項",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc": "預付作業",
      "addc_delete_confirm_text": "請先確認要刪除的預付作業無誤，再進行刪除",
      "statement": "項目說明",
      "amount": "未稅金額",
      "selectstatement": "請選擇加扣款項",
      "totalStart": "最小值",
      "totalEnd": "最大值",
      "deal_history": "產品交易歷史查詢",
      "transferqty": "已拋轉數量",
      "throwqty": "本次拋轉數量",
      "message_transfer_small_thsn_0": "拋轉數量不可小於0",
      "exit_Disposal": "退出處置",
    },
    "instock": {
      "pageHeader": "進料作業",
      "create_header": "新建進料主檔",
      "edit_header": "編輯進料主檔",
      "hd_drawer_title": "進料單主檔",
      "contact": "聯絡人",
      "tel": "聯絡人電話",
      "sampleID": "抽樣人員",
      "sampleT": "抽樣日期",
      "note": "進料註記",
      "del_hd_confirm_text": "請先確認要刪除的進料單資料無誤，再進行刪除",
      "tb": "進料明細",
      "goqty": "驗收數量",
      "rejectqty": "驗退數量",
      "indqty": "暫收數量",
      "inspectID": "進檢人員",
      "inspectT": "完驗時間",
      "qalID": "品判人員",
      "qalT": "放行時間",
      "lifeF_0": "發佈",
      "lifeF_2": "進料覆核",
      "lifeF_3": "IQC抽驗",
      "lifeF_4": "IQC完驗",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "IQCstart": "進檢抽樣",
      "QAcheck": "品保放行",
      "punit": "進料單位",
      "inbatchno": "進料批號",
      "judgeT": "品保判定日期",
      "instockCheck_confirm": "進料覆核確認",
      "instockCheck_confirm_text": "請先確認要進料覆核的資料無誤，再進行進料覆核",
      "cancel_instockCheck_confirm_text": "請先確認要取消進料覆核的資料無誤，再進行取消進料覆核",
      "IQCcheck_confirm": "進檢抽樣確認",
      "IQCcheck_confirm_text": "請先確認要進檢抽樣的資料無誤，再進行進檢抽樣",
      "cancel_IQCcheck_confirm_text": "請先確認要取消進檢抽樣的資料無誤，再進行取消進檢抽樣",
      "IQCfinish_confirm": "IQC完驗確認",
      "IQCfinish_confirm_text": "請先確認要IQC完驗的資料無誤，再進行IQC完驗",
      "cancel_IQCfinish_confirm_text": "請先確認要取消IQC完驗的資料無誤，再進行取消IQC完驗",
      "QAcheck_confirm": "品保放行確認",
      "QAcheck_confirm_text": "請先確認要品保放行的資料無誤，再進行品保放行",
      "cancel_QAcheck_confirm_text": "請先確認要取消品保放行的資料無誤，再進行取消品保放行",
      "QAQTYcheck": "進料明細[品保判退數量]+[品保放行數量]要等於[進料數量]",
      "throwStock_message": "即將進入拋轉進料檢驗單作業，是否確定拋轉?",
      "dealN": "廠商單號",
      "takeqty": "引用數量",
      "qty": "引用數量",
      "unitprice_placeholder": "請填寫未稅單價",
      "Tooltip_disable_take_balqty_0": "剩餘數量為0不可引用",
      "message_take_before_choose": "請先選擇引用再輸入引用數量",
      "message_transfer_small_thsn_0": "拋轉數量不可小於0",
      "message_take_same_detail": "不可引用相同明細",
    },
    "gostock": {
      "pageHeader": "進料檢驗查詢",
      "4120_header": "驗收主檔",
      "4230_header": "驗退主檔",
      "total": "移轉總量",
      "planqty": "移轉數量",
      "jobN": "來源單號",
      "batchno": "批號",
      "batchno_placeholder": "請輸入批號",
      "note": "註記",
      "batchdate": "批號日期",
      "tb": "驗收明細",
      "punit": "使用單位",
      "S": "人工計數",
      "D": "條碼計數",
      "M": "混合計數",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "應付帳款管理",
      "ap_create_header": "新建對帳單主檔",
      "ap_edit_header": "編輯對帳單主檔",
      "ap_sumtotal": "總項金額",
      "strike_sumtotal": "沖帳總金額",
      "takeAp": "引用對帳單",
      "contactWindow": "聯絡窗口資訊",
      "chooseWindow": "選擇窗口",
      "ap_tb": "對帳單明細",
      "strike_tb": "沖帳單明細",
      "inbatchno": "進料批號",
      "itemtotal": "應付含稅金額",
      "hubqty": "入庫數量",
      "balance": "帳差",
      "recon": "對帳資料",
      "addr": "客戶地址",
      "addr_placeholder": "請輸入聯絡地址",
      "strike_tb_delete_confirm": "沖帳明細刪除確認",
      "paytotal": "已沖帳金額",
      "ap_total": "應收含稅總金額",
      "apCheck": "對帳覆核",
      "cancel_apCheck": "取消覆核",
      "apCheck_confirm": "對帳覆核確認",
      "apCheck_confirm_text": "即將進入對帳覆核作業，是否儲存對帳單資料?",
      "releaseAP": "出對帳單",
      "payment_placeholder": "請選擇付款方式",
      "cancel_releaseAP": "取消出對帳單",
      "releaseAP_confirm": "出對帳單確認",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck": "沖帳覆核",
      "strike_create_header": "新建沖帳單主檔",
      "strike_edit_header": "編輯沖帳單主檔",
      "hd_drawer_title": "對帳資料主檔",
      "itemNM": "窗口註記",
      "paytype": "付款方式",
      "strikeCheck_confirm": "沖帳覆核確認",
      "strikeCheck_confirm_text": "請先確認要覆核的資料無誤，再進行覆核",
      "cancel_strikeCheck_confirm_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "confirmStrike": "確認沖帳",
      "confirmStrike_confirm": "沖帳確認",
      "confirmStrike_confirm_text": "確認沖帳後此沖帳單將結案，請確認是否沖帳",
      "strike_paytotal": "付款金額",
      "payT": "付款時間",
      "note": "沖帳註記",
      "PN": "ERP料號",
      "punit": "使用單位",
      "inqty": "移轉數量",
      "itemsum": "各項金額",
      "itemsumtax": "各項稅額",
      "subtotal": "含稅小計",
      "ap_del_hd_confirm_text": "請先確認要刪除的對帳單資料無誤，再進行刪除",
      "strike_del_hd_confirm_text": "請先確認要刪除的沖帳單資料無誤，再進行刪除",
      "message_chose_take": "請選擇要引用的對帳資料",
      "AP_lifeF_0": "創立",
      "AP_lifeF_1": "覆核",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "沖帳(付款)未結案",
      "AP_lifeF_T": "結案",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "完成沖帳",
      "message_chose_take_which_ap": "請選擇要引用的對帳資料",
      "message_only_take_one_ap": "只可引用一張對帳單",
    },
    "return": {
      "pageHeader": "進料退出作業",
      "create_header": "新建進料退出主檔",
      "edit_header": "編輯進料退出主檔",
      "contact": "聯絡人",
      "tel": "聯絡電話",
      "addr": "聯絡地址",
      "del_hd_confirm_text": "請先確認要刪除的進料退出單資料無誤，再進行刪除",
      "rtnqty": "退回數量",
      "punit": "使用單位",
      "inbatchno": "進料批號",
      "note": "進退註記",
      "tb": "進料退出明細",
      "itemNM": "窗口註記",
      "itemNM_placeholder": "請輸入窗口註記",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
      "message_at_least_chose_one": "請至少選擇一筆驗退單明細",
      "planqty": "驗退數量",
    },
  },
  OMS: {
    "pageHeader": "銷貨管理系統",
    "util": {
      "opcode_1110": "國內報價",
      "opcode_1120": "國外報價",
      "opcode_1210": "預估訂單",
      "opcode_1310": "國內訂單",
      "opcode_1320": "國外訂單",
      "opcode_1410": "國內訂單",
      "opcode_1420": "國外訂單",
      "opcode_4220": "驗出單",
      "opcode_4130": "驗退單",
      "opcode_1610": "銷貨退回",
      "opcode_1710": "銷售出貨",
      "opcode_1810": "應收沖帳",
      "plan": "計畫單",
      "quo": "報價單",
      "fod": "預訂單",
      "ord": "銷貨單",
      "outstock": "發貨單",
      "return": "銷貨退回單",
      "ap": "對帳單",
      "outN": "驗退單號",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "add": "新增",
      "submit": "確定",
      "cancel": "取消",
      "signBack": "签回",
      "search": "搜尋",
      "sysMsg": "系統訊息",
      "del_confirm": "刪除確認",
      "advanceSearch": "進階查詢",
      "ok": "確認",
      "yes": "是",
      "no": "否",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N11": "報價單號",
      "N12": "預訂單號",
      "N13": "銷貨單號",
      "N14": "發貨單號",
      "N17": "應收單號",
      "N18": "沖帳單號",
      "jobN": "作業單號",
      "batchno": "批號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "planSearch": "計畫紀錄查詢",
      "quoSearch": "報價紀錄查詢",
      "fodSearch": "預訂紀錄查詢",
      "ordSearch": "銷貨紀錄查詢",
      "stockSearch": "驗收記錄查詢",
      "outstockSearch": "發貨紀錄查詢",
      "apSearch": "對帳資料查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "N_placeholder": "請選擇單號",
      "currencytype_placeholder": "請選擇幣別",
      "custID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入稅率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "custID": "客戶ID",
      "custalias": "客戶簡稱",
      "custNM": "客戶名稱",
      "currencytype": "幣別",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "taxtype": "稅別",
      "taxrate": "稅率",
      "confirmID": "覆核人員",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "approveT": "核准日期",
      "updtID": "更新人員",
      "updtT": "更新日期",
      "EDT": "預計交貨日",
      "startEDT": "預計交貨日(起)",
      "endEDT": "預計交貨日(迄)",
      "dealN": "客戶單號",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "drawno": "圖號",
      "attribute": "物料屬性",
      "pdtclass": "商品類別",
      "pdttype": "管理分類",
      "pbom": "組合數量",
      "utype": "單位類別",
      "unit": "計量單位",
      "opunit": "操作單位",
      "punit": "使用單位",
      "localprice": "本幣金額",
      "tax": "總稅額",
      "itemsum": "單項金額(未稅)",
      "itemsumtax": "各項稅額",
      "subtotal": "含稅小計",
      "batchdate": "批號日期",
      "quoID": "報價人員",
      "quoT": "報價日期",
      "balqty": "剩餘數量",
    },
    "quo": {
      "pageHeader": "報價作業",
      "quoID": "報價人員",
      "quoT": "報價日期",
      "validdays": "有效天數",
      "isvalid": "是否有效",
      "hd_drawer_title": "報價單主檔",
      "tb_drawer_title": "報價單明細",
      "demand": "報價數量",
      "punit": "報價單位",
      "unitprice": "報價金額",
      "isapprove": "單項成交",
      "itemsum": "單項金額",
      "itemsumtax": "單項稅額",
      "subtotal": "單項含稅小計",
      "itemtotal": "未稅總金額",
      "local_subtotal": "本幣含稅總金額",
      "total": "含稅總金額(交易幣別)",
      "note": "報價註記",
      "hd_delete_confirm_text": "請先確認要刪除的報價單資料無誤，再進行刪除",
      "lifeF_0": "發佈",
      "lifeF_2": "報價覆核",
      "lifeF_7": "審核正式簽送",
      "lifeF_T": "結案",
      "quoCheck_confirm_Text": "請先確認要覆核的資料無誤，再進行覆核",
      "cancel_quoCheck_confirm_Text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "Audit_confirm": "審查簽核確認",
      "Audit_confirm_text": "審查簽核後此報價單將無法編輯，確定要完成審查嗎",
      "tb": "報價明細",
    },
    "fod": {
      "pageHeader": "預訂作業",
      "hd_drawer_title": "預訂單主檔",
      "tb_drawer_title": "預訂單明細",
      "driID": "負責業務",
      "trade": "貿易條件",
      "dealcontact": "客戶業務窗口",
      "payment": "付款方式",
      "dealaddr": "客戶地址",
      "custHistory": "客戶交易歷史查詢",
      "contact": "客戶交貨窗口",
      "sumtotal": "未稅總金額",
      "tax": "總稅額",
      "note": "預訂註記",
      "total": "含稅總金額",
      "demandqty": "客訂數量",
      "punit": "訂購單位",
      "unitprice": "訂購單價",
      "confirmqty": "確認數量",
      "cancelqty": "取消數量",
      "returnqty": "退貨數量",
      "transferqty": "已拋轉數量",
      "qty": "預計拋轉數量",
      "hd_delete_confirm_text": "請先確認要刪除的預訂單資料無誤，再進行刪除",
      "lifeF_0": "發佈",
      "lifeF_2": "預訂覆核",
      "lifeF_7": "客戶簽回",
      "lifeF_T": "結案",
      "local_total": "含稅總金額(本幣)",
      "fodCheck_confirm_text": "請先確認要預訂覆核的資料無誤，再進行預訂覆核",
      "cancel_fodCheck_confirm_text": "請先確認要取消預訂覆核的資料無誤，再進行取消預訂覆核",
      "custCheck_confirm": "客戶簽回確認",
      "custCheck_confirm_text": "請先確認要簽回的資料無誤，再進行簽回",
      "cancel_custCheck_confirm_text": "請先確認要取消簽回的資料無誤，再進行取消簽回",
      "reviewFlow": "審核流程(3)",
      "tb": "預訂明細",
      "EDT": "預計交貨日",
      "deal_history": "產品交易歷史查詢",
    },
    "ord": {
      "pageHeader": "銷貨作業",
      "hd_drawer_title": "銷貨單主檔",
      "tb": "銷貨單明細",
      "adda": "條款註記",
      "addb": "加扣款項",
      "addc": "預付作業",
      "lifeF_0": "發佈",
      "lifeF_2": "銷貨覆核",
      "lifeF_7": "客戶簽回",
      "lifeF_T": "結令",
      "driID": "負責業務",
      "sumtotal": "未稅總金額",
      "total": "含稅總金額(交易幣別)",
      "dealaddr": "客戶地址",
      "contact": "客戶交貨窗口",
      "dealcontact": "客戶業務窗口",
      "payment": "付款方式",
      "trade": "貿易條件",
      "note": "銷貨註記",
      "reviewFlow": "審核流程(3)",
      "demandqty": "銷貨數量",
      "transferqty": "已拋轉數量",
      "qty": "本次拋轉數量",
      "punit": "銷貨單位",
      "unitprice": "銷貨單價",
      "confirmqty": "客戶確認數量",
      "cancelqty": "取消數量",
      "returnqty": "退貨數量",
      "itemsum": "單項金額",
      "itemsumtax": "單項稅額",
      "charge": "總加扣款項",
      "subtotal": "含稅小計",
      "statement": "項目說明",
      "amount": "未稅金額",
      "local_total": "含稅總金額(本幣)",
      "startEDT": "預計出貨日(起)",
      "endEDT": "預計出貨日(迄)",
      "closeData_confirm_text": "請先確認要結令的銷貨單資料無誤，再進行強制結令",
      "removeData_confirm_text": "請先確認要刪除的銷貨單資料無誤，再進行刪除",
      "orderCheck_text": "請先確認要銷貨覆核的資料無誤，再進行銷貨覆核",
      "cancel_orderCheck_text": "請先確認要取消銷貨覆核的資料無誤，再進行取消銷貨覆核",
      "cancel_custCheck_confirm_text": "請先確認要取消簽回的資料無誤，再進行取消簽回",
      "custCheck_confirm_text": "請先確認要簽回的資料無誤，再進行簽回",
      "adda_delete_confirm_text": "請先確認要刪除的條款資料無誤，再進行刪除",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text": "請先確認要刪除的預付作業無誤，再進行刪除",
      "deal_history": "產品交易歷史查詢",
    },
    "outstock": {
      "pageHeader": "發貨作業",
      "hd_drawer_title": "發貨單主檔",
      "tb_drawer_title": "發貨單明細",
      "tb": "發貨明細",
      "lifeF_0": "發佈",
      "lifeF_2": "發貨覆核",
      "lifeF_3": "IQC抽驗",
      "lifeF_4": "IQC完驗",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "contact": "聯絡人",
      "tel": "聯絡電話",
      "sampleID": "抽樣人員",
      "sampleT": "抽樣日期",
      "note": "發貨註記",
      "punit": "發貨單位",
      "indqty": "發貨數量",
      "goqty": "驗收數量",
      "unitprice": "銷貨單價",
      "rejectqty": "驗退數量",
      "IQCcheck": "進檢抽樣",
      "inspectID": "進檢人員",
      "inspectT": "完驗時間",
      "qalID": "品判人員",
      "qalT": "放行時間",
      "judgeT": "品保判定日期",
      "qty": "數量",
      "choose": "選擇",
      "choose_err": "剩餘數量為0不可引用",
      "throw_save_msg": "請先選擇引用再輸入引用數量",
      "throwQty_msg": "拋轉數量不可小於0",
      "useDetail_msg": "不可引用相同明細",
      "tb_remove_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "IQCcheck_text": "即將進入進檢抽樣作業，是否儲存發貨單資料?",
      "closeData_confirm_text": "請先確認要刪除的發貨單資料無誤，再進行刪除",
      "outstockCheck_text": "請先確認要發貨覆核的資料無誤，再進行發貨覆核",
      "cancel_outstockCheck_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "IQCfinish_text": "即將進入IQC完驗作業，是否儲存發貨單資料?",
      "QAcheck_err": "發貨明細 [驗收數量]+[驗退數量] 要等於 [發貨數量]",
      "QAcheck": "品保放行",
      "QAcheck_text": "即將進入品保放行作業，是否儲存發貨單資料?",
      "throwStock_text": "即將進入拋轉發貨單作業，是否確定拋轉?",
    },
    "check": {
      "pageHeader": "銷貨檢驗查詢",
      "4220_header": "銷售發貨單主檔",
      "4220_tb": "發貨明細",
      "4130_header": "銷售退回單主檔",
      "4130_tb": "退回明細",
      "N": "作業單號",
      "jobN": "來源單號",
      "batchno": "批號",
      "batchdate": "批號日期",
      "note": "註記",
      "total": "移轉總量",
      "planqty": "移轉數量",
      "punit": "銷貨單位",
      "stockinT": "移轉時間",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "return": {
      "pageHeader": "銷貨退回作業",
      "tb": "銷貨退回明細",
      "hd_remove_text": "請先確認要刪除的銷貨退回單資料無誤，再進行刪除",
      "tb_remove_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "take": "取用",
      "unitprice": "出貨單價",
      "rtnqty": "退回數量",
      "inbatchno": "進料批號",
      "contact": "聯絡人",
      "contact_window": "客戶業務窗口",
      "note": "進退註記",
      "tel": "聯絡電話",
      "addr": "聯絡地址",
      "itemNM": "窗口註記",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "應收帳款管理",
      "rcv": "應收帳款查詢",
      "rcvData": "應收資料",
      "rcv_drawer_title": "應收帳款主檔",
      "inbatchno": "進料批號",
      "contactWindow": "聯絡窗口資訊",
      "ap": "對帳單",
      "ap_tb": "對帳單明細",
      "strike": "沖帳作業",
      "strike_tb": "沖帳單明細",
      "unitprice": "銷貨單價",
      "rcv_inqty": "移轉數量",
      "rcv_itemsum": "各項金額",
      "rcv_itemsumtax": "各項稅額",
      "rcv_subtotal": "含稅小計",
      "ap_remove_text": "請先確認要刪除的對帳單資料無誤，再進行刪除",
      "strike_remove_text": "請先確認要刪除的沖帳單資料無誤，再進行刪除",
      "ap_total": "應收含稅總金額",
      "ap_itemtotal": "應收含稅金額",
      "ap_paytotal": "已沖帳金額",
      "ap_sumtotal": "總項金額",
      "strike_sumtotal": "沖帳總金額",
      "ap_hubqty": "出庫數量",
      "ap_addr": "客戶地址",
      "ap_balance": "帳差",
      "ap_lifeF_0": "創立",
      "ap_lifeF_1": "應收覆核",
      "ap_lifeF_2": "出應收單",
      "ap_lifeF_3": "沖帳(收款)未結案",
      "ap_lifeF_T": "結案",
      "ap_contact": "客戶業務窗口",
      "ap_itemNM": "窗口註記",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "沖帳註記",
      "confirmStrike": "確認沖帳",
      "apCheck_confirm_text": "即將進入對帳覆核作業，是否儲存對帳單資料?",
      "releaseAP_confirm_text": "即將進入出應收單確認，是否儲存應收單資料?",
      "strikeCheck_confirm_text": "請先確認要覆核的資料無誤，再進行覆核",
      "cancel_strikeCheck_confirm_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "confirmStrike_confirm_text": "確認沖帳後此沖帳單將結案，請確認是否沖帳",
      "strike_tb_remove_text": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
  },
  BPM: {
    util: {
      "itemno": "項序",
      "N": "單號",
      "opcode": "單別",
      "signmode": "簽核層級",
      "level": "階",
      "opfunction_1": "訂單系統",
      "opfunction_2": "採購系統",
      "opfunction_3": "生產系統",
      "opfunction_4": "庫儲系統",
      "opfunction_5": "財務系統",
      "optype_11": "訂單專案",
      "optype_12": "採購專案",
      "optype_40": "庫儲專案",
      "optype_41": "出庫專案",
      "permissions": "權限",
      "open": "立案",
      "check": "查權",
      "review": "審權",
      "approve": "核權",
      "lifeFT": "歷程時間",
    },
    "sign-level": {
      "pageHeader": "簽核權限設定",
      "opfunction": "營運功能",
      "optype": "作業類別",
    },
    "dsevent": {
      "system": "系統模組",
      "keynote": "主旨",
    },
  },
  PDS: {
    "wpc-search": {
      "wlID": "線別",
      "linemix": "混線",
      "hdC": "高峰人數",
      "issueday": "發佈時間",
    },
    "overview-lines": {
      "pageHeader": "線體稼動總表",
      "wlID": "線別",
      "act": "稼動率",
      "shiftD": "日班",
      "shiftN": "夜班",
      "allDay": "全天",
    },
    "dashboard": {
      "pageHeader": "數位生產看板",
    },
    "abc-board": {
      "pageHeader": "異常管理看板",
    },
  },
};

export default zh_TW;
