import { useState, forwardRef } from "react";

import HD from "./HD";
import TB from "./TB";

const Station = forwardRef((props, ref) => {
  const [c_lightData, set_c_lightData] = useState({}); // 要亮起來的資料

  return (
    <>
      <HD
        {...props}
        ref={ref}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
      />
      {Object.keys(c_lightData).length > 0 &&
      <TB
        {...props}
        c_lightData={c_lightData} />
      }
    </>
  );
});

export default Station;
