/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Spin,
  message,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
// API
import useAPI from "@/hooks/useAPI";
import { getReturnHD } from "@/service/apis/PMS/Return";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../../index";

const { Text } = Typography;

const DetailModal = ({
  // s_detailSearch,
  // set_s_detailSearch,
  call_getReturnHDAd,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 取得全部進料單表頭資料
  const call_getReturnHD = useAPI(getReturnHD);
  // 廠商資料、人員資料
  const { c_supps } = useContext(ERPContext);
  const { c_state, updateState } = useContext(DataContext);
  const { detailSearch } = c_state;
  // 全部進料單單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      suppID: values.suppID?.join("|") ?? "",
      lifeF: values.lifeF?.join("|") ?? "",
    };
    // 取得詳細搜尋的表頭資料
    call_getReturnHDAd.request(new_data);
    // 更新資料並關閉跳顯
    updateState({ detailSearch: { show: false, data: values } });
  };

  // 第一次渲染取得全部進料單做下拉選單、賦值初始值
  useEffect(() => {
    call_getReturnHD.request();
    const { data } = detailSearch;
    if (Object.keys(data).length > 0) {
      form.setFieldsValue(data);
    }
  }, []);

  // useAPI 取得全部進料退出單資料
  useEffect(() => {
    const { data, msg, status } = call_getReturnHD;

    if (status === "suc") {
      const allNs = data?.map((proItem) => ({ lable: proItem.N, value: proItem.N })) ?? [];
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getReturnHD.status]);

  return (
    <Spin size="large" spinning={call_getReturnHD.status === "load"}>
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.opcode")}</Text>} name="opcode">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.opcode_placeholder")}
                options={[{ value: "2620", label: t("PMS.util.opcode_2620") }]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.N")}</Text>} name="N">
              <Select mode="multiple" allowClear placeholder={t("PMS.util.placeholder_select") + t("PMS.util.N")} options={s_allNs} />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.suppalias")}</Text>} name="suppID">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.suppalias")}
                options={c_supps.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 歷程 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.lifeF")}
                options={[
                  { value: "0", label: t("PMS.return.lifeF_0") },
                  { value: "T", label: t("PMS.return.lifeF_T") },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[12, 0]}>
          <Col span={24} className="flex justify-end gap-1">
            <CancelButton onClick={() => updateState({ detailSearch: { ...detailSearch, show: false } })}/>

            <ConfirmButton type="primary" htmlType="submit"/>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DetailModal;
