import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { Modal, Space, message } from "antd";
import { deleteProduct, getProduct, getSuppHd, searchDeal } from "@/service/apis/ADM/pnManage";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer/index";
import { getUnit } from "@/service/apis/ADM/systemParamsManage";
// util
import { multipleIDtoName } from "@/util/format";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = forwardRef((props, ref) => {
	const { t } = useTranslation();

	// arrMap = 把各種ID轉成NM所需要的資料
	const { arrMap, s_tabKey, s_searchData, set_s_searchData, c_lightData, set_c_lightData } = props;

	const [s_tableData, set_s_tableData] = useState([]);
	// 後端回來的資料
	const [s_pageInfo, set_s_pageInfo] = useState({
		currentPage: 0,
		endItemNumber: 0,
		pageItemNumber: 0,
		startItemNumber: 0,
		totalItems: 0,
	});
	const [s_isShowModal, set_s_isShowModal] = useState(false);

	const call_getProduct = useAPI(getProduct);
	const call_searchDeal = useAPI(searchDeal);
	const call_getSuppHd = useAPI(getSuppHd);
	const call_deleteProduct = useAPI(deleteProduct);
	const call_getUnit = useAPI(getUnit);

	// 傳上去查詢用的
	useImperativeHandle(ref, () => ({
		// onSearch: () => call_getProduct.request({ ...s_searchData, page: 1, pageSize: 20 }),
		onSearch: () => call_getProduct.request({ ...s_searchData }),
		onCreate: () => {
			set_s_isShowModal(true);
			set_c_lightData({});
		},
		onDealSearch: () => {
			call_searchDeal.request(s_searchData);
		},
	}));

	const tableColumns = [
		{
			title: "序",
			dataIndex: "SN",
			align: "center",
			width: "5%",
			render: (text, rowData, index) => index + 1,
		},
		{
			title: "料号",
			dataIndex: "PN",
			align: "center",
			width: "20%",
			sorter: (a, b) => a.PN.localeCompare(b.PN),
		},
		{
			title: "品名",
			dataIndex: "pdtNM",
			align: "left",
			width: "20%",
			sorter: (a, b) => a.pdtNM.localeCompare(b.pdtNM),
		},
		// {
		//   title: t("util.mtype"), //引用標準
		//   dataIndex: "mtype",
		//   align: "left",
		//   width: "20%",
		//   sorter: (a, b) => a.mtype.toString().localeCompare(b.mtype.toString()),
		//   render: (text) => multipleIDtoName(arrMap.mgmtcat75, text, "mgmtitem", "itemNM"),
		// },
		{
			title: "规格",
			dataIndex: "pdtspec",
			align: "left",
			width: "20%",
			sorter: (a, b) => a.pdtspec.localeCompare(b.pdtspec),
		},
		{
			title: "查询分类",
			dataIndex: "querycat",
			align: "center",
			className: "bg-[#f9e9d7ac]",
			render: (text) => {
				const map = {
					"1": "办公用品",
					"2": "试剂耗材",
					"3": "玻璃器皿",
					"4": "设备配件",
					"5": "其他类别",
				};
				return map[text];
			},
			// render: (text) => arrMap.attributeMap.find((x) => x.value === text)?.label,
			width: "6%",
		},
		// {
		//   title: t("util.heirdealID"), //項目編號
		//   dataIndex: "heirdealID",
		//   align: "center",
		//   width: "20%",
		//   // sorter: (a, b) => a.PN.localeCompare(b.PN),
		// },

		// {
		//   title: t("util.pdtclass"),//商品
		//   dataIndex: "pdtclass",
		//   className: "bg-[#f9e9d7ac]",
		//   align: "center",
		//   render: (text) => arrMap.pdtclassMap.find((x) => x.value === text)?.label,
		//   width: "8%",
		// },
		// {
		//   title: t("util.querycat"),//查詢分類
		//   dataIndex: "querycat",
		//   className: "bg-[#f9e9d7ac]",
		//   align: "center",
		//   width: "8%",
		//   render: (text) =>
		//     multipleIDtoName(arrMap.mgmtcat74, text, "mgmtitem", "itemNM") || t("util.all"),
		// },
		// {
		//   title: t("util.mgmtcat"), // 授權分類
		//   dataIndex: "mgmtcat",
		//   className: "bg-[#f9e9d7ac]",
		//   align: "center",
		//   width: "8%",
		//   render: (text) =>
		//     multipleIDtoName(arrMap.mgmtcat71, text, "mgmtitem", "itemNM") || t("util.all"),
		// },
		// {
		//   title: t("util.unitNM"),//計量單位
		//   dataIndex: "unit",
		//   align: "center",
		//   width: "5%",
		// },
		// {
		//   title: t("util.unitwt"),
		//   dataIndex: "unitwt",
		//   align: "center",
		//   width: "7%",
		// },
		// {
		//   title: t("util.opunitNM"),//操作單位
		//   dataIndex: "opunit",
		//   width: "5%",
		//   align: "center",
		// },
		// {
		//   title: t("util.isfocus"),//關注
		//   dataIndex: "isfocus",
		//   align: "center",
		//   width: "5%",
		//   sorter: (a, b) => a.isfocus - b.isfocus,
		//   render: (text) => (text ? <IconCheck /> : <IconClose />),
		// },
		// {
		//   title: t("util.isvalid"),//有效
		//   dataIndex: "isvalid",
		//   align: "center",
		//   width: "4.5%",
		//   sorter: (a, b) => a.isvalid - b.isvalid,
		//   render: (text) => (text ? <IconCheck /> : <IconClose />),
		// },
		{
			title: t("util.action"), //操作
			dataIndex: "action",
			align: "center",
			width: "6%",
			render: (_, rowData) => (
				<Space>
					<IconPen
						onClick={(e) => {
							set_c_lightData(rowData);
							set_s_isShowModal(true);
							e.stopPropagation();
						}}
					/>
					<IconGarbage
						onClick={(e) => {
							removeData(rowData);
							e.stopPropagation();
						}}
					/>
				</Space>
			),
		},
	];

	// 確認是否刪資料
	const removeData = (rowData) => {
		Modal.confirm({
			title: t("util.del_confirm"),
			icon: <ExclamationCircleOutlined className="text-red-600" />,
			content: t("util.del_confirm_msg"),
			okText: t("util.ok"),
			onOk: () => call_deleteProduct.request(rowData),
			cancelText: t("util.cancel"),
		});
	};

	useEffect(() => {
		call_getSuppHd.request();
		call_getUnit.request();
	}, []);

	useEffect(() => {
		// 這樣寫是因為『交易料號查詢』call 的是另一條 api
		// 而且只有在點擊搜尋時才需要 call『交易料號查詢』的 api
		if (s_tabKey === "basic") {
			call_getProduct.request(s_searchData);
		}
	}, [s_searchData.page, s_searchData.pageSize]);

	useEffect(() => {
		// 重新拿到資料都把c_lightData清空
		set_c_lightData({});
		if (call_getProduct.status === "suc") {
			message.success(call_getProduct.msg);
			set_s_tableData(
				call_getProduct.data.tableData?.map((x, i) => {
					// 整理資料
					// const querycat = x.querycat.split("|").filter((x) => x !== "");
					const mgmtcat = x.mgmtcat.split("|").filter((x) => x !== "");
					const heirdealID = x.heirdealID.split("|").filter((x) => x !== "");
					const mtype = x.mtype.split("|").filter((x) => x !== "");
					return {
						...x,
						key: i,
						index: i,
						// querycat: querycat,
						mgmtcat: mgmtcat,
						heirdealID: heirdealID,
						mtype: mtype,
					};
				})
			);
			set_s_pageInfo(call_getProduct.data.pageInfo);
		} else if (call_getProduct.status === "err") {
			message.error(t(`errorCode.${call_getProduct.msg}`));
			set_s_tableData([]);
			set_s_pageInfo({
				currentPage: 0,
				endItemNumber: 0,
				pageItemNumber: 0,
				startItemNumber: 0,
				totalItems: 0,
			});
		}
	}, [call_getProduct.status]);

	console.log("s_tableData = ", s_tableData);

	useEffect(() => {
		if (call_deleteProduct.status === "suc") {
			message.success(call_deleteProduct.msg);
			// delete之後 重call表格資料
			call_getProduct.request(s_searchData);
		} else if (call_deleteProduct.status === "err") {
			message.error(t(`errorCode.${call_deleteProduct.msg}`));
		}
	}, [call_deleteProduct.status]);

	// 透過交易料好取得 tabledata
	useEffect(() => {
		const { data, status, msg } = call_searchDeal;
		if (status === "suc") {
			message.success(msg);
			set_s_tableData(
				data?.map((x, i) => {
					// 整理資料
					const querycat = x.querycat.split("|").filter((x) => x !== "");
					const mgmtcat = x.mgmtcat.split("|").filter((x) => x !== "");
					const heirdealID = x.heirdealID.split("|").filter((x) => x !== "");
					return {
						...x,
						key: i,
						index: i,
						querycat: querycat,
						mgmtcat: mgmtcat,
						heirdealID: heirdealID,
					};
				})
			);
		}
		if (status === "err") {
			message.error(t(`errorCode.${msg}`));
			set_s_tableData([]);
		}
	}, [call_searchDeal.status]);

	// 不要刪掉這個鬼東西
	// function handleClick(event) {
	//   event.preventDefault();
	//   if (event.detail === 1) {
	//     if (event.button === 0) {
	//       console.log("滑鼠左鍵單擊了");
	//     } else if (event.button === 2) {
	//       console.log("滑鼠右鍵單擊了");
	//     }
	//   } else if (event.detail === 2) {
	//     if (event.button === 0) {
	//       console.log("滑鼠左鍵雙擊了");
	//     } else if (event.button === 2) {
	//       console.log("滑鼠右鍵雙擊了");
	//     }
	//   }
	//   event.preventDefault();
	// }

	// 不要刪掉這個鬼東西
	// function handleContextMenu(event) {
	//   event.preventDefault();
	//   console.log("event.detail = ", event.detail);
	//   if (event.button === 2 && event.detail === 2) {
	//     console.log("滑鼠右鍵雙擊了");
	//     return;
	//   }
	//   console.log("滑鼠右鍵單擊了");
	// }

	return (
		<>
			{/* <div onClick={handleClick} onDoubleClick={handleClick} onContextMenu={handleContextMenu}>
        不要刪掉這個鬼東西
      </div> */}
			<CustomTable
				columns={tableColumns.filter((x) => !x.hidden)}
				dataSource={s_tableData}
				loading={call_getProduct.status === "load"}
				// tbMode={Object.keys(c_lightData).length > 0}
				c_lightData={c_lightData}
				// onRow={(record) => {
				//   return {
				//     onClick: () =>
				//       record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
				//   };
				// }}
				pagination={{
					total: s_pageInfo?.totalItems,
					current: s_searchData.page,
					pageSize: s_searchData.pageSize,
					onChange: (current, size) =>
						set_s_searchData((prev) => {
							// 如果有更動pageSize 回第一頁
							let checkPage = prev.pageSize !== size ? 1 : current;
							return { ...prev, page: checkPage, pageSize: size };
						}),
				}}
			/>

			<CustomModal
				title={
					Object.keys(c_lightData).length > 0 ? `${t("util.edit")}料号` : `${t("util.add")}料号`
				}
				width={"60%"}
				open={s_isShowModal}
				onCancel={() => set_s_isShowModal(false)}
				afterClose={() => {
					set_c_lightData({});
					call_getProduct.request(s_searchData);
				}}
			>
				<ModalConintaer
					s_editData={c_lightData}
					set_s_editData={set_c_lightData}
					set_s_isShowModal={set_s_isShowModal}
					arrMap={arrMap}
				/>
			</CustomModal>
		</>
	);
});

export default HD;
