/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// context
import { ERPContext } from "./SystemContext";
// route
import { Outlet } from "react-router-dom";
import { getCurrency } from "@/service/apis/ADM/functionParamsManage";
import { getCustHD } from "@/service/apis/OMS/util";
import { getDetectionSelect } from "@/service/apis/ADM/publicAPI";
import { getStaff } from "@/service/apis/ADM/userManage";
import { getSuppHd } from "@/service/apis/ADM/company";
import { message } from "antd";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const ERPOutlet = () => {
  const { t } = useTranslation();
  // useAPI 取得料號資料
  const call_getProduct = useAPI(getDetectionSelect);
  // useAPI 取得廠商資料
  const call_getSuppHD = useAPI(getSuppHd);
  // useAPI 取得客戶資料
  const call_getCustHD = useAPI(getCustHD);
  // useAPI 取得幣別
  const call_getCurrency = useAPI(getCurrency);
  // useAPI 取得員工資料
  const call_getStaff = useAPI(getStaff);

  const [c_supps, set_c_supps] = useState({ data: [], options: [] });
  const [c_custs, set_c_custs] = useState({ data: [], options: [] });
  const [c_currencys, set_c_currencys] = useState({ data: [], options: [] });
  const [c_staffs, set_c_staffs] = useState({ data: [], options: [] });
  const [c_PNs, set_c_PNs] = useState({ data: [], options: [] });

  const c_taxtypes = {
    data: [
      {
        taxtype: "PPN_11%",
        disabled: true,
        taxrate: 11,
      },
      {
        taxtype: "PPN_0%",
        disabled: true,
        taxrate: 0,
      },
    ],
    options: [
      { label: "PPN_11%", value: "PPN_11%" },
      { label: "PPN_0%", value: "PPN_0%" },
    ],
  };

  // 取得員工名稱
  const getStaffName = (staffID = "") => {
    return c_staffs.data?.find((item) => item.staffID === staffID)?.peopleNM ?? staffID;
  };

  useEffect(() => {
    call_getSuppHD.request();
    call_getCustHD.request();
    call_getCurrency.request();
    call_getStaff.request();
    call_getProduct.request();
  }, []);

  // useAPI 取得料號
  useEffect(() => {
    const { data, msg, status } = call_getProduct;

    if (status === "suc") {
      const options =
        // data?.map((item) => ({ label: `${item.pdtNM}(${item.pdtspec})`, value: item.PN })) ?? [];
        data?.map((item) => ({
          label: (
            <span>
              {item.pdtNM}
              <span className="pl-1 text-blue-700">({item.pdtspec})</span>
            </span>
          ),
          value: item.PN,
        })) ?? [];
      console.log("options = ", options);
      set_c_PNs({
        data: data,
        options: options,
      });
      message.success(msg);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProduct.status]);

  // 取得廠商資料
  useEffect(() => {
    const { data, status, msg } = call_getSuppHD;
    if (status === "suc") {
      const options = data
        // .filter((item) => item.rebate === true)
        .filter((item) => item.isvalid)
        .map((item) => {
          return { value: item.suppID, label: `${item.suppID} (${item.suppNM})` };
        });
      set_c_supps({ options: options, data: data });
      message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getSuppHD.status]);

  // 取得客戶資料
  useEffect(() => {
    const { data, status, msg } = call_getCustHD;
    if (status === "suc") {
      const options = data
        //只顯示正式客戶
        .filter((item) => item.isvalid && item.custID.includes("C"))
        .map((item) => {
          // return { value: item.custID, label: `${item.custID}_${item.custalias}` };
          return { value: item.custID, label: `${item.custID}_${item.custNM}` };
        });
      set_c_custs({ options: options, data: data });
      // message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getCustHD.status]);

  // 取得幣別資料
  useEffect(() => {
    const { data, status, msg } = call_getCurrency;
    if (status === "suc") {
      const options = data.map((item) => {
        return { value: item.currencyitem, label: item.itemNM };
      });
      const myCurrency = data.find((item) => item.ismycurrency);

      set_c_currencys({ options: options, data: data, myCurrency: myCurrency });
      // message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getCurrency.status]);

  // 取得員工資料
  useEffect(() => {
    const { data, status, msg } = call_getStaff;

    if (status === "suc") {
      const options = data.map((item) => {
        return { value: item?.staffID, label: item?.peopleNM };
      });
      set_c_staffs({ options: options, data: data, status: status, msg: msg });
      // message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getStaff.status]);

  return (
    <ERPContext.Provider
      value={{
        c_supps: c_supps,
        c_custs: c_custs,
        c_staffs: c_staffs,
        c_PNs: c_PNs,
        set_c_PNs: set_c_PNs,
        c_currencys: c_currencys,
        getStaffName: getStaffName,
        c_taxtypes: c_taxtypes,
      }}
    >
      <Outlet />
    </ERPContext.Provider>
  );
};

export default ERPOutlet;
