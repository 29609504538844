import React from "react";
import { Form, Typography, Steps, } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
// component
import { SummaryButton } from "@/components/BPM/Button";
import PageHeader from "@/components/BPM/PageHeader";
import EditControl from "@/pages/BPM/Event/EditPanel/EditControl";
import Document from "@/pages/BPM/Event/EditPanel/Document";
import History from "@/pages/BPM/Event/EditPanel/History";

const { Text } = Typography;

const EditPage = (props) => {
  const { set_is_showEdit, c_lightData } = props;
  const [form] = Form.useForm();

  // pageHeader中 Steps元件的設定
  const stepItems = [
    {
      title: "創立",
      status: "finish",
    },
    {
      title: "待查案",
      status: "process",
      icon: <EllipsisOutlined className="bg-[#4F81CF] text-3xl text-white rounded-full" />,
    },
    {
      title: "待審案",
    },
    {
      title: "待核案",
    },
    {
      title: "審核完成",
    },
  ];
  const handleIsShowEdit = () => {
    set_is_showEdit((previousValue) => !previousValue);
  };

  return (
    <section className="w-full flex flex-col">
      <PageHeader
        title="簽核作業"
        extra={
          <div className="flex justify-between">
            <Steps
              //className="absolute top-0.5 left-[170px] w-[80%]"
              className="flex-1 mx-12"
              items={stepItems}
            />
            <SummaryButton onClick={handleIsShowEdit} />
          </div>
        }
      />
      <div className="flex gap-3 h-full">
        <div className="flex-[5] p-5 h-full shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
          <EditControl c_lightData={c_lightData} set_is_showEdit={set_is_showEdit} />
        </div>
        {Object.keys(c_lightData).length > 0 &&
          <>
            <div className="flex-[2] h-full">
              <Document c_lightData={c_lightData} />
            </div>

            <div className="flex-[3] h-full flex justify-center shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
              <History c_lightData={c_lightData} />
            </div>
          </>
        }
      </div>
    </section>
  );
};

export default EditPage;
