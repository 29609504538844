import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

import StaffData from "./StaffData";
import RoleAuth from "./RoleAuth";
import SystemAuth from "./SystemAuth";

export default function UserManage() {
  const { t } = useTranslation();

  // 利用ref 子傳父
  const staffData_ref = useRef(null);
  const roleAuth_ref = useRef(null);
  const systemAuth_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 100,
  });
  const [s_tabKey, set_s_tabKey] = useState("staffData"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      query: undefined,
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "staffData" && staffData_ref.current.onSearch();
    s_tabKey === "roleAuth" && roleAuth_ref.current.onSearch();
    s_tabKey === "systemAuth" && systemAuth_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "staffData" && staffData_ref.current.onCreate();
    s_tabKey === "roleAuth" && roleAuth_ref.current.onCreate();
    s_tabKey === "systemAuth" && systemAuth_ref.current.onCreate();
  };
  const tabsData = [
    {
      key: "staffData",
      label: t("ADM.user-manage.staffData"),//員工資料管理
      children: (
        <StaffData
          ref={staffData_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "roleAuth",
      label: t("ADM.user-manage.staffAuth"),//角色權限設定
      children: (
        <RoleAuth
          ref={roleAuth_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "systemAuth",
      label: t("ADM.user-manage.systemAuth"),//系統登入權限
      children: (
        <SystemAuth
          ref={systemAuth_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    s_tabKey === "staffData" && (
      <Input
        key="s_searchData.query"
        value={s_searchData.query}
        onChange={(e) => handlChange("query", e.target.value)}
        placeholder={`${t("util.placeholder_input")}${t("ADM.user-manage.staffID")}`}
      />
    ),
    s_tabKey === "staffData" && (
      // <Button type="primary" key="search" onClick={onSearch}>
      //   {t("util.search")}
      // </Button>
      <SearchButton type="primary" key="search" onClick={onSearch} />
    ),
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.user-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
