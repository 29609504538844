import React, { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Typography,
  Input,
  InputNumber,
  Divider,
  Button,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
// context
import { MainPageContext } from "../../MainPage/index";
// API
import useAPI from "@/hooks/useAPI";
import { addProADD, updateProADD } from "@/service/apis/PMS/Pro";

const { Text } = Typography;

const AddcModal = ({ s_tbModal, set_s_tbModal }) => {
  const { t } = useTranslation();
  const { call_getProADD, hd_values, s_Adds, s_disabled } = useContext(MainPageContext);
  const [form] = Form.useForm();
  // useAPI 新增條款註記
  const call_addProADD = useAPI(addProADD);
  // useAPI 編輯條款註記
  const call_updateProADD = useAPI(updateProADD);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    if (type === "create") {
      // step1 call API新建條款註記
      call_addProADD.request(values);
    }

    if (type === "edit") {
      // step1 call API更新條款註記
      call_updateProADD.request(values);
    }
  };

  // 改變下拉選單
  const changeAddc = (addc) => {
    form.setFieldsValue({
      statement: addc,
    });
  };

  // 第一次渲染賦值
  useEffect(() => {
    const formValues = {
      ...s_tbModal.data,
      addtype: "addc",
      opUUID: hd_values.opUUID,
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 新增提款註記
  useEffect(() => {
    const { status, msg } = call_addProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addc",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addProADD.status]);

  // useAPI 編輯提款註記
  useEffect(() => {
    const { status, msg } = call_updateProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addc",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateProADD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="addtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="selectstatement">
            <Select onChange={changeAddc} options={s_Adds.addc} placeholder={t("util.placeholder_select")+t("PMS.pro.addc")}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={<Text disabled={s_disabled["addc_statement"]}>{t("PMS.pro.statement")}</Text>}
            name="statement"
          >
            <Input disabled={s_disabled["addc_statement"]} placeholder={t("PMS.pro.statement_placeholder")}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={<Text disabled={s_disabled["addc_amount"]}>{t("PMS.pro.amount")}</Text>} name="amount">
            <InputNumber
              disabled={s_disabled["addc_amount"]}
              className="w-full"
              placeholder={t("PMS.pro.amount_placeholder")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })}/>
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit}/>
        </Col>
      </Row>
    </Form>
  );
};

export default AddcModal;
