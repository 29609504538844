import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { addCustHd, modifyCustHd } from "@/service/apis/ADM/client";
import { getAdd, getCurrency } from "@/service/apis/ADM/functionParamsManage";
import { getStaff } from "@/service/apis/ADM/userManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addCustHd = useAPI(addCustHd);
  const call_modifyCustHd = useAPI(modifyCustHd);
  const call_getAdd = useAPI(getAdd);
  const call_getCurrency = useAPI(getCurrency);
  // 取得員工姓名
  const call_getStaff = useAPI(getStaff);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    console.log("=======watch=====");
    console.log(values);

    if (values.driID) {
      values.driID = values.driID?.join("|");
    }

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|");
    }

    // 兆科沒有使用 spuualias 但是系統很多地方都有使用，因此預設 custalias = custNM
    values.custalias = values.custNM;

    if (type === "create") {
      call_addCustHd.request({ ...values, custType: "C" });
    }

    if (type === "edit") {
      call_modifyCustHd.request({ ...s_editData, ...values });
    }
  };

  useEffect(() => {
    console.log("========here=========");

    console.log(s_editData);
    call_getAdd.request();
    call_getCurrency.request();
    // 取得員工姓名
    call_getStaff.request();

    console.log("arrMap?.s_mgmtcat71Options =, ", arrMap?.s_mgmtcat71Options);
    form.setFieldsValue({
      querycat: [arrMap?.s_mgmtcat73Options[0].value],
      mgmtcat: [arrMap?.s_mgmtcat71Options[0].value],
    });
  }, []);

  useEffect(() => {
    if (call_getCurrency.status === "suc") {
      const currencyIsvalidAry = call_getCurrency.data.filter((data) => data.isvalid === true);
      console.log(currencyIsvalidAry[0]);
      form.setFieldsValue({
        maincurrency: currencyIsvalidAry[0].currencyitem,
      });
    }
  }, [call_getCurrency.status]);

  useEffect(() => {
    if (call_getStaff.status === "suc") {
      console.log("call_getStaff data = ", call_getStaff.data);
    }
  }, [call_getStaff.status]);

  useEffect(() => {
    if (call_addCustHd.status === "suc") {
      message.success(call_addCustHd.msg);
      set_s_isShowModal(false);
    } else if (call_addCustHd.status === "err") {
      message.error(t(`errorCode.${call_addCustHd.msg}`));
    }
  }, [call_addCustHd.status]);

  useEffect(() => {
    if (call_modifyCustHd.status === "suc") {
      message.success(call_modifyCustHd.msg);
      set_s_isShowModal(false);
    } else if (call_modifyCustHd.status === "err") {
      message.error(t(`errorCode.${call_modifyCustHd.msg}`));
    }
  }, [call_modifyCustHd.status]);
  console.log(form.getFieldValue());
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      {/* 影藏欄位 */}
      <div>
        <Form.Item label={t("util.isvalid")} hidden name="isvalid">
          <Radio.Group>
            <Radio value={true}>{t("util.yes")}</Radio>
            <Radio value={false}>{t("util.no")}</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item hidden name="custalias">
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Collapse defaultActiveKey={[1]}>
            <Panel header={t("ADM.client.customerBasicInformation")} key="1">
              <Row gutter={[24, 0]}>
                {/* 列  : 8 */}
                <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.custID")} //客戶ID
                    name="custID"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.custalias")} //客戶簡稱
                    name="custalias"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.custNM")} //客戶名稱
                    name="custNM"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item label={t("ADM.client.groupalias")} name="groupalias">
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.location")} //市場區域
                    name="location"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item
                    label={t("util.querycat")} // 查詢分類
                    name="querycat"
                    rules={[{ required: true }]}
                  >
                    <Select allowClear options={arrMap?.s_mgmtcat73Options} />
                  </Form.Item>
                </Col> */}

                {/* <Col span={8}>
                  <Form.Item
                    label={t("util.mgmtcat")}
                    name="mgmtcat" //授權分類
                    // rules={[{ required: true }]}
                  >
                    <Select mode="multiple" allowClear options={arrMap?.s_mgmtcat71Options} />
                  </Form.Item>
                </Col> */}

                <Col span={4}>
                  <Form.Item
                    label={"负责业务"} // 负责业务
                    name="driID"
                  >
                    <Select
                      mode="multiple" // 多選
                      options={
                        call_getStaff.data
                          ?.filter((item) => item.isvalid && item.deptID === "業務")
                          ?.map((x) => ({
                            label: x.peopleNM,
                            value: x.staffID,
                          })) || []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item
                    label={"交易币别"} // 交易幣別
                    name="maincurrency"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getCurrency.data?.map((x) => ({
                          label: x.itemNM,
                          value: x.currencyitem,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={"移动电话"} //公司電話
                    name="tel"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={"客户传真"} // 客戶傳真
                    name="fax"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.taxID")} // 統一編號
                    name="taxID"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={4}>
                  <Form.Item
                    label={"结算方式"} // 結算方式
                    name="maxcredit"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: "月结", value: "月结" },
                        { label: "现结", value: "现结" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="统一社会信用代码"
                    name="usciCode"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="请输入统一社会信用代码" />
                  </Form.Item>
                </Col>
                {/* <Col span={4}>
                  <Form.Item
                    label={
                      t("util.isvalid") //有效
                    }
                    name="isvalid"
                  >
                    <Radio.Group
                      options={[
                        { value: true, label: t("util.yes") },
                        { value: false, label: t("util.no") },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={16}>
                  <Form.Item
                    label={
                      "公司地址" //公司地址
                    }
                    name="addr"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"备注"} name="note">
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.payterm")}//付款條件
                    name="payterm"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getAdd.data?.filter(item => item.addtype === 'addh')?.map((x) => ({
                          label: x.itemNM,
                          key: x.additem,
                          value: x.itemNM,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Panel>
            <Panel header={t("ADM.client.domestic_OverseasBankInformation")} key="2">
              <Row gutter={[24, 0]}>
                <Col span={6}>
                  <Form.Item
                    label={
                      //國內銀行名稱
                      <span className="text-[#9FB2FC]">{t("ADM.client.domesticBankName")}</span>
                    }
                    name="bank"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label={t("ADM.client.branchOS")} //分行名稱
                        name="branch"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t("ADM.client.bankCodeOS")} //分行代號
                        name="bankCode"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col> */}

                <Col span={6}>
                  <Form.Item
                    label={t("ADM.client.bankCodeOS")} //分行代號
                    name="bankCodeOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={t("ADM.client.account")} //銀行帳號
                    name="account"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label={t("ADM.client.username")} //戶名
                    name="username"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={24}>
                  <Divider className="mb-6 p-0" />
                </Col> */}

                {/* <Col span={6}>
                  <Form.Item
                    label={
                      <span className="text-[#9FB2FC]">{t("ADM.client.overseasBankName")}</span>
                    } //海外銀行名稱
                    name="bankOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item
                        label={t("ADM.client.branchOS")} //分行名稱
                        name="branchOS"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t("ADM.client.bankCodeOS")} //分行代號
                        name="bankCodeOS"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={5}>
                  <Form.Item
                    label={t("ADM.client.swiftCode")} //SWIFT CODE
                    name="swiftCode"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={5}>
                  <Form.Item
                    label={t("ADM.client.account")} //銀行帳號
                    name="accountOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={t("ADM.client.username")} //戶名
                    name="usernameOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label={t("ADM.client.bankAddrOS")} //銀行地址
                    name="bankAddrOS"
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addCustHd.status === "load" || call_modifyCustHd.status === "load"}
            >
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
