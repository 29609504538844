import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { addProduct, getCustHd, getSuppHd, modifyProduct } from "@/service/apis/ADM/pnManage";
import { getOpUnit, getUnit } from "@/service/apis/ADM/systemParamsManage";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import WpcSetting from "@/components/BDM/WpcSetting";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({
  custalias = "",
  OMSform,
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  arrMap,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_unit = Form.useWatch("unit", form);
  const f_attribute = Form.useWatch("attribute", form);
  const [s_isShowWpc, set_s_isShowWpc] = useState(false);
  const wpcWrapperRef = useRef(null);

  const call_getMgmtcat74 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);
  const call_getOpUnit = useAPI(getOpUnit);
  const call_getUnit = useAPI(getUnit);
  const call_addProduct = useAPI(addProduct);
  const call_modifyProduct = useAPI(modifyProduct);
  const call_getSuppHd = useAPI(getSuppHd);
  const call_getCustHd = useAPI(getCustHd);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    console.log("values = ", values);

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|") ?? "";
    }
    // if (values.mtype && values.mtype.length > 0) {
    //   values.mtype = values.mtype?.join("|") ?? "";
    // }
    // if (values.querycat && values.querycat.length > 0) {
    //   values.querycat = values.querycat?.join("|") ?? "";
    // }
    // if (values.attribute && values.attribute.length > 0) {
    //   values.attribute = values.attribute?.join("|") ?? "";
    // }
    // if (values.pdtclass && values.pdtclass.length > 0) {
    //   values.pdtclass = values.pdtclass?.join("|") ?? "";
    // }
    // if (values.mtype && values.mtype.length > 0) {
    //   values.mtype = values.mtype?.join("|") ?? "";
    // }
    // if (values.heirdealID && values.heirdealID.length > 0) {
    //   values.heirdealID = values.heirdealID?.join("|") ?? "";
    // }
    if (values.wpcs && values.wpcs.length > 0) {
      values.wpcs.forEach((wpc) =>
        wpc.subWpcs.forEach((subwpc) => (subwpc.pwcID = subwpc.pwcID?.join("|")))
      );
    }

    if (type === "create") {
      call_addProduct.request({
        ...values,
        // 如果是 ERP 那邊建立的料號會帶 custalias
        custalias: custalias,
      });
    }

    if (type === "edit") {
      call_modifyProduct.request(values);
    }
  };

  // 商品類別(pdtclass)選擇 "X" 時塞的固定值
  const onPdtclassChange = (value) => {
    if (value !== "X") return;
    form.setFieldsValue({
      attribute: "0",
      mgmtcat: ["All"], // 授權分類設為"全部"
      querycat: ["All"], // 查詢分類設為"全部"
      unit: "PCS",
      idmtype: "S",
    });
  };

  const handleChange = (key, data) => {
    let formData = form.getFieldValue();

    // 成品或半成品才有tb
    if (key === "attribute") {
      if (["4", "5"].includes(data.value)) {
        set_s_isShowWpc(true);
      } else {
        set_s_isShowWpc(false);
      }
    }

    form.setFieldsValue(formData);
  };

  useEffect(() => {
    // 取得管理分類
    call_getMgmtcat71.request({ pageType: "71", type: "pdt" });
    call_getMgmtcat74.request({ pageType: "74" });
    // 取得操作單位資料
    call_getOpUnit.request();
    // 取得單位資料
    call_getUnit.request();
    call_getSuppHd.request();
    call_getCustHd.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_addProduct;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
      // 有custalias代表是OMS建立的
      if (custalias?.length > 0) {
        OMSform.setFieldsValue({
          ...data,
          // note
          note: OMSform.getFieldValue("note"),
        });
      }
    } else if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_addProduct.status]);

  useEffect(() => {
    const { status, msg } = call_modifyProduct;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_modifyProduct.status]);

  console.log("arrMap.pdtclassMap = ", arrMap.pdtclassMap);
  console.log(s_editData);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        setpack: false,
        setidm: false,
        setbatch: false,
        isfocus: false,
        isvalid: true,
        wpcs: [
          {
            subWpcs: [
              {
                wpcSN: "1",
                nextSN: "T",
                stdwh: 20,
                stdmh: 20,
                stdlt: 3600,
                // 'stdcost': 0,
                planhead: 1,
                pfID: null,
                wsID: null,
                pwcID: [],
                itemno: 0,
                batchbom: 0,
                ieUUID: null,
                // wlID: null,
                warnyield: 90,
                alertyield: 80,
                alertspeed: 90,
                alertstop: 1200,
              },
            ],
          },
        ],
        // attribute: "0",
        pdtclass: "X",
        mgmtcat: ["All"],
        // querycat: ["All"],
        mtype: [],
        // unit: "PCS",
        idmtype: "S",
        attribute: "1",
        ...s_editData,
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="custalias" hidden>
          <Input />
        </Form.Item>
      </div>
      <div></div>
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item label="料号" name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="品名" name="pdtNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="规格" name="pdtspec">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<span>属性</span>} name="attribute">
            <Select
              // disabled
              // allowClear
              onChange={(_, data) => handleChange("attribute", data)}
              // options={arrMap.attributeMap}
              options={[
                { label: "检验项目", value: "0", disabled: true },
                { label: "一般物料", value: "1" },
                // { label: "消耗品", value: "2" },
                // { label: "耐用品", value: "3" },
                // { label: "其他", value: "4" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="品牌" name="mtype">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="查询分类" name="querycat" rules={[{ required: true }]}>
            <Select
              // mode="tags"
              options={[
                { label: "办公用品", value: "1" },
                { label: "试剂耗材", value: "2" },
                { label: "玻璃器皿", value: "3" },
                { label: "设备配件", value: "4" },
                { label: "其他类别", value: "5" },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 計量單位 */}
          <Form.Item label={t("util.util.unit")} name="unit">
            <Select
              allowClear
              options={call_getUnit.data?.reduce((accumulator, option) => {
                option.isvalid &&
                  accumulator.push({
                    label: option.unitNM,
                    value: option.unit,
                  });
                return accumulator;
              }, [])}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 計數單重(g) */}
          <Form.Item label="面價" name="unitwt">
            {/* <Input disabled={f_unit !== "PCS"} /> */}
            <InputNumber
              className="w-full"
              // disabled={f_unit !== "PCS"}
              // addonAfter={"g"}
              // step="0.01"
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="安全库存" name="batchbom">
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        {Object.keys(s_editData).length === 0 && s_isShowWpc && (
          <Col span={24}>
            <Divider className="m-0" />
            <WpcSetting form={form} wpcWrapperRef={wpcWrapperRef} />
          </Col>
        )}
        <Col span={24}>
          <Row>
            <Col span={4}>
              {Object.keys(s_editData).length > 0 && (
                <CreateFromItem
                  set_s_editData={set_s_editData}
                  s_editData={s_editData}
                  form={form}
                />
              )}
            </Col>
            <Col span={20} className="flex justify-end gap-2">
              {/* <Space> */}
              <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={call_addProduct.status === "load" || call_modifyProduct.status === "load"}
              >
                {t("util.ok")}
              </Button>
              {/* </Space> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
