import React from "react";
import { Divider, Image } from "antd";
const Document = (props) => {
  const { c_lightData } = props;

  return (
    <div className="flex flex-col ">
      {/* 申請文件元件 */}
      <div className="h-full flex-col  mb-[10px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
        <div className="flex justify-between mt-[17px] ml-[10px]">
          <h2 className="text-[#6584B4]">申請文件</h2>
          <div>展開按鈕</div>
        </div>
        <Divider className="p-0 m-0" />
        <div className="flex justify-around mt-[11px]">
          <div className="flex flex-col items-center ">
            <Image
              width={80}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
            <h3 className="mt-1 text-sm "> 申請文件.jpg</h3>
          </div>
        </div>
      </div>
      {/* 申請文件元件 */}
    </div>
  );
};

export default Document;
