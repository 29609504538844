import { useState, useEffect, useRef } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Space,
  Button,
  message,
  InputNumber,
  Select,
  Modal,
  Spin,
  Collapse,
} from "antd";
import useAPI from "@/hooks/useAPI";
import {
  getLabel,
  getModelTb,
  print,
  getDaycodeHd,
  getDaycodeTb,
  printWoN,
  reprint,
  getPrinter,
} from "@/service/apis/LMS/LabelManage";
import apiBasic from "@/service/APIBasic";
import axios from "axios";
import dayjs from "dayjs";
import canvasLabel from "../../../util/labelBuild";

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    default:
      return value * 96;
  }
};

const engineOptions = [
  { value: " ", label: "無" },
  { value: "code39", label: "sony code 39" },
];

// 分組函數，用於將一個大陣列分成多個子陣列，每個子陣列的元素數量為 subGroupLength
// buildLabel 函數用於組合條碼的圖片，data 和 modalsData 參數用於提供條碼的相關資料
async function group(array, subGroupLength) {
  const newArray = [];
  for (let i = 0; i < array.length; i += subGroupLength) {
    const boxSN = array.slice(i, i + subGroupLength);
    newArray.push(boxSN);
  }
  return newArray;
}
// 輸入：
// base64Images：一個包含多個 base64 格式的圖像的陣列。
// imgWidth：每個圖像的寬度。
// imgHeight：每個圖像的高度。
// imgGap：每兩個圖像之間的間隙。
// size：縮放因子，用於控制圖像的大小。
// type：(可選)函式的運作模式，預設為 "test"。

// 輸出：
// 一個以 base64 格式編碼的圖像，表示所有輸入圖像組合在一起的結果。

// 函式的運作流程：
// 創建一個 canvas 元素，並計算 canvas 的寬度和高度。
// 取得 canvas 的 2D 繪圖上下文。
// 如果函式的運作模式不是 "test"，則填滿整個 canvas 的背景色。
// 將 canvas 的視窗縮放到所需的大小。
// 對於每個輸入圖像：
// a. 創建一個 Image 元素，並設置其來源為輸入的 base64 格式的圖像。
// b. 等待圖像加載完成，然後在 canvas 上繪製圖像。
// 等待所有圖像都加載完成。
// 將 canvas 轉換為 base64 格式的圖像，並返回結果。
async function combineBase64Images(
  base64Images,
  imgWidth,
  imgHeight,
  imgGap,
  col = 1,
  size,
  type = "test"
) {
  const canvas = document.createElement("canvas");
  const canvasWidth = (imgWidth * col + imgGap * (col - 1)) * size;
  const canvasHeight = imgHeight * size;
  const canvasScaledWidth = canvasWidth;
  const canvasScaledHeight = canvasHeight;
  canvas.width = canvasScaledWidth;
  canvas.height = canvasScaledHeight;
  const ctx = canvas.getContext("2d");
  ctx.width = canvasScaledWidth;
  ctx.height = canvasScaledHeight;

  // 在非测试模式下，条码间距为白条
  if (type !== "test") {
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, ctx.width, ctx.height);
  }

  ctx.scale(size, size); // 缩放因子进行缩放
  const imagePromises = base64Images.map((src, i) => {
    const image = new Image();
    image.src = src;
    return new Promise((resolve, reject) => {
      image.onload = () => {
        const x = (imgWidth + imgGap) * i;
        const y = 0;
        ctx.drawImage(image, x, y, imgWidth, imgHeight);
        resolve();
      };
      image.onerror = reject;
    });
  });
  await Promise.all(imagePromises);
  return canvas.toDataURL("image/png", 1).split(",")[1];
}

// 引用其他 item 固定字串或關鍵字串的內容
// variable = 要帶入的變數
// reg_g: 使用正則表達式 /(\$\{(.+?)\})/g 進行全局匹配，找到引用文字中的 ${}。
// list: 用於存儲找到的 ${} 中的索引值。
// newStr: 用於存儲替換後的新引用文字。
// varStr: 這是一個函數，根據變數是否存在，將其值帶入 ${}，或保留 ${}。
// newStr.replace(...): 逐個替換引用文字中的 ${} 為對應的值。
const quoteRef = (data, item, variable) => {
  // 尋找${} 做成item index
  if (item.type !== "keyText") {
    const reg_g = /\$\{(.+?)}/g;
    let result = null;
    let list = [];

    // 使用正則表達式逐個查找引用文字中的${}並提取索引值
    do {
      result = reg_g.exec(item.quoteText);
      result && list.push(result[1]);
    } while (result);

    let newStr = item.quoteText;

    // 逐個處理引用文字中的${}替換為對應的值
    for (const index of list) {
      let varStr = () => {
        try {
          // 嘗試獲取變數值
          let result = variable[data[index - 1].keyText];

          // 如果變數值不存在，則將其保留為${}
          if (!result) {
            result = "${" + data[index - 1].keyText + "}";
          }
          return result;
        } catch (e) {
          // 出錯時，也將變數保留為${}
          result = "${" + data[index - 1].keyText + "}";
          return result;
        }
      };

      // 替換引用文字中的${}為對應的值
      newStr = newStr.replace(
        "${" + index + "}",
        data[index - 1].type === "text"
          ? data[index - 1].text
          : data[index - 1].type === "keyText"
          ? varStr()
          : ""
      );
    }

    // 返回更新後的引用文字對象
    return { ...item, quoteText: newStr };
  } else {
    // 如果是"keyText"類型，直接將對應變數的值賦值給"keyText"屬性
    return { ...item, keyText: variable[item.keyText] };
  }
};

const { buildLabel } = canvasLabel;
const { conn } = apiBasic;
const { Panel } = Collapse;

export default function Printer({
  set_s_showModal,
  s_editData,
  c_lightData,
  s_recordSN, //紀錄條碼
}) {
  const [form] = Form.useForm();

  const [s_dateHdOptions, set_s_dateHdOptions] = useState([]);
  const [s_dateTbOptions, set_s_dateTbOptions] = useState([]);
  const [s_paperOptions, set_s_paperOptions] = useState([]);
  const [s_wonOptions, set_s_wonOptions] = useState([]);
  const [s_choosePaper, set_s_choosePaper] = useState({});
  const [s_chooseMachines, set_s_chooseMachines] = useState({});
  const [s_spinning, set_s_spinning] = useState(false);
  const [s_spinningTip, set_s_spinningTip] = useState("");
  const call_getModelTb = useAPI(getModelTb);
  const call_getDaycodeHd = useAPI(getDaycodeHd);
  const call_getDaycodeTb = useAPI(getDaycodeTb);
  const call_print = useAPI(print);
  const call_getPrinter = useAPI(getPrinter);
  const call_getLabel = useAPI(getLabel);
  const call_printWoN = useAPI(printWoN);
  const call_reprint = useAPI(reprint);

  const onSubmit = async (values) => {
    const modalsData = call_getModelTb.data?.map((x) => ({ ...JSON.parse(x.variable) }));
    const getMainCode = modalsData.filter((x) => x.groupType === "barcode");

    if (getMainCode.length === 0) {
      message.error("查無圖形條碼");
      return;
    }

    // 進入補印環節
    if (s_recordSN.length > 0) {
      const formData = form.getFieldsValue();
      // 使用record s_recordSN[0] 代表只能用同一張工單進行列印
      const data = {
        SNs: s_recordSN.map((x) => x.SN),
        variable: {
          ...c_lightData,
          ...s_editData,
          ...formData,
          ...s_recordSN[0],
          date: s_recordSN[0].timecode,
        },
      };
      call_reprint.request(s_recordSN.map((x) => ({ ...x, printC: formData.printC })));
      handlePrint(data);
      return;
    }

    // 轉換quoteText
    // 找到第一個有 {SN}
    // 如果都沒有找到 就用地1個
    let parseStr = "";
    for (const mainCodeItem of getMainCode) {
      let mainCode = quoteRef(modalsData, mainCodeItem, {
        ...values,
        ...s_editData,
        ...c_lightData,
      }).quoteText;
      const result = mainCode.match("{SN}");
      if (result) {
        parseStr = result.input;
        break;
      }
    }

    if (parseStr === "") {
      parseStr = quoteRef(modalsData, getMainCode[0], {
        ...values,
        ...s_editData,
        ...c_lightData,
      }).quoteText;
    }

    // 檢查 單綁需要SN
    if (values.bindClass === "U") {
      // 檢查parseStr有沒有${SN}
      const result = parseStr.match("{SN}");
      if (!result) {
        message.error("請檢查模板 ${SN} 不存在");
        return;
      }
    }

    // 防止超出限制長度
    // -5 ${SN}
    // 如果有檢驗碼${keyDigits} 在 -12 + 1 因為檢驗碼為1位 所以總共減11
    let mainBarcodeLen =
      parseStr.length -
      (values.bindClass === "U" ? 5 : 0) -
      (values.engineID === " " ? 0 : 11) +
      (values.bindClass === "U" ? values.digits : 0);

    if (mainBarcodeLen !== s_editData.length) {
      message.error("條碼不等於設定長度，請檢查圖形條碼組成，並且要檢查的條碼位於首位");
      return;
    }
    // 給後端的quoteText
    const endStr = parseStr.replace("${SN}", "%sn").replace("${keyDigits}", "%eg");

    set_s_spinning(true);
    set_s_spinningTip("生成條碼流水號");
    call_print.request({
      ...values,
      timecode: values.date,
      height: unitToPx(s_choosePaper.high, s_choosePaper.highUnit), //存成px
      width: unitToPx(s_choosePaper.width, s_choosePaper.widthUnit), //存成px
      encode: parseInt(values.encode),
      codeUUID: s_editData.codeUUID,
      codeTemplate: endStr,
      count: values.count,
    });
  };

  const handlePrint = async (data) => {
    set_s_spinning(true);
    set_s_spinningTip(`條碼準備中...`);
    const formData = form.getFieldsValue();
    const printCount = formData.printC ?? 1;
    const modalsData = call_getModelTb?.data?.map((x) => JSON.parse(x.variable));

    const realSNs = [];
    const realKeyDigits = [];
    for (let i = 0; i < data.SNs.length; i++) {
      const SN = data.SNs[i];
      const keyDigit = data.checkDigit[i];
      for (let j = 0; j < printCount; j++) {
        realSNs.push(SN);
        realKeyDigits.push(keyDigit);
      }
    }
    // console.log(realSNs, keyDigit);
    // 分組取決於紙張設定的的col
    const imgGroup = await group(realSNs, s_choosePaper.col);
    const keyDigitGroup = await group(realKeyDigits, s_choosePaper.col);
    // 單條碼限制最大寬度
    const imgWidth = unitToPx(data.variable.width, data.variable.unit);
    // 單條碼限制最大高度
    const imgHeight = unitToPx(data.variable.height, data.variable.unit);
    // 多條碼之間隙
    const imgGap = unitToPx(s_choosePaper.gap, s_choosePaper.gapUnit);

    // 批次處理 imgGroup
    const batchSize = 50;
    for (let i = 0; i < imgGroup.length; i += batchSize) {
      const batch = imgGroup.slice(i, i + batchSize);
      const keyDigitBatch = keyDigitGroup.slice(i, i + batchSize);
      set_s_spinningTip(
        `共列印　${imgGroup.length}　張，正在處理第 ${i + 1} - ${i + batch.length} 張條碼`
      );
      // 以每100張為單位進行 base64 編碼
      const rowImgPromises = batch.map(async (x, idx) => {
        const keyDigit = keyDigitBatch[idx];
        const rowImg = await Promise.all(
          x.map((SN, subIdx) =>
            buildLabel({ ...data.variable, SN, keyDigits: keyDigit[subIdx] }, modalsData)
          )
        );
        const type = formData.woN === " " ? "test" : "go";
        return combineBase64Images(
          rowImg,
          imgWidth,
          imgHeight,
          imgGap,
          s_choosePaper.col,
          10,
          type
        );
      });
      const rowImgs = await Promise.all(rowImgPromises);
      try {
        // 團長 http://192.168.0.121:59487/Printer/store
        // 部屬 http://localhost:59487/Printer/store
        const _conn =
          conn === "http"
            ? "http://192.168.0.121:59487/Printer/store"
            : "http://localhost:59487/Printer/store";
        await axios.post(_conn, {
          type: s_chooseMachines.encode, //"img"
          SAT: rowImgs,
          width: imgWidth * s_choosePaper.col + imgGap * (s_choosePaper.col - 1),
          height: imgHeight,
        });
      } catch (error) {
        set_s_spinning(false);
        console.log(`存儲條碼失敗：${error.message}`);
        message.error(`存儲條碼失敗：${error.message}`);
      }
    }

    message.success("條碼成功發送至列印伺服器");
    set_s_spinning(false);
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  useEffect(() => {
    // 找當前 arr中對應的model
    call_getModelTb.request(s_editData);
    // getDate
    call_getDaycodeHd.request();
    // 紙張選擇
    call_getLabel.request();
    // won
    call_printWoN.request(s_editData);
    // 機器
    call_getPrinter.request();
  }, []);

  useEffect(() => {
    if (call_getLabel.status === "suc") {
      set_s_paperOptions(
        call_getLabel.data.map((x) => ({
          label: x.labelNM,
          value: x.labelID,
          data: x,
        }))
      );
    } else if (call_getLabel.status === "err") {
      message.error(call_getLabel.msg);
    }
  }, [call_getLabel.status]);

  useEffect(() => {
    if (call_printWoN.status === "suc") {
      let woNOptions = call_printWoN.data.map((curr) => ({
        label: curr.woN,
        value: curr.woN,
      }));
      woNOptions.unshift({
        label: "測試用-無工單號",
        value: " ",
      });
      set_s_wonOptions(woNOptions);
    } else if (call_printWoN.status === "err") {
      message.error(call_printWoN.msg);
      set_s_wonOptions([
        {
          label: "測試用-無工單號",
          value: " ",
        },
      ]);
    }
  }, [call_printWoN.status]);

  useEffect(() => {
    if (call_getModelTb.status === "err") {
      message.error(call_getModelTb.msg);
    }
  }, [call_getModelTb.status]);

  useEffect(() => {
    if (call_getDaycodeHd.status === "suc") {
      set_s_dateHdOptions(
        call_getDaycodeHd.data.reduce(
          (data, current) => {
            data.push({
              label: current.custNM,
              value: current.custID,
              data: current,
            });
            return data;
          },
          [{ label: "無", value: " ", data: "" }]
        )
      );
    } else if (call_getDaycodeHd.status === "err") {
      message.error(call_getDaycodeHd.msg);
      set_s_dateHdOptions([{ label: "無", value: " ", data: "" }]);
    }
  }, [call_getDaycodeHd.status]);

  useEffect(() => {
    if (call_getDaycodeTb.status === "suc") {
      set_s_dateTbOptions(
        call_getDaycodeTb.data.map((x) => ({
          label: `${dayjs(x.date).format("YYYY-MM-DD")} || ${x.timecode}`,
          value: dayjs(x.date).format("YYYY-MM-DD"),
        }))
      );
    } else if (call_getDaycodeTb.status === "err") {
      message.error(call_getDaycodeTb.msg);
      set_s_dateTbOptions([{ label: "無", value: " " }]);
    }
  }, [call_getDaycodeTb.status]);

  useEffect(() => {
    if (call_print.status === "suc") {
      message.success(call_print.msg);
      const formData = form.getFieldsValue();
      const data = {
        SNs: call_print.data.SN,
        checkDigit: call_print.data.checkDigit,
        variable: { ...c_lightData, ...s_editData, ...formData },
      };
      handlePrint(data);
    } else if (call_print.status === "err") {
      if (call_print.msg === "re") {
        Modal.error({
          title: "重碼錯誤,請確認列印條碼",
          content: call_print.data.map((x) => x.barcode).toString(),
        });
      } else {
        message.error(call_print.msg);
      }
      set_s_spinning(false);
    }
  }, [call_print.status]);

  useEffect(() => {
    if (call_reprint.status === "suc") {
      message.success(call_reprint.msg);
    } else if (call_reprint.status === "err") {
      message.error(call_reprint.msg);
    }
  }, [call_reprint.status]);

  return (
    <Spin spinning={s_spinning} tip={s_spinningTip}>
      <Form
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{
          ...s_editData,
          count: 1,
          printC: 1,
          digits: 4,
          encode: "10",
          bindClass: "U",
          engineID: " ",
          note: "",
          startSN: "0",
          endSN: "0",
        }}
      >
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="modelID" label="模板ID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="ver" label="版本">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="PN" label="ERP料號">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="pdtNM" label="品名">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="machines" label="設備">
              <Select
                onChange={(_, data) => set_s_chooseMachines(data.data)}
                options={call_getPrinter.data.map((x) => ({
                  label: x.printerNM,
                  value: x.printerID,
                  data: x,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item rules={[{ required: true }]} name="paper" label="紙張">
              <Select
                onChange={(_, data) => set_s_choosePaper(data.data)}
                options={s_paperOptions}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Collapse defaultActiveKey={["1", "2"]} className="overflow-y-scroll ">
              <Panel key="1" forceRender header="模板參數設定">
                <Row gutter={[12, 0]}>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="var1" label="自訂關鍵字1">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="var2" label="自訂關鍵字2">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="var3" label="自訂關鍵字3">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="engineID" label="引擎ID">
                      <Select options={engineOptions} disabled />
                    </Form.Item>
                  </Col>
                  {s_recordSN.length === 0 && (
                    <>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="digits" label="流水號位數">
                          <InputNumber min={1} className="w-full" />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="encode" label="流水號進位">
                          <Select
                            options={[
                              { label: "10進位", value: "10" },
                              { label: "16進位", value: "16" },
                              { label: "32進位不含(I,O,U,V)", value: "32" },
                              { label: "36進位", value: "36" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="cust" label="客戶">
                          <Select
                            showSearch
                            onChange={(value, data) => {
                              if (value === " ") {
                                form.setFieldsValue({ date: " " });
                              } else {
                                form.setFieldsValue({ date: undefined });
                              }
                              call_getDaycodeTb.request(data.data);
                            }}
                            options={s_dateHdOptions}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          rules={[{ required: true }]}
                          name="date"
                          label="客戶週期碼"
                          shouldUpdate
                        >
                          <Select placeholder="請先選擇客戶" showSearch options={s_dateTbOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="woN" label="工單號">
                          <Select showSearch options={s_wonOptions} />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item rules={[{ required: true }]} name="bindClass" label="批綁或單綁">
                          <Select
                            onChange={(e) => {
                              e === "B" && form.setFieldsValue({ count: 1 });
                            }}
                            options={[
                              { label: "單綁", value: "U" },
                              { label: "批綁", value: "B" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </Panel>
              <Panel key="2" forceRender header="打印參數設定">
                <Row gutter={[12, 0]}>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="top_gap" label="上間隙">
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="left_gap" label="左間隙">
                      <InputNumber className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item shouldUpdate noStyle>
                      {() => {
                        const formData = form.getFieldsValue();
                        return (
                          <Form.Item rules={[{ required: true }]} name="count" label="流水號產生數">
                            <InputNumber
                              min={1}
                              disabled={formData?.bindClass === "B"}
                              className="w-full"
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="printC" label="連續列印數">
                      <InputNumber min={1} className="w-full" />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item rules={[{ required: true }]} name="startSN" label="手動起始號">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>

          <Col span={24}>
            <Form.Item name="note" label="備註">
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>

          <Col span={24} className="flex justify-end">
            <Space>
              <Button onClick={handleCancel}>取消</Button>
              <Button type="primary" htmlType="submit">
                列印
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
