import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, InputNumber, Select, Divider, Space, message } from "antd";
import { PercentageOutlined } from "@ant-design/icons";
import { getStation, getPF, getPWC } from "@/service/apis/ADM/publicAPI";
import { getWpcIe, addWpcTb, updateWpcTb } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function Edit({ s_editData, set_s_isShowModal, c_lightData }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const f_pfID = Form.useWatch("pfID", form);
  const f_wsID = Form.useWatch("wsID", form);
  const f_pwcID = Form.useWatch("pwcID", form);
  const f_ieUUID = Form.useWatch("ieUUID", form); // 純更新用

  const call_getStation = useAPI(getStation);
  const call_getWpcIe = useAPI(getWpcIe);
  const call_addWpcTb = useAPI(addWpcTb);
  const call_updateWpcTb = useAPI(updateWpcTb);
  const call_getPF = useAPI(getPF);
  const call_getPWC = useAPI(getPWC);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    if (values.pwcID && values.pwcID.length > 0) {
      values.pwcID = values.pwcID?.join("|") ?? "";
    }
    values.wpcSN = `${values.wpcSN}`;

    if (type === "create") {
      call_addWpcTb.request(values);
    }
    if (type === "edit") {
      call_updateWpcTb.request(values);
    }
  };

  useEffect(() => {
    call_getStation.request();
    call_getWpcIe.request();
    call_getPF.request();
    call_getPWC.request();
  }, []);

  useEffect(() => {
    if (call_addWpcTb.status === "suc") {
      message.success(call_addWpcTb.msg);
      set_s_isShowModal(false);
    } else if (call_addWpcTb.status === "err") {
      message.error(call_addWpcTb.msg);
    }
  }, [call_addWpcTb.status]);

  useEffect(() => {
    if (call_updateWpcTb.status === "suc") {
      message.success(call_updateWpcTb.msg);
      set_s_isShowModal(false);
    } else if (call_updateWpcTb.status === "err") {
      message.error(t(`errorCode.${call_updateWpcTb.msg}`));
    }
  }, [call_updateWpcTb.status]);

  const handleChange = (key) => {
    let formData = form.getFieldValue();
    let workClass = ["pwcID", "wsID", "pfID"];
    let i = 0;

    if (workClass.includes(key)) {
      // 將當前階級以下的工作環境都清空
      while (workClass[i] !== key) {
        formData[workClass[i]] = workClass[i] === "pwcID" ? [] : null;
        i++;
      }
    }

    if (["planhead", "stdwh", "stdmh", "stdlt"].includes(key)) {
      formData.ieUUID = null;
    }

    form.setFieldsValue(formData);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        PN: c_lightData.PN,
        wpcset: c_lightData.wpcset,
        wpcsetNM: c_lightData.wpcsetNM,
        isvalid: true,
        warnyield: 90,
        alertyield: 80,
        alertspeed: 90,
        alertstop: 1200,
        stdwh: 20,
        stdmh: 20,
        planhead: 1,
        stdlt: 3600,
        batchbom: 0,
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]} className="bg-[#EDF2F7]">
        <Form.Item name="wpcset" nostyle />
        <Form.Item name="itemno" nostyle />
        <Form.Item name="nextSN" nostyle />
        <Col span={6}>
          <Form.Item label={t("util.PN")} name="PN">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.wpcsetNM")} name="wpcsetNM">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.wpcSN")} name="wpcSN" rules={[{ required: true }]}>
            <InputNumber disabled={Object.keys(s_editData).length > 0} className="w-full" min={1} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.ieUUID")} name="ieUUID">
            <Select
              allowClear
              onChange={(_, data) => {
                form.setFieldsValue({
                  stdwh: data.data.stdwh,
                  stdmh: data.data.stdmh,
                  planhead: data.data.planhead,
                  stdlt: data.data.stdlt,
                });
              }}
              options={call_getWpcIe.data?.map(
                (x) =>
                  ({
                    label: `${x.planhead} / ${x.stdwh} / ${x.stdmh} / ${x.stdlt}`,
                    value: x.ieUUID,
                    data: x,
                  } || [])
              )}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.pfNM")} name="pfID" rules={[{ required: true }]}>
            <Select
              allowClear
              onChange={() => handleChange("pfID")}
              options={
                call_getPF.data?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.ws")} name="wsID" rules={[{ required: true }]}>
            <Select
              allowClear
              disabled={!f_pfID}
              onChange={() => handleChange("wsID")}
              options={call_getStation.data?.reduce((options, curr) => {
                curr.pfID.split("|").includes(f_pfID) &&
                  options.push({
                    label: curr.wsID + "_" + curr.wsNM,
                    value: curr.wsID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.pwcNM")} name="pwcID" rules={[{ required: true }]}>
            <Select
              disabled={!f_wsID}
              allowClear
              mode="multiple"
              onChange={() => handleChange("pwcID")}
              options={call_getPWC.data?.reduce((options, curr) => {
                call_getStation.data.find((item) => item.pwcID.includes(curr.pwcID))?.wsID ===
                  f_wsID &&
                  options.push({
                    label: curr.pwcID + "_" + curr.pwcNM,
                    value: curr.pwcID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="batchbom" label={t("ADM.edm.batchbom")}>
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        {/* <Col span={6}>
          <Form.Item label="輔線機數" name="auxline">
            <Input />
          </Form.Item>
        </Col> */}
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Form.Item name="planhead" label={t("ADM.edm.planhead")} rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} onChange={() => handleChange("planhead")} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="stdwh"
            label={t("ADM.edm.stdwh")}
            rules={[
              {
                validator: (rule, value, callback) => {
                  let formData = form.getFieldValue();
                  if (formData.stdwh + formData.stdmh <= 0)
                    return callback(t("ADM.edm.errorPleaseFull"));

                  callback();
                },
                required: true,
              },
            ]}
          >
            <InputNumber
              className="w-full"
              min={0}
              onChange={() => handleChange("stdwh")}
              addonAfter="s"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="stdmh"
            label={t("ADM.edm.stdmh")}
            rules={[
              {
                validator: (rule, value, callback) => {
                  let formData = form.getFieldValue();
                  if (formData.stdwh + formData.stdmh <= 0)
                    return callback(t("ADM.edm.errorPleaseFull"));

                  callback();
                },
                required: true,
              },
            ]}
          >
            <InputNumber
              className="w-full"
              min={0}
              onChange={() => handleChange("stdmh")}
              addonAfter="s"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="stdlt" label={t("ADM.edm.stdlt")} rules={[{ required: true }]}>
            <InputNumber
              className="w-full"
              min={0}
              onChange={() => handleChange("stdlt")}
              addonAfter="s"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.warnyield")} name="warnyield" rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} addonAfter="%" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.alertyield")} name="alertyield" rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} addonAfter="%" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.alertspeed")} name="alertspeed" rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} addonAfter="%" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.alertstop")} name="alertstop" rules={[{ required: true }]}>
            <InputNumber className="w-full" min={0} addonAfter="s" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider className="m-0 p-0" />
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
