/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import {
  Divider,
  Button,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Form,
  InputNumber,
  message,
  Tooltip,
  DatePicker,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// content
import { ERPContext } from "@/components/Context/SystemContext";
import { CancelButton, QuoteButton } from "@/components/ERP/Button";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import TbTitle from "@/components/ERP/PMS/TbTitle";
// API
import useAPI from "@/hooks/useAPI";
import { getOutRtn } from "@/service/apis/OMS/Return";
const { Text } = Typography;

const TakeOutRtnModal = ({ set_is_showTake, hd_values, call_takeOutRtn }) => {
  const { t } = useTranslation();
  // 廠商資料
  const { c_custs } = useContext(ERPContext);
  const [form] = Form.useForm();
  // useAPI 取得退回資料
  const call_getOutRtn = useAPI(getOutRtn);

  const [s_tableData, set_s_tableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onFinish = (values) => {
    call_getOutRtn.request({
      ...values,
      stockinT: values.stockinT ? dayjs(values.stockinT).format("YYYY-MM-DD") : undefined,
    });
  };

  // 確定選擇
  const onSubmit = () => {
    if (selectedRowKeys.length === 0) {
      //請至少選擇一筆明細
      message.error(t("util.msg_chose_atleast_one_Detail"));
      return;
    }

    // 要被引用的明細
    const takeList = selectedRowKeys.map((rowkey) => {
      return {
        ...s_tableData[rowkey],
        opUUID: hd_values.opUUID,
        linkType: "take",
      };
    });

    // console.log("takeList = ", takeList);
    // return;

    // call 引用的API
    call_takeOutRtn.request(takeList);

    // 關閉跳顯
    set_is_showTake(false);
  };

  // 第一次渲染覆值表單
  useEffect(() => {
    form.setFieldsValue({
      custID: hd_values.custID,
    });
  }, []);

  useEffect(() => {
    const { status, data, msg } = call_getOutRtn;
    if (status === "suc") {
      message.success(msg);
      console.log(data);
      set_s_tableData(data ?? []);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOutRtn.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "3%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: "17%",
    },
    {
      title: t("OMS.check.planqty"), // 移轉總量
      dataIndex: "planqty",
      align: "center",
      width: "7%",
    },
    // {
    //   title: '單位類別',
    //   dataIndex: 'utype',
    //   align: 'center',
    //   width: '8%'
    // },
    {
      title: t("OMS.check.punit"), // 銷貨單位
      dataIndex: "punit",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.outN"), // 驗退單號
      dataIndex: "N",
      align: "center",
      width: "9%",
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("newSelectedRowKeys = ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <Form onFinish={onFinish} name="basic" form={form}>
        <Row gutter={[12, 0]}>
          <Col span={4}>
            {/* 客戶ID */}
            <Form.Item name="custID" label={<Text disabled>{t("OMS.util.custID")}</Text>}>
              <Select
                disabled
                showSearch
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            {/* 驗退單號 */}
            <Form.Item name="outN" label={<Text>{t("OMS.util.outN")}</Text>}>
              <Input placeholder={t("util.placeholder_input") + t("OMS.util.outN")} />
            </Form.Item>
          </Col>

          {/* <Col span={4}>
            <Form.Item name="batchno" label={<Text>批號</Text>}>
              <Input placeholder="請輸入批號" />
            </Form.Item>
          </Col> */}

          <Col span={4}>
            {/* 移轉時間 */}
            <Form.Item name="stockinT" label={<Text>{t("OMS.check.stockinT")}</Text>}>
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={1}>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              {/* 取用 */}
              {t("OMS.util.search")}
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider />

      <CustomTable
        columns={tableColumns}
        rowSelection={rowSelection}
        rowNumber={6}
        dataSource={s_tableData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_is_showTake(false)} />
          <QuoteButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </div>
  );
};

export default TakeOutRtnModal;
