/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { Space, Select, Divider, Row, Col, Button, message, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { CancelButton, TransferButton } from "@/components/ERP/Button"
// API
import useAPI from "@/hooks/useAPI";
import { proToInstock } from "@/service/apis/PMS/Pro";
// util function
import { formatAmount } from "@/util/format";

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(values.qty ?? 0, record.itemUUID);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     // message: `${title} is required.`,
        //   },
        // ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "100%" }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const OrdModal = ({ set_is_showOrd, s_fodTb, hd_values, call_fodToOrd }) => {
  const call_proToInstock = useAPI(proToInstock);
  const { t } = useTranslation();
  // 拋轉介面的資料
  const [s_dataSourec, set_s_dataSourec] = useState(s_fodTb.tb);

  const [s_ori_dataSourec, set_s_ori_dataSourec] = useState(s_fodTb.tb);

  // 要拋轉的單別
  const [s_opcode, set_s_opcode] = useState(undefined);

  // 關閉拋轉介面
  const onCancel = () => {
    set_is_showOrd(false);
  };

  // 確定拋轉
  const onSubmit = async () => {
    if (!s_opcode) {
      //請選擇要拋轉的單別
      message.error(t("util.msg_chose_whichType_toThrow"));
      return;
    }
    // 轉換s_dataSourec中的每個項目，返回新的項目組成的陣列tb
    const tb = s_dataSourec.map((item) => {
      // 解構s_dataSourec中每個項目的屬性
      const { qty = 0, cancelqty = 0, returnqty = 0, unitprice } = item;
      // (廠商確認數量-取消數量)*未稅單價，這邊的廠商確認數量預設使用採購數量
      const itemsum = (qty - cancelqty - returnqty) * unitprice;
      // 解構s_dataSourec中每個項目的balqty屬性，並根據條件設定lifeF屬性
      const { balqty = 0 } = item;
      const lifeF = balqty === 0 || balqty === qty ? "T" : item.lifeF;

      return {
        ...item,
        lifeF,
        qty,
        balqty: qty,
        confirmqty: qty,
        demandqty: qty,
        transferno: 0,
        transferqty: 0,
        itemsum,
        itemsumtax: itemsum * hd_values.taxrate * 0.01,
        subtotal: itemsum + itemsum * hd_values.taxrate * 0.01,
      };
    });

    // 計算單項金額(未稅)
    const itemtotal = tb?.reduce((prev, curr) => prev + (curr.itemsum ?? 0), 0) ?? 0;
    // 計算未稅總金額
    const sumtotal = itemtotal;
    // 計算總稅額
    const tax = sumtotal * hd_values.taxrate * 0.01;
    // 計算總金額
    const total = itemtotal + tax;

    // 組成hd物件，包含轉單相關資訊和總金額相關資訊
    const hd = {
      ...hd_values,
      opcode: s_opcode,
      itemtotal,
      sumtotal,
      tax,
      total,
    };

    // 輸出tb物件，並呼叫API進行轉單
    console.log("要傳給後端的資料 = ", tb);
    call_fodToOrd.request({ hd, tb });
    set_is_showOrd(false);
  };

  // 取得表頭資料，用來建立新【進料單】
  useEffect(() => {
    const tmpdata = s_fodTb.tb.map((item) => {
      return {
        ...item,
        "balqty": 0,
        // 本次拋轉數量預設為剩餘數量
        "qty": item.balqty,
      };
    });
    set_s_dataSourec(tmpdata);
  }, []);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "7%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.fod.confirmqty"), // 確認數量
      onHeaderCell: (_) => {
        return {
          style: {
            backgroundColor: "rgba(208, 211, 230, .44)",
          },
        };
      },
      dataIndex: "confirmqty",
      align: "center",
      width: "12.5%",
      onCell: (rowData) => {
        if (rowData?.lifeF !== "T") {
          return {
            style: {
              backgroundColor: "rgba(208, 211, 230, .44)",
              color: rowData.confirmqty === 0 ? "#b6b1b1" : "#6A68A1",
            },
          };
        }
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.transferqty"), // 已拋轉數量
      onHeaderCell: (_) => {
        return {
          style: {
            backgroundColor: "rgba(208, 211, 230, .44)",
          },
        };
      },
      dataIndex: "transferqty",
      align: "center",
      width: "12.5%",
      onCell: (rowData) => {
        if (rowData?.lifeF !== "T") {
          return {
            style: {
              backgroundColor: "rgba(208, 211, 230, .44)",
              color: rowData.confirmqty === 0 ? "#b6b1b1" : "#6A68A1",
            },
          };
        }
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.balqty"), // 剩餘數量
      onHeaderCell: (column) => {
        return {
          style: {
            backgroundColor: "rgba(208, 211, 230, .44)",
          },
        };
      },
      dataIndex: "balqty",
      align: "center",
      width: "12.5%",
      onCell: (rowData) => {
        if (rowData?.lifeF !== "T") {
          return {
            style: {
              backgroundColor: "rgba(208, 211, 230, .44)",
              color: rowData.confirmqty === 0 ? "#b6b1b1" : rowData.balqty < 0 ? "red" : "#6A68A1",
            },
          };
        }
      },
      render: (text) => formatAmount(text),
    },
    {
      title: <span style={{ color: "#FF850A" }}>{t("OMS.fod.qty")}</span>, // 預計拋轉數量
      dataIndex: "qty",
      align: "center",
      width: "12.5%",
      editable: true,
      onCell: (rowData) => {
        // alert("123");
        if (rowData.lifeF !== "T") {
          return {
            style: {
              color: "#FF850A",
            },
          };
        }
      },
      render: (text) => formatAmount(text),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (qty = 0, itemUUID) => {
    if (parseInt(qty) < 0) {
      //拋轉數量不可小於0
      message.error(t("PMS.instock.message_transfer_small_thsn_0"));
      return;
    }

    const targetItem = s_ori_dataSourec.find((item) => item.itemUUID === itemUUID);
    if (!targetItem) {
      return;
    }

    const updatedItem = {
      ...targetItem,
      editable: true,
      qty: parseInt(qty),
      // 已拋數量
      transferqty: parseInt(targetItem.transferqty) + parseInt(qty),
      balqty:
        parseInt(targetItem.confirmqty) +
        targetItem.returnqty -
        parseInt(targetItem.transferqty) -
        parseInt(qty),
    };

    const updatedData = s_dataSourec.map((item) =>
      item.itemUUID === itemUUID ? updatedItem : item
    );

    set_s_dataSourec(updatedData);
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <Wrapper>
      <Space className="mb-3">
        {/* 拋轉單別 */}
        <span className="fontSize-h4">{t("OMS.util.throw") + t("OMS.util.opcode")}：</span>
        <Select
          value={s_opcode}
          placeholder={t()}
          className="w-[150px]"
          onChange={(value) => set_s_opcode(value)}
          options={[
            { label: t("OMS.util.opcode_1310"), value: "1310" },
            { label: t("OMS.util.opcode_1320"), value: "1320" },
          ]}
        />
      </Space>
      <CustomTable
        components={components}
        columns={columns}
        rowNumber={6}
        rowClassName={(record, _) => {
          if (record.lifeF === "T") {
            return "editable-row bg-zinc-300 opacity-50 cursor-not-allowed";
          }
          return "editable-row";
        }}
        dataSource={s_dataSourec?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={onCancel}/>

          <TransferButton type="primary" onClick={onSubmit}/>

        </Col>
      </Row>
    </Wrapper>
  );
};

export default OrdModal;
