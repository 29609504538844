/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Spin,
  DatePicker,
  Space,
  Typography,
  Select,
  InputNumber,
  Button,
  message,
  Modal,
  Drawer,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import qs from "qs";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import Light from "@/components/ERP/Light";
import ContactButton from "@/components/ERP/OMS/ContactButton";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
// component
import DetailContainer from "./Details/DetailContainer";
import OutstockModal from "./Modal/OutstockModal";
import PrintDrawer from "./Modal/JUPrintDrawer/index";
import HiddenFormItem from "./HiddenFormItem";
import { INIT_DISABLED } from "./initValue";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import {
  getOrdHD,
  getOrdTB,
  getOrdADD,
  getAdd,
  addOrdHD,
  updateOrdHD,
} from "@/service/apis/OMS/Ord";

export const MainPageContext = React.createContext();

const { Text } = Typography;

const MainPage = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getOrdHD = useAPI(getOrdHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getOrdHD = useAPI(getOrdHD);
  // useAPI 取得表身資料
  const call_getOrdTB = useAPI(getOrdTB);
  // useAPI 取得條款註記、加扣款項、預付作業
  const call_getOrdADD = useAPI(getOrdADD);
  // useAPI 取得下拉選單
  const call_getAdd = useAPI(getAdd);
  // 表身在編輯之前用來更新表頭
  const call_updateOrdHd = useAPI(updateOrdHD);
  // 新建表頭資料
  const call_addOrdHD = useAPI(addOrdHD);

  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_custID = Form.useWatch("custID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  const f_currencytype = Form.useWatch("currencytype", form);
  const f_total = Form.useWatch("total", form);
  // 用來紀錄表頭是否修改過，以及修改了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  const { c_userData } = useContext(SystemContext);
  // 客戶資料、人員資料
  const { c_custs, c_staffs, c_currencys, c_taxtypes } = useContext(ERPContext);
  const { mainPage, ord_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 控制列印的 drawer
  const [is_showPrint, set_is_showPrint] = useState(false);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 是否顯示引用的開關
  const [is_showTakeModal, set_is_showTakeModal] = useState(false);
  // 是否顯示拋轉跳顯的開關
  const [is_showOutstock, set_is_showOutstock] = useState(false);
  // 表身資料
  const [s_ordTb, set_s_ordTb] = useState({
    tb: [],
    adda: [], // 條款註記
    addb: [], // 加扣款項
    addc: [], // 預付作業
  });
  // 下拉選單
  const [s_Adds, set_s_Adds] = useState({
    adda: [], // 條款註記下拉選單
    addb: [], // 加扣款項下拉選單
    addc: [], // 預付作業下拉選單
    addd: [], // 付款方式下拉選單
    adde: [], // 貿易條件下拉選單
  });

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (editMode) {
      call_updateOrdHd.request({
        ...values,
        APImethod: "submit",
      });
    } else {
      console.log("values = ", values);
      // return;
      call_addOrdHD.request({
        ...values,
        // 如果是據此創建就要連表身一起給
        tb: Object.keys(s_oriData).length > 0 ? s_ordTb.tb : [],
      });
    }

    set_s_oriData({});
  };

  // 選擇幣別
  const currencytypeChange = (value) => {
    const currency_obj = c_currencys.data?.find((item) => {
      return item.currencyitem === value;
    });

    // 幣別如果是本幣，匯率要設成1
    if (currency_obj?.ismycurrency === true) {
      form.setFieldsValue({
        exchangerate: 1,
      });

      if (s_ordTb.tb.length === 0) return;

      // 表身的本幣金額要重算
      calculate_localprice(1);
    }
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_ordTb.tb.length === 0) return;

    const tb = s_ordTb.tb.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_ordTb({
      ...s_ordTb,
      tb: tb,
    });
  };

  // 選擇稅別
  const taxtypeChange = (value) => {
    const tax_obj = c_taxtypes.data?.find((item) => item.taxtype === value);
    // 只有tax_obj.disabled為false才可以編輯
    set_s_disabled({
      ...s_disabled,
      taxrate: tax_obj.disabled,
    });

    // 將稅別的稅率塞進表單
    form.setFieldsValue({
      taxrate: tax_obj.taxrate,
    });

    // 稅別發生改變時要重新計算表身稅務資訊
    change_tb_tax(tax_obj.taxrate);
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_ordTb.tb.map((item) => {
      // 單項金額 = (客戶確認數量-取消數量)*未稅單價，這邊的客戶確認數量預設使用銷貨數量
      const itemsum = (item.confirmqty - item.cancelqty - item.returnqty) * item.unitprice;
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_ordTb({
      ...s_ordTb,
      tb: tb,
    });
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      // 'createID': storeData?.userData?.staffID,
      // 'driID': storeData?.userData?.staffID,
      opUUID: "",
      preopUUID: "",
      startEDT: dayjs(),
      endEDT: dayjs(),
      exchangeD: dayjs(),
      lifeFT: null,
      createT: null,
      approveT: null,
      signT: null,
      confirmID: "",
      confirmT: null,
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_ordTb,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    change_MainPage_disabled(new_Hd_Data);

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_ordTb({
      ...s_ordTb,
      tb: s_ordTb.tb?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          balqty: 0,
          returnqty: 0,
          cancelqty: 0,
        };
      }),
    });
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      custNM: cust_obj.custNM,
      dealaddr: cust_obj.addr,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有上位資料(報價單)
    const hasQuo = hd_values.quoCount > 0;
    // 是否有上位資料(預訂單)
    const hasFod = hd_values.fodCount > 0;
    // 是否有下位資料(發貨單)
    const hasOutstock = hd_values.outstockCount > 0;
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        dealN: false,
        currencytype: false,
        exchangerate: false,
        exchangeD: false,
        taxtype: false,
        takeQuo: false,
        //------------
        custID: false,
        payment: false,
        trade: false,
        // 報價紀錄查詢
        quoSearch: !hasQuo,
        // 預訂紀錄查詢
        fodSearch: !hasFod,
        //------------
        driID: false,
        startEDT: false,
        endEDT: false,
        //------------
        dealcontact: false,
        dealtel: false,
        orderCheck: false, // 訂購覆核
        //------------
        contact: false,
        tel: false,
        note: false,
        // ------------ 表身 ------------
        // 建立表身的按鈕(據此創建可以基於舊資料再新建新的表身)
        createBtn: isCreateByThis ? false : isNewdoc,
        PN: false,
        unitprice: false,
        demandqty: false,
        tb_note: false,
        tbSubmit: false, // 所有表身跳顯內的確認按鈕
        tb_edit: false, // 所有表身的編輯
        tb_delete: false, // 所有表身的刪除
        // ------------ 條款註記 ------------
        adda_statement: false,
        // ------------ 加扣款項 ------------
        addb_statement: false,
        addb_amount: false,
        // ------------ 預付作業 ------------
        addc_statement: false,
        addc_amount: false,
      });
    }
    // 訂購覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 報價紀錄查詢
        quoSearch: !hasQuo,
        // 預訂紀錄查詢
        fodSearch: !hasFod,
        orderCheck: false, // 銷貨覆核
        custCheck: false, // 客戶簽回
        // ------------ tb ------------
      });
    }
    // 客戶簽回
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 拋轉發貨單
        throwOutstock: hd_values.outstockCount > 0 ? true : false,
        // 報價紀錄查詢
        quoSearch: !hasQuo,
        // 預訂紀錄查詢
        fodSearch: !hasFod,
        // 發貨紀錄查詢
        outstockSearch: !hasOutstock,
        // 客戶簽回 or 取消簽回 => 有下位資料就不可編輯
        custCheck: hasOutstock,
        // 退出處置
        goStock: hd_values.stockCount > 0 ? false : true,
        // 客戶確認數量
        confirmqty: false,
      });
    }
    // 結案
    if (hd_values.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 報價紀錄查詢
        quoSearch: !hasQuo,
        // 預訂紀錄查詢
        fodSearch: !hasFod,
        // 發貨紀錄查詢
        outstockSearch: !hasOutstock,
      });
    }
  };

  // 查詢上位資料
  const go_quo = () => {
    const quo_opcode = {
      1310: "1110",
      1320: "1120",
    };
    const params_obj = {
      opcode: quo_opcode[mainPage.data?.opcode],
      opUUID: mainPage.data?.preopUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/quo?${params}`, "_blank");
  };

  // 查詢上位資料
  const go_fod = () => {
    const params_obj = {
      opcode: "1210",
      opUUID: mainPage.data?.preopUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/fod?${params}`, "_blank");
  };

  // 查詢下位資料
  const go_outStock = () => {
    const outstock_opcode = {
      1310: "1410",
      1320: "1420",
      1330: "1430",
    };

    const params_obj = {
      opcode: outstock_opcode[mainPage.data?.opcode],
      preopUUID: mainPage.data?.opUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/outstock?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("util.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    // call_prev_updateOrdHD.request(form.getFieldsValue());
    call_updateOrdHd.request({
      APImethod: "prev",
      ...form.getFieldsValue(),
    });
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === ord_Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    // call_next_updateOrdHD.request(form.getFieldsValue());
    call_updateOrdHd.request({
      APImethod: "next",
      ...form.getFieldsValue(),
    });
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = ord_Ns.findIndex((item) => item === N);

    return index;
  };

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_ordTb.tb?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總加扣款項
    const addb = s_ordTb.addb?.reduce((prev, curr) => prev + (curr.amount || 0), 0) || 0;
    // 未稅總金額
    const sumtotal = itemtotal + addb;
    // 總稅額
    const tax = (sumtotal * f_taxrate) / 100;
    // 總金額 = 單項金額(未稅) + 總加扣款項 + 總稅額
    const total = itemtotal + addb + tax;

    form.setFieldsValue({
      charge: addb,
      itemtotal,
      sumtotal,
      tax,
      total,
    });
  };

  // 銷貨覆核 or 取消銷貨覆核
  const orderCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "2" ? "取消銷貨覆核確認" : "銷貨覆核確認"}`,
      title: `${
        f_lifeF === "2"
          ? t("util.util.cancel") + t("OMS_ori.ord.lifeF_2") + t("util.util.ok")
          : t("OMS_ori.ord.lifeF_2") + t("util.util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="color-red-500" />,
      content:
        f_lifeF === "2"
          ? t("OMS_ori.ord.cancel_orderCheck_text")
          : t("OMS_ori.ord.orderCheck_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_updateOrdHd.request({
          ...values,
          APImethod: "orderCheck",
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 客戶簽回 or 取消客戶簽回
  const custCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "7" ? "取消客戶簽回確認" : "客戶簽回確認"}`,
      title: `${
        f_lifeF === "7"
          ? t("util.util.cancel") + t("OMS_ori.ord.lifeF_7") + t("util.util.ok")
          : t("OMS_ori.ord.lifeF_7") + t("util.util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content:
        f_lifeF === "7"
          ? t("OMS_ori.ord.cancel_custCheck_confirm_text")
          : t("OMS_ori.ord.custCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_updateOrdHd.request({
          ...values,
          APImethod: "custCheck",
          lifeF: f_lifeF === "7" ? "2" : "7",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 取得下拉選單
    call_getAdd.request();

    // 新建銷貨單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});

      // 設定表單的起始值
      form.setFieldsValue({
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        // 匯率預設1
        lifeF: "0",
        lifeFT: dayjs(),
        exchangerate: 1,
        taxtype: "PPN_11%",
        taxrate: 11,
        trade: "FOB",
        payment: "銀行轉帳",
        createID: c_userData?.staffID,
        driID: c_userData?.staffID,
        startEDT: dayjs(),
        endEDT: dayjs(),
        createT: dayjs(),
        exchangeD: dayjs(),
      });
    } else {
      // 取得表身【銷貨明細】
      call_getOrdTB.request({ opUUID: mainPage.data?.opUUID });
      // 取得【條款註記】、【加扣款項】、【預付作業】
      call_getOrdADD.request({
        "opUUID": mainPage.data?.opUUID,
        "addtype": "addb",
      });

      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  useEffect(() => {
    calculateMoneyInfo();
  }, [MainPage.data, f_exchangerate, f_taxrate, s_ordTb.tb, s_ordTb.addb]);

  // useAPI 取得下拉選單
  useEffect(() => {
    const { status, msg, data } = call_getAdd;
    if (status === "suc") {
      const { adda, addb, addc, addd, adde } = data;
      console.log("data = ", data);
      // format成下拉選單可用的
      const getOptions = (oriData) => {
        return (
          oriData?.map((item) => ({
            label: item.itemNM,
            value: item.itemNM,
          })) ?? []
        );
      };
      set_s_Adds({
        adda: getOptions(adda), // 條款註記下拉選單
        addb: getOptions(addb), // 加扣款項下拉選單
        addc: getOptions(addc), // 預付作業下拉選單
        addd: getOptions(addd), // 付款方式下拉選單
        adde: getOptions(adde), // 貿易條件下拉選單
      });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getAdd.status]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getOrdHD;
    if (status === "load") {
      set_s_spin(true);
    }
    if (status === "suc") {
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      console.log("newData = ", newData);
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
      // 更新mainPage資料
      updateState({ mainPage: { show: true, data: newData } });
      set_s_spin(false);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_spin(false);
    }
  }, [call_getOrdHD.status]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdTB;
    if (status === "suc") {
      const new_tb = data?.map((item, i) => ({ ...item, key: i }));
      set_s_ordTb((prev) => ({
        ...prev,
        tb: new_tb,
      }));
    }

    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_ordTb((prev) => ({
        ...prev,
        tb: [],
      }));
    }
  }, [call_getOrdTB.status]);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { status, msg, data } = call_getOrdADD;
    if (status === "suc") {
      set_s_ordTb((prev) => ({
        ...prev,
        adda: data.adda ?? prev.adda, // 條款註記
        addb: data.addb ?? prev.addb, // 加扣款項
        addc: data.addc ?? prev.addc, // 預付作業
      }));
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getOrdADD.status]);

  // useAPI 新建表頭資料
  useEffect(() => {
    const { status, data, msg } = call_addOrdHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getOrdHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getOrdTB.request({ opUUID: data.opUUID });
      }
      // 清空表頭編輯紀錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_addOrdHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getOrdHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getOrdTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getOrdHD.status]);

  useEffect(() => {
    const { data, status, msg } = call_updateOrdHd;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      set_s_spin(false);
      // 修改表頭
      if (data.APImethod === "submit") {
        message.success(msg);
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        // 清空表頭編輯紀錄
        set_is_editForm({ isEdit: false, editFlag: [] });
        return;
      }

      if (data.APImethod === "orderCheck") {
        message.success(
          data.lifeF === "2"
            ? t("OMS_ori.ord.lifeF_2") + t("util.success")
            : t("OMS_ori.util.cancel") + t("OMS_ori.ord.lifeF_2") + t("util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        return;
      }
      if (data.APImethod === "custCheck") {
        message.success(
          data.lifeF === "7"
            ? t("OMS_ori.ord.lifeF_7") + t("util.util.success")
            : t("util.util.cancel") + t("OMS_ori.ord.lifeF_7") + t("util.util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        return;
      }

      if (data.APImethod === "prev") {
        message.success(t("util.util.get_prev_data_suc"));
        const nowIndex = getIndex(form.getFieldValue("N"));
        // 更新表頭資料(減1是因為要找上一筆資料)
        call_prev_next_getOrdHD.request({ N: ord_Ns[nowIndex - 1] });
      }

      if (data.APImethod === "next") {
        message.success(t("util.util.get_next_data_suc"));
        const nowIndex = getIndex(form.getFieldValue("N"));
        // 更新表頭資料(減1是因為要找上一筆資料)
        call_prev_next_getOrdHD.request({ N: ord_Ns[nowIndex + 1] });
      }
    }

    if (status === "err") {
      set_s_spin(false);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateOrdHd.status]);

  // useAPI 取得表身資料 =>【銷貨明細】、【條款註記】、【加扣款項】、【預付作業】
  // useEffect(() => {
  //   const { status, msg, data } = call_getOrdTB;
  //   console.log("data = ", data);
  //   if (status === "suc") {
  //     set_s_ordTb({
  //       tb: data?.tb ?? [],
  //       adda: data.adda ?? [],
  //       addb: data.addb ?? [],
  //       addc: data.addc ?? [],
  //     });
  //     message.success(msg);
  //   }

  //   if (status === "err") {
  //     set_s_ordTb({
  //       tb: [],
  //       adda: [],
  //       addb: [],
  //       addc: [],
  //     });
  //     message.error(msg);
  //   }
  // }, [call_getOrdTB.status]);

  return (
    <MainPageContext.Provider
      value={{
        call_getOrdHD,
        call_getOrdTB,
        call_updateOrdHd,
        call_getOrdADD,
        is_editForm,
        set_is_editForm,
        s_ordTb,
        set_s_ordTb,
        s_disabled,
        s_Adds,
        hd_values: form.getFieldsValue(),
      }}
    >
      <div className="flex flex-col h-full">
        <Spin spinning={s_spin}>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            validateMessages={{
              required: t("util.util.placeholder_select") + "${label}",
            }}
            onValuesChange={(changedValues, _) => {
              if (editMode) {
                // 在編輯模式時紀錄表頭修改了哪些欄位
                set_is_editForm((prev) => {
                  let editFlag = [...prev.editFlag];
                  const [key] = Object.keys(changedValues);

                  if (!editFlag.includes(key)) {
                    editFlag.push(key);
                  }
                  return {
                    isEdit: true,
                    editFlag,
                  };
                });
              }
            }}
          >
            {/* 表頭部分 */}
            <Row gutter={[12, 0]}>
              <Col span={8}>
                <PageHeader
                  // editMode ? "編輯銷貨主檔" : "新建銷貨主檔"}
                  title={
                    editMode
                      ? t("util.util.edit") + t("OMS_ori.util.ord")
                      : t("util.util.add") + t("OMS_ori.util.ord")
                  }
                />
              </Col>
              <Col span={16} className="flex justify-end">
                {/* 列印 */}
                <PrintButton onClick={() => set_is_showPrint(true)} />
                {/* 儲存 */}
                <SaveButton htmlType="submit" />
                {/* 上一筆 */}
                <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
                {/* 下一筆 */}
                <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
                {Object.keys(s_oriData).length > 0 && (
                  <CancelCreateButton
                    onClick={() => {
                      updateState({ mainPage: { show: true, data: s_oriData.hd } });
                      set_s_ordTb(s_oriData.tb);
                      // 如果有日期格式就轉換成 moment
                      const newData = { ...s_oriData.hd };
                      Object.entries(s_oriData.hd).forEach(([key, value]) => {
                        if (isValidDate(value)) {
                          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                        }
                      });

                      form.setFieldsValue({
                        ...newData,
                      });
                      set_s_oriData({});
                    }}
                  />
                )}
                {/* 回總表 */}
                <SummaryButton
                  onClick={() => {
                    updateState({ mainPage: { show: false, data: {} } });
                  }}
                />
                {/* 回總覽 */}
                <TourButton />
              </Col>
            </Row>
            {/* 隱藏欄位 */}
            <HiddenFormItem />
            {/* 表單開始區域 */}
            <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
              {/* 單別 */}
              <Col xl={editMode ? 2 : 3} xxl={editMode ? 2 : 3}>
                <Form.Item
                  name="opcode"
                  label={<Text disabled={s_disabled.opcode}>{t("util.util.opcode")}</Text>}
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("util.util.opcode") }}
                >
                  <Select
                    placeholder={t("util.util.placeholder_select") + t("util.util.opcode")}
                    disabled={s_disabled.opcode}
                    options={[
                      { label: t("OMS_ori.util.opcode_1310"), value: "1310" },
                      { label: t("OMS_ori.util.opcode_1320"), value: "1320" },
                    ]}
                  />
                </Form.Item>
              </Col>
              {/* 單號 */}
              <Col xl={editMode ? 2 : 3} xxl={editMode ? 3 : 3}>
                <Form.Item
                  name="N"
                  label={
                    <Space>
                      <Text disabled>{t("util.util.N")}</Text>
                      {mainPage.data.preopUUID && <Light linkType="trans" />}
                    </Space>
                  }
                >
                  <Input disabled className="border border-[#4493e3] border-solid" />
                </Form.Item>
              </Col>
              {/* 客戶單號 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="dealN"
                  label={<Text disabled={s_disabled.dealN}>{t("OMS_ori.util.dealN")}</Text>}
                >
                  <Input
                    placeholder={t("util.util.placeholder_input") + t("OMS_ori.util.dealN")}
                    disabled={s_disabled.dealN}
                    className="border border-[#4493e3] border-solid"
                  />
                </Form.Item>
              </Col>
              {/* 幣別 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="flex gap-3">
                <Form.Item
                  label={
                    <Text disabled={s_disabled.currencytype}>{t("util.util.currencytype")}</Text>
                  }
                  name="currencytype"
                  className="flex-1"
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("util.util.currencytype") }}
                >
                  <Select
                    placeholder={t("util.util.placeholder_select") + t("util.util.currencytype")}
                    disabled={s_disabled.currencytype}
                    onChange={currencytypeChange}
                    options={c_currencys.options}
                  />
                </Form.Item>

                <Form.Item dependencies={["currencytype"]} className="flex-1">
                  {() => {
                    // 如果幣別為『本幣』，匯率要disabled，且匯率為1
                    const currencytype = form.getFieldValue("currencytype");
                    const ismycurrency =
                      c_currencys.data?.find((item) => {
                        return item.currencyitem === currencytype;
                      })?.ismycurrency ?? true;

                    return (
                      <Form.Item
                        label={
                          <Text disabled={s_disabled.exchangerate || ismycurrency}>
                            {t("util.util.exchangerate")}
                          </Text>
                        }
                        rules={[{ required: true }]}
                        messageVariables={{ label: t("util.util.exchangerate") }}
                        name="exchangerate"
                        // className="mb-0"
                        disabled={s_disabled.exchangerate || ismycurrency}
                      >
                        <InputNumber
                          // placeholder="請輸入匯率"
                          className="w-full"
                          disabled={s_disabled.exchangerate || ismycurrency}
                          onChange={(value) => calculate_localprice(value)}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>

                <Form.Item
                  className="flex-[2]"
                  label={<Text disabled={s_disabled.exchangeD}>{t("util.util.exchangeD")}</Text>}
                  name="exchangeD"
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    className="w-full"
                    disabled={s_disabled.exchangeD}
                  />
                </Form.Item>
              </Col>
              {/* 稅別、稅率 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="flex gap-3">
                <Form.Item
                  label={<Text disabled={s_disabled.taxtype}>{t("util.util.taxtype")}</Text>}
                  name="taxtype"
                  className="flex-1"
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("util.util.taxrate") }}
                >
                  <Select
                    placeholder={t("util.util.placeholder_input") + t("util.util.taxrate")}
                    disabled={s_disabled.taxtype}
                    onChange={taxtypeChange}
                    options={c_taxtypes.options}
                  />
                </Form.Item>
                {/* 税率 */}
                <Form.Item dependencies={["taxtype"]} className="flex-1">
                  {() => {
                    const taxtype = form.getFieldValue("taxtype");
                    const isDisabled =
                      c_taxtypes.data.find((item) => item.taxtype === taxtype)?.disabled ?? true;

                    return (
                      <Form.Item
                        disabled={s_disabled.taxrate || isDisabled}
                        label={<Text disabled={isDisabled}>{t("util.util.taxrate")}</Text>}
                        rules={[{ required: true }]}
                        messageVariables={{ label: t("util.util.taxrate") }}
                        name="taxrate"
                      >
                        <InputNumber
                          disabled={s_disabled.taxrate || isDisabled}
                          formatter={(value) => `${value}%`}
                          placeholder={t("util.util.placeholder_input") + t("util.util.taxrate")}
                          className="w-full"
                          suffix="%"
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* 引用報價單 */}
                  <Button
                    className="flex-1 h-full oms-btn-darkBlue"
                    disabled // s_disabled.takeQuo
                    onClick={() => set_is_showTakeModal(true)}
                  >
                    {t("util.util.take")}
                    <br />
                    {t("OMS.util.quo")}
                  </Button>
                  {/* 據此創建 */}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    disabled={editMode ? false : true}
                    onClick={createByThis}
                  >
                    {t("util.util.accord")}
                    <br />
                    {t("util.util.create")}
                  </Button>
                  {/* 拋轉發貨單*/}
                  <Button
                    className="flex-1 h-full oms-btn-darkBlue"
                    onClick={() => set_is_showOutstock(true)}
                    // disabled={s_disabled.throwOutstock}
                    disabled
                  >
                    {t("util.util.throw")}
                    <br />
                    {t("OMS_ori.util.outstock")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶ID */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="custID"
                  label={<Text disabled={s_disabled.custID}>{t("util.util.custID")}</Text>}
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("util.util.custID") }}
                >
                  <Select
                    showSearch
                    disabled={s_disabled.custID}
                    placeholder={t("util.util.placeholder_select") + t("util.util.custID")}
                    onChange={onCustChange}
                    options={c_custs.options}
                    filterOption={(input, option) => {
                      return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                    }}
                  />
                </Form.Item>
              </Col>
              {/* 客戶簡稱 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled>{t("util.util.custalias")}</Text>}
                  name="custalias"
                >
                  <Input
                    disabled
                    placeholder={t("util.util.placeholder_select") + t("util.util.custID")}
                  />
                </Form.Item>
              </Col>
              {/* 付款方式 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.payment}>{t("OMS_ori.ord.payment")}</Text>}
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("OMS_ori.ord.payment") }}
                  name="payment"
                >
                  <Select
                    placeholder={t("util.util.placeholder_select") + t("OMS_ori.fod.payment")}
                    disabled={s_disabled.payment}
                    options={s_Adds.addd}
                  />
                </Form.Item>
              </Col>
              {/* 貿易條件 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.trade}>{t("OMS_ori.fod.trade")}</Text>}
                  name="trade"
                >
                  <Select
                    placeholder={t("util.util.placeholder_select") + t("OMS_ori.fod.trade")}
                    disabled={s_disabled.trade}
                    allowClear
                    options={s_Adds.adde}
                  />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {mainPage.data?.fodCount > 0 ? (
                    <Button
                      className="flex-1 h-full pms-btn-blue-outline"
                      onClick={() => go_fod()}
                      disabled={s_disabled.fodSearch}
                    >
                      {/* 預訂紀錄查詢 */}
                      {t("OMS_ori.util.fodSearch")}
                    </Button>
                  ) : (
                    <Button
                      className="flex-1 h-full pms-btn-blue-outline"
                      onClick={() => go_quo()}
                      disabled={s_disabled.quoSearch}
                    >
                      {/* 報價紀錄查詢 */}
                      {t("OMS_ori.util.quoSearch")}
                    </Button>
                  )}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    onClick={() => go_outStock()}
                    disabled={s_disabled.outstockSearch}
                  >
                    {/* 發貨紀錄查詢 */}
                    {t("OMS_ori.util.outstockSearch")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶地址 */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12}>
                <Form.Item
                  label={<Text disabled={s_disabled.dealaddr}>{t("OMS_ori.ord.dealaddr")}</Text>}
                  name="dealaddr"
                >
                  <Input
                    disabled={s_disabled.dealaddr}
                    placeholder={t("util.util.placeholder_input") + t("OMS_ori.ord.dealaddr")}
                  />
                </Form.Item>
              </Col>
              {/* 負責業務 */}
              <Col xl={editMode ? 3 : 4} xxl={editMode ? 4 : 4}>
                <Form.Item
                  label={<Text disabled={s_disabled.driID}>{t("OMS_ori.ord.driID")}</Text>}
                  name="driID"
                >
                  <Select
                    placeholder={t("util.util.placeholder_select") + t("OMS_ori.ord.driID")}
                    disabled={s_disabled.driID}
                    showSearch
                    options={c_staffs.options}
                    filterOption={(input, option) => {
                      return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                    }}
                  />
                </Form.Item>
              </Col>
              {/* 預計交貨日期(起) */}
              <Col xl={editMode ? 4 : 5} xxl={editMode ? 3 : 4}>
                <Form.Item
                  label={<Text disabled={s_disabled.startEDT}>{t("util.util.startEDT")}</Text>}
                  name="startEDT"
                >
                  <DatePicker disabled={s_disabled.startEDT} className="w-full" />
                </Form.Item>
              </Col>
              {/* 預計交貨日期(迄) */}
              <Col xl={editMode ? 4 : 5} xxl={editMode ? 3 : 4}>
                <Form.Item
                  label={<Text disabled={s_disabled.endEDT}>{t("util.util.endEDT")}</Text>}
                  name="endEDT"
                >
                  <DatePicker disabled={s_disabled.endEDT} className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button className="w-full h-full" disabled>
                    {/* 客戶交易歷史查詢 */}
                    {t("OMS_ori.fod.custHistory")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶業務窗口 */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="mt-1">
                <Form.Item shouldUpdate label={<Text>{t("OMS_ori.ord.dealcontact")}</Text>}>
                  {() => (
                    <Row gutter={[12, 0]}>
                      <Col span={12} className="flex items-center">
                        <ContactButton
                          disabled={s_disabled.contact}
                          custID={form.getFieldValue("custID")}
                          chooseContact={(contact_obj) => {
                            form.setFieldsValue({
                              dealcontact: contact_obj?.contact,
                              dealtel: contact_obj?.tel,
                            });
                          }}
                          title={t("util.util.placeholder_select") + t("OMS_ori.ord.dealcontact")}
                        />
                        {/* 窗口 */}
                        <span className="ml-2">{t("util.util.window")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealcontact" className="flex-1 mb-0px" shouldUpdate>
                          <Input
                            disabled={s_disabled.dealcontact}
                            className="flex-1"
                            placeholder={
                              f_custID
                                ? t("util.util.placeholder_input") + t("util.util.tel")
                                : t("util.util.placeholder_select") + t("util.util.custID")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="flex items-center">
                        {/* 電話 */}
                        <span>{t("util.util.tel")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealtel" className="flex-1 mb-0px">
                          <Input
                            disabled={s_disabled.dealtel}
                            placeholder={
                              f_custID
                                ? t("util.util.placeholder_input") + t("util.util.tel")
                                : t("util.util.placeholder_select") + t("util.util.custID")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>

              {/* 核准日期 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
                <Form.Item label={<Text disabled>{t("util.util.approveT")}</Text>} name="approveT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>
              {/* 簽回日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
                <Form.Item label={<Text disabled>{t("util.util.signT")}</Text>} name="signT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "2" ? "pms-btn-orange" : "oms-btn-darkBlue"
                    }`}
                    disabled={
                      // 沒有表身時不可以覆核
                      s_ordTb.tb.length === 0 ? true : s_disabled.orderCheck
                    }
                    onClick={() => orderCheck()}
                  >
                    {/* {f_lifeF === "2" ? "取消覆核" : "銷貨覆核(1)"} */}
                    {f_lifeF === "2"
                      ? t("util.util.cancel") + t("util.util.check")
                      : `${t("OMS_ori.ord.lifeF_2")}(1)`}
                  </Button>
                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "7" ? "pms-btn-red" : "oms-btn-darkBlue"
                    }`}
                    onClick={() => custCheck()}
                    disabled={s_disabled.custCheck}
                  >
                    {/* {f_lifeF === "7" ? "取消簽回" : "客戶簽回(2)"} */}
                    {f_lifeF === "7"
                      ? t("util.util.cancel_Check")
                      : `${t("OMS_ori.ord.lifeF_7")}(2)`}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12}>
                {/* 客戶交貨窗口 */}
                <Form.Item label={<Text>{t("OMS_ori.ord.contact")}</Text>} shouldUpdate>
                  {() => (
                    <Row gutter={[12, 0]}>
                      <Col span={12} className="flex items-center">
                        <ContactButton
                          disabled={s_disabled.contact}
                          custID={form.getFieldValue("custID")}
                          chooseContact={(contact_obj) => {
                            form.setFieldsValue({
                              contact: contact_obj?.contact,
                              tel: contact_obj?.tel,
                            });
                          }}
                          title={t("util.util.placeholder_select") + t("OMS_ori.fod.contact")}
                        />
                        {/* 窗口 */}
                        <span className="ml-2">{t("util.util.window")}:&nbsp;&nbsp;</span>

                        <Form.Item name="contact" className="flex-1" shouldUpdate>
                          <Input
                            disabled={s_disabled.contact}
                            className="flex-1"
                            placeholder={
                              f_custID
                                ? t("util.util.placeholder_input") + t("util.util.tel")
                                : t("util.util.placeholder_select") + t("util.util.custID")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="flex items-center">
                        {/* 電話 */}
                        <span>{t("util.util.tel")}:&nbsp;&nbsp;</span>

                        <Form.Item name="tel" className="flex-1">
                          <Input
                            disabled={s_disabled.tel}
                            placeholder={
                              f_custID
                                ? t("util.util.placeholder_select") + t("util.util.tel")
                                : t("util.util.placeholder_select") + t("util.util.custID")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>
              {/* 覆核人員 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item
                  label={<Text disabled>{t("util.util.confirmID")}</Text>}
                  name="confirmID"
                >
                  <Select disabled options={c_staffs.options} />
                </Form.Item>
              </Col>
              {/* 覆核日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item label={<Text disabled>{t("util.util.confirmT")}</Text>} name="confirmT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button disabled className="flex-1 h-full">
                    {/* 上傳簽呈 */}
                    {t("util.util.UploadSignature")}
                  </Button>
                  <Button disabled className="flex-1 h-full">
                    {/* 上傳回簽 */}
                    {t("util.util.UploadSign")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
              {/* 銷貨註記  */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="pb-2">
                <Form.Item
                  label={<Text disabled={s_disabled.note}>{t("OMS_ori.ord.note")}</Text>}
                  name="note"
                >
                  <Input disabled={s_disabled.note} />
                </Form.Item>
              </Col>

              {/* 創建人員 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="pb-2 bg-gray-100">
                <Form.Item label={<Text disabled>{t("util.util.createID")}</Text>} name="createID">
                  <Select disabled options={c_staffs.options} />
                </Form.Item>
              </Col>

              {/* 創建日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item label={<Text disabled>{t("util.util.createT")}</Text>} name="createT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l flex border-gray-300 border-solid py-2"
                >
                  <Button disabled className="flex-1 h-full">
                    {/* 審核流程(3) */}
                    {t("OMS_ori.ord.reviewFlow")}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
        <DetailContainer />

        {/* 拋轉發貨單-跳顯 */}
        <CustomModal
          width="90%"
          title={t("util.util.throw") + t("OMS_ori.util.outstock")} // 拋轉發貨單
          open={is_showOutstock}
          onCancel={() => {
            set_is_showOutstock(false);
          }}
        >
          {is_showOutstock === true ? (
            <OutstockModal
              hd_values={form.getFieldsValue()}
              set_is_showOutstock={set_is_showOutstock}
              s_ordTb={s_ordTb}
              call_getOrdHD={call_getOrdHD}
              call_getOrdTB={call_getOrdTB}
            />
          ) : null}
        </CustomModal>

        {/* 列印抽屜 */}
        <Drawer
          placement="right"
          closable={false}
          width={820} //優如測試
          onClose={() => set_is_showPrint(false)}
          open={is_showPrint}
        >
          <PrintDrawer hd_values={form.getFieldsValue()} s_ordTb={s_ordTb} />
        </Drawer>
      </div>
    </MainPageContext.Provider>
  );
};

export default MainPage;
