import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//廠別資料
//----------------------------------------- 取得全部廠別資料 ----------------------------------------
export const getCorp = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCorp`, {
    params: data,
  });
};

//----------------------------------------- 新增廠別資料 ----------------------------------------
export const addCorp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addCorp`, data);
};

//----------------------------------------- 編輯廠別資料 ----------------------------------------
export const modifyCorp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyCorp`, data);
};

//----------------------------------------- 刪除廠別資料 ----------------------------------------
export const deleteCorp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteCorp`, data);
};
