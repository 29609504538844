import { ClearButton, CreateButton, TourButton } from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Input, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

// AP
import { default as APHD } from "./AP/HD";
import { default as APMainPage } from "./AP/MainPage";
import { default as APTB } from "./AP/TB";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import PageHeader from "@/components/PageHeader";
// Pay
import { default as RcvHD } from "./Rcv/HD";
// strike
import { default as StrikeHD } from "./Strike/HD";
import { default as StrikeMainPage } from "./Strike/MainPage";
import { default as StrikeTB } from "./Strike/TB";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// 一階搜尋條件的起始值
const INIT_SEARCHCONFIG = {
  opcode: "rcv",
  // startDate: dayjs().startOf("month"), // 開始日期
  // endDate: dayjs().endOf("month"), // 結束日期
  startDate: dayjs().startOf("year"), // 開始日期
  endDate: dayjs().endOf("month"), // 結束日期
  searchFlag: undefined, // 要搜尋的欄位名稱
  searchValue: undefined, // 要搜尋的欄位資料
};

export const DataContext = React.createContext();

const ApManage = () => {
  const { t } = useTranslation();
  const pay_ref = useRef(null);
  const ap_ref = useRef(null);
  const strike_ref = useRef(null);
  const { c_custs, c_staffs } = useContext(ERPContext);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();
  const [c_state, set_c_state] = useState({
    lightData: {}, // 要被查看的『表頭』資料
    mainPage: { show: false, data: {} }, // 是否要顯示新建、修改『採購主檔』畫面的頁面
    tabKey: "rcv", // CustomTabs 目前被選中的選項
    searchConfig: INIT_SEARCHCONFIG, // 要搜尋的資料(一階搜尋用)
    // detailSearch: { show: false, data: {} },
    Ns: [], // 用來做上一筆、下一筆的單號陣列
    INIT_SEARCHCONFIG: INIT_SEARCHCONFIG,
  });

  const placeholder_map = {
    N: t("util.placeholder_input") + t("OMS.util.N"), // 請輸入單號
    custID: t("util.placeholder_select") + t("OMS.util.custID"), // 請選擇客戶
    lifeF: t("util.placeholder_select") + t("OMS.util.lifeF"), // 請選擇歷程
  };

  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "rcv", label: t("OMS.ap-manage.rcv") }, // 應收帳款查詢
    { key: "ap", label: t("OMS.ap-manage.ap") }, // 對帳請款
    { key: "strike", label: t("OMS.ap-manage.strike") }, // 收款冲帐
  ];

  const searchFlag_map = {
    rcv: [
      // { value: "N14", label: t("OMS.util.N14") }, // 發貨單號
      { value: "custID", label: t("OMS.util.custNM") }, // 客戶名稱
      { value: "N13", label: t("OMS.util.N13") }, // 銷貨單號
      { value: "dealN", label: t("OMS.util.dealN") }, // 客戶單號
      { value: "driID", label: t("OMS.ord.driID") }, // 負責業務
      // { value: "N12", label: t("OMS.util.N12") }, // 預訂單號
      // { value: "N11", label: t("OMS.util.N11") }, // 報價單號
    ],
    ap: [
      { value: "N", label: t("OMS.util.N") }, // 單號
      { value: "custID", label: t("OMS.util.custID") }, // 客戶
      { value: "lifeF", label: t("OMS.util.lifeF") }, // 歷程
    ],
    strike: [
      { value: "N", label: t("OMS.util.N") }, // 單號
      { value: "custID", label: t("OMS.util.custID") }, // 客戶
      { value: "lifeF", label: t("OMS.util.lifeF") }, // 歷程
    ],
  };
  console.log("c_staffs = ", c_staffs);
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    custID: c_custs.options,
    driID: [],
    lifeF: [
      { value: "0", label: "發佈" },
      { value: "2", label: "進料覆核" },
      { value: "3", label: "IQC抽驗" },
      { value: "4", label: "IQC完驗" },
      { value: "7", label: "品保覆核" },
      { value: "T", label: "結案" },
    ],
  });

  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      driID: c_staffs.options,
    }));
  }, [c_staffs]);

  // 用來更新 state
  const updateState = (partialState) => {
    set_c_state((prev) => ({ ...prev, ...partialState }));
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    updateState({
      tabKey: activeKey,
      searchConfig: INIT_SEARCHCONFIG,
      lightData: {},
    });
    clearSearch();
  };

  const handleChange = (type, value) => {
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...c_state.searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate:
        type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : c_state.searchConfig?.startDate,
      endDate:
        type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : c_state.searchConfig?.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue"
          ? value
          : type === "searchFlag"
          ? null
          : c_state.searchConfig?.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...c_state.searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : c_state.searchConfig?.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      // call_getInstockHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    // call_getInstockHD.request({
    //   ...searchConfig,
    //   opcode: tabKey,
    //   [searchConfig.searchFlag]: searchConfig.searchValue,
    // });
    console.log("c_state.tabKey = ", c_state.tabKey);
    if (c_state.tabKey === "rcv") {
      pay_ref.current.Call_hd();
    }
    if (c_state.tabKey === "ap") {
      ap_ref.current.Call_hd();
    }
    if (c_state.tabKey === "strike") {
      strike_ref.current.Call_hd();
    }
    // 更新狀態
    updateState({ c_lightData: {} });
  };

  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...c_state.searchConfig,
      // opcode: c_state.tabKey,
      // startDate: dayjs().startOf("month"), // 開始日期
      // endDate: dayjs().endOf("month"), // 結束日期
      startDate: dayjs().startOf("year"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    // call_getInstockHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("tabkey");
    searchParams.delete("N");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const N = searchParams.get("N");
    const tabkey = searchParams.get("tabkey");

    if (N !== null) {
      updateState({
        tabKey: tabkey,
      });
    }
    // c_staffs.options
  }, []);

  useEffect(() => {
    if (c_state.tabKey === "ap") {
      set_s_selectOptions({
        ...s_selectOptions,
        suppID: c_custs.options,
        lifeF: [
          { value: "0", label: t("OMS.ap-manage.AP_lifeF_0") },
          { value: "1", label: t("OMS.ap-manage.AP_lifeF_1") },
          { value: "2", label: t("OMS.ap-manage.releaseAP") },
          { value: "3", label: t("OMS.ap-manage.AP_lifeF_3") },
          { value: "T", label: t("OMS.ap-manage.AP_lifeF_T") },
        ],
      });
    }
    if (c_state.tabKey === "strike") {
      set_s_selectOptions({
        ...s_selectOptions,
        suppID: c_custs.options,
        lifeF: [
          { value: "0", label: t("OMS.ap-manage.strike_lifeF_0") },
          { value: "1", label: t("OMS.ap-manage.strike_lifeF_1") },

          { value: "T", label: t("OMS.ap-manage.strike_lifeF_T") },
        ],
      });
    }
  }, [c_state.tabKey, c_custs]);

  return (
    <DataContext.Provider value={{ c_state, updateState }}>
      <div className="flex flex-col h-full">
        {c_state.mainPage.show && c_state.tabKey === "ap" && <APMainPage />}
        {c_state.mainPage.show && c_state.tabKey === "strike" && <StrikeMainPage />}

        {!c_state.mainPage.show && (
          <div className="flex-1 flex flex-col">
            <PageHeader
              title={t("OMS.ap-manage.pageHeader")} // 應收帳款管理
              // extra={<TourButton />}
            />
            <CustomTabs
              items={tabsData} // CustomTabs 的所有選項
              activeKey={c_state.tabKey} // 目前亮起來的 Tab
              onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
              tabBarExtraContent={
                <Space>
                  <CustomRangePicker
                    // disabled={c_state.tabKey === "rcv"}
                    value={[c_state.searchConfig?.startDate, c_state.searchConfig?.endDate]}
                    onCalendarChange={(dates, _, __) => {
                      updateState({
                        searchConfig: {
                          ...c_state.searchConfig,
                          startDate: dates[0],
                          endDate: dates[1],
                        },
                      });
                    }}
                  />

                  <Input.Group compact>
                    <CustomSelect
                      // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "rcv"}
                      disabled={c_state.tabKey === "detailSearch"}
                      value={c_state.searchConfig?.searchFlag}
                      placeholder={t("util.search")}
                      onChange={(value) => handleChange("searchFlag", value)}
                      options={searchFlag_map[c_state.tabKey]}
                    />

                    {c_state.searchConfig?.searchFlag === "lifeF" ||
                    c_state.searchConfig?.searchFlag === "custID" ||
                    c_state.searchConfig?.searchFlag === "driID" ? (
                      <CustomSelect
                        showSearch
                        // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "rcv"}
                        disabled={c_state.tabKey === "detailSearch"}
                        value={c_state.searchConfig?.searchValue}
                        className="w-[300px]"
                        placeholder={placeholder_map[c_state.searchConfig?.searchFlag]}
                        onChange={(value) => handleChange("searchValue", value)}
                        // 根據searchFlag判斷現在是哪個下拉選單
                        options={s_selectOptions[c_state.searchConfig?.searchFlag]}
                        filterOption={(input, option) => {
                          return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                        }}
                      />
                    ) : (
                      <Input
                        // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "rcv"}
                        disabled={c_state.tabKey === "detailSearch"}
                        value={c_state.searchConfig?.searchValue}
                        className="w-[250px]"
                        placeholder={placeholder_map[c_state.searchConfig?.searchFlag]}
                        onChange={(e) => handleChangeInput(e.target.value)}
                      />
                    )}
                    <CustomSearchButton
                      disabled={c_state.tabKey === "detailSearch"}
                      onClick={handleSearch}
                    />
                  </Input.Group>

                  {/* 清除搜詢紀錄 */}
                  <ClearButton
                    // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "rcv"}
                    disabled={c_state.tabKey === "detailSearch"}
                    onClick={clearSearch}
                  />
                  {/* 新建 */}
                  <CreateButton
                    // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "rcv"}
                    disabled={c_state.tabKey === "rcv"}
                    onClick={() => updateState({ mainPage: { show: true, data: {} } })}
                  />
                </Space>
              }
            />
            {c_state.tabKey === "rcv" && <RcvHD ref={pay_ref} />}
            {c_state.tabKey === "ap" && <APHD ref={ap_ref} />}
            {c_state.tabKey === "strike" && <StrikeHD ref={strike_ref} />}

            {Object.keys(c_state.lightData).length > 0 && (
              <>
                {c_state.tabKey === "ap" && <APTB />}
                {c_state.tabKey === "strike" && <StrikeTB />}
              </>
            )}
          </div>
        )}
      </div>
    </DataContext.Provider>
  );
};

export default ApManage;
