import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { Button, Input } from "antd";
import { useRef, useState } from "react";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader";
import WareHouse from "./WareHouse";
import { useTranslation } from "react-i18next";

export default function WarehouseManage() {
  const wareHouse_ref = useRef(null);
  const { t } = useTranslation();
  const [s_searchData, set_s_searchData] = useState({
    query: null,
    page: 1,
    pageSize: 100,
  });
  const [s_tabKey, set_s_tabKey] = useState("wareHouse"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      query: undefined,
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "wareHouse" && wareHouse_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "wareHouse" && wareHouse_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "wareHouse",
      label: t("ADM.warehouse-manage.tabWareHouse"), //"倉別資料設定",
      children: (
        <WareHouse
          ref={wareHouse_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "test",
      label: "储位设定",
      disabled: true,
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("query", e.target.value)}
      placeholder={t("util.search")}
      className="w-[300px]"
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   查詢
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   新增
    // </Button>,

    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.warehouse-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
