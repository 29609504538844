/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Space,
  Typography,
  Select,
  Modal,
  Button,
  message,
  Grid,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "qs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import PageHeader from "@/components/PageHeader";
import Light from "@/components/ERP/Light";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
// component
import TbContainer from "./Details/TbContainer";
import TakeOrdModal from "./Modal/TakeOrdModal";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import {
  getOutstockHD,
  getOutstockTB,
  addOutstockHD,
  updateOutstockHD,
  leaveStock,
} from "@/service/apis/OMS/Outstock";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  dealN: true,
  takeOrd: true, // 引用銷貨單
  throwStock: true, // 拋轉出貨單
  custID: true,
  ordSearch: true, //銷貨紀錄查詢
  stockSearch: true, // 驗收紀錄查詢
  outstockCheck: true, // 發貨覆核
  IQCstart: true, // 進檢抽樣
  note: true,
  IQCfinish: true, // IQC完驗
  QAcheck: true, // 品保放行
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  tb_submit: true, // 表身確定按鈕
  tb_delete: true, // 表身刪除按鈕
  utype: true,
  unitprice: true,
  indqty: true,
  inbatchno: true,
  goqty: true,
  rejectqty: true,
  judgeT: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getOutstockHD = useAPI(getOutstockHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getOutstockHD = useAPI(getOutstockHD);
  // useAPI 取得表身資料
  const call_getOutstockTB = useAPI(getOutstockTB);
  // useAPI 新增表頭
  const call_finish_addOutstockHD = useAPI(addOutstockHD);
  // useAPI 儲存表頭
  const call_finish_updateOutstockHD = useAPI(updateOutstockHD);
  // useAPI 發貨覆核
  const call_check = useAPI(updateOutstockHD);
  // useAPI 進檢抽樣
  const call_IQC = useAPI(updateOutstockHD);
  // useAPI IQC完驗
  const call_IQCFinish = useAPI(updateOutstockHD);
  // useAPI 品保放行
  const call_QAcheck_updateOutstockHD = useAPI(updateOutstockHD);
  // useAPI 取得上一筆資料
  const call_prev_updateOutstockHD = useAPI(updateOutstockHD);
  // useAPI 取得下一筆資料
  const call_next_updateOutstockHD = useAPI(updateOutstockHD);
  // 拋轉銷貨檢驗單
  const call_leaveStock = useAPI(leaveStock);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const { c_state, updateState } = useContext(DataContext);
  // 客戶資料、人員資料
  const { c_userData } = useContext(SystemContext);
  const { c_custs, c_staffs } = useContext(ERPContext);
  const { mainPage, outstock_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 引用銷貨單的跳顯開關
  const [is_showOrd, set_is_showOrd] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      // call_finish_addOutstockHD.request({ ...values, lifeFT: dayjs().format("YYYY-MM-DD") });
      call_finish_addOutstockHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateOutstockHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      createID: "",
      createT: null,
      opUUID: "",
      preopUUID: "",
      inspectID: "",
      inspectT: null,
      qalID: "",
      qalT: null,
      sampleID: "",
      sampleT: null,
      endT: null,
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    change_MainPage_disabled(new_Hd_Data);

    // 清空表身資料，清空時 useEffect 會自動計算金額
    const new_tbData = s_tbData.map((item) => {
      return {
        ...item,
        opUUID: "",
        itemUUID: "",
        rejectqty: 0,
        goqty: 0,
        judgeT: null,
      };
    });

    set_s_tbData(new_tbData);
  };

  // 選擇客戶
  const onCustChange = (value) => {
    console.log("c_custs.data = ", c_custs.data);
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    console.log("cust_obj.custID = ", cust_obj.custID);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      custNM: cust_obj.custNM,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 銷貨紀錄查詢
    const ordSearch = hd_values.preopUUID?.length > 0 ? false : true;
    // 驗收紀錄查詢
    // const stockSearch = hd_values.count > 0 ? false : true;
    // console.log("出貨單lifeF = ", hd_values);
    // console.log("isNewdoc = ", isNewdoc);
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        dealN: false,
        custID: !isNewdoc,
        note: false,
        // 引用銷貨單
        takeOrd: hd_values?.linkType === "trans" ? true : false,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        outstockCheck: false,
        // ------- 表身 -------
        // 有上位資料、就不可以手動建立表身
        createBtn: hd_values.preopUUID?.length > 0 ? true : isCreateByThis ? false : isNewdoc,
        tb_delete: false,
        tb_submit: false,
        utype: false,
        unitprice: false,
        indqty: false,
        inbatchno: false,
        tb_note: false,
      });
    }

    // 發貨覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        outstockCheck: false, // 發貨覆核
        IQCstart: false, // ICQ抽驗
      });
    }

    // ICQ抽驗
    if (hd_values?.lifeF === "3") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        IQCstart: false, // ICQ抽驗
        IQCfinish: false, // IQC完驗
      });
    }

    // ICQ完驗
    if (hd_values?.lifeF === "4") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        IQCfinish: false, // IQC完驗
        QAcheck: false, // 品保放行
        //----------------------------
        goqty: false, // 驗收數量
        rejectqty: false, // 驗退數量
        judgeT: false, // 品保判定日期
        tb_submit: false,
        tb_note: false,
      });
    }

    // 品保放行
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        QAcheck: false, // 品保放行
        throwStock: false, // 拋轉出貨檢驗單
      });
    }

    // 結案
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: ordSearch,
        stockSearch: false, // 驗收紀錄查詢
      });
    }
  };

  // 發貨覆核 or 取消發貨覆
  const outstockCheck = () => {
    Modal.confirm({
      // title: `${f_lifeF === "2" ? "取消發貨覆核確認" : "發貨覆核確認"}`,
      title:
        f_lifeF === "2"
          ? t("util.cancel") + t("OMS.outstock.lifeF_2") + t("util.ok")
          : t("OMS.outstock.lifeF_2") + t("util.ok"),
      icon: <ExclamationCircleOutlined />,
      content:
        f_lifeF === "2"
          ? t("OMS.outstock.cancel_outstockCheck_text")
          : t("OMS.outstock.outstockCheck_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_check.request({
          ...form.getFieldsValue(),
          // 0: 發佈 2: 發貨覆核
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 進檢抽樣 or 取消進檢抽樣
  const IQCcheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "2" ? "進檢抽樣確認" : "取消進檢抽樣"}`,
      title:
        f_lifeF === "2"
          ? t("OMS.outstock.IQCcheck") + t("util.ok")
          : t("util.cancel") + t("OMS.outstock.IQCcheck"),
      icon: <ExclamationCircleOutlined />,
      content: t("OMS.outstock.IQCcheck_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_IQC.request({
          ...values,
          // 0: 發佈 2: 發貨覆核
          lifeF: f_lifeF === "2" ? "3" : "2",
          lifeFT: dayjs(),
          "sampleID": c_userData?.staffID,
          sampleT: dayjs(),
        });
      },
    });
  };

  // IQC完驗 or 取消IQC完驗
  const IQCfinish = () => {
    const values = form.getFieldsValue();
    Modal.confirm({
      // title: `${f_lifeF === "4" ? "取消IQC完驗確認" : "IQC完驗"}`,
      title: `${
        f_lifeF === "4"
          ? t("util.cancel") + t("OMS.outstock.lifeF_4") + t("util.ok")
          : t("OMS.outstock.lifeF_4") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined />,
      content: t("OMS.outstock.IQCfinish_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_IQCFinish.request({
          ...values,
          // 0: 發佈 2: 發貨覆核
          lifeF: f_lifeF === "3" ? "4" : "3",
          lifeFT: dayjs(),
          inspectID: c_userData?.staffID,
          inspectT: dayjs(),
        });
      },
    });
  };

  // 品保放行 or 取消品保放行
  const QAcheck = () => {
    let is_break = false;

    s_tbData.forEach((item) => {
      // [品保判退數量]+[品保放行數量] 要等於 [發貨數量]
      if (item.rejectqty + item.goqty !== item.indqty) {
        is_break = true;
      }
    });

    if (is_break) {
      // message.error("發貨明細 [品保判退數量]+[品保放行數量] 要等於 [發貨數量]");
      message.error(t("OMS.outstock.QAcheck_err"));
      return;
    }

    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "7" ? "取消品保放行確認" : "品保放行"}`,
      title:
        f_lifeF === "7"
          ? t("util.cancel") + t("OMS.outstock.QAcheck") + t("util.ok")
          : t("OMS.outstock.QAcheck") + t("util.ok"),
      icon: <ExclamationCircleOutlined />,
      content: t("OMS.outstock.QAcheck_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_QAcheck_updateOutstockHD.request({
          ...values,
          // 0: 發佈 2: 發貨覆核
          lifeF: f_lifeF === "7" ? "4" : "7",
          lifeFT: dayjs(),
          "qalID": c_userData?.staffID,
          qalT: dayjs(),
        });
      },
    });
  };

  // 拋轉發貨單
  const throwStock = () => {
    Modal.confirm({
      title: t("OMS.util.sysMsg"), // 系統提示
      icon: <ExclamationCircleOutlined />,
      content: t("OMS.outstock.throwStock_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_leaveStock.request({
          hd: mainPage.data,
          tb: s_tbData,
        });
      },
    });
  };

  // 查詢上位資料
  const go_ord = () => {
    const ord_opcode = {
      1410: "1310",
      1420: "1320",
      1430: "1330",
    };

    const params_obj = {
      opcode: ord_opcode[mainPage.data.opcode],
      opUUID: mainPage.data.preopUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/ord?${params}`, "_blank");
  };

  // 查詢下位資料
  const go_stock = () => {
    // return;
    const params_obj = {
      TabKey: "detailSearch",
      opcode: "4220|4130",
      jobN: mainPage.data.N,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/check?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateOutstockHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === outstock_Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateOutstockHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = outstock_Ns.findIndex((item) => item === N);

    return index;
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建發貨單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        lifeFT: dayjs(),
        opcode: "1410",
        // 預設登入人員
        createID: c_userData?.staffID,
        createT: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getOutstockTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getOutstockHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getOutstockHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getOutstockTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getOutstockTB.status]);

  // 新增發貨單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addOutstockHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getOutstockHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getOutstockTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addOutstockHD.status]);

  // 編輯發貨單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateOutstockHD;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getOutstockHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getOutstockTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateOutstockHD.status]);

  // useAPI 發貨覆核 or 取消覆核
  useEffect(() => {
    const { data, status, msg } = call_check;
    if (status === "suc") {
      // const msg = data.lifeF === "2" ? "[發貨覆核]成功" : "[取消覆核]成功";
      const msg =
        data.lifeF === "2"
          ? `[${t("OMS.outstock.lifeF_2")}]${t("util.success")}`
          : `[${t("util.cancel") + t("util.check")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getOutstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_check.status]);

  // 進檢抽樣 or 取消進檢抽樣
  useEffect(() => {
    const { data, status, msg } = call_IQC;
    if (status === "suc") {
      // const msg = data.lifeF === "3" ? "[IQC完驗]成功" : "[取消IQC完驗]成功";
      const msg =
        data.lifeF === "3"
          ? `[${t("OMS.outstock.lifeF_3")}]${t("util.success")}`
          : `[${t("util.cancel") + t("OMS.outstock.lifeF_3")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getOutstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_IQC.status]);

  // useAPI IQC完驗 or 取消IQC完驗
  useEffect(() => {
    const { data, status, msg } = call_IQCFinish;
    if (status === "suc") {
      // const msg = data.lifeF === "3" ? "[取消IQC完驗]成功" : "[IQC完驗]成功";
      const msg =
        data.lifeF === "3"
          ? `${t("util.cancel")}[${t("OMS.outstock.lifeF_4")}]${t("util.success")}`
          : `[${t("OMS.outstock.lifeF_4")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getOutstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_IQCFinish.status]);

  // useAPI 品保放行 or 取消品保放行
  useEffect(() => {
    const { data, status, msg } = call_QAcheck_updateOutstockHD;
    if (status === "suc") {
      // const msg = data.lifeF === "4" ? "[取消品保放行]成功" : "[品保放行]成功";
      const msg =
        data.lifeF === "4"
          ? `${t("util.cancel")}[${t("OMS.outstock.lifeF_7")}]${t("util.success")}`
          : `[${t("OMS.outstock.lifeF_7")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getOutstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_QAcheck_updateOutstockHD.status]);

  // useAPI 拋轉出貨單
  useEffect(() => {
    const { msg, status } = call_leaveStock;
    if (status === "suc") {
      message.success(msg);
      call_getOutstockHD.request({ N: mainPage.data.N });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_leaveStock.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateOutstockHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getOutstockHD.request({ N: outstock_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateOutstockHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateOutstockHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getOutstockHD.request({ N: outstock_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateOutstockHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getOutstockHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getOutstockTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getOutstockHD.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯發貨主檔" : "新建發貨主檔"} /> */}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("OMS.util.outstock")
                  : t("util.add") + t("OMS.util.outstock")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="linkType" hidden>
            <Input />
          </Form.Item>
        </div>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別、單號 */}
          <Col span={editMode ? 5 : 6} className="flex gap-3">
            <Form.Item
              name="opcode"
              className="flex-1"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("OMS.util.opcode_1410"), value: "1410" }, // 國內訂單
                  { label: t("OMS.util.opcode_1420"), value: "1420" }, // 國外訂單
                ]}
              />
            </Form.Item>
            <Form.Item
              name="N"
              className="flex-1"
              label={
                <Space>
                  <Text disabled>{t("OMS.util.N")}</Text>
                  {mainPage.data.preopUUID && <Light linkType={mainPage.data.linkType} />}
                </Space>
              }
            >
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 客戶單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.dealN}>{t("OMS.util.dealN")}</Text>}
              name="dealN"
            >
              <Input disabled={s_disabled.dealN} />
            </Form.Item>
          </Col>
          {/* 建檔人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createID")}</Text>} name="createID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 引用銷貨單 */}
              <Button
                className="flex-1 h-full oms-btn-darkBlue"
                disabled={s_disabled.takeOrd}
                onClick={() => set_is_showOrd(true)}
              >
                {t("OMS.util.take")}
                <br />
                {t("OMS_ori.util.ord")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("OMS.util.accord")}
                <br />
                {t("OMS.util.create")}
              </Button>
              {/* 拋轉出貨單 */}
              <Button
                className="flex-1 h-full oms-btn-darkBlue"
                onClick={throwStock}
                disabled={s_disabled.throwStock}
              >
                {t("OMS.util.throw")}
                <br />
                {t("OMS.util.outstock")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="custID"
              label={<Text disabled={s_disabled.custID}>{t("OMS.util.custID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.custID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.custID}
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                onChange={onCustChange}
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          {/* 客戶簡稱 */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.custalias")}</Text>} name="custalias">
              <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.custID")} />
            </Form.Item>
          </Col>
          {/* 抽驗人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.outstock.sampleID")}</Text>} name="sampleID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 抽驗時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.outstock.sampleT")}</Text>} name="sampleT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1 pms-btn-blue-outline"
                onClick={() => go_ord()}
                disabled={s_disabled.ordSearch}
              >
                {/* 銷貨記錄查詢 */}
                {t("OMS.util.ordSearch")}
              </Button>
              <Button
                className="h-full flex-1 pms-btn-blue-outline"
                onClick={() => go_stock()}
                disabled={s_disabled.stockSearch}
              >
                {/* 驗收記錄查詢 */}
                {t("OMS.util.stockSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶名稱 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item label={<Text disabled>{t("OMS.util.custNM")}</Text>} name="custNM">
              <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.custID")} />
            </Form.Item>
          </Col>
          {/* 進檢人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.outstock.inspectID")}</Text>} name="inspectID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 完驗時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.outstock.inspectT")}</Text>} name="inspectT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 發貨覆核 */}
              <Button
                className={`h-full flex-1 ${
                  f_lifeF === "2" ? "pms-btn-orange" : "oms-btn-darkBlue"
                }`}
                disabled={s_tbData.length === 0 ? true : s_disabled.outstockCheck}
                onClick={outstockCheck}
              >
                {/* {f_lifeF === "2" ? "取消覆核" : "發貨覆核(1)"} */}
                {f_lifeF === "2"
                  ? t("util.cancel") + t("util.check")
                  : `${t("OMS.outstock.lifeF_2")}(1)`}
              </Button>
              {/* 進檢抽樣 */}
              <Button
                className={`h-full flex-1 ${
                  f_lifeF === "3" ? "pms-btn-orange" : "oms-btn-darkBlue"
                }`}
                disabled={s_disabled.IQCstart}
                onClick={IQCcheck}
              >
                {/* {f_lifeF === "3" ? "取消進檢抽樣" : "進檢抽樣(2)"} */}
                {f_lifeF === "3"
                  ? t("util.cancel") + t("OMS.outstock.IQCcheck")
                  : `${t("OMS.outstock.IQCcheck")}(2)`}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 發貨註記 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.note}>{t("OMS.outstock.note")}</Text>}
              name="note"
              className="mb-0px"
            >
              <Input
                placeholder={t("util.placeholder_input") + t("OMS.outstock.note")}
                disabled={s_disabled.note}
              />
            </Form.Item>
          </Col>
          {/* 品判人員 */}
          <Col span={editMode ? 5 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("OMS.outstock.qalID")}</Text>} name="qalID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 放行時間 */}
          <Col span={editMode ? 4 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("OMS.outstock.qalT")}</Text>} name="qalT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              <Button
                className={`h-full flex-1 ${f_lifeF === "4" ? "pms-btn-red" : "oms-btn-darkBlue"}`}
                disabled={s_tbData.length === 0 ? true : s_disabled.IQCfinish}
                onClick={IQCfinish}
              >
                {/* {f_lifeF === "4" ? "取消IQC完驗" : "IQC完驗(3)"} */}
                {f_lifeF === "4"
                  ? t("util.cancel") + t("OMS.outstock.lifeF_4")
                  : `${t("OMS.outstock.lifeF_4")}(3)`}
              </Button>
              <Button
                className={`h-full flex-1 ${f_lifeF === "7" ? "pms-btn-red" : "oms-btn-darkBlue"}`}
                onClick={QAcheck}
                disabled={s_disabled.QAcheck}
              >
                {/* {f_lifeF === "7" ? "取消品保放行" : "品保放行(4)"} */}
                {f_lifeF === "7"
                  ? t("util.cancel") + t("OMS.outstock.QAcheck")
                  : `${t("OMS.outstock.QAcheck")}(4)`}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        hd_values={form.getFieldsValue()}
        call_getOutstockTB={call_getOutstockTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
      />

      {/* 引用銷貨單 */}
      <CustomModal
        width={screens.xxl ? "80%" : "90%"}
        title={t("OMS.util.take") + t("OMS.util.ord")} // 引用銷貨單
        open={is_showOrd}
        onCancel={() => set_is_showOrd(false)}
      >
        {is_showOrd ? (
          <TakeOrdModal
            set_is_showOrd={set_is_showOrd}
            hd_values={form.getFieldsValue()}
            call_getOutstockHD={call_getOutstockHD}
            call_getOutstockTB={call_getOutstockTB}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
