import { Upload, Button, Input, Form, InputNumber, message, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const uploadProps = {
  name: "file",
  accept: ".jpg,.png",
  maxCount: 1,
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const handleChange = (info) => {
  if (info.fileList[0]?.originFileObj) {
    getBase64(info.fileList[0].originFileObj, (imageUrl) => imageUrl);
  } else {
    message.error("上傳失敗");
  }
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("你只能上傳 JPG/PNG 圖片檔案!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("圖片必須小於 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const normFile = (e) => {
  //如果是typescript, 那么参数写成 e: any
  console.log("Upload event:", e);
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const LabelImage = {
  imageLabel: (field, rowData) => (
    <>
      <Form.Item name="imgName" hidden children={<></>} />
      <Form.Item name="imgUrl" hidden children={<></>} />
      <Col span={6}>
        <Form.Item label="長度" name={[field.name, "len"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="寬度" name={[field.name, "boxWidth"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
      <Col span={6}>
        {rowData.imgName === "" ? (
          <Form.Item
            label="圖片上傳 (.jpg , .png)"
            name={[field.name, "img"]}
            shouldUpdate
            valuePropName="file"
            getValueFromEvent={normFile}
          >
            <Upload {...uploadProps} onChange={handleChange} beforeUpload={beforeUpload}>
              <Button icon={<UploadOutlined />} block>
                圖片上傳
              </Button>
            </Upload>
          </Form.Item>
        ) : (
          <Form.Item label="圖片名稱" name={[field.name, "imgName"]}>
            <Input disabled style={{ color: "#000" }} />
          </Form.Item>
        )}
      </Col>
    </>
  ),
};

export default LabelImage;
