/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { Drawer, Grid, Space, Input, message, Typography, Tag, Modal, theme } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/BPM/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { CustomRangePicker, CustomSelect, CustomSearchButton } from "@/components/ERP/TabExtra";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { CreateButton, ClearButton, DeleteButton, EditButton } from "@/components/ERP/Button";
// component
import TagDrawer from "./Modal/TagDrawer";
import ModalContainer from "./Modal/ModalContainer";
// API
import useAPI from "@/hooks/useAPI";
import { getRole } from "@/service/apis/ADM/userManage";
// import { getSignLevel } from "./moke";
import { getSignLevel, deleteSignLevel } from "@/service/apis/BPM/SignLevel";

const { useToken } = theme;
const { Title } = Typography;

const INIT_SEARCHCONFIG = {
  startDate: dayjs().startOf("month"), // 開始日期
  endDate: dayjs().endOf("month"), // 結束日期
  searchFlag: undefined, // 要搜尋的欄位名稱
  searchValue: undefined, // 要搜尋的欄位資料
};

const SignLevel = () => {
  const { token } = useToken();
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // 取得角色
  const call_getRole = useAPI(getRole);
  // useAPI 取得權限設定
  const call_getSignLevel = useAPI(getSignLevel);
  // useAPI 刪除權限設定
  const call_deleteSignLevel = useAPI(deleteSignLevel);
  // 要搜尋的欄位
  const [s_searchConfig, set_s_searchConfig] = useState(INIT_SEARCHCONFIG);
  // 所有角色
  const [s_Roles, set_s_Roles] = useState({
    options: [],
    dataObj: {},
  });
  // 搜尋欄位的下拉選單
  const [s_selectOptions, set_s_selectOptions] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 編輯的資料
  const [s_editData, set_s_editData] = useState({});
  // 控制開啟編輯跳顯的開關
  const [is_showModal, set_is_showModal] = useState(false);
  // 是否顯示Tag Drawer的開關
  const [is_showDrawer, set_is_showDrawer] = useState(false);
  const [s_roleID, set_s_roleID] = useState({});

  // 搜尋
  const handleSearch = () => {
    call_getSignLevel.request(s_searchConfig);
  };

  // 清除搜尋紀錄
  const clearSearch = () => {
    set_s_searchConfig(INIT_SEARCHCONFIG);
  };

  // 改變搜尋欄位
  const handleChange = (type, value) => {
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...s_searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]) : s_searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]) : s_searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : s_searchConfig.searchValue,
    };
    // 更新狀態
    set_s_searchConfig(tmp);
  };

  // 刪除表頭資料
  const removeData = (event, rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.del_confirm_msg"),
      onOk: () => call_deleteSignLevel.request(rowData),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    set_s_editData(rowData);
    set_is_showModal(true);
    event.stopPropagation();
  };

  const placeholder_map = {
    "N": t("util.placeholder_select") + t("BPM.util.N"),
    "signmode": t("util.placeholder_select") + t("BPM.util.signmode"),
  };

  const opfunctionMap = [
    { label: t("BPM.util.opfunction_1"), value: "1" },
    { label: t("BPM.util.opfunction_2"), value: "2" },
    { label: t("BPM.util.opfunction_3"), value: "3" },
    { label: t("BPM.util.opfunction_4"), value: "4" },
    { label: t("BPM.util.opfunction_5"), value: "5" },
  ];

  const optypeMap = [
    { label: t("BPM.util.optype_11"), value: "11" },
    { label: t("BPM.util.optype_12"), value: "12" },
    { label: t("BPM.util.optype_40"), value: "40" },
    { label: t("BPM.util.optype_41"), value: "41" },
  ];

  const permissionsTag = (roles) => (
    <Space>
      {roles?.map((roleID, index) => (
        <Tag
          key={index}
          color="processing"
          onClick={() => {
            set_is_showDrawer(true);
            set_s_roleID(roleID);
          }}
          className="cursor-pointer"
        >
          {s_Roles.dataObj[roleID]}
        </Tag>
      )) || ""}
    </Space>
  );

  // 表格欄位
  const tableColumns = [
    {
      title: t("BPM.sign-level.opfunction"), // 營運功能
      dataIndex: "opfunction",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => t(`BPM.util.opfunction_${text}`),
    },
    {
      title: t("BPM.sign-level.optype"), // 作業類別
      dataIndex: "optype",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => t(`BPM.util.optype_${text}`),
    },
    {
      title: t("BPM.util.signmode"), // 簽核層級
      dataIndex: "signmode",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => text + t(`BPM.util.level`),
    },
    {
      title: t("BPM.util.permissions") + "_" + t("BPM.util.open"), // 權限_立案
      dataIndex: "open",
      align: "left",
      // width: screens.xxl ? "11%" : "17%",
      render: (text, _, __) => permissionsTag(text),
    },
    {
      title: t("BPM.util.permissions") + "_" + t("BPM.util.check"), // 權限_查權
      dataIndex: "check",
      align: "left",
      width: screens.xxl ? "15%" : "17%",
      render: (text, _, __) => permissionsTag(text),
    },
    {
      title: t("BPM.util.permissions") + "_" + t("BPM.util.review"), // 權限_審權
      dataIndex: "review",
      align: "left",
      width: screens.xxl ? "15%" : "17%",
      render: (text, _, __) => permissionsTag(text),
    },
    {
      title: t("BPM.util.approve") + "_" + t("BPM.util.approve"), // 權限_核權
      dataIndex: "approve",
      align: "left",
      width: screens.xxl ? "15%" : "17%",
      render: (text, _, __) => permissionsTag(text),
    },
    {
      title: t("util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: screens.xxl ? "7%" : "5%",
      render: (text, _, __) => (
        <div className="checkIcon">
          {text === true ? (
            <CheckCircleFilled style={{ color: token.colorPrimary }} />
          ) : (
            <CloseCircleOutlined />
          )}
        </div>
      ),
    },
    {
      title: t("util.action"), // 操作
      dataIndex: "render",
      align: "center",
      width: screens.xxl ? "5%" : "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton onClick={(e) => removeData(e, rowData)} />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  // 第一次渲染
  useEffect(() => {
    // 取得權限設定
    call_getSignLevel.request(s_searchConfig);
    // 取得角色
    call_getRole.request();
  }, []);

  // useAPI取得角色
  useEffect(() => {
    const { status, data, msg } = call_getRole;
    if (status === "suc") {
      message.success(msg);

      const _options = data?.map((item) => ({ label: item.itemNM, value: item.itemID })) || [];
      const _dataObj =
        data?.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.itemID]: curr.itemNM,
          };
        }, {}) || {};

      set_s_Roles({
        options: _options,
        dataObj: _dataObj,
      });
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getRole.status]);

  // useAPI 取得權限資料
  useEffect(() => {
    const { data, msg, status } = call_getSignLevel;
    if (status === "suc") {
      message.success(msg);
      const _data = data?.map((item, index) => {
        const open = item.open.split("|").filter((x) => x !== "");
        const check = item.check.split("|").filter((x) => x !== "");
        const review = item.review.split("|").filter((x) => x !== "");
        const approve = item.approve.split("|").filter((x) => x !== "");
        return {
          ...item,
          key: index,
          open: open,
          check: check,
          review: review,
          approve: approve,
        };
      });
      // console.log("_data = ", _data);
      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData();
    }
  }, [call_getSignLevel.status]);

  // useAPI 刪除權限資料
  useEffect(() => {
    const { status, msg, data } = call_deleteSignLevel;
    if (status === "suc") {
      message.success(msg);
      call_getSignLevel.request(s_searchConfig);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteSignLevel.status]);
  console.log(s_Roles);
  return (
    <div className="flex-1">
      <PageHeader
        title={t("BPM.sign-level.pageHeader")} // 簽核權限設定
        extra={
          <Space className="flex justify-end mb-2">
            <CustomRangePicker
              value={[s_searchConfig?.startDate, s_searchConfig?.endDate]}
              onChange={(time, _) => handleChange("time", time)}
            />

            <Input.Group compact>
              <CustomSelect
                value={s_searchConfig.searchFlag}
                placeholder="搜尋欄位"
                onChange={(value) => handleChange("searchFlag", value)}
                options={[
                  { value: "N", label: t("BPM.util.N") }, // 單號
                  { value: "signmode", label: t("BPM.util.signmode") }, // 簽核層級
                ]}
              />

              <CustomSelect
                showSearch
                value={s_searchConfig.searchValue}
                className="w-[250px]"
                placeholder={placeholder_map[s_searchConfig.searchFlag]}
                // 根據searchFlag判斷現在是哪個下拉選單
                options={s_selectOptions[s_searchConfig.searchFlag]}
              />
              <CustomSearchButton onClick={handleSearch} />
            </Input.Group>

            {/* 清除搜詢紀錄 */}
            <ClearButton onClick={clearSearch} />
            {/* 新建 */}
            <CreateButton onClick={() => set_is_showModal(true)} />
          </Space>
        }
      />

      <CustomTable
        // bordered={true}
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getSignLevel.status === "load"}
        onRow={(record) => {
          return {
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 查看角色-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {s_Roles.dataObj[s_roleID]}
          </Title>
        }
        placement="left"
        closable={false}
        width={500}
        onClose={() => {
          set_is_showDrawer(false);
          set_s_editData({});
        }}
        open={is_showDrawer}
      >
        <TagDrawer
          set_is_showDrawer={set_is_showDrawer}
          s_roleID={s_roleID}
          set_s_roleID={set_s_roleID}
        />
      </Drawer>

      <CustomModal
        width="70%"
        title={
          (Object.keys(s_editData).length > 0 ? t("util.edit") : t("util.add")) +
          t("BPM.sign-level.pageHeader")
        }
        open={is_showModal}
        onCancel={() => {
          set_is_showModal(false);
          set_s_editData({});
        }}
        afterClose={() => {
          call_getSignLevel.request(s_searchConfig);
        }}
      >
        {is_showModal && (
          <ModalContainer
            set_is_showModal={set_is_showModal}
            s_editData={s_editData}
            set_s_editData={set_s_editData}
            arrMap={{
              opfunctionMap: opfunctionMap,
              optypeMap: optypeMap,
              roleMap: s_Roles.options,
            }}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default SignLevel;
