import { Grid, Table, theme } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import styled from "styled-components";

const StyleTable = styled(Table)`
  .light {
    background-color: ${({ token }) => token.colorTableLight};
  }

  .dark {
    background-color: ${({ token }) => token.colorTableDark};
  }

  .ant-table-body {
    border-bottom: ${({ token }) => `.5px solid ${token.colorTableBodyBorder}`};
    border-left: ${({ token }) => `.5px solid ${token.colorTableBodyBorder}`};
    border-right: ${({ token }) => `.5px solid ${token.colorTableBodyBorder}`};
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ant-table-header {
    border-top: ${({ token }) => `.5px solid ${token.colorTableBodyBorder}`};
  }

  .ant-table-container table {
    font-weight: 400 !important;
  }
  /* 表頭論如何都置中 */
  .ant-table-container table > thead > tr:first-child > * {
    background: ${({ token, type }) =>
      type === "dark-purple"
        ? token.colorTableHeader_dark
        : token.colorTableHeader_light} !important;
    text-align: center !important;
    color: ${({ token, type }) =>
      type === "dark-purple"
        ? token.colorTableHeaderText_dark
        : token.colorTableHeaderText_light} !important;
  }
  /* hover 背景色 */
  .ant-table-cell-row-hover {
    background: ${({ token }) => token.colorTableCellRowHover} !important;
  }
  /* 如果是表身，table header就不要出現分隔線 */
  .ant-table-cell::before {
    background-color: ${({ type }) => type === "dark-purple" && "transparent !important"};
  }
  .clickRowStyle {
    background: ${({ token }) => token.colorTableOnCellRow} !important;
  }
  .clickRowStyle > td {
    color: #ffaa00;
  }
  .clickRowStyle > td:nth-child(1) {
    border-left: 5px solid #ffaa00;
    padding-left: 3px;
  }

  /* 拖動Style*/
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1677ff !important;
    transition: 1s;
    background: #fafafa;
    z-index: 9999;
  }
  tr.drop-over-upward td {
    border-top: 2px dashed #1677ff !important;
    transition: 1s;
  }

  // 編輯Table用
  .editable-cell {
    position: relative;
  }
  // 編輯Table用
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  // 編輯Table用
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  // 編輯Table用
  [data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }
`;
const { useToken } = theme;

/**
 * @param bool tbMode 有無表身，可從c_lightData判斷
 * @param bool fullScreen 螢幕是否全滿模式
 * @param number rowNumber Table要顯示幾筆資料
 * @param string type Table要顯示的樣式(ex: 當table出現在Tb，table header要顯示深紫色)
 * */
export default function CustomTable(props) {
  const { token } = useToken();
  // tbMode = 有無表身 可從c_lightData判斷
  const { lightData, tbMode = false, rowNumber, columns = [] } = props;

  const [scrollY, setScrollY] = useState(0);
  const screens = Grid.useBreakpoint();

  const rowHeight = token.fontSize * 1.5 + 13;
  useEffect(() => {
    // 一般螢幕尺寸
    if (screens.xxl) {
      // 表身資料
      tbMode ? setScrollY(rowHeight * 7) : setScrollY(rowHeight * 18);
    } else if (screens.xl) {
      // macbook 尺寸
      tbMode ? setScrollY(rowHeight * 6) : setScrollY(rowHeight * 14);
    }
  }, [screens, tbMode, token.fontSize]);
  //使用 dangerouslySetInnerHTML 將換行標籤轉換為html
  // let customColumns = columns?.map((x) => {
  //   console.log("x = ", x);
  //   return {
  //     ...x,
  //     title: <span dangerouslySetInnerHTML={{ __html: x.title.replace(/\n/g, "<br>") }} />,
  //   };
  // });

  return (
    <StyleTable
      rowClassName={(record, index) => {
        // 如果lightData有傳進來代表需要表頭表身結構
        const clickRowStyle = record.key === lightData?.key ? "clickRowStyle" : "";
        const type = index % 2 === 1 ? "dark" : "light";
        return `${type} ${clickRowStyle}`;
      }}
      // columns={customColumns}
      locale={{ emptyText: "No data" }}
      scroll={{
        // x: "max-content",
        y: rowNumber ? rowNumber * rowHeight : scrollY,
      }}
      pagination={false}
      token={token}
      {...props}
    />
  );
}
