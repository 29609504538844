import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { addOrdTB, updateOrdTB } from "@/service/apis/OMS/Ord";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// context
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import PNButton from "@/components/ERP/PNButton";
import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const TbModal = ({
  hd_values,
  s_mgmtcat75,
  s_disabled,
  s_tbModal,
  set_s_tbModal,
  call_getOrdHD,
  call_getOrdTB,
}) => {
  const [s_PN_Options, set_s_PN_Options] = useState([]);
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addOrdTB = useAPI(addOrdTB);
  // useAPI 編輯表身
  const call_updateOrdTB = useAPI(updateOrdTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);
  const f_note = Form.useWatch("note", form);
  const f_unitprice = Form.useWatch("unitprice", form);
  const f_demandqty = Form.useWatch("demandqty", form);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    if (type === "create") {
      // step1 call 新建表身的API
      call_addOrdTB.request(values);
    }
    if (type === "edit") {
      // step1 call 編輯表身的API
      call_updateOrdTB.request(values);
    }
  };

  // 改變料號
  const changePdtNM = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.pdtNM + item.pdtspec === value) ?? {};
    // console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // note
      note: form.getFieldValue("note"),
    });
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    // 判斷目前是編輯表身還是新建表身
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    const formValues = {
      opUUID: hd_values.opUUID,
      currencytype: hd_values.currencytype,
      exchangerate: hd_values.exchangerate,
      exchangeD: hd_values.exchangeD,
      ...(type === "create"
        ? {
            planday: hd_values.planday,
            EDT: hd_values.EDT ? dayjs(hd_values.EDT) : undefined,
            returnqty: 0,
            confirmqty: 0,
            cancelqty: 0,
            balqty: 0,
            lifeF: "0",
          }
        : {
            ...s_tbModal.data,
            EDT: dayjs(s_tbModal.data.EDT),
          }),
    };
    // format
    // formValues.EDT = dayjs(formValues.EDT);
    // console.log("formValues = ", formValues);
    form.setFieldsValue(formValues);
  }, []);

  useEffect(() => {
    const tmp = c_PNs?.data?.map((item) => {
      return {
        // label: `${item.pdtNM} 檢測標準:${item.pdtspec}`,
        label: (
          <span>
            {item.pdtNM}
            <span className="text-blue-500 ml-2">檢測標準: {item.pdtspec || "N/A"}</span>
          </span>
        ),
        value: item.pdtNM + item.pdtspec,
        key: item.PN,
      };
    });
    set_s_PN_Options(tmp);
  }, [c_PNs]);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addOrdTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addOrdTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateOrdTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateOrdTB.status]);

  // 改變採購單價、採購數量時重新計算各項金額(itemsum)、各項稅額(itemsumtax)、含稅小記(subtotal)
  // 改變採購單價時重新計算本幣單價
  useEffect(() => {
    if (!f_demandqty || !f_unitprice) return;

    form.setFieldsValue({
      itemsum: f_demandqty * f_unitprice,
    });
  }, [f_unitprice, f_demandqty]);

  const hasNote = f_note?.length > 0 ? false : true;

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>
        {/* 退出數量 */}
        <Form.Item name="returnqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 取消數量 */}
        <Form.Item name="cancelqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 廠商確認數量 */}
        <Form.Item name="confirmqty" hidden>
          <InputNumber />
        </Form.Item>

        {/* 剩餘數量 */}
        <Form.Item name="balqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項金額 */}
        <Form.Item name="itemsum" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項稅額 */}
        <Form.Item name="itemsumtax" hidden>
          <InputNumber />
        </Form.Item>
        {/* 含稅小計 */}
        <Form.Item name="subtotal" hidden>
          <InputNumber />
        </Form.Item>
      </div>
      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS.ord.note")}</Text>}
            name="note"
          >
            <Input
              disabled={s_disabled.tb_note}
              placeholder={t("util.placeholder_input") + t("OMS.ord.note")}
            />
          </Form.Item>
        </Col>
        <Col span={13}>
          <Form.Item
            label={<Text disabled={hasNote || s_disabled.PN}>{t("util.pdtNM")}</Text>}
            // 品名
            name="pdtNM"
            rules={[{ required: true }]}
            messageVariables={{ label: t("util.pdtNM") }}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("util.pdtNM")}
              disabled={hasNote || s_disabled.PN}
              onChange={changePdtNM}
              options={s_PN_Options}
              // filterOption={(input, option) => {
              //   return ((option?.PN ?? "") + (option?.pdtNM ?? "")).includes(input);
              // }}
            />
          </Form.Item>
        </Col>

        <Col span={3} className="flex items-end">
          <PNButton OMSform={form} custalias={hd_values.custalias} disabled={hasNote} />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 料號 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.PN")}</Text>} name="PN">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")} />
          </Form.Item>
        </Col>

        {/* 引用標準 */}
        {/* <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.mtype")}</Text>} name="mtype">
            <Select
              disabled
              placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")}
              options={s_mgmtcat75.option}
            />
          </Form.Item>
        </Col> */}

        {/* 規格 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}></Col>
        <Col span={8}>
          {/* 檢驗項目 */}
          {/* <Form.Item label={<Text>{t("OMS.ord.checkitem")}</Text>} name="checkitem">
            <Input />
          </Form.Item> */}
        </Col>
        <Col span={8}>
          {/* 測試方法/標準 */}
          {/* <Form.Item label={<Text>{t("OMS.ord.checkmethod")}</Text>} name="checkmethod">
            <Input />
          </Form.Item> */}
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            // 测试费用
            label={<Text disabled={s_disabled.unitprice}>{t("OMS.ord.unitprice")}</Text>}
            name="unitprice"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ord.unitprice") }}
          >
            <InputNumber
              disabled={s_disabled.unitprice}
              className="w-full"
              formatter={(value) => formatAmount(value)}
              placeholder={t("util.placeholder_input") + t("OMS.ord.unitprice")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 數量
            label={<Text disabled={s_disabled.demandqty}>{t("OMS.ord.demandqty")}</Text>}
            name="demandqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ord.demandqty") }}
          >
            <InputNumber
              disabled={s_disabled.demandqty}
              className="w-full"
              formatter={(value) => formatAmount(value)}
              placeholder={t("util.placeholder_select") + t("OMS.ord.demandqty")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.itemsum")}</Text>} name="itemsum">
            <InputNumber disabled formatter={(value) => formatAmount(value)} className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 計畫時間 */}
          <Form.Item label={<Text>{t("OMS.ord.planday")}</Text>} name="planday">
            <Input disabled={s_disabled.planday} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.endEDT}>{t("OMS.util.EDT")}</Text>}
            name="EDT"
          >
            <DatePicker disabled={s_disabled.EDT} className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
