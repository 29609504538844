import { useState, useEffect, useRef } from "react";
import { Table, Grid, theme, message } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const StyleTable = styled(Table)`
  
  .ant-table-container table {
    font-weight: 600 !important;
  }
  .ant-table-wrapper{
    border-radius:8px !important
  }
  .ant-table-container {
    border:1px solid #D1D5DB !important;
    border-radius:8px
  }
  .ant-table-tbody{
     
    border-radius:8px
  }
  .ant-table-body {
   
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .ant-table-cell {
    
    border:none
  }

  /* 表頭論如何都置中 */
  .ant-table-container table > thead > tr:first-child > * {
    background: ${({ token }) => token.colorTableHeader} !important;
    text-align: center !important;
  }
  /* hover 背景色 */
  .ant-table-cell-row-hover {
    background: ${({ token }) => token.colorTableCellRowHover} !important;
  }
  .clickRowStyle {
    background: ${({ token }) => token.colorTableOnCellRow} !important;
  }
  .clickRowStyle > td:nth-child(1) {
    transition: 0.2s;
    border-left: 5px solid ${({ token }) => token.colorTableOnCellRow};
    padding-left: 3px;
  }

  /* 拖動Style*/
  tr.drop-over-downward td {
    border-bottom: 2px dashed #1677ff !important;
    transition: 1s;
    background: #fafafa;
    z-index: 9999;
  }
  tr.drop-over-upward td {
    border-top: 2px dashed #1677ff !important;
    transition: 1s;
  }
`;
const { useToken } = theme;

/**
 * @param bool tbMode 有無表身，可從c_lightData判斷
 * @param bool fullScreen 螢幕是否全滿模式
 * */
export default function CustomTable(props) {
  const { token } = useToken();
  const tableRef = useRef(null);
  const { t } = useTranslation();
  const [s_ClientRect, set_s_ClientRect] = useState({});
  // tbMode = 有無表身 可從c_lightData判斷
  // fullScreen = 螢幕是否全滿模式
  const {
    rowClassName,
    pagination,
    tbMode = false,
    fullScreen = false,
    c_lightData = {},
    columns = [],
    ...other
  } = props;

  const [scrollY, setScrollY] = useState(0);

  const screens = Grid.useBreakpoint();
  // 沒有 rowClassName 的情況 深淺交替
  let customRowClassName = (record, index) =>
    record.key === c_lightData?.key
      ? "clickRowStyle"
      : rowClassName || index % 2 === 0
        ? rowClassName
        : "bg-slate-50";
  // 沒有 Pagination 的情況 預設值
  let customPagination =
    pagination === false
      ? false
      : {
        ...pagination,
        defaultPageSize: "100",
        showSizeChanger: true,
        pageSizeOptions: ["100", "200", "500", "1000"],
        showTotal: (total, range) => (
          <span className="text-lg font-semibold">
            {t("util.showTotal", {
              total: total,
              rangeFirst: range[0],
              rangeEnd: range[1],
            })}
          </span>
        ),
      };

  //使用 dangerouslySetInnerHTML 將換行標籤轉換為html
  let customColumns = columns.map((x) => ({
    ...x,
    title:
      typeof x.title === "string" ? (
        <span dangerouslySetInnerHTML={{ __html: x.title.replace(/\n/g, "<br>") }} />
      ) : (
        x.title
      ),
  }));

  useEffect(() => {
    function handleResize() {
      // Set window width/height to state
      set_s_ClientRect(tableRef.current?.getBoundingClientRect());
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [other.dataSource]);

  // 有表身時保證表頭選中的row一定不會被遮住
  useEffect(() => {
    if (Object.keys(c_lightData).length === 0) return;

    if (!c_lightData.index) {
      // message.error("請在s_tabledata給index，可參考 BDM -> PNManage");
      return;
    }
    const header = tableRef.current.querySelector(".ant-table-thead");
    // 可滾動的區域
    const table_element = tableRef.current.querySelector(".ant-table-body");

    const tbody_element = tableRef.current.querySelector(".ant-table-tbody");
    // 全部 s_tableData 的dom元素
    const childNodes = tbody_element.querySelectorAll("tr");
    // c_lightData的row（減1是因為antd會多生成一個tr）
    const target = childNodes[c_lightData.index - 1];
    if (!target) return;

    if (table_element) {
      table_element.scrollBy({
        top: target.getBoundingClientRect().y - header.getBoundingClientRect().y,
        behavior: "smooth",
      });
    }
  }, [c_lightData, scrollY]);

  // 計算table要顯示的高度
  useEffect(() => {
    const rowHeight = token.fontSize * 1.5 + 13;
    // 螢幕全滿模式
    if (fullScreen) {
      setScrollY("100%");
      return;
    }

    // 一般螢幕尺寸
    if (screens.xxl) {
      // 表身資料
      // !tbMode ? setScrollY(rowHeight * 14) : setScrollY(rowHeight * 6);
      // 這邊要剪掉pagination跟table-header的高度，所以直接剪掉pagination*2
      !tbMode
        ? setScrollY(window.innerHeight - s_ClientRect.top - 56 * 2)
        : setScrollY(rowHeight * 6);
    } else if (screens.xl) {
      // macbook 尺寸
      // !tbMode ? setScrollY(rowHeight * 12) : setScrollY(rowHeight * 6);
      !tbMode
        ? setScrollY(window.innerHeight - s_ClientRect.top - 56 * 2)
        : setScrollY(rowHeight * 6);
    }
  }, [screens, tbMode, fullScreen, token.fontSize, s_ClientRect]);

  return (
    <StyleTable
      ref={tableRef}
      rowClassName={customRowClassName}
      locale={{ emptyText: "No data" }}
      scroll={{ y: scrollY }}
      pagination={customPagination}
      token={token}
      columns={customColumns}
      {...other}
    />
  );
}
