import { Input, Form, DatePicker, InputNumber } from "antd";
const HiddenFormItem = () => {
  return (
    <div>
      <Form.Item name="linkType" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="custalias" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="lifeF" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="lifeFT" hidden>
        <DatePicker />
      </Form.Item>
      <Form.Item name="proCount" hidden>
        <InputNumber />
      </Form.Item>
      <Form.Item name="proLifeF2count" hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item name="opUUID" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="preopUUID" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="charge" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="itemtotal" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="sumtotal" hidden>
        <InputNumber />
      </Form.Item>

      <Form.Item name="tax" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="total" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="entrust" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="quoSign" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="inspcer" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="contract" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="AR" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="rcv" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="bill" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="apCount" hidden>
        <InputNumber />
      </Form.Item>
    </div>
  );
};

export default HiddenFormItem;
