/* eslint-disable react-hooks/exhaustive-deps */
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { Divider, Input, Modal, Space, message } from "antd";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { IconCheck, IconClose } from "@/components/Icon/Action";
import { deleteRunA3HD, getRunA3HD } from "@/service/apis/OMS/runA3";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalContainer from "./ModalContainer";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = forwardRef(({ c_lightData, set_c_lightData }, ref) => {
  const { t } = useTranslation();
  const call_getRunA3HD = useAPI(getRunA3HD);
  const call_deleteRunA3HD = useAPI(deleteRunA3HD);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState([]);
  const [s_searchData, set_s_searchData] = useState({
    query: "",
  });
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 客戶資料、人員資料
  const { getStaffName } = useContext(ERPContext);

  useImperativeHandle(ref, () => ({
    Call_hd: () => call_getRunA3HD.request(s_searchData),
  }));

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onCreate = () => {
    set_s_isShowModal(true);
  };

  const onSearch = () => {
    call_getRunA3HD.request(s_searchData);
  };

  const removeData = (e, rowData) => {
    Modal.confirm({
      title: t("OMS.util.ord") + t("OMS.util.del_confirm"), // 銷貨單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.ord.removeData_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteRunA3HD.request(rowData),
    });
    e.stopPropagation();
  };
  const editData = (e, rowData) => {
    set_s_editData(rowData);
    set_s_isShowModal(true);
    e.stopPropagation();
  };

  const basicColumns = genTableColumns(t, getStaffName);

  const tableColumns = basicColumns.concat([
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ]);

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.query}
      onChange={(e) => handlChange("query", e.target.value)}
      placeholder={t("util.search")}
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  // 第一次渲染
  useEffect(() => {
    call_getRunA3HD.request(s_searchData);
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getRunA3HD;
    if (status === "suc") {
      message.success(msg);
      const _data =
        data?.map((item, index) => ({
          ...item,
          key: index + 1,
        })) || [];

      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getRunA3HD.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteRunA3HD;
    if (status === "suc") {
      message.success(msg);
      call_getRunA3HD.request(s_searchData);
      set_c_lightData({});
    }
    if (status === "err") {
      message.error(msg);
      call_getRunA3HD.request(s_searchData);
      set_c_lightData({});
    }
  }, [call_deleteRunA3HD.status]);

  return (
    <>
      <PageHeader title="订单收款作业" extra={searchBasic} />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getRunA3HD.status === "load"}
        lightData={c_lightData}
        tbMode={Object.keys(c_lightData).length > 0}
        onRow={(record) => {
          return {
            onClick: () =>
              record.opUUID === c_lightData.opUUID ? set_c_lightData({}) : set_c_lightData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_s_editData(record);
              set_s_isShowModal(true);
            },
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}订单收款作业`
            : `${t("util.add")}订单收款作业`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          isDisabled={false} // 如果為 true 代表是在訂單查看時使用
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export const genTableColumns = (t, getStaffName) => {
  return [
    {
      title: t("util.util.N"),
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    {
      title: "訂單單號",
      dataIndex: "preN",
      align: "center",
      width: "8%",
      // render: (text) => t(`OMS.util.opcode_${text}`),
    },
    {
      title: "總應付金額", // 訂單的total
      dataIndex: "duepay",
      align: "left",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "累計金額",
      dataIndex: "cumpay",
      align: "left",
      width: "8%",
      render: (text) => formatAmount(text),
    },

    {
      title: "創建人員",
      dataIndex: "createID",
      align: "center",
      width: "7%",
      render: (text) => getStaffName(text),
    },
    {
      title: "創建日期",
      dataIndex: "createT",
      align: "center",
      width: "7%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "是否關帳",
      dataIndex: "isclose",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "關帳人員",
      dataIndex: "confirmID",
      align: "center",
      width: "7%",
      render: (text) => getStaffName(text),
    },
    {
      title: "關帳日期",
      dataIndex: "confirmT",
      align: "center",
      width: "7%",
      render: (text, index, rowData) => rowData.isclose && dayjs(text).format("YYYY-MM-DD"),
    },
  ];
};

export default HD;
