import { Button, Modal, Space, message } from "antd";
import {
  IconCheck,
  IconClose,
  IconConfirm,
  IconDownload,
  IconGarbage,
  IconInvCheck,
  IconInvDoubleCheck,
  IconInvThirdCheckk,
  IconInvZeroCheck,
} from "@/components/Icon/Action";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { deleteInvHd, exportInvExcel, getInvHd, updateInvHd } from "@/service/apis/WMS/Inventory";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalContainer from "./ModalContainer";
// component
import PageHeader from "@/components/PageHeader/index";
import TbModalContainer from "../TB/ModalContainer";
import apiBasic from "@/service/APIBasic";
import dayjs from "dayjs";
// import exportExcel from "@/util/exportExcel_new";
// util
import { formatAmount } from "@/util/format";
import { generateTableColumn } from "../TB/ModalContainer";
// api
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = ({ project_ref, s_tabKey, s_spinning, set_s_spinning, c_lightData }) => {
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  // const [is_spinning, set_is_spinning] = useState(false);
  const [is_showModal, set_is_showModal] = useState(false);
  const [is_showTbModal, set_is_showTbModal] = useState(false);
  const [s_editData, set_s_editData] = useState({});
  // 是否為初盤
  const [s_invCheck, set_s_invCheck] = useState("3"); // 0:複核 1:初盤 2:複盤 3:查看
  const call_getInvHd = useAPI(getInvHd);
  const call_deleteInvHd = useAPI(deleteInvHd);
  const call_exportInvExcel = useAPI(exportInvExcel);
  // 更新盤點單
  const call_updateInvHd = useAPI(updateInvHd);

  useEffect(() => {
    call_getInvHd.request(c_lightData);
  }, [c_lightData]);

  // 取得表身資料
  useEffect(() => {
    const { status, msg, data } = call_getInvHd;
    if (status === "suc") {
      const _data = data?.map((x, i) => ({ ...x, key: i })) || [];
      set_s_tableData(_data);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_getInvHd.status]);

  useEffect(() => {
    const { status, msg, data } = call_updateInvHd;
    if (status === "suc") {
      message.success(msg);
      set_is_showTbModal(false);
      call_getInvHd.request(c_lightData);
      project_ref.Call_project();
    }
    if (status === "error") {
      message.error(msg);
      set_is_showTbModal(false);
    }
  }, [call_updateInvHd.status]);

  // useAPI 取得盤點詳情資料(匯出Excel)
  useEffect(() => {
    const { status, msg, data } = call_exportInvExcel;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      message.success(msg);
      set_s_spinning(false);
      // console.log("href = ", `http://localhost:11112${data}`);
      // return;
      const fileName = data.split("/").reverse()[0];
      console.log("fileName = ", fileName);
      // return;
      const link = document.createElement("a");
      link.href = `${apiBasic.conn}://${apiBasic.url}${data}`;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      //   if (data.length === 0) return;
      //   const ExcelSourec = data?.map((item) => ({
      //     ...item,
      //     whID: `${item.whID} -${item.whNM}`,
      //     N: "20231020-001", // 請後端給我打API時傳遞的N
      //     closeT: dayjs(item.closeT).format("YYYY-MM-DD"),
      //   }));

      //   const basicColumn = [
      //     {
      //       title: t("PMS.util.N"),
      //       dataIndex: "N",
      //       isExcel: true,
      //     },
      //   ];
      //   const tbColumn = generateTableColumn(t, s_invCheck);
      //   const column = basicColumn.concat(tbColumn);

      //   console.log("ExcelSourec = ", ExcelSourec);
      //   // return;

      //   exportExcel(
      //     column,
      //     ExcelSourec,
      //     `${s_invCheck === "1" ? "初盤" : s_invCheck === "2" ? "複盤" : ""}盤點表-${dayjs().format(
      //       "YYYY-MM-DD"
      //     )}.xlsx`
      //   );
      //   set_s_spinning(false);
      //   set_s_invCheck("0");
    }
    if (status === "err") {
      message.error(msg);
      set_s_spinning(false);
    }
  }, [call_exportInvExcel.status]);

  // 刪除表身資料
  useEffect(() => {
    const { status, msg } = call_deleteInvHd;
    if (status === "suc") {
      message.success(msg);
      call_getInvHd.request(c_lightData);
      project_ref.Call_project();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteInvHd.status]);

  const deleteHd = (e, rowData) => {
    Modal.confirm({
      title: t("util.util.del_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("util.util.del_confirm_msg"),
      okText: t("util.util.ok"),
      onOk: () => call_deleteInvHd.request(rowData),
      cancelText: t("util.util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  const chooseExportType = (rowData) => {
    Modal.info({
      title: "档案类别确认",
      maskClosable: true,
      footer: null,
      content: (
        <div className="flex justify-between gap-2">
          {/* <Button onClick={() => Modal.destroyAll()}>aaaaa</Button> */}
          <Button
            type="primary"
            className="invDownloadBtn"
            icon={<IconDownload />}
            onClick={() => {
              set_s_invCheck("1");
              const modifiedRowData = { ...rowData, query: rowData.attribute }; // 在這裡增加 query: attribute
              call_exportInvExcel.request({ type: "inv1", ...modifiedRowData });
            }}
          >
            初盘
          </Button>
          <Button
            type="primary"
            className="invDownloadBtn"
            icon={<IconDownload />}
            onClick={() => {
              set_s_invCheck("2");
              const modifiedRowData = { ...rowData, query: rowData.attribute }; // 在這裡增加 query: attribute
              call_exportInvExcel.request({ type: "inv2", ...modifiedRowData });
            }}
          >
            复盘
          </Button>
        </div>
      ),
      okText: "ok",
    });
  };

  const tableColumns = [
    {
      title: t("util.util.SN"),
      dataIndex: "itemno",
      width: "3%",
      align: "center",
      isExcel: true,
      render: (_, __, index) => index + 1,
    },
    {
      title: t("util.util.N"), // 倉別
      dataIndex: "N",
      width: "10%",
      align: "center",
      isExcel: true,
    },
    {
      title: t("util.util.whID"), // 倉別
      dataIndex: "whID",
      width: "34%",
      align: "center",
      isExcel: true,
      render: (_, record) => `${record.whID} -${record.whNM}`,
    },
    {
      title: "屬性",
      dataIndex: "attribute",
      width: "25%",
      align: "center",
      isExcel: true,
      render: (text) => {
        const attribute_options = [
          { value: "0|1|2|3|4|5|6|7", label: "All" },
          { value: "0", label: "0 原料" },
          { value: "1", label: "1 物料" },
          { value: "2", label: "2 非標零件" },
          { value: "3", label: "3 標準零件" },
          { value: "4", label: "4 半成品" },
          { value: "5", label: "5 製成品" },
          { value: "6", label: "6 客供品" },
          { value: "7", label: "7 維修備品" },
        ];
        return attribute_options.find((item) => item.value === text)?.label;
      },
    },
    {
      title: "有效",
      dataIndex: "isvalid",
      width: "6%",
      align: "center",
      // sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "關注",
      dataIndex: "isfocus",
      width: "6%",
      align: "center",
      // sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "封存",
      dataIndex: "isstock",
      width: "6%",
      align: "center",
      // sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    // {
    //   title: t("IMS.inv-project.selectnote"), // 篩選條件紀錄
    //   dataIndex: "selectnote",
    //   align: "center",
    //   isExcel: true,
    // },
    // {
    //   title: "庫存準確率",
    //   dataIndex: "rate",
    //   align: "center",
    //   width: "10%",
    //   isExcel: true,
    //   render: (text) => `${formatAmount(text) * 100}%`,
    // },
    {
      title: t("util.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: "10%",
      render: (text) => {
        const map = {
          "0": "創建",
          "1": "复核",
          "2": "初盘",
          "3": "初盘复核",
          "4": "复盘",
          "5": "复盘复核",
          "R": "盘点异动中",
          "E": "盘点异动错误",
          "T": "盘点调整结束",
        };
        return map[text];
      },
    },
    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      width: "20%",
      render: (_, rowData) => (
        <Space size="middle">
          <IconInvZeroCheck
            disabled={rowData.lifeF === "0" ? false : true}
            onClick={() => {
              set_s_editData(rowData);
              set_s_invCheck("0");
              set_is_showTbModal(true);
            }}
          />
          <IconInvCheck
            disabled={(rowData.lifeF === "1") | (rowData.lifeF === "2") ? false : true}
            onClick={() => {
              set_s_editData(rowData);
              set_is_showTbModal(true);
              set_s_invCheck("1");
            }}
          />
          <IconInvDoubleCheck
            disabled={(rowData.lifeF === "3") | (rowData.lifeF === "4") ? false : true}
            onClick={() => {
              set_s_editData(rowData);
              set_is_showTbModal(true);
              set_s_invCheck("2");
            }}
          />
          <IconDownload onClick={(e) => chooseExportType(rowData)} />
          <IconInvThirdCheckk
            disabled={rowData.lifeF === "T" ? false : true}
            onClick={() => {
              set_s_editData(rowData);
              set_is_showTbModal(true);
              set_s_invCheck("3");
            }}
          />
          <IconConfirm
            // 只有初盤覆核跟複盤覆核才可以異動
            disabled={(rowData.lifeF === "3") | (rowData.lifeF === "5") ? false : true}
            onClick={() => call_updateInvHd.request({ ...rowData, lifeF: "T" })}
          />
          <IconGarbage
            disabled={rowData.lifeF === "0" ? false : true}
            onClick={(e) => deleteHd(e, rowData)}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={"盤點單"}
        extra={[
          <Button type="primary" onClick={() => set_is_showModal(true)}>
            新增盘点单
          </Button>,
        ]}
      />

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getInvHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        pagination={false}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.util.modify")}盘点单`
            : `${t("util.util.add")}盘点单`
        }
        width="60%"
        open={is_showModal}
        onCancel={() => {
          console.log("s_spinning = ", s_spinning);
          if (!s_spinning) {
            set_is_showModal(false);
          }
        }}
        afterClose={() => {
          set_s_editData({});
          call_getInvHd.request(c_lightData);
          project_ref.Call_project();
        }}
      >
        <ModalContainer
          s_spinning={s_spinning}
          set_s_spinning={set_s_spinning}
          s_editData={s_editData}
          s_tabKey={s_tabKey}
          c_lightData={c_lightData}
          set_is_showModal={set_is_showModal}
        />
      </CustomModal>

      <CustomModal
        title={`盘点单 ${t("util.util.detail")}`}
        width="80%"
        open={is_showTbModal}
        onCancel={() => set_is_showTbModal(false)}
        afterClose={() => {
          set_s_editData({});

          set_s_invCheck("0"); //預設為 複核
          project_ref.Call_project();
        }}
      >
        <TbModalContainer
          call_updateInvHd={call_updateInvHd}
          s_invCheck={s_invCheck}
          s_editData={s_editData}
          set_is_showTbModal={set_is_showTbModal}
        />
      </CustomModal>
    </>
  );
};

export default HD;
