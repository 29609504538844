import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { data } = s_drawer;

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 料號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.PN")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.PN}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 品名 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.pdtNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 規格 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.pdtspec")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtspec}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 單位類別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.utype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.utype === "unit" ? t("OMS.util.unit") : t("OMS.util.opunit")}</Text>
        </Col>
        {/* 報價單位 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.quo.punit")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.punit}</Text>
        </Col>
        {/* 幣別 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.currencytype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.currencytype}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 匯率 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.exchangerate")}</Text>
        </Col>

        <Col span={4} className="pms-drawer-value">
          <Text>{data.exchangerate}</Text>
        </Col>
        {/* 報價數量 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.quo.demand")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.demand)}</Text>
        </Col>
        {/* 報價金額 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.quo.unitprice")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.unitprice)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 本幣金額 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.localprice")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{formatAmount(data.localprice)}</Text>
        </Col>
        {/* 單項成交 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.quo.isapprove")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.isapprove ? "是" : "否"}</Text>
        </Col>
      </Row>

      <Row className="h-24 border border-t-0 pms-drawer-border-color">
        {/* 註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.quo.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
