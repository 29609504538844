export const getWpcRun = () => {
  return new Promise((resolve, reject) => {
    const data = {
      status: true,
      msg: "取得簽核權限設定成功",
      data: [
        {
          UUID: "afjkldergjiopjqagjaeioprgjdfkl",
          opfunction: "1",
          optype: "40",
          signmode: 4,
          open: "admin|manager",
          check: "manager",
          review: "manager",
          approve: "manager|admin",
          note: "測試註記測試註記",
          isvalid: true,
        },
        {
          UUID: "ageoprjjrjregjorgerfgr",
          opfunction: "2",
          optype: "12",
          signmode: 4,
          open: "admin",
          check: "manager|admin|manager",
          review: "admin",
          approve: "manager",
          note: "測試註記測試註記",
          isvalid: true,
        },
      ],
    };
    return setTimeout(() => resolve(data), 1000);
  });
};

export const getApsRun = () => {
  return new Promise((resolve, reject) => {
    const data = {
      status: true,
      msg: "取得簽核權限設定成功",
      data: [
        {
          UUID: "afjkldergjiopjqagjaeioprgjdfkl",
          opfunction: "1",
          optype: "40",
          signmode: 4,
          open: "admin|manager",
          check: "manager",
          review: "manager",
          approve: "manager|admin",
          note: "測試註記測試註記",
          isvalid: true,
        },
        {
          UUID: "ageoprjjrjregjorgerfgr",
          opfunction: "2",
          optype: "12",
          signmode: 4,
          open: "admin",
          check: "manager|admin|manager",
          review: "admin",
          approve: "manager",
          note: "測試註記測試註記",
          isvalid: true,
        },
      ],
    };
    return setTimeout(() => resolve(data), 1000);
  });
};
