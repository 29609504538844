import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";
// 取得廠商資料
export const getSuppHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppHD`, {
    params: data,
  });
};

// 取得聯絡人資料
export const getSuppTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppTB`, {
    params: data,
  });
};

// 取得幣別資料
export const getCurrency = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getCurrency`, {
    params: data,
  });
};

// 取得員工資料
export const getStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getStaff`, { params: data });
};
