import React from "react";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import SignOffState from "@/components/BPM/SignOffState";

const History = (props) => {
  const { c_lightData } = props;



  //第三元件 table加資料
  const tableData = [
    {
      progress: "審案",
      indexes: "2022-01-01",
      charge: "XXX",
      caseStatus: "立案",
      key: 1,
      flowF: "agree",
    },
    {
      progress: "退回",
      indexes: "2022-01-01",
      charge: "XXX",
      caseStatus: "立案",
      key: "2",
      flowF: "cancel",
    },
  ];
  //第三元件 table欄位
  const tableColumns = [
    {
      title: "進度", // 進度
      dataIndex: "progress",
      align: "center",
      width: "25%",
    },
    {
      title: "時標", //時標
      dataIndex: "indexes",
      width: "25%",
      align: "center",
    },
    {
      title: "負責人", // 負責人
      dataIndex: "charge",
      width: "25%",
      align: "center",
    },
    {
      title: "狀態", // 狀態
      dataIndex: "caseStatus",
      align: "center",
      width: "25%",
      render: (_, data) => (

        <SignOffState
          flowF={data.flowF}
          progress={data.progress}
          className={""}


        />
      ),
    },
  ];

  return (
    <div className="w-[95%] flex items-center flex-col">
      <h2 className="text-[#6584B4] my-6">簽核歷程</h2>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={tableData}
        pagination={false}
      // loading={call_getCustHd.status === "load"}
      // c_lightData={c_lightData}
      />
    </div>
  );
};

export default History;
