/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Row, Col, Drawer, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getProTB } from "@/service/apis/PMS/Pro";
// util
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getProTB = useAPI(getProTB);

  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;

  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getProTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getProTB;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getProTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"),
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "3%" : "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "16%" : "13%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      // title: "規格",
      title: t("PMS.util.pdtspec"),
      dataIndex: "pdtspec",
      align: "center",
      width: screens.xxl ? "14%" : "14%",
    },
    // {
    //   title: '計量單位',
    //   dataIndex: 'unit',
    //   align: 'center',
    //   width: screens.xxl ? '5%' : '5%',
    // },
    {
      // title: "採購數量",
      title: t("PMS.pro.demandqty"),
      dataIndex: "demandqty",
      align: "right",
      width: screens.xxl ? "5%" : "5%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "採購單位",
      title: t("PMS.pro.punit"),
      dataIndex: "punit",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
    },
    {
      // title: "採購單價(未稅)",
      title: t("PMS.pro.unitprice"),
      dataIndex: "unitprice",
      align: "right",
      width: screens.xxl ? "9%" : "9%",
      render: (text) => formatAmount(text),
    },

    {
      // title: "廠商確認數量",
      title: t("PMS.pro.confirmqty"),
      dataIndex: "confirmqty",
      align: "right",
      width: screens.xxl ? "7%" : "7%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      // title: "取消數量",
      title: t("PMS.pro.cancelqty"),
      dataIndex: "cancelqty",
      align: "right",
      width: screens.xxl ? "6%" : "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      // title: "退貨數量",
      title: t("PMS.pro.returnqty"),
      dataIndex: "returnqty",
      align: "right",
      width: screens.xxl ? "6%" : "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      // title: "單項稅額",
      title: t("PMS.pro.itemsumtax"),
      dataIndex: "itemsumtax",
      align: "right",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "含稅小計",
      title: t("PMS.pro.subtotal"),
      dataIndex: "subtotal",
      align: "right",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "3%" : "3%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={() => set_s_drawer({ show: true, data: rowData })} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#6A68A1]">
        {/* 總項數 */}
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("PMS.util.itemno_total")}: </span>
          <span className="self-center">{s_tableData.length}</span>
        </Col>
        {/* 未稅總金額 */}
        <Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("PMS.pro.sumtotal")}:</span>
          <span className="self-center">{formatAmount(lightData.sumtotal)}</span>
        </Col>
        {/* 加扣款項 */}
        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 加扣款項 */}
          <span className="self-center">{t("PMS.pro.charge")}:</span>
          <span className="self-center">{formatAmount(lightData.charge)}</span>
        </Col>
        {/* 總稅額 */}
        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("PMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(lightData.tax)}</span>
        </Col>
        {/* 含稅總金額 */}
        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("PMS.pro.total")}:</span>
          <span className="self-center">{formatAmount(lightData.total)}</span>
        </Col>
        {/* 含稅總金額(本幣) */}
        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("PMS.pro.local_total")}:</span>
          <span className="self-center">
            {" "}
            {formatAmount(lightData.total * lightData.exchangerate)}
          </span>
        </Col>
      </Row>

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 採購訂單明細 */}
            {t("PMS.pro.tb_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default TB;
