/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
// util component
import PNButton from "@/components/ERP/PNButton";
import { ConfirmButton, CancelButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
// import { addPlanTB, updatePlanTB } from "../../moke";
import { addPlanTB, updatePlanTB } from "@/service/apis/PMS/Plan";
const { Text } = Typography;

const TbModal = ({ call_getPlanTB, hd_values, s_disabled, s_tbModal, set_s_tbModal }) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addPlanTB = useAPI(addPlanTB);
  // useAPI 編輯表身
  const call_updatePlanTB = useAPI(updatePlanTB);
  const [form] = Form.useForm();

  // 儲存時執行
  const onFinish = async (values) => {
    // alert(123);
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    if (type === "create") {
      // step1 call 新建表身的API
      call_addPlanTB.request(values);
    }
    if (type === "edit") {
      // step1 call 編輯表身的API
      console.log(values);
      call_updatePlanTB.request(values);
    }
  };

  // 改變料號
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // note
      note: form.getFieldValue("note"),
    });
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    form.setFieldsValue({
      ...s_tbModal.data,
      opUUID: hd_values.opUUID,
    });
  }, []);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addPlanTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表身資料
      call_getPlanTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addPlanTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updatePlanTB;
    console.log("call_updatePlanTB = ", call_updatePlanTB);
    if (status === "suc") {
      message.success(msg);
      // call 更新表身資料
      call_getPlanTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updatePlanTB.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>
      </div>

      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("PMS.util.PN")}</Text>}
            name="PN"
            messageVariables={{ label: t("PMS.util.PN") }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("PMS.util.PN")}
              disabled={s_disabled.PN}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 品名 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("PMS.util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 規格 */}
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.pdtspec}>{t("PMS.util.pdtspec")}</Text>}
            name="pdtspec"
          >
            <Input
              disabled={s_disabled.pdtspec}
              placeholder={t("util.placeholder_select") + t("OMS.util.PN")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.attribute")}</Text>} name="attribute">
            <Select
              className="w-full"
              allowClear
              name="attribute"
              disabled
              options={[
                { label: t("util.attribute_0"), value: "0" },
                { label: t("util.attribute_1"), value: "1" },
                { label: t("util.attribute_2"), value: "2" },
                { label: t("util.attribute_3"), value: "3" },
                { label: t("util.attribute_4"), value: "4" },
                { label: t("util.attribute_5"), value: "5" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 商品類別 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.pdtclass")}</Text>} name="pdtclass">
            <Input disabled placeholder={t("util.placeholder_select") + t("util.PN")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 圖號 */}
          <Form.Item label={<Text disabled>{t("util.drawno")}</Text>} name="drawno">
            <Input disabled placeholder={t("util.placeholder_select") + t("util.PN")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 材料 */}
          <Form.Item label={<Text disabled>{t("util.matl")}</Text>} name="matl">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 報價數量 */}
          <Form.Item
            label={<Text disabled={s_disabled.demandqty}>{t("PMS.plan.demandqty")}</Text>}
            name="demandqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("PMS.plan.demandqty") }}
          >
            <InputNumber
              className="w-full"
              placeholder={t("util.placeholder_input") + t("PMS.plan.demandqty")}
              disabled={s_disabled.demandqty}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          {/* 註記 */}
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("util.note")}</Text>}
            name="note"
          >
            <Input disabled={s_disabled.tb_note} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          {/* 確定 */}
          <ConfirmButton htmlType="submit" disabled={s_disabled.tb_submit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
