import { useState, useEffect, useRef } from "react";
import { Row, Col, Input, InputNumber, Select, Form, message, Radio } from "antd";
import { useTranslation } from "react-i18next";

import { unitArr } from "@/pages/LMS/util/anyMap";
import useAPI from "@/hooks/useAPI";
import { getLabel } from "@/service/apis/LMS/LabelManage";

const initialValues = {
  width: 20,
  height: 20,
  unit: "mm",
  note: "",
  isvalid: false,
};

export default function FormHD(props) {
  const { form_hd_ref, c_lightData } = props;

  const { t } = useTranslation();

  const call_getLabel = useAPI(getLabel);

  const [s_paperOptions, set_s_paperOptions] = useState([]);

  useEffect(() => {
    call_getLabel.request();
  }, []);

  useEffect(() => {
    if (call_getLabel.status === "suc") {
      set_s_paperOptions(
        call_getLabel.data.map((x) => ({
          label: x.labelNM,
          value: x.labelID,
          data: x,
        }))
      );
    } else if (call_getLabel.status === "err") {
      message.error(call_getLabel.msg);
    }
  }, [call_getLabel.status]);

  return (
    <Form {...props} form={form_hd_ref} layout="vertical" initialValues={initialValues}>
      <Row gutter={[12, 0]}>
        <Col span={3}>
          <Form.Item label="標籤ID" name="modelID" rules={[{ required: true }]}>
            <Input disabled={Object.keys(c_lightData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤名稱" name="modelNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="版本號" name="ver">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="紙張快速帶入" name="paper">
            <Select
              onChange={(_, data) =>
                form_hd_ref.setFieldsValue({
                  width: data.data.width,
                  height: data.data.high,
                  unit: data.data.widthUnit,
                })
              }
              options={s_paperOptions}
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤寬度" name="width" rules={[{ required: true }]}>
            <InputNumber min={0.001} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤高度" name="height" rules={[{ required: true }]}>
            <InputNumber min={0.001} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="標籤單位" name="unit" rules={[{ required: true }]}>
            <Select options={unitArr} />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item label="是否啟用" name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label="備註" name="note">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
