import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { Col, Divider, Drawer, Grid, Modal, Row, Space, message } from "antd";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { deleteRunA3TB, getRunA3TB } from "@/service/apis/OMS/runA3";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalContainer from "./ModalContainer";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
// util
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const TB = ({ c_lightData, hdRef }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getRunA3TB = useAPI(getRunA3TB);
  const call_deleteRunA3TB = useAPI(deleteRunA3TB);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  // 取得表身資料
  useEffect(() => {
    call_getRunA3TB.request({ opUUID: c_lightData.opUUID });
  }, [c_lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getRunA3TB;
    // console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getRunA3TB.status]);

  useEffect(() => {
    const { status, msg } = call_deleteRunA3TB;
    if (status === "suc") {
      message.success(msg);
      call_getRunA3TB.request({ opUUID: c_lightData.opUUID });
    }
    if (status === "err") {
      message.error(msg);
      call_getRunA3TB.request({ opUUID: c_lightData.opUUID });
    }
  }, [call_deleteRunA3TB.status]);

  const removeData = (e, rowData) => {
    Modal.confirm({
      title: t("OMS.util.ord") + t("OMS.util.del_confirm"), // 銷貨單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.ord.removeData_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteRunA3TB.request(rowData),
    });
    e.stopPropagation();
  };
  const editData = (e, rowData) => {
    set_s_editData(rowData);
    set_s_isShowModal(true);
    e.stopPropagation();
  };

  const onCreate = () => {
    set_s_isShowModal(true);
  };

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "8%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "付款金额",
      dataIndex: "payment",
      align: "left",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: "註記",
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];
  const searchBasic = [<AddButton type="primary" key="create" onClick={onCreate} />];
  return (
    <div className="mt-12">
      <PageHeader title="订单收款明细" extra={searchBasic} />
      <CustomTable
        type="dark-purple"
        lightData={c_lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}订单收款明细`
            : `${t("util.add")}订单收款明细`
        }
        width={"30%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getRunA3TB.request({ opUUID: c_lightData.opUUID });
          hdRef.Call_hd();
        }}
      >
        <ModalContainer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </div>
  );
};

export default TB;
