import { ClearButton, CreateButton, TourButton } from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Input, Space } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

// AP
import { default as APHD } from "./AP/HD";
import { default as APMainPage } from "./AP/MainPage";
import { default as APTB } from "./AP/TB";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import PageHeader from "@/components/PageHeader";
// Pay
import { default as PayHD } from "./Pay/HD";
import { default as PayMainPage } from "./Pay/MainPage";
// strike
import { default as StrikeHD } from "./Strike/HD";
import { default as StrikeMainPage } from "./Strike/MainPage";
import { default as StrikeTB } from "./Strike/TB";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// 一階搜尋條件的起始值
const INIT_SEARCHCONFIG = {
  opcode: "pay",
  startDate: dayjs().startOf("year"), // 開始日期
  endDate: dayjs().endOf("month"), // 結束日期
  searchFlag: undefined, // 要搜尋的欄位名稱
  searchValue: undefined, // 要搜尋的欄位資料
};

export const DataContext = React.createContext();

const ApManage = () => {
  const { t } = useTranslation();
  const pay_ref = useRef(null);
  const ap_ref = useRef(null);
  const strike_ref = useRef(null);
  const { c_supps } = useContext(ERPContext);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();
  const [c_state, set_c_state] = useState({
    lightData: {}, // 要被查看的『表頭』資料
    mainPage: { show: false, data: {} }, // 是否要顯示新建、編輯『採購主檔』畫面的頁面
    tabKey: "pay", // CustomTabs 目前被選中的選項
    searchConfig: INIT_SEARCHCONFIG, // 要搜尋的資料(一階搜尋用)
    // detailSearch: { show: false, data: {} },
    Ns: [], // 用來做上一筆、下一筆的單號陣列
    INIT_SEARCHCONFIG: INIT_SEARCHCONFIG,
  });

  const placeholder_map = {
    N: t("PMS.util.placeholder_input") + t("PMS.util.N"), // 請輸入單號
    suppID: t("PMS.util.suppID_placeholder"), // 請選擇廠商
    lifeF: t("PMS.util.placeholder_select") + t("PMS.util.lifeF"), // 請選擇歷程
  };

  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "pay", label: t("PMS.util.pay") }, // 應付帳款查詢
    { key: "ap", label: t("PMS.util.ap") }, // 對帳作業
    { key: "strike", label: t("PMS.util.strike") }, // 付款沖帳
  ];

  const searchFlag_map = {
    pay: [
      { value: "N24", label: t("PMS.util.N24") },
      { value: "N23", label: t("PMS.util.N23") },
      { value: "N22", label: t("PMS.util.N22") },
    ],
    ap: [
      { value: "N", label: t("PMS.util.N") },
      { value: "suppID", label: t("PMS.util.suppalias") },
      { value: "lifeF", label: t("PMS.util.lifeF") },
    ],
    strike: [
      { value: "N", label: t("PMS.util.N") },
      { value: "suppID", label: t("PMS.util.suppalias") },
      { value: "lifeF", label: t("PMS.util.lifeF") },
    ],
  };

  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    suppID: c_supps.options,
    driID: [],
    lifeF: [
      { value: "0", label: t("PMS.ap-manage.AP_lifeF_0") }, //這裡的lifeF是前單的
      { value: "1", label: t("PMS.ap-manage.AP_lifeF_1") },
      { value: "2", label: t("PMS.ap-manage.releaseAP") },
      { value: "3", label: t("PMS.ap-manage.AP_lifeF_3") },
      { value: "T", label: t("PMS.ap-manage.AP_lifeF_T") },
    ],
  });

  // 用來更新 state
  const updateState = (partialState) => {
    set_c_state((prev) => ({ ...prev, ...partialState }));
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    console.log("activeKey = ", activeKey);
    updateState({
      tabKey: activeKey,
      searchConfig: INIT_SEARCHCONFIG,
      lightData: {},
    });
    clearSearch();
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...c_state.searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate:
        type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : c_state.searchConfig?.startDate,
      endDate:
        type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : c_state.searchConfig?.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue"
          ? value
          : type === "searchFlag"
          ? null
          : c_state.searchConfig?.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...c_state.searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : c_state.searchConfig?.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      // call_getInstockHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    // call_getInstockHD.request({
    //   ...searchConfig,
    //   opcode: tabKey,
    //   [searchConfig.searchFlag]: searchConfig.searchValue,
    // });

    if (c_state.tabKey === "pay") {
      pay_ref.current.Call_hd();
    }
    if (c_state.tabKey === "ap") {
      ap_ref.current.Call_hd();
    }
    if (c_state.tabKey === "strike") {
      strike_ref.current.Call_hd();
    }
    // 更新狀態
    updateState({ c_lightData: {} });
  };

  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...c_state.searchConfig,
      // opcode: c_state.tabKey,
      startDate: dayjs().startOf("year"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    // call_getInstockHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("tabkey");
    searchParams.delete("N");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (c_state.tabKey === "ap") {
      set_s_selectOptions({
        ...s_selectOptions,
        suppID: c_supps.options,
        lifeF: [
          { value: "0", label: t("PMS.ap-manage.AP_lifeF_0") },
          { value: "1", label: t("PMS.ap-manage.AP_lifeF_1") },
          { value: "2", label: t("PMS.ap-manage.releaseAP") },
          { value: "3", label: t("PMS.ap-manage.AP_lifeF_3") },
          { value: "T", label: t("PMS.ap-manage.AP_lifeF_T") },
        ],
      });
    }
    if (c_state.tabKey === "strike") {
      set_s_selectOptions({
        ...s_selectOptions,
        suppID: c_supps.options,
        lifeF: [
          { value: "0", label: t("PMS.ap-manage.Strike_lifeF_0") },
          { value: "1", label: t("PMS.ap-manage.Strike_lifeF_1") },

          { value: "T", label: t("PMS.ap-manage.Strike_lifeF_T") },
        ],
      });
    }
  }, [c_state.tabKey, c_supps]);

  useEffect(() => {
    const N = searchParams.get("N");
    const tabkey = searchParams.get("tabkey");
    if (N !== null) {
      updateState({
        tabKey: tabkey,
      });
    }
  }, []);

  return (
    <DataContext.Provider value={{ c_state, updateState }}>
      <div className="flex flex-col h-full">
        {c_state.mainPage.show && c_state.tabKey === "ap" && <APMainPage />}
        {c_state.mainPage.show && c_state.tabKey === "pay" && <PayMainPage />}
        {c_state.mainPage.show && c_state.tabKey === "strike" && <StrikeMainPage />}

        {!c_state.mainPage.show && (
          <div className="flex-1 flex flex-col">
            {/* 應付帳款管理 */}
            <PageHeader title={t("PMS.ap-manage.pageHeader")} />
            <CustomTabs
              items={tabsData} // CustomTabs 的所有選項
              activeKey={c_state.tabKey} // 目前亮起來的 Tab
              onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
              tabBarExtraContent={
                <Space>
                  <CustomRangePicker
                    // disabled={c_state.tabKey === "pay"}
                    value={[c_state.searchConfig?.startDate, c_state.searchConfig?.endDate]}
                    onCalendarChange={(dates, _, __) => {
                      updateState({
                        searchConfig: {
                          ...c_state.searchConfig,
                          startDate: dates[0],
                          endDate: dates[1],
                        },
                      });
                    }}
                  />

                  <Input.Group compact>
                    <CustomSelect
                      // disabled={c_state.tabKey === "pay"}
                      value={c_state.searchConfig?.searchFlag}
                      placeholder={t("PMS.util.searchField")}
                      onChange={(value) => handleChange("searchFlag", value)}
                      options={searchFlag_map[c_state.tabKey]}
                    />

                    {c_state.searchConfig?.searchFlag === "lifeF" ||
                    c_state.searchConfig?.searchFlag === "suppID" ? (
                      <CustomSelect
                        showSearch
                        disabled={c_state.tabKey === "detailSearch"}
                        value={c_state.searchConfig?.searchValue}
                        className="w-[250px]"
                        placeholder={placeholder_map[c_state.searchConfig?.searchFlag]}
                        onChange={(value) => handleChange("searchValue", value)}
                        // 根據searchFlag判斷現在是哪個下拉選單
                        options={s_selectOptions[c_state.searchConfig?.searchFlag]}
                      />
                    ) : (
                      <Input
                        // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "pay"}
                        value={c_state.searchConfig?.searchValue}
                        className="w-[250px]"
                        placeholder={placeholder_map[c_state.searchConfig?.searchFlag]}
                        onChange={(e) => handleChangeInput(e.target.value)}
                      />
                    )}
                    <CustomSearchButton
                      // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "pay"}
                      onClick={handleSearch}
                    />
                  </Input.Group>

                  {/* 清除搜詢紀錄 */}
                  <ClearButton
                    // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "pay"}
                    onClick={clearSearch}
                  />
                  {/* 新建 */}
                  <CreateButton
                    // disabled={c_state.tabKey === "detailSearch" || c_state.tabKey === "pay"}
                    onClick={() => {
                      c_state.tabKey === "pay"
                        ? pay_ref.current.onCreate()
                        : updateState({ mainPage: { show: true, data: {} } });
                    }}
                  />
                </Space>
              }
            />
            {c_state.tabKey === "pay" && <PayHD ref={pay_ref} />}
            {c_state.tabKey === "ap" && <APHD ref={ap_ref} />}
            {c_state.tabKey === "strike" && <StrikeHD ref={strike_ref} />}

            {Object.keys(c_state.lightData).length > 0 && (
              <>
                {c_state.tabKey === "ap" && <APTB />}
                {c_state.tabKey === "strike" && <StrikeTB />}
              </>
            )}
          </div>
        )}
      </div>
    </DataContext.Provider>
  );
};

export default ApManage;
