import { useState } from "react";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

/**
 * @name 據此創建條目
 * @param s_editData --原本資料
 * @param set_s_editData --set原本資料
 * @param form --如果有form回去更改原本資料
 */
const CreateFromItem = ({ s_editData, set_s_editData, form }) => {
  const { t } = useTranslation();
  const [s_copyItem, set_s_copyItem] = useState({});

  const changeState = () => {
    console.log(s_copyItem);
    if (Object.keys(s_copyItem).length === 0) {
      set_s_copyItem(s_editData);
      set_s_editData({});
    } else {
      form && form.setFieldsValue(s_copyItem);
      set_s_editData(s_copyItem);
      set_s_copyItem({});
    }
  };

  return (
    <Space>
      {Object.keys(s_copyItem).length > 0 ? (
        <Button type="primary" onClick={changeState} danger>
          {t("util.header_cancel_create")}
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={changeState}
          disabled={Object.keys(s_copyItem).length === Object.keys(s_editData).length}
        >
          {t("util.header_create")}
        </Button>
      )}
    </Space>
  );
};

export default CreateFromItem;
