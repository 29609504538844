/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Input, Divider, message, Radio } from "antd";
import { addOkItem, updateOkItem } from "@/service/apis/ADM/StringManage";
import useAPI from "@/hooks/useAPI";

const ModalContainer = ({ s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 新增停工項目
  const call_addOkItem = useAPI(addOkItem);
  // useAPI 編輯停工項目
  const call_updateOkItem = useAPI(updateOkItem);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addOkItem.request(values);
    }
    if (type === "edit") {
      call_updateOkItem.request(values);
    }
  };

  // useAPI 新增良品項目
  useEffect(() => {
    if (call_addOkItem.status === "suc") {
      message.success(call_addOkItem.msg);
      set_s_isShowModal(false);
    } else if (call_addOkItem.status === "err") {
      message.error(t(`errorCode.${call_addOkItem.msg}`));
    }
  }, [call_addOkItem.status]);

  // useAPI 編輯良品項目
  useEffect(() => {
    if (call_updateOkItem.status === "suc") {
      message.success(call_updateOkItem.msg);
      set_s_isShowModal(false);
    } else if (call_updateOkItem.status === "err") {
      message.error(t(`errorCode.${call_updateOkItem.msg}`));
    }
  }, [call_updateOkItem.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("util.itemID")} // 項目ID
            name="itemID"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("util.placeholder_input")}${t("util.itemID")}`}
              disabled={Object.keys(s_editData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("util.itemNM")} // 項目名稱
            name="itemNM"
            rules={[{ required: true }]}
          >
            <Input placeholder={`${t("util.placeholder_input")}${t("util.itemNM")}`} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("util.isvalid")} // 是否有效
            name="isvalid"
          >
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label={t("util.note")} // 註記
            name="note"
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder={`${t("util.placeholder_input")}${t("util.note")}`} />
          </Form.Item>
        </Col>

        <Divider className="m-0" />

        <Col span={24} className="flex justify-end gap-3">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
