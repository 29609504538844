import React, { useState } from "react";
import dayjs from "dayjs";
import HD from "./HD";
import TB from "./TB";
import MainPage from "./MainPage";

// 一階搜尋條件的起始值
const INIT_SEARCHCONFIG = {
  opcode: "1310",
  startDate: dayjs().startOf("month"), // 開始日期
  endDate: dayjs().endOf("month"), // 結束日期
  searchFlag: undefined, // 要搜尋的欄位名稱
  searchValue: undefined, // 要搜尋的欄位資料
};

export const DataContext = React.createContext();

export default function Pro() {
  const [c_state, set_c_state] = useState({
    lightData: {}, // 要被查看的『表頭』資料
    mainPage: { show: false, data: {} }, // 是否要顯示新建、修改『銷貨主檔』畫面的頁面
    tabKey: "1310", // CustomTabs 目前被選中的選項
    searchConfig: INIT_SEARCHCONFIG, // 要搜尋的資料(一階搜尋用)
    ord_Ns: [], // 用來做上一筆、下一筆的單號陣列
    INIT_SEARCHCONFIG: INIT_SEARCHCONFIG,
  });
  // 用來更新 state
  const updateState = (partialState) => {
    set_c_state((prev) => ({ ...prev, ...partialState }));
  };

  return (
    <DataContext.Provider value={{ c_state, updateState }}>
      <div className="flex flex-col h-full">
        {c_state.mainPage.show && <MainPage />}

        {!c_state.mainPage.show && (
          <div className="flex-1 flex flex-col">
            <HD />
            {Object.keys(c_state.lightData).length > 0 && <TB />}
          </div>
        )}
      </div>
    </DataContext.Provider>
  );
}
