import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { getMgmtcat, deleteMgmtcat } from "@/service/apis/ADM/manage";
import dayjs from "dayjs";

import useAPI from "@/hooks/useAPI";
import ModalConintaer from "./ModalContainer";

const AuthManage = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_tabKey, s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getMgmtcat = useAPI(getMgmtcat);
  const call_deleteMgmtcat = useAPI(deleteMgmtcat);

  const tableColumns = [
    {
      title: t("ADM.data-auth-manage.mgmtitem"),
      dataIndex: "mgmtitem",
      align: "center",
    },
    {
      title: t("util.itemNM"),
      dataIndex: "itemNM",
      width: "20%",
    },
    {
      title: t("util.updtT"),
      dataIndex: "updtT",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      width: "10%",
    },
    {
      title: t("util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
      width: "10%",
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      width: "40%",
      align: "left",
    },
    {
      title: t("util.action"),
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage onClick={() => remove(rowData)} />
        </Space>
      ),
    },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () =>
      call_getMgmtcat.request({
        pageType: "71",
        type: s_tabKey,
        ...s_searchData,
      }),
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const remove = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () =>
        call_deleteMgmtcat.request({
          ...rowData,
          pageType: "71",
          type: s_tabKey,
        }),
      cancelText: t("util.cancel"),
    });
  };

  const modalTitle =
    s_tabKey === "pdt"
      ? t("ADM.data-auth-manage.tabPdt")
      : s_tabKey === "supp"
      ? t("ADM.data-auth-manage.tabSupp")
      : s_tabKey === "cust"
      ? t("ADM.data-auth-manage.tabCust")
      : s_tabKey === "wh"
      ? t("ADM.data-auth-manage.tabWh")
      : "";

  useEffect(() => {
    call_getMgmtcat.request({
      pageType: "71",
      type: s_tabKey,
      ...s_searchData,
    });
  }, []);

  useEffect(() => {
    if (call_getMgmtcat.status === "suc") {
      message.success(call_getMgmtcat.msg);
      // 整理資料
      set_s_tableData(
        call_getMgmtcat.data.map((x) => ({
          ...x,
          key: x.mgmtitem,
        }))
      );
    } else if (call_getMgmtcat.status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${call_getMgmtcat.msg}`));
    }
  }, [call_getMgmtcat.status]);

  useEffect(() => {
    if (call_deleteMgmtcat.status === "suc") {
      message.success(call_deleteMgmtcat.msg);
    } else if (call_deleteMgmtcat.status === "err") {
      message.error(t(`errorCode.${call_deleteMgmtcat.msg}`));
    }
    call_getMgmtcat.request({
      pageType: "71",
      type: s_tabKey,
      ...s_searchData,
    });
  }, [call_deleteMgmtcat.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getMgmtcat.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${modalTitle}`
            : `${t("util.add")}${modalTitle}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getMgmtcat.request({
            pageType: "71",
            type: s_tabKey,
            ...s_searchData,
          });
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_tabKey={s_tabKey}
        />
      </CustomModal>
    </>
  );
});

export default AuthManage;
