import {
	BgColorsOutlined,
	FontSizeOutlined,
	GlobalOutlined,
	MenuFoldOutlined,
} from "@ant-design/icons";
import { Button, Layout, Select, Space, Switch, Typography, theme } from "antd";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import Cookies from "js-cookie";
// component
import Countrys from "./Countrys";
import FontIcon from "./FontIcon";
import SystemContext from "../Context/SystemContext";
import dayjs from "dayjs";
import styled from "styled-components";
import { t } from "i18next";

const Line = styled.span`
  position: relative;
  width: 10px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    width: 1px;
    background-color: #d9d9d9;
  }
`;

const { Title } = Typography;
const { Header } = Layout;
const { useToken } = theme;

const MyHeader = (props) => {
	const location = useLocation();
	const navigate = useNavigate();

	const { token } = useToken();
	const { c_systemConfig, set_c_systemConfig, c_userData } = useContext(SystemContext);

	const change_sider_state = (e) => {
		stop_propagation(e);
		if (props.navbarState === "close" || props.navbarState === "shrinkClose") {
			props.setNavbarState("open");
		} else {
			props.setNavbarState("close");
		}
	};

	const headerStyle = {
		backgroundColor: Cookies.get("plantID") === "dev" ? "#EAB308" : token.colorHeaderBg,
	};

	const headerTextStyle = {
		color: Cookies.get("plantID") === "dev" ? "#FFF" : token.colorHeaderText,
	};

	const stop_propagation = (e) => {
		e?.nativeEvent.stopImmediatePropagation();
	};

	const handlerSystemConfigChange = (type, value) => {
		console.log({ type, value });
		// return;
		set_c_systemConfig((prev) => {
			prev[type] = value;
			return { ...prev };
		});
	};

	useEffect(() => {
		const plantID = Cookies.get("plantID");
		!plantID && navigate("/login");
	}, []);

	useEffect(() => {
		const closeSider = () => {
			props.setNavbarState("close");
		};
		if (props.navbarState === "open") {
			window.addEventListener("click", closeSider);
		}
		return () => {
			window.removeEventListener("click", closeSider);
		};
	}, [change_sider_state]);

	return (
		<Header className="shadow-lg flex px-4" style={headerStyle}>
			<Space>
				<span className="text-3xl flex">
					<MenuFoldOutlined
						className="cursor-pointer"
						onClick={change_sider_state}
						style={headerTextStyle}
					/>
				</span>
				<Title level={3} className="m-0 font-normal" style={headerTextStyle}>
					兆科检验中心
				</Title>
			</Space>
			<Space className="flex-1 justify-end items-center">
				<Title level={4} className="m-0 px-2 font-normal" style={headerTextStyle}>
					{c_userData.peopleNM}
				</Title>
				<Line />
				<Title level={4} className="m-0 px-2 font-normal" style={headerTextStyle}>
					{dayjs().format("YYYY-MM-DD")}
				</Title>
				<Line />
				<Title level={4} className="m-0 px-2 font-normal" style={headerTextStyle}>
					{/* {Cookies.get("plantNM")} */}
					{/* {"TEST_" + Cookies.get("plantID")} */}
					兆科检测
				</Title>
				<Line />
				{/* <FontSizeOutlined />
        <Line /> */}
				<FontIcon
					c_systemConfig={c_systemConfig}
					handlerSystemConfigChange={handlerSystemConfigChange}
				/>
				{/* <Line /> */}
				{/* <Select
          onChange={(e) => handlerSystemConfigChange("size", e)}
          value={c_systemConfig.size}
          options={[
            { value: "large", label: t("util.size_large") },
            { value: "middle", label: t("util.size_middle") },
            { value: "small", label: t("util.size_small") },
          ]}
        /> */}
				{/* <BgColorsOutlined />
        <Select
          onChange={(e) => handlerSystemConfigChange("theme", e)}
          value={c_systemConfig.theme}
          options={[{ value: "default", label: "預設" }]}
        />
        <Line /> */}
				{/* <GlobalOutlined />
        <Select
          className="w-[200px]"
          value={c_systemConfig.locale}
          onChange={(e) => handlerSystemConfigChange("locale", e)}
          options={[
            { value: "zh-TW", label: "zh-TW | 繁中" },
            { value: "zh-CN", label: "zh-CN | 简中" },
            { value: "en-US", label: "en-US | English" },
            { value: "vi-VN", label: "vi_VN | Tiếng Việt" },
          ]}
        /> */}
				{/* <Countrys
          locale={c_systemConfig.locale}
          handlerSystemConfigChange={handlerSystemConfigChange}
        /> */}
			</Space>
		</Header>
	);
};

export default MyHeader;
