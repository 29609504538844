import React, { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Typography,
  Input,
  InputNumber,
  Divider,
  Button,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
//component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { MainPageContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { addOrdADD, updateOrdADD } from "@/service/apis/OMS/Ord";

const { Text } = Typography;

const AddbModal = ({ s_tbModal, set_s_tbModal }) => {
  const { t } = useTranslation();
  const { call_getOrdADD, hd_values, s_Adds, s_disabled } = useContext(MainPageContext);
  const [form] = Form.useForm();
  // useAPI 新增加扣款項
  const call_addOrdADD = useAPI(addOrdADD);
  // useAPI 編輯加扣款項
  const call_updateOrdADD = useAPI(updateOrdADD);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    if (type === "create") {
      // step1 call API新建加扣款項
      call_addOrdADD.request(values);
    }

    if (type === "edit") {
      // step1 call API更新加扣款項
      call_updateOrdADD.request(values);
    }
  };

  // 改變下拉選單
  const changeAddb = (addb) => {
    form.setFieldsValue({
      statement: addb,
    });
  };

  // 第一次渲染賦值
  useEffect(() => {
    const formValues = {
      ...s_tbModal.data,
      addtype: "addb",
      opUUID: hd_values.opUUID,
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 新增提款註記
  useEffect(() => {
    const { status, msg } = call_addOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addOrdADD.status]);

  // useAPI 編輯提款註記
  useEffect(() => {
    const { status, msg } = call_updateOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateOrdADD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="addtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="selectstatement">
            <Select
              onChange={changeAddb}
              options={s_Adds.addb}
              placeholder={t("util.util.placeholder_select") + t("OMS_ori.ord.addb")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="statement"
            label={
              <Text disabled={s_disabled["addb_statement"]}>{t("OMS_ori.ord.statement")}</Text>
            }
          >
            <Input
              disabled={s_disabled["addb_statement"]}
              placeholder={t("util.util.placeholder_select") + t("OMS_ori.ord.statement")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="amount"
            label={<Text disabled={s_disabled["addb_amount"]}>{t("OMS_ori.ord.amount")}</Text>}
          >
            <InputNumber
              disabled={s_disabled["addb_amount"]}
              className="w-full"
              placeholder={t("util.util.placeholder_input") + t("OMS_ori.ord.amount")}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddbModal;
