/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Typography,
  Select,
  InputNumber,
  Modal,
  Button,
  message,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import qs from "qs";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import PageHeader from "@/components/PageHeader";
import ContactButton from "@/components/ERP/PMS/ContactButton";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
import { useTranslation } from "react-i18next";
// util
import { isValidDate } from "@/util/Vaild";
// component
import TbContainer from "./Details/TbContainer";
import TakeApModal from "./Modal/TakeApModal";
// context
import { DataContext } from "../../index";
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getCustTb } from "@/service/apis/ADM/client";
import { getStrikeHD, getStrikeTB, addStrikeHD, updateStrikeHD } from "@/service/apis/PMS/strike";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  takeAp: true, // 引用對帳單
  suppID: true,
  contact: true,
  tel: true,
  apSearch: true, // 對帳資料查詢
  addr: true,
  strikeCheck: true, // 沖帳覆核
  confirmStrike: true, // 確認沖帳
  // ----- tb -----
  createBtn: true,
  tb_delete: true,
  tb_submit: true,
  paytype: true,
  paytotal: true,
  payT: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const call_getCustTb = useAPI(getCustTb);
  // useAPI 取得表頭資料
  const call_getStrikeHD = useAPI(getStrikeHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getStrikeHD = useAPI(getStrikeHD);
  // useAPI 更新表身
  const call_getStrikeTB = useAPI(getStrikeTB);
  // useAPI 取得上一筆資料
  const call_prev_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 取得下一筆資料
  const call_next_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 新增表頭
  const call_finish_addStrikeHD = useAPI(addStrikeHD);
  // useAPI 儲存表頭
  const call_finish_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 沖帳覆核
  const call_check_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 沖帳確認
  const call_confirmStrike_updateStrikeHD = useAPI(updateStrikeHD);
  const [form] = Form.useForm();
  const f_suppID = Form.useWatch("suppID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  // 廠商資料、人員資料
  const { c_supps } = useContext(ERPContext);
  const { c_state, updateState } = useContext(DataContext);
  const { mainPage, Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  const [is_showTake, set_is_showTake] = useState(false);
  const [s_custTbOptions, set_s_custTbOptions] = useState([]);

  // 據此創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      opUUID: "",
      N27: "",
      sumtotal: 0, // 總項金額
      tax: 0, //  總項稅額
      lifeFT: dayjs(),
      createT: dayjs(),
    };

    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    change_MainPage_disabled(new_Hd_Data);

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 表身資料需要手動引用
    set_s_tbData([]);
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);
    const custTb_obj = s_custTbOptions.find((item) => item.value === value);
    if (supp_obj) {
      form.setFieldsValue({
        suppID: supp_obj.suppID,
        suppalias: supp_obj.suppalias,
        contact: null, // 選擇新廠商時要清空業務窗口資料
        tel: null, // 選擇新廠商時要清空業務窗口資料
      });
    } else {
      form.setFieldsValue({
        suppID: custTb_obj.value,
        suppalias: custTb_obj.label,
        addr: custTb_obj.label,
        contact: null, // 選擇新廠商時要清空業務窗口資料
        tel: null, // 選擇新廠商時要清空業務窗口資料
      });
    }
  };

  // 沖帳覆核 or 取消覆核
  const strikeCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "1" ? "取消沖帳覆核確認" : "沖帳覆核確認"}`,
      title: (f_lifeF === "1" ? t(`PMS.util.cancel`) : "") + t("PMS.ap-manage.strikeCheck_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content:
        f_lifeF === "1"
          ? t("PMS.ap-manage.cancel_strikeCheck_confirm_text")
          : t("PMS.ap-manage.strikeCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_check_updateStrikeHD.request({
          ...values,
          lifeF: f_lifeF === "0" ? "1" : "0",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 確認沖帳
  const confirmStrike = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      title: t("PMS.ap-manage.confirmStrike_confirm"), // 沖帳確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "確認沖帳後此沖帳單將結案，請確認是否沖帳",
      content: t("PMS.ap-manage.confirmStrike_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_confirmStrike_updateStrikeHD.request({
          ...values,
          lifeF: "T",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 查詢對帳單
  const go_ap = () => {
    const params_obj = {
      tabkey: "ap",
      N: mainPage.data?.N27,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/ap-manage?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      // "目前資料為第一筆，已無上一筆資料"
      message.success(t("PMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateStrikeHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === Ns.length) {
      // "目前資料為最後一筆，已無下一筆資料"
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateStrikeHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = Ns.findIndex((item) => item === N);

    return index;
  };

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addStrikeHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateStrikeHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有對帳單
    const apSearch = hd_values.N27?.length > 0 ? false : true;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: isNewdoc ? false : true,
        suppID: isNewdoc ? false : true,
        addr: false,
        takeAp: !apSearch, // 引用對帳資料
        apCheck: false,
        note: false,
        contact: false,
        tel: false,
        itemNM: false,
        strikeCheck: false, // 沖帳覆核
        goAp: apSearch, // 對帳資料查詢
        createBtn: apSearch,
        // ----- tb -----
        paytype: false, // 付款方式
        paytotal: false, // 付款金額
        payT: false, // 付款時間
        tb_note: false,
        tb_submit: false,
        tb_delete: false,
      });
    }

    // 沖帳覆核
    if (hd_values?.lifeF === "1") {
      set_s_disabled({
        ...INIT_DISABLED,
        contact: false,
        tel: false,
        addr: false,
        apSearch: apSearch, // 對帳資料查詢
        strikeCheck: false, // 沖帳覆核
        confirmStrike: false, // 確認沖帳
      });
    }

    // 完成沖帳
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        apSearch: apSearch, // 對帳資料查詢
      });
    }
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建對帳單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        createT: dayjs(),
        opcode: "2810",
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getStrikeTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    call_getCustTb.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getCustTb;
    if (status === "suc") {
      console.log("data = ", data);
      const options = data
        .filter((item) => item.isvalid)
        .map((item) => {
          // return { value: item.custID, label: `${item.custID}_${item.custalias}` };
          return { value: item.itemUUID, label: `${item.contact}` };
        });
      console.log("options = ", options);
      set_s_custTbOptions(options);
    }
    if (status === "err") {
      message.error(msg);
      set_s_custTbOptions([]);
    }
  }, [call_getCustTb.status]);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getStrikeHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getStrikeHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getStrikeTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getStrikeTB.status]);

  // 新增對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addStrikeHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getStrikeHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getStrikeTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addStrikeHD.status]);

  // 編輯對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateStrikeHD;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getStrikeHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getStrikeTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateStrikeHD.status]);

  // useAPI 沖帳覆核
  useEffect(() => {
    const { status, data, msg } = call_check_updateStrikeHD;
    if (status === "suc") {
      // "沖帳覆核成功" : "取消覆核成功"
      message.success(
        data.lifeF === "1"
          ? t("PMS.ap-manage.strikeCheck") + t("util.success")
          : t("util.cancel") + t("PMS.ap-manage.strikeCheck")
      );
      // 更新表頭資料
      call_getStrikeHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_check_updateStrikeHD.status]);

  // useAPI 完成沖帳
  useEffect(() => {
    const { status, data, msg } = call_confirmStrike_updateStrikeHD;
    if (status === "suc") {
      // "完成沖帳"
      message.success(t("PMS.ap-manage.Strike_lifeF_T"));
      // 更新表頭資料
      call_getStrikeHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_confirmStrike_updateStrikeHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateStrikeHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // "取得上一筆資料成功"
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getStrikeHD.request({ N: Ns[nowIndex - 1] });
    }

    if (status === "err") {
      // "取得上一筆資料失敗"
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateStrikeHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateStrikeHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // "取得下一筆資料成功"
      message.success(t("PMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getStrikeHD.request({ N: Ns[nowIndex + 1] });
    }

    if (status === "err") {
      // "取得下一筆資料失敗"
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateStrikeHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getStrikeHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getStrikeTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getStrikeHD.status]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* 編輯沖帳單、新增沖帳單 */}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("PMS.util.strike")
                  : t("util.add") + t("PMS.util.strike")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            {/* <TourButton /> */}
          </Col>
        </Row>

        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>
        </div>

        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("PMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("PMS.util.opcode_placeholder")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("PMS.util.opcode_2810"), value: "2810" }, // 庫房退出
                ]}
              />
            </Form.Item>
          </Col>

          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 對帳單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N27")}</Text>} name="N27">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 各項稅額、沖帳總金額 */}
          <Col span={editMode ? 5 : 6} className="flex gap-2">
            {/* 各項稅額 */}
            {/* <Form.Item
              label={<Text disabled>{t("PMS.util.tax")}</Text>}
              name="tax"
              className="flex-1"
            >
              <InputNumber className="w-full" disabled />
            </Form.Item> */}
            {/* 沖帳總金額 */}
            <Form.Item
              label={<Text disabled>{t("PMS.ap-manage.strike_sumtotal")}</Text>}
              name="sumtotal"
              className="flex-1"
            >
              <InputNumber className="w-full" disabled />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-blue"
                disabled={s_disabled.takeAp}
                onClick={() => set_is_showTake(true)}
              >
                {/* 引用對帳單 */}
                {t("PMS.ap-manage.takeAp")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="suppNM"
              label={<Text disabled={s_disabled.suppID}>{t("PMS.util.suppNM")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.suppNM") }}
            >
              <Select
                showSearch
                disabled={s_disabled.suppID}
                placeholder={t("util.placeholder_select") + t("PMS.util.suppNM")}
                onChange={onSuppChange}
                options={c_supps.options.concat(s_custTbOptions)}
                filterOption={(input, option) => {
                  return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                }}
              />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.suppID")}</Text>} name="suppID">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {/* 創建人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.createID")}</Text>} name="createID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("PMS.util.accord")}
                {t("PMS.util.create")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 聯絡窗口資訊 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item
              shouldUpdate
              label={<Text disabled={s_disabled.contact}>{t("PMS.ap-manage.contactWindow")}</Text>}
            >
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      suppID={form.getFieldValue("suppID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          contact: contact_obj?.contact,
                          tel: contact_obj?.tel,
                        });
                      }}
                      title={t("PMS.ap-manage.chooseWindow")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("PMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="contact" className="flex-1" shouldUpdate>
                      <Input
                        disabled={s_disabled.contact}
                        className="flex-1"
                        placeholder={
                          f_suppID
                            ? t("PMS.pro.dealcontact_placeholder")
                            : t("PMS.util.suppID_placeholder")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("PMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="tel" className="flex-1">
                      <Input
                        disabled={s_disabled.tel}
                        placeholder={
                          f_suppID
                            ? t("PMS.util.tel_placeholder")
                            : t("PMS.util.suppID_placeholder")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 覆核人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.confirmID")}</Text>} name="confirmID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 覆核日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.confirmT")}</Text>} name="confirmT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-blue"
                disabled={s_disabled.apSearch}
                onClick={go_ap}
              >
                {/* 對帳資料查詢 */}
                {t("PMS.util.apSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 聯絡地址 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              name="addr"
              label={<Text disabled={s_disabled.addr}>{t("PMS.ap-manage.addr")}</Text>}
            >
              <Input disabled={s_disabled.addr} placeholder={t("PMS.ap-manage.addr_placeholder")} />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                disabled
                options={[
                  { label: t("PMS.ap-manage.Strike_lifeF_0"), value: "0" },
                  { label: t("PMS.ap-manage.Strike_lifeF_1"), value: "1" },
                  { label: t("PMS.ap-manage.Strike_lifeF_T"), value: "T" },
                ]}
              />
            </Form.Item>
          </Col>
          {/* 歷程時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.lifeFT")}</Text>} name="lifeFT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              {/* 沖帳覆核 */}
              <Button
                className={`h-full flex-1 ${f_lifeF === "1" ? "pms-btn-orange" : "pms-btn-purple"}`}
                // 必須有表身才可以覆核
                disabled={s_tbData.length > 0 ? s_disabled.strikeCheck : true}
                onClick={strikeCheck}
              >
                {/* {f_lifeF === "1" ? "取消覆核" : "沖帳覆核"} */}
                {f_lifeF === "1"
                  ? t("PMS.ap-manage.cancel_apCheck")
                  : t("PMS.ap-manage.strikeCheck")}
              </Button>
              {/* 確認沖帳 */}
              <Button
                className="h-full flex-1 pms-btn-purple"
                // 必須有表身才可以覆核
                disabled={s_disabled.confirmStrike}
                onClick={confirmStrike}
              >
                {/* 確認沖帳 */}
                {t("PMS.ap-manage.confirmStrike")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        call_getStrikeTB={call_getStrikeTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
        hd_values={form.getFieldsValue()}
      />

      {/* 引用對帳單 */}
      <CustomModal
        width="80%"
        title={t("PMS.ap-manage.takeAp")} // 引用對帳單
        open={is_showTake}
        onCancel={() => set_is_showTake(false)}
      >
        {is_showTake ? (
          <TakeApModal
            set_is_showTake={set_is_showTake}
            hd_values={form.getFieldsValue()}
            call_getStrikeHD={call_getStrikeHD}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
