import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import { AddButton,SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

import AuthManage from "./AuthManage";

export default function DataAuthManage() {
  const { t } = useTranslation();

  // 利用ref 子傳父
  const authManage_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    item: null,
  });
  const [s_tabKey, set_s_tabKey] = useState("pdt"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      item: undefined,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });

    // 清除時要重新取得表頭資料
    if (value === "") {
      authManage_ref.current.onSearch();
    }
  };

  const onSearch = () => {
    authManage_ref.current.onSearch();
  };
  const onCreate = () => {
    authManage_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "pdt",
      label: t("ADM.data-auth-manage.tabPdt"),
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "supp",
      label: t("ADM.data-auth-manage.tabSupp"),
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "cust",
      label: t("ADM.data-auth-manage.tabCust"),
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "wh",
      label: t("ADM.data-auth-manage.tabWh"),
      children: (
        <AuthManage
          ref={authManage_ref}
          s_searchData={s_searchData}
          s_tabKey={s_tabKey}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("item", e.target.value)}
      placeholder={t("util.search") + t("ADM.data-auth-manage.mgmtitem")}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,
    <SearchButton type="primary" key="search" onClick={onSearch}/>,

    <AddButton type="primary" key="create" onClick={onCreate}/>
  ];

  return (
    <>
      <PageHeader title={t("ADM.data-auth-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
