/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Space, Drawer, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getOutstockTB } from "@/service/apis/OMS/Outstock";
// util
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  // useAPI 取得表身
  const call_getOutstockTB = useAPI(getOutstockTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getOutstockTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getOutstockTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getOutstockTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "20%",
    },

    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.drawno"), // 圖號
      dataIndex: "drawno",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.outstock.punit"), // 進料單位
      dataIndex: "punit",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.outstock.indqty"), // 發貨數量
      dataIndex: "indqty",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.outstock.goqty"), // 驗收數量
      dataIndex: "goqty",
      align: "right",
      width: "6%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "rgb(194, 214, 244)",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.outstock.rejectqty"), // 驗退數量
      dataIndex: "rejectqty",
      align: "right",
      width: "6%",
      className: "bg-[#C2D6F4]",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={() => set_s_drawer({ show: true, data: rowData })} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 發貨單明細 */}
            {t("OMS.outstock.tb_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default TB;
