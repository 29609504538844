import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, Button, Typography, Space, Spin, DatePicker } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from '@/util/format' //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButton";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import { getDiff } from "@/service/apis/PMS/Pro";
import useAPI from "@/hooks/useAPI";

const { Text } = Typography;
const { RangePicker } = DatePicker;


//採購價格調幅表
const Diff = () => {

  const { c_supps, c_PNs, c_currencys } = useContext(ERPContext);  //從ERPContext拿到廠商資料

  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料

  const [is_spin, set_is_spin] = useState(false)

  const call_getDiff = useAPI(getDiff);
  const { t } = useTranslation();
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    suppID: [], //廠商ID
    PN: "", //料號
    currencytype: 'USD',
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 採購日期(起)
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 採購日期(迄)
  });

  const tableColumns = [
    {
      title: "廠商代號",
      dataIndex: "suppID",
      align: "center",
      width: '7%',
    },
    {
      title: "廠商簡稱",
      dataIndex: "suppalias",
      align: "left",
      width: '10%',
    },
    {
      title: "採購單號",
      dataIndex: "N",
      align: "center",
      width: '7%',
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
      width: '10%'
    },
    {
      title: "規格",
      dataIndex: "pdtspec",
      align: "left",
      width: '7%'
    },
    {
      title: "幣別",
      dataIndex: "currencytype",
      align: "center",
      width: '5%'
    },
    {
      title: "採購單位",
      dataIndex: "punit",
      align: "center",
      width: '5%',
      render: (text) => text === undefined ? null : text
    },
    {
      title: "採購人員",
      dataIndex: "driID",
      align: "center",
      width: '7%'
    },
    // {
    //   title: "前次未稅單價",
    //   dataIndex: "lastPrice",
    //   align: "right",
    //   width: "7%",
    //   render: (text) => formatAmount(text),
    // },
    {
      title: "未稅單價",
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: "調幅",
      dataIndex: "diff",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}%</div>)
    },
    {
      title: "採購日期",
      dataIndex: "proT",
      align: "center",
      width: '8%',
      render: (text) => dayjs(text).format("YYYY-MM-DD")
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    // console.log("attr = ", attr, "value = ", value);
    // let tmp = { ...s_searchData, [attr]: value };
    // set_s_searchData(tmp);
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = { ...s_searchData, suppID: s_searchData.suppID.join("|") }; //要將廠商陣列轉成字串，用逗號隔開
    console.log("tmp", tmp);
    call_getDiff.request(tmp);
  }

  useEffect(() => {
    console.log("c_currencys == ", c_currencys);
  }, [])

  //所有採購調幅資料
  useEffect(() => {
    const { status, msg, data } = call_getDiff;
    if (status === "suc") {
      console.log("call_getDiff = ", data);
      set_s_TableData(data);
      message.success(msg);
    } else if (status === "err") {
      set_s_TableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getDiff.status]);

  useEffect(() => {
    if (call_getDiff.status === 'load') {
      set_is_spin(true)
    } else {
      set_is_spin(false)
    }
  }, [call_getDiff.status])

  //----------------------------EXCEL---------------------------------------------------

  const exportToExcel = () => {
    console.log("s_TableData === ", s_TableData);

    const excelTable = s_TableData.map((item) => {
      //移除s_TableData createT
      if ('createT' in item) {
        delete item.createT;
      }
      //diff加上百分比符號，及數字分位
      return { ...item, unitprice: formatAmount(item.unitprice), lastPrice: formatAmount(item.lastPrice), diff: item.diff + '%', proT: dayjs(item.proT).format("YYYY-MM-DD") }
    })

    const ws = XLSX.utils.json_to_sheet(excelTable, { header: ["suppID", "suppalias", "N", "PN", "pdtNM", "pdtspec", "currencytype", "punit", "driID", "lastPrice", "unitprice", "diff", "proT"] });   //要轉出excel的資料
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    // 计算每列的最大宽度
    const colWidths = s_TableData[0] && Object.keys(s_TableData[0]).map(() => 0);
    s_TableData.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        console.log("cellValue",cellValue);
        console.log("cellValue.length",cellValue.length);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length ;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map(w => ({ wch: w + 10 })); // 根据最大长度调整列宽
    ws['!cols'] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }

    const today = dayjs().format("YYYY-MM-DD");

    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `採購價格調幅表${today}.xlsx`);

    console.log("excelTable === ", excelTable);

  };

  //----------------------------EXCEL---------------------------------------------------


  return (
    <div className="flex flex-col flex-1 w-full">

      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4" />

      {/*表頭*/}

      <PageHeader title="採購價格調幅表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">

        {/*導出excel*/}


        <Button
          type="primary"
          onClick={() => exportToExcel()}
        >
          導出excel
        </Button>


        <Text>廠商：</Text>

        {/*廠商選擇*/}
        <Select
          className="min-w-[200px]"
          name="suppID"
          mode="multiple"
          showSearch
          placeholder="請選擇廠商"
          onChange={(value) => handle_SearchData_Change("suppID", value)}
          options={[...c_supps.options, { label: '全部', value: '' }]}
          defaultValue={''}
        />

        <PNButton />


        <Text>料號：</Text>
        <Select
          name='PN'
          className="min-w-[300px]"
          showSearch
          placeholder="請填寫料號"
          onChange={(value) => { handle_SearchData_Change("PN", value) }}
          options={c_PNs.options}
        />

        <Text>幣別：</Text>
        <Select
          name='currencytype'
          className="min-w-[300px]"
          showSearch
          placeholder="請選擇幣別"
          onChange={(value) => { handle_SearchData_Change("currencytype", value) }}
          options={c_currencys.options}
          defaultValue={s_searchData.currencytype}
        />

        {/*採購日期起、迄*/}
        <RangePicker
          // allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />


        <Button type="primary" onClick={handleClick}>
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};


export default Diff