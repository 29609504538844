/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, message, Space, Button } from "antd";
import { ExclamationCircleOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconConfirm } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { deleteTb, confirm } from "@/service/apis/WMS/WMS";
import { getrun8090Tb } from "@/service/apis/WMS/ProInstock";
import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData, hdRef }) => {
  const { t } = useTranslation();
  // useAPI 取得表身資料
  const call_getrun8090Tb = useAPI(getrun8090Tb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

  const [s_editData, set_s_editData] = useState({});

  const tableColumns = [
    {
      title: t("util.SN"), // 序
      dataIndex: "itemno",
      align: "center",
    },
    {
      title: t("util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("util.whID"), // 倉別
      dataIndex: "whID",
      align: "center",
      render: (_, record) => `${record.whID} -${record.whNM}`,
    },
    {
      title: t("util.idmtype"), // 過帳型態
      dataIndex: "idmtype",
      align: "center",
      render: (text, _, __) => {
        const keyMap = {
          S: t("util.idmtype_s"),
          D: t("util.idmtype_d"),
          M: t("util.idmtype_m"),
        };
        return keyMap[text];
      },
    },
    {
      // title: opcodeBatchnoMap[c_lightData.opcode],
      title: t(`WMS.util.batchno_${c_lightData.opcode}`),
      dataIndex: "batchno",
      align: "center",
    },
    {
      title: t("WMS.util.planqty"), // 計畫數量
      dataIndex: "planqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    {
      title: t("WMS.util.realqty"), // 實際數量
      dataIndex: "realqty",
      align: "right",
      render: (text) => formatAmount(text),
    },

    {
      title: "操作",
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => {
        return (
          <Space className="flex-center font-size-24">
            <IconPen onClick={() => editData(rowData)} disabled={c_lightData.lifeF !== "0"} />

            {/* <IconGarbage onClick={() => deleteData(rowData)} /> */}
          </Space>
        );
      },
    },
  ];

  const editData = (rowData) => {
    set_s_editData(rowData);
    set_s_showModal(true);
  };

  // 關閉明細資料
  const handleClose = () => {
    set_c_lightData({});
  };

  useEffect(() => {
    call_getrun8090Tb.request(c_lightData);
  }, [c_lightData]);

  // 取得表身資料
  useEffect(() => {
    if (call_getrun8090Tb.status === "suc") {
      set_s_tableData(call_getrun8090Tb.data.map((x, i) => ({ ...x, key: i, SN: i + 1 })));

    } else if (call_getrun8090Tb.status === "err") {
      set_s_tableData([]);
      message.error(call_getrun8090Tb.msg);
    }
  }, [call_getrun8090Tb.status]);

  return (
    <>
      <PageHeader
        title={t(`WMS.util.opcode_${c_lightData.opcode}`) + " " + t(`util.detail`)}
        extra={[
          <Button key="hidden" danger icon={<FullscreenExitOutlined />} onClick={handleClose}>
            {t("util.hide_detail")}
          </Button>,
        ]}
      />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        tbMode={Object.keys(c_lightData).length > 0}
        pagination={false}
      />

      <CustomModal
        width="60%"
        title={t(`util.edit`) + t(`WMS.util.opcode_${c_lightData.opcode}`) + t(`util.detail`)}
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          hdRef.Call_hd();
        }}
      >
        <ModalContainer
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
          c_lightData={c_lightData}
        />
      </CustomModal>
    </>
  );
};

export default TB;
