import React, { useContext } from "react";
import { QRCode } from "antd";
import dayjs from "dayjs";
import { PrintText } from "./index";
import { ERPContext } from "@/components/Context/SystemContext";
const Footer = (props) => {
  const { hd_values, currentPage, totalPages } = props;
  const { c_custs, c_staffs } = useContext(ERPContext);

  return (
    <>
      {currentPage === totalPages - 1 && (
        <>
          <div className="flex flex-col min-h-[100vh]  w-full relative ">
            {/* <PrintText className="flex justify-end items-center absolute top-[0vh] right-[0] w-full text-xs overflow-visible">
              {`page ${totalPages}/${totalPages}`}
            </PrintText> */}

            {/*文字區*/}
            <div className="flex flex-col w-full leading-6 pt-3 font-[400]">
              {/*前言*/}
              <PrintText>
                本报价在委托方回签后即具有合同效力。双方应共同遵守上述条款，并保证按照该报价单的规定履行义务和承担相应责任。本报价单扫描件、传真件与原件具有同等法律效力。
              </PrintText>

              {/*報價說明*/}
              <div className="flex flex-col w-full">
                <PrintText className="font-[900] pl-[2em]">一、报价说明：</PrintText>
                <PrintText>
                  &emsp;&nbsp;1.本报价不包括样品运输费、整改费等未列名的其他费用。
                </PrintText>
                {/* <PrintText>&emsp;&emsp;  2.该报价单是基于委托方所提供的信息而报出的。如果收到完整的样板及文件后发现必须增加更多的测试时，检测方保留调整收费及时间表的权力。</PrintText> */}
                <PrintText>
                  &emsp;&nbsp;2.该报价单是基于委托方所提供的信息而报出的。如果收到完整的样板及文件后发现必须增加更多的测
                </PrintText>
                <PrintText>
                  &emsp;&nbsp;&ensp;&nbsp;试时，检测方保留调整收费及时间表的权力。
                </PrintText>
                {/* <PrintText>&emsp;&emsp;  3.因一方违约或侵权给守约方造成损失的，违约方应向守约方支付违约金并赔偿守约方损失，损失包括因违约行为产生的直接或间接经济损失及守约方为维权支出的律师费、诉讼费、仲裁费、公证费、差旅费等合理支出。</PrintText> */}
                <PrintText>
                  &emsp;&nbsp;3.因一方违约或侵权给守约方造成损失的，违约方应向守约方支付违约金并赔偿守约方损失，损失包括
                </PrintText>
                <PrintText>
                  &emsp;&nbsp;&ensp;&nbsp;因违约行为产生的直接或间接经济损失及守约方为维权支出的律师费、诉讼费、仲裁费、公证费、差
                </PrintText>
                <PrintText>&emsp;&nbsp;&ensp;&nbsp;旅费等合理支出。</PrintText>
                {/* <div>&emsp;&emsp;  4.计划时间自收齐样品，产品资料及相应款项之日起，至取得证书之日止；因样品数量，资料不齐，改板重测，付款不及时等原因拖延的时问不计算在内。</div> */}
                <PrintText>
                  &emsp;&nbsp;4.计划时间自收齐样品，产品资料及相应款项之日起，至取得证书之日止；因样品数量，资料不齐，改
                </PrintText>
                <PrintText>
                  &emsp;&nbsp;&ensp; 板重测，付款不及时等原因拖延的时问不计算在内。
                </PrintText>
                <PrintText>
                  &emsp;&nbsp;5.报价单汇率，美元兑人民币，以汇款当日中国银行外汇牌价为准。
                </PrintText>
                <PrintText>&emsp;&nbsp;6.本报价单自本日起30 天有效。</PrintText>
              </div>

              {/*服務流程*/}
              <div className="flex flex-col w-full">
                <PrintText className="font-[900] pl-[2em]">二、服务流程</PrintText>
                {/* <PrintText>1、此报价单作为第三方检测服务技术合同依据，委托方需准确填写“委托检验单”中相关信息，若因误填、漏填等原因而造成测试报告编印错误，一切责任均由委托方承担，若需修改、增加测试报告，检测方按200元/份收取费用。</PrintText> */}
                <PrintText>
                  &emsp;&nbsp;1、此报价单作为第三方检测服务技术合同依据，委托方需准确填写“委托检验单”中相关信息，若因误填
                </PrintText>
                <PrintText>
                  &emsp;&nbsp;&ensp;、漏填等原因而造成测试报告编印错误，一切责任均由委托方承担，若需修改、增加测试报告，检测方
                </PrintText>
                <PrintText>&emsp;&nbsp;&ensp;按200元/份收取费用。</PrintText>
                {/* <PrintText>2、委托方寄送样板和产品资料，并按照付款方式于5个工作日内支付全款。因未按时支付款项所造成的一切损失及责任由委托方承担。</PrintText> */}
                <PrintText>
                  &emsp;&nbsp;2、委托方寄送样板和产品资料，并按照付款方式于5个工作日内支付全款。因未按时支付款项所造成的一
                </PrintText>
                <PrintText>&emsp;&nbsp;&ensp;切损失及责任由委托方承担。</PrintText>
                {/* <PrintText>3、检测方安排预测试并核对资料后，如果有不符合项目，将及时通知委托方改板，并重新送样和提供合格的资料。</PrintText> */}
                <PrintText>
                  &emsp;&nbsp;3、检测方安排预测试并核对资料后，如果有不符合项目，将及时通知委托方改板，并重新送样和提供合
                </PrintText>
                <PrintText>&emsp;&nbsp;&ensp;格的资料。</PrintText>
                <PrintText>
                  &emsp;&nbsp;4、样板正式测试完成，委托方结清尾款后，检测方安排发送检测报告。
                </PrintText>
              </div>

              {/*付款方式*/}
              <div className="flex flex-col w-full">
                {/* <QRCode className="float-right opacity-0" type="svg" size={150} bordered value={'SAT' || '-'} /> */}
                <PrintText className="font-[900] pl-[2em]">
                  三、付款方式（银行转账或扫描公账收款码）
                </PrintText>
                <PrintText>&emsp;&nbsp;名称: 广东兆科检验认证有限公司</PrintText>
                <PrintText>&emsp;&nbsp;税号: 91441900MACEK5QJ5W</PrintText>
                <PrintText>&emsp;&nbsp;地址: 广东省东莞市厚街镇莞太路厚街段216号106室</PrintText>
                <PrintText>&emsp;&nbsp;开户行：中国银行东莞厚街支行营业部</PrintText>
                <PrintText>&emsp;&nbsp;账 号: 653577131246</PrintText>
                {/* </div> */}

                {/*注意事項*/}
                <div className="flex flex-col w-full">
                  <PrintText className="font-[900] pl-[2em]">四、注意事项</PrintText>
                  <PrintText>
                    &emsp;&nbsp;1、汇款后请将汇款单扫描后发至检测方，并注明厂名及委托编号，检测方凭回单安排检验工作。
                  </PrintText>
                  <PrintText>
                    &emsp;&nbsp;2、如使用个人卡转账，请务必在交易附言或备注中注明企业名称和委托报价单编号。
                  </PrintText>
                  <PrintText>&emsp;&nbsp;3、如需要开具增值税专用发票，请提供以下资料：</PrintText>
                  <PrintText>
                    &emsp;&nbsp;(1)税务登记号；(2)公司账号、开户行；(3)公司名称；(4)公司地址、电话
                  </PrintText>
                </div>
              </div>
            </div>

            {/* <div className="flex justify-center items-center w-full h-[20px] " /> */}

            <div className="flex flex-col w-full border-solid border-[1px] mt-[20px]">
              <div className="flex w-full leading-8 border-solid border-[0px] border-b-[1px]">
                <PrintText className="flex justify-center items-center w-[50%] border-solid border-[0px] border-r-[1px]">
                  委托方:
                </PrintText>
                <PrintText className="flex justify-center items-center w-[50%]">检测方:</PrintText>
              </div>

              <div className="flex w-full leading-7 divide-x divide-y-0 divide-solid border-solid border-[0px] border-b-[1px]">
                <div className="flex justify-center items-center w-[10%]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>公司名称</PrintText>
                    <PrintText>(盖章)</PrintText>
                  </div>
                </div>
                <PrintText className="flex justify-center items-center w-[40%]">
                  {hd_values.custNM}
                </PrintText>
                <div className="flex justify-center items-center w-[10%]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>公司名称</PrintText>
                    <PrintText>(盖章)</PrintText>
                  </div>
                </div>
                <PrintText className="flex justify-center items-center w-[40%]">
                  广东兆科检验认证有限公司
                </PrintText>
              </div>

              <div className="flex w-full leading-7 divide-x divide-y-0 divide-solid border-solid border-[0px]">
                <div className="flex justify-center items-center w-[10%]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>委托方</PrintText>
                    <PrintText>代表人</PrintText>
                  </div>
                </div>
                <div className="flex justify-center items-center w-[40%]" />

                <div className="flex justify-center items-center w-[10%]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>检测方</PrintText>
                    <PrintText>代表人</PrintText>
                  </div>
                </div>
                <PrintText className="flex justify-center items-center w-[40%]">
                  {c_staffs.options?.find((item) => item.value === hd_values.driID)?.label ??
                    hd_values.driID}
                </PrintText>
              </div>

              {/* <div className="flex w-full leading-8 border-solid border-[0px] border-t-[1px] ">
                <div className="flex justify-center items-center w-[10%] ">客戶签订:</div>
                <div className="flex justify-start items-center w-[90%] border-solid border-[0px] border-l-[1px] pl-[20px]">
                </div>
              </div> */}

              <div className="flex w-full leading-7 divide-x divide-y-[1px] divide-solid border-solid border-[0px]">
                <div className="flex justify-center items-center w-[10%] border-solid border-[0px] border-t-[1px]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>签订日期</PrintText>
                  </div>
                </div>
                <div className="flex justify-center items-center w-[40%]" />

                <div className="flex justify-center items-center w-[10%]">
                  <div className="flex flex-col justify-center items-center w-full">
                    <PrintText>签订日期</PrintText>
                  </div>
                </div>
                <div className="flex justify-center items-center w-[40%]"></div>
              </div>
            </div>

            <div className="flex justify-center items-center w-full text-xs leading-4 mt-[25px] font-[400]">
              <PrintText className="flex justify-center items-center">
                地址: 广东省东莞市厚街镇莞太路厚街段216号106室
              </PrintText>
              <PrintText className="flex justify-center items-center ml-4">
                电话: 0769-82259653
              </PrintText>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Footer;
