/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Row, Col, Typography, message, Spin } from "antd";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { getRec } from "@/service/apis/PMS/Plan";
// import { getRec } from "../../moke";

const { Text } = Typography;

const RecDrawer = ({ s_recDrawer, set_s_recDrawer }) => {
  const { t } = useTranslation();
  const { data } = s_recDrawer;
  const [s_recData, set_s_recData] = useState([]);

  // 取得規格評估
  const call_getRec = useAPI(getRec);

  // 第一次渲染取得規格評估
  useEffect(() => {
    call_getRec.request({ "itemUUID": data.itemUUID });
  }, [data]);

  // useAPI 取得規格評估
  useEffect(() => {
    const { data, msg, status } = call_getRec;

    if (status === "suc") {
      message.success(msg);
      set_s_recData(data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getRec.status]);

  return (
    <div className="h-full" onClick={() => set_s_recDrawer({ show: false, data: {} })}>
      <Spin spinning={call_getRec.status === "load"}>
        {s_recData.map((item, index) => (
          <Row
            key={index}
            className={`h-24 border pms-drawer-border-color ${index > 0 && "border-t-0"}`}
          >
            {/* 項序 */}
            <Col span={2} className="pms-drawer-title border-r flex-center">
              <Text>{item.recno}</Text>
            </Col>
            {/* 規格 */}
            <Col span={4} className="pms-drawer-title border-r">
              <Text>{t("util.pdtspec")}</Text>
            </Col>
            <Col span={6} className="pms-drawer-value">
              <Text>{item.pdtspec}</Text>
            </Col>
            {/* 註記 */}
            <Col span={4} className="pms-drawer-title border-r border-l">
              <Text>{t("util.note")}</Text>
            </Col>
            <Col span={8} className="pms-drawer-value">
              <Text>{item.note}</Text>
            </Col>
          </Row>
        ))}
      </Spin>
    </div>
  );
};

export default RecDrawer;
