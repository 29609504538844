/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { Grid, Space, Input, Drawer, Typography, message, Divider, Modal, theme } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import Light from "@/components/ERP/Light";
import { CustomRangePicker, CustomSelect, CustomSearchButton } from "@/components/ERP/TabExtra";
import {
  CreateButton,
  ClearButton,
  SeeMoreButton,
  DeleteButton,
  EditButton,
  DetailButton,
  ExcelButton,
} from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
import DetailModal from "./Modal/DetailModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getOutstockHD, getOutstockHDAD, deleteOutstockHD } from "@/service/apis/OMS/Outstock";
const { Title } = Typography;
const { useToken } = theme;
const HD = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  // useAPI 取得表頭資料
  const call_getOutstockHD = useAPI(getOutstockHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getOutstockHDAD = useAPI(getOutstockHDAD);
  // useAPI 刪除表頭資料
  const call_deleteOutstockHD = useAPI(deleteOutstockHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG, detailSearch } = c_state;
  // 客戶資料、人員資料
  const { c_supps, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    custID: [],
    driID: [],
    lifeF: [
      { value: "0", label: t("OMS.outstock.lifeF_0") }, // 發佈
      { value: "2", label: t("OMS.outstock.lifeF_2") }, // 發貨覆核
      { value: "3", label: t("OMS.outstock.lifeF_3") }, // IQC抽驗
      { value: "4", label: t("OMS.outstock.lifeF_4") }, // IQC完驗
      { value: "7", label: t("OMS.outstock.lifeF_7") }, // 品保覆核
      { value: "T", label: t("OMS.outstock.lifeF_T") }, // 結案
    ],
  });
  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  // const [s_detailSearch, set_s_detailSearch] = useState({
  //   show: false,
  //   data: {},
  // });
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "1410", label: t("OMS.util.opcode_1410") }, // 國內訂單
    { key: "1420", label: t("OMS.util.opcode_1420") }, // 國外訂單
    { key: "detailSearch", label: t("OMS.util.advanceSearch") }, // 進階查詢
  ];
  const placeholder_map = {
    N: t("util.placeholder_input") + t("OMS.util.N"), // 請輸入單號
    custID: t("util.placeholder_select") + t("OMS.util.custID"), // 請選擇客戶
    lifeF: t("util.placeholder_select") + t("OMS.util.lifeF"), // 請選擇歷程
    driID: t("util.placeholder_select") + t("OMS.ord.driID"), // 請選擇負責業務
  };
  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    console.log("activeKey = ", activeKey);
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getOutstockHD.request({ ...tmp, opcode: tabKey });
    }
  };
  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getOutstockHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getOutstockHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T" || rowData.count > 0) {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("OMS.util.outstock") + t("OMS.util.del_confirm"), // 發貨單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.outstock.closeData_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteOutstockHD.request(rowData),
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getOutstockHD.request({
        ...searchConfig,
        opcode: tabKey,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getOutstockHD.request({
      ...searchConfig,
      opcode: opcode,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      custID: c_supps.options,
      driID: c_staffs.options,
    }));
  }, [c_supps, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getOutstockHD;
    console.log("data = ", data);
    if (status === "suc") {
      console.log("call_getOutstockHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newOutstock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ outstock_Ns: newOutstock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOutstockHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getOutstockHDAD;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newOutstock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ outstock_Ns: newOutstock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOutstockHDAD.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deleteOutstockHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getOutstockHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteOutstockHD.status]);

  // 表格欄位
  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "6%" : "8%",
      render: (text) => {
        const map = {
          1410: t("OMS.util.opcode_1410"), // 國內訂單
          1420: t("OMS.util.opcode_1420"), // 國外訂單
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text, rowData, _) => (
        <div className="text-left">
          <span className="mr-1">{text}</span>
          {rowData.linkType !== "" && <Light linkType={rowData.linkType} />}
        </div>
      ),
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      dataIndex: "dealN",
      align: "center",
      width: screens.xxl === true ? "11%" : "10%",
    },
    {
      title: t("OMS.util.custID"), // 客戶ID
      dataIndex: "custID",
      align: "center",
      width: screens.xxl ? "8.3%" : "8.3%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
      width: screens.xxl ? "12%" : "12%",
    },
    {
      title: t("OMS.outstock.contact"), // 聯絡人
      dataIndex: "contact",
      align: "center",
      width: screens.xxl ? "9%" : "10%",
    },
    {
      title: t("OMS.outstock.tel"), // 聯絡電話
      dataIndex: "tel",
      align: "center",
      width: screens.xxl ? "9%" : "10%",
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "6%" : "8%",
      render: (text) => {
        const map = {
          0: t("OMS.outstock.lifeF_0"), // 發佈
          2: t("OMS.outstock.lifeF_2"), // 發貨覆核
          3: t("OMS.outstock.lifeF_3"), // IQC抽驗
          4: t("OMS.outstock.lifeF_4"), // IQC完驗
          7: t("OMS.outstock.lifeF_7"), // 品保覆核
          T: t("OMS.outstock.lifeF_T"), // 結案
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl ? "10%" : "13%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("OMS.util.updtID"), // 更新人員
      dataIndex: "updtID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.util.updtT"), // 更新時間
      dataIndex: "updtT",
      align: "center",
      width: screens.xxl ? "10.3%" : "13%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "7%" : "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <PageHeader
        title={t("OMS.outstock.pageHeader")} // 發貨作業
        extra={<TourButton />}
      />
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <CustomRangePicker
              disabled={tabKey === "detailSearch"}
              value={[searchConfig?.startDate, searchConfig?.endDate]}
              onCalendarChange={(dates, _, __) => {
                updateState({
                  searchConfig: {
                    ...searchConfig,
                    startDate: dates[0],
                    endDate: dates[1],
                  },
                });
              }}
            />

            <Input.Group compact>
              <CustomSelect
                disabled={tabKey === "detailSearch"}
                value={searchConfig.searchFlag}
                placeholder={t('util.search')}
                onChange={(value) => handleChange("searchFlag", value)}
                options={[
                  { value: "N", label: t("OMS.util.N") }, // 單號
                  { value: "custID", label: t("OMS.util.custID") }, // 客戶ID
                  { value: "lifeF", label: t("OMS.util.lifeF") }, // 歷程
                  { value: "driID", label: t("OMS.ord.driID") }, // 負責業務
                  { value: "contact", label: t("OMS.outstock.contact") }, // 聯絡人
                ]}
              />

              {searchConfig.searchFlag === "lifeF" ||
              searchConfig.searchFlag === "custID" ||
              searchConfig.searchFlag === "driID" ||
              searchConfig.searchFlag === "contact" ? (
                <CustomSelect
                  showSearch
                  disabled={tabKey === "detailSearch"}
                  value={searchConfig.searchValue}
                  className="w-[250px]"
                  placeholder={placeholder_map[searchConfig.searchFlag]}
                  onChange={(value) => handleChange("searchValue", value)}
                  // 根據searchFlag判斷現在是哪個下拉選單
                  options={s_selectOptions[searchConfig.searchFlag]}
                />
              ) : (
                <Input
                  disabled={tabKey === "detailSearch"}
                  value={searchConfig.searchValue}
                  className="w-[250px]"
                  placeholder={placeholder_map[searchConfig.searchFlag]}
                  onChange={(e) => handleChangeInput(e.target.value)}
                />
              )}
              <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
            </Input.Group>

            {/* 清除搜詢紀錄 */}
            <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
            {/* 新建 */}
            <CreateButton
              disabled={tabKey === "detailSearch"}
              onClick={() => updateState({ mainPage: { show: true, data: {} } })}
            />
          </Space>
        }
      />

      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
          <DetailButton
            onClick={() => updateState({ detailSearch: { ...detailSearch, show: true } })}
          />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getOutstockHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 發貨單主檔 */}
            {t("OMS.outstock.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={900}
        open={s_drawer.show}
        onClose={() => set_s_drawer({ show: false, data: {} })}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("OMS.util.setAdvanced")}
        open={detailSearch.show}
        onCancel={() => updateState({ detailSearch: { ...detailSearch, show: false } })}
      >
        {detailSearch.show && <DetailModal call_getOutstockHDAD={call_getOutstockHDAD} />}
      </CustomModal>
    </div>
  );
};

export default HD;
