import apiBasic from "./APIBasic";
import axios from "axios";
import qs from "qs";

// axios的全局配置
export const instance = axios.create({
  timeout: 40000,
  baseURL: apiBasic.url,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
  //跨域存取cookies
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      config.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: "indices" });
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  async (response) => {
    if (response.data.status === 200) {
      return {
        status: true,
        msg: response.data.msg,
        data: response.data.data,
      };
    } else if (response.data.status === 403) {
      window.location.assign("/login");
    } else {
      return {
        status: false,
        msg: response.data.msg || `${response.config.url} >>> 伺服器連接失敗`,
        data: response.data.data,
      };
    }
  },
  (err) => {
    const { response } = err;
    if (response) {
      return {
        status: false,
        data: {},
        msg: errorHandle(response.status, response.data),
      };
    } else {
      return {
        status: false,
        data: {},
        msg: "請求逾時",
      };
    }
  }
);
const errorHandle = (status, other) => {
  switch (status) {
    case 400:
      return "handlerAPI -> 訊息效驗失敗 400";
    case 401:
      return "handlerAPI ->認證失败 401";
    case 403:
      return "handlerAPI ->403";
    case 404:
      return "handlerAPI ->請求的資源不存在 404";
    default:
      console.log("handlerAPI ->", other);
      return "handlerAPI ->無法辨識的資源";
  }
};
