import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";

// ----------------- 表頭 -----------------
// 新建進料單表頭
export const addInstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInstockHD`, data);
};
// 取得進料單(表頭ㄧ階搜尋)
export const getInstockHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInstockHD`, {
    params: data,
  });
};
// 取得進料單(表頭ㄧ階搜尋)
export const getInstockHdAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInstockHD`, {
    params: data,
  });
};
// 編輯進料單表頭
export const updateInstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInstockHD`, data);
};
// 刪除進料單表頭
export const deleteInstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInstockHD`, data);
};
// =============================================================================
// 拋轉進料單
export const goStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/goStock`, data);
};
// 引用採購單(取得採購單資料)
export const getTakePro = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getTakePro`, {
    params: data,
  });
};
// 引用採購單(建立)
export const takePro = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takePro`, data);
};
// =============================================================================
// 新建進料單表身
export const addInstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addInstockTB`, data);
};
// 取得進料單表身
export const getInstockTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getInstockTB`, {
    params: data,
  });
};
// 編輯進料單表身
export const updateInstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateInstockTB`, data);
};
// 刪除進料單表身
export const deleteInstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteInstockTB`, data);
};
