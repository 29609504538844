/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Row, Col, Input, Divider, message, Radio } from "antd";
import { addDri, updateDri } from "@/service/apis/ADM/StringManage";
import useAPI from "@/hooks/useAPI";

const ModalContainer = ({ s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 新增責任單位
  const call_addDri = useAPI(addDri);
  // useAPI 編輯責任單位
  const call_updateDri = useAPI(updateDri);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addDri.request(values);
    }
    if (type === "edit") {
      call_updateDri.request(values);
    }
  };

  // useAPI 新增責任單位
  useEffect(() => {
    if (call_addDri.status === "suc") {
      message.success(call_addDri.msg);
      set_s_isShowModal(false);
    } else if (call_addDri.status === "err") {
      message.error(t(`errorCode.${call_addDri.msg}`));
    }
  }, [call_addDri.status]);

  // useAPI 編輯責任單位
  useEffect(() => {
    if (call_updateDri.status === "suc") {
      message.success(call_updateDri.msg);
      set_s_isShowModal(false);
    } else if (call_updateDri.status === "err") {
      message.error(t(`errorCode.${call_updateDri.msg}`));
    }
  }, [call_updateDri.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("ADM.string-manage.driItemID")} // 單位ID
            name="itemID"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("util.placeholder_input")}${t("ADM.string-manage.driItemID")}`}
              disabled={Object.keys(s_editData).length > 0}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("ADM.string-manage.driItemNM")} // 單位名稱
            name="itemNM"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("util.placeholder_input")}${t("ADM.string-manage.driItemNM")}`}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("util.isvalid")} // 是否有效
            name="isvalid"
          >
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Divider className="m-0" />

        <Col span={24} className="flex justify-end gap-3">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
