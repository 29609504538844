import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";
import { getAllWhID, addTxnHd, getHdSelect, getTb } from "@/service/apis/WMS/WMS";
import { formatAmount } from "@/util/format";
const ModalContainer = ({ opcode, s_editData, set_s_showModal }) => {
	const { t } = useTranslation();

	const [form] = Form.useForm();

	const call_getAllWhID = useAPI(getAllWhID);
	const call_getTb = useAPI(getTb);
	const call_addTxnHd = useAPI(addTxnHd);
	const call_getHdSelect = useAPI(getHdSelect);

	const onSubmit = async (values) => {
		let temp = {
			opcode: opcode,
			opUUID: values.opUUID,
			whID: values.whID,
			qty: values.total,
			tb: values.details,
			note: values.note,
		};

		if (Object.keys(s_editData).length === 0) {
			call_addTxnHd.request(temp);
		}
	};

	useEffect(() => {
		call_getAllWhID.request();
		call_getHdSelect.request({ opcode: opcode });
	}, []);

	useEffect(() => {
		if (call_addTxnHd.status === "suc") {
			message.success(call_addTxnHd.msg);
			set_s_showModal(false);
		} else if (call_addTxnHd.status === "err") {
			message.error(call_addTxnHd.msg);
		}
	}, [call_addTxnHd.status]);

	useEffect(() => {
		if (call_getAllWhID.status === "suc") {
		} else if (call_getAllWhID.status === "err") {
			message.error(call_getAllWhID.msg);
		}
	}, [call_getAllWhID.status]);

	useEffect(() => {
		if (call_getHdSelect.status === "suc") {
		} else if (call_getHdSelect.status === "err") {
			message.error(call_getHdSelect.msg);
		}
	}, [call_getHdSelect.status]);

	useEffect(() => {
		if (call_getTb.status === "suc") {
			let formData = form.getFieldValue();
			formData.total = 0;
			for (const x of call_getTb.data) {
				formData.total = formData.total + x.planqty;
			}
			formData["details"] = call_getTb.data.map((x) => {
				return { ...x, whID: formData.whID };
			});

			form.setFieldsValue({ ...formData });
		} else if (call_getTb.status === "err") {
			message.error(call_getTb.msg);
		}
	}, [call_getTb.status]);

	return (
		<Form
			form={form}
			initialValues={{
				isvalid: true,
				...s_editData,
			}}
			onFinish={onSubmit}
			layout="vertical"
		>
			<Row gutter={[12, 12]}>
				{/* 額外不顯示欄位 */}
				<Form.Item name="opUUID" noStyle hidden>
					<Input />
				</Form.Item>

				<Col span={8}>
					<Form.Item
						label={t(`WMS.util.opcode_${opcode}`) + t(`util.order`)}
						name="operN"
						rules={[{ required: true }]}
					>
						<Select
							showSearch
							onChange={(_, rowData) => {
								call_getTb.request(rowData.data);
								const { opUUID, whID } = rowData.data;
								// 要自動帶入倉別
								form.setFieldsValue({ opUUID, whID });
							}}
							options={
								call_getHdSelect?.data?.map((x) => ({
									label: x.jobN,
									value: x.jobN,
									data: x,
								})) || []
							}
						/>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						//倉別
						label={t(`util.whID`)}
						name="whID"
					>
						<Select
							allowClear
							options={
								call_getAllWhID?.data?.map((x) => ({
									label: x.whNM,
									value: x.whID,
								})) || []
							}
							onChange={(whID) => {
								if (!whID) return;
								let formData = form.getFieldValue();
								if (!formData["details"]) {
									message.error("請先選擇入庫單");
									return;
								}
								formData["details"] = formData["details"].map((x) => {
									return { ...x, whID: whID };
								});
								form.setFieldsValue(formData);
							}}
						/>
					</Form.Item>
				</Col>

				<Col span={8}>
					<Form.Item
						//總數
						label={t(`util.total`)}
						name="total"
						shouldUpdate
					>
						<InputNumber className="w-full" formatter={(value) => formatAmount(value)} disabled />
					</Form.Item>
				</Col>

				<Col span={24}>
					{/* 分割線 */}
					<Divider className="p-0 m-0" />
				</Col>

				<Col span={24}>
					<Form.List name="details" shouldUpdate>
						{(fields) => (
							<>
								{fields.map((field) => (
									<Row key={field.key} gutter={[12, 12]}>
										<Col span={4} className="mb12">
											<Form.Item
												{...field}
												// 料號
												label={t(`util.PN`)}
												name={[field.name, "PN"]}
											>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col span={3}>
											<Form.Item label="品名" name={[field.name, "pdtNM"]}>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col span={3}>
											<Form.Item label="规格" name={[field.name, "pdtspec"]}>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col span={3}>
											<Form.Item label="品牌" name={[field.name, "mtype"]}>
												<Input disabled />
											</Form.Item>
										</Col>
										<Col span={3} className="mb12">
											<Form.Item
												{...field}
												// 過帳型態
												label={t(`util.idmtype`)}
												shouldUpdate
												name={[field.name, "idmtype"]}
											>
												<Select
													disabled
													options={[
														{ label: t("util.idmtype_s"), value: "S" },
														{ label: t("util.idmtype_d"), value: "D" },
														{ label: t("util.idmtype_m"), value: "M" },
													]}
												/>
											</Form.Item>
										</Col>
										<Col span={2}>
											<Form.Item
												{...field}
												//倉別
												label={t(`util.whID`)}
												name={[field.name, "whID"]}
												rules={[{ required: true }]}
											>
												<Select
													options={
														call_getAllWhID.data?.map((x) => ({
															label: x.whNM,
															value: x.whID,
														})) || []
													}
													onChange={(e) => {
														let formData = form.getFieldValue();
														formData["whID"] = undefined;
														form.setFieldsValue(formData);
													}}
												/>
											</Form.Item>
										</Col>

										<Col span={2} className="mb12">
											<Form.Item
												{...field}
												//入庫批號
												label={t(`WMS.util.batchno_${opcode}`)}
												name={[field.name, "batchno"]}
											>
												<InputNumber min={0} className="w-full" disabled />
											</Form.Item>
										</Col>

										<Col span={2} className="mb12">
											<Form.Item
												{...field}
												//計畫數量
												label={t(`util.planqty`)}
												name={[field.name, "planqty"]}
											>
												<InputNumber
													min={0}
													formatter={(value) => formatAmount(value)}
													className="w-full"
													disabled
												/>
											</Form.Item>
										</Col>
										<Col span={2}>
											<Form.Item label="存放位置" name={[field.name, "note"]}>
												<Input />
											</Form.Item>
										</Col>
									</Row>
								))}
							</>
						)}
					</Form.List>
				</Col>

				<Col span={24} className="flex justify-end">
					<Space>
						<Button onClick={() => set_s_showModal(false)}>{t("util.cancel")}</Button>
						<Button type="primary" htmlType="submit">
							{t("util.ok")}
						</Button>
					</Space>
				</Col>
			</Row>
		</Form>
	);
};

export default ModalContainer;
