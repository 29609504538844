import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import { getPush, deletePush } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const Pushlvl = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const { t } = useTranslation();
  const call_getPush = useAPI(getPush);
  const call_deletePush = useAPI(deletePush);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getPush.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.lvlcode"), //"層級碼",
      dataIndex: "lvlcode",
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.lvlNM"), //"層級名稱",
      dataIndex: "lvlNM",
      align: "center",
    },
    {
      title: t("util.note"), //"註記",
      dataIndex: "note",
      width: "70%",
    },
    {
      title: t("util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deletePush.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getPush.status === "suc") {
      message.success(call_getPush.msg);
      set_s_tableData(
        call_getPush.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getPush.status === "err") {
      message.error(t(`errorCode.${call_getPush.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPush.status]);

  useEffect(() => {
    if (call_deletePush.status === "suc") {
      message.success(call_deletePush.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deletePush.status === "err") {
      message.error(t(`errorCode.${call_deletePush.msg}`));
    }
  }, [call_deletePush.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getPush.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.edit") + t("ADM.function-params-manage.pushlayer")
            : t("util.add") + t("ADM.function-params-manage.pushlayer")
        }
        width={"50%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Pushlvl;
