import React, { useState, useEffect } from "react";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import Details from "./TabsContainer/Details";
import BindPN from "./TabsContainer/BindPN";

export default function TB({ c_lightData, set_c_lightData }) {
  const [s_tabKey, set_s_tabKey] = useState("bindPN");

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    set_s_tabKey(activeKey);
  };

  const tabsData = [
    {
      key: "details",
      label: "標籤明細",
      children: <Details c_lightData={c_lightData} set_c_lightData={set_c_lightData} />,
    },
    {
      key: "bindPN",
      label: "料號綁定",
      children: <BindPN c_lightData={c_lightData} set_c_lightData={set_c_lightData} />,
    },
  ];

  return (
    <>
      <CustomTabs
        activeKey={s_tabKey}
        items={tabsData}
        onChange={(activeKey) => handleChangeTab(activeKey)}
      />
    </>
  );
}
