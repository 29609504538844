/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import React, { useContext, useEffect } from "react";
import { addA1Hd, updateA1Hd } from "@/service/apis/OMS/runA1";

import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import { ERPContext } from "@/components/Context/SystemContext";
import { getOrdHD } from "@/service/apis/OMS/Ord";
//api
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const ModalContainer = ({ s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const { c_staffs } = useContext(ERPContext);
  const [form] = Form.useForm();
  const call_addA1Hd = useAPI(addA1Hd);
  const call_updateA1Hd = useAPI(updateA1Hd);
  console.log("c_staffs = ", c_staffs);
  const type = Object.keys(s_editData).length === 0 ? "create" : "edit";
  const onSubmit = (values) => {
    if (type === "create") {
      call_addA1Hd.request(values);
    }
    if (type === "edit") {
      call_updateA1Hd.request({ ...s_editData, ...values });
    }
  };

  const aa = [
    {
      label: "专职业务员",
      type: "A",
    },
    {
      label: "非专职业务员",
      type: "B",
    },
    {
      label: "公司全员",
      type: "C",
    },
  ];

  useEffect(() => {
    if (call_addA1Hd.status === "suc") {
      message.success(call_addA1Hd.msg);
      set_s_isShowModal(false);
    } else if (call_addA1Hd.status === "err") {
      message.error(call_addA1Hd.msg);
    }
  }, [call_addA1Hd.status]);

  // useAPI 更新預收款表頭
  useEffect(() => {
    if (call_updateA1Hd.status === "suc") {
      message.success(call_updateA1Hd.msg);
      set_s_isShowModal(false);
    }
    if (call_updateA1Hd.status === "err") {
      message.error(call_updateA1Hd.msg);
    }
  }, [call_updateA1Hd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[12, 24]}>
        <Col span={6}>
          <Form.Item label="提成案号文编" name="approvalID">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label="是否有效"
            name="isvalid"
            // rules={[{ required: true }]}
          >
            <Radio.Group name="isvalid">
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      {aa.map((item, index) => (
        <>
          <Divider orientation="left">{item.label}</Divider>
          <Row gutter={[24, 12]} className="flex items-end">
            <Col span={3}>
              <Typography.Title level={5}>阶层1</Typography.Title>
            </Col>

            <Col span={7}>
              <Form.Item
                label="基点起数"
                name={`fmcount_${item.type}_1`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="基点迄数"
                name={`ftocount_${item.type}_1`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="提成比率"
                name={`bonusrate_${item.type}_1`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 12]} className="flex items-end">
            <Col span={3}>
              <Typography.Title level={5}>阶层2</Typography.Title>
            </Col>

            <Col span={7}>
              <Form.Item
                label="基点起数"
                name={`fmcount_${item.type}_2`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="基点迄数"
                name={`tocount_${item.type}_2`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="提成比率"
                name={`bonusrate_${item.type}_2`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[24, 12]} className="flex items-end">
            <Col span={3}>
              <Typography.Title level={5}>阶层3</Typography.Title>
            </Col>

            <Col span={7}>
              <Form.Item
                label="基点起数"
                name={`fmcount_${item.type}_3`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="基点迄数"
                name={`tocount_${item.type}_3`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="提成比率"
                name={`bonusrate_${item.type}_3`}
                rules={[{ required: true }]}
              >
                <InputNumber className="w-full" min={0} addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ))}

      <Divider className="my-2" />

      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label="創建人員" name="createID">
            <Select disabled options={c_staffs.options} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="創建日期" name="createT">
            <DatePicker disabled className="w-full" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="覆核人員" name="confirmID">
            <Select disabled options={c_staffs.options} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="覆核日期" name="confirmT">
            <DatePicker disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[24, 12]}>
        <Col span={12}>
          <CreateFromItem
            set_s_editData={set_s_editData}
            s_editData={s_editData}
            form={form}
            // afterCopy={afterCopy}
          />
        </Col>
        <Col span={12} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_isShowModal(false)} />

          <ConfirmButton type="primary" htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
