/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Row, Col, Drawer, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getProTB } from "@/service/apis/PMS/Pro";
// util
import { formatAmount } from "@/util/format";
import dayjs from "dayjs";

const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getProTB = useAPI(getProTB);

  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;

  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getProTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getProTB;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getProTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"),
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "3%" : "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.note"), // 樣品名稱
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 檢驗項目
      dataIndex: "pdtNM",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.pdtNM.localeCompare(b.pdtNM),
    },
    {
      title: t("PMS.util.pdtspec"), //測試方法/標準
      dataIndex: "pdtspec",
      align: "center",
      width: screens.xxl ? "14%" : "14%",
    },
    {
      title: t("OMS.ord.planday"), // 計畫時間
      dataIndex: "planday",
      align: "center",
      width: "7%",
      render: (text) => (text ? `${text}天` : text),
      // onCell: (_) => {
      //   return {
      //     style: {
      //       backgroundColor: "#ecf2fa",
      //     },
      //   };
      // },
    },
    {
      title: t("OMS.util.EDT"), // 預計交貨日
      dataIndex: "EDT",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "检测费用",
      dataIndex: "unitprice",
      align: "right",
      width: screens.xxl ? "9%" : "9%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "採購數量",
      title: "數量",
      dataIndex: "demandqty",
      align: "right",
      width: screens.xxl ? "5%" : "5%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "含稅小計",
      title: "金额",
      dataIndex: "itemsum",
      align: "right",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "3%" : "3%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={() => set_s_drawer({ show: true, data: rowData })} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 採購訂單明細 */}
            {t("PMS.pro.tb_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default TB;
