/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { message, Space, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { AddButton } from "@/components/PDS/Buttons";
import { IconPen } from "@/components/Icon/Action";
// component
import ModalContainer from "./index";
import Preview from "../../HD/Preview";
// api
import useAPI from "@/hooks/useAPI";
import { updateQmsSipTbItemno } from "@/service/apis/QMS/QMSManage";

const DndModal = ({
  tableColumns,
  s_tableData,
  set_s_tableData,
  c_lightData,
  call_getQmsSipTb,
}) => {
  const { t } = useTranslation();
  // 更新 sip tb 的順序
  const call_updateQmsSipTbItemno = useAPI(updateQmsSipTbItemno);
  // 是否顯示新增、修改的跳顯
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 要被編輯的資料
  const [s_editData, set_s_editData] = useState({});

  // 跟 TB 共用同一個 tableColumn，但只有這邊可以編輯
  const EditTableColumn = tableColumns.concat([
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ]);

  // useAPI 更新 sip tb 的順序
  useEffect(() => {
    const { status, msg, data } = call_updateQmsSipTbItemno;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(msg);
    }
  }, [call_updateQmsSipTbItemno.status]);

  return (
    <>
      <Row gutter={[12, 12]} className="mb-3">
        <Col span={12} className="flex text-lg">
          {c_lightData.drawing && (
            <>
              <Typography.Title level={3}>工程圖檔：</Typography.Title>
              <Typography.Title level={3} className="mt-0">
                <Preview url={c_lightData.drawing} />
              </Typography.Title>
            </>
          )}
        </Col>
        <Col span={12} className="flex justify-end">
          <AddButton onClick={() => set_s_isShowModal(true)} />
        </Col>
      </Row>
      <DragSortingTable
        columns={EditTableColumn}
        dataSource={s_tableData}
        set_s_tableData={set_s_tableData}
        afterSorting={(data) => {
          const _data = data.map((item, index) => ({
            ...item,
            itemno: index + 1,
          }));
          call_updateQmsSipTbItemno.request(_data);
          return _data;
        }}
        pagination={false}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}標準檢驗明細`
            : `${t("util.add")}標準檢驗明細`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <ModalContainer
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default DndModal;
