import React, { useState, useEffect, useContext } from "react";
import { message, Space, Input, Select, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SearchButton } from "@/components/PDS/Buttons";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getPostage } from "@/service/apis/OMS/Ord";
// util
import { formatAmount } from "@/util/format";

const Postage = () => {
  const [s_tableData, set_s_TableData] = useState([]);
  const call_getPostage = useAPI(getPostage);
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { c_custs } = useContext(ERPContext);
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    N: "", //單號
    custID: [], //客戶ID
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 第一次渲染取得郵寄費用
  useEffect(() => {
    call_getPostage.request({ ...s_searchData });
    // call_getPostage.request();
  }, []);

  // 搜尋資料
  const handleClick = () => {
    const tmp = {
      ...s_searchData,
      custID: s_searchData.custID.join("|"),
    };
    call_getPostage.request(tmp);
  };

  // useAPI 取得郵寄費用
  useEffect(() => {
    const { data, msg, status } = call_getPostage;
    if (status === "suc") {
      message.success("取得郵寄費用成功");
      set_s_TableData(data);
    }

    if (status === "err") {
      set_s_TableData([]);
      message.error("取得郵寄費用失敗");
    }
  }, [call_getPostage.status]);

  const tableColumns = [
    {
      title: t("OMS.util.custID"), // 客戶簡稱
      dataIndex: "custID",
      align: "center",
      width: "12%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
      width: "12%",
      render: (_, record) => c_custs?.options?.find((item) => item.value === record.custID)?.label,
    },
    // {
    //   title: t("OMS.util.N"), // 單號
    //   dataIndex: "N",
    //   align: "center",
    //   width: "5%",
    // },
    {
      title: t("util.note"),
      dataIndex: "note",
      width: "15%",
      align: "center",
    },
    {
      title: t("OMS.ord.amount"), // 金額
      dataIndex: "amount",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("util.action"), // 操作
    //   dataIndex: "operate",
    //   align: "center",
    //   width: "4%",
    //   render: (_, rowData, __) => (
    //     <Space className="px-2">
    //       {/* 編輯表身資料 */}
    //       <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
    //       {/* 刪除表身資料 */}
    //       <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="flex-1">
      <PageHeader title={"邮寄费用管理"} />

      <Space className="flex justify-end gap-2 mb-2">
        {/*預交日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        {/*訂單單號*/}
        <Input
          className="max-w-[150px]"
          placeholder="请输入单号"
          name="N"
          onChange={(e) => handle_SearchData_Change("N", e.target.value)}
          value={s_searchData.N}
          allowClear
          onPressEnter={handleClick}
        />

        {/*客戶選擇*/}
        <Select
          className="min-w-[200px]"
          name="custID"
          mode="multiple"
          showSearch
          placeholder="请选择客户"
          onChange={(value) => handle_SearchData_Change("custID", value)}
          options={c_custs.options}
          allowClear
        />

        <SearchButton type="primary" key="search" onClick={handleClick} />
      </Space>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData?.map((item) => ({ key: item.itemUUID, ...item })) ?? []}
      />
    </div>
  );
};

export default Postage;
