import { Button, Col, DatePicker, Form, Input, Radio, Row, Space, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { addCustTb, modifyCustTb } from "@/service/apis/ADM/client";

import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal, c_lightData, ref }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // API
  const call_addCustTb = useAPI(addCustTb);
  const call_modifyCustTb = useAPI(modifyCustTb);

  const itemNMOp = [
    { label: "高管干部", value: "1" },
    { label: "採购单位", value: "2" },
    { label: "业务单位", value: "3" },
    { label: "品质单位", value: "4" },
    { label: "产品单位", value: "5" },
    { label: "技术单位", value: "6" },
    { label: "其他单位", value: "7" },
  ];

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      custID: c_lightData.custID,
      itemUUID: s_editData.itemUUID,
      itemno: s_editData.itemno,
      // Q: 需要加type
      ...values,
    };

    if (type === "create") {
      call_addCustTb.request(postData);
    }

    if (type === "edit") {
      call_modifyCustTb.request(postData);
    }
  };

  useEffect(() => {
    if (call_addCustTb.status === "suc") {
      message.success(call_addCustTb.msg);
      set_s_isShowModal(false);
    } else if (call_addCustTb.status === "err") {
      message.error(t(`errorCode.${addCustTb.msg}`));
    }
  }, [call_addCustTb.status]);

  useEffect(() => {
    if (call_modifyCustTb.status === "suc") {
      message.success(call_modifyCustTb.msg);
      set_s_isShowModal(false);
    } else if (call_modifyCustTb.status === "err") {
      message.error(t(`errorCode.${call_modifyCustTb.msg}`));
    }
  }, [call_modifyCustTb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        custID: c_lightData.custID,
        ...s_editData,
      }}
    >
      {/* 影藏欄位 */}
      <div>
        <Form.Item label={t("util.isvalid")} hidden name="isvalid">
          <Radio.Group>
            <Radio value={true}>{t("util.yes")}</Radio>
            <Radio value={false}>{t("util.no")}</Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item hidden name="itemUUID">
          <Input />
        </Form.Item> */}
      </div>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item label={"客户编号"} name="custID" rules={[{ required: true }]}>
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label={"联络窗口名称"} name="itemNM" rules={[{ required: true }]}>
            <Select key="s_searchData.driID" options={itemNMOp} placeholder={"请选择窗口名称"} />
          </Form.Item>
        </Col>

        {/* <Col span={8}>
          <Form.Item label={t("util.isvalid")} name="isvalid">
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col> */}
        {/* <Col span={8}></Col> */}

        <Col span={4}>
          <Form.Item label={"联络人"} name="contact" rules={[{ required: true }]}>
            <Input placeholder="请输入联络人" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"部门"} name="department">
            <Input placeholder="请输入部门" />
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item label={"公司联络住址"} name="addr">
            <Input placeholder="请输入公司联络住址" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"公司联络电话"} name="tel">
            <Input placeholder="请输入公司联络电话" />
          </Form.Item>
        </Col>

        {/* <Col span={12}>
          <Form.Item label={t("ADM.client.addr")} name="addr">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col> */}

        <Col span={16}>
          <Form.Item label={"邮件地址"} name="mail">
            <Input placeholder="请输入邮件地址" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"个人行动电话"} name="mobile">
            <Input placeholder="请输入个人行动电话" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={"窗口註记"} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{"取消"}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addCustTb.status === "load" || call_modifyCustTb.status === "load"}
            >
              {"确认"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
