import {
  ClearButton,
  CloseButton,
  CreateButton,
  DeleteButton,
  DetailButton,
  EditButton,
  ExcelButton,
  SeeMoreButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Divider, Drawer, Grid, Input, Modal, Space, Spin, Typography, message } from "antd";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteOrdHD, getOrdHD, updateOrdHD } from "@/service/apis/OMS/Ord_ori";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { DataContext } from "../index";
import DetailModal from "./Modal/DetailModal";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Light from "@/components/ERP/Light";
import PageHeader from "@/components/PageHeader";
import { TourButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const HD = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getOrdHD = useAPI(getOrdHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getOrdHDAD = useAPI(getOrdHD);
  // useAPI 刪除表頭資料
  const call_deleteOrdHD = useAPI(deleteOrdHD);
  // useAPI 強制結令
  const call_updateOrdHd = useAPI(updateOrdHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
  // 客戶資料、人員資料
  const { c_custs, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // ㄧ階搜尋的下拉選單選項
  // const [s_selectOptions, set_s_selectOptions] = useState({
  //   custID: [],
  //   driID: [],
  //   lifeF: [
  //     { value: "0", label: t("OMS.ord.lifeF_0") }, // 發佈
  //     { value: "2", label: t("OMS.ord.lifeF_2") }, // 訂購覆核
  //     { value: "7", label: t("OMS.ord.lifeF_7") }, // 客戶簽回
  //     { value: "T", label: t("OMS.ord.lifeF_T") }, // 強制結令
  //   ],
  // });

  const s_selectOptions = {
    custID: c_custs.options,
    driID: c_staffs.options,
    lifeF: [
      { value: "0", label: "发布" }, // 發佈
      { value: "2", label: "订购覆核" }, // 訂購覆核
      { value: "7", label: t("OMS_ori.ord.lifeF_7") }, // 客戶簽回
      { value: "T", label: t("OMS_ori.ord.lifeF_T") }, // 強制結令
    ],
  };

  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  const [s_detailSearch, set_s_detailSearch] = useState({
    show: false,
    data: {},
  });
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "1310", label: "国内订单" }, // 國內訂單
    { key: "1320", label: "国外订单" }, // 國外訂單
    { key: "detailSearch", label: "进阶查询" }, // 進階查詢
  ];

  const placeholder_map = {
    N: "请输入单号", // 請輸入單號
    custID: "请选择客户", // 請選擇客戶
    lifeF: "请选择历程", // 請選擇歷程
    driID: "请选择负责业务", // 請選擇負責業務
    dealN: "请输入客户单号", // 請輸入客戶單號
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getOrdHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getOrdHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
      startDate: dayjs(searchConfig.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(searchConfig.endDate).format("YYYY-MM-DD"),
    });
    // 更新狀態
    updateState({ lightData: {} });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp, lightData: {} });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getOrdHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 強制結令
  const closeData = (event, rowData) => {
    Modal.confirm({
      title: "销货单强制结令确认", //銷貨單強制結令確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: "请先确认要结令的销货单资料无误，再进行强制结令",
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        // call 更新表頭的API
        // call_updateOrdHd.request({ ...rowData, lifeF: "T" });
        call_updateOrdHd.request({
          APImethod: "close",
          ...rowData,
          lifeF: "T",
        });
      },
    });
    // 阻止冒泡
    event.stopPropagation();
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T" || rowData.count > 0) {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: "销货单删除确认", // 銷貨單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: "请先确认要删除的销货单资料无误，再进行删除",
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_deleteOrdHD.request(rowData);
      },
    });
    event.stopPropagation();
  };

  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getOrdHD.request({
        ...searchConfig,
        opcode: tabKey,
        [searchConfig.searchFlag]: searchConfig.searchValue,
        startDate: dayjs(searchConfig.startDate).format("YYYY-MM-DD"),
        endDate: dayjs(searchConfig.endDate).format("YYYY-MM-DD"),
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getOrdHD.request({
      // ...searchConfig,
      // opcode: "",
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  // useEffect(() => {
  //   set_s_selectOptions((prev) => ({
  //     ...prev,
  //     custID: c_custs.options,
  //     driID: c_staffs.options,
  //   }));
  // }, [c_custs, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdHD;

    if (status === "suc") {
      console.log("data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newOrd_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ ord_Ns: newOrd_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getOrdHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdHDAD;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newOrd_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ pro_Ns: newOrd_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getOrdHDAD.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deleteOrdHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getOrdHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_deleteOrdHD.status]);

  // useAPI 強制結令
  useEffect(() => {
    const { data, status, msg } = call_updateOrdHd;
    if (status === "suc") {
      if (data.APImethod === "close") {
        message.success("强制结令成功");
        // 更新表頭
        call_getOrdHD.request({
          ...searchConfig,
          opcode: tabKey,
          [searchConfig.searchFlag]: searchConfig.searchValue,
        });
      }
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_updateOrdHd.status]);

  const tableColumns = [
    {
      title: t("util.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "5.5%" : "8%",
      render: (text) => {
        const map = {
          "1310": "国内订单", // 國內訂單
          "1320": "国外订单", // 國外訂單
        };
        return map[text];
      },
    },
    {
      title: "单号", // 單號
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8%" : "11%",
      render: (text, rowData, _) => (
        <div className="text-left">
          <span className="mr-1">{text}</span>
          {rowData.linkType !== "" && <Light linkType={rowData.linkType} />}
        </div>
      ),
    },
    {
      title: "客户单号",
      dataIndex: "dealN",
      align: "center",
      width: screens.xxl ? "8%" : "11%",
    },
    {
      title: "币别", // 幣別
      dataIndex: "currencytype",
      align: "center",
      width: screens.xxl ? "7%" : "8.3%",
      // render: (text) => {
      //   return (
      //     s_currencys.find((item) => item.currencyitem === text)?.itemNM ??
      //     "本幣"
      //   );
      // },
    },
    {
      title: "汇率", // 匯率
      dataIndex: "exchangerate",
      align: "center",
      width: screens.xxl ? "4%" : "4%",
    },
    {
      title: "税别", // 稅別
      dataIndex: "taxtype",
      align: "center",
      width: screens.xxl ? "7.5%" : "8.3%",
    },
    {
      title: "税率", // 稅率
      dataIndex: "taxrate",
      align: "center",
      width: screens.xxl ? "4%" : "5%",
      render: (text, _, __) => `${text}%`,
    },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
    },
    {
      title: "负责业务", // 負責業務
      dataIndex: "driID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: "预计出货日(起)", // 預計出貨日(起)
      dataIndex: "startEDT",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "预计出货日(迄)", // 預計出貨日(迄)
      dataIndex: "endEDT",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "历程", // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => {
        const lifeF_map = {
          0: "发布", // 發佈
          2: "销货覆核", // 銷貨覆核
          7: "客户签回", // 客戶簽回
          T: "结案", // 結案
        };
        return lifeF_map[text];
      },
    },
    {
      title: "历程时间", // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl ? "10%" : "7%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "操作", // 操作
      dataIndex: "render",
      align: "center",
      width: screens.xxl ? "9%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 強制結令 */}
          <CloseButton disabled={rowData.lifeF === "T"} onClick={(e) => closeData(e, rowData)} />
          {/* 刪除表頭資料 */}
          <DeleteButton
            disabled={rowData.lifeF === "T" || rowData.stockCount > 0}
            onClick={(e) => removeData(e, rowData)}
          />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <Spin spinning={call_getOrdHD.status === "load"}>
        <PageHeader
          title="销货作业" // 銷貨作業
          extra={<TourButton />}
        />
        <CustomTabs
          items={tabsData} // CustomTabs 的所有選項
          activeKey={tabKey} // 目前亮起來的 Tab
          onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
          tabBarExtraContent={
            <Space>
              <CustomRangePicker
                disabled={tabKey === "detailSearch"}
                value={[searchConfig?.startDate, searchConfig?.endDate]}
                onCalendarChange={(dates, _, __) => {
                  updateState({
                    searchConfig: {
                      ...searchConfig,
                      startDate: dates[0],
                      endDate: dates[1],
                    },
                  });
                }}
              />

              <Input.Group compact>
                <CustomSelect
                  disabled={tabKey === "detailSearch"}
                  value={searchConfig.searchFlag}
                  placeholder={t("util.util.search")}
                  onChange={(value) => handleChange("searchFlag", value)}
                  options={[
                    { value: "N", label: "单号" }, // 單號
                    { value: "custID", label: "客户ID" }, // 客戶ID
                    { value: "lifeF", label: "历程" }, // 歷程
                    { value: "driID", label: "负责业务" }, // 負責業務
                    { value: "dealN", label: "客户单号" }, // 客戶單號
                  ]}
                />

                {searchConfig.searchFlag === "lifeF" ||
                searchConfig.searchFlag === "custID" ||
                searchConfig.searchFlag === "driID" ? (
                  <CustomSelect
                    showSearch
                    disabled={tabKey === "detailSearch"}
                    value={searchConfig.searchValue}
                    className="w-[250px]"
                    placeholder={placeholder_map[searchConfig.searchFlag]}
                    onChange={(value) => handleChange("searchValue", value)}
                    // 根據searchFlag判斷現在是哪個下拉選單
                    options={s_selectOptions[searchConfig.searchFlag]}
                  />
                ) : (
                  <Input
                    disabled={tabKey === "detailSearch"}
                    value={searchConfig.searchValue}
                    className="w-[250px]"
                    placeholder={placeholder_map[searchConfig.searchFlag]}
                    onChange={(e) => handleChangeInput(e.target.value)}
                  />
                )}
                <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
              </Input.Group>

              {/* 清除搜詢紀錄 */}
              <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
              {/* 新建 */}
              <CreateButton
                disabled={tabKey === "detailSearch"}
                onClick={() => updateState({ mainPage: { show: true, data: {} } })}
              />
            </Space>
          }
        />

        {tabKey === "detailSearch" && (
          <Space className="w-full flex justify-end pb-2">
            <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
            <DetailButton onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: true }))} />
          </Space>
        )}

        <CustomTable
          columns={tableColumns}
          dataSource={s_tableData}
          tbMode={Object.keys(lightData).length > 0}
          lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
          onRow={(record) => {
            return {
              onClick: () =>
                updateState({
                  lightData: record.N === lightData.N ? {} : record,
                }),
              // 雙擊可進入編輯模式
              onDoubleClick: (event) => editData(event, record),
            };
          }}
        />
        {/* 查看更多-抽屜 */}
        <Drawer
          title={
            <Title level={5} className="mb-0">
              {/* 銷貨單主檔 */}
              销货单主档
            </Title>
          }
          placement="right"
          closable={false}
          width={1000}
          onClose={() => set_s_drawer({ show: false, data: {} })}
          open={s_drawer.show}
        >
          <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
        </Drawer>

        {/* 進階搜尋條件-跳顯 */}
        <CustomModal
          width="70%"
          title="设定进阶筛选条件"
          open={s_detailSearch.show}
          onCancel={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
        >
          {s_detailSearch.show && (
            <DetailModal
              s_detailSearch={s_detailSearch}
              set_s_detailSearch={set_s_detailSearch}
              call_getOrdHDAD={call_getOrdHDAD}
            />
          )}
        </CustomModal>
      </Spin>
    </div>
  );
};

export default HD;
