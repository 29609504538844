export default {
  token: {
    fontFamily:
      "-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,'Noto Sans SC',sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji",
    fontSize: 16, // 20 ,18, 16 ,14
    colorText: "#6C6C6C",
    colorHeaderBg: "#fff",
    colorHeaderText: "#5D80EC",
    colorTextDisabled: "rgba(0, 0, 0, 0.6)",
    colorSecondary: "#315AE7",
    colorPurple: "#c616f5",
    colorSkyblue: "#00d3db",
    colorError: "red",
    colorPageHeader: "rgb(108, 108, 108)",
    colorTableHeader: "#c0dcf0",
    colorTableCellRowHover: "rgba(212,236,254,0.5)",
    colorTableOnCellRow: "rgba(255,241,171,0.5)",
    // colorTableCellRowHover: "rgba(197, 59, 247 , 0.22)",
    // colorTableOnCellRow: "rgba(58, 73, 247 , 0.25)",
  },
  components: {
    Modal: {
      wireframe: true, //線條風格
    },
    Menu: {
      fontSize: 16,
      marginXXS: 0,
    },
    Tabs: {
      fontSize: 20,
      colorTextDisabled: "rgba(0, 0, 0, 0.25)",
    },
    Table: {
      padding: 6, //上下間距
      paddingContentVertical: 6,
      paddingContentVerticalLG: 6,
      paddingContentVerticalSM: 6,
      paddingLG: 6,
      paddingMD: 6,
      paddingSM: 6,
      paddingXL: 6,
      paddingXS: 6,
    },
    Collapse: {
      colorTextHeading: "#5D80EC",
      // fontSize: "20px",
    },
  },
};
