import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

// 取得表頭資料
export const getCheckHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCheckHD`, { params: data });
};

export const getCheckHDAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCheckHD`, { params: data });
};

// 取得驗退單
export const getOutRtn = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutRtn`, { params: data });
};
//-----------------------------------------表身資料----------------------------------------
export const getCheckTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCheckTB`, { params: data });
};

export const deleteCheck = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteCheck`, data);
};
export const deleteCheckTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteCheckTb`, data);
};
export const addCheckTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addCheckTb`, data);
};

// 新建表頭表身
export const addCheck = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addCheck`, data);
};

export const updateCheckHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateCheckHD`, data);
};
