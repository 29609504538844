// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import TbTitle from "@/components/ERP/OMS/TbTitle";
// util function
import { formatAmount } from "@/util/format";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { useTranslation } from "react-i18next";

const TbContainer = ({ call_getApTB, s_tbData, s_disabled }) => {
  const { getStaffName } = useContext(ERPContext);
  const { t } = useTranslation();
  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 報價單號
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "8%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.ord.payment"), // 負責業務
      dataIndex: "payment",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
    },
    {
      title: t("OMS.ap-manage.rcv_subtotal"), // 金額
      dataIndex: "itemsum",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "预收款金额",
      dataIndex: "cumpay",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("OMS.util.table_operate"), // 操作
    //   dataIndex: "operate",
    //   align: "center",
    //   width: "3%",
    //   render: (_, rowData, __) => (
    //     <Space className="px-2">
    //       {/* 查看更多 */}
    //       <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 對帳明細 */}
        <TbTitle>{t("OMS.ap-manage.ap_tb")}</TbTitle>
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />
    </div>
  );
};

export default TbContainer;
