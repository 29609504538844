import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";

import { getPF, deletePF } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";
import dayjs from "dayjs";

const ProccessFlow = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getPF = useAPI(getPF);
  const call_deletePF = useAPI(deletePF);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getPF.request({ psID: s_searchData.item });

  const tableColumns = [
    {
      title: t("util.SN"), //序
      dataIndex: "SN",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.procedure-manage.pfID"), //"製程ID",
      dataIndex: "pfID",
      align: "center",
      width: "8%",
    },
    {
      title: t("util.name"), //"名稱",
      dataIndex: "pfNM",
      width: "20%",
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      align: "left",
      // width: "40%",
    },
    {
      title: t("util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      sorter: (a, b) => a.isvalid - b.isvalid,
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪除資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deletePF.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_s_editData({});
    if (call_getPF.status === "suc") {
      // message.success(call_getPF.msg);
      set_s_tableData(
        call_getPF.data?.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getPF.status === "err") {
      message.error(t(`errorCode.${call_getPF.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPF.status]);

  useEffect(() => {
    if (call_deletePF.status === "suc") {
      message.success(call_deletePF.msg);
    } else if (call_deletePF.status === "err") {
      message.error(t(`errorCode.${call_deletePF.msg}`));
    }
    // delete之後 重call表格資料
    onSearch();
  }, [call_deletePF.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.map((item, index) => {
          return { ...item, key: index, SN: index + 1 };
        })}
        loading={call_getPF.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              set_s_editData(record);
              set_s_isShowModal(true);
            },
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.procedure-manage.tabFlow")}`
            : `${t("util.add")}${t("ADM.procedure-manage.tabFlow")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default ProccessFlow;
