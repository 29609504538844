/* eslint-disable import/no-anonymous-default-export */
export default {
  token: {
    colorBgLayout: "rgb(255, 255, 255)",
    fontSize: 16, // 20 ,18, 16 ,14
    colorText: "#6C6C6C",
    // colorPrimaryTextHover: "rgba(0, 0, 0, 0.6)",
    colorPrimary: "#4973B5",
    colorHeaderBg: "#273D56",
    colorHeaderText: "#FFF",
    colorPageHeader: "rgb(108, 108, 108)",
    colorTableHeader_light: "#F5F5F5",
    colorTableHeaderText_light: "#4973B5",
    colorTableHeaderText_dark: "#FFF",
    colorTableHeader_dark: "#4973B5",
    colorTableBodyBorder: "#e6e6e6",
    // colorTableCellRowHover: "rgba(236, 236, 93, 0.22)",
    colorTableCellRowHover: "#D7E9F9",
    colorTableOnCellRow: "rgba(255,170,0, .2)",
    colorTableLight: "#FFF",
    colorTableDark: "rgb(249 250 251)",
    tableBg: "#EBEDF9",
  },
  components: {
    Modal: {
      wireframe: true, //線條風格
      colorBgElevated: "#ffffff",
    },
    Steps: {
      colorPrimary: "#4F81CF",
      iconFontSize: 30,
    },
    Button: {
      colorText: "#6C6C6C",
      colorPrimaryTextHover: "#FFF",
    },
    Table: {
      borderRadius: 0,

    },

    DatePicker: {
      colorPrimary: "#4973B5",
    },
    Menu: {
      fontSize: 16,
    },
    Tabs: {
      // fontSize: 20,
      colorPrimaryActive: "#4973B5",
      colorPrimary: "#4973B5",
      colorPrimaryHover: "#5074ab",
    },
    Form: {},
    Table: {
      colorTextHeading: "#4973B5",
      padding: 6, //上下間距
      paddingContentVertical: 6,
      paddingContentVerticalLG: 6,
      paddingContentVerticalSM: 6,
      paddingLG: 6,
      paddingMD: 6,
      paddingSM: 6,
      paddingXL: 6,
      paddingXS: 6,
    },
  },
};
