import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import AddaContainer from "../Details/AddaContainer";
import AddbContainer from "../Details/AddbContainer";
import AddcContainer from "../Details/AddcContainer";
import Commission from "../Details/Commission";
import { CreateButton } from "@/components/ERP/Button";
// util component
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// context
import { MainPageContext } from "../index";
import Postage from "./Postage";
// component
import TbContainer from "../Details/TbContainer";
import { useTranslation } from "react-i18next";

const DetailContainer = (props) => {
  const { t } = useTranslation();
  const { s_disabled, is_editForm, call_updateOrdHd, hd_values } = useContext(MainPageContext);
  // CustomTabs 目前被選中的選項
  const [s_tabKey, set_s_tabKey] = useState("details");
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });
  const tabsData = [
    { key: "details", label: t("OMS.ord.tb") }, // 檢測項目明細
    { key: "Postage", label: t("OMS.ord.postage") }, // 其他費用
    { key: "adda", label: t("OMS.ord.adda") }, // 报价注记
    { key: "addc", label: t("OMS.ord.addc") }, // 預收款項
    { key: "Commission", label: t("OMS.ord.commission") }, // 文件上載
  ];
  // 新建資料
  const createData = () => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">{is_editForm.editFlag.join(",")}</span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateOrdHd.request({
            ...hd_values,
            APImethod: "update",
          });
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          // set_s_tbModal({ show: false, data: {} });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: {} });
  };
  console.log("s_tabKey = ", s_tabKey);
  return (
    <div className="flex flex-col flex-1">
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={s_tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => set_s_tabKey(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <CreateButton
              // onClick={() => set_s_tbModal({ show: true, data: {} })}
              onClick={createData}
              disabled={
                // s_tabKey === "adda" ||
                // s_tabKey === "Commission" ||
                // s_tabKey === "Postage" ||
                s_tabKey === "addc" || s_disabled.createBtn
              }
            />
          </Space>
        }
      />

      {s_tabKey === "details" ? (
        <TbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}

      {/* 四流文件上載 */}
      {s_tabKey === "Commission" && (
        <Commission s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      )}

      {/* 加扣款項 */}
      {s_tabKey === "addb" && <AddbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {/* 條款註記 */}
      {s_tabKey === "adda" && <AddaContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {s_tabKey === "addc" ? (
        <AddcContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}

      {s_tabKey === "Postage" ? (
        <Postage s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}
    </div>
  );
};

export default DetailContainer;
