import { Button, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// util component
import CustomModal from "../CustomAntd/ERP/CustomModal";
import { ERPContext } from "../Context/SystemContext";
import ModalConintaer from "@/pages/ADM/BDM/DetectionManage/Basic/HD/ModalContainer/index";
import { getMgmtcat } from "../../service/apis/ADM/client";
import { getProductSelect } from "../../service/apis/ADM/publicAPI";
// API
import useAPI from "../../hooks/useAPI";
import { useTranslation } from "react-i18next";

const PNButton = ({ disabled = false, custalias, OMSform }) => {
  const { t } = useTranslation();
  const { set_c_PNs } = useContext(ERPContext);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 商品類別
  const [s_mgmtcat75, set_s_mgmtcat75] = useState([]);
  // useAPI 取得料號
  const call_getProduct = useAPI(getProductSelect);
  // useAPI 取得商品類別
  const call_getMgmtcat75 = useAPI(getMgmtcat);
  // 取得商品類別資料
  useEffect(() => {
    call_getMgmtcat75.request({ pageType: "75" });
  }, []);

  // useAPI 取得料號
  useEffect(() => {
    const { data, msg, status } = call_getProduct;

    if (status === "suc") {
      const options = data?.map((item) => ({ label: item.pdtNM, value: item.pdtNM })) ?? [];
      set_c_PNs({
        data: data,
        options: options,
      });
      message.success(msg);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProduct.status]);

  // useAPI 取得商品類別
  useEffect(() => {
    if (call_getMgmtcat75.status === "suc") {
      set_s_mgmtcat75(call_getMgmtcat75.data);
    }
    if (call_getMgmtcat75.status === "err") {
      message.error(t(`errorCode.${call_getMgmtcat75.msg}`));
    }
  }, [call_getMgmtcat75.status]);

  return (
    <>
      <Button type="primary" onClick={() => set_s_isShowModal(true)} disabled={disabled}>
        {/* 新增產品基本資料 */}
        {t("util.add") + t("ADM.pn-manage.basicProduct")}
      </Button>

      <CustomModal
        width="50%"
        title={t("util.add") + t("ADM.pn-manage.basicProduct")}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          call_getProduct.request();
        }}
      >
        {/* {is_showPNModal && <PNModal set_visible={set_is_showPNModal} />} */}
        {s_isShowModal && (
          <ModalConintaer
            s_editData={{}}
            custalias={custalias}
            OMSform={OMSform}
            set_s_isShowModal={set_s_isShowModal}
            arrMap={{
              mgmtcat75: s_mgmtcat75,
            }}
          />
        )}
      </CustomModal>
    </>
  );
};

export default PNButton;
