/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Drawer, Typography, Row, Col } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getPlanTB } from "@/service/apis/PMS/Plan";
// import { getPlanTB } from "../moke";
// util
import { formatAmount } from "@/util/format";
// component
import { RecButton } from "@/components/ERP/Button";
import DrawerModal from "./Modal/DrawerModal";
import RecDrawer from "./Modal/RecDrawer";
const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getPlanTB = useAPI(getPlanTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;

  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  const [s_recDrawer, set_s_recDrawer] = useState({ show: false, data: {} });

  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 查看規格
  const openRec = (event, rowData) => {
    set_s_recDrawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 取得表身資料
  useEffect(() => {
    call_getPlanTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getPlanTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getPlanTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "12%",
    },

    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
      width: "12%",
    },
    {
      title: t("PMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: "13%",
    },
    {
      title: t("util.attribute"), // 屬性
      dataIndex: "attribute",
      align: "center",
      width: "9%",
      render: (text) => t(`util.attribute_${text}`),
    },
    {
      title: t("util.pdtclass"), // 商品類別
      dataIndex: "pdtclass",
      align: "left",
      width: "11%",
    },
    {
      title: t("util.drawno"), // 工程圖號
      dataIndex: "drawno",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.matl"), // 原料材質
      dataIndex: "matl",
      align: "center",
      width: "7%",
    },
    {
      title: t("PMS.plan.demandqty"), // 需求數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.note"), // 註記
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "3%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          <RecButton onClick={(e) => openRec(e, rowData)} />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        loading={call_getPlanTB.status === "load"}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 詢價計畫明細 */}
            {t("PMS.plan.tb_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>

      {/* 規格評估-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 詢價計畫明細 */}
            {t("PMS.plan.rec_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_recDrawer({ show: false, data: {} })}
        open={s_recDrawer.show}
      >
        <RecDrawer s_recDrawer={s_recDrawer} set_s_recDrawer={set_s_recDrawer} />
      </Drawer>
    </div>
  );
};

export default TB;
