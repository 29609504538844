import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { addTxnHd, getAllWhID, getWosOut } from "@/service/apis/WMS/WMS";

import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import SystemContext from "@/components/Context/SystemContext";
import dayjs from "dayjs";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const OutInvModal = ({ opcode, set_s_showModal }) => {
  const [form] = Form.useForm();
  const f_tb = Form.useWatch("tb", form);
  const { t } = useTranslation();
  const call_getAllWhID = useAPI(getAllWhID);
  const call_addTxnHd = useAPI(addTxnHd);

  const handleCancel = () => {
    set_s_showModal(false);
  };

  const onSubmit = async (values) => {
    let temp = {
      ...values,
      opcode: opcode,
      opUUID: values.opUUID,
      whID: values.whID,
      tb: values.tb,
    };

    console.log(temp);

    // return;

    // if (Object.keys(s_editData).length === 0) {
    call_addTxnHd.request(temp);
    // }
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  // 給form起始值 & call api
  useEffect(() => {
    call_getAllWhID.request();

    form.setFieldsValue({
      opcode: opcode,
      // ...s_editData,
    });
  }, []);

  useEffect(() => {
    if (call_addTxnHd.status === "suc") {
      message.success(call_addTxnHd.msg);
      set_s_showModal(false);
    } else if (call_addTxnHd.status === "err") {
      message.error(call_addTxnHd.msg);
    }
  }, [call_addTxnHd.status]);

  useEffect(() => {
    const qty =
      f_tb?.reduce((prev, curr) => {
        let nowQty = curr.planqty || 0;
        prev = prev + nowQty;
        return prev;
      }, 0) || 0;

    form.setFieldsValue({ qty });
  }, [f_tb]);

  console.log("f_tb = ", f_tb);

  return (
    <Form onFinish={onSubmit} name="basic" form={form} autoComplete="off" layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            // 作業單別
            label={t("WMS.util.opcode")}
            name="opcode"
          >
            <Input disabled placeholder={t("util.placeholder_select") + t("WMS.util.opcode")} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            // 作業單號
            label={t("WMS.util.jobN")}
            name="jobN"
          >
            <Input disabled placeholder={t("util.placeholder_input") + t("WMS.util.jobN")} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            // 倉別
            label={t("util.whID")}
            name="whID"
            shouldUpdate
          >
            <Select
              placeholder={t("util.placeholder_select") + t("util.whID")}
              onChange={(e) => {
                let formData = form.getFieldValue();
                if (formData.tb?.length > 0) {
                  formData.tb = formData.tb.map((x) => {
                    return { ...x, whID: e };
                  });
                  form.setFieldsValue({ ...formData });
                }
              }}
            >
              {call_getAllWhID.data.map((x) => (
                <Select.Option key={x.whID} value={x.whID}>
                  {x.whNM}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t(`util.total`)} name="qty" shouldUpdate>
            <InputNumber className="w-full" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item
            label={<label style={{ color: "#6C6C6C" }}>備註</label>}
            name="note"
            // rules={[{ required: true }]}
          >
            <Input.TextArea name="note" rows={4} />
          </Form.Item>
        </Col>
      </Row>

      {/* {Object.keys(s_editData).length === 0 && ( */}
      <div className="tbWrapper">
        <Divider style={{ margin: "6px" }} />
        <Row gutter={[36, 12]}>
          <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
            {t(`WMS.util.opcode_${opcode}`) + t("APS.util.detail")}
          </Col>
        </Row>

        <Form.List name="tb" shouldUpdate>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} gutter={[12, 12]}>
                  <Col span={2} className="mb12">
                    <Form.Item
                      {...field}
                      //序
                      label="序"
                      name={[field.name, "itemno"]}
                    >
                      <Input disabled value={index + 1} />
                    </Form.Item>
                  </Col>

                  <Col span={7} className="mb12">
                    <Form.Item
                      {...field}
                      // 料號
                      label={t("util.PN")}
                      rules={[
                        { required: true, message: t("util.placeholder_select") + t("util.PN") },
                      ]}
                      name={[field.name, "PN"]}
                    >
                      <DebounceSelect
                        key="DebounceSelect"
                        // className="w-[300px]"
                        placeholder={t("util.placeholder_input") + " " + t("util.PN")}
                        allowClear
                        fetchOptions={fetchPNList}
                        showSearch
                        maxTagCount={10}
                        onChange={(value, option) => {
                          let _tb = form.getFieldValue("tb");
                          _tb[field.name].PN = option.data.PN;
                          _tb[field.name].idmtype = option.data.idmtype;
                          _tb[field.name].pdtNM = option.data.pdtNM;
                          form.setFieldsValue({ tb: _tb });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6} className="mb12">
                    <Form.Item
                      {...field}
                      // 品名
                      label={t("util.pdtNM")}
                      shouldUpdate
                      name={[field.name, "pdtNM"]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      {...field}
                      // 倉別
                      label={t("util.whID")}
                      name={[field.name, "whID"]}
                    >
                      <Select
                        placeholder={t("util.placeholder_select") + t("util.whID")}
                        onChange={(e) => {
                          let formData = form.getFieldValue();
                          formData["whID"] = undefined;
                          form.setFieldsValue(formData);
                        }}
                      >
                        {call_getAllWhID.data.map((x) => (
                          <Select.Option key={x.whID} value={x.whID}>
                            {x.whNM}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* <Col span={3} className="mb12">
                      <Form.Item
                        {...field}
                        name={[field.name, "batchno"]}
                        // 原料出庫單號
                        label={t(`WMS.util.batchno_${opcode}`)}
                        rules={[
                          {
                            required: true,
                            message: t("util.placeholder_select") + t(`WMS.util.batchno_${opcode}`),
                          },
                        ]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Col> */}

                  <Col span={3} className="mb12">
                    <Form.Item
                      {...field}
                      // 計劃數量
                      label={t("util.planqty")}
                      rules={[
                        {
                          required: true,
                          message: "請輸入大於0的數量!",
                          validator: (_, value) => {
                            return new Promise(async (resolve, reject) => {
                              if (!value) {
                                await reject("請輸入數量");
                              } else if (value - 0 === NaN) {
                                await reject("請輸入大於0的數字");
                              } else {
                                await resolve();
                              }
                            });
                          },
                        },
                      ]}
                      name={[field.name, "planqty"]}
                    >
                      <InputNumber min={0} style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>

                  {/* <Col span={3} className="mb12">
                      <Form.Item
                        {...field}
                        label={t("WMS.util.workOrderN")} // 工令單號
                        name={[field.name, "workOrderN"]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Col> */}

                  <Col span={1} className="flex-center">
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                        let formData = form.getFieldValue();
                        formData.tb = formData.tb.map((x, index) => {
                          return { ...x, itemno: index + 1 };
                        });
                        form.setFieldsValue(formData);
                      }}
                      style={{ fontSize: "20px", marginLeft: "5px" }}
                    />
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  //新增生產入庫詳情
                  type="dashed"
                  onClick={() => {
                    let formData = form.getFieldsValue();
                    let whID = formData?.whID;
                    add({ itemno: fields.length + 1, whID: whID });
                  }}
                  block
                  icon={<PlusOutlined />}
                  size="large"
                >
                  {t("util.add") + t(`WMS.util.opcode_${opcode}`) + t("util.detail")}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
      {/* )} */}

      <Divider style={{ margin: "0px 0px 12px 0px" }} />

      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button size="large" onClick={handleCancel}>
              {/* 取消 */}
              {t("util.cancel")}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={call_addTxnHd.status === "load"}
            >
              {/* 確定 */}
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default OutInvModal;
