import { Button, Col, Divider, Form, Input, Row, Select, Spin, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// api
import useAPI from "@/hooks/useAPI";
import { getWh } from "@/service/apis/ADM/publicAPI";
import { addInvHd } from "@/service/apis/WMS/Inventory";

import { addInvEntoryHd } from "../../moke";

const ModalContainer = ({
	s_spinning,
	c_lightData,
	set_s_spinning,
	s_tabKey,
	s_editData,
	set_is_showModal,
}) => {
	const { t } = useTranslation();

	const isCreate = Object.keys(s_editData).length === 0;
	const call_getWh = useAPI(getWh);
	const call_addInvHd = useAPI(addInvHd);

	const [form] = Form.useForm();

	useEffect(() => {
		call_getWh.request();
		if (isCreate) {
			console.log("c_lightData = ", c_lightData);
			form.setFieldsValue({ preopUUID: c_lightData.opUUID, opcode: s_tabKey });
		}
	}, []);

	// useAPI 取得倉別
	useEffect(() => {
		const { status, msg } = call_getWh;

		if (status === "err") {
			message.error(msg);
		}
	}, [call_getWh.status]);

	useEffect(() => {
		const { status, msg } = call_addInvHd;
		if (status === "suc") {
			message.success(msg);
			set_is_showModal(false);
			set_s_spinning(false);
		}
		if (status === "err") {
			message.error(msg);
			set_is_showModal(false);
			set_s_spinning(false);
		}
	}, [call_addInvHd.status]);

	const onSubmit = (values) => {
		if (isCreate) {
			call_addInvHd.request(values);
			set_s_spinning(true);
		}
	};

	return (
		<Spin spinning={s_spinning}>
			<Form
				form={form}
				initialValues={{
					isvalid: true,
				}}
				onFinish={onSubmit}
				layout="vertical"
			>
				{/* 隱藏欄位 */}
				<div>
					<Form.Item name="preopUUID" hidden>
						<Input />
					</Form.Item>
					<Form.Item name="opcode" hidden>
						<Input />
					</Form.Item>
				</div>
				<Row gutter={[12, 12]}>
					<Col span={8}>
						<Form.Item
							label={t("util.util.whID")} // 倉別
							name="whID"
							rules={[{ required: true }]}
						>
							<Select
								placeholder={t("util.util.placeholder_select") + t("util.util.whID")}
								options={call_getWh.data?.map((item) => ({
									label: item.whNM,
									value: item.whID,
								}))}
							/>
						</Form.Item>
					</Col>

					<Col span={16} />

					<Col span={12}>
						<Form.Item
							// 篩選條件記録
							label={"筛选条件记录"}
							name="selectnote"
						>
							<Input.TextArea
								rows={4}
								placeholder={t("util.util.placeholder_input") + ("筛选条件记录")}
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item label={t("util.util.note")} name="note">
							<Input.TextArea
								rows={4}
								placeholder={t("util.util.placeholder_input") + t("util.util.note")}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<div className="flex justify-end gap-2">
					<Button size="large" onClick={() => set_is_showModal(false)}>
						{/* 取消 */}
						{t("util.util.cancel")}
					</Button>
					<Button
						size="large"
						type="primary"
						htmlType="submit"
						loading={call_addInvHd.status === "load"}
					>
						{/* 確定 */}
						{t("util.util.ok")}
					</Button>
				</div>
			</Form>
		</Spin>
	);
};

export default ModalContainer;
