import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, message, Radio, Space, DatePicker, Select } from "antd";
import { useTranslation } from "react-i18next";
import { getCustTb } from "@/service/apis/ADM/client";
import { addCustRec, modifyCustRec } from "@/service/apis/ADM/client";
import useAPI from "@/hooks/useAPI";
import dayjs from "dayjs";

export default function ModalConintaer({
  s_staff_options,
  s_editData,
  set_s_isShowModal,
  c_lightData,
}) {
  const { t } = useTranslation();
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const [form] = Form.useForm();
  // 聯絡人下拉選單
  const [s_contact_options, set_s_contact_options] = useState([]);

  // API
  const call_getCustTb = useAPI(getCustTb);
  const call_addCustRec = useAPI(addCustRec);
  const call_modifyCustRec = useAPI(modifyCustRec);

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const postData = {
      custID: c_lightData.custID,
      itemUUID: s_editData.itemUUID,
      itemno: s_editData.itemno,
      ...values,
    };

    if (type === "create") {
      call_addCustRec.request(postData);
    }

    if (type === "edit") {
      call_modifyCustRec.request(postData);
    }
  };

  useEffect(() => {
    if (call_addCustRec.status === "suc") {
      message.success(call_addCustRec.msg);
      set_s_isShowModal(false);
    } else if (call_addCustRec.status === "err") {
      message.error(t(`errorCode.${addCustRec.msg}`));
    }
  }, [call_addCustRec.status]);

  useEffect(() => {
    if (call_modifyCustRec.status === "suc") {
      message.success(call_modifyCustRec.msg);
      set_s_isShowModal(false);
    } else if (call_modifyCustRec.status === "err") {
      message.error(t(`errorCode.${call_modifyCustRec.msg}`));
    }
  }, [call_modifyCustRec.status]);
  console.log(dayjs(s_editData?.recT));
  useEffect(() => {
    call_getCustTb.request(c_lightData);
  }, []);

  // useAPI 取得聯絡人
  useEffect(() => {
    const { status, msg, data } = call_getCustTb;
    if (status === "suc") {
      const _data =
        data?.map((cust) => ({
          label: cust.contact,
          value: cust.contact,
        })) || [];
      set_s_contact_options(_data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getCustTb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,

        ...s_editData,
        recT: type === "create" ? dayjs() : dayjs(s_editData?.recT),
        remdT: type === "create" ? dayjs() : dayjs(s_editData?.remdT),
      }}
    >
      {/* 影藏欄位 */}
      <div>
        <Form.Item label={t("util.isvalid")} hidden name="isvalid">
          <Radio.Group>
            <Radio value={true}>{t("util.yes")}</Radio>
            <Radio value={false}>{t("util.no")}</Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item hidden name="itemUUID">
          <Input />
        </Form.Item> */}
      </div>

      <Row gutter={[24, 12]}>
        {/* <Col span={8}>
          <Form.Item label={t("ADM.client.itemNM")} name="itemNM">
            <Input />
          </Form.Item>
        </Col> */}

        {/* <Col span={8}>
          <Form.Item label={t("util.isvalid")} name="isvalid">
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col> */}
        {/* <Col span={8}></Col> */}
        <Col span={8}>
          <Form.Item label={"窗口ID"} name="itemUUID" rules={[{ required: true }]}>
            <Select options={s_contact_options} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"记录日期"} name="recT" rules={[{ required: true }]}>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={"提醒日期"} name="remdT">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"客户生产产品"} name="custpdt">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"客户验证需求"} name="custitem">
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={"负责业务"} name="mgmtcat">
            <Select options={s_staff_options} />
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item label={t("ADM.client.addr")} name="addr">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col> */}

        <Col span={24}>
          <Form.Item label={"讯息内容"} name="content">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={"追踪事项"} name="reminder">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{"取消"}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addCustRec.status === "load" || call_modifyCustRec.status === "load"}
            >
              {"确认"}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
