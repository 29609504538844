import { Input, DatePicker, Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

const CustomRangePicker = ({ children, ...props }) => {
  return (
    <Input.Group className="w-full flex" compact>
      <DatePicker.RangePicker className="hover:border-gray-300" suffixIcon={null} {...props} />
      <Button
        className="text-white bg-gray-300 hover:border-gray-300"
        icon={<CalendarOutlined />}
      />
    </Input.Group>
  );
};

export default CustomRangePicker;
