/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Drawer, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getInstockTB } from "@/service/apis/PMS/Instock";
// util
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getInstockTB = useAPI(getInstockTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getInstockTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getInstockTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getInstockTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "3%" : "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "18%" : "18%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: t("PMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => (text === "unit" ? t("PMS.util.unit") : t("PMS.util.opunit")), //"計量單位" ,"操作單位"
    },
    {
      title: t("PMS.instock.punit"), // 使用單位
      dataIndex: "punit",
      align: "center",
      width: screens.xxl ? "7%" : "9%",
    },
    {
      title: t("PMS.util.unitprice"), // 採購單價(未稅)
      dataIndex: "unitprice",
      align: "right",
      width: screens.xxl ? "9%" : "12%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.indqty"), // 暫收數量
      dataIndex: "indqty",
      align: "right",
      width: screens.xxl ? "6%" : "9%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.goqty"), // 驗收數量
      dataIndex: "goqty",
      align: "right",
      width: screens.xxl ? "6%" : "9%",
      onCell: (_, __) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
            color: "#6A68A1",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.rejectqty"), // 驗退數量
      dataIndex: "rejectqty",
      align: "right",
      width: screens.xxl ? "6%" : "9%",
      onCell: (_, __) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
            color: "#6A68A1",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      // width: '60px',
      width: screens.xxl ? "3%" : "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={() => set_s_drawer({ show: true, data: rowData })} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 進料單明細 */}
            {t("PMS.instock.tb")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default TB;
