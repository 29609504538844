/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import { Divider, Row, Col, Button, message, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { CancelButton, TransferButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { ordToOutstock } from "@/service/apis/OMS/Ord";
// util function
import { formatAmount } from "@/util/format";

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(values.qty ?? 0, record.itemUUID);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     // message: `${title} is required.`,
        //   },
        // ]}
      >
        <InputNumber
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ width: "100%" }}
          min={0}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const OutstockModal = ({
  set_is_showOutstock,
  s_ordTb,
  hd_values,
  call_getOrdHD,
  call_getOrdTB,
}) => {
  const { t } = useTranslation();
  const call_ordToOutstock = useAPI(ordToOutstock);

  // 拋轉介面的資料
  const [s_dataSourec, set_s_dataSourec] = useState(s_ordTb.tb);

  const [s_ori_dataSourec, set_s_ori_dataSourec] = useState(s_ordTb.tb);

  // 關閉拋轉介面
  const onCancel = () => {
    set_is_showOutstock(false);
  };

  const outstock_opcode = {
    1310: "1410",
    1320: "1420",
  };

  // 確定拋轉
  const onSubmit = async () => {
    const tmp = {
      hd: {
        ...hd_values,
        opcode: outstock_opcode[hd_values.opcode],
      },
      tb: s_dataSourec.map((item) => {
        return {
          ...item,
          // 剩餘數量 > 0 自動結令
          lifeF: item.balqty <= 0 ? "T" : item.lifeF,
          // lifeF: item.balqty < item.qty ? 'T' : item.lifeF,
          qty: item.qty ?? 0,
        };
      }),
    };
    console.log("要傳給後端的資料 = ", tmp);
    // call 拋轉的API
    call_ordToOutstock.request(tmp);
  };

  // 取得表頭資料，用來建立新【發貨單】
  useEffect(() => {
    const tmpdata = s_ordTb.tb.map((item) => {
      return {
        ...item,
        balqty: 0,
        // 本次拋轉數量預設為剩餘數量
        qty: item.balqty,
      };
    });
    set_s_dataSourec(tmpdata);
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    if (call_ordToOutstock.status === "suc") {
      // 拋轉成功要更新資料
      message.success(call_ordToOutstock.msg);
      // 更新表頭
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // 更新表身
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      set_is_showOutstock(false);
    }
    if (call_ordToOutstock.status === "err") {
      message.error(t(`error.error-code.${call_ordToOutstock.msg}`));
      set_is_showOutstock(false);
    }
  }, [call_ordToOutstock.status]);

  const tableCcolumns = [
    {
      title: t("util.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "20%",
    },
    {
      title: t("util.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS_ori.ord.confirmqty"), // 客戶確認數量
      dataIndex: "confirmqty",
      align: "center",
      width: "9%",
      render: (text, _, __) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.transferqty"), // 已拋轉數量
      dataIndex: "transferqty",
      align: "center",
      width: "8%",
      onCell: (rowData) => {
        if (rowData.lifeF !== "T") {
          return {
            style: {
              backgroundColor: "rgba(208, 211, 230, .44)",
              color: "#4B6EA2",
            },
          };
        }
      },
      render: (text, _, __) => formatAmount(text),
    },
    {
      title: t("util.util.balqty"), // 剩餘數量
      dataIndex: "balqty",
      align: "center",
      width: "6%",
      onCell: (rowData) => {
        if (rowData.lifeF !== "T") {
          return {
            style: {
              backgroundColor: "rgba(208, 211, 230, .44)",
              color: rowData.balqty < 0 ? "red" : "#4B6EA2",
            },
          };
        }
      },
      render: (text, _, __) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.qty"), // 本次拋轉數量
      dataIndex: "qty",
      align: "center",
      width: "10%",
      editable: true,
      onCell: (rowData) => {
        if (rowData.lifeF !== "T") {
          return {
            style: {
              color: "#FF850A",
            },
          };
        }
      },
      render: (text, _, __) => formatAmount(text),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleSave = (qty = 0, itemUUID) => {
    if (parseInt(qty) < 0) {
      //拋轉數量不可小於0
      message.error(t("OMS_ori.out-stock.throwQty_msg"));
      return;
    }

    const targetItem = s_ori_dataSourec.find((item) => item.itemUUID === itemUUID);
    if (!targetItem) {
      return;
    }

    const updatedItem = {
      ...targetItem,
      editable: true,
      qty: parseInt(qty),
      // 已拋數量
      transferqty: parseInt(targetItem.transferqty) + parseInt(qty),
      balqty:
        parseInt(targetItem.confirmqty) +
        targetItem.returnqty -
        parseInt(targetItem.transferqty) -
        parseInt(qty),
    };

    const updatedData = s_dataSourec.map((item) =>
      item.itemUUID === itemUUID ? updatedItem : item
    );

    set_s_dataSourec(updatedData);
  };

  const columns = tableCcolumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        style: {
          backgroundColor: record?.lifeF === "T" ? "#e1dbdb" : "",
          pointerEvents: record?.lifeF === "T" ? "none" : "",
          opacity: record?.lifeF === "T" ? ".5" : "1",
          color:
            record?.lifeF === "T" ? "#b6b1b1" : record?.confirmqty === 0 ? "#b6b1b1" : "#FF850A",
        },
        // handleSave,
      }),
    };
  });

  return (
    <Wrapper>
      <CustomTable
        components={components}
        columns={columns}
        rowNumber={6}
        rowClassName={(record, _) => {
          if (record.lifeF === "T") {
            return "editable-row bg-zinc-300 opacity-50 cursor-not-allowed";
          }
          return "editable-row";
        }}
        dataSource={s_dataSourec?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={onCancel} />
          <TransferButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </Wrapper>
  );
};

export default OutstockModal;
