import {
  ClearButton,
  CreateButton,
  DeleteButton,
  DetailButton,
  EditButton,
  ExcelButton,
  SeeMoreButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Divider, Drawer, Grid, Input, Modal, Space, Typography, message, theme } from "antd";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteInstockHD, getInstockHD, getInstockHdAd } from "@/service/apis/PMS/Instock";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { DataContext } from "../index";
import DetailModal from "./Modal/DetailModal";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Light from "@/components/ERP/Light";
import PageHeader from "@/components/PageHeader";
import { TourButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { useToken } = theme;
const HD = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  // useAPI 取得表頭資料
  const call_getInstockHD = useAPI(getInstockHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getInstockHDAd = useAPI(getInstockHdAd);
  // useAPI 刪除表頭資料
  const call_deleteInstockHD = useAPI(deleteInstockHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG, detailSearch } = c_state;
  // 廠商資料、人員資料
  const { c_supps, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    suppID: [],
    driID: [],
    lifeF: [
      { value: "0", label: t("PMS.instock.lifeF_0") }, //"發佈"
      { value: "2", label: t("PMS.instock.lifeF_2") }, //"進料覆核"
      { value: "3", label: t("PMS.instock.lifeF_3") }, //"IQC抽驗"
      { value: "4", label: t("PMS.instock.lifeF_4") }, //"IQC完驗"
      { value: "7", label: t("PMS.instock.lifeF_7") }, //"品保覆核"
      { value: "T", label: t("PMS.instock.lifeF_T") }, //"結案"
    ],
  });
  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  // const [s_detailSearch, set_s_detailSearch] = useState({
  //   show: false,
  //   data: {},
  // });
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "2410", label: t("PMS.util.opcode_2410") }, // 國內進料
    { key: "2420", label: t("PMS.util.opcode_2420") }, // 國外進料
    { key: "2430", label: t("PMS.util.opcode_2430") }, // 委外進料
    { key: "detailSearch", label: t("PMS.util.advanceSearch") }, // 進階查詢
  ];
  const placeholder_map = {
    N: t("PMS.util.placeholder_input") + t("PMS.util.N"), //"請輸入單號",
    suppID: t("PMS.util.suppID_placeholder"), //"請選擇廠商",
    lifeF: t("PMS.util.placeholder_select") + t("PMS.util.lifeF"), //"請選擇歷程",
    sampleID: t("PMS.util.placeholder_select") + t("PMS.instock.sampleID"), //"請選擇採購人員",
  };
  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    console.log("activeKey = ", activeKey);
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getInstockHD.request({ ...tmp, opcode: tabKey });
    }
  };
  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getInstockHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getInstockHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T" || rowData.count > 0) {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("PMS.util.instock") + t("PMS.util.delete_confirm"), // 進料單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的進料單資料無誤，再進行刪除",
      content: t("PMS.instock.del_hd_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteInstockHD.request(rowData),
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    console.log("event = ", event);
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      if (tabKey === "detailSearch") {
        const result = {};
        for (const [key, value] of Object.entries(detailSearch.data)) {
          if (value) {
            result[key] = Array.isArray(value) ? value.join("|") : value;
          }
        }
        call_getInstockHDAd.request(result);
      } else {
        call_getInstockHD.request({
          ...searchConfig,
          opcode: tabKey,
          [searchConfig.searchFlag]: searchConfig.searchValue,
        });
      }
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabkey: opcode,
    });

    call_getInstockHD.request({
      ...searchConfig,
      opcode: opcode,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      suppID: c_supps.options,
      sampleID: c_staffs.options,
    }));
  }, [c_supps, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getInstockHD;
    console.log("data = ", data);
    if (status === "suc") {
      console.log("call_getInstockHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newInstock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ instock_Ns: newInstock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getInstockHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getInstockHDAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newInstock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ instock_Ns: newInstock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getInstockHDAd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    const { msg, status } = call_deleteInstockHD;
    if (status === "suc") {
      message.success(msg);

      if (opcode === null) {
        //刪除完成後要重新取得表頭資料
        call_getInstockHD.request({
          ...searchConfig,
          [searchConfig.searchFlag]: searchConfig.searchValue,
        });
      } else {
        call_getInstockHD.request({
          ...searchConfig,
          opcode: opcode,
          startDate: undefined,
          endDate: undefined,
          [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
        });
      }

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteInstockHD.status]);

  // 表格欄位
  const tableColumns = [
    // {
    //   // title: "單別",
    //   title: t("PMS.util.opcode"),
    //   dataIndex: "opcode",
    //   align: "center",
    //   width: screens.xxl ? "6%" : "8%",
    //   render: (text) => t(`PMS.util.opcode_${text}`),
    // },
    {
      // title: "單號",
      title: t("PMS.util.N"),
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      // onCell: (_) => {
      //   return {
      //     style: {
      //       padding: "0px",
      //     },
      //   };
      // },
      render: (text, rowData, _) => (
        <div className="text-left">
          <span className="mr-1">{text}</span>
          {rowData.linkType !== "" && <Light linkType={rowData.linkType} />}
        </div>
      ),
    },
    {
      // title: "廠商ID",
      title: t("PMS.util.suppID"),
      dataIndex: "suppID",
      align: "center",
      responsive: ["xxl"],
      width: screens.xxl ? "8.3%" : "8.3%",
    },
    // {
    //   // title: "廠商簡稱",
    //   title: t("PMS.util.suppalias"),
    //   dataIndex: "suppalias",
    //   align: "center",
    //   responsive: ["xxl"],
    //   width: screens.xxl ? "14%" : "12%",
    // },
    {
      // title: "廠商名稱",
      title: t("PMS.util.suppNM"),
      dataIndex: "suppNM",
      align: "center",
    },
    {
      // title: "抽樣人員",
      title: t("PMS.pro.sampleID"),
      dataIndex: "sampleID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      // title: "歷程",
      title: t("PMS.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "6%" : "8%",
      render: (text) => {
        const map = {
          0: t("PMS.instock.lifeF_0"),
          2: t("PMS.instock.lifeF_2"),
          3: t("PMS.instock.lifeF_3"),
          4: t("PMS.instock.lifeF_4"),
          7: t("PMS.instock.lifeF_7"),
          T: t("PMS.instock.lifeF_T"),
        };
        return map[text];
      },
    },
    {
      // title: "歷程時間",
      title: t("PMS.util.lifeFT"),
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl ? "10%" : "13%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      // title: "更新人員",
      title: t("PMS.util.updtID"),
      dataIndex: "updtID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      // title: "更新時間",
      title: t("PMS.util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: screens.xxl ? "8%" : "13%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "7%" : "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      {/* 進料作業 */}
      <PageHeader title={t("PMS.instock.pageHeader")} extra={<TourButton />} />
      {/* <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          
        }
      /> */}
      <Space className="flex justify-end">
        <CustomRangePicker
          disabled={tabKey === "detailSearch"}
          value={[searchConfig?.startDate, searchConfig?.endDate]}
          onCalendarChange={(dates, _, __) => {
            updateState({
              searchConfig: {
                ...searchConfig,
                startDate: dates[0],
                endDate: dates[1],
              },
            });
          }}
        />

        <Input.Group compact>
          <CustomSelect
            disabled={tabKey === "detailSearch"}
            value={searchConfig.searchFlag}
            placeholder={t("PMS.util.searchField")}
            onChange={(value) => handleChange("searchFlag", value)}
            options={[
              { value: "N", label: t("PMS.util.N") },
              { value: "suppID", label: t("PMS.util.suppalias") },
              { value: "lifeF", label: t("PMS.util.lifeF") },
              { value: "sampleID", label: t("PMS.instock.sampleID") },
            ]}
          />

          {searchConfig.searchFlag === "lifeF" ||
          searchConfig.searchFlag === "suppID" ||
          searchConfig.searchFlag === "sampleID" ? (
            <CustomSelect
              showSearch
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(value) => handleChange("searchValue", value)}
              // 根據searchFlag判斷現在是哪個下拉選單
              options={s_selectOptions[searchConfig.searchFlag]}
            />
          ) : (
            <Input
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          )}
          <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
        </Input.Group>

        {/* 清除搜詢紀錄 */}
        <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
        {/* 新建 */}
        <CreateButton
          disabled={tabKey === "detailSearch"}
          onClick={() => updateState({ mainPage: { show: true, data: {} } })}
        />
      </Space>

      <Divider className="my-2" />
      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
          <DetailButton
            onClick={() => updateState({ detailSearch: { ...detailSearch, show: true } })}
          />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getInstockHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 進料單主檔 */}
            {t("PMS.instock.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={900}
        open={s_drawer.show}
        onClose={() => set_s_drawer({ show: false, data: {} })}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("PMS.util.setAdvanced")}
        open={detailSearch.show}
        onCancel={() => updateState({ detailSearch: { ...detailSearch, show: false } })}
      >
        {detailSearch.show && <DetailModal call_getInstockHDAd={call_getInstockHDAd} />}
      </CustomModal>
    </div>
  );
};

export default HD;
