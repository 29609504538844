import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { Modal, Space, message } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { freezeStaff, getFunctions, getPushlvl, getStaff } from "@/service/apis/ADM/userManage";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const StaffData = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getStaff = useAPI(getStaff);
  const call_freezeStaff = useAPI(freezeStaff);
  const call_getFunctions = useAPI(getFunctions); // 取得組織功能
  const call_getPushlvl = useAPI(getPushlvl); // 取得推播層級
  const call_getMgmtcat = useAPI(getMgmtcat); // 取得推播層級

  const tableColumns = [
    {
      title: t("ADM.user-manage.staffID"), //員工工號

      dataIndex: "staffID",
      align: "center",
      width: "10%",
    },
    {
      title: t("ADM.user-manage.peopleNM"), //員工姓名
      dataIndex: "peopleNM",
      width: "9%",
    },
    {
      title: t("ADM.user-manage.mail"), //郵址
      dataIndex: "mail",
      width: "15%",
    },
    {
      title: t("ADM.user-manage.cardID"), //卡勤識別碼
      dataIndex: "cardID",
      width: "6%",
      align: "center",
    },
    {
      title: t("ADM.user-manage.tel"), //電話
      dataIndex: "tel",
      width: "8%",
    },
    {
      title: t("util.dept"), //部門
      dataIndex: "deptNM",
      width: "10%",
    },
    {
      title: t("ADM.user-manage.function"), //奖金类型
      dataIndex: "function",
      // className: "bg-[#f9e9d7ac]",
      width: "8%",
      align: "center",
      render: (text) => text && t(`ADM.user-manage.function_${text}`),
      //   render: (text) =>
      //     text
      //       ?.reduce((data, current) => {
      //         current === "All" && data.push(t("util.all"));
      //         const res = call_getFunctions.data.filter((x) => x.funcode === current);
      //         res.length > 0 && data.push(res[0].funNM);
      //         return data;
      //       }, [])
      //       .join("，") || "",
    },
    {
      title: t("util.util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    // {
    //   title: t("ADM.user-manage.dealmgmt"), //對象授權分類
    //   dataIndex: "dealmgmt",
    //   className: "bg-[#f9e9d7ac]",
    //   width: "8%",
    //   render: (text) => joinColMgmtcat(text, "supp|cust"),
    // },
    // {
    //   title: t("ADM.user-manage.pdtmgmt"), //物料授權分類
    //   dataIndex: "pdtmgmt",
    //   width: "8%",
    //   className: "bg-[#f9e9d7ac]",
    //   render: (text) => joinColMgmtcat(text, "pdt"),
    // },
    // {
    //   title: t("ADM.user-manage.whmgmt"), //倉庫授權分類
    //   dataIndex: "whmgmt",
    //   width: "8%",
    //   className: "bg-[#f9e9d7ac]",
    //   render: (text) => joinColMgmtcat(text, "wh"),
    // },
    {
      title: t("util.action"), //操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];
  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getStaff.request({ staffID: s_searchData.query });

  const joinColMgmtcat = (text, type) => {
    return text
      .reduce((data, current) => {
        current === "All" && data.push(t("util.all"));
        const res = call_getMgmtcat.data.find(
          (x) => x.mgmtitem === current && type.includes(x.type)
        );
        res && data.push(res.itemNM);
        return data;
      }, [])
      .join("，");
  };

  const remove = (rowData) => {
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "請確認您希望删除此資料",
      okText: "刪除",
      onOk: () => call_freezeStaff.request(rowData),
      cancelText: "取消",
    });
  };

  useEffect(() => {
    call_getStaff.request();
    call_getPushlvl.request();
    call_getFunctions.request();
    call_getMgmtcat.request({ pageType: 71 });
  }, []);

  useEffect(() => {
    if (call_getStaff.status === "suc") {
      message.success(call_getStaff.msg);

      // 整理資料
      set_s_tableData(
        call_getStaff.data.map((x) => ({
          ...x,
          key: x.staffID,
          // function: x.function?.split("|").filter((x) => x !== "") || [],
          pdtmgmt: x.pdtmgmt?.split("|").filter((x) => x !== "") || [],
          dealmgmt: x.dealmgmt?.split("|").filter((x) => x !== "") || [],
          whmgmt: x.whmgmt?.split("|").filter((x) => x !== "") || [],
        }))
      );
    } else if (call_getStaff.status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${call_getStaff.msg}`));
    }
  }, [call_getStaff.status]);

  useEffect(() => {
    if (call_freezeStaff.status === "suc") {
      message.success(call_freezeStaff.msg);
    } else if (call_freezeStaff.status === "err") {
      message.error(t(`errorCode.${call_freezeStaff.msg}`));
    }
    call_getStaff.request();
  }, [call_freezeStaff.status]);

  useEffect(() => {
    if (call_getFunctions.status === "err") {
      message.error(t(`errorCode.${call_getFunctions.msg}`));
    }
  }, [call_getFunctions.status]);

  useEffect(() => {
    if (call_getPushlvl.status === "err") {
      message.error(t(`errorCode.${call_getPushlvl.msg}`));
    }
  }, [call_getPushlvl.status]);

  useEffect(() => {
    if (call_getMgmtcat.status === "err") {
      message.error(t(`errorCode.${call_getMgmtcat.msg}`));
    }
  }, [call_getMgmtcat.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getStaff.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.user-manage.pageHeader")}`
            : `${t("util.add")}${t("ADM.user-manage.pageHeader")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getStaff.request();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
    </>
  );
});

export default StaffData;
