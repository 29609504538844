import { instance } from "../../handlerAPI";
import { ims_apiBasic } from "@/service/APIBasic";
//----------------------------------------- 入庫查詢 getAllTable----------------------------------------
export const getAllInv = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getAllInv`, {
    params: _params,
  });
};

export const getAllWhInv = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getAllWhInv`, {
    params: _params,
  });
};
export const getInvBatch = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getInvBatch`, {
    params: _params,
  });
};

export const getDetail = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getDetail`, {
    params: _params,
  });
};

export const excelDataProduct = (_params) => {
	return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/excelDataProduct`, {
	  params: _params,
	});
  };