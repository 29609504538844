import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";

import Unit from "./Unit";
import OpUnit from "./OpUnit";
import Currency from "./Currency";
import Functions from "./Functions";
import Pushlvl from "./Pushlvl";
import Notify from "./Notify";
// import WorkSegment from "./WorkSegment";

export default function FunctionParamsManage() {
  const { t } = useTranslation();
  const unit_ref = useRef(null);
  const opUnit_ref = useRef(null);
  const currency_ref = useRef(null);
  const functions_ref = useRef(null);
  const pushlvl_ref = useRef(null);
  const notify_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    item: null,
    page: 1,
    pageSize: 100,
  });
  const [s_tabKey, set_s_tabKey] = useState("unit"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      item: undefined,
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "unit" && unit_ref.current.onSearch();
    s_tabKey === "opUnit" && opUnit_ref.current.onSearch();
    s_tabKey === "currency" && currency_ref.current.onSearch();
    s_tabKey === "functions" && functions_ref.current.onSearch();
    s_tabKey === "pushlvl" && pushlvl_ref.current.onSearch();
    s_tabKey === "notify" && notify_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "unit" && unit_ref.current.onCreate();
    s_tabKey === "opUnit" && opUnit_ref.current.onCreate();
    s_tabKey === "currency" && currency_ref.current.onCreate();
    s_tabKey === "functions" && functions_ref.current.onCreate();
    s_tabKey === "pushlvl" && pushlvl_ref.current.onCreate();
    s_tabKey === "notify" && notify_ref.current.onCreate();
  };
  
  const tabsData = [
    {
      key: "unit",
      // label: t("ADM.function-params-manage.unitSetting"), //"計量單位設定",
      label: t("ADM.function-params-manage.unit"), //"計量單位設定",
      
      children: (
        <Unit ref={unit_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
    // {
    //   key: "opUnit",
    //   label: t("ADM.function-params-manage.opUnitSetting"), //"操作單位設定",
    //   children: (
    //     <OpUnit ref={opUnit_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
    //   ),
    // },
    {
      key: "currency",
      // label: t("ADM.function-params-manage.currencySetting"), //"交易幣別設定",
      label: t("ADM.function-params-manage.currency"), //"交易幣別",
      children: (
        <Currency
          ref={currency_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "functions",
      // label: t("ADM.function-params-manage.functions"), //"推播群組設定",
      label: t("ADM.function-params-manage.function"), //"推播群組",
      children: (
        <Functions
          ref={functions_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "pushlvl",
      // label: t("ADM.function-params-manage.pushlvl"), //"推播層級設定",
      label: t("ADM.function-params-manage.pushlayer"), //"推播層級",
      children: (
        <Pushlvl
          ref={pushlvl_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "notify",
      // label: t("ADM.function-params-manage.notify"), //"推播事件設定",
      label: t("ADM.function-params-manage.pushEvent"), //"推播事件設定",
      
      children: (
        <Notify ref={notify_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("item", e.target.value)}
      placeholder={t("ADM.function-params-manage.searchPlaceholder")}
      className="w-[300px]"
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,

    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("ADM.function-params-manage.pageHeader")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
