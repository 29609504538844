import { useState, useEffect, useContext } from "react";
import { Space, Button, message, Select, Row, Col, Form, InputNumber, Input } from "antd";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { addModelPdt, getDaycodeTb, getDaycodeHd } from "@/service/apis/LMS/LabelManage";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import dayjs from "dayjs";

export default function ChooseBindPN({ c_lightData, set_is_showModal }) {
  const [form] = Form.useForm();
  const [s_custPNoptions, set_s_custPNoptions] = useState([]);
  const [s_dateHdOptions, set_s_dateHdOptions] = useState([]);
  const [s_dateTbOptions, set_s_dateTbOptions] = useState([]);

  const call_getDaycodeHd = useAPI(getDaycodeHd);
  const call_addModelPdt = useAPI(addModelPdt);
  const call_getDaycodeTb = useAPI(getDaycodeTb);

  const handleCancel = () => {
    set_is_showModal(false);
  };

  const fetchProductPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value, attribute: "5" })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  const onSubmit = async () => {
    let formData = await form.validateFields();

    if (!formData.bindPNs) {
      message.error("請先新增綁定ERP料號");
      return;
    }
    let postData = formData.bindPNs.map((x) => ({
      ...x,
      timecode: x.timecode !== " " ? x.timecode.split("_")[1] : x.timecode,
    }));
    call_addModelPdt.request({ ...c_lightData, PNs: postData });
  };

  useEffect(() => {
    // getDate
    call_getDaycodeHd.request();
  }, []);

  useEffect(() => {
    if (call_addModelPdt.status === "suc") {
      handleCancel();
      message.success(call_addModelPdt.msg);
    } else if (call_addModelPdt.status === "err") {
      message.error(call_addModelPdt.msg);
    }
  }, [call_addModelPdt.status]);

  useEffect(() => {
    if (call_getDaycodeHd.status === "suc") {
      message.success(call_getDaycodeHd.msg);
      set_s_dateHdOptions(
        call_getDaycodeHd.data.reduce(
          (data, current) => {
            data.push({
              label: current.custNM,
              value: current.custID,
              data: current,
            });
            return data;
          },
          [{ label: "無", value: " ", data: "" }]
        )
      );
    } else if (call_getDaycodeHd.status === "err") {
      message.error(call_getDaycodeHd.msg);
      set_s_dateHdOptions([]);
    }
  }, [call_getDaycodeHd.status]);

  useEffect(() => {
    if (call_getDaycodeTb.status === "suc") {
      message.success(call_getDaycodeTb.msg);
      set_s_dateTbOptions(
        call_getDaycodeTb.data.map((x) => ({
          label: `${dayjs(x.date).format("YYYY-MM-DD")} || ${x.timecode}`,
          value: `${dayjs(x.date).format("YYYY-MM-DD")}_${x.timecode}`,
        }))
      );
    } else if (call_getDaycodeTb.status === "err") {
      message.error(call_getDaycodeTb.msg);
      set_s_dateTbOptions([{ label: "無", value: " " }]);
    }
  }, [call_getDaycodeTb.status]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Form form={form} onFinish={onSubmit} autoComplete="off" layout="vertical">
          <Form.List name="bindPNs" shouldUpdate>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Space key={field.fieldKey}>
                    <Form.Item name={[field.name, "pdtNM"]} children={<></>} hidden />
                    <Form.Item
                      label="ERP料號"
                      name={[field.name, "PN"]}
                      rules={[{ required: true, message: "請選擇ERP料號" }]}
                    >
                      <DebounceSelect
                        fetchOptions={fetchProductPNList}
                        maxTagCount={10}
                        showSearch
                        className="w-[200px]"
                        onChange={(_, data) => {
                          let formData = form.getFieldValue();
                          formData.bindPNs[index].PN = data.data.PN;
                          formData.bindPNs[index].pdtNM = data.data.pdtNM;
                          formData.bindPNs[index].custPN = data.data.custPN;
                          form.setFieldsValue(formData);
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="客戶料號" name={[field.name, "custPN"]}>
                      <Input placeholder="請輸入客戶料號" className="w-[200px]" />
                    </Form.Item>
                    <Form.Item
                      label="條碼最大總長"
                      name={[field.name, "length"]}
                      rules={[{ required: true, message: "請選擇條碼最大總長" }]}
                    >
                      <InputNumber min={1} className="w-[150px]" />
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      name={[field.name, "cust"]}
                      label="客戶"
                    >
                      <Select
                        showSearch
                        onChange={(value, data) => {
                          let formData = form.getFieldValue();
                          formData.bindPNs[index].timecode = value === " " ? " " : undefined;
                          form.setFieldsValue(formData);
                          call_getDaycodeTb.request(data.data);
                        }}
                        className="w-[250px]"
                        options={s_dateHdOptions}
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[{ required: true }]}
                      style={{ width: "250px" }}
                      name={[field.name, "timecode"]}
                      label="客戶週期碼"
                      shouldUpdate
                    >
                      <Select placeholder="請先選擇客戶" showSearch options={s_dateTbOptions} />
                    </Form.Item>
                    <Form.Item
                      label="起始序"
                      name={[field.name, "startSN"]}
                      rules={[{ required: true, message: "起始序" }]}
                    >
                      <InputNumber min={0} />
                    </Form.Item>
                    <Form.Item label="備註" name={[field.name, "note"]}>
                      <Input />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={() => add({ startSN: 0 })}
                    block
                    icon={<PlusOutlined />}
                  >
                    新增綁定ERP料號
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Col>
      <Col span={24}>
        <Space className="flex justify-end">
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={onSubmit}>
            確定
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
