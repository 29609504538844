/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Spin,
  Tooltip,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Space,
  Typography,
  Select,
  InputNumber,
  Modal,
  Button,
  message,
  Grid,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import PageHeader from "@/components/PageHeader";
import Light from "@/components/ERP/Light";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
import ContactButton from "@/components/ERP/OMS/ContactButton";
// component
import TbContainer from "./Details/TbContainer";
import TakeOutRtnModal from "./Modal/TakeOutRtnModal";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import {
  getReturnHD,
  getReturnTB,
  addReturnHD,
  updateReturnHD,
  takeOutRtn,
} from "@/service/apis/OMS/Return";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  takeOut21Rtn: true, // 引用退回單
  custID: true,
  contact: true,
  tel: true,
  itemNM: true,
  addr: true,
  note: true,
  // ---------- tb -----------
  createBtn: true,
  PN: true,
  utype: true,
  rtnqty: true,
  tb_delete: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getReturnHD = useAPI(getReturnHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getReturnHD = useAPI(getReturnHD);
  // useAPI 取得表身資料
  const call_getReturnTB = useAPI(getReturnTB);
  // useAPI 新增表頭
  const call_finish_addReturnHD = useAPI(addReturnHD);
  // useAPI 儲存表頭
  const call_finish_updateReturnHD = useAPI(updateReturnHD);
  // useAPI 取得上一筆資料
  const call_prev_updateReturnHD = useAPI(updateReturnHD);
  // useAPI 取得下一筆資料
  const call_next_updateReturnHD = useAPI(updateReturnHD);
  // 引用
  const call_takeOutRtn = useAPI(takeOutRtn);

  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_custID = Form.useWatch("custID", form);
  const { c_state, updateState } = useContext(DataContext);
  // 客戶資料、人員資料
  const { c_custs, c_staffs } = useContext(ERPContext);
  const { mainPage, return_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 是否顯示引用的跳顯
  const [is_showTake, set_is_showTake] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addReturnHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateReturnHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      N: "",
      opUUID: "",
      count: 0,
      linkType: "",
      // 'createID': storeData?.userData?.staffID,
      createT: dayjs(),
      lifeF: "0",
      lifeFT: dayjs(),
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    set_s_tbData([]);
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      addr: cust_obj.addr,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        // 一旦退料單有表身就不可再更改，因為每張退料單的明細必須是同一個客戶
        // 'custID': isNewdoc || isCreateByThis ? false : has_Tb,
        custID: hd_values.linkType?.length > 0 ? true : false,
        contact: false,
        tel: false,
        itemNM: false,
        addr: false,
        note: false,
        // 引用
        takeOut21Rtn: false,
        tb_delete: false,
        PN: false,
        utype: false,
        rtnqty: false,
        tb_note: false,
      });
    }

    // 結案
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
      });
    }
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateReturnHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === return_Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateReturnHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = return_Ns.findIndex((item) => item === N);

    return index;
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建進料單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getReturnTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getReturnHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getReturnHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getReturnTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getReturnTB.status]);

  // 新增銷售退回單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addReturnHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getReturnHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getReturnTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addReturnHD.status]);

  // 編輯銷售退回單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateReturnHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getReturnHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getReturnTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateReturnHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateReturnHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getReturnHD.request({ N: return_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateReturnHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateReturnHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getReturnHD.request({ N: return_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateReturnHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getReturnHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getReturnTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getReturnHD.status]);

  // useAPI 引用驗退單
  useEffect(() => {
    const { status, msg } = call_takeOutRtn;
    console.log("call_takeOutRtn = ", call_takeOutRtn);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getReturnHD.request({ opUUID: mainPage.data?.opUUID });
      // 更新表身資料
      call_getReturnTB.request({ opUUID: mainPage.data?.opUUID });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 更新表頭資料
      call_getReturnHD.request({ opUUID: mainPage.data?.opUUID });
      // 更新表身資料
      call_getReturnTB.request({ opUUID: mainPage.data?.opUUID });
    }
  }, [call_takeOutRtn.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯銷售退回主檔" : "新建銷售退回主檔"} /> */}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("OMS.util.return")
                  : t("OMS.util.add") + t("OMS.util.return")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>
        </div>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[{ label: t("OMS.util.opcode_1610"), value: "1610" }]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 建檔人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createID")}</Text>} name="createID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 引用退回單 */}
              <Button
                className="flex-1 h-full oms-btn-darkBlue"
                disabled={s_disabled.takeOut21Rtn}
                onClick={() => set_is_showTake(true)}
              >
                {t("OMS.util.take")}
                <br />
                {t("OMS.util.opcode_4130")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("OMS.util.accord")}
                <br />
                {t("OMS.util.create")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="custID"
              label={<Text disabled={s_disabled.custID}>{t("OMS.util.custID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.custID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.custID}
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                onChange={onCustChange}
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          {/* 客戶簡稱 */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.custalias")}</Text>} name="custalias">
              <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.custID")} />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("util.lifeF")}</Text>} name="lifeF">
              <Select
                disabled
                options={[
                  { label: t("OMS.return.lifeF_0"), value: "0" }, // 發佈
                  { label: t("OMS.return.lifeF_T"), value: "T" }, // 結案
                ]}
              />
            </Form.Item>
          </Col>
          {/* 歷程時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.lifeFT")}</Text>} name="lifeFT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1"
                // onClick={() => go_req()}
                disabled
                // disabled={s_disabled.reqSearch}
              >
                {/* 報價記錄查詢 */}
                {t("OMS.util.quoSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶業務窗口 */}
          <Col span={editMode ? 10 : 12} className="mt-1">
            <Form.Item shouldUpdate label={<Text>{t("OMS.return.contact_window")}</Text>}>
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      custID={form.getFieldValue("custID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          contact: contact_obj?.contact,
                          tel: contact_obj?.tel,
                        });
                      }}
                      title={t("util.placeholder_select") + t("OMS.return.contact_window")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("OMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="contact" className="flex-1 mb-0px" shouldUpdate>
                      <Input
                        disabled={s_disabled.contact}
                        className="flex-1"
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.window")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("OMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="tel" className="flex-1 mb-0px">
                      <Input
                        disabled={s_disabled.tel}
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 窗口註記 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item
              label={<Text disabled={s_disabled.itemNM}>{t("OMS.return.itemNM")}</Text>}
              name="itemNM"
            >
              <Input
                disabled={s_disabled.itemNM}
                placeholder={t("util.placeholder_input") + t("OMS.return.itemNM")}
              />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1"
                // onClick={() => go_req()}
                // disabled={s_disabled.proSearch}
                disabled
              >
                {/* 銷貨記錄查詢 */}
                {t("OMS.util.ordSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 聯絡地址 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.addr}>{t("OMS.return.addr")}</Text>}
              name="addr"
            >
              <Input disabled={s_disabled.addr} />
            </Form.Item>
          </Col>
          {/* 進退註記 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.note}>{t("OMS.return.note")}</Text>}
              name="note"
            >
              <Input disabled={s_disabled.note} />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              <Button
                className="h-full flex-1"
                // onClick={() => go_req()}
                // disabled={s_disabled.instockSearch}
                disabled
              >
                {/* 發貨記錄查詢 */}
                {t("OMS.util.outstockSearch")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        hd_values={form.getFieldsValue()}
        call_getReturnTB={call_getReturnTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
      />
      <CustomModal
        width={screens.xxl ? "90%" : "90%"}
        title={t("OMS.util.take") + t("OMS.util.opcode_4130")} // 引用退回單
        open={is_showTake}
        onCancel={() => set_is_showTake(false)}
      >
        {is_showTake ? (
          <TakeOutRtnModal
            set_is_showTake={set_is_showTake}
            hd_values={form.getFieldsValue()}
            call_takeOutRtn={call_takeOutRtn}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
