import React, { useState, useEffect } from "react";
import { Button, Space, Modal, message, Row, Col, Typography, Input } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { getModelPdt, deleteModelPdt } from "@/service/apis/LMS/LabelManage";
import { IconGarbage, IconPen } from "@/components/Icon/Action";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ChooseBindPN from "../ModalCantainer/ChooseBindPN";
import EditBindPN from "../ModalCantainer/EditBindPN";
import Printer from "../ModalCantainer/Printer";
import Record from "../ModalCantainer/Record";

const { Link } = Typography;

export default function BindPN({ c_lightData, set_c_lightData }) {
  const call_getModelPdt = useAPI(getModelPdt);
  const call_deleteModelPdt = useAPI(deleteModelPdt);

  const [is_showModal, set_is_showModal] = useState(false);

  const [s_printerShowModal, set_s_printerShowModal] = useState(false);

  const [s_recordShowModal, set_s_recordShowModal] = useState(false);

  const [s_editBindPNModal, set_s_editBindPNModal] = useState(false);

  const [s_searchQuery, set_s_searchQuery] = useState(undefined);

  const [s_editData, set_s_editData] = useState({});

  const [s_tableData, set_s_tableData] = useState([]);
  // 要補印的條碼
  const [s_recordSN, set_s_recordSN] = useState([]);

  const tableColumns = [
    {
      title: "標籤ID",
      dataIndex: "modelID",
    },
    {
      title: "版本",
      dataIndex: "ver",
      width: "120px",
      align: "center",
    },
    {
      title: "料號",
      dataIndex: "PN",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
    },
    {
      title: "客戶料號",
      dataIndex: "custPN",
    },
    {
      title: "限制長度",
      dataIndex: "length",
      align: "center",
      width: "120px",
    },
    {
      title: "累積列印數",
      dataIndex: "maxN",
      align: "center",
    },
    {
      title: "備註",
      dataIndex: "note",
      width: "20%",
    },
    {
      title: "紀錄",
      dataIndex: "record",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Link
          onClick={(e) => {
            e.stopPropagation(); // 這裡不要開啟表身
            set_s_editData(rowData);
            set_s_recordShowModal(true);
          }}
        >
          紀錄
        </Link>
      ),
    },
    {
      title: "列印",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Link
          onClick={(e) => {
            e.stopPropagation(); // 這裡不要開啟表身
            set_s_editData(rowData);
            set_s_printerShowModal(true);
          }}
        >
          列印
        </Link>
      ),
    },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              e.stopPropagation(); // 這裡不要開啟表身
              set_s_editBindPNModal(true);
            }}
          />
          <IconGarbage onClick={(e) => deleteRow(e, rowData)} />
        </Space>
      ),
    },
  ];

  const deleteRow = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deleteModelPdt.request({ ...rowData }),
      cancelText: "取消",
    });
  };

  const onSearch = () => {
    if (s_searchQuery !== "" && s_searchQuery) {
      set_s_tableData(
        call_getModelPdt.data.reduce((data, curr, i) => {
          (curr.PN.includes(s_searchQuery) || curr.custPN.includes(s_searchQuery)) &&
            data.push({ ...curr, key: i });
          return data;
        }, [])
      );
    } else {
      set_s_tableData(
        call_getModelPdt.data.map((x, i) => {
          return { ...x, key: i };
        })
      );
    }
  };

  useEffect(() => {
    call_getModelPdt.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getModelPdt.status === "suc") {
      set_s_tableData(call_getModelPdt.data.map((x, i) => ({ ...x, key: i })));
      message.success(call_getModelPdt.msg);
    } else if (call_getModelPdt.status === "err") {
      message.error(call_getModelPdt.msg);
      set_s_tableData([]);
    }
  }, [call_getModelPdt.status]);

  useEffect(() => {
    if (call_deleteModelPdt.status === "suc") {
      message.success(call_deleteModelPdt.msg);
      call_getModelPdt.request(c_lightData);
    } else if (call_deleteModelPdt.status === "err") {
      message.error(call_deleteModelPdt.msg);
    }
  }, [call_deleteModelPdt.status]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="flex justify-end">
        <Space>
          <Input
            placeholder="請輸入料號或客戶料號"
            style={{ width: 250 }}
            value={s_searchQuery}
            onChange={(e) => set_s_searchQuery(e.target.value)}
          />
          <Button type="primary" onClick={onSearch}>
            查詢
          </Button>
          <Button type="primary" onClick={() => set_is_showModal(true)}>
            新增
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns.filter((x) => !x.hidden)}
          dataSource={s_tableData}
          tbMode={Object.keys(c_lightData).length > 0}
          c_lightData={s_editData}
          onRow={(record) => {
            return {
              onClick: () =>
                record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
            };
          }}
        />
      </Col>

      <CustomModal
        width="80%"
        title="新增料號"
        open={is_showModal}
        onCancel={() => set_is_showModal(false)}
        afterClose={() => call_getModelPdt.request(c_lightData)}
      >
        <ChooseBindPN set_is_showModal={set_is_showModal} c_lightData={c_lightData} />
      </CustomModal>

      <CustomModal
        width="60%"
        title="修改料號"
        open={s_editBindPNModal}
        onCancel={() => set_s_editBindPNModal(false)}
        afterClose={() => call_getModelPdt.request(c_lightData)}
      >
        {s_editBindPNModal && (
          <EditBindPN
            set_s_showModal={set_s_editBindPNModal}
            c_lightData={c_lightData}
            s_editData={s_editData}
          />
        )}
      </CustomModal>

      <CustomModal
        width="60%"
        title="條碼列印"
        open={s_printerShowModal}
        onCancel={() => set_s_printerShowModal(false)}
        afterClose={() => {
          call_getModelPdt.request(c_lightData);
        }}
      >
        <Printer
          set_s_showModal={set_s_printerShowModal}
          s_editData={s_editData}
          c_lightData={c_lightData}
          s_recordSN={s_recordSN}
        />
      </CustomModal>

      <CustomModal
        width="80%"
        title="條碼紀錄"
        open={s_recordShowModal}
        onCancel={() => set_s_recordShowModal(false)}
        afterClose={() => {
          call_getModelPdt.request(c_lightData);
          set_s_recordSN([]);
        }}
      >
        <Record
          set_s_printerShowModal={set_s_printerShowModal}
          set_is_showModal={set_s_recordShowModal}
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_recordSN={set_s_recordSN}
        />
      </CustomModal>
    </Row>
  );
}
