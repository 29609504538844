/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { message, Space, Modal, Drawer } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getTxnHd, txn, deleteTxnHd } from "@/service/apis/WMS/WMS";
import { IconCircle, IconGarbage } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";
import { SeeMoreButton } from "@/components/ERP/Button";
import ReturnMaterialDrawer from "./ReturnMaterialDrawer/index";
import LoanOutReturnDrawer from "./LoanOutReturnDrawer";
import InInvModal from "./ModalContainer/InInvModal";

const In = forwardRef((props, ref) => {
	const { c_lightData, set_c_lightData, c_searchData } = props;
	const { t } = useTranslation();

	const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
	const [s_tableData, set_s_tableData] = useState([]);

	// 控制抽屜開啟、關閉、抽屜要顯示的資料
	const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

	// 後端的資料
	const [s_pageInfo, set_s_pageInfo] = useState({
		currentPage: 0,
		endItemNumber: 0,
		pageItemNumber: 0,
		startItemNumber: 0,
		totalItems: 0,
	});

	// 送給後端的資料
	const [s_searchValue, set_s_searchValue] = useState({
		page: 1,
		pageSize: 100,
	});
	const call_getTxnHd = useAPI(getTxnHd);
	const call_txn = useAPI(txn);
	const call_deleteTxnHd = useAPI(deleteTxnHd);

	const opcode_Drawer = [
		// { opcode: "4110", drawer: "WMS.util.opcode_4110" },//生產入庫
		// { opcode: "4120", drawer: "WMS.util.opcode_4120" },//採購入庫
		// { opcode: "4130", drawer: "WMS.util.opcode_4130" },//銷售退回
		// { opcode: "4141", drawer: "WMS.util.opcode_4141" },//庫房調入
		// { opcode: "4142", drawer: "WMS.util.opcode_4142" },//庫房借入
		{ opcode: "4143", drawer: <LoanOutReturnDrawer /> }, //借出歸還
		{ opcode: "4160", drawer: <ReturnMaterialDrawer /> }, //生產退料
		// { opcode: "4010", drawer: "WMS.util.opcode_4010" },//開帳盤點
		// { opcode: "4020", drawer: "WMS.util.opcode_4020" },//週期盤點
		// { opcode: "4030", drawer: "WMS.util.opcode_4030" },//專案盤點
	];

	//判斷要顯示哪個drawer
	const getDrawerByOpcode = (opcode) => {
		const matchedItem = opcode_Drawer.find((item) => item.opcode === opcode);
		return matchedItem ? matchedItem.drawer : null;
	};

	// 查看更多
	const openDetail = (event, rowData) => {
		set_s_drawer({ show: true, data: rowData });
		event.stopPropagation();
	};

	const tableColumns = [
		{
			title: t("WMS.util.opcode"), //作業單別

			dataIndex: "opcode",
			align: "center",
			render: (text) => t(`WMS.util.opcode_${text}`),
		},
		{
			title: t("WMS.util.jobN"), //作業單號
			dataIndex: "jobN",
		},
		{
			title: t(`WMS.util.preN_${c_searchData.opcode}`), //進料單號
			dataIndex: "woN",
			hidden: c_searchData.opcode === "4170",
		},
		{
			title: t("WMS.util.planqty"), //計畫數量
			dataIndex: "planqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("WMS.util.realqty"), //實際數量

			dataIndex: "realqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("WMS.util.bal"), //帳差

			dataIndex: "bal",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("WMS.util.lifeF"), //歷程
			dataIndex: "lifeF",
			align: "center",
			render: (text) => t(`WMS.util.lifeF_${text}`),
			onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
		},
		{
			title: t("WMS.util.updtT"), //更新時間
			dataIndex: "lifeFT",
			align: "center",
			render: (text) => dayjs(text).format("MM-DD HH:mm"),
		},
		{
			title: t("util.action"), //操作
			dataIndex: "render",
			align: "center",
			render: (_, rowData) => (
				<Space>
					<IconCircle disabled={rowData.lifeF !== "2"} onClick={(e) => recycleTxn(e, rowData)} />
					{/* 查看更多 */}
					<SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
					<IconGarbage disabled={rowData.lifeF !== "0"} onClick={(e) => deleteTxn(e, rowData)} />
				</Space>
			),
		},
	];

	useImperativeHandle(ref, () => ({
		Create: () => set_s_showModal(true), //開啟新增Modal
		Call_hd: () => call_getTxnHd.request({ ...c_searchData, ...s_searchValue }),
	}));

	const recycleTxn = (e, rowData) => {
		Modal.confirm({
			title: t("WMS.util.txn_confirm"),
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: t("WMS.util.txn_confirm_msg"),
			okText: t("util.ok"),
			onOk: () => call_txn.request(rowData),
			cancelText: t("util.cancel"),
		});

		e.stopPropagation(); // 這裡不要開啟表身
	};

	const deleteTxn = (e, rowData) => {
		Modal.confirm({
			title: t("util.del_confirm"),
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: t("util.del_confirm_msg"),
			okText: t("util.ok"),
			onOk: () => call_deleteTxnHd.request(rowData),
			cancelText: t("util.cancel"),
		});

		e.stopPropagation(); // 這裡不要開啟表身
	};

	// c_searchData 變更時改變 page
	useEffect(() => {
		set_c_lightData({});
		set_s_searchValue((prev) => ({ ...prev, page: 1 }));
	}, [c_searchData]);

	useEffect(() => {
		call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
	}, [s_searchValue]);

	useEffect(() => {
		if (call_getTxnHd.status === "suc") {
			const { tableData, pageInfo } = call_getTxnHd.data;
			const newTableData = tableData.map((x, i) => ({ ...x, key: x.opUUID, index: i }));

			set_s_tableData(newTableData);
			set_s_pageInfo(pageInfo);

			// 更新 c_lightData
			if (c_lightData.opUUID && !newTableData.some((x) => x.opUUID === c_lightData.opUUID)) {
				set_c_lightData({});
			} else {
				const thisData = newTableData.find((x) => x.opUUID === c_lightData.opUUID);
				set_c_lightData(thisData ? { ...thisData, key: thisData.opUUID } : {});
			}
			message.success(call_getTxnHd.msg);
		} else if (call_getTxnHd.status === "err") {
			set_s_tableData([]);
			message.error("查无资料");
		}
	}, [call_getTxnHd.status]);

	useEffect(() => {
		if (call_txn.status === "suc") {
			call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
		} else if (call_txn.status === "err") {
			message.error(call_txn.msg);
		}
	}, [call_txn.status]);

	useEffect(() => {
		if (call_deleteTxnHd.status === "suc") {
			call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
		} else if (call_deleteTxnHd.status === "err") {
			message.error(call_deleteTxnHd.msg);
		}
	}, [call_deleteTxnHd.status]);
	return (
		<>
			<CustomTable
				columns={tableColumns}
				dataSource={s_tableData}
				loading={call_getTxnHd.status === "load"}
				tbMode={Object.keys(c_lightData).length > 0}
				c_lightData={c_lightData}
				onRow={(record) => ({
					onClick: () =>
						record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
				})}
				pagination={{
					total: s_pageInfo.totalItems,
					current: s_searchValue.page,
					pageSize: s_searchValue.pageSize,
					onChange: (current, size) =>
						set_s_searchValue((prev) => ({
							...prev,
							page: prev.pageSize !== size ? 1 : current,
							pageSize: size,
						})),
				}}
			/>

			<CustomModal
				title={
					Object.keys(c_lightData).length > 0
						? `${t("util.edit")}${t(`WMS.util.opcode_${c_searchData.opcode}`)}`
						: `${t("util.add")}${t(`WMS.util.opcode_${c_searchData.opcode}`)}`
				}
				width="85%"
				open={s_showModal}
				onCancel={() => set_s_showModal(false)}
				afterClose={() => {
					set_c_lightData({});
					call_getTxnHd.request({ ...c_searchData, ...s_searchValue });
				}}
			>
				{c_searchData.opcode === "4170" ?
					<InInvModal opcode={c_searchData.opcode} set_s_showModal={set_s_showModal} s_editData={c_lightData} /> :
					<ModalContainer
						opcode={c_searchData.opcode}
						set_s_showModal={set_s_showModal}
						s_editData={c_lightData}
					/>}
			</CustomModal>

			{/* 列印抽屜 */}
			<Drawer
				placement="right"
				closable={false}
				width={820}
				onClose={() => set_s_drawer({ show: false, data: {} })}
				open={s_drawer.show}
			>
				{getDrawerByOpcode(c_searchData.opcode)}
			</Drawer>
		</>
	);
});

export default In;
