import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
  TourButton,
} from "@/components/ERP/Button";
// component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { addApHD, getApHD, getApTB, updateApHD } from "@/service/apis/PMS/ap";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import ContactButton from "@/components/ERP/PMS/ContactButton";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../../index";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PageHeader from "@/components/PageHeader";
import TakePayModal from "./Modal/TakePayModal";
import TbContainer from "./Details/TbContainer";
import dayjs from "dayjs";
import { getCustTb } from "@/service/apis/ADM/client";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  takePay: true, // 引用對帳資料
  strikeSearch: true, // 沖帳紀錄查詢
  apCheck: true, // 對帳覆核
  releaseAP: true, //出對帳單
  suppID: true,
  contact: true,
  tel: true,
  itemNM: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const f_suppID = Form.useWatch("suppID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const { c_state, updateState } = useContext(DataContext);
  const call_getCustTb = useAPI(getCustTb);
  // useAPI 取得表頭資料
  const call_getApHD = useAPI(getApHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getApHD = useAPI(getApHD);
  // useAPI 更新表身
  const call_getApTB = useAPI(getApTB);
  // useAPI 取得上一筆資料
  const call_prev_updateApHD = useAPI(updateApHD);
  // useAPI 取得下一筆資料
  const call_next_updateApHD = useAPI(updateApHD);
  // useAPI 新增表頭
  const call_finish_addApHD = useAPI(addApHD);
  // useAPI 儲存表頭
  const call_finish_updateApHD = useAPI(updateApHD);
  // useAPI 對帳覆核
  const call_apCheck_updateApHD = useAPI(updateApHD);
  // useAPI 出對帳單
  const call_release_updateApHD = useAPI(updateApHD);
  // 廠商資料、人員資料
  const { c_supps } = useContext(ERPContext);
  const { mainPage, Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  const [is_showTake, set_is_showTake] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  const [s_custTbOptions, set_s_custTbOptions] = useState([]);
  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateApHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === Ns.length) {
      // "目前資料為最後一筆，已無下一筆資料"
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateApHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = Ns.findIndex((item) => item === N);

    return index;
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);
    const custTb_obj = s_custTbOptions.find((item) => item.value === value);
    console.log("supp_obj = ", supp_obj);
    if (supp_obj) {
      form.setFieldsValue({
        suppID: supp_obj.suppID,
        suppalias: supp_obj.suppalias,
        suppNM: supp_obj.suppNM,
        addr: supp_obj.addr,
        contact: null, // 選擇新廠商時要清空業務窗口資料
        tel: null, // 選擇新廠商時要清空業務窗口資料
      });
    } else {
      form.setFieldsValue({
        suppID: custTb_obj.value,
        suppalias: custTb_obj.label,
        suppNM: custTb_obj.label,
        addr: custTb_obj.label,
        contact: null, // 選擇新廠商時要清空業務窗口資料
        tel: null, // 選擇新廠商時要清空業務窗口資料
      });
    }
  };

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addApHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateApHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 判斷是否有沖帳單
    const strikeSearch = hd_values.N28?.length > 0 ? false : true;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        suppID: !isNewdoc,
        takePay: false, // 引用沖帳單
        apCheck: false,
        contact: false,
        tel: false,
        itemNM: false,
      });
    }

    // 覆核
    if (hd_values?.lifeF === "1") {
      set_s_disabled({
        ...INIT_DISABLED,
        apCheck: false,
        note: false,
        contact: false,
        tel: false,
        itemNM: false,
        releaseAP: false, // 出對帳單
        strikeSearch: strikeSearch, // 沖帳資料查詢
      });
    }

    // 出對帳單
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        releaseAP: false, // 出對帳單
        strikeSearch: strikeSearch, // 對帳資料查詢
      });
    }

    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        strikeSearch: strikeSearch, // 對帳資料查詢
      });
    }
  };

  // 查詢對帳單
  const go_strike = () => {
    const params_obj = {
      tabkey: "strike",
      N: mainPage.data?.N28,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/ap-manage?${params}`, "_blank");
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      opUUID: "",
      N28: "",
      sumtotal: 0, // 總項金額
      tax: 0, //  總項稅額
      total: 0, // 應付含稅總金額
      paytotal: 0, // 已沖帳金額
      balance: 0, // 帳差
      lifeFT: dayjs(),
      createT: dayjs(),
    };

    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    change_MainPage_disabled(new_Hd_Data);

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 表身資料需要手動引用
    set_s_tbData([]);
  };

  // 對帳覆核 or 取消覆核
  const apCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "0" ? "對帳覆核確認" : "取消對帳覆核確認"}`,
      title:
        f_lifeF === "0"
          ? t("PMS.ap-manage.apCheck_confirm")
          : t("PMS.util.cancel") + t("PMS.ap-manage.apCheck_confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("PMS.ap-manage.apCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_apCheck_updateApHD.request({
          ...values,
          // 0: 發佈 1: 對帳覆核
          lifeF: f_lifeF === "0" ? "1" : "0",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 出對帳單
  const releaseAP = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "1" ? "出對帳單確認" : "取消出對帳單確認"}`,
      title:
        f_lifeF === "1"
          ? t("PMS.ap-manage.releaseAP_confirm")
          : t("PMS.util.cancel") + t("PMS.ap-manage.releaseAP_confirm"),
      icon: <ExclamationCircleOutlined />,
      content: t("PMS.ap-manage.releaseAP_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_release_updateApHD.request({
          ...values,
          // 1: 對帳覆核 2: 出對帳單
          lifeF: f_lifeF === "2" ? "1" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建對帳單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        opcode: "2710",
        createT: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getApTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }

    call_getCustTb.request();
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getCustTb;
    if (status === "suc") {
      console.log("data = ", data);
      const options = data
        .filter((item) => item.isvalid)
        .map((item) => {
          // return { value: item.custID, label: `${item.custID}_${item.custalias}` };
          return { value: item.itemUUID, label: `${item.contact}` };
        });
      console.log("options = ", options);
      set_s_custTbOptions(options);
    }
    if (status === "err") {
      message.error(msg);
      set_s_custTbOptions([]);
    }
  }, [call_getCustTb.status]);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getApHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getApHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getApTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getApTB.status]);

  // 新增對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addApHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getApHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getApTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addApHD.status]);

  // 編輯對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateApHD;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getApHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getApTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateApHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateApHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // "取得上一筆資料成功"
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getApHD.request({ N: Ns[nowIndex - 1] });
    }

    if (status === "err") {
      //"取得上一筆資料失敗"
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateApHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateApHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      //"取得下一筆資料成功"
      message.success(t("PMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getApHD.request({ N: Ns[nowIndex + 1] });
    }

    if (status === "err") {
      //"取得下一筆資料失敗"
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateApHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getApHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getApTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getApHD.status]);

  // useAPI 對帳覆核
  useEffect(() => {
    const { status, msg, data } = call_apCheck_updateApHD;
    if (status === "suc") {
      // "對帳覆核成功" : "取消覆核成功"
      message.success(
        data.lifeF === "1"
          ? t("PMS.ap-manage.apCheck") + t("util.success")
          : t("PMS.util.cancel") + t("PMS.ap-manage.apCheck")
      );
      // 更新表頭
      call_getApHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_apCheck_updateApHD.status]);

  // useAPI 出對帳單
  useEffect(() => {
    const { status, msg, data } = call_release_updateApHD;
    if (status === "suc") {
      // "出對帳單成功" : "取消出對帳單成功"
      message.success(
        data.lifeF === "2"
          ? t("PMS.ap-manage.releaseAP") + t("util.success")
          : t("PMS.util.cancel") + t("PMS.ap-manage.releaseAP") + t("util.success")
      );
      // 更新表頭
      call_getApHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_release_updateApHD.status]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* 編輯對帳單主檔、新建對帳單主檔 */}
            <PageHeader
              title={
                editMode ? t("util.edit") + t("PMS.util.ap") : t("util.add") + t("PMS.util.ap")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            {/* <PrintButton onClick={() => alert("wait")} /> */}
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  console.log("_oriData.hd  = ", s_oriData.hd);
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            {/* <TourButton /> */}
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="lifeFT" hidden>
            <DatePicker />
          </Form.Item>
          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="createT" hidden>
            <DatePicker />
          </Form.Item>

          {/* <Form.Item name="createT" hidden>
            <DatePicker />
          </Form.Item> */}
        </div>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("PMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("PMS.util.opcode_placeholder")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("PMS.util.opcode_2710"), value: "2710" }, // 对帐单
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                disabled
                options={[
                  { label: t("PMS.ap-manage.AP_lifeF_0"), value: "0" }, //"創立",
                  { label: t("PMS.ap-manage.AP_lifeF_1"), value: "1" }, //"覆核",
                  { label: t("PMS.ap-manage.AP_lifeF_2"), value: "2" }, //"出對帳單",
                  { label: t("PMS.ap-manage.AP_lifeF_3"), value: "3" }, //"沖帳(付款)未結案", "沖帳未結案"
                  { label: t("PMS.ap-manage.AP_lifeF_T"), value: "T" }, //"結案" ,"沖帳已結案"
                ]}
              />
            </Form.Item>
          </Col>
          {/* 沖帳單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N28")}</Text>} name="N28">
              <Input disabled />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 引用對帳資料 */}
              <Button
                className="flex-1 h-full pms-btn-blue"
                disabled={s_disabled.takePay}
                onClick={() => set_is_showTake(true)}
              >
                {t("PMS.util.take")}
                <br />
                {t("PMS.ap-manage.recon")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                // disabled={editMode ? false : true}
                disabled
                onClick={createByThis}
              >
                {/* {t("PMS.util.accord")}
                <br />
                {t("PMS.util.create")} */}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="suppNM"
              label={<Text disabled={s_disabled.suppID}>{t("PMS.util.suppNM")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.suppNM") }}
            >
              <Select
                showSearch
                disabled={s_disabled.suppID}
                placeholder={t("PMS.util.suppID_placeholder")}
                onChange={onSuppChange}
                options={c_supps.options.concat(s_custTbOptions)}
                filterOption={(input, option) => {
                  return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                }}
              />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.suppID")}</Text>} name="suppID">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {/* 廠商地址 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item label={<Text disabled>{t("PMS.ap-manage.addr")}</Text>} name="addr">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1"
                onClick={go_strike}
                disabled={s_disabled.strikeSearch}
              >
                {/* 沖帳紀錄查詢 */}
                {t("PMS.util.strikeSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 廠商業務窗口 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item shouldUpdate label={<Text>{t("PMS.pro.dealcontact")}</Text>}>
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      suppID={form.getFieldValue("suppID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          contact: contact_obj?.contact,
                          tel: contact_obj?.tel,
                        });
                      }}
                      title={t("PMS.pro.dealcontact_placeholder")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("PMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="contact" className="flex-1 mb-0px" shouldUpdate>
                      <Input
                        disabled={s_disabled.contact}
                        className="flex-1"
                        placeholder={
                          f_suppID
                            ? t("PMS.pro.dealcontact_placeholder")
                            : t("PMS.util.suppID_placeholder")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("PMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="tel" className="flex-1">
                      <Input
                        disabled={s_disabled.tel}
                        placeholder={
                          f_suppID
                            ? t("PMS.util.tel_placeholder")
                            : t("PMS.util.suppID_placeholder")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 窗口註記 */}
          {/* <Col span={editMode ? 10 : 12}> */}
          {/* <Form.Item
              label={<Text disabled={s_disabled.itemNM}>{t("PMS.ap-manage.itemNM")}</Text>}
              name="itemNM"
            >
              <Input disabled={s_disabled.itemNM} />
            </Form.Item> */}
          {/* </Col> */}
          {/* 應付含稅總金額 */}
          <Col span={editMode ? 5 : 6} className="pb-2">
            {/* 應付含稅總金額 */}
            <Form.Item label={<Text disabled>{t("PMS.ap-manage.ap_total")}</Text>} name="total">
              <InputNumber className="w-full" disabled />
            </Form.Item>
          </Col>

          <Col span={editMode ? 5 : 6} className="pb-2 flex gap-3">
            {/* 已沖帳金額 */}
            <Form.Item
              className="flex-1"
              label={<Text disabled>{t("PMS.ap-manage.paytotal")}</Text>}
              name="paytotal"
            >
              <InputNumber className="w-full" disabled />
            </Form.Item>
            {/* 帳差 */}
            <Form.Item
              className="flex-1"
              label={<Text disabled>{t("PMS.ap-manage.balance")}</Text>}
              name="balance"
            >
              <InputNumber className="w-full" disabled />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              {/* 對帳覆核 */}
              <Button
                className={`h-full flex-1 ${f_lifeF === "1" ? "pms-btn-orange" : "pms-btn-purple"}`}
                disabled={s_tbData.length === 0 ? true : s_disabled.apCheck}
                onClick={apCheck}
              >
                {/* {f_lifeF === "1" ? "取消覆核" : "對帳覆核"} */}
                {f_lifeF === "1" ? t("PMS.ap-manage.cancel_apCheck") : t("PMS.ap-manage.apCheck")}
              </Button>
              {/* 對帳覆核 */}
              <Button
                className={`h-full flex-1 ${f_lifeF === "1" ? "pms-btn-purple" : "pms-btn-red"}`}
                disabled={s_disabled.releaseAP}
                // onClick={apCheck}
                onClick={releaseAP}
              >
                {/* {f_lifeF === "1" ? "出對帳單" : "取消出對帳單"} */}
                {f_lifeF === "1"
                  ? t("PMS.ap-manage.releaseAP")
                  : t("PMS.ap-manage.cancel_releaseAP")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer call_getApTB={call_getApTB} s_tbData={s_tbData} s_disabled={s_disabled} />

      {/* 引用採購單 */}
      <CustomModal
        width="80%"
        title={t("PMS.util.take") + t("PMS.ap-manage.recon")} // 引用對帳資料
        open={is_showTake}
        onCancel={() => set_is_showTake(false)}
      >
        {is_showTake ? (
          <TakePayModal
            set_is_showTake={set_is_showTake}
            hd_values={form.getFieldsValue()}
            call_getApHD={call_getApHD}
            call_getApTB={call_getApTB}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
