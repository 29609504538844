/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Divider,
  message,
  Typography,
  InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import { formatAmount } from "@/util/format";
// api
import useAPI from "@/hooks/useAPI";
import { addQmsSipTb, updateQmsSipTb } from "@/service/apis/QMS/QMSManage";
import Preview from "../../HD/Preview";

const ModalContainer = ({ s_editData, set_s_editData, c_lightData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_insptype = Form.useWatch("insptype", form);
  // 新增表身資料
  const call_addQmsSipTb = useAPI(addQmsSipTb);
  // 編輯表身資料
  const call_updateQmsSipTb = useAPI(updateQmsSipTb);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      console.log("values = ", values);
      call_addQmsSipTb.request(values);
    }
    if (type === "edit") {
      call_updateQmsSipTb.request(values);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ sipID: c_lightData.sipID });
  }, []);

  useEffect(() => {
    const { status, msg } = call_addQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipTb.status]);

  useEffect(() => {
    const { status, msg } = call_updateQmsSipTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(msg);
    }
  }, [call_updateQmsSipTb.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="dcUUID" hidden>
          <Input />
        </Form.Item>
      </div>

      {c_lightData.drawing && (
        <Row gutter={[12, 12]}>
          <Col span={12} className="flex text-lg">
            <Typography.Title level={5}>工程圖檔：</Typography.Title>
            <Typography.Title level={5} className="mt-0">
              <Preview url={c_lightData.drawing} />
            </Typography.Title>
          </Col>
          <Divider className="mt-0" />
        </Row>
      )}

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="檢驗項目" name="inspitem" rules={[{ required: true }]}>
            <Input placeholder="請輸入檢驗項目" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="檢驗類別" name="itemtype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "一般項目", value: "normal" },
                { label: "重要項目", value: "secure" },
                { label: "監控項目", value: "sepcial" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="驗收型態" name="insptype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              onChange={() => {
                form.setFieldsValue({
                  drawarea: "",
                  drawno: "",
                });
              }}
              allowClear
              options={[
                { label: "計數型", value: "count" },
                { label: "計量型", value: "measure" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={16}>
          <Form.Item label="檢驗重點" name="inspkeynote">
            <Input placeholder="請輸入檢驗重點" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="檢測環境" name="sipcond">
            <Input placeholder="請輸入檢測環境" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="允收品質水準" name="AQL">
            <Input placeholder="請輸入允收品質水準" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="加嚴檢驗" name="TINSP">
            <Input placeholder="請輸入加嚴檢驗" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="減量檢驗" name="RINSP">
            <Input placeholder="請輸入檢驗檢驗" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="圖面圖域" name="drawarea">
            <Input disabled={f_insptype === "count"} placeholder="請輸入圖面圖域" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="圖面球號" name="drawno">
            <Input disabled={f_insptype === "count"} placeholder="請輸入圖面球號" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="標準值" name="CL">
            <InputNumber
              formatter={(value) => formatAmount(value)}
              className="w-full"
              placeholder="請輸入標準值"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="測量單位" name="UOM">
            <Input placeholder="請輸入測量單位" />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="規格上限" name="USL">
            <InputNumber
              formatter={(value) => formatAmount(value)}
              className="w-full"
              onChange={(value) => form.setFieldValue("UCL", value)}
              placeholder="請輸入規格上限"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="規格下限" name="LSL">
            <InputNumber
              formatter={(value) => formatAmount(value)}
              className="w-full"
              onChange={(value) => form.setFieldValue("LCL", value)}
              placeholder="請輸入規格下限"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item label="管制上限" name="UCL">
            <InputNumber
              formatter={(value) => formatAmount(value)}
              className="w-full"
              placeholder="請輸入管制上限"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="管制下限" name="LCL">
            <InputNumber
              formatter={(value) => formatAmount(value)}
              className="w-full"
              placeholder="請輸入規格下限"
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
