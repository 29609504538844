import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

//----------------------------------------- 取得產基本資料 ----------------------------------------

export const getProduct = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProduct`, {
    params: _params,
  });
};
export const getDetection = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDetection`, {
    params: _params,
  });
};

export const getDeal = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDeal`, {
    params: _params,
  });
};

export const getProductSelect = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProductSelect`, {
    params: _params,
  });
};

//----------------------------------------- 新增產基本資料 ----------------------------------------
export const addProduct = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProduct`, data);
};
export const addDetection = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDetection`, data);
};

//----------------------------------------- 編輯產基本資料 ----------------------------------------
export const modifyProduct = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyProduct`, data);
};
export const modifyDetection = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyDetection`, data);
};

//----------------------------------------- 刪除產基本資料 ----------------------------------------
export const deleteProduct = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteProduct`, data);
};
export const deleteDetection = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDetection`, data);
};

//----------------------------------------- 取得產品識別管理資料 ----------------------------------------
export const getIdkw = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getIdkw`, {
    params: _params,
  });
};

//----------------------------------------- 新增產品識別管理資料 ----------------------------------------
export const addIdkw = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addIdkw`, data);
};

//----------------------------------------- 編輯產品識別管理資料 ----------------------------------------
export const modifyIdkw = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyIdkw`, data);
};

//----------------------------------------- 刪除產品識別管理資料 ----------------------------------------
export const deleteIdkw = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteIdkw`, data);
};

//----------------------------------------- 取得包裝管理資料 ----------------------------------------
export const getPack = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPack`, {
    params: _params,
  });
};

//----------------------------------------- 新增包裝管理資料 ----------------------------------------
export const addPack = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPack`, data);
};

//----------------------------------------- 編輯包裝管理資料 ----------------------------------------
export const modifyPack = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPack`, data);
};

//----------------------------------------- 刪除包裝管理資料 ----------------------------------------
export const deletePack = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePack`, data);
};

//----------------------------------------- 標準成本 ----------------------------------------
export const getCost = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCost`, {
    params: _params,
  });
};

export const addCost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addCost`, data);
};

export const deleteCost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteCost`, data);
};

export const updateCost = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateCost`, data);
};

//----------------------------------------- 庫管 ----------------------------------------
export const getStock = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStock`, {
    params: _params,
  });
};

export const addStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStock`, data);
};

export const deleteStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStock`, data);
};

export const updateStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateStock`, data);
};

//----------------------------------------- 標準價格 ----------------------------------------
export const getPrice = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPrice`, {
    params: _params,
  });
};

export const addPrice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPrice`, data);
};

export const deletePrice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePrice`, data);
};

export const updatePrice = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePrice`, data);
};

//----------------------------------------- 產品尺寸 ----------------------------------------
export const getExtra = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getExtra`, {
    params: _params,
  });
};

export const addExtra = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addExtra`, data);
};

export const deleteExtra = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteExtra`, data);
};

export const updateExtra = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateExtra`, data);
};

//----------------------------------------- 存貨料號 ----------------------------------------
export const searchDeal = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/searchDeal`, {
    params: _params,
  });
};

export const addDeal = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDeal`, data);
};

export const deleteDeal = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteDeal`, data);
};

export const updateDeal = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDeal`, data);
};

export const getCustHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustHd`, {
    params: _params,
  });
};

//----------------------------------------- 取得廠商基本資料(表頭) ----------------------------------------
export const getSuppHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppHd`, {
    params: _params,
  });
};
