import React, { useState, useEffect } from "react";
import { Button, Space, message } from "antd";
import useAPI from "@/hooks/useAPI";
import { getDaycodeTb, deleteDaycodeTb } from "@/service/apis/LMS/LabelManage";

import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export default function TB({ c_lightData, set_c_lightData }) {
  const call_getDaycodeTb = useAPI(getDaycodeTb);
  const call_deleteDaycodeTb = useAPI(deleteDaycodeTb);

  const [s_editData, set_s_editData] = useState({});

  const [s_showModal, set_s_showModal] = useState(false);

  const [s_tableData, set_s_tableData] = useState([]);

  const downloadExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    let excelData = s_tableData.map((x) => {
      return {
        date: dayjs(x.date).format("YYYY-MM-DD"),
        timecode: x.timecode.toString(),
      };
    });

    console.log(excelData);

    const ws = XLSX.utils.json_to_sheet(excelData);
    // ws['!cols'] = tableData;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([excelBuffer], { type: fileType }), "條碼日期明細.xlsx");
  };

  const tableColumns = [
    {
      title: "日期",
      dataIndex: "date",
      render: (text) => <div>{dayjs(text).format("YYYY-MM-DD")}</div>,
    },
    { title: "日期編碼", dataIndex: "timecode" },
  ];

  useEffect(() => {
    call_getDaycodeTb.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getDaycodeTb.status === "suc") {
      set_s_tableData(
        call_getDaycodeTb.data.map((x) => {
          return { ...x, key: x.date };
        })
      );
    } else if (call_getDaycodeTb.status === "err") {
      message.error(call_getDaycodeTb.msg);
      set_s_tableData([]);
    }
  }, [call_getDaycodeTb.status]);

  useEffect(() => {
    if (call_deleteDaycodeTb.status === "suc") {
      call_getDaycodeTb.request();
    } else if (call_deleteDaycodeTb.status === "err") {
      message.error(call_deleteDaycodeTb.msg);
    }
  }, [call_deleteDaycodeTb.status]);

  return (
    <>
      <PageHeader
        title="條碼日期明細"
        extra={
          <Space className="flex justify-end">
            <Button onClick={downloadExcel}>下載日期明細資料</Button>
            <Button
              type="primary"
              onClick={() => {
                set_s_showModal(true);
              }}
            >
              更新日期明細資料
            </Button>
          </Space>
        }
      />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getDaycodeTb.status === "load"}
        c_lightData={c_lightData}
        tbMode={Object.keys(s_editData).length > 0}
      />

      <CustomModal
        width="60%"
        title="重新上傳日期資料"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getDaycodeTb.request(c_lightData);
        }}
      >
        {s_showModal && (
          <ModalContainer
            set_s_showModal={set_s_showModal}
            s_editData={c_lightData}
            tableData={s_tableData}
          />
        )}
      </CustomModal>
    </>
  );
}
