import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { Modal, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { deleteStrikeHD, getStrikeHD } from "@/service/apis/PMS/strike";

import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../../index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
//util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { c_state, updateState } = useContext(DataContext);
  const { searchConfig, lightData } = c_state;
  // useAPI 取得表頭資料
  const call_getStrikeHD = useAPI(getStrikeHD);
  // useAPI 刪除表頭資料
  const call_deleteStrikeHD = useAPI(deleteStrikeHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);

  useImperativeHandle(ref, () => ({
    Call_hd: () =>
      call_getStrikeHD.request({
        ...searchConfig,
        opcode: null,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      }),
  }));

  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 刪除表頭資料
  const removeData = (event, rowData) => {
    Modal.confirm({
      title: t("PMS.util.strike") + t("PMS.util.delete_confirm"), // 沖帳單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的沖帳單資料無誤，再進行刪除",
      content: t("PMS.ap-manage.strike_del_hd_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteStrikeHD.request(rowData),
    });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const N = searchParams.get("N");
    if (N === null) {
      call_getStrikeHD.request({
        ...searchConfig,
        opcode: null,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
    } else {
      call_getStrikeHD.request({
        ...searchConfig,
        opcode: null,
        N: N,
      });

      updateState({
        searchConfig: {
          ...searchConfig,
          searchFlag: "N",
          searchValue: N,
        },
      });
    }
  }, []);

  // useAPI 取得對帳作業表頭
  useEffect(() => {
    const { status, msg, data } = call_getStrikeHD;

    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.opUUID }));
      set_s_tableData(tmpData);
      // 取得所有單號(用來做上一筆、下一筆)
      const new_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ Ns: new_Ns, lightData: {} });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getStrikeHD.status]);

  // useAPI 刪除對帳作業表頭
  useEffect(() => {
    const { status, msg } = call_deleteStrikeHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getStrikeHD.request({
        ...searchConfig,
        opcode: null,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteStrikeHD.status]);

  const tableColumns = [
    {
      title: t("PMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "6%",
      render: (text) => {
        const map = {
          2810: t("PMS.util.opcode_2810"), //"應付沖帳",
        };
        return map[text];
      },
    },
    {
      title: t("PMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    // {
    //   title: t("PMS.util.suppID"), // 廠商ID
    //   dataIndex: "suppID",
    //   width: "8%",
    //   align: "center",
    // },
    {
      title: t("PMS.util.suppNM"), // 廠商簡稱
      dataIndex: "suppNM",
      align: "center",
    },
    {
      title: t("PMS.util.contact"), // 聯絡人
      dataIndex: "contact",
      width: "8%",
      align: "center",
    },
    {
      title: t("PMS.util.tel"), // 聯絡電話
      dataIndex: "tel",
      width: "11%",
      align: "center",
    },
    // {
    //   title: t("PMS.util.tax"), // 各項稅額
    //   dataIndex: "tax",
    //   align: "center",
    //   width: "8%",
    //   render: (text) => formatAmount(text),
    // },
    {
      title: t("PMS.ap-manage.strike_sumtotal"), // 沖帳總金額
      dataIndex: "sumtotal",
      align: "center",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.createID"), // 創建人員
      dataIndex: "createID",
      width: "8%",
      align: "center",
    },
    {
      title: t("PMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "6%",
      render: (text, _, __) => {
        const map = {
          0: t("PMS.ap-manage.Strike_lifeF_0"), //"創立",
          1: t("PMS.ap-manage.Strike_lifeF_1"), //"沖帳覆核",
          T: t("PMS.ap-manage.Strike_lifeF_T"), //"完成沖帳",
        };
        return map[text];
      },
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />

          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />
    </div>
  );
});

export default HD;
