import { useState, useEffect } from "react";
import { Button, Space, Typography, message } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
import { AddButton } from "@/components/PDS/Buttons";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { multipleIDtoName } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { getDoc } from "@/service/apis/ADM/edm";
import { getPF } from "@/service/apis/ADM/publicAPI";
// component
import ModalContainer from "./ModalContainer";

// import Issued from "./Issued";
// import Notify from "./Notify";

const { Link } = Typography;

export default function TB({ c_lightData }) {
  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_TabKey, set_s_TabKey] = useState("doc");
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_pf, set_s_pf] = useState([]);

  // useAPI 取得表身
  const call_getDoc = useAPI(getDoc);
  const call_getPF = useAPI(getPF);

  const tabsData = [
    {
      key: "doc",
      label: t("ADM.edm.doc"),
    },
    {
      key: "ECN",
      label: t("ADM.edm.ECN"),
    },
  ];

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    set_s_TabKey(activeKey);
  };

  // 建立
  const handleCreate = () => {
    set_s_isShowModal(true);
  };

  useEffect(() => {
    call_getDoc.request({ doctype: s_TabKey, PN: c_lightData.PN });
    call_getPF.request();
  }, [s_TabKey]);

  useEffect(() => {
    const { data, status, msg } = call_getDoc;
    if (status === "suc") {
      message.success(msg);
      console.log("data = ", data);
      const _data = data?.map((item) => ({
        ...item,
        key: `${item.PN}${item.doctype}${item.ver}`,
      }));
      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${call_getDoc.msg}`));
    }
  }, [call_getDoc.status]);

  useEffect(() => {
    if (call_getPF.status === "suc") {
      message.success(call_getPF.msg);
      set_s_pf(call_getPF.data);
    }
    if (call_getPF.status === "err") {
      message.error(call_getPF.msg);
    }
  }, [call_getPF.status]);

  const tableColumns = [
    {
      title: t("ADM.edm.pdftype"), //"文件類別",
      dataIndex: "doctype",
      align: "center",
      width: "8%",
    },
    {
      title: t("ADM.production-line-manage.pf"), //"製程名稱",
      dataIndex: "pfID",
      align: "center",
      render: (text) => multipleIDtoName(s_pf, text, "pfID", "pfNM")
    },
    {
      title: t("ADM.edm.docVer"), //"文件版本",
      dataIndex: "ver",
      align: "center",
      width: "8%",
    },
    {
      title: t("ADM.edm.docName"), //"文件名稱",
      dataIndex: "path",
      align: "center",
      width: "20%",
      render: (text) => {
        const splitArr = text.split("/");
        return (
          <Link
            onClick={() => {
              window.open(text, "_block");
            }}
          >
            {splitArr[splitArr.length - 1]}
          </Link>
        );
      },
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.updtT"),
      dataIndex: "updtT",
      align: "center",
      width: "5%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <CustomTabs
        items={tabsData}
        activeKey={s_TabKey}
        onChange={(activeKey) => handleChangeTab(activeKey)}
        tabBarExtraContent={<AddButton type="primary" onClick={handleCreate} />}
      />

      <CustomTable dataSource={s_tableData} columns={tableColumns} pagination={false} />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t(`ADM.edm.${s_TabKey}`)}`
            : `${t("util.add")}${t(`ADM.edm.${s_TabKey}`)}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getDoc.request({ doctype: s_TabKey, PN: c_lightData.PN });
        }}
      >
        <ModalContainer
          s_TabKey={s_TabKey}
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          s_pf={s_pf}
        />
      </CustomModal>
    </>
  );
}
