import { PlusOutlined, SearchOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import { Button } from "antd";

// 新建的按鈕
export const AddButton = (props) => {
  return (
    <Button
      shape="circle"
      className={
        !props.disabled &&
        `
      bg-[#4AC6F5] border-[#4AC6F5] text-white
      hover:bg-[#7BD2F3] hover:border-[#7BD2F3]
      focus:bg-[#4AC6F5] focus:border-[#4AC6F5]
      ${props.className}
      `
      }
      {...props}
    >
      <PlusOutlined />
    </Button>
  );
};

// 查詢的按鈕
export const SearchButton = (props) => {
  return (
    <Button className={`flex text-center justify-center items-center`} {...props}>
      <SearchOutlined />
    </Button>
  );
};

// 展開的按鈕
export const ExpandButton = (props) => {
  return (
    <Button
      type="primary"
      className={"flex text-center justify-center items-center px-8"}
      icon={<ArrowsAltOutlined className="text-[20px]" />}
      {...props}
    />
  );
};
