import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space } from "antd";
import { addLine, modifyLine, getStation } from "@/service/apis/ADM/publicAPI";
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
export default function ModalConintaer({ arrMap, set_s_editData, s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();
  const f_pfID = Form.useWatch("pfID", form);
  const f_wsID = Form.useWatch("wsID", form);
  const f_pwcID = Form.useWatch("pwcID", form);
  const call_addLine = useAPI(addLine);
  const call_modifyLine = useAPI(modifyLine);
  const call_getStation = useAPI(getStation);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    values.psID = values.psID?.join("|");
    values.pwcID = values.pwcID?.join("|");
    values.pccID = values.pccID?.join("|");

    if (type === "create") {
      call_addLine.request(values);
    }
    if (type === "edit") {
      call_modifyLine.request(values);
    }
  };

  const changePwcID = (pwcIDs = []) => {
    const pccIDs = arrMap.pccArr?.reduce((arr, curr) => {
      for (const pwcID of pwcIDs) {
        pwcID === curr.pwcID && arr.push(curr.pccID);
      }
      return arr;
    }, []);
    form.setFieldsValue({ pccID: pccIDs });
  };

  useEffect(() => {
    call_getStation.request();
  }, []);

  useEffect(() => {
    if (f_pwcID && f_pwcID.length > 0) {
      changePwcID(f_pwcID);
    }
  }, [f_pwcID]);

  useEffect(() => {
    if (call_addLine.status === "suc") {
      message.success(call_addLine.msg);
      set_s_isShowModal(false);
    } else if (call_addLine.status === "err") {
      message.error(t(`errorCode.${call_addLine.msg}`));
    }
  }, [call_addLine.status]);

  useEffect(() => {
    if (call_modifyLine.status === "suc") {
      message.success(call_modifyLine.msg);
      set_s_isShowModal(false);
    } else if (call_modifyLine.status === "err") {
      message.error(t(`errorCode.${call_modifyLine.msg}`));
    }
  }, [call_modifyLine.status]);

  useEffect(() => {
    if (call_getStation.status === "err") {
      message.error(t(`errorCode.${call_getStation.msg}`));
    }
  }, [call_getStation.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={s_editData}>
      <Row gutter={[24, 12]}>
        <Col span={6}>
          {/* "工線ID" */}
          <Form.Item
            label={t("ADM.production-line-manage.wlID")}
            name="wlID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "工線名稱" */}
          <Form.Item
            label={t("ADM.production-line-manage.wlNM")}
            name="wlNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "製程" */}
          <Form.Item
            label={t("ADM.production-line-manage.pf")}
            name="pfID"
            // rules={[{ required: true }]}
          >
            <Select
              // placeholder="請選擇製程"
              allowClear
              onChange={() => {
                form.setFieldsValue({ wsID: null, psID: [], pwcID: [], pccID: [] });
              }}
              options={
                arrMap.pfArr?.map((x) => ({
                  label: `${x.pfID}_${x.pfNM}`,
                  value: x.pfID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "工站" */}
          <Form.Item
            label={t("ADM.production-line-manage.ws")}
            name="wsID"
            rules={[{ required: true }]}
          >
            <Select
              // placeholder="請選擇工站"
              allowClear
              // disabled={!f_pfID}
              options={call_getStation.data?.reduce((options, curr) => {
                // curr.pfID.includes(f_pfID) &&
                options.push({
                  label: curr.wsID + "_" + curr.wsNM,
                  value: curr.wsID,
                  data: curr,
                });
                return options;
              }, [])}
              onChange={(_, data) =>
                form.setFieldsValue({
                  psID: data?.data?.psID.split("|"),
                  psNM: data?.data?.psID,
                  pwcID: [],
                  pccID: [],
                })
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* "車間" */}
          <Form.Item label={t("ADM.production-line-manage.ps")} name="psID">
            <Select
              mode="multiple"
              // placeholder="請選擇工站"
              disabled
              options={
                arrMap.psArr?.map((x) => ({
                  label: `${x.psID}_${x.psNM}`,
                  value: x.psID,
                  data: x,
                })) || []
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* "工作中心" */}
          <Form.Item label={t("ADM.production-line-manage.pwc")} name="pwcID" shouldUpdate>
            <Select
              mode="multiple"
              // placeholder="請選擇工作中心"
              disabled={!f_wsID}
              allowClear
              options={arrMap.pwcArr?.reduce((options, curr) => {
                call_getStation.data
                  .find((item) => item.wsID === f_wsID)
                  ?.pwcID.includes(curr.pwcID) &&
                  options.push({
                    label: curr.pwcID + "_" + curr.pwcNM,
                    value: curr.pwcID,
                    data: curr,
                  });
                return options;
              }, [])}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* "成本中心" */}
          <Form.Item label={t("ADM.production-line-manage.pcc")} name="pccID" shouldUpdate>
            <Select
              mode="multiple"
              // placeholder="請選擇工作中心"
              disabled
              options={arrMap.pccArr?.map((x) => ({
                label: x.pccID + "_" + x.pccNM,
                value: x.pccID,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
