/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Spin,
  Tooltip,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Radio,
  Typography,
  Select,
  InputNumber,
  Modal,
  Button,
  message,
  Grid,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "qs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import PageHeader from "@/components/PageHeader";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// component
import TbContainer from "./Details/TbContainer";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
// import { getPlanHD, getPlanTB, addPlanHD, updatePlanHD } from "../moke";
import { getPlanHD, getPlanTB, addPlanHD, updatePlanHD } from "@/service/apis/PMS/Plan";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  isvalid: true, // 是否有效
  quoSearch: true,
  note: true,
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 客戶確認數量
  PN: true,
  pdtspec: true,
  demandqty: true,
  tb_note: true,
  tb_delete: true,
  tb_submit: true,
  rec_createBtn: true,
  rec_spec: true,
  rec_note: true,
  rec_delete: true,
  rec_submit: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getPlanHD = useAPI(getPlanHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getPlanHD = useAPI(getPlanHD);
  // useAPI 取得表身資料
  const call_getPlanTB = useAPI(getPlanTB);
  // useAPI 新增表頭
  const call_finish_addPlanHD = useAPI(addPlanHD);
  // useAPI 更新表頭
  const call_updatePlanHD = useAPI(updatePlanHD);
  // useAPI 儲存表頭
  const call_finish_updatePlanHD = useAPI(updatePlanHD);
  // useAPI 取得上一筆資料
  const call_prev_updatePlanHD = useAPI(updatePlanHD);
  // useAPI 取得下一筆資料
  const call_next_updatePlanHD = useAPI(updatePlanHD);
  const [form] = Form.useForm();
  const { c_state, updateState } = useContext(DataContext);
  const { c_staffs } = useContext(ERPContext);
  const { mainPage, plan_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);

  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addPlanHD.request({
        ...values,
        // 新建時lifeFT預設當下時間
        lifeFT: dayjs(),
      });
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updatePlanHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        isvalid: false,
        note: false,
        // ------------ tb ------------
        // 建立表身的按鈕
        createBtn: isCreateByThis ? false : isNewdoc,
        PN: false,
        pdtspec: false,
        demandqty: false,
        tb_note: false,
        tb_submit: false,
        tb_delete: false,
        rec_createBtn: false,
        rec_spec: false,
        rec_note: false,
        rec_delete: false,
        rec_submit: false,
      });
    }
  };
  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      // message.success("目前資料為第一筆，已無上一筆資料");
      message.success(t("PMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updatePlanHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === plan_Ns.length) {
      // message.success("目前資料為最後一筆，已無下一筆資料");
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updatePlanHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = plan_Ns.findIndex((item) => item === N);

    return index;
  };

  // 據此條目創建
  const createByThis = () => {
    // 重新 set formData
    const newData = {
      ...mainPage.data,
      lifeF: "0",
      lifeFT: dayjs(),
      createT: dayjs(),
    };
    Object.entries(mainPage.data).forEach(([key, value]) => {
      if (isValidDate(value)) {
        newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
      }
    });
    form.setFieldsValue({
      ...newData,
    });

    change_MainPage_disabled(newData);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_tbData(
      s_tbData?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
        };
      })
    );
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建報價單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        isvalid: true,
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getPlanTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });

      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getPlanHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getPlanHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getPlanTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];

      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getPlanTB.status]);

  // 新增詢價計畫表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addPlanHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getPlanHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getPlanTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addPlanHD.status]);

  // 編輯詢價計畫表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updatePlanHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getPlanHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getPlanTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updatePlanHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updatePlanHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // message.success("取得上一筆資料成功");
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getPlanHD.request({ N: plan_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      // message.success("取得上一筆資料失敗");
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updatePlanHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updatePlanHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // message.success("取得下一筆資料成功");
      message.success(t("PMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getPlanHD.request({ N: plan_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      // message.success("取得下一筆資料失敗");
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updatePlanHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getPlanHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getPlanTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getPlanHD.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯報價主檔" : "新建報價主檔"} /> */}
            <PageHeader
              title={
                (editMode ? t("PMS.util.table_edit") : t("PMS.util.create")) +
                t("PMS.plan.pageHeader")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  change_MainPage_disabled(s_oriData.hd);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="lifeFT" hidden>
            <DatePicker />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="createT" hidden>
            <DatePicker />
          </Form.Item>
        </div>

        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              label={<Text disabled={s_disabled.opcode}>{t("PMS.util.opcode")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.opcode") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("PMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("PMS.plan.pageHeader"), value: "2010" }, // 國內報價
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 是否有效 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.isvalid}>{t("util.isvalid")}</Text>}
              name="isvalid"
              rules={[{ required: true }]}
              messageVariables={{ label: t("util.isvalid") }}
            >
              <Radio.Group name="isvalid" disabled={s_disabled.isvalid}>
                <Radio value={true}>{t("util.yes")}</Radio>
                <Radio value={false}>{t("util.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          {/* 更新人名 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.updtID")}</Text>} name="updtID">
              <Select
                placeholder={t("util.placeholder_select") + t("PMS.util.updtID")}
                disabled
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("PMS.util.accord")}

                {t("PMS.util.create")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          <Col span={editMode ? 15 : 18} className="pb-2">
            <Form.Item label={<Text disabled={s_disabled.note}>{t("util.note")}</Text>} name="note">
              <Input disabled={s_disabled.note} />
            </Form.Item>
          </Col>
          {/* 更新日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.updtT")}</Text>} name="updtT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="pb-2 border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button className="flex-1 h-full" disabled>
                {/* 計畫紀錄查詢 */}
                {t("OMS.util.planSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <TbContainer
          call_getPlanTB={call_getPlanTB}
          hd_values={form.getFieldsValue()}
          s_tbData={s_tbData}
          s_disabled={s_disabled}
        />
      </Form>
    </div>
  );
};

export default MainPage;
