import {
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { addSuppHd, modifySuppHd } from "@/service/apis/ADM/company";
import { getAdd, getCurrency } from "@/service/apis/ADM/functionParamsManage";

import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_editData, set_s_isShowModal, arrMap }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addSuppHd = useAPI(addSuppHd);
  const call_modifySuppHd = useAPI(modifySuppHd);
  const call_getAdd = useAPI(getAdd);
  const call_getCurrency = useAPI(getCurrency);

  const onSubmit = async (values) => {
    //const result = await form.validateFields()
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|") ?? "";
    }
    // 兆科沒有使用 spuualias 但是系統很多地方都有使用，因此預設 suppalias = suppNM
    values.suppalias = values.suppNM;

    if (type === "create") {
      call_addSuppHd.request(values);
    }

    if (type === "edit") {
      call_modifySuppHd.request(values);
    }
  };
  useEffect(() => {
    if (call_getCurrency.status === "suc") {
      console.log(call_getCurrency.data);
      const currencyIsvalidAry = call_getCurrency.data.filter((data) => data.isvalid === true);
      form.setFieldsValue({
        maincurrency: currencyIsvalidAry[0].currencyitem,
      });
    }
  }, [call_getCurrency.status]);
  useEffect(() => {
    console.log(s_editData);
    call_getAdd.request();
    call_getCurrency.request();

    form.setFieldsValue({
      // querycat: [arrMap?.s_mgmtcat72Options[0].value],
      querycat: arrMap?.s_mgmtcat72Options[0].value,
      mgmtcat: [arrMap?.s_mgmtcat71Options[0].value],
    });
  }, []);

  useEffect(() => {
    if (call_addSuppHd.status === "suc") {
      message.success(call_addSuppHd.msg);
      set_s_isShowModal(false);
    } else if (call_addSuppHd.status === "err") {
      message.error(t(`errorCode.${call_addSuppHd.msg}`));
    }
  }, [call_addSuppHd.status]);

  useEffect(() => {
    if (call_modifySuppHd.status === "suc") {
      message.success(call_modifySuppHd.msg);
      set_s_isShowModal(false);
    } else if (call_modifySuppHd.status === "err") {
      message.error(t(`errorCode.${call_modifySuppHd.msg}`));
    }
  }, [call_modifySuppHd.status]);

  // useEffect(() => {
  //   if (call_getAdd.status === "err") {
  //     message.error(call_getAdd.msg);
  //   }
  // }, [call_getAdd.status]);

  // useEffect(() => {
  //   if (call_getCurrency.status === "err") {
  //     message.error(call_getCurrency.msg);
  //   }
  // }, [call_getCurrency.status]);
  console.log("arrMap?.s_mgmtcat71Options = ", arrMap?.s_mgmtcat71Options);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="rebate" hidden>
          <Radio.Group
            options={[
              { value: true, label: t("util.yes") },
              { value: false, label: t("util.no") },
            ]}
          />
        </Form.Item>
        <Form.Item hidden name="isvalid">
          <Radio.Group
            options={[
              { value: true, label: t("util.yes") },
              { value: false, label: t("util.no") },
            ]}
          />
        </Form.Item>
        <Form.Item hidden name="suppalias">
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={24}>
          <Collapse defaultActiveKey={[1]}>
            <Panel header={t("ADM.company.manufacturerBasicInformation")} key="1">
              <Row gutter={[24, 0]}>
                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.suppID")} // 客戶ID
                    name="suppID"
                    // rules={[{ required: true }]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.suppalias")} // 客戶簡稱
                    name="suppalias"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.suppNM")} // 客戶名稱
                    name="suppNM"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* <Col span={8}>
                  <Form.Item label={t("ADM.company.groupalias")} name="groupalias">
                    <Input />
                  </Form.Item>
                </Col> */}

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.location")} // 市場區域
                    name="location"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}
                {/* <Col span={8}>
                  <Form.Item
                    label={t("util.querycat")} // 客戶分類
                    name="querycat"
                    // rules={[{ required: true }]}
                  >
                    <Select allowClear options={arrMap?.s_mgmtcat72Options} />
                  </Form.Item>
                </Col> */}

                {/* <Col span={8}>
                  <Form.Item
                    label={t("util.mgmtcat")}
                    name="mgmtcat" // 授權分類
                    // rules={[{ required: true }]}
                  >
                    <Select mode="multiple" allowClear options={arrMap?.s_mgmtcat71Options} />
                  </Form.Item>
                </Col> */}
                <Col span={8}>
                  <Form.Item
                    label={t("ADM.client.location")} //市場區域
                    name="location"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.maincurrency")} // 幣別
                    name="maincurrency"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getCurrency.data?.map((x) => ({
                          label: x.itemNM,
                          value: x.currencyitem,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.tel")} // 公司電話
                    name="tel"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="供应商/分包商传真" // 客戶傳真
                    name="fax"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.suppfax")} // 公司電話
                    name="fax"
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.taxID")} // 統一編號
                    name="taxID"
                    // rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col span={2}>
                  <Form.Item
                    label={t("ADM.company.maxcredit")} // 结算方式
                    name="maxcredit"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={[
                        { label: "现结", value: "现结" },
                        { label: "月结", value: "月结" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    label="统一社会信用代码"
                    name="usciCode"
                    // rules={[{ required: true }]}
                  >
                    <Input placeholder="请输入统一社会信用代码" />
                  </Form.Item>
                </Col>
                {/* <Col span={4}>
                  <Form.Item
                    label={
                      t("util.isvalid") // 有效
                    }
                    name="isvalid"
                  >
                    <Radio.Group
                      options={[
                        { value: true, label: t("util.yes") },
                        { value: false, label: t("util.no") },
                      ]}
                    />
                  </Form.Item>
                </Col> */}
                <Col span={16}>
                  <Form.Item
                    label={
                      t("ADM.company.addr") // 公司地址
                    }
                    name="addr"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("util.note")} name="note">
                    <Input.TextArea rows={1} />
                  </Form.Item>
                </Col>

                {/* <Col span={8}>
                  <Form.Item
                    label={t("ADM.company.payterm")}//付款條件
                    name="payterm"
                    // rules={[{ required: true }]}
                  >
                    <Select
                      options={
                        call_getAdd.data?.filter(item => item.addtype === 'addh')?.map((x) => ({
                          label: x.itemNM,
                          key: x.additem,
                          value: x.itemNM,
                        })) || []
                      }
                    />
                  </Form.Item>
                </Col> */}
              </Row>
            </Panel>
            <Panel header="开票资料" key="2">
              <Row gutter={[24, 0]}>
                <Col span={6}>
                  <Form.Item
                    label={
                      <span className="text-[#9FB2FC]">{t("ADM.company.domesticBankName")}</span>
                    }
                    name="bank"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={9}>
                  <Row gutter={[24, 0]}>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.branchOS")} name="branch">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.bankCodeOS")} name="bankCode">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col> */}

                <Col span={6}>
                  <Form.Item
                    label={t("ADM.client.bankCodeOS")} //分行代號
                    name="bankCodeOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label={t("ADM.company.account")} name="account">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label={t("ADM.company.username")} name="username">
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col span={24}>
                  <Divider className="mb-6 p-0" />
                </Col> */}

                {/* <Col span={6}>
                  <Form.Item
                    label={
                      <span className="text-[#9FB2FC]">{t("ADM.company.overseasBankName")}</span>
                    }
                    name="bankOS"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={9}>
                  <Row gutter={[24, 0]}>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.branchOS")} name="branchOS">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item label={t("ADM.company.bankCodeOS")} name="bankCodeOS">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col span={5}>
                  <Form.Item label={t("ADM.company.swiftCode")} name="swiftCode">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item label={t("ADM.company.account")} name="accountOS">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={t("ADM.company.username")} name="usernameOS">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item label={t("ADM.company.bankAddrOS")} name="bankAddrOS">
                    <Input />
                  </Form.Item>
                </Col> */}
              </Row>
            </Panel>
          </Collapse>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={call_addSuppHd.status === "load" || call_modifySuppHd.status === "load"}
            >
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
