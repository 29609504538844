import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

// 取得對帳單表頭資料
export const getRcvHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRcvHD`, {
    params: data,
  });
};

// 取得對帳單表身資料
export const getRcvTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRcvTB`, {
    params: data,
  });
};

// 編輯對帳單資料
export const updateRcvHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRcvHD`, data);
};
