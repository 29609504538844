/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Spin,
  message,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import { SwapRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
// API
import useAPI from "@/hooks/useAPI";
import { getInstockHD } from "@/service/apis/PMS/Instock";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../../index";

const { Text } = Typography;

const DetailModal = ({
  // s_detailSearch,
  // set_s_detailSearch,
  call_getInstockHDAd,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // useAPI 取得全部進料單表頭資料
  const call_getInstockHD = useAPI(getInstockHD);
  // 廠商資料、人員資料
  const { c_supps, c_staffs } = useContext(ERPContext);
  // page 的context
  const { c_state, updateState } = useContext(DataContext);
  const { detailSearch } = c_state;
  // 全部進料單單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      sampleID: values.sampleID?.join("|") ?? "",
      createID: values.createID?.join("|") ?? "",
      suppID: values.suppID?.join("|") ?? "",
      startDate: values.createT ? dayjs(values.createT[0]).format("YYYY-MM-DD") : null,
      endDate: values.createT ? dayjs(values.createT[1]).format("YYYY-MM-DD") : null,
      sampleStart: values.sampleT ? dayjs(values.sampleT[0]).format("YYYY-MM-DD") : null,
      sampleEnd: values.sampleT ? dayjs(values.sampleT[1]).format("YYYY-MM-DD") : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getInstockHDAd.request(new_data);
    // 更新資料並關閉跳顯
    // 更新資料並關閉跳顯
    updateState({ detailSearch: { show: false, data: values } });
  };

  // 第一次渲染取得全部進料單做下拉選單、賦值初始值
  useEffect(() => {
    call_getInstockHD.request();
    const { data } = detailSearch;
    if (Object.keys(data).length > 0) {
      form.setFieldsValue({
        ...data,
        createT: data.createT ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])] : null,
        sampleT: data.sampleT ? [dayjs(data.sampleT?.[0]), dayjs(data.sampleT?.[1])] : null,
      });
    }
  }, []);

  // useAPI 取得全部進料單資料
  useEffect(() => {
    const { data, msg, status } = call_getInstockHD;

    if (status === "suc") {
      const allNs = data?.map((proItem) => ({ lable: proItem.N, value: proItem.N })) ?? [];
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getInstockHD.status]);

  return (
    <Spin size="large" spinning={call_getInstockHD.status === "load"}>
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.opcode")}</Text>} name="opcode">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.opcode_placeholder")}
                options={[
                  { value: "2410", label: t("PMS.util.opcode_2410") },
                  { value: "2420", label: t("PMS.util.opcode_2420") },
                  { value: "2430", label: t("PMS.util.opcode_2430")  },
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.N")}</Text>} name="N">
              <Select mode="multiple" allowClear placeholder={t("PMS.util.placeholder_select")+t("PMS.util.N")} options={s_allNs} />
            </Form.Item>
          </Col>
          {/* 抽樣人員 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.instock.sampleID")}</Text>} name="sampleID">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select")+t("PMS.instock.sampleID")}
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 創建人員 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.createID")}</Text>} name="createID">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select")+t("PMS.util.createID")}
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
          {/* 創建日期 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.createT")}</Text>} name="createT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
          {/* 抽樣日期 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.instock.sampleT")}</Text>} name="sampleT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商簡稱 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.suppalias")}</Text>} name="suppID">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.suppID_placeholder")}
                options={c_supps.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[12, 0]}>
          <Col span={24} className="flex justify-end gap-1">
            <CancelButton onClick={() => updateState({ detailSearch: { ...detailSearch, show: false } })}/>
            <ConfirmButton type="primary" htmlType="submit"/>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DetailModal;
