import {
	ClearButton,
	CreateButton,
	DeleteButton,
	DetailButton,
	EditButton,
	ExcelButton,
	SeeMoreButton,
} from "@/components/ERP/Button";
// import { TourButton } from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Divider, Drawer, Grid, Input, Modal, Space, Typography, message, Select } from "antd";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { IconCheck, IconClose } from "@/components/Icon/Action";
import exportExcel from "@/util/exportExcel";
import { IconExcelDownload } from "@/components/Icon/Action";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteOrdHD, excelOrdDoc, getOrdHD, updateOrdHD } from "@/service/apis/OMS/Ord";
import { getStaff } from "@/service/apis/ADM/userManage";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { default as CustomTableOrd } from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { DataContext } from "../index";
import DetailModal from "./Modal/DetailModal";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Light from "@/components/ERP/Light";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const HD = () => {
	const { t } = useTranslation();
	// useAPI 取得表頭資料
	const call_getOrdHD = useAPI(getOrdHD);
	// useAPI 詳細搜尋取得表頭資料
	const call_getOrdHDAD = useAPI(getOrdHD);
	// useAPI 刪除表頭資料
	const call_deleteOrdHD = useAPI(deleteOrdHD);
	// useAPI 強制結令
	const call_updateOrdHD = useAPI(updateOrdHD);

	const call_excelOrdDoc = useAPI(excelOrdDoc)

	// 第三方套件用來判斷URL是否帶有資料
	const [searchParams, setSearchParams] = useSearchParams();

	const { c_state, updateState } = useContext(DataContext);
	const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
	// 客戶資料、人員資料
	const { c_custs, c_staffs, getStaffName } = useContext(ERPContext);
	const { c_userData } = useContext(SystemContext);
	// 表格的資料
	const [s_tableData, set_s_tableData] = useState([]);
	console.log("s_tableData = ", s_tableData);
	// ㄧ階搜尋的下拉選單選項
	const [s_selectOptions, set_s_selectOptions] = useState({
		// custType: "C",
		custID: [],
		driID: [],
		lifeF: [
			{ value: "0", label: t("OMS.ord.lifeF_0") }, // 發佈
			// { value: "0", label: t("OMS.ord.lifeF_0") }, // 發佈
			// { value: "2", label: t("OMS.ord.lifeF_2") }, // 訂購覆核
			// { value: "7", label: t("OMS.ord.lifeF_7") }, // 客戶簽回
			{ value: "5", label: t("OMS.ord.lifeF_5") }, // 完成委托
		],
	});
	// 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
	const [s_detailSearch, set_s_detailSearch] = useState({
		show: false,
		data: {},
	});

	// 控制抽屜開啟、關閉、抽屜要顯示的資料
	const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
	// 負責業務 - 下拉式選單
	const [s_staff_options, set_s_staff_options] = useState([]);
	// 用來判斷目前螢幕尺寸
	const screens = Grid.useBreakpoint();
	// CustomTab 要顯示的選項
	const tabsData = [
		{ key: "1310", label: t("OMS.util.opcode_1310") }, // 终端客户
		// { key: "1320", label: t("OMS.util.opcode_1320") }, // 合作伙伴
		// { key: "1330", label: t("OMS.util.opcode_1330") }, // 其他檢測机构
		// { key: "detailSearch", label: t("OMS.util.advanceSearch") }, // 進階查詢
	];

	const placeholder_map = {
		N: t("util.placeholder_input") + t("OMS.util.N"), // 請輸入單號
		custID: t("util.placeholder_select") + t("OMS.util.custNM"), // 請選擇客戶
		lifeF: t("util.placeholder_select") + t("OMS.util.lifeF"), // 請選擇歷程
		driID: t("util.placeholder_select") + t("OMS.ord.driID"), // 請選擇負責業務
	};

	// 切換 Tab 時執行
	// const handleChangeTab = (activeKey) => {
	//   updateState({
	//     tabKey: activeKey,
	//     opcode: activeKey === "detailSearch" ? "" : activeKey,
	//     searchConfig:
	//       activeKey === "detailSearch"
	//         ? { ...INIT_SEARCHCONFIG, searchValue: "" }
	//         : INIT_SEARCHCONFIG,
	//     lightData: {},
	//   });

	//   // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
	//   if (activeKey === "detailSearch") {
	//     set_s_tableData([]);
	//   }
	// };

	const handleChange = (type, value) => {
		console.log({ type, value });
		// 創建一個臨時的物件，更新搜尋設定
		const tmp = {
			...searchConfig,
			// 根據傳入的類型，更新相應的值
			[type]: value,
			// 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
			startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
			endDate:
				type === "time" ? dayjs(value[0]).endOf("day").format("YYYY-MM-DD") : searchConfig.endDate,
			// 如果傳入的類型是搜尋欄位，則清空搜尋文字
			searchValue:
				type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
		};
		// 更新狀態
		updateState({ searchConfig: tmp });
	};

	const handleChangeInput = (value) => {
		const tmp = {
			...searchConfig,
			searchValue: value,
			// 如果 '搜尋文字' 為空要清空 '搜尋欄位'
			searchFlag: value === "" ? undefined : searchConfig.searchFlag,
		};
		// 更新狀態
		updateState({ searchConfig: tmp });
		// 如果搜尋文字為空要重新取得表頭資料
		if (value === "") {
			call_getOrdHD.request({ ...tmp, opcode: tabKey });
		}
	};

	// 搜尋(一階搜尋用)
	const handleSearch = () => {
		call_getOrdHD.request({
			...searchConfig,
			opcode: tabKey,
			// driID: "",
			// dayjs(value[0]).endOf('day').format("YYYY-MM-DD HH:mm:ss")
			startDate: !searchConfig.startDate
				? null
				: dayjs(searchConfig.startDate).hour(0).minute(0).second(1).format("YYYY-MM-DD HH:mm:ss"),
			endDate: !searchConfig.startDate
				? null
				: dayjs(searchConfig.endDate).hour(23).minute(59).second(59).format("YYYY-MM-DD HH:mm:ss"),
			[searchConfig.searchFlag]: searchConfig.searchValue,
		});
		console.log("call in handelSearch");

		// 更新狀態
		updateState({ lightData: {} });
	};
	// 查看更多
	const openDetail = (event, rowData) => {
		set_s_drawer({ show: true, data: rowData });
		event.stopPropagation();
	};
	// 清除搜尋紀錄
	const clearSearch = () => {
		// 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
		const tmp = {
			...searchConfig,
			opcode: tabKey,
			startDate: dayjs().startOf("month"), // 開始日期
			endDate: dayjs().endOf("month"), // 結束日期
			searchFlag: undefined, // 要搜尋的欄位名稱
			searchValue: undefined,
		};

		// 清除搜尋紀錄
		updateState({ searchConfig: tmp, lightData: {} });

		// 重新取得表頭資料
		console.log("tmp = ", tmp);
		call_getOrdHD.request(tmp);
		console.log("call");

		// 清空URL帶的資料
		searchParams.delete("opUUID");
		searchParams.delete("preopUUID");
		searchParams.delete("opcode");
		setSearchParams(searchParams);
	};
	// 強制結令
	const closeData = (event, rowData) => {
		Modal.confirm({
			title: t("OMS.util.ord") + t("OMS.util.table_close") + t("util.ok"), //銷貨單強制結令確認
			icon: <ExclamationCircleOutlined className="text-red-500" />,
			content: t("OMS.ord.closeData_confirm_text"),
			okText: <IconConfirm />, // 確定
			cancelText: <IconCancel />, // 取消
			onOk: () => {
				// call 更新表頭的API
				call_updateOrdHD.request({ ...rowData, lifeF: "T" });
			},
		});
		// 阻止冒泡
		event.stopPropagation();
	};
	// 刪除表頭資料
	const removeData = (event, rowData) => {
		if (rowData.lifeF === "T" || rowData.count > 0) {
			event.stopPropagation();
			return;
		}

		Modal.confirm({
			title: t("OMS.util.ord") + t("OMS.util.del_confirm"), // 銷貨單刪除確認
			icon: <ExclamationCircleOutlined className="text-red-500" />,
			content: t("OMS.ord.removeData_confirm_text"),
			okText: <IconConfirm />, // 確定
			cancelText: <IconCancel />, // 取消
			onOk: () => call_deleteOrdHD.request(rowData),
		});
		event.stopPropagation();
	};

	// 編輯
	const editData = (event, rowData) => {
		updateState({ mainPage: { show: true, data: rowData } });
		event.stopPropagation();
	};

	const handleKeyDownEnter = (e) => {
		if (e.key === "Enter") {
			// set_c_lightData({});
			console.log("in keydown");
			call_getOrdHD.request({ ...searchConfig });
		}
	};

	// // //CALL API從後端導出EXCEL
	const exportToExcel = () => {
		// 將opUUID轉為陣列給後端產EXCEL
		const opUUIDs = s_tableData?.map((item) => item.opUUID)

		call_excelOrdDoc.request({
			// ...s_tableData,
			opUUID: opUUIDs,
		});
	};

	useEffect(() => {
		const { status, msg, data } = call_excelOrdDoc;
		if (status === "suc") {
			message.success(msg);
			console.log("data", data);

			const link = document.createElement("a"); // DOM創建a連結
			const fileName = data.split("/").reverse()[0];

			// link.target = "_blank";
			link.download = fileName;
			link.href = data; // 置連結的 href 屬性為 data，即文件的 URL
			console.log("link.href = ", link.href);

			document.body.appendChild(link); // 將 <a> 標籤添加到 body 中，使其成為 DOM 的一部分
			// return
			link.click(); // 觸發 <a> 標籤的 click 事件
			document.body.removeChild(link); // 關閉 <a> 標籤 清理 DOM。
		}
		if (status === "err") {
			message.error(msg);
		}
	}, [call_excelOrdDoc.status]);


	// 第一次渲染取得表頭資料
	useEffect(() => {
		const opcode = searchParams.get("opcode");
		const opUUID = searchParams.get("opUUID");
		const preopUUID = searchParams.get("preopUUID");

		// opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
		if (opcode === null) {
			call_getOrdHD.request({
				...searchConfig,
				opcode: tabKey,
				[searchConfig.searchFlag]: searchConfig.searchValue,
			});
			console.log("in opcode");

			return;
		}

		// 程式往下走代表現在是查詢上下位資料
		updateState({
			searchConfig: {
				...searchConfig,
				startDate: undefined,
				endDate: undefined,
				// here
				driID: "",
			},
			tabKey: opcode,
		});

		// const result = s_staff_options.find((item) => item.label === c_userData?.staffID);
		// console.log("result", result);
		// call_getOrdHD.request({
		//   // ...searchConfig,
		//   // opcode: "",
		//   startDate: undefined,
		//   driID: result?.value,
		//   endDate: undefined,
		//   [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
		// });
		// console.log("call api in useEffect tabkey");
	}, [tabKey]);

	//===================try
	useEffect(() => {
		const opUUID = searchParams.get("opUUID");
		const preopUUID = searchParams.get("preopUUID");

		if (s_staff_options.length > 0) {
			const result = s_staff_options.find((item) => item.value === c_userData?.staffID);
			console.log("result", result);

			if (result?.deptNM === "業務部門") {
				call_getOrdHD.request({
					...searchConfig,
					opcode: tabKey,
					driID: result?.value,
					[opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
				});
				// updateState({ driID: result?.value });
				updateState({
					searchConfig: {
						...searchConfig,
						startDate: undefined,
						endDate: undefined,
						// here
						driID: result?.value,
					},
				});
			} else {
				call_getOrdHD.request({
					...searchConfig,
					opcode: tabKey,
					driID: "All",
					[opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
				});
				updateState({
					searchConfig: {
						...searchConfig,
						startDate: undefined,
						endDate: undefined,
						driID: "All",
					},
				});
			}

			console.log("call api in useEffect tabkey");
		}
	}, [c_userData, s_staff_options]);

	// 下拉選單覆值
	useEffect(() => {
		set_s_selectOptions((prev) => ({
			...prev,
			custID: c_custs.options,

			driID: c_staffs.options,
		}));
	}, [c_custs, c_staffs]);

	// useAPI 取得表頭資料
	useEffect(() => {
		const { status, data, msg } = call_getOrdHD;

		if (status === "suc") {
			// 把資料塞進表頭的table
			set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
			// 取得所有單號(用來做上一筆、下一筆)
			const newOrd_Ns = data?.map((item) => item.N) ?? [];
			// 更新資料
			updateState({ ord_Ns: newOrd_Ns });
			// UI顯示API回傳的狀態
			message.success(msg);
		}
		console.log("call_getOrdHD = ", call_getOrdHD.data);
		if (status === "err") {
			set_s_tableData([]);
			message.error(t(`errorCode.${msg}`));
		}
	}, [call_getOrdHD.status]);

	// useAPI 詳細搜尋取得表頭資料
	useEffect(() => {
		const { status, data, msg } = call_getOrdHDAD;

		if (status === "suc") {
			// 把資料塞進表頭的table
			set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
			// 取得所有單號(用來做上一筆、下一筆)
			const newOrd_Ns = data?.map((item) => item.N) ?? [];
			// 更新資料
			updateState({ pro_Ns: newOrd_Ns });
			// UI顯示API回傳的狀態
			message.success(msg);
		}

		if (status === "err") {
			set_s_tableData([]);
			message.error(t(`errorCode.${msg}`));
		}
	}, [call_getOrdHDAD.status]);

	// useAPI 刪除表頭資料
	useEffect(() => {
		const { msg, status } = call_deleteOrdHD;
		if (status === "suc") {
			message.success(msg);
			//刪除完成後要重新取得表頭資料
			call_getOrdHD.request({
				...searchConfig,
				[searchConfig.searchFlag]: searchConfig.searchValue,
			});

			// 如果有 lightData 要清空
			if (Object.keys(lightData).length > 0) {
				updateState({ lightData: {} });
			}
		}

		if (status === "err") {
			message.error(t(`errorCode.${msg}`));
		}
	}, [call_deleteOrdHD.status]);

	// useAPI 強制結令
	useEffect(() => {
		const { status, msg } = call_updateOrdHD;
		if (status === "suc") {
			message.success("強制結令成功");
			// 更新表頭
			call_getOrdHD.request({
				...searchConfig,
				opcode: tabKey,
				[searchConfig.searchFlag]: searchConfig.searchValue,
			});
		}
		if (status === "err") {
			message.error(t(`errorCode.${msg}`));
		}
	}, [call_updateOrdHD.status]);

	// 取得所有負責業務
	useEffect(() => {
		// state option
		const { status, data, msg } = c_staffs;
		// 負責業務下拉選單 -- 篩選有效員工 + 業務部門
		if (status === "suc") {
			const filData = data.filter((text) => text.isvalid && text.deptID === "業務");
			const _data = filData.reduce(
				(prev, curr) => {
					// prev[curr.staffID] = curr.peopleNM;
					const tmp = {
						label: curr.peopleNM,
						value: curr.staffID === "TEST01" ? "superman" : curr.staffID,
						deptNM: curr.deptNM,
					};
					prev.push(tmp);
					return prev;
				},
				[{ label: "All", value: "All" }]
			);
			set_s_staff_options(_data);
		}
		if (status === "err") {
			message.error(msg);
		}
	}, [c_staffs.status]);


	const tableColumns = [
		{
			title: t("OMS.util.opcode"), // 單別
			dataIndex: "opcode",
			align: "center",
			width: screens.xxl ? "6%" : "6%",
			// isExcel: true,
			render: (text) => t(`OMS.util.opcode_${text}`),
		},
		{
			title: t("OMS.util.N"), // 單號
			dataIndex: "N",
			align: "center",
			width: "9%",
			// isExcel: true,
			render: (text, rowData, _) => (
				<div className="text-center">
					<span className="mr-1">{text}</span>
					{rowData.linkType !== "" && <Light linkType={rowData.linkType} />}
				</div>
			),
		},
		{
			title: t("OMS.util.custNM"), // 客戶簡稱
			dataIndex: "custNM",
			align: "left",
			// isExcel: true,
			width: "12%",
		},
		// {
		//   title: t("OMS.util.dealN"), // 客戶單號
		//   sorter: (a, b) => a.dealN.localeCompare(b.dealN),
		//   dataIndex: "dealN",
		//   align: "center",
		//   // width: screens.xxl ? "8%" : "11%",
		// },
		{
			title: t("OMS.ord.driID"), // 負責業務
			dataIndex: "driID",
			align: "center",
			width: screens.xxl ? "7%" : "7%",
			// isExcel: true,

			render: (text) => getStaffName(text),
		},
		{
			title: t("OMS.ord.planday"), // 計劃工作日
			dataIndex: "planday",
			align: "center",
			width: screens.xxl ? "6%" : "6%",
			// isExcel: true,
			// render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		{
			title: t("OMS.util.EDT"), // 預計完成時間
			dataIndex: "EDT",
			align: "center",
			sorter: (a, b) => a.EDT.localeCompare(b.EDT),
			width: screens.xxl ? "9.5%" : "9.5%",
			// isExcel: true,
			render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		// {
		//   title: "付款截止日",
		//   dataIndex: "dueday",
		//   align: "center",
		//   width: screens.xxl ? "7%" : "7%",
		//   render: (text) => dayjs(text).format("YYYY-MM-DD"),
		// },
		{
			title: "完成付款",
			dataIndex: "ispay",
			align: "center",
			width: screens.xxl ? "6%" : "6%",
			// isExcel: true,
			render: (text) => (text ? <IconCheck /> : <IconClose />),
		},
		{
			title: t("OMS.util.lifeF"), // 歷程
			dataIndex: "lifeF",
			align: "center",
			width: screens.xxl ? "6%" : "6%",
			// isExcel: true,
			// sorter: (a, b) => a.lifeF.localeCompare(b.lifeF),
			render: (text) => {
				return t(`OMS.ord.lifeF_${text}`);
			},
		},
		{
			title: t("OMS.util.lifeFT"), // 歷程時間
			dataIndex: "lifeFT",
			align: "center",
			width: "10%",
			sorter: (a, b) => a.lifeFT.localeCompare(b.lifeFT),
			// isExcel: true,
			render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
		},
		{
			title: t("OMS.util.table_operate"), // 操作
			dataIndex: "render",
			align: "center",
			width: screens.xxl ? "8%" : "8%",
			render: (_, rowData, __) => (
				<Space className="px-2">
					{/* 強制結令 */}
					{/* <CloseButton disabled={rowData.lifeF === "T"} onClick={(e) => closeData(e, rowData)} /> */}
					{/* 刪除表頭資料 */}
					<DeleteButton
						// proCount 有東西代表有外包項目
						disabled={rowData.lifeF === "T" || rowData.apCount > 0 || rowData.proCount > 0}
						onClick={(e) => removeData(e, rowData)}
					/>
					{/* 查看更多 */}
					<SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

					<Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
					{/* 編輯 */}
					<EditButton onClick={(e) => editData(e, rowData)} />
				</Space>
			),
		},
	];
	console.log(s_selectOptions[searchConfig.searchFlag]);

	return (
		<div className="flex-1">
			<PageHeader
				title={t("OMS.ord.pageHeader")} // 報價訂單作業
			// extra={<TourButton />}
			/>

			<Space className="w-full flex justify-end">
				<CustomRangePicker
					allowClear
					disabled={tabKey === "detailSearch"}
					value={[searchConfig?.startDate, searchConfig?.endDate]}
					onCalendarChange={(dates, _, __) => {
						updateState({
							searchConfig: {
								...searchConfig,
								startDate: dates ? dates[0] : null,
								endDate: dates ? dates[1] : null,
							},
						});
					}}
				/>
				<Input.Group compact>
					<CustomSelect
						disabled={tabKey === "detailSearch"}
						value={searchConfig.searchFlag}
						placeholder={t("util.search")}
						onChange={(value) => handleChange("searchFlag", value)}
						options={[
							{ value: "N", label: t("OMS.util.N") }, // 單號
							{ value: "custID", label: t("OMS.util.custNM") }, // 客戶ID
							{ value: "lifeF", label: t("OMS.util.lifeF") }, // 歷程
							{ value: "driID", label: t("OMS.fod.driID") }, // 負責業務
						]}
					/>

					{searchConfig.searchFlag === "lifeF" ||
						searchConfig.searchFlag === "custID" ||
						searchConfig.searchFlag === "driID" ? (
						<CustomSelect
							showSearch
							disabled={tabKey === "detailSearch"}
							value={searchConfig.searchValue}
							className="w-[300px]"
							placeholder={placeholder_map[searchConfig.searchFlag]}
							// onChange={(value) => handleChange("searchValue", value.split("_")[0])}
							onChange={(value) => handleChange("searchValue", value)}
							// 根據searchFlag判斷現在是哪個下拉選單
							options={s_selectOptions[searchConfig.searchFlag]}
							filterOption={(input, option) => {
								return (
									(option?.value.toLowerCase() ?? "") + (option?.label.toLowerCase() ?? "")
								).includes(input.toLowerCase());
							}}
						/>
					) : (
						<Input
							disabled={tabKey === "detailSearch"}
							value={searchConfig.searchValue}
							className="w-[250px]"
							placeholder={placeholder_map[searchConfig.searchFlag]}
							onChange={(e) => handleChangeInput(e.target.value)}
						/>
					)}

					<CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
				</Input.Group>
				{/* 清除搜詢紀錄 */}
				<ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />

				<span style={{ fontSize: "16px" }}>{"负责业务:"}</span>
				<Select
					key="driID"
					className="w-[160px]"
					allowClear
					showSearch
					options={s_staff_options || []}
					onKeyDown={handleKeyDownEnter}
					value={searchConfig.driID}
					onChange={(e) => handleChange("driID", e)}
					placeholder={"负责业务"}
				/>
				{/* excel匯出 */}
				<IconExcelDownload key="excel" className="text-[38px]"
					// onClick={downloadExcel} 
					onClick={() => exportToExcel()}
				/>

				{/* 新建 */}
				<CreateButton
					disabled={tabKey === "detailSearch"}
					onClick={() => updateState({ mainPage: { show: true, data: {} } })}
				/>
			</Space>

			<Divider className="my-1" />

			{tabKey === "detailSearch" && (
				<Space className="w-full flex justify-end pb-2">
					<ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
					<DetailButton onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: true }))} />
				</Space>
			)}

			<CustomTableOrd
				columns={tableColumns}
				dataSource={s_tableData}
				loading={call_getOrdHD.status === "load"}
				tbMode={Object.keys(lightData).length > 0}
				lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
				pagination={{
					defaultPageSize: "100",
					showSizeChanger: true,
					pageSizeOptions: ["30", "50", "100"],
					showTotal: (total, range) => (
						<span className="text-lg font-semibold">
							{t("util.showTotal", {
								total: total,
								rangeFirst: range[0],
								rangeEnd: range[1],
							})}
						</span>
					),
				}}
				onRow={(record) => {
					return {
						onClick: () =>
							updateState({
								lightData: record.N === lightData.N ? {} : record,
							}),
						// 雙擊可進入編輯模式
						onDoubleClick: (event) => editData(event, record),
					};
				}}
			/>
			{/* 查看更多-抽屜 */}
			<Drawer
				title={
					<Title level={5} className="mb-0">
						{/* 銷貨單主檔 */}
						{t("OMS.ord.hd_drawer_title")}
					</Title>
				}
				placement="right"
				closable={false}
				width={1000}
				onClose={() => set_s_drawer({ show: false, data: {} })}
				open={s_drawer.show}
			>
				<DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
			</Drawer>

			{/* 進階搜尋條件-跳顯 */}
			<CustomModal
				width="70%"
				title={t("OMS.util.setAdvanced")}
				open={s_detailSearch.show}
				onCancel={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
			>
				{s_detailSearch.show && (
					<DetailModal
						s_detailSearch={s_detailSearch}
						set_s_detailSearch={set_s_detailSearch}
						call_getOrdHDAD={call_getOrdHDAD}
					/>
				)}
			</CustomModal>
		</div>
	);
};

export default HD;
