import React, { useEffect, useContext } from "react";
import { Modal, Space, Grid, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button"
// util
import { formatAmount } from "@/util/format";
// component
import AddbModal from "../Modal/AddbModal";
// context
import { MainPageContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { deleteOrdADD } from "@/service/apis/OMS/Ord";

const AddbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled, s_ordTb, set_s_ordTb, s_Adds, call_getOrdADD, call_getOrdHD } =
    useContext(MainPageContext);
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();

  // useAPI 刪除加扣款項
  const call_deleteOrdADD = useAPI(deleteOrdADD);
  // 刪除
  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("OMS.ord.addb") + t("OMS.util.del_confirm"), // 加扣款項刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.ord.addb_delete_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_deleteOrdADD.request(rowData);
      },
    });
  };

  // 第一次渲染取得加扣款項
  useEffect(() => {
    call_getOrdADD.request({
      "addtype": "addb",
      "opUUID": hd_values.opUUID,
    });
  }, []);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { data, msg, status } = call_getOrdADD;
    if (status === "suc") {
      message.success(msg);
      set_s_ordTb((prev) => ({
        ...prev,
        addb: data.addb ?? [],
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdADD.status]);

  // useAPI 刪除加扣款項
  useEffect(() => {
    const { status, msg } = call_deleteOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      // 加扣款項會動到表頭欄位，所要更新表頭
      call_getOrdHD.request({ "opUUID": hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteOrdADD.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.statement"), // 項目說明
      dataIndex: "statement",
      align: "center",
    },
    {
      title: t("OMS.ord.amount"), // 未稅金額
      dataIndex: "amount",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_ordTb.addb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <CustomModal
        width={screens.xxl ? "20%" : "40%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={Object.keys(s_tbModal.data).length > 0 ? "編輯加扣款項" : "新增加扣款項"}
        title={
          Object.keys(s_tbModal.data).length > 0
            ? t("util.edit") + t("OMS.ord.addb")
            : t("util.add") + t("OMS.ord.addb")
        }
      >
        {s_tbModal.show && (
          <AddbModal
            s_Adds={s_Adds}
            hd_values={hd_values}
            s_disabled={s_disabled}
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
          />
        )}
      </CustomModal>
    </>
  );
};

export default AddbContainer;
