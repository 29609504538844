/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { message } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import PageHeader from "@/components/PageHeader";
// import { IconCheck, IconClose, IconPen } from "@/components/Icon/Action";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// API
import useAPI from "@/hooks/useAPI";
import { getQmsSipTb } from "@/service/apis/QMS/QMSManage";
import { ExpandButton } from "@/components/PDS/Buttons";
// component
import DndModal from "./ModalContainer/DndModal";

const TB = ({ c_lightData, set_c_lightData }) => {
  const { t } = useTranslation();
  const call_getQmsSipTb = useAPI(getQmsSipTb);

  const [s_tableData, set_s_tableData] = useState([]);

  const [s_isShowDndModal, set_s_isShowDndModal] = useState(false);

  const tableColumns = [
    {
      title: "",
      dataIndex: "sort",
      align: "center",
      render: () => <MenuOutlined />,
    },
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "6%",
    },
    {
      title: "檢驗項目",
      dataIndex: "inspitem",
      align: "center",
      // width: "8%",
    },
    {
      title: "檢驗類別",
      dataIndex: "itemtype",
      align: "center",
      width: "6%",
      // 等i18n
      render: (text) => {
        const map = {
          normal: "一般項目",
          secure: "重要項目",
          sepcial: "監控項目",
        };
        return map[text];
      },
    },
    {
      title: "驗收型態",
      dataIndex: "insptype",
      align: "center",
      width: "6%",
      // 等 i18N
      render: (text) => {
        const map = {
          count: "計數型",
          measure: "計量型",
        };
        return map[text];
      },
    },
    {
      title: "檢驗重點",
      dataIndex: "inspkeynote",
      align: "center",
    },
    {
      title: "工程圖域",
      dataIndex: "drawarea",
      align: "center",
    },
    {
      title: "圖面球號",
      dataIndex: "drawno",
      align: "center",
    },
    {
      title: "標準值",
      dataIndex: "CL",
      align: "center",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: "規格上限",
      dataIndex: "USL",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: "規格下限",
      dataIndex: "LSL",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: "管制上限",
      dataIndex: "UCL",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      render: (text) => formatAmount(text),
    },
    {
      title: "管制下限",
      dataIndex: "LCL",
      align: "center",
      className: "bg-[#f9e9d7ac]",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: "量測單位",
      dataIndex: "UOM",
      align: "center",
      width: "6%",
    },
    {
      title: "檢測環境",
      dataIndex: "sipcond",
      align: "center",
    },
    {
      title: "允收品質水準",
      dataIndex: "AQL",
      align: "center",
      width: "7%",
    },
    {
      title: "減量抽樣",
      dataIndex: "AQL",
      align: "center",
    },
  ];

  // 第一次渲染及每次 c_lightData 改變時執行
  useEffect(() => {
    call_getQmsSipTb.request(c_lightData);
  }, [c_lightData]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getQmsSipTb;
    if (status === "suc") {
      console.log("call_getQmsSipTb.data = ", data);
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getQmsSipTb.status]);

  const searchBasic = [
    <ExpandButton key="ExpandButton" onClick={() => set_s_isShowDndModal(true)} />,
    // <AddButton onClick={() => set_s_isShowModal(true)} />,
  ];

  return (
    <>
      <PageHeader
        title={
          <span>
            檢驗標準明細_
            <span className="text-blue-500 font-semibold">{c_lightData.sipID}</span>
          </span>
        }
        extra={searchBasic}
      />
      <CustomTable
        columns={tableColumns.filter((tableColumn) => tableColumn.dataIndex !== "sort")}
        dataSource={s_tableData}
        pagination={false}
      />

      <CustomModal
        title={
          <span>
            檢驗標準明細_
            <span className="text-blue-500 font-semibold">{c_lightData.sipID}</span>
          </span>
        }
        width={"90%"}
        open={s_isShowDndModal}
        onCancel={() => set_s_isShowDndModal(false)}
        afterClose={() => {
          call_getQmsSipTb.request(c_lightData);
        }}
      >
        <DndModal
          c_lightData={c_lightData}
          tableColumns={tableColumns}
          s_tableData={s_tableData}
          set_s_tableData={set_s_tableData}
          call_getQmsSipTb={call_getQmsSipTb}
        />
      </CustomModal>
    </>
  );
};

export default TB;
