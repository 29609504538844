import IconBox from "./IconBox";
// 技術資料維護
import { ReactComponent as TechData } from "@/assets/icon/NavBar/icon-maintenanceOfTechnicalData.svg";
// 銷售發貨
import { ReactComponent as SalesShipmentManagement } from "@/assets/icon/NavBar/icon-salesShipmentManagement.svg";
// 生產資訊查詢
import { ReactComponent as ProductionQuery } from "@/assets/icon/NavBar/icon-productionInformationManagement.svg";
// 系統資料維護
import { ReactComponent as SystemInformation } from "@/assets/icon/NavBar/icon-systemInformationMaintenance.svg";
// 基本資料維護
import { ReactComponent as BasicData } from "@/assets/icon/NavBar/icon-basicDataMaintenance.svg";
// 設備稼動總表
import { ReactComponent as Equip } from "@/assets/icon/NavBar/icon-equipmentOperationSummary.svg";
// 數位生產看板
import { ReactComponent as DigitalKanban } from "@/assets/icon/NavBar/icon-digitalProductionKanban.svg";
// 數位生產圖框
import { ReactComponent as Digital } from "@/assets/icon/NavBar/icon-digitalProductionFrame.svg";
// 生管派工管理
import { ReactComponent as ProductionManagement } from "@/assets/icon/NavBar/icon-productionManagementDispatch.svg";
// 異常管理看板
import { ReactComponent as Exception } from "@/assets/icon/NavBar/icon-exceptionManagementKanban.svg";
// 設備生產狀況
import { ReactComponent as Equipment } from "@/assets/icon/NavBar/icon-equipmentProductionStatus.svg";
// 庫存管理系統
import { ReactComponent as WarehouseManager } from "@/assets/icon/NavBar/icon-warehouseManager.svg";
// 採購管理系統
import { ReactComponent as PMS } from "@/assets/icon/NavBar/icon-pms.svg";
// 銷貨管理系統
import { ReactComponent as OMS } from "@/assets/icon/NavBar/icon-oms.svg";
// 報表查詢
import { ReactComponent as Report } from "@/assets/icon/NavBar/icon-report.svg";
//模具資料管理
import { ReactComponent as Mold } from "@/assets/icon/NavBar/icon-moldManagement.svg";

// 庫存管理系統
export const IconWarehouseManager = (props) => <IconBox {...props} component={WarehouseManager} />;
// 設備生產狀況
export const IconEquipment = (props) => <IconBox {...props} component={Equipment} />;
// 技術資料維護
export const IconTechData = (props) => <IconBox {...props} component={TechData} />;
// 銷售發貨
export const IconSalesShip = (props) => <IconBox {...props} component={SalesShipmentManagement} />;
// 生產資訊查詢
export const IconProductionQuery = (props) => <IconBox {...props} component={ProductionQuery} />;
// 系統資料維護
export const IconSystemInformation = (props) => (
  <IconBox {...props} component={SystemInformation} />
);
// 基本資料維護
export const IconBasicData = (props) => <IconBox {...props} component={BasicData} />;
// 設備稼動總表
export const IconEquip = (props) => <IconBox {...props} component={Equip} />;
// 生管派工管理
export const IconProductionManagement = (props) => (
  <IconBox {...props} component={ProductionManagement} />
);
// 數位生產圖框
export const IconDigital = (props) => <IconBox {...props} component={Digital} />;
// 數位生產看板
export const IconDigitalKanban = (props) => <IconBox {...props} component={DigitalKanban} />;
// 異常管理看板
export const IconException = (props) => <IconBox {...props} component={Exception} />;
// 採購管理系統
export const IconPMS = (props) => <IconBox {...props} component={PMS} />;
// 銷貨管理系統
export const IconOMS = (props) => <IconBox {...props} component={OMS} />;
// 報表查詢
export const IconReport = (props) => <IconBox {...props} component={Report} />;
// 模具資料管理
export const IconMold = (props) => <IconBox {...props} component={Mold} />;
