import { Drawer, Space, Typography, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";

// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { DataContext } from "../../index";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { SeeMoreButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
//util function
import { formatAmount } from "@/util/format";
import { getRcvTB } from "@/service/apis/OMS/rcv";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const call_getRcvTB = useAPI(getRcvTB);
  const { getStaffName } = useContext(ERPContext);
  const { c_state, updateState } = useContext(DataContext);
  const { searchConfig, lightData } = c_state;
  // const { c_state, updateState } = useContext(DataContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

  useImperativeHandle(ref, () => ({
    Call_hd: () =>
      call_getRcvTB.request({
        ...searchConfig,
        opcode: null,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      }),
  }));

  // 開啟查看詳細資料
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getRcvTB.request();
  }, []);

  // useAPI 取得對帳作業表頭
  useEffect(() => {
    const { status, msg, data } = call_getRcvTB;

    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID }));
      console.log("tmpData.length = ", tmpData.length);
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getRcvTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 報價單號
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      dataIndex: "dealN",
      width: "10%",
      align: "center",
    },
    {
      title: t("OMS.util.signT"), // 完成委託日期
      dataIndex: "signT",
      width: "10%",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "8%",
      render: (text) => getStaffName(text),
    },

    {
      title: t("OMS.ord.payment"), // 付款方式
      dataIndex: "payment",
      align: "center",
      width: "8%",
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "8%",
      render: (text) => t(`OMS.ap-manage.rcv_lifeF_${text}`),
    },
    {
      title: t("OMS.ap-manage.rcv_subtotal"), // 金額
      dataIndex: "subtotal",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "预收款金额",
      dataIndex: "cumpay",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("OMS.util.table_operate"), // 操作
    //   dataIndex: "operate",
    //   align: "center",
    //   width: "3%",
    //   render: (_, rowData, __) => (
    //     <Space className="px-2">
    //       {/* 查看更多 */}
    //       <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="flex-1">
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        onRow={(record) => {
          return {
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => openDetail(event, record),
          };
        }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 應收帳款主檔 */}
            {t("OMS.ap-manage.rcv_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={900}
        open={s_drawer.show}
        onClose={() => set_s_drawer({ show: false, data: {} })}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
});

export default HD;
