/* eslint-disable react-hooks/exhaustive-deps */
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { Divider, Drawer, Grid, Input, Modal, Row, Select, Space, Typography, message } from "antd";
import { ExpendButton, SeeMoreButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { IconCheck, IconClose } from "@/components/Icon/Action";
import React, { useContext, useEffect, useState } from "react";
import { deleteA1Hd, getA1Hd } from "@/service/apis/OMS/runA1";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalContainer from "./ModalContainer";
// util component
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
// service
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const RunA1 = () => {
  const { t } = useTranslation();
  const call_getA1Hd = useAPI(getA1Hd);
  const call_deleteA1Hd = useAPI(deleteA1Hd);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_searchData, set_s_searchData] = useState({
    query: "",
  });

  const tableColumns = [
    {
      title: "提成案号文编",
      dataIndex: "approvalID",
      align: "center",
      width: "8%",
    },
    {
      title: "版次",
      dataIndex: "ver",
      align: "center",
      width: "4%",
    },
    {
      title: "奖金类别",
      dataIndex: "type",
      align: "center",
      width: "4%",
      render: (text) => t(`OMS.runa1.type_${text}`),
    },
    {
      title: "阶层",
      dataIndex: "level",
      align: "center",
      width: "4%",
    },
    {
      title: "基点起数",
      dataIndex: "fmcount",
      align: "center",
      width: "4%",
      render: (text) => formatAmount(text),
    },
    {
      title: "基点迄数",
      dataIndex: "tocount",
      align: "center",
      width: "4%",
      render: (text) => formatAmount(text),
    },
    {
      title: "提成比率",
      dataIndex: "bonusrate",
      align: "center",
      width: "4%",
      render: (text) => text + "%",
    },
    {
      title: "是否有效",
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => onDelete(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => onEdit(e, rowData)} />
        </Space>
      ),
    },
  ];

  const onSearch = () => {
    call_getA1Hd.request(s_searchData);
  };
  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };
  const onCreate = () => {
    set_s_isShowModal(true);
  };
  const onEdit = (e, rowData) => {
    set_s_editData(rowData);
    set_s_isShowModal(true);
    e.stopPropagation();
  };
  const onDelete = (e, rowData) => {
    Modal.confirm({
      title: t("OMS.util.ord") + t("OMS.util.del_confirm"), // 銷貨單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.ord.removeData_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteA1Hd.request(rowData),
    });
    e.stopPropagation();
  };
  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.query}
      onChange={(e) => handlChange("query", e.target.value)}
      placeholder={t("util.search")}
    />,
    <SearchButton type="primary" key="search" onClick={onSearch} />,

    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  // 第一次渲染
  useEffect(() => {
    call_getA1Hd.request(s_searchData);
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getA1Hd;
    if (status === "suc") {
      message.success(msg);
      const _data =
        data?.map((item, index) => ({
          ...item,
          key: index + 1,
          createT: dayjs(item.createT),
          confirmT: dayjs(item.confirmT),
        })) || [];

      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getA1Hd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteA1Hd;
    if (status === "suc") {
      message.success(msg);
      call_getA1Hd.request(s_searchData);
      // set_c_lightData({});
    }
    if (status === "err") {
      message.error(msg);
      call_getA1Hd.request(s_searchData);
      // set_c_lightData({});
    }
  }, [call_deleteA1Hd.status]);
  return (
    <>
      <PageHeader title="提成核决表" extra={searchBasic} />
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getA1Hd.status === "load"}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}提成核决表`
            : `${t("util.add")}提成核决表`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default RunA1;
