import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { Grid, Modal, Space, message } from "antd";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { deletePostage, getPostage } from "@/service/apis/OMS/Ord";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// context
import { MainPageContext } from "../index";
// component
import PostageModal from "../Modal/PostageModal";
// util
import { formatAmount } from "@/util/format";
import { getSuppHD } from "@/service/apis/PMS/util";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const Postage = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled } = useContext(MainPageContext);
  const [s_tableData, set_s_TableData] = useState([]);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除郵寄費用
  const call_deletePostage = useAPI(deletePostage);
  const call_getPostage = useAPI(getPostage);
  const call_getSuppHD = useAPI(getSuppHD);

  const supp_map = useMemo(() => {
    return call_getSuppHD.data?.reduce((prev, curr) => {
      prev[curr.suppID] = curr.suppNM;
      return prev;
    }, {});
  }, [call_getSuppHD.status]);

  // 刪除
  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("OMS.ord.postage") + t("util.del_confirm"), // 郵寄費用刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: "请先确认要删除的邮寄费用无误，再进行删除",
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_deletePostage.request(rowData);
      },
    });
  };

  // 第一次渲染取得郵寄費用
  useEffect(() => {
    call_getPostage.request({
      "opUUID": hd_values.opUUID,
    });
    call_getSuppHD.request();
  }, []);

  useEffect(() => {
    const { status, msg } = call_getSuppHD;
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getSuppHD.status]);

  // useAPI 取得郵寄費用
  useEffect(() => {
    const { data, msg, status } = call_getPostage;
    if (status === "suc") {
      message.success("取得郵寄費用成功");
      set_s_TableData(data);
    }

    if (status === "err") {
      set_s_TableData([]);
      message.error("取得郵寄費用失敗");
    }
  }, [call_getPostage.status]);

  // useAPI 刪除郵寄費用
  useEffect(() => {
    const { status, msg } = call_deletePostage;
    if (status === "suc") {
      message.success(msg);
      call_getPostage.request({
        "opUUID": hd_values.opUUID,
      });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deletePostage.status]);

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "項目",
      dataIndex: "type",
      width: "20%",
      align: "center",
      render: (text) => t(`OMS.ord.type_${text}`),
    },
    {
      title: t("OMS.ord.statement"),
      dataIndex: "statement",
      // width: "15%",
      align: "center",
      render: (text) => supp_map[text] || text,
      // render: (text, rowData, _) => {
      //   if (rowData.type === "0") {
      //     return <span className="text-[#6989ba]">外测单号: {text}</span>;
      //   }

      //   return text;
      // },
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      width: "20%",
      align: "center",
    },
    {
      title: t("OMS.ord.amount"), // 金額
      dataIndex: "amount",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.action"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          {/* <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} /> */}
        </Space>
      ),
    },
  ];
  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData?.map((item) => ({ key: item.itemno, ...item })) ?? []}
      />

      <CustomModal
        width={screens.xxl ? "30%" : "30%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          Object.keys(s_tbModal.data).length > 0
            ? t("util.edit") + t("OMS.ord.postage")
            : t("util.add") + t("OMS.ord.postage")
        }
      >
        {s_tbModal.show && (
          <PostageModal
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
            set_s_TableData={set_s_TableData}
          />
        )}
      </CustomModal>
    </>
  );
};

export default Postage;
