/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Divider, Row, Col, message, Spin, Select } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { CancelButton, TransferButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { ordToPro } from "@/service/apis/OMS/Ord";
// util function
import { formatAmount } from "@/util/format";

const OutstockModal = ({
  set_is_showOutstock,
  s_ordTb,
  hd_values,
  call_getOrdHD,
  call_getOrdTB,
}) => {
  const { t } = useTranslation();
  const { c_supps } = useContext(ERPContext);
  // 拋轉外包項目
  const call_ordToPro = useAPI(ordToPro);

  // 拋轉介面的資料
  const [s_tableData, set_s_tableData] = useState(s_ordTb.tb);
  console.log("s_tableData = ", s_tableData);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [s_suppID, set_s_suppID] = useState(null);

  // 確定拋轉
  const onSubmit = async () => {
    console.log("selectedRowKeys = ", selectedRowKeys);
    if (selectedRowKeys.length === 0) {
      message.error("请选择外包项目");
      return;
    }
    if (!s_suppID) {
      message.error("请选择外包厂商");
      return;
    }
    const tmp = {
      hd: {
        ...hd_values,
        opcode: "2340",
      },
      tb: selectedRowKeys,
      suppID: s_suppID,
    };
    // console.log("要傳給後端的資料 = ", tmp);
    // // call 轉外包項目
    call_ordToPro.request(tmp);
  };

  // 取得表頭資料，用來建立新【發貨單】
  useEffect(() => {
    const tmpdata = s_ordTb.tb
      .filter((item) => item.lifeF !== "1") // lifeF = 1 代表有被拋轉過
      .map((item, index) => {
        return {
          ...item,
          key: index + 1,
        };
      });
    set_s_tableData(tmpdata);
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    if (call_ordToPro.status === "suc") {
      // 拋轉成功要更新資料
      message.success(call_ordToPro.msg);
      // 更新表頭
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // 更新表身
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      set_is_showOutstock(false);
    }
    if (call_ordToPro.status === "err") {
      message.error(t(`errorCode.${call_ordToPro.msg}`));
      set_is_showOutstock(false);
    }
  }, [call_ordToPro.status]);

  const onSelectChange = (_, newSelectedRowData) => {
    console.log("selectedRowKeys changed: ", newSelectedRowData);
    setSelectedRowKeys(newSelectedRowData);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys.map((x) => x.key),
    onChange: onSelectChange,
  };

  const tableCcolumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.note"), // 樣品名稱
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 檢驗項目
      dataIndex: "pdtNM",
      align: "center",
      width: "15%",
    },
    {
      title: t("util.pdtspec"), // 測試方法/標準
      dataIndex: "pdtspec",
      align: "center",
      width: "15%",
    },
    // {
    //   title: t("OMS.ord.planday"), // 計畫時間
    //   dataIndex: "planday",
    //   align: "center",
    //   width: "7%",
    // },
    {
      title: t("OMS.util.EDT"), // 預計交貨日
      dataIndex: "EDT",
      align: "center",
      width: "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.ord.unitprice"), // 檢測費用
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.ord.demandqty"), // 銷貨數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
  ];
  console.log("c_supps = ", c_supps);
  return (
    <Spin spinning={call_ordToPro.status === "load"}>
      <div>
        <span className="mr-2">外包廠商:</span>
        <Select
          showSearch
          value={s_suppID}
          onChange={(value) => set_s_suppID(value)}
          className="w-[250px] mb-2"
          placeholder={t("PMS.util.suppID_placeholder")}
          options={c_supps.options}
          filterOption={(input, option) => {
            return (
              (option?.value.toLowerCase() ?? "") + (option?.label.toLowerCase() ?? "")
            ).includes(input.toLowerCase());
          }}
        />
      </div>
      <CustomTable
        columns={tableCcolumns}
        rowSelection={rowSelection}
        rowNumber={6}
        dataSource={s_tableData}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_is_showOutstock(false)} />
          <TransferButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </Spin>
  );
};

export default OutstockModal;
