const en_US = {
  util: {
    "itemID": "Item Number",
    "itemno": "Item Order",
    "sign_out": "Sign Out",
    "add": "Add ",
    "edit": "Edit ",
    "delete": "Delete ",
    "search": "Search ",
    "cancel": "Cancel ",
    "close": "Close ",
    "ok": "Confirm ",
    "render": "Select ",
    "check": "Check ",
    "success": "Success ",
    "all": "All ",
    "yes": "Yes ",
    "no": "No ",
    "name": "Name ",
    "total": "Total ",
    "SN": "Sequence ",
    "detail": "Detail ",
    "action": "Operation ",
    "size_big": "Big Size ",
    "size_large": "Large Size ",
    "size_middle": "Middle Size ",
    "size_small": "Small Size ",
    "hide_detail": "Hide Detail File ",
    "del_confirm": "Delete Commit Confirmation ",
    "del_confirm_msg":
      "Please Confirm That The Transaction Data Is Correct Before Confirming The Deletion ",
    "note": "Remark ",
    "no_data": "No Data ",
    "data": "data",
    "header_cancel_create": "Cancel Create Based On",
    "header_create": "Create entry from this",
    "placeholder_select": "Choose ",
    "placeholder_input": "Input ",
    "or": "Or ",
    "order": "Order ",
    "showTotal": "{{total}} Items In Total, Displaying Item {{rangeFirst}} To {{rangeEnd}} ",
    "itemNM": "Item Name",
    "excluding_tax": "(excluding tax)",
    "start": "start ",
    "end": "end ",
    "wl": "Work Line ",
    "ws": "Work Station ",
    "woN": "woN ",
    "linemix": "linemix ",
    "situF": "State ",
    "PN": "Part No ",
    "whID": "Warehouse ID",
    "idmtype": "Posting Type ",
    "custID": "Customer Number ",
    "custPN": "Customer Part No ",
    "pdtNM": "Product Name ",
    "pdtspec": "Specification ",
    "planqty": "Number Of Projects ",
    "inqty": "Production Quantity ",
    "tqty": "Total ",
    "sqty": "Manual Input ",
    "dqty": "Barcode Scanning ",
    "okC": "quantity of good items ",
    "inC": "production quantity ",
    "ngC": "quantity of defects ",
    "lifeF": "Course ",
    "isvalid": "validation",
    "isfocus": "focused",
    "dept": "Department ",
    "updtT": "Updated time ",
    "pdtclass": "sell group",
    "mtype": "Machine Type",
    "ver": "version",
    "qty": "number of BOM",
    "attribute": "attribute ",
    "unitNM": "UOM",
    "opunitNM": "UOP",
    "unitwt": "Unit Weight",
    "mgmtcat": "Access Group",
    "querycat": "Query Group",
    "drawno": "Drawing No.",
    "matl": "Material ",
    "moldID": "mold ID",
    "heirPN": "Inherited Item Number",
    "heirdealID": "Inheritance Object",
    searchPN: "Material Number Query",
    "edit_detect_msg1": "The system has detected that you have edited the following field",
    "edit_detect_msg2": "Please save the system data before editing the details",
    "msg_chose_data": "Please select the data to be referenced",
    "msg_chose_whichType_toThrow": "Please select the document type to be transferred",
    "msg_chose_atleast_one_Detail": "Please select at least one detail",
    "linemix_0": "no cross-contamination",
    "linemix_1": "Right",
    "linemix_2": "Left",
    "idmtype_s": "Manual Counting ",
    "idmtype_d": "Barcode Counting ",
    "idmtype_m": "Mixed Count ",
    "attribute_0": "0 Raw Matls",
    "attribute_1": "1 Consumables ",
    "attribute_2": "2 Non-Std Parts ",
    "attribute_3": "3 Standard Parts ",
    "attribute_4": "4 Semi-Manufactures ",
    "attribute_5": "5 Finished Goods ",
    "attribute_6": "6 Customer Offerings ",
    "pdtclass_X": "N/A",
    "pdtclass_M": "Commodity ",
    "pdtclass_A": "Accessory ",
    "pdtclass_P": "Packaging ",
    "packtype_0": "operational packaging_use of operational units",
    "packtype_1": "shipping use for packaging_use for packaging and shipping",
    "packtype_2": "turnover packaging_use for production line turnover",
    "assetkind": "asset classification ",
    "assetkind_TI": "injection molding",
    "assetkind_TS": "punching",
    "assetkind_TD": "die casting",
    "assetkind_TF": "forging",
    "fileType_SOP": "SOP",
    "fileType_SIP": "SIP",
    "fileType_PACK": "PACK",
    "fileType_DRAW": "DWG",
    "fileType_PIC": "PIC",
    "nav_pds_dashboard": "Digital Production Kanban",
    "nav_pds_abc-board": "Exception Management Kanban",
    "nav_pds_overview-lines": "Line Body Operation Summary Table",
    "nav_BDM": "Material Data Management",
    "nav_bdm_pn-manage": "Master Data Maintenance",
    "nav_bdm_edm": "Technical Data Maintenance",
    "nav_EDM": "Equipment Data Management",
    "nav_edm_mold": "Mold Data Maintenance",
    "nav_TDM": "Plant Data Management",
    "nav_tdm_plant-manage": "Factory Master File Setting",
    "nav_tdm_procedure-manage": "Process Planning Maintenance",
    "nav_tdm_production-line-manage": "Production Line Planning and Maintenance",
    "nav_tdm_warehouse-manage": "Warehouse Planning and Maintenance",
    "nav_tdm_string-manage": "Capability string management",
    "nav_tdm_plant-params-manage": "廠別參數設定",
    "nav_SDM": "System Data Management",
    "nav_sdm_user-manage": "Employee Data Maintenance",
    "nav_sdm_company": "Supplier Data Maintenance",
    "nav_sdm_client": "Customer Data Maintenance",
    "nav_sdm_category-manage": "Query Item Settings",
    "nav_sdm_data-auth-manage": "Function access settings",
    "nav_sdm_trading-pattern": "Item Keywords Setting",
    "nav_sdm_function-params-manage": "System Parameter Setting",
    "nav_WMS": "Inventory Management System",
    "nav_wms_plan-in": "Warehouse Storage Operations",
    "nav_wms_plan-out": "Warehouse Outbound Operations",
    "nav_wms_stock-search": "Inventory Check",
    "nav_wms_inv-history": "Monthly Inventory Record",
    "nav_wms_pro-instock": "Agent Inbound Operation",
    "nav_PMS": "Purchasing Management System",
    "nav_pms_plan": "Quotation plan",
    "nav_pms_req": "Requisition Job",
    "nav_pms_pro": "Procurement",
    "nav_pms_instock": "Incoming Operation",
    "nav_pms_gostock": "Incoming Inspection Query",
    "nav_pms_return": "Incoming Returns Operation",
    "nav_pms_ap-manage": "Accounts Payable Management",
    "nav_pms_report": "Report querying",
    "nav_pms_report-unpurchase": "採購未進貨明細表",
    "nav_pms_report-diff": "採購價格調幅表",
    "nav_pms_report-instocksummary": "庫存彙總表",
    "nav_pms_report-inventorydiff": "庫存異動明細帳",
    "nav_pms_report-psisummary": "進銷存彙總表",
    "nav_pms_report-purchasesummary": "進退貨彙總表",
    "nav_pms_report-purchaselist": "進退貨明細表",
    "nav_pms_report-purchasestatistics": "進退貨統計表",
    "nav_OMS": "Sales Management System",
    "nav_oms_quo": "Quotation Job",
    "nav_oms_fod": "Book Homework",
    "nav_oms_ord": "Sales Operation",
    "nav_oms_outstock": "Shipping Job",
    "nav_oms_check": "Shipping Inspection",
    "nav_oms_return": "Sales Return Operation",
    "nav_oms_ap-manage": "Accounts Receivable Management",
    "nav_oms_report": "Report querying",
    "nav_oms_report-unshipped": "訂單未出明細表",
    "nav_oms_report-salesummary": "銷退貨彙總表",
    "nav_oms_report-salelist": "銷退貨明細表",
  },
  WOS: {
    "WOC": "生產控制",
    "MC": "物料作業",
    "QC": "質控作業",
    "TK": "生產看板",
    "partsBinding": "部品掃碼",
    "ppidp": "成品綁定",
    "wpcSearch": "途單查詢",
    "dispachManage": "派單管理",
    "mold": "模具管理",
    "whm": "工時管理",
    "batch": "批號管理",
    "oem": "委外報工",
  },
  WMS: {
    "pageHeader": "Inventory Management System",
    "util": {
      "lifeF_0": "已創建",
      "lifeF_1": "執行中",
      "lifeF_2": "已提交",
      "lifeF_A": "全部",
      "lifeF_W": "待規劃",
      "lifeF_R": "已歸還",
      "lifeF_T": "已異動",
      "opcode_4010": "開帳盤點",
      "opcode_4020": "週期盤點",
      "opcode_4030": "專案盤點",
      "opcode_4110": "生產入庫",
      "opcode_4120": "Purchase Storage",
      "opcode_4130": "銷售退回",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4141": "庫房調入",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4143": "借出歸還",
      "opcode_4142": "庫房借入",
      "opcode_4243": "借入歸還",
      "opcode_4210": "生產領用",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "opcode_4160": "生產退料",
      "opcode_4260": "生產超領",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "來源單號",
      "preN_4120": "進料單號",
      "preN_4130": "來源單號",
      "preN_4220": "來源單號",
      "preN_4230": "來源單號",
      "preN_4141": "來源單號",
      "preN_4241": "來源單號",
      "preN_4242": "來源單號",
      "preN_4143": "來源單號",
      "preN_4142": "來源單號",
      "preN_4243": "來源單號",
      "preN_4210": "來源單號",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "preN_4160": "來源單號",
      "preN_4260": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4299": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "whID_4010": "盤點單號",
      "whID_4020": "盤點單號",
      "whID_4030": "盤點單號",
      "whID_4110": "單號",
      "whID_4120": "採購單號",
      "whID_4130": "銷售單號",
      "whID_4220": "銷售單號",
      "whID_4230": "退出單號",
      "whID_4141": "本倉",
      "whID_4241": "本倉",
      "whID_4242": "倉別",
      "whID_4143": "倉別",
      "whID_4142": "倉別",
      "whID_4243": "倉別",
      "whID_4160": "倉別",
      "whID_4260": "倉別",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4299": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4299": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "異動提交確認",
      "txn_confirm_msg": "請先確認資料無誤，再異動",
      "jobN": "作業單號",
      "lifeF": "歷程",
      "opcode": "作業單別",
      "plantxnqty": "規劃異動量",
      "realtxnqty": "實際異動量",
      "planqty": "計畫數量",
      "realqty": "實際數量",
      "totalqty": "總數",
      "planN": "來源單號碼",
      "updtT": "更新時間",
      "whID": "倉別",
      "pdtNM": "品名",
      "bal": "帳差",
    },
    "plan-in": {
      "pageHeader": "Warehouse Storage Operations",
    },
    "plan-out": {
      "pageHeader": "Warehouse Outbound Operations",
    },
    "stock-search": {
      "pageHeader": "Inventory Check",
      "searchPN": "Material Number Query",
      "searchWh": "Warehouse Query",
      "whDetail": "各倉明細",
      "changeDetail": "異動明細",
      "borrow": "Borrow",
      "inTransit": "In Transit",
      "loan": "Loan",
      "lockqty": "Blocked Number",
      "onhandqty": "Effective Inventory",
      "planin": "Inbound Planning",
      "planout": "Outbound Planning",
      "posted": "Posted",
      "scanin": "Ordering And Warehousing",
      "scanout": "Pick Out",
      "timeSeries": "Time Series",
      "total": "Estimated Availability",
      "totalIn": "Total Storage",
      "totalOut": "Total Delivery",
      "tqty": "Inventory",
    },
    "inv-history": {
      "pageHeader": "Monthly Inventory Record",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "Thick",
      "inCBM": "進\n(CBM)",
      "l": "Long",
      "level": "Grade",
      "suppalias": "Supplier",
      "unit": "UOM",
      "w": "Width",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "",
      "lifeF_T": "",
      "wmsopUUID": "",
      "inchargeID": "",
    },
  },
  ADM: {
    "oem-manage": {
      "type": "",
      "itemID": "",
      "oem_item_manage": "",
      "oem_item": "",
      "type_A": "",
      "type_B": "",
      "type_C": "",
      "oem_supp_manage": "",
      "oem_supp": "",
      "vendorcode": "",
      "pfNM": "",
      "oemitemNM": "",
    },
    "function-params-manage": {
      "pageHeader": "System Parameter Setting",
      "searchPlaceholder": "Search ID, unit, code, function code, and level name",
      "unitSetting": "UOM Setting",
      "opUnitSetting": "UOP Setting",
      "currencySetting": "Currency Setting",
      "functions": "Push-Group Setting",
      "pushlvl": "Push-Level Setting",
      "notify": "Push-Event Setting",
      "unit": "UOM",
      "unitNM1": "Unit Name",
      "type": "Measurement Type",
      "category": "Measurement Category",
      "countType": "Count Type",
      "quantitative": "Quantitative",
      "count": "Count",
      "weight": "weight",
      "length": "length",
      "capacity": "capacity",
      "area": "area",
      "volume": "volume",
      "opunit": "pack unit",
      "sort": "To Sort",
      "currencyitem": "Currency Code",
      "itemNM": "Currency Name",
      "currency": "Currency",
      "ismycurrency": "Local Currency",
      "funcode": "Organization Function Code",
      "funNM": "Function Name",
      "function": "Pushing Group",
      "lvlcode": "Level Code",
      "lvlNM": "Hierarchy Name",
      "pushlayer": "Push Level",
      "team": "1 (Team)",
      "class": "2 (Class Level)",
      "management": "3 (Management Level)",
      "total": "4 (Total Level)",
      "eventID": "Event Id",
      "eventNM": "Event Name",
      "rptcode": "Submission Category",
      "pusher": "Sending System",
      "situF": "Warning Light",
      "audience": "Push Audience",
      "startlvl": "Starting Level",
      "setlvl": "Push Series",
      "cruisebot": "Monitoring Program",
      "msgmodel": "Push Message Template",
      "pushEvent": "Pushing Event",
      "rptcode_A0": "Personnel Report",
      "rptcode_B0": "System Notification (Wos)",
      "rptcode_C0": "Equipment Report",
      "rptcode_D0": "Management Report_Remote",
      "rptcode_E0": "Management Report_Cloud",
      "situF_Y": "Yellow",
      "situF_R": "Red",
      "startlvl_0": "Full Staff",
      "startlvl_1": "Team Cadres",
      "startlvl_2": "Class Cadres",
      "startlvl_3": "Manager",
      "startlvl_4": "Chief Supervisor",
      "add_string": "Add String",
      "add_number": "Add Number",
      "add_point": "Add decimal point",
      "1_decimal_place": "One decimal places",
      "2_decimal_place": "Two decimal places",
    },
    "procedure-manage": {
      "pageHeader": "Process Planning Maintenance",
      "pccID": "Cost Center Number",
      "pccNM": "Cost Center Name",
      "pf": "Process",
      "pfID": "Process Number",
      "pfNM": "Process Name",
      "pwc": "Work Center",
      "pwcID": "Work Center Number",
      "pwcNM": "Work Center Name",
      "tabCost": "Cost Center Setting",
      "tabFlow": "Process Master File Setting",
      "tabWork": "Work Center Settings",
    },
    "user-manage": {
      "dashboard": "Digital Production Kanban",
      "abc-board": "Exception Management Kanban",
      "BDM": "Material Data Management",
      "SDM": "System Data Management",
      "TDM": "Plant Data Management",
      "EDM": "Equipment Data Management",
      "WMS": "Inventory Management System",
      "OMS": "Sales Management System",
      "PMS": "Purchasing Management System",
      "WOC": "Production Control",
      "MC": "Material Work",
      "QC": "Quality Control Work",
      "TK": "Production Kanban",
      "wpcSearch": "Waybill Query",
      "dispachManage": "Dispatch Management",
      "ppidp": "Finished Product Binding",
      "partsBinding": "Parts Scan Code",
      "account": "Staff Account",
      "pwd": "Password",
      "role": "Role Permissions",
      "roleID": "Role ID",
      "roleNM": "Role Name",
      "systemAuth": "System Login Authority",
      "autoReport": "Receive Automatic Reports",
      "APP": "Mobile APP",
      "PDA": "PDA Permission",
      "dealmgmt": "Object access group",
      "pdtmgmt": "Material access group",
      "whmgmt": "Warehouse access group",
      "cardID": "Card Number",
      "function": "Organizational Function",
      "level": "Push Level",
      "mail": "Postal Address",
      "pageHeader": "Employee Data Maintenance",
      "peopleNM": "Employee'S Name",
      "personalID": "Id Number",
      "postID": "Job Title",
      "proxyNM": "Contact Name",
      "proxytel": "Contact Number",
      "staffAuth": "Role permission setting",
      "staffData": "Employee Data Management",
      "staffID": "Employee Number",
      "tel": "Telephone",
    },
    "trading-pattern": {
      "OMS": "Order System",
      "PMS": "Purchasing System",
      "adda": "Terms And Conditions",
      "addb": "Additional Deduction",
      "addc": "Prepaid Assignment",
      "addd": "Payment Method",
      "adde": "Terms Of Trade",
      "addf": "Return Operation",
      "addg": "Cancel Order",
      "addh": "Payment Terms",
      "addi": "Payment terms",
      "additem": "Item Number",
      "addr": "Contact Address",
      "addtype": "Transaction Terms",
      "contact": "Contact Person",
      "contactitem": "Contact Window Code",
      "department": "Department",
      "itemNM": "Contact Window Name",
      "mail": "Postal Address",
      "mobile": "Mobile Phone",
      "pageHeader": "Item Keywords Setting",
      "tabAdded": "Additional Item Settings",
      "tabWindow": "External Window Settings",
      "tabPMSAdded": "Purchase Additional Terms And Conditions",
      "tabOMSAdded": "Sales Additional Terms And Conditions",
      "tel": "Contact Number",
    },
    "data-auth-manage": {
      "mgmtitem": "Category Item",
      "pageHeader": "Function access settings",
      "tabCust": "Customer access group",
      "tabPdt": "Material access group",
      "tabSupp": "Factory Capital access group",
      "tabWh": "Warehouse access group",
    },
    "category-manage": {
      "mgmtitem": "Category Item",
      "pageHeader": "Query Item Settings",
      "tabCust": "Customer Query",
      "tabQuery": "Materials Query",
      "tabSupp": "Supplier Query",
      "tabMtype": "Material model classification",
    },
    "warehouse-manage": {
      "cancelClose": "Cancellation",
      "close": "Close Account",
      "isClose": "Whether To Close The Account",
      "mgmtcat": "Warehouse Authorization",
      "pageHeader": "Warehouse Planning and Maintenance",
      "pdtClass_0": "原料/耗材",
      "pdtClass_1": "物料",
      "pdtClass_2": "非標零件",
      "pdtClass_3": "通用零件",
      "pdtClass_4": "半成品",
      "pdtClass_5": "成品",
      "pdtclass": "BOM level",
      "ps": "Workshop",
      "tabWareHouse": "Warehouse Data Setting",
      "whID": "Warehouse ID",
      "whNM": "Warehouse Name",
      "psNM": "Workshop Name",
    },
    "string-manage": {
      "pageHeader": "Capability string management",
      "tabPause": "shutdown project",
      "tabOkItem": "good product",
      "tabNgItem": "Defective item",
      "tabDri": "Responsible unit",
      "tabOffLine": "offline project",
      "driItemID": "Unit ID",
      "driItemNM": "company name",
    },
    "client": {
      "groupalias": "Nick Name",
      "location": "Country",
      "account": "Bank Account",
      "addCustomerInformation": "Add Customer Information",
      "addr": "Company Address",
      "address": "Contact Address",
      "bankAddrOS": "Bank Address",
      "bankCodeOS": "Branch Code",
      "branchOS": "Branch Name",
      "classificationOfRestrictions": "Classification Of Restrictions",
      "contact": "Contact Person",
      "country": "Country",
      "custID": "Customer Number",
      "custNM": "Client'S Name",
      "custalias": "Nick Name",
      "customerBasicInformation": "Customer Basic Information",
      "customerContactInformation": "Customer Contact Information",
      "department": "Department",
      "domesticBankName": "Domestic Bank Name",
      "domestic_OverseasBankInformation": "Domestic/Overseas Bank Information",
      "fullRangeOfFurniture": "Full Range Of Furniture",
      "item": "10 Items/Page",
      "itemNM": "Window Function",
      "itemno": "Item Order",
      "livingRoomFurniture": "Living Room Furniture",
      "mail": "Mail",
      "maincurrency": "Currency",
      "maxcredit": "Credit Terms",
      "mobile": "Mobile Phone",
      "modifyContactWindowInformation": "Modify Contact Window Information",
      "modifyCustomerInformation": "Modify Customer Information",
      "no": "No",
      "ordertype": "Order Category",
      "overseasBankName": "Overseas Bank Name",
      "pageHeader": "Customer Data Maintenance",
      "pageHeader2": "Customer Profile Management",
      "payterm": "Payment Terms",
      "postID": "Job Title",
      "showTotal": "1 Items In Total, Displaying Item 1 To 1",
      "swiftCode": "Swift Code",
      "taxID": "Tax ID",
      "tel": "Company Phone",
      "username": "Account Name",
      "yes": "Yes",
    },
    "company": {
      "manufacturerBasicInformation": "Supplier Basic Information",
      "manufacturerContactInformation": "Supplier Contact Information",
      "pageHeader": "Supplier Data Maintenance",
      "suppID": "Supplier Number",
      "suppNM": "Supplier Names",
      "suppalias": "Nick Name",
      "groupalias": "Group abbreviation",
      "location": "Country",
      "account": "Bank Account",
      "addCustomerInformation": "新增客戶資料",
      "addr": "Company Address",
      "address": "連絡住址",
      "bankAddrOS": "Bank Address",
      "bankCodeOS": "Branch Code",
      "branchOS": "Branch Name",
      "classificationOfRestrictions": "Classification Of Restrictions",
      "contact": "Contact Person",
      "country": "Country",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "Department",
      "domesticBankName": "Domestic Bank Name",
      "domestic_OverseasBankInformation": "Domestic/Overseas Bank Information",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10 Items/Page",
      "itemNM": "Window Function",
      "itemno": "Item Order",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "Mail",
      "maincurrency": "Currency",
      "maxcredit": "Credit Terms",
      "mobile": "Mobile Phone",
      "modifyContactWindowInformation": "編輯聯絡窗口資料",
      "modifyCustomerInformation": "編輯客戶資料",
      "no": "No",
      "ordertype": "訂貨類別",
      "overseasBankName": "Overseas Bank Name",
      "payterm": "Payment Terms",
      "postID": "Job Title",
      "showTotal": "1 Items In Total, Displaying Item 1 To 1",
      "swiftCode": "Swift Code",
      "taxID": "Tax ID",
      "tel": "Company Phone",
      "username": "Account Name",
      "yes": "Yes",
    },
    "edm": {
      "alertspeed": "Production Rate Alarm",
      "alertstop": "Over-Stop Alarm",
      "warnyield": "Yield Warnning",
      "alertyield": "Yield Alarm",
      "attribute": "Material Attribute",
      "bom": "BOM maintenance",
      "errorPleaseFull": "Please Fill In The Complete Information",
      "ieUUID": "Bill Of Exchange Number",
      "isrework": "Whether To Rework The Route",
      "level": "BOM level",
      "nextSN": "Second Trip Number",
      "pPN": "Parent Item Number",
      "pageHeader": "Technical Data Maintenance",
      "pdtclass": "sell group",
      "pfNM": "Process",
      "planhead": "Planned Number Of People On Duty",
      "ppdtNM": "Parent Product Name",
      "productionScheduleDetails": "Process routing details",
      "pwcNM": "Work Center",
      "qty": "Number of BOM",
      "stdlt": "Production Lead Time",
      "stdmh": "Standard Machine Time",
      "stdwh": "Standard Working Hours",
      "batchbom": "Batch number full package",
      "unitNM": "UOM",
      "updtT": "Update Time",
      "ver": "Version",
      "wpcManage": "Process routing maintenance",
      "wpc": "",
      "wpcSN": "Journey Number",
      "wpcSetting": "Journey Number Setting",
      "wpcset": "Travel Group",
      "wpcsetNM": "Note For Journey Group",
      "whID": "Receiving warehouse",
      "alternative_wpc": "Alternative process",
      "main_wpc": "Main process",
      "edit_all_wpc": "Process global editing",
      "Pfc": "Project File Version Control",
      "doc": "Standard Document Issue",
      "ECN": "Engineering Change Notification",
      "pdftype": "File Type",
      "docUpload": "File Upload",
      "Upload": "Upload",
      "docName": "File Name",
      "docVer": "File Version",
    },
    "pn-manage": {
      "basicProduct": "Basic Product Information",
      "dealPNSearch": "Transaction Number Query",
      "boxH": "Package Size (H)",
      "boxL": "Package Size (L)",
      "boxW": "Package Size (Width)",
      "dealID": "Object Number",
      "dealNM": "Trade Name",
      "dealPN": "Transaction Item Number/Sku",
      "dealalias": "Nick Name",
      "dealpid": "Barcode",
      "idkw": "Packaging Barcode Key Words",
      "inititalcost": "Initial Schedule",
      "isfocus": "isfocus",
      "isvalid": "validation",
      "maxcost": "Inventory Amount Limit",
      "maxstock": "Inventory Limit",
      "minstock": "Inventory Lower Limit",
      "moq": "Moq",
      "newPN": "New Version Number",
      "nextpacksn": "Layers Of Outer Packaging",
      "opunit": "pack unit",
      "pack": "Packaging Unit",
      "packsn": "Packing Layers",
      "packtype": "Packaging Purpose",
      "pageHeader": "Master Data  Maintenance",
      "palH": "Pallet (High)",
      "palL": "Pallet (Long)",
      "palW": "Pallet (Width)",
      "pbom": "Package Inner Group Number",
      "pdtH": "Product Dimensions (H)",
      "pdtL": "Product Size (L)",
      "pdtW": "Product Size (Width)",
      "punit": "Inner Unit",
      "purchasecost": "Current Purchase Cost",
      "purchaseqty": "Feed Amount In This Period",
      "replacePN": "Alternate Part Number",
      "safestock": "Safety Stock",
      "salecurrency": "Price Currency",
      "setbatch": "Use Lot Number",
      "setidm": "Barcode Setting",
      "setpack": "Package Settings",
      "settlemonth": "Change Billing Month",
      "shipV": "Packaging Volume Cuft",
      "shipW": "Shipping Weight",
      "sizeunit": "Measurement Unit",
      "stdcost": "Standard Cost",
      "stdsale": "Standard Price",
      "stdstock": "Standard Stock",
      "stocklife": "Inventory Cycle",
      "tabCost": "Standard Cost Setting",
      "tabDeal": "Transaction Number Setting",
      "tabExtra": "Product Size Setting",
      "tabIdkw": "Production Binding Settings",
      "tabPack": "Packaging Binding Settings",
      "tabPrice": "Standard Price Setting",
      "tabStock": "Inventory Condition Setting",
      "unitwt": "unit  weight",
      "varcost": "Variable Costs",
      "ver": "Packaging Standard Version",
      "weightunit": "Unit Of Weight",
      "mtype": "Machine Type",
    },
    "mold": {
      "pageHeader": "Mold Data Maintenance",
      "mold": "Mold information",
      "assetkind": "Mold type",
      "assetID": "Mold No.",
      "assetNM": "Asset Name",
      "pfID": "Process Number",
      "moldtype": "Moldtype",
      "cavity": "Real Cavity",
      "stdcavity": "Standard Cavity",
      "validcavity": "Valid Cavity",
      "wsID": "Work Station",
      "property": "Ownership",
      "custID": "Customer No.",
      "company": "Company",
      "gatesystem": "Gate Type",
      "moldlife": "Design Life",
      "draw": "Mold drawing",
      "pdtpic": "Finished photo",
      "malepic": "Male model photos",
      "femalepic": "Master model photo",
      "whloepic": "Mold photo",
      "advance": "Mold advanced information",
      "length": "Length",
      "width": "Width",
      "height": "Height",
      "weight": "Weight",
      "ismrplan": "",
      "ismrcycle": "",
      "setmrcycle": "",
      "ismrperiod": "",
      "setmrperiod": "",
      "premrT": "",
      "premrC": "",
      "nextmrT": "",
      "nextmrC": "",
      "openC": "Beginning Mode",
      "openT": "Beginning  Date",
      "cummoldC": "Final Cumulative Modulus",
      "precummoldC": "Last Cumulative Modulus",
      "status": "Status",
      "note": "Note",
    },
    "plant-manage": {
      "pageHeader": "Factory Master File Setting",
      "plantID": "Site",
      "deptID": "Department",
      "postID": "Title Id",
      "level": "Push Level",
      "push1": "First-Level Broadcast Time Interval (Minutes)",
      "push2": "Secondary Push Time Interval (Minutes)",
      "push3": "Three-Level Push Time Interval (Minutes)",
      "tabDept": "Department Master File",
      "tabPlant": "Factory Master File",
      "tabPost": "Title Master File",
    },
    "production-line-manage": {
      "MC": "Material Handling",
      "QC": "Quality Control Operation",
      "TK": "Production Dashboard",
      "WOC": "Production Control",
      "auth_setting": "Authorization Settings",
      "batch": "Lot Management",
      "dispachManage": "Dispatch Management",
      "mold": "Mold Management",
      "oem": "Outsourcing Reporting",
      "pageHeader": "Production Line Planning and Maintenance",
      "partsBinding": "Part Scanning",
      "pcc": "Cost Center",
      "pf": "Process",
      "ppidp": "Finished Goods Bundling",
      "ps": "Workshop",
      "psID": "Workshop Id",
      "psNM": "Workshop Name",
      "pwc": "Work Center",
      "tabLine": "Line Data Setting",
      "tabPS": "Workshop Data Setting",
      "tabStation": "Workstation Data Settings",
      "tbHeader_PS": "Workstation Data",
      "tbHeader_Station": "Workstation Data",
      "whm": "Time Management",
      "wlID": "Line Id",
      "wlNM": "Line Name",
      "wpcSearch": "Shipment Tracking",
      "ws": "Workstation",
      "wsID": "Workstation Id",
      "wsNM": "Station Name",
    },
  },
  APS: {
    "util": {
      "opcode": "作業單別",
      "opcode_3310": "正常工單",
      "opcode_3320": "重工工單",
      "opcode_3330": "試產工單",
      "opcode_3340": "工程工單",
      "duestart": "預計完成起日",
      "dueend": "預計完成迄日",
      "realqty": "實際數量",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "",
      "wo": "",
      "tabWo": "",
      "mo": "",
      "jobN": "",
      "tabMo": "",
      "orderN": "",
      "ETDstart": "",
      "ETDend": "",
      "issueID": "",
      "issueday": "",
    },
  },
  PMS: {
    "pageHeader": "Purchasing Management System",
    "util": {
      "opcode_2010": "詢價計畫",
      "opcode_2210": "國內請購",
      "opcode_2220": "國外請購",
      "opcode_2230": "委外申請",
      "opcode_2310": "國內採購",
      "opcode_2320": "國外採購",
      "opcode_2330": "委外加工",
      "opcode_2410": "國內進料",
      "opcode_2420": "國外進料",
      "opcode_2430": "委外進料",
      "opcode_2610": "庫房退出",
      "opcode_2620": "品保退出",
      "opcode_2710": "Purchase of materials",
      "opcode_2810": "應付沖帳",
      "opcode_4120": "驗收單",
      "opcode_4230": "驗退單",
      "plan": "詢價單",
      "req": "請購單",
      "pro": "採購單",
      "instock": "進料單",
      "gostock": "進料檢驗單",
      "return": "進料退出",
      "pay": "應付帳款查詢",
      "ap": "對帳單",
      "strike": "付款沖帳",
      "advanceSearch": "進階查詢",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "table_suppCheckNum": "廠商確認數量",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "submit": "確定",
      "cancel": "取消",
      "sysMsg": "系統訊息",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N22": "請購單號",
      "N23": "採購單號",
      "N24": "進料單號",
      "N27": "對帳單號",
      "N28": "沖帳單號",
      "outN": "驗退單號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "itemsum": "單項金額(未稅)",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "delete_confirm": "刪除確認",
      "reqSearch": "請購紀錄查詢",
      "proSearch": "採購紀錄查詢",
      "instockSearch": "進料紀錄查詢",
      "stockSearch": "驗收紀錄查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "apSearch": "對帳資料查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "currencytype_placeholder": "請選擇幣別",
      "suppID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入税率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "currencytype": "交易幣別",
      "taxtype": "交易稅別",
      "taxrate": "稅率",
      "suppID": "廠商ID",
      "suppalias": "廠商簡稱",
      "suppNM": "廠商名稱",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "EDT": "預計交貨日",
      "demansDate": "需求日期",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmID": "覆核人員",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "updtID": "更新人員",
      "updtT": "更新時間",
      "batchno": "批號",
      "batchdate": "批號日期",
      "stockinT": "移轉時間",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "idmtype": "過帳型態",
      "drawno": "圖號",
      "pbom": "組合數量",
      "utype": "單位類別",
      "punit": "使用單位",
      "unit": "計量單位",
      "opunit": "操作單位",
      "unitprice": "採購單價",
      "localprice": "本幣單價",
      "tax": "總稅額",
      "balqty": "剩餘數量",
    },
    "plan": {
      "pageHeader": "詢價計畫",
      "hd_delete_confirm_text": "",
      "demandqty": "",
      "tb": "",
      "tb_drawer_title": "",
      "rec_drawer_title": "",
    },
    "req": {
      "pageHeader": "Requisition Job ",
      "create_header": "Create New Requisition Master File ",
      "edit_header": "Edit Requisition Master File ",
      "hd_note": "Note",
      "driID": "Responsible For Requisition ",
      "punit": "Unit",
      "unitprice": "Unit Price (Tax Excluded)",
      "demandqty": "Requisition Quantity ",
      "demandDate": "Demand Date ",
      "itemsum": "Individual Amount (Before Tax) ",
      "itemsumtax": "Individual Tax ",
      "itemtotal": "Total Untaxed Amount ",
      "planSearch": "Inquiry Record Inquiry ",
      "local_subtotal": "",
      "subtotal": "Individual Tax Subtotal ",
      "total": "Total Amount Including Tax (Transaction Currency)",
      "lifeF_0": "Release ",
      "lifeF_2": "Purchase Review ",
      "lifeF_7": "Review And Signing Completed ",
      "lifeF_T": "Close",
      "del_hd_confirm_text": "",
      "audit_start": "Review The Official Signature ",
      "audit_finish": "Review Completed ",
      "reqCheck": "",
      "cancel_reqCheck": "",
      "check_confirm": "Please Purchase Review Confirmation ",
      "check_confirm_text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review ",
      "cancel_check_confirm": "Cancel Requisition Review Confirmation ",
      "cancel_check_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Review ",
      "reqCheck_lifeF_0_okText": "Confirm Review ",
      "reqCheck_lifeF_2_okText": "Cancel Review ",
      "audit_confirm": "Review And Sign Off ",
      "audit_confirm_text":
        "This Purchase Requisition Cannot Be Edited After Review And Approval. Are You Sure You Want To Complete The Review?",
      "audit_okText": "Ok Review ",
      "driID_placeholder": "Please Select The Purchaser ",
      "demandqty_placeholder": "Please Fill In The Requisition Quantity ",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax ",
      "tb_delete_confirm": "Purchase Requisition Details Delete Confirmation ",
      "tb_title": "Purchase Details ",
      "create_tb_title": "Add Purchase Requisition Details",
      "edit_tb_title": "Edit Requisition Details ",
      "throw_modal_submit": "Confirm The Transfer [Purchase Order] ",
    },
    "pro": {
      "pageHeader": "Procurement",
      "create_header": "New Purchase Master File",
      "edit_header": "Edit Purchase Master File",
      "hd_drawer_title": "Purchase Order Master",
      "driID": "Responsible Procurement",
      "EDT": "",
      "startEDT": "Estimated Delivery Date",
      "endEDT": "Estimated Delivery Date (To Date)",
      "sampleID": "",
      "supp_history": "Supplier Transaction History Query",
      "trade": "Terms Of Trade",
      "dealaddr": "Supplier Address",
      "contact": "Supplier Delivery Window",
      "dealcontact": "Supplier Business Window",
      "payment": "Payment Method",
      "sumtotal": "Total Untaxed Amount",
      "local_subtotal": "Total Amount Including Tax In Local Currency",
      "total": "Total Amount Including Tax",
      "local_total": "Total Amount Including Tax (Transaction Currency)",
      "note": "Note",
      "demandqty": "Purchase Quantity",
      "punit": "Unit",
      "unitprice": "Unit of Prices (Tax Excluded)",
      "confirmqty": "The Supplier Confirms The Quantity",
      "dealN": "Supplier's Order Number",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "itemsum": "Untaxed Individual Amount",
      "itemsumtax": "Individual Tax",
      "subtotal": "Subtotal Including Tax",
      "approveT": "Approval Date",
      "charge": "Additional Deduction",
      "ReviewProcess": "Review Process",
      "tb_drawer_title": "Purchase Order Details",
      "lifeF_0": "Release",
      "lifeF_2": "Procurement Review",
      "lifeF_7": "Supplier Sign Back",
      "lifeF_T": "结案",
      "del_hd_confirm_text":
        "Please Confirm That The Purchase Order Information To Be Deleted Is Correct Before Deleting",
      "hd_close_confirm_text":
        "Please Confirm That The Information On The Purchase Order To Be Closed Is Correct Before Proceeding To Force The Order To Be Closed",
      "proT": "",
      "dealN_placeholder": "",
      "payment_placeholder": "",
      "trade_placeholder": "",
      "dealcontact_placeholder": "",
      "contact_placeholder": "",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax",
      "demandqty_placeholder": "Please Fill In The Purchase Quantity",
      "note_placeholder": "Please Fill In The Note ",
      "statement_placeholder": "Please Enter A Project Description",
      "amount_placeholder": "Please Enter The Amount",
      "proCheck": "",
      "cancel_proCheck": "",
      "suppCheck": "",
      "cancel_suppCheck": "",
      "proCheck_confirm": "",
      "proCheck_confirm_text": "",
      "cancel_proCheck_confirm_text":
        "Please Confirm That The Information You Want To Cancel The Purchase Review Is Correct, And Then Cancel The Purchase Review",
      "suppCheck_confirm": "",
      "suppCheck_confirm_text": "",
      "cancel_suppCheck_confirm": "Cancel The Supplier's Sign-Back Confirmation",
      "cancel_suppCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "tb": "Purchase Details",
      "adda": "Terms And Conditions",
      "adda_delete_confirm_text": "",
      "addb": "",
      "addb_delete_confirm_text": "",
      "addc": "Prepaid Assignment",
      "addc_delete_confirm_text": "",
      "statement": "Project Instruction",
      "amount": "Untaxed Amount",
      "selectstatement": "Please Select The Deduction Payment",
      "totalStart": "Minimum Value",
      "totalEnd": "Maximum Value",
      "deal_history": "產品交易歷史查詢",
      "transferqty": "已拋轉數量",
      "throwqty": "本次拋轉數量",
      "message_transfer_small_thsn_0": "拋轉數量不可小於0",
      "exit_Disposal": "Exit Disposal",
    },
    "instock": {
      "pageHeader": "Incoming Operation",
      "create_header": "",
      "edit_header": "",
      "hd_drawer_title": "Bill Of Material Master File",
      "contact": "聯絡人",
      "tel": "聯絡人電話",
      "sampleID": "Sampling Staff",
      "sampleT": "Sampling Date",
      "note": "Notes",
      "del_hd_confirm_text":
        "Please Confirm That The Data Of The Bill Of Materials To Be Deleted Is Correct Before Deleting",
      "tb": "Incoming Details",
      "goqty": "Acceptance Quantity",
      "rejectqty": "Quantity",
      "indqty": "Suspended Quantity",
      "inspectID": "Inspection Personnel",
      "inspectT": "Completion Time",
      "qalID": "QC Staff",
      "qalT": "Release Time",
      "lifeF_0": "Release",
      "lifeF_2": "",
      "lifeF_3": "Iqc Sampling Test",
      "lifeF_4": "Iqc Inspection",
      "lifeF_7": "Quality Assurance Review",
      "lifeF_T": "結案",
      "IQCstart": "Incoming Inspection And Sampling",
      "QAcheck": "Quality Assurance Release",
      "punit": "Unit Of Use",
      "inbatchno": "進料批號",
      "judgeT": "Quality Assurance Judgment Date",
      "instockCheck_confirm": "Incoming Material Review And Confirmation",
      "instockCheck_confirm_text": "",
      "cancel_instockCheck_confirm_text": "",
      "IQCcheck_confirm": "",
      "IQCcheck_confirm_text": "",
      "cancel_IQCcheck_confirm_text": "",
      "IQCfinish_confirm": "",
      "IQCfinish_confirm_text": "",
      "cancel_IQCfinish_confirm_text": "",
      "QAcheck_confirm": "",
      "QAcheck_confirm_text": "",
      "cancel_QAcheck_confirm_text": "",
      "QAQTYcheck":
        "Incoming Material Details [Quality Assurance Refund Quantity] + [Quality Assurance Release Quantity] Must Be Equal To [Incoming Material Quantity]",
      "throwStock_message": "",
      "dealN": "Supplier's Order Number",
      "takeqty": "引用數量",
      "qty": "Number Of Quote",
      "unitprice_placeholder": "Please Fill In The Unit Price Without Tax",
      "Tooltip_disable_take_balqty_0": "",
      "message_take_before_choose": "",
      "message_transfer_small_thsn_0": "",
      "message_take_same_detail": "",
    },
    "gostock": {
      "pageHeader": "Incoming Inspection Query",
      "4120_header": "",
      "4230_header": "",
      "total": "Total Amount Transferred",
      "planqty": "Transfer Amount",
      "jobN": "Source Tracking Number",
      "batchno": "Batch Number",
      "batchno_placeholder": "Please Enter The Batch Number",
      "note": "Note",
      "batchdate": "Batch Number Date",
      "tb": "Acceptance Details",
      "punit": "Unit",
      "S": "人工計數",
      "D": "條碼計數",
      "M": "混合計數",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "Accounts Payable Management",
      "ap_create_header": "Create A New Statement Master",
      "ap_edit_header": "Edit Statement Master",
      "ap_sumtotal": "Total Amount",
      "strike_sumtotal": "Total Chargeback Amount",
      "takeAp": "Quote Statement",
      "contactWindow": "Contact Window Information",
      "chooseWindow": "Select Window",
      "ap_tb": "Statement Details",
      "strike_tb": "Offset Bill Details",
      "inbatchno": "Feed Batch Number",
      "itemtotal": "Amount Payable Including Tax",
      "hubqty": "Receipt",
      "balance": "Account Difference",
      "recon": "Reconciliation Data",
      "addr": "Customer Address",
      "addr_placeholder": "",
      "strike_tb_delete_confirm": "",
      "paytotal": "Charged Amount",
      "ap_total": "Total Amount Receivable Including Tax",
      "apCheck": "Reconciliation Review",
      "cancel_apCheck": "",
      "apCheck_confirm": "",
      "apCheck_confirm_text": "",
      "releaseAP": "",
      "payment_placeholder": "請選擇付款方式",
      "cancel_releaseAP": "Cancel Statement",
      "releaseAP_confirm": "",
      "releaseAP_confirm_text": "",
      "strikeCheck": "Reverse Account Review",
      "strike_create_header": "Create A New Reverse Bill Master File",
      "strike_edit_header": "",
      "hd_drawer_title": "Reconciliation Data Master",
      "itemNM": "Note",
      "paytype": "Payment Method",
      "strikeCheck_confirm": "Reverse Account Review Confirmation",
      "strikeCheck_confirm_text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review",
      "cancel_strikeCheck_confirm_text": "",
      "confirmStrike": "",
      "confirmStrike_confirm": "Chargeback Confirmation",
      "confirmStrike_confirm_text":
        "After Confirming The Chargeback, The Chargeback Bill Will Be Closed, Please Confirm Whether To Charge The Account",
      "strike_paytotal": "Payment Amount",
      "payT": "Payment Time",
      "note": "Note",
      "PN": "Erp Material Number",
      "punit": "Unit",
      "inqty": "Transfer Amount",
      "itemsum": "Single Amounts",
      "itemsumtax": "Single Tax",
      "subtotal": "Subtotal Including Tax",
      "ap_del_hd_confirm_text":
        "Please Confirm That The Statement Information To Be Deleted Is Correct Before Deleting",
      "strike_del_hd_confirm_text":
        "Please Confirm That The Billing Information To Be Deleted Is Correct Before Deleting",
      "message_chose_take": "請選擇要引用的對帳資料",
      "AP_lifeF_0": "Create",
      "AP_lifeF_1": "",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "",
      "AP_lifeF_T": "",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "",
      "message_chose_take_which_ap": "Please Select The Reconciliation Data To Quote",
      "message_only_take_one_ap": "",
    },
    "return": {
      "pageHeader": "Sales Return Operation",
      "create_header": "New Feed Exits Main File",
      "edit_header": "Edit Feed Exit Master File",
      "contact": "Contact Person",
      "tel": "Phone Number",
      "addr": "Contact Address",
      "del_hd_confirm_text": "",
      "rtnqty": "Return Quantity",
      "punit": "Unit",
      "inbatchno": "",
      "note": "Note",
      "tb": "Sales Return Details",
      "itemNM": "Note",
      "itemNM_placeholder": "Please Fill In The Note ",
      "lifeF_0": "",
      "lifeF_T": "",
      "message_at_least_chose_one": "Please Select At Least One Inspection And Refund Details",
      "planqty": "驗退數量",
    },
  },
  OMS: {
    "pageHeader": "銷貨管理系統",
    "util": {
      "opcode_1110": "國內報價",
      "opcode_1120": "國外報價",
      "opcode_1210": "預估訂單",
      "opcode_1310": "國內訂單",
      "opcode_1320": "國外訂單",
      "opcode_1410": "國內訂單",
      "opcode_1420": "國外訂單",
      "opcode_4220": "驗出單",
      "opcode_4130": "驗退單",
      "opcode_1610": "銷貨退回",
      "opcode_1710": "銷售出貨",
      "opcode_1810": "Accounts Receivable Offsetting",
      "plan": "計畫單",
      "quo": "報價單",
      "fod": "預訂單",
      "ord": "銷貨單",
      "outstock": "發貨單",
      "return": "銷貨退回單",
      "ap": "對帳單",
      "outN": "驗退單號",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "add": "新建",
      "submit": "確定",
      "cancel": "取消",
      "signBack": "签回",
      "search": "搜尋",
      "sysMsg": "系統訊息",
      "del_confirm": "刪除確認",
      "advanceSearch": "進階查詢",
      "ok": "確認",
      "yes": "是",
      "no": "否",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N11": "報價單號",
      "N12": "預訂單號",
      "N13": "銷貨單號",
      "N14": "發貨單號",
      "N17": "應收單號",
      "N18": "沖帳單號",
      "jobN": "作業單號",
      "batchno": "批號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "planSearch": "計畫紀錄查詢",
      "quoSearch": "報價紀錄查詢",
      "fodSearch": "預訂紀錄查詢",
      "ordSearch": "銷貨紀錄查詢",
      "stockSearch": "驗收記錄查詢",
      "outstockSearch": "發貨紀錄查詢",
      "apSearch": "對帳資料查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "N_placeholder": "請選擇單號",
      "currencytype_placeholder": "請選擇幣別",
      "custID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入稅率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "custID": "客戶ID",
      "custalias": "客戶簡稱",
      "custNM": "客戶名稱",
      "currencytype": "幣別",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "taxtype": "稅別",
      "taxrate": "稅率",
      "confirmID": "覆核人員",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "approveT": "核准日期",
      "updtID": "更新人員",
      "updtT": "更新日期",
      "EDT": "預計交貨日",
      "startEDT": "預計交貨日(起)",
      "endEDT": "預計交貨日(迄)",
      "dealN": "客戶單號",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "drawno": "圖號",
      "attribute": "物料屬性",
      "pdtclass": "商品類別",
      "pdttype": "管理分類",
      "pbom": "組合數量",
      "utype": "單位類別",
      "unit": "計量單位",
      "opunit": "操作單位",
      "punit": "使用單位",
      "localprice": "本幣金額",
      "tax": "總稅額",
      "itemsum": "單項金額(未稅)",
      "itemsumtax": "各項稅額",
      "subtotal": "含稅小計",
      "batchdate": "批號日期",
      "quoID": "報價人員",
      "quoT": "報價日期",
      "balqty": "剩餘數量",
    },
    "quo": {
      "pageHeader": "Quotation Job",
      "quoID": "",
      "quoT": "Quotation Date",
      "validdays": "Valid Date",
      "isvalid": "validation",
      "hd_drawer_title": "",
      "tb_drawer_title": "Quotation Details",
      "demand": "Quotation Quantity",
      "punit": "Quotation Unit",
      "unitprice": "Quotation Amount",
      "isapprove": "Single Transaction",
      "itemsum": "",
      "itemsumtax": "",
      "subtotal": "",
      "itemtotal": "",
      "local_subtotal": "",
      "total": "",
      "note": "",
      "hd_delete_confirm_text":
        "Please Confirm That The Quotation Information To Be Deleted Is Correct Before Deleting",
      "lifeF_0": "",
      "lifeF_2": "Quotation Review",
      "lifeF_7": "Review And Sign Off",
      "lifeF_T": "",
      "quoCheck_confirm_Text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review",
      "cancel_quoCheck_confirm_Text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Review",
      "Audit_confirm": "Review And Sign Off",
      "Audit_confirm_text":
        "This Quotation Cannot Be Edited After Review And Approval. Are You Sure You Want To Complete The Review?",
      "tb": "Offer Details",
    },
    "fod": {
      "pageHeader": "Book Homework",
      "hd_drawer_title": "",
      "tb_drawer_title": "",
      "driID": "Responsible For Business",
      "trade": "Terms Of Trade",
      "dealcontact": "Customer Purchasing Window",
      "payment": "付款方式",
      "dealaddr": "Customer Address",
      "custHistory": "Customer Transaction History Inquiry",
      "contact": "",
      "sumtotal": "Total Untaxed Amount",
      "tax": "Total Tax",
      "note": "Note",
      "total": "",
      "demandqty": "Number Of Customers",
      "punit": "Order Unit",
      "unitprice": "Order Unit Price",
      "confirmqty": "Confirm The Quantity",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "transferqty": "Transfered Quantity",
      "qty": "Estimated Number Of Transfer",
      "hd_delete_confirm_text":
        "Please Confirm That The Reservation Information To Be Deleted Is Correct Before Deleting",
      "lifeF_0": "Release",
      "lifeF_2": "Booking Review",
      "lifeF_7": "客戶簽回",
      "lifeF_T": "Close The Case",
      "local_total": "Total Amount Including Tax (Local Currency)",
      "fodCheck_confirm_text": "",
      "cancel_fodCheck_confirm_text": "",
      "custCheck_confirm": "Customer Sign Back Confirmation",
      "custCheck_confirm_text":
        "Please Confirm That The Information To Be Signed Back Is Correct Before Signing Back",
      "cancel_custCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "reviewFlow": "",
      "tb": "",
      "EDT": "",
      "deal_history": "Product Transaction History Query",
    },
    "ord": {
      "pageHeader": "Sales Operation",
      "hd_drawer_title": "",
      "tb": "",
      "adda": "Terms And Conditions",
      "addb": "Additional Deduction",
      "addc": "Prepaid Assignment",
      "lifeF_0": "Release",
      "lifeF_2": "Order Review",
      "lifeF_7": "Customer Sign Back",
      "lifeF_T": "",
      "driID": "Responsible For Business",
      "sumtotal": "Total Untaxed Amount",
      "total": "Total Amount Including Tax (Transaction Currency)",
      "dealaddr": "Customer Address",
      "contact": "",
      "dealcontact": "",
      "payment": "Payment Method",
      "trade": "Terms Of Trade",
      "note": "Note",
      "reviewFlow": "",
      "demandqty": "",
      "transferqty": "Transfered Quantity",
      "qty": "",
      "punit": "",
      "unitprice": "",
      "confirmqty": "Customer Confirmation Quantity",
      "cancelqty": "Number Of Cancellations",
      "returnqty": "Return Quantity",
      "itemsum": "Untaxed Individual Amount",
      "itemsumtax": "",
      "charge": "Total Deductions",
      "subtotal": "Subtotal Including Tax",
      "statement": "Project Instruction",
      "amount": "",
      "local_total": "Total Amount Including Tax (Local Currency)",
      "startEDT": "",
      "endEDT": "",
      "closeData_confirm_text": "",
      "removeData_confirm_text": "",
      "orderCheck_text":
        "Please Confirm That The Information To Be Ordered For Review Is Correct Before Proceeding With The Order Review",
      "cancel_orderCheck_text": "",
      "cancel_custCheck_confirm_text":
        "Please Confirm That The Information To Be Canceled Is Correct Before Canceling The Sign-In",
      "custCheck_confirm_text":
        "Please Confirm That The Information To Be Signed Back Is Correct Before Signing Back",
      "adda_delete_confirm_text": "",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text": "",
      "deal_history": "Product Transaction History Query",
    },
    "outstock": {
      "pageHeader": "發貨作業",
      "hd_drawer_title": "發貨單主檔",
      "tb_drawer_title": "發貨單明細",
      "tb": "發貨明細",
      "lifeF_0": "發佈",
      "lifeF_2": "發貨覆核",
      "lifeF_3": "IQC抽驗",
      "lifeF_4": "IQC完驗",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "contact": "聯絡人",
      "tel": "聯絡電話",
      "sampleID": "抽樣人員",
      "sampleT": "抽樣日期",
      "note": "發貨註記",
      "punit": "發貨單位",
      "indqty": "發貨數量",
      "goqty": "驗收數量",
      "unitprice": "銷貨單價",
      "rejectqty": "驗退數量",
      "IQCcheck": "進檢抽樣",
      "inspectID": "進檢人員",
      "inspectT": "完驗時間",
      "qalID": "品判人員",
      "qalT": "放行時間",
      "judgeT": "品保判定日期",
      "qty": "數量",
      "choose": "選擇",
      "choose_err": "剩餘數量為0不可引用",
      "throw_save_msg": "請先選擇引用再輸入引用數量",
      "throwQty_msg": "拋轉數量不可小於0",
      "useDetail_msg": "不可引用相同明細",
      "tb_remove_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "IQCcheck_text": "即將進入進檢抽樣作業，是否儲存發貨單資料?",
      "closeData_confirm_text": "請先確認要刪除的發貨單資料無誤，再進行刪除",
      "outstockCheck_text": "請先確認要發貨覆核的資料無誤，再進行發貨覆核",
      "cancel_outstockCheck_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "IQCfinish_text": "即將進入IQC完驗作業，是否儲存發貨單資料?",
      "QAcheck_err": "發貨明細 [驗收數量]+[驗退數量] 要等於 [發貨數量]",
      "QAcheck": "品保放行",
      "QAcheck_text": "即將進入品保放行作業，是否儲存發貨單資料?",
      "throwStock_text": "即將進入拋轉發貨單作業，是否確定拋轉?",
    },
    "check": {
      "pageHeader": "Shipping Inspection",
      "4220_header": "",
      "4220_tb": "",
      "4130_header": "",
      "4130_tb": "退回明細",
      "N": "",
      "jobN": "Source Tracking Number",
      "batchno": "Batch Number",
      "batchdate": "Batch Number Date",
      "note": "Note",
      "total": "Total Amount Transferred",
      "planqty": "Transfer Quantity",
      "punit": "",
      "stockinT": "",
      "lifeF_0": "Release",
      "lifeF_T": "",
    },
    "return": {
      "pageHeader": "Sales Return Operation",
      "tb": "Sales Return Details",
      "hd_remove_text": "",
      "tb_remove_text": "",
      "take": "",
      "unitprice": "",
      "rtnqty": "Return Quantity",
      "inbatchno": "",
      "contact": "Contact Person",
      "contact_window": "",
      "note": "Note",
      "tel": "Phone Number",
      "addr": "Contact Address",
      "itemNM": "Note",
      "lifeF_0": "",
      "lifeF_T": "",
    },
    "ap-manage": {
      "pageHeader": "Accounts Receivable Management",
      "rcv": "Accounts receivable inquiry",
      "rcvData": "Receivable Data",
      "rcv_drawer_title": "",
      "inbatchno": "Feed Batch Number",
      "contactWindow": "Contact Window Information",
      "ap": "Reconciliation Job",
      "ap_tb": "Statement Details",
      "strike": "Reverse Operation",
      "strike_tb": "Offset Bill Details",
      "unitprice": "",
      "rcv_inqty": "Transfer Quantity",
      "rcv_itemsum": "Single Amounts",
      "rcv_itemsumtax": "Single Tax",
      "rcv_subtotal": "Subtotal Including Tax",
      "ap_remove_text": "",
      "strike_remove_text": "",
      "ap_total": "Total Amount Receivable Including Tax",
      "ap_itemtotal": "Amount Receivable Including Tax",
      "ap_paytotal": "Charged Amount",
      "ap_sumtotal": "Total Amount",
      "strike_sumtotal": "Total Chargeback Amount",
      "ap_hubqty": "Outbound Quantity",
      "ap_addr": "客戶地址",
      "ap_balance": "Account Difference",
      "ap_lifeF_0": "Create",
      "ap_lifeF_1": "",
      "ap_lifeF_2": "Issue The Bill Of Receivable",
      "ap_lifeF_3": "Unapplied Cash Receipts",
      "ap_lifeF_T": "結案",
      "ap_contact": "Customer Business Window",
      "ap_itemNM": "Note",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "Note",
      "confirmStrike": "",
      "apCheck_confirm_text": "",
      "releaseAP_confirm_text": "",
      "strikeCheck_confirm_text":
        "Please Confirm That The Information To Be Reviewed Is Correct Before Proceeding With The Review",
      "cancel_strikeCheck_confirm_text": "",
      "confirmStrike_confirm_text":
        "After Confirming The Chargeback, The Chargeback Bill Will Be Closed, Please Confirm Whether To Charge The Account",
      "strike_tb_remove_text": "",
    },
  },
  PDS: {
    "wpc-search": {
      "wlID": "",
      "linemix": "",
      "hdC": "",
      "issueday": "",
    },
    "overview-lines": {
      "pageHeader": "Line Body Operation Summary Table",
      "wlID": "Line",
      "act": "Utilization Rate",
      "shiftD": "Day Shift",
      "shiftN": "Night Shift",
      "allDay": "Left",
    },
    "dashboard": {
      "pageHeader": "Digital Production Kanban",
    },
    "abc-board": {
      "pageHeader": "Exception Management Kanban",
    },
  },
};

export default en_US;
