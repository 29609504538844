/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
// component
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// api
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addApsOutWo, updateApsOutWo } from "@/service/apis/APS/apsMange";

const ModalContainer = ({ s_editData, OPCODE_LABELS, set_s_showModal }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  // useAPI 新增工單
  const call_addApsOutWo = useAPI(addApsOutWo);
  // useAPI 編輯工單
  const call_updateApsOutWo = useAPI(updateApsOutWo);

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value }).then(
      (e) =>
        e?.data.map((x) => ({
          label: x.PN,
          value: x.PN,
          data: x,
        })) || []
    );
  };

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "edit";
    type === "create" ? call_addApsOutWo.request(values) : call_updateApsOutWo.request(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...s_editData,
    });
  }, []);

  // useAPI 新增工單
  useEffect(() => {
    const { status, msg } = call_addApsOutWo;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_addApsOutWo.status]);

  // useAPI 編輯工單
  useEffect(() => {
    const { status, msg } = call_updateApsOutWo;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_updateApsOutWo.status]);

  const type = Object.keys(s_editData).length === 0 ? "create" : "edit";

  return (
    <Form
      form={form}
      // initialValues={{
      //   ...s_editData,
      // }}
      onFinish={onSubmit}
      autoComplete="off"
      layout="vertical"
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="UUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("APS.util.opcode")} // 單別
            name="opcode"
            rules={[{ required: true }]}
          >
            <Select disabled={type === "edit"} options={OPCODE_LABELS} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("APS.aps-manage.jobN")} // 工單號
            name="jobN"
            rules={[{ required: true }]}
          >
            <Input disabled={type === "edit"} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("APS.aps-manage.orderN")} // 客戶訂購單號
            name="orderN"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label={t("util.PN")} name="PN" rules={[{ required: true }]}>
            {Object.keys(s_editData).length > 0 ? (
              <Input disabled={type === "edit"} />
            ) : (
              <DebounceSelect
                fetchOptions={fetchPNList}
                maxTagCount={10}
                showSearch
                onChange={(value, data) => {
                  // console.log("data = ", data);
                  form.setFieldsValue({ PN: data.data?.PN, pdtNM: data.data?.pdtNM });
                }}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={16}>
          <Form.Item
            label={t("util.pdtNM")} // 品名
            name="pdtNM"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("APS.util.duestart")} // 預計完成起日
            name="duestart"
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("APS.util.dueend")} // 預計完成迄日
            name="dueend"
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("APS.aps-manage.ETDstart")} // 預計交貨起日
            name="ETDstart"
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t("APS.aps-manage.ETDend")} // 預計交貨迄日
            name="ETDend"
          >
            <DatePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_showModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
