/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import {
  Divider,
  Button,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Form,
  InputNumber,
  message,
  Tooltip,
  DatePicker,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// content
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import TbTitle from "@/components/ERP/PMS/TbTitle";
// API
import useAPI from "@/hooks/useAPI";
import { getOutRtn, takeRtn } from "@/service/apis/PMS/Return";
const { Text } = Typography;

const TakeRtnModal = ({ set_is_showTake, hd_values, call_takeRtn }) => {
  const { t } = useTranslation();
  // 廠商資料
  const { c_supps } = useContext(ERPContext);
  const [form] = Form.useForm();
  // useAPI 取得驗退單
  const call_getOutRtn = useAPI(getOutRtn);
  // 引用驗退單
  // const call_takeRtn = useAPI(takeRtn);

  const [s_tableData, set_s_tableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onFinish = (values) => {
    call_getOutRtn.request({
      ...values,
      stockinT: values.stockinT ? dayjs(values.stockinT).format("YYYY-MM-DD") : undefined,
    });
  };

  // 確定選擇
  const onSubmit = () => {
    if (selectedRowKeys.length === 0) {
      // "請至少選擇一筆驗退單明細"
      message.error(t("PMS.return.message_at_least_chose_one"));
      return;
    }

    // 要被引用的明細
    const takeList = selectedRowKeys.map((rowkey) => {
      return {
        ...s_tableData[rowkey],
        opUUID: hd_values.opUUID,
        linkType: "take",
      };
    });

    // console.log("takeList = ", takeList);
    // return;

    // call 引用的API
    call_takeRtn.request(takeList);

    // 關閉跳顯
    set_is_showTake(false);
  };

  // 第一次渲染覆值表單
  useEffect(() => {
    form.setFieldsValue({
      suppID: hd_values.suppID,
    });
  }, []);

  useEffect(() => {
    const { status, data, msg } = call_getOutRtn;
    if (status === "suc") {
      message.success(msg);
      console.log(data);
      set_s_tableData(data ?? []);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOutRtn.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "3%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("PMS.util.pdtspec"),//"規格",
      dataIndex: "pdtspec",
      align: "center",
      width: "17%",
    },
    {
      title: t("PMS.return.planqty"),//"驗退數量",
      dataIndex: "planqty",
      align: "center",
      width: "7%",
    },
    // {
    //   title: '單位類別',
    //   dataIndex: 'utype',
    //   align: 'center',
    //   width: '8%'
    // },
    {
      title: t("PMS.return.punit"),//"採購(使用)單位",
      dataIndex: "punit",
      align: "center",
      width: "10%",
    },
    {
      title: t("PMS.util.outN"),//"驗退單號",
      dataIndex: "N",
      align: "center",
      width: "9%",
    },
    {
      title: t("PMS.return.inbatchno"),//"進料批號",
      dataIndex: "inbatchno",
      align: "center",
      width: "12%",
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("newSelectedRowKeys = ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div>
      <Form onFinish={onFinish} name="basic" form={form}>
        <Row gutter={[12, 0]}>
          <Col span={4}>
            <Form.Item name="suppID" label={<Text disabled>{t("PMS.util.suppalias")}</Text>}>
              <Select disabled showSearch placeholder={t("PMS.util.suppID_placeholder")} options={c_supps.options} />
            </Form.Item>
          </Col>
          <Col span={4}>
            {/* 驗退單號 */}
            <Form.Item name="outN" label={<Text>{t("PMS.util.outN")}</Text>}>
              <Input placeholder={t("PMS.util.placeholder_input")+t("PMS.util.outN")} />
            </Form.Item>
          </Col>

          <Col span={4}>
          {/* 批號 */}
            <Form.Item name="batchno" label={<Text>{t("PMS.util.batchno")}</Text>}>
              <Input placeholder={t("PMS.util.placeholder_input")+t("PMS.util.batchno")} />
            </Form.Item>
          </Col>

          <Col span={4}>
          {/* 移轉時間 */}
            <Form.Item name="stockinT" label={<Text>{t("PMS.util.stockinT")}</Text>}>
              <DatePicker />
            </Form.Item>
          </Col>

          <Col span={1}>
            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
              {t("util.search")}
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider />

      <CustomTable
        columns={tableColumns}
        rowSelection={rowSelection}
        rowNumber={6}
        dataSource={s_tableData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_is_showTake(false)}/>
          <ConfirmButton type="primary" onClick={onSubmit}/>
        </Col>
      </Row>
    </div>
  );
};

export default TakeRtnModal;
