import { Select, Form, InputNumber, Input, Col, Alert } from "antd";

const datamatrixVerOptions = [
  { label: "10x10", value: "10x10" },
  { label: "12x12", value: "12x12" },
  { label: "14x14", value: "14x14" },
  { label: "16x16", value: "16x16" },
  { label: "18x18", value: "18x18" },
  { label: "20x20", value: "20x20" },
  { label: "22x22", value: "22x22" },
  { label: "24x24", value: "24x24" },
  { label: "26x26", value: "26x26" },
  { label: "32x32", value: "32x32" },
  { label: "36x36", value: "36x36" },
  { label: "40x40", value: "40x40" },
  { label: "44x44", value: "44x44" },
  { label: "48x48", value: "48x48" },
  { label: "52x52", value: "52x52" },
  { label: "64x64", value: "64x64" },
  { label: "72x72", value: "72x72" },
  { label: "80x80", value: "80x80" },
  { label: "88x88", value: "88x88" },
  { label: "96x96", value: "96x96" },
  { label: "104x104", value: "104x104" },
  { label: "120x120", value: "120x120" },
  { label: "132x132", value: "132x132" },
  { label: "144x144", value: "144x144" },
];

const barcodeLabel = (field, rowData) => {
  const hasChinese = (str) => {
    const reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    return reg.test(str);
  };

  return (
    <>
      <Col span={24} className="pb-2">
        <Alert message="一維條碼的寬度推薦為高度的 2.5 倍 !" type="info" />
      </Col>
      <Col span={12}>
        <Form.Item
          label="ex:固定字串${1}${2}"
          name={[field.name, "quoteText"]}
          rules={[
            {
              required: true,
              validator: async (_, value) => {
                if (hasChinese(value)) {
                  throw new Error("輸入文字不得包含中文");
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item label="替代字串" name={[field.name, "fakeText"]} rules={[{ required: true }]}>
          <Input className="w-full" />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item
          label="大小(0.1-10)"
          name={[field.name, "barcodeSize"]}
          rules={[{ required: true }]}
        >
          <InputNumber min={0.1} max={10} className="w-full" />
        </Form.Item>
      </Col>

      {rowData.type === "datamatrix" && (
        <>
          <Col span={6}>
            <Form.Item
              label="版本"
              name={[field.name, "datamatrixVer"]}
              rules={[{ required: true }]}
            >
              <Select options={datamatrixVerOptions} />
            </Form.Item>
          </Col>
        </>
      )}
      {(rowData.type === "code128" ||
        rowData.type === "code39" ||
        rowData.type === "upca" ||
        rowData.type === "ean13") && (
        <>
          <Col span={6}>
            <Form.Item
              label="條碼高度"
              name={[field.name, "code128height"]}
              rules={[{ required: true }]}
            >
              <InputNumber min={1} className="w-full" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="條碼寬度"
              name={[field.name, "code128width"]}
              rules={[{ required: true }]}
            >
              <InputNumber min={1} className="w-full" />
            </Form.Item>
          </Col>
        </>
      )}

      <Col span={6}>
        <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>

      <Col span={6}>
        <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
          <InputNumber min={0} className="w-full" />
        </Form.Item>
      </Col>
    </>
  );
};

export default { barcodeLabel };
