/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../../index";
// API
import useAPI from "@/hooks/useAPI";
import { getStrikeTB } from "@/service/apis/PMS/strike";
// util
import { formatAmount } from "@/util/format";

const TB = () => {
  const { t } = useTranslation();
  // useAPI 取得表身
  const call_getStrikeTB = useAPI(getStrikeTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getStrikeTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getStrikeTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getStrikeTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.ap-manage.paytype"), // 付款方式
      dataIndex: "paytype",
      align: "center",
      width: "20%",
      render: (textarr, _, __) => {
        const map = {
          1: t("OMS.ap-manage.rcvtype_1"), // "銀行滙款(T/T)",
          2: t("OMS.ap-manage.rcvtype_2"), // "應付票據(支票)",
          3: t("OMS.ap-manage.rcvtype_3"), // "現金支付(CASH)",
          4: t("OMS.ap-manage.rcvtype_4"), // "現金支付(CASH)",
        };

        return textarr?.map((item) => map[item])?.join(" , ") || "";
      },
    },
    {
      title: t("PMS.ap-manage.strike_paytotal"), // 付款金額
      dataIndex: "paytotal",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.ap-manage.payT"), // 付款時間
      dataIndex: "payT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("PMS.ap-manage.note"), // 沖帳註記
      dataIndex: "note",
      align: "center",
    },
  ];

  return (
    <div className="flex-1">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
    </div>
  );
};

export default TB;
