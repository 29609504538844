import bwipjs from "bwip-js";

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    default:
      return value * 96;
  }
};

// 繪製barcode
const createBarcodeImage = async (ctx, data) => {
  let opts = {
    bcid: data.type,
    text: data.quoteText,
    scale: 3,
    includetext: false,
    // textxalign: "center",
  };

  if (data.type === "datamatrix") {
    // 防止舊模板出錯 預設"20x20"
    opts["version"] = data.datamatrixVer || "20x20";
  }

  if (data.type === "upca" || data.type === "ean13") {
    opts["includetext"] = true;
    // 設定高度須把px 轉換成mm
    opts["height"] = (data.code128height * 25.4) / 96;
    opts["textsize"] = 9;
  }
  // 創建 barcode_canvas
  const barcode_canvas = document.createElement("canvas");
  // 使用 bwipjs 將條碼繪製到 canvas 上
  bwipjs.toCanvas(barcode_canvas, opts);

  // 將繪製好的條碼轉換為 base64 編碼的圖片資料
  const barcodeDataUrl = barcode_canvas.toDataURL("image/png", 1);
  return new Promise((resolve, reject) => {
    const barcode_image = new Image();
    barcode_image.src = barcodeDataUrl;
    barcode_image.onload = () => {
      const barcodeWidth =
        data.type === "datamatrix" || data.type === "qrcode" ? 126 : data.code128width;
      const barcodeHeight =
        data.type === "datamatrix" || data.type === "qrcode" ? barcodeWidth : data.code128height;
      const scaledWidth = barcodeWidth * data.barcodeSize;
      const scaledHeight = barcodeHeight * data.barcodeSize;
      // 將圖片繪製到 canvas 上
      ctx.drawImage(barcode_image, data.x, data.y, scaledWidth, scaledHeight);
      resolve("ok");
    };
    barcode_image.onerror = () => reject("ng");
  });
};

// 繪製圖片指定位置
const createImage = (ctx, data) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = data.imgUrl;
    img.onload = () => {
      ctx.drawImage(img, data.x, data.y, data.boxWidth, data.len);
      resolve();
    };
    img.onerror = (error) => {
      console.log("err");
      reject(error);
    };
  });
};

// 繪製文字
const createText = (ctx, data) => {
  // 計算文字的總寬度
  const calculateTotalTextWidth = (text, fontFamily, bold, fontSize) => {
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${bold} ${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  // 根據 data.type 設置要繪製的文字內容
  let text = "err";
  switch (data.type) {
    case "keyText":
      text = data.keyText;
      break;
    case "date":
      text = data.date;
      break;
    case "text":
      text = data.text;
      break;
    case "quoteText":
      text = data.quoteText;
      break;
  }

  // 計算文字的總寬度
  const totalTextWidth = calculateTotalTextWidth(text, data.fontFamily, data.bold, data.fontSize);

  // 創建 Canvas 元素
  const canvasText = document.createElement("canvas");
  const ctxText = canvasText.getContext("2d");

  // 設置 Canvas 大小
  const canvasWidth = totalTextWidth * 10; // 使用文字的總寬度 + x 作為 canvas 寬度
  const canvasHeight = data.fontSize * 10; // 使用 fontSize 的高度作為 canvas 高度
  canvasText.width = canvasWidth;
  canvasText.height = canvasHeight;

  // 設置文字樣式、顏色、對齊方式等
  ctxText.font = `${data.bold} ${data.fontSize}px ${data.fontFamily}`;
  ctxText.fillStyle = data.c;
  ctxText.textAlign = data.boxAlign;
  ctxText.textBaseline = "top";
  ctxText.scale(10, 10);
  ctxText.fillText(text, 0, 0);
  const textImgSrc = canvasText.toDataURL();

  return new Promise((resolve, reject) => {
    const text_image = new Image();
    text_image.src = textImgSrc;
    text_image.onload = () => {
      // 將圖片繪製到 canvas 上
      ctx.drawImage(
        text_image,
        data.x,
        data.y,
        totalTextWidth * (data.fontWidth / 100),
        data.fontSize
      );
      resolve("ok");
    };
    text_image.onerror = () => reject("ng");
  });
};
// 繪製形狀
const createShape = (ctx, data) => {
  ctx.beginPath(); // 開始繪製路徑
  switch (data.type) {
    case "border":
      // 繪製邊框
      ctx.moveTo(data.x, data.y); // 設定路徑起點
      const diameter = (data.angle * Math.PI) / 180; // 計算弧度
      ctx.lineTo(
        data.x + data.len * Math.cos(diameter), // 設定路徑終點 x 座標
        data.y + data.len * Math.sin(diameter) // 設定路徑終點 y 座標
      );
      ctx.strokeStyle = data.c; // 設定筆畫顏色
      ctx.lineWidth = data.b; // 設定筆畫寬度
      ctx.stroke(); // 繪製筆畫
      break;
    case "round":
      // 繪製圓形
      ctx.moveTo(data.x, data.y); // 設定路徑起點
      ctx.arc(data.x, data.y, data.radius, 0, 360); // 繪製圓弧
      ctx.fillStyle = data.c; // 設定填滿顏色
      ctx.fill(); // 填滿圖形
      break;
    default:
      break;
  }
  ctx.closePath(); // 結束繪製路徑
};

/**
替換字串中的變數的函數
@param {String} text - 要替換的字串
@param {Array} variables - 包含變數的陣列
@returns {String} - 回傳替換後的字串
*/
const replaceVariable = (text, data, variables) => {
  // 使用正則表達式找到所有變數
  const reg_g = /\$\{(.+?)}/g;
  let result = null;
  let list = [];
  do {
    result = reg_g.exec(text);
    result && list.push(result[1]);
  } while (result);

  let newText = text;
  // 將每個變數替換為對應的值
  for (const index of list) {
    // 從變數陣列中取得對應的值
    let variableValue = data[index - 1]?.text || variables[data[index - 1]?.keyText];
    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      variableValue = "${" + index + "}";
    }
    // 將變數替換為對應的值
    newText = newText.replace("${" + index + "}", variableValue);
  }
  return newText;
};

/**
引用其他項目固定字串或關鍵字串的內容的函數
@param {Object} data - 包含替換的數據的物件
@param {Object} item - 要引用的 item 物件
@param {Object} variable - 包含變數的物件
@returns {Object} - 回傳修改後的 item 物件
*/
const quoteRef = (data, item, variable) => {
  if (item.type === "keyText") {
    // 如果是 keyText，則從 variable 中取得對應的值並替換掉 keyText 的值
    let variableValue = variable[item.keyText];
    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      variableValue = "${" + item.keyText + "}";
    }
    return { ...item, keyText: variableValue };
  }
  // 替換掉該 item 的 quoteText 中的變數
  let replacedQuoteText = replaceVariable(item.quoteText, data, variable);
  // 如果不是 keyText，則將 quoteText 替換為新的值
  return { ...item, quoteText: replacedQuoteText };
};

/**
生成標籤的函數
@param {Array} variables - 要替換的變數
@param {Array} models - 條碼模板
@returns {Promise} - 回傳一個 Promise 物件，內容為生成的標籤圖片的 base64 編碼 URL
*/
const buildLabel = async (variables, models) => {
  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  // 提升解析度 10 倍
  canvas.height = unitToPx(variables.height, variables.unit) * 10;
  canvas.width = unitToPx(variables.width, variables.unit) * 10;

  ctx.height = canvas.height;
  ctx.width = canvas.width;

  // 清除畫布的內容，並將畫布填滿白色
  ctx.fillStyle = "#ffffff";
  ctx.fillRect(0, 0, ctx.width, ctx.height);

  // 縮放畫布，以提高解析度
  ctx.scale(10, 10);

  // 遍歷模板中的所有項目
  for (const model of models) {
    // 如果模板無效，則跳過此模板
    if (!model.isvalid) continue;

    // 替換項目中的變數
    const data = quoteRef(models, model, variables);
    ctx.beginPath();

    // 根據項目的類型繪製圖形或文字
    switch (data.type) {
      case "text":
      case "keyText":
      case "date":
      case "quoteText":
        createText(ctx, data);
        break;
      case "border":
      case "round":
        createShape(ctx, data);
        break;
      case "img":
        await createImage(ctx, data);
        break;
      default:
        // 如果是條碼類型，則使用對應的函數繪製條碼
        await createBarcodeImage(ctx, data);
        break;
    }
    ctx.closePath();
  }

  // 將畫布轉換成 base64 編碼的圖片 URL，並返回
  const dataURL = canvas.toDataURL("image/png", 1);
  return dataURL;
};

export default {
  buildLabel,
  replaceVariable,
  quoteRef,
};
