import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";
//-----------------------------------------表頭資料----------------------------------------

// 取得採購單價調幅資料
export const getDiff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDiff`, {
    params: data,
  });
};

export const addProDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProDoc`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 取得採購單進貨資料
export const getPurchased = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPurchased`, {
    params: data,
  });
};

// 新建表頭資料
export const addProHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProHD`, data);
};
export const getProHdAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProHdAd`, {
    params: data,
  });
};
// 取得表頭資料
export const getProHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProHD`, {
    params: data,
  });
};
// 更新表頭資料
export const updateProHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateProHD`, data);
};
// 刪除表頭資料
export const deleteProHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteProHD`, data);
};

// 取得入庫單(表身)
export const getStockTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStockTbPro`, {
    params: data,
  });
};

//-----------------------------------------表身資料----------------------------------------
// 新建表身資料
export const addProTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProTB`, data);
};
// 取得表身資料
export const getProTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProTB`, {
    params: data,
  });
};
// 更新表頭資料
export const updateProTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateProTB`, data);
};
// 刪除表頭資料
export const deleteProTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteProTB`, data);
};

//------------------------ 取得條款註記、預付作業、加扣款項 ----------------------------------------
export const getProADD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProADD`, {
    params: data,
  });
};
//------------------------ 新增條款註記、預付作業、加扣款項 ----------------------------------------
export const addProADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addProADD`, data);
};
//------------------------ 更新條款註記、預付作業、加扣款項 ----------------------------------------
export const updateProADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateProADD`, data);
};
//------------------------ 更新條款註記、預付作業、加扣款項 ----------------------------------------
export const deleteProADD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteProADD`, data);
};
//-----------------------------------------收貨地址、發票地址----------------------------------------
export const getContact = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getContact`, {
    params: data,
  });
};

//-----------------------------------------附加項目----------------------------------------
export const getAdd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getAdd`, { params: data });
};

//-----------------------------------------拋轉----------------------------------------
export const proToInstock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/proToInstock`, data);
};

// -----------------------------------------退出處置----------------------------------------
export const returnStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/returnStock`, data);
};

//-----------------------------------------取得繼承料號----------------------------------------
export const getHeirPN = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getHeirPN`, {
    params: data,
  });
};
