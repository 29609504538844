import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ERPContext } from "@/components/Context/SystemContext";
import { message } from "antd";
// component
import { PrintText } from "./index";
import ZHAOKE from "@/assets/img/ZHAOKE.svg";
//api
import { getCustTb } from "@/service/apis/ADM/client";
//hook
import useAPI from "@/hooks/useAPI";

const Header = ({ hd_values, currentPage, totalPages }) => {
  const { t } = useTranslation();
  // 客戶資料、人員資料
  const { c_custs, c_staffs } = useContext(ERPContext);
  const [s_contactTb, set_s_contactTb] = useState([]); //客戶聯絡人資料;

  const call_getCustTb = useAPI(getCustTb);

  const yyyy = hd_values.N.substring(0, 4);
  const mm = hd_values.N.substring(4, 6);
  const dd = hd_values.N.substring(6, 8);
  const nn = hd_values.N.substring(8, 11);

  useEffect(() => {
    call_getCustTb.request({ custID: hd_values?.custID });
  }, []);

  useEffect(() => {
    if (call_getCustTb.status === "suc") {
      message.success(call_getCustTb.msg);
      set_s_contactTb(call_getCustTb.data);
    } else if (call_getCustTb.status === "err") {
      message.error(t(`errorCode.${call_getCustTb.msg}`));
      set_s_contactTb([]);
    }
  }, [call_getCustTb.status]);

  return (
    <div className="flex flex-col w-full">
      {/* 頁首 資訊 */}
      {/* <div className="flex justify-end items-center absolute top-[0vh] right-[0] w-full text-xs overflow-visible">
        {`page ${currentPage}/${totalPages}`}
      </div> */}

      {/* 標題大圖 */}
      <div className="flex justify-center items-center  w-full pb-2 pt-6">
        <img src={ZHAOKE} />
      </div>

      {/* 報價單 標題 */}
      <div className="flex justify-center items-center  my-3 w-full">
        <PrintText className="text-[16px] tracking-[1px] ">检测服务报价单</PrintText>
      </div>

      <div className="flex justify-between items-center w-full">
        <div className="flex justify-start items-center">
          <PrintText>客户单号：{hd_values?.dealN}</PrintText>
        </div>
        <div className="flex justify-start items-center">
          <PrintText>
            报价单编号：DGZK-{yyyy}-{mm}-{dd}-{nn}
          </PrintText>
        </div>
      </div>

      {/* 表頭-表格*/}

      <div className="flex flex-col w-full border border-black border-solid box-border font-normal">
        <div className="flex w-full border-black border-solid border-x-0 border-y-0 border-b-[1px]">
          <div className="flex flex-col w-1/2">
            <div className="flex justify-start items-center text-sm leading-6 pl-1.5">
              <PrintText>委托方联络人：</PrintText>
              <PrintText>{hd_values.dealcontact}</PrintText>
            </div>
            <div className="flex justify-start items-center text-sm leading-6 pl-1.5">
              <PrintText>电　话：</PrintText>
              <PrintText>{hd_values.dealtel}</PrintText>
            </div>
            <div className="flex justify-start items-start text-sm  pl-1.5 min-h-[2.5rem] break-all">
              邮　件：{s_contactTb?.find((item) => item.contact === hd_values.dealcontact)?.mail}
            </div>
          </div>

          <div className="flex flex-col w-1/2 border-black border-solid border-y-0 border-x-0 border-l">
            <div className="flex justify-start items-center text-sm leading-6 pl-1.5">
              <PrintText>检测方联络人：</PrintText>
              <PrintText>
                {c_staffs.options?.find((item) => item.value === hd_values.driID)?.label ??
                  hd_values.driID}
              </PrintText>
            </div>
            <div className="flex justify-start items-center text-sm leading-6 pl-1.5">
              <PrintText>电　话：</PrintText>
              <PrintText>
                {c_staffs.data?.find((item) => item.staffID === hd_values.driID)?.tel}
              </PrintText>
            </div>
            <div className="flex justify-start items-start text-sm  pl-1.5 min-h-[2.5rem] break-all">
              邮　件：{c_staffs.data?.find((item) => item.staffID === hd_values.driID)?.mail}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full">
          <div className="flex justify-start items-center w-full leading-8 border-black border-solid border-y-0 border-x-0 border-b ">
            <div className="flex justify-start items-center w-[25%]">
              <PrintText className="ml-4 text-[14px]">生产单位/委托方单位：</PrintText>
            </div>
            <div className="flex justify-start items-center w-[75%]  border-black border-solid border-y-0 border-x-0 border-l">
              <PrintText className="text-[12px] pl-1.5">{hd_values.custNM}</PrintText>
            </div>
          </div>

          <div className="flex justify-start items-center w-full leading-8 border-black border-solid border-y-0 border-x-0 border-b ">
            <div className="flex justify-start items-center w-[25%] ">
              <PrintText className="ml-4 text-[14px]">生产单位/委托方地址：</PrintText>
            </div>
            <div className="flex justify-start items-center w-[75%] border-black border-solid border-y-0 border-x-0 border-l">
              <PrintText className="text-[12px] pl-1.5">{hd_values.dealaddr}</PrintText>
            </div>
          </div>

          <div className="flex justify-start items-center w-full leading-6 ">
            <div className="flex justify-center items-center w-[55%] ">
              <PrintText>申请服务项目</PrintText>
            </div>
            <div className="flex justify-center items-center w-[45%] border border-black border-solid border-y-0 border-x-0 border-l-[1px]">
              <PrintText>服务费用</PrintText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
