import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//-----------------------------------------管理分類----------------------------------------
export const getMgmtcat = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMgmtcat`, {
    params: data,
  });
};

//----------------------------------------- 取得廠商基本資料(表頭) ----------------------------------------
export const getSuppHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppHd`, {
    params: data,
  });
};

//----------------------------------------- 新增廠商基本資料(表頭)  ----------------------------------------
export const addSuppHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addSuppHd`,
    data
  );
};

//----------------------------------------- 編輯廠商基本資料(表頭)  ----------------------------------------
export const modifySuppHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifySuppHd`,
    data
  );
};

//----------------------------------------- 刪除廠商基本資料(表頭)  ----------------------------------------
export const deleteSuppHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteSuppHd`,
    data
  );
};

// -------------------------------------------------------------------

//----------------------------------------- 廠商基本資料(表身) ----------------------------------------
export const getSuppTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppTb`, {
    params: data,
  });
};

//----------------------------------------- 新增廠商基本資料(表身)  ----------------------------------------
export const addSuppTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addSuppTb`,
    data
  );
};

//----------------------------------------- 編輯廠商基本資料(表身)  ----------------------------------------
export const modifySuppTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifySuppTb`,
    data
  );
};

//----------------------------------------- 刪除廠商基本資料(表身)  ----------------------------------------
export const deleteSuppTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteSuppTb`,
    data
  );
};

//-------------------------------------------------------------------------------------------------------
//----------------------------------------- 取得客戶基本資料(表頭) ----------------------------------------
export const getCustHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustHd`, {
    params: data,
  });
};

//----------------------------------------- 新增客戶基本資料(表頭)  ----------------------------------------
export const addCustHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addCustHd`,
    data
  );
};

//----------------------------------------- 編輯客戶基本資料(表頭)  ----------------------------------------
export const modifyCustHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifyCustHd`,
    data
  );
};

//----------------------------------------- 刪除客戶基本資料(表頭)  ----------------------------------------
export const deleteCustHd = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteCustHd`,
    data
  );
};

// -------------------------------------------------------------------

//----------------------------------------- 客戶基本資料(表身) ----------------------------------------
export const getCustTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustTb`, {
    params: data,
  });
};

//----------------------------------------- 新增客戶基本資料(表身)  ----------------------------------------
export const addCustTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/addCustTb`,
    data
  );
};

//----------------------------------------- 編輯客戶基本資料(表身)  ----------------------------------------
export const modifyCustTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/modifyCustTb`,
    data
  );
};

//----------------------------------------- 刪除客戶基本資料(表身)  ----------------------------------------
export const deleteCustTb = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/deleteCustTb`,
    data
  );
};
