import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Button, Typography, Space, Spin } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from '@/util/format' //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import { getShipped } from "@/service/apis/OMS/Ord";
import useAPI from "@/hooks/useAPI";

const { Text } = Typography;

//進退貨彙總表
const PurchaseSummary = () => {
  const { RangePicker } = DatePicker;

  const { c_supps } = useContext(ERPContext);  //從ERPContext拿到廠商資料

  const { t } = useTranslation();

  const [is_spin, set_is_spin] = useState(false)

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    opcode: "", //單別
    suppID: '', //廠商ID
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  
  // const call_getShipped = useAPI(getShipped); // useAPI 取得訂單出貨狀態資料


  const [s_TableData, set_s_TableData] = useState([
    {
      createT: '2023/03/17',
      opcode: '2310',
      N: '20230317001',
      Invoicenumber: '12345678',
      suppalias: '廠商1',
      subtotal: 100,
      tax: 10,
      total: 110,
    },
    {
      createT: '2023/03/18',
      opcode: '2310',
      N: '20230318001',
      Invoicenumber: '12345678',
      suppalias: '廠商2',
      subtotal: 9000,
      tax: 900,
      total: 9900,
    },
    {
      createT: '2023/03/19',
      opcode: '2320',
      N: '20230319001',
      Invoicenumber: '12345678',
      suppalias: '廠商3',
      subtotal: 8800,
      tax: 880,
      total: 9680,
    },
    {
      createT: '2023/03/20',
      opcode: '2610',
      N: '20230320001',
      Invoicenumber: '12345678',
      suppalias: '廠商1',
      subtotal: -1000,
      tax: -100,
      total: -1100,
    },
    {
      createT: '2023/03/22',
      opcode: '2310',
      N: '20230322001',
      Invoicenumber: '12345678',
      suppalias: '廠商2',
      subtotal: 5000,
      tax: 500,
      total: 5500,
    },
    {
      createT: '2023/03/24',
      opcode: '2610',
      N: '20230324001',
      Invoicenumber: '12345678',
      suppalias: '廠商3',
      subtotal: -500,
      tax: -50,
      total: -550,
    },
    {
      createT: '2023/03/25',
      opcode: '2620',
      N: '20230325001',
      Invoicenumber: '12345678',
      suppalias: '廠商6',
      subtotal: -8500,
      tax: -1200,
      total: -9700,
    },
  ]); //放到表格的資料

  
  const tableColumns = [
    {
      title: "日期",
      dataIndex: "createT",
      align: "center",
      width: '5%'
    },
    {
      title: "單別",
      dataIndex: "opcode",
      align: "center",
      width: '5%',
      render: text => t(`PMS.util.opcode_${text}`)
    },
    {
      title: "單號",
      dataIndex: "N",
      align: "center",
      width: '5%'
    },
    {
      title: "發票號碼",
      dataIndex: "Invoicenumber",
      align: "center",
      width: '5%'
    },
    {
      title: "廠商",
      dataIndex: "suppalias",
      align: "left",
      width: '8%'
    },
    {
      title: "合計金額",
      dataIndex: "subtotal",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "稅額",
      dataIndex: "tax",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "總金額",
      dataIndex: "total",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    // console.log("call api");
    console.log("s_searchData", s_searchData);
    // call_getShipped.request(tmp);
  }

  //----------------------------導出EXCEL---------------------------------------------------

  const exportToExcel = () => {

    const excelTable = s_TableData.map((item) => {
      //時間格式轉換，數字加上千分位,opcode轉換為銷貨單別
      return {
        ...item,
        opcode:t(`PMS.util.opcode_${item.opcode}`),
        subtotal: formatAmount(item.subtotal),
        tax: formatAmount(item.tax),
        total: formatAmount(item.total),
        createT: dayjs(item.startEDT).format("YYYY-MM-DD")
      }
    })


    //ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excelTable,
      { header: ["createT", "opcode", "N", "Invoicenumber", "suppalias", "subtotal", "tax", "total"] });
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    // 计算每列的最大宽度
    const colWidths = s_TableData[0] && Object.keys(s_TableData[0]).map(() => 0);
    s_TableData.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        console.log("cellValue", cellValue);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map(w => ({ wch: w + 7 })); // 根据最大长度调整列宽
    ws['!cols'] = wscols;



    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }

    const today = dayjs().format("YYYY-MM-DD");

    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `進退貨彙總表${today}.xlsx`);

  };

  //----------------------------EXCEL---------------------------------------------------

  //所有銷貨單、銷退單資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getShipped;
  //   if (status === "suc") {
  //     console.log("call_getShipped = ", data);
  //     set_s_TableData(data);
  //     message.success(msg);
  //   } else if (status === "err") {
  //     set_s_TableData([]);
  //     message.error(msg);
  //   }
  // }, [call_getShipped.status]);

  // useEffect(() => {
  //   if (call_getShipped.status === 'load') {
  //     set_is_spin(true)
  //   } else {
  //     set_is_spin(false)
  //   }
  // }, [call_getShipped.status])


  return (
    <div className="flex flex-col flex-1 w-full">

      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4" />

      {/*表頭*/}

      <PageHeader title="進退貨彙總表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">

        {/*導出excel*/}
        <Button
          type="primary"
        onClick={() => exportToExcel()}
        >
          導出excel
        </Button>



        <Text>單別：</Text>

        <Select
          className="min-w-[150px]"
          placeholder="請選擇單別"
          name="opcode"
          onChange={(value) => handle_SearchData_Change("opcode", value)}
          value={s_searchData.opcode}
          options={[
            { label: "全部", value: "" },
            { label: "國內採購", value: "2310" },
            { label: "國外採購", value: "2320" },
            { label: "庫房退出", value: "2610" },
            { label: "品保退出", value: "2620" },
          ]}
        />


        {/*廠商選擇*/}
        <Select
          className="min-w-[200px]"
          name="suppID"
          showSearch
          placeholder="請選擇廠商"
          onChange={(value) => handle_SearchData_Change("suppID", value)}
          options={c_supps.options}
        />

        {/*日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button type="primary" onClick={handleClick}>
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};



export default PurchaseSummary