import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { data } = s_drawer;

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 料號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.util.PN")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.PN}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 品名 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.util.pdtNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 規格 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.util.pdtspec")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtspec}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 圖號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS_ori.util.drawno")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.drawno}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 銷貨單位類別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.util.utype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.utype === "unit" ? t("util.util.unit") : t("util.util.opunit")}</Text>
        </Col>
        {/* 銷貨單位 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS_ori.ord.punit")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.punit}</Text>
        </Col>
        {/* 銷貨單價 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS_ori.ord.unitprice")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.unitprice)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 銷貨數量 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS_ori.ord.demandqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.demandqty)}</Text>
        </Col>
        {/* 客戶確認數量 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS_ori.ord.confirmqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.confirmqty)}</Text>
        </Col>
        {/* 退貨數量 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS_ori.ord.returnqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.returnqty)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 單項金額 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.util.itemsum") + t("util.util.excluding_tax")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.itemsum)}</Text>
        </Col>
        {/* 各項稅額 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("util.util.itemsumtax")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.itemsumtax)}</Text>
        </Col>
        {/* 含稅小記 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("util.util.subtotal")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.subtotal)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS_ori.ord.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
