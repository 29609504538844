/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import qs from "qs";
//webSocket close code
const closeCode = (code) => {
  const codes = {
    1000: "1000 CLOSE_NORMAL",
    1001: "1001 CLOSE_GOING_AWAY",
    1002: "1002 CLOSE_PROTOCOL_ERROR",
    1003: "1003 CLOSE_UNSUPPORTED",
    1004: "1004 CLOSE_RETAIN",
    1005: "1005 CLOSE_NO_STATUS",
    1006: "1006 CLOSE_ABNORMAL",
    1007: "1007 UNSUPPORTED_DATA",
    1008: "1008 POLICY_VIOLATION",
    1009: "1009 CLOSE_TOO_LARGE",
    1010: "1010 MISSING_EXTENSION",
    1011: "1011 INTERNAL_ERROR",
    1012: "1012 SERVICE_RESTART",
    1013: "1013 TRY_AGAIN_LATER",
    1014: "1014 CLOSE_RETAIN",
    1015: "1015 TLS_HANDSHAKE",
  };
  let error = codes[code];
  if (error === undefined) error = "0000 UNKNOWN_ERROR 未知錯誤";
  return error;
};

//
const handlerSocketData = (res) => {
  switch (res.status) {
    case 200:
      const data = res.data;
      return { status: true, data: data, msg: "資料獲取成功" };
    case 403:
      return { status: false, data: {}, msg: "403憑證過期返回登入頁面" };
    case 404:
      return {
        status: false,
        data: {},
        msg: "404查無資料，請確認資料是否正確",
      };
    default:
      return {
        status: false,
        data: {},
        msg: "資料格式不符合規範，請再次確認",
      };
  }
};

/**
 * @version 0.0.1
 * @param {string} conn - ws://websocket/socket
 * @return {Array} [data, function changeParams , function changeOpen]
 * @example
 *
 * ```js
 * const [webSocketData, changeParams, changeOpen] = useWebSocket("ws://websocket/socket")
 * changeOpen(true)
 * ```
 *
 */
const useWebSocket = (conn) => {
  //存儲socket連線地方
  const [ws, setWs] = useState();
  //socket連線參數
  const [socketParams, setSocketParams] = useState({});
  //socket 回傳 data
  const [socketData, setSocketData] = useState({
    status: false,
    data: null,
    msg: "",
  });
  //是否開啟Socket
  const [isOpen, setIsOpen] = useState(false);
  //紀錄重新連線次數
  const [reconnect, setReconnect] = useState(0);
  //判斷是否有改變 params 重新連線 webSocket
  const [isUpdateParams, setIsUpdateParams] = useState(false);

  const createSocket = () => {
    // console.log(qs.stringify(socketParams, { arrayFormat: "indices" }));
    const socketConn = new WebSocket(
      `${conn}?${qs.stringify(socketParams, { arrayFormat: "indices" })}`
      // `${conn}${socketParams.length > 0 ? "?" + socketParams : ""}`
    );
    return socketConn;
  };

  const changeParams = (params) => {
    setIsUpdateParams(true);
    setSocketParams(params);
  };

  //判斷conn是否合格
  useEffect(() => {
    if (conn.substring(0, 1) !== "ws" || conn.substring(0, 2) !== "wss") {
      setSocketData({
        ...socketData,
        msg: "webSocket->conn非webSocket連線",
      });
    } else {
      setSocketData({
        ...socketData,
        msg: "webSocket->conn預期外錯誤",
      });
    }
  }, []);

  //isOpen
  useEffect(() => {
    if (isOpen) {
      const socketEx = createSocket();
      setWs(socketEx);
      setReconnect(0);
    } else {
      ws && ws.close();
    }
  }, [isOpen]);

  // 當 webSocket 為開啟狀態 且有新的params
  useEffect(() => {
    if (ws && isUpdateParams && isOpen) {
      console.log("當 webSocket 為開啟狀態 且有新的params", socketParams);
      const socketEx = createSocket();
      setWs(socketEx);
      setReconnect(0);
      setIsUpdateParams(false);
      console.log(`webSocket ${conn} 重新連線-> params 改變`);
    }
  }, [socketParams, isUpdateParams]);

  useEffect(() => {
    if (!isOpen || !ws) {
      return;
    }
    ws.onopen = () => {
      console.log(`webSocket ${conn} 連線開啟`);
      //如果連線成功嘗試次數歸0
      setReconnect(0);
    };
    ws.onmessage = (event) => {
      const res = JSON.parse(event.data);
      setSocketData(handlerSocketData(res));
    };
    ws.onclose = (event) => {
      setSocketData({ status: false, data: {}, msg: "webSocket已關閉" });
      console.log(
        `webSocket ${conn} 連線關閉 , message:${closeCode(event.code)} params:`,
        socketParams
      );
      setWs("");
      //1006表示被意外關閉
      if (event.code === 1006 || event.code === 1005) {
        setReconnect(reconnect + 1);
      }
    };
    return () => {
      ws && ws.close();
    };
  }, [ws]);

  useEffect(() => {
    // =0 代表 連線成功 不用進來嘗試
    if (reconnect === 0) {
      return;
    }

    const waitS = async () => {
      // 重連100次
      if (reconnect < 100 && isOpen === true && socketData.status === false) {
        setTimeout(() => {}, 1500);
        console.log(`webSocket ${conn} 嘗試重新連線 ${reconnect}`);
        const socketEx = createSocket();
        setWs(socketEx);
        setTimeout(function () {}, 3000);
      } else {
        ws && ws.close();
      }
    };

    waitS();
  }, [reconnect]);

  return { socketData, changeParams, setIsOpen };
};
export default useWebSocket;
