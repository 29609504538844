import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, Radio } from "antd";
import { useTranslation } from "react-i18next";
import { addUnit, modifyUnit } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_addUnit = useAPI(addUnit);
  const call_modifyUnit = useAPI(modifyUnit);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addUnit.request(values);
    }
    if (type === "edit") {
      call_modifyUnit.request(values);
    }
  };

  useEffect(() => {
    if (call_addUnit.status === "suc") {
      message.success(call_addUnit.msg);
      set_s_isShowModal(false);
    } else if (call_addUnit.status === "err") {
      message.error(t(`errorCode.${call_addUnit.msg}`));
    }
  }, [call_addUnit.status]);

  useEffect(() => {
    if (call_modifyUnit.status === "suc") {
      message.success(call_modifyUnit.msg);
      set_s_isShowModal(false);
    } else if (call_modifyUnit.status === "err") {
      message.error(t(`errorCode.${call_modifyUnit.msg}`));
    }
  }, [call_modifyUnit.status]);

  useEffect(() => {
    form.setFieldsValue({
      type:"count",
      category:"count"
    });
  }, []);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          {/*計量單位*/}
          <Form.Item
            label={t("ADM.function-params-manage.unit")}
            name="unit"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*單位名稱*/}
          <Form.Item
            label={t("ADM.function-params-manage.unitNM1")}
            name="unitNM1"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*計量型態*/}
          <Form.Item
            label={t("ADM.function-params-manage.type")}
            name="type"
            rules={[{ required: true }]}
            shouldUpdate
          >
            <Select
             disabled
              allowClear
              // disabled={Object.keys(s_editData).length > 0}
              onChange={() => form.setFieldsValue({ category: null })}
              options={[
                { label: t("ADM.function-params-manage.countType"), value: "count" },
                { label: t("ADM.function-params-manage.quantitative"), value: "measure" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*計量類別*/}
          <Form.Item shouldUpdate>
            {() => {
              const formData = form.getFieldsValue();
              const options1 = [
                {
                  label: t("ADM.function-params-manage.count"),
                  value: "count",
                },
              ];
              const options2 = [
                {
                  label: t("ADM.function-params-manage.weight"),
                  value: "weight",
                },
                {
                  label: t("ADM.function-params-manage.length"),
                  value: "length",
                },
                {
                  label: t("ADM.function-params-manage.capacity"),
                  value: "capacity",
                },
                {
                  label: t("ADM.function-params-manage.area"),
                  value: "area",
                },
                {
                  label: t("ADM.function-params-manage.volume"),
                  value: "volume",
                },
              ];
              return (
                <Form.Item
                  label={t("ADM.function-params-manage.category")}
                  name="category"
                  rules={[{ required: true }]}
                >
                  <Select
                  disabled
                    allowClear
                    // disabled={Object.keys(s_editData).length > 0}
                    options={
                      formData.type === "count" //計數型
                        ? options1
                        : formData.type ===  "measure"//"計量型"
                        ? options2
                        : []
                    }
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*是否有效*/}
          <Form.Item name="isvalid" label={t("util.isvalid")}>
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
