import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Row,
  Space,
  Typography,
  message,
  theme,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getAllWhInv, getDetail } from "@/service/apis/WMS/StockSearch";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
import styled from "styled-components";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { useToken } = theme;
const { Title } = Typography;
const { RangePicker } = DatePicker;

const CustomDescriptions = styled(Descriptions)`
  .ant-descriptions-item-label {
    background-color: ${({ token }) => token.colorTableHeader} !important;
    text-align: center;
    font-weight: bold;
  }
  .ant-descriptions-item-content {
    text-align: center;
  }
  .ant-descriptions-header {
    margin: 6px 0;
  }
`;

// 分倉明細
const WhDetail = ({ s_editData }) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const [s_details, set_s_details] = useState([]);

  const call_getAllWhInv = useAPI(getAllWhInv);

  useEffect(() => {
    call_getAllWhInv.request(s_editData);
  }, []);
  console.log("s_details = ", s_details);
  useEffect(() => {
    if (call_getAllWhInv.status === "suc") {
      const tmp = call_getAllWhInv.data?.reduce(
        (data, current) => {
          let total =
              current.tqty + current.planin + current.scanin - current.planout - current.scanout,
            onhandqty = current.tqty - current.lockqty;
          data[0].planin += current.planin;
          data[0].tqty += current.tqty;
          data[0].lockqty += current.lockqty;
          data[0].onhandqty += onhandqty;
          data[0].scanin += current.scanin;
          data[0].planout += current.planout;
          data[0].scanout += current.scanout;
          data[0].batchbom += current.batchbom;
          data[0].total += total;
          data.push({ ...current, total: total, onhandqty: onhandqty });
          return data;
        },
        [
          {
            whID: "全庫",
            tqty: 0,
            onhandqty: 0,
            planin: 0,
            scanin: 0,
            planout: 0,
            scanout: 0,
            lockqty: 0,
            total: 0,
          },
        ]
      );
      set_s_details(tmp);
      message.success(call_getAllWhInv.msg);
    } else if (call_getAllWhInv.status === "err") {
      message.error(call_getAllWhInv.msg);
    }
  }, [call_getAllWhInv.status]);

  console.log("s_details = ", s_details);

  return (
    <Row gutter={[24, 24]}>
      {s_details.map((x, i) => {
        return (
          <Col span={24} key={x.whID}>
            <CustomDescriptions
              title={i === 0 ? "总数量" : x.whNM}
              // title={i === 0 ? "总数量" : x.whID + "-" + x.whNM}
              layout="vertical"
              bordered
              column={10}
              token={token}
            >
              <Descriptions.Item label={t("WMS.stock-search.tqty")} style={{ color: "#1e6da1" }}>
                {formatAmount(x.tqty)}
              </Descriptions.Item>
              {/* <Descriptions.Item label={t("WMS.stock-search.lockqty")} style={{ color: "#c93939" }}>
                {formatAmount(x.lockqty)}
              </Descriptions.Item> */}
              <Descriptions.Item label={t("WMS.stock-search.onhandqty")} style={{ color: "blue" }}>
                {formatAmount(call_getAllWhInv?.data?.[0]?.batchbom)}
              </Descriptions.Item>
              {/* <Descriptions.Item
                label={t("WMS.stock-search.planin")}
                contentStyle={{ color: "#7cc1ee" }}
              >
                +&nbsp;&nbsp;{formatAmount(x.planin)}
              </Descriptions.Item> */}
              {/* <Descriptions.Item
                label={t("WMS.stock-search.scanin")}
                contentStyle={{ color: "#7cc1ee" }}
              >
                +&nbsp;&nbsp;{formatAmount(x.scanin)}
              </Descriptions.Item> */}
              {/* <Descriptions.Item label={t("WMS.stock-search.planout")} style={{ color: "#f72cd5" }}>
                -&nbsp;&nbsp;{formatAmount(x.planout)}
              </Descriptions.Item> */}
              {/* <Descriptions.Item label={t("WMS.stock-search.scanout")} style={{ color: "#f72cd5" }}>
                -&nbsp;&nbsp;{formatAmount(x.scanout)}
              </Descriptions.Item> */}
              <Descriptions.Item label="庫房" style={{ color: "#077803" }}>
                {/* {formatAmount(x.total)} */}
              </Descriptions.Item>
              <Descriptions.Item label={t("WMS.stock-search.total")} style={{ color: "#077803" }}>
                {formatAmount(x.total)}
              </Descriptions.Item>
            </CustomDescriptions>
          </Col>
        );
      })}
    </Row>
  );
};

// 異動明細
const ChangeDetail = ({ s_editData }) => {
  const { token } = useToken();
  const { t } = useTranslation();

  const [s_total, set_s_total] = useState({
    in: 0,
    out: 0,
  });
  const [s_tableData, set_s_tableData] = useState([]);

  const [s_searchValue, set_s_searchValue] = useState({
    startT: dayjs().startOf("month"),
    endT: dayjs(),
  });

  const call_getDetail = useAPI(getDetail);

  const sortTable = (keyWord) => {
    let { total, newData } = {};

    const processApiData = (data) => {
      let total = {
        in: 0,
        out: 0,
      };

      const newData = data.map((x) => {
        x.lifeFT = dayjs(x.lifeFT).format("YYYY-MM-DD HH:mm");
        // 判斷借或貸
        let loan = 0, //貸
          borrow = 0, //借
          opnm = x.opcode[0] + x.opcode[1];
        // opnm === "41" ? (borrow = x.tqty) : (loan = x.tqty);
        if (opnm === "41") {
          borrow = x.tqty;
        } else {
          if (x.opcode === "4010" || x.opcode === "4020" || x.opcode === "4030") {
            x.tqty > 0 ? (borrow = x.tqty) : (loan = x.tqty);
          } else {
            loan = x.tqty * -1;
          }
        }
        total.in += borrow;
        total.out += loan;
        return { ...x, key: x.opUUID, loan: loan, borrow: borrow };
      });

      return {
        total,
        newData,
      };
    };

    switch (keyWord) {
      case "time":
        ({ total, newData } = processApiData(call_getDetail.data));
        break;
      case "T":
        ({ total, newData } = processApiData(call_getDetail.data.filter((x) => x.lifeF === "T")));
        break;
      case "!T":
        ({ total, newData } = processApiData(call_getDetail.data.filter((x) => x.lifeF !== "T")));
        break;
      default:
        ({ total, newData } = processApiData(call_getDetail.data));
        break;
    }

    set_s_total({ ...total });
    set_s_tableData(newData);
  };

  const tableColumns = [
    {
      dataIndex: "lifeFT",
      title: t("WMS.util.updtT"),
      render: (text) => dayjs(text).format("YY-MM-DD"),
    },
    {
      title: t("WMS.util.opcode"),
      dataIndex: "opcode",
      render: (text) => t(`WMS.util.opcode_${text}`),
    },
    {
      title: t("WMS.util.jobN"),
      dataIndex: "jobN",
    },
    {
      title: "期初数",
      dataIndex: "previnv",
      align: "right",
      width: "10%",
      // render: (text) => formatAmount(text),
    },
    {
      title: t("WMS.stock-search.borrow"),
      dataIndex: "borrow",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("WMS.stock-search.loan"),
      dataIndex: "loan",
      align: "right",
      width: "10%",
      // render: (text) => formatAmount(text),
    },
    {
      title: "期末数",
      dataIndex: "postinv",
      align: "right",
      width: "10%",
      // render: (text) => formatAmount(text),
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: "规格",
      dataIndex: "pdtspec",
      align: "center",
    },
    {
      title: "品牌",
      dataIndex: "mtype",
      align: "center",
    },
    {
      title: "存放位置",
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      render: (text) => t(`WMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
  ];

  useEffect(() => {
    call_getDetail.request({
      ...s_editData,
      startT: dayjs(s_searchValue.startT).format("YYYY-MM-DD"),
      endT: dayjs(s_searchValue.endT).format("YYYY-MM-DD"),
    });
  }, [s_searchValue]);

  useEffect(() => {
    if (call_getDetail.status === "suc") {
      sortTable();
      message.success(call_getDetail.msg);
    } else if (call_getDetail.status === "err") {
      message.error(call_getDetail.msg);
      set_s_tableData([]);
    }
  }, [call_getDetail.status]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Space size={20}>
          <Title level={4} style={{ color: token.colorPrimaryActive }}>
            {t("WMS.stock-search.totalIn")}：{formatAmount(s_total.in)}
          </Title>
          <Title level={4} style={{ color: token.colorErrorActive }}>
            {t("WMS.stock-search.totalOut")}：{formatAmount(s_total.out)}
          </Title>
        </Space>
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Space>
          <Button type="primary" onClick={() => sortTable("time")}>
            {t("WMS.stock-search.timeSeries")}
          </Button>
          <Button type="primary" onClick={() => sortTable("T")}>
            {t("WMS.stock-search.posted")}
          </Button>
          <Button type="primary" onClick={() => sortTable("!T")}>
            {t("WMS.stock-search.inTransit")}
          </Button>
          <RangePicker
            value={[s_searchValue.startT, s_searchValue.endT]}
            onChange={(val) =>
              set_s_searchValue((prev) => ({
                ...prev,
                startT: val[0],
                endT: val[1],
              }))
            }
          />
        </Space>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns.filter((x) => !x.hidden)}
          dataSource={s_tableData}
          loading={call_getDetail.status === "load"}
        />
      </Col>
    </Row>
  );
};

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [s_chooseTab, set_s_chooseTab] = useState("detail");

  const tabsData = [
    {
      key: "detail",
      label: t("WMS.stock-search.whDetail"),
      children: <WhDetail s_editData={s_editData} />,
    },
    {
      key: "stockChange",
      label: t("WMS.stock-search.changeDetail"),
      children: <ChangeDetail s_editData={s_editData} />,
    },
  ];

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <CustomTabs
          onChange={(activeKey) => set_s_chooseTab(activeKey)}
          activeKey={s_chooseTab}
          items={tabsData}
        />
      </Col>
      <Col span={24} className="flex justify-end">
        <Space>
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
        </Space>
      </Col>
    </Row>
  );
}
