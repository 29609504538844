import React, { useContext, useEffect } from "react";
import { Modal, Space, Grid, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// util
import { formatAmount } from "@/util/format";
// component
import AddcModal from "../Modal/AddcModal";
// context
import { MainPageContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { deleteOrdADD } from "@/service/apis/OMS/Ord";

const AddcContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled, s_ordTb, set_s_ordTb, s_Adds, call_getOrdADD } =
    useContext(MainPageContext);
  const screens = Grid.useBreakpoint();
  const { t } = useTranslation();
  // useAPI 刪除預付作業
  const call_deleteOrdADD = useAPI(deleteOrdADD);
  // 刪除
  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("OMS_ori.ord.addc") + t("util.util.del_confirm"), // 預付作業刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS_ori.ord.addc_delete_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_deleteOrdADD.request(rowData);
      },
    });
  };

  // 第一次渲染取得條款註記
  useEffect(() => {
    call_getOrdADD.request({
      "addtype": "addc",
      "opUUID": hd_values.opUUID,
    });
  }, []);

  // useAPI 取得條款註記
  useEffect(() => {
    const { data, msg, status } = call_getOrdADD;
    if (status === "suc") {
      // message.success(msg);
      set_s_ordTb((prev) => ({
        ...prev,
        addc: data.addc ?? [],
      }));
    }

    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getOrdADD.status]);

  // useAPI 刪除條款註記
  useEffect(() => {
    const { status, msg } = call_deleteOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "addc",
        "opUUID": hd_values.opUUID,
      });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_deleteOrdADD.status]);

  const tableColumns = [
    {
      title: t("util.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS_ori.ord.statement"), // 項目
      dataIndex: "statement",
      align: "center",
    },
    {
      title: t("OMS_ori.ord.amount"), // 金額
      dataIndex: "amount",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "render",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_ordTb.addc?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <CustomModal
        width={screens.xxl ? "20%" : "40%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={Object.keys(s_tbModal.data).length > 0 ? "編輯編輯預付作業" : "新增編輯預付作業"}
        title={
          Object.keys(s_tbModal.data).length > 0
            ? t("util.util.edit") + t("OMS_ori.ord.addc")
            : t("util.util.add") + t("OMS_ori.ord.addc")
        }
      >
        {s_tbModal.show && (
          <AddcModal
            s_Adds={s_Adds}
            hd_values={hd_values}
            s_disabled={s_disabled}
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
          />
        )}
      </CustomModal>
    </>
  );
};

export default AddcContainer;
