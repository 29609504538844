/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, theme } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getStockTB } from "@/service/apis/PMS/GoStock";
// util
import { formatAmount } from "@/util/format";
const { useToken } = theme;
const TB = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  // useAPI 取得表身
  const call_getStockTB = useAPI(getStockTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getStockTB.request({
      opUUID: lightData.opUUID,
      opcode: lightData.opcode,
    });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getStockTB;
    if (status === "suc") {
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getStockTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("PMS.util.idmtype"), //"過帳型態",
      dataIndex: "idmtype",
      align: "center",
      width: "10%",
      render: (text, rowData, index) => {
        const map = {
          S: t("util.idmtype_s"), //"人工計數",
          D: t("util.idmtype_d"), //"條碼計數",
          M: t("util.idmtype_m"), //"混合計數",
        };

        return map[text];
      },
    },
    {
      title: t("PMS.util.N24"), //"進料單號",
      dataIndex: "N24",
      align: "center",
      width: "10%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
    },
    {
      title: t("PMS.util.N23"), //"採購單號",
      dataIndex: "N23",
      align: "center",
      width: "10%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
    },
    {
      title: t("PMS.util.N22"), //"請購單號",
      dataIndex: "N22",
      align: "center",
      width: "10%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
    },
    {
      title: t("PMS.gostock.planqty"), //"移轉數量",
      dataIndex: "planqty",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.lifeF"), //"歷程",
      dataIndex: "lifeF",
      align: "center",
      width: "10%",
      render: (text) => {
        const map = {
          0: t("PMS.gostock.lifeF_0"), //"發佈",
          T: t("PMS.gostock.lifeF_T"), //"結案",
        };
        return map[text];
      },
    },
    {
      title: t("PMS.util.lifeFT"), //"歷程時間",
      dataIndex: "lifeFT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
    </div>
  );
};

export default TB;
