import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, InputNumber } from "antd";
import { addWh, modifyWh } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
export default function ModalConintaer({ s_editData, set_s_isShowModal, arrMap }) {
  const [form] = Form.useForm();

  const call_addWh = useAPI(addWh);
  const call_modifyWh = useAPI(modifyWh);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.mgmtcat && values.mgmtcat.length > 0) {
      values.mgmtcat = values.mgmtcat?.join("|") ?? "";
    }

    if (values.pdtclass && values.pdtclass.length > 0) {
      values.pdtclass = values.pdtclass?.join("|") ?? "";
    }

    if (type === "create") {
      call_addWh.request(values);
    }
    if (type === "edit") {
      call_modifyWh.request(values);
    }
  };

  useEffect(() => {
    if (call_addWh.status === "suc") {
      message.success(call_addWh.msg);
      set_s_isShowModal(false);
    } else if (call_addWh.status === "err") {
      message.error(t(`errorCode.${call_addWh.msg}`));
    }
  }, [call_addWh.status]);

  useEffect(() => {
    if (call_modifyWh.status === "suc") {
      message.success(call_modifyWh.msg);
      set_s_isShowModal(false);
    } else if (call_modifyWh.status === "err") {
      message.error(t(`errorCode.${call_modifyWh.msg}`));
    }
  }, [call_modifyWh.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        mgmtcat: ["All"],
        pdtclass: ["All"],
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          {/* "倉別ID" */}
          <Form.Item
            label={t("ADM.warehouse-manage.whID")}
            name="whID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "倉別名稱" */}
          <Form.Item
            label={t("ADM.warehouse-manage.whNM")}
            name="whNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} />
        <Col span={8}>
          {/* 料階 */}
          <Form.Item label={t("ADM.warehouse-manage.pdtclass")} name="pdtclass">
            <Select allowClear mode="tags" options={arrMap.pdtClassMap} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "車間" */}
          <Form.Item label={t("ADM.warehouse-manage.ps")} name="psID">
            <Select allowClear options={arrMap.s_getPsOptions} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "庫房授權" */}
          <Form.Item label={t("ADM.warehouse-manage.mgmtcat")} name="mgmtcat">
            <Select allowClear mode="tags" options={arrMap.s_mgmtcat71Options} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
