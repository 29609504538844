/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Select, Typography, Spin, message, Divider } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { getReqHD } from "@/service/apis/PMS/Req";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../../index";

const { Text } = Typography;

const DetailModal = ({ s_detailSearch, set_s_detailSearch, call_getReqHDAd, s_selectOptions }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // useAPI 取得全部請購單表頭資料
  const call_getReqHD = useAPI(getReqHD);
  // 廠商資料、人員資料
  const { c_supps, c_currencys } = useContext(ERPContext);
  // page 的context
  const { c_state } = useContext(DataContext);
  // const { taxtypes, detailConfig } = c_state;
  // 全部請購單單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      sampleID: values.sampleID?.join("|") ?? "",
      createID: values.createID?.join("|") ?? "",
      suppID: values.suppID?.join("|") ?? "",
      lifeF: values.lifeF?.join("|") ?? "",
      startDate: values.createT ? dayjs(values.createT[0]).format("YYYY-MM-DD") : null,
      endDate: values.createT ? dayjs(values.createT[1]).format("YYYY-MM-DD") : null,
      sampleStart: values.sampleT ? dayjs(values.sampleT[0]).format("YYYY-MM-DD") : null,
      sampleEnd: values.sampleT ? dayjs(values.sampleT[1]).format("YYYY-MM-DD") : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getReqHDAd.request(new_data);
    // 更新資料並關閉跳顯
    set_s_detailSearch({ show: false, data: values });
  };

  // 第一次渲染取得全部請購單做下拉選單、賦值初始值
  useEffect(() => {
    call_getReqHD.request();
    const { data } = s_detailSearch;
    // console.log("data = ", data);
    if (Object.keys(data).length > 0) {
      form.setFieldsValue({
        ...data,
        createT: data.createT ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])] : null,
        sampleT: data.sampleT ? [dayjs(data.sampleT?.[0]), dayjs(data.sampleT?.[1])] : null,
      });
    }
  }, []);

  // useAPI 取得全部請購單資料
  useEffect(() => {
    const { data, msg, status } = call_getReqHD;

    if (status === "suc") {
      const allNs = data?.map((reqItem) => ({ lable: reqItem.N, value: reqItem.N })) ?? [];
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getReqHD.status]);

  return (
    <Spin size="large" spinning={call_getReqHD.status === "load"}>
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.opcode")}</Text>} name="opcode">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.opcode_placeholder")}
                options={[
                  { value: "2210", label: t("PMS.util.opcode_2210") },
                  { value: "2220", label: t("PMS.util.opcode_2220") },
                  { value: "2230", label: t("PMS.util.opcode_2230") },
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.N")}</Text>} name="N">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.N")}
                options={s_allNs}
              />
            </Form.Item>
          </Col>
          {/* 幣別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.currencytype")}</Text>} name="currencytype">
              <Select
                mode="multiple"
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.currencytype")}
                allowClear
                options={c_currencys.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商簡稱 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.suppalias")}</Text>} name="suppID">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.suppID_placeholder")}
                options={c_supps.options}
              />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.lifeF")}
                options={s_selectOptions.lifeF}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}></Row>

        <Divider />

        <Row gutter={[12, 0]}>
          <Col span={24} className="flex justify-end gap-1">
            <ConfirmButton htmlType="submit" type="primary" />
            <CancelButton
              onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
            />
            {/* <Button onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}>
              {t("util.cancel")}
            </Button> */}

            {/* <Button type="primary" htmlType="submit">
            {t("PMS.util.submit")}
            </Button> */}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DetailModal;
