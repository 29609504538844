import {
  ClearButton,
  CloseButton,
  CreateButton,
  DeleteButton,
  DetailButton,
  EditButton,
  ExcelButton,
  SeeMoreButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Divider, Drawer, Grid, Input, Modal, Space, Typography, message } from "antd";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteProHD, getProHD } from "@/service/apis/PMS/Pro";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { DataContext } from "../index";
import DetailModal from "./Modal/DetailModal";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Light from "@/components/ERP/Light";
import PageHeader from "@/components/PageHeader";
import { TourButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
// util function
import exportExcel from "@/util/exportExcel";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const HD = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getProHD = useAPI(getProHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getProHdAd = useAPI(getProHD);
  // useAPI 刪除表頭資料
  const call_deleteProHD = useAPI(deleteProHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
  // 廠商資料、人員資料
  const { c_supps, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    suppID: [],
    driID: [],
    lifeF: [
      { value: "0", label: t("PMS.pro.lifeF_0") },
      { value: "2", label: t("PMS.pro.lifeF_2") },
      { value: "7", label: t("PMS.pro.lifeF_7") },
      { value: "T", label: t("PMS.pro.lifeF_T") },
    ],
  });
  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  const [s_detailSearch, set_s_detailSearch] = useState({
    show: false,
    data: {},
  });
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "2310", label: t("PMS.util.opcode_2310") }, // 國內採購
    { key: "2320", label: t("PMS.util.opcode_2320") }, // 國外採購
    { key: "2330", label: t("PMS.util.opcode_2330") }, // 委外加工
    { key: "detailSearch", label: t("PMS.util.advanceSearch") }, // 進階查詢
  ];

  const placeholder_map = {
    N: t("PMS.util.placeholder_input") + t("PMS.util.N"), // 請輸入單號
    suppID: t("PMS.util.suppID_placeholder"), // 請選擇廠商
    lifeF: t("PMS.util.placeholder_select") + t("PMS.util.lifeF"), // 請選擇歷程
    driID: t("PMS.util.placeholder_select") + t("PMS.pro.driID"), // 請選擇請購人員
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    console.log("activeKey = ", activeKey);
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getProHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getProHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ lightData: {} });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp, lightData: {} });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getProHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 強制結令
  const closeData = (event, rowData) => {
    Modal.confirm({
      title: t("PMS.util.pro") + t("PMS.util.table_close") + t("util.ok"), //"採購單強制結令確認",
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("PMS.pro.hd_close_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        // call 更新表頭的API
        alert("wait");
      },
    });
    // 阻止冒泡
    event.stopPropagation();
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T" || rowData.count > 0) {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("PMS.util.pro") + t("PMS.util.delete_confirm"), // 採購單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的採購訂單資料無誤，再進行刪除",
      content: t("PMS.pro.del_hd_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteProHD.request(rowData),
    });
    event.stopPropagation();
  };

  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getProHD.request({
        ...searchConfig,
        opcode: tabKey,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getProHD.request({
      ...searchConfig,
      opcode: opcode,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
    // 詳細搜尋 call 另一條 API
    // if (tabKey !== "detailSearch") {
    //   call_getProHD.request({
    //     ...searchConfig,
    //     opcode: tabKey,
    //     [searchConfig.searchFlag]: searchConfig.searchValue,
    //   });
    // }
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      suppID: c_supps.options,
      driID: c_staffs.options,
    }));
  }, [c_supps, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getProHD;
    // console.log("data = ", data);
    if (status === "suc") {
      // console.log("call_getProHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newPro_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ pro_Ns: newPro_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getProHdAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newPro_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ pro_Ns: newPro_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProHdAd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deleteProHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getProHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteProHD.status]);

  const tableColumns = [
    // {
    //   // title: "單別",
    //   title: t("PMS.util.opcode"),
    //   dataIndex: "opcode",
    //   align: "center",
    //   isExcel: true,
    //   width: screens.xxl ? "5.5%" : "8%",
    //   render: (text) => t(`PMS.util.opcode_${text}`),
    // },
    {
      // title: "單號",
      title: t("PMS.util.N"),
      dataIndex: "N",
      isExcel: true,
      align: "center",
      width: screens.xxl ? "8%" : "11%",
      render: (text, rowData, _) => (
        <div className="flex-center">
          <span className="mr-1">{text}</span>
          {rowData.linkType !== "" && <Light linkType={rowData.linkType} />}
        </div>
      ),
    },
    {
      // title: "交易幣別",
      title: t("PMS.util.currencytype"),
      dataIndex: "currencytype",
      align: "center",
      width: screens.xxl ? "7%" : "8.3%",
      // render: (text) => {
      //   return (
      //     s_currencys.find((item) => item.currencyitem === text)?.itemNM ??
      //     "本幣"
      //   );
      // },
    },
    // {
    //   // title: "匯率",
    //   title: t("PMS.util.exchangerate"),
    //   dataIndex: "exchangerate",
    //   align: "center",
    //   width: screens.xxl ? "4%" : "4%",
    // },
    // {
    //   // title: "稅別",
    //   title: t("PMS.util.taxtype"),
    //   dataIndex: "taxtype",
    //   align: "center",
    //   width: screens.xxl ? "7.5%" : "8.3%",
    // },
    {
      // title: "稅率",
      title: t("PMS.util.taxrate"),
      dataIndex: "taxrate",
      align: "center",
      width: screens.xxl ? "4%" : "5%",
      render: (text, rowData, index) => {
        return `${text}%`;
      },
    },
    {
      // title: "廠商簡稱",
      title: t("PMS.util.suppalias"),
      dataIndex: "suppalias",
      align: "center",
    },
    {
      // title: "負責採購",
      title: t("PMS.pro.driID"),
      dataIndex: "driID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
      // render: (text) => {
      //   return staffs_obj[text];
      //   // return s_staffs.find((item) => item.staffID === text)?.peopleNM ?? "";
      // },
    },
    {
      // title: "需求日期",
      title: t("PMS.req.demandDate"),
      dataIndex: "demandDate",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    // {
    //   // title: "預計交貨日",
    //   title: t("PMS.pro.endEDT"),
    //   dataIndex: "endEDT",
    //   align: "center",
    //   width: screens.xxl ? "9.5%" : "9.5%",
    //   render: (text) => dayjs(text).format("YYYY-MM-DD"),
    // },
    {
      // title: "歷程",
      title: t("PMS.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => t(`PMS.pro.lifeF_${text}`),
    },
    {
      // title: "歷程時間",
      title: t("PMS.util.lifeFT"),
      dataIndex: "lifeFT",
      align: "center",
      responsive: ["xxl"],
      width: screens.xxl ? "10%" : "7%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "9%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 強制結令 */}
          <CloseButton disabled={rowData.lifeF === "T"} onClick={(e) => closeData(e, rowData)} />
          {/* 刪除表頭資料 */}
          <DeleteButton
            disabled={rowData.lifeF === "T" || rowData.instockCount > 0}
            onClick={(e) => removeData(e, rowData)}
          />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  // 匯出Excel的表頭(順序依照物件排列順序)
  const ExcelColumns = {
    opcode: "單別",
    N: "單號",
    currencytype: "幣別",
    exchangerate: "匯率",
    exchangeD: "匯率日期",
    taxtype: "稅別",
    taxrate: "稅率",
    itemtotal: "貨品金額",
    total: "總金額",
    payment: "付款方式",
    trade: "交易條件",
    suppID: "客戶ID",
    suppalias: "客戶簡稱",
    suppNM: "客戶名稱",
    driID: "採購人員",
    dealaddr: "廠商地址",
    EDTstart: "交貨日期(起)",
    EDTend: "交貨日期(迄)",
    lifeF: "歷程",
    lifeFT: "歷程時間",
    approveT: "核准日期",
    signT: "簽回日期",
    confirmID: "覆核人員",
    confirmT: "覆核時間",
    createID: "建立人員",
    createT: "建立時間",
  };
  // 轉換日期格式、只留下要導出的資料
  const ExcelSourec = s_tableData.map((item) => {
    let obj = {};
    for (let prop in ExcelColumns) {
      obj[prop] = item[prop];
    }
    obj.lifeFT = dayjs(item.lifeFT).format("YYYY-MM-DD HH:MM");
    obj.approveT = dayjs(item.approveT).format("YYYY-MM-DD HH:MM");
    obj.signT = dayjs(item.signT).format("YYYY-MM-DD HH:MM");
    obj.confirmT = dayjs(item.confirmT).format("YYYY-MM-DD HH:MM");
    obj.createT = dayjs(item.createT).format("YYYY-MM-DD HH:MM");
    return obj;
  });

  return (
    <div className="flex-1">
      {/* 採購作業 */}
      <PageHeader title={t("PMS.pro.pageHeader")} extra={<TourButton />} />
      <Space className="flex justify-end">
        <CustomRangePicker
          disabled={tabKey === "detailSearch"}
          value={[searchConfig?.startDate, searchConfig?.endDate]}
          onCalendarChange={(dates, _, __) => {
            updateState({
              searchConfig: {
                ...searchConfig,
                startDate: dates[0],
                endDate: dates[1],
              },
            });
          }}
        />

        <Input.Group compact>
          <CustomSelect
            disabled={tabKey === "detailSearch"}
            value={searchConfig.searchFlag}
            placeholder={t("PMS.util.searchField")}
            onChange={(value) => handleChange("searchFlag", value)}
            options={[
              { value: "N", label: t("PMS.util.N") }, // 單號
              { value: "suppID", label: t("PMS.util.suppalias") }, // 廠商ID
              { value: "lifeF", label: t("PMS.util.lifeF") }, // 歷程
              { value: "driID", label: t("PMS.req.driID") }, // 負責採購
            ]}
          />

          {searchConfig.searchFlag === "lifeF" ||
          searchConfig.searchFlag === "suppID" ||
          searchConfig.searchFlag === "driID" ? (
            <CustomSelect
              showSearch
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(value) => handleChange("searchValue", value)}
              // 根據searchFlag判斷現在是哪個下拉選單
              options={s_selectOptions[searchConfig.searchFlag]}
            />
          ) : (
            <Input
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          )}
          <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
        </Input.Group>

        {/* 清除搜詢紀錄 */}
        <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
        {/* 新建 */}
        <CreateButton
          disabled={tabKey === "detailSearch"}
          onClick={() => updateState({ mainPage: { show: true, data: {} } })}
        />
      </Space>

      <Divider className="my-2" />
      {/* <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          
        }
      /> */}

      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton
            onClick={() =>
              exportExcel(
                tableColumns,
                s_tableData,
                `${t("PMS.pro.pageHeader")}_${dayjs().format("YYYY-MM-DD")}.xlsx`
              )
            }
            disabled={s_tableData.length === 0}
          />
          <DetailButton onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: true }))} />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getProHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: () =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => editData(event, record),
          };
        }}
      />
      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 採購訂單主檔 */}
            {t("PMS.pro.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("PMS.util.setAdvanced")}
        open={s_detailSearch.show}
        onCancel={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
      >
        {s_detailSearch.show && (
          <DetailModal
            s_detailSearch={s_detailSearch}
            set_s_detailSearch={set_s_detailSearch}
            call_getProHdAd={call_getProHdAd}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default HD;
