import React from "react";
import { useState, useEffect } from "react";
import { message, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// API
import useAPI from "@/hooks/useAPI";
import { getOrdTB } from "@/service/apis/OMS/Ord";
// util
import { formatAmount } from "@/util/format";
const { Title } = Typography;
const Income = ({ s_incomeDrawer, set_s_incomeDrawer }) => {
  const { t } = useTranslation();
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_income, set_s_income] = useState(0);
  // useAPI 取得表身
  const call_getOrdTB = useAPI(getOrdTB);

  // 取得表身資料
  useEffect(() => {
    call_getOrdTB.request({ opUUID: s_incomeDrawer.data.opUUID });
  }, []);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdTB;
    // console.log("data = ", data);
    if (status === "suc") {
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID })) ?? [];
      let income = 0;
      tmpData.forEach((item) => (income = income + item.itemsum));
      set_s_income(income);
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getOrdTB.status]);

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "6%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.note"), // 樣品名稱
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 檢驗項目
      dataIndex: "pdtNM",
      align: "center",
      // width: "30%",
      sorter: (a, b) => a.pdtNM.localeCompare(b.pdtNM),
    },

    {
      title: t("OMS.ord.unitprice"), // 檢測費用
      dataIndex: "unitprice",
      align: "right",
      width: "10%",

      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.ord.demandqty"), // 銷貨數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",

      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      className: "bg-[#e1edf9] text-[#0081FF] font-extrabold",
      render: (text) => formatAmount(text),
    },
  ];

  return (
    <div>
      <CustomTable
        type="dark-purple"
        rowNumber={15}
        columns={tableColumns}
        dataSource={s_tableData}
      />
      <div className="flex justify-end mt-3">
        <Title className="m-0" level={3}>
          收益總計:
          <span className="ml-2 text-[#0081FF]">{formatAmount(s_income)}</span>
        </Title>
      </div>
    </div>
  );
};

export default Income;
