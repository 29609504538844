import { ClearButton, DetailButton, EditButton, ExcelButton } from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Grid, Input, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
// context
import { DataContext } from "../index";
// component
import DetailModal from "./Modals/DetailModal";
// util component
import PageHeader from "@/components/PageHeader";
import { TourButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
// util
import { formatAmount } from "@/util/format";
import { getStockHD } from "@/service/apis/PMS/GoStock";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HD = (props) => {
  const { t } = useTranslation();
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭
  const call_getStockHD = useAPI(getStockHD);
  // useAPI 詳細搜尋取得表頭
  const call_getStockHDAd = useAPI(getStockHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();
  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG, detailSearch } = c_state;
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "4120", label: t("PMS.util.opcode_4120") }, // 驗收入庫單
    { key: "4230", label: t("PMS.util.opcode_4230") }, // 驗退退出單
    { key: "detailSearch", label: t("PMS.util.advanceSearch") }, // 進階查詢
  ];

  const placeholder_map = {
    jobN: t("PMS.util.placeholder_input") + t("PMS.gostock.jobN"), //"請輸入來源單號",
    N23: t("PMS.util.placeholder_input") + t("PMS.util.N23"), //"請輸入採購單號",
    N22: t("PMS.util.placeholder_input") + t("PMS.util.N22"), //"請輸入請購單號",
    batchno: t("PMS.gostock.batchno_placeholder"), //"請輸入批號",
  };

  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  // const [s_detailSearch, set_s_detailSearch] = useState({
  //   show: false,
  //   data: {},
  // });

  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getStockHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getStockHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getStockHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };

  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const TabKey = searchParams.get("TabKey");
    const jobN = searchParams.get("jobN");
    if (TabKey === null) {
      console.log("tabKey = ", tabKey);
      if (tabKey === "detailSearch") {
        const result = {};
        for (const [key, value] of Object.entries(detailSearch.data)) {
          if (value) {
            result[key] = Array.isArray(value) ? value.join("|") : value;
          }
        }
        call_getStockHDAd.request(result);
      } else {
        call_getStockHD.request({
          ...searchConfig,
          opcode: tabKey,
          [searchConfig.searchFlag]: searchConfig.searchValue,
        });
      }
    } else {
      call_getStockHD.request({
        ...searchConfig,
        opcode: "4120|4230",
        tabKey: "detailSearch",
        jobN: jobN,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      updateState({ tabKey: "detailSearch" });
    }
    // 詳細搜尋 call 另一條 API
    // if (tabKey !== "detailSearch") {
    //   call_getStockHD.request({
    //     ...searchConfig,
    //     opcode: tabKey,
    //     [searchConfig.searchFlag]: searchConfig.searchValue,
    //   });
    // }
  }, [tabKey]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getStockHD;
    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.opUUID })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newGostock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ gostock_Ns: newGostock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getStockHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getStockHDAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.opUUID })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newGostock_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ gostock_Ns: newGostock_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getStockHDAd.status]);

  // 表格欄位
  const tableColumns = [
    {
      // title: "單別",
      title: t("PMS.util.opcode"),
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
      render: (text) => t(`PMS.util.opcode_${text}`),
    },
    {
      // title: "單號",
      title: t("PMS.util.N"),
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8%" : "8%",
    },
    {
      // title: "廠商ID",
      title: t("PMS.util.suppID"),
      dataIndex: "suppID",
      align: "center",
      width: screens.xxl ? "9%" : "8%",
    },
    {
      // title: "廠商簡稱",
      title: t("PMS.util.suppNM"),
      dataIndex: "suppalias",
      align: "center",
      // width: screens.xxl ? '9%' : '9%',
    },
    {
      // title: "移轉數量",
      title: t("PMS.gostock.total"),
      dataIndex: "total",
      align: "right",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "批號",
      title: t("PMS.util.batchno"),
      dataIndex: "batchno",
      align: "center",
      width: screens.xxl ? "12%" : "12%",
    },
    {
      // title: "批號日期",
      title: t("PMS.util.batchdate"),
      dataIndex: "batchdate",
      align: "center",
      width: screens.xxl ? "10%" : "9%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      // title: "移轉時間",
      title: t("PMS.util.stockinT"),
      dataIndex: "stockinT",
      align: "center",
      width: screens.xxl ? "9%" : "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD hh:mm"),
    },
    {
      // title: "歷程",
      title: t("PMS.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => {
        const map = {
          0: t("PMS.gostock.lifeF_0"), //"發佈",
          T: t("PMS.gostock.lifeF_T"), //"結案",
        };
        return map[text];
      },
    },
    {
      // title: "歷程時間",
      title: t("PMS.util.lifeFT"),
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl ? "10%" : "9%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "5%" : "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      {/* 進料檢驗查詢 */}
      <PageHeader title={t("PMS.gostock.pageHeader")} extra={<TourButton />} />
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <CustomRangePicker
              disabled={tabKey === "detailSearch"}
              value={[searchConfig?.startDate, searchConfig?.endDate]}
              onCalendarChange={(dates, _, __) => {
                updateState({
                  searchConfig: {
                    ...searchConfig,
                    startDate: dates[0],
                    endDate: dates[1],
                  },
                });
              }}
            />

            <Input.Group compact>
              <CustomSelect
                disabled={tabKey === "detailSearch"}
                value={searchConfig.searchFlag}
                placeholder={t("PMS.util.searchField")}
                onChange={(value) => handleChange("searchFlag", value)}
                options={[
                  { value: "jobN", label: t("PMS.gostock.jobN") }, //來源單號
                  { value: "N23", label: t("PMS.util.N23") }, //採購單號
                  { value: "N22", label: t("PMS.util.N22") }, //請購單號
                  { value: "batchno", label: t("PMS.gostock.batchno") }, //批號
                ]}
              />

              <Input
                disabled={tabKey === "detailSearch"}
                value={searchConfig.searchValue}
                className="w-[250px]"
                placeholder={placeholder_map[searchConfig.searchFlag]}
                onChange={(e) => handleChangeInput(e.target.value)}
              />

              <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
            </Input.Group>

            {/* 清除搜詢紀錄 */}
            <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
          </Space>
        }
      />

      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
          <DetailButton
            onClick={() =>
              // set_s_detailSearch((prev) => ({ ...prev, show: true }))
              updateState({ detailSearch: { ...detailSearch, show: true } })
            }
          />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getStockHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.opUUID === lightData.opUUID ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("PMS.util.setAdvanced")}
        open={detailSearch.show}
        onCancel={() => updateState({ detailSearch: { ...detailSearch, show: true } })}
      >
        {detailSearch.show && <DetailModal call_getStockHDAd={call_getStockHDAd} />}
      </CustomModal>
    </div>
  );
};

export default HD;
