/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, message, Space, Modal, Grid, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/OMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteOutstockTB } from "@/service/apis/OMS/Outstock";

const { Title } = Typography;

const TbContainer = ({ call_getOutstockTB, hd_values, s_tbData, s_disabled }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteOutstockTB = useAPI(deleteOutstockTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });

  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("OMS.outstock.tb") + t("util.del_confirm"), // 發貨明細刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.outstock.tb_remove_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteOutstockTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteOutstockTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getOutstockTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteOutstockTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "5%" : "7%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "15%" : "15%",
    },
    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: screens.xxl ? "11%" : "11%",
    },
    {
      title: t("OMS.util.drawno"), // 圖號
      dataIndex: "drawno",
      align: "center",
      width: screens.xxl ? "11%" : "11%",
    },
    {
      title: t("OMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: "6%",
      render: (text) => (text === "unit" ? "計量單位" : "包裝單位"),
    },
    {
      title: t("OMS.outstock.punit"), // 使用單位
      dataIndex: "punit",
      align: "center",
      width: "6%",
    },
    {
      title: t("OMS.outstock.indqty"), // 發貨數量
      dataIndex: "indqty",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.outstock.goqty"), // 驗收數量
      dataIndex: "goqty",
      align: "right",
      width: "6%",
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: "rgb(194, 214, 244)",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.outstock.rejectqty"), // 驗退數量
      dataIndex: "rejectqty",
      align: "right",
      width: "6%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "rgb(194, 214, 244)",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "5%" : "7%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 發貨明細 */}
        <TbTitle>{t("OMS.outstock.tb")}</TbTitle>
        {/* 新建的 button */}
        <CreateButton
          disabled={s_disabled.createBtn}
          onClick={() => set_s_tbModal({ show: true, data: {} })}
        />
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      {/* 新建、編輯表身的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-end mr-9">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯發貨明細" : "新增發貨明細"} */}
              {type === "edit"
                ? t("util.edit") + t("OMS.outstock.tb")
                : t("util.add") + t("OMS.outstock.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("OMS.util.opcode")}：{t(`OMS.util.opcode_${hd_values.opcode}`)} {t("OMS.util.N")}
                ：{hd_values.N} {t("OMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        <TbModal
          call_getOutstockTB={call_getOutstockTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
