import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import useAPI from "@/hooks/useAPI";
import { getCustTb, deleteCustTb } from "@/service/apis/ADM/client";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { Modal, message, Space, Input } from "antd";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalContainer from "./ModalContainer";

const CustCon = forwardRef(({ c_lightData, t }, ref) => {
  // 狀態
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModalCon, set_s_isShowModalCon] = useState(false);
  const [s_editData, set_s_editData] = useState({});

  // API
  const call_getCustTb = useAPI(getCustTb);
  const call_deleteCustTb = useAPI(deleteCustTb);

  const itemNMOp = [
    { label: "高管干部", value: "1" },
    { label: "採购单位", value: "2" },
    { label: "业务单位", value: "3" },
    { label: "品质单位", value: "4" },
    { label: "产品单位", value: "5" },
    { label: "技术单位", value: "6" },
    { label: "其他单位", value: "7" },
  ];

  const tableColumns = [
    {
      title: "联络窗口名称",
      dataIndex: "itemNM",
      width: "8%",
      render: (text) => {
        const foundItem = itemNMOp.find((item) => item.value === text);
        return foundItem ? foundItem.label : text;
      },
    },
    {
      title: "联络人",
      dataIndex: "contact",
      align: "center",
      width: "8%",
    },

    {
      title: "部门",
      dataIndex: "department",
      width: "8%",
    },
    {
      title: "公司联络住址",
      dataIndex: "addr",
      width: "10%",
    },
    {
      title: "公司联络电话",
      dataIndex: "tel",
      width: "8%",
    },
    {
      title: "个人行动电话",
      dataIndex: "mobile",
      width: "8%",
    },

    {
      title: "邮件地址",
      dataIndex: "mail",
      width: "16%",
    },
    {
      title: "窗口註记",
      dataIndex: "note",
      width: "16%",
    },

    // {
    //   title: t("util.isvalid"),
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: "5%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModalCon(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_isShowModalCon(true),
    aa: () => {
      call_getCustTb.request(c_lightData);
    },
  }));

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: '"删除确认"',
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "请先确认资料无误，再删除",
      okText: "确认",
      onOk: () => call_deleteCustTb.request(rowData),
      cancelText: "取消",
    });
  };

  // 一開始的客戶聯絡頁面
  useEffect(() => {
    call_getCustTb.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getCustTb.status === "suc") {
      message.success(call_getCustTb.msg);
      set_s_tableData(
        call_getCustTb.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getCustTb.status === "err") {
      message.error(t(`errorCode.${call_getCustTb.msg}`));
      set_s_tableData([]);
    }
  }, [call_getCustTb.status]);

  useEffect(() => {
    if (call_deleteCustTb.status === "suc") {
      message.success(call_deleteCustTb.msg);
      // delete之後 重call表格資料
      call_getCustTb.request(c_lightData);
    } else if (call_deleteCustTb.status === "err") {
      message.error(t(`errorCode.${call_deleteCustTb.msg}`));
    }
  }, [call_deleteCustTb.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getCustTb.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={Object.keys(s_editData).length > 0 ? "编辑客户联络" : "新增客户联络"}
        width={"60%"}
        open={s_isShowModalCon}
        onCancel={() => set_s_isShowModalCon(false)}
        afterClose={() => {
          set_s_editData({});
          call_getCustTb.request(c_lightData);
        }}
      >
        <ModalContainer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModalCon}
        />
      </CustomModal>
    </>
  );
});

export default CustCon;
