import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
  TourButton,
} from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import {
  addInstockHD,
  getInstockHD,
  getInstockTB,
  goStock,
  updateInstockHD,
} from "@/service/apis/PMS/Instock";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Light from "@/components/ERP/Light";
import PageHeader from "@/components/PageHeader";
import TakeProModal from "./Modal/TakeProModal";
// component
import TbContainer from "./Details/TbContainer";
import dayjs from "dayjs";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  takePro: true, // 引用採購單
  throwStock: true, // 拋轉進料檢驗單
  suppID: true,
  proSearch: true, //採購紀錄查詢
  stockSearch: true, // 驗收紀錄查詢
  instockCheck: true, // 進料覆核
  IQCstart: true, // 進檢抽樣
  note: true,
  IQCfinish: true, // IQC完驗
  QAcheck: true, // 品保放行
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  tb_submit: true, // 表身確定按鈕
  tb_delete: true, // 表身刪除按鈕
  utype: true,
  unitprice: true,
  indqty: true,
  inbatchno: true,
  goqty: true,
  rejectqty: true,
  judgeT: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getInstockHD = useAPI(getInstockHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getInstockHD = useAPI(getInstockHD);
  // useAPI 取得表身資料
  const call_getInstockTB = useAPI(getInstockTB);
  // useAPI 新增表頭
  const call_finish_addInstockHD = useAPI(addInstockHD);
  // useAPI 儲存表頭
  const call_finish_updateInstockHD = useAPI(updateInstockHD);
  // useAPI 進料覆核
  const call_check = useAPI(updateInstockHD);
  // useAPI 進檢抽樣
  const call_IQC = useAPI(updateInstockHD);
  // useAPI IQC完驗
  const call_IQCFinish = useAPI(updateInstockHD);
  // useAPI 品保放行
  const call_QAcheck_updateInstockHD = useAPI(updateInstockHD);
  // useAPI 取得上一筆資料
  const call_prev_updateInstockHD = useAPI(updateInstockHD);
  // useAPI 取得下一筆資料
  const call_next_updateInstockHD = useAPI(updateInstockHD);
  // 拋轉進料檢驗單
  const call_goStock = useAPI(goStock);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const { c_state, updateState } = useContext(DataContext);
  // 廠商資料、人員資料
  const { c_userData } = useContext(SystemContext);
  const { c_supps, c_staffs } = useContext(ERPContext);
  const { mainPage, instock_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 引用採購單的跳顯開關
  const [is_showPro, set_is_showPro] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addInstockHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateInstockHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      createID: "",
      createT: null,
      opUUID: "",
      preopUUID: "",
      inspectID: "",
      inspectT: null,
      qalID: "",
      qalT: null,
      sampleID: "",
      sampleT: null,
      endT: null,
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 清空表身資料，清空時 useEffect 會自動計算金額
    const new_tbData = s_tbData.map((item) => {
      return {
        ...item,
        opUUID: "",
        itemUUID: "",
        rejectqty: 0,
        goqty: 0,
        judgeT: null,
      };
    });

    set_s_tbData(new_tbData);
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);
    form.setFieldsValue({
      suppID: supp_obj.suppID,
      suppalias: supp_obj.suppalias,
      suppNM: supp_obj.suppNM,
      contact: null, // 選擇新廠商時要清空業務窗口資料
      tel: null, // 選擇新廠商時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 採購紀錄查詢
    const proSearch = hd_values.preopUUID?.length > 0 ? false : true;
    // 驗收紀錄查詢
    // const stockSearch = hd_values.count > 0 ? false : true;
    // console.log("進料單lifeF = ", hd_values);
    // console.log("isNewdoc = ", isNewdoc);
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        // opcode: !isNewdoc,
        suppID: !isNewdoc,
        note: false,
        // 引用採購單
        takePro: hd_values?.linkType === "trans" ? true : false,
        // 採購紀錄查詢
        proSearch: proSearch,
        instockCheck: false,
        // ------- 表身 -------
        // 有上位資料、就不可以手動建立表身
        createBtn: hd_values.preopUUID?.length > 0 ? true : isCreateByThis ? false : isNewdoc,
        tb_delete: false,
        tb_submit: false,
        utype: false,
        unitprice: false,
        indqty: false,
        inbatchno: false,
        tb_note: false,
      });
    }

    // 進料覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 採購紀錄查詢
        proSearch: proSearch,
        instockCheck: false, // 進料覆核
        IQCstart: false, // ICQ抽驗
      });
    }

    // ICQ抽驗
    if (hd_values?.lifeF === "3") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 採購紀錄查詢
        proSearch: proSearch,
        IQCstart: false, // ICQ抽驗
        IQCfinish: false, // IQC完驗
      });
    }

    // ICQ完驗
    if (hd_values?.lifeF === "4") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 採購紀錄查詢
        proSearch: proSearch,
        IQCfinish: false, // IQC完驗
        QAcheck: false, // 品保放行
        //----------------------------
        goqty: false, // 驗收數量
        rejectqty: false, // 驗退數量
        judgeT: false, // 品保判定日期
        tb_submit: false,
        tb_note: false,
      });
    }

    // 品保放行
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 採購紀錄查詢
        proSearch: proSearch,
        QAcheck: false, // 品保放行
        throwStock: false, // 拋轉進料檢驗單
      });
    }

    // 結案
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 採購紀錄查詢
        proSearch: proSearch,
        stockSearch: false, // 驗收紀錄查詢
      });
    }
  };

  // 進料覆核 or 取消進料覆核
  const instockCheck = () => {
    Modal.confirm({
      title: `${
        f_lifeF === "2"
          ? t("PMS.util.cancel") + t("PMS.instock.instockCheck_confirm")
          : t("PMS.instock.instockCheck_confirm")
      }`,
      icon: <ExclamationCircleOutlined />,
      content:
        f_lifeF === "2"
          ? t("PMS.instock.cancel_instockCheck_confirm_text")
          : t("PMS.instock.instockCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_check.request({
          ...form.getFieldsValue(),
          // 0: 發佈 2: 進料覆核
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 進檢抽樣 or 取消進檢抽樣
  const IQCcheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      title: `${
        f_lifeF === "3"
          ? t("PMS.util.cancel") + t("PMS.instock.IQCcheck_confirm")
          : t("PMS.instock.IQCcheck_confirm")
      }`,
      icon: <ExclamationCircleOutlined />,
      content:
        f_lifeF === "3"
          ? t("PMS.instock.cancel_IQCcheck_confirm_text")
          : t("PMS.instock.IQCcheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_IQC.request({
          ...values,
          // 0: 發佈 2: 進料覆核
          lifeF: f_lifeF === "2" ? "3" : "2",
          lifeFT: dayjs(),
          sampleID: c_userData?.staffID,
          sampleT: dayjs(),
        });
      },
    });
  };

  // IQC完驗 or 取消IQC完驗
  const IQCfinish = () => {
    const values = form.getFieldsValue();
    Modal.confirm({
      title: `${
        f_lifeF === "4"
          ? t("PMS.util.cancel") + t("PMS.instock.IQCfinish_confirm")
          : t("PMS.instock.IQCfinish_confirm")
      }`,
      icon: <ExclamationCircleOutlined />,
      content:
        f_lifeF === "4"
          ? t("PMS.instock.cancel_IQCfinish_confirm_text")
          : t("PMS.instock.IQCfinish_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_IQCFinish.request({
          ...values,
          // 0: 發佈 2: 進料覆核
          lifeF: f_lifeF === "3" ? "4" : "3",
          lifeFT: dayjs(),
          inspectID: c_userData?.staffID,
          inspectT: dayjs(),
        });
      },
    });
  };

  // 品保放行 or 取消品保放行
  const QAcheck = () => {
    let is_break = false;

    s_tbData.forEach((item) => {
      // [品保判退數量]+[品保放行數量] 要等於 [進料數量]
      if (item.rejectqty + item.goqty !== item.indqty) {
        is_break = true;
      }
    });

    if (is_break) {
      // message.error("進料明細 [品保判退數量]+[品保放行數量] 要等於 [進料數量]");

      message.error(t("PMS.instock.QAQTYcheck"));
      return;
    }

    const values = form.getFieldsValue();

    Modal.confirm({
      title: `${
        f_lifeF === "7"
          ? t("PMS.util.cancel") + t("PMS.instock.QAcheck_confirm")
          : t("PMS.instock.QAcheck_confirm")
      }`,
      icon: <ExclamationCircleOutlined />,
      content: `${
        f_lifeF === "7"
          ? t("PMS.instock.cancel_QAcheck_confirm_text")
          : t("PMS.instock.QAcheck_confirm_text")
      }`,
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_QAcheck_updateInstockHD.request({
          ...values,
          flag: "U",
          // 0: 發佈 2: 進料覆核
          lifeF: f_lifeF === "7" ? "4" : "7",
          lifeFT: dayjs(),
          qalID: c_userData?.staffID,
          qalT: dayjs(),
        });
      },
    });
  };

  // 拋轉進料單
  const throwStock = () => {
    Modal.confirm({
      title: t("PMS.util.sysMsg"), //"系統提示",
      icon: <ExclamationCircleOutlined />,
      content: <div>{t("PMS.instock.throwStock_message")}</div>,
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => {
        call_goStock.request({
          hd: mainPage.data,
          tb: s_tbData,
        });
      },
    });
  };

  // 查詢上位資料
  const go_pro = () => {
    const pro_opcode = {
      2410: "2310",
      2420: "2320",
      2430: "2330",
    };

    const params_obj = {
      opcode: pro_opcode[mainPage.data.opcode],
      opUUID: mainPage.data.preopUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/pro?${params}`, "_blank");
  };

  // 查詢下位資料進料檢驗
  const go_stock = () => {
    const params_obj = {
      TabKey: "detailSearch",
      // opcode: "4120|4230",
      jobN: mainPage.data.N,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/gostock?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      // message.success("目前資料為第一筆，已無上一筆資料");
      message.success(t("PMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateInstockHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === instock_Ns.length) {
      // message.success("目前資料為最後一筆，已無下一筆資料");
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateInstockHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = instock_Ns.findIndex((item) => item === N);

    return index;
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建進料單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        opcode: "2410",
        lifeF: "0",
        lifeFT: dayjs(),
        // 預設登入人員
        createID: c_userData?.staffID,
        createT: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getInstockTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getInstockHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getInstockHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getInstockTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getInstockTB.status]);

  // 新增進料單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addInstockHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getInstockHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getInstockTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addInstockHD.status]);

  // 編輯進料單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateInstockHD;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getInstockHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getInstockTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateInstockHD.status]);

  // useAPI 進料覆核 or 取消覆核
  useEffect(() => {
    const { data, status, msg } = call_check;
    if (status === "suc") {
      //"[進料覆核]成功" : "[取消覆核]成功"
      const msg =
        data.lifeF === "2"
          ? `[${t("PMS.instock.lifeF_2")}]${t("util.success")}`
          : `[${t("util.cancel")}${t("util.check")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getInstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_check.status]);

  // 進檢抽樣 or 取消進檢抽樣
  useEffect(() => {
    const { data, status, msg } = call_IQC;
    if (status === "suc") {
      //"[進檢抽樣]成功" : "取消[進檢抽樣]成功"
      const msg =
        data.lifeF === "3"
          ? `[${t("PMS.instock.IQCstart")}]${t("util.success")}`
          : `${t("util.cancel")}[${t("PMS.instock.IQCstart")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getInstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_IQC.status]);

  // useAPI IQC完驗 or 取消IQC完驗
  useEffect(() => {
    const { data, status, msg } = call_IQCFinish;
    if (status === "suc") {
      const msg =
        data.lifeF === "4"
          ? `[${t("PMS.instock.lifeF_4")}]${t("util.success")}`
          : `${t("util.cancel")}[${t("PMS.instock.lifeF_4")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getInstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_IQCFinish.status]);

  // useAPI 品保放行 or 取消品保放行
  useEffect(() => {
    const { data, status, msg } = call_QAcheck_updateInstockHD;
    if (status === "suc") {
      const msg =
        data.lifeF === "7"
          ? `[${t("PMS.instock.QAcheck")}]${t("util.success")}`
          : `${t("util.cancel")}[${t("PMS.instock.QAcheck")}]${t("util.success")}`;
      message.success(msg);
      // 更新表頭資料
      call_getInstockHD.request({ N: mainPage.data.N });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_QAcheck_updateInstockHD.status]);

  // useAPI 拋轉進料檢驗單
  useEffect(() => {
    const { msg, status } = call_goStock;
    if (status === "suc") {
      message.success(msg);
      call_getInstockHD.request({ N: mainPage.data.N });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_goStock.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateInstockHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // 取得上一筆資料成功
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getInstockHD.request({ N: instock_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      // 取得上一筆資料失敗
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateInstockHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateInstockHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      //"取得下一筆資料成功
      message.success(t("PMS.util.get_next_data_suc"));
      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getInstockHD.request({ N: instock_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      //取得下一筆資料失敗
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateInstockHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getInstockHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getInstockTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getInstockHD.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* 編輯進料主檔、新建進料主檔 */}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("PMS.instock.pageHeader")
                  : t("util.add") + t("PMS.instock.pageHeader")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="opcode" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="linkType" hidden>
            <Input />
          </Form.Item>
        </div>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          {/* <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("PMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("PMS.util.opcode_placeholder")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("PMS.util.opcode_2410"), value: "2410" }, // 國內進料
                  { label: t("PMS.util.opcode_2420"), value: "2420" }, // 國外進料
                  { label: t("PMS.util.opcode_2430"), value: "2430" }, // 委外進料
                ]}
              />
            </Form.Item>
          </Col> */}
          {/* 單號 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item
              label={
                <Space>
                  <Text disabled>{t("PMS.util.N")}</Text>
                  {mainPage.data.preopUUID && <Light linkType={mainPage.data.linkType} />}
                </Space>
              }
              name="N"
            >
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 建檔人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.createID")}</Text>} name="createID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-purple"
                disabled={s_disabled.takePro}
                onClick={() => set_is_showPro(true)}
              >
                {t("PMS.util.take")}
                <br />
                {t("PMS.util.pro")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("PMS.util.accord")}
                <br />
                {t("PMS.util.create")}
              </Button>
              <Button
                className="flex-1 h-full pms-btn-purple"
                onClick={throwStock}
                disabled={s_disabled.throwStock}
              >
                {t("PMS.util.throw")}
                <br />
                {t("PMS.util.gostock")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="suppID"
              label={<Text disabled={s_disabled.suppID}>{t("PMS.util.suppID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.suppID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.suppID}
                placeholder={t("PMS.util.suppID_placeholder")}
                onChange={onSuppChange}
                options={c_supps.options}
              />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.suppalias")}</Text>} name="suppalias">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {/* 抽驗人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.instock.sampleID")}</Text>} name="sampleID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 抽驗時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.instock.sampleT")}</Text>} name="sampleT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1 pms-btn-blue-outline"
                // onClick={() => go_req()}
                onClick={() => go_pro()}
                disabled={s_disabled.proSearch}
              >
                {/* 採購紀錄查詢 */}
                {t("PMS.util.proSearch")}
              </Button>
              <Button
                className="h-full flex-1 pms-btn-blue-outline"
                // onClick={() => go_inStock()}
                onClick={() => go_stock()}
                disabled={s_disabled.stockSearch}
              >
                {/* 驗收紀錄查詢 */}
                {t("PMS.util.stockSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商名稱 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item label={<Text disabled>{t("PMS.util.suppalias")}</Text>} name="suppNM">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {/* 進檢人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.instock.inspectID")}</Text>} name="inspectID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 完驗時間 */}
          <Col span={editMode ? 4 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.instock.inspectT")}</Text>} name="inspectT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 進料覆核 */}
              <Button
                className={`h-full flex-1 ${f_lifeF === "2" ? "pms-btn-orange" : "pms-btn-purple"}`}
                disabled={s_tbData.length === 0 ? true : s_disabled.instockCheck}
                onClick={instockCheck}
              >
                {/* f_lifeF === "2" ? "取消覆核" : "進料覆核(1)" */}
                {f_lifeF === "2"
                  ? t("util.cancel") + t("util.check")
                  : t("PMS.instock.lifeF_2") + "(1)"}
              </Button>
              {/* 進檢抽樣 */}
              <Button
                className={`h-full flex-1 ${f_lifeF === "3" ? "pms-btn-orange" : "pms-btn-purple"}`}
                disabled={s_disabled.IQCstart}
                onClick={IQCcheck}
              >
                {/* {f_lifeF === "3" ? "取消進檢抽樣" : "進檢抽樣(2)"} */}
                {f_lifeF === "3"
                  ? t("util.cancel") + t("PMS.instock.IQCstart")
                  : t("PMS.instock.IQCstart") + "(2)"}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 進料註記 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.note}>{t("PMS.instock.note")}</Text>}
              name="note"
              className="mb-0px"
            >
              <Input
                placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.note")}
                disabled={s_disabled.note}
              />
            </Form.Item>
          </Col>
          {/* 品判人員 */}
          <Col span={editMode ? 5 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("PMS.instock.qalID")}</Text>} name="qalID">
              <Select disabled options={c_staffs.options} />
            </Form.Item>
          </Col>
          {/* 放行時間 */}
          <Col span={editMode ? 4 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("PMS.instock.qalT")}</Text>} name="qalT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={5}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              <Button
                className={`h-full flex-1 ${f_lifeF === "4" ? "pms-btn-red" : "pms-btn-purple"}`}
                disabled={s_tbData.length === 0 ? true : s_disabled.IQCfinish}
                onClick={IQCfinish}
              >
                {/* {f_lifeF === "4" ? "取消IQC完驗" : "IQC完驗(3)"} */}
                {f_lifeF === "4"
                  ? t("PMS.util.cancel") + t("PMS.instock.lifeF_4")
                  : t("PMS.instock.lifeF_4") + "(3)"}
              </Button>
              <Button
                className={`h-full flex-1 ${f_lifeF === "7" ? "pms-btn-red" : "pms-btn-purple"}`}
                onClick={QAcheck}
                disabled={s_disabled.QAcheck}
              >
                {/* {f_lifeF === "7" ? "取消品保放行" : "品保放行(4)"} */}
                {f_lifeF === "7"
                  ? t("PMS.util.cancel") + t("PMS.instock.QAcheck")
                  : t("PMS.instock.QAcheck") + "(4)"}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        hd_values={form.getFieldsValue()}
        call_getInstockTB={call_getInstockTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
      />

      {/* 引用採購單 */}
      <CustomModal
        width={screens.xxl ? "80%" : "90%"}
        // title="引用採購單"
        title={t("PMS.util.take") + t("PMS.util.pro")}
        open={is_showPro}
        onCancel={() => set_is_showPro(false)}
      >
        {is_showPro ? (
          <TakeProModal
            set_is_showPro={set_is_showPro}
            hd_values={form.getFieldsValue()}
            call_getInstockHD={call_getInstockHD}
            call_getInstockTB={call_getInstockTB}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
