import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { data } = s_drawer;
  console.log("data = ", data);
  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 料號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.PN")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.PN}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 品名 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.pdtNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 規格 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.pdtspec")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtspec}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 單位類別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.utype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.utype === "unit" ? t("OMS.util.unit") : t("OMS.util.opunit")}</Text>
        </Col>
        {/* 使用單位 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.outstock.punit")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.punit}</Text>
        </Col>
        {/* 銷貨單價 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.outstock.unitprice")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.unitprice)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 暫收數量 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.indqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.indqty)}</Text>
        </Col>
        {/* 驗收數量 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.outstock.goqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.goqty)}</Text>
        </Col>
        {/* 驗退數量 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.outstock.rejectqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.rejectqty)}</Text>
        </Col>
      </Row>

      <Row className="mt-5 h-24 border pms-drawer-border-color">
        {/* 註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
