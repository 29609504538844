import * as XLSX from "xlsx";

import { Col, DatePicker, Grid, Row, Space, Typography, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExcelButton } from "@/components/ERP/Button";
import FileSaver from "file-saver";
// util component
import PageHeader from "@/components/PageHeader";
import { SearchButton } from "@/components/PDS/Buttons";
import dayjs from "dayjs";
// util function
import exportExcel from "@/util/exportExcel";
import { getPayment } from "@/service/apis/OMS/Ord";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Payment = () => {
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_dueday, set_s_dueday] = useState(undefined);
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // 客戶資料、人員資料
  const { getStaffName } = useContext(ERPContext);
  const call_getPayment = useAPI(getPayment);

  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      isExcel: true,
      width: screens.xxl ? "6%" : "6%",
      render: (text) => t(`OMS.util.opcode_${text}`),
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "9%",
      isExcel: true,
    },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
      width: "12%",
      isExcel: true,
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      sorter: (a, b) => a.dealN.localeCompare(b.dealN),
      dataIndex: "dealN",
      align: "center",
      isExcel: true,
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => getStaffName(text),
      isExcel: true,
    },
    {
      title: t("OMS.ord.planday"), // 計畫時間
      dataIndex: "planday",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      isExcel: true,
    },
    {
      title: t("OMS.util.EDT"), // 預計出貨日
      dataIndex: "EDT",
      align: "center",
      sorter: (a, b) => a.EDT.localeCompare(b.EDT),
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      isExcel: true,
    },
    {
      title: "付款截止日",
      dataIndex: "dueday",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      isExcel: true,
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      // sorter: (a, b) => a.lifeF.localeCompare(b.lifeF),
      render: (text) => {
        return t(`OMS.ord.lifeF_${text}`);
      },
    },
  ];

  const downloadExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    let excelData = s_tableData.map((x) => {
      return {
        // date: dayjs(x.date).format("YYYY-MM-DD"),
        // timecode: x.timecode.toString(),
        ...x,
        opcode: t(`OMS.util.opcode_${x.opcode}`),
      };
    });

    console.log(excelData);

    const ws = XLSX.utils.json_to_sheet(excelData);
    // ws['!cols'] = tableData;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([excelBuffer], { type: fileType }), "條碼日期明細.xlsx");
  };

  useEffect(() => {
    const { status, msg, data } = call_getPayment;

    if (status === "suc") {
      message.success(msg);
      const _data =
        data
          ?.filter((item) => !item.dueday.includes("0001-01-01"))
          ?.map((item, index) => ({ ...item, key: index })) || [];
      set_s_tableData(_data);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getPayment.status]);
  // 轉換日期格式、只留下要導出的資料
  const ExcelSourec = s_tableData.map((item) => {
    return {
      ...item,
      opcode: t(`OMS.util.opcode_${item.opcode}`),
      lifeFT: dayjs(item.lifeFT).format("YYYY-MM-DD HH:MM"),
      approveT: dayjs(item.approveT).format("YYYY-MM-DD HH:MM"),
      signT: dayjs(item.signT).format("YYYY-MM-DD HH:MM"),
      confirmT: dayjs(item.confirmT).format("YYYY-MM-DD HH:MM"),
      createT: dayjs(item.createT).format("YYYY-MM-DD HH:MM"),
    };
  });

  return (
    <div className="flex-1">
      <PageHeader title="订单付款查询" extra={[]} />
      <Space className="flex justify-end gap-5 mb-2">
        付款截止日:
        <DatePicker
          value={s_dueday}
          onChange={(value) => set_s_dueday(value)}
          className="w-[250px]"
          placeholder="请选择付款截止日"
        />
        <SearchButton
          type="primary"
          key="search"
          onClick={() => call_getPayment.request({ dueday: dayjs(s_dueday).format("YYYY-MM-DD") })}
        />
        <ExcelButton
          disabled={s_tableData.length === 0}
          onClick={() =>
            exportExcel(
              tableColumns,
              ExcelSourec,
              `订单付款查询_${dayjs().format("YYYY-MM-DD")}.xlsx`
            )
          }
          // onClick={downloadExcel}
        />
      </Space>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        // loading={call_getOrdHD.status === "load"}
      />
    </div>
  );
};

export default Payment;
