import apiBasic from "../APIBasic";

const wsConn = apiBasic.conn === "http" ? "ws" : "wss";

export const dashboardSocket = `${wsConn}://${apiBasic.url}/api/dashboard/socket`;

export const actSocket = `${wsConn}://${apiBasic.url}/api/act/socket`;

export const getSpcDataSocket = `${wsConn}://${apiBasic.url}/api/spc/getSpcData`;

export const getSpcDetailSocket = `${wsConn}://${apiBasic.url}/api/spc/getSpcDetail`;
