import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Button, Typography, Space, Spin } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from '@/util/format' //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButton";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import { getShipped } from "@/service/apis/OMS/Ord";
import useAPI from "@/hooks/useAPI";

const { Text } = Typography;

//進退貨統計表
const PurchaseStatistics = () => {
  const { RangePicker } = DatePicker;

  const { c_supps, c_PNs } = useContext(ERPContext);  //從ERPContext拿到廠商資料

  const { t } = useTranslation();

  const [s_excelData, set_s_excelData] = useState([]);

  const [s_groupData, set_s_groupData] = useState([]);

  const [is_spin, set_is_spin] = useState(false)

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    suppID: [], //廠商ID
    PN: [], //料號
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  // const call_getShipped = useAPI(getShipped); // useAPI 取得訂單出貨狀態資料


  const [s_TableData, set_s_TableData] = useState([
    {
      suppalias: '廠商1',
      PN: 'PN0000001',
      pdtNM: '品名0000001',
      unit: "PCS",
      purchaseqty: 100,
      purchasesubtotal: 10000,
      returnqty: 50,
      returnsubtotal: 5000,
      purchasenetamount: 50,
      purchasenetvalue: 5000,
    },
    {
      suppalias: '廠商1',
      PN: 'PN0000002',
      pdtNM: '品名0000002',
      unit: "PCS",
      purchaseqty: 200,
      purchasesubtotal: 20000,
      returnqty: 100,
      returnsubtotal: 10000,
      purchasenetamount: 100,
      purchasenetvalue: 10000,
    },
    {
      suppalias: '廠商2',
      PN: 'PN0000003',
      pdtNM: '品名0000003',
      unit: "PCS",
      purchaseqty: 20,
      purchasesubtotal: 200,
      returnqty: 10,
      returnsubtotal: 100,
      purchasenetamount: 10,
      purchasenetvalue: 100,
    },
    {
      suppalias: '廠商2',
      PN: 'PN0000004',
      pdtNM: '品名0000004',
      unit: "PCS",
      purchaseqty: 40,
      purchasesubtotal: 4000,
      returnqty: 10,
      returnsubtotal: 10,
      purchasenetamount: 1000,
      purchasenetvalue: 3000,
    },
    {
      suppalias: '廠商3',
      PN: 'PN0000005',
      pdtNM: '品名0000005',
      unit: "PCS",
      purchaseqty: 250,
      purchasesubtotal: 25000,
      returnqty: 30,
      returnsubtotal: 3000,
      purchasenetamount: 220,
      purchasenetvalue: 22000,
    },


  ]); //放到表格的資料



  const tableColumns = [
    {
      title: "廠商簡稱",
      dataIndex: "suppalias",
      align: "left",
      width: '8%'
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "left",
      width: '5%'
    },
    {
      title: "進貨數量",
      dataIndex: "purchaseqty",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "進貨金額",
      dataIndex: "purchasesubtotal",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "退貨數量",
      dataIndex: "returnqty",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "退貨金額",
      dataIndex: "returnsubtotal",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "進貨淨量",
      dataIndex: "purchasenetamount",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "進貨淨額",
      dataIndex: "purchasenetvalue",
      align: "right",
      width: '8%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    // console.log("call api");
    const tmp = { ...s_searchData, PN: s_searchData.PN.join('|'), suppID: s_searchData.suppID.join('|') }
    console.log("tmp === ", tmp);
    // console.log("s_searchData", s_searchData);
    // call_getShipped.request(tmp);
  }

  //----------------------------導出EXCEL---------------------------------------------------

  const exportToExcel = () => {

    const excelTable = s_TableData.map((item) => {
      //數字加上千分位

      return {
        ...item,
        purchaseqty:formatAmount(item.purchaseqty),
        purchasesubtotal:formatAmount(item.purchasesubtotal),
        returnqty:formatAmount(item.returnqty),
        returnsubtotal:formatAmount(item.returnsubtotal),
        purchasenetamount:formatAmount(item.purchasenetamount),
        purchasenetvalue:formatAmount(item.purchasenetvalue),
      }
    })


    //ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excelTable,
      { header: ["suppalias","PN","pdtNM","unit","purchaseqty","purchasesubtotal","returnqty","returnsubtotal","purchasenetamount","purchasenetvalue"] });
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    let mergeCells = [];
    console.log("s_groupData.length", s_groupData.length);
    for (let j = 0; j < s_groupData.length; j++) {

      for (let i = 1 + (j === 0 ? 0 : s_groupData.slice(0, j).reduce((acc, cur) => acc + cur.length, 0)); i < excelTable.length; i += s_groupData[j].length) {

        if (excelTable[i].suppalias === excelTable[i - 1].suppalias) {
          mergeCells.push({ s: { r: i, c: 0 }, e: { r: i + s_groupData[j].length - 1, c: 0 } });
        }
      }
    }



    // 计算每列的最大宽度
    const colWidths = excelTable[0] && Object.keys(excelTable[0]).map(() => 0);
    excelTable.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map(w => ({ wch: w + 7 })); // 根据最大长度调整列宽
    ws['!cols'] = wscols;



    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }
    ws["!merges"] = mergeCells;
    const today = dayjs().format("YYYY-MM-DD");

    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `進退貨彙總表${today}.xlsx`);

  };

  //----------------------------EXCEL---------------------------------------------------

  //所有銷貨單、銷退單資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getShipped;
  //   if (status === "suc") {
  //     console.log("call_getShipped = ", data);
  //     set_s_TableData(data);
  //     message.success(msg);
  //   } else if (status === "err") {
  //     set_s_TableData([]);
  //     message.error(msg);
  //   }
  // }, [call_getShipped.status]);

  // useEffect(() => {
  //   if (call_getShipped.status === 'load') {
  //     set_is_spin(true)
  //   } else {
  //     set_is_spin(false)
  //   }
  // }, [call_getShipped.status])

  //拿到原始資料後做分組，將資料組成要的格式再塞到表格
  useEffect(() => {

    // group 方法
    const groupResult = s_TableData.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.suppalias]) {
        accumulator[currentValue.suppalias] = [];
      }
      accumulator[currentValue.suppalias].push(currentValue);
      return accumulator;
    }, {});

    //取出各群組中的值
    const groupArr = Object.values(groupResult);
    set_s_groupData(groupArr);
    console.log("groupArr", groupArr);

    // -----------------加上合併欄位數、總計列-------------------------------------

    // const CountArr = groupArr.map((item) => {

    //   const count = item.reduce(
    //     (accumulator, currentValue) => {
    //       // accumulator.tax += currentValue.tax;
    //       accumulator.subtotal += currentValue.subtotal;
    //       // accumulator.total += currentValue.subtotal + currentValue.tax;
    //       return accumulator;
    //     },
        // {
        //   // tax: 0,      //稅額
        //   // subtotal: 0, //合計金額(未稅)
        //   // total: 0,     //總金額(含稅)
        // }
      // );
      // 加上合計欄
      // item.push({
      //   // driID: "發票號碼",
      //   pdtNM: '合計金額',
      //   whNM: count.subtotal,
      //   // qty: "稅額",
      //   // unit: count.tax,
      //   // unitprice: "總金額",
      //   // subtotal: count.total,
      // },);
      // console.log("count", count);
      // console.log("item", item);
      // return item;
    // });

    // console.log("CountArr", CountArr);

    // const finalArr = [].concat(...CountArr);  //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列 
    // console.log("finalArr", finalArr);

    // set_s_excelData(finalArr);
  }, [s_TableData]);


  return (
    <div className="flex flex-col flex-1 w-full">

      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4" />

      {/*表頭*/}

      <PageHeader title="進退貨統計表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">

        {/*導出excel*/}


        <Button
          type="primary"
        onClick={() => exportToExcel()}
        >
          導出excel
        </Button>

        {/*廠商選擇*/}
        <Select
          className="min-w-[200px]"
          name="suppID"
          mode="multiple"
          showSearch
          placeholder="請選擇廠商"
          onChange={(value) => handle_SearchData_Change("suppID", value)}
          options={c_supps.options}
        />

        <Text>料號：</Text>
        <Select
          mode='multiple'
          name='PN'
          className="min-w-[500px]"
          showSearch
          placeholder="請填寫料號"
          onChange={(value) => { handle_SearchData_Change("PN", value) }}
          options={c_PNs.options}
        />

        <PNButton />


        {/*日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button type="primary" onClick={handleClick}>
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};

export default PurchaseStatistics