/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
// util component
import PNButton from "@/components/ERP/PNButton";
import { ConfirmButton, CancelButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { addQuoTB, updateQuoTB } from "@/service/apis/OMS/Quo";
const { Text } = Typography;

const TbModal = ({
  hd_values,
  s_disabled,
  s_tbModal,
  set_s_tbModal,
  call_getQuoHD,
  call_getQuoTB,
}) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addQuoTB = useAPI(addQuoTB);
  // useAPI 編輯表身
  const call_updateQuoTB = useAPI(updateQuoTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);
  const f_unitprice = Form.useWatch("unitprice", form);
  const f_demand = Form.useWatch("demand", form);

  // 儲存時執行
  const onFinish = async (values) => {
    // alert(123);
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    if (type === "create") {
      // step1 call 新建表身的API
      call_addQuoTB.request(values);
    }
    if (type === "edit") {
      // step1 call 編輯表身的API
      console.log(values);
      call_updateQuoTB.request(values);
    }
  };

  // 改變料號
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // note
      note: form.getFieldValue("note"),
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 重新計算金額
  const updateAmount = (demand, unitprice) => {
    if (demand > 0 && unitprice > 0) {
      const itemsum = demand * unitprice;
      const localprice = hd_values.exchangerate * unitprice;
      const itemsumtax = parseFloat((itemsum * hd_values.taxrate * 0.01).toFixed(2));
      const subtotal = parseFloat((itemsum + itemsumtax).toFixed(2));
      console.log({ localprice, itemsum, itemsumtax, subtotal });
      form.setFieldsValue({ localprice, itemsum, itemsumtax, subtotal });
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    form.setFieldsValue({
      ...s_tbModal.data,
      opUUID: hd_values.opUUID,
      currencytype: hd_values.currencytype,
      exchangerate: hd_values.exchangerate,
    });
  }, []);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addQuoTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getQuoHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getQuoTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addQuoTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateQuoTB;
    console.log("call_updateQuoTB = ", call_updateQuoTB);
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getQuoHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getQuoTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateQuoTB.status]);

  // 如果bdm資料沒有 unit、opunit就不可選擇單位類別
  const isDisabled = f_unit?.length === 0 && f_opunit?.length === 0;
  const hasSelectedPN = f_PN?.length > 0;
  const isUTypeDisabled = isDisabled && !hasSelectedPN;

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>
      </div>
      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("OMS.util.PN")}</Text>}
            name="PN"
            messageVariables={{ label: t("OMS.util.PN") }}
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("OMS.util.PN")}
              disabled={s_disabled.PN}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 品名 */}
        <Col span={24}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 規格 */}
        <Col span={16}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 圖號 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.drawno")}</Text>} name="drawno">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.attribute")}</Text>} name="attribute">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 商品類別 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtclass")}</Text>} name="pdtclass">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 管理分類 */}
          <Form.Item label={<Text disabled>{t("OMS.util.pdttype")}</Text>} name="pdttype">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 計量單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 操作單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 報價數量 */}
          <Form.Item
            label={<Text disabled={s_disabled.demandqty}>{t("OMS.quo.demand")}</Text>}
            name="demand"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.quo.demand") }}
          >
            <InputNumber
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.quo.demand")}
              disabled={s_disabled.demand}
              onChange={(demand) => {
                const unitprice = form.getFieldValue("unitprice");
                updateAmount(demand, unitprice);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          {/* 單位類別 */}
          <Form.Item
            label={
              <Text disabled={s_disabled.utype || isUTypeDisabled}>{t("PMS.util.utype")}</Text>
            }
            name="utype"
            // rules={[{required:true,message:"請選擇採購單位類別!"}]}
          >
            <Select
              placeholder={t("PMS.util.utype_placeholder")}
              optionFilterProp="children"
              disabled={s_disabled.utype || isUTypeDisabled}
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && <Select.Option value="unit">{t("OMS.util.unit")}</Select.Option>}
              {f_opunit?.length > 0 && <Select.Option value="opunit">{t("OMS.util.opunit")}</Select.Option>}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          {/* 報價單位 */}
          <Form.Item
            label={
              <Text disabled={s_disabled.utype || s_disabled.opunit}>{t("OMS.quo.punit")}</Text>
            }
            name="punit"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8} className="flex gap-3">
          {/* 報價幣別 */}
          <Form.Item label={<Text disabled>{t("OMS.util.currencytype")}</Text>} name="currencytype">
            <Input disabled />
          </Form.Item>
          {/* 匯率 */}
          <Form.Item label={<Text disabled>{t("OMS.util.exchangerate")}</Text>} name="exchangerate">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            // 未稅單價
            label={<Text disabled={s_disabled.unitprice}>{t("OMS.quo.unitprice")}</Text>}
            name="unitprice"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.quo.unitprice") }}
          >
            <InputNumber
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.quo.unitprice")}
              disabled={s_disabled.unitprice}
              onChange={(unitprice) => {
                const demand = form.getFieldValue("demand");
                updateAmount(demand, unitprice);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 本幣單價(未稅) */}
          <Form.Item label={<Text disabled>{t("OMS.util.localprice")}</Text>} name="localprice">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 單項金額 */}
          <Form.Item label={<Text disabled>{t("OMS.quo.itemsum")}</Text>} name="itemsum">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 單項稅額 */}
          <Form.Item label={<Text disabled>{t("OMS.quo.itemsumtax")}</Text>} name="itemsumtax">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 單項含稅小計 */}
          <Form.Item label={<Text disabled>{t("OMS.quo.subtotal")}</Text>} name="subtotal">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24}>
          {/* 註記 */}
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS.quo.note")}</Text>}
            name="note"
          >
            <Input disabled={s_disabled.tb_note} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          {/* 確定 */}
          <ConfirmButton htmlType="submit" disabled={s_disabled.tb_submit} />
          {/* <Button onClick={() => set_s_tbModal({ show: false, data: {} })}>
            {t("OMS.util.cancel")}
          </Button>
          <Button type="primary" htmlType="submit" disabled={s_disabled.tb_submit}>
            {t("OMS.util.submit")}
          </Button> */}
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
