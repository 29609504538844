import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";
//-----------------------------------------新增對帳作業表頭----------------------------------------
export const addApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addApHD`, data);
};
//-----------------------------------------取得對帳作業表頭----------------------------------------
export const getApHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApHD`, {
    params: data,
  });
};
//-----------------------------------------編輯對帳作業表頭----------------------------------------
export const updateApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateApHD`, data);
};
//-----------------------------------------刪除對帳作業表頭----------------------------------------
export const deleteApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApHD`, data);
};
//-----------------------------------------取得對帳作業表身----------------------------------------
export const getApTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApTB`, {
    params: data,
  });
};

//-----------------------------------------刪除對帳作業表身----------------------------------------
export const deleteApTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApTB`, data);
};
//-----------------------------------------引用對帳資料----------------------------------------
export const takeOut = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeOut`, data);
};
