/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Divider,
  InputNumber,
  message,
  Space,
  DatePicker,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// component
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// api
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addApsOutMoHd, updateApsOutMoHd, getApsOutWoSelect } from "@/service/apis/APS/apsMange";

const ModalContainer = ({ s_editData, OPCODE_LABELS, set_s_showModal }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [s_jobN, set_s_jobN] = useState([]);
  const type = Object.keys(s_editData).length === 0 ? "create" : "edit";
  // useAPI 取得工單下拉選單
  const call_getApsOutWoSelect = useAPI(getApsOutWoSelect);
  // useAPI 新增料單表頭
  const call_addApsOutMoHd = useAPI(addApsOutMoHd);
  // useAPI 編輯料單表頭
  const call_updateApsOutMoHd = useAPI(updateApsOutMoHd);

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  const onSubmit = async (values) => {
    // 新增時要給表頭表身
    type === "create"
      ? call_addApsOutMoHd.request({
          hd: values,
          tb: values.tb,
        })
      : call_updateApsOutMoHd.request(values);
  };

  // 第一次渲染賦值表單、call API
  useEffect(() => {
    call_getApsOutWoSelect.request();
    form.setFieldsValue({
      ...s_editData,
    });
  }, []);

  // useAPI 取得工單下拉選單
  useEffect(() => {
    const { data, status, msg } = call_getApsOutWoSelect;
    if (status === "suc") {
      message.success(msg);
      console.log("data = ", data);
      set_s_jobN(data?.map((item) => ({ label: item.jobN, value: item.jobN })) || []);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getApsOutWoSelect.status]);

  // useAPI 新增工單
  useEffect(() => {
    const { status, msg } = call_addApsOutMoHd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_addApsOutMoHd.status]);

  // useAPI 編輯工單
  useEffect(() => {
    const { status, msg } = call_updateApsOutMoHd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_updateApsOutMoHd.status]);

  return (
    <Form form={form} onFinish={onSubmit} autoComplete="off" layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preopUUID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item
            label={t("APS.util.opcode")} // 單別
            name="opcode"
            rules={[{ required: true }]}
          >
            <Select disabled={type === "edit"} options={OPCODE_LABELS} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={t("APS.aps-manage.jobN")} // 工單號
            name="jobN"
            rules={[{ required: true }]}
          >
            <Select showSearch options={s_jobN} />
          </Form.Item>
        </Col>
      </Row>

      {Object.keys(s_editData).length === 0 && (
        <div className="tbWrapper">
          <Divider />
          <Row gutter={[36, 12]}>
            <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
              {t("APS.aps-manage.mo") + "明細"}
            </Col>
          </Row>

          <Form.List name="tb" shouldUpdate>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[12, 12]}>
                    <Col span={2} className="mb12">
                      <Form.Item {...field} label={t("util.SN")} name={[field.name, "itemno"]}>
                        <Input disabled value={index + 1} />
                      </Form.Item>
                    </Col>

                    <Form.Item hidden name={[field.name, "pdtNM"]}>
                      <Input />
                    </Form.Item>

                    <Col span={6}>
                      <Form.Item
                        {...field}
                        label={t("util.PN")} // 料號
                        rules={[
                          { required: true, message: t("util.placeholder_select") + t("util.PN") },
                        ]}
                        name={[field.name, "PN"]}
                      >
                        <DebounceSelect
                          key="DebounceSelect"
                          placeholder={t("util.placeholder_input") + " " + t("util.PN")}
                          allowClear
                          fetchOptions={fetchPNList}
                          showSearch
                          maxTagCount={10}
                          onChange={(value, option) => {
                            let _tb = form.getFieldValue("tb");
                            _tb[field.name].PN = option.data.PN;
                            _tb[field.name].pdtNM = option.data.pdtNM;
                            _tb[field.name].idmtype = option.data.idmtype;
                            form.setFieldsValue({ tb: _tb });
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        {...field}
                        label={t("util.idmtype")} // 過帳型態
                        shouldUpdate
                        name={[field.name, "idmtype"]}
                      >
                        <Select disabled>
                          <Select.Option value="S">{t("util.idmtype_s")}</Select.Option>
                          <Select.Option value="D">{t("util.idmtype_d")}</Select.Option>
                          <Select.Option value="M">{t("util.idmtype_m")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb12">
                      <Form.Item
                        {...field}
                        label={t("util.planqty")} // 計畫數量
                        name={[field.name, "planqty"]}
                      >
                        <InputNumber className="w-full" />
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb12">
                      <Form.Item
                        {...field}
                        label={t("APS.util.realqty")} // 實際數量
                        name={[field.name, "realqty"]}
                      >
                        <InputNumber disabled className="w-full" />
                      </Form.Item>
                    </Col>

                    <Col span={2} className="flex items-end">
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                          let formData = form.getFieldValue();
                          formData.tb = formData.tb.map((x, index) => {
                            return { ...x, itemno: index + 1 };
                          });
                          form.setFieldsValue(formData);
                        }}
                        className="text-[30px] mb-8"
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      // let formData = form.getFieldsValue();
                      add({ itemno: fields.length + 1 });
                    }}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    {t("util.add") + t("APS.aps-manage.mo") + t("APS.util.detail")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      )}

      <Divider />

      <Row>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button size="large" onClick={handleCancel}>
              {/* 取消 */}
              {t("util.cancel")}
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              {/* 確定 */}
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
