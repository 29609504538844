import { useContext } from "react";
import { Row, Col, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);

  const { data } = s_drawer;

  const opcode_map = {
    1410: t("OMS.util.opcode_1410"), // 國內訂單
    1420: t("OMS.util.opcode_1420"), // 國外訂單
  };

  const lifeF_map = {
    0: t("OMS.outstock.lifeF_0"), // 發佈
    2: t("OMS.outstock.lifeF_2"), // 發貨覆核
    3: t("OMS.outstock.lifeF_3"), // IQC抽驗
    4: t("OMS.outstock.lifeF_4"), // IQC完驗
    7: t("OMS.outstock.lifeF_7"), // 品保覆核
    T: t("OMS.outstock.lifeF_T"), // 結案
  };
  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 單別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.opcode")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{opcode_map[data.opcode]}</Text>
        </Col>
        {/* 單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.N")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.N}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 客戶ID */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.custID")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.custID}</Text>
        </Col>
        {/* 客戶簡稱 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.custalias")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.custalias}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 客戶名稱 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.custNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.custNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 聯絡人 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.contact")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.contact}</Text>
        </Col>
        {/* 聯絡人電話 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.outstock.tel")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.tel}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 抽樣日期 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.sampleT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.sampleT?.includes("0001-01-01") ? "" : dayjs(data.sampleT).format("YYYY-MM-DD")}
          </Text>
        </Col>
        {/* 創建日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.createT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.createT).format("YYYY-MM-DD")}</Text>
        </Col>
        {/* 歷程時間 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.lifeFT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.lifeFT).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 抽樣人員 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.sampleID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.sampleID)}</Text>
        </Col>
        {/* 創建人員 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.createID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.createID)}</Text>
        </Col>
        {/* 歷程 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.lifeF")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{lifeF_map[data.lifeF]}</Text>
        </Col>
      </Row>

      <Row className="mt-5 h-24 border pms-drawer-border-color">
        {/* 註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.outstock.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
