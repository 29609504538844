const domain = {
  // conn: "http",
  // url: "localhost",
  conn: "https",
  url: "dgzk.gdztc.com.cn",
  // 中耀
  // conn: "https",
  // url: "cpt.satdg.com",
};

const apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":6487" : "/pds"}`,
  // url: `${domain.url}${domain.conn === "http" ? ":5487" : ":22443/pds"}`,
};

export const ims_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":6487" : "/"}`,
  // url: `${domain.url}${domain.conn === "http" ? ":5487" : ":22443/ims"}`,
};

export const pms_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":6485" : "/pms"}`,
  // url: `${domain.url}${domain.conn === "http" ? ":6485" : "/pms"}`,
};

export const oms_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":6486" : "/oms"}`,
  // url: `${domain.url}${domain.conn === "http" ? ":6486" : "/oms"}`,
};

export const bpm_apiBasic = {
  conn: domain.conn,
  url: `${domain.url}${domain.conn === "http" ? ":8989" : "/bpm"}`,
};

export default apiBasic;
