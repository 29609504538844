import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

// 新建表頭資料
export const addQuoHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQuoHD`, data);
};
// 取得表頭資料
export const getQuoHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQuoHD`, { params: data });
};
// 更新表頭資料
export const updateQuoHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQuoHD`, data);
};
// 刪除表頭資料
export const deleteQuoHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQuoHD`, data);
};
// ==================================================================================
// 拋轉銷貨單
export const quoToOrd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/quoToOrd`, data);
};
// ==================================================================================
// 新建表身資料
export const addQuoTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQuoTB`, data);
};
// 取得表身資料
export const getQuoTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQuoTB`, { params: data });
};
// 更新表身資料
export const updateQuoTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQuoTB`, data);
};
// 刪除表身資料
export const deleteQuoTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQuoTB`, data);
};
