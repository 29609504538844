import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { Drawer, Modal, Space, Typography, message } from "antd";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { deletePayTb, getPayTB } from "@/service/apis/PMS/pay";

import CreateModal from "./Modal/CreateModal";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../../index";
// component
import DrawerModal from "./Modal/DrawerModal";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SeeMoreButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
//util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  const call_getPayTB = useAPI(getPayTB);
  const call_deletePayTb = useAPI(deletePayTb);
  const { c_state, updateState } = useContext(DataContext);
  const { searchConfig, lightData } = c_state;
  // const { c_state, updateState } = useContext(DataContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

  useImperativeHandle(ref, () => ({
    Call_hd: () =>
      call_getPayTB.request({
        ...searchConfig,
        opcode: null,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      }),
    onCreate: () => set_s_isShowModal(true),
  }));

  // 開啟查看詳細資料
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    call_getPayTB.request();
  }, []);

  // useAPI 取得對帳作業表頭
  useEffect(() => {
    const { status, msg, data } = call_getPayTB;

    if (status === "suc") {
      message.success(msg);
      const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID }));
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getPayTB.status]);

  const tableColumns = [
    {
      // title: "採購單號",
      title: t("OMS.util.N13"),
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      // title: "採購單號",
      title: t("PMS.util.N23"),
      dataIndex: "N23",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      dataIndex: "dealN",
      width: "10%",
      align: "center",
    },
    {
      title: t("PMS.util.suppNM"), // 客戶單號
      dataIndex: "suppNM",
      width: "10%",
      align: "center",
    },
    {
      title: t("OMS.util.signT"), // 完成委託日期
      dataIndex: "signT",
      width: "10%",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "8%",
      render: (text) => getStaffName(text),
    },

    {
      title: t("OMS.ord.payment"), // 付款方式
      dataIndex: "payment",
      align: "center",
      width: "8%",
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "8%",
      render: (text) => t(`OMS.ap-manage.rcv_lifeF_${text}`),
    },
    {
      title: t("OMS.ap-manage.rcv_subtotal"), // 金額
      dataIndex: "subtotal",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: "是否一般采购",
      dataIndex: "ispro",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton
            // disabled={rowData.lifeF === "0" && rowData.isauto === false ? false : true}
            disabled={true}
            onClick={(e) => removeData(e, rowData)}
          />

          {/* 編輯 */}
          <EditButton
            // disabled={rowData.lifeF === "0" && rowData.isauto === false ? false : true}
            disabled={true}
            onClick={(e) => editData(e, rowData)}
          />
        </Space>
      ),
    },
  ];

  const editData = (event, rowData) => {
    set_s_editData(rowData);
    set_s_isShowModal(true);
    event.stopPropagation();
  };

  const removeData = (event, rowData) => {
    Modal.confirm({
      title: t("PMS.util.pay") + t("PMS.util.delete_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: "请先确认要删除的应付资料无误，再进行删除",
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deletePayTb.request(rowData),
    });
    event.stopPropagation();
  };

  useEffect(() => {
    const { status, msg } = call_deletePayTb;
    if (status === "suc") {
      message.success(msg);
      call_getPayTB.request();
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deletePayTb.status]);

  return (
    <div className="flex-1">
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        onRow={(record) => {
          return {
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => openDetail(event, record),
          };
        }}
      />

      <CustomModal
        title={Object.keys(s_editData).length > 0 ? "编辑应付资料" : "新增应付资料"}
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getPayTB.request();
        }}
      >
        <CreateModal
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 對帳資料主檔 */}
            {t("PMS.ap-manage.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={900}
        open={s_drawer.show}
        onClose={() => set_s_drawer({ show: false, data: {} })}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
});

export default HD;
