// util function
import { formatAmount } from "@/util/format";
import React, { useContext } from "react";
import { PrintText } from "./index";

const Container = (props) => {
  const { hd_values, pageData, currentPage, totalPages, s_ordTb } = props;

  console.log("props  = ", props);

  let group = pageData.reduce((prev, curr) => {
    if (!prev[curr.note]) {
      prev[curr.note] = [curr];
    } else {
      prev[curr.note].push(curr);
    }
    return prev;
  }, {});

  //樣本所需數量qty、檢測樣品郵寄需求require、附加信息info
  const AddContent = (props) => {
    const { str } = props;
    //從後端拿到的statement會是一個大字串，每個小字串用\n隔開，要將內容轉成陣列
    const stringCut = s_ordTb?.adda?.find((item) => item.statement === str)?.note?.split("\n");
    console.log("stringCut = ", stringCut);

    const rowData = stringCut?.map((item) => {
      return <div className="flex justify-center items-center w-full break-all">{item}</div>;
    });

    return rowData;
  };

  return (
    <div className="flex flex-col w-full box-border font-normal">
      {/* 表頭 */}
      <div className="flex w-full leading-6 border-solid border-t-0 border divide-solid divide-x divide-y-0 box-border">
        <PrintText className="flex justify-center items-center w-[5%]">编号</PrintText>
        <PrintText className="flex justify-center items-center w-[15%] ">样品名称</PrintText>
        <PrintText className="flex justify-center items-center w-[20%] ">检测项目</PrintText>
        <PrintText className="flex justify-center items-center w-[15%] ">测试方法</PrintText>
        <PrintText className="flex justify-center items-center w-[12%] ">计划工作日</PrintText>
        <PrintText className="flex justify-center items-center w-[15%] ">测试费</PrintText>
        <PrintText className="flex justify-center items-center w-[8%] ">数量</PrintText>
        <PrintText className="flex justify-center items-center w-[10%]">金额</PrintText>
      </div>

      {/* 表格內容 */}
      {Object.entries(group)?.map((item, index) => (
        <div
          key={index}
          className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-center "
        >
          {/* 編號*/}
          <PrintText className="flex justify-center items-center w-[5%]">{index + 1}</PrintText>
          {/* 樣品名稱*/}
          <PrintText className="flex justify-center items-center w-[15%]" key={item.pdtNM}>
            {item[0]}
          </PrintText>

          <div className="flex flex-col justify-center flex-nowrap items-center w-[80%] divide-solid divide-y divide-x-0">
            {item[1].map((data) => {
              return (
                <div className="flex flex-wrap justify-center items-center w-full divide-solid divide-y-0 divide-x-[1px]">
                  <PrintText className="flex justify-center items-center w-[25%] text-xs h-full leading-8">
                    {data?.pdtNM}
                  </PrintText>
                  <PrintText className="flex justify-center items-center w-[18.75%] text-xs h-full leading-8">
                    {" "}
                    {data?.pdtspec}
                  </PrintText>
                  <PrintText className="flex justify-center items-center w-[15%] text-xs h-full leading-8">
                    {" "}
                    {data?.planday}
                  </PrintText>
                  <PrintText className="flex justify-center items-center w-[18.75%] text-xs h-full leading-8">
                    {" "}
                    {formatAmount(data?.unitprice)}
                  </PrintText>
                  <PrintText className="flex justify-center items-center w-[10%] text-xs h-full leading-8">
                    {" "}
                    {formatAmount(data?.demandqty)}
                  </PrintText>
                  <PrintText className="flex justify-center items-center w-[12.5%] text-xs h-full leading-8">
                    {" "}
                    {formatAmount(data?.itemsum)}
                  </PrintText>
                </div>
              );
            })}
          </div>
        </div>
      )) ?? undefined}
      {currentPage !== totalPages - 1 && (
        <PrintText className="flex justify-end text-[12px] pt-1">续下页...</PrintText>
      )}

      {/* 最後一頁才顯示 */}
      {currentPage === totalPages - 1 && (
        <div className="flex-1 flex-col w-full">
          <div className="flex w-full">
            <PrintText className="flex justify-end items-center w-[90%] border-solid border-t-0  border-[1px] leading-7">
              合计
            </PrintText>
            <PrintText className="flex justify-center items-center w-[10%] border-solid border-t-0 border-l-0 border-[1px] leading-7">
              {formatAmount(hd_values.sumtotal)}
            </PrintText>
          </div>

          {/* <div className="flex w-full">
            <div className="flex justify-center items-center w-[5%] border-solid border-t-0  border-[1px] leading-7">
            注记
            </div>
            <div className="flex justify-start items-center w-[95%] border-solid border-t-0 border-l-0 border-[1px] leading-7 pl-2 break-all">
              {hd_values.note}
            </div>
          </div> */}

          <div className="flex justify-center items-center w-full h-[20px]" />

          <div className="flex flex-col w-full border-solid  border-[1px]">
            <div className="flex justify-center w-full leading-8 border-solid  border-x-0 border-y-0 border-b-[1px] divide-solid divide-x divide-y-0">
              <PrintText className="flex justify-center items-center w-[20%]">
                检测所需样本数量
              </PrintText>
              <div className="flex flex-col justify-center items-center w-[35%] min-h-[2rem] break-all">
                <AddContent str={"qty"} />
              </div>
              <PrintText className="flex justify-center items-center w-[17%] flex-grow-[2]">
                检测样品邮寄需求
              </PrintText>
              <div className="flex flex-col justify-center items-center w-[28%] min-h-[2rem] break-all">
                <AddContent str={"require"} />
              </div>
            </div>

            <div className="flex justify-center w-full leading-8  divide-solid divide-x divide-y-0">
              <PrintText className="flex justify-center items-center w-[20%]">
                检测报告附加信息
              </PrintText>
              <div className="flex flex-col justify-center items-center w-[35%] min-h-[2rem] break-all">
                <AddContent str={"info"} />
              </div>
              <PrintText className="flex justify-center items-center w-[17%] flex-grow-[2]">
                注记
              </PrintText>
              <PrintText className="flex flex-col justify-center items-center w-[28%] min-h-[2rem] break-all">
                {hd_values?.note}
              </PrintText>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-center items-center mt-5 w-full text-xs ">
        <PrintText className="flex justify-center items-center">
          地址: 广东省东莞市厚街镇莞太路厚街段216号106室
        </PrintText>
        <PrintText className="flex justify-center items-center ml-4">电话: 0769-82259653</PrintText>
      </div>
    </div>
  );
};

export default Container;
