import { pms_apiBasic as apiBasic } from "../../APIBasic";
import { instance } from "../../handlerAPI";

// 取得對帳單表頭資料
export const getPayHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPayHD`, {
    params: data,
  });
};

// 取得對帳單表身資料
export const getPayTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPayTB`, {
    params: data,
  });
};

// 編輯對帳單資料
export const modifyPayHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePayHD`, data);
};
export const addPayTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPayTb`, data);
};
export const updatePayTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePayTb`, data);
};
export const deletePayTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePayTb`, data);
};
