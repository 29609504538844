/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Button, Space, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
// import StockModal from '../Modal/StockModal';
// component
import TbContainer from "../Details/TbContainer";

const DetailContainer = (props) => {
  const { t } = useTranslation();
  const { s_disabled, is_editForm, set_is_editForm, call_updateFodHD, hd_values } = props;
  // CustomTabs 目前被選中的選項
  const [s_tabKey, set_s_tabKey] = useState("details");
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });
  const tabsData = [
    { key: "details", label: t("OMS.fod.tb") }, // 預訂明細
    // { key: "adda", label: "條款註記" },
    // { key: "addb", label: "加扣款項" },
    // { key: "addc", label: "預付作業" },
  ];

  const flagMap = {
    dealN: t("OMS.util.dealN"),
    custID: t("OMS.util.custID"),
    startEDT: t("OMS.ord.startEDT"),
    endEDT: t("OMS.ord.endEDT"),
    isvalid: t("OMS.quo.isvalid"),
    taxtype: t("OMS.util.taxtype"),
    exchangeD: t("OMS.util.exchangeD"),
    exchangerate: t("PMS.util.exchangerate"),
    currencytype: t("OMS.util.currencytype"),
    driID: t("OMS.ord.driID"),
    note: t("OMS.ord.note"),
    payment: t("OMS.ord.payment"),
    trade: t("OMS.ord.trade"),
  };
  // 新建資料
  const createData = () => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => flagMap[item] ?? item)?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: t("OMS.util.submit"), // 確定
        onOk: async () => {
          // 更新表頭
          call_updateFodHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          // set_s_tbModal({ show: false, data: {} });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: {} });
  };
  // 更新表頭的API
  // useEffect(() => {
  //   const { status, msg } = call_updateFodHD;
  //   if (status === "suc") {
  //     message.success(msg);
  //     // 更新完成後打開跳顯讓使用者編輯
  //     set_s_tbModal({ show: true, data: {} });
  //     // 清除編輯記錄
  //     set_is_editForm({ isEdit: false, editFlag: [] });
  //   }
  //   if (status === "err") {
  //     message.error(msg);
  //   }
  // }, [call_updateFodHD.status]);
  return (
    <div className="flex flex-col flex-1">
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={s_tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => set_s_tabKey(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <CreateButton
              // onClick={() => set_s_tbModal({ show: true, data: {} })}
              onClick={createData}
              disabled={s_disabled.createBtn}
            />
          </Space>
        }
      />

      {s_tabKey === "details" ? (
        <TbContainer {...props} s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}
    </div>
  );
};

export default DetailContainer;
