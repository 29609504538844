import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Input, Button, Typography, Space, Spin } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from "@/util/format"; //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import { getShipped } from "@/service/apis/OMS/Ord";
import useAPI from "@/hooks/useAPI";

const { Text } = Typography;

//訂單未出貨明細表
const UnShipped = () => {
  const { RangePicker } = DatePicker;

  const { c_custs } = useContext(ERPContext); //從ERPContext拿到客戶資料

  const { t } = useTranslation();

  const call_getShipped = useAPI(getShipped); // useAPI 取得訂單出貨狀態資料

  const [s_OriData, set_s_OriData] = useState([]); //api拿到的原始資料

  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料

  const [is_spin, set_is_spin] = useState(false);

  const [dateRangeType, setDateRangeType] = useState("month"); //搜尋區間: 當日、當週、當月

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    opcode: "", //單別
    shippedType: "", //已出貨/未出貨  是否有下位資料(出貨單)，outstockCount > 0 or outstockCount = 0
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
    dealN: "", //客戶PO號
    custID: [], //客戶ID
    PN: "", //料號
    N: "", //訂單單號
  });

  const tableColumns = [
    {
      title: "訂單單號",
      dataIndex: "N",
      align: "center",
      onCell: (record, _) => {
        return {
          rowSpan: record.rowSpan === undefined ? 0 : record.rowSpan,
        };
      },
      width: "8%",
    },
    {
      title: "單別",
      dataIndex: "opcode",
      align: "center",
      width: "8%",
      render: (text) => (text === undefined ? null : t(`OMS.util.opcode_${text}`)),
      onCell: (record) => {
        return {
          rowSpan: record.rowSpan === undefined ? 0 : record.rowSpan,
        };
      },
    },
    {
      title: "客戶簡稱",
      dataIndex: "custalias",
      align: "left",
      onCell: (record, _) => {
        return {
          rowSpan: record.rowSpan === undefined ? 0 : record.rowSpan,
        };
      },
      width: "12%",
    },
    {
      title: "客戶單號",
      dataIndex: "dealN",
      align: "center",
      width: "8%",
      onCell: (record) => {
        return {
          rowSpan: record.rowSpan === undefined ? 0 : record.rowSpan,
        };
      },
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
      width: "15%",
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "center",
      width: "5%",
      render: (text) =>
        text === "合計" ? <div className="text-black font-bold">{text}</div> : text,
    },
    {
      title: "訂單數量",
      dataIndex: "confirmqty",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return record.unit === "合計" ? (
          <div className="text-black font-bold">{formatAmount(text)}</div>
        ) : (
          formatAmount(text)
        );
      },
    },
    {
      title: "已交量",
      dataIndex: "transferqty",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return record.unit === "合計" ? (
          <div className="text-black font-bold">{formatAmount(text)}</div>
        ) : (
          formatAmount(text)
        );
      },
    },
    {
      title: "未交量",
      dataIndex: "balqty",
      align: "right",
      width: "6%",
      render: (text, record) => {
        return record.unit === "合計" ? (
          <div className="text-black font-bold">{formatAmount(text)}</div>
        ) : (
          formatAmount(text)
        );
      },
    },
    {
      title: "預計交貨日(起)",
      dataIndex: "startEDT",
      align: "center",
      render: (text, record) => (record.unit === "合計" ? null : dayjs(text).format("YYYY-MM-DD")),
      width: "8%",
    },
    {
      title: "預計交貨日(迄)",
      dataIndex: "endEDT",
      align: "center",
      render: (text, record) => (record.unit === "合計" ? null : dayjs(text).format("YYYY-MM-DD")),
      width: "8%",
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = {
      ...s_searchData,
      custID: s_searchData.custID.join("|"), //要將客戶陣列轉成字串，用直槓隔開
    };
    setDateRangeType(null);
    call_getShipped.request(tmp);
  };

  const handleDateRangeTypeChange = (type) => {
    setDateRangeType(type);
    call_getShipped.request({
      ...s_searchData,
      custID: s_searchData.custID.join("|"),
      startDate: dayjs().startOf(type).format("YYYY-MM-DD"), // 開始日期
      endDate: dayjs().endOf(type).format("YYYY-MM-DD"), // 結束日期
    });
    set_s_searchData({
      ...s_searchData,
      startDate: dayjs().startOf(type).format("YYYY-MM-DD"),
      endDate: dayjs().endOf(type).format("YYYY-MM-DD"),
    });
  };

  //----------------------------導出EXCEL---------------------------------------------------

  const exportToExcel = () => {
    const excelTable = s_TableData.map((item) => {
      //移除s_TableData 的rowSpan
      if ("rowSpan" in item) {
        delete item.rowSpan;
      }
      //時間格式轉換，數字加上千分位
      return {
        ...item,
        confirmqty: formatAmount(item.confirmqty),
        transferqty: formatAmount(item.transferqty),
        balqty: formatAmount(item.balqty),
        startEDT: dayjs(item.startEDT).format("YYYY-MM-DD"),
        endEDT: dayjs(item.endEDT).format("YYYY-MM-DD"),
      };
    });

    //合併儲存格，N、suppalias如果跟上一筆資料相同就合併儲存格

    let mergeCells = [];
    for (let i = 0; i < excelTable.length; i++) {
      if (
        excelTable[i].N === excelTable[i - 1]?.N ||
        excelTable[i].opcode === excelTable[i - 1]?.opcode ||
        excelTable[i].custalias === excelTable[i - 1]?.custalias ||
        excelTable[i].dealN === excelTable[i - 1]?.dealN
      ) {
        if (excelTable[i].N === excelTable[i - 1]?.N) {
          mergeCells.push({ s: { r: i, c: 0 }, e: { r: i + 1, c: 0 } });
        }
        if (excelTable[i].opcode === excelTable[i - 1]?.opcode) {
          mergeCells.push({ s: { r: i, c: 1 }, e: { r: i + 1, c: 1 } });
        }
        if (excelTable[i].custalias === excelTable[i - 1]?.custalias) {
          mergeCells.push({ s: { r: i, c: 2 }, e: { r: i + 1, c: 2 } });
        }
        if (excelTable[i].dealN === excelTable[i - 1]?.dealN) {
          mergeCells.push({ s: { r: i, c: 3 }, e: { r: i + 1, c: 3 } });
        }
      }
    }

    //ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excelTable, {
      header: [
        "N",
        "opcode",
        "custalias",
        "dealN",
        "PN",
        "pdtNM",
        "unit",
        "confirmqty",
        "transferqty",
        "balqty",
      ],
    });
    const wb = XLSX.utils.book_new(); //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); //活頁簿wb添加資料表Sheet1，資料為ws

    // 计算每列的最大宽度
    const colWidths = s_TableData[0] && Object.keys(s_TableData[0]).map(() => 0);
    s_TableData.forEach((row) => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        console.log("cellValue", cellValue);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map((w) => ({ wch: w + 7 })); // 根据最大长度调整列宽
    ws["!cols"] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title; //excel從A欄開始，Unicode的65就是A
    }

    ws["!merges"] = mergeCells;
    const today = dayjs().format("YYYY-MM-DD");

    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `訂單未出貨明細表${today}.xlsx`);
  };

  //----------------------------EXCEL---------------------------------------------------

  //所有訂單表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getShipped;
    if (status === "suc") {
      console.log("call_getShipped = ", data);
      set_s_OriData(data);
      message.success(msg);
    } else if (status === "err") {
      set_s_OriData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getShipped.status]);

  useEffect(() => {
    if (call_getShipped.status === "load") {
      set_is_spin(true);
    } else {
      set_is_spin(false);
    }
  }, [call_getShipped.status]);

  //拿到原始資料後做分組，將資料組成要的格式再塞到表格
  useEffect(() => {
    //group 方法.
    const groupResult = s_OriData.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.N]) {
        accumulator[currentValue.N] = [];
      }
      accumulator[currentValue.N].push(currentValue);
      return accumulator;
    }, {});

    //取出各群組中的值
    const groupArr = Object.values(groupResult);

    //-----------------加上合併欄位數、總計列-------------------------------------

    const CountArr = groupArr.map((item) => {
      const count = item.reduce(
        (accumulator, currentValue) => {
          accumulator.confirmqty += currentValue.confirmqty;
          accumulator.transferqty += currentValue.transferqty;
          accumulator.balqty += currentValue.balqty;
          return accumulator;
        },
        {
          unit: "合計",
          confirmqty: 0,
          transferqty: 0,
          balqty: 0,
        }
      );

      item.push(count); //加上合計欄
      item[0].rowSpan = item.length; //第一筆資料加上合併欄位數字

      return item;
    });

    const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列
    console.log("finalArr", finalArr);

    set_s_TableData(finalArr);
  }, [s_OriData]);

  return (
    <div className="flex flex-col flex-1 w-full">
      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4" />

      {/*表頭*/}

      <PageHeader title="訂單未出明細表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">
        {/*導出excel*/}

        <Button type="primary" onClick={() => exportToExcel()}>
          導出excel
        </Button>

        <Text>單別：</Text>

        <Select
          className="min-w-[100px]"
          placeholder="請選擇單別"
          name="opcode"
          onChange={(value) => handle_SearchData_Change("opcode", value)}
          value={s_searchData.opcode}
          options={[
            { label: "全部", value: "" },
            { label: "國內訂單", value: "1310" },
            { label: "國外訂單", value: "1320" },
          ]}
        />

        <Text>訂單出貨狀態：</Text>

        <Select
          className="min-w-[100px]"
          placeholder="請選擇訂單狀態"
          name="shippedType"
          onChange={(value) => handle_SearchData_Change("shippedType", value)}
          value={s_searchData.shippedType}
          options={[
            { label: "全部", value: "" },
            { label: "已出貨", value: "shipped" },
            { label: "未出貨", value: "unshipped" },
          ]}
        />

        {/*訂單單號*/}
        <Input
          className="max-w-[150px]"
          placeholder="請輸入訂單單號"
          name="N"
          onChange={(e) => handle_SearchData_Change("N", e.target.value)}
          value={s_searchData.N}
        />

        {/*客戶單號*/}
        <Input
          className="max-w-[150px]"
          placeholder="請輸入客戶單號"
          name="dealN"
          onChange={(e) => handle_SearchData_Change("dealN", e.target.value)}
          value={s_searchData.dealN}
        />

        {/*客戶選擇*/}
        <Select
          className="min-w-[200px]"
          name="custID"
          mode="multiple"
          showSearch
          placeholder="請選擇客戶"
          onChange={(value) => handle_SearchData_Change("custID", value)}
          options={c_custs.options}
        />

        {/*料號*/}
        <Input
          placeholder="請輸入料號"
          name="PN"
          onChange={(e) => handle_SearchData_Change("PN", e.target.value)}
          value={s_searchData.PN}
        />

        {/*預交日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button
          className={`${dateRangeType === "day" ? "bg-[#6CA0EE]" : null}`}
          type="primary"
          onClick={() => handleDateRangeTypeChange("day")}
        >
          當日
        </Button>

        <Button
          className={`${dateRangeType === "week" ? "bg-[#6CA0EE]" : null}`}
          type="primary"
          onClick={() => handleDateRangeTypeChange("week")}
        >
          當周
        </Button>

        <Button
          className={`${dateRangeType === "month" ? "bg-[#6CA0EE]" : null}`}
          type="primary"
          onClick={() => handleDateRangeTypeChange("month")}
        >
          當月
        </Button>

        <Button
          className={`${dateRangeType === null ? "bg-[#6CA0EE]" : null}`}
          type="primary"
          onClick={handleClick}
        >
          查詢訂單
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};

export default UnShipped;
