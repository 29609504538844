import { instance } from "../../handlerAPI";
import { ims_apiBasic } from "@/service/APIBasic";

export const daily = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/daily`, {
    params: _params,
  });
};

export const dailyDetail = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/dailyDetail`, {
    params: _params,
  });
};

//庫存彙總表
export const getInvPrice = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getInvPrice`, {
    params: _params,
  });
};
