import IconBox from "./IconBox";

import { ReactComponent as PDSFontSizeSm } from "@/assets/icon/Header/icon-PDS-fontSize-sm.svg";
import { ReactComponent as ERPFontSizeSm } from "@/assets/icon/Header/icon-ERP-fontSize-sm.svg";
import { ReactComponent as ERPFontSizeMd } from "@/assets/icon/Header/icon-ERP-fontSize-md.svg";
import { ReactComponent as ERPFontSizeLg } from "@/assets/icon/Header/icon-ERP-fontSize-lg.svg";
import { ReactComponent as PDSFontSizeMd } from "@/assets/icon/Header/icon-PDS-fontSize-md.svg";
import { ReactComponent as PDSFontSizeLg } from "@/assets/icon/Header/icon-PDS-fontSize-lg.svg";

// PDS 字體切換(小)
export const IconPDSFontSizeSm = (props) => <IconBox {...props} component={PDSFontSizeSm} />;
// PDS 字體切換(中)
export const IconPDSFontSizeMd = (props) => <IconBox {...props} component={PDSFontSizeMd} />;
// PDS 字體切換(大)
export const IconPDSFontSizeLg = (props) => <IconBox {...props} component={PDSFontSizeLg} />;

// ERP 字體切換(小)
export const IconERPFontSizeSm = (props) => <IconBox {...props} component={ERPFontSizeSm} />;
// ERP 字體切換(中)
export const IconERPFontSizeMd = (props) => <IconBox {...props} component={ERPFontSizeMd} />;
// ERP 字體切換(大)
export const IconERPFontSizeLg = (props) => <IconBox {...props} component={ERPFontSizeLg} />;
