import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Input, Button, Typography, Space, InputNumber } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from "@/util/format"; //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButton";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import useAPI from "@/hooks/useAPI";
import { getAllWhID } from "@/service/apis/WMS/WMS";
import { cloneWith } from "lodash";
// import { getDiff } from "@/service/apis/PMS/Pro";

const { Text } = Typography;
const { RangePicker } = DatePicker;

//庫存異動明細帳
const InventoryDiff = () => {

  const [excel_TableData, set_excel_TableData] = useState([]); //產出excel的資料
  const [groupArr, set_groupArr] = useState([]);                //用料號組成的群組資料
  const { t } = useTranslation();
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    PN: [], //料號(多選，call api時要把陣列轉成字串
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),   // 異動日期(起)
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),       // 異動日期(迄)
  });

  const { c_PNs } = useContext(ERPContext); //從ERPContext拿到料號資料
  const call_getAllWhID = useAPI(getAllWhID);


  const [s_TableData, set_s_TableData] = useState([
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/13',
      N: '銷貨20230313001',
      whNM: "公司倉",
      transferqty: -10,
      unitcost: 100,
      cost: -1000,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/13',
      N: '進貨20230313002',
      whNM: "公司倉",
      transferqty: 20,
      unitcost: 100,
      cost: 2000,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/14',
      N: '銷貨20230314001',
      whNM: "公司倉",
      transferqty: -10,
      unitcost: 100,
      cost: -1000,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/15',
      N: '進貨20230315001',
      whNM: "公司倉",
      transferqty: 50,
      unitcost: 100,
      cost: 5000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/13',
      N: '銷貨20230313001',
      whNM: "公司倉",
      transferqty: -50,
      unitcost: 50,
      cost: -2500,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/14',
      N: '進貨20230314001',
      whNM: "公司倉",
      transferqty: 20,
      unitcost: 50,
      cost: 1000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/14',
      N: '銷貨20230314002',
      whNM: "公司倉",
      transferqty: -40,
      unitcost: 50,
      cost: -2000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/15',
      N: '進貨20230315001',
      whNM: "公司倉",
      transferqty: 30,
      unitcost: 50,
      cost: 1500,
    },

  ]); //放到表格的資料


  const tableColumns = [
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
      width: '15%'
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "center",
      width: '10%'
    },
    {
      title: "日期",
      dataIndex: "createT",
      align: "center",
      width: '10%'
    },
    {
      title: "單號",
      dataIndex: "N",
      align: "center",
      width: '10%'
    },
    {
      title: "倉庫",
      dataIndex: "whNM",
      align: "center",
      width: '5%'
    },
    {
      title: "異動數量",
      dataIndex: "transferqty",
      align: "right",
      width: "10%",
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "單位成本",
      dataIndex: "unitcost",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: "成本",
      dataIndex: "cost",
      align: "right",
      width: '10%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
  ];


  const excelData = [
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      // createT: null,
      // N: null,
      whNM: "期初",
      transferqty: 0,
      unitcost: 0,
      cost: 0,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/13',
      N: '銷貨20230313001',
      whNM: "公司倉",
      transferqty: -10,
      unitcost: 100,
      cost: -1000,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/13',
      N: '進貨20230313002',
      whNM: "公司倉",
      transferqty: 20,
      unitcost: 100,
      cost: 2000,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/14',
      N: '銷貨20230314001',
      whNM: "公司倉",
      transferqty: -5,
      unitcost: 100,
      cost: -500,
    },
    {
      PN: 'PN-000001',
      pdtNM: '品名0000001',
      unit: '頂',
      createT: '2023/03/15',
      N: '進貨20230315001',
      whNM: "公司倉",
      transferqty: 30,
      unitcost: 100,
      cost: 3000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      // createT: '2023/03/16',
      // N: '銷貨20230316001',
      whNM: "期初",
      transferqty: 0,
      unitcost: 0,
      cost: 0,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/16',
      N: '銷貨20230316001',
      whNM: "公司倉",
      transferqty: -40,
      unitcost: 40,
      cost: -1600,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/16',
      N: '進貨20230316001',
      whNM: "公司倉",
      transferqty: 60,
      unitcost: 50,
      cost: 3000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/17',
      N: '銷貨20230317001',
      whNM: "公司倉",
      transferqty: -20,
      unitcost: 50,
      cost: -1000,
    },
    {
      PN: 'PN-000002',
      pdtNM: '品名0000002',
      unit: '件',
      createT: '2023/03/17',
      N: '進貨20230317002',
      whNM: "公司倉",
      transferqty: 25,
      unitcost: 50,
      cost: 1250,
    },
    {
      PN: 'PN-000003',
      pdtNM: '品名0000003',
      unit: '桶',
      // createT: '2023/03/16',
      // N: '銷貨20230316001',
      whNM: "期初",
      transferqty: 50,
      unitcost: 10,
      cost: 500,
    },
    {
      PN: 'PN-000003',
      pdtNM: '品名0000003',
      unit: '桶',
      createT: '2023/04/01',
      N: '進貨20230401001',
      whNM: "公司倉",
      transferqty: 100,
      unitcost: 10,
      cost: 1000,
    },
    {
      PN: 'PN-000003',
      pdtNM: '品名0000003',
      unit: '桶',
      createT: '2023/04/02',
      N: '銷貨20230402001',
      whNM: "公司倉",
      transferqty: -40,
      unitcost: 10,
      cost: -400,
    },
    {
      PN: 'PN-000003',
      pdtNM: '品名0000003',
      unit: '桶',
      createT: '2023/04/02',
      N: '銷貨20230402002',
      whNM: "公司倉",
      transferqty: -10,
      unitcost: 10,
      cost: -100,
    },
    {
      PN: 'PN-000003',
      pdtNM: '品名0000003',
      unit: '桶',
      createT: '2023/04/04',
      N: '進貨20230404001',
      whNM: "公司倉",
      transferqty: 10,
      unitcost: 10,
      cost: 100,
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = { ...s_searchData, PN: s_searchData.PN.join("|") }; //要將料號陣列轉成字串，用逗號隔開
    console.log("tmp",tmp);
    // call_getDiff.request(tmp);
  }


  //根據料號組成資料群組
  const groupFunction = (arr) => {

    //將料號組成群組，groupResult = { PN-000001: (6) [{…}, {…}, {…}, {…}, {…}, {…}],PN-000002: (6) [{…}, {…}, {…}, {…}, {…}, {…}] }
    const groupResult = arr.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.PN]) {
        accumulator[currentValue.PN] = [];
      }
      accumulator[currentValue.PN].push(currentValue);
      return accumulator;
    }, {})

    //取出groupResult物件的值，也就是陣列
    // console.log("groupArr === ", groupArr);   //[arr(6),arr(6)]
    const group = Object.values(groupResult);


    //將群組內的每個陣列末端加上一個合計欄
    const CountArr = group.map((item) => {
      //累加器
      const count = item.reduce(
        (accumulator, currentValue) => {
          accumulator.transferqty += currentValue.transferqty;
          accumulator.unitcost += currentValue.unitcost;
          accumulator.cost += currentValue.cost;
          return accumulator;
        },
        {
          whNM: "合計",
          transferqty: 0,
          unitcost: 0,
          cost: 0,
        }
      );

      item.push(count); //加上合計欄
      return item;
    });

    const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列

    set_excel_TableData(finalArr); //匯出的excel資料
    set_groupArr(group);  //資料群組，主要是利用群組的各陣列長度來計算合併的儲存格數量
  }

  //----------------------------EXCEL---------------------------------------------------

  //待完成導出excel功能
  const exportToExcel = () => {

    const excel_out_Data = excel_TableData.map((item) => {
      //時間格式轉換，數字加上千分位
      return {
        ...item,
        transferqty: formatAmount(item.transferqty),
        unitcost: formatAmount(item.unitcost),
        cost: formatAmount(item.cost),
        // createT: dayjs(item.startEDT).format("YYYY-MM-DD"),
      }
    })

    //料號、品名、單位，合併儲存格，利用groupArr的各群陣列長度來判斷合併的儲存格數量，假設groupArr = {arr(5),arr(3),arr(4)}
    //這樣即可知道合併的欄位數

    let mergeCells = [];
    console.log("groupArr.length", groupArr.length);
    for (let j = 0; j < groupArr.length; j++) {

      for (let i = 1 + (j === 0 ? 0 : groupArr.slice(0, j).reduce((acc, cur) => acc + cur.length, 0)); i < excel_TableData.length; i += groupArr[j].length) {

        if (excel_TableData[i].PN === excel_TableData[i - 1].PN) {
          mergeCells.push({ s: { r: i, c: 0 }, e: { r: i + groupArr[j].length - 2, c: 0 } });
        }

        if (excel_TableData[i].pdtNM === excel_TableData[i - 1].pdtNM) {
          mergeCells.push({ s: { r: i, c: 1 }, e: { r: i + groupArr[j].length - 2, c: 1 } });
        }

        if (excel_TableData[i].unit === excel_TableData[i - 1].unit) {
          mergeCells.push({ s: { r: i, c: 2 }, e: { r: i + groupArr[j].length - 2, c: 2 } });
        }

        if (excel_TableData[i].createT === excel_TableData[i - 1].createT) {
          mergeCells.push({ s: { r: i, c: 3 }, e: { r: i + 1, c: 3 } });
        }
      }
    }


    //日期欄合併儲存格，因為合併邏輯不同，所以分開寫
    for (let j = 0; j < excel_TableData.length; j++) {
      //如果createT相同，合并单元格
      if (excel_TableData[j].createT && excel_TableData[j - 1].createT && excel_TableData[j].createT === excel_TableData[j - 1].createT) {
        mergeCells.push({ s: { r: j, c: 3 }, e: { r: j + 1, c: 3 } });
      }
    }


    // ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excel_out_Data, { header: ["PN", "pdtNM", "unit", "createT", "N", "whNM", "transferqty", "unitcost", "cost"] });
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    const ww = excel_TableData[0] && Object.keys(excel_TableData[0]).map(()=>0)

    excel_TableData.forEach(row => {
      Object.keys(row).forEach((key,index)=> {
        console.log("key",key);
        const cellvalue = row[key].toString();
        console.log("cellvalue",cellvalue);
        if(cellvalue.length > ww[index]){
          ww[index] = cellvalue.length;
        }
      })
    })

    const wscols = ww.map((w) => ({wch:w+5}))

    ws['!cols'] =wscols;
    // // 计算每列的最大宽度
    // const colWidths = excel_TableData[0] && Object.keys(excel_TableData[0]).map(() => 0);
    // excel_TableData.forEach(row => {
    //   Object.keys(row).forEach((key, index) => {
    //     const cellValue = row[key].toString();
    //     console.log("cellValue",cellValue);
    //     if (cellValue.length > colWidths[index]) {
    //       colWidths[index] = cellValue.length;
    //     }
    //   });
    // });

    // // 设置列宽
    // const wscols = colWidths.map(w => ({ wch: w + 5 })); // 根据最大长度调整列宽
    // ws['!cols'] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }

    ws["!merges"] = mergeCells;


    const today = dayjs().format("YYYY-MM-DD");

    // 輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `庫存異動明細帳${today}.xlsx`);
  }
  //----------------------------EXCEL---------------------------------------------------


  useEffect(() => {
    groupFunction(excelData) //將原始資料拿去組成群組
  }, [])

  useEffect(() => {
    call_getAllWhID.request();
  }, []);

  useEffect(() => {
    if (call_getAllWhID.status === "suc") {
      message.success(call_getAllWhID.msg);
    } else if (call_getAllWhID.status === "err") {
      message.error(t(`errorCode.${call_getAllWhID.msg}`));
    }
  }, [call_getAllWhID.status]);

  //所有採購調幅資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getDiff;
  //   if (status === "suc") {
  //     console.log("call_getDiff = ", data);
  //     set_s_TableData(data);
  //     message.success(msg);
  //   } else if (status === "err") {
  //     set_s_TableData([]);
  //     message.error(msg);
  //   }
  // }, [call_getDiff.status]);

  return (
    <div className="flex flex-col flex-1 w-full">
      {/*表頭*/}

      <PageHeader title="庫存異動明細帳" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">
        {/*導出excel*/}


        <Button
          type="primary"
          onClick={() => exportToExcel()}
        >
          導出excel
        </Button>


        <Text>料號：</Text>
        <Select
          mode="multiple"
          name="PN"
          className="min-w-[500px]"
          showSearch
          placeholder="請填寫料號"
          onChange={(value) => {
            handle_SearchData_Change("PN", value);
          }}
          options={c_PNs.options}
        />

        <PNButton />

        <Text>倉別：</Text>
        <Select
          name='wdID'
          allowClear
          className="min-w-[100px]"
          showSearch
          placeholder="請選擇倉別"
          onChange={(value) => { handle_SearchData_Change("whID", value) }}
          options={
            call_getAllWhID.data?.map((x) => ({
              label: x.whNM,
              value: x.whID,
            })) || []
          }
        />


        {/*異動日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button type="primary" onClick={handleClick}>
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};

export default InventoryDiff;
