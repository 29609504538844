/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Col, message, Space, Modal, Grid, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteReqTB } from "@/service/apis/PMS/Req";

const { Title } = Typography;

const TbContainer = ({
  call_getReqHD,
  call_getReqTB,
  call_updateReqHD,
  hd_values,
  is_editForm,
  set_is_editForm,
  s_tbData,
  s_disabled,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteReqTB = useAPI(deleteReqTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });

  const flagMap = {
    currencytype: t("PMS.util.currencytype"),
    exchangerate: t("PMS.util.exchangerate"),
    driID: t("PMS.req.driID"),
    taxtype: t("PMS.util.taxtype"),
    demandDate: t("PMS.req.demandDate"),
    suppID: t("PMS.util.suppID"),
    EDT:t("PMS.util.EDT"),
    note:t("util.note"),
    exchangeD:t("PMS.util.exchangeD")
  };

  // 新建資料
  const createData = () => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        // title: "系統訊息",
        title: t("PMS.util.sysMsg"),
        icon: <ExclamationCircleOutlined />,
        content: (
          //2023/03/22 先不加i18n，寫法優化後再加
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => flagMap[item])?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        // okText: t("PMS.util.submit"), // 確定

        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateReqHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          // set_s_tbModal({ show: false, data: {} });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: {} });
  };
  // 編輯資料
  const editData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        // title: "系統訊息",
        title: t("PMS.util.sysMsg"),
        icon: <ExclamationCircleOutlined />,
        content: (
          //2023/03/22 先不加i18n，寫法優化後再加
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => flagMap[item])?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),

        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        // okText: t("PMS.util.submit"), // 確定
        onOk: async () => {
          // 更新表頭
          call_updateReqHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          set_s_tbModal({ show: false, data: rowData });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: rowData });
  };
  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      // title: "請購明細刪除確認",
      title: t("PMS.req.tb_delete_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的明細資料無誤，再進行刪除",
      content: t("PMS.util.del_tb_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      // okText: t("PMS.util.submit"), // 確定
      // cancelText: t("PMS.util.cancel"), // 取消
      onOk: () => call_deleteReqTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteReqTB;
    if (status === "suc") {
      message.success(msg);
      call_getReqHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getReqTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteReqTB.status]);

  // 更新表頭的API
  useEffect(() => {
    const { status, msg } = call_updateReqHD;
    if (status === "suc") {
      message.success(msg);
      // 更新完成後打開跳顯讓使用者編輯
      set_s_tbModal((prev) => ({
        show: true,
        data: prev.data,
      }));
      // 清除編輯記錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateReqHD.status]);

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "20%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: t("PMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: "7%",
      render: (text) =>
        text === "unit" ? t("PMS.util.unit") : text === "opunit" ? t("PMS.util.opunit") : "",
    },
    {
      title: t("PMS.req.punit"), // 請購單位
      dataIndex: "punit",
      align: "center",
      width: "7%",
    },
    {
      title: t("PMS.req.unitprice"), // 請購單價(未稅)
      dataIndex: "unitprice",
      align: "right",
      width: "10%",
    },
    {
      title: t("PMS.req.demandqty"), // 請購數量
      dataIndex: "demandqty",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.req.itemsum"), // 單項金額(未稅)
      dataIndex: "itemsum",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.req.itemsumtax"), // 單項稅額
      dataIndex: "itemsumtax",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.req.subtotal"), // 含稅小計
      dataIndex: "subtotal",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },

    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: screens.xxl === true ? "5%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => editData(rowData)} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];
  console.log("hd_values = ", hd_values);
  console.log("s_tbData = ", s_tbData);

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div>
        <div className="flex justify-between items-center py-2">
          {/* 請購明細 */}
          <TbTitle>{t("PMS.req.tb_title")}</TbTitle>
          {/* 新建的 button */}
          <CreateButton disabled={s_disabled.createBtn} onClick={createData} />
        </div>

        <CustomTable
          columns={tableColumns}
          rowNumber={6}
          dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
        />
      </div>

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#6A68A1]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("PMS.util.itemno_total")}: </span>
          <span className="self-center">{s_tbData?.length ?? 0}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("PMS.req.itemtotal")}:</span>
          <span className="self-center">{formatAmount(hd_values.itemtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("PMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(hd_values.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 本幣含稅總金額 */}
          <span className="self-center">{t("PMS.req.local_subtotal")}:</span>
          <span className="self-center">
            {formatAmount(hd_values.total * hd_values.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("PMS.req.total")}:</span>
          <span className="self-center"> {formatAmount(hd_values.total)}</span>
        </Col>
      </Row>

      {/* 新建、編輯表身的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-end mr-9">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯請購明細" : "新增請購明細"} */}
              {type === "edit" ? t("PMS.req.edit_tb_title") : t("PMS.req.create_tb_title")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
                ：{hd_values.N} {t("PMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        <TbModal
          call_getReqHD={call_getReqHD}
          call_getReqTB={call_getReqTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
