import { Select, Form, InputNumber, Input, Row, Col } from "antd";

const keyTextOptions = [
  { key: "PN", text: "ERP料號" },
  { key: "custPN", text: "客戶料號" },
  { key: "SN", text: "流水號" },
  { key: "date", text: "日期" },
  { key: "woN", text: "工單號" },
  { key: "keyDigits", text: "檢驗碼" }, //因為跟其他地方命名重複 所以取名為 keyDigits !!!需要付錢才能開啟
  { key: "var1", text: "自訂關鍵字1" },
  { key: "var2", text: "自訂關鍵字2" },
  { key: "var3", text: "自訂關鍵字3" },
];

const fontFamilyOptions = [
  {
    label: "新細明體",
    value: "Noto Sans TC",
  },
  {
    label: "微软雅黑",
    value: "Microsoft YaHei",
  },
  {
    label: "標楷體",
    value: "DFKai-SB",
  },
  {
    label: "微軟正黑體",
    value: "Microsoft JhengHei",
  },
  {
    label: "楷體",
    value: "KaiTi",
  },
  {
    label: "黑體",
    value: "SimHei",
  },
  {
    label: "宋體",
    value: "SimSun",
  },
  {
    label: "新宋体",
    value: "NSimSun",
  },

  {
    label: "Calibri",
    value: "Calibri",
  },
  {
    label: "Times New Roman",
    value: "Times New Roman",
  },
  {
    label: "Arial",
    value: "Arial",
  },
  {
    label: "Verdana",
    value: "Verdana",
  },
];

const textBoldOptions = [
  { label: "一般", value: " " },
  { label: "粗體", value: "bold" },
  { label: "斜體", value: "italic" },
  {
    label: "粗斜體",
    value: "bold italic",
  },
];

const textColor = [
  { label: "黑", value: "#000000" },
  { label: "白", value: "#ffffff" },
];

const keyTextLabel = (field) => (
  <>
    <Col span={12}>
      <Form.Item label="關鍵文字" name={[field.name, "keyText"]} rules={[{ required: true }]}>
        <Select
          options={keyTextOptions.map(({ key, text }) => ({
            value: key,
            label: text,
          }))}
        />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item label="替代字串" name={[field.name, "fakeText"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
  </>
);

const textLabel = (field) => (
  <>
    <Col span={12}>
      <Form.Item label="固定字串" name={[field.name, "text"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
  </>
);

const quoteTextLabel = (field) => (
  <>
    <Col span={12}>
      <Form.Item
        label="ex:固定字串${1}${2}"
        name={[field.name, "quoteText"]}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
    </Col>
    <Col span={12}>
      <Form.Item label="替代字串" name={[field.name, "fakeText"]} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
    </Col>
  </>
);

const textUtilLabel = (field) => (
  <>
    <Col span={12}>
      <Form.Item label="字型" name={[field.name, "fontFamily"]} rules={[{ required: true }]}>
        <Select options={fontFamilyOptions} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="長" name={[field.name, "fontSize"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="寬(%)" name={[field.name, "fontWidth"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="粗細" name={[field.name, "bold"]} rules={[{ required: true }]}>
        <Select options={textBoldOptions} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="顏色" name={[field.name, "c"]} rules={[{ required: true }]}>
        <Select options={textColor} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const LabelText = {
  keyTextLabel,
  textLabel,
  quoteTextLabel,
  textUtilLabel,
};

export default LabelText;
