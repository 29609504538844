/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  Button,
  message,
  Divider,
} from "antd";
import PNButton from "@/components/ERP/PNButtonOri";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { addReturnTB, updateReturnTB } from "@/service/apis/PMS/Return";
const { Text } = Typography;

const TbModal = ({ hd_values, s_disabled, s_tbModal, set_s_tbModal, call_getReturnTB }) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addReturnTB = useAPI(addReturnTB);
  // useAPI 編輯表身
  const call_updateReturnTB = useAPI(updateReturnTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(s_tbModal.data).length > 0;

  // 改變料號時
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};

    form.setFieldsValue({
      ...PN_obj,
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 點擊確認時執行
  const onFinish = async (values) => {
    // 新建進料明細
    if (!editMode) {
      call_addReturnTB.request(values);
    }

    // 編輯進料明細
    if (editMode) {
      call_updateReturnTB.request(values);
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    if (!editMode) {
      form.setFieldsValue({
        opUUID: hd_values.opUUID,
      });
    }

    if (editMode) {
      form.setFieldsValue({
        ...s_tbModal.data,
        opUUID: hd_values.opUUID,
      });
    }
  }, []);

  // useAPI 新建表身資料 or useAPI 更新表頭資料
  useEffect(() => {
    const { status, msg } = editMode ? call_updateReturnTB : call_addReturnTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getReturnTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addReturnTB.status, call_updateReturnTB.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
      </div>

      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("OMS.util.PN")}</Text>}
            name="PN"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.util.PN") }}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("OMS.util.PN")}
              disabled={s_disabled.PN}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 發貨單號 */}
          <Form.Item label={<Text disabled>{t("OMS.util.N14")}</Text>} name="N14">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 銷貨單號 */}
          <Form.Item label={<Text disabled>{t("OMS.util.N13")}</Text>} name="N13">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 報價單號 */}
          <Form.Item label={<Text disabled>{t("OMS.util.N12")}</Text>} name="N12">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={
              <Text
                disabled={
                  s_disabled.utype ||
                  (f_unit?.length === 0 && f_opunit?.length === 0) ||
                  f_PN?.length === 0
                }
              >
                {/* 單位類別 */}
                {t("OMS.util.utype")}
              </Text>
            }
            name="utype"
            onChange={(value) => {
              // 選擇「計量單位」或「操作單位」帶入對應的採購單位
              form.setFieldsValue({
                punit: form.getFieldValue(value),
              });
            }}
          >
            <Select
              placeholder={t("util.placeholder_select") + t("OMS.util.utype")}
              optionFilterProp="children"
              disabled={
                s_disabled.utype ||
                (f_unit?.length === 0 && f_opunit?.length === 0) ||
                f_PN?.length === 0
              }
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && (
                <Select.Option value="unit">{t("OMS.util.unit")}</Select.Option>
              )}
              {f_opunit?.length > 0 && (
                <Select.Option value="opunit">{t("OMS.util.opunit")}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 進料單位
            label={<Text disabled>{t("OMS.fod.punit")}</Text>}
            name="punit"
            // rules={[{required:true,message:"請填寫進料使用單位!"}]}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 退回數量
            label={<Text disabled={s_disabled.rtnqty}>{t("OMS.return.rtnqty")}</Text>}
            name="rtnqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.return.rtnqty") }}
          >
            <InputNumber
              className="w-full"
              disabled={s_disabled.rtnqty}
              placeholder={t("util.placeholder_input") + t("OMS.return.rtnqty")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 4]}>
        <Col span={24}>
          {/* 註記 */}
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS.return.note")}</Text>}
            name="note"
          >
            <Input disabled={s_disabled.tb_note} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
