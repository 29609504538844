/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, theme } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getReturnTB } from "@/service/apis/PMS/Return";
// util
import { formatAmount } from "@/util/format";
const { useToken } = theme;
const TB = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getReturnTB = useAPI(getReturnTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getReturnTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getReturnTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getReturnTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "3%" : "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "18%" : "18%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("PMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      //"計量單位" : "操作單位"
      render: (text) => (text === "unit" ? t("PMS.util.unit"):t("PMS.util.opunit")),
    },
    {
      title: t("PMS.return.punit"), // 使用單位
      dataIndex: "punit",
      align: "center",
      width: screens.xxl ? "7%" : "9%",
    },
    {
      title: t("PMS.util.unitprice"), // 採購單價
      dataIndex: "unitprice",
      align: "right",
      width: screens.xxl ? "9%" : "12%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.return.rtnqty"), // 退回數量
      dataIndex: "rtnqty",
      align: "right",
      width: screens.xxl ? "6%" : "9%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.return.inbatchno"), // 進料批號
      dataIndex: "inbatchno",
      align: "center",
      width: screens.xxl === true ? "8%" : "8%",
    },
    {
      title: t("PMS.util.outN"), // 驗退單號
      dataIndex: "outN",
      align: "center",
      width: screens.xxl === true ? "8%" : "8%",
    },
    {
      title: t("PMS.util.N24"), // 進料單號
      dataIndex: "N24",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "8%" : "8%",
    },
    {
      title: t("PMS.util.N23"), // 採購單號
      dataIndex: "N23",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "8%" : "8%",
    },
    {
      title: t("PMS.util.N22"), // 請購單號
      dataIndex: "N22",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "8%" : "8%",
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
    </div>
  );
};

export default TB;
