import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  message,
  Radio,
  Space,
} from "antd";
import { addPS, modifyPS } from "@/service/apis/ADM/publicAPI";
import { getCustHd } from "@/service/apis/ADM/pnManage";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_addPS = useAPI(addPS);
  const call_modifyPS = useAPI(modifyPS);
  const call_getCustHd = useAPI(getCustHd);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addPS.request(values);
    }
    if (type === "edit") {
      call_modifyPS.request(values);
    }
  };

  useEffect(() => {
    call_getCustHd.request();
  }, []);

  useEffect(() => {
    if (call_addPS.status === "suc") {
      message.success(call_addPS.msg);
      set_s_isShowModal(false);
    } else if (call_addPS.status === "err") {
      message.error(t(`errorCode.${call_addPS.msg}`));
    }
  }, [call_addPS.status]);

  useEffect(() => {
    if (call_modifyPS.status === "suc") {
      message.success(call_modifyPS.msg);
      set_s_isShowModal(false);
    } else if (call_modifyPS.status === "err") {
      message.error(t(`errorCode.${call_modifyPS.msg}`));
    }
  }, [call_modifyPS.status]);

  useEffect(() => {
    if (call_getCustHd.status === "err") {
      message.error(t(`errorCode.${call_getCustHd.msg}`));
    }
  }, [call_getCustHd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={12}>
          {/*  "車間ID"*/}
          <Form.Item
            label={t("ADM.production-line-manage.psID")}
            name="psID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* "車間名稱" */}
          <Form.Item label={t("util.name")} name="psNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
