import { DatePicker, Drawer, Grid, Input, Select, Space, Typography, message } from "antd";
import React, { useContext, useEffect, useState } from "react";

import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { DataContext } from "../index";
// util component
import DrawerModal from "./Modal/DrawerModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import PageHeader from "@/components/PageHeader";
import { SearchButton } from "@/components/PDS/Buttons";
import { SeeMoreButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
//util
import { formatAmount } from "@/util/format";
import { getOrdHD } from "@/service/apis/OMS/Ord";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = () => {
  const { RangePicker } = DatePicker;
  const { t } = useTranslation();
  const { Title } = Typography;

  // useAPI 取得表頭資料
  const call_getOrdHD = useAPI(getOrdHD);

  const { c_lightData, set_c_lightData, s_HD_Data, set_HD_Data, set_s_total } =
    useContext(DataContext);
  // 客戶資料、人員資料
  const { c_custs, c_staffs, getStaffName } = useContext(ERPContext);
  console.log("c_custs = ", c_custs);
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    opcode: [], //單別
    N: "", //單號
    custID: [], //客戶ID
    driID: [], //負責業務ID
    dealN: "", //客戶PO號
    lifeF: [],
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();

  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    set_c_lightData({});
    const tmp = {
      ...s_searchData,
      opcode: s_searchData.opcode?.join("|"), //要將資料陣列轉成字串，用直槓隔開
      custID: s_searchData.custID?.join("|"),
      lifeF: s_searchData.lifeF?.join("|"),
      driID: s_searchData.driID?.join("|"),
    };
    call_getOrdHD.request(tmp);
  };

  useEffect(() => {
    console.log("s_searchData === ", s_searchData);
  }, [s_searchData]);

  // 表格欄位
  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "5.5%" : "8%",
      render: (text) => t(`OMS.util.opcode_${text}`),
    },
    // {
    //   title: t("OMS.util.N"), // 單號
    //   dataIndex: "N",
    //   align: "center",
    //   width: screens.xxl ? "8%" : "11%",
    // },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
      width: "12%",
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      sorter: (a, b) => a.dealN.localeCompare(b.dealN),
      dataIndex: "dealN",
      align: "center",
      width: screens.xxl ? "8%" : "11%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.util.itemsum"), // 金額
      dataIndex: "sumtotal",
      align: "right",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.ord.planday"), // 計畫時間
      dataIndex: "planday",
      align: "center",
      width: screens.xxl ? "6%" : "6%",
      render: (text) => (text === "" ? null : text),
    },
    {
      title: t("OMS.util.EDT"), // 預計交貨日
      dataIndex: "EDT",
      align: "center",
      sorter: (a, b) => a.EDT.localeCompare(b.EDT),
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "8%" : "8%",
      render: (text) => {
        return t(`OMS.ord.lifeF_${text}`);
      },
    },
    {
      title: t("OMS.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      width: "7%",
      sorter: (a, b) => a.lifeFT.localeCompare(b.lifeFT),
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    call_getOrdHD.request(s_searchData);
    console.log("c_staffs = ", c_staffs);
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdHD;

    if (status === "suc") {
      //表頭資料
      const tmp = data?.map((item) => ({ ...item, key: item.N }));

      //累積金額
      const total = data?.reduce((acc, curr) => {
        return acc + curr.itemtotal;
      }, 0);

      set_HD_Data(tmp);
      set_s_total({ itemtotal: total });

      message.success(msg);
    }

    if (status === "err") {
      set_HD_Data([]);
      set_s_total({ itemtotal: 0 });
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdHD.status]);

  return (
    <div className="flex-1">
      <PageHeader
        title={t("util.nav_A-2")} // 项目业绩查询
      />
      <Space className="flex justify-end gap-2 mb-2">
        {/*導出excel*/}

        {/* <Button type="primary" onClick={() => exportToExcel()}>
          導出excel
        </Button> */}

        {/*單別*/}
        {/* <Select
          className="min-w-[200px]"
          name="opcode"
          mode="multiple"
          showSearch
          placeholder="请选择单别"
          onChange={(value) => handle_SearchData_Change("opcode", value)}
          options={[
            { value: "", label: t("util.all") }, // 终端客户
            { value: "1310", label: t("OMS.util.opcode_1310") }, // 终端客户
            { value: "1320", label: t("OMS.util.opcode_1320") }, // 合作伙伴
            { value: "1330", label: t("OMS.util.opcode_1330") }, // 其他檢測机构
          ]}
          allowClear
        /> */}

        {/*客戶選擇*/}
        <Select
          className="min-w-[300px]"
          name="custID"
          mode="multiple"
          showSearch
          placeholder="客户"
          // placeholder="请选择客户"
          onChange={(value) => handle_SearchData_Change("custID", value)}
          filterOption={(input, option) => {
            return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
          }}
          options={c_custs?.options}
          allowClear
        />

        {/*客戶單號*/}
        <Input
          className="min-w-[150px]"
          placeholder="客户单号"
          // placeholder="请输入客户单号"
          name="dealN"
          onChange={(e) => handle_SearchData_Change("dealN", e.target.value)}
          value={s_searchData.dealN}
          allowClear
        />

        {/*負責業務選擇*/}
        <Select
          className="min-w-[300px]"
          name="driID"
          mode="multiple"
          showSearch
          placeholder="負責業務"
          // placeholder="请选择負責業務"
          onChange={(value) => handle_SearchData_Change("driID", value)}
          // defaultValue={[{ label: "全部", value: "" }]}
          options={c_staffs?.options}
          allowClear
        />

        {/*歷程*/}
        <Select
          className="min-w-[200px]"
          name="lifeF"
          mode="multiple"
          showSearch
          placeholder="历程"
          // placeholder="请选择历程"
          onChange={(value) => handle_SearchData_Change("lifeF", value)}
          options={[
            // { label: "发布", value: "0" },
            // { label: "报价审批", value: "2" },
            { label: t("OMS.ord.lifeF_3"), value: "3" },
            // { label: "上传客户委托", value: "4" },
            // { label: t("OMS.ord.lifeF_4"), value: "4" },
            { label: t("OMS.ord.lifeF_5"), value: "5" },
            { label: t("OMS.ord.lifeF_6"), value: "6" },
            // { label: "应收帐款", value: "8" },
            // { label: "收款入帐", value: "9" },
            // { label: "上传统一发票", value: "10" },
          ]}
          allowClear
        />

        {/*預交日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <SearchButton type="primary" key="search" onClick={handleClick} />
      </Space>
      <CustomTable
        columns={tableColumns}
        dataSource={s_HD_Data}
        loading={call_getOrdHD.status === "load"}
        // lightData={c_lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        // onRow={(record) => {
        //   return {
        //     onClick: (e) =>
        //       set_c_lightData(record.N === c_lightData.N ? {} : record),
        //   };
        // }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {t("OMS.outstock.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={900}
        open={s_drawer.show}
        onClose={() => set_s_drawer({ show: false, data: {} })}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default HD;
