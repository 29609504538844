import IconBox from "../IconBox";
import { ReactComponent as Circular } from "@/assets/icon/LMS/icon-circular.svg";
import { ReactComponent as Line } from "@/assets/icon/LMS/icon-line.svg";
import { ReactComponent as Photo } from "@/assets/icon/LMS/icon-photo.svg";
import { ReactComponent as Square } from "@/assets/icon/LMS/icon-square.svg";
import { ReactComponent as Word } from "@/assets/icon/LMS/icon-word.svg";

// 圓
export const IconCircular = (props) => <IconBox component={Circular} {...props} />;
// line
export const IconLine = (props) => <IconBox component={Line} {...props} />;
// 相片
export const IconPhoto = (props) => <IconBox component={Photo} {...props} />;
// 方框
export const IconSquare = (props) => <IconBox component={Square} {...props} />;
// 文字
export const IconWord = (props) => <IconBox component={Word} {...props} />;
