import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util
import { formatAmount } from "@/util/format";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { data } = s_drawer;

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 樣品名稱 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 檢測項目 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.pdtNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 規格 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.pdtspec")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtspec}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 計畫時間 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.planday")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.planday}天</Text>
        </Col>
        {/* 預計交貨日 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.EDT")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{dayjs(data.EDT).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 檢測費用 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.unitprice")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{formatAmount(data.unitprice)}</Text>
        </Col>
        {/* 數量 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.ord.demandqty")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{formatAmount(data.demandqty)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 金額 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.itemsum")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{formatAmount(data.itemsum)}</Text>
        </Col>
        
      </Row>

    </div>
  );
};

export default DrawerModal;
