/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Button, Space, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { CreateButton } from "@/components/ERP/Button";
// import StockModal from '../Modal/StockModal';
// context
import { MainPageContext } from "../index";
// component
import TbContainer from "../Details/TbContainer";
import AddaContainer from "../Details/AddaContainer";
import AddbContainer from "../Details/AddbContainer";
import AddcContainer from "../Details/AddcContainer";

const DetailContainer = () => {
  const { s_disabled, is_editForm, set_is_editForm, call_updateProHD, hd_values } =
    useContext(MainPageContext);

  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_tabKey, set_s_tabKey] = useState("details");
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });
  const tabsData = [
    { key: "details", label: t("PMS.pro.tb") }, // 採購明細
    { key: "adda", label: t("PMS.pro.adda") }, // 條款註記
    { key: "addb", label: t("PMS.pro.addb") }, // 加扣款項
    { key: "addc", label: t("PMS.pro.addc") }, // 預付作業
  ];
  const flagMap = {
    dealN: t("PMS.pro.dealN"),
    currencytype: t("PMS.util.currencytype"),
    exchangeD: t("PMS.util.exchangeD"),
    exchangerate: t("PMS.util.exchangerate"),
    suppID:t("PMS.util.suppID"),
    payment: t("PMS.pro.payment"),
    trade: t("PMS.pro.trade"),
    driID: t("PMS.pro.driID"),
    proD: t("PMS.pro.proT"),
    startEDT: t("PMS.pro.startEDT"),
    endEDT: t("PMS.pro.endEDT"),
    taxtype: t("PMS.util.taxtype"),
    contact: t("PMS.pro.contact"),
    tel: t("PMS.util.tel"),
    dealcontact: t("PMS.pro.dealcontact"),
    dealtel: t("PMS.util.tel"),
  };
  // 新建資料
  const createData = () => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: "系統訊息",
        icon: <ExclamationCircleOutlined />,
        content: (
          //2023/03/22 先不加i18n，寫法優化後再加
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {/* {is_editForm.editFlag?.map((item) => item)?.join(",")} */}
              {is_editForm.editFlag?.map((item) => flagMap[item])?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateProHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          // set_s_tbModal({ show: false, data: {} });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: {} });
  };
  // 更新表頭的API
  useEffect(() => {
    const { status, msg } = call_updateProHD;
    if (status === "suc") {
      message.success(msg);
      // 更新完成後打開跳顯讓使用者編輯
      set_s_tbModal({ show: true, data: {} });
      // 清除編輯記錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateProHD.status]);

  return (
    <div className="flex flex-col flex-1">
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={s_tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => set_s_tabKey(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <Button
              disabled
              // onClick={() => openStockModal()}
            >
              {/* 退出處置 */}
              {t("PMS.pro.exit_Disposal")}
            </Button>

            <CreateButton
              // onClick={() => set_s_tbModal({ show: true, data: {} })}
              onClick={createData}
              disabled={s_disabled.createBtn}
            />
          </Space>
        }
      />

      {s_tabKey === "details" ? (
        <TbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}

      {/* 條款註記 */}
      {s_tabKey === "adda" && <AddaContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {/* 加扣款項 */}
      {s_tabKey === "addb" && <AddbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {s_tabKey === "addc" ? (
        <AddcContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}
    </div>
  );
};

export default DetailContainer;
