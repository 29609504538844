/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Drawer, Typography, Row, Col } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getQuoTB } from "@/service/apis/OMS/Quo";
// util
import { formatAmount } from "@/util/format";
// component
import DrawerModal from "./Modal/DrawerModal";
const { Title } = Typography;

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getQuoTB = useAPI(getQuoTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;

  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });

  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 取得表身資料
  useEffect(() => {
    call_getQuoTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getQuoTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getQuoTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "20%" : "18%",
    },

    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.quo.demand"), // 報價數量
      dataIndex: "demand",
      align: "right",
      width: screens.xxl ? "10%" : "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.utype"), // 報價單位
      dataIndex: "utype",
      align: "center",
      width: screens.xxl ? "10%" : "10%",
      render: (text) => (text === "unit" ? t("OMS.util.unit") : text === "opunit" ? t("OMS.util.opunit") : ""),
    },
    {
      title: t("OMS.quo.unitprice"), // 報價金額
      dataIndex: "unitprice",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.localprice"), // 本幣金額
      dataIndex: "localprice",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.quo.isapprove"), // 單項成交
      dataIndex: "isapprove",
      align: "center",
      width: "8%",
      render: (text, _, __) => {
        return (
          <div className="checkIcon">
            {text === true ? (
              <CheckCircleFilled />
            ) : text === false ? (
              <CloseCircleOutlined />
            ) : null}
          </div>
        );
      },
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#4B6EA2]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("OMS.util.itemno_total")}: </span>
          <span className="self-center">{s_tableData?.length ?? 0}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("OMS.quo.itemtotal")}:</span>
          <span className="self-center">{formatAmount(lightData.itemtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("OMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(lightData.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 本幣含稅總金額 */}
          <span className="self-center">{t("OMS.quo.local_subtotal")}:</span>
          <span className="self-center">
            {formatAmount(lightData.total * lightData.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("OMS.quo.total")}:</span>
          <span className="self-center"> {formatAmount(lightData.total)}</span>
        </Col>
      </Row>

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 報價單明細 */}
            {t("OMS.quo.tb_drawer_title")}
          </Title>
        }
        placement="left"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default TB;
