import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import PageHeader from "@/components/PageHeader";
// API
// import useAPI from "@/hooks/useAPI";
// import { getBPM } from "@/service/apis/BPM/Dsevent";

const { Title } = Typography;

const INIT_DATA = {
  create: 0,
  check: 0,
  review: 0,
  approve: 0,
  isurgent: 0,
};

const PendingApproval = ({ s_dataSource }) => {
  const [s_data, set_s_data] = useState(INIT_DATA);

  useEffect(() => {
    const counts = s_dataSource.Run10?.reduce((prev, curr) => {
      if (curr.flowF === "create") {
        prev.create++;
      }
      if (curr.flowF === "check") {
        prev.check++;
      }
      if (curr.flowF === "review") {
        prev.review++;
      }
      if (curr.flowF === "approve") {
        prev.approve++;
      }
      if (curr.isurgent) {
        prev.isurgent++;
      }

      return prev;
    }, INIT_DATA);

    set_s_data(counts || INIT_DATA);
  }, [s_dataSource]);

  return (
    <div className="flex flex-1 flex-col ">
      <PageHeader title="待簽核文件" />
      <div className="flex-1 flex justify-bewtween overflow-hidden items-center shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
        <div className="h-full grow flex-center flex-col bg-[#4973B5]">
          <Title level={5} className="text-white">
            總待案數
          </Title>
          <Title level={5} className="text-white">
            {s_dataSource.Run10?.length || 0}件
          </Title>
        </div>
        <div className="h-full grow flex-center flex-col">
          <Title level={5}>總創建</Title>
          <Title level={5}>{s_data.create}件</Title>
        </div>
        <div className="h-full grow flex-center flex-col">
          <Title level={5}>總查案</Title>
          <Title level={5}>{s_data.check}件</Title>
        </div>
        <div className="h-full grow flex-center flex-col">
          <Title level={5}>待審案</Title>
          <Title level={5} className="text-red-500">
            {s_data.review}件
          </Title>
        </div>
        <div className="h-full grow flex-center flex-col">
          <Title level={5}>待核案</Title>
          <Title level={5} className="text-red-500">
            {s_data.approve}件
          </Title>
        </div>
        <div className="h-full grow flex-center flex-col">
          <Title level={5}>急件</Title>
          <Title level={5}>{s_data.isurgent}件</Title>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;
