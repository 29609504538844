/* eslint-disable import/no-anonymous-default-export */
import { useContext } from "react";
import { theme, Button, Tooltip } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { IconOverview } from "@/components/Icon/BPM/index";

const SharedButton = ({ children, Icon, className, disabled, ...props }) => {
  const { useToken } = theme;
  const { token } = useToken();

  return (
    <div
      style={{
        width: token.sizeLG,
        height: token.sizeLG,
        borderRadius: token.borderRadiusSM,
      }}
      className={`
        flex justify-center items-center 
        ${disabled ? "cursor-not-allowed" : "cursor-pointer"} 
        ${disabled ? "text-stone-400" : "text-white"}
        ${disabled ? "bg-zinc-200" : className}
      `}
      {...props}
      onClick={disabled ? null : props.onClick}
    >
      <Icon style={{ fontSize: token.sizeMD }} />
    </div>
  );
};

// Table-編輯
export const EditButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t("PMS.util.table_edit")}>
      <SharedButton Icon={MenuOutlined} className="bg-[#7DB5C6]" {...props} />
    </Tooltip>
  );
};

// 回總表
export const SummaryButton = ({ children, ...props }) => {
  const { t } = useTranslation();
  return (
    <Button type="text" icon={<IconOverview />} {...props}>
      {/* {t("PMS.util.header_return")} */}
      回總表
    </Button>
  );
};
