import React, { useEffect } from "react";
import { Typography } from "antd";
import PageHeader from "@/components/PageHeader";
// useAPI
import useAPI from "@/hooks/useAPI";
import { getCountmonth } from "@/service/apis/BPM/Dsevent";
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const Accumulation = () => {
  const call_getCountmonth = useAPI(getCountmonth);

  const { data } = call_getCountmonth;

  useEffect(() => {
    call_getCountmonth.request();
  }, []);

  // console.log("call_getCountmonth = ", call_getCountmonth);
  return (
    <div className="flex flex-1 flex-col">
      <PageHeader title="當月累計" />
      <div className="flex-1 flex flex-col justify-center items-center gap-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
        <Title
          level={4}
          className="m-0 tracking-[5px]">
          立&nbsp;&nbsp;&nbsp;案
          <span className="px-5 border-0 border-b-2 border-solid ">{data?.create || 0}</span>
          件， 平均
          <span className="px-5 border-0 border-b-2 border-solid ">
            {formatAmount((data?.create || 0) / 30)}
          </span>
          件/天
        </Title>

        <Title
          level={4}
          className="m-0 tracking-[5px]">
          完查案
          <span className="px-5 border-0 border-b-2 border-solid ">{data?.check || 0}</span>
          件， 平均
          <span className="px-5 border-0 border-b-2 border-solid ">
            {formatAmount((data?.check || 0) / 30)}
          </span>
          件/天
        </Title>

        <Title
          level={4}
          className="m-0 tracking-[5px]">
          完審查
          <span className="px-5 border-0 border-b-2 border-solid ">{data?.review || 0}</span>
          件， 平均
          <span className="px-5 border-0 border-b-2 border-solid ">
            {formatAmount((data?.review || 0) / 30)}
          </span>
          件/天
        </Title>

        <Title
          level={4}
          className="m-0 tracking-[5px]">
          完核案
          <span className="px-5 border-0 border-b-2 border-solid ">{data?.approve || 0}</span>
          件， 平均
          <span className="px-5 border-0 border-b-2 border-solid ">
            {formatAmount((data?.approve || 0) / 30)}
          </span>
          件/天
        </Title>
      </div>
    </div>
  );
};

export default Accumulation;
