import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//----------------------------------------- 取得PN ----------------------------------------
export const getProduct = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProduct`, { params: _params });
};

//----------------------------------------- createBom ----------------------------------------
export const addBom = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addBom`, data);
};

export const getBom = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBom`, { params: _params });
};

export const getTreeStruct = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/getTreeStruct`, data);
};

export const deleteBom = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteBom`, data);
};

export const modifyBom = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyBom`, data);
};

export const getArrayStruct = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getArrayStruct`, {
    params: _params,
  });
};

export const getDeleteArrayStruct = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/getDeleteArrayStruct`, data);
};
