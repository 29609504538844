import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import { getCurrency, deleteCurrency, sortCurrency } from "@/service/apis/ADM/functionParamsManage";

import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import DragSortingTable from "@/components/CustomAntd/PDS/DragSortingTable";

import ModalConintaer from "./ModalContainer/index";

const Currency = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const { t } = useTranslation();
  const call_getCurrency = useAPI(getCurrency);
  const call_deleteCurrency = useAPI(deleteCurrency);
  const call_sortCurrency = useAPI(sortCurrency);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getCurrency.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.sort"), //"排序",
      dataIndex: "sort",
      width: "80px",
      align: "center",
      render: () => <MenuOutlined />,
    },
    {
      title: t("util.SN"), //"序",
      dataIndex: "currencyno",
      align: "center",
      width: "80px",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("ADM.function-params-manage.currencyitem"), //"幣別代號",
      dataIndex: "currencyitem",
      align: "center",
    },
    {
      title: t("ADM.function-params-manage.itemNM"), //"幣別名稱",
      dataIndex: "itemNM",
      align: "center",
    },
    {
      title: t("util.note"), //"註記",
      dataIndex: "note",
      width: "55%",
      align: "left",
    },
    {
      title: t("ADM.function-params-manage.ismycurrency"), //"註記",
      dataIndex: "ismycurrency",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.isvalid"), //"是否啟用",
      dataIndex: "isvalid",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteCurrency.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getCurrency.status === "suc") {
      message.success(call_getCurrency.msg);
      set_s_tableData(
        call_getCurrency.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getCurrency.status === "err") {
      message.error(t(`errorCode.${call_getCurrency.msg}`));
      set_s_tableData([]);
    }
  }, [call_getCurrency.status]);

  useEffect(() => {
    if (call_deleteCurrency.status === "suc") {
      message.success(call_deleteCurrency.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteCurrency.status === "err") {
      message.error(t(`errorCode.${call_deleteCurrency.msg}`));
    }
  }, [call_deleteCurrency.status]);

  useEffect(() => {
    if (call_sortCurrency.status === "suc") {
      message.success(call_sortCurrency.msg);
      // 排序之後 重call表格資料
      onSearch();
    } else if (call_sortCurrency.status === "err") {
      message.error(t(`errorCode.${call_sortCurrency.msg}`));
      // 排序之後 重call表格資料
      onSearch();
    }
  }, [call_sortCurrency.status]);

  return (
    <>
      <DragSortingTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getCurrency.status === "load"}
        c_lightData={s_editData}
        set_s_tableData={set_s_tableData}
        afterSorting={(data) => {
          call_sortCurrency.request(data.map((x, i) => ({ ...x, currencyno: i + 1 })));
          return data;
        }}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.edit") + t("ADM.function-params-manage.currency")
            : t("util.add") + t("ADM.function-params-manage.currency")
        }
        width={"50%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Currency;
