import React, { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Typography, Input, Divider, InputNumber, message, Select } from "antd";
import { useTranslation } from "react-i18next";
// component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { MainPageContext } from "../index";
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getPostage, addPostage, updatePostage } from "@/service/apis/OMS/Ord";
import { getCustTB } from "@/service/apis/OMS/util";

const { Text } = Typography;

const PostageModal = ({ s_tbModal, set_s_tbModal, set_s_TableData }) => {
  const { hd_values, s_disabled } = useContext(MainPageContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const f_type = Form.useWatch("type", form);
  const { c_supps } = useContext(ERPContext);
  const call_getCustTB = useAPI(getCustTB);
  // useAPI 新增郵寄費用
  const call_addPostage = useAPI(addPostage);
  // useAPI 編輯郵寄費用
  const call_updatePostage = useAPI(updatePostage);
  //取得郵寄費用
  const call_getPostage = useAPI(getPostage);

  const [s_custTb, set_s_custTb] = useState({});

  // 儲存時執行
  const onFinish = async (values) => {
    console.log("values === ", values);

    // return
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    if (type === "create") {
      // step1 call API新建郵寄費用
      call_addPostage.request(values);
    }

    if (type === "edit") {
      // step1 call API更新郵寄費用
      call_updatePostage.request({ ...values, amount: values.amount || 0 });
    }
  };

  // 第一次渲染賦值
  useEffect(() => {
    console.log("s_tbModal=== ", s_tbModal);
    const formValues = {
      ...s_tbModal?.data,
      opUUID: hd_values.opUUID,
      custID: hd_values.custID,
    };
    form.setFieldsValue(formValues);
    call_getCustTB.request({ custID: hd_values.custID });
  }, []);

  // 取得客戶聯絡人資訊
  useEffect(() => {
    const { data, status, msg } = call_getCustTB;
    if (status === "suc") {
      message.success(msg);
      const options = data
        // .filter((item) => item.rebate === true)
        .filter((item) => item.isvalid)
        .map((item) => {
          return { value: item.contact, label: item.contact };
        });
      set_s_custTb({ options: options, data: data });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_custTb({});
    }
  }, [call_getCustTB.status]);

  // useAPI 新增郵寄費用
  useEffect(() => {
    const { status, msg } = call_addPostage;
    if (status === "suc") {
      message.success(msg);
      call_getPostage.request({
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addPostage.status]);

  // useAPI 編輯郵寄費用
  useEffect(() => {
    const { status, msg } = call_updatePostage;
    if (status === "suc") {
      message.success(msg);
      call_getPostage.request({
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updatePostage.status]);

  // useAPI 取得郵寄費用
  useEffect(() => {
    const { data, msg, status } = call_getPostage;
    if (status === "suc") {
      set_s_TableData(data);
    }

    if (status === "err") {
      set_s_TableData([]);
      message.error("取得郵寄費用失敗");
    }
  }, [call_getPostage.status]);
  console.log("s_custTb = ", s_custTb);
  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="custID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="type" label="項目">
            <Select
              disabled
              options={[
                // { label: t("OMS.ord.type_0"), value: "0" },
                { label: t("OMS.ord.type_1"), value: "1" }, // 快遞費
                { label: t("OMS.ord.type_2"), value: "2" }, // 信息費
                { label: t("OMS.ord.type_3"), value: "3" }, // 其他
                // { label: t("OMS.ord.type_4"), value: "4" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="statement" label="厂商">
            <Select
              showSearch
              disabled={s_disabled.postage_statement}
              placeholder={t("PMS.util.suppID_placeholder")}
              // onChange={onSuppChange}
              options={f_type === "2" ? s_custTb.options : c_supps.options}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="note" label={t("util.note")}>
            <Input disabled={s_disabled.postage_note} placeholder="请输入注记" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="amount" label={<Text disabled={s_disabled["addb_amount"]}>金额</Text>}>
            <InputNumber
              disabled={s_disabled.postage_amount}
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.ord.amount")}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />

          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default PostageModal;
