import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import { addReqTB, updateReqTB } from "@/service/apis/PMS/Req";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";

// context
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import PNButton from "@/components/ERP/PNButtonOri";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
const { Text } = Typography;

const TbModal = ({
  hd_values,
  s_disabled,
  s_tbModal,
  set_s_tbModal,
  call_getReqHD,
  call_getReqTB,
}) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addReqTB = useAPI(addReqTB);
  // useAPI 編輯表身
  const call_updateReqTB = useAPI(updateReqTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);
  const f_unitprice = Form.useWatch("unitprice", form);
  const f_demandqty = Form.useWatch("demandqty", form);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    if (type === "create") {
      // step1 call 新建表身的API
      call_addReqTB.request(values);
    }
    if (type === "edit") {
      // step1 call 編輯表身的API
      call_updateReqTB.request(values);
    }
  };

  // 改變料號
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 重新計算金額
  const updateAmount = (demandqty, unitprice) => {
    if (demandqty > 0 && unitprice > 0) {
      const itemsum = demandqty * unitprice;
      const localprice = hd_values.exchangerate * unitprice;
      const itemsumtax = parseFloat((itemsum * hd_values.taxrate * 0.01).toFixed(2));
      const subtotal = parseFloat((itemsum + itemsumtax).toFixed(2));
      form.setFieldsValue({ localprice, itemsum, itemsumtax, subtotal });
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    form.setFieldsValue({
      ...s_tbModal.data,
      opUUID: hd_values.opUUID,
    });
  }, []);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addReqTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getReqHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getReqTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addReqTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateReqTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getReqHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getReqTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateReqTB.status]);

  // 如果bdm資料沒有 unit、opunit就不可選擇單位類別
  const isDisabled = f_unit?.length === 0 && f_opunit?.length === 0;
  const hasSelectedPN = f_PN?.length > 0;
  const isUTypeDisabled = isDisabled && !hasSelectedPN;

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>

        {/* 廠商確認數量 */}
        <Form.Item name="confirmqty" hidden>
          <InputNumber />
        </Form.Item>
      </div>
      {/* 表單開始區域 */}

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("PMS.util.PN")}</Text>}
            messageVariables={{ label: t("PMS.util.PN") }}
            name="PN"
            className="flex-1"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t("PMS.util.PN_placeholder")}
              disabled={s_disabled.PN}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>

        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 品名 */}
        <Col span={24}>
          <Form.Item label={<Text disabled>品名</Text>} name="pdtNM">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 規格 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>規格</Text>} name="pdtspec">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 圖號 */}
          <Form.Item label={<Text disabled>{t("PMS.util.drawno")}</Text>} name="drawno">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 組合數量 */}
          <Form.Item label={<Text disabled>{t("PMS.util.pbom")}</Text>} name="pbom">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 計量單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("PMS.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 操作單位 */}
          <Form.Item label={<Text disabled>{t("PMS.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("PMS.util.PN_placeholder")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 請購數量 */}
          <Form.Item
            name="demandqty"
            label={<Text disabled={s_disabled.demandqty}>{t("PMS.req.demandqty")}</Text>}
            messageVariables={{ label: t("PMS.req.demandqty") }}
            rules={[{ required: true }]}
          >
            <InputNumber
              className="w-full"
              placeholder={t("PMS.req.demandqty_placeholder")}
              disabled={s_disabled.demandqty}
              onChange={(demandqty) => {
                const unitprice = form.getFieldValue("unitprice");
                updateAmount(demandqty, unitprice);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 請購單價(未稅) */}
          <Form.Item
            name="unitprice"
            label={<Text disabled={s_disabled.unitprice}>{t("PMS.req.unitprice")}</Text>}
            messageVariables={{ label: t("PMS.req.unitprice") }}
            rules={[{ required: true }]}
          >
            <InputNumber
              className="w-full"
              placeholder={t("PMS.req.unitprice_placeholder")}
              disabled={s_disabled.unitprice}
              onChange={(unitprice) => {
                const demandqty = form.getFieldValue("demandqty");
                updateAmount(demandqty, unitprice);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 本幣單價 */}
          <Form.Item label={<Text disabled>{t("PMS.util.localprice")}</Text>} name="localprice">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>

        <Col span={4}>
          {/* 單位類別 */}
          <Form.Item
            label={
              <Text disabled={s_disabled.utype || isUTypeDisabled}>{t("PMS.util.utype")}</Text>
            }
            name="utype"
            // rules={[{required:true,message:"請選擇採購單位類別!"}]}
          >
            <Select
              placeholder={t("PMS.util.utype_placeholder")}
              optionFilterProp="children"
              disabled={s_disabled.utype || isUTypeDisabled}
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && (
                <Select.Option value="unit">{t("PMS.util.unit")}</Select.Option>
              )}
              {f_opunit?.length > 0 && (
                <Select.Option value="opunit">{t("PMS.util.opunit")}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          {/* 請購單位 */}
          <Form.Item
            label={
              <Text disabled={s_disabled.utype || s_disabled.opunit}>{t("PMS.req.punit")}</Text>
            }
            name="punit"
          >
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        {/* 單項金額(未稅) */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("PMS.req.itemsum")}</Text>} name="itemsum">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
        {/* 單項稅額 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("PMS.req.itemsumtax")}</Text>} name="itemsumtax">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
        {/* 單項含稅小計 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("PMS.req.subtotal")}</Text>} name="subtotal">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          {/* <Button onClick={() => set_s_tbModal({ show: false, data: {} })}>
            {t("PMS.util.cancel")}
          </Button> */}
          {/* 確定 */}
          <ConfirmButton htmlType="submit" type="primary" disabled={s_disabled.tb_submit} />
          {/* <Button type="primary" htmlType="submit" disabled={s_disabled.tb_submit}>
            {t("PMS.util.submit")}
          </Button> */}
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
