/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Typography,
  Select,
  InputNumber,
  Button,
  message,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import TbTitle from "@/components/ERP/OMS/TbTitle";
import { PrintButton, SaveButton, SummaryButton, TourButton } from "@/components/ERP/Button";
// util
import { isValidDate } from "@/util/Vaild";
import { formatAmount } from "@/util/format";
// context
import { DataContext } from "../index";
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getCheckTB, updateCheckHD } from "@/service/apis/OMS/Check";

const { Text } = Typography;

const INIT_DISABLED = {};

const MainPage = () => {
  const { t } = useTranslation();
  // useAPI 取得表身
  const call_getCheckTB = useAPI(getCheckTB);
  // useAPI 編輯表頭
  const call_updateCheckHD = useAPI(updateCheckHD);
  const [form] = Form.useForm();
  // 廠商資料、人員資料
  const { c_custs } = useContext(ERPContext);
  const { c_state, updateState } = useContext(DataContext);
  const { mainPage } = c_state;
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 所有明細資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    call_updateCheckHD.request({
      ...mainPage.data,
      ...values,
    });
  };

  // 第一次渲染取得表身資料
  useEffect(() => {
    call_getCheckTB.request({
      opUUID: mainPage.data?.opUUID,
      opcode: mainPage.data?.opcode,
    });

    // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
    const newData = { ...mainPage.data };
    Object.entries(mainPage.data).forEach(([key, value]) => {
      if (isValidDate(value)) {
        newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
      }
    });
    form.setFieldsValue({
      ...newData,
    });
  }, []);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getCheckTB;
    if (status === "suc") {
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCheckTB.status]);

  // useAPI 編輯表頭
  useEffect(() => {
    const { status, msg } = call_updateCheckHD;
    if (status === "suc") {
      message.success(msg);
      updateState({ mainPage: { show: false, data: {} } });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateCheckHD.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("util.idmtype"), // 過帳型態
      dataIndex: "idmtype",
      align: "center",
      width: "10%",
      render: (text, _, __) => {
        const map = {
          S: t("util.idmtype_s"), // 人工計數
          D: t("util.idmtype_d"), // 條碼計數
          M: t("util.idmtype_m"), // 混合計數
        };

        return map[text];
      },
    },
    {
      title: t("OMS.util.N14"), // 發貨單號
      dataIndex: "N14",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.N13"), // 銷貨單號
      dataIndex: "N13",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.N12"), // 報價單號
      dataIndex: "N12",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.N11"), // 報價單號
      dataIndex: "N11",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.check.planqty"), // 移轉數量
      dataIndex: "planqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "10%",
      render: (text) => {
        const map = {
          0: "", // 發佈
          T: "", // 結案
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
  ];

  return (
    <div className="flex flex-col h-full">
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <PageHeader
              // title={mainPage.data?.opcode === "4220" ? "銷售發貨單主檔" : "銷售退回單主檔"}
              title={
                mainPage.data?.opcode === "4220"
                  ? t("OMS.check.4220_header")
                  : t("OMS.check.4130_header")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={5}>
            <Form.Item name="opcode" label={<Text disabled>{t("OMS.util.opcode")}</Text>}>
              <Select
                placeholder="請選擇單別"
                disabled
                options={[
                  { label: t("OMS.util.opcode_4220"), value: "4220" }, // 銷售發貨單
                  { label: t("OMS.util.opcode_4130"), value: "4130" }, // 銷售退回單
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={5}>
            <Form.Item label={<Text disabled>{t("OMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 移轉總量 */}
          <Col span={5}>
            <Form.Item name="total" label={<Text disabled>{t("OMS.check.total")}</Text>}>
              <InputNumber className="w-full" disabled />
            </Form.Item>
          </Col>
          {/* 來源單號 */}
          <Col span={5}>
            <Form.Item name="jobN" label={<Text disabled>{t("OMS.check.jobN")}</Text>}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={4} className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2">
            <Button className="flex-1 h-full" disabled>
              {/* 報價紀錄查詢 */}
              {t("OMS.util.quoSearch")}
            </Button>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶ID */}
          <Col span={5}>
            <Form.Item name="custID" label={<Text disabled>{t("OMS.util.custID")}</Text>}>
              <Select disabled options={c_custs.options} />
            </Form.Item>
          </Col>
          {/* 客戶簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.custalias")}</Text>} name="custalias">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 批號 */}
          <Col span={5}>
            <Form.Item
              label={<Text disabled={s_disabled.batchno}>{t("OMS.check.batchno")}</Text>}
              name="batchno"
            >
              <Input
                disabled={s_disabled.batchno}
                placeholder={t("util.placeholder_input") + t("OMS.check.batchno")}
              />
            </Form.Item>
          </Col>
          {/* 批號日期 */}
          <Col span={5}>
            <Form.Item
              label={<Text disabled={s_disabled.batchdate}>{t("OMS.check.batchdate")}</Text>}
              name="batchdate"
            >
              <DatePicker disabled={s_disabled.batchdate} className="w-full" />
            </Form.Item>
          </Col>
          <Col span={4} className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2">
            <Button
              className="h-full flex-1"
              disabled
              // onClick={() => go_req()}
              // onClick={() => go_pro()}
            >
              {/* 銷貨紀錄查詢 */}
              {t("OMS.util.ordSearch")}
            </Button>
          </Col>
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          <Col span={20} className="pb-2">
            <Form.Item label={<Text>{t("OMS.check.note")}</Text>} name="note">
              <Input />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              <Button className="h-full flex-1" disabled>
                {/* 發貨紀錄查詢 */}
                {t("OMS.util.outstockSearch")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <div className="flex flex-col flex-1">
        <div className="flex justify-between items-center py-2">
          {/* 驗收明細 */}
          {/* <TbTitle>{mainPage.data?.opcode === "4220" ? "發貨明細" : "退回明細"}</TbTitle> */}
          <TbTitle>
            {mainPage.data?.opcode === "4220" ? t("OMS.check.4220_tb") : t("OMS.check.4130_tb")}
          </TbTitle>
        </div>
        <CustomTable
          columns={tableColumns}
          rowNumber={6}
          dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
        />
      </div>
    </div>
  );
};

export default MainPage;
