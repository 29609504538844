const vi_VN = {
  util: {
    "itemID": "ID hạng mục ",
    "itemno": "Đơn hàng ",
    "sign_out": "Đăng xuất",
    "add": "Thêm mới ",
    "edit": "Thay đổi ",
    "delete": "xóa bỏ",
    "search": "Tìm kiếm",
    "cancel": "Hủy",
    "close": "Khép kín",
    "ok": "xác nhận",
    "render": "選擇",
    "check": "覆核",
    "success": "成功",
    "all": "Toàn bộ ",
    "yes": "Yes ",
    "no": "No ",
    "name": "Tên ",
    "total": "Tổng lượng",
    "SN": "Số thứ tự",
    "detail": "chi tiết",
    "action": "Thao tác",
    "size_big": "特大",
    "size_large": "Cỡ lớn",
    "size_middle": "Cỡ trung ",
    "size_small": "Cỡ nhỏ",
    "hide_detail": "Ẩn tệp chi tiết",
    "del_confirm": "Xác nhận xóa",
    "del_confirm_msg": "Vui lòng xác nhận dữ liệu di chuyển không có sai xót, rồi xác nhận xóa",
    "note": "Ghi chú lịch trình  ",
    "no_data": "Không có dữ liệu",
    "data": "dữ liệu",
    "header_cancel_create": "Hủy tạo dựa trên",
    "header_create": "Tạo mục từ này",
    "placeholder_select": "lựa chọn",
    "placeholder_input": "đầu vào",
    "or": "或",
    "order": "đặt hàng",
    "showTotal": "Tổng có {{total}} mục, đang hiện thị điều {{rangeFirst}} đến mục {{rangeEnd}}",
    "itemNM": "Tên mục",
    "excluding_tax": "(未稅)",
    "start": "起",
    "end": "迄",
    "wl": "工線",
    "ws": "Trạm làm việc",
    "woN": "工單號",
    "linemix": "混線",
    "situF": "狀態",
    "PN": "Số liệu",
    "whID": "Loại kho hàng",
    "idmtype": "Loại nhập sổ",
    "custID": "ID khách hàng ",
    "custPN": "客戶料號",
    "pdtNM": "Tên sản phẩm",
    "pdtspec": "Sự chỉ rõ",
    "planqty": "Số lượng kế hoạch",
    "inqty": "生產數量",
    "tqty": "Số hàng tồn",
    "sqty": "Nhân công nhập",
    "dqty": "條碼掃入",
    "okC": "良品數量",
    "inC": "產出數量",
    "ngC": "不良數量",
    "lifeF": "Lịch trình",
    "isvalid": "Có khởi động không",
    "isfocus": "Có chú ý không",
    "dept": "Bộ phận ",
    "updtT": "Cập nhật thời gian ",
    "pdtclass": "Danh mục sản phẩm ",
    "mtype": "Loại máy",
    "ver": "Mã bản",
    "qty": "Số nhóm",
    "attribute": "Thuộc tính vật liệu ",
    "unitNM": "Đơn vị đếm",
    "opunitNM": "Đơn vị thao tác",
    "unitwt": "Đếm trọng lượng đơn",
    "mgmtcat": "Phân loại ủy quyền",
    "querycat": "Phân loại tìm kiếm ",
    "drawno": "Số bản vẽ kỹ thuật",
    "matl": "Chất liệu",
    "moldID": "模具ID",
    "heirPN": "Code no kế thừa",
    "heirdealID": "Đối tượng kế thừa",
    searchPN: "Tìm kiếm số liệu",
    "edit_detect_msg1": "Hệ thống phát hiện bạn đã chỉnh sửa trường dữ liệu sau",
    "edit_detect_msg2": "Vui lòng lưu dữ liệu hệ thống trước khi chỉnh sửa các chi tiết",
    "msg_chose_data": "Vui lòng chọn dữ liệu để tham chiếu",
    "msg_chose_whichType_toThrow": "Vui lòng chọn loại tài liệu để chuyển đổi",
    "msg_chose_atleast_one_Detail": "Vui lòng chọn ít nhất một chi tiết",
    "linemix_0": "不混線",
    "linemix_1": "右",
    "linemix_2": "左",
    "idmtype_s": "Đếm thủ công ",
    "idmtype_d": "Đếm mã vạch",
    "idmtype_m": "Đếm hỗn hợp ",
    "attribute_0": "0 Raw Matls",
    "attribute_1": "1 Consumables ",
    "attribute_2": "2 Non-Std Parts ",
    "attribute_3": "3 Standard Parts ",
    "attribute_4": "4 Semi-Manufactures ",
    "attribute_5": "5 Finished Goods ",
    "attribute_6": "6 Customer Offerings ",
    "pdtclass_X": "N/A",
    "pdtclass_M": "Commodity ",
    "pdtclass_A": "Accessory ",
    "pdtclass_P": "Packaging ",
    "packtype_0": "操作包裝_操作單位使用",
    "packtype_1": "出貨包裝_打包出貨使用",
    "packtype_2": "週轉包裝_產線週轉使用",
    "assetkind": "資產分類",
    "assetkind_TI": "Phun ",
    "assetkind_TS": "Dập",
    "assetkind_TD": "Đúc",
    "assetkind_TF": "Rèn",
    "fileType_SOP": "SOP",
    "fileType_SIP": "SIP",
    "fileType_PACK": "PACK",
    "fileType_DRAW": "DWG",
    "fileType_PIC": "PIC",
    "nav_pds_dashboard": "Kanban sản xuất kỹ thuật số",
    "nav_pds_abc-board": "Kanban quản lý bất thường",
    "nav_pds_overview-lines": "線體稼動總表",
    "nav_BDM": "Quản lý dữ liệu vật liệu",
    "nav_bdm_pn-manage": "Bảo trì dữ liệu cơ bản",
    "nav_bdm_edm": "Bảo trì dữ liệu kỹ thuật",
    "nav_EDM": "Quản lý dữ liệu thiết bị",
    "nav_edm_mold": "Bảo trì dữ liệu khuôn",
    "nav_TDM": "Quản lý dữ liệu kỹ thuật",
    "nav_tdm_plant-manage": "Cài đặt tệp chính của nhà xưởng",
    "nav_tdm_procedure-manage": "Quy trình kế hoạch bảo trì",
    "nav_tdm_production-line-manage": "Lập kế hoạch và bảo trì dây chuyền sản xuất",
    "nav_tdm_warehouse-manage": "Bảo trì quy hoạch nhà kho",
    "nav_tdm_string-manage": "Quản lý chuỗi công năng",
    "nav_tdm_plant-params-manage": "廠別參數設定",
    "nav_SDM": "Quản lý dữ liệu hệ thống",
    "nav_sdm_user-manage": "Bảo trì dữ liệu nhân viên",
    "nav_sdm_company": "Bảo trì dữ liệu nhà cung cấp",
    "nav_sdm_client": "Bảo trì dữ liệu khách hàng",
    "nav_sdm_category-manage": "Cài đặt phân loại tìm kiếm",
    "nav_sdm_data-auth-manage": "Cài đặt ủy quyền chức năng",
    "nav_sdm_trading-pattern": "Cài đặt chuỗi thường dùng",
    "nav_sdm_function-params-manage": "Cài đặt tham số hệ thống ",
    "nav_WMS": "Hệ thống quản lý kho",
    "nav_wms_plan-in": "Hoạt động nhập kho",
    "nav_wms_plan-out": "Hoạt động xuất kho",
    "nav_wms_stock-search": "Kiểm tra tình hình hàng tồn kho",
    "nav_wms_inv-history": "每月庫存紀錄",
    "nav_wms_pro-instock": "Hoạt động đại lý nhập kho",
    "nav_PMS": "Hệ thống quản lý thu mua",
    "nav_pms_plan": "詢價計畫",
    "nav_pms_req": "Hoạt động yêu cầu thu mua",
    "nav_pms_pro": "Hoạt động thu mua",
    "nav_pms_instock": "Hoạt động nhập liệu",
    "nav_pms_gostock": "Tìm kiếm kiểm tra nhập liệu",
    "nav_pms_return": "Hoạt động hàng bán bị trả lại",
    "nav_pms_ap-manage": "Quản lý khoản phải thu",
    "nav_pms_report": "報表查詢",
    "nav_pms_report-unpurchase": "採購未進貨明細表",
    "nav_pms_report-diff": "採購價格調幅表",
    "nav_pms_report-instocksummary": "庫存彙總表",
    "nav_pms_report-inventorydiff": "庫存異動明細帳",
    "nav_pms_report-psisummary": "進銷存彙總表",
    "nav_pms_report-purchasesummary": "進退貨彙總表",
    "nav_pms_report-purchaselist": "進退貨明細表",
    "nav_pms_report-purchasestatistics": "進退貨統計表",
    "nav_OMS": "Hệ thống quản lý bán hàng",
    "nav_oms_quo": "Hoạt động báo giá",
    "nav_oms_fod": "Hoạt động đặt trước",
    "nav_oms_ord": "Hoạt động bán hàng",
    "nav_oms_outstock": "Hoạt động phân phối",
    "nav_oms_check": "Tìm kiếm kiểm tra bán hàng",
    "nav_oms_return": "Hoạt động hàng bán bị trả lại",
    "nav_oms_ap-manage": "Quản lý khoản phải thu",
    "nav_oms_report": "報表查詢",
    "nav_oms_report-unshipped": "訂單未出明細表",
    "nav_oms_report-salesummary": "銷退貨彙總表",
    "nav_oms_report-salelist": "銷退貨明細表",
  },
  WOS: {
    "WOC": "生產控制",
    "MC": "物料作業",
    "QC": "質控作業",
    "TK": "生產看板",
    "partsBinding": "部品掃碼",
    "ppidp": "成品綁定",
    "wpcSearch": "途單查詢",
    "dispachManage": "派單管理",
    "mold": "模具管理",
    "whm": "工時管理",
    "batch": "批號管理",
    "oem": "委外報工",
  },
  WMS: {
    "pageHeader": "Hệ thống quản lý kho",
    "util": {
      "lifeF_0": "已創建",
      "lifeF_1": "執行中",
      "lifeF_2": "已提交",
      "lifeF_A": "全部",
      "lifeF_W": "待規劃",
      "lifeF_R": "已歸還",
      "lifeF_T": "已異動",
      "opcode_4010": "開帳盤點",
      "opcode_4020": "週期盤點",
      "opcode_4030": "專案盤點",
      "opcode_4110": "生產入庫",
      "opcode_4120": "Nhập kho thu mua",
      "opcode_4130": "銷售退回",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4141": "庫房調入",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4143": "借出歸還",
      "opcode_4142": "庫房借入",
      "opcode_4243": "借入歸還",
      "opcode_4210": "生產領用",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "其他入庫",
      "opcode_4160": "生產退料",
      "opcode_4260": "生產超領",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "來源單號",
      "preN_4120": "進料單號",
      "preN_4130": "來源單號",
      "preN_4220": "來源單號",
      "preN_4230": "來源單號",
      "preN_4141": "來源單號",
      "preN_4241": "來源單號",
      "preN_4242": "來源單號",
      "preN_4143": "來源單號",
      "preN_4142": "來源單號",
      "preN_4243": "來源單號",
      "preN_4210": "來源單號",
      "preN_4299": "來源單號",
      "preN_4150": "來源單號",
      "preN_4170": "來源單號",
      "preN_4160": "來源單號",
      "preN_4260": "來源單號",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4299": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "whID_4010": "盤點單號",
      "whID_4020": "盤點單號",
      "whID_4030": "盤點單號",
      "whID_4110": "單號",
      "whID_4120": "採購單號",
      "whID_4130": "銷售單號",
      "whID_4220": "銷售單號",
      "whID_4230": "退出單號",
      "whID_4141": "本倉",
      "whID_4241": "本倉",
      "whID_4242": "倉別",
      "whID_4143": "倉別",
      "whID_4142": "倉別",
      "whID_4243": "倉別",
      "whID_4160": "倉別",
      "whID_4260": "倉別",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4299": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4299": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "異動提交確認",
      "txn_confirm_msg": "請先確認資料無誤，再異動",
      "jobN": "作業單號",
      "lifeF": "歷程",
      "opcode": "作業單別",
      "plantxnqty": "規劃異動量",
      "realtxnqty": "實際異動量",
      "planqty": "計畫數量",
      "realqty": "實際數量",
      "totalqty": "總數",
      "planN": "來源單號碼",
      "updtT": "更新時間",
      "whID": "倉別",
      "pdtNM": "品名",
      "bal": "帳差",
    },
    "plan-in": {
      "pageHeader": "Hoạt động nhập kho ",
    },
    "plan-out": {
      "pageHeader": "Hoạt động xuất kho ",
    },
    "stock-search": {
      "pageHeader": "Kiểm tra tình hình hàng tồn kho ",
      "searchPN": "Tìm kiếm số liệu",
      "searchWh": "Tìm kiếm từng kho hàng",
      "whDetail": "各倉明細",
      "changeDetail": "異動明細",
      "borrow": "Mượn",
      "inTransit": "On going",
      "loan": "Vay ",
      "lockqty": "Số hàng niêm phong",
      "onhandqty": "Số hàng tồn còn hạn",
      "planin": "Kế hoạc nhập kho",
      "planout": "Kế hoạc xuất kho",
      "posted": "Đã nhập sổ ",
      "scanin": "Kiểm kê nhập kho ",
      "scanout": "Kiểm kê xuất kho",
      "timeSeries": "Thứ tự thời gian ",
      "total": "Ước tính số lượng có thể dùng",
      "totalIn": "Tổng nhập kho",
      "totalOut": "Tổng xuất kho ",
      "tqty": "Số hàng tồn",
    },
    "inv-history": {
      "pageHeader": "",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "",
      "inCBM": "進\n(CBM)",
      "l": "",
      "level": "",
      "suppalias": "",
      "unit": "",
      "w": "",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "Hoạt động đại lý nhập kho ",
      "lifeF_T": "Đã quy hoạch ",
      "wmsopUUID": "Số đơn hoạt động lưu trữ",
      "inchargeID": "Nhân viên phụ trách ",
    },
  },
  ADM: {
    "oem-manage": {
      "type": "Danh mục thuê ngoài",
      "itemID": "ID hạng mục ",
      "oem_item_manage": "Cài đặt hạng mục thuê ngoài",
      "oem_item": "Hạng mục thuê ngoài",
      "type_A": "Nhân lực",
      "type_B": "Bề ngoài",
      "type_C": "",
      "oem_supp_manage": "Cung cấp bảo trì thuê ngoài",
      "oem_supp": "Cung cấp thuê ngoài",
      "vendorcode": "Mã cung cấp",
      "pfNM": "Tên quy trình ",
      "oemitemNM": "Tên hạng mục thuê ngoài",
    },
    "function-params-manage": {
      "pageHeader": "Cài đặt tham số hệ thống ",
      "searchPlaceholder": "Tìm kiếm ID, đơn vị, mã số, mã chức năng, tên phân cấp",
      "unitSetting": "Cài đặt đơn vị đếm",
      "opUnitSetting": "Cài đặt đơn vị thao tác ",
      "currencySetting": "Cài đặt tiền tệ giao dịch ",
      "functions": "Cài đặt số nhóm đẩy",
      "pushlvl": "Cài đặt số tầng đẩy",
      "notify": "Cài đặt mức đẩy sự việc ",
      "unit": "Đơn vị đếm",
      "unitNM1": "Tên đơn vị ",
      "type": "Trạng thái đếm",
      "category": "Danh mục đếm",
      "countType": "Cách đếm",
      "quantitative": "Đếm",
      "count": "Cách đếm",
      "weight": "Trọng lượng ",
      "length": "Độ dài",
      "capacity": "Dung tích",
      "area": "Diện tích",
      "volume": "Thể tích",
      "opunit": "Đơn vị thao tác ",
      "sort": "Sắp xếp",
      "currencyitem": "Mã tiền tệ",
      "itemNM": "Tên tiền tệ ",
      "currency": "Loại tiền giao dịch ",
      "ismycurrency": "Có phải loại tiền này không?",
      "funcode": "Mã công năng tổ chức ",
      "funNM": "Tên công năng ",
      "function": "Đẩy nhóm",
      "lvlcode": "Mã số tầng",
      "lvlNM": "Tên số tầng",
      "pushlayer": "Đẩy phân cấp",
      "team": "1(Nhóm đội)",
      "class": "2(Cấp tổ trưởng)",
      "management": "3(Cấp quản lý)",
      "total": "4(Cấp cao )",
      "eventID": "ID Sự kiện ",
      "eventNM": "Tên sự kiện ",
      "rptcode": "Danh mục đề cập",
      "pusher": "Hệ thống gửi",
      "situF": "Đèn cảnh báo ",
      "audience": "Đẩy đối tượng",
      "startlvl": "Cấp độ bắt đầu",
      "setlvl": "Số nhóm đẩy",
      "cruisebot": "Chương trình giám sát ",
      "msgmodel": "Đẩy tin nhắn bản mẫu",
      "pushEvent": "Đẩy sự việc",
      "rptcode_A0": "Nhân viên báo cáo ",
      "rptcode_B0": "Thông báo hệ thống(WOS)",
      "rptcode_C0": "Báo cáo thiết bị ",
      "rptcode_D0": "Báo cáo quản lý -Từ xa",
      "rptcode_E0": "Báo cáo quản lý -Đám mây",
      "situF_Y": "Vàng",
      "situF_R": "Đỏ",
      "startlvl_0": "Toàn bộ nhân viên ",
      "startlvl_1": "Cán bộ nhóm (tổ)",
      "startlvl_2": "Cán bộ tổ trưởng ",
      "startlvl_3": "Quản lý ",
      "startlvl_4": "Quản lý cấp cao ",
      "add_string": "Thêm chuỗi",
      "add_number": "Thêm số",
      "add_point": "Thêm dấu thập phân",
      "1_decimal_place": "Sau dấu thập phân 1 đơn vị",
      "2_decimal_place": "Sau dấu thập phân 2 đơn vị",
    },
    "procedure-manage": {
      "pageHeader": "Quy trình kế hoạch bảo trì",
      "pccID": "ID trung tâm chi phí",
      "pccNM": "Tên trung tâm chi phí",
      "pf": "Quy trình ",
      "pfID": "ID quy trình ",
      "pfNM": "Tên quy trình ",
      "pwc": "Trung tâm công việc ",
      "pwcID": "ID trung tâm làm việc ",
      "pwcNM": "Tên trung tâm công việc ",
      "tabCost": "Cài đặt trung tâm chi phí",
      "tabFlow": "Quy trình cài đặt tệp chính ",
      "tabWork": "Cài đặt trung tâm làm việc ",
    },
    "user-manage": {
      "dashboard": "Kanban sản xuất kỹ thuật số",
      "abc-board": "Kanban quản lý bất thường",
      "BDM": "Quản lý dữ liệu vật liệu ",
      "SDM": "Quản lý dữ liệu hệ thống",
      "TDM": "Quản lý dữ liệu kỹ thuật",
      "EDM": "Quản lý dữ liệu thiết bị ",
      "WMS": "Hệ thống quản lý kho",
      "OMS": "Hệ thống quản lý bán hàng ",
      "PMS": "Hệ thống quản lý thu mua",
      "WOC": "Kiểm soát sản xuất ",
      "MC": "Hoạt động vật liệu ",
      "QC": "Hoạt động kiểm soát chất lượng ",
      "TK": "Kanban sản xuất ",
      "wpcSearch": "Tìm kiếm vận đơn",
      "dispachManage": "Quản lý công văn ",
      "ppidp": "Ràng buộc thành phẩm ",
      "partsBinding": "Mã quét bộ phận",
      "account": "Tài khoản nhân viên ",
      "pwd": "Mật mã",
      "role": "Quyền vai trò",
      "roleID": "ID vai trò",
      "roleNM": "Tên vai trò",
      "systemAuth": "Quyền đăng nhập hệ thống",
      "autoReport": "Nhận báo cáo tự động",
      "APP": "Ứng dụng di động",
      "PDA": "Quyền PDA",
      "dealmgmt": "Đối tượng phân loại ủy quyền",
      "pdtmgmt": "Vật liệu phân loại ủy quyền ",
      "whmgmt": "Kho phân loại ủy quyền ",
      "cardID": "Mã số thẻ ",
      "function": "Công năng tổ chức ",
      "level": "Mức đẩy",
      "mail": "Địa chỉ bưu điện ",
      "pageHeader": "Bảo trì dữ liệu nhân viên ",
      "peopleNM": "Họ tên nhân viên ",
      "personalID": "Số căn cước công dân ",
      "postID": "Chức danh ",
      "proxyNM": "Họ và tên người liên lạc",
      "proxytel": "Số điện thoại liên hệ ",
      "staffAuth": "Cài đặt quyền của vai trò",
      "staffData": "Quản lý dữ liệu nhân viên ",
      "staffID": "Mã số nhân viên ",
      "tel": "Điện thoại",
    },
    "trading-pattern": {
      "OMS": "Hệ thống đơn hàng",
      "PMS": "Hệ thống thu mua ",
      "adda": "Đánh dấu điều khoản",
      "addb": "Khẩu trừ bổ sung ",
      "addc": "Hoạt động trả trước",
      "addd": "Phương thức thanh toán ",
      "adde": "Điều kiện giao dịch ",
      "addf": "Hoạt động trả hàng ",
      "addg": "Hủy đơn ",
      "addh": "Phương thức thanh toán ",
      "addi": "Điều khoản thanh toán ",
      "additem": "ID hạng mục ",
      "addr": "Địa chỉ liên lạc",
      "addtype": "Điều kiện giao dịch ",
      "contact": "Người liên hệ",
      "contactitem": "Mã cửa sổ liên lạc ",
      "department": "Bộ phận ",
      "itemNM": "Tên cửa sổ liên lạc ",
      "mail": "Địa chỉ bưu điện",
      "mobile": "Điện thoại di động",
      "pageHeader": "Cài đặt chuỗi thường dùng ",
      "tabAdded": "Cài đặt mục bổ sung",
      "tabWindow": "Cài đặt cửa sổ đối ngoại",
      "tabPMSAdded": "Điều kiện thu mua bổ sung ",
      "tabOMSAdded": "Điều kiện bán hàng bổ sung",
      "tel": "Số điện thoại liên lạc",
    },
    "data-auth-manage": {
      "mgmtitem": "Danh mục ",
      "pageHeader": "Cài đặt ủy quyền chức năng",
      "tabCust": "Nhóm quyền dữ liệu khách hàng ",
      "tabPdt": "Nhóm ủy quyền vật liệu",
      "tabSupp": "Nhóm ủy quyền thông tin nhà xưởng ",
      "tabWh": "Nhóm ủy quyền kho ",
    },
    "category-manage": {
      "mgmtitem": "Hạng mục ",
      "pageHeader": "Cài đặt phân loại tìm kiếm ",
      "tabCust": "Phân loại tìm kiếm khách hàng ",
      "tabQuery": "Phân loại tìm kiếm vật liệu ",
      "tabSupp": "Phân loại tìm kiếm nhà cung cấp",
      "tabMtype": "Phân loại loại máy vật liệu",
    },
    "warehouse-manage": {
      "cancelClose": "Hủy đóng tài khoản ",
      "close": "Đóng tài khoản ",
      "isClose": "Đã đóng tài khoản chưa?",
      "mgmtcat": "Uỷ quyền kho ",
      "pageHeader": "Bảo trì quy hoạch nhà kho",
      "pdtClass_0": "原料/耗材",
      "pdtClass_1": "物料",
      "pdtClass_2": "非標零件",
      "pdtClass_3": "通用零件",
      "pdtClass_4": "半成品",
      "pdtClass_5": "成品",
      "pdtclass": "Giai đoạn vật liệu",
      "ps": "Xưởng ",
      "tabWareHouse": "Cài đặt dữ liệu kho ",
      "whID": "ID Kho ",
      "whNM": "Tên kho",
      "psNM": "Tên xưởng",
    },
    "string-manage": {
      "pageHeader": "Quản lý chuỗi công năng ",
      "tabPause": "Dự án đình công ",
      "tabOkItem": "Sản phẩm tốt",
      "tabNgItem": "Sản phẩm lỗi",
      "tabDri": "Đơn vị chịu trách nhiệm ",
      "tabOffLine": "Dự án ngoại tuyến ",
      "driItemID": "ID đơn vị ",
      "driItemNM": "Tên đơn vị ",
    },
    "client": {
      "groupalias": "Tên tập đoàn viết tắt ",
      "location": "Khu vực thị trường",
      "account": "Tài khoản ngân hàng ",
      "addCustomerInformation": "Thêm mới thông tin khách hàng ",
      "addr": "Địa chỉ công ty ",
      "address": "Địa chỉ liên lạc ",
      "bankAddrOS": "Địa chỉ ngân hàng ",
      "bankCodeOS": "Mã số chi nhánh",
      "branchOS": "Tên chi nhánh ",
      "classificationOfRestrictions": "Quyền truy cập ",
      "contact": "Người liên lạc ",
      "country": "Quốc gia",
      "custID": "ID khách hàng ",
      "custNM": "Tên khách hàng ",
      "custalias": "Tên viết tắt của khách hàng ",
      "customerBasicInformation": "Thông tin khách hàng cơ bản ",
      "customerContactInformation": "Thông tin liên lạc khách hàng ",
      "department": "Bộ phận ",
      "domesticBankName": "Tên ngân hàng trong nước ",
      "domestic_OverseasBankInformation": "Thông tin ngân hàng nước ngoài/trong nước",
      "fullRangeOfFurniture": "Đẩy đủ đồ dùng nội thất",
      "item": "10 điều/trang ",
      "itemNM": "Công năng cửa sổ ",
      "itemno": "Đơn hàng ",
      "livingRoomFurniture": "Đồ dùng phòng khách ",
      "mail": "Mail",
      "maincurrency": "Currency",
      "maxcredit": "Điều kiện tín dụng ",
      "mobile": "Số điện thoại di động ",
      "modifyContactWindowInformation": "Sửa đổi thông tin cửa sổ liên lạc ",
      "modifyCustomerInformation": "Sửa đổi thông tin khách hàng ",
      "no": "No",
      "ordertype": "Danh mục đặt hàng ",
      "overseasBankName": "Tên ngân hàng nước ngoài ",
      "pageHeader": "Bảo trì dữ liệu khách hàng ",
      "pageHeader2": "Quản lý thông tin khách hàng ",
      "payterm": "Phương thức thanh toán ",
      "postID": "Chức danh  ",
      "showTotal": "Tổng 1 mục, đang hiển thị từ 1 đến 1",
      "swiftCode": "Swift Code",
      "taxID": "Mã số thuế",
      "tel": "Số điện thoại công ty ",
      "username": "Tên tài khoản",
      "yes": "Yes",
    },
    "company": {
      "manufacturerBasicInformation": "Thông tin nhà cung cấp cơ bản ",
      "manufacturerContactInformation": "Thông tin liên lạc nhà cung cấp",
      "pageHeader": "Bảo trì dữ liệu nhà cung cấp ",
      "suppID": "ID nhà cung cấp ",
      "suppNM": "Tên nhà cung cấp",
      "suppalias": "Tên viết tắt nhà cung cấp",
      "groupalias": "Tên tập đoàn viết tắt",
      "location": "Khu vực thị trường",
      "account": "Tài khoản ngân hàng ",
      "addCustomerInformation": "新增客戶資料",
      "addr": "Địa chỉ công ty ",
      "address": "連絡住址",
      "bankAddrOS": "Địa chỉ ngân hàng ",
      "bankCodeOS": "Mã số chi nhánh",
      "branchOS": "Tên chi nhánh ",
      "classificationOfRestrictions": "Quyền truy cập ",
      "contact": "Người liên lạc ",
      "country": "Quốc gia ",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "Bộ phận ",
      "domesticBankName": "Tên ngân hàng trong nước ",
      "domestic_OverseasBankInformation": "Thông tin ngân hàng trong nước/nước ngoài",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10 diều /trang ",
      "itemNM": "Công năng cửa sổ",
      "itemno": "Đơn hàng",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "Mail",
      "maincurrency": "Currency",
      "maxcredit": "Điều kiện tín dụng ",
      "mobile": "Số điện thoại di động ",
      "modifyContactWindowInformation": "編輯聯絡窗口資料",
      "modifyCustomerInformation": "編輯客戶資料",
      "no": "No",
      "ordertype": "訂貨類別",
      "overseasBankName": "Tên ngân hàng nước ngoài",
      "payterm": "Phương thức thanh toán ",
      "postID": "Chức danh ",
      "showTotal": "Tổng 1 mục, đang hiển thị từ 1 đến 1 ",
      "swiftCode": "SWIFT CODE",
      "taxID": "Mã số thuế",
      "tel": "Số điện thoại công ty ",
      "username": "Tên tài khoản",
      "yes": "Yes",
    },
    "edm": {
      "alertspeed": "Báo động tốc độ sản xuất ",
      "alertstop": "Báo động quá mức",
      "warnyield": "Đèn vàng năng suất",
      "alertyield": "Đèn đỏ năng suất",
      "attribute": "Thuộc tính vật liệu ",
      "bom": "Hóa đơn bảo trì vật liệu ",
      "errorPleaseFull": "Vui lòng nhập dữ liệu hoàn chỉnh ",
      "ieUUID": "Số hối phiếu",
      "isrework": "Có bảng hành trình sản xuất làm lại không?",
      "level": "Giai đoạn vật liệu ",
      "nextSN": "Số hành trình thứ 2",
      "pPN": "Số hiệu mặt hàng gốc",
      "pageHeader": "Bảo trì dữ liệu kỹ thuật ",
      "pdtclass": "Danh mục sản phẩm ",
      "pfNM": "Quá trình",
      "planhead": "Số người kế hoạch ",
      "ppdtNM": "Tên sản phẩm gốc",
      "productionScheduleDetails": "Phiếu chi tiết lịch trình ",
      "pwcNM": "Trung tâm làm việc",
      "qty": "Số nhóm",
      "stdlt": "Thời gian chuẩn bị sản xuất ",
      "stdmh": "Thời gian máy tiêu chuẩn",
      "stdwh": "Giờ công tiêu chuẩn",
      "batchbom": "Số lô đầy đủ gói",
      "unitNM": "Đơn vị đếm",
      "updtT": "Cập nhật thời gian",
      "ver": "Mã bản",
      "wpcManage": "Bảo trì lịch trình sản xuất ",
      "wpc": "Quy trình sản xuất",
      "wpcSN": "Số lịch trình ",
      "wpcSetting": "Cài đặt số lịch trình ",
      "wpcset": "Nhóm lịch trình ",
      "wpcsetNM": "Tên nhóm lịch trình",
      "whID": "Loại nhập kho",
      "alternative_wpc": "Quy trình thay thế",
      "main_wpc": "Quy trình chính ",
      "edit_all_wpc": "Thay đổi lịch trình sản xuất toàn cầu",
      "Pfc": "Kiểm soát văn kiện kỹ thuật ",
      "doc": "Phát hành văn kiện tiêu chuẩn ",
      "ECN": "Thông báo thay đổi kỹ thuật ",
      "pdftype": "Loại văn kiện ",
      "docUpload": "Tải văn kiện lên",
      "Upload": "Tải lên",
      "docName": "Tên văn kiện ",
      "docVer": "Bản văn kiện ",
    },
    "pn-manage": {
      "basicProduct": "Dữ liệu sản phẩm cơ bản ",
      "dealPNSearch": "Tìm kiếm code no giao dịch",
      "boxH": "Kích thước đóng gói(cao)",
      "boxL": "Kích thước đóng gói(dài)",
      "boxW": "Kích thước đóng gói(rộng)",
      "dealID": "ID đối tượng ",
      "dealNM": "Tên giao dịch ",
      "dealPN": "Code No giao dịch/SKU",
      "dealalias": "Viết tắt đối tượng",
      "dealpid": "Mã vạch ",
      "idkw": "Từ khóa mã vạch bao bì",
      "inititalcost": "Lịch trình ban đầu",
      "isfocus": "Có chú ý không?",
      "isvalid": "Có khởi động không",
      "maxcost": "Giới hạn số lượng hàng tồn kho",
      "maxstock": "Giới hạn hàng tồn kho cao nhất",
      "minstock": "Giới hạn hàng tồn kho thấp nhất",
      "moq": "Lượng mua  tối thiểu",
      "newPN": "Bản mới code no",
      "nextpacksn": "Số nhóm đóng gói lớp bên ngoài",
      "opunit": "Đơn vị thao tác ",
      "pack": "Đơn vị đóng gói",
      "packsn": "Số lớp đóng gói",
      "packtype": "Mục đích đóng gói ",
      "pageHeader": "Bảo trì dữ liệu cơ bản",
      "palH": "Pallet (cao)",
      "palL": "Pallet (dài)",
      "palW": "Pallet (rộng)",
      "pbom": "Số nhóm đóng gói lớp bên trong",
      "pdtH": "Kích thước sản phẩm(cao)",
      "pdtL": "Kích thước sản phẩm(dài)",
      "pdtW": "Kích thước sản phẩm(rộng)",
      "punit": "Đơn vị sử dụng lớp bên trong ",
      "purchasecost": "Chi phí nhập liệu trong kỳ",
      "purchaseqty": "Lượng nhập liệu trong kỳ",
      "replacePN": "Code no thay thế",
      "safestock": "Số lượng hàng trong kho an toàn ",
      "salecurrency": "Tỷ giá tiền tệ ",
      "setbatch": "Số lô sử dụng ",
      "setidm": "Cài đặt mã vạch",
      "setpack": "Cài đặt đóng gói",
      "settlemonth": "Thay đổi tháng thanh toán",
      "shipV": "Khối lượng đóng gói Cuft",
      "shipW": "Trọng lượng vận chuyển ",
      "sizeunit": "Đơn vị đo kích thước",
      "stdcost": "Chi phí tiêu chuẩn ",
      "stdsale": "Giá cả tiêu chuẩn ",
      "stdstock": "Hàng tồn kho tiêu chuẩn ",
      "stocklife": "Hàng tồn kho định kì",
      "tabCost": "Cài đặt chi phí tiêu chuẩn ",
      "tabDeal": "Cài đặt code no giao dịch ",
      "tabExtra": "Cài đặt kích thước sản phẩm",
      "tabIdkw": "Cài đặt ràng buộc sản xuất ",
      "tabPack": "Cài đặt ràng buộc bao bì",
      "tabPrice": "Cài đặt gái cả tiêu chuẩn ",
      "tabStock": "Cài đặt điều kiện hàng tồn kho ",
      "unitwt": "Đếm trọng lượng đơn",
      "varcost": "Chi phí biến động ",
      "ver": "Bản tiêu chuẩn đóng gói ",
      "weightunit": "Đơn vị trọng lượng ",
      "mtype": "Loại máy",
    },
    "mold": {
      "pageHeader": "Bảo trì dữ liệu khuôn ",
      "mold": "Dữ liệu khuôn",
      "assetkind": "Loại khuôn ",
      "assetID": "ID khuôn ",
      "assetNM": "Tên tài sản ",
      "pfID": "ID quy trình ",
      "moldtype": "Mẫu khuôn chung ",
      "cavity": "Số lỗ thực tế",
      "stdcavity": "Số lỗ tiêu chuẩn ",
      "validcavity": "Số lỗ dùng được ",
      "wsID": "Trạm ",
      "property": "Thuộc về tài sản ",
      "custID": "ID khách hàng ",
      "company": "Công ty ",
      "gatesystem": "Loại cổng",
      "moldlife": "Tuổi thọ thiết kế",
      "draw": "Bản vẽ khuôn ",
      "pdtpic": "Hình ảnh thành phẩm",
      "malepic": "Hình ảnh khuôn công",
      "femalepic": "Hình ảnh khuôn mẫu",
      "whloepic": "HÌnh ảnh khuôn hỗn hợp",
      "advance": "Dữ liệu khuôn nâng cao ",
      "length": "Tổng chiều dài khuôn",
      "width": "Tổng chiều rộng khuôn ",
      "height": "Tổng chiều cao khuôn ",
      "weight": "Trọng lượng khuôn ",
      "ismrplan": "",
      "ismrcycle": "",
      "setmrcycle": "",
      "ismrperiod": "",
      "setmrperiod": "",
      "premrT": "",
      "premrC": "",
      "nextmrT": "",
      "nextmrC": "",
      "openC": "Mở tài khoản Mô-đun ",
      "openT": "Ngày mờ tài khoản ",
      "cummoldC": "Mô đun sản xuất tích lũy cuối cùng",
      "precummoldC": "Mô đun sản xuất tích lũy của thời gian lần trước",
      "status": "Trạng thái đèn ",
      "note": "Ghi chú khuôn ",
    },
    "plant-manage": {
      "pageHeader": "Cài đặt tệp chính của nhà xưởng",
      "plantID": "Nhà xưởng",
      "deptID": "Bộ phận ",
      "postID": "ID chức danh ",
      "level": "Mức đẩy",
      "push1": "Khoảng thời gian đẩy cấp 1(phút)",
      "push2": "Khoảng thời gian đẩy cấp 2(phút)",
      "push3": "Khoảng thời gian đẩy cấp 3(phút)",
      "tabDept": "Tệp tin tổng thể  bộ phận",
      "tabPlant": "Tệp chính khu vực xưởng",
      "tabPost": "File chức danh chính",
    },
    "production-line-manage": {
      "MC": "Hoạt động vật liệu",
      "QC": "Hoạt động kiểm soát chất lượng ",
      "TK": "Sản xuất Kanban",
      "WOC": "Kiểm soát sản xuất",
      "auth_setting": "Cài đặt quyền hạn ",
      "batch": "Quản lý số lô",
      "dispachManage": "Quản lý xếp đơn",
      "mold": "Quản lý khuôn ",
      "oem": "Báo công thuê ngoài",
      "pageHeader": "Lập kế hoạch và bảo trì dây chuyền sản xuất",
      "partsBinding": "Bộ phận quét mã",
      "pcc": "Trung tâm chi phí",
      "pf": "Quá trình ",
      "ppidp": "Rành buộc thành phẩm",
      "ps": "Xưởng",
      "psID": "ID Xưởng ",
      "psNM": "Tên xưởng ",
      "pwc": "Trung tâm công việc",
      "tabLine": "Cài đặt dữ liệu dây chuyền làm việc ",
      "tabPS": "Cài đặt dữ liệu xưởng ",
      "tabStation": "Cài đặt dữ liệu trạm làm việc ",
      "tbHeader_PS": "Dữ liệu trạm làm việc",
      "tbHeader_Station": "Dữ liệu dây chuyền công việc",
      "whm": "Quản lý thời gian làm việc ",
      "wlID": "ID dây chuyền làm việc ",
      "wlNM": "Tên dây chuyền làm việc",
      "wpcSearch": "Tìm kiếm đơn hành trình ",
      "ws": "Trạm công việc",
      "wsID": "ID trạm làm việc ",
      "wsNM": "Tên trạm làm việc ",
    },
  },
  APS: {
    "util": {
      "opcode": "作業單別",
      "opcode_3310": "正常工單",
      "opcode_3320": "重工工單",
      "opcode_3330": "試產工單",
      "opcode_3340": "工程工單",
      "duestart": "預計完成起日",
      "dueend": "預計完成迄日",
      "realqty": "實際數量",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "Hoạt động điều phối quản lý sản xuất",
      "wo": "Lệnh sản xuất",
      "tabWo": "Tệp chính lệnh sản xuất ",
      "mo": "Đơn liệu ",
      "jobN": "Số lệnh sản xuất ",
      "tabMo": "Tệp chính đơn liệu ",
      "orderN": "Số đơn đặt hàng khách hàng ",
      "ETDstart": "Dự kiến ngày giao hàng ",
      "ETDend": "Ngày giao hàng dự kiến",
      "issueID": "Nhân viên công bố",
      "issueday": "Ngày công bố",
    },
  },
  PMS: {
    "pageHeader": "Hệ thống quản lý thu mua",
    "util": {
      "opcode_2010": "詢價計畫",
      "opcode_2210": "國內請購",
      "opcode_2220": "國外請購",
      "opcode_2230": "委外申請",
      "opcode_2310": "國內採購",
      "opcode_2320": "國外採購",
      "opcode_2330": "委外加工",
      "opcode_2410": "國內進料",
      "opcode_2420": "國外進料",
      "opcode_2430": "委外進料",
      "opcode_2610": "庫房退出",
      "opcode_2620": "品保退出",
      "opcode_2710": "Mua hàng vật liệu",
      "opcode_2810": "應付沖帳",
      "opcode_4120": "驗收單",
      "opcode_4230": "驗退單",
      "plan": "詢價單",
      "req": "請購單",
      "pro": "採購單",
      "instock": "進料單",
      "gostock": "進料檢驗單",
      "return": "進料退出",
      "pay": "應付帳款查詢",
      "ap": "對帳單",
      "strike": "付款沖帳",
      "advanceSearch": "進階查詢",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "table_suppCheckNum": "廠商確認數量",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "submit": "確定",
      "cancel": "取消",
      "sysMsg": "系統訊息",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N22": "請購單號",
      "N23": "採購單號",
      "N24": "進料單號",
      "N27": "對帳單號",
      "N28": "沖帳單號",
      "outN": "驗退單號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "itemsum": "單項金額(未稅)",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "delete_confirm": "刪除確認",
      "reqSearch": "請購紀錄查詢",
      "proSearch": "採購紀錄查詢",
      "instockSearch": "進料紀錄查詢",
      "stockSearch": "驗收紀錄查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "apSearch": "對帳資料查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "currencytype_placeholder": "請選擇幣別",
      "suppID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入税率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "currencytype": "交易幣別",
      "taxtype": "交易稅別",
      "taxrate": "稅率",
      "suppID": "廠商ID",
      "suppalias": "廠商簡稱",
      "suppNM": "廠商名稱",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "EDT": "預計交貨日",
      "demansDate": "需求日期",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmID": "覆核人員",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "updtID": "更新人員",
      "updtT": "更新時間",
      "batchno": "批號",
      "batchdate": "批號日期",
      "stockinT": "移轉時間",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "idmtype": "過帳型態",
      "drawno": "圖號",
      "pbom": "組合數量",
      "utype": "單位類別",
      "punit": "使用單位",
      "unit": "計量單位",
      "opunit": "操作單位",
      "unitprice": "採購單價",
      "localprice": "本幣單價",
      "tax": "總稅額",
      "balqty": "剩餘數量",
    },
    "plan": {
      "pageHeader": "詢價計畫",
      "hd_delete_confirm_text": "",
      "demandqty": "",
      "tb": "",
      "tb_drawer_title": "",
      "rec_drawer_title": "",
    },
    "req": {
      "pageHeader": "Hoạt động yêu cầu thu mua",
      "create_header": "Tạo mới tệp chính yêu cầu thu mua",
      "edit_header": "Thay đổi tệp chính yêu cầu thu mua",
      "hd_note": "Ghi chú yêu cầu thu mua",
      "driID": "Phụ trách yêu cầu thu mua",
      "punit": "Đơn vị yêu cầu thu mua",
      "unitprice": "Đơn giá yêu cầu thu mua(chưa thuế)",
      "demandqty": "Số lượng yêu cầu thu mua ",
      "demandDate": "Ngày yêu cầu",
      "itemsum": "Số tiền mục đơn(chưa thuế)",
      "itemsumtax": "Số thuế mục đơn",
      "itemtotal": "Tổng số tiền chưa thuế",
      "planSearch": "Tìm kiếm biên bản yêu cầu báo giá",
      "local_subtotal": "Tổng số tiền nội tệ gồm thuế",
      "subtotal": "Tổng mục đơn gồm thuế",
      "total": "Tổng số tiền gồm thuế(Loại tiền giao dịch)",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá yêu cầu thu mua",
      "lifeF_7": "Hoàn thành phê duyệt gửi ký",
      "lifeF_T": "Kết án ",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn yêu cầu thu mua không sai xót, rồi tiến  hành xóa",
      "audit_start": "Phê duyệt chính thức gửi ký",
      "audit_finish": "Đã hoàn thành xét duyệt ",
      "reqCheck": "Đánh giá đơn yêu cầu thu mua",
      "cancel_reqCheck": "Hủy đánh giá yêu cầu thu mua",
      "check_confirm": "Xác nhận đánh giá yêu cầu thu mua ",
      "check_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá không sai xót, rồi tiến hành đánh giá ",
      "cancel_check_confirm": "Hủy xác nhận yêu cầu đánh giá thu mua ",
      "cancel_check_confirm_text":
        "Vui lòng xác nhận dữ liệu hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá",
      "reqCheck_lifeF_0_okText": "Xác nhận đánh giá ",
      "reqCheck_lifeF_2_okText": "Hủy đánh giá ",
      "audit_confirm": "Xét duyệt và đăng xuất",
      "audit_confirm_text":
        "Sau khi xét duyệt và đăng xuất, đơn yêu cầu thu mua không thể thay đổi , bạn có chắc chắn hoàn thành xét duyệt không?",
      "audit_okText": "Chắc chắn xét duyệt ",
      "driID_placeholder": "Vui lòng chọn nhân viên yêu cầu thu mua",
      "demandqty_placeholder": "Vui lòng nhập số lượng yêu cầu thu mua ",
      "unitprice_placeholder": "Vui lòng nhập đơn giá chưa thuế",
      "tb_delete_confirm": "Xác nhận xóa phiếu chi tiết yêu cầu thu mua ",
      "tb_title": "Phiếu chi tiết yêu cầu thu mua",
      "create_tb_title": "Thêm mới phiếu chi tiết yêu cầu thu mua",
      "edit_tb_title": "Thay đổi phiếu chi tiết yêu cầu thu mua",
      "throw_modal_submit": "Chắc chắn chuyển đổi(đơn thu mua)",
    },
    "pro": {
      "pageHeader": "Hoạt động thu mua ",
      "create_header": "Tạo mới tệp chính thu mua",
      "edit_header": "Thay đổi tệp chính thu mua ",
      "hd_drawer_title": "Tệp chính đơn thu mua",
      "driID": "Chịu trách nhiệm thu mua",
      "EDT": "Dự tính ngày giao hàng ",
      "startEDT": "Dự tính ngày giao hàng",
      "endEDT": "Dự tính ngày giao hàng (muộn nhất)",
      "sampleID": "Nhân viên lấy mẫu ",
      "supp_history": "Tìm kiếm lịch sử giao dịch nhà cung cấp",
      "trade": "Điều khoản thương mại",
      "dealaddr": "Địa chỉ nhà cung cấp",
      "contact": "Cửa sổ giao hàng nhà cung cấp",
      "dealcontact": "Cửa sổ nghiệp vụ nhà cung cấp",
      "payment": "Hình thức thanh toán ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "local_subtotal": "Tổng nội tệ gồm thuế",
      "total": "Tổng số tiền bao gồm thuế",
      "local_total": "Tổng số tiền gồm thuế(loại tiền tệ giao dịch)",
      "note": "Ghi chú thu mua",
      "demandqty": "Số lượng thu mua ",
      "punit": "Đơn vị thu mua",
      "unitprice": "Đơn giá thu mua(chưa thuế)",
      "confirmqty": "Nhà xưởng xác nhận số lượng ",
      "dealN": "Số đơn nhà cung cấp",
      "cancelqty": "Số lượng hủy",
      "returnqty": "Số lượng trả hàng ",
      "itemsum": "Số tiền mục đơn chưa thuế",
      "itemsumtax": "Thuế cá nhân ",
      "subtotal": "Tổng gồm thuế",
      "approveT": "Ngày phê duyệt ",
      "charge": "Tổng khấu trừ bổ sung ",
      "ReviewProcess": "Quy trình phê duyệt",
      "tb_drawer_title": "Phiếu chi tiết đơn thu mua",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá thu mua",
      "lifeF_7": "Nhà cung cấp ký và gửi lại ",
      "lifeF_T": "结案",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn thu mua không sai xót, rồi tiến hành xóa",
      "hd_close_confirm_text":
        "Vui lòng xác nhận dữ liệu lệnh đơn thu mua không sai xót, rồi tiến hành lệnh bắt buộc",
      "proT": "Ngày thu mua ",
      "dealN_placeholder": "Vui lòng nhập số đơn nhà cung cấp",
      "payment_placeholder": "Vui lòng chọn hình thức thanh toán ",
      "trade_placeholder": "Vui lòng chọn điều kiện giao dịch ",
      "dealcontact_placeholder": "Chọn cửa sổ nghiệp vụ nhà cung cấp",
      "contact_placeholder": "Chọn cửa sổ nhà cung cấp giao hàng ",
      "unitprice_placeholder": "Vui lòng nhập đơn giá chưa thuế",
      "demandqty_placeholder": "Vui lòng điền số lượng thu mua ",
      "note_placeholder": "Vui lòng nhập ghi chú thu mua",
      "statement_placeholder": "Vui lòng nhập mô tả dự án ",
      "amount_placeholder": "Vui lòng nhập số tiền ",
      "proCheck": "Đánh giá thu mua(1)",
      "cancel_proCheck": "Hủy đánh giá",
      "suppCheck": "Nhà cung cấp  ký và gửi lại (2)",
      "cancel_suppCheck": "Hủy  ký và gửi lại ",
      "proCheck_confirm": "Xác nhận đánh giá thu mua",
      "proCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá thu mua không sai xót, rồi tiến hành đánh giá thu mua",
      "cancel_proCheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu đánh giá thu mua không sai xót, rồi tiến hành hủy đánh giá thu mua",
      "suppCheck_confirm": "Xác nhận nhà cung cấp  ký và gửi lại ",
      "suppCheck_confirm_text":
        "Vui lòng xác nhận trước dữ liệu  ký và gửi lại không sai xót, rồi tiến hành  ký và gửi lại ",
      "cancel_suppCheck_confirm": "Xác nhận hủy nhà cung cấp  ký và gửi lại ",
      "cancel_suppCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại ",
      "tb": "Phiếu chi tiết thu mua",
      "adda": "Các điều khoản và điều kiện ",
      "adda_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu điều khoản cần xóa không sai xót, rồi tiến hành xóa",
      "addb": "Khấu trừ bổ sung",
      "addb_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu các khoản cần xóa không sai xót, rồi tiến hành xóa",
      "addc": "Hoạt động trả trước",
      "addc_delete_confirm_text":
        "Vui lòng xác nhận hoạt động trả trước cần xóa không sai xót, rồi tiến hành xóa",
      "statement": "Mô tả dự án ",
      "amount": "Số tiền chưa thuế",
      "selectstatement": "Vui lòng chọn các khoản khẩu trừ",
      "totalStart": "Giá trị tối thiểu ",
      "totalEnd": "Giá trị cực đại",
      "deal_history": "產品交易歷史查詢",
      "transferqty": "已拋轉數量",
      "throwqty": "本次拋轉數量",
      "message_transfer_small_thsn_0": "拋轉數量不可小於0",
      "exit_Disposal": "Xử lý trả hàng ",
    },
    "instock": {
      "pageHeader": "Hoạt động nhập liệu ",
      "create_header": "Tạo mới tệp chính nhập liệu",
      "edit_header": "Thay đổi tệp chính nhập liệu",
      "hd_drawer_title": "Tệp chính đơn nhập liệu",
      "contact": "聯絡人",
      "tel": "聯絡人電話",
      "sampleID": "Nhân viên lấy mẫu",
      "sampleT": "Ngày lấy mẫu",
      "note": "Đánh dấu nhập liệu",
      "del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn nhập liệu không sai xót, rồi tiến hành xóa",
      "tb": "Phiếu chi tiết nhập liệu",
      "goqty": "Số lượng nghiệm thu ",
      "rejectqty": "Số lượng  nghiệm trả",
      "indqty": "Số lượng tạm bị đình chỉ",
      "inspectID": "Nhân viên kiểm nghiệm ",
      "inspectT": "Thời gian hoàn thành kiểm nghiệm ",
      "qalID": "Nhân viên đánh giá",
      "qalT": "Thời gian phát hành ",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá nhập liệu",
      "lifeF_3": "Kiểm tra lấy mẫu IQC",
      "lifeF_4": "Hoàn thành kiểm tra mẫu IQC",
      "lifeF_7": "Đánh giá đảm bảo chất lượng",
      "lifeF_T": "結案",
      "IQCstart": "Lấy mẫu kiểm nghiệm",
      "QAcheck": "Đảm bảo chất lượng phát hành",
      "punit": "Đơn vị nhập liệu",
      "inbatchno": "進料批號",
      "judgeT": "Ngày đánh giá đảm bảo chất lượng ",
      "instockCheck_confirm": "Xác nhận đánh giá nhập liệu",
      "instockCheck_confirm_text":
        "Vui lòng xác nhận  dữ liệu cần đánh giá nhập liệu không sai xót, rồi tiến hành đánh giá nhập liệu",
      "cancel_instockCheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu cần đánh giá nhập liệu không sai xót, rồi tiến hành hủy đánh giá nhập liệu",
      "IQCcheck_confirm": "Xác nhận lấy mẫu kiểm nghiệm",
      "IQCcheck_confirm_text":
        "Vui lòng xác nhận dữ liệu lấy mẫu kiểm nghiệm không sai xót, rồi tiền hành lấy mẫu",
      "cancel_IQCcheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu lấy mẫu kiểm nghiệm không sai xót, rồi tiền hành hủy",
      "IQCfinish_confirm": "Xác nhận hoàn thành kiểm nghiệm IQC",
      "IQCfinish_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hoàn thành kiểm nghiệm IQC không sai xót, rồi tiến hành kiểm nghiệm IQC",
      "cancel_IQCfinish_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu kiểm nghiệm IQC không sai xót, rồi tiến hành hủy kiểm nghiệm IQC",
      "QAcheck_confirm": "Xác nhận phát hành đảm bảo chất lượng",
      "QAcheck_confirm_text":
        "Vui lòng xác nhận dữ liệu phát hành đảm bảo chất lượng không sai xót, rồi tiến hành phát hành đảm bảo chất lượng",
      "cancel_QAcheck_confirm_text":
        "Vui lòng xác nhận hủy dữ liệu phát hành đảm bảo chất lượng không sai xót, rồi tiến hành hủy phát hành đánh giá chất lượng ",
      "QAQTYcheck":
        "Chi tiết nhập liệu[Số lượng Từ chối Đảm bảo Chất lượng]+[Số lượng phát hành đảm bảo chất lương] phải bằng[số lượng nhập liệu]",
      "throwStock_message":
        "Chuẩn bị bước vào hoạt động chuyển đổi đơn kiểm tra nhập liệu, bạn có chắc chắn muốn chuyển đổi không?",
      "dealN": "Số đơn nhà cung cấp",
      "takeqty": "引用數量",
      "qty": "Số lượng trích dẫn",
      "unitprice_placeholder": "Vui lòng điền đơn giá chưa thuế",
      "Tooltip_disable_take_balqty_0": "Số lượng còn lại bằng 0 không thể trích dẫn ",
      "message_take_before_choose": "Vui lòng chọn trước trích dẫn rồi nhập số lượng trích dẫn ",
      "message_transfer_small_thsn_0": "Số lượng chuyển đổi không được nhỏ hơn 0",
      "message_take_same_detail": "Không thể trích dẫn chi tiết giống nhau ",
    },
    "gostock": {
      "pageHeader": "Tìm kiếm kiểm tra nhập liệu",
      "4120_header": "Tệp chính nghiệm thu ",
      "4230_header": "Tệp chính nghiệm trả",
      "total": "Tổng lượng di chuyển ",
      "planqty": "Số lượng di chuyển",
      "jobN": "Số đơn nguồn",
      "batchno": "Số lô",
      "batchno_placeholder": "Vui lòng nhập số lô",
      "note": "Đánh dấu",
      "batchdate": "Số lô ngày tháng ",
      "tb": "Phiếu chi tiết nghiệm thu ",
      "punit": "Đơn vị sử dụng",
      "S": "人工計數",
      "D": "條碼計數",
      "M": "混合計數",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": " Quản lý khoản phải thu ",
      "ap_create_header": "Tạo mới tệp chính đơn sao kê",
      "ap_edit_header": "Thay đổi tệp chính đơn sao kê",
      "ap_sumtotal": "Tổng số tiền ",
      "strike_sumtotal": "Tổng số tiền cân bằng thu chi",
      "takeAp": "Trích dẫn phiếu sao kê",
      "contactWindow": "Thông tin liên lạc cửa sổ",
      "chooseWindow": "Chọn cửa sổ",
      "ap_tb": "Chi tiết đơn sao kê",
      "strike_tb": "Chi tiết phiếu cân bằng thu chi ",
      "inbatchno": "Số lô nhập liệu ",
      "itemtotal": "Số tiền gồm thuế phải trả",
      "hubqty": "Số lượng nhập kho ",
      "balance": "Chênh lệch tài khoản",
      "recon": "Dữ liệu sao kê",
      "addr": "Địa chỉ khách hàng ",
      "addr_placeholder": "Vui lòng nhập địa chỉ liên lạc ",
      "strike_tb_delete_confirm": "Xác nhận xóa chi tiết cân bằng thu chi",
      "paytotal": "Số tiền đã cân bằng thu chi",
      "ap_total": "Tổng số tiền phải thu bao gồm thuế",
      "apCheck": "Đánh giá sao kê",
      "cancel_apCheck": "Hủy đánh giá ",
      "apCheck_confirm": "Xác nhận đánh giá sao kê",
      "apCheck_confirm_text":
        "Sắp bước vào thao tác duyệt sao kê, bạn có muốn lưu dữ liệu sao kê không?",
      "releaseAP": "Xuất đơn sao kê",
      "payment_placeholder": "請選擇付款方式",
      "cancel_releaseAP": "Hủy xuất đơn sao kê",
      "releaseAP_confirm": "Xác nhận xuất đơn sao kê",
      "releaseAP_confirm_text":
        "Sắp vào bước xác nhận chứng từ phải thu, bạn muốn lưu lại dữ liệu của chứng từ phải thu?",
      "strikeCheck": "Đánh giá cân bằng thu chi ",
      "strike_create_header": "Thêm mới tệp chính đơn cân bằng thu chi",
      "strike_edit_header": "Thay đổi tệp chính đơn cân bằng thu chi ",
      "hd_drawer_title": "Tệp chính dữ liệu sao kê",
      "itemNM": "Đánh dấu cửa sổ",
      "paytype": "Hình thức thanh toán ",
      "strikeCheck_confirm": "Xác nhận đánh giá cân bằng thu chi ",
      "strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá là chính xác, rồi tiến hành đánh giá",
      "cancel_strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá ",
      "confirmStrike": "Xác nhận cân bằng thu chi ",
      "confirmStrike_confirm": "Xác nhận cân bằng thu chi ",
      "confirmStrike_confirm_text":
        "Sau khi xác nhận cân bằng thu chi và kết án đơn cân bằng thu chi. Vui lòng xác nhận có cân bằng thu chi không?",
      "strike_paytotal": "Số tiền thanh toán ",
      "payT": "Thời gian thanh toán ",
      "note": "Ghi chú phiếu cân bằng thu chi ",
      "PN": "Số nguyên liệu ERP",
      "punit": "Đơn vị sử dụng ",
      "inqty": "Số lượng di chuyển ",
      "itemsum": "Số tiền các mục ",
      "itemsumtax": "Số tiền thuế các mục ",
      "subtotal": "Tổng phụ bao gồm thuế",
      "ap_del_hd_confirm_text":
        "Vui lòng xác nhận dữ liệu đơn sao kê cần xóa là chính xác, rồi tiến hành xóa",
      "strike_del_hd_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn cân bằng thu chi không sai xót, rồi tiến hành xóa",
      "message_chose_take": "請選擇要引用的對帳資料",
      "AP_lifeF_0": "Tạo lập",
      "AP_lifeF_1": "Đánh giá",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "Cân bằng thu chi(Thanh toán) chưa kết án ",
      "AP_lifeF_T": "Kết án ",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "",
      "message_chose_take_which_ap": "Vui lòng chọn dữ liệu sao kê cần trích dẫn",
      "message_only_take_one_ap": "Chỉ có thể trích dẫn 1 bản sao kê",
    },
    "return": {
      "pageHeader": "Hoạt động hàng bán bị trả lại ",
      "create_header": "Tạo mới nhập liệu thoát khỏi tệp chính",
      "edit_header": "Thay đổi tệp chính nhập liệu trả hàng ",
      "contact": "Người liên lạc ",
      "tel": "Số điện thoại liên lạc ",
      "addr": "Địa chỉ liên lạc ",
      "del_hd_confirm_text":
        "Vui lòng xác nhận dữ liệu đơn nhập liệu trả hàng cần xóa không sai xót, rồi tiến hành xóa",
      "rtnqty": "Số lượng hoàn trả",
      "punit": "Đơn vị sử dụng ",
      "inbatchno": "Số lô nhập liệu ",
      "note": "Ghi chú nhập trả hàng ",
      "tb": "Phiếu chi tiết  hàng bán bị trả lại ",
      "itemNM": "Ghi chú cửa sổ",
      "itemNM_placeholder": "Vui lòng nhập ghi chú nhà xưởng",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án",
      "message_at_least_chose_one": "Vui lòng chọn ít nhất 1 mục phiếu chi tiết đơn nghiệm trả",
      "planqty": "驗退數量",
    },
  },
  OMS: {
    "pageHeader": "銷貨管理系統",
    "util": {
      "opcode_1110": "國內報價",
      "opcode_1120": "國外報價",
      "opcode_1210": "預估訂單",
      "opcode_1310": "國內訂單",
      "opcode_1320": "國外訂單",
      "opcode_1410": "國內訂單",
      "opcode_1420": "國外訂單",
      "opcode_4220": "驗出單",
      "opcode_4130": "驗退單",
      "opcode_1610": "銷貨退回",
      "opcode_1710": "銷售出貨",
      "opcode_1810": " Cân bằng phải thu",
      "plan": "計畫單",
      "quo": "報價單",
      "fod": "預訂單",
      "ord": "銷貨單",
      "outstock": "發貨單",
      "return": "銷貨退回單",
      "ap": "對帳單",
      "outN": "驗退單號",
      "UploadSignature": "上傳簽呈",
      "UploadSign": "上傳回簽",
      "searchField": "搜尋欄位",
      "removeSearch": "清除搜尋紀錄",
      "setAdvanced": "設定進階篩選條件",
      "exportExcel": "匯出Excel",
      "table_close": "強制結令",
      "table_delete": "刪除",
      "table_edit": "編輯",
      "table_more": "查看更多",
      "table_operate": "操作",
      "header_next": "下一筆",
      "header_prev": "上一筆",
      "header_print": "列印",
      "header_return": "回總表",
      "header_save": "儲存",
      "header_tour": "回導覽",
      "header_cancel_create": "取消據此創建",
      "take": "引用",
      "throw": "拋轉",
      "accord": "據此",
      "create": "創建",
      "add": "新建",
      "submit": "確定",
      "cancel": "取消",
      "signBack": "签回",
      "search": "搜尋",
      "sysMsg": "系統訊息",
      "del_confirm": "刪除確認",
      "advanceSearch": "進階查詢",
      "ok": "確認",
      "yes": "是",
      "no": "否",
      "window": "窗口",
      "placeholder_select": "請選擇",
      "placeholder_input": "請輸入",
      "contact": "聯絡人",
      "tel": "電話",
      "N11": "報價單號",
      "N12": "預訂單號",
      "N13": "銷貨單號",
      "N14": "發貨單號",
      "N17": "應收單號",
      "N18": "沖帳單號",
      "jobN": "作業單號",
      "batchno": "批號",
      "itemno": "項序",
      "itemno_total": "總項數",
      "planSearch": "計畫紀錄查詢",
      "quoSearch": "報價紀錄查詢",
      "fodSearch": "預訂紀錄查詢",
      "ordSearch": "銷貨紀錄查詢",
      "stockSearch": "驗收記錄查詢",
      "outstockSearch": "發貨紀錄查詢",
      "apSearch": "對帳資料查詢",
      "strikeSearch": "沖帳紀錄查詢",
      "del_tb_confirm_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "get_prev_data_suc": "取得上一筆資料成功",
      "get_next_data_suc": "取得下一筆資料成功",
      "get_prev_data_err": "取得上一筆資料失敗",
      "get_next_data_err": "取得下一筆資料失敗",
      "prev_data_errMsg": "目前資料為第一筆，已無上一筆資料",
      "next_data_errMsg": "目前資料為最後一筆，已無下一筆資料",
      "opcode_placeholder": "請選擇單別",
      "N_placeholder": "請選擇單號",
      "currencytype_placeholder": "請選擇幣別",
      "custID_placeholder": "請選擇廠商",
      "taxtype_placeholder": "請選擇稅別",
      "taxrate_placeholder": "请输入稅率",
      "PN_placeholder": "請選擇料號",
      "utype_placeholder": "請選擇單位類別",
      "tel_placeholder": "請選擇聯絡人",
      "opcode": "單別",
      "N": "單號",
      "custID": "客戶ID",
      "custalias": "客戶簡稱",
      "custNM": "客戶名稱",
      "currencytype": "幣別",
      "exchangerate": "匯率",
      "exchangeD": "匯率日期",
      "taxtype": "稅別",
      "taxrate": "稅率",
      "confirmID": "覆核人員",
      "lifeF": "歷程",
      "lifeFT": "歷程時間",
      "createID": "創建人員",
      "createT": "創建日期",
      "confirmT": "覆核日期",
      "signT": "簽回日期",
      "approveT": "核准日期",
      "updtID": "更新人員",
      "updtT": "更新日期",
      "EDT": "預計交貨日",
      "startEDT": "預計交貨日(起)",
      "endEDT": "預計交貨日(迄)",
      "dealN": "客戶單號",
      "PN": "料號",
      "pdtNM": "品名",
      "pdtspec": "規格",
      "drawno": "圖號",
      "attribute": "物料屬性",
      "pdtclass": "商品類別",
      "pdttype": "管理分類",
      "pbom": "組合數量",
      "utype": "單位類別",
      "unit": "計量單位",
      "opunit": "操作單位",
      "punit": "使用單位",
      "localprice": "本幣金額",
      "tax": "總稅額",
      "itemsum": "單項金額(未稅)",
      "itemsumtax": "各項稅額",
      "subtotal": "含稅小計",
      "batchdate": "批號日期",
      "quoID": "報價人員",
      "quoT": "報價日期",
      "balqty": "剩餘數量",
    },
    "quo": {
      "pageHeader": "Hoạt động báo giá ",
      "quoID": "Nhân viên báo giá",
      "quoT": "Ngày báo giá",
      "validdays": "Số ngày có hiệu lực ",
      "isvalid": "Có hiệu lực không?",
      "hd_drawer_title": "Tệp chính đơn báo giá",
      "tb_drawer_title": "Chi tiết đơn báo giá",
      "demand": "Số lượng báo giá ",
      "punit": "Đơn vị báo giá ",
      "unitprice": "Số tiền báo giá ",
      "isapprove": "Giao dịch mục đơn ",
      "itemsum": "Số tiền mục đơn ",
      "itemsumtax": "Số thuế mục đơn",
      "subtotal": "Tổng mục đơn gồm thuế",
      "itemtotal": "Tổng số tiền chưa thuế",
      "local_subtotal": "Tổng số tiền nội tệ gồm thuế",
      "total": "Tổng số tiền gồm thuế(loại tiền giao dịch)",
      "note": "Ghi chú báo giá",
      "hd_delete_confirm_text":
        "Vui lòng xác nhận dữ liệu cần xóa đơn báo giá không sai xót, rồi tiến hành xóa",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá báo giá",
      "lifeF_7": "Phê duyệt và kí gửi ",
      "lifeF_T": "Kết án ",
      "quoCheck_confirm_Text":
        "Vui lòng xác nhận trước dữ liệu cần đánh giá không sai xót, rồi tiến hành đánh giá",
      "cancel_quoCheck_confirm_Text":
        "Vui lòng xác nhận trước hủy dữ liệu đánh giá không sai xót, rồi tiến hành hủy đánh giá",
      "Audit_confirm": "Xác nhận phê duyệt ký tên ",
      "Audit_confirm_text":
        "Không thể chỉnh sửa báo giá này sau khi xem xét và phê duyệt. Bạn có chắc chắn muốn hoàn thành việc xem xét không?",
      "tb": "Chi tiết báo giá",
    },
    "fod": {
      "pageHeader": "Hoạt động đặt trước ",
      "hd_drawer_title": "Tệp chính đơn đặt trước",
      "tb_drawer_title": "Phiếu chi tiết đơn đặt trước",
      "driID": "Phụ trách nghiệp vụ ",
      "trade": "Điều khoản giao dịch ",
      "dealcontact": "Cửa sổ nghiệp vụ khách hàng ",
      "payment": "付款方式",
      "dealaddr": "Địa chỉ khách hàng ",
      "custHistory": "Tìm kiếm lịch sử giao dịch khách hàng ",
      "contact": "Cửa sổ giao hàng khách hàng ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "tax": "Tổng số tiền ",
      "note": "Đánh dấu đặt trước",
      "total": "Tổng số tiền gồm thuế",
      "demandqty": "Số lượng khách hàng đặt hàng",
      "punit": "Đơn vị đặt hàng ",
      "unitprice": "Đơn giá đặt hàng ",
      "confirmqty": "Xác nhận số lượng ",
      "cancelqty": "Số lượng hủy",
      "returnqty": "Số lượng hoàn trả",
      "transferqty": "Số lượng đã chuyển đổi",
      "qty": "Số lượng dự tính chuyển đổi",
      "hd_delete_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn đặt trước không sai xót, rồi tiến hành xóa",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá đặt trước",
      "lifeF_7": "客戶簽回",
      "lifeF_T": "Kết án ",
      "local_total": "Tổng số tiền gồm thuế(nội tệ)",
      "fodCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu đánh giá đặt trước không sai xót, rồi tiến hành xóa đánh giá đặt trước",
      "cancel_fodCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy đánh giá đặt trước không sai xót, rồi tiến hành hủy đánh giá đặt trước",
      "custCheck_confirm": "Khách hàng  ký và gửi lại xác nhận ",
      "custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần  ký và gửi lại không sai xót, rồi tiền hành ký và gửi lại ",
      "cancel_custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại",
      "reviewFlow": "Phê duyệt quy trình (3)",
      "tb": "Phiếu đơn đặt trước",
      "EDT": "Dự tính ngày giao hàng ",
      "deal_history": "Tìm kiếm lịch sử giao dịch sản phẩm",
    },
    "ord": {
      "pageHeader": "Hoạt động bán hàng ",
      "hd_drawer_title": "Tệp chính đơn bán hàng ",
      "tb": "Chi tiết đơn bán hàng ",
      "adda": "Các điều khoản và điều kiện",
      "addb": "Khấu trừ bổ sung ",
      "addc": "Hoạt động trả trước",
      "lifeF_0": "Công bố",
      "lifeF_2": "Đánh giá bán hàng ",
      "lifeF_7": "Khách hàng ký và gửi lại",
      "lifeF_T": "Lệnh ",
      "driID": "Phụ trách nghiệp vụ ",
      "sumtotal": "Tổng số tiền chưa thuế",
      "total": "Tổng số tiền gồm thuế(loại tiền tệ giao dịch )",
      "dealaddr": "Địa chỉ khách hàng ",
      "contact": "Cửa sổ giao hàng khách hàng ",
      "dealcontact": "Cửa sổ nghiệp vụ khách hàng ",
      "payment": "Hình thức thanh toán ",
      "trade": "Điều khoản giao dịch ",
      "note": "Ghi chú bán hàng ",
      "reviewFlow": "Phê duyệt quy trình(3)",
      "demandqty": "Số lượng bán hàng ",
      "transferqty": "Số lượng đã chuyển đổi",
      "qty": "Số lượng chuyển đổi lần này",
      "punit": "Đơn vị bán hàng ",
      "unitprice": "Đơn giá bán hàng ",
      "confirmqty": "Xác nhận số lượng khách hàng ",
      "cancelqty": "Hủy số lượng ",
      "returnqty": "Số lượng hoàn hàng ",
      "itemsum": "Số tiền mục đơn ",
      "itemsumtax": "Số thuế mục đơn ",
      "charge": "Tổng khấu trừ bổ sung",
      "subtotal": "Tổng gồm thuế",
      "statement": "Mô tả dự án ",
      "amount": "Số tiền chưa thuế",
      "local_total": "Tổng số tiền gồm thuế(nội tệ)",
      "startEDT": "Dự tính ngày xuất hàng (bắt đầu)",
      "endEDT": "Dự tính ngày xuất hàng (kết thúc)",
      "closeData_confirm_text":
        "Vui lòng xác nhận trước dữ liệu đơn bán hàng không sai xót, rồi tiến hành lệnh bắt buộc ",
      "removeData_confirm_text":
        "Vui lòng xác nhận xóa dữ liệu đơn bán hàng không sai xót, rồi tiến hành xóa",
      "orderCheck_text":
        "Vui lòng xác nhận dữ liệu đánh giá bán hàng không sai xót, rồi tiến hành đánh giá bán hàng ",
      "cancel_orderCheck_text":
        "Vui lòng xác nhận trước hủy dữ liệu đánh giá bán hàng không sai xót, rồi tiến hành hủy đánh giá bán hàng ",
      "cancel_custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần hủy  ký và gửi lại không sai xót, rồi tiến hành hủy  ký và gửi lại",
      "custCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần  ký và gửi lại không sai xót, rồi tiền hành  ký và gửi lại",
      "adda_delete_confirm_text":
        "Vui lòng xác nhận trước dữ liệu điền kiện cần xóa không sai xót, rồi tiến hành xóa",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text":
        "Vui lòng xác nhận trước hoạt động trả trước cần xóa không sai xót, rồi tiến hành xóa",
      "deal_history": "Tìm kiếm lịch sử giao dịch sản phẩm",
    },
    "outstock": {
      "pageHeader": "發貨作業",
      "hd_drawer_title": "發貨單主檔",
      "tb_drawer_title": "發貨單明細",
      "tb": "發貨明細",
      "lifeF_0": "發佈",
      "lifeF_2": "發貨覆核",
      "lifeF_3": "IQC抽驗",
      "lifeF_4": "IQC完驗",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "contact": "聯絡人",
      "tel": "聯絡電話",
      "sampleID": "抽樣人員",
      "sampleT": "抽樣日期",
      "note": "發貨註記",
      "punit": "發貨單位",
      "indqty": "發貨數量",
      "goqty": "驗收數量",
      "unitprice": "銷貨單價",
      "rejectqty": "驗退數量",
      "IQCcheck": "進檢抽樣",
      "inspectID": "進檢人員",
      "inspectT": "完驗時間",
      "qalID": "品判人員",
      "qalT": "放行時間",
      "judgeT": "品保判定日期",
      "qty": "數量",
      "choose": "選擇",
      "choose_err": "剩餘數量為0不可引用",
      "throw_save_msg": "請先選擇引用再輸入引用數量",
      "throwQty_msg": "拋轉數量不可小於0",
      "useDetail_msg": "不可引用相同明細",
      "tb_remove_text": "請先確認要刪除的明細資料無誤，再進行刪除",
      "IQCcheck_text": "即將進入進檢抽樣作業，是否儲存發貨單資料?",
      "closeData_confirm_text": "請先確認要刪除的發貨單資料無誤，再進行刪除",
      "outstockCheck_text": "請先確認要發貨覆核的資料無誤，再進行發貨覆核",
      "cancel_outstockCheck_text": "請先確認要取消覆核的資料無誤，再進行取消覆核",
      "IQCfinish_text": "即將進入IQC完驗作業，是否儲存發貨單資料?",
      "QAcheck_err": "發貨明細 [驗收數量]+[驗退數量] 要等於 [發貨數量]",
      "QAcheck": "品保放行",
      "QAcheck_text": "即將進入品保放行作業，是否儲存發貨單資料?",
      "throwStock_text": "即將進入拋轉發貨單作業，是否確定拋轉?",
    },
    "check": {
      "pageHeader": "Tìm kiếm kiểm tra bán hàng ",
      "4220_header": "Tệp chính đơn phân phối bán hàng ",
      "4220_tb": "Phiếu chi tiết phân phối",
      "4130_header": "Tệp chính đơn hàng bị trả lại ",
      "4130_tb": "退回明細",
      "N": "Số đơn hoạt động ",
      "jobN": "Số đơn nguồn",
      "batchno": "Số lô",
      "batchdate": "Số lô ngày tháng ",
      "note": "Đánh dấu",
      "total": "Tổng số lượng di chuyển ",
      "planqty": "Số lượng di chuyển ",
      "punit": "Đơn vị phân phối",
      "stockinT": "Thời gian di chuyển ",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án ",
    },
    "return": {
      "pageHeader": "Hoạt động hàng bán bị trả lại ",
      "tb": "Phiếu chi tiết  hàng bán bị trả lại ",
      "hd_remove_text":
        "Vui lòng xác nhận trước dữ liệu  hàng bán bị trả lại cần xóa không sai xót, rồi tiến hành xóa",
      "tb_remove_text":
        "Vui lòng xác nhận dữ liệu chi tiết cần xóa không sai xót, rồi tiến hành xóa",
      "take": "Lấy dùng",
      "unitprice": "Đơn giá xuất hàng ",
      "rtnqty": "Số lượng hoàn trả",
      "inbatchno": "Số lô nhập liệu ",
      "contact": "Người liên lạc ",
      "contact_window": "Cửa sổ nghiệp vụ khách hàng ",
      "note": "Ghi chú nhập trả hàng ",
      "tel": "Số điện thoại liên lạc ",
      "addr": "Địa chỉ liên lạc ",
      "itemNM": "Ghi chú cửa sổ",
      "lifeF_0": "Công bố",
      "lifeF_T": "Kết án",
    },
    "ap-manage": {
      "pageHeader": " Quản lý khoản phải thu ",
      "rcv": "Tìm kiếm khoản phải thu ",
      "rcvData": "Dữ liệu phải thu ",
      "rcv_drawer_title": "Tệp chính khoản phải thu ",
      "inbatchno": "Số lô nhập liệu ",
      "contactWindow": "Thông tin liên lạc cửa sổ",
      "ap": "Đơn sao kê",
      "ap_tb": "Chi tiết đơn sao kê",
      "strike": "Hoạt động cân bằng thu chi ",
      "strike_tb": "Chi tiết phiếu cân bằng thu chi ",
      "unitprice": "Đơn giá phân phối",
      "rcv_inqty": "Số lượng di chuyển ",
      "rcv_itemsum": "Số tiền các mục ",
      "rcv_itemsumtax": "Số tiền thuế các mục ",
      "rcv_subtotal": "Tổng gồm thuế",
      "ap_remove_text":
        "Vui lòng xác nhận dữ liệu đơn sao kê cần xóa không sai xót, rồi tiến hành xóa",
      "strike_remove_text":
        "Vui lòng xác nhận dữ liệu cân bằng thu chi cần xóa không sai xót, rồi tiến hành xóa",
      "ap_total": "Tổng số tiền phải thu bao gồm thuế",
      "ap_itemtotal": "Số tiền phải thu gồm thuế",
      "ap_paytotal": "Số tiền đã cân bằng thu chi ",
      "ap_sumtotal": "Tổng số tiền ",
      "strike_sumtotal": "Tổng số tiền cân bằng thu chi",
      "ap_hubqty": "Số lượng xuất kho ",
      "ap_addr": "客戶地址",
      "ap_balance": "Chênh lệch tài khoản",
      "ap_lifeF_0": "Tạo ",
      "ap_lifeF_1": "Đánh giá phải thu",
      "ap_lifeF_2": "Xuất đơn phải thu ",
      "ap_lifeF_3": "Cân bằng thu chi(thanh toán) chưa kết án",
      "ap_lifeF_T": "結案",
      "ap_contact": "Cửa sổ nghiệp vụ khách hàng",
      "ap_itemNM": "Ghi chú cửa sổ",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "Ghi chú cân bằng thu chi ",
      "confirmStrike": "Xác nhận cân bằng thu chi ",
      "apCheck_confirm_text":
        "Sắp bước vào thao tác duyệt sao kê, bạn có muốn lưu dữ liệu sao kê không?",
      "releaseAP_confirm_text":
        "Sắp vào bước xác nhận chứng từ phải thu, bạn muốn lưu lại dữ liệu của chứng từ phải thu?",
      "strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu cần đánh giá là chính xác, rồi tiến hành đánh giá",
      "cancel_strikeCheck_confirm_text":
        "Vui lòng xác nhận dữ liệu hủy đánh giá không sai xót, rồi tiến hành hủy đánh giá ",
      "confirmStrike_confirm_text":
        "Sau khi xác nhận cân bằng thu chi và kết án đơn cân bằng thu chi. Vui lòng xác nhận có cân bằng thu chi không?",
      "strike_tb_remove_text":
        "Vui lòng xác nhận xóa dữ liệu chi tiết không sai xót, rồi tiến hành xóa",
    },
  },
  PDS: {
    "wpc-search": {
      "wlID": "",
      "linemix": "",
      "hdC": "",
      "issueday": "",
    },
    "overview-lines": {
      "pageHeader": "",
      "wlID": "",
      "act": "",
      "shiftD": "",
      "shiftN": "",
      "allDay": "",
    },
    "dashboard": {
      "pageHeader": " Kanban sản xuất kỹ thuật số",
    },
    "abc-board": {
      "pageHeader": "Kanban quản lý bất thường",
    },
  },
};

export default vi_VN;
