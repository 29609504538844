/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button, Divider } from "antd";
import useAPI from "@/hooks/useAPI";
import { addProDoc } from "@/service/apis/PMS/Pro";

const FileModal = ({
  call_getProHD,
  hd_values,
  call_updateProHD,
  s_fileType,
  set_s_showFileModal,
}) => {
  // 上傳工程圖檔
  const call_addProDoc = useAPI(addProDoc);
  const preview = () => {
    const element = document.createElement("a");
    element.href = s_fileType === "outsource" ? hd_values.outsource : hd_values.outReport;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element); // inspcer
  };
  // 不要問
  let count = 0;
  const props = {
    // name: "file",
    multiple: true,
    beforeUpload: () => false,
    fileList: [],
    onChange(info) {
      const files = info.fileList.map((item) => item.originFileObj);
      // console.log(props);
      // console.log("info = ", info);
      // // 新建
      let formData = new FormData();
      formData.append("opUUID", hd_values.opUUID);
      formData.append("N", hd_values.N);
      formData.append("types", s_fileType);
      // console.log("JSON.stringify = ", JSON.stringify(info.fileList.length));
      // formData.append("files", files);
      for (let i = 0; i < info.fileList.length; i++) {
        // console.log("info.fileList[i] = ", info.fileList[i].originFileObj);
        formData.append("files", info.fileList[i].originFileObj);
      }
      console.log("call_addProDoc.status = ", call_addProDoc.status);
      // 這樣寫是因為當fileList.length=2, onChange就會跑兩次
      if (count === 0) {
        call_addProDoc.request(formData);
        console.log(count);
      }
      count += 1;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // console.log("hd_values = ", hd_values);
  useEffect(() => {
    const { status, msg, data } = call_addProDoc;
    if (status === "suc") {
      message.success(msg);
      call_getProHD.request({ opUUID: hd_values.opUUID });
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
    console.log("hd_values = ", hd_values);
  }, [call_addProDoc.status]);

  return (
    <div className="flex flex-col">
      {hd_values.outsource?.length > 0 && (
        <>
          <Button type="primary" size="large" onClick={preview}>
            {/* 查看委外报告 */}
            {s_fileType === "outsource" ? "查看外侧资料" : "查看委外报告"}
          </Button>
          <Divider />
        </>
      )}
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
      </Upload.Dragger>
    </div>
  );
};

export default FileModal;
