import { instance } from "../../handlerAPI";
import { ims_apiBasic } from "../../APIBasic";

// ----------------------------------------PMS-----------------------------------------------
export const getPmsOutHd = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getPmsOutHd`, {
    params: _params,
  });
};

export const getPmsOutTb = (data) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getPmsOutTb`, {
    params: data,
  });
};

//----------------------------------------- 編輯  表身----------------------------------------
export const modifyTb = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/modifyTb`, data);
};

// ================================================================================================
//----------------------------------------- 表身check----------------------------------------
export const confirm = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/confirm`, data);
};

//-----------------------------------------異動表頭資料----------------------------------------
export const txn = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/txn`, data);
};

//----------------------------------------- 編輯循環表身----------------------------------------
export const updateTxnTb = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateTxnTb`, data);
};

//----------------------------------------- 新增循環表頭----------------------------------------
export const getTxnTb = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getTxnTb`, {
    params: _params,
  });
};

//----------------------------------------- 新增循環表頭----------------------------------------
export const addTxnHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addTxnHd`, data);
};

//----------------------------------------- 刪除循環表頭----------------------------------------
export const deleteTxnHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleteTxnHd`, data);
};

//----------------------------------------- 取得循環表頭入庫單下拉選單 ----------------------------------------
export const getWosOut = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getWosOut`, {
    params: _params,
  });
};

//----------------------------------------- 取得循環表頭 ----------------------------------------
export const getTxnHd = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getTxnHd`, {
    params: _params,
  });
};

//----------------------------------------- 取得 表頭 ----------------------------------------
export const getHd = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getHd`, {
    params: _params,
  });
};

//----------------------------------------- 取得 表頭 ----------------------------------------
export const getOmsOutHd = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getOmsOutHd`, {
    params: _params,
  });
};
//----------------------------------------- 取得 表身 ----------------------------------------
export const getOmsOutTb = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getOmsOutTb`, {
    params: _params,
  });
};
//----------------------------------------- 取得 表頭 ----------------------------------------
export const getHdSelect = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getHdSelect`, {
    params: _params,
  });
};
//----------------------------------------- 新增 表頭  ----------------------------------------
export const addHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addHd`, data);
};
//----------------------------------------- 刪除 表頭  ----------------------------------------
export const deleteHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleteHd`, data);
};
//----------------------------------------- 編輯 表頭----------------------------------------
export const modifyHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/modifyHd`, data);
};

export const updateHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateHd`, data);
};
//----------------------------------------- 取得 表身  ----------------------------------------
export const getTb = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getTb`, {
    params: _params,
  });
};

//----------------------------------------- 刪除 表身 ----------------------------------------
export const deleteTb = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleteTb`, data);
};

//----------------------------------------- 編輯  表身----------------------------------------
export const updateTb = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateTb`, data);
};

//----------------------------------------- 調撥入庫 新增----------------------------------------
export const addWhTransInHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addWhTransInHd`, data);
};

//-----------------------------------------取得全部庫房資料----------------------------------------
export const getAllWhID = () => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getAllWhID`);
};

//-----------------------------------------取得BOMPN表投----------------------------------------
export const getParent = () => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getParent`);
};

//-----------------------------------------取得BOM表身----------------------------------------
export const getChild = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getChild`, {
    params: _params,
  });
};
