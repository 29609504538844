/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getReqTB } from "@/service/apis/PMS/Req";
// util
import { formatAmount } from "@/util/format";

const TB = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表身
  const call_getReqTB = useAPI(getReqTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;

  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getReqTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getReqTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getReqTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl === true ? "5%" : "7%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl === true ? "17%" : "17%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      // title: "單位類別",
      title: t("PMS.util.utype"),
      dataIndex: "utype",
      align: "center",
      width: "10%",
      render: (text) =>
        text === "unit" ? t("PMS.util.unit") : text === "opunit" ? t("PMS.util.opunit") : "",
    },
    {
      // title: "請購單位",
      title: t("PMS.req.punit"),
      dataIndex: "punit",
      align: "center",
      width: screens.xxl === true ? "11%" : "11%",
    },
    {
      // title: "請購單價(未稅)",
      title: t("PMS.req.unitprice"),
      dataIndex: "unitprice",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "請購數量",
      title: t("PMS.req.demandqty"),
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "單項金額",
      title: t("PMS.req.itemsum"),
      dataIndex: "itemsum",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "單項稅額",
      title: t("PMS.req.itemsumtax"),
      dataIndex: "itemsumtax",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      // title: "含稅小計",
      title: t("PMS.req.subtotal"),
      dataIndex: "subtotal",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#6A68A1]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("PMS.util.itemno_total")}: </span>
          <span className="self-center">{s_tableData?.length ?? 0}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("PMS.req.itemtotal")}:</span>
          <span className="self-center">{formatAmount(lightData.itemtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("PMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(lightData.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 本幣含稅總金額 */}
          <span className="self-center">{t("PMS.req.local_subtotal")}:</span>
          <span className="self-center">
            {formatAmount(lightData.total * lightData.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("PMS.req.total")}:</span>
          <span className="self-center"> {formatAmount(lightData.total)}</span>
        </Col>
      </Row>
    </div>
  );
};

export default TB;
