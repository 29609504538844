import { useEffect, useRef, useState } from "react";

import { AddButton } from "@/components/PDS/Buttons";
import CustCon from "./custCon/index";
import CustRec from "./custRec";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader/index";
import { useTranslation } from "react-i18next";
const TB = (props) => {
  const { t } = useTranslation();
  const [s_tabKey, set_s_tabKey] = useState("custCon");
  const { c_lightData, set_c_lightData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});

  // Ref
  const ConRef = useRef(null);
  const RecRef = useRef(null);

  const handleAdd = () => {
    switch (s_tabKey) {
      case "custCon":
        ConRef.current.Create();
        break;
      case "custRec":
        RecRef.current.Create();
        break;
    }
  };

  // handleAdd 寫法 2
  // const handleAdd = () => {
  //   const actions = {
  //     custCon: () => ConRef.current.Create(),
  //     custRec: () => RecRef.current.Create()
  //   };

  //   if (actions[s_tabKey]) {
  //     actions[s_tabKey]();
  //   }
  // };

  const tabsData = [
    {
      key: "custCon",
      label: "客戶聯絡",
      children: (
        <CustCon
          ref={ConRef}
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          t={t}
        />
      ),
    },
    {
      key: "custRec",
      label: "客戶紀錄",
      children: (
        <CustRec
          ref={RecRef}
          c_lightData={c_lightData}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          t={t}
        />
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={t("ADM.client.customerContactInformation")}
        // extra={[
        //   // <Button type="primary" key="create" onClick={() => set_s_isShowModal(true)}>
        //   //   {t("util.add")}
        //   // </Button>,
        //   <AddButton type="primary" key="create" onClick={handleAdd} />,
        // ]}
      />

      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => set_s_tabKey(activeKey)}
        tabBarExtraContent={<AddButton type="primary" key="create" onClick={handleAdd} />}
      />
    </>
  );
};

export default TB;
