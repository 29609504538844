import { Typography } from "antd";

const { Title } = Typography;

const TbTitle = ({ children }) => {
  return (
    <Title level={4} className="m-0 font-normal text-[#4B6EA2]">
      {children}
    </Title>
  );
};

export default TbTitle;
