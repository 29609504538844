import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";

const Tour = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigate_page = (pageName, query = {}) => {
    navigate({
      "pathname": pageName,
      "search": `?${createSearchParams(query)}`,
    });
  };
  return (
    <div className="flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100vw"
        height="511.238"
        viewBox="0 0 1411.762 511.238"
      >
        <style>
          {`
          .icon {
            transition: fill 1s;
            fill: none;
            cursor: pointer;
          }
          .icon:hover {
            transition: fill 1s;
            fill: url(#linear-gradient);
          }

          .icon.disabled  {
            cursor: not-allowed;
            fill: #9796A5;
          }
        `}
        </style>
        <defs>
          <linearGradient
            id="linear-gradient"
            y1="0.539"
            x2="0.991"
            y2="0.541"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#99b1d4" />
            <stop offset="1" stopColor="#5174a6" />
          </linearGradient>
          <clipPath id="clipPath">
            <rect
              id="Rectangle_2220"
              data-name="Rectangle 2220"
              width="72.943"
              height="52.216"
              fill="#4b6ea2"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-2"
            x1="-0.045"
            y1="0.49"
            x2="1.078"
            y2="0.483"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#9eb6d8" />
            <stop offset="1" stopColor="#4b6ea2" />
          </linearGradient>
          <filter
            id="框"
            x="1210.882"
            y="258.666"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="框-2"
            x="919.881"
            y="55.356"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-2" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-3">
            <rect
              id="Rectangle_2321"
              data-name="Rectangle 2321"
              width="63.322"
              height="60.517"
              transform="translate(0 0)"
              fill="#4b6ea2"
            />
          </clipPath>
          <filter
            id="框-3"
            x="705.881"
            y="55.356"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-3" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-3" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-4">
            <rect
              id="Rectangle_2232"
              data-name="Rectangle 2232"
              width="70.065"
              height="54.56"
              fill="#4b6ea2"
            />
          </clipPath>
          <filter
            id="框-4"
            x="484.881"
            y="55.356"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-4" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-4" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-5">
            <rect
              id="Rectangle_2234"
              data-name="Rectangle 2234"
              width="53.024"
              height="65.117"
              fill="#4b6ea2"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient-10"
            x1="-0.045"
            y1="0.49"
            x2="1.078"
            y2="0.483"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#a4bbdc" />
            <stop offset="1" stopColor="#4b6ea2" />
          </linearGradient>
          <filter
            id="框-5"
            x="268.881"
            y="55.356"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-5" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-5" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-6">
            <rect
              id="Rectangle_2275"
              data-name="Rectangle 2275"
              width="75.094"
              height="57.846"
              fill="#4b6ea2"
            />
          </clipPath>
          <filter
            id="框-6"
            x="51.881"
            y="55.356"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-6" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-6" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-7">
            <rect
              id="Rectangle_2233"
              data-name="Rectangle 2233"
              width="70.416"
              height="55.143"
              fill="#4b6ea2"
            />
          </clipPath>
        </defs>
        <g id="銷貨導覽圖" transform="translate(-243.119 -241.644)">
          <path
            id="_6"
            data-name="6"
            d="M-11531.393,1382.713l-22.793-20.828a2.5,2.5,0,0,1,0-3.692l22.793-20.828a2.5,2.5,0,0,1,4.188,1.844v13.557c52.771-.78,96.662-42.227,100.246-95h0a7.01,7.01,0,0,1,7-6.766,7.009,7.009,0,0,1,7.008,6.766h.016l-.012.192v.052a7.146,7.146,0,0,1-.068.987,116.488,116.488,0,0,1-2.071,15.161,115.015,115.015,0,0,1-6.76,21.773,115.821,115.821,0,0,1-10.692,19.705,117.144,117.144,0,0,1-14.164,17.168,116.775,116.775,0,0,1-17.168,14.163,116.149,116.149,0,0,1-19.705,10.7,115.606,115.606,0,0,1-21.776,6.76,116.854,116.854,0,0,1-21.859,2.346v14.1a2.5,2.5,0,0,1-2.517,2.5A2.453,2.453,0,0,1-11531.393,1382.713Z"
            transform="translate(13067.809 -630.491)"
            fill="#dae1ec"
          />
          <path
            id="_5"
            data-name="5"
            d="M-11263.618-2168.043h-.162l-.156-.005-.089,0h-73.4c-4.323,0-7.84-3.142-7.84-7v-.006h0a101.306,101.306,0,0,0-29.908-72.089,101.308,101.308,0,0,0-72.092-29.911,101.913,101.913,0,0,0-100.378,83.808l-7-7.657a2.507,2.507,0,0,0-1.845-.812,2.5,2.5,0,0,0-1.846.813l-2.672,2.922c.025-.125.052-.25.075-.374l.018-.075a115.524,115.524,0,0,1,6.757-21.775,115.929,115.929,0,0,1,10.7-19.7,116.791,116.791,0,0,1,14.164-17.168,116.61,116.61,0,0,1,17.168-14.165,115.932,115.932,0,0,1,19.7-10.695,115.3,115.3,0,0,1,21.774-6.76,116.81,116.81,0,0,1,23.377-2.356,116.8,116.8,0,0,1,23.377,2.356,115.282,115.282,0,0,1,21.775,6.76,116.1,116.1,0,0,1,19.706,10.695,116.753,116.753,0,0,1,17.165,14.165,116.645,116.645,0,0,1,14.165,17.168,115.993,115.993,0,0,1,10.7,19.7,115.456,115.456,0,0,1,6.758,21.775,116.584,116.584,0,0,1,2.149,16.381h61.855q.217-1.182.456-2.353a115.4,115.4,0,0,1,6.758-21.772,115.783,115.783,0,0,1,10.694-19.7,116.8,116.8,0,0,1,14.165-17.168,116.576,116.576,0,0,1,17.17-14.165,115.669,115.669,0,0,1,19.7-10.695,115.211,115.211,0,0,1,21.775-6.763,116.946,116.946,0,0,1,21.827-2.341v-14.475a2.5,2.5,0,0,1,4.189-1.845l22.791,20.829a2.5,2.5,0,0,1,0,3.691l-22.791,20.829a2.5,2.5,0,0,1-4.189-1.848v-13.181a101.293,101.293,0,0,0-70.542,29.9,100.834,100.834,0,0,0-29.026,58.591,6.962,6.962,0,0,1-1.605,3.972l-.009.01-.041.049-.014.016-.037.042-.048.055h0a6.985,6.985,0,0,1-4.382,2.293v.038h-.437c-.134.009-.271.011-.406.011Z"
            transform="translate(12677.418 2535.643)"
            fill="#dae1ec"
          />
          <path
            id="_4"
            data-name="4"
            d="M90.626,139.844a115.388,115.388,0,0,1-21.775-6.759,116.052,116.052,0,0,1-19.7-10.695A116.633,116.633,0,0,1,17.816,91.058a116.047,116.047,0,0,1-10.7-19.7A115.5,115.5,0,0,1,.361,49.578C.237,48.971.115,48.353,0,47.739l3.94,4.312a2.5,2.5,0,0,0,3.691,0L13.8,45.3a102.007,102.007,0,0,0,202.2-17.51H202.457a2.5,2.5,0,0,1-1.846-4.187L221.441.814a2.5,2.5,0,0,1,3.691,0L245.96,23.607a2.5,2.5,0,0,1-1.845,4.187H229.993a116.787,116.787,0,0,1-2.345,21.785,115.335,115.335,0,0,1-17.456,41.479A116.667,116.667,0,0,1,178.86,122.39a115.873,115.873,0,0,1-19.7,10.695,115.412,115.412,0,0,1-21.775,6.759,117.107,117.107,0,0,1-46.755,0Z"
            transform="translate(897.638 334.296)"
            fill="#dae1ec"
          />
          <path
            id="_3"
            data-name="3"
            d="M-1768.559,2561.387l-20.829-22.793a2.5,2.5,0,0,1,1.845-4.187h13.536a101.285,101.285,0,0,0-29.9-70.5A101.308,101.308,0,0,0-1876,2434a102.08,102.08,0,0,0-100.208,82.9l-6.166-6.747a2.5,2.5,0,0,0-1.845-.813,2.5,2.5,0,0,0-1.846.813l-3.94,4.312c.126-.672.245-1.273.361-1.84a115.454,115.454,0,0,1,6.759-21.775,116.04,116.04,0,0,1,10.7-19.7,116.747,116.747,0,0,1,14.164-17.167,116.883,116.883,0,0,1,17.168-14.165,115.966,115.966,0,0,1,19.7-10.695,115.415,115.415,0,0,1,21.775-6.759A116.815,116.815,0,0,1-1876,2420a116.8,116.8,0,0,1,23.377,2.357,115.373,115.373,0,0,1,21.775,6.759,115.9,115.9,0,0,1,19.7,10.695,116.766,116.766,0,0,1,17.168,14.165,116.718,116.718,0,0,1,14.165,17.167,115.99,115.99,0,0,1,10.7,19.7,115.454,115.454,0,0,1,6.759,21.775,116.8,116.8,0,0,1,2.345,21.784h14.122a2.5,2.5,0,0,1,1.845,4.187l-20.829,22.793a2.49,2.49,0,0,1-1.845.813A2.49,2.49,0,0,1-1768.559,2561.387Z"
            transform="translate(2670.096 -2175.072)"
            fill="#dae1ec"
          />
          <path
            id="_2"
            data-name="2"
            d="M90.626,139.844a115.388,115.388,0,0,1-21.775-6.759,116.052,116.052,0,0,1-19.7-10.695A116.633,116.633,0,0,1,17.816,91.058a116.047,116.047,0,0,1-10.7-19.7A115.5,115.5,0,0,1,.361,49.578C.237,48.971.115,48.353,0,47.739l3.94,4.312a2.5,2.5,0,0,0,3.691,0L13.8,45.3a102.007,102.007,0,0,0,202.2-17.51H202.457a2.5,2.5,0,0,1-1.846-4.187L221.441.814a2.5,2.5,0,0,1,3.691,0L245.96,23.607a2.5,2.5,0,0,1-1.845,4.187H229.993a116.787,116.787,0,0,1-2.345,21.785,115.335,115.335,0,0,1-17.456,41.479A116.667,116.667,0,0,1,178.86,122.39a115.873,115.873,0,0,1-19.7,10.695,115.412,115.412,0,0,1-21.775,6.759,117.107,117.107,0,0,1-46.755,0Z"
            transform="translate(462.59 334.296)"
            fill="#dae1ec"
          />
          <path
            id="_1"
            data-name="1"
            d="M-5767.559,2561.387l-20.829-22.794a2.5,2.5,0,0,1,1.845-4.186h13.536A102.121,102.121,0,0,0-5874.995,2434a102.116,102.116,0,0,0-102,102h0c0,.024,0,.048,0,.072a7,7,0,0,1-7,7,7,7,0,0,1-7-7c0-.1,0-.2.006-.3a116.843,116.843,0,0,1,2.356-23.151,115.447,115.447,0,0,1,6.76-21.776,116.036,116.036,0,0,1,10.695-19.7,116.874,116.874,0,0,1,14.165-17.167,116.9,116.9,0,0,1,17.167-14.165,115.941,115.941,0,0,1,19.7-10.695,115.423,115.423,0,0,1,21.774-6.76,116.875,116.875,0,0,1,23.378-2.356,116.872,116.872,0,0,1,23.378,2.356,115.423,115.423,0,0,1,21.774,6.76,115.922,115.922,0,0,1,19.7,10.695,116.971,116.971,0,0,1,17.167,14.165,116.877,116.877,0,0,1,14.165,17.167,116.067,116.067,0,0,1,10.694,19.7,115.484,115.484,0,0,1,6.76,21.776,116.992,116.992,0,0,1,2.345,21.785h14.12a2.5,2.5,0,0,1,1.846,4.186l-20.829,22.794a2.49,2.49,0,0,1-1.846.813A2.491,2.491,0,0,1-5767.559,2561.387Z"
            transform="translate(6234.119 -2175.072)"
            fill="#dae1ec"
          />
          <g
            id="銷貨退回"
            className="icon"
            onClick={() => navigate_page("/oms/return")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(1460 577)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="銷貨退回-2"
              data-name="銷貨退回"
              transform="translate(1515 743.311)"
              fill="#4b6ea2"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 銷貨退回 */}
                {t("OMS.util.return")}
              </tspan>
            </text>
            <g id="銷售退回" transform="translate(1482 612.433)">
              <g
                id="Group_3406"
                data-name="Group 3406"
                transform="translate(0)"
                clipPath="url(#clipPath)"
              >
                <path
                  id="Path_2048"
                  data-name="Path 2048"
                  d="M72.368,12.279,67.551,1.87A3.225,3.225,0,0,0,64.225.025L36.261,3.518v.03L8.443.072A3.225,3.225,0,0,0,5.117,1.918L.3,12.328a3.224,3.224,0,0,0,2.147,4.483l21.5,5.349a3.224,3.224,0,0,0,3.379-1.222l9.03-12.312L45.346,20.89a3.225,3.225,0,0,0,3.379,1.221l21.5-5.349a3.224,3.224,0,0,0,2.147-4.483M30.921,8.4l-6.471,8.825a.646.646,0,0,1-.676.245L5.81,13a.645.645,0,0,1-.429-.9l3.24-7a.647.647,0,0,1,.665-.37L30.481,7.375a.645.645,0,0,1,.44,1.021m35.937,4.551-17.963,4.47a.647.647,0,0,1-.676-.244L41.747,8.349a.645.645,0,0,1,.44-1.021L63.382,4.68a.645.645,0,0,1,.665.369l3.24,7a.645.645,0,0,1-.429.9"
                  transform="translate(0 0)"
                  fill="#4b6ea2"
                />
                <path
                  id="Path_2049"
                  data-name="Path 2049"
                  d="M123.884,232.343a13.985,13.985,0,0,0-11.77,21.549l-8.59,2.371V230.18a2.289,2.289,0,0,0-2.194-2.3,2.258,2.258,0,0,0-2.321,2.257v26.09L75.22,249.655v-15.7a.645.645,0,0,0-.489-.626l-3.225-.8a.645.645,0,0,0-.8.626v18.383a2.146,2.146,0,0,0,1.827,2.06c.041.014.082.027.125.038l27.616,7.625a2.312,2.312,0,0,0,.266.109l.014,0c.053.017.108.033.163.047l.016,0c.047.011.1.022.144.03l.036.007c.047.007.1.013.145.018l.059,0,.114,0,.036,0,.064,0h.011l.073,0a2.25,2.25,0,0,0,.981-.228l13.216-3.648a13.989,13.989,0,1,0,8.274-25.267m6.258,17.485a1.96,1.96,0,1,1-2.771,2.771l-3.532-3.532-3.532,3.532a1.96,1.96,0,1,1-2.771-2.771l3.532-3.532-3.532-3.532a1.96,1.96,0,0,1,2.771-2.771l3.532,3.532,3.532-3.532a1.96,1.96,0,1,1,2.771,2.771l-3.532,3.532Z"
                  transform="translate(-64.933 -209.27)"
                  fill="#4b6ea2"
                />
              </g>
            </g>
            <g id="框-7" data-name="框" transform="translate(520 117.31)">
              <g transform="matrix(1, 0, 0, 1, -276.88, 195.33)" filter="url(#框)">
                <path
                  id="框-8"
                  data-name="框"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(8359.88 -5708.33)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
            </g>
          </g>
          <g
            id="銷售發貨"
            className="icon"
            onClick={() => navigate_page("/ims/plan-out", { "opcode": "4220" })}
            transform="translate(0 -71)"
          >
            <text
              id="銷售發貨-2"
              data-name="銷售發貨"
              transform="translate(1520 541)"
              fill="#4b6ea2"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 銷售發貨 */}
                {t("WMS.util.opcode_4220")}
              </tspan>
            </text>
            <g id="銷售發貨-3" data-name="銷售發貨" transform="translate(1482 412.475)">
              <g
                id="Group_3408"
                data-name="Group 3408"
                transform="translate(0)"
                clipPath="url(#clipPath)"
              >
                <path
                  id="Path_2050"
                  data-name="Path 2050"
                  d="M72.368,12.279,67.551,1.87A3.225,3.225,0,0,0,64.225.025L36.261,3.518v.03L8.443.072A3.225,3.225,0,0,0,5.117,1.918L.3,12.328a3.224,3.224,0,0,0,2.147,4.483l21.5,5.349a3.224,3.224,0,0,0,3.379-1.222l9.03-12.312L45.346,20.89a3.225,3.225,0,0,0,3.379,1.221l21.5-5.349a3.224,3.224,0,0,0,2.147-4.483M30.921,8.4l-6.471,8.825a.646.646,0,0,1-.676.245L5.81,13a.645.645,0,0,1-.429-.9l3.24-7a.647.647,0,0,1,.665-.37L30.481,7.375a.645.645,0,0,1,.44,1.021m35.937,4.551-17.963,4.47a.647.647,0,0,1-.676-.244L41.747,8.349a.645.645,0,0,1,.44-1.021L63.382,4.68a.645.645,0,0,1,.665.369l3.24,7a.645.645,0,0,1-.429.9"
                  transform="translate(0 0)"
                  fill="#4b6ea2"
                />
                <path
                  id="Path_2051"
                  data-name="Path 2051"
                  d="M123.884,232.343a13.986,13.986,0,0,0-11.77,21.549l-8.59,2.371V230.18a2.289,2.289,0,0,0-2.194-2.3,2.258,2.258,0,0,0-2.321,2.257v26.09L75.22,249.655v-15.7a.645.645,0,0,0-.489-.626l-3.225-.8a.645.645,0,0,0-.8.626v18.383a2.146,2.146,0,0,0,1.827,2.06c.041.014.082.027.125.038l27.616,7.625a2.3,2.3,0,0,0,.266.109l.014,0c.053.017.108.033.162.047l.015,0c.047.011.1.022.144.03l.036.007c.047.007.1.013.145.018l.059,0,.114,0,.036,0,.065,0h.011l.073,0a2.249,2.249,0,0,0,.981-.228l13.216-3.648a13.989,13.989,0,1,0,8.274-25.267m8.813,10.772-9.281,9.281a1.96,1.96,0,0,1-2.771,0,1.983,1.983,0,0,1-.157-.179l-5.334-5.334a1.96,1.96,0,0,1,2.771-2.771l4.116,4.117,7.884-7.884a1.96,1.96,0,0,1,2.771,2.771"
                  transform="translate(-64.933 -209.27)"
                  fill="#4b6ea2"
                />
              </g>
            </g>
          </g>
          <g
            id="出貨檢驗"
            className="icon"
            onClick={() => navigate_page("/oms/check")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色-2"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(1169 374)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="出貨檢驗-2"
              data-name="出貨檢驗"
              transform="translate(1230 541)"
              fill="#4b6ea2"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 出貨檢驗 */}
                {t("OMS.check.pageHeader")}
              </tspan>
            </text>
            <g id="Group_3519" data-name="Group 3519">
              <g id="訂單" transform="translate(228.999 -86)">
                <g transform="matrix(1, 0, 0, 1, 14.12, 398.64)" filter="url(#框-2)">
                  <path
                    id="框-9"
                    data-name="框"
                    d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                    transform="translate(8068.88 -5911.64)"
                    fill="url(#linear-gradient-2)"
                  />
                </g>
              </g>
              <g id="Group_3518" data-name="Group 3518" transform="translate(1195 401)">
                <g id="Group_3517" data-name="Group 3517" clipPath="url(#clipPath-3)">
                  <path
                    id="Path_2104"
                    data-name="Path 2104"
                    d="M243.034,29.639h20.031a4.8,4.8,0,0,0,4.8-4.8V4.8a4.8,4.8,0,0,0-4.8-4.8H243.034a4.8,4.8,0,0,0-4.8,4.8V24.835a4.8,4.8,0,0,0,4.8,4.8m-.371-23.885a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426A1.266,1.266,0,0,0,251,14.125h3.932a1.266,1.266,0,0,0,1.266-1.267V4.433h5.912a1.321,1.321,0,0,1,1.321,1.321V23.885a1.321,1.321,0,0,1-1.321,1.321H243.984a1.321,1.321,0,0,1-1.321-1.321Z"
                    transform="translate(-221.388)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2105"
                    data-name="Path 2105"
                    d="M24.835,479.2H4.8a4.8,4.8,0,0,0-4.8,4.8v20.031a4.8,4.8,0,0,0,4.8,4.8H24.835a4.8,4.8,0,0,0,4.8-4.8V484.007a4.8,4.8,0,0,0-4.8-4.8m.372,23.885a1.321,1.321,0,0,1-1.321,1.321H5.754a1.321,1.321,0,0,1-1.321-1.321V484.957a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426a1.266,1.266,0,0,0,1.267,1.266h3.932a1.266,1.266,0,0,0,1.266-1.266v-8.426h5.912a1.321,1.321,0,0,1,1.321,1.321Z"
                    transform="translate(0 -448.326)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2106"
                    data-name="Path 2106"
                    d="M501.293,479.2H481.262a4.8,4.8,0,0,0-4.8,4.8v20.031a4.8,4.8,0,0,0,4.8,4.8h20.031a4.8,4.8,0,0,0,4.8-4.8V484.007a4.8,4.8,0,0,0-4.8-4.8m.372,23.885a1.321,1.321,0,0,1-1.321,1.321H482.212a1.321,1.321,0,0,1-1.321-1.321V484.957a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426a1.266,1.266,0,0,0,1.266,1.266h3.932a1.266,1.266,0,0,0,1.266-1.266v-8.426h5.912a1.321,1.321,0,0,1,1.321,1.321Z"
                    transform="translate(-442.775 -448.326)"
                    fill="#4b6ea2"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="發貨"
            className="icon"
            onClick={() => navigate_page("/oms/outstock")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色-3"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(955 374)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="發貨-2"
              data-name="發貨"
              transform="translate(1010 351)"
              fill="#4b6ea2"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 發貨 */}
                {t("OMS.util.outstock")}
              </tspan>
            </text>
            <g id="發貨-3" data-name="發貨" transform="translate(235 -86)">
              <g transform="matrix(1, 0, 0, 1, 8.12, 398.64)" filter="url(#框-3)">
                <path
                  id="框-10"
                  data-name="框"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(7854.88 -5911.64)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
              <g id="Group_3435" data-name="Group 3435" transform="translate(744 490)">
                <g
                  id="Group_3434"
                  data-name="Group 3434"
                  transform="translate(0)"
                  clipPath="url(#clipPath-4)"
                >
                  <path
                    id="Path_2069"
                    data-name="Path 2069"
                    d="M69.852,29.406l0-.005a2.276,2.276,0,0,0-.16-.242l-.286-.377L60.845,17.513a2.187,2.187,0,0,0-1.972-.852H49.154V6.876A6.877,6.877,0,0,0,42.278,0H6.876A6.877,6.877,0,0,0,0,6.876V42.682A2.188,2.188,0,0,0,2.188,44.87H6.377a9.69,9.69,0,0,0,19.379,0H44.2a9.69,9.69,0,0,0,19.379,0h4.3a2.188,2.188,0,0,0,2.188-2.188V30.349a2.18,2.18,0,0,0-.213-.943M16.067,50.184A5.314,5.314,0,1,1,21.38,44.87a5.319,5.319,0,0,1-5.314,5.314M45.055,35.738a2.5,2.5,0,0,1-2.5,2.5H23.671a9.931,9.931,0,0,0-15.209,0H6.277a2.5,2.5,0,0,1-2.5-2.5V6.967a2.5,2.5,0,0,1,2.5-2.5H42.555a2.5,2.5,0,0,1,2.5,2.5Zm8.832,14.446a5.319,5.319,0,0,1-5.314-5.314c0-.026,0-.052,0-.078a5.122,5.122,0,0,1,.055-.688h0a5.26,5.26,0,0,1,.524-1.646,5.313,5.313,0,1,1,4.733,7.727M49.154,28.161V21.037h8.873l5.41,7.124Z"
                    fill="#4b6ea2"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="銷貨"
            className="icon"
            onClick={() => navigate_page("/oms/fod")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色-4"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(734 374)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="銷貨-2"
              data-name="銷貨"
              transform="translate(792 541)"
              fill="#4b6ea2"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 銷貨 */}
                {t("OMS.util.ord")}
              </tspan>
            </text>
            <g id="銷貨-3" data-name="銷貨" transform="translate(-206.001 -86)">
              <g transform="matrix(1, 0, 0, 1, 449.12, 398.64)" filter="url(#框-4)">
                <path
                  id="框-11"
                  data-name="框"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(7633.88 -5911.64)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
              <g id="Group_3441" data-name="Group 3441" transform="translate(971.332 484.5)">
                <g
                  id="Group_3440"
                  data-name="Group 3440"
                  transform="translate(-0.342 0)"
                  clipPath="url(#clipPath-5)"
                >
                  <path
                    id="Path_2073"
                    data-name="Path 2073"
                    d="M51.04,7.608H33.695c.018-.2.028-.412.028-.621a6.988,6.988,0,1,0-13.975,0c0,.209.01.416.028.621H2.43A2.43,2.43,0,0,0,0,10.039V62.205a2.43,2.43,0,0,0,2.43,2.43H52.255a1.215,1.215,0,0,0,1.215-1.215V10.039a2.43,2.43,0,0,0-2.43-2.43M26.852,4.292a2.7,2.7,0,0,1,2.509,3.316c-.009.037-.018.074-.029.111a2.681,2.681,0,0,1-.261.618l0,0c-.017.03-.036.06-.055.09l-.005.008c-.019.03-.038.058-.058.087l0,.008a3.116,3.116,0,0,1-.272.328l-.009.01c-.021.021-.042.042-.063.062l-.022.021-.062.055-.028.024-.061.051-.03.024-.064.049-.016.011c-.043.031-.087.062-.131.09l-.042.027-.057.033-.049.028-.055.03-.054.027-.054.026-.058.027-.065.028-.05.019-.051.02-.1.033-.033.01c-.035.011-.07.021-.106.03l-.043.011-.079.018-.049.009-.076.014-.05.008-.078.011-.049.005-.086.006-.043,0c-.043,0-.087,0-.13,0s-.087,0-.13,0l-.043,0-.086-.006-.049-.005-.078-.011L26.3,9.65l-.076-.014-.049-.009L26.1,9.609,26.052,9.6c-.036-.009-.071-.02-.106-.03l-.033-.01-.1-.033-.051-.02-.05-.019-.065-.028-.058-.027-.054-.026-.054-.027-.055-.03-.049-.028-.057-.033-.042-.027c-.045-.028-.088-.059-.131-.09l-.016-.011-.064-.049-.03-.024-.061-.051-.028-.024-.062-.055-.022-.021c-.021-.021-.043-.041-.063-.062l-.009-.01a3.116,3.116,0,0,1-.272-.328l0-.008c-.021-.029-.039-.058-.058-.087l-.005-.008c-.019-.03-.037-.059-.055-.09l0,0a2.681,2.681,0,0,1-.261-.618c-.011-.037-.02-.074-.029-.111a2.737,2.737,0,0,1-.072-.621,2.7,2.7,0,0,1,2.814-2.7M47.985,60.259H5.469a1.215,1.215,0,0,1-1.215-1.215V12.816A1.215,1.215,0,0,1,5.469,11.6h5.947V22.561a1.215,1.215,0,0,0,1.215,1.215H40.84a1.215,1.215,0,0,0,1.215-1.215V11.6H48a1.215,1.215,0,0,1,1.215,1.215V59.026a1.233,1.233,0,0,1-1.233,1.233"
                    transform="translate(-0.124 0.734)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2074"
                    data-name="Path 2074"
                    d="M172.429,509H144.273a2.127,2.127,0,0,1,0-4.253h28.156a2.127,2.127,0,1,1,0,4.253"
                    transform="translate(-131.474 -465.679)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2075"
                    data-name="Path 2075"
                    d="M172.429,620.253H144.273a2.127,2.127,0,0,1,0-4.253h28.156a2.127,2.127,0,0,1,0,4.253"
                    transform="translate(-131.474 -568.479)"
                    fill="#4b6ea2"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="預訂"
            className="icon"
            onClick={() => navigate_page("/oms/fod")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色-5"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(518 374)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="預訂-2"
              data-name="預訂"
              transform="translate(525 351)"
              fill="#4b6ea2"
              fontSize="33"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 預訂 */}
                {t("OMS.util.fod")}
              </tspan>
            </text>
            <g id="預訂-3" data-name="預訂" transform="translate(15 -86)">
              <g transform="matrix(1, 0, 0, 1, 228.12, 398.64)" filter="url(#框-5)">
                <path
                  id="框-12"
                  data-name="框"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(7417.88 -5911.64)"
                  fill="url(#linear-gradient-10)"
                />
              </g>
              <g
                id="Group_3455"
                data-name="Group 3455"
                transform="translate(522.954 488.578)"
                clipPath="url(#clipPath-6)"
              >
                <path
                  id="Path_2082"
                  data-name="Path 2082"
                  d="M149.137,259.177l21.717-4.758a1.74,1.74,0,0,1,.745,3.4l-21.717,4.758a1.74,1.74,0,1,1-.745-3.4"
                  transform="translate(-135.384 -233.059)"
                  fill="#4b6ea2"
                />
                <path
                  id="Path_2083"
                  data-name="Path 2083"
                  d="M172.486,365.373l21.717-4.757a1.74,1.74,0,0,1,.745,3.4l-21.717,4.757a1.74,1.74,0,1,1-.745-3.4"
                  transform="translate(-156.776 -330.355)"
                  fill="#4b6ea2"
                />
                <path
                  id="Path_2084"
                  data-name="Path 2084"
                  d="M195.314,469.581l21.717-4.758a1.74,1.74,0,0,1,.745,3.4l-21.717,4.758a1.74,1.74,0,1,1-.745-3.4"
                  transform="translate(-177.691 -425.829)"
                  fill="#4b6ea2"
                />
                <path
                  id="Path_2085"
                  data-name="Path 2085"
                  d="M70.677,6.669l-14.749-3.3A6.574,6.574,0,0,0,50.007,0L27.255.026h-.006q-.312,0-.62.028h0A5.836,5.836,0,0,0,24.6.134h-.006l-20,4.379A5.833,5.833,0,0,0,.135,11.453l9.108,41.8a5.833,5.833,0,0,0,6.941,4.458L36.406,53.3h0l7.105-.014,15.148,3.377a5.717,5.717,0,0,0,6.847-4.117l9.44-39.287a5.5,5.5,0,0,0-4.268-6.6M45.855,47.763l-30.4,6.623a2.433,2.433,0,0,1-2.892-1.857L3.46,10.729A2.434,2.434,0,0,1,5.315,7.838l18.6-4.071,2.611,11.981a1.7,1.7,0,0,0,2.024,1.3l12.51-2.726,6.656,30.549a2.434,2.434,0,0,1-1.857,2.892m4.273,2.184-1.154,0a5.831,5.831,0,0,0,2.063-5.8L44.188,12.715a5.831,5.831,0,0,0-2.548-3.666l-8.875-5.7,13.506-.015,3.741,0A2.731,2.731,0,0,1,52.5,4.786a2.126,2.126,0,0,1,.211.916v.236l.106,41.625a2.555,2.555,0,0,1-2.694,2.384M71.7,12.54l-9.44,39.287a2.385,2.385,0,0,1-2.854,1.716l-5.636-1.257A5.548,5.548,0,0,0,56.6,47.556l-.1-40.77L69.924,9.792A2.293,2.293,0,0,1,71.7,12.54"
                  transform="translate(0 0)"
                  fill="#4b6ea2"
                />
              </g>
            </g>
          </g>
          <g
            id="報價"
            className="icon"
            onClick={() => navigate_page("/oms/quo")}
            transform="translate(0 -71)"
          >
            <circle
              id="底色-6"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(301 374)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="報價-2"
              data-name="報價"
              transform="translate(355 541)"
              textAnchor="middle"
              fill="#4b6ea2"
              fontSize="33"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 報價 */}
                {t("OMS.util.quo")}
              </tspan>
            </text>
            <g id="報價-3" data-name="報價" transform="translate(-202 -86)">
              <g transform="matrix(1, 0, 0, 1, 445.12, 398.64)" filter="url(#框-6)">
                <path
                  id="框-13"
                  data-name="框"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(7200.88 -5911.64)"
                  fill="url(#linear-gradient-10)"
                />
              </g>
              <g id="Group_3439" data-name="Group 3439" transform="translate(526 490)">
                <g
                  id="Group_3438"
                  data-name="Group 3438"
                  transform="translate(0 0)"
                  clipPath="url(#clipPath-7)"
                >
                  <path
                    id="Path_2070"
                    data-name="Path 2070"
                    d="M43.18,0H2.5A2.5,2.5,0,0,0,0,2.5V31.429a2.5,2.5,0,0,0,2.5,2.5H7.81v9.756a.626.626,0,0,0,.983.515L23.6,33.933H43.18a2.5,2.5,0,0,0,2.5-2.5V2.5A2.5,2.5,0,0,0,43.18,0M41.3,28.3a1.251,1.251,0,0,1-1.252,1.252H22.27l-6.355,4.381-.365.251-.025.017-2.352,1.632a.626.626,0,0,1-.983-.515V29.552H5.633A1.251,1.251,0,0,1,4.381,28.3V5.634A1.251,1.251,0,0,1,5.633,4.382H40.05A1.251,1.251,0,0,1,41.3,5.634Z"
                    transform="translate(0 -0.001)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2071"
                    data-name="Path 2071"
                    d="M229.234,81.1v1.107a6.419,6.419,0,0,1,1.882.451,3.718,3.718,0,0,1,1.369,1.025,4.359,4.359,0,0,1,.713,1.077,2.484,2.484,0,0,1,.251,1.015,1.212,1.212,0,0,1-.374.887,1.236,1.236,0,0,1-.907.374,1.276,1.276,0,0,1-1.3-1.087,2.323,2.323,0,0,0-1.631-1.7v4.266q1.271.349,2.025.636a4.2,4.2,0,0,1,1.348.831,3.84,3.84,0,0,1,.979,1.348,4.237,4.237,0,0,1,.343,1.718,4.554,4.554,0,0,1-.549,2.189,4.335,4.335,0,0,1-1.615,1.666,5.749,5.749,0,0,1-2.533.769v2.553a2.418,2.418,0,0,1-.118.882.5.5,0,0,1-.518.277.573.573,0,0,1-.518-.226,1.289,1.289,0,0,1-.149-.7V97.693a6.477,6.477,0,0,1-2.1-.564,4.963,4.963,0,0,1-1.5-1.072,4.464,4.464,0,0,1-.887-1.333,3.528,3.528,0,0,1-.287-1.359,1.241,1.241,0,0,1,.385-.887,1.284,1.284,0,0,1,.959-.395,1.357,1.357,0,0,1,.779.215,1.11,1.11,0,0,1,.441.605,12.936,12.936,0,0,0,.482,1.287,2.476,2.476,0,0,0,.62.815,2.769,2.769,0,0,0,1.1.564V90.8a12.536,12.536,0,0,1-2.3-.846,3.867,3.867,0,0,1-1.5-1.328,4.395,4.395,0,0,1,.549-5.107,5.03,5.03,0,0,1,3.246-1.313V81.122q0-.861.646-.861t.656.841m-1.3,7.035V84.209a3.579,3.579,0,0,0-1.343.677,1.9,1.9,0,0,0-.031,2.5,4.152,4.152,0,0,0,1.374.754m1.3,3.046v4.491a2.815,2.815,0,0,0,1.6-.831,2.108,2.108,0,0,0,.564-1.456,1.748,1.748,0,0,0-.549-1.379,4.466,4.466,0,0,0-1.615-.825"
                    transform="translate(-205.705 -73.982)"
                    fill="#4b6ea2"
                  />
                  <path
                    id="Path_2072"
                    data-name="Path 2072"
                    d="M359.327,135.092H340.065a.626.626,0,0,0-.626.626v3.129a.626.626,0,0,0,.626.626H356.2a1.252,1.252,0,0,1,1.252,1.252V163.39a1.252,1.252,0,0,1-1.252,1.252h-5.786a.626.626,0,0,0-.626.626v3.755h.006v1.647a.626.626,0,0,1-.983.514l-2.263-1.57-.005.008-.8-.564-.05-.035h0l-5.164-3.656-.891-.614a.626.626,0,0,0-.355-.111H321.78a1.252,1.252,0,0,1-1.252-1.252v-2.334a.626.626,0,0,0-.626-.626h-3.129a.626.626,0,0,0-.626.626v5.463a2.5,2.5,0,0,0,2.5,2.5h19.161a.626.626,0,0,1,.357.112l15.022,10.42a.626.626,0,0,0,.983-.515v-9.391a.626.626,0,0,1,.626-.626h4.528a2.5,2.5,0,0,0,2.5-2.5V137.6a2.5,2.5,0,0,0-2.5-2.5"
                    transform="translate(-291.415 -124.524)"
                    fill="#4b6ea2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Tour;
