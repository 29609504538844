import { useState, useEffect } from "react";
import { Modal, message, Button, DatePicker } from "antd";
import { numberWithCommas } from "@/util/format";
import { IconExcelDownload } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { SearchButton } from "@/components/PDS/Buttons";
import { getMonthInv } from "@/service/apis/WMS/InvHistory";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const InvHistory = () => {
  const { t } = useTranslation();
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_searchData, set_s_searchData] = useState({
    page: 1,
    pageSize: 100,
    PN: null,
    startDate: dayjs().startOf("month").format("YYYY-MM"),
    endDate: dayjs().startOf("month").format("YYYY-MM"),
  });
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const call_getMonthInv = useAPI(getMonthInv);

  const onSearch = () => {
    call_getMonthInv.request({
      ...s_searchData,
      startDate: dayjs(s_searchData.startDate).startOf("month").format("YYYY-MM-DD"),
      endDate: dayjs(s_searchData.endDate).endOf("month").format("YYYY-MM-DD"),
    });
  };

  const handleChange = (type, value) => {
    set_s_searchData((prev) => ({
      ...prev,
      ...(type === "time"
        ? {
          startDate: dayjs(value[0]).format("YYYY-MM"),
          endDate: dayjs(value[1]).format("YYYY-MM"),
        }
        : { [type]: value }),
      page: 1,
      pageSize: 100,
    }));
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  const specialColor_F9E9D7AC = { className: "bg-[#F9E9D7AC]" };
  const specialColor_DFFFDF = { className: "bg-[#DFFFDF]" };

  const tableColumns = [
    {
      title: t("util.PN"),//料號
      dataIndex: "PN",
      width: "15%",
      isExcel: true,
    },
    {
      title: t("util.pdtNM"),//品名
      dataIndex: "pdtNM",
      width: "10%",
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.h"),//厚
      dataIndex: "h",
      align: "right",
      isExcel: true,
      render: (text) => numberWithCommas(text),
    },
    {
      title: t("WMS.inv-history.w"),//寬
      dataIndex: "w",
      align: "right",
      isExcel: true,
      render: (text) => numberWithCommas(text),
    },
    {
      title: t("WMS.inv-history.l"),//長
      dataIndex: "l",
      align: "right",
      isExcel: true,
      render: (text) => numberWithCommas(text),
    },
    {
      title: t("WMS.inv-history.unit"),//計量單位
      dataIndex: "unit",
      align: "center",
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.level"),//等級
      dataIndex: "level",
      align: "center",
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.beginCBM"),//期初 (CBM)
      dataIndex: "beginCBM",
      align: "right",
      onCell: () => specialColor_F9E9D7AC,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.inCBM"),//進 (CBM)
      dataIndex: "inCBM",
      align: "right",
      onCell: () => specialColor_F9E9D7AC,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.outCBM"),//銷 (CBM)
      dataIndex: "outCBM",
      align: "right",
      onCell: () => specialColor_F9E9D7AC,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.useCBM"),//耗 (CBM)
      dataIndex: "useCBM",
      align: "right",
      onCell: () => specialColor_F9E9D7AC,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.endCBM"),//期末 (CBM)
      dataIndex: "endCBM",
      align: "right",
      onCell: () => specialColor_F9E9D7AC,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.stdsale"),//單價
      dataIndex: "stdsale",
      align: "right",
      width: "90px",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.beginPrice"),//期初金額
      dataIndex: "beginPrice",
      align: "right",
      width: "90px",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.inPrice"),//進貨金額
      dataIndex: "inPrice",
      align: "right",
      width: "90px",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.outPrice"),//銷貨金額
      dataIndex: "outPrice",
      align: "right",
      width: "90px",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.usePrice"),//耗用金額
      dataIndex: "usePrice",
      align: "right",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      width: "90px",
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.endPrice"),//期末金額
      dataIndex: "endPrice",
      align: "right",
      width: "90px",
      render: (text) => numberWithCommas(text),
      onCell: () => specialColor_DFFFDF,
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.proD"),//進料日期
      dataIndex: "proD",
      width: "120px",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
      isExcel: true,
    },
    {
      title: t("WMS.inv-history.suppalias"),//供應商
      dataIndex: "suppalias",
      isExcel: true,
    },
  ];

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    if (call_getMonthInv.status === "suc") {
      message.success(call_getMonthInv.msg);
      set_s_tableData(
        call_getMonthInv.data?.tableData.map((x, i) => ({
          ...x,
          key: i,
        })) || []
      );
      set_s_pageInfo(call_getMonthInv.data.pageInfo);
    } else if (call_getMonthInv.status === "err") {
      message.error(call_getMonthInv.msg);
      set_s_tableData([]);
    }
  }, [call_getMonthInv.status]);

  return (
    <>
      <PageHeader
        title={t("WMS.inv-history.pageHeader")}
        extra={[
          <DebounceSelect
            key="DebounceSelect"
            className="w-[300px]"
            placeholder={t("util.placeholder_input") + " " + t("util.PN")}
            allowClear
            fetchOptions={fetchPNList}
            showSearch
            maxTagCount={10}
            onChange={(e) => handleChange("PN", e?.value || null)}
          />,
          <RangePicker
            key="RangePicker"
            picker="month"
            value={[dayjs(s_searchData.startDate), dayjs(s_searchData.endDate)]}
            onChange={(e) => handleChange("time", e)}
            onOpenChange={(e) => !e && onSearch()}
          />,
          // <Button type="primary" key="search" onClick={onSearch}>
          //   {t("util.search")}
          // </Button>,
          <SearchButton type="primary" key="search" onClick={onSearch} />,
          <IconExcelDownload key="IconExcelDownload" className="text-5xl" />,
        ]}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getMonthInv.status === "load"}
        pagination={{
          total: s_pageInfo?.totalItems,
          current: s_searchData.page,
          pageSize: s_searchData.pageSize,
          onChange: (current, size) =>
            set_s_searchData((prev) => {
              // 如果有更動pageSize 回第一頁
              let checkPage = prev.pageSize !== size ? 1 : current;
              return { ...prev, page: checkPage, pageSize: size };
            }),
        }}
      />
    </>
  );
};

export default InvHistory;
