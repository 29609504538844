import { useState, useEffect } from "react";
import { Button, message, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomModal from "../../CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// API
import useAPI from "@/hooks/useAPI";
import { getCustTB } from "@/service/apis/OMS/util";

const ContactButton = ({ disabled = false, custID, chooseContact, title = "客戶窗口" }) => {
  const { t } = useTranslation();
  const [isShow, set_isShow] = useState(false); //廠商資料跳顯
  const [s_ContactData, set_s_ContactData] = useState([]); //廠商表身資料
  const call_getCustTB = useAPI(getCustTB);

  //按下選擇的廠商窗口
  const afterOK = (contact_obj) => {
    chooseContact(contact_obj);
    set_isShow(false);
  };

  //點擊聯絡人跳顯時才call取得廠商表身的api
  useEffect(() => {
    if (isShow === false) {
      return;
    }
    call_getCustTB.request({ custID: custID });
  }, [isShow]);

  //取得廠商資料
  useEffect(() => {
    const { data, status, msg } = call_getCustTB;
    if (status === "suc") {
      message.success(msg);
      set_s_ContactData(data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_ContactData([]);
    }
  }, [call_getCustTB.status]);

  const tableColumns = [
    // {
    //   title: t("ADM.client.itemNM"), //"窗口功能",
    //   dataIndex: "itemNM",
    //   align: "center",
    //   width: "10%",
    // },
    {
      title: t("OMS.util.contact"), //"聯絡人",
      dataIndex: "contact",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.tel"), //"聯絡人號碼",
      dataIndex: "tel",
      align: "center",
      width: "15%",
    },
    {
      title: t("util.dept"), //"所屬部門",
      dataIndex: "department",
      align: "center",
      width: "15%",
    },
    // {
    //   title: t("PMS.return.addr"), //"聯絡地址",
    //   dataIndex: "addr",
    //   align: "center",
    //   width: "30%",
    // },
    // {
    //   title: t("util.note"), //"聯絡地址",
    //   dataIndex: "note",
    //   align: "center",
    //   width: "30%",
    // },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "10%",
      render: (_, rowData, __) => (
        <Button type="primary" onClick={() => afterOK(rowData)}>
          {t("util.render")}
        </Button>
      ),
    },
  ];

  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          className="find-btn"
          disabled={disabled}
          icon={<SearchOutlined />}
          onClick={() => {
            set_isShow(true);
          }}
        />
      </Tooltip>
      <CustomModal width="40%" title={title} onCancel={() => set_isShow(false)} open={isShow}>
        <CustomTable
          columns={tableColumns}
          rowNumber={5}
          dataSource={s_ContactData?.map((item, index) => ({ key: index, ...item })) ?? []}
        />
      </CustomModal>
    </>
  );
};

export default ContactButton;
