import { Button, Col, Row } from "antd";
import React, { useContext, useState } from "react";

// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// component
import FileModal from "../Modal/FileModal";
import InspcerModal from "../Modal/InspcerModal";
// context
import { MainPageContext } from "../index";
import { useTranslation } from "react-i18next";

const Commission = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled, call_updateOrdHd, call_getOrdHD } = useContext(MainPageContext);
  const { t } = useTranslation();
  const [s_fileType, set_s_fileType] = useState("");
  const [s_fileTypeName, set_s_fileTypeName] = useState("");
  const [s_showFileModal, set_s_showFileModal] = useState(false);
  const [s_showInspcer, set_s_showInspcer] = useState(false);
  const BTN_STYLE = "oms-btn-darkBlue h-28 text-2xl flex-center";
  // console.log("s_disabled.viewcontract = ", s_disabled.viewcontract);
  // 上傳應收帳款 or 瀏覽應收帳款
  const AR = () => {
    if (hd_values.lifeF === "7") {
      // 上傳應收帳款
      set_s_showFileModal(true);
      set_s_fileType("AR");
      set_s_fileTypeName(t("OMS.ord.AR"));
    } else {
      const element = document.createElement("a");
      element.href = hd_values.AR;
      element.target = "_blank";
      // element.download = "myFile.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const rcv = () => {
    if (hd_values.lifeF === "8") {
      // 上傳收款入帳
      set_s_showFileModal(true);
      set_s_fileType("rcv");
      set_s_fileTypeName(t("OMS.ord.rcv"));
    } else {
      console.log("hd_values.rcv = ", hd_values);
      // return;
      const element = document.createElement("a");
      element.href = hd_values.rcv;
      element.target = "_blank";
      // element.download = "myFile.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const bill = () => {
    if (hd_values.lifeF === "9") {
      // 上傳收款入帳
      set_s_showFileModal(true);
      set_s_fileType("bill");
      set_s_fileTypeName(t("OMS.ord.bill"));
    } else {
      const element = document.createElement("a");
      element.href = hd_values.AR;
      element.target = "_blank";
      // element.download = "myFile.txt";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const viewFile = (type) => {
    const element = document.createElement("a");
    element.href = hd_values[type];
    element.target = "_blank";
    // element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  // console.log("hd_values = ", hd_values);
  // console.log("hd_values.quoSign?.length === 0 = ", hd_values.quoSign?.length === 0);
  return (
    <>
      <div className="mt-5 flex justify-center gap-5">
        {/* 上傳客戶委託 */}
        <Button
          // disabled={s_disabled.quoSign}
          disabled={hd_values.quoSign?.length === 0}
          className={BTN_STYLE}
          onClick={() => viewFile("quoSign")}
        >
          瀏覽{t("OMS.ord.quoSign")}
        </Button>

        {/* 瀏覽客戶簽回 */}
        <Button
          disabled={hd_values.entrust?.length === 0}
          onClick={() => viewFile("entrust")}
          className={BTN_STYLE}
        >
          瀏覽{t("OMS.util.entrust")}
        </Button>

        {/* 瀏覽檢測證書 */}
        <Button
          disabled={hd_values.inspcer?.length === 0}
          // onClick={() => viewFile("inspcer")}
          onClick={() => {
            // alert(123);
            console.log("hd_values === ", hd_values);
            set_s_showInspcer(true);
          }}
          className={BTN_STYLE}
        >
          瀏覽{t("OMS.ord.inspcer")}
        </Button>
      </div>

      <CustomModal
        title={`上傳 ${s_fileTypeName}`}
        width="30%"
        open={s_showFileModal}
        maskClosable={true}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_fileType("");
          set_s_fileTypeName("");
        }}
      >
        <FileModal
          s_fileType={s_fileType}
          s_fileTypeName={s_fileTypeName}
          hd_values={hd_values}
          set_s_showFileModal={set_s_showFileModal}
          call_getOrdHD={call_getOrdHD}
          call_updateOrdHd={call_updateOrdHd}
        />
      </CustomModal>

      <CustomModal
        title={`瀏覽${t("OMS.ord.inspcer")}`}
        width="60%"
        open={s_showInspcer}
        maskClosable={true}
        onCancel={() => set_s_showInspcer(false)}
      >
        <InspcerModal hd_values={hd_values} set_s_showInspcer={set_s_showInspcer} />
      </CustomModal>
    </>
  );
};

export default Commission;
