// 用來判斷傳進去的值是否可以轉成時間
export const isValidDate = (str) => {
  // 後端傳來的日期欄位一定是字串
  if (typeof str !== "string") return false;
  // 用正則表達式判斷是因為很多字串都可以轉成時間
  // 用正則判斷是判斷傳進來的字串是否含有 xxxx-xx-xx 這種格式，有的話就判定成字串
  const dateRegex = /\d{4}-\d{2}-\d{2}/;
  if (!dateRegex.test(str)) return false;
  const date = new Date(str);
  return !isNaN(date.getTime());
};
