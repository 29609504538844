/* eslint-disable react-hooks/exhaustive-deps */
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { Grid, Modal, Space, message } from "antd";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import React, { useContext, useEffect, useState } from "react";

// component
import AddcModal from "../Modal/AddcModal";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// context
import { MainPageContext } from "../index";
import { default as RunA3ModalContainer } from "@/pages/OMS/RunA3/HD/ModalContainer";
import { deleteOrdADD } from "@/service/apis/OMS/Ord";
// util
import { formatAmount } from "@/util/format";
import { genTableColumns } from "@/pages/OMS/RunA3/HD";
import { getRunA3HD } from "@/service/apis/OMS/runA3";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const AddcContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values } = useContext(MainPageContext);
  const { getStaffName } = useContext(ERPContext);

  const { t } = useTranslation();
  const [s_editData, set_s_editData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // useAPI 刪除預付作業
  const call_getRunA3HD = useAPI(getRunA3HD);
  const [s_tableData, set_s_tableData] = useState([]);
  // 第一次渲染取得條款註記
  useEffect(() => {
    call_getRunA3HD.request({
      "preN": hd_values.N,
    });
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getRunA3HD;
    if (status === "suc") {
      message.success(msg);
      const _data =
        data?.map((item, index) => ({
          ...item,
          key: index + 1,
        })) || [];

      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
    }
  }, [call_getRunA3HD.status]);

  const basicColumns = genTableColumns(t, getStaffName);

  const tableColumns = basicColumns.concat([
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯 */}
          <EditButton
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
            }}
          />
        </Space>
      ),
    },
  ]);
  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getRunA3HD.status === "load"}
        tbMode={true}
      />

      <CustomModal
        title="查看订单收款作业"
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
        }}
      >
        <RunA3ModalContainer
          isDisabled={true} // 如果為 true 代表是在訂單查看時使用
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
};

export default AddcContainer;
