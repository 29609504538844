import { useContext } from "react";
import { Row, Col, Typography, theme } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { useToken } = theme;
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);

  const { token } = useToken();

  const { data } = s_drawer;

  const opcode_map = {
    "1110": t("OMS.util.opcode_1110"), // 國內報價
    "1120": t("OMS.util.opcode_1120"), // 國外報價
  };

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 單別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.opcode")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{opcode_map[data.opcode]}</Text>
        </Col>
        {/* 單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.N")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.N}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 幣別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.currencytype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.currencytype}</Text>
        </Col>
        {/* 匯率 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.exchangerate")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.exchangerate}</Text>
        </Col>
        {/* 匯率日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.exchangeD")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.exchangeD).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 税別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.taxtype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.taxtype}</Text>
        </Col>
        {/* 稅率 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.taxrate")}</Text>
        </Col>
        <Col span={12} className="pms-drawer-value">
          <Text>{data.taxrate}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 客戶ID */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.custID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.custID}</Text>
        </Col>
        {/* 客戶簡稱 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.custalias")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.custalias}</Text>
        </Col>
      </Row>

      <Row className="mt-5 h-24 border pms-drawer-border-color">
        {/* 報價註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.quo.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 報價人員 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.quo.quoID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.quoID)}</Text>
        </Col>
        {/* 更新日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.updtT")}</Text>
        </Col>
        <Col span={12} className="pms-drawer-value">
          <Text>{dayjs(data.updtT).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 是否有效 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.quo.isvalid")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value flex justify-center">
          {/* <Text>{data.isvalid ? "是" : "否"}</Text> */}
          {data.isvalid ? (
            <CheckCircleFilled style={{ color: token.colorPrimary }} />
          ) : (
            <CloseCircleOutlined />
          )}
        </Col>
        {/* 有效天數 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.quo.validdays")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.validdays}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
