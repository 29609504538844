import { instance } from "../../handlerAPI";
import { ims_apiBasic } from "../../APIBasic";

export const addInvProject = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addInvProject`, data);
};

export const getInvProject = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getInvProject`, {
    params: _params,
  });
};

export const getInvHd = (_params) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getInvHd`, {
    params: _params,
  });
};

export const deleteInvHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleteInvHd`, data);
};
export const updateInvProject = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateInvProject`, data);
};
export const txnInvHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/txnInvHd`, data);
};
export const updateInvHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateInvHd`, data);
};

export const addInvHd = (data) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addInvHd`, data);
};


// -------- Inv Project 盤點計畫 ---------

export const exportInvExcel = (_params) => {
	return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/exportInvExcel`, {
	  params: _params,
	});
  };

  export const deleteInvProject = (data) => {
	return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleteInvProject`, data);
  };

  export const getInvTb = (_params) => {
	return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getInvTb`, {
	  params: _params,
	});
  };


  export const updateInvTbQty = (data) => {
	return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updateInvTbQty`, data);
  };
  
  export const importInvExcel = (data) => {
	return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/importInvExcel`, data, {
	  headers: {
		"Content-Type": "multipart/form-data",
	  },
	});
  };