import { useContext } from "react";
import { useTranslation } from "react-i18next";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// util function
import { formatAmount } from "@/util/format";

  //註解勿刪
  // 類似w-[19.7368%]這種百分比是為了對齊表格
  //例如no欄位在上方表格中總寬度100%中所佔比例為4%，如果在底下表格中no要跟上方no相同寬度的話，必須經過轉換
  //下方表格總寬度為52%，要讓no欄位在下方佔的寬度跟上方表格一樣的話，套用此公式:(4% / 100%) * (100% / 52%) = 7.692%

const Container = ({ hd_values, pageData, currentPage, totalPages }) => {
  // 人員資料
  const { getStaffName } = useContext(ERPContext);
  const { t } = useTranslation();

  const width = {
    no: 4,
    item_kode: 18,
    specification: 30,
    stn: 10,
    price: 10,
    amount: 15,
    remark: 13,
  };

  return (
    <div className="flex flex-col w-full" >
      {/* 表頭 */}
      <div className="flex border-solid border-y-2 border-x-2 divide-x-2 divide-y-0 divide-solid text-[12px] text-center">
        {/* 序號 */}
        <div className={`flex-center pt-1`} style={{width:`${width.no}%`}}>NO</div>
        {/* 料號 */}
        <div className={`flex-center pt-1`} style={{width:`${width.item_kode}%`}}>ITEM KODE</div>
        <div className={`flex-center pt-1`} style={{width:`${width.specification}%`}}>SPECIFICATION</div>
        <div className={`flex flex-col pt-1`} style={{width:`${width.stn}%`}}> 
          <div>STN</div>
          <div>KG</div>
        </div>
        <div className={`flex flex-col pt-1`} style={{width:`${width.price}%`}}>
          <div>PRICE</div>
          <div></div>
        </div>
        <div className={`flex flex-col pt-1`} style={{width:`${width.amount}%`}}>
          <div>AMOUNT</div>
          <div>RP</div>
        </div>
        <div className={`flex-center pt-1`} style={{width:`${width.remark}%`}}>
          <div>REMARK</div>
        </div>
      </div>

      <div className="w-full  border-solid border-b-[1px] border-t-[0px] border-r-[0px] border-l-[0px] border h-4" />


      {/* 表格內容 */}
      {pageData?.map((item, index) => (
        <div
          key={index}
          className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-[10px] text-center h-[30px]"
        >
          <div className={`flex-center`} style={{width:`${width.no}%`}}>{item.itemno}</div>
          <div className={`flex justify-start items-center`} style={{width:`${width.item_kode}%`}} >
            <div className="scale-90 whitespace-nowrap">{item.PN}</div>
          </div>
          <div className={`flex justify-start items-center`} style={{width:`${width.specification}%`}} >
            <div className="flex h-full flex-col justify-around">
              <div className="scale-90 flex items-start">{item.pdtNM}</div>
            </div>
          </div>

          <div className={`flex justify-end items-center`} style={{width:`${width.stn}%`}}>
            <div className="scale-90">{formatAmount(item.confirmqty)}</div>
          </div>
          <div className={`flex justify-end items-center`} style={{width:`${width.price}%`}}>
            <div className="scale-90">{formatAmount(item.unitprice)}</div>
          </div>
          <div className={`flex justify-end items-center`} style={{width:`${width.amount}%`}}>
            <div className="scale-90">{formatAmount(item.itemsum)}</div>
          </div>
          <div className={`flex-center`} style={{width:`${width.remark}%`}}>
            <div className="scale-75">{item.note}</div>
          </div>
        </div>
      )) ?? undefined}
      {currentPage !== totalPages && (
        <span className="flex justify-end text-[12px] mt-1">see next page...</span>
      )}

      {/* 最後一頁才要顯示 */}
      {currentPage === totalPages && (
        <div className="flex flex-col">
          <div className="flex border border-solid border-t-1 border-b-1  divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`flex-center pt-1`} style={{width:`${width.no + width.item_kode + width.specification}%`}}>
              SUB TOTAL
            </div>
            <div className={`pt-1`} style={{width:`${width.stn}%`}}/>
            <div className={`pt-1`} style={{width:`${width.price}%`}}/>
            <div className={`pt-1 flex justify-end`} style={{width:`${width.amount}%`}}>
              {formatAmount(hd_values.sumtotal)}
            </div>
            <div className={`flex-center`} style={{width:`${width.remark}%`}}/>
          </div>

          <div className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`pt-1 flex-center`} style={{width:`${width.no + width.item_kode + width.specification}%`}}>
              PPN 11%（pajak）
            </div>
            <div className={`pt-1`} style={{width:`${width.stn}%`}}/>
            <div className={`pt-1`} style={{width:`${width.price}%`}}/>
            <div className={`pt-1 flex justify-end`} style={{width:`${width.amount}%`}}>
              {formatAmount(hd_values.tax)}
            </div>
            <div className={`flex-center`} style={{width:`${width.remark}%`}}/>
          </div>

          <div className="flex border-solid border-t-0 border-b-2 border divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`pt-1 flex-center`} style={{width:`${width.no + width.item_kode + width.specification}%`}}>
              TOTAL
            </div>
            <div className={`pt-1`} style={{width:`${width.stn}%`}}/>
            <div className={`pt-1`} style={{width:`${width.price}%`}}/>
            <div className={`pt-1 flex justify-end`} style={{width:`${width.amount}%`}}>
              {formatAmount(hd_values.total)}
            </div>
            <div className={`flex-center`} style={{width:`${width.remark}%`}}/>
          </div>

          <div className="text-[12px] flex flex-col mt-2">
            <span className="leading-5">
              PAYMENT CONDITION : ONE MONTH (45 DAYS AFTER INVOICE )
            </span>
          </div>

          {/* 簽名區域要在最後一頁的最下方 */}
          <div className="absolute bottom-[0px] text-[12px] w-full">

            <div className="flex items-start w-full justify-between mb-6">
              <div className="flex justify-center items-start flex-col w-[52%]" />
              <div className="flex justify-center items-start flex-col w-[38%] text-[12px] border-solid border-[1px]" />
            </div>

            <div className="flex items-start w-full justify-between">

              <div className={`flex justify-center items-start flex-col text-[12px]`}  style={{width:`${width.no + width.item_kode + width.specification}%`}}>
                <div className="flex justify-start items-center w-full bg-[#d8d8d8] divide-x divide-solid divide-y-0 border-t-2 border-b-2 border-solid border-x-2" >
                  <div className={`flex-center w-[7.692%] text-[12px] h-6`}>NO</div>
                  <div className={`flex-center w-[34.615%] text-[12px] h-6`}>OLD PRICE</div>
                  <div className={`flex-center w-[21.154%] text-[12px] h-6`}>NEW PRICE</div>
                  <div className={`flex-center w-[21.154%] text-[12px] h-6`}>STATUS</div>
                  <div className={`flex-center w-[15.385%] text-[12px] h-6`}>%</div>
                </div>

                {/* 這裡之後要把過去歷史價格的陣列用map跑回圈呈現出來 */}

                <div className="flex-center w-full divide-x divide-solid divide-y-0 border-t-0 border-y-[1px] border-solid border-x-[1px] ">
                  <div className="flex-center w-[7.692%] text-[12px] h-6">1</div>
                  <div className="flex-center  w-[34.615%] text-[12px] h-6">24,805</div>
                  <div className="flex-center w-[21.154%] text-[12px] h-6">25,434</div>
                  <div className="flex-center w-[21.154%] text-[12px] h-6">YES</div>
                  <div className="flex-center w-[15.385%] text-[12px] h-6">↑3%</div>
                </div>

                <div className="flex-center w-full divide-x divide-solid divide-y-0 border-t-0 border-y-[1px] border-solid border-x-[1px] ">
                  <div className="flex-center w-[7.692%] text-[12px] h-6">2</div>
                  <div className="flex-center  w-[34.615%] text-[12px] h-6">24,805</div>
                  <div className="flex-center w-[21.154%] text-[12px] h-6">25,434</div>
                  <div className="flex-center w-[21.154%] text-[12px] h-6">YES</div>
                  <div className="flex-center w-[15.385%] text-[12px] h-6">↑3%</div>
                </div>
              </div>

              {/* 這裡之後要把過去歷史價格的陣列用map跑回圈呈現出來 */}

              <div className="flex justify-center items-start flex-col w-[38%] text-[12px] border border-solid ">

                <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[1px] border-solid border-x-[1px] ">
                  <div className="flex-center w-[26.3158%] text-[12px] h-6">APPROVAL,</div>
                  <div className="flex-center  w-[39.4737%] text-[12px] h-6">CHECKED,</div>
                  <div className="flex-center w-[34.2105%] text-[12px] h-6">PREPARED,</div>
                </div>

                <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[1px] border-solid border-x-[1px] ">
                  <div className="flex-center w-[26.3158%] text-[12px] h-[80px]"></div>
                  <div className="flex-center  w-[19.7368%] text-[12px] h-[80px]"></div>
                  <div className="flex-center  w-[19.7368%] text-[12px] h-[80px]"></div>
                  <div className="flex-center w-[34.2105%] text-[12px] h-[80px]"></div>
                </div>

                <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[1px] border-solid border-x-[1px] ">
                  <div className="flex-center w-[26.3158%] text-[10px] h-6 ">
                    <div className="scale-[.8]">Mr.Zack</div>
                  </div>
                  <div className="flex-center  w-[19.7368%] text-[10px] h-6 ">
                    <div className="scale-[.8]">Mr.Philbert</div>
                  </div>
                  <div className="flex-center  w-[19.7368%] text-[10px] h-6 ">
                    <div className="scale-[.8]">Mr.Budi</div>
                  </div>
                  <div className="flex-center w-[34.2105%] text-[10px] h-6 ">
                    <div className="scale-[.8]">Tria</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Container;
