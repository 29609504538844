/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Space, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// util component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { IconPen, IconGarbage, IconCheck, IconClose, IconFile } from "@/components/Icon/Action";
// component
import ModalConintaer from "./ModalContainer";
import FileModal from "./ModalContainer/FileModal";
import Preview from "./Preview";
// util
import { multipleIDtoName } from "@/util/format";
// api
import useAPI from "@/hooks/useAPI";
import { getQmsSipHd, deleteQmsSipHd } from "@/service/apis/QMS/QMSManage";

const HD = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, c_lightData, set_c_lightData, s_arrMap } = props;
  // 要被編輯的表頭資料
  const [s_editData, set_s_editData] = useState({});
  // 表格資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 是否顯示新建、修改的跳顯開關
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // 是否顯示上傳檔案的跳顯
  const [s_showFileModal, set_s_showFileModal] = useState(false);

  // useAPI 取得表頭資料
  const call_getQmsSipHd = useAPI(getQmsSipHd);
  // useAPI 刪除表頭資料
  const call_deleteQmsSipHd = useAPI(deleteQmsSipHd);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => set_s_isShowModal(true),
  }));

  // 搜尋
  const onSearch = () => call_getQmsSipHd.request(s_searchData);

  // 刪除表頭資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteQmsSipHd.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  const tableColumns = [
    {
      title: "sip文編",
      dataIndex: "sipID",
      width: "20%",
    },
    {
      title: "sip版次",
      dataIndex: "version",
      align: "center",
      width: "5%",
    },
    {
      title: t("util.PN"), // 料號
      dataIndex: "PN",
      width: "15%",
    },
    {
      title: "類型",
      dataIndex: "siptype",
      width: "8%",
      align: "center",
      // 等i18N寫法
      render: (text) => {
        const map = {
          "FAI": "首件檢驗",
          "IQC": "進料檢驗",
          "IPQC": "製中檢驗",
          "ESPC": "設備檢測",
          "FQC": "終品檢驗",
          "OQC": "出貨檢驗",
        };
        return map[text];
      },
    },
    {
      title: "控點",
      dataIndex: "ctrlspot",
      width: "8%",
      align: "center",
      render: (text) => {
        const map = {
          pf: "製程比對",
          ws: "工站比對",
          wl: "工線比對",
          wp: "全品比對",
        };
        return map[text];
      },
    },
    {
      title: "控點編號",
      dataIndex: "spotID",
      render: (text, rowData, _) => {
        return multipleIDtoName(s_arrMap[rowData.ctrlspot], text, "value", "label");
      },
    },
    {
      title: t("util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      width: "8%",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "工程圖檔",
      dataIndex: "drawing",
      width: "8%",
      align: "center",
      render: (text) => text && <Preview url={text} />,
    },
    {
      title: t("util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconFile
            onClick={(e) => {
              set_s_showFileModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconPen
            onClick={(e) => {
              set_s_isShowModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 第一次渲染取的 SIP 資料
  useEffect(() => {
    call_getQmsSipHd.request();
  }, []);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(
        data?.map((x, i) => ({
          ...x,
          index: i,
          key: i,
        }))
      );
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getQmsSipHd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      // delete之後 重call表格資料
      onSearch();
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteQmsSipHd.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getQmsSipHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.sipID === c_lightData.sipID ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}標準檢驗`
            : `${t("util.add")}標準檢驗`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_arrMap={s_arrMap}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      <CustomModal
        title="上傳檔案"
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <FileModal s_editData={s_editData} set_s_showFileModal={set_s_showFileModal} />
      </CustomModal>
    </>
  );
});

export default HD;
