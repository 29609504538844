/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, theme } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../../index";
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getApTB } from "@/service/apis/PMS/ap";
// util
import { formatAmount } from "@/util/format";
const { useToken } = theme;
const TB = () => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  const { token } = useToken();
  // useAPI 取得表身
  const call_getApTB = useAPI(getApTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getApTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getApTB;
    if (status === "suc") {
      message.success(msg);
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getApTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 報價單號
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "8%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.ord.payment"), // 負責業務
      dataIndex: "payment",
      align: "center",
      width: "8%",
    },
    {
      title: t("PMS.util.suppalias"), // 客戶簡稱
      dataIndex: "suppalias",
      align: "center",
    },
    {
      title: t("PMS.ap-manage.subtotal"), // 金額
      dataIndex: "itemsum",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
  ];

  return (
    <div className="flex-1">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
    </div>
  );
};

export default TB;
