import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Upload, Modal, Typography, Divider, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// API
import { uploadMold, updateMold } from "@/service/apis/ADM/mold";
import useAPI from "@/hooks/useAPI";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileModalContainer = ({ s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  // 上傳圖片
  const call_uploadMold = useAPI(uploadMold);
  // 更新資料
  const call_updateMold = useAPI(updateMold);
  // 是否開啟預覽的開關
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [s_tableData, set_s_tableData] = useState([]);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  // 上傳檔案時執行
  const handleChange = (props) => {
    const { file, doctype } = props;
    // 刪除
    if (file.status === "removed") {
      call_updateMold.request({ ...s_editData, [doctype]: "" });
      return;
    }
    // 新建
    let formData = new FormData();
    console.log("file = ", file);
    console.log("file = ", file);
    formData.append("file", file);
    formData.append("assetID", s_editData.assetID);
    formData.append("doctype", doctype);
    call_uploadMold.request(formData);
  };

  // const handleDelete = (props) => {
  //   console.log("props = ", props);
  //   // call_updateMold.request({ ...s_editData, [doctype]: "" });
  // };

  const handleCancel = () => {
    setPreviewOpen(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };
  const types = ["draw", "pdtpic", "malepic", "femalepic", "whloepic"];

  useEffect(() => {
    const _data = types.map((item) => {
      const fileList = s_editData[item].length > 0 ? [{ url: s_editData[item] }] : [];
      return {
        doctype: item,
        fileList: fileList,
      };
    });

    console.log("_data = ", _data);
    set_s_tableData(_data);
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_uploadMold;
    if (status === "suc") {
      message.success(msg);
      console.log("data = ", data);
      // set_s_isShowModal(false);
      const _tableData = s_tableData.map((item) => {
        console.log(" item[item.doctype] = ", data[item.doctype]);
        const fileList = data[item.doctype].length > 0 ? [{ url: data[item.doctype] }] : [];
        return {
          ...item,
          fileList: fileList,
        };
      });
      set_s_tableData(_tableData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_uploadMold.status]);

  useEffect(() => {
    const { status, data, msg } = call_updateMold;
    if (status === "suc") {
      console.log("data = ", data);
      const _tableData = s_tableData.map((item) => {
        console.log(" item[item.doctype] = ", data[item.doctype]);
        const fileList = data[item.doctype].length > 0 ? [{ url: data[item.doctype] }] : [];
        return {
          ...item,
          fileList: fileList,
        };
      });
      set_s_tableData(_tableData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_updateMold.status]);

  return (
    <div className="flex justify-around gap-2">
      {s_tableData.map((item) => {
        return (
          <section key={item.doctype} className="flex flex-col gap-2">
            <Typography.Title level={3}>{t(`ADM.mold.${item.doctype}`)}</Typography.Title>
            <Upload
              key={item.doctype}
              className="flex"
              listType="picture-card"
              beforeUpload={() => false}
              fileList={item.fileList}
              onPreview={handlePreview}
              // onRemove={handleDelete}
              onChange={(props) => handleChange({ ...props, doctype: item.doctype })}
              // onChange={handleChange}
            >
              {item.fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {/* <Divider className="my-0" /> */}
          </section>
        );
      })}

      <Modal
        open={previewOpen}
        width="70%"
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};

export default FileModalContainer;
