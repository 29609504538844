/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { message, Space, Modal, Grid, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteStrikeTB } from "@/service/apis/PMS/strike";

const { Title } = Typography;

const TbContainer = ({ call_getStrikeTB, s_tbData, s_disabled, hd_values }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteStrikeTB = useAPI(deleteStrikeTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });

  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("PMS.ap-manage.strike_tb_delete_confirm"), // 沖帳明細刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的明細資料無誤，再進行刪除",
      content: t("PMS.util.del_tb_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteStrikeTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteStrikeTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getStrikeTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteStrikeTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.ap-manage.paytype"), // 付款方式
      dataIndex: "paytype",
      align: "center",
      width: "20%",
      render: (textarr, _, __) => {
        const map = {
          1: t("OMS.ap-manage.rcvtype_1"),
          2: t("OMS.ap-manage.rcvtype_2"),
          3: t("OMS.ap-manage.rcvtype_3"),
          4: t("OMS.ap-manage.rcvtype_4"),
        };

        return textarr?.map((item) => map[item])?.join(" , ") || "";
      },
    },
    {
      title: t("PMS.ap-manage.paytotal"), // 付款金額
      dataIndex: "paytotal",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.ap-manage.payT"), // 付款時間
      dataIndex: "payT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("PMS.ap-manage.note"), // 沖帳註記
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 沖帳單明細 */}
        <TbTitle>{t("PMS.ap-manage.strike_tb")}</TbTitle>
        {/* 新建的 button */}
        <CreateButton
          disabled={s_disabled.createBtn}
          onClick={() => set_s_tbModal({ show: true, data: {} })}
        />
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      {/* 新建、編輯表身的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={type === "edit" ? "編輯沖帳明細" : "新增沖帳明細"}
        title={t(`util.${type === "edit" ? "edit" : "add"}`) + t("PMS.ap-manage.strike_tb")}
      >
        <TbModal
          call_getStrikeTB={call_getStrikeTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
