/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import {
  Divider,
  Button,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Form,
  InputNumber,
  message,
  Tooltip,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// content
import { ERPContext } from "@/components/Context/SystemContext";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import TbTitle from "@/components/ERP/OMS/TbTitle";
import { CancelButton, QuoteButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { getTakeOrd, takeOrd } from "@/service/apis/OMS/Outstock";
const { Text } = Typography;

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(values.qty ?? 0, record.itemUUID);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     // message: `${title} is required.`,
        //   },
        // ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "100%" }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const TakeOrdModal = ({ hd_values, set_is_showOrd, call_getOutstockHD, call_getOutstockTB }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // useAPI 取得上位資料(銷貨單)的表身
  const call_getTakeOrd = useAPI(getTakeOrd);

  const call_takeOrd = useAPI(takeOrd);

  const { c_PNs } = useContext(ERPContext);

  // 採購單明細資料
  const [s_ordData, set_s_ordData] = useState([]);

  // 用來存發貨單明細的原始資料，計算剩餘數量使用
  const [s_ori_tableData, set_s_ori_tableData] = useState([]);

  // 準備建立的進料明細
  const [s_outstockData, set_s_outstockData] = useState([]);

  const map = {
    1410: "1310",
    1420: "1320",
  };

  const [s_searchValue, set_s_searchValue] = useState({
    custID: hd_values.custID,
    opcode: map[hd_values.opcode],
    PN: undefined,
    N: "",
    dealN: "",
  });

  const onFinish = async (values) => {
    // console.log("values = ", values);
    // return;
    call_getTakeOrd.request(values);
  };

  const ord_tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    // {
    //   title: '單別',
    //   dataIndex: 'opcode',
    //   align: 'center',
    //   width: '15%',
    // },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "20%",
    },
    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
      // width: "20%",
    },
    {
      title: t("OMS.util.dealN"), // 客戶料號
      dataIndex: "dealN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.balqty"), // 剩餘數量
      dataIndex: "balqty",
      align: "center",
      width: "15%",
    },
    {
      title: <span style={{ color: "#FF850A" }}>{t("OMS.util.take") + t("OMS.outstock.qty")}</span>, // 引用數量
      dataIndex: "qty",
      editable: true,
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.outstock.choose"), // 選擇
      dataIndex: "render",
      align: "center",
      width: "10%",
      render: (_, rowData, __) => {
        console.log("rowData = ", rowData);

        return (
          // <Tooltip title={rowData.balqty === 0 ? t("OMS.outstock.choose_err") : ""}>
          <Checkbox
            disabled={rowData.balqty === 0}
            checked={rowData.isUse}
            onChange={(e) => {
              set_s_ordData(
                s_ordData.map((item) =>
                  item.itemUUID === rowData.itemUUID ? { ...item, isUse: e.target.checked } : item
                )
              );
            }}
          />
          // </Tooltip>
        );
      },
    },
  ];

  const outstock_tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.dealN"), // 客戶料號
      dataIndex: "dealN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.outstock.qty"), // 數量
      dataIndex: "qty",
      align: "center",
      width: "15%",
    },
  ];

  const handleSave = (qty = 0, itemUUID) => {
    const target_obj = s_ordData.find((item) => item.itemUUID === itemUUID);
    if (!target_obj.isUse) {
      // message.error("請先選擇引用再輸入引用數量");
      message.error(t("OMS.outstock.throw_save_msg"));
      return;
    }
    if (isNaN(qty) || parseInt(qty) < 0) {
      //拋轉數量不可小於0
      message.error(t("OMS.outstock.throwQty_msg"));
      return;
    }
    console.log(qty);
    const tmp = s_ordData.reduce((acc, item) => {
      if (itemUUID === item.itemUUID) {
        const oriData = s_ori_tableData.find((ori_item) => ori_item.itemUUID === item.itemUUID);
        acc.push({
          ...oriData,
          editable: true,
          isUse: item.isUse,
          // 剩餘數量 = 廠商確認數量(ori) + 退貨數量 - 已拋數量(ori) - 預計拋轉數量
          balqty: parseInt(oriData?.balqty) - parseInt(qty),
          // 預計拋轉數量
          qty: parseInt(qty),
        });
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
    set_s_ordData(tmp);
  };

  const useDetail = () => {
    // 取出有勾選[選擇]的明細
    const tmpArray = s_ordData.filter((item) => item.isUse === true) ?? [];
    console.log("tmpArray = ", tmpArray);
    // 沒有勾選[選擇]
    if (tmpArray.length === 0) return;
    // 取出料號不重複的明細
    const newArray = tmpArray.filter((item) => {
      const index = s_outstockData?.findIndex((item1) => item1.itemUUID === item.itemUUID);
      console.log(index);
      // if(index > 0) return item
      return index !== -1;
    });

    if (newArray.length > 0) {
      // message.error("不可引用相同明細");
      message.error(t("OMS.outstock.useDetail_msg"));
      return;
    }

    set_s_outstockData(tmpArray.concat(s_outstockData));
  };

  const onSubmit = () => {
    const tmpData = s_outstockData.map((item) => {
      return {
        ...item,
        HdOpUUID: hd_values.opUUID,
      };
    });
    console.log("tmpData = ", tmpData);
    call_takeOrd.request(tmpData);
  };

  // 只能引用相同opcode ex:國內進料只能引用國內採購
  useEffect(() => {
    form.setFieldsValue({
      opcode: map[hd_values.opcode],
    });
  }, []);

  // useAPI 取得上位資料(採購單)的表身
  useEffect(() => {
    const { status, data, msg } = call_getTakeOrd;
    if (status === "suc") {
      message.success(msg);
      console.log("call_getTakeOrd.data = ", data);
      // 計算剩餘數量使用
      set_s_ori_tableData(data);
      // 拋轉數量預設剩餘數量
      const ordData = data?.map((item) => ({ ...item, qty: item.balqty, isUse: false }));
      // console.log("ordData = ", ordData);
      set_s_ordData(ordData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getTakeOrd.status]);

  // useAPI 引用
  useEffect(() => {
    if (call_takeOrd.status === "suc") {
      message.success(call_takeOrd.msg);
      // 引用完成，關閉引用的跳顯
      set_is_showOrd(false);
      // 更新表頭、表身資料
      call_getOutstockHD.request({ opUUID: hd_values.opUUID });
      call_getOutstockTB.request({ opUUID: hd_values.opUUID });
    }
    if (call_takeOrd.status === "err") {
      message.error(t(`errorCode.${call_takeOrd.msg}`));
      // 引用完成，關閉引用的跳顯
      set_is_showOrd(false);
      // 更新表頭、表身資料
      call_getOutstockHD.request({ opUUID: hd_values.opUUID });
      call_getOutstockTB.request({ opUUID: hd_values.opUUID });
    }
  }, [call_takeOrd.status]);

  const columns = ord_tableColumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Wrapper>
      {/* 搜尋區域 */}
      <Form name="basic" onFinish={onFinish} form={form}>
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={4}>
            <Form.Item name="opcode" label={<Text disabled>單別</Text>}>
              <Select
                placeholder="請選擇單別"
                disabled
                options={[
                  { label: t("OMS.util.opcode_1310"), value: "1310" }, // 國內訂單
                  { label: t("OMS.util.opcode_1320"), value: "1320" }, // 國外訂單
                ]}
              />
            </Form.Item>
          </Col>

          {/* 單號 */}
          <Col span={6}>
            <Form.Item name="N" label={<Text>{t("OMS.util.N")}</Text>}>
              <Input placeholder={t("util.placeholder_input") + t("OMS.util.N")} />
            </Form.Item>
          </Col>

          {/* 廠商單號 */}
          <Col span={6}>
            <Form.Item name="dealN" label={<Text>{t("OMS.util.dealN")}</Text>}>
              <Input placeholder={t("util.placeholder_input") + t("OMS.util.dealN")} />
            </Form.Item>
          </Col>

          {/* 料號 */}
          <Col span={6}>
            <Form.Item label={<Text>{t("OMS.util.PN")}</Text>} name="PN">
              <Select
                showSearch
                placeholder={t("util.placeholder_select") + t("OMS.util.PN")}
                options={c_PNs.options}
              />
            </Form.Item>
          </Col>

          <Col span={2} className="flex justify-end">
            <Button
              type="primary"
              // onClick={() => call_getTakeOrd.request(s_searchValue)}
              htmlType="submit"
              icon={<SearchOutlined />}
            >
              {/* 搜尋 */}
              {t("util.search")}
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider />

      <CustomTable
        components={components}
        rowClassName={() => "editable-row"}
        columns={columns}
        rowNumber={6}
        dataSource={s_ordData?.map((item, index) => ({ ...item, key: index })) ?? []}
      />

      <div className="flex justify-between mt-8 mb-2">
        {/* <TbTitle>進料明細</TbTitle> */}
        <TbTitle>{t("OMS.ord.tb")}</TbTitle>
        <div>
          <Button className="blue-outline mr-5px rounded-5px" onClick={useDetail}>
            {/* 引用 */}
            {t("OMS.util.take")}
          </Button>
        </div>
      </div>

      <CustomTable
        columns={outstock_tableColumns}
        rowNumber={6}
        dataSource={s_outstockData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <div className="flex justify-end pb-3 gap-3">
        {/* 取消 */}
        <CancelButton onClick={() => set_is_showOrd(false)} />
        <QuoteButton type="primary" onClick={onSubmit} />
      </div>
    </Wrapper>
  );
};

export default TakeOrdModal;
