import React from 'react'

const SignOffState = (props) => {
  const { flowF, progress, className } = props

  const StateStyle = {
    basic: "rounded-lg px-8 py-1 border-none",
    agree: "bg-[#67ffc280] text-[#489F88]",
    reject: "bg-[#F2BEB6] text-[#E47867]",
    cancel: "bg-[#FCE8C0] text-[#E39228]",
  };
  return (
    <span
      className={`${StateStyle[flowF]} ${StateStyle.basic} ${className} `}>
      {progress}
    </span>
  )
}

export default SignOffState