
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Input, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import CustomTabs from "@/components/CustomAntd/BPM/CustomTabs";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomRangePicker from "@/components/BPM/CustomRangePicker";
import CustomSearchButton from "@/components/BPM/CustomSearchButton";
import CustomSelect from "@/components/BPM/CustomSelect";
import { EditButton } from "@/components/BPM/Button";
import { AddButton } from "@/components/PDS/Buttons";
// component
// import EditPage from "../EditPanel/index";
import FinishDoc from "./FinishDoc";
import PendingApproval from "./PendingApproval";
import Accumulation from "./Accumulation";
// API
import useAPI from "@/hooks/useAPI";
import { getBPM } from "@/service/apis/BPM/Dsevent";

const INIT_SEARCHCONFIG = {
  startDate: dayjs().startOf("month"), // 開始日期
  endDate: dayjs().endOf("month"), // 結束日期
  fpc: "open",
  searchFlag: undefined, // 要搜尋的欄位名稱
  searchValue: undefined, // 要搜尋的欄位資料
};



const EventDashboard = (props) => {
  const { c_lightData, set_c_lightData, set_is_showEdit } = props
  const { t } = useTranslation();
  const wrapper_Ref = useRef(null);

  // useAPI 取得簽核事件
  const call_getBPM = useAPI(getBPM);

  const [scrollY, set_scrollY] = useState(0);
  // 搜尋的欄位
  const [s_searchConfig, set_s_searchConfig] = useState(INIT_SEARCHCONFIG);
  // 重後端拿到的所有資料，內容包含(Run10, Run12)
  const [s_dataSource, set_s_dataSource] = useState({});
  // 前端顯示的tableData，不直接用s_searchConfig.Run10是因為[急件]跟[簽核流程]的欄位不同
  const [s_tableData, set_s_tableData] = useState([]);


  // const [s_IsShowAdd, set_s_isShowAdd] = useState(false);
  // 簽核事件的表頭
  const tableColumns = [
    {
      title: t("BPM.util.itemno"), // 單別
      dataIndex: "itemno",
      align: "center",
      width: "7%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("BPM.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "7%",
    },
    {
      title: t("BPM.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "13%",
    },
    {
      title: t("BPM.dsevent.keynote"), // 主旨
      dataIndex: "keynote",
      // align: "center",
    },
    {
      title: "目前進度",
      dataIndex: "flowF",
      align: "center",
      width: "10%",
    },
    {
      title: t("BPM.util.lifeFT"), // 歷程時間
      dataIndex: "flowFT",
      align: "center",
      width: "10%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"), // 歷程時間
      dataIndex: "action",
      width: "5%",
      render: (_, rowData, __) => (
        <div className="flex-center">
          <EditButton
            onClick={(e) => {
              console.log(rowData);
              set_c_lightData(rowData);
              set_is_showEdit(true);
              // 阻止冒泡
              e.stopPropagation()
            }}
          />
        </div>
      ),
    },
  ];
  // 所有簽核流程(包含急件)
  const tabsData = [
    {
      key: "open",
      label: "立案",
    },
    {
      key: "check",
      label: "待查案",
    },
    {
      key: "review",
      label: "待審案",
    },
    {
      key: "approve",
      label: "待核案",
    },
    // {
    //   key: "key4",
    //   label: "指定",
    // },
    {
      key: "isurgent",
      label: "急件",
    },
    // {
    //   key: "Pfc",
    //   label: t("ADM.edm.Pfc"),
    //   children: (
    //     <Pfc
    //       ref={pfcRef}
    //       s_searchData={s_searchData}
    //       set_s_searchData={set_s_searchData}
    //       s_arrMap={s_arrMap}
    //     />
    //   ),
    // },
  ];

  const handleClick = () => {
    // set_s_isShowAdd((previousValue) => !previousValue)
    set_is_showEdit((previousValue) => !previousValue)
    set_c_lightData({})
  };
  useEffect(() => {
    // call_getBPM.request(s_searchConfig);
    call_getBPM.request();
  }, []);

  useEffect(() => {
    let _data = [];
    // console.log("s_searchConfig.fpc = ", s_searchConfig.fpc);
    // 如果是急件的話
    if (s_searchConfig.fpc === "isurgent") {
      _data =
        s_dataSource.Run10?.filter((item) => item.isurgent === true)?.map((item, index) => ({
          ...item,
          key: index + 1,
        })) || [];
    } else {
      _data =
        s_dataSource.Run10?.filter((item) => item.flowF === s_searchConfig.fpc)?.map(
          (item, index) => ({
            ...item,
            key: index + 1,
          })
        ) || [];
    }
    set_s_tableData(_data);
  }, [s_searchConfig.fpc, s_dataSource]);

  // useAPI 取得簽核事件
  useEffect(() => {
    const { data, status, msg } = call_getBPM;
    if (status === "suc") {
      message.success(msg);
      set_s_dataSource(data);
      // const _data = data.Run10?.map((item, index) => ({ ...item, key: index })) || [];
      // set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getBPM.status]);

  // 計算 table 的高度
  useEffect(() => {
    set_scrollY(wrapper_Ref.current?.getBoundingClientRect().height);
  }, [wrapper_Ref.current]);
  return (
    <>
      <div className="flex-[2] flex gap-2 flex-col">
        <CustomTabs
          items={tabsData}
          activeKey={s_searchConfig.fpc}
          onChange={(activeKey) => {
            set_s_searchConfig((prev) => ({
              ...prev,
              fpc: activeKey,
            }));
          }}
          tabBarExtraContent={
            <Space className="">
              <AddButton
                type="primary"
                onClick={handleClick}
              />
              <CustomRangePicker />
              <Input.Group className="w-full flex" compact>
                <CustomSelect
                  className="w-[150px]"
                  placeholder="搜尋欄位"
                />

                <CustomSelect
                  showSearch
                  className="w-[200px]"
                />
                <CustomSearchButton onClick={() => alert("wait")} />
              </Input.Group>
            </Space>
          }
        />
        <div
          ref={wrapper_Ref}
          className="flex-[2] overflow-hidden shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md"
        >
          <CustomTable
            columns={tableColumns}
            scroll={{ y: scrollY }}
            dataSource={s_tableData}
            pagination={false}
            c_lightData={c_lightData}
            onRow={(record) => {
              return {
                onClick: () =>
                  record.key === c_lightData.key
                    ? set_c_lightData({})
                    : set_c_lightData(record),
              };
            }}
          />
        </div>
        <div className="flex-1 flex gap-5">
          {/* 待簽核文件 */}
          <PendingApproval s_dataSource={s_dataSource} />
          {/* 當月累計 */}
          <Accumulation />
        </div>
      </div>
      <FinishDoc c_lightData={c_lightData} />
    </>
  )
}

export default EventDashboard