import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Input, Button, Typography, Space, InputNumber } from "antd";
import * as XLSX from "xlsx";
//util
import { formatAmount } from '@/util/format' //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButton";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import useAPI from "@/hooks/useAPI";
// import { getDiff } from "@/service/apis/PMS/Pro";

const { Text } = Typography;
const { RangePicker } = DatePicker

//進銷存彙總表
const Psisummary = () => {

  const { c_PNs } = useContext(ERPContext);  //從ERPContext拿到廠商資料

  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料


  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    PN: [], //料號(多選，call api時要把陣列轉成字串
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),   // 異動日期(起)
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),       // 異動日期(迄)
  });

  const tableColumns = [
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
      width: '10%'
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "center",
      width: '7%'
    },
    {
      title: "期初數量",
      dataIndex: "initialqty",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },
    {
      title: "+本期進貨數量",
      dataIndex: "purchaseqty",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },
    {
      title: "-本期銷貨數量",
      dataIndex: "saleqty",
      align: "right",
      width: '7%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "+本期調整數量",
      dataIndex: "adjustqty",
      align: "right",
      width: '7%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "=期末數量",
      dataIndex: "endqty",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },
    {
      title: "期初成本",
      dataIndex: "initialcost",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },
    {
      title: "+本期進貨成本",
      dataIndex: "purchasecost",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },
    {
      title: "-本期銷貨成本",
      dataIndex: "salecost",
      align: "right",
      width: '7%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "+本期調整成本",
      dataIndex: "adjustcost",
      align: "right",
      width: '7%',
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "=期末成本",
      dataIndex: "endcost",
      align: "right",
      width: '7%',
      render: (text) => formatAmount(text)
    },

  ];


  const data = [
    {
      PN: "PN-000-001",
      pdtNM: "品名-000-001",
      unit: "頂",
      initialqty: 0,
      purchaseqty: 100,
      saleqty: 80,
      adjustqty: -5,
      endqty: 15,
      initialcost: 0,
      purchasecost: 10000,
      salecost: 8000,
      adjustcost: 500,
      endcost: 1500,
    },
    {
      PN: "PN-000-002",
      pdtNM: "品名-000-002",
      unit: "件",
      initialqty: 10,
      purchaseqty: 50,
      saleqty: 40,
      adjustqty: -5,
      endqty: 15,
      initialcost: 100,
      purchasecost: 500,
      salecost: 400,
      adjustcost: -50,
      endcost: 150,
    },
    {
      PN: "PN-000-003",
      pdtNM: "品名-000-003",
      unit: "顆",
      initialqty: 80,
      purchaseqty: 80,
      saleqty: 100,
      adjustqty: -10,
      endqty: 50,
      initialcost: 2400,
      purchasecost: 2400,
      salecost: 3000,
      adjustcost: 300,
      endcost: 1500,
    },
    {
      PN: "PN-000-004",
      pdtNM: "品名-000-004",
      unit: "套",
      initialqty: 25,
      purchaseqty: 25,
      saleqty: 30,
      adjustqty: -5,
      endqty: 15,
      initialcost: 250,
      purchasecost: 250,
      salecost: 300,
      adjustcost: 50,
      endcost: 150,
    },
    {
      PN: "PN-000-005",
      pdtNM: "品名-000-005",
      unit: "盒",
      initialqty: 30,
      purchaseqty: 70,
      saleqty: 60,
      adjustqty: -5,
      endqty: 35,
      initialcost: 600,
      purchasecost: 1400,
      salecost: 1200,
      adjustcost: 100,
      endcost: 700,
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = { ...s_searchData, PN: s_searchData.PN.join("|") }; //要將料號陣列轉成字串，用直槓隔開
    console.log("tmp", tmp);
    // call_getDiff.request(tmp);
  }


  //所有資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getDiff;
  //   if (status === "suc") {
  //     console.log("call_getDiff = ", data);
  //     set_s_TableData(data);
  //     message.success(msg);
  //   } else if (status === "err") {
  //     set_s_TableData([]);
  //     message.error(msg);
  //   }
  // }, [call_getDiff.status]);

  //----------------------------EXCEL---------------------------------------------------

  //待完成導出excel功能
  const exportToExcel = () => {

    const excel_out_Data = data.map((item) => {
      //時間格式轉換，數字加上千分位
      return {
        ...item,
        initialqty: formatAmount(item.initialqty),
        purchaseqty: formatAmount(item.purchaseqty),
        saleqty: formatAmount(item.saleqty),
        adjustqty: formatAmount(item.adjustqty),
        endqty: formatAmount(item.endqty),
        initialcost: formatAmount(item.initialcost),
        purchasecost: formatAmount(item.purchasecost),
        salecost: formatAmount(item.salecost),
        adjustcost: formatAmount(item.adjustcost),
        endcost: formatAmount(item.endcost),
        // createT: dayjs(item.startEDT).format("YYYY-MM-DD"),
      }
    })

    // ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excel_out_Data,
      {
        header:
          [
            "PN", "pdtNM", "unit",
            "initialqty", "purchaseqty", "saleqty", "adjustqty", "endqty",
            "initialcost", "purchasecost", "salecost", "adjustcost", "endcost"
          ]
      });
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    // 计算每列的最大宽度
    const colWidths = data[0] && Object.keys(data[0]).map(() => 0);
    data.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        console.log("cellValue", cellValue);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map(w => ({ wch: w + 10 })); // 根据最大长度调整列宽
    ws['!cols'] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }

    const today = dayjs().format("YYYY-MM-DD");

    // 輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `進銷存彙總表${today}.xlsx`);
  }
  //----------------------------EXCEL---------------------------------------------------


  return (
    <div className="flex flex-col flex-1 w-full">
      {/*表頭*/}

      <PageHeader title="進銷存彙總表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">

        {/*導出excel*/}
          <Button
            type="primary"
            onClick={() => exportToExcel()}
          >
            導出excel
          </Button> 
         

        <Text>料號：</Text>
        <Select
          mode='multiple'
          name='PN'
          className="min-w-[500px]"
          showSearch
          placeholder="請填寫料號"
          onChange={(value) => { handle_SearchData_Change("PN", value) }}
          options={c_PNs.options}
        />

        <PNButton />

        {/*異動日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button type="primary"
          onClick={handleClick}
        >
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={data.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};


export default Psisummary
