import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, InputNumber, Dropdown } from "antd";
import { addNotify, modifyNotify } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
export default function ModalConintaer({ s_editData, set_s_isShowModal, s_arrMap }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [s_lastMousePosition, set_s_lastMousePosition] = useState(0);

  const call_addNotify = useAPI(addNotify);
  const call_modifyNotify = useAPI(modifyNotify);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (values.funcode && values.funcode.length > 0) {
      values.funcode = values.funcode.join("|");
    }

    if (type === "create") {
      call_addNotify.request(values);
    }
    if (type === "edit") {
      call_modifyNotify.request(values);
    }
  };

  const items = [
    {
      key: "1",
      label: t("ADM.function-params-manage.1_decimal_place"), //"小數點後1位",
      onClick: () => {
        let formData = form.getFieldsValue();
        let strArr = formData.msgmodel?.split("");
        strArr.splice(s_lastMousePosition, 0, "%.1f");
        formData.msgmodel = strArr.join("");
        form.setFieldsValue(formData);
      },
    },
    {
      key: "2",
      label: t("ADM.function-params-manage.2_decimal_place"), //"小數點後2位",
      onClick: () => {
        let formData = form.getFieldsValue();
        let strArr = formData.msgmodel?.split("");
        strArr.splice(s_lastMousePosition, 0, "%.2f");
        formData.msgmodel = strArr.join("");
        form.setFieldsValue(formData);
      },
    },
  ];

  useEffect(() => {
    if (call_addNotify.status === "suc") {
      message.success(call_addNotify.msg);
      set_s_isShowModal(false);
    } else if (call_addNotify.status === "err") {
      message.error(t(`errorCode.${call_addNotify.msg}`));
    }
  }, [call_addNotify.status]);

  useEffect(() => {
    if (call_modifyNotify.status === "suc") {
      message.success(call_modifyNotify.msg);
      set_s_isShowModal(false);
    } else if (call_modifyNotify.status === "err") {
      message.error(t(`errorCode.${call_modifyNotify.msg}`));
    }
  }, [call_modifyNotify.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 0]}>
        {/* "事件ID" */}
        <Col span={8}>
          <Form.Item
            label={t("ADM.function-params-manage.eventID")}
            rules={[{ required: true }]}
            name="eventID"
          >
            <Input
              name="eventID"
              placeholder={t("util.placeholder_input") + t("ADM.function-params-manage.eventID")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "事件名稱" */}
          <Form.Item
            label={t("ADM.function-params-manage.eventNM")}
            rules={[{ required: true }]}
            name="eventNM"
          >
            <Input
              name="eventNM"
              placeholder={t("util.placeholder_input") + t("ADM.function-params-manage.eventNM")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "提報類別" */}
          <Form.Item
            label={t("ADM.function-params-manage.rptcode")}
            rules={[{ required: true }]}
            name="rptcode"
          >
            <Select
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.rptcode")}
              allowClear
              options={s_arrMap.rptcodeMap.map((item) => {
                return { ...item, label: t(`ADM.function-params-manage.rptcode_${item.value}`) };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "發報系統" */}
          <Form.Item
            label={t("ADM.function-params-manage.pusher")}
            rules={[{ required: true }]}
            name="pusher"
          >
            <Select
              allowClear
              options={s_arrMap.pusherMap}
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.pusher")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "警訊燈號" */}
          <Form.Item
            label={t("ADM.function-params-manage.situF")}
            rules={[{ required: true }]}
            name="situF"
          >
            <Select
              allowClear
              options={s_arrMap.situFMap.map((item) => {
                return { ...item, label: t(`ADM.function-params-manage.situF_${item.value}`) };
              })}
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.situF")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "推播受群" */}
          <Form.Item
            label={t("ADM.function-params-manage.audience")}
            rules={[{ required: true }]}
            name="funcode"
          >
            <Select
              mode="multiple"
              showArrow
              allowClear
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.audience")}
              options={s_arrMap.funcodeMap}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*起報層級*/}
          <Form.Item
            label={t("ADM.function-params-manage.startlvl")}
            rules={[{ required: true }]}
            name="startlvl"
          >
            <Select
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.startlvl")}
              allowClear
              options={s_arrMap.startlvlMap.map((item) => {
                return { ...item, label: t(`ADM.function-params-manage.startlvl_${item.value}`) };
              })}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*推播級數*/}
          <Form.Item
            label={t("ADM.function-params-manage.setlvl")}
            rules={[{ required: true }]}
            name="setlvl"
          >
            <InputNumber
              min={1}
              max={4}
              className="w-full"
              placeholder={t("util.placeholder_input") + t("ADM.function-params-manage.setlvl")}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/*監控程式*/}
          <Form.Item label={t("ADM.function-params-manage.cruisebot")} name="cruisebot">
            <Input
              name="cruisebot"
              placeholder={t("util.placeholder_input") + t("ADM.function-params-manage.cruisebot")}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* 推播訊息模版 */}
          <Form.Item
            label={t("ADM.function-params-manage.msgmodel")}
            name="msgmodel"
            className="mb-1"
          >
            <Input.TextArea
              rows={4}
              onSelect={(e) => set_s_lastMousePosition(e.target.selectionStart)}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              onClick={() => {
                let formData = form.getFieldsValue();
                let strArr = formData.msgmodel?.split("");
                strArr.splice(s_lastMousePosition, 0, "%s");
                formData.msgmodel = strArr.join("");
                form.setFieldsValue(formData);
              }}
            >
              {t("ADM.function-params-manage.add_string")}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                let formData = form.getFieldsValue();
                let strArr = formData.msgmodel?.split("");
                strArr.splice(s_lastMousePosition, 0, "%.f");
                formData.msgmodel = strArr.join("");
                form.setFieldsValue(formData);
              }}
            >
              {t("ADM.function-params-manage.add_number")}
            </Button>
            <Dropdown
              menu={{
                items,
              }}
            >
              <Button type="primary">{t("ADM.function-params-manage.add_point")}</Button>
            </Dropdown>
          </Space>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
