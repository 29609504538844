import React, { useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import useAPI from "@/hooks/useAPI";
import { addQmsSipHdImg } from "@/service/apis/QMS/QMSManage";

const FileModal = ({ s_editData, set_s_showFileModal }) => {
  // 上傳工程圖檔
  const call_addQmsSipHdImg = useAPI(addQmsSipHdImg);

  const props = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    fileList: [],
    onChange(info) {
      console.log("info = ", info);
      // 新建
      let formData = new FormData();
      formData.append("sipID", s_editData.sipID);
      formData.append("file", info.file);
      call_addQmsSipHdImg.request(formData);
      // formData.append("assetID", s_editData.assetID);
      // formData.append("doctype", doctype);
      // call_uploadMold.request(formData);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    const { status, msg } = call_addQmsSipHdImg;
    if (status === "suc") {
      set_s_showFileModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_addQmsSipHdImg.status]);

  return (
    <Upload.Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading company data or
        other banned files.
      </p>
    </Upload.Dragger>
  );
};

export default FileModal;
