import { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, message, Space, Upload, Button } from "antd";
import useAPI from "@/hooks/useAPI";
import { uploadCustRec } from "@/service/apis/ADM/client";

export default function UploadModalContainer({
  set_s_isShowPicModal,
  s_isShowPicModal,
  s_editData,
}) {
  // 上傳圖片API
  const call_uploadCustRec = useAPI(uploadCustRec);
  const [s_file, set_s_file] = useState(null);
  const [fileList, setFileList] = useState([]);
  const { Dragger } = Upload;

  // 處理檔案上傳
  const handleChange = ({ file }) => {
    console.log("=========here test====");
    console.log(file);

    // 透過FormData(物件) - 傳送檔案給後端
    let formData = new FormData();
    formData.append("file", file);
    formData.append("itemUUID", s_editData.itemUUID);
    formData.append("itemno", s_editData.itemno);
    //                         資料庫欄位名稱
    formData.append("doctype", "addfile");

    // set_s_file(formData);
    call_uploadCustRec.request(formData);
  };

  useEffect(() => {
    const { status, msg, data } = call_uploadCustRec;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowPicModal(false);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_uploadCustRec.status]);

  // 用來配置dragger組件的props
  // const props = {
  //   name: "file", // 上傳的檔案名稱
  //   multiple: true, // 是否支持多選檔案
  //   action: "#", // 上傳檔案的 API 端點
  //   // onChange: 文件上傳過程中, 狀態改變時被調用
  //   // onDrop: 使用者拖曳文件至上傳區並放開滑鼠時被調用
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onDrop(e) {
  //     console.log("Dropped files", e.dataTransfer.files);
  //   },
  // };

  return (
    <>
      <Dragger
        name="file"
        maxCount={1}
        // 注意 ：需要beforeUpload來做上傳處理 -- 上傳之前執行的函數, 這裡使用false表示禁止上傳
        beforeUpload={() => false}
        onChange={(props) => handleChange({ ...props })}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        {/* 點擊新增或拖曳檔案到此區塊 */}
        <p className="ant-upload-text">点击新增或拖曳档案到此区块</p>
        <p className="ant-upload-hint">Support for a single upload.</p>
      </Dragger>

      <Space className="flex justify-end" style={{ marginTop: "16px" }}>
        <Button onClick={() => set_s_isShowPicModal(false)}>{"取消"}</Button>
        <Button type="primary" htmlType="submit">
          {"確認"}
        </Button>
      </Space>
    </>
  );
}
