import React, { useContext, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Typography,
  Input,
  InputNumber,
  Divider,
  Button,
  message,
} from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
import { useTranslation } from "react-i18next";
// context
import { MainPageContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { addProADD, updateProADD } from "@/service/apis/PMS/Pro";

const { Text } = Typography;

const AddbModal = ({ s_tbModal, set_s_tbModal }) => {
  const { call_getProADD, hd_values, s_Adds, s_disabled } = useContext(MainPageContext);
  console.log("s_Adds = ", s_Adds);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 新增加扣款項
  const call_addProADD = useAPI(addProADD);
  // useAPI 編輯加扣款項
  const call_updateProADD = useAPI(updateProADD);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    if (type === "create") {
      // step1 call API新建加扣款項
      call_addProADD.request(values);
    }

    if (type === "edit") {
      // step1 call API更新加扣款項
      call_updateProADD.request(values);
    }
  };

  // 改變下拉選單
  const changeAddb = (addb) => {
    form.setFieldsValue({
      statement: addb,
    });
  };

  // 第一次渲染賦值
  useEffect(() => {
    const formValues = {
      ...s_tbModal.data,
      addtype: "addb",
      opUUID: hd_values.opUUID,
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 新增提款註記
  useEffect(() => {
    const { status, msg } = call_addProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addProADD.status]);

  // useAPI 編輯提款註記
  useEffect(() => {
    const { status, msg } = call_updateProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateProADD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="addtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item name="selectstatement">
            <Select
              onChange={changeAddb}
              options={s_Adds.addb}
              placeholder={t("PMS.pro.selectstatement")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* 項目說明 */}
          <Form.Item
            name="statement"
            label={<Text disabled={s_disabled["addb_statement"]}>{t("PMS.pro.statement")}</Text>}
          >
            <Input
              disabled={s_disabled["addb_statement"]}
              placeholder={t("PMS.pro.statement_placeholder")}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          {/* 未稅金額 */}
          <Form.Item
            name="amount"
            label={<Text disabled={s_disabled["addb_amount"]}>{t("PMS.pro.amount")}</Text>}
          >
            <InputNumber
              disabled={s_disabled["addb_amount"]}
              className="w-full"
              placeholder={t("PMS.pro.amount_placeholder")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })}/>
          {/* 確定 */}
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit}/>
        </Col>
      </Row>
    </Form>
  );
};

export default AddbModal;
