/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { message, Space, Modal, Grid, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteInstockTB } from "@/service/apis/PMS/Instock";

const { Title } = Typography;

const TbContainer = ({ call_getInstockTB, hd_values, s_tbData, s_disabled }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteInstockTB = useAPI(deleteInstockTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });

  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("PMS.instock.tb") + t("util.delete") + t("util.ok"), // 進料明細刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的明細資料無誤，再進行刪除",
      content: t("PMS.util.del_tb_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteInstockTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteInstockTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getInstockTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteInstockTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl ? "4%" : "7%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl ? "15%" : "13%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: t("PMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: screens.xxl ? "10%" : "10%",
      render: (text) => {
        return text === "unit" ? t("PMS.util.unit") : t("PMS.util.opunit");
      },
    },
    {
      title: t("PMS.instock.punit"), // "採購單位"
      dataIndex: "punit",
      align: "center",
      width: screens.xxl ? "8%" : "10%",
    },
    {
      title: t("PMS.util.unitprice"), //"採購單價"
      dataIndex: "unitprice",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.indqty"), // "暫收數量",
      dataIndex: "indqty",
      align: "right",
      width: "6%",
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
            color: "#6A68A1",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.indqty"), //"驗收數量",
      dataIndex: "goqty",
      align: "right",
      width: "6%",
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
            color: "#6A68A1",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.instock.rejectqty"), //"驗退數量",
      dataIndex: "rejectqty",
      align: "right",
      width: "6%",
      onCell: (record, rowIndex) => {
        return {
          style: {
            backgroundColor: "#EBEDF9",
            color: "#6A68A1",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: screens.xxl ? "5%" : "7%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 進料明細 */}
        <TbTitle>{t("PMS.instock.tb")}</TbTitle>
        {/* 新建的 button */}
        <CreateButton
          disabled={s_disabled.createBtn}
          onClick={() => set_s_tbModal({ show: true, data: {} })}
        />
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      {/* 新建、編輯表身的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-end mr-9">
            <Title level={4} className="m-0">
              {/* {t(`util.${type === "edit" ? "編輯" : "新增"}`) + 進料明細 */}
              {t(`util.${type === "edit" ? "edit" : "add"}`) + t("PMS.instock.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {/* 單別：{hd_values.opcode} 單號：{hd_values.N} 項序：
                {s_tbModal.data.key + 1} */}
                {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
                ：{hd_values.N} {t("PMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        <TbModal
          call_getInstockTB={call_getInstockTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
