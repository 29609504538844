import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
  TourButton,
} from "@/components/ERP/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import ContactButton from "@/components/ERP/PMS/ContactButton";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../../index";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PageHeader from "@/components/PageHeader";
import dayjs from "dayjs";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const MainPage = () => {
  const { c_state, updateState } = useContext(DataContext);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const { c_supps, c_custs, c_staffs } = useContext(ERPContext);
  const [form] = Form.useForm();
  const { mainPage, Ns } = c_state;
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  const onFinish = async (values) => {};

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);

    form.setFieldsValue({
      suppID: supp_obj.suppID,
      suppalias: supp_obj.suppalias,
    });
  };
  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* 編輯對帳單主檔、新建對帳單主檔 */}
            <PageHeader
              title={editMode ? t("util.edit") + "应付帐款" : t("util.add") + "应付帐款"}
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            {/* <PrintButton onClick={() => alert("wait")} /> */}
            {/* 儲存 */}
            <SaveButton htmlType="submit" />

            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
          </Col>
        </Row>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]}>
          <Col span={6}>
            <Form.Item
              name="N13"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.N13") }}
              label={<Text>{t("OMS.util.N13")}</Text>}
            >
              {/* <Select
                placeholder={t("util.util.placeholder_select") + t("OMS.util.N13")}
                // disabled={s_disabled.opcode}
                options={
                  [
                    // { label: t("PMS.util.opcode_2710"), value: "2710" }, // 对帐单
                  ]
                }
              /> */}
              <Input placeholder={t("PMS.util.placeholder_input") + t("OMS.util.N13")} />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={6}>
            <Form.Item label={<Text disabled>{t("PMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 廠商單號 */}
          <Col span={6}>
            <Form.Item name="dealN" label={<Text>{t("PMS.pro.dealN")}</Text>}>
              <Input
                placeholder={t("PMS.pro.dealN_placeholder")}
                className="border border-[#6A68A1] border-solid"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="payment" label={<Text>{t("OMS.ord.payment")}</Text>}>
              <Input placeholder="请输入付款方式" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={6}>
            {/* 負責採購 */}
            <Form.Item label={<Text>{t("OMS.ord.driID")}</Text>} name="driID">
              <Select
                placeholder={t("PMS.util.placeholder_select") + t("OMS.ord.driID")}
                // disabled={s_disabled.driID}
                showSearch
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            {/* 完成委託日期 */}
            <Form.Item label={<Text>{t("OMS.util.signT")}</Text>} name="signT">
              <DatePicker className="w-full" />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item label={<Text>{t("PMS.util.suppNM")}</Text>} name="suppNM">
              <Select
                showSearch
                placeholder={t("PMS.util.suppID_placeholder")}
                onChange={onSuppChange}
                options={c_supps?.options}
                filterOption={(input, option) => {
                  return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                }}
              />
            </Form.Item>
          </Col>

          {/* 廠商ID */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              name="suppID"
              label={<Text disabled>{t("PMS.util.suppID")}</Text>}
              messageVariables={{ label: t("PMS.util.suppID") }}
            >
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MainPage;
