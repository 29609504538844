import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//-----------------------------------------異常看版----------------------------------------X
export const getAllAbc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/abc/getAllAbc`, {
    params: _params,
  });
};

export const getAbcSelect = () => {
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/abc/getAbcSelect`
  );
};

export const judge = (data) => {
  return instance.post(
    `${apiBasic.conn}://${apiBasic.url}/api/abc/judge`,
    data
  );
};
