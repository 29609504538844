import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";

import { getPost, deletePost } from "@/service/apis/ADM/functionParamsManage";
import { getPush } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalConintaer from "./ModalContainer/index";

const JobTitle = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_pushOptions, set_s_pushOptions] = useState([]);
  const { t } = useTranslation();
  const call_getPost = useAPI(getPost);
  const call_deletePost = useAPI(deletePost);
  const call_getPush = useAPI(getPush);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getPost.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.plant-manage.postID"), //"職稱ID",
      dataIndex: "postID",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.name"), //"職稱名稱",
      dataIndex: "postNM",
      width: "15%",
    },
    {
      title: t("ADM.plant-manage.level"), //"推播層級",
      dataIndex: "level",
      render: (text) => s_pushOptions.filter((x) => x.value == text)[0]?.label,
      align: "center",
      width: "10%",
    },
    {
      title: "",
      dataIndex: "",
    },
    {
      title: t("util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "10%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deletePost.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    call_getPush.request();
  }, []);

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getPost.status === "suc") {
      message.success(call_getPost.msg);
      set_s_tableData(
        call_getPost.data.map((x, i) => ({
          ...x,
          key: i,
          level: x.level === 0 ? undefined : x.level,
        }))
      );
    } else if (call_getPost.status === "err") {
      message.error(t(`errorCode.${call_getPost.msg}`));
      set_s_tableData([]);
    }
  }, [call_getPost.status]);

  useEffect(() => {
    if (call_deletePost.status === "suc") {
      message.success(call_deletePost.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deletePost.status === "err") {
      message.error(t(`errorCode.${call_deletePost.msg}`));
    }
  }, [call_deletePost.status]);

  useEffect(() => {
    if (call_getPush.status === "suc") {
      set_s_pushOptions(
        call_getPush.data.map((x) => ({
          label: x.lvlNM,
          value: x.lvlcode,
        }))
      );
    } else if (call_getPush.status === "err") {
      // message.error(call_getPush.msg);
      set_s_pushOptions([]);
    }
  }, [call_getPush.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getPost.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.plant-manage.tabPost")}`
            : `${t("util.add")}${t("ADM.plant-manage.tabPost")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_pushOptions={s_pushOptions}
        />
      </CustomModal>
    </>
  );
});

export default JobTitle;
