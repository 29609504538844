/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

//component
import EditPage from "./EditPanel/index";
import EventDashboard from "./EventDashboard/index";


const Event = () => {
  // 目前選中 or 要編輯的簽核事件
  const [c_lightData, set_c_lightData] = useState({});
  // 是否開啟編輯畫面的開關
  const [is_showEdit, set_is_showEdit] = useState(false);
  console.log('c_lightData = ', c_lightData);
  return (
    <div className="h-full flex gap-3">
      {!is_showEdit && (
        <>
          <EventDashboard
            c_lightData={c_lightData}
            set_c_lightData={set_c_lightData}
            set_is_showEdit={set_is_showEdit}
          />
        </>
      )}

      {/* 編輯簽核單 */}
      {is_showEdit &&
        <EditPage
          set_is_showEdit={set_is_showEdit}
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
        />}
    </div>
  );
};

export default Event;
