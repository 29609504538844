import { Button, Col, Divider, Form, Input, InputNumber, Row, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { addCorp, modifyCorp } from "@/service/apis/ADM/plantManage";

import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();

  const call_addCorp = useAPI(addCorp);
  const call_modifyCorp = useAPI(modifyCorp);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addCorp.request(values);
    }
    if (type === "edit") {
      call_modifyCorp.request(values);
    }
  };

  useEffect(() => {
    if (call_addCorp.status === "suc") {
      message.success(call_addCorp.msg);
      set_s_isShowModal(false);
    } else if (call_addCorp.status === "err") {
      message.error(t(`errorCode.${call_addCorp.msg}`));
    }
  }, [call_addCorp.status]);

  useEffect(() => {
    if (call_modifyCorp.status === "suc") {
      message.success(call_modifyCorp.msg);
      set_s_isShowModal(false);
    } else if (call_modifyCorp.status === "err") {
      message.error(t(`errorCode.${call_modifyCorp.msg}`));
    }
  }, [call_modifyCorp.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        {/* "廠別" */}
        <Col span={6}>
          <Form.Item
            label={t("ADM.plant-manage.plantID")}
            name="plantID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={6}>
          {/* "名稱" */}
          <Form.Item label={t("util.name")} name="plantNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={23} />
        <Col span={6}>
          <Form.Item label="公司全名" name="corpNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="公司简称" name="corpalias">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="公司住址" name="addr">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="公司电话" name="tel">
            <Input />
          </Form.Item>
        </Col>
        {/* <Col span={8}> */}
        {/* "一級推播時距(分)" */}
        {/* <Form.Item label={t("ADM.plant-manage.push1")} name="push1" rules={[{ required: true }]}>
            <InputNumber
              placeholder={t("util.placeholder_input") + t("ADM.plant-manage.push1")}
              min={0}
              className="w-full"
            />
          </Form.Item> */}
        {/* </Col> */}
        {/* <Col span={8}> */}
        {/* "二級推播時距(分)" */}
        {/* <Form.Item label={t("ADM.plant-manage.push2")} name="push2" rules={[{ required: true }]}>
            <InputNumber
              placeholder={t("util.placeholder_input") + t("ADM.plant-manage.push2")}
              min={0}
              className="w-full"
            />
          </Form.Item> */}
        {/* </Col> */}
        {/* <Col span={8}> */}
        {/* "三級推播時距(分)" */}
        {/* <Form.Item label={t("ADM.plant-manage.push3")} name="push3" rules={[{ required: true }]}>
            <InputNumber
              placeholder={t("util.placeholder_input") + t("ADM.plant-manage.push3")}
              min={0}
              className="w-full"
            />
          </Form.Item> */}
        {/* </Col> */}
        <Col span={24}>
          <Divider className="py-2" />
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
