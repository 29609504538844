import styled from "styled-components";
import { Select } from "antd";
const StyleSelect = styled(Select)`
  .ant-select-focused,
  .ant-select-focused .ant-select-selector,
  .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }
`;

const CustomSelect = ({ children, ...props }) => {
  return (
    <StyleSelect className="w-[150px]" {...props}>
      {children}
    </StyleSelect>
  );
};

export default CustomSelect;
