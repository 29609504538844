import React, { useState } from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DateRangePicker = ({ s_searchData, handle_SearchData_Change }) => {
  const [dateMon, setDateMon] = useState("date");
  // 狀態切換
  const handleChange = (value) => {
    // setDateMon(!dateMon);
    setDateMon(value);
  };

  const dateMonth = [
    { label: "日期", value: "date" },
    { label: "月份", value: "month" },
  ];
  console.log("dateMon", dateMon);

  return (
    <>
      <Select
        className="min-w-[200px]"
        name="dateMonth"
        placeholder={"日期"}
        onChange={(value) => {
          handleChange(value);

          if (value === "month") {
            handle_SearchData_Change("time", [
              dayjs().startOf("month").format("YYYY-MM-DD"),
              dayjs().endOf("month").format("YYYY-MM-DD"),
            ]);
            return;
          }
          handle_SearchData_Change("time", [
            dayjs().format("YYYY-MM-DD"),
            dayjs().endOf("month").format("YYYY-MM-DD"),
          ]);
        }}
        options={dateMonth}
      />
      <RangePicker
        key={dateMon === "month" ? "month" : "time"}
        picker={dateMon === "month" ? "month" : "date"}
        allowClear={false}
        value={[
          s_searchData.startDate && dayjs(s_searchData.startDate),
          s_searchData.endDate && dayjs(s_searchData.endDate),
        ]}
        // onChange={(_, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        onChange={(_, dateStrings) => {
          handle_SearchData_Change("time", dateStrings);
        }}
      />
      {/* {dateMon === "date" ? (
        <RangePicker
          key="time"
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(_, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />
      ) : (
        <RangePicker
          picker="month"
          key="month"
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(_, dateStrings) => handle_SearchData_Change("month", dateStrings)}
        />
      )} */}
    </>
  );
};

export default DateRangePicker;
