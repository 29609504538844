/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { message, Space, Modal, Grid, theme } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteReturnTB } from "@/service/apis/PMS/Return";
const { useToken } = theme;
const TbContainer = ({ call_getReturnTB, hd_values, s_tbData, s_disabled }) => {
  const { t } = useTranslation();
  const { token } = useToken();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteReturnTB = useAPI(deleteReturnTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });

  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("PMS.return.tb") + t("util.delete") + t("util.ok"), // 進料退出明細刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的明細資料無誤，再進行刪除",
      content: t("PMS.util.del_tb_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteReturnTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteReturnTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getReturnTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteReturnTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: screens.xxl === true ? "4%" : "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: screens.xxl === true ? "15%" : "15%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("PMS.util.utype"), // 單位類別
      dataIndex: "utype",
      align: "center",
      width: screens.xxl === true ? "7%" : "7%",
      render: (text) =>
        text === "unit" ? t("PMS.util.unit") : text === "opunit" ? t("PMS.util.opunit") : "",
    },
    {
      title: t("PMS.util.punit"), // 使用單位
      dataIndex: "punit",
      align: "center",
      width: screens.xxl === true ? "7%" : "7%",
    },
    {
      title: t("PMS.util.unitprice"), // 採購單價
      dataIndex: "unitprice",
      align: "right",
      width: screens.xxl === true ? "6%" : "6%",
    },
    {
      title: t("PMS.return.rtnqty"), // 退回數量
      dataIndex: "rtnqty",
      align: "right",
      width: screens.xxl === true ? "6%" : "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.return.inbatchno"), // 進料批號
      dataIndex: "inbatchno",
      align: "center",
      width: screens.xxl === true ? "10%" : "10%",
    },
    {
      title: t("PMS.util.N24"), // 進料單號
      dataIndex: "N24",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "7%" : "7%",
    },
    {
      title: t("PMS.util.N23"), // 採購單號
      dataIndex: "N23",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "7%" : "7%",
    },
    {
      title: t("PMS.util.N22"), // 請購單號
      dataIndex: "N22",
      align: "center",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      width: screens.xxl === true ? "7%" : "7%",
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "5%" : "7%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 進料退出明細 */}
        <TbTitle>{t("PMS.return.tb")}</TbTitle>
        {/* 新建的 button */}
        <CreateButton
          disabled={s_disabled.createBtn}
          onClick={() => set_s_tbModal({ show: true, data: {} })}
        />
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      {/* 新建、編輯表身的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={type === "edit" ? "編輯進料退出明細" : "新增進料退出明細"}
        title={t(`util.${type === "edit" ? "edit" : "add"}`) + t("PMS.return.tb")}
      >
        <TbModal
          call_getReturnTB={call_getReturnTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
