import { useState, useRef } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

import { AddButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";

import Window from "./Window";
import Added from "./Added";
import ERPAdded from "./ERPAdded";

export default function TradingPattern() {
  const { t } = useTranslation();
  const window_ref = useRef(null);
  const added_ref = useRef(null);

  const [s_searchData, set_s_searchData] = useState({
    query: null,
    addtype: "",
    page: 1,
    pageSize: 100,
  });
  const [s_tabKey, set_s_tabKey] = useState("window"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      query: undefined,
      addtype: "",
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "window" && window_ref.current.onSearch();
    s_tabKey === "added" && added_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "window" && window_ref.current.onCreate();
    // s_tabKey === "added" && added_ref.current.onCreate();
    (s_tabKey === "tabPMS" || s_tabKey === "tabOMS") && added_ref.current.onCreate();
  };

  const addTypeMap = [
    { label: t("ADM.trading-pattern.adda"), value: "adda" },
    { label: t("ADM.trading-pattern.addb"), value: "addb" },
    { label: t("ADM.trading-pattern.addc"), value: "addc" },
    { label: t("ADM.trading-pattern.addd"), value: "addd" },
    { label: t("ADM.trading-pattern.adde"), value: "adde" },
    { label: t("ADM.trading-pattern.addf"), value: "addf" },
    { label: t("ADM.trading-pattern.addg"), value: "addg" },
    { label: t("ADM.trading-pattern.addh"), value: "addh" },
    { label: t("ADM.trading-pattern.addi"), value: "addi" },
  ];

  const tabsData = [
    {
      key: "window",
      label: t("ADM.trading-pattern.tabWindow"),
      children: (
        <Window ref={window_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
    // {
    //   key: "added",
    //   label: t("ADM.trading-pattern.tabAdded"),
    //   children: (
    //     <Added ref={added_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
    //   ),
    // },
    {
      key: "tabPMS",
      label: t("ADM.trading-pattern.tabPMSAdded"), // 採購附加條件
      children: (
        <ERPAdded
          ref={added_ref}
          type="PMS"
          addTypeMap={addTypeMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "tabOMS",
      label: t("ADM.trading-pattern.tabOMSAdded"), // 銷貨附加條件
      children: (
        <ERPAdded
          ref={added_ref}
          type="OMS"
          addTypeMap={addTypeMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
  ];

  const searchItem = addTypeMap.map((option) => (
    <Button
      key={option.value}
      type={s_searchData.addtype === option.value ? "primary" : "default"}
      onClick={() =>
        set_s_searchData((prev) => ({
          ...prev,
          addtype: prev.addtype === option.value ? "" : option.value,
        }))
      }
    >
      {option.label}
    </Button>
  ));

  const searchBasic = [
    // <Input
    //   key="s_searchData.query"
    //   value={s_searchData.query}
    //   onChange={(e) => handlChange("query", e.target.value)}
    //   placeholder="查詢ID，單位，代號，功能碼，層級碼"
    //   className="w-[300px]"
    // />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   查詢
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader
        title={t("ADM.trading-pattern.pageHeader")}
        extra={s_tabKey === "window" ? searchBasic : [...searchItem, ...searchBasic]}
      />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
