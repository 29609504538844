import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Row, Collapse, Form, Space } from "antd";
import { useTranslation } from "react-i18next";
import { IconGarbage } from "@/components/Icon/Action";
import { EyeOutlined, EyeInvisibleOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";
import styled from "styled-components";

import LabelText from "./FormTbItem/LabelText";
import LabelRound from "./FormTbItem/LabelRound";
import LabelImage from "./FormTbItem/LabelImage";
import LabelBorder from "./FormTbItem/LabelBoorder";
import LabelBarcode from "./FormTbItem/LabelBarcode";

const { Panel } = Collapse;
const CustomPanel = styled(Panel)`
  .ant-form-item {
    margin-bottom: 6px;
  }
  .ant-collapse-header {
    align-items: center !important;
  }
`;

const initialValue = [
  {
    isvalid: true,
    type: "text",
    text: "Hello",
    groupType: "text",
    x: 10,
    y: 10,
    c: "#000000",
    fontSize: 10,
    boxWidth: 600,
    boxAlign: "left",
    bold: " ",
    fontFamily: "Noto Sans TC",
    fontWidth: 100,
  },
];

const typeMapping = {
  "text": "文字",
  "keyText": "關鍵文字",
  "quoteText": "引用文字",
  "border": "線條",
  "round": "圓",
};

const FormTB = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { form_tb_ref } = props;
  const f_details = Form.useWatch("details", form_tb_ref);
  const [s_collapseOpenKey, set_s_collapseOpenKey] = useState([]);

  const changeCollapseOpenKey = (id) => {
    set_s_collapseOpenKey((prev) => {
      const findIDIndex = prev.findIndex((x) => x === id);
      if (findIDIndex === -1) {
        return [...prev, id];
      } else {
        return [...prev.slice(0, findIDIndex), ...prev.slice(findIDIndex + 1)];
      }
    });
  };

  const delCollapseOpenKey = (id) => {
    set_s_collapseOpenKey((prev) => {
      const index = prev.findIndex((x) => x === id);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return prev;
    });
  };

  // 打開全部
  const openAllTbCollapseKey = () => {
    set_s_collapseOpenKey(f_details.map((x) => x.id - 1));
  };
  // 根據傳入打開
  const openTbCollapseKey = (openArr = []) => {
    set_s_collapseOpenKey(openArr);
  };

  // 傳上去
  useImperativeHandle(ref, () => ({
    openAllTbCollapseKey,
    openTbCollapseKey,
  }));

  return (
    <Form {...props} form={form_tb_ref} layout="vertical">
      <Form.List name="details" shouldUpdate initialValue={initialValue}>
        {(fields, { add, remove }) => (
          <Collapse
            className="h-[500px] overflow-y-scroll"
            expandIcon={(e) => {
              const { panelKey, isActive } = e;
              return isActive ? (
                <DownOutlined onClick={() => changeCollapseOpenKey(parseInt(panelKey))} />
              ) : (
                <RightOutlined onClick={() => changeCollapseOpenKey(parseInt(panelKey))} />
              );
            }}
            activeKey={s_collapseOpenKey}
          >
            {fields.map((field, index) => {
              const rowData = form_tb_ref.getFieldValue(["details", field.name]);
              const titleQuoteText = rowData.quoteText ?? rowData.text ?? rowData.keyText;

              const typeValue = typeMapping[rowData.type] || rowData.type;
              const title = (
                <span onClick={() => changeCollapseOpenKey(field.name)}>
                  {field.name + 1} {typeValue} {titleQuoteText ? " - " + titleQuoteText : ""}
                </span>
              );

              const changeIsvaild = () => {
                const newDetails = f_details.map((item) => {
                  if (item.id === field.name + 1) {
                    return {
                      ...item,
                      isvalid: !item.isvalid,
                    };
                  }
                  return item;
                });
                form_tb_ref.setFieldsValue({ details: newDetails });
              };

              const genExtra = () => (
                <Space>
                  <IconGarbage
                    className="text-cyan-500"
                    onClick={(e) => {
                      remove(field.name);
                      delCollapseOpenKey(field.name);
                      e.stopPropagation();
                    }}
                  />
                  {!rowData.isvalid ? (
                    <EyeInvisibleOutlined className="text-cyan-500" onClick={changeIsvaild} />
                  ) : (
                    <EyeOutlined className="text-cyan-500" onClick={changeIsvaild} />
                  )}
                </Space>
              );

              const isTextGroupType = rowData.groupType === "text";
              const isBorderType = rowData.type === "border";
              const isRoundType = rowData.type === "round";
              const isImageType = rowData.type === "img";
              const isBarcodeGroupType = rowData.groupType === "barcode";

              return (
                <CustomPanel
                  collapsible="header"
                  key={field.name}
                  header={title}
                  extra={genExtra()}
                  forceRender
                >
                  <Form.Item name={[field.name, "type"]} hidden children={<></>} />
                  <Form.Item name={[field.name, "isvalid"]} hidden children={<></>} />

                  <Row gutter={[12, 0]}>
                    {isTextGroupType && (
                      <>
                        {rowData.quoteText !== undefined && LabelText.quoteTextLabel(field)}
                        {rowData.text !== undefined && LabelText.textLabel(field)}
                        {rowData.keyText !== undefined && LabelText.keyTextLabel(field)}
                        {LabelText.textUtilLabel(field)}
                      </>
                    )}

                    {isBorderType && LabelBorder.borderLabel(field)}

                    {isRoundType && LabelRound.roundLabel(field)}

                    {isImageType && LabelImage.imageLabel(field, rowData)}

                    {isBarcodeGroupType && LabelBarcode.barcodeLabel(field, rowData)}
                  </Row>
                </CustomPanel>
              );
            })}
          </Collapse>
        )}
      </Form.List>
    </Form>
  );
});

export default FormTB;
