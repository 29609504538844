import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import { addPayTb, updatePayTb } from "@/service/apis/PMS/pay";
import { useContext, useEffect, useState } from "react";

import ContactButton from "@/components/ERP/PMS/ContactButton";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getOrdHD } from "@/service/apis/OMS/Ord";
import { getProHD } from "@/service/apis/PMS/Pro";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const CreateModal = ({ s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const { c_supps, c_custs, c_staffs } = useContext(ERPContext);
  const call_addPayTb = useAPI(addPayTb);
  const call_updatePayTb = useAPI(updatePayTb);
  const [form] = Form.useForm();
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(s_editData).length > 0;

  const onFinish = async (values) => {
    if (editMode) {
      call_updatePayTb.request(values);
    } else {
      call_addPayTb.request(values);
    }
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);

    form.setFieldsValue({
      suppID: supp_obj.suppID,
      suppNM: supp_obj.suppNM,
    });
  };

  const onClose = () => {
    set_s_isShowModal(false);
  };

  const fetchN13 = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getOrdHD({ N: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.N,
            value: x.N,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };
  const fetchN23 = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProHD({ opcode: "2340", N: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.N,
            value: x.N,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue({ ...s_editData, lifeFT: dayjs(s_editData.lifeFT) });
    } else {
      // isauto = false 代表不是自動建單
      form.setFieldsValue({ isauto: false });
    }
  }, []);

  useEffect(() => {
    const { status, msg } = call_addPayTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_isShowModal(false);
    }
  }, [call_addPayTb.status]);

  useEffect(() => {
    const { status, msg } = call_updatePayTb;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_isShowModal(false);
    }
  }, [call_updatePayTb.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 影藏欄位 */}
      <Form.Item name="isauto" hidden>
        <Radio.Group
          options={[
            { value: true, label: t("util.yes") },
            { value: false, label: t("util.no") },
          ]}
        />
      </Form.Item>
      <Form.Item name="suppID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="itemUUID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="preopUUID" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="lifeF" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="lifeFT" hidden>
        <DatePicker />
      </Form.Item>
      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Form.Item
            name="N13"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.util.N13") }}
            label={<Text>{t("OMS.util.N13")}</Text>}
          >
            <DebounceSelect
              key="DebounceSelect1"
              placeholder={t("util.util.placeholder_select") + t("OMS.util.N13")}
              allowClear
              fetchOptions={fetchN13}
              showSearch
              maxTagCount={10}
              onChange={(value, option) => {
                form.setFieldsValue({ N13: value.value, preopUUID: option.data.opUUID });
              }}
            />
          </Form.Item>
        </Col>
        {/* 單號 */}
        <Col span={6}>
          <Form.Item label={<Text>{t("PMS.util.N23")}</Text>} name="N23">
            <DebounceSelect
              key="DebounceSelect2"
              placeholder={t("util.util.placeholder_select") + t("PMS.util.N23")}
              allowClear
              fetchOptions={fetchN23}
              showSearch
              maxTagCount={10}
              onChange={(value) => {
                form.setFieldsValue({ N23: value.value });
              }}
            />
          </Form.Item>
        </Col>
        {/* 廠商單號 */}
        <Col span={6}>
          <Form.Item name="dealN" label={<Text>{t("PMS.pro.dealN")}</Text>}>
            <Input
              placeholder={t("PMS.pro.dealN_placeholder")}
              // className="border border-[#6A68A1] border-solid"
            />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item name="payment" label={<Text>{t("OMS.ord.payment")}</Text>}>
            <Input placeholder="请输入付款方式" />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            name="subtotal"
            label={<Text>{t("OMS.ap-manage.rcv_subtotal")}</Text>}
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ap-manage.rcv_subtotal") }}
          >
            <InputNumber
              className="w-full"
              placeholder={t("util.util.placeholder_input") + t("OMS.ap-manage.rcv_subtotal")}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("PMS.util.suppNM")}</Text>}
            name="suppNM"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              placeholder={t("PMS.util.suppID_placeholder")}
              onChange={onSuppChange}
              options={c_supps?.options?.concat(c_custs.options)}
              filterOption={(input, option) => {
                return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label={<Text>{t("PMS.pro.driID")}</Text>} name="driID" className="flex-1">
            <Select
              placeholder={t("util.util.placeholder_select") + t("PMS.pro.driID")}
              showSearch
              options={c_staffs.options}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item label={<Text>注记</Text>} name="note">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Divider className="my-2 mx-0" />
      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={onClose} />
          <ConfirmButton
            loading={call_addPayTb.status === "load" || call_updatePayTb.status === "load"}
            type="primary"
            htmlType="submit"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateModal;
