/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { Row, Col, Form, InputNumber, Divider, message, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import { TransferButton, CancelButton } from "@/components/ERP/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { reqToPro } from "@/service/apis/PMS/Req";
const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;
const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  editable,
  children,
  dataIndex,
  itemUUID,
  record,
  handleSave,
  ...restProps
}) => {
  // console.log('restProps = ', restProps)
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      // console.log('aaa = ',s_dataSourec)
      const values = await form.validateFields();
      toggleEdit();
      handleSave(dataIndex, values, itemUUID);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      // rules={[
      //   {
      //     required: true,
      //     // message: `${title} is required.`,
      //   },
      // ]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "100%" }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const ThrowProModal = ({ set_is_showPro, hd_values, call_getReqHD, call_getReqTB, s_tbData }) => {
  const { t } = useTranslation();
  // useAPI 掏轉請購單
  const call_reqToPro = useAPI(reqToPro);

  const [s_tableData, set_s_tableData] = useState(s_tbData);

  // 控制刪除、解除刪除
  const changeState = (rowData) => {
    const { isDelete, itemno } = rowData;
    const updatedData = s_tableData.map((item) => {
      if (item.itemno === itemno) {
        return {
          // 刪除時要把改過的資料換成原本的
          ...s_tbData.find((ori_item) => ori_item.itemno === itemno),
          isDelete: !isDelete,
        };
      } else {
        return item;
      }
    });
    set_s_tableData(updatedData);
  };

  // 確定拋轉
  const onSubmit = () => {
    const delete_array = s_tableData.filter((item) => item.isDelete === true);

    //如果刪除的筆數 ＝ s_tableData.length 就不要拋轉
    if (delete_array.length === s_tableData) {
      return;
    }

    const pro_opcode = {
      2210: "2310",
      2220: "2320",
      2230: "2330",
    };

    const validItems = s_tableData.reduce((result, item) => {
      // 取出要拋轉的請購明細
      if (item.isDelete === false) {
        const itemsum = item.unitprice * item.demandqty;
        result.push({
          ...item,
          confirmqty: item.demandqty,
          localprice: item.unitprice * hd_values.exchangerate,
          itemsum: itemsum,
          itemsumtax: itemsum * hd_values.taxrate * 0.01,
          subtotal: itemsum + itemsum * hd_values.taxrate * 0.01,
        });
      }
      return result;
    }, []);

    // 計算表頭的itemtotal
    const itemtotal = validItems.reduce((total, item) => {
      return total + item.itemsum;
    }, 0);

    // return;

    call_reqToPro.request({
      hd: {
        ...hd_values,
        opcode: pro_opcode[hd_values.opcode],
        itemtotal: itemtotal,
        // sumtotal=itemtotal+加扣款項。這邊還沒有加扣款項，所以sumtotal=itemtotal
        sumtotal: itemtotal,
        tax: itemtotal * hd_values.taxrate * 0.01,
        total: itemtotal + itemtotal * hd_values.taxrate * 0.01,
        lifeFT: dayjs(),
      },
      tb: validItems,
    });
  };

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("PMS.req.punit"), // 請購單位
      dataIndex: "punit",
      align: "center",
      width: "10%",
    },
    {
      // 請購單價
      title: <div className="text-[#ff8800]">{t("PMS.req.unitprice")}</div>,
      editable: true,
      dataIndex: "unitprice",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      // 請購數量
      title: <div className="text-[#ff8800]">{t("PMS.req.demandqty")}</div>,
      editable: true,
      dataIndex: "demandqty",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      // 單項金額(未稅)
      title: <div className="text-[#ff8800]">{t("PMS.req.itemsum")}</div>,
      editable: true,
      dataIndex: "itemsum",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      // 單項稅額
      title: <div className="text-[#ff8800]">{t("PMS.req.itemsumtax")}</div>,
      editable: true,
      dataIndex: "itemsumtax",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.req.subtotal"), // 含稅小計
      dataIndex: "subtotal",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: "操作",
    //   dataIndex: "operate",
    //   align: "center",
    //   width: "5%",
    //   render: (_, rowData, __) => (
    //     <Space className="px-2">
    //       {/* 取消刪除表身資料 && 刪除表身資料 */}
    //       {rowData.isDelete ? (
    //         <CancelDeleteButton onClick={() => changeState(rowData)} />
    //       ) : (
    //         <DeleteButton onClick={() => changeState(rowData)} />
    //       )}
    //     </Space>
    //   ),
    // },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const handleSave = (dataIndex, values, itemUUID) => {
    console.log("values = ", values);
    // console.log("s_tableData = ", s_tableData);
    const new_tableData = s_tableData.map((item) => {
      if (item.itemUUID === itemUUID) {
        const new_item = {
          ...item,
          [dataIndex]: values[dataIndex],
        };
        const itemsum = new_item.demandqty * new_item.unitprice;
        const localprice = hd_values.exchangerate * new_item.unitprice;
        const itemsumtax = parseFloat((itemsum * hd_values.taxrate * 0.01).toFixed(2));
        const subtotal = parseFloat((itemsum + itemsumtax).toFixed(2));
        return {
          ...new_item,
          itemsum,
          localprice,
          itemsumtax,
          subtotal,
          // [dataIndex]: values[dataIndex],
        };
      } else {
        return item;
      }
    });
    console.log("new_tableData = ", new_tableData);
    set_s_tableData(new_tableData);
  };
  const columns = tableColumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
        itemUUID: record.itemUUID,
        style: {
          backgroundColor: record.isDelete ? "#e1dbdb" : "",
          pointerEvents: record.isDelete ? "none" : "",
          opacity: record.isDelete ? ".5" : "1",
        },
      }),
    };
  });

  // 第一次渲染先將表身isDelete預設為false
  useEffect(() => {
    const tmp = s_tbData.map((item) => ({ ...item, isDelete: false }));
    set_s_tableData(tmp);
  }, []);

  // useAPI 拋轉請購單
  useEffect(() => {
    const { status, msg } = call_reqToPro;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getReqHD.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_is_showPro(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_is_showPro(false);
    }
  }, [call_reqToPro.status]);
  return (
    <Wrapper>
      <CustomTable
        components={components}
        columns={columns}
        rowNumber={6}
        rowClassName={(record, _) => {
          if (record.isDelete) {
            return "editable-row bg-zinc-300 opacity-50 cursor-not-allowed";
          }
          return "editable-row";
        }}
        dataSource={s_tableData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_is_showPro(false)} />
          {/* <Button onClick={() => set_is_showPro(false)}>{t("PMS.util.cancel")}</Button> */}

          {/* 確定拋轉【採購單】 */}
          <TransferButton htmlType="submit" type="primary" onClick={onSubmit} />
          
          {/* <Button type="primary" onClick={onSubmit}> */}
          {/* {t("PMS.req.throw_modal_submit")} */}
          {/* </Button> */}


        </Col>
      </Row>
    </Wrapper>
  );
};

export default ThrowProModal;
