import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, createSearchParams } from "react-router-dom";

const Tour = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigate_page = (pageName, query = {}) => {
    navigate({
      "pathname": pageName,
      "search": `?${createSearchParams(query)}`,
    });
  };
  return (
    <div className="flex justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="100vw"
        height="382.639"
        viewBox="0 0 1565 382.639"
      >
        <style>
          {`
          .icon {
            transition: fill 1s;
            fill: none;
            cursor: pointer;
          }
          .icon:hover {
            transition: fill 1s;
            fill: url(#linear-gradient);
          }

          .icon.disabled  {
            cursor: not-allowed;
            fill: #9796A5;
          }
        `}
        </style>
        <defs>
          <linearGradient
            id="linear-gradient"
            y1="0.539"
            x2="0.991"
            y2="0.541"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#9eaef7" />
            <stop offset="1" stopColor="#898ac1" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="-0.045"
            y1="0.49"
            x2="1.078"
            y2="0.483"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#9fb1fb" />
            <stop offset="1" stopColor="#8787bc" />
          </linearGradient>
          <filter
            id="Exclusion_67"
            x="1431.999"
            y="203.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath">
            <rect
              id="Rectangle_2169"
              data-name="Rectangle 2169"
              width="65.186"
              height="61.954"
              fill="#6a68a1"
            />
          </clipPath>
          <clipPath id="clipPath-2">
            <rect
              id="Rectangle_2170"
              data-name="Rectangle 2170"
              width="73.931"
              height="68.777"
              fill="#6a68a1"
            />
          </clipPath>
          <filter
            id="Exclusion_66"
            x="1023"
            y="122.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-2" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-2" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-3">
            <rect
              id="Rectangle_2321"
              data-name="Rectangle 2321"
              width="63.322"
              height="63.516"
              fill="#6a68a1"
            />
          </clipPath>
          <clipPath id="clipPath-4">
            <rect
              id="Rectangle_1918"
              data-name="Rectangle 1918"
              width="64.815"
              height="61.602"
              fill="#6a68a1"
            />
          </clipPath>
          <filter
            id="Exclusion_11"
            x="808"
            y="122.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-3" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-3" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-5">
            <rect
              id="Rectangle_1909"
              data-name="Rectangle 1909"
              width="61.718"
              height="61.602"
              fill="#6a68a1"
            />
          </clipPath>
          <filter
            id="Exclusion_10"
            x="588"
            y="122.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-4" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-4" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-6">
            <rect
              id="Rectangle_1922"
              data-name="Rectangle 1922"
              width="56.839"
              height="61.602"
              fill="#6a68a1"
            />
          </clipPath>
          <filter
            id="Exclusion_9"
            x="370"
            y="122.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-5" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-5" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-7">
            <rect
              id="Rectangle_1911"
              data-name="Rectangle 1911"
              width="48.293"
              height="61.602"
              fill="#6a68a1"
            />
          </clipPath>
          <filter
            id="Exclusion_8"
            x="0"
            y="203.639"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-6" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-6" />
            <feComposite in="SourceGraphic" />
          </filter>
          <filter
            id="Exclusion_15"
            x="0.001"
            y="0"
            width="133.001"
            height="133.001"
            filterUnits="userSpaceOnUse"
          >
            <feOffset dx="3" dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur-7" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur-7" />
            <feComposite in="SourceGraphic" />
          </filter>
          <clipPath id="clipPath-8">
            <rect
              id="Rectangle_2156"
              data-name="Rectangle 2156"
              width="51.153"
              height="58.091"
              fill="#6a68a1"
            />
          </clipPath>
        </defs>
        <g id="採購導覽圖" transform="translate(-175 -318.361)">
          <path
            id="_6-2"
            data-name="6-2"
            d="M-1875.723,2543.1V2529a116.758,116.758,0,0,1-21.862-2.344,115.611,115.611,0,0,1-21.774-6.76,116.113,116.113,0,0,1-19.706-10.7,116.765,116.765,0,0,1-17.167-14.165,117.021,117.021,0,0,1-14.165-17.168,115.9,115.9,0,0,1-10.693-19.7,115.185,115.185,0,0,1-6.759-21.774A116.733,116.733,0,0,1-1990,2420h14.028c3.586,52.774,47.477,94.221,100.248,95v-13.557a2.5,2.5,0,0,1,4.187-1.846l22.793,20.83a2.5,2.5,0,0,1,0,3.691l-22.793,20.829a2.454,2.454,0,0,1-1.671.659A2.5,2.5,0,0,1-1875.723,2543.1Z"
            transform="translate(3425.599 -1908.372)"
            fill="#e6e6ef"
          />
          <path
            id="_6-1"
            data-name="6-1"
            d="M-1990,2549.12a116.761,116.761,0,0,1,2.148-16.365,115.159,115.159,0,0,1,6.759-21.773,115.785,115.785,0,0,1,10.693-19.7,116.883,116.883,0,0,1,14.165-17.167,116.7,116.7,0,0,1,17.168-14.166,115.834,115.834,0,0,1,19.706-10.695,115.228,115.228,0,0,1,21.774-6.761,116.858,116.858,0,0,1,21.862-2.346v-14.472a2.5,2.5,0,0,1,4.187-1.846l22.793,20.83a2.5,2.5,0,0,1,0,3.691l-22.793,20.829a2.5,2.5,0,0,1-4.187-1.846v-13.183c-52.756.779-96.645,42.216-100.247,94.976H-1990Z"
            transform="translate(3425.6 -2051.619)"
            fill="#e6e6ef"
          />
          <path
            id="_5"
            data-name="5"
            d="M286.244,123H223.772c-4.431,0-8.034-3.14-8.034-7v0h0A102.007,102.007,0,0,0,13.358,97.808l-7-7.657a2.5,2.5,0,0,0-3.691,0L0,93.073c.024-.126.05-.25.076-.375l.015-.075A115.354,115.354,0,0,1,17.545,51.143,116.336,116.336,0,0,1,68.582,9.116,115.321,115.321,0,0,1,90.356,2.357a117.111,117.111,0,0,1,46.756,0,115.361,115.361,0,0,1,41.478,17.454,116.345,116.345,0,0,1,42.028,51.037A115.371,115.371,0,0,1,229.525,109H300.27c-.157,2.32-.237,4.671-.237,6.988,0,2.335.08,4.7.238,7.013Z"
            transform="translate(1149.5 388.624)"
            fill="#e6e6ef"
          />
          <path
            id="_4"
            data-name="4"
            d="M90.626,139.844a115.388,115.388,0,0,1-21.775-6.759,116.052,116.052,0,0,1-19.7-10.695A116.633,116.633,0,0,1,17.816,91.058a116.047,116.047,0,0,1-10.7-19.7A115.5,115.5,0,0,1,.361,49.578C.237,48.971.115,48.353,0,47.739l3.94,4.312a2.5,2.5,0,0,0,3.691,0L13.8,45.3a102.007,102.007,0,0,0,202.2-17.51H202.457a2.5,2.5,0,0,1-1.846-4.187L221.441.814a2.5,2.5,0,0,1,3.691,0L245.96,23.607a2.5,2.5,0,0,1-1.845,4.187H229.993a116.787,116.787,0,0,1-2.345,21.785,115.335,115.335,0,0,1-17.456,41.479A116.667,116.667,0,0,1,178.86,122.39a115.873,115.873,0,0,1-19.7,10.695,115.412,115.412,0,0,1-21.775,6.759,117.107,117.107,0,0,1-46.755,0Z"
            transform="translate(930.7 478.198)"
            fill="#e6e6ef"
          />
          <path
            id="_3"
            data-name="3"
            d="M-1768.559,2561.387l-20.829-22.793a2.5,2.5,0,0,1,1.845-4.187h13.536a101.285,101.285,0,0,0-29.9-70.5A101.308,101.308,0,0,0-1876,2434a102.08,102.08,0,0,0-100.208,82.9l-6.166-6.747a2.5,2.5,0,0,0-1.845-.813,2.5,2.5,0,0,0-1.846.813l-3.94,4.312c.126-.672.245-1.273.361-1.84a115.454,115.454,0,0,1,6.759-21.775,116.04,116.04,0,0,1,10.7-19.7,116.747,116.747,0,0,1,14.164-17.167,116.883,116.883,0,0,1,17.168-14.165,115.966,115.966,0,0,1,19.7-10.695,115.415,115.415,0,0,1,21.775-6.759A116.815,116.815,0,0,1-1876,2420a116.8,116.8,0,0,1,23.377,2.357,115.373,115.373,0,0,1,21.775,6.759,115.9,115.9,0,0,1,19.7,10.695,116.766,116.766,0,0,1,17.168,14.165,116.718,116.718,0,0,1,14.165,17.167,115.99,115.99,0,0,1,10.7,19.7,115.454,115.454,0,0,1,6.759,21.775,116.8,116.8,0,0,1,2.345,21.784h14.122a2.5,2.5,0,0,1,1.845,4.187l-20.829,22.793a2.49,2.49,0,0,1-1.845.813A2.49,2.49,0,0,1-1768.559,2561.387Z"
            transform="translate(2703.192 -2031.139)"
            fill="#e6e6ef"
          />
          <path
            id="_2"
            data-name="2"
            d="M-1826.859,2559.843a115.26,115.26,0,0,1-21.775-6.759,115.983,115.983,0,0,1-19.7-10.694,116.924,116.924,0,0,1-17.168-14.165,116.742,116.742,0,0,1-14.164-17.168,115.98,115.98,0,0,1-10.7-19.7,115.426,115.426,0,0,1-6.759-21.774,116.589,116.589,0,0,1-2.134-16.141H-1990c.157-2.321.237-4.672.237-6.988,0-2.336-.08-4.7-.239-7.015h76.5c4.43,0,8.034,3.142,8.034,7,0,.138,0,.274-.014.409.344,55.89,46.1,101.355,102,101.355a102.118,102.118,0,0,0,101.987-100.407h-13.535a2.5,2.5,0,0,1-1.846-4.187l20.829-22.793a2.5,2.5,0,0,1,3.692,0l20.829,22.793a2.5,2.5,0,0,1-1.846,4.187h-14.121a116.8,116.8,0,0,1-2.345,21.785,115.5,115.5,0,0,1-6.759,21.774,115.828,115.828,0,0,1-10.7,19.7,116.77,116.77,0,0,1-14.165,17.168,116.925,116.925,0,0,1-17.167,14.165,115.913,115.913,0,0,1-19.7,10.694,115.26,115.26,0,0,1-21.775,6.759,116.666,116.666,0,0,1-23.378,2.358A116.681,116.681,0,0,1-1826.859,2559.843Z"
            transform="translate(2413.178 -1941.802)"
            fill="#e6e6ef"
          />
          <path
            id="_1-2"
            data-name="1-2"
            d="M-1982.07,2685.737h0v-.031c-.184.013-.363.02-.53.02a7.009,7.009,0,0,1-7-7,7.007,7.007,0,0,1,7-7c.186,0,.365.007.53.02v-.007c53.444,0,98.144-41.732,101.765-95.011h14.028a116.755,116.755,0,0,1-2.15,16.391,115.217,115.217,0,0,1-6.759,21.774,115.934,115.934,0,0,1-10.693,19.7,117.053,117.053,0,0,1-14.165,17.168,116.808,116.808,0,0,1-17.167,14.165,116.167,116.167,0,0,1-19.707,10.7,115.619,115.619,0,0,1-21.774,6.76A116.87,116.87,0,0,1-1982.07,2685.737Z"
            transform="translate(2303.5 -2065.097)"
            fill="#e6e6ef"
          />
          <path
            id="_1-1"
            data-name="1-1"
            d="M-1866.679,2529h-14.028c-3.636-53.264-48.335-94.987-101.763-94.987v-.034c-.173.013-.352.02-.53.02a7.007,7.007,0,0,1-7-7,7.007,7.007,0,0,1,7-7c.2,0,.377.007.53.02v-.008a116.963,116.963,0,0,1,23.378,2.356,115.186,115.186,0,0,1,21.774,6.761,115.824,115.824,0,0,1,19.706,10.695,116.729,116.729,0,0,1,17.168,14.166,116.847,116.847,0,0,1,14.165,17.167,115.758,115.758,0,0,1,10.693,19.7,115.157,115.157,0,0,1,6.759,21.773,116.745,116.745,0,0,1,2.148,16.366Z"
            transform="translate(2303.9 -2031.372)"
            fill="#e6e6ef"
          />
          <g id="進料退出" className="icon" onClick={() => navigate("/pms/return")}>
            <circle id="底色" cx="57.5" cy="57.5" r="57.5" transform="translate(1613 528)" />
            <text
              id="進料退出-2"
              data-name="進料退出"
              transform="translate(1604 693)"
              fill="#6a68a1"
              fontSize="33"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 進料退出 */}
                {t("PMS.util.return")}
              </tspan>
            </text>
            <g id="進料退出-3" data-name="進料退出" transform="translate(0.817 0.08)">
              <g transform="matrix(1, 0, 0, 1, 174.18, 318.28)" filter="url(#Exclusion_67)">
                <path
                  id="Exclusion_67-2"
                  data-name="Exclusion 67"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(8581 -5763.36)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
              <g id="Group_3286" data-name="Group 3286" transform="translate(1637.089 558.032)">
                <g id="Group_3285" data-name="Group 3285" clipPath="url(#clipPath)">
                  <path
                    id="Path_1941"
                    data-name="Path 1941"
                    d="M65.11,41.568a2.292,2.292,0,0,0-2.8-1.634L34.965,47.118l-.059.016a10.62,10.62,0,0,0-9.729-6.388h-.132a2.241,2.241,0,0,0-.059-.307L14.868,1.928A2.294,2.294,0,0,0,12.6,0H2.292a2.292,2.292,0,0,0,0,4.585h8.533L20.552,41.6c.016.061.034.12.054.178a10.6,10.6,0,1,0,15.171,9.833,2.238,2.238,0,0,0,.353-.064l27.345-7.184a2.289,2.289,0,0,0,1.634-2.8M25.177,57.37A6.019,6.019,0,1,1,31.2,51.351a6.026,6.026,0,0,1-6.019,6.019"
                    fill="#6a68a1"
                  />
                  <path
                    id="Path_1942"
                    data-name="Path 1942"
                    d="M349.736,120.446l-6.118-23.287a3.93,3.93,0,0,0-4.8-2.8l-25.046,6.581a3.929,3.929,0,0,0-2.8,4.8l5.185,19.733a.544.544,0,0,0,.526.406,10.6,10.6,0,0,1,8.762,4.638.545.545,0,0,0,.589.222l20.9-5.491a3.93,3.93,0,0,0,2.8-4.8m-10.158-1.867h0a2.292,2.292,0,0,1-3.135.824l-4.728-2.76-2.76,4.728a2.292,2.292,0,1,1-3.959-2.312l2.76-4.728-4.728-2.76a2.292,2.292,0,1,1,2.312-3.959l4.728,2.76,2.76-4.728a2.292,2.292,0,0,1,3.135-.824h0a2.292,2.292,0,0,1,.824,3.135l-2.76,4.728,4.728,2.76a2.292,2.292,0,0,1,.824,3.135"
                    transform="translate(-288.393 -87.423)"
                    fill="#6a68a1"
                  />
                </g>
              </g>
            </g>
          </g>
          <g
            id="採購入庫"
            transform="translate(0 12.76)"
            className="icon"
            onClick={() => navigate("/ims/plan-in")}
          >
            <text
              id="採購入庫-2"
              data-name="採購入庫"
              transform="translate(1674 464.017)"
              fill="#6a68a1"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 採購入庫 */}
                {t("WMS.util.opcode_4120")}
              </tspan>
            </text>
            <g id="Group_3288" data-name="Group 3288" transform="translate(1633 345.24)">
              <g id="Group_3287" data-name="Group 3287" clipPath="url(#clipPath-2)">
                <path
                  id="Path_1943"
                  data-name="Path 1943"
                  d="M373.5,543.386h11.078a.731.731,0,0,1,.66.785v3.8a.731.731,0,0,1-.66.785H373.5a.731.731,0,0,1-.66-.785v-3.8a.731.731,0,0,1,.66-.785"
                  transform="translate(-342.078 -490.056)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1944"
                  data-name="Path 1944"
                  d="M73.931,22.392V67.2a1.461,1.461,0,0,1-1.32,1.57H70.626a1.461,1.461,0,0,1-1.32-1.57V25.8a1.62,1.62,0,0,0-.735-1.407L38.132,6.5a2.266,2.266,0,0,0-2.34,0L5.356,24.4A1.619,1.619,0,0,0,4.621,25.8v41.4a1.462,1.462,0,0,1-1.32,1.57H1.32A1.462,1.462,0,0,1,0,67.207V22.375a1.656,1.656,0,0,1,.594-1.31l.023-.019L.858,20.9a.012.012,0,0,1,.008,0l.021-.009L35.96.271a1.986,1.986,0,0,1,2,0h0L73.2,20.985a1.619,1.619,0,0,1,.735,1.407"
                  transform="translate(0 0)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1945"
                  data-name="Path 1945"
                  d="M212.855,255.566H169.29a1.462,1.462,0,0,0-1.32,1.57v40.55a1.462,1.462,0,0,0,1.32,1.57h1.98a1.462,1.462,0,0,0,1.32-1.57V275.876a.731.731,0,0,1,.66-.785h35.644a.731.731,0,0,1,.66.785v21.811a1.462,1.462,0,0,0,1.32,1.57h1.98a1.462,1.462,0,0,0,1.32-1.57v-40.55a1.462,1.462,0,0,0-1.32-1.57m-40.265,13.37v-7.089a.731.731,0,0,1,.66-.785h35.644a.731.731,0,0,1,.66.785v7.089a.731.731,0,0,1-.66.785H173.251a.731.731,0,0,1-.66-.785"
                  transform="translate(-154.111 -230.484)"
                  fill="#6a68a1"
                />
              </g>
            </g>
          </g>
          <g id="進料檢驗" className="icon" onClick={() => navigate_page("/pms/gostock")}>
            <circle
              id="底色-2"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(1204 447)"
              // fill="url(#linear-gradient)"
            />
            <g transform="matrix(1, 0, 0, 1, 175, 318.36)" filter="url(#Exclusion_66)">
              <path
                id="Exclusion_66-2"
                data-name="Exclusion 66"
                d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                transform="translate(8172 -5844.36)"
                fill="url(#linear-gradient-2)"
              />
            </g>
            <g id="Group_3518" data-name="Group 3518" transform="translate(1229.84 473)">
              <g id="Group_3517" data-name="Group 3517" clipPath="url(#clipPath-3)">
                <path
                  id="Path_2104"
                  data-name="Path 2104"
                  d="M243.034,29.639h20.031a4.8,4.8,0,0,0,4.8-4.8V4.8a4.8,4.8,0,0,0-4.8-4.8H243.034a4.8,4.8,0,0,0-4.8,4.8V24.835a4.8,4.8,0,0,0,4.8,4.8m-.371-23.885a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426A1.266,1.266,0,0,0,251,14.125h3.932a1.266,1.266,0,0,0,1.266-1.267V4.433h5.912a1.321,1.321,0,0,1,1.321,1.321V23.885a1.321,1.321,0,0,1-1.321,1.321H243.984a1.321,1.321,0,0,1-1.321-1.321Z"
                  transform="translate(-221.388)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_2105"
                  data-name="Path 2105"
                  d="M24.835,479.2H4.8a4.8,4.8,0,0,0-4.8,4.8v20.031a4.8,4.8,0,0,0,4.8,4.8H24.835a4.8,4.8,0,0,0,4.8-4.8V484.007a4.8,4.8,0,0,0-4.8-4.8m.372,23.885a1.321,1.321,0,0,1-1.321,1.321H5.754a1.321,1.321,0,0,1-1.321-1.321V484.957a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426a1.266,1.266,0,0,0,1.267,1.266h3.932a1.266,1.266,0,0,0,1.266-1.266v-8.426h5.912a1.321,1.321,0,0,1,1.321,1.321Z"
                  transform="translate(0 -448.326)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_2106"
                  data-name="Path 2106"
                  d="M501.293,479.2H481.262a4.8,4.8,0,0,0-4.8,4.8v20.031a4.8,4.8,0,0,0,4.8,4.8h20.031a4.8,4.8,0,0,0,4.8-4.8V484.007a4.8,4.8,0,0,0-4.8-4.8m.372,23.885a1.321,1.321,0,0,1-1.321,1.321H482.212a1.321,1.321,0,0,1-1.321-1.321V484.957a1.321,1.321,0,0,1,1.321-1.321h5.754v8.426a1.266,1.266,0,0,0,1.266,1.266h3.932a1.266,1.266,0,0,0,1.266-1.266v-8.426h5.912a1.321,1.321,0,0,1,1.321,1.321Z"
                  transform="translate(-442.775 -448.326)"
                  fill="#6a68a1"
                />
              </g>
            </g>
            <text
              id="進料檢驗-2"
              data-name="進料檢驗"
              transform="translate(1260 611.694)"
              fill="#6a68a1"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 進料檢驗 */}
                {t("PMS.util.gostock")}
              </tspan>
            </text>
          </g>
          <g id="進料單" className="icon" onClick={() => navigate_page("/pms/instock")}>
            <circle
              id="底色-3"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(989 447)"
              // fill="url(#linear-gradient)"
            />
            <g id="Group_3516" data-name="Group 3516" transform="translate(1 0.167)">
              <g id="Group_2513" data-name="Group 2513" transform="translate(1013 476.833)">
                <g id="Group_2512" data-name="Group 2512" clipPath="url(#clipPath-4)">
                  <path
                    id="Path_1801"
                    data-name="Path 1801"
                    d="M64.739,41.331a2.279,2.279,0,0,0-2.783-1.625L34.766,46.85l-.059.016a10.559,10.559,0,0,0-9.674-6.352H24.9a2.248,2.248,0,0,0-.059-.305L14.784,1.917A2.281,2.281,0,0,0,12.533,0H2.279a2.279,2.279,0,1,0,0,4.559h8.485l9.671,36.809c.015.06.033.119.054.177a10.543,10.543,0,1,0,15.085,9.777,2.234,2.234,0,0,0,.351-.063l27.19-7.143a2.276,2.276,0,0,0,1.625-2.784M25.033,57.043a5.985,5.985,0,1,1,5.985-5.985,5.991,5.991,0,0,1-5.985,5.985"
                    fill="#6a68a1"
                  />
                  <path
                    id="Path_1802"
                    data-name="Path 1802"
                    d="M349.077,120.164l-6.083-23.154a3.907,3.907,0,0,0-4.772-2.786l-24.9,6.543a3.907,3.907,0,0,0-2.786,4.772l5.155,19.621a.541.541,0,0,0,.523.4,10.54,10.54,0,0,1,8.712,4.611.542.542,0,0,0,.585.221l20.783-5.46a3.908,3.908,0,0,0,2.786-4.772m-10.69-6.658-5.265,1.383,1.383,5.265a2.279,2.279,0,0,1-4.409,1.158l-1.383-5.265-5.265,1.383a2.279,2.279,0,0,1-1.158-4.409l5.265-1.383-1.383-5.265a2.279,2.279,0,0,1,4.409-1.158l1.383,5.265,5.265-1.383a2.279,2.279,0,0,1,1.158,4.409"
                    transform="translate(-288.084 -87.329)"
                    fill="#6a68a1"
                  />
                </g>
              </g>
              <g transform="matrix(1, 0, 0, 1, 174, 318.19)" filter="url(#Exclusion_11)">
                <path
                  id="Exclusion_11-2"
                  data-name="Exclusion 11"
                  d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                  transform="translate(7957 -5844.36)"
                  fill="url(#linear-gradient-2)"
                />
              </g>
            </g>
            <text
              id="進料-2"
              data-name="進料"
              transform="translate(1044 423.903)"
              fill="#6a68a1"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 進料 */}
                {t("PMS.util.instock")}
              </tspan>
            </text>
          </g>
          <g id="採購" className="icon" onClick={() => navigate_page("/pms/pro")}>
            <circle
              id="底色-4"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(769 447)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="採購-2"
              data-name="採購"
              transform="translate(825 611.694)"
              fill="#6a68a1"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 採購 */}
                {t("PMS.util.pro")}
              </tspan>
            </text>
            <g id="Group_2491" data-name="Group 2491" transform="translate(795.479 474.833)">
              <g id="Group_2490" data-name="Group 2490" clipPath="url(#clipPath-5)">
                <path
                  id="Path_1771"
                  data-name="Path 1771"
                  d="M22.983,31.208h27.9a2.343,2.343,0,0,0,2.059-1.357l8.594-19.571a2.148,2.148,0,0,0,.1-1.456,2.158,2.158,0,0,0-2.122-1.765H15.16a2.179,2.179,0,0,0-.257.015l-2.5-5.7a2.153,2.153,0,0,0-.684-.861h0A2.154,2.154,0,0,0,10.32,0H2.159a2.159,2.159,0,0,0,0,4.317H8.977L19.538,28.368,11.451,39.28a2.168,2.168,0,0,0-.268.475v0a2.159,2.159,0,0,0,1.907,3.17H54.443a2.159,2.159,0,0,0,0-4.317H17.319L22.812,31.2q.084.007.171.007M16.792,11.376H56.339L49.526,26.891H23.6Z"
                  transform="translate(0)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1772"
                  data-name="Path 1772"
                  d="M168.882,633.549a6.636,6.636,0,1,1-6.636-6.636,6.636,6.636,0,0,1,6.636,6.636"
                  transform="translate(-143.613 -578.584)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1773"
                  data-name="Path 1773"
                  d="M575.327,633.549a6.636,6.636,0,1,1-6.637-6.636,6.637,6.637,0,0,1,6.637,6.636"
                  transform="translate(-518.725 -578.584)"
                  fill="#6a68a1"
                />
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 175, 318.36)" filter="url(#Exclusion_10)">
              <path
                id="Exclusion_10-2"
                data-name="Exclusion 10"
                d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                transform="translate(7737 -5844.36)"
                fill="url(#linear-gradient-2)"
              />
            </g>
          </g>
          <g id="請購" className="icon" onClick={() => navigate_page("/pms/req")}>
            <circle
              id="底色-5"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(551 447)"
              // fill="url(#linear-gradient)"
            />
            <text
              id="請購-2"
              data-name="請購"
              transform="translate(610 423.903)"
              fill="#6a68a1"
              fontSize="33"
              textAnchor="middle"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                {/* 請購 */}
                {t("PMS.util.req")}
              </tspan>
            </text>
            <g id="Group_2521" data-name="Group 2521" transform="translate(585.728 473.833)">
              <g id="Group_2520" data-name="Group 2520" clipPath="url(#clipPath-6)">
                <path
                  id="Path_1818"
                  data-name="Path 1818"
                  d="M31.493,61.6H2.159A2.159,2.159,0,0,1,0,59.443V2.159A2.159,2.159,0,0,1,2.159,0H44.451a2.159,2.159,0,0,1,2.159,2.159V28.676a2.159,2.159,0,0,1-4.317,0V6.476a2.159,2.159,0,0,0-2.159-2.159H6.476A2.159,2.159,0,0,0,4.317,6.476v48.65a2.159,2.159,0,0,0,2.159,2.159H31.493a2.159,2.159,0,0,1,0,4.317"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1819"
                  data-name="Path 1819"
                  d="M158.316,194.807H136.681a2.159,2.159,0,0,0,0,4.317h21.635a2.159,2.159,0,0,0,0-4.317"
                  transform="translate(-124.152 -179.789)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1820"
                  data-name="Path 1820"
                  d="M158.316,342.513H136.681a2.159,2.159,0,1,0,0,4.317h21.635a2.159,2.159,0,1,0,0-4.317"
                  transform="translate(-124.152 -316.109)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1821"
                  data-name="Path 1821"
                  d="M145.34,490.22h-8.659a2.159,2.159,0,1,0,0,4.317h8.659a2.159,2.159,0,1,0,0-4.317"
                  transform="translate(-124.152 -452.429)"
                  fill="#6a68a1"
                />
                <path
                  id="Path_1822"
                  data-name="Path 1822"
                  d="M394.712,428.325a13.735,13.735,0,1,0,13.735,13.735,13.735,13.735,0,0,0-13.735-13.735m6.82,11.479-6.746,7.7a1.716,1.716,0,0,1-2.421.16c-.031-.027-.058-.055-.086-.084a1.709,1.709,0,0,1-.234-.2l-4.089-4.176a1.716,1.716,0,1,1,2.452-2.4l3,3.065,5.543-6.325a1.716,1.716,0,0,1,2.581,2.261"
                  transform="translate(-351.607 -395.305)"
                  fill="#6a68a1"
                />
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 175, 318.36)" filter="url(#Exclusion_9)">
              <path
                id="Exclusion_9-2"
                data-name="Exclusion 9"
                d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                transform="translate(7519 -5844.36)"
                fill="url(#linear-gradient-2)"
              />
            </g>
          </g>
          <g id="詢價" className="icon disabled">
            <circle
              id="底色-6"
              data-name="底色"
              cx="57.5"
              cy="57.5"
              r="57.5"
              transform="translate(181 528)"
              fill="none"
              // fill="url(#linear-gradient)"
            />
            <text
              id="詢價-2"
              data-name="詢價"
              transform="translate(205 689)"
              // fill="#6a68a1"
              fontSize="33"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                詢價
              </tspan>
            </text>
            <g id="Group_2501" data-name="Group 2501" transform="translate(214 555)">
              <g id="Group_2494" data-name="Group 2494" clipPath="url(#clipPath-7)">
                <path
                  id="Path_1777"
                  data-name="Path 1777"
                  d="M24.147,4.02a2.976,2.976,0,0,1,2.1.875L43.495,21.958a3.287,3.287,0,0,1,.957,2.326v30.1a3.136,3.136,0,0,1-3.059,3.2H6.9a3.136,3.136,0,0,1-3.059-3.2v-30.1A3.287,3.287,0,0,1,4.8,21.958L22.045,4.895a2.976,2.976,0,0,1,2.1-.875m0-4.02a6.72,6.72,0,0,0-4.741,1.974L2.16,19.037A7.384,7.384,0,0,0,0,24.284v30.1A7.066,7.066,0,0,0,6.9,61.6H41.393a7.066,7.066,0,0,0,6.9-7.221v-30.1a7.384,7.384,0,0,0-2.16-5.247L28.888,1.974A6.72,6.72,0,0,0,24.147,0"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1778"
                  data-name="Path 1778"
                  d="M314.816,185.34a3.785,3.785,0,1,1-3.785-3.785,3.785,3.785,0,0,1,3.785,3.785"
                  transform="translate(-286.884 -169.837)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1779"
                  data-name="Path 1779"
                  d="M256.623,380.954a5.188,5.188,0,0,1-.872,2.869,5.739,5.739,0,0,1-2.505,2.1,8.394,8.394,0,0,1-2.907.721v2.447a1.1,1.1,0,0,1-1.1,1.1h-.145a1.1,1.1,0,0,1-1.1-1.1v-2.5a9.464,9.464,0,0,1-2.763-.677,6.079,6.079,0,0,1-2.746-2.271,6.282,6.282,0,0,1-.911-2.213,1.1,1.1,0,0,1,.983-1.313l.266-.023a1.1,1.1,0,0,1,1.161.822,4.567,4.567,0,0,0,.622,1.44,4.293,4.293,0,0,0,1.919,1.483,7.262,7.262,0,0,0,2.927.566,7.019,7.019,0,0,0,2.551-.429,3.537,3.537,0,0,0,1.646-1.178,2.731,2.731,0,0,0,.54-1.633,2.482,2.482,0,0,0-.521-1.569,3.854,3.854,0,0,0-1.717-1.125,33.519,33.519,0,0,0-3.4-.93,17.307,17.307,0,0,1-3.682-1.191,5.166,5.166,0,0,1-2.037-1.776,4.35,4.35,0,0,1-.67-2.374,4.848,4.848,0,0,1,.82-2.7,5.074,5.074,0,0,1,2.394-1.906,8.659,8.659,0,0,1,2.575-.611v-2.487a1.1,1.1,0,0,1,1.1-1.1h.145a1.1,1.1,0,0,1,1.1,1.1v2.532a8.615,8.615,0,0,1,2.321.6,5.4,5.4,0,0,1,2.492,2.011,5.625,5.625,0,0,1,.755,1.717,1.1,1.1,0,0,1-.982,1.376l-.277.021a1.1,1.1,0,0,1-1.141-.8,3.549,3.549,0,0,0-1.159-1.864,5.144,5.144,0,0,0-3.325-.924,5.34,5.34,0,0,0-3.338.839,2.519,2.519,0,0,0-1.047,2.024,2.171,2.171,0,0,0,.742,1.692,10.055,10.055,0,0,0,3.806,1.36,25.229,25.229,0,0,1,4.222,1.217,5.793,5.793,0,0,1,2.46,1.945,4.744,4.744,0,0,1,.794,2.714"
                  transform="translate(-224.94 -338.402)"
                  // fill="#6a68a1"
                />
              </g>
            </g>
            <g transform="matrix(1, 0, 0, 1, 175, 318.36)" filter="url(#Exclusion_8)">
              <path
                id="Exclusion_8-2"
                data-name="Exclusion 8"
                d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                transform="translate(7149 -5763.36)"
                // fill="url(#linear-gradient-2)"
              />
            </g>
          </g>
          <g id="計畫" className="icon disabled" transform="translate(0 -12.639)">
            <path
              id="底色-7"
              data-name="底色"
              d="M57.5,0A57.5,57.5,0,1,1,0,57.5,57.5,57.5,0,0,1,57.5,0Z"
              transform="translate(181 337)"
              fill="none"
              // fill="url(#linear-gradient)"
            />
            <text
              id="計劃"
              transform="translate(205 498.268)"
              // fill="#6a68a1"
              fontSize="33"
              fontFamily="MicrosoftJhengHeiRegular, Microsoft JhengHei"
            >
              <tspan x="0" y="0">
                計劃
              </tspan>
            </text>
            <g transform="matrix(1, 0, 0, 1, 175, 331)" filter="url(#Exclusion_15)">
              <path
                id="Exclusion_15-2"
                data-name="Exclusion 15"
                d="M-7085.5,6088a57.148,57.148,0,0,1-22.382-4.519,57.318,57.318,0,0,1-18.277-12.324,57.318,57.318,0,0,1-12.324-18.277A57.148,57.148,0,0,1-7143,6030.5a57.141,57.141,0,0,1,4.519-22.381,57.314,57.314,0,0,1,12.324-18.276,57.306,57.306,0,0,1,18.277-12.323A57.148,57.148,0,0,1-7085.5,5973a57.137,57.137,0,0,1,22.381,4.519,57.3,57.3,0,0,1,18.277,12.323,57.317,57.317,0,0,1,12.323,18.276A57.141,57.141,0,0,1-7028,6030.5a57.148,57.148,0,0,1-4.519,22.382,57.3,57.3,0,0,1-12.323,18.277,57.313,57.313,0,0,1-18.277,12.324A57.137,57.137,0,0,1-7085.5,6088Zm0-111a53.561,53.561,0,0,0-53.5,53.5,53.561,53.561,0,0,0,53.5,53.5,53.561,53.561,0,0,0,53.5-53.5A53.561,53.561,0,0,0-7085.5,5977Z"
                transform="translate(7149 -5967)"
                // fill="url(#linear-gradient-2)"
              />
            </g>
            <g id="Group_3103" data-name="Group 3103" transform="translate(213 366)">
              <g id="Group_3102" data-name="Group 3102" clipPath="url(#clipPath-8)">
                <path
                  id="Path_1919"
                  data-name="Path 1919"
                  d="M1.909,98.907h0A1.972,1.972,0,0,1,0,96.878V50.029A1.972,1.972,0,0,1,1.909,48a1.972,1.972,0,0,1,1.909,2.029V96.878a1.972,1.972,0,0,1-1.909,2.029"
                  transform="translate(0 -44.522)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1920"
                  data-name="Path 1920"
                  d="M648.474,73.505h0a1.972,1.972,0,0,1-1.909-2.029V50.029a1.913,1.913,0,1,1,3.818,0V71.477a1.972,1.972,0,0,1-1.909,2.029"
                  transform="translate(-602.484 -44.522)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1921"
                  data-name="Path 1921"
                  d="M0,698.029H0A1.972,1.972,0,0,1,1.909,696h20.18a2.033,2.033,0,0,1,0,4.058H1.909A1.972,1.972,0,0,1,0,698.029"
                  transform="translate(0 -645.569)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1922"
                  data-name="Path 1922"
                  d="M0,50.029H0A1.972,1.972,0,0,1,1.909,48H45.99a2.033,2.033,0,0,1,0,4.058H1.909A1.972,1.972,0,0,1,0,50.029"
                  transform="translate(0 -44.522)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1923"
                  data-name="Path 1923"
                  d="M13.913,232.8v-1.55A1.219,1.219,0,0,1,15.093,230H56.648a1.219,1.219,0,0,1,1.18,1.254v1.735a1.039,1.039,0,0,1-1.006,1.069H15.093a1.218,1.218,0,0,1-1.18-1.254"
                  transform="translate(-12.964 -213.335)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1924"
                  data-name="Path 1924"
                  d="M123.921,0h0a1.972,1.972,0,0,1,1.909,2.029v2.2a1.972,1.972,0,0,1-1.909,2.029h-.174a1.792,1.792,0,0,1-1.735-1.844V2.029A1.972,1.972,0,0,1,123.921,0"
                  transform="translate(-113.694)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1925"
                  data-name="Path 1925"
                  d="M527.563,0h0a1.972,1.972,0,0,1,1.909,2.029v2.2a1.972,1.972,0,0,1-1.909,2.029h-.174a1.792,1.792,0,0,1-1.735-1.844V2.029A1.972,1.972,0,0,1,527.563,0"
                  transform="translate(-489.817)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1926"
                  data-name="Path 1926"
                  d="M416.42,523.166l-4.647-4.938L425.9,503.209a.523.523,0,0,1,.771,0l3.875,4.119a.606.606,0,0,1,0,.82Z"
                  transform="translate(-383.7 -466.59)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1927"
                  data-name="Path 1927"
                  d="M641.257,450.3l-3.875-4.119a.606.606,0,0,1,0-.82l2.743-2.915a.523.523,0,0,1,.771,0l3.875,4.119a.606.606,0,0,1,0,.82l-2.743,2.915a.523.523,0,0,1-.771,0"
                  transform="translate(-593.778 -410.234)"
                  // fill="#6a68a1"
                />
                <path
                  id="Path_1928"
                  data-name="Path 1928"
                  d="M394.643,714.789l2.331,2.477-3.184.907-2.08.592a.682.682,0,0,1-.809-.859l.557-2.21.853-3.384Z"
                  transform="translate(-364.23 -660.699)"
                  // fill="#6a68a1"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Tour;
