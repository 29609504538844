/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButtonOri";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { addInstockTB, updateInstockTB } from "@/service/apis/PMS/Instock";
const { Text } = Typography;

const TbModal = ({ hd_values, s_disabled, s_tbModal, set_s_tbModal, call_getInstockTB }) => {
  const { c_PNs } = useContext(ERPContext);
  const { t } = useTranslation();
  // useAPI 新建表身
  const call_addInstockTB = useAPI(addInstockTB);
  // useAPI 編輯表身
  const call_updateInstockTB = useAPI(updateInstockTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(s_tbModal.data).length > 0;

  // 改變料號時
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 點擊確認時執行
  const onFinish = async (values) => {
    // 新建進料明細
    if (!editMode) {
      call_addInstockTB.request(values);
    }

    // 編輯進料明細
    if (editMode) {
      // [品保判退數量]+[品保放行數量]要等於[進料數量]
      if (hd_values.lifeF === "4" && values.rejectqty + values.goqty !== values.indqty) {
        // message.error("[品保判退數量]+[品保放行數量] 要等於 [進料數量]");
        message.error(t("PMS.instock.QAQTYcheck"));
        return;
      }
      call_updateInstockTB.request(values);
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    if (!editMode) {
      form.setFieldsValue({
        opUUID: hd_values.opUUID,
        judgeT: dayjs(),
      });
    }

    if (editMode) {
      form.setFieldsValue({
        ...s_tbModal.data,
        opUUID: hd_values.opUUID,
        rejectqty: s_tbModal.data?.rejectqty ?? 0,
        goqty: s_tbModal.data?.goqty ?? 0,
        judgeT:
          s_tbModal.data?.judgeT && !s_tbModal.data?.judgeT.includes("0001-01-01")
            ? dayjs(s_tbModal.data?.judgeT)
            : dayjs(),
      });
    }
  }, []);

  // useAPI 新建表身資料 or useAPI 更新表頭資料
  useEffect(() => {
    const { status, msg } = editMode ? call_updateInstockTB : call_addInstockTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getInstockTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addInstockTB.status, call_updateInstockTB.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preitemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="unitprice" hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item name="localprice" hidden>
          <InputNumber />
        </Form.Item>
      </div>

      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            //料號
            label={<Text disabled={editMode}>{t("util.PN")}</Text>}
            name="PN"
            rules={[{ required: true, message: t("PMS.util.PN_placeholder") + "!" }]}
          >
            <Select
              showSearch
              placeholder={t("PMS.util.placeholder_select") + t("util.PN")} //"請填寫料號"
              disabled={editMode}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/*品名*/}
          <Form.Item label={<Text disabled>{t("util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/*規格*/}
          <Form.Item label={<Text disabled>{t("util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/*圖號*/}
          <Form.Item label={<Text disabled>{t("PMS.util.drawno")}</Text>} name="drawno">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/*計量單位*/}
          <Form.Item label={<Text disabled>{t("PMS.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("PMS.util.placeholder_select") + t("PMS.util.unit")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/*操作單位*/}
          <Form.Item label={<Text disabled>{t("PMS.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("PMS.util.placeholder_select") + t("PMS.util.opunit")} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/*操作單位*/}
          <Form.Item label={<Text disabled>{t("PMS.util.pbom")}</Text>} name="pbom">
            <Input disabled placeholder={t("PMS.util.placeholder_select") + t("PMS.util.pbom")} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/*單位類別*/}
          <Form.Item
            label={
              <Text
                disabled={
                  s_disabled.utype ||
                  (f_unit?.length === 0 && f_opunit?.length === 0) ||
                  f_PN?.length === 0
                }
              >
                {t("PMS.util.utype")}
              </Text>
            }
            name="utype"
            onChange={(value) => {
              // 選擇「計量單位」或「操作單位」帶入對應的採購單位
              form.setFieldsValue({
                punit: form.getFieldValue(value),
              });
            }}
          >
            <Select
              placeholder={t("PMS.util.placeholder_select") + t("PMS.util.utype")} //"請選擇進料單位類別"
              optionFilterProp="children"
              disabled={
                s_disabled.utype ||
                (f_unit?.length === 0 && f_opunit?.length === 0) ||
                f_PN?.length === 0
              }
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && (
                <Select.Option value="unit">{t("PMS.util.unit")}</Select.Option>
              )}
              {f_opunit?.length > 0 && (
                <Select.Option value="opunit">{t("PMS.util.opunit")}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            //進料單位
            label={<Text disabled>{t("PMS.instock.punit")}</Text>}
            name="punit"
            // rules={[{required:true,message:"請填寫進料使用單位!"}]}
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            //暫收數量
            label={<Text disabled={s_disabled.indqty}>{t("PMS.instock.indqty")}</Text>}
            name="indqty"
            rules={[
              {
                required: true,
                message: t("PMS.util.placeholder_input") + t("PMS.instock.indqty") + "!",
              },
            ]}
          >
            <InputNumber
              className="w-full"
              disabled={s_disabled.indqty}
              placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.indqty")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={
              <Text disabled={s_disabled.unitprice}>
                {t("PMS.util.unitprice")}
                {t("util.excluding_tax")}
              </Text>
            }
            name="unitprice"
            rules={[{ required: true, message: t("PMS.instock.unitprice_placeholder") + "!" }]}
          >
            <InputNumber
              disabled={s_disabled.unitprice}
              className="w-full"
              placeholder={t("PMS.instock.unitprice_placeholder")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.inbatchno}>{t("PMS.instock.inbatchno")}</Text>}
            name="inbatchno"
          >
            <Input
              disabled={s_disabled.inbatchno}
              placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.inbatchno")}
            />
          </Form.Item>
        </Col>
      </Row>

      {editMode && (
        <Row gutter={[12, 0]}>
          <Col span={8}>
            <Form.Item
              label={<Text disabled={s_disabled.goqty}>{t("PMS.instock.goqty")}</Text>}
              name="goqty"
              rules={[
                {
                  required: true,
                  message: t("PMS.util.placeholder_input") + t("PMS.instock.goqty") + "!",
                },
              ]}
            >
              <InputNumber
                className="w-full"
                disabled={s_disabled.goqty}
                placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.goqty")}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<Text disabled={s_disabled.rejectqty}>{t("PMS.instock.rejectqty")}</Text>}
              name="rejectqty"
              rules={[
                {
                  required: true,
                  message: t("PMS.util.placeholder_input") + t("PMS.instock.rejectqty") + "!",
                },
              ]}
            >
              <InputNumber
                className="w-full"
                placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.rejectqty")}
                disabled={s_disabled.rejectqty}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={<Text disabled={s_disabled.judgeT}>{t("PMS.instock.judgeT")}</Text>}
              name="judgeT"
              rules={[
                {
                  required: true,
                  message: t("PMS.util.placeholder_input") + t("PMS.instock.judgeT") + "!",
                },
              ]}
            >
              <DatePicker
                className="w-full"
                placeholder={t("PMS.util.placeholder_input") + t("PMS.instock.judgeT")}
                disabled={s_disabled.judgeT}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[24, 4]}>
        <Col span={24}>
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("PMS.instock.note")}</Text>}
            name="note"
          >
            <Input.TextArea rows={2} disabled={s_disabled.tb_note} placeholder="請輸入進料註記" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tb_submit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
