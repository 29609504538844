/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";

const Preview = ({ url }) => {
  const [s_showModal, set_s_showModal] = useState(false);
  return (
    <>
      <a
        className="underline"
        onClick={(e) => {
          set_s_showModal(true);
          e.stopPropagation();
        }}
      >
        preview
      </a>

      <CustomModal
        open={s_showModal}
        title="preview"
        width="80%"
        footer={null}
        onCancel={() => set_s_showModal(false)}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={url}
        />
      </CustomModal>
    </>
  );
};

export default Preview;
