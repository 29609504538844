import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, message } from "antd";
import * as XLSX from 'xlsx-js-style';
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// component
import Page from "./Page";
// util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import {
  getOrdADD,
} from "@/service/apis/OMS/Ord";

const PrintDrawer = ({ hd_values, s_ordTb }) => {
  const [showPages, set_showPages] = useState(false);
  // const [s_adda, set_s_adda] = useState(null)
  const printRef = useRef(null);
  const { t } = useTranslation();
  // useAPI 取得條款註記、加扣款項、預付作業
  const call_getOrdADD = useAPI(getOrdADD);

  const Print = useReactToPrint({
    content: () => {
      // console.log(printRef.current);
      return printRef.current;
    },
    bodyClass: "print-style",
    onAfterPrint: () => {
      set_showPages(false);
    },
    pageStyle: () => `
    body{
      font-size:1px !important;
      -webkit-print-color-adjust: exact; 
      overflow: visible;
    }
    @page {
      size:A4 portrait;
      margin: 10mm 10mm 10mm 10mm;
    }

    #content1{
      counter-reset: page ;
    }

    #page:before {
      counter-increment: page ;
    }
  `,
  });

  // 一頁最多六筆資料, 每一個<Page />都吃一個陣列
  const getPages = (array, pageSize) => {
    return array.reduce((prev, curr, index) => {
      // 計算當前明細屬於第幾頁
      const page = Math.floor(index / pageSize) + 1;
      // 計算當前頁的資料陣列
      const data = prev[page - 1] ? prev[page - 1].data : [];
      // 將當前元素加入當前頁的資料陣列
      curr.itemno = index + 1;
      prev[page - 1] = { currentPage: page, data: [...data, curr] };
      return prev;
    }, []);
  };

  // 一頁最多有幾筆明細
  // const page_length = 10;
  const page_length = 10;

  // 一頁最多顯示六筆明細
  const renderdata = getPages(s_ordTb.tb, page_length);
  console.log("renderdata = ", renderdata);
  //等待總頁數state更新完成才顯示瀏覽器列印視窗，不然總頁數無法顯示正常
  const handlePrint = async () => {
    await setTimeout(() => {
      Print();
    }, 500);
  };

  //打開抽屜的時候call 報價註記的api拿資料
  // useEffect(() => {
  //   call_getOrdADD.request({
  //     "opUUID": hd_values?.opUUID,
  //     "addtype": "adda",
  //   })
  //   },[hd_values?.opUUID])

  // useAPI 取得加扣款項
  // useEffect(() => {
  //   const { status, msg, data } = call_getOrdADD;
  //   if (status === "suc") {
  //     console.log("adda = ", data);
  //     set_s_adda(data.adda);
  //   }
  //   if (status === "err") {
  //     message.error(t(`errorCode.${msg}`));
  //   }
  // }, [call_getOrdADD.status]);

    // console.log('s_ordTb === ',s_ordTb);


  return (
    <>
      <div className="mb-2">
        <Button type="primary" size="large" onClick={handlePrint}>
          {t('OMS.util.header_print')}
        </Button>
      </div>
      <div
        id="content1"
        ref={printRef}
        // style={{ minHeight: `${totalPages * 100}vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
        style={{ minHeight: `${renderdata.length * 100}vh` }} // 不用 Tailwind 是因為 Tailwind 不支持變數的寫法
        className="flex flex-col items-start w-full font-semibold text-black"
      >
        {renderdata.map((item, index) => (
          <Page
            key={index}
            pageData={item.data}
            currentPage={item.currentPage}
            totalPages={renderdata.length + 1}
            hd_values={hd_values}
            s_ordTb={s_ordTb}
          />
        ))}
      </div>
    </>
  );
};

export const PrintText = ({ children, ...props }) => (
  <span  {...props} className={`text-[14px] ${props.className}`}>
    {children}
  </span>
);  

export default PrintDrawer;
