import "./table.css";

import { Button, DatePicker, Select, Spin, Table, Typography, message } from "antd";
import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
//component
import PageHeader from "@/components/PageHeader/index";
import { SearchButton } from "@/components/PDS/Buttons";
import dayjs from "dayjs";
import DateMonthSwi from "@/components/BDM/DateMonthSwi";
//util
import { formatAmount } from "@/util/format";
import { getCustsHD } from "@/service/apis/OMS/sales";
import { getSalesSummary } from "./mock";
//api
import { getStaff } from "@/service/apis/ADM/userManage";
//hook
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const SalesRanking = () => {
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    driID: [], //負責業務
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  const [s_OriData, set_s_OriData] = useState([]); //api拿到的原始資料
  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料
  const [c_staffs, set_c_staffs] = useState({ data: [], options: [] });
  const [tableHeight, setTableHeight] = useState(0);
  const [is_spin, set_is_spin] = useState(false);
  const [is_expanded_AllRow, set_is_expanded_AllRow] = useState(false); //是否展開所有子表格
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); //子表格展開行,因為展開行的索引會被記住，所以每次拿到資料都要重新設定展開行的索引為空陣列

  const [total_Info, set_total_Info] = useState({
    sumtotal: 0, //總項金額
    tax: 0, //總項稅額
    total: 0, //應收含稅總金額
  }); //所有總計

  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Text } = Typography;

  // useAPI 取得員工資料
  const call_getAllStaff = useAPI(getStaff);
  const call_getSaleSummary = useAPI(getCustsHD);
  // const call_getSaleSummary = useAPI(getSalesSummary);

  const updateTableHeight = () => {
    setTableHeight(window.innerHeight * 0.7);
  };

  const onSearch = () => {
    const tmp = {
      ...s_searchData,
      driID: s_searchData?.driID?.join("|"),
    };
    set_is_expanded_AllRow(false);
    call_getSaleSummary.request(tmp);
  };

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  //設定子表格全部展開或收合
  const handleExpandedRowKeys = () => {
    if (is_expanded_AllRow) {
      setExpandedRowKeys([]);
      set_is_expanded_AllRow(false);
    } else {
      const keys = s_TableData.map((_, index) => index + 1);
      setExpandedRowKeys(keys);
      set_is_expanded_AllRow(true);
    }
  };

  // 將CountArr資料組成要的格式再塞到表格
  const handleTableData = (data) => {
    let new_tableData = [];

    data.forEach((item) => {
      let new_obj = {};
      let childrenItem = item.slice(0, -1); //除了合計以外的資料
      new_obj = { ...item[0], ...item[item.length - 1], childrenItem: [...childrenItem] }; //把合計以外的物件變成childrenItem
      new_tableData.push(new_obj);
    });

    return new_tableData;
  };

  // 渲染子表格的函数
  const expandedRowRender = (record) => {
    // 使用record的某个属性作为子表格的数据源
    const subData = record.childrenItem; // 假设subItems是包含子表格数据的属性

    return (
      <Table
        rowKey={(record) => record.key}
        columns={subColumns}
        dataSource={subData}
        pagination={false}
        className="tbchild"
      />
    );
  };

  const tableColumns = [
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      width: "50%",
      key: "driID",
      align: "center",
      render: (text) => c_staffs?.options?.find((item) => item.value === text)?.label || text,
      // render: (text) => {
      //   return <span className={"text-black font-semibold"}>{c_staffs?.options?.find((item) => item.value === text)?.label || text}</span>;
      // },
    },
    {
      title: "业绩",
      dataIndex: "sumtotal",
      width: "30%",
      key: "sumtotal",
      align: "right",
      render: (text) => <span className="text-stone-800">{formatAmount(text)}</span>,
      sorter: {
        compare: (a, b) => a.sumtotal - b.sumtotal,
      },
    },
    {
      title: "",
      dataIndex: "null",
      width: "20%",
      key: "null",
    },
  ];

  const subColumns = [
    {
      title: t("OMS.util.custalias"),
      dataIndex: "custalias",
      align: "center",
      width: "50%",
      // render: (text) => {
      //   return <span className={"text-black font-semibold"}>{text}</span>;
      // },
    },
    {
      title: "小计",
      dataIndex: "sales",
      width: "30%",
      key: "sales",
      align: "right",
      render: (text) => <span className="text-stone-800">{formatAmount(text)}</span>,
      // sorter: {
      //   compare: (a, b) => a.sumtotal - b.sumtotal,
      // },
    },
    {
      title: "",
      dataIndex: "null",
      width: "20%",
      key: "null",
    },
  ];

  useEffect(() => {
    call_getAllStaff.request();
    call_getSaleSummary.request(s_searchData);
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getSaleSummary;
    if (status === "suc") {
      set_s_OriData(data);
      setExpandedRowKeys([]);
      message.success(msg);
    } else if (status === "err") {
      set_s_OriData([]);
      setExpandedRowKeys([]);
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getSaleSummary.status]);

  useEffect(() => {
    if (call_getSaleSummary.status === "load") {
      set_is_spin(true);
    } else {
      set_is_spin(false);
    }
  }, [call_getSaleSummary.status]);

  // 取得員工資料
  useEffect(() => {
    const { data, status, msg } = call_getAllStaff;
    if (status === "suc") {
      const options = data?.map((item) => {
        return { value: item?.staffID, label: `${item.staffID}_${item?.peopleNM}` };
      });
      set_c_staffs({ options: options, data: data });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getAllStaff.status]);

  useEffect(() => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [tableHeight]);

  //拿到原始資料後做分組，將資料組成要的格式再塞到表格
  useEffect(() => {
    console.log("s_OriData = ", s_OriData);
    if (s_OriData.length === 0) {
      set_s_TableData([]);
      set_total_Info({
        sumtotal: 0, //總項金額
        tax: 0, //總項稅額
        total: 0, //應收含稅總金額
      });
      return;
    }
    //group 方法.
    const groupResult = s_OriData.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.driID]) {
        accumulator[currentValue.driID] = [];
      }
      accumulator[currentValue.driID].push(currentValue);
      return accumulator;
    }, {});
    console.log("groupResult = ", groupResult);
    //取出各群組中的值
    const groupArr = Object.values(groupResult);
    console.log("groupArr = ", groupArr);
    //-----------------加上合併欄位數、總計列-------------------------------------
    let count_total_Info = {
      sumtotal: 0, //總項金額
      tax: 0, //總項稅額
      total: 0, //應收含稅總金額
    };
    const CountArr = groupArr.map((item) => {
      const count = item.reduce(
        (accumulator, currentValue) => {
          accumulator.sumtotal += currentValue.sales;
          accumulator.tax += currentValue.itemsumtax;
          accumulator.total += currentValue.subtotal;

          count_total_Info.sumtotal += currentValue.sales;
          count_total_Info.tax += currentValue.itemsumtax;
          count_total_Info.total += currentValue.subtotal;

          return accumulator;
        },
        {
          N: "總計",
          sumtotal: 0, //總項金額
          tax: 0, //總項稅額
          total: 0, //應收含稅總金額
        }
      );

      set_total_Info({ ...count_total_Info });
      item.push(count); //加上合計欄

      return item;
    });

    const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列
    console.log("finalArr", finalArr);
    set_s_TableData(finalArr);
    set_s_TableData(handleTableData(CountArr));
  }, [s_OriData]);

  return (
    <>
      <div className="flex flex-col w-full justify-between relative">
        <Spin spinning={is_spin}>
          <div className="flex flex-col w-full justify-between">
            <PageHeader
              title="业务业绩查询"
              extra={[
                <Button type="primary" onClick={() => handleExpandedRowKeys()}>
                  {is_expanded_AllRow ? <CompressOutlined /> : <ExpandOutlined />}
                </Button>,
                <Select
                  key="driID"
                  className="min-w-[200px]"
                  name="driID"
                  mode="multiple"
                  showSearch
                  placeholder={t("util.placeholder_select") + t("OMS.ord.driID")}
                  onChange={(value) => handle_SearchData_Change("driID", value)}
                  options={c_staffs.options}
                  allowClear
                />,
                <DateMonthSwi
                  s_searchData={s_searchData}
                  handle_SearchData_Change={handle_SearchData_Change}
                />,
                <SearchButton type="primary" key="search" onClick={onSearch} />,
              ]}
            />
            <CustomTable
              // rowClassName=""
              className="customHeaderBG"
              columns={tableColumns}
              dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
              expandedRowRender={expandedRowRender} //子表格
              expandedRowKeys={expandedRowKeys} //子表格展開行
              // //把展開行的索引記住，每次展開都檢查是否被展開過
              onExpand={(expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.key]);
                } else {
                  setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
                }
              }}
              scroll={{ x: false, y: tableHeight }}
            />
          </div>
        </Spin>
      </div>
      <div className="flex absolute bottom-2 justify-center items-center text-[24px] text-[#4B6EA2] p-2 w-[99%] bg-[#F8F8F8] rounded-md shadow-[0px_3px_6px_#dcdcdc] ">
        <div className="w-[75%] min-w-[75%]"></div>
        <div className="flex min-w-[25%]">总业绩: {formatAmount(total_Info.sumtotal)}</div>
      </div>
    </>
  );
};

export default SalesRanking;
