/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Spin,
  message,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { getProHD } from "@/service/apis/PMS/Pro";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../../index";

const { Text } = Typography;

const DetailModal = ({ s_detailSearch, set_s_detailSearch, call_getProHdAd }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 取得全部採購單表頭資料
  const call_getProHD = useAPI(getProHD);
  // 幣別資料
  const { c_currencys, c_supps } = useContext(ERPContext);
  // page 的context
  const { c_state } = useContext(DataContext);
  // const { taxtypes, detailConfig } = c_state;
  const { taxtypes } = c_state;
  // 全部採購單單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      currencytype: values.currencytype?.join("|") ?? "",
      taxtype: values.taxtype?.join("|") ?? "",
      suppID: values.suppID?.join("|") ?? "",
      lifeF: values.lifeF?.join("|") ?? "",
      total: `${values.totalStart}-${values.totalEnd}`,
      startDate: values.createT ? dayjs(values.createT[0]).format("YYYY-MM-DD") : null,
      endDate: values.createT ? dayjs(values.createT[1]).format("YYYY-MM-DD") : null,
      startEDT: values.EDT ? dayjs(values.EDT[0]).format("YYYY-MM-DD") : null,
      endEDT: values.EDT ? dayjs(values.EDT[1]).format("YYYY-MM-DD") : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getProHdAd.request(new_data);
    // 更新資料並關閉跳顯
    set_s_detailSearch({ show: false, data: values });
  };

  // 第一次渲染取得全部採購單做下拉選單、賦值初始值
  useEffect(() => {
    call_getProHD.request();
    const { data } = s_detailSearch;
    // console.log("data = ", data);
    if (Object.keys(data).length > 0) {
      form.setFieldsValue({
        ...data,
        createT: data.createT ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])] : null,
        EDT: data.EDT ? [dayjs(data.EDT?.[0]), dayjs(data.EDT?.[1])] : null,
      });
    }
  }, []);

  // useAPI 取得全部採購單資料
  useEffect(() => {
    const { data, msg, status } = call_getProHD;

    if (status === "suc") {
      const allNs = data?.map((proItem) => ({ lable: proItem.N, value: proItem.N })) ?? [];
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProHD.status]);

  return (
    <Spin size="large" spinning={call_getProHD.status === "load"}>
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.opcode")}</Text>} name="opcode">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.opcode_placeholder")}
                options={[
                  { value: "2310", label: t("PMS.util.opcode_2310") },
                  { value: "2320", label: t("PMS.util.opcode_2320") },
                  { value: "2330", label: t("PMS.util.opcode_2330") },
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.N")}</Text>} name="N">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.N")}
                options={s_allNs}
              />
            </Form.Item>
          </Col>
          {/* 幣別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.currencytype")}</Text>} name="currencytype">
              <Select
                mode="multiple"
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.currencytype")}
                allowClear
                options={c_currencys?.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 稅別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.taxtype")}</Text>} name="taxtype">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.taxtype_placeholder")}
                options={taxtypes?.options}
              />
            </Form.Item>
          </Col>
          {/* 廠商ID */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.suppalias")}</Text>} name="suppID">
              <Select
                showSearch
                mode="multiple"
                placeholder={t("PMS.util.suppID_placeholder")}
                allowClear
                options={c_supps?.options}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("PMS.util.placeholder_select") + t("PMS.util.lifeF")}
                options={[
                  { value: "0", label: t("PMS.pro.lifeF_0") }, // 發佈
                  { value: "2", label: t("PMS.pro.lifeF_2") }, // 請購覆核
                  { value: "7", label: t("PMS.pro.lifeF_7") }, // 審核送簽完成
                  { value: "T", label: t("PMS.pro.lifeF_T") }, // 結案
                ]}
              />
            </Form.Item>
          </Col>
          {/* 含稅總金額 */}
          {/* <Col span={8}>
            <Form.Item label={<Text>{t("PMS.pro.total")}</Text>}>
              <div className="w-full flex gap-1">
                <Form.Item name="totalStart" className="mb-0">
                  <InputNumber placeholder={t("PMS.pro.totalStart")} className="w-full" />
                </Form.Item>

                <Form.Item className="mb-0">
                  <SwapRightOutlined />
                </Form.Item>

                <Form.Item name="totalEnd" className="mb-0">
                  <InputNumber placeholder={t("PMS.pro.totalEnd")} className="w-full" />
                </Form.Item>
              </div>
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 創建日期 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.createT")}</Text>} name="createT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
          {/* 預計交貨日 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("PMS.util.EDT")}</Text>} name="EDT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[12, 0]}>
          <Col span={24} className="flex justify-end gap-1">
            <CancelButton
              onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
            />
            {/* <Button onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}>
              {t("util.cancel")}
            </Button> */}
            <ConfirmButton htmlType="submit" type="primary" />
            {/* <Button type="primary" htmlType="submit">
            {t("util.ok")}
            </Button> */}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DetailModal;
