import React, { useState, useEffect } from "react";
import { Button, Space, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useAPI from "@/hooks/useAPI";
import { getPrinter, deletePrinter } from "@/service/apis/LMS/LabelManage";
import { IconGarbage, IconPen } from "@/components/Icon/Action";

import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalContainer from "./ModalContainer";
import dayjs from "dayjs";

export default function LabelMachines() {
  const call_getPrinter = useAPI(getPrinter);
  const call_deletePrinter = useAPI(deletePrinter);

  const [s_showModal, set_s_showModal] = useState(false);

  const [s_editData, set_s_editData] = useState({});

  const [s_tableData, set_s_tableData] = useState([]);

  const tableColumns = [
    { title: "標籤機ID", dataIndex: "printerID", align: "center" },
    { title: "標籤機名稱", dataIndex: "printerNM" },
    { title: "廠牌", dataIndex: "brand" },
    { title: "分辨率", dataIndex: "dpi", align: "right" },
    { title: "列印方式", dataIndex: "encode", align: "center" },
    { title: "備註", dataIndex: "note", width: "30%" },
    {
      title: "更新時間",
      dataIndex: "astUpdateT",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "90px",
      render: (_, rowData) => {
        return (
          <Space className="font-size-24">
            <IconPen
              onClick={(e) => {
                e.stopPropagation(); // 這裡不要開啟表身
                set_s_editData(rowData);
                set_s_showModal(true);
              }}
            />
            <IconGarbage onClick={(e) => deleteRow(e, rowData)} />
          </Space>
        );
      },
    },
  ];

  const deleteRow = (e, rowData) => {
    e.stopPropagation(); // 這裡不要開啟表身
    Modal.confirm({
      title: "資料刪除確認",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "請先確認要刪除的資料無誤，再進行刪除",
      okText: "確定",
      onOk: () => call_deletePrinter.request({ ...rowData }),
      cancelText: "取消",
    });
  };

  useEffect(() => {
    call_getPrinter.request();
  }, []);

  useEffect(() => {
    if (call_getPrinter.status === "suc") {
      set_s_tableData(
        call_getPrinter.data.map((x) => {
          return { ...x, key: x.printerID };
        })
      );
      message.success(call_getPrinter.msg);
    } else if (call_getPrinter.status === "err") {
      message.error(call_getPrinter.msg);
      set_s_tableData([]);
    }
  }, [call_getPrinter.status]);

  useEffect(() => {
    if (call_deletePrinter.status === "suc") {
      message.success(call_deletePrinter.msg);
      call_getPrinter.request();
      set_s_editData({});
    } else if (call_deletePrinter.status === "err") {
      message.error(call_deletePrinter.msg);
    }
  }, [call_deletePrinter.status]);

  return (
    <>
      <PageHeader
        title="條碼設備管理"
        extra={
          <Space className="flex justify-end">
            <Button type="primary" onClick={() => set_s_showModal(true)}>
              新增
            </Button>
          </Space>
        }
      />

      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getPrinter.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />

      <CustomModal
        width="60%"
        title={Object.keys(s_editData).length > 0 ? "編輯標籤機資料" : "新建標籤機資料"}
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getPrinter.request();
        }}
      >
        {s_showModal && (
          <ModalContainer set_s_showModal={set_s_showModal} s_editData={s_editData} />
        )}
      </CustomModal>
    </>
  );
}
