import React, { useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Radio, Space } from "antd";

//HOOK
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";

//API
import { addOEM, updateOEM } from "@/service/apis/ADM/oem";
import { getProduct, getSuppHd } from "@/service/apis/ADM/pnManage";
import { getPF } from "@/service/apis/ADM/publicAPI";
import { getOemitem } from "@/service/apis/ADM/oem";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const call_addOEM = useAPI(addOEM);
  const call_updateOEM = useAPI(updateOEM);

  const call_getPF = useAPI(getPF);
  const call_getOemitem = useAPI(getOemitem);
  const call_getSuppHd = useAPI(getSuppHd);
  const call_getProduct = useAPI(getProduct);



  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addOEM.request({ ...values ,});
    }

    if (type === "edit") {
      call_updateOEM.request({ ...values ,vendorcode:s_editData.vendorcode});
    }
  };

  useEffect(() => {
    call_getPF.request();
    call_getOemitem.request();
    call_getSuppHd.request();
    call_getProduct.request();
  }, [])

  useEffect(() => {
    if (call_addOEM.status === "suc") {
      message.success(call_addOEM.msg);
      set_s_isShowModal(false);
    } else if (call_addOEM.status === "err") {
      message.error(call_addOEM.msg);
    }
  }, [call_addOEM.status]);

  useEffect(() => {
    if (call_updateOEM.status === "suc") {
      message.success(call_updateOEM.msg);
      set_s_isShowModal(false);
    } else if (call_updateOEM.status === "err") {
      message.error(call_updateOEM.msg);
    }
  }, [call_updateOEM.status]);


  //取得製程資料
  useEffect(() => {
    if (call_getPF.status === "suc") {
    } else if (call_getPF.status === "err") {
      message.error(call_getPF.msg);
    }
  }, [call_getPF.status]);

  //取得委外項目
  useEffect(() => {
    if (call_getOemitem.status === "suc") {
    } else if (call_getOemitem.status === "err") {
      message.error(call_getOemitem.msg);
    }
  }, [call_getOemitem.status]);

  //取得廠商
  useEffect(() => {
    if (call_getSuppHd.status === "suc") {
    } else if (call_getSuppHd.status === "err") {
      message.error(call_getSuppHd.msg);
    }
  }, [call_getSuppHd.status]);

  //取得料號
  useEffect(() => {
    if (call_getProduct.status === "suc") {
    } else if (call_getProduct.status === "err") {
      message.error(call_getProduct.msg);
    }
  }, [call_getProduct.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true, // 是否有效
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]}>

        <Col span={8}>
          {/* 料號 */}
          <Form.Item label={t("util.PN")} name="PN" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={call_getProduct.data.tableData?.map((item) => { return { value: item.PN, label: item.PN } })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 製程ID*/}
          <Form.Item label={t("ADM.mold.pfID")} name="pfID" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={call_getPF.data?.map((item) => { return { value: item.pfID, label: item.pfNM } })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 委外項目 */}
          <Form.Item label={t("ADM.oem-manage.oem_item")} name="oemitemID" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={call_getOemitem.data?.map((item) => { return { value: item.itemID, label: item.itemNM } })}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={[12, 0]}>
          {/* 廠商ID */}
        <Col span={8}>
          <Form.Item label={t("ADM.company.suppID")} name="suppID" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length > 0}
              options={call_getSuppHd.data?.map((item) => { return { label: item.suppID, value: item.suppID } })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item name="isvalid" label={t("util.isvalid")}>
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>

    </Form>
  );
}
