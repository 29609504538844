import { useContext } from "react";
import { Row, Col, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);

  const { data } = s_drawer;

  const opcode_map = {
    2310: t("PMS.util.opcode_2310"),
    2320: t("PMS.util.opcode_2320"),
    2330: t("PMS.util.opcode_2330"),
  };

  const lifeF_map = {
    0: t("PMS.pro.lifeF_0"),
    2: t("PMS.pro.lifeF_2"),
    7: t("PMS.pro.lifeF_7"),
    T: t("PMS.pro.lifeF_T"),
  };
  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 單別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.opcode")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{opcode_map[data.opcode]}</Text>
        </Col>
        {/* 單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.N")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.N}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 幣別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.currencytype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.currencytype}</Text>
        </Col>
        {/* 匯率 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.exchangerate")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.exchangerate}</Text>
        </Col>
        {/* 貿易條件 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.pro.trade")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.trade}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 税別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.taxtype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.taxtype}</Text>
        </Col>
        {/* 稅率 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.taxrate")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.taxrate}</Text>
        </Col>
        {/* 付款方式 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.pro.payment")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.payment}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 未稅總金額 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.sumtotal")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.sumtotal)}</Text>
        </Col>
        {/* 合計税額 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.tax")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.tax)}</Text>
        </Col>
        {/* 含稅總金額 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.pro.total")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.total)}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 廠商ID */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.suppID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.suppID}</Text>
        </Col>
        {/* 廠商簡稱 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.suppalias")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.suppalias}</Text>
        </Col>
        {/* 預計交貨日 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.pro.EDT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value flex-col">
          <Text>
            {t("util.start")}：{dayjs(data.startEDT).format("YYYY-MM-DD")}
          </Text>
          <Text>
            {t("util.end")}：{dayjs(data.endEDT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 廠商名稱 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.suppNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.suppNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 廠商地址 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.dealaddr")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.dealaddr}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 廠商交貨窗口 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.contact")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.contact}</Text>
        </Col>
        {/* 電話 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.tel")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.tel}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 廠商業務窗口 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.dealcontact")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.dealcontact}</Text>
        </Col>
        {/* 電話 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.tel")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.dealtel}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 創建日期 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.createT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.createT).format("YYYY-MM-DD")}</Text>
        </Col>
        {/* 覆核日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.confirmT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.confirmT?.includes("0001-01-01") ? "" : dayjs(data.confirmT).format("YYYY-MM-DD")}
          </Text>
        </Col>
        {/* 簽回日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.signT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.signT?.includes("0001-01-01") ? "" : dayjs(data.signT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 創建人員 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.createID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.createID)}</Text>
        </Col>
        {/* 覆核人員 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.confirmID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.confirmID)}</Text>
        </Col>
        {/* 簽回日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.pro.approveT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.approveT?.includes("0001-01-01") ? "" : dayjs(data.approveT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 負責採購 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.driID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.driID)}</Text>
        </Col>
        {/* 歷程 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.lifeF")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{lifeF_map[data.lifeF]}</Text>
        </Col>
        {/* 更新日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.updtT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.updT?.includes("0001-01-01") ? "" : dayjs(data.updT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 採購註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.pro.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
