import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

//-----------------------------------------取得應收作業表頭----------------------------------------
export const getApHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getApHD`, {
    params: data,
  });
};
//-----------------------------------------取得應收作業表身----------------------------------------
export const getAPTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAPTB`, {
    params: data,
  });
};
//-----------------------------------------新增應收作業表頭----------------------------------------
export const addApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addApHD`, data);
};
//-----------------------------------------編輯應收作業表頭----------------------------------------
export const updateApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateApHD`, data);
};
//-----------------------------------------刪除應收作業表頭----------------------------------------
export const deleteApHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApHD`, data);
};
//-----------------------------------------刪除應收作業表身----------------------------------------
export const deleteApTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteApTB`, data);
};
//-----------------------------------------引用應收資料----------------------------------------
export const takeOut = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeOut`, data);
};
