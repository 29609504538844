/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space, message } from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import React, { useEffect } from "react";
import { addRunA3TB, updateRunA3TB } from "@/service/apis/OMS/runA3";

//api
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const ModalContainer = ({ c_lightData, s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // useAPI 新增預收款表身
  const call_addRunA3TB = useAPI(addRunA3TB);
  // useAPI 編輯預收款表身
  const call_updateRunA3TB = useAPI(updateRunA3TB);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addRunA3TB.request({
        opUUID: c_lightData.opUUID,
        ...values,
      });
    }
    if (type === "edit") {
      call_updateRunA3TB.request({ ...s_editData, ...values });
    }
  };

  // useAPI 新增預收款表身
  useEffect(() => {
    if (call_addRunA3TB.status === "suc") {
      message.success(call_addRunA3TB.msg);
      set_s_isShowModal(false);
    } else if (call_addRunA3TB.status === "err") {
      message.error(call_addRunA3TB.msg);
    }
  }, [call_addRunA3TB.status]);

  // useAPI 更新預收款表身
  useEffect(() => {
    if (call_updateRunA3TB.status === "suc") {
      message.success(call_updateRunA3TB.msg);
      set_s_isShowModal(false);
    }
    if (call_updateRunA3TB.status === "err") {
      message.error(call_updateRunA3TB.msg);
    }
  }, [call_updateRunA3TB.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        ...s_editData,
      }}
    >
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Form.Item label="付款金额" name="payment">
            <InputNumber className="w-full" min={0} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="注记" name="note">
            <Input.TextArea rows={2} />
          </Form.Item>
        </Col>
      </Row>
      <Divider className="my-2" />

      <Row gutter={[12, 24]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_isShowModal(false)} />

          <ConfirmButton type="primary" htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
