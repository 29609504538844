/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  message,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { MainPageContext } from "../index";
// util
import { formatAmount } from "@/util/format";

// API
import useAPI from "@/hooks/useAPI";
import { updateProTB } from "@/service/apis/PMS/Pro";

const { Text } = Typography;

const TbModal = ({ s_tbModal, set_s_tbModal, call_getProTB, call_getProHD }) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  const { hd_values, s_disabled } = useContext(MainPageContext);
  const [form] = Form.useForm();
  const f_unitprice = Form.useWatch("unitprice", form);
  const f_demandqty = Form.useWatch("demandqty", form);

  // useAPI 編輯表身
  const call_updateProTB = useAPI(updateProTB);

  // 儲存時執行
  const onFinish = async (values) => {
    call_updateProTB.request(values);
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    // 判斷目前是編輯表身還是新建表身
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    const formValues = {
      opUUID: hd_values.opUUID,
      currencytype: hd_values.currencytype,
      exchangerate: hd_values.exchangerate,
      exchangeD: hd_values.exchangeD,
      EDT: dayjs(hd_values.EDT),
      ...(type === "create"
        ? {
            returnqty: 0,
            confirmqty: 0,
            cancelqty: 0,
            balqty: 0,
            lifeF: "0",
          }
        : s_tbModal.data),
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateProTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getProHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getProTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateProTB.status]);

  useEffect(() => {
    const { cancelqty } = form.getFieldsValue();
    // 廠商確認數量預設為採購數量
    // 如果當前確認數量不等於採購數量也沒關係，因為只要表單可編輯就必須重跑流程
    const confirmqty = f_demandqty;
    // 各項金額
    const itemsum = (confirmqty - cancelqty) * f_unitprice;
    // 各項稅額
    // const itemsumtax = itemsum * hd_values.taxrate;
    // 含稅小記
    // const subtotal = itemsum + itemsumtax;

    form.setFieldsValue({
      confirmqty: confirmqty,
      localprice: f_unitprice ? f_unitprice * hd_values.exchangerate : 0,
      itemsum: itemsum,
      // itemsumtax: itemsumtax,
      // subtotal: subtotal,
      balqty: f_demandqty, // 剩餘數量在拋轉之前都預設為採購數量
    });
  }, [f_unitprice, f_demandqty]);

  return (
    <Form
      name="basic"
      form={form}
      layout="vertical"
      onFinish={onFinish}
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preitemUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>
        {/* 退出數量 */}
        <Form.Item name="returnqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 取消數量 */}
        <Form.Item name="cancelqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 廠商確認數量 */}
        <Form.Item name="confirmqty" hidden>
          <InputNumber />
        </Form.Item>

        {/* 剩餘數量 */}
        <Form.Item name="balqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項金額 */}
        <Form.Item name="itemsum" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項稅額 */}
        <Form.Item name="itemsumtax" hidden>
          <InputNumber />
        </Form.Item>
        {/* 含稅小計 */}
        <Form.Item name="subtotal" hidden>
          <InputNumber />
        </Form.Item>
      </div>
      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.ord.note")}</Text>} name="note">
            <Input disabled placeholder={t("util.placeholder_input") + t("OMS.ord.note")} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("util.pdtNM")}</Text>}
            // 品名
            name="pdtNM"
            rules={[{ required: true }]}
            messageVariables={{ label: t("util.pdtNM") }}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("util.pdtNM")}
              disabled={s_disabled.PN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>

        <Col span={5} className="flex items-end">
          {/* <PNButton OMSform={form} custalias={hd_values.custalias} /> */}
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 料號 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.PN")}</Text>} name="PN">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")} />
          </Form.Item>
        </Col>

        {/* 規格 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")} />
          </Form.Item>
        </Col>

        {/* 機種 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.mtype")}</Text>} name="mtype">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.pdtNM")} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            // 测试费用
            label={<Text disabled={s_disabled.unitprice}>{t("OMS.ord.unitprice")}</Text>}
            name="unitprice"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ord.unitprice") }}
          >
            <InputNumber
              disabled={s_disabled.unitprice}
              className="w-full"
              formatter={(value) => formatAmount(value)}
              placeholder={t("util.placeholder_input") + t("OMS.ord.unitprice")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 數量
            label={<Text disabled>{t("OMS.ord.demandqty")}</Text>}
            name="demandqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ord.demandqty") }}
          >
            <InputNumber
              disabled
              className="w-full"
              formatter={(value) => formatAmount(value)}
              placeholder={t("util.placeholder_select") + t("OMS.ord.demandqty")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.itemsum")}</Text>} name="itemsum">
            <InputNumber disabled formatter={(value) => formatAmount(value)} className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 計畫時間 */}
          <Form.Item
            label={<Text disabled={s_disabled.tb_planday}>{t("OMS.ord.planday")}</Text>}
            name="planday"
          >
            <Input disabled={s_disabled.tb_planday} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.tb_EDT}>{t("OMS.util.EDT")}</Text>}
            name="EDT"
          >
            <DatePicker disabled={s_disabled.tb_EDT} className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
