import React from 'react';
import styled from 'styled-components';

import { Popover, Divider } from 'antd';

const Wrapper = styled.div`
  width: 80%;
  height: 40px;
  //box-shadow: inset 0px 3px 6px #00000029;
  box-shadow: inset -0.5px 1px 3px #00000029;
  border: 0.5px solid #C4C4C4;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  //background-color: gray;
`
const Slice = styled.div`
  height: 100%;
  width: ${({ width }) => width}%;
  //border-radius: 5px;
  box-shadow: inset 0px 3px 6px #00000029;
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  left: ${({left}) => left}%;

  &:hover {
    cursor: pointer;
    box-shadow: inset 2px 2px 20px 2px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 24%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`
const Light = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${props => props.color ? props.color : null};
  border: 0.1px solid #E0E0E0;
  border-radius: 50%;
  vertical-align: middle;
`

const colorMap = {
  'Y': '#F9DC7B',
  'R': '#FC9191',
  'G': '#50C9A4',
  'W': '#FFFFFF',
  'B': '#71AFF8'
}

const TimeChart = ({type, date, data}) => {// data

  const datax = [
    {
      "woN": "344",
      "situF": "G",
      "startTime": "2021-11-03T03:53:41+08:00",
      "endTime": "2021-11-03T17:28:28.809022939+08:00",
      "keeptime": 48887,
      "newstartTime": "11-03 03:53",
      "newendTime": "11-03 09:10",
      "shiftstartTime": "11-03 08:00",
      "shiftendTime": "11-03 20:00"
  }
  ]
  const nowMonth = new Date(date).getMonth() + 1;
  const nowDate = new Date(date).getDate();
  const nowYear = new Date(date).getFullYear();

  // 班別開始時間
  const total_startT = type === 'morningData' ? `${nowMonth}-${nowDate} 08:00` : `${nowMonth}-${nowDate} 20:00`;

  //const target_startT = '10-27 09:30';
  //const target_endT = '10-27 13:40';


  // 計算開始時間跟結束時間的秒數
  // const getTimeDiff = (startT, endT) => {
  //   let newStartT = new Date(startT);
  //   let newEndT = new Date(endT);
    
  //   const startSec = newStartT.getHours() * 60 * 60 + newStartT.getMinutes() * 60 + newStartT.getSeconds();
  //   const endSec = newEndT.getHours() * 60 * 60 + newEndT.getMinutes() * 60 + newEndT.getSeconds();
    
  //   return endSec - startSec;
  //   //return [startSec, endSec];
  // };

  const getTimeDiff = (dt2, dt1) => {

    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    //diff /= 60;

    return Math.abs(Math.round(diff)) > 0 ? Math.abs(Math.round(diff)) : 0;
    
  }
 
  

  // 計算班別總共幾秒(目前12小時為一班)
  //const all_ProductionTime = getTimeDiff(total_startT, total_endT);
  const all_ProductionTime = 43200;

  // 計算出每一秒佔 <Wrapper /> 幾 % 的寬度
  const percentage = (100/all_ProductionTime).toFixed(6); 
  
  // 計算出每一個切片要距離左邊多少 %
  const getOffset = (startT) => {

    if(new Date(total_startT) > new Date(startT)) {
      return 0
    }

    // 用目標切片開始時間 - 班別開始時間 算出距離班別開始時間幾秒
    const secDiff = getTimeDiff(new Date(total_startT), new Date(startT));  

    // 如果秒數差大於半天，距離左邊0趴
    if(secDiff >= 43200) {
      return 0
    } else {
      return secDiff * percentage;
    }
  }

  // 計算切片寬度要幾 %
  const getWidth = (startT, endT) => {

    if(new Date(startT) > new Date(total_startT)) {

    } 

    const sec = getTimeDiff(new Date(startT), new Date(endT));
    if(sec >= 43200) {
      return 43200 * percentage;
    } else {
      return sec * percentage;
    }
    
    
  }

  // 切片距離左邊幾 %
  //const left_percentage = getOffset(target_startT);

  // 切片寬度幾 %
  //const width_percentage = getWidth(target_startT, target_endT);


  return (
    <Wrapper>
      {data && data.map((item, index) => {
        
        const today = type === 'morningData' ? new Date(`${nowYear}-${nowMonth}-${nowDate} 08:00`) : new Date(`${nowYear}-${nowMonth}-${nowDate} 20:00`)
        
        // 切片距離左邊幾 %
        //const left_percentage = today > new Date(item.startTime) ? getOffset(item.startTime) : getOffset(item.newstartTime);
        const left_percentage = index === 0 ? (
          today > new Date(item.startTime) ? getOffset(item.shiftstartTime) : getOffset(item.newstartTime)
        ) : (
          getOffset(item.newstartTime)
        )
        
        // 切片寬度幾 %
        //const width_percentage = today < new Date(item.startTime) ? getWidth(item.newstartTime, item.newendTime) : getWidth(item.startTime, item.newendTime);
        const width_percentage = index === 0 ? (
          today > new Date(item.startTime) ? getWidth(item.shiftstartTime, item.newendTime) : getWidth(item.newstartTime, item.newendTime)
        ) : (
          getWidth(item.newstartTime, item.newendTime)
        )


        return (
          <Popover 
            key={index}
            placement="topLeft" 
            title={() => {
              return (
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                  <span style={{fontWeight: 'bold', marginRight: '10px'}}>工單</span>
                  <span>{item.woN}</span>
                </div>
              )
            }} 
            content={() => {
              return (
                <div>
                  <div>
                    <span style={{width: '40%'}}>起始時間：</span>
                    <span style={{width: '60%'}}>{item.newstartTime}</span>
                  </div>
                  <Divider style={{marginTop: '3px', marginBottom: '3px'}} />
                  <div>
                    <span style={{width: '40%'}}>結束時間：</span>
                    <span style={{width: '60%'}}>{item.newendTime}</span>
                  </div>
                  <Divider style={{marginTop: '3px', marginBottom: '6px'}} />
                  <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span style={{width: '40%'}}>燈號：</span>
                    {/* <span style={{width: '60%'}}> */}
                      <Light color={colorMap[item.situF]} />
                    {/* </span> */}
                  </div>
                </div>
              )
            }} 
            trigger="click"
          >
            <Slice left={left_percentage} width={width_percentage} backgroundColor={colorMap[item.situF]} />
          </Popover>
        )
      })}
    </Wrapper>
  )
};

export default TimeChart;