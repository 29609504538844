import * as XLSX from "xlsx/xlsx.mjs";

/**
 * 將提供的數據導出到 Excel 文件
 * @param {Array} columns 列的定義，包含是否導出到Excel、標題和數據索引等信息
 * @param {Array} data 要導出的數據
 * @param {string} fileName 導出的文件名
 */
const exportExcel = async (columns, data, fileName) => {
  // 輸出列的定義以供調試
  console.log("columns = ", columns);

  // 根據列的定義，將數據過濾和轉換為導出到Excel的格式
  const filterToExcelData = data.map((curr) => {
    let excelObj = {};
    columns.forEach((x) => {
      // 如果該列被標記為導出到Excel，則添加到excelObj
      x.isExcel && (excelObj[x.title] = curr[x.dataIndex]);
    });
    return excelObj;
  });

  // 輸出過濾和轉換後的數據以供調試
  console.log("filterToExcelData = ", filterToExcelData);

  // 使用 XLSX 工具將數據轉換為工作表
  const worksheet = XLSX.utils.json_to_sheet(filterToExcelData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

  // 根據列的定義，計算每一列的最大寬度
  const cols = columns
    .map((column) => {
      if (!column.isExcel) return null;

      let maxLen = column.title.length;
      for (let item of data) {
        const valueLength = item[column.dataIndex]?.toString().length || 0;
        maxLen = Math.max(maxLen, valueLength);
      }
      return {
        wch: maxLen + 10, // 加10以確保寬度足夠
      };
    })
    .filter(Boolean); // 过滤掉null，保留真正的宽度对象

  // 设置工作表的列宽
  worksheet["!cols"] = cols;

  // 使用 XLSX 工具將工作簿寫入文件
  XLSX.writeFile(workbook, fileName);
};

export default exportExcel;
