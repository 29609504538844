import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
  TourButton,
} from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import { addStrikeHD, getStrikeHD, getStrikeTB, updateStrikeHD } from "@/service/apis/OMS/strike";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import ContactButton from "@/components/ERP/OMS/ContactButton";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../../index";
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PageHeader from "@/components/PageHeader";
import TakeApModal from "./Modal/TakeApModal";
// component
import TbContainer from "./Details/TbContainer";
import dayjs from "dayjs";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  takeAp: true, // 引用對帳單
  custID: true,
  contact: true,
  tel: true,
  apSearch: true, // 對帳資料查詢
  addr: true,
  strikeCheck: true, // 沖帳覆核
  confirmStrike: true, // 確認沖帳
  // ----- tb -----
  createBtn: true,
  tb_delete: true,
  tb_submit: true,
  rcvtype: true,
  rcvtotal: true,
  rcvT: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getStrikeHD = useAPI(getStrikeHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getStrikeHD = useAPI(getStrikeHD);
  // useAPI 更新表身
  const call_getStrikeTB = useAPI(getStrikeTB);
  // useAPI 取得上一筆資料
  const call_prev_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 取得下一筆資料
  const call_next_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 新增表頭
  const call_finish_addStrikeHD = useAPI(addStrikeHD);
  // useAPI 儲存表頭
  const call_finish_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 沖帳覆核
  const call_check_updateStrikeHD = useAPI(updateStrikeHD);
  // useAPI 沖帳確認
  const call_confirmStrike_updateStrikeHD = useAPI(updateStrikeHD);
  const [form] = Form.useForm();
  const f_custID = Form.useWatch("custID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  // 廠商資料、人員資料
  const { c_custs } = useContext(ERPContext);
  const { c_state, updateState } = useContext(DataContext);
  const { mainPage, Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  const [is_showTake, set_is_showTake] = useState(false);

  // 據此創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      opUUID: "",
      N17: "",
      sumtotal: 0, // 總項金額
      tax: 0, //  總項稅額
      lifeFT: dayjs(),
      createT: dayjs(),
    };

    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    change_MainPage_disabled(new_Hd_Data);

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 表身資料需要手動引用
    set_s_tbData([]);
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      addr: cust_obj.addr,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 沖帳覆核 or 取消覆核
  const strikeCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "1" ? "取消沖帳覆核確認" : "沖帳覆核確認"}`,
      title: `${
        f_lifeF === "1"
          ? t("OMS.util.cancel") + t("OMS.ap-manage.strike_lifeF_1") + t("util.ok")
          : t("OMS.ap-manage.strike_lifeF_1") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content:
        f_lifeF === "1"
          ? t("OMS.ap-manage.cancel_strikeCheck_confirm_text")
          : t("OMS.ap-manage.strikeCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_check_updateStrikeHD.request({
          ...values,
          lifeF: f_lifeF === "0" ? "1" : "0",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 確認沖帳
  const confirmStrike = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      title: t("OMS.ap-manage.confirmStrike"), // 確認沖帳
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.ap-manage.confirmStrike_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_confirmStrike_updateStrikeHD.request({
          ...values,
          lifeF: "T",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 查詢對帳單
  const go_ap = () => {
    const params_obj = {
      tabkey: "ap",
      N: mainPage.data?.N17,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/ap-manage?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateStrikeHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateStrikeHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = Ns.findIndex((item) => item === N);

    return index;
  };

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addStrikeHD.request(values);
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateStrikeHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有對帳單
    const apSearch = hd_values.N17?.length > 0 ? false : true;
    console.log("apSearch = ", apSearch);
    console.log("hd_values = ", hd_values);
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: isNewdoc ? false : true,
        custID: isNewdoc ? false : true,
        addr: false,
        takeAp: !apSearch, // 引用對帳資料
        apCheck: false,
        note: false,
        contact: false,
        tel: false,
        itemNM: false,
        strikeCheck: false, // 沖帳覆核
        goAp: apSearch, // 對帳資料查詢
        createBtn: apSearch,
        // ----- tb -----
        rcvtype: false, // 付款方式
        rcvtotal: false, // 付款金額
        rcvT: false, // 付款時間
        tb_note: false,
        tb_submit: false,
        tb_delete: false,
      });
    }

    // 沖帳覆核
    if (hd_values?.lifeF === "1") {
      set_s_disabled({
        ...INIT_DISABLED,
        contact: false,
        tel: false,
        addr: false,
        apSearch: apSearch, // 對帳資料查詢
        strikeCheck: false, // 沖帳覆核
        confirmStrike: false, // 確認沖帳
      });
    }

    // 完成沖帳
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        apSearch: apSearch, // 對帳資料查詢
      });
    }
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建對帳單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        createT: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getStrikeTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getStrikeHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getStrikeHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getStrikeTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getStrikeTB.status]);

  // 新增對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addStrikeHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getStrikeHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getStrikeTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addStrikeHD.status]);

  // 編輯對帳單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateStrikeHD;
    console.log("data = ", data);
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getStrikeHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getStrikeTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateStrikeHD.status]);

  // useAPI 沖帳覆核
  useEffect(() => {
    const { status, data, msg } = call_check_updateStrikeHD;
    if (status === "suc") {
      // message.success(data.lifeF === "1" ? "沖帳覆核成功" : "取消覆核成功");
      message.success(
        data.lifeF === "1"
          ? t("OMS.ap-manage.strike_lifeF_1") + t("util.success")
          : t("OMS.util.cancel") + t("OMS.ap-manage.strike_lifeF_1") + t("util.success")
      );
      // 更新表頭資料
      call_getStrikeHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_check_updateStrikeHD.status]);

  // useAPI 完成沖帳
  useEffect(() => {
    const { status, data, msg } = call_confirmStrike_updateStrikeHD;
    if (status === "suc") {
      message.success(t("OMS.ap-manage.strike_lifeF_T")); // 完成沖帳
      // 更新表頭資料
      call_getStrikeHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_confirmStrike_updateStrikeHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateStrikeHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getStrikeHD.request({ N: Ns[nowIndex - 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateStrikeHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateStrikeHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getStrikeHD.request({ N: Ns[nowIndex + 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateStrikeHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getStrikeHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getStrikeTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getStrikeHD.status]);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  return (
    <div className="flex flex-col h-full">
      <Form
        onFinish={onFinish}
        name="basic"
        form={form}
        layout="vertical"
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
        initialValues={{
          opcode: "1810",
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯沖帳作業" : "新建沖帳作業"} /> */}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("OMS.ap-manage.strike")
                  : t("OMS.util.create") + t("OMS.ap-manage.strike")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            {/* <TourButton /> */}
          </Col>
        </Row>

        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>
        </div>

        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.opcode") }}
              label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[{ label: t("OMS.util.opcode_1810"), value: "1810" }]}
              />
            </Form.Item>
          </Col>

          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 應收單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.N17")}</Text>} name="N17">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 各項稅額、沖帳總金額 */}
          <Col span={editMode ? 5 : 6} className="flex gap-2">
            {/* 各項稅額 */}
            {/* <Form.Item label={<Text>{t("OMS.util.tax")}</Text>} name="tax" className="flex-1">
              <InputNumber className="w-full" disabled />
            </Form.Item> */}
            {/* 沖帳總金額 */}
            <Form.Item
              label={<Text>{t("OMS.ap-manage.strike_sumtotal")}</Text>}
              name="sumtotal"
              className="flex-1"
            >
              <InputNumber className="w-full" disabled />
            </Form.Item>
            <Form.Item label={<Text>收款总金额</Text>} name="rcvtotal" className="flex-1">
              <InputNumber className="w-full" disabled />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-blue"
                disabled={s_disabled.takeAp}
                onClick={() => set_is_showTake(true)}
              >
                {/* 引用應收單 */}
                {t("OMS.util.take") + t("OMS.ap-manage.ap")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶簡稱 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.custID}>{t("OMS.util.custNM")}</Text>}
              name="custNM"
            >
              <Select
                showSearch
                disabled={s_disabled.custID}
                placeholder={t("util.placeholder_select") + t("OMS.util.custNM")}
                onChange={onCustChange}
                options={c_custs.options}
                filterOption={(input, option) => {
                  return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                }}
              />
            </Form.Item>
          </Col>
          {/* 客戶ID */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              name="custID"
              label={<Text disabled>{t("OMS.util.custID")}</Text>}
              messageVariables={{ label: t("OMS.util.custalias") }}
            >
              <Input
                disabled
                placeholder={t("util.placeholder_select") + t("OMS.util.custalias")}
              />
            </Form.Item>
          </Col>
          {/* 創建人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createID")}</Text>} name="createID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {/* 據此創建 */}
                {t("OMS.util.accord") + t("OMS.util.create")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 聯絡窗口資訊 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item shouldUpdate label={<Text>{t("OMS.ap-manage.contactWindow")}</Text>}>
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      custID={form.getFieldValue("custID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          contact: contact_obj?.contact,
                          tel: contact_obj?.tel,
                        });
                      }}
                      title={t("OMS.ap-manage.contactWindow")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("OMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="contact" className="flex-1" shouldUpdate>
                      <Input
                        disabled={s_disabled.contact}
                        className="flex-1"
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.contact")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("OMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="tel" className="flex-1">
                      <Input
                        disabled={s_disabled.tel}
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 覆核人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.confirmID")}</Text>} name="confirmID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 覆核日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.confirmT")}</Text>} name="confirmT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="flex-1 h-full pms-btn-blue"
                disabled={s_disabled.apSearch}
                onClick={go_ap}
              >
                {/* 對帳資料查詢 */}
                {t("OMS.util.apSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 聯絡地址 */}
          <Col span={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              name="addr"
              label={<Text disabled={s_disabled.addr}>{t("OMS.ap-manage.ap_addr")}</Text>}
            >
              <Input
                disabled={s_disabled.addr}
                placeholder={t("util.placeholder_input") + t("OMS.ap-manage.ap_addr")}
              />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                disabled
                options={[
                  { label: t("OMS.ap-manage.strike_lifeF_0"), value: "0" }, // 創立
                  { label: t("OMS.ap-manage.strike_lifeF_1"), value: "1" }, // 沖帳覆核
                  { label: t("OMS.ap-manage.strike_lifeF_T"), value: "T" }, // 結案
                ]}
              />
            </Form.Item>
          </Col>
          {/* 歷程時間 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.lifeFT")}</Text>} name="lifeFT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
            >
              {/* 沖帳覆核 */}
              <Button
                className={`h-full flex-1 ${
                  f_lifeF === "1" ? "pms-btn-orange" : "oms-btn-darkBlue"
                }`}
                // 必須有表身才可以覆核
                disabled={s_tbData.length > 0 ? s_disabled.strikeCheck : true}
                onClick={strikeCheck}
              >
                {/* {f_lifeF === "1" ? "取消覆核" : "沖帳覆核"} */}
                {f_lifeF === "1"
                  ? t("OMS.util.cancel") + t("util.check")
                  : t("OMS.ap-manage.strike_lifeF_1")}
              </Button>
              {/* 確認沖帳 */}
              <Button
                className="h-full flex-1 oms-btn-darkBlue"
                // 必須有表身才可以覆核
                disabled={s_disabled.confirmStrike}
                onClick={confirmStrike}
              >
                {t("OMS.ap-manage.confirmStrike")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        call_getStrikeTB={call_getStrikeTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
        hd_values={form.getFieldsValue()}
      />

      {/* 引用對帳單 */}
      <CustomModal
        width="80%"
        title={t("OMS.util.take") + t("OMS.ap-manage.ap")} // 引用對帳單
        open={is_showTake}
        onCancel={() => set_is_showTake(false)}
        afterClose={() => {
          const hd_values = form.getFieldsValue();
          call_getStrikeTB.request({ opUUID: hd_values.opUUID });
        }}
      >
        {is_showTake ? (
          <TakeApModal
            set_is_showTake={set_is_showTake}
            hd_values={form.getFieldsValue()}
            call_getStrikeHD={call_getStrikeHD}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
