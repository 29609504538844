import React, { useEffect, useContext } from "react";
import { Form, Row, Col, Select, Typography, Input, Divider, Button, message } from "antd";
import { useTranslation } from "react-i18next";
// component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { MainPageContext } from "../../MainPage/index";
// API
import useAPI from "@/hooks/useAPI";
import { addOrdADD, updateOrdADD } from "@/service/apis/OMS/Ord";

const { Text } = Typography;

const AddaModal = ({ s_tbModal, set_s_tbModal }) => {
  const { call_getOrdADD, hd_values, s_Adds, set_s_ordTb, s_disabled } =
    useContext(MainPageContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  // useAPI 新增條款註記
  const call_addOrdADD = useAPI(addOrdADD);
  // useAPI 編輯條款註記
  const call_updateOrdADD = useAPI(updateOrdADD);
  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

    if (type === "create") {
      // step1 call API新建條款註記
      call_addOrdADD.request(values);
    }

    if (type === "edit") {
      // step1 call API更新條款註記
      call_updateOrdADD.request(values);
    }
  };
  // 改變下拉選單
  const changeAdda = (adda) => {
    form.setFieldsValue({
      statement: adda,
    });
  };

  // 第一次渲染賦值
  useEffect(() => {
    const formValues = {
      ...s_tbModal.data,
      addtype: "adda",
      opUUID: hd_values.opUUID,
      itemno: s_tbModal.data.itemno,
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 新增提款註記
  useEffect(() => {
    const { status, msg } = call_addOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "adda",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addOrdADD.status]);

  // useAPI 編輯提款註記
  useEffect(() => {
    const { status, msg } = call_updateOrdADD;
    if (status === "suc") {
      message.success(msg);
      call_getOrdADD.request({
        "addtype": "adda",
        "opUUID": hd_values.opUUID,
      });
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateOrdADD.status]);

  // useAPI 取得條款註記
  useEffect(() => {
    const { data, msg, status } = call_getOrdADD;
    if (status === "suc") {
      set_s_ordTb((prev) => ({
        ...prev,
        adda: data.adda ?? [],
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdADD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="addtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
      </div>
      {/* <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item name="selectstatement">
            <Select
              onChange={changeAdda}
              options={s_Adds.adda}
              placeholder={t("util.placeholder_select") + t("OMS.ord.adda")}
            />
          </Form.Item>
        </Col>
      </Row> */}

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item
            name="statement"
            // 條款註記
            label={<Text disabled={s_disabled["adda_statement"]}>{t("OMS.ord.adda")}</Text>}
          >
            {/* <Input
              // disabled={s_disabled["adda_statement"]}
              disabled
              placeholder={t("util.placeholder_input") + t("OMS.ord.adda")}
            /> */}
            <Select
              disabled
              options={[
                { label: t("OMS.ord.adda_info"), value: "info" },
                { label: t("OMS.ord.adda_qty"), value: "qty" },
                { label: t("OMS.ord.adda_require"), value: "require" },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={<Text disabled={s_disabled["adda_statement"]}>{t("util.note")}</Text>}
          >
            <Input.TextArea disabled={s_disabled["adda_statement"]} rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />

          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default AddaModal;
