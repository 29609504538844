import React from "react";
import { FrownOutlined } from "@ant-design/icons";
// import Text from "@/components/MC/Text";
const Error = (props) => {
  return (
    <div>
      <FrownOutlined className="text-[50px] text-red-500" twoToneColor="#ee2626" spin />
      <span>沒指定 type阿 小王八蛋 OVO b</span>
      <span> 範例</span>
      <div className="flex ">
        <span>{`<Preview url={span}`}</span>
        <span className="span-red-500">{`type`}</span>
        <span>{`={"Image"}/>  `}</span>
      </div>
    </div>
  );
};

export default Error;
