import { Col, Divider, Grid, Modal, Row, Space, Typography, message, theme } from "antd";
import { DeleteButton, EditButton, SuppCheckButton } from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// context
import { MainPageContext } from "../index";
// component
import TbModal from "../Modal/TbModal";
import { deleteProTB } from "@/service/apis/PMS/Pro";
// util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { useToken } = theme;
const TbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const {
    hd_values,
    s_disabled,
    s_proTb,
    call_getProHD,
    call_getProTB,
    call_updateProHD,
    is_editForm,
    set_is_editForm,
  } = useContext(MainPageContext);
  const { t } = useTranslation();

  const screens = Grid.useBreakpoint();
  const { token } = useToken();
  // useAPI 刪除表身資料
  const call_deleteProTB = useAPI(deleteProTB);

  const flagMap = {
    currencytype: t("PMS.util.currencytype"),
    exchangerate: t("PMS.util.exchangerate"),
    driID: t("PMS.pro.driID"),
    proD: t("PMS.pro.proT"),
    startEDT: t("PMS.pro.startEDT"),
    endEDT: t("PMS.pro.endEDT"),
    payment: t("PMS.pro.payment"),
    trade: t("PMS.pro.trade"),
    taxtype: t("PMS.util.taxtype"),
    demandDate: t("PMS.req.demandDate"),
    suppID: t("PMS.util.suppID"),
  };

  // 編輯資料
  const editData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("PMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          //2023/03/22 先不加i18n，寫法優化後再加
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {/* {is_editForm.editFlag?.map((item) => item)?.join(",")} */}
              {is_editForm.editFlag?.map((item) => flagMap[item])?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateProHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          set_s_tbModal({ show: false, data: rowData });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: rowData });
  };

  // 刪除資料
  const removeData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("PMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          //2023/03/22 先不加i18n，寫法優化後再加
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            {is_editForm.editFlag?.map((item) => t(`PMS.pro.${item}`))?.join(",")}
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateProHD.request(hd_values);
        },
      });
    } else {
      Modal.confirm({
        title: t("PMS.pro.tb") + t("util.delete") + t("util.ok"), // 採購明細刪除確認
        icon: <ExclamationCircleOutlined />,
        // content: "請先確認要刪除的明細資料無誤，再進行刪除",
        content: t("PMS.util.del_tb_confirm_text"),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 刪除表身
          call_deleteProTB.request(rowData);
        },
      });
    }
  };

  // 更新表頭的API
  useEffect(() => {
    const { status, msg } = call_updateProHD;
    if (status === "suc") {
      message.success(msg);
      // 更新完成後打開跳顯讓使用者編輯
      set_s_tbModal((prev) => ({
        show: true,
        data: prev.data,
      }));
      // 清除編輯記錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateProHD.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteProTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getProHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getProTB.request({ opUUID: hd_values.opUUID });
    }
  }, [call_deleteProTB.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: t("PMS.pro.unitprice"), // 未稅單價
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.localprice"), // 本幣單價
      dataIndex: "localprice",
      align: "right",
      width: "7%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.pro.demandqty"), // 採購數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.pro.punit"), // 採購單位
      dataIndex: "punit",
      align: "center",
      width: "7%",
    },
    {
      title: t("PMS.pro.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.pro.confirmqty"), // 廠商確認數量
      dataIndex: "confirmqty",
      align: "right",
      width: "8%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.pro.returnqty"), // 退出數量
      dataIndex: "returnqty",
      align: "right",
      width: "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.pro.cancelqty"), // 取消數量
      dataIndex: "cancelqty",
      align: "right",
      width: "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 廠商確認數量 */}
          <SuppCheckButton disabled /> {/* {s_disabled.confirmqty} */}
          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯表身資料 */}
          <EditButton
            // onClick={() => set_s_tbModal({ show: true, data: rowData })}
            onClick={() => editData(rowData)}
          />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data)?.length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-1 flex-col justify-between">
      <CustomTable
        columns={tableColumns}
        rowNumber={3}
        dataSource={s_proTb.tb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#6A68A1]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("PMS.util.itemno_total")}: </span>
          <span className="self-center">{s_proTb.tb?.length ?? 0}</span>
        </Col>

        <Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總加扣款項 */}
          <span className="self-center">{t("PMS.pro.charge")}:</span>
          <span className="self-center">{formatAmount(hd_values.charge)}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("PMS.pro.sumtotal")}:</span>
          <span className="self-center">{formatAmount(hd_values.sumtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("PMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(hd_values.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 本幣含稅總金額 */}
          <span className="self-center">{t("PMS.pro.local_subtotal")}:</span>
          <span className="self-center">
            {formatAmount(hd_values.total * hd_values.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("PMS.pro.local_total")}:</span>
          <span className="self-center"> {formatAmount(hd_values.total)}</span>
        </Col>
      </Row>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-center mr-5">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯採購明細" : "新增採購明細"} */}
              {type === "edit" ? t("util.edit") + t("PMS.pro.tb") : t("util.add") + t("PMS.pro.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
                ：{hd_values.N} {t("PMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        {s_tbModal.show && <TbModal s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}
      </CustomModal>
    </div>
  );
};

export default TbContainer;
