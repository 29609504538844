import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { getStaffPerm } from "@/service/apis/ADM/userManage";
import useAPI from "@/hooks/useAPI";
import CustomTable from "@/components/CustomAntd/BPM/CustomTable";

const TagDrawer = ({ set_is_showDrawer, s_roleID, set_s_roleID }) => {
  const { t } = useTranslation();

  const call_getStaffPerm = useAPI(getStaffPerm);

  //表頭欄位
  const tableColumns = [
    {
      title: t("ADM.user-manage.account"), //員工帳號
      dataIndex: "account",
      align: "center",
    },
    {
      title: t("ADM.user-manage.peopleNM"), //員工姓名
      dataIndex: "peopleNM",
      align: "center",
    },
  ];
  const [s_tableData, set_s_tableData] = useState([]);

  useEffect(() => {
    call_getStaffPerm.request({ role: s_roleID });
  }, [s_roleID]);
  console.log(s_tableData);
  useEffect(() => {
    if (call_getStaffPerm.status === "suc") {
      message.success(call_getStaffPerm.msg);
      const _tableData = call_getStaffPerm.data?.map((StaffPerm, index) => ({
        ...StaffPerm,
        key: index,
      }));
      set_s_tableData(_tableData);
    } else if (call_getStaffPerm.status === "err") {
      message.error(call_getStaffPerm.msg);
    }
  }, [call_getStaffPerm.status]);
  return (
    <div className="h-full flex " onClick={() => set_is_showDrawer(false)}>
      <CustomTable
        // bordered={true}
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getStaffPerm.status === "load"}
        pagination={false}
      />
    </div>
  );
};

export default TagDrawer;
