/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Spin,
  Grid,
  Tooltip,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Space,
  Typography,
  Select,
  InputNumber,
  Button,
  message,
  Modal,
} from "antd";
import { SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import qs from "qs";
import { useTranslation } from "react-i18next";
// util component
import PageHeader from "@/components/PageHeader";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import ContactButton from "@/components/ERP/OMS/ContactButton";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
// component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import DetailContainer from "./Details/DetailContainer";
import OrdModal from "./Modal/OrdModal";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getFodHD, getFodTB, addFodHD, updateFodHD, fodToOrd } from "@/service/apis/OMS/Fod";
import { getAdd } from "@/service/apis/OMS/Ord";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  dealN: true,
  currencytype: true,
  exchangerate: true,
  exchangeD: true,
  taxtype: true,
  taxrate: true,
  throwOrd: true, //拋轉銷貨單
  custID: true,
  payment: true, // 付款方式
  trade: true, // 貿易條件
  reqSearch: true, //請購紀錄查詢
  instockSearch: true, //進料紀錄查詢
  dealaddr: true, //客戶地址
  driID: true, //負責採購
  startEDT: true, //預計交貨日期(起)
  endEDT: true, //預計交貨日期(迄)
  suppTradeSearch: true, //客戶交易查詢
  dealcontact: true, //客戶業務窗口(窗口)
  dealtel: true, //客戶業務窗口(電話)
  fodCheck: true, //預訂覆核
  custCheck: true, //客戶簽回
  contact: true, //客戶交貨窗口(窗口)
  tel: true, //客戶交貨窗口(電話)
  note: true, //採購註記
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 客戶確認數量
  tb_delete: true,
  unitprice: true,
  utype: true,
  demandqty: true,
  tb_note: true,
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getFodHD = useAPI(getFodHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getFodHD = useAPI(getFodHD);
  // useAPI 取得下拉選單
  const call_getAdd = useAPI(getAdd);
  // useAPI 取得表身資料
  const call_getFodTB = useAPI(getFodTB);
  // 表身在編輯之前用來更新表頭
  const call_updateFodHD = useAPI(updateFodHD);
  // 點擊儲存時修改表頭資料
  const call_finish_updateFodHD = useAPI(updateFodHD);
  // useAPI 預訂覆核
  const call_fodCheck_updateFodHD = useAPI(updateFodHD);
  // useAPI 客戶簽回
  const call_custCheck_updateFodHD = useAPI(updateFodHD);
  // useAPI 取得上一筆資料
  const call_prev_updateFodHD = useAPI(updateFodHD);
  // useAPI 取得下一筆資料
  const call_next_updateFodHD = useAPI(updateFodHD);
  // 新建表頭資料
  const call_addFodHD = useAPI(addFodHD);
  // 拋轉銷貨單
  const call_fodToOrd = useAPI(fodToOrd);

  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_custID = Form.useWatch("custID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  const f_currencytype = Form.useWatch("currencytype", form);
  const f_total = Form.useWatch("total", form);
  // 用來紀錄表頭是否修改過，以及修改了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  const { c_userData } = useContext(SystemContext);
  // 客戶資料、人員資料
  const { c_custs, c_staffs, c_currencys, c_taxtypes } = useContext(ERPContext);
  const { mainPage, fod_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 是否顯示拋轉跳顯的開關
  const [is_showOrd, set_is_showOrd] = useState(false);
  // 表身資料
  const [s_fodTb, set_s_fodTb] = useState({
    tb: [],
    // adda: [], // 條款註記
    // addb: [], // 加扣款項
    // addc: [], // 預付作業
  });
  // 下拉選單
  const [s_Adds, set_s_Adds] = useState({
    adda: [], // 條款註記下拉選單
    addb: [], // 加扣款項下拉選單
    addc: [], // 預付作業下拉選單
    addd: [], // 付款方式下拉選單
    adde: [], // 貿易條件下拉選單
  });

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (editMode) {
      call_finish_updateFodHD.request(values);
    } else {
      call_addFodHD.request({
        ...values,
        // 如果是據此創建就要連表身一起給
        tb: Object.keys(s_oriData).length > 0 ? s_fodTb.tb : [],
      });
    }

    set_s_oriData({});
  };

  // 選擇幣別
  const currencytypeChange = (value) => {
    const currency_obj = c_currencys.data?.find((item) => {
      return item.currencyitem === value;
    });

    // 幣別如果是本幣，匯率要設成1
    if (currency_obj?.ismycurrency === true) {
      form.setFieldsValue({
        exchangerate: 1,
      });

      if (s_fodTb.tb.length === 0) return;

      // 表身的本幣金額要重算
      calculate_localprice(1);
    }
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_fodTb.tb.length === 0) return;

    const tb = s_fodTb.tb.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_fodTb({
      ...s_fodTb,
      tb: tb,
    });
  };

  // 選擇稅別
  const taxtypeChange = (value) => {
    const tax_obj = c_taxtypes.data?.find((item) => item.taxtype === value);
    // 只有tax_obj.disabled為false才可以編輯
    set_s_disabled({
      ...s_disabled,
      taxrate: tax_obj.disabled,
    });

    // 將稅別的稅率塞進表單
    form.setFieldsValue({
      taxrate: tax_obj.taxrate,
    });

    // 稅別發生改變時要重新計算表身稅務資訊
    change_tb_tax(tax_obj.taxrate);
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_fodTb.tb.map((item) => {
      // 單項金額 = (客戶確認數量-取消數量)*未稅單價，這邊的客戶確認數量預設使用採購數量
      const itemsum = (item.confirmqty - item.cancelqty - item.returnqty) * item.unitprice;
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_fodTb({
      ...s_fodTb,
      tb: tb,
    });
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      // 'createID': storeData?.userData?.staffID,
      // 'driID': storeData?.userData?.staffID,
      opUUID: "",
      preopUUID: "",
      startEDT: dayjs(),
      endEDT: dayjs(),
      exchangeD: dayjs(),
      lifeFT: null,
      createT: null,
      approveT: null,
      signT: null,
      confirmID: "",
      confirmT: null,
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_fodTb,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_fodTb({
      ...s_fodTb,
      tb: s_fodTb.tb?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          returnqty: 0,
          cancelqty: 0,
        };
      }),
    });
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      dealaddr: cust_obj.addr,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有下位資料(銷貨單)
    const hasOrd = hd_values.ordCount > 0;
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        dealN: false,
        currencytype: false,
        exchangerate: false,
        exchangeD: false,
        taxtype: false,
        //------------
        custID: false,
        payment: false,
        trade: false,
        // 銷貨紀錄查詢
        ordSearch: !hasOrd,
        //------------
        driID: false,
        startEDT: false,
        endEDT: false,
        //------------
        dealcontact: false,
        dealtel: false,
        fodCheck: false, // 預訂覆核
        //------------
        contact: false,
        tel: false,
        note: false,
        // ------------ 表身 ------------
        // 建立表身的按鈕(據此創建可以基於舊資料再新建新的表身)
        createBtn: isCreateByThis ? false : isNewdoc,
        PN: false,
        unitprice: false,
        demandqty: false,
        tb_note: false,
        tbSubmit: false, // 所有表身跳顯內的確認按鈕
        tb_edit: false, // 所有表身的編輯
        tb_delete: false, // 所有表身的刪除
        // ------------ 條款註記 ------------
        adda_statement: false,
        // ------------ 加扣款項 ------------
        addb_statement: false,
        addb_amount: false,
        // ------------ 預付作業 ------------
        addc_statement: false,
        addc_amount: false,
      });
    }
    // 訂購覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: !hasOrd,
        fodCheck: false, // 預訂覆核
        custCheck: false, // 客戶簽回
        // ------------ tb ------------
      });
    }
    // 客戶簽回
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 拋轉銷貨單
        throwOrd: false,
        // 銷貨紀錄查詢
        ordSearch: !hasOrd,
        // 客戶簽回 or 取消簽回 => 有下位資料就不可編輯
        custCheck: hasOrd,
        // 退出處置
        goStock: hd_values.stockCount > 0 ? false : true,
        // 客戶確認數量
        confirmqty: false,
      });
    }
    // 結案
    if (hd_values.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        // 銷貨紀錄查詢
        ordSearch: !hasOrd,
      });
    }
  };

  // 查詢下位資料
  const go_ord = () => {
    const params_obj = {
      opcode: "detailSearch",
      preopUUID: mainPage.data?.opUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/ord?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateFodHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === fod_Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateFodHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = fod_Ns.findIndex((item) => item === N);

    return index;
  };

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_fodTb.tb?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總加扣款項(預定單預設為0)
    const addb = 0;
    // 未稅總金額
    const sumtotal = itemtotal + addb;
    // 總稅額
    const tax = (sumtotal * f_taxrate) / 100;
    // 總金額 = 單項金額(未稅) + 總加扣款項 + 總稅額
    const total = itemtotal + addb + tax;

    form.setFieldsValue({
      charge: addb,
      itemtotal,
      sumtotal,
      tax,
      total,
    });
  };

  // 預訂覆核 or 取消預訂覆核
  const fodCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "2" ? "取消預訂覆核確認" : "預訂覆核確認"}`,
      title:
        f_lifeF === "2"
          ? t("OMS.util.cancel") + t("OMS.fod.lifeF_2") + t("util.ok")
          : t("OMS.fod.lifeF_2") + t("util.ok"),
      icon: <ExclamationCircleOutlined className="color-red-500" />,
      content:
        f_lifeF === "2"
          ? t("OMS.fod.cancel_fodCheck_confirm_text")
          : t("OMS.fod.fodCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_fodCheck_updateFodHD.request({
          ...values,
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 客戶簽回 or 取消客戶簽回
  const custCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "7" ? "取消客戶簽回確認" : "客戶簽回確認"}`,
      title:
        f_lifeF === "7"
          ? t("util.cancel") + t("OMS.fod.lifeF_7") + t("util.ok")
          : t("OMS.fod.lifeF_7") + t("util.ok"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t(`OMS.fod.${f_lifeF === "7" ? "cancel_" : ""}custCheck_confirm_text`),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_custCheck_updateFodHD.request({
          ...values,
          lifeF: f_lifeF === "7" ? "2" : "7",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 取得下拉選單
    call_getAdd.request();

    // 新建採購單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});

      // 設定表單的起始值
      form.setFieldsValue({
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        // 匯率預設1
        exchangerate: 1,
        lifeF: "0",
        lifeFT: dayjs(),
        taxtype: "PPN_11%",
        taxrate: 11,
        // trade: "FOB",
        payment: "銀行轉帳",
        // 預設登入人員
        createID: c_userData?.staffID,
        driID: c_userData?.staffID,
        startEDT: dayjs(),
        endEDT: dayjs(),
        createT: dayjs(),
        exchangeD: dayjs(),
      });
    } else {
      // 取得表身【採購明細】
      call_getFodTB.request({ opUUID: mainPage.data?.opUUID });

      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // useAPI 取得下拉選單
  useEffect(() => {
    const { status, msg, data } = call_getAdd;
    if (status === "suc") {
      const { adda, addb, addc, addd, adde } = data;
      console.log("data = ", data);
      // format成下拉選單可用的
      const getOptions = (oriData) => {
        return (
          oriData?.map((item) => ({
            label: item.itemNM,
            value: item.itemNM,
          })) ?? []
        );
      };
      set_s_Adds({
        adda: getOptions(adda), // 條款註記下拉選單
        addb: getOptions(addb), // 加扣款項下拉選單
        addc: getOptions(addc), // 預付作業下拉選單
        addd: getOptions(addd), // 付款方式下拉選單
        adde: getOptions(adde), // 貿易條件下拉選單
      });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getAdd.status]);

  useEffect(() => {
    calculateMoneyInfo();
  }, [MainPage.data, f_exchangerate, f_taxrate, s_fodTb.tb]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getFodHD;
    if (status === "suc") {
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      console.log("newData = ", newData);
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
      // 更新mainPage資料
      updateState({ mainPage: { show: true, data: newData } });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getFodHD.status]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getFodTB;
    if (status === "suc") {
      const new_tb = data?.map((item, i) => ({ ...item, key: i }));
      set_s_fodTb((prev) => ({
        ...prev,
        tb: new_tb,
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_fodTb((prev) => ({
        ...prev,
        tb: [],
      }));
    }
  }, [call_getFodTB.status]);

  // useAPI 新建表頭資料
  useEffect(() => {
    const { status, data, msg } = call_addFodHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getFodHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getFodTB.request({ opUUID: data.opUUID });
      }
      // 清空表頭編輯紀錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_addFodHD.status]);

  // useAPI 儲存表頭
  useEffect(() => {
    const { status, data, msg } = call_finish_updateFodHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getFodHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getFodTB.request({ opUUID: data.opUUID });
      }
      // 清空表頭編輯紀錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateFodHD.status]);

  // useAPI 預訂單覆核
  useEffect(() => {
    const { data, status, msg } = call_fodCheck_updateFodHD;
    if (status === "suc") {
      // message.success(data.lifeF === "2" ? "覆核成功" : "取消覆核成功");
      message.success(
        data.lifeF === "2"
          ? t("util.check") + t("util.success")
          : t("OMS.util.cancel") + t("util.check") + t("util.success")
      );
      // 更新表頭
      call_getFodHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_fodCheck_updateFodHD.status]);

  // useAPI 客戶簽回
  useEffect(() => {
    const { status, msg, data } = call_custCheck_updateFodHD;
    if (status === "suc") {
      // message.success(data.lifeF === "7" ? "客戶簽回成功" : "取消客戶簽回成功");
      message.success(
        data.lifeF === "7"
          ? t("OMS.fod.lifeF_7") + t("util.success")
          : t("OMS.util.cancel") + t("OMS.fod.lifeF_7") + t("util.success")
      );
      // 更新表頭
      call_getFodHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_custCheck_updateFodHD.status]);

  // useAPI 拋轉銷貨單
  useEffect(() => {
    const { status, msg } = call_fodToOrd;
    if (status === "suc") {
      message.success(msg);

      // 更新表頭資料
      call_getFodHD.request({ "opUUID": mainPage.data.opUUID });
      // 更新表身資料
      call_getFodTB.request({ "opUUID": mainPage.data.opUUID });
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_fodToOrd.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateFodHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getFodHD.request({ N: fod_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateFodHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateFodHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      message.success(t("OMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getFodHD.request({ N: fod_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      message.success(t("OMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateFodHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getFodHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getFodTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getFodHD.status]);

  // useAPI 取得表身資料 =>【採購明細】、【條款註記】、【加扣款項】、【預付作業】
  // useEffect(() => {
  //   const { status, msg, data } = call_getFodTB;
  //   console.log("data = ", data);
  //   if (status === "suc") {
  //     set_s_fodTb({
  //       tb: data?.tb ?? [],
  //       adda: data.adda ?? [],
  //       addb: data.addb ?? [],
  //       addc: data.addc ?? [],
  //     });
  //     message.success(msg);
  //   }

  //   if (status === "err") {
  //     set_s_fodTb({
  //       tb: [],
  //       adda: [],
  //       addb: [],
  //       addc: [],
  //     });
  //     message.error(msg);
  //   }
  // }, [call_getFodTB.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
        onValuesChange={(changedValues, _) => {
          if (editMode) {
            // 在編輯模式時紀錄表頭修改了哪些欄位
            set_is_editForm((prev) => {
              let editFlag = [...prev.editFlag];
              const [key] = Object.keys(changedValues);

              if (!editFlag.includes(key)) {
                editFlag.push(key);
              }
              return {
                isEdit: true,
                editFlag,
              };
            });
          }
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯預訂主檔" : "新建預訂主檔"} /> */}
            <PageHeader
              title={
                (editMode ? t("util.edit") : t("util.add")) + t("OMS.util.fod")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_fodTb(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>
        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="linkType" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="custNM" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="lifeFT" hidden>
            <DatePicker />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="charge" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="itemtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="sumtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="tax" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="total" hidden>
            <Input />
          </Form.Item>
        </div>
        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col xl={editMode ? 2 : 3} xxl={editMode ? 2 : 3}>
            <Form.Item
              name="opcode"
              label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.opcode") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[{ label: t("OMS.util.opcode_1210"), value: "1210" }]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col xl={editMode ? 2 : 3} xxl={editMode ? 3 : 3}>
            <Form.Item name="N" label={<Text disabled>{t("OMS.util.N")}</Text>}>
              <Input disabled className="border border-[#4493e3] border-solid" />
            </Form.Item>
          </Col>
          {/* 客戶單號 */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              name="dealN"
              label={<Text disabled={s_disabled.dealN}>{t("OMS.util.dealN")}</Text>}
            >
              <Input
                placeholder={t("util.placeholder_select") + t("OMS.util.dealN")}
                disabled={s_disabled.dealN}
                className="border border-[#4493e3] border-solid"
              />
            </Form.Item>
          </Col>
          {/* 幣別 */}
          <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="flex gap-3">
            <Form.Item
              label={<Text disabled={s_disabled.currencytype}>{t("OMS.util.currencytype")}</Text>}
              name="currencytype"
              className="flex-1"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.currencytype") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.currencytype")}
                disabled={s_disabled.currencytype}
                onChange={currencytypeChange}
                options={c_currencys.options}
              />
            </Form.Item>

            <Form.Item dependencies={["currencytype"]} className="flex-1">
              {() => {
                // 如果幣別為『本幣』，匯率要disabled，且匯率為1
                const currencytype = form.getFieldValue("currencytype");
                const ismycurrency =
                  c_currencys.data?.find((item) => {
                    return item.currencyitem === currencytype;
                  })?.ismycurrency ?? true;

                return (
                  <Form.Item
                    label={
                      <Text disabled={s_disabled.exchangerate || ismycurrency}>
                        {t("OMS.util.exchangerate")}
                      </Text>
                    }
                    rules={[{ required: true }]}
                    messageVariables={{ label: t("OMS.util.exchangerate") }}
                    name="exchangerate"
                    // className="mb-0"
                    disabled={s_disabled.exchangerate || ismycurrency}
                  >
                    <InputNumber
                      // placeholder="請輸入匯率"
                      className="w-full"
                      disabled={s_disabled.exchangerate || ismycurrency}
                      onChange={(value) => calculate_localprice(value)}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item
              className="flex-[2]"
              label={<Text disabled={s_disabled.exchangeD}>{t("OMS.util.exchangeD")}</Text>}
              name="exchangeD"
            >
              <DatePicker format="YYYY-MM-DD" className="w-full" disabled={s_disabled.exchangeD} />
            </Form.Item>
          </Col>
          {/* 稅別、稅率 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="flex gap-3">
            <Form.Item
              label={<Text disabled={s_disabled.taxtype}>{t("OMS.util.taxtype")}</Text>}
              name="taxtype"
              className="flex-1"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.taxtype") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.taxtype")}
                disabled={s_disabled.taxtype}
                onChange={taxtypeChange}
                options={c_taxtypes.options}
              />
            </Form.Item>
            {/* 税率 */}
            <Form.Item dependencies={["taxtype"]} className="flex-1">
              {() => {
                const taxtype = form.getFieldValue("taxtype");
                const isDisabled =
                  c_taxtypes.data.find((item) => item.taxtype === taxtype)?.disabled ?? true;

                return (
                  <Form.Item
                    disabled={s_disabled.taxrate || isDisabled}
                    label={<Text disabled={isDisabled}>{t("OMS.util.taxrate")}</Text>}
                    rules={[{ required: true }]}
                    messageVariables={{ label: t("OMS.util.taxrate") }}
                    name="taxrate"
                  >
                    <InputNumber
                      disabled={s_disabled.taxrate || isDisabled}
                      formatter={(value) => `${value}%`}
                      placeholder={t("util.placeholder_input") + t("OMS.util.taxrate")}
                      className="w-full"
                      suffix="%"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("OMS.util.accord")}
                <br />
                {t("OMS.util.create")}
              </Button>
              {/* 拋轉銷貨單 */}
              <Button
                className="flex-1 h-full oms-btn-darkBlue"
                onClick={() => set_is_showOrd(true)}
                disabled={s_disabled.throwOrd}
              >
                {t("OMS.util.throw")}
                <br />
                {t("OMS.util.ord")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶ID */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              name="custID"
              label={<Text disabled={s_disabled.custID}>{t("OMS.util.custID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.custID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.custID}
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                onChange={onCustChange}
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          {/* 客戶簡稱 */}
          <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.custalias")}</Text>} name="custalias">
              <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.custID")} />
            </Form.Item>
          </Col>
          {/* 付款方式 */}
          <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.payment}>{t("OMS.fod.payment")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.fod.payment") }}
              name="payment"
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.fod.payment")}
                disabled={s_disabled.payment}
                options={s_Adds.addd}
              />
            </Form.Item>
          </Col>
          {/* 貿易條件 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.trade}>{t("OMS.fod.trade")}</Text>}
              name="trade"
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.fod.trade")}
                disabled={s_disabled.trade}
                allowClear
                options={s_Adds.adde}
              />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className="h-full flex-1 pms-btn-blue-outline"
                onClick={() => go_ord()}
                disabled={s_disabled.ordSearch}
              >
                {/* 銷貨紀錄查詢 */}
                {t("OMS.util.ordSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶地址 */}
          <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12}>
            <Form.Item
              label={<Text disabled={s_disabled.dealaddr}>{t("OMS.fod.dealaddr")}</Text>}
              name="dealaddr"
            >
              <Input
                disabled={s_disabled.dealaddr}
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
              />
            </Form.Item>
          </Col>
          {/* 負責業務 */}
          <Col xl={editMode ? 3 : 4} xxl={editMode ? 4 : 4}>
            <Form.Item
              label={<Text disabled={s_disabled.driID}>{t("OMS.fod.driID")}</Text>}
              name="driID"
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.fod.driID")}
                disabled={s_disabled.driID}
                showSearch
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
          {/* 預計交貨日期(起) */}
          <Col xl={editMode ? 4 : 5} xxl={editMode ? 3 : 4}>
            <Form.Item
              // 預計交貨日期(起)
              label={<Text disabled={s_disabled.startEDT}>{t("OMS.util.startEDT")}</Text>}
              name="startEDT"
            >
              <DatePicker disabled={s_disabled.startEDT} className="w-full" />
            </Form.Item>
          </Col>
          {/* 預計交貨日期(迄) */}
          <Col xl={editMode ? 4 : 5} xxl={editMode ? 3 : 4}>
            <Form.Item
              // 預計交貨日期(迄)
              label={<Text disabled={s_disabled.endEDT}>{t("OMS.util.endEDT")}</Text>}
              name="endEDT"
            >
              <DatePicker disabled={s_disabled.endEDT} className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button className="w-full h-full" disabled>
                {/* 客戶交易歷史查詢 */}
                {t("OMS.fod.custHistory")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶採購窗口 */}
          <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="mt-1">
            {/* 客戶採購窗口 */}
            <Form.Item shouldUpdate label={<Text>{t("OMS.fod.dealcontact")}</Text>}>
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      custID={form.getFieldValue("custID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          dealcontact: contact_obj?.contact,
                          dealtel: contact_obj?.tel,
                        });
                      }}
                      // title="選擇客戶業務窗口"
                      title={t("util.placeholder_select") + t("OMS.fod.dealcontact")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("OMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="dealcontact" className="flex-1 mb-0px" shouldUpdate>
                      <Input
                        disabled={s_disabled.dealcontact}
                        className="flex-1"
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("OMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="dealtel" className="flex-1 mb-0px">
                      <Input
                        disabled={s_disabled.dealtel}
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 核准日期 */}
          <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
            <Form.Item label={<Text disabled>{t("OMS.util.approveT")}</Text>} name="approveT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {/* 簽回日期 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
            <Form.Item label={<Text disabled>{t("OMS.util.signT")}</Text>} name="signT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className={`h-full flex-1 ${
                  f_lifeF === "2" ? "pms-btn-orange" : "oms-btn-darkBlue"
                }`}
                disabled={
                  // 沒有表身時不可以覆核
                  s_fodTb.tb.length === 0 ? true : s_disabled.fodCheck
                }
                onClick={fodCheck}
              >
                {/* {f_lifeF === "2" ? "取消覆核" : "預訂覆核(1)"} */}
                {f_lifeF === "2"
                  ? t("util.cancel") + t("util.check")
                  : `${t("OMS.fod.lifeF_2")}(1)`}
              </Button>
              <Button
                className={`h-full flex-1 ${f_lifeF === "7" ? "pms-btn-red" : "oms-btn-darkBlue"}`}
                onClick={custCheck}
                disabled={s_disabled.custCheck}
              >
                {/* {f_lifeF === "7" ? "取消簽回" : "客戶簽回(2)"} */}
                {f_lifeF === "7"
                  ? t("util.cancel") + t("OMS.util.signBack")
                  : `${t("OMS.fod.lifeF_7")}(2)`}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12}>
            {/* 客戶交貨窗口 */}
            <Form.Item label={<Text>{t("OMS.fod.contact")}</Text>} shouldUpdate>
              {() => (
                <Row gutter={[12, 0]}>
                  <Col span={12} className="flex items-center">
                    <ContactButton
                      disabled={s_disabled.contact}
                      custID={form.getFieldValue("custID")}
                      chooseContact={(contact_obj) => {
                        form.setFieldsValue({
                          contact: contact_obj?.contact,
                          tel: contact_obj?.tel,
                        });
                      }}
                      title={t("util.placeholder_select") + t("OMS.fod.contact")}
                    />
                    {/* 窗口 */}
                    <span className="ml-2">{t("OMS.util.window")}:&nbsp;&nbsp;</span>

                    <Form.Item name="contact" className="flex-1" shouldUpdate>
                      <Input
                        disabled={s_disabled.contact}
                        className="flex-1"
                        placeholder={
                          f_custID
                            ? t("util.placeholder_input") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} className="flex items-center">
                    {/* 電話 */}
                    <span>{t("OMS.util.tel")}:&nbsp;&nbsp;</span>

                    <Form.Item name="tel" className="flex-1">
                      <Input
                        disabled={s_disabled.tel}
                        placeholder={
                          f_custID
                            ? t("util.placeholder_select") + t("OMS.util.tel")
                            : t("util.placeholder_select") + t("OMS.util.custID")
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Col>
          {/* 覆核人員 */}
          <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
            <Form.Item label={<Text disabled>{t("OMS.util.confirmID")}</Text>} name="confirmID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 覆核日期 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
            <Form.Item label={<Text disabled>{t("OMS.util.confirmT")}</Text>} name="confirmT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button disabled className="flex-1 h-full">
                {/* 上傳回簽 */}
                {t("OMS.util.UploadSign")}
              </Button>
              <Button disabled className="flex-1 h-full">
                {/* 上傳簽呈 */}
                {t("OMS.util.UploadSignature")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 註記  */}
          <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="pb-2">
            <Form.Item
              name="note"
              label={<Text disabled={s_disabled.note}>{t("OMS.fod.note")}</Text>}
            >
              <Input disabled={s_disabled.note} />
            </Form.Item>
          </Col>

          {/* 創建人員 */}
          <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="pb-2 bg-gray-100">
            <Form.Item label={<Text disabled>{t("OMS.util.createID")}</Text>} name="createID">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* 創建日期 */}
          <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
            <Form.Item label={<Text disabled>{t("OMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l flex border-gray-300 border-solid py-2"
            >
              <Button disabled className="flex-1 h-full">
                {/* 審核流程(3) */}
                {t("OMS.fod.reviewFlow")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <DetailContainer
        call_getFodHD={call_getFodHD}
        call_getFodTB={call_getFodTB}
        call_updateFodHD={call_updateFodHD}
        s_disabled={s_disabled}
        is_editForm={is_editForm}
        set_is_editForm={set_is_editForm}
        s_fodTb={s_fodTb}
        s_Adds={s_Adds}
        set_s_fodTb={set_s_fodTb}
        hd_values={form.getFieldsValue()}
      />

      {/* 拋轉銷貨單-跳顯 */}
      <CustomModal
        width="90%"
        title={t("OMS.util.throw") + t("OMS.util.ord")} // 拋轉銷貨單
        open={is_showOrd}
        onCancel={() => {
          set_is_showOrd(false);
        }}
      >
        {is_showOrd ? (
          <OrdModal
            hd_values={form.getFieldsValue()}
            set_is_showOrd={set_is_showOrd}
            s_fodTb={s_fodTb}
            call_fodToOrd={call_fodToOrd}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
