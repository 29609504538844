import { Button, Divider, Form, InputNumber, Select, Spin, Upload, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { IconConfirm } from "@/components/Icon/Action";
// api
import useAPI from "@/hooks/useAPI";
import { getInvTb, importInvExcel, updateInvTbQty } from "@/service/apis/WMS/Inventory";
// util
import { formatAmount } from "@/util/format";
import { FileAddOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #6b6868;
    border-radius: 2px;
  }
`;

export const generateTableColumn = (t, s_invCheck) => {
	return [
		{
			title: "序",
			dataIndex: "itemno",
			width: "4%",
			align: "center",
			// render: (text, rowData, index) => index + 1,
		},
		{
			title: t("util.util.N"),
			dataIndex: "N",
			width: "10%",
			isExcel: true,
			align: "center",
		},
		{
			title: t("util.util.PN"),
			dataIndex: "PN",
			width: "20%",
			isExcel: true,
			align: "center",
		},
		{
			title: "尺寸規格",
			dataIndex: "heirType",
			width: "10%",
			isExcel: true,
			align: "center",
		},
		{
			title: "批號",
			dataIndex: "batchno",
			width: "7%",
			isExcel: true,
			align: "center",
		},
		{
			title: t("util.util.pdtNM"), // 品名
			dataIndex: "pdtNM",
			width: "15%",
			isExcel: true,
			align: "center",
		},
		{
			title: "关帐库存数", // 關帳庫存數
			dataIndex: "onhand",
			align: "right",
			width: "10%",
			isExcel: true,
			render: (text) => formatAmount(text, 2),
		},
		{
			title: "初盘数量", // 初盤數量
			className: "bg-[#F1F3FE] text-[#2092BC]",
			dataIndex: "inv1tqty",
			align: "right",
			isExcel: true,
			width: "7%",
			editable: s_invCheck === "1",
			hidden: s_invCheck === "0",
			render: (text) => formatAmount(text, 2),
		},
		{
			title: "初盘帐差", // 初盤帳差
			dataIndex: "inv1bal",
			className: "bg-[#F1F3FE]",
			align: "center",
			width: "7%",
			hidden: s_invCheck === "0",
			render: (text) => (
				<span className={`${text === 0 ? "" : text > 0 ? "text-[#1DC511]" : "text-[#D50F0F]"}`}>
					{/* {text} */}
					{formatAmount(text, 2)}
				</span>
			),
		},
		{
			title: "复盘数量", // 複盤數量
			dataIndex: "inv2tqty",
			className: "bg-[#FFF3EE] text-[#CF6B14]",
			align: "center",
			width: "7%",
			hidden: (s_invCheck === "0") | (s_invCheck === "1"), // 初盤時不要顯示
			editable: s_invCheck === "2",
			render: (text) => formatAmount(text, 2),
		},
		{
			title: "复盘帐差", // 複盤帳差
			dataIndex: "inv2bal",
			className: "bg-[#FFF3EE]",
			align: "center",
			width: "7%",
			hidden: (s_invCheck === "0") | (s_invCheck === "1"), // 初盤時不要顯示
			render: (text) => (
				<span className={`${text === 0 ? "" : text > 0 ? "text-[#1DC511]" : "text-[#D50F0F]"}`}>
					{formatAmount(text, 2)}
				</span>
			),
		},
	];
};

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const EditableCell = ({
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	s_invCheck,
	...restProps
}) => {
	const [editing, setEditing] = useState(false);
	const inputRef = useRef(null);
	const form = useContext(EditableContext);
	useEffect(() => {
		if (editing) {
			inputRef.current.focus();
		}
	}, [editing]);

	// 切換是否編輯
	const toggleEdit = () => {
		setEditing(!editing);
		form.setFieldsValue({
			[dataIndex]: record[dataIndex],
		});
	};

	// 編輯完成執行
	const save = async () => {
		try {
			const values = await form.validateFields();
			toggleEdit();
			const qtyType = s_invCheck === "1" ? "inv1tqty" : "inv2tqty";
			handleSave(values[qtyType] ?? 0, record.PN);
		} catch (errInfo) {
			console.log("Save failed:", errInfo);
		}
	};

	let childNode = children;

	if (editable) {
		childNode = editing ? (
			<Form.Item
				style={{
					margin: 0,
				}}
				name={dataIndex}
			// rules={[
			//   {
			//     required: true,
			//     // message: `${title} is required.`,
			//   },
			// ]}
			>
				<InputNumber ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "100%" }} />
			</Form.Item>
		) : (
			<div
				className="editable-cell-value-wrap"
				style={{
					paddingRight: 24,
				}}
				onClick={toggleEdit}
			>
				{children}
			</div>
		);
	}

	return <td {...restProps}>{childNode}</td>;
};

const ModalContainer = ({ call_updateInvHd, s_invCheck, s_editData, set_is_showTbModal }) => {
	const { t } = useTranslation();

	const [s_tableData, set_s_tableData] = useState([]);
	const [s_spinning, set_s_spinning] = useState(false);
	// 取得盤點資料
	const call_getInvTb = useAPI(getInvTb);

	// 更新初盤、複盤資料
	const call_updateInvHdQty = useAPI(updateInvTbQty);
	const call_importInvExcel = useAPI(importInvExcel);

	const [s_pageInfo, set_s_pageInfo] = useState({
		currentPage: 0,
		endItemNumber: 0,
		pageItemNumber: 0,
		startItemNumber: 0,
		totalItems: 0,
	});

	const [s_searchData, set_s_searchData] = useState({
		page: 1,
		pageSize: 50,
	});

	useEffect(() => {
		call_getInvTb.request({ opUUID: s_editData.opUUID, opcode: s_editData.opcode, attribute: s_editData.attribute, ...s_searchData });
	}, [s_searchData.page, s_searchData.pageSize]);

	// useAPI 取得盤點資料
	useEffect(() => {
		const { status, msg, data } = call_getInvTb;
		if (status === "load") {
			set_s_spinning(true);
		}
		if (status === "suc") {
			console.log("data = ", data);
			const _data =
				data?.tableData?.map((item, index) => ({
					...item,
					N: s_editData.N,
					key: index + 1,
					realqty: 0,
					bal: 0,
				})) || [];

			set_s_tableData(_data);
			set_s_spinning(false);
			set_s_pageInfo(data.pageInfo);
		}
		if (status === "err") {
			message.error(msg);
			set_s_spinning(false);
		}
	}, [call_getInvTb.status]);

	useEffect(() => {
		const { status, msg, data } = call_updateInvHdQty;
		if (status === "load") {
			set_s_spinning(true);
		}
		if (status === "suc") {
			message.success(msg);
			console.log("data = ", data);
			const _tableData = s_tableData.map((item) => {
				if (item.PN === data.PN && item.pdtNM === data.pdtNM) {
					return {
						...item,
						...data,
					};
				}
				return item;
			});
			set_s_tableData(_tableData);
			set_s_spinning(false);
		}

		if (status === "err") {
			message.error(msg);
			set_s_spinning(false);
		}
	}, [call_updateInvHdQty.status]);

	useEffect(() => {
		const { status, data, msg } = call_importInvExcel;
		if (status === "load") {
			set_s_spinning(true);
		}
		if (status === "suc") {
			message.success(msg);
			call_getInvTb.request({ opUUID: s_editData.opUUID, ...s_searchData });
		}
		if (status === "err") {
			set_s_spinning(false);
		}
	}, [call_importInvExcel.status]);

	const tableColumns = generateTableColumn(t, s_invCheck);

	// 匯入 Excel
	const insertData = (data) => {
		console.log("data = ", data);
		// 建立一個映射，讓我們可以根據PN屬性快速找到數據
		const dataByPN = {};
		const qtyType = s_invCheck === "1" ? "inv1tqty" : "inv2tqty";
		const invBal = s_invCheck === "1" ? "inv1bal" : "inv2bal";

		for (let i = 0; i < data.length; i++) {
			if (!data[i][qtyType] || data[i][qtyType] === 0) {
				message.error(t(`IMS.inv-project.${qtyType}`) + "不可為0");
				return;
			}
			// 構造新的item對象
			let item = {
				...data[i],
				[qtyType]: data[i][qtyType],
				invqty: data[i].invqty,
				[invBal]: data[i].invqty - data[i].onhand,
				bal: data[i].invqty - data[i].onhand,
			};

			// 用PN值作為鍵，存儲在dataByPN對象中
			dataByPN[item.PN] = item;
		}

		// 用map方法高效地遍歷s_tableData，並與dataByPN中的數據合併
		const _tableData = s_tableData.map((item) => {
			// 使用PN屬性快速從dataByPN獲取數據
			const target = dataByPN[item.PN];

			return {
				...item,
				...target, // 如果target為undefined（即dataByPN中沒有對應的PN），這裡會忽略它
			};
		});

		set_s_spinning(false);
		console.log("_tableData = ", _tableData);
		// return;
		set_s_tableData(_tableData);
		message.success(`匯入 ${s_invCheck === "1" ? "初盤" : "複盤"} 成功`);
	};

	const props = {
		name: "file",
		multiple: true,
		fileList: [],
		beforeUpload() {
			return false;
		},
		async onChange({ file }) {
			let formData = new FormData();
			formData.append("file", file);
			formData.append("N", s_editData.N);
			call_importInvExcel.request(formData);
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const handleSave = (qty = 0, PN) => {
		console.log("qty = ", qty);
		console.log("PN = ", PN);
		if (qty <= 0) {
			message.error("盤點數量不可小於0");
			return;
		}

		const qtyType = s_invCheck === "1" ? "inv1tqty" : "inv2tqty";
		const balType = s_invCheck === "1" ? "inv1bal" : "inv2bal";

		const targetItem = s_tableData.find((item) => item.PN === PN);
		if (!targetItem) {
			return;
		}

		const updatedItem = {
			...targetItem,
			editable: true,
			N: s_editData.N,
			[qtyType]: qty, // 初盤使用 “初盤數量” 複盤使用 “複盤數量”
			// [balType]: targetItem.onhand - qty, // 初盤使用 “初盤帳差” 複盤使用 “複盤帳差”
			[balType]: qty - targetItem.onhand, // 初盤使用 “初盤帳差” 複盤使用 “複盤帳差”
			// invqty: targetItem.onhand - qty, // 如果 “複盤帳差” 有值就用“ 複盤帳差” 沒有就用 “初盤帳差”
			invqty: qty - targetItem.onhand, // 如果 “複盤帳差” 有值就用“ 複盤帳差” 沒有就用 “初盤帳差”
		};
		console.log("updatedItem = ", updatedItem);
		// return;
		call_updateInvHdQty.request(updatedItem);

		// const updatedData = s_tableData.map((item) =>
		//   item.itemUUID === itemUUID ? updatedItem : item
		// );

		// set_s_tableData(updatedData);
	};

	const onSubmit = () => {
		if (s_invCheck === "0") {
			call_updateInvHd.request({ ...s_editData, "lifeF": "1" });
		}
		// 初盤
		if (s_invCheck === "1") {
			call_updateInvHd.request({ ...s_editData, "lifeF": "3" });
		}
		// 複盤
		if (s_invCheck === "2") {
			call_updateInvHd.request({ ...s_editData, "lifeF": "5" });
		}
	};

	const columns = tableColumns.map((col) => {
		if (!col.editable) return col;

		return {
			...col,
			onCell: (record) => ({
				record,
				s_invCheck: s_invCheck,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSave,
			}),
		};
	});

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell,
		},
	};

	return (
		<Wrapper>
			<Spin spinning={s_spinning}>
				{s_invCheck !== "0" && s_invCheck !== "3" && (
					<div className="flex mb-2">
						<Upload {...props}>
							<Button
								type="primary"
								className={`${s_invCheck === "2" && "bg-[#E8784E]"}`}
								icon={<FileAddOutlined />}
							>
								{`匯入 ${s_invCheck === "1" ? "初盤" : "複盤"} 盤點表`}
							</Button>
						</Upload>
					</div>
				)}

				<CustomTable
					components={components}
					columns={columns.filter((item) => !item.hidden)}
					dataSource={s_tableData}
					rowClassName="editable-row"
					pagination={{
						total: s_pageInfo?.totalItems,
						current: s_searchData.page,
						pageSize: s_searchData.pageSize,
						onChange: (current, size) => {
							// 如果有更動pageSize 回第一頁
							let checkPage = s_searchData.pageSize !== size ? 1 : current;
							const tmpSearchData = {
								...s_searchData,
								page: checkPage,
								pageSize: size,
							};
							set_s_searchData(tmpSearchData);
							// call_getInvTb.request({ opUUID: s_editData.opUUID, page: checkPage, pageSize: size });
						},
					}}
				/>

				<Divider />

				<div className="flex justify-end gap-2">
					<Button size="large" onClick={() => set_is_showTbModal(false)}>
						{/* 取消 */}
						{t("util.util.cancel")}
					</Button>
					{s_invCheck !== "3" && (
						<Button
							size="large"
							type="primary"
							// 只有初盤根複盤可以按負荷按鈕
							// disabled={s_editData.lifeF === "2" || s_editData.lifeF === "4" ? false : true}
							// loading={call_addInvData.status === "load"}
							icon={<IconConfirm />}
							onClick={onSubmit}
						>
							{/* 初盤確認、複盤確認 */}
							{s_invCheck === "0" ? "覆核" : s_invCheck === "1" ? "初盤確認" : "複盤確認"}
						</Button>
					)}
				</div>
			</Spin>
		</Wrapper>
	);
};

export default ModalContainer;
