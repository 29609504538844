import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
} from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import {
  addOrdHD,
  getAdd,
  getOrdADD,
  getOrdHD,
  getOrdTB,
  updateOrdHD,
} from "@/service/apis/OMS/Ord";

import ContactButton from "@/components/ERP/OMS/ContactButton";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../index";
// component
import DetailContainer from "./Details/DetailContainer";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FileModal from "./Modal/FileModal";
import HiddenFormItem from "./HiddenFormItem";
import { INIT_DISABLED } from "./initValue";
import Light from "@/components/ERP/Light";
import OutstockModal from "./Modal/OutstockModal";
import PageHeader from "@/components/PageHeader";
import PrintDrawer from "./Modal/PrintDrawer/index";
import dayjs from "dayjs";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

// import PrintDrawer from "./Modal/PrintDrawer_test/index";

export const MainPageContext = React.createContext();

const { Text } = Typography;

const MainPage = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getOrdHD = useAPI(getOrdHD);
  // useAPI 取得表身資料
  const call_getOrdTB = useAPI(getOrdTB);
  // useAPI 取得條款註記、加扣款項、預付作業
  const call_getOrdADD = useAPI(getOrdADD);
  // useAPI 取得下拉選單
  const call_getAdd = useAPI(getAdd);
  // useAPI 客戶簽回
  const call_custCheck_updateOrdHD = useAPI(updateOrdHD);
  // 上傳客戶委託
  const call_updateOrdHd = useAPI(updateOrdHD);
  // 新建表頭資料
  const call_addOrdHD = useAPI(addOrdHD);

  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_inspcer = Form.useWatch("inspcer", form);

  const f_custID = Form.useWatch("custID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_proCount = Form.useWatch("proCount", form);
  const f_proLifeF2count = Form.useWatch("proLifeF2count", form);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  const f_currencytype = Form.useWatch("currencytype", form);
  const f_total = Form.useWatch("total", form);
  const f_sumtotal = Form.useWatch("sumtotal", form);
  // 用來紀錄表頭是否修改過，以及修改了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  const { c_userData, c_systemConfig } = useContext(SystemContext);
  const { auth } = c_systemConfig;
  // 客戶資料、人員資料
  const { c_custs, c_staffs, c_currencys } = useContext(ERPContext);
  const { mainPage, ord_Ns, tabKey } = c_state;
  const [s_fileType, set_s_fileType] = useState("");
  const [s_fileTypeName, set_s_fileTypeName] = useState("");
  const [s_showFileModal, set_s_showFileModal] = useState(false);
  const [is_showOutstock, set_is_showOutstock] = useState(false);

  const [s_spinning, set_s_spinning] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 控制列印的 drawer
  const [is_showPrint, set_is_showPrint] = useState(false);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_ordTb, set_s_ordTb] = useState({
    tb: [],
    adda: [], // 條款註記
    addb: [], // 加扣款項
    addc: [], // 預付作業
  });
  // 下拉選單
  const [s_Adds, set_s_Adds] = useState({
    adda: [], // 條款註記下拉選單
    addb: [], // 加扣款項下拉選單
    addc: [], // 預付作業下拉選單
    addd: [], // 付款方式下拉選單
    adde: [], // 貿易條件下拉選單
  });

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (editMode) {
      call_updateOrdHd.request({
        ...values,
        APImethod: "submit",
      });
    } else {
      // console.log("values = ", values);
      // return;
      call_addOrdHD.request({
        ...values,
        // 如果是據此創建就要連表身一起給
        tb: Object.keys(s_oriData).length > 0 ? s_ordTb.tb : [],
      });
    }

    set_s_oriData({});
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_ordTb.tb.length === 0) return;

    const tb = s_ordTb.tb.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_ordTb({
      ...s_ordTb,
      tb: tb,
    });
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_ordTb.tb.map((item) => {
      // 單項金額 = (客戶確認數量-取消數量)*未稅單價，這邊的客戶確認數量預設使用銷貨數量
      const itemsum = (item.confirmqty - item.cancelqty - item.returnqty) * item.unitprice;
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_ordTb({
      ...s_ordTb,
      tb: tb,
    });
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      // 'createID': storeData?.userData?.staffID,
      // 'driID': storeData?.userData?.staffID,
      opUUID: "",
      preopUUID: "",
      EDT: dayjs(),
      startEDT: dayjs(),
      endEDT: dayjs(),
      exchangeD: dayjs(),
      lifeFT: null,
      createT: null,
      approveT: null,
      signT: null,
      confirmID: "",
      confirmT: null,
    };
    // console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    // console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_ordTb,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    change_MainPage_disabled(new_Hd_Data);

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_ordTb({
      ...s_ordTb,
      tb: s_ordTb.tb?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          balqty: 0,
          returnqty: 0,
          cancelqty: 0,
        };
      }),
    });
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
      custNM: cust_obj.custNM,
      dealaddr: cust_obj.addr,
      contact: null, // 選擇新客戶時要清空業務窗口資料
      tel: null, // 選擇新客戶時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有下位資料(發貨單)
    const hasOutstock = hd_values.outstockCount > 0;
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        createT: false,
        dueday: false,
        ispay: false, // 是否完成付款
        custID: false,
        planday: false, // 預計完成日期
        driID: false,
        EDT: false,
        dealcontact: false,
        dealtel: false,
        note: false,
        custHistory: hd_values.proCount > 0 ? false : true,
        contact: false,
        // finishEntrust: false, // 完成委託
        // 如果有外包項目未完成就不能點擊完成委託
        finishEntrust:
          hd_values.proCount === 0
            ? false
            : hd_values.proCount === hd_values.proLifeF2count
            ? false
            : true, // 只有歷程為2才可以按
        uploadEntrust: false, // 上傳客戶簽訂
        uploadInspcer: false, // 上傳檢測證書
        throwOutstock: false, // 轉外包項目
        // ------------ 表身 ------------
        // 建立表身的按鈕(據此創建可以基於舊資料再新建新的表身)
        createBtn: isCreateByThis ? false : isNewdoc,
        pdtNM: false,
        unitprice: false,
        demandqty: false,
        PN: false,
        tb_note: false,
        tbSubmit: false, // 所有表身跳顯內的確認按鈕
        tb_edit: isCreateByThis ? true : false, // 所有表身的編輯
        tb_delete: isCreateByThis ? true : false, // 所有表身的刪除
        // ------------ 條款註記 ------------
        adda_statement: false,
        // ------------ 加扣款項 ------------
        addb_statement: false,
        addb_amount: false,
        // ------------ 預付作業 ------------
        addc_statement: false,
        addc_amount: false,
        addc_note: false,
        // ------------ 其他費用 ------------
        postage_statement: false,
        postage_note: false,
        postage_amount: false,
      });
    }
    // 報價審批
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        orderCheck: false, // 報價審批
        custCheck: false, // 客戶簽回
        confirmID: false,
        dueday: false,
        ispay: false,
        custHistory: hd_values.proCount > 0 ? false : true, // 外包項目查詢
        contact: false,
        // ------------ tb ------------
        postage_statement: false,
        postage_note: false,
        postage_amount: false,
      });
    }

    // 報價簽回
    if (hd_values?.lifeF === "3") {
      set_s_disabled({
        ...INIT_DISABLED,
        custCheck: false, // 客戶簽回
        uploadEntrust: false, // 上傳客戶簽訂
        quoSign: false,
        approveT: false,
        dueday: false,
        ispay: false,
        contact: false,
        custHistory: hd_values.proCount > 0 ? false : true, // 外包項目查詢
        // 有上傳客戶簽訂才可以點選『轉外包項目』or『完成委託』
        throwOutstock: hd_values.entrust === "" ? true : false, // 轉外包項目
        finishEntrust: hd_values.entrust === "" ? true : false, // 完成委託項目

        postage_statement: false,
        postage_note: false,
        postage_amount: false,
      });
    }
    // 轉外包項目
    if (hd_values?.lifeF === "4") {
      set_s_disabled({
        ...INIT_DISABLED,
        uploadEntrust: false, // 上傳客戶簽訂
        custHistory: hd_values.proCount > 0 ? false : true, // 外包項目查詢
        dueday: false,
        ispay: false,
        throwOutstock: false,
        finishEntrust: hd_values.proCount === hd_values.proLifeF2count ? false : true, // 只有歷程為2才可以按
        postage_statement: false,
        postage_note: false,
        postage_amount: false,
        contact: false,
      });
    }
    // 完成委託項目
    if (hd_values?.lifeF === "5") {
      set_s_disabled({
        ...INIT_DISABLED,
        uploadInspcer: false, // 上傳檢測證書
        contract: false, // 上傳正式合同
        entrust: false, // 上傳客戶委託
        viewentrust: false, // 瀏覽客戶委託
        finishEntrust: false, // 完成委託項目
        custHistory: hd_values.proCount > 0 ? false : true, // 外包項目查詢
        quoSign: false, // 瀏覽客戶簽回
        dueday: false,
        ispay: false,
      });
    }

    // 結案
    if (hd_values.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        quoSign: false, // 瀏覽客戶簽回
        entrust: false, // 上傳客戶委託
        inspcer: true, // 上傳檢測報告
        dueday: false,
        ispay: false,
      });
    }
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));
    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      message.success(t("OMS_ori.util.prev_data_errMsg"));
      return;
    }
    // 存擋現在的資料
    call_updateOrdHd.request({
      ...form.getFieldsValue(),
      APImethod: "prev_doc",
    });
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));
    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === ord_Ns.length) {
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }
    // 存擋現在的資料
    call_updateOrdHd.request({
      ...form.getFieldsValue(),
      APImethod: "next_doc",
    });
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = ord_Ns.findIndex((item) => item === N);

    return index;
  };

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_ordTb.tb?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總加扣款項
    const addb = s_ordTb.addb?.reduce((prev, curr) => prev + (curr.amount || 0), 0) || 0;
    // 未稅總金額
    const sumtotal = itemtotal + addb;
    // 總稅額
    const tax = (sumtotal * f_taxrate) / 100;
    // 總金額 = 單項金額(未稅) + 總加扣款項 + 總稅額
    const total = itemtotal + addb + tax;
    // console.log("sumtotal = ", sumtotal);
    form.setFieldsValue({
      charge: addb,
      itemtotal,
      sumtotal,
      tax,
      total,
    });
  };

  // 報價審批 or 取消報價審批
  const orderCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "2" ? "取消報價審批" : "報價審批"}`,
      title: `${
        f_lifeF === "2"
          ? t("OMS.util.cancel") + t("OMS.ord.lifeF_2") + t("util.ok")
          : t("OMS.ord.lifeF_2") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="color-red-500" />,
      content: f_lifeF === "2" ? t("OMS.ord.cancel_orderCheck_text") : t("OMS.ord.orderCheck_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_updateOrdHd.request({
          ...values,
          APImethod: "orderCheck",
          lifeF: f_lifeF === "2" ? "0" : "2",
          quoSign: f_lifeF === "2" ? "" : values.quoSign,
          confirmT: dayjs(),
          confirmID: c_userData?.staffID,
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 客戶簽回 or 取消客戶簽回
  const custCheck = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: `${f_lifeF === "7" ? "取消客戶簽回確認" : "客戶簽回確認"}`,
      title: `${
        f_lifeF === "3"
          ? t("OMS.util.cancel") + t("OMS.ord.lifeF_3") + t("util.ok")
          : t("OMS.ord.lifeF_3") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content:
        f_lifeF === "3"
          ? t("OMS.ord.cancel_custCheck_confirm_text")
          : t("OMS.ord.custCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_custCheck_updateOrdHD.request({
          ...values,
          lifeF: f_lifeF === "3" ? "2" : "3",
          entrust: f_lifeF === "3" ? "" : values.entrust,
          lifeFT: dayjs(),
          approveT: dayjs(),
        });
      },
    });
  };

  const goCust = () => {
    const params_obj = {
      opcode: "2340",
      preopUUID: mainPage.data?.opUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/outsourcing?${params}`, "_blank");
  };

  // 上傳客戶委託 or 取消上傳客戶委託
  const entrust = () => {
    // if (f_lifeF === "3") {
    // 上傳客戶委託
    set_s_showFileModal(true);
    set_s_fileType("entrust");
    set_s_fileTypeName(t("OMS.util.entrust"));
    // }
    // if (f_lifeF === "4") {
    //   call_updateOrdHd.request({
    //     ...form.getFieldsValue(),
    //     APImethod: "cancelEntrust",
    //     lifeF: "3",
    //   });
    // }
  };

  // 上傳檢測證書
  const inspcer = () => {
    set_s_showFileModal(true);
    set_s_fileType("inspcer");
    set_s_fileTypeName(t("OMS.ord.inspcer"));
  };

  console.log("f_lifeF = ", f_lifeF);
  // 完成委託
  const finishEntrust = () => {
    const values = form.getFieldsValue();
    if (f_lifeF === "0" || f_lifeF === "") {
      call_updateOrdHd.request({
        ...values,
        APImethod: "finishEntrust",
        lifeF: "5",
        signT: dayjs(),
        lifeFT: dayjs(),
      });
    }
    // return;
    // lifeF = 3 -> 一般訂單確認
    if (f_lifeF === "3") {
      call_updateOrdHd.request({
        ...values,
        APImethod: f_lifeF === "5" ? "cancelfinishEntrust" : "finishEntrust",
        lifeF: f_lifeF === "3" ? "5" : "3",
        inspcer: f_lifeF === "5" ? "" : values.inspcer,
        signT: dayjs(),
        lifeFT: dayjs(),
      });
    }
    // lifeF = 4 -> 轉外包項目
    if (f_lifeF === "4") {
      call_updateOrdHd.request({
        ...values,
        APImethod: f_lifeF === "5" ? "cancelfinishEntrust" : "finishEntrust",
        lifeF: f_lifeF === "4" ? "5" : "4",
        inspcer: f_lifeF === "5" ? "" : values.inspcer,
        signT: dayjs(),
        lifeFT: dayjs(),
      });
    }

    if (f_lifeF === "5") {
      call_updateOrdHd.request({
        ...values,
        APImethod: "cancelfinishEntrust",
        lifeF: f_proCount > 0 ? "4" : "0",
        inspcer: f_lifeF === "5" ? "" : values.inspcer,
        signT: dayjs(),
        lifeFT: dayjs(),
      });
    }
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 取得下拉選單
    call_getAdd.request();

    // 新建銷貨單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});

      // 設定表單的起始值
      form.setFieldsValue({
        opcode: tabKey,
        // opcode: c_state.opcode,
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        ispay: false,
        // 匯率預設1
        lifeF: "0",
        lifeFT: dayjs(),
        exchangerate: 1,
        taxtype: "PPN_11%",
        taxrate: 11,
        planday: "7",
        trade: "FOB",
        payment: "銀行轉帳",
        createID: c_userData?.staffID,
        driID: c_userData?.staffID,
        startEDT: dayjs(),
        endEDT: dayjs(),
        createT: dayjs(),
        exchangeD: dayjs(),
      });
    } else {
      // 取得表身【銷貨明細】
      call_getOrdTB.request({ opUUID: mainPage.data?.opUUID });
      // 取得【條款註記】、【加扣款項】、【預付作業】
      call_getOrdADD.request({
        "opUUID": mainPage.data?.opUUID,
        // "addtype": "addb",
      });

      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      // console.log("mainPage.data = ", mainPage.data);
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      // console.log("newData = ", newData);
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  useEffect(() => {
    calculateMoneyInfo();
  }, [MainPage.data, f_exchangerate, f_taxrate, s_ordTb.tb, s_ordTb.addb]);

  // useAPI 取得下拉選單
  useEffect(() => {
    const { status, msg, data } = call_getAdd;
    if (status === "suc") {
      const { adda, addb, addc, addd, adde } = data;
      // format成下拉選單可用的
      const getOptions = (oriData) => {
        return (
          oriData?.map((item) => ({
            label: item.itemNM,
            value: item.itemNM,
          })) ?? []
        );
      };
      set_s_Adds({
        adda: getOptions(adda), // 條款註記下拉選單
        addb: getOptions(addb), // 加扣款項下拉選單
        addc: getOptions(addc), // 預付作業下拉選單
        addd: getOptions(addd), // 付款方式下拉選單
        adde: getOptions(adde), // 貿易條件下拉選單
      });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getAdd.status]);
  // console.log("f_lifeF = ", f_lifeF);
  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getOrdHD;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", newData);
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
      // 更新mainPage資料
      updateState({ mainPage: { show: true, data: newData } });

      // 更新表身資料
      call_getOrdTB.request({ opUUID: data[0].opUUID });
      set_s_spinning(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_spinning(false);
    }
  }, [call_getOrdHD.status]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getOrdTB;
    if (status === "suc") {
      const new_tb = data?.map((item, i) => ({ ...item, key: i }));
      set_s_ordTb((prev) => ({
        ...prev,
        tb: new_tb,
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_ordTb((prev) => ({
        ...prev,
        tb: [],
      }));
    }
  }, [call_getOrdTB.status]);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { status, msg, data } = call_getOrdADD;
    if (status === "suc") {
      console.log("asdasdasdasdasd = ", data);

      set_s_ordTb((prev) => ({
        ...prev,
        adda: data.adda ?? prev.adda, // 條款註記
        addb: data.addb ?? prev.addb, // 加扣款項
        addc: data.addc ?? prev.addc, // 預付作業
      }));
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdADD.status]);

  // useAPI 新建表頭資料
  useEffect(() => {
    const { status, data, msg } = call_addOrdHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getOrdHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getOrdTB.request({ opUUID: data.opUUID });
      }
      // 清空表頭編輯紀錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_addOrdHD.status]);

  useEffect(() => {
    const { data, status, msg } = call_updateOrdHd;
    if (status === "load") {
      set_s_spinning(true);
    }
    if (status === "suc") {
      if (data.APImethod === "update") {
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        // 清空表頭編輯紀錄
        set_is_editForm({ isEdit: false, editFlag: [] });
        return;
      }
      // 修改表頭
      if (data.APImethod === "submit") {
        message.success(msg);
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        // 清空表頭編輯紀錄
        set_is_editForm({ isEdit: false, editFlag: [] });
        return;
      }
      // 報價審批
      if (data.APImethod === "orderCheck") {
        message.success(
          data.lifeF === "2"
            ? t("OMS.ord.lifeF_2") + t("util.success")
            : t("OMS.util.cancel") + t("OMS.ord.lifeF_2") + t("util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        return;
      }
      // 上傳報價簽回
      if (data.APImethod === "entrust") {
        // 上傳報價簽回成功
        message.success(t("OMS.util.upload") + t("OMS.util.entrust") + t("util.success"));
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        return;
      }
      // 上傳客戶委託
      if (data.APImethod === "quoSign") {
        // 上傳報價簽回成功
        message.success(t("OMS.util.upload") + t("OMS.ord.quoSign") + t("util.success"));
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
        return;
      }
      // 取消上傳客戶委託
      if (data.APImethod === "cancelEntrust") {
        // 取消上傳客戶委託成功
        message.success(
          t("util.cancel") +
            t("OMS.util.upload") +
            t("OMS.util.cust") +
            t("OMS.util.entrust") +
            t("util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 上傳正式合同
      if (data.APImethod === "contract") {
        // 上傳正式合同成功
        message.success(
          t("OMS.util.upload") + t("OMS.ord.contract") + t("OMS.util.entrust") + t("util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 取消上傳正式合同
      if (data.APImethod === "cancelContract") {
        // 取消上傳正式合同成功
        message.success(
          t("util.cancel") +
            t("OMS.util.upload") +
            t("OMS.ord.contract") +
            t("OMS.util.entrust") +
            t("util.success")
        );
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 完成委託
      if (data.APImethod === "finishEntrust") {
        // 完成委託成功
        message.success(t("OMS.ord.finishEntrust") + t("util.success"));
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 取消完成委託
      if (data.APImethod === "cancelfinishEntrust") {
        // 取消完成委託
        message.success(t("util.cancel") + t("OMS.ord.finishEntrust") + t("util.success"));
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 上傳檢驗證書
      if (data.APImethod === "inspcer") {
        // 上傳檢驗證書成功
        message.success(t("OMS.util.complete") + t("OMS.ord.inspcer") + t("util.success"));
        // 更新表頭
        call_getOrdHD.request({ opUUID: data.opUUID });
      }
      // 上一筆
      if (data.APImethod === "prev_doc") {
        message.success(t("OMS.util.get_prev_data_suc"));
        const nowIndex = getIndex(form.getFieldValue("N"));
        // 更新表頭資料(減1是因為要找上一筆資料)
        call_getOrdHD.request({ N: ord_Ns[nowIndex - 1] });
      }
      // 下一筆
      if (data.APImethod === "next_doc") {
        message.success(t("OMS.util.get_next_data_suc"));
        const nowIndex = getIndex(form.getFieldValue("N"));
        // 更新表頭資料(減1是因為要找上一筆資料)
        call_getOrdHD.request({ N: ord_Ns[nowIndex + 1] });
      }
      set_s_spinning(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_spinning(false);
    }
  }, [call_updateOrdHd.status]);

  // useAPI 客戶簽回
  useEffect(() => {
    const { status, msg, data } = call_custCheck_updateOrdHD;
    if (status === "suc") {
      // message.success(data.lifeF === "7" ? "客戶簽回成功" : "取消客戶簽回成功");
      message.success(
        data.lifeF === "3"
          ? t("OMS.ord.lifeF_3") + t("util.success")
          : t("OMS.util.cancel") + t("OMS.ord.lifeF_3") + t("util.success")
      );
      // 更新表頭
      call_getOrdHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_custCheck_updateOrdHD.status]);
  // console.log(form.getFieldValue("apCount"));
  return (
    <MainPageContext.Provider
      value={{
        call_getOrdHD,
        call_getOrdTB,
        call_updateOrdHd,
        call_getOrdADD,
        is_editForm,
        set_is_editForm,
        s_ordTb,
        set_s_ordTb,
        s_disabled,
        s_Adds,
        hd_values: form.getFieldsValue(),
      }}
    >
      <div className="flex flex-col h-full">
        <Spin spinning={s_spinning}>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            validateMessages={{
              required: t("PMS.util.placeholder_select") + "${label}",
            }}
            onValuesChange={(changedValues, _) => {
              if (editMode) {
                // 在編輯模式時紀錄表頭修改了哪些欄位
                set_is_editForm((prev) => {
                  let editFlag = [...prev.editFlag];
                  const [key] = Object.keys(changedValues);

                  if (!editFlag.includes(key)) {
                    editFlag.push(key);
                  }
                  return {
                    isEdit: true,
                    editFlag,
                  };
                });
              }
            }}
          >
            {/* 表頭部分 */}
            <Row gutter={[12, 0]}>
              <Col span={8}>
                <PageHeader
                  // editMode ? "編輯銷貨主檔" : "新建銷貨主檔"}
                  title={
                    editMode
                      ? t("OMS.util.table_edit") + t("OMS.ord.pageHeader")
                      : t("OMS.util.add") + t("OMS.ord.pageHeader")
                  }
                />
              </Col>
              <Col span={16} className="flex justify-end">
                {/* 列印 */}
                <PrintButton onClick={() => set_is_showPrint(true)} />
                {/* 儲存 */}
                <SaveButton htmlType="submit" />
                {/* 上一筆 */}
                <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
                {/* 下一筆 */}
                <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
                {Object.keys(s_oriData).length > 0 && (
                  <CancelCreateButton
                    // 取消據此創建
                    onClick={() => {
                      updateState({ mainPage: { show: true, data: s_oriData.hd } });
                      set_s_ordTb(s_oriData.tb);
                      // 如果有日期格式就轉換成 moment
                      const newData = { ...s_oriData.hd };
                      Object.entries(s_oriData.hd).forEach(([key, value]) => {
                        if (isValidDate(value)) {
                          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                        }
                      });

                      form.setFieldsValue({
                        ...newData,
                      });
                      set_s_oriData({});
                    }}
                  />
                )}
                {/* 回總表 */}
                <SummaryButton
                  onClick={() => {
                    updateState({ mainPage: { show: false, data: {} } });
                  }}
                />
              </Col>
            </Row>
            {/* 隱藏欄位 */}
            <HiddenFormItem />
            {/* 表單開始區域 */}
            <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
              {/* 單別 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="opcode"
                  label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("OMS.util.opcode") }}
                >
                  <Select
                    placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                    // disabled={s_disabled.opcode}
                    disabled
                    options={[
                      { label: t("OMS.util.opcode_1310"), value: "1310" },
                      { label: t("OMS.util.opcode_1320"), value: "1320" },
                      { label: t("OMS.util.opcode_1330"), value: "1330" },
                    ]}
                  />
                </Form.Item>
              </Col>
              {/* 單號 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="N"
                  label={
                    <Space>
                      <Text disabled>{t("OMS.util.N")}</Text>
                      {mainPage.data.preopUUID && <Light linkType="trans" />}
                    </Space>
                  }
                >
                  <Input disabled className="border border-[#4493e3] border-solid" />
                </Form.Item>
              </Col>

              {/* 客戶單號 */}
              {/* <Col xl={editMode ? 3 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="dealN"
                  label={<Text disabled={s_disabled.dealN}>{t("OMS.util.dealN")}</Text>}
                >
                  <Input
                    placeholder={t("util.placeholder_input") + t("OMS.util.dealN")}
                    disabled={s_disabled.dealN}
                    className="border border-[#4493e3] border-solid"
                  />
                </Form.Item>
              </Col> */}

              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="flex gap-2">
                {/* 付款方式 */}
                {/* <Form.Item
                  className="flex-1"
                  label={<Text disabled={s_disabled.payment}>{t("OMS.ord.payment")}</Text>}
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("OMS.ord.payment") }}
                  name="payment"
                >
                  <Select
                    placeholder={t("util.placeholder_select") + t("OMS.fod.payment")}
                    disabled={s_disabled.payment}
                    options={s_Adds.addd}
                  />
                </Form.Item> */}
                {/* 負責業務 */}
                <Form.Item
                  label={<Text disabled={s_disabled.driID}>{t("OMS.ord.driID")}</Text>}
                  name="driID"
                  className="flex-1"
                >
                  <Select
                    placeholder={t("util.placeholder_select") + t("OMS.ord.driID")}
                    disabled={s_disabled.driID}
                    showSearch
                    options={c_staffs.options}
                    filterOption={(input, option) => {
                      return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                    }}
                  />
                </Form.Item>
              </Col>

              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="flex gap-5">
                {/* 付款截止日 */}
                <Form.Item
                  label={<Text disabled={s_disabled.dueday}>付款截止日</Text>}
                  name="dueday"
                  className="flex-[3]"
                >
                  <DatePicker disabled={s_disabled.dueday} className="w-full" />
                </Form.Item>
                {/* 是否完成付款 */}
                <Form.Item
                  label={<Text disabled={s_disabled.ispay}>是否完成付款</Text>}
                  name="ispay"
                  className="flex-1"
                >
                  <Radio.Group
                    disabled
                    className="w-full flex gap-10"
                    options={[
                      { value: true, label: t("util.yes") },
                      { value: false, label: t("util.no") },
                    ]}
                  />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* 據此創建 */}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    // disabled={editMode ? false : true}
                    disabled
                    onClick={createByThis}
                  >
                    {t("OMS.util.accord")}
                    {t("OMS.util.create")}
                  </Button>
                  {/* 轉外包項目 */}
                  <Button
                    className="flex-1 h-full oms-btn-darkBlue"
                    onClick={() => set_is_showOutstock(true)}
                    disabled={s_disabled.throwOutstock}
                  >
                    {/* 轉外包項目 */}
                    {t("OMS.ord.throwOutstock")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶名稱 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.custID}>{t("OMS.util.custNM")}</Text>}
                  name="custNM"
                >
                  <Select
                    showSearch
                    disabled={s_disabled.custID}
                    placeholder={t("util.placeholder_select") + t("OMS.util.custalias")}
                    onChange={onCustChange}
                    options={c_custs.options}
                    filterOption={(input, option) => {
                      return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                    }}
                  />
                </Form.Item>
              </Col>
              {/* 客戶ID */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="custID"
                  label={<Text disabled>{t("OMS.util.custID")}</Text>}
                  messageVariables={{ label: t("OMS.util.custalias") }}
                >
                  <Input
                    disabled
                    placeholder={t("util.placeholder_select") + t("OMS.util.custalias")}
                  />
                </Form.Item>
              </Col>

              {/* 計畫時間 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.startEDT}>{t("OMS.ord.planday")}</Text>}
                  name="planday"
                >
                  <Input disabled={s_disabled.planday} />
                </Form.Item>
              </Col>
              {/* 預計完成時間 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.endEDT}>{t("OMS.util.EDT")}</Text>}
                  name="EDT"
                >
                  <DatePicker disabled={s_disabled.EDT} className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button
                    className="w-full h-full"
                    onClick={goCust}
                    disabled={s_disabled.custHistory}
                  >
                    {/* 外包資料查詢 */}
                    {t("OMS.fod.custHistory")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶地址 */}
              <Col xl={editMode ? 9 : 12} xxl={editMode ? 10 : 12}>
                <Form.Item
                  label={<Text disabled={s_disabled.dealaddr}>{t("OMS.ord.dealaddr")}</Text>}
                  name="dealaddr"
                >
                  <Input
                    disabled={s_disabled.dealaddr}
                    placeholder={t("util.placeholder_input") + t("OMS.ord.dealaddr")}
                  />
                </Form.Item>
              </Col>

              {/* 客户签回日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="mt-1 bg-pink-50">
                <Form.Item
                  label={<Text disabled={s_disabled.approveT}>{t("OMS.util.approveT")}</Text>}
                  name="approveT"
                >
                  <DatePicker disabled={s_disabled.approveT} className="w-full" />
                </Form.Item>
              </Col>

              {/* 完成委託日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="mt-1 bg-gray-50">
                <Form.Item label={<Text disabled>{t("OMS.util.signT")}</Text>} name="signT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* 上傳客戶委託 */}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outlin"
                    onClick={() => {
                      // 上傳客戶委託
                      set_s_showFileModal(true);
                      set_s_fileType("quoSign");
                      set_s_fileTypeName(t("OMS.ord.quoSign"));
                    }}
                  >
                    {t("OMS.util.upload") + t("OMS.ord.quoSign")}
                  </Button>
                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "2" ? "pms-btn-orange" : "oms-btn-darkBlue"
                    }`}
                    disabled={
                      // 沒有表身時不可以覆核
                      s_ordTb.tb.length === 0 || !auth?.orderCheck ? true : s_disabled.orderCheck
                    }
                    onClick={() => orderCheck()}
                  >
                    {/* {f_lifeF === "2" ? "取消報價審批" : "報價審批"} */}
                    {/* {f_lifeF === "2"
                      ? t("util.cancel") + t("OMS.ord.lifeF_2")
                      : `${t("OMS.ord.lifeF_2")}`} */}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 客戶業務窗口 */}
              <Col xl={editMode ? 9 : 12} xxl={editMode ? 10 : 12} className="mt-1">
                <Form.Item shouldUpdate label={<Text>{t("OMS.ord.dealcontact")}</Text>}>
                  {() => (
                    <Row gutter={[12, 0]}>
                      <Col span={12} className="flex items-center">
                        <ContactButton
                          disabled={s_disabled.contact}
                          custID={form.getFieldValue("custID")}
                          chooseContact={(contact_obj) => {
                            form.setFieldsValue({
                              dealcontact: contact_obj?.contact,
                              dealtel: contact_obj?.tel,
                            });
                          }}
                          title={t("util.placeholder_select") + t("OMS.ord.dealcontact")}
                        />
                        {/* 窗口 */}
                        <span className="ml-2">{t("OMS.util.window")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealcontact" className="flex-1 mb-0px" shouldUpdate>
                          <Input
                            disabled={s_disabled.dealcontact}
                            className="flex-1"
                            placeholder="填写客户联络人"
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="flex items-center">
                        {/* 電話 */}
                        <span>{t("OMS.util.tel")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealtel" className="flex-1 mb-0px">
                          <Input disabled={s_disabled.dealtel} placeholder="填写连络人电话" />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>

              {/* 覆核人員 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-50">
                <Form.Item
                  label={<Text disabled={s_disabled.confirmID}>{t("OMS.util.confirmID")}</Text>}
                  name="confirmID"
                >
                  <Select disabled={s_disabled.confirmID} options={c_staffs.options} />
                </Form.Item>
              </Col>
              {/* 覆核日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-50">
                <Form.Item label={<Text disabled>{t("OMS.util.confirmT")}</Text>} name="confirmT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* {f_lifeF === "3" ? "取消簽回" : "完成訂單確認"} */}
                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "3" ? "pms-btn-red" : "oms-btn-darkBlue"
                    }`}
                    onClick={() => custCheck()}
                    // disabled={s_disabled.custCheck}
                    disabled
                  >
                    {/* {f_lifeF === "3"
                      ? t("util.cancel") + t("OMS.util.signBack")
                      : `${t("OMS.ord.lifeF_3")}`} */}
                  </Button>
                  {/* 完成委託 */}
                  <Button
                    disabled={
                      // 如果產生的對帳資料有被引用，就不可以再取消委託了
                      f_inspcer?.length > 0 ? s_disabled.finishEntrust : true
                      // form.getFieldValue("apCount") > 0 ? true : s_disabled.finishEntrust
                    }
                    onClick={finishEntrust}
                    className={`h-full flex-1 ${
                      f_lifeF === "5" ? "pms-btn-red" : "oms-btn-darkBlue"
                    }`}
                  >
                    {f_lifeF === "5" ? (
                      <>
                        <span>{t("util.cancel")}</span> <br />{" "}
                        <span>{t("OMS.ord.finishEntrust")}</span>
                      </>
                    ) : (
                      t("OMS.ord.finishEntrust")
                    )}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
              {/* 銷貨註記  */}
              <Col xl={editMode ? 9 : 12} xxl={editMode ? 10 : 12} className="py-2">
                <Form.Item
                  label={<Text disabled={s_disabled.note}>{t("util.note")}</Text>}
                  name="note"
                >
                  <Input disabled={s_disabled.note} />
                </Form.Item>
              </Col>

              {/* 創建人員 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="py-2 bg-gray-50">
                <Form.Item label={<Text disabled>{t("OMS.util.createID")}</Text>} name="createID">
                  <Select disabled options={c_staffs.options} />
                </Form.Item>
              </Col>

              {/* 創建日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="py-2 bg-gray-50">
                <Form.Item
                  label={<Text disabled={s_disabled.createT}>{t("OMS.util.createT")}</Text>}
                  name="createT"
                >
                  <DatePicker disabled={s_disabled.createT} className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 py-2"
                >
                  {/* 上傳客戶簽回 */}
                  <Button
                    disabled={s_disabled.uploadEntrust}
                    className="flex-1 h-full pms-btn-blue-outline"
                    onClick={entrust}
                  >
                    {t("OMS.util.upload") + t("OMS.util.entrust")}
                  </Button>

                  <Button
                    disabled={s_disabled.uploadInspcer}
                    onClick={inspcer}
                    className={`h-full flex-1 pms-btn-blue-outline`}
                  >
                    {/* 上傳檢測證書 */}
                    {t("OMS.util.upload") + t("OMS.ord.inspcer")}
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
        <DetailContainer />

        {/* 列印抽屜 */}
        <Drawer
          placement="right"
          closable={false}
          width={860}
          onClose={() => set_is_showPrint(false)}
          open={is_showPrint}
          bodyStyle={{ padding: 50 }}
        >
          <PrintDrawer hd_values={form.getFieldsValue()} s_ordTb={s_ordTb} />
        </Drawer>
      </div>
      <CustomModal
        title={`上傳 ${s_fileTypeName}`}
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        maskClosable={true}
        afterClose={() => {
          set_s_fileType("");
          set_s_fileTypeName("");
        }}
      >
        <FileModal
          s_fileType={s_fileType}
          s_fileTypeName={s_fileTypeName}
          hd_values={form.getFieldsValue()}
          set_s_showFileModal={set_s_showFileModal}
          call_getOrdHD={call_getOrdHD}
          call_updateOrdHd={call_updateOrdHd}
        />
      </CustomModal>
      {/* 轉外包項目-跳顯 */}
      <CustomModal
        width="90%"
        title={t("OMS.ord.throwOutstock")}
        open={is_showOutstock}
        onCancel={() => {
          set_is_showOutstock(false);
        }}
      >
        {is_showOutstock === true ? (
          <OutstockModal
            hd_values={form.getFieldsValue()}
            set_is_showOutstock={set_is_showOutstock}
            s_ordTb={s_ordTb}
            call_getOrdHD={call_getOrdHD}
            call_getOrdTB={call_getOrdTB}
          />
        ) : null}
      </CustomModal>
    </MainPageContext.Provider>
  );
};

export default MainPage;
