import { useState, useRef, useEffect } from "react";
import { Button, Input, Checkbox, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";

import Wpc from "./Wpc";
import Bom from "./Bom";
import Pfc from "./Pfc";

export default function EDM() {
  const { t } = useTranslation();
  // 利用ref 子傳父
  const wpc_ref = useRef(null);
  const bom_ref = useRef(null);
  const pfcRef = useRef(null);
  const call_getMgmtcat = useAPI(getMgmtcat);

  // 查詢用資料
  const [s_searchData, set_s_searchData] = useState({
    PN: undefined,
    attributeArr: ["4", "5"],
    pdtclass: undefined,
    mgmtcat: undefined,
    attribute: "5", //BOM
    page: 1,
    pageSize: 100,
  });

  const s_arrMap = {
    mgmtcatArr: [],
    pdtclassArr: [
      { label: t("util.pdtclass_X"), value: "X" },
      { label: t("util.pdtclass_M"), value: "M" },
      { label: t("util.pdtclass_A"), value: "A" },
      { label: t("util.pdtclass_P"), value: "P" },
    ],
    attributeArr: [
      { label: t("util.attribute_0"), value: "0" },
      { label: t("util.attribute_1"), value: "1" },
      { label: t("util.attribute_2"), value: "2" },
      { label: t("util.attribute_3"), value: "3" },
      { label: t("util.attribute_4"), value: "4" },
      { label: t("util.attribute_5"), value: "5" },
      { label: t("util.attribute_6"), value: "6" },
    ],
  };

  const [s_tabKey, set_s_tabKey] = useState("wpc"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      PN: undefined,
      attribute: undefined,
      attributeArr: ["4", "5"],
      pdtclass: undefined,
      mgmtcat: undefined,
      // attribute: "5", //BOM
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "wpc" && wpc_ref.current.onSearch();
    s_tabKey === "bom" && bom_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "wpc" && wpc_ref.current.onCreate();
    s_tabKey === "bom" && bom_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "wpc",
      label: t("ADM.edm.wpcManage"),
      children: (
        <Wpc ref={wpc_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
    {
      key: "bom",
      label: t("ADM.edm.bom"),
      children: (
        <Bom
          ref={bom_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
          s_arrMap={s_arrMap}
        />
      ),
    },
    {
      key: "Pfc",
      label: t("ADM.edm.Pfc"),
      // disabled: true,
      children: (
        <Pfc
          ref={pfcRef}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
          s_arrMap={s_arrMap}
        />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.PN"
      value={s_searchData.PN}
      allowClear
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.placeholder_input")}${t("util.PN")}`}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,

    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  const searchBom = [
    <Checkbox.Group
      key="s_searchData.attributeArr"
      value={s_searchData.attributeArr}
      type="primary"
      onChange={(e) => handlChange("attributeArr", e)}
      options={[
        { label: t("util.attribute_4"), value: "4" },
        { label: t("util.attribute_5"), value: "5" },
      ]}
    />,
    <Input
      className="w-[200px]"
      key="s_searchData.PN"
      value={s_searchData.PN}
      onChange={(e) => handlChange("PN", e.target.value)}
      placeholder={`${t("util.placeholder_input")}${t("util.PN")}`}
    />,
    <Input
      className="w-[200px]"
      key="s_searchData.pdtNM"
      value={s_searchData.pdtNM}
      onChange={(e) => handlChange("pdtNM", e.target.value)}
      placeholder={`${t("util.placeholder_input")}${t("util.pdtNM")}`}
    />,
    <Select
      className="w-[200px]"
      key="s_searchData.pdtclass"
      value={s_searchData.pdtclass}
      onChange={(e) => handlChange("pdtclass", e)}
      placeholder={`${t("util.placeholder_select")}${t("util.pdtclass")}`}
      allowClear
      options={s_arrMap.pdtclassArr}
    />,
    <Select
      className="w-[200px]"
      key="s_searchData.mgmtcat"
      value={s_searchData.mgmtcat}
      onChange={(e) => handlChange("mgmtcat", e)}
      placeholder={`${t("util.placeholder_select")}${t("util.querycat")}`}
      allowClear
      options={call_getMgmtcat.data.reduce((data, curr) => {
        curr.isvalid &&
          data.push({
            label: curr.itemNM,
            value: curr.mgmtitem,
          });
        return data;
      }, [])}
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  const pageHeaderExtra = s_tabKey === "wpc" ? searchBasic : s_tabKey === "bom" ? searchBom : null;

  useEffect(() => {
    call_getMgmtcat.request({ pageType: "74" });
  }, []);

  useEffect(() => {
    if (s_searchData.PN === "") {
      onSearch();
    }
  }, [s_searchData]);

  useEffect(() => {
    if (call_getMgmtcat.status === "err") {
      message.error(t(`errorCode.${call_getMgmtcat.msg}`));
    }
  }, [call_getMgmtcat.status]);

  return (
    <>
      <PageHeader title={t("ADM.edm.pageHeader")} extra={pageHeaderExtra} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
