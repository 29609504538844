/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";

import { getDri, deleteDri } from "@/service/apis/ADM/StringManage";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";

import ModalContainer from "./ModalContainer";

const Dri = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  // useAPI 取得表頭資料
  const call_getDri = useAPI(getDri);
  // useAPI 刪除表頭資料
  const call_deleteDri = useAPI(deleteDri);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getDri.request({ item: s_searchData.query });

  const tableColumns = [
    {
      title: t("ADM.string-manage.driItemID"), // 單位ID
      dataIndex: "itemID",
      align: "center",
      width: "10%",
    },
    {
      title: t("ADM.string-manage.driItemNM"), // 單位名稱
      dataIndex: "itemNM",
      width: "20%",
    },
    {
      title: t("util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "10%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteDri.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getDri.status === "suc") {
      message.success(call_getDri.msg);
      set_s_tableData(
        call_getDri.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getDri.status === "err") {
      message.error(t(`errorCode.${call_getDri.msg}`));
      set_s_tableData([]);
    }
  }, [call_getDri.status]);

  useEffect(() => {
    if (call_deleteDri.status === "suc") {
      message.success(call_deleteDri.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteDri.status === "err") {
      message.error(t(`errorCode.${call_deleteDri.msg}`));
    }
  }, [call_deleteDri.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getDri.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.string-manage.tabDri")}`
            : `${t("util.add")}${t("ADM.string-manage.tabDri")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalContainer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>
    </>
  );
});

export default Dri;
