import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";

// 取得入庫單(表頭ㄧ階搜尋)
export const getStockHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStockHD`, {
    params: data,
  });
};
// 編輯入庫單(表頭ㄧ階搜尋)
export const updateStockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateStockHD`, data);
};

// 取得入庫單(表頭詳細搜尋)
export const getStockHDAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStockHD`, {
    params: data,
  });
};

// 取得入庫單(表身)
export const getStockTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStockTB`, {
    params: data,
  });
};
