/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { message, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// component
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import ModalContainer from "./ModalContainer";
// API
import useAPI from "@/hooks/useAPI";
import { getrun8090Hd, deleterun8090Hd } from "@/service/apis/WMS/ProInstock";
// util
import { formatAmount } from "@/util/format";
import BorrowModalContainer from "./ModalContainer/BorrowModalContainer";
import { default as ProInstockModalContainer } from "@/pages/WMS/ProInstock/Run80Run90/HD/ModalContainer"
const HD = forwardRef((props, ref) => {
  const { c_lightData, set_c_lightData, c_searchData } = props;
  const { t } = useTranslation();

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);

  // 後端的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // 送給後端的資料
  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 100,
  });

  // useAPI 取得表頭資料
  const call_getrun8090Hd = useAPI(getrun8090Hd);
  // useAPI 刪除表頭資料
  const call_deleterun8090Hd = useAPI(deleterun8090Hd);

  const tableColumns = [
    {
      title: t("WMS.util.opcode"),
      dataIndex: "opcode",
      align: "center",
      render: (text) => t(`WMS.util.opcode_${text}`),
    },
    {
      title: t("WMS.util.jobN"),//作業單號
      dataIndex: "jobN",
      align: "center"
    },
    {
      title: t(`WMS.util.preN_${c_searchData.opcode}`),//來源單號
      dataIndex: "operN",
      align: "center"
    },
    {
      title: t("WMS.util.realqty"),//實際數量
      dataIndex: "realqty",
      align: "right",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("WMS.util.bal"),//帳差
    //   dataIndex: "bal",
    //   align: "right",
    //   // render: (text) => formatAmount(text),
    // },
    {
      title: t("WMS.util.lifeF"),//歷程
      dataIndex: "lifeF",
      align: "center",
      render: (text) => t(`WMS.util.lifeF_${text}`),
      onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
    },
    {
      title: t("WMS.util.updtT"),//更新時間
      dataIndex: "lifeFT",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"),//操作
      dataIndex: "render",
      align: "center",
      render: (_, rowData) => (
        <Space>
          <IconPen
            disabled={rowData.lifeF !== "0"}
            onClick={(e) => {
              console.log("rowData = ", rowData);
              set_s_showModal(true);
              set_s_editData(rowData);
              e.stopPropagation();
            }}
          />
          <IconGarbage disabled={rowData.lifeF !== "0"} onClick={(e) => deleteTxn(e, rowData)} />
        </Space>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_showModal(true), //開啟新增Modal
    Call_hd: () => call_getrun8090Hd.request({ ...c_searchData, ...s_searchValue }),
  }));

  // 刪除表頭資料
  const deleteTxn = (e, rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleterun8090Hd.request(rowData),
      cancelText: t("util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };
  //根據 opcode不同 渲染不同 ModalContainer 
  const renderModalContainer = (opcode) => {
    if (opcode === "4243") {
      return <BorrowModalContainer
        opcode={c_searchData.opcode}
        set_s_showModal={set_s_showModal}
        s_editData={s_editData} />
    }

    else if (opcode === "4242") {
      const _opcode = ''
      return <ProInstockModalContainer
        opcode={c_searchData.opcode}
        set_s_showModal={set_s_showModal}
        s_editData={s_editData}
      />
    } else {
      return <ModalContainer
        opcode={c_searchData.opcode}
        set_s_showModal={set_s_showModal}
        s_editData={s_editData}
      />
    }

  }
  // c_searchData 變更時改變 page
  useEffect(() => {
    set_c_lightData({});
    set_s_searchValue((prev) => ({ ...prev, page: 1 }));
  }, [c_searchData]);

  // 第一次渲染跟每次改變搜尋資料都重新取得表頭資料
  useEffect(() => {
    call_getrun8090Hd.request({ ...c_searchData, ...s_searchValue });
  }, [s_searchValue]);

  // useAPI 取得表頭資料
  useEffect(() => {
    if (call_getrun8090Hd.status === "suc") {
      const { tableData, pageInfo } = call_getrun8090Hd.data;
      const newTableData = tableData.map((x, i) => ({ ...x, key: x.opUUID, index: i, dueday: dayjs(x.dueday), }));

      set_s_tableData(newTableData);
      // 後端分頁
      set_s_pageInfo(pageInfo);

      // 為了保證 c_lightData 會取得最新的值
      if (Object.keys(c_lightData).length === 0) return;
      console.clear();
      newTableData.forEach((x) => {
        console.log(x);
      });
      const _c_lightData =
        newTableData?.find((tableData) => tableData.opUUID === c_lightData.opUUID) || {};
      set_c_lightData(_c_lightData);
      // if (c_lightData.opUUID && !newTableData.some((x) => x.opUUID === c_lightData.opUUID)) {
      //   set_c_lightData({});
      // } else {
      //   const thisData = newTableData.find((x) => x.opUUID === c_lightData.opUUID);
      //   set_c_lightData(thisData ? { ...thisData, key: thisData.opUUID } : {});
      // }

    } else if (call_getrun8090Hd.status === "err") {
      set_s_tableData([]);
      message.error(call_getrun8090Hd.msg);
    }
  }, [call_getrun8090Hd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    if (call_deleterun8090Hd.status === "suc") {
      message.success(call_deleterun8090Hd.msg);
      call_getrun8090Hd.request({ ...c_searchData, ...s_searchValue });
    } else if (call_deleterun8090Hd.status === "err") {
      message.error(call_deleterun8090Hd.msg);
    }
  }, [call_deleterun8090Hd.status]);
  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getrun8090Hd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.opUUID === c_lightData.opUUID ? set_c_lightData({}) : set_c_lightData(record),
        })}
        pagination={{
          total: s_pageInfo.totalItems,
          current: s_searchValue.page,
          pageSize: s_searchValue.pageSize,
          onChange: (current, size) =>
            set_s_searchValue((prev) => ({
              ...prev,
              page: prev.pageSize !== size ? 1 : current,
              pageSize: size,
            })),
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.edit")}${t(`WMS.util.opcode_${c_searchData.opcode}`)}`
            : `${t("util.add")}${t(`WMS.util.opcode_${c_searchData.opcode}`)}`
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getrun8090Hd.request({ ...c_searchData, ...s_searchValue });
        }}
      >
        {renderModalContainer(c_searchData.opcode)}
      </CustomModal>
    </>
  );
});

export default HD;
