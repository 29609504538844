/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { message, Grid, Space, Row, Col, Drawer, Typography } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { SeeMoreButton } from "@/components/ERP/Button";
// component
import DrawerModal from "./Modal/DrawerModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getOrdTB } from "@/service/apis/OMS/Ord";
// util
import { formatAmount } from "@/util/format";

const { Title } = Typography;

const TB = () => {
	const { t } = useTranslation();
	const screens = Grid.useBreakpoint();
	// useAPI 取得表身
	const call_getOrdTB = useAPI(getOrdTB);

	const { c_state } = useContext(DataContext);
	const { lightData } = c_state;

	// 控制抽屜開啟、關閉、抽屜要顯示的資料
	const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
	// table 顯示的資料
	const [s_tableData, set_s_tableData] = useState([]);

	// 取得表身資料
	useEffect(() => {
		call_getOrdTB.request({ opUUID: lightData.opUUID });
	}, [lightData]);

	// useAPI 取得表身資料
	useEffect(() => {
		const { status, data, msg } = call_getOrdTB;
		// console.log("data = ", data);
		if (status === "suc") {
			message.success(msg);
			const tmpData = data?.map((item) => ({ ...item, key: item.itemUUID })) ?? [];
			set_s_tableData(tmpData);
		}

		if (status === "err") {
			message.error(t(`errorCode.${msg}`));
			set_s_tableData([]);
		}
	}, [call_getOrdTB.status]);

	const tableColumns = [
		{
			title: t("util.itemno"), // 項序
			dataIndex: "itemno",
			align: "center",
			width: "4%",
			render: (_, __, index) => index + 1,
		},
		{
			title: t("OMS.ord.note"), // 樣品名稱
			dataIndex: "note",
			align: "center",
		},
		{
			title: t("util.pdtNM"), // 檢驗項目
			dataIndex: "pdtNM",
			align: "center",
			width: "15%",
			sorter: (a, b) => a.pdtNM.localeCompare(b.pdtNM),
		},
		// {
		//   title: t("util.PN"), // 料號
		//   dataIndex: "PN",
		//   align: "center",
		//   width: "5%",
		// },
		{
			title: t("util.pdtspec"), // 測試方法/標準
			dataIndex: "pdtspec",
			align: "center",
			width: "15%",
		},
		{
			title: t("OMS.ord.planday"), // 計畫時間
			dataIndex: "planday",
			align: "center",
			width: "7%",
			// render: (text) => (text ? `${text}天` : text),
			// onCell: (_) => {
			//   return {
			//     style: {
			//       backgroundColor: "#ecf2fa",
			//     },
			//   };
			// },
		},
		{
			title: t("OMS.util.EDT"), // 預計交貨日
			dataIndex: "EDT",
			align: "center",
			width: screens.xxl ? "9.5%" : "9.5%",
			render: (text) => dayjs(text).format("YYYY-MM-DD"),
		},
		{
			title: t("OMS.ord.unitprice"), // 檢測費用
			dataIndex: "unitprice",
			align: "right",
			width: "7%",
			onCell: (_) => {
				return {
					style: {
						backgroundColor: "#ecf2fa",
					},
				};
			},
			render: (text) => formatAmount(text),
		},
		{
			title: t("OMS.ord.demandqty"), // 銷貨數量
			dataIndex: "demandqty",
			align: "right",
			width: "7%",
			onCell: (_) => {
				return {
					style: {
						backgroundColor: "#ecf2fa",
					},
				};
			},
			render: (text) => formatAmount(text),
		},
		{
			title: t("OMS.util.itemsum"), // 未稅單項金額
			dataIndex: "itemsum",
			align: "right",
			width: "10%",
			onCell: (_) => {
				return {
					style: {
						backgroundColor: "#ecf2fa",
					},
				};
			},
			render: (text) => formatAmount(text),
		},
		{
			title: t("OMS.util.table_operate"), // 操作
			dataIndex: "operate",
			align: "center",
			width: screens.xxl ? "3%" : "3%",
			render: (_, rowData, __) => (
				<Space className="px-2">
					{/* 查看更多 */}
					<SeeMoreButton onClick={() => set_s_drawer({ show: true, data: rowData })} />
				</Space>
			),
		},
	];

	return (
		<div className="mt-12 flex-1 flex flex-col justify-between">
			<CustomTable
				type="dark-purple"
				lightData={lightData}
				rowNumber={5}
				columns={tableColumns}
				dataSource={s_tableData}
			/>

			<Row className="hidden bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#4B6EA2]">
				{/* 總項數 */}
				<Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
					{/* 總項數 */}
					<span className="self-center">{t("OMS.util.itemno_total")}: </span>
					<span className="self-center">{s_tableData.length}</span>
				</Col>
				{/* 未稅總金額 */}
				<Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
					<span className="self-center">{t("OMS.ord.sumtotal")}:</span>
					<span className="self-center">{formatAmount(lightData.sumtotal)}</span>
				</Col>
				{/* 加扣款項 */}
				<Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
					<span className="self-center">加扣款項:</span>
					<span className="self-center">{formatAmount(lightData.charge)}</span>
				</Col>
				{/* 總稅額 */}
				<Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
					<span className="self-center">{t("OMS.util.tax")}: </span>
					<span className="self-center">{formatAmount(lightData.tax)}</span>
				</Col>
				{/* 含稅總金額 */}
				<Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
					<span className="self-center">{t("OMS.ord.total")}:</span>
					<span className="self-center">{formatAmount(lightData.total)}</span>
				</Col>
				{/* 含稅總金額(本幣) */}
				<Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
					<span className="self-center">{t("OMS.ord.local_total")}:</span>
					<span className="self-center">
						{" "}
						{formatAmount(lightData.total * lightData.exchangerate)}
					</span>
				</Col>
			</Row>

			{/* 查看更多-抽屜 */}
			<Drawer
				title={
					<Title level={5} className="mb-0">
						{/* 銷貨單明細 */}
						{t("OMS.ord.tb")}
					</Title>
				}
				placement="left"
				closable={false}
				width={1000}
				onClose={() => set_s_drawer({ show: false, data: {} })}
				open={s_drawer.show}
			>
				<DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
			</Drawer>
		</div>
	);
};

export default TB;
