import styled from "styled-components";
import { theme } from "antd";

const { useToken } = theme;

const Light = (
  props,
  { className, linkType = "trans", size = "12px", disabled = false }
) => {
  const { token } = useToken();

  return (
    <LightStyle {...props} token={token} size={size || token.fontSize}>
      <span className="badge-status-dot badge-status-processing" />
    </LightStyle>
  );
};

/**
 * @param {string} type "main , error , warning , success"
 * @param {string} size "default 18px , input any px"
 * @param {Bool} disabled - description
 */

const LightStyle = styled.span`
  .badge-status-dot {
    position: relative;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-color: ${(props) => {
      const map = {
        trans: props.token.trans,
        take: props.token.take,
      };
      return map[props.linkType];
    }};
  }
  .badge-status-processing {
    top: -1px;
    display: ${(props) => (props.disabled ? "none" : "inline-block")};
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    vertical-align: middle;
    border-radius: 50%;
  }
  .badge-status-dot:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => {
      const map = {
        trans: props.token.trans,
        take: props.token.take,
      };
      return map[props.linkType];
    }};
    border: ${(props) => {
      const map = {
        trans: props.token.trans,
        take: props.token.take,
      };
      return `1px solid ${map[props.linkType]}`;
    }};
    border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation: antStatusProcessing 1.2s ease-in-out infinite;
    content: "";
  }

  @keyframes antStatusProcessing {
    0% {
      transform: scale(0.8);
      opacity: 0.5;
    }

    100% {
      transform: scale(2.4);
      opacity: 0;
    }
  }
`;

export default Light;
