/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Space, message } from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import React, { useEffect } from "react";
import { addRunA3HD, updateRunA3HD } from "@/service/apis/OMS/runA3";

import { getOrdHD } from "@/service/apis/OMS/Ord";
//api
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const ModalContainer = ({ isDisabled, s_editData, set_s_editData, set_s_isShowModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 取得訂單單號(只可取得lifeF=0的訂單)
  const call_getOrdHD = useAPI(getOrdHD);
  // useAPI 新增預收款表頭
  const call_addRunA3HD = useAPI(addRunA3HD);
  // useAPI 編輯預收款表頭
  const call_updateRunA3HD = useAPI(updateRunA3HD);
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addRunA3HD.request(values);
    }
    if (type === "edit") {
      call_updateRunA3HD.request({ ...s_editData, ...values });
    }
  };

  // 第一次渲染取得lifeF=0的訂單單號
  useEffect(() => {
    call_getOrdHD.request({ lifeF: "0" });
  }, []);

  // useAPI 新增預收款表頭
  useEffect(() => {
    if (call_addRunA3HD.status === "suc") {
      message.success(call_addRunA3HD.msg);
      set_s_isShowModal(false);
    } else if (call_addRunA3HD.status === "err") {
      message.error(call_addRunA3HD.msg);
    }
  }, [call_addRunA3HD.status]);

  // useAPI 更新預收款表頭
  useEffect(() => {
    if (call_updateRunA3HD.status === "suc") {
      message.success(call_updateRunA3HD.msg);
      set_s_isShowModal(false);
    }
    if (call_updateRunA3HD.status === "err") {
      message.error(call_updateRunA3HD.msg);
    }
  }, [call_updateRunA3HD.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      disabled={isDisabled} // 如果為true代表是訂單在查看
      initialValues={{
        isvalid: true,
        opcode: "4910",
        ...s_editData,
      }}
    >
      {/* 影藏欄位 */}
      <Form.Item hidden name="preopcode">
        <Input disabled />
      </Form.Item>
      <Row gutter={[12, 24]}>
        <Col span={6}>
          <Form.Item label="單號" name="N">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="報價訂單單號" name="preN">
            <Select
              placeholder="請選擇報價訂單單號"
              showSearch
              options={call_getOrdHD?.data?.map((item) => ({
                label: (
                  <span>
                    {item.N} <span className="text-blue-500">客户名称: {item.custalias}</span>
                  </span>
                ),
                value: item.N,
                data: item,
              }))}
              filterOption={(input, option) => {
                return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
              }}
              onChange={(_, data) => {
                form.setFieldsValue({
                  duepay: data.data.itemtotal,
                  preopcode: data.data.opcode,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="總應付金額" name="duepay">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="總累計金額" name="cumpay">
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 24]}>
        <Col span={24}>
          <Form.Item label="註記" name="note">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Divider className="py-2" />

      <Row gutter={[12, 12]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_isShowModal(false)} />

          <ConfirmButton type="primary" htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
