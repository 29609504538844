import { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #00000029;
  padding: 10px;
  /* background-color: #fffafa; */
`;

export default function LineChart({ s_board, width = 0, height = 0 }) {
  // const r_isNavbar = useSelector((allReducers) => allReducers.navBar);
  // const r_isNavbarInline = useSelector(
  //   (allReducers) => allReducers.navBarInline
  // );

  const chartEle = useRef(null);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      height: 330,
      width: 1350,
      animation: false,
    },
    time: {
      useUTC: false,
    },
    title: { text: "" },

    credits: {
      enabled: false,
    },

    yAxis: {
      title: "",
      min: 0,
      max: 100,
      tickAmount: 5,
    },

    xAxis: {
      tickInterval: 1000 * 30 * 60,
      type: "datetime",
      min: Date.UTC(2020, 7, 1, 0, 0, 0),
      max: Date.UTC(2020, 7, 1, 23, 59, 59),
      dateTimeLabelFormats: {
        day: "%H:%M",
      },
      //tickInterval: 1,
      labels: {
        overflow: "justify",
        style: {
          color: "#46474a",
        },
        format: "{value:%H:%M}",
      },
    },

    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
      enabled: false,
    },

    tooltip: {
      shared: true,
      headerFormat: "",
      valueDecimals: 2,
    },

    series: [
      {
        name: "ACT",
        data: [56.8],
        pointStart: Date.UTC(2020, 7, 1, 0, 0, 0),
        pointInterval: 1000 * 30 * 60,
      },
    ],
  });

  useEffect(() => {
    const dataArr = s_board ? s_board : [];
    setChartOptions((prev) => {
      prev.series[0].data = dataArr.map((x) => x.ACT);
      return { ...prev };
    });
  }, [s_board]);

  // useEffect(() => {
  //   // 300毫秒為動畫運行時間
  //   // 300毫秒後重新計算卡片寬度
  //   setTimeout(() => {
  //     setChartOptions((prev) => {
  //       prev.chart.width = chartEle.current.container.current.clientWidth;
  //       return { ...prev };
  //     });
  //   }, 300);
  // }, [r_isNavbar, r_isNavbarInline]);

  return (
    <Wrapper width={width} height={height}>
      <HighchartsReact ref={chartEle} highcharts={Highcharts} options={chartOptions} />
    </Wrapper>
  );
}
