import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { message, Select, DatePicker, Button, Typography, Space, Spin } from "antd";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from "@/util/format"; //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import { getShipped } from "@/service/apis/OMS/Ord";
import useAPI from "@/hooks/useAPI";

const { Text } = Typography;

//銷退貨明細表
const SaleList = () => {
  const { RangePicker } = DatePicker;

  const { c_custs, c_staffs } = useContext(ERPContext); //從ERPContext拿到客戶資料

  const { t } = useTranslation();

  // const call_getShipped = useAPI(getShipped); // useAPI 取得訂單出貨狀態資料

  const [s_TableData, set_s_TableData] = useState([
    {
      opcode: "1310",
      N: "20230317001",
      custalias: "客戶1",
      driID: "業務001",
      invoicenumber: "00000001",
      PN: "PN0000001",
      pdtNM: "品名0000001",
      whNM: "公司倉",
      qty: 100,
      unit: "PCS",
      tax: 50,
      unitprice: 100,
      subtotal: 10000,
    },
    {
      opcode: "1310",
      N: "20230317001",
      custalias: "客戶1",
      driID: "業務001",
      invoicenumber: "00000001",
      PN: "PN0000002",
      pdtNM: "品名0000002",
      whNM: "公司倉",
      qty: 50,
      tax: 20,
      unit: "PCS",
      unitprice: 100,
      subtotal: 5000,
    },
    {
      opcode: "1310",
      N: "20230317001",
      custalias: "客戶1",
      driID: "業務001",
      invoicenumber: "00000001",
      PN: "PN0000003",
      pdtNM: "品名0000003",
      whNM: "公司倉",
      qty: 30,
      tax: 0,
      unit: "PCS",
      unitprice: 30,
      subtotal: 900,
    },
    {
      opcode: "1320",
      N: "20230318001",
      custalias: "客戶2",
      driID: "業務002",
      invoicenumber: "00000002",
      PN: "PN0000004",
      pdtNM: "品名0000004",
      whNM: "公司倉",
      qty: 20,
      tax: 20,
      unit: "PCS",
      unitprice: 50,
      subtotal: 1000,
    },
    {
      opcode: "1320",
      N: "20230318001",
      custalias: "客戶2",
      driID: "業務002",
      invoicenumber: "00000002",
      PN: "PN0000005",
      pdtNM: "品名0000005",
      whNM: "公司倉",
      qty: 40,
      tax: 60,
      unit: "PCS",
      unitprice: 40,
      subtotal: 1600,
    },
    {
      opcode: "1610",
      N: "20230318002",
      custalias: "客戶3",
      driID: "業務003",
      invoicenumber: "00000003",
      PN: "PN0000006",
      pdtNM: "品名0000006",
      whNM: "公司倉",
      qty: -20,
      tax: -50,
      unit: "PCS",
      unitprice: 50,
      subtotal: -1000,
    },
    {
      opcode: "1610",
      N: "20230318002",
      custalias: "客戶3",
      driID: "業務003",
      invoicenumber: "00000003",
      PN: "PN0000007",
      pdtNM: "品名0000007",
      whNM: "公司倉",
      qty: -70,
      tax: -40,
      unit: "PCS",
      unitprice: 50,
      subtotal: -3500,
    },
  ]); //放到表格的資料

  const [s_excelData, set_s_excelData] = useState([]);

  const [s_groupData, set_s_groupData] = useState([]);

  const [is_spin, set_is_spin] = useState(false);

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    opcode: "", //單別
    custID: "", //客戶ID
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "8%",
      render: (text) => t(`OMS.util.opcode_${text}`),
    },
    {
      title: "單號",
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    {
      title: "客戶簡稱",
      dataIndex: "custalias",
      align: "left",
      width: "8%",
    },
    {
      title: "業務員",
      dataIndex: "driID",
      align: "left",
      width: "8%",
    },
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: "倉庫",
      dataIndex: "whNM",
      align: "left",
      width: "5%",
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "left",
      width: "5%",
    },
    {
      title: "數量",
      dataIndex: "qty",
      align: "right",
      width: "5%",
      render: (text) => (
        <div className={`${text < 0 ? "text-red-500" : null}`}>{formatAmount(text)}</div>
      ),
    },
    {
      title: "單價",
      dataIndex: "unitprice",
      align: "right",
      width: "5%",
      render: (text) => (
        <div className={`${text < 0 ? "text-red-500" : null}`}>{formatAmount(text)}</div>
      ),
    },
    {
      title: "金額",
      dataIndex: "subtotal",
      align: "right",
      width: "5%",
      render: (text) => (
        <div className={`${text < 0 ? "text-red-500" : null}`}>{formatAmount(text)}</div>
      ),
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    // console.log("call api");
    console.log("s_searchData", s_searchData);
    // call_getShipped.request(tmp);
  };

  //----------------------------導出EXCEL---------------------------------------------------

  const exportToExcel = () => {
    const excelTable = s_excelData.map((item) => {
      //時間格式轉換，數字加上千分位,opcode轉換為銷貨單別
      if ("tax" in item) {
        delete item.tax;
      }
      if ("invoicenumber" in item) {
        delete item.invoicenumber;
      }
      return {
        ...item,
        opcode: item.opcode === undefined ? null : t(`OMS.util.opcode_${item.opcode}`),
        qty: typeof item.qty === "string" ? item.qty : formatAmount(item.qty),
        unit: typeof item.unit === "string" ? item.unit : formatAmount(item.unit),
        unitprice:
          typeof item.unitprice === "string" ? item.unitprice : formatAmount(item.unitprice),
        subtotal: typeof item.subtotal === "string" ? item.subtotal : formatAmount(item.subtotal),
        // createT: dayjs(item.startEDT).format("YYYY-MM-DD")
      };
    });

    //ws = 要轉出excel的資料，必須要定義表頭資料的dataIndex，不然轉出資料順序會亂掉
    const ws = XLSX.utils.json_to_sheet(excelTable, {
      header: [
        "opcode",
        "N",
        "custalias",
        "driID",
        "PN",
        "pdtNM",
        "whNM",
        "qty",
        "unit",
        "unitprice",
        "subtotal",
      ],
    });
    const wb = XLSX.utils.book_new(); //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); //活頁簿wb添加資料表Sheet1，資料為ws

    let mergeCells = [];
    console.log("s_groupData.length", s_groupData.length);
    for (let j = 0; j < s_groupData.length; j++) {
      for (
        let i =
          1 + (j === 0 ? 0 : s_groupData.slice(0, j).reduce((acc, cur) => acc + cur.length, 0));
        i < s_excelData.length;
        i += s_groupData[j].length
      ) {
        if (s_excelData[i].opcode === s_excelData[i - 1].opcode) {
          mergeCells.push({ s: { r: i, c: 0 }, e: { r: i + s_groupData[j].length - 2, c: 0 } });
        }

        if (s_excelData[i].N === s_excelData[i - 1].N) {
          mergeCells.push({ s: { r: i, c: 1 }, e: { r: i + s_groupData[j].length - 2, c: 1 } });
        }

        if (s_excelData[i].custalias === s_excelData[i - 1].custalias) {
          mergeCells.push({ s: { r: i, c: 2 }, e: { r: i + s_groupData[j].length - 2, c: 2 } });
        }

        if (s_excelData[i].driID === s_excelData[i - 1].driID) {
          mergeCells.push({ s: { r: i, c: 3 }, e: { r: i + s_groupData[j].length - 2, c: 3 } });
        }
      }
    }

    // 计算每列的最大宽度
    const colWidths = s_TableData[0] && Object.keys(s_TableData[0]).map(() => 0);
    s_TableData.forEach((row) => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        // console.log("cellValue", cellValue);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map((w) => ({ wch: w + 7 })); // 根据最大长度调整列宽
    ws["!cols"] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title; //excel從A欄開始，Unicode的65就是A
    }
    ws["!merges"] = mergeCells;
    const today = dayjs().format("YYYY-MM-DD");

    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `銷退貨彙總表${today}.xlsx`);
  };

  //----------------------------EXCEL---------------------------------------------------

  //所有銷貨單、銷退單資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getShipped;
  //   if (status === "suc") {
  //     console.log("call_getShipped = ", data);
  //     set_s_TableData(data);
  //     message.success(msg);
  //   } else if (status === "err") {
  //     set_s_TableData([]);
  //     message.error(msg);
  //   }
  // }, [call_getShipped.status]);

  // useEffect(() => {
  //   if (call_getShipped.status === 'load') {
  //     set_is_spin(true)
  //   } else {
  //     set_is_spin(false)
  //   }
  // }, [call_getShipped.status])

  useEffect(() => {
    console.log("c_staffs", c_staffs);
  }, [c_staffs]);

  //拿到原始資料後做分組，將資料組成要的格式再塞到表格
  useEffect(() => {
    //group 方法.
    const groupResult = s_TableData.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.N]) {
        accumulator[currentValue.N] = [];
      }
      accumulator[currentValue.N].push(currentValue);
      return accumulator;
    }, {});

    //取出各群組中的值
    const groupArr = Object.values(groupResult);
    set_s_groupData(groupArr);
    console.log("groupArr", groupArr);

    //-----------------加上合併欄位數、總計列-------------------------------------

    const CountArr = groupArr.map((item) => {
      const count = item.reduce(
        (accumulator, currentValue) => {
          accumulator.tax += currentValue.tax;
          accumulator.subtotal += currentValue.subtotal;
          accumulator.total += currentValue.subtotal + currentValue.tax;
          return accumulator;
        },
        {
          tax: 0, //稅額
          subtotal: 0, //合計金額(未稅)
          total: 0, //總金額(含稅)
        }
      );
      //加上合計欄
      item.push({
        driID: "發票號碼",
        pdtNM: "合計金額",
        whNM: count.subtotal,
        qty: "稅額",
        unit: count.tax,
        unitprice: "總金額",
        subtotal: count.total,
      });
      console.log("count", count);
      console.log("item", item);
      return item;
    });

    console.log("CountArr", CountArr);

    const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列
    console.log("finalArr", finalArr);

    set_s_excelData(finalArr);
  }, [s_TableData]);

  return (
    <div className="flex flex-col flex-1 w-full">
      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4" />

      {/*表頭*/}

      <PageHeader title="銷退貨明細表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">
        {/*導出excel*/}

        <Button type="primary" onClick={() => exportToExcel()}>
          導出excel
        </Button>

        <Text>單別：</Text>

        <Select
          className="min-w-[150px]"
          placeholder="請選擇單別"
          name="opcode"
          onChange={(value) => handle_SearchData_Change("opcode", value)}
          value={s_searchData.opcode}
          options={[
            { label: "全部", value: "" },
            { label: "國內訂單", value: "1310" },
            { label: "國外訂單", value: "1320" },
            { label: "銷貨退出", value: "1610" },
          ]}
        />

        {/*客戶選擇*/}
        <Select
          className="min-w-[200px]"
          name="custID"
          showSearch
          placeholder="請選擇客戶"
          onChange={(value) => handle_SearchData_Change("custID", value)}
          options={c_custs.options}
        />

        {/*業務選擇*/}
        <Select
          className="min-w-[200px]"
          name="staffID"
          showSearch
          placeholder="請選擇業務"
          onChange={(value) => handle_SearchData_Change("staffID", value)}
          options={c_staffs.options}
        />

        {/*日期起、迄*/}
        <RangePicker
          allowClear={false}
          value={[
            s_searchData.startDate && dayjs(s_searchData.startDate),
            s_searchData.endDate && dayjs(s_searchData.endDate),
          ]}
          onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
        />

        <Button type="primary" onClick={handleClick}>
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};

export default SaleList;
