/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Spin,
  Tooltip,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Radio,
  Typography,
  Select,
  InputNumber,
  Modal,
  Button,
  message,
  Grid,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import qs from "qs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import PageHeader from "@/components/PageHeader";
import {
  PrintButton,
  SaveButton,
  PrevDataButton,
  NextDataButton,
  SummaryButton,
  TourButton,
  CancelCreateButton,
} from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// component
import TbContainer from "./Details/TbContainer";
import ThrowOrdModal from "./Modal/ThrowOrdModal";
// util
import { isValidDate } from "@/util/Vaild";
// context
import { DataContext } from "../index";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { getQuoHD, getQuoTB, addQuoHD, updateQuoHD } from "@/service/apis/OMS/Quo";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  isvalid: true, // 是否有效
  validdays: true, // 有效日期
  throwOrd: true, // 拋轉訂單
  // -----
  custID: true, //客戶ID
  currencytype: true,
  exchangerate: true,
  exchangeD: true,
  ordSearch: true, // 訂單紀錄查詢
  // --------
  quoID: true,
  quoT: true,
  taxtype: true,
  taxrate: true,
  quoCheck: true, //報價覆核
  // ----------
  note: true,
  audit: true, // 審核正式簽送
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 客戶確認數量
  PN: true,
  tb_delete: true,
  tb_submit: true,
  demandqty: true, // 報價數量
  unitprice: true, // 報價單價
  utype: true, // 單位類別
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getQuoHD = useAPI(getQuoHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getQuoHD = useAPI(getQuoHD);
  // useAPI 取得表身資料
  const call_getQuoTB = useAPI(getQuoTB);
  // useAPI 新增表頭
  const call_finish_addQuoHD = useAPI(addQuoHD);
  // useAPI 更新表頭
  const call_updateQuoHD = useAPI(updateQuoHD);
  // useAPI 儲存表頭
  const call_finish_updateQuoHD = useAPI(updateQuoHD);
  // useAPI 報價覆核 or 取消覆核
  const call_quoCheck_updateQuoHD = useAPI(updateQuoHD);
  // useAPI 審核正式簽送
  const call_Audit_updateQuoHD = useAPI(updateQuoHD);
  // useAPI 取得上一筆資料
  const call_prev_updateQuoHD = useAPI(updateQuoHD);
  // useAPI 取得下一筆資料
  const call_next_updateQuoHD = useAPI(updateQuoHD);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_custID = Form.useWatch("custID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_tax = Form.useWatch("tax", form);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  // 用來紀錄表頭是否編輯過，以及編輯了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  // 客戶資料、人員資料
  const { c_custs, c_staffs, c_currencys, c_taxtypes } = useContext(ERPContext);
  const { c_userData } = useContext(SystemContext);
  const { mainPage, quo_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 拋轉訂單的跳顯開關
  const [is_showOrd, set_is_showOrd] = useState(false);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;

  // 點擊儲存執行
  const onFinish = async (values) => {
    if (!editMode) {
      call_finish_addQuoHD.request({
        ...values,
        // 新建時lifeFT預設當下時間
        lifeFT: dayjs(),
      });
    }

    if (editMode) {
      // console.log("s_tbData = ", s_tbData);
      call_finish_updateQuoHD.request(values);
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 選擇客戶
  const onCustChange = (value) => {
    const cust_obj = c_custs.data.find((item) => item.custID === value);
    form.setFieldsValue({
      custID: cust_obj.custID,
      custalias: cust_obj.custalias,
    });
  };

  // 選擇幣別
  const currencytypeChange = (value) => {
    const currency_obj = c_currencys.data?.find((item) => {
      return item.currencyitem === value;
    });

    // 幣別如果是本幣，匯率要設成1
    if (currency_obj?.ismycurrency === true) {
      form.setFieldsValue({
        exchangerate: 1,
      });

      // 表身的本幣金額要重算
      calculate_localprice(1);
    }
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_tbData.length === 0) return;

    const tb = s_tbData.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_tbData(tb);
  };

  // 選擇幣別
  const taxtypeChange = (value) => {
    const tax_obj = c_taxtypes.data?.find((item) => item.taxtype === value);
    // 只有tax_obj.disabled為false才可以編輯
    set_s_disabled({
      ...s_disabled,
      taxrate: tax_obj.disabled,
    });

    // 將稅別的稅率塞進表單
    form.setFieldsValue({
      taxrate: tax_obj.taxrate,
    });

    // 稅別發生改變時要重新計算表身稅務資訊
    change_tb_tax(tax_obj.taxrate);
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_tbData.map((item) => {
      // 單項金額
      const itemsum = item.confirmqty * item.unitprice;
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_tbData(tb);
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        isvalid: false,
        validdays: false,
        custID: false,
        currencytype: false,
        exchangerate: false,
        exchangeD: false,
        quoID: false,
        quoT: false,
        taxtype: false,
        quoCheck: false,
        taxrate: false,
        note: false,
        // ------------ tb ------------
        // 建立表身的按鈕
        createBtn: isCreateByThis ? false : isNewdoc,
        PN: false,
        utype: false,
        unitprice: false,
        demandqty: false,
        tb_note: false,
        tb_submit: false,
        tb_delete: false,
      });
    }

    // 報價覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        quoCheck: false,
        audit: false,
      });
    }

    // 審核正式送簽
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        throwOrd: false,
      });
    }

    // 結案
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        ordSearch: false,
      });
    }
  };

  // 查詢下位資料進料檢驗
  const go_ord = () => {
    const ord_opcode = {
      1110: "1310",
      1120: "1320",
    };

    const params_obj = {
      opcode: ord_opcode[mainPage.data?.opcode],
      preopUUID: mainPage.data?.opUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/oms/ord?${params}`, "_blank");
  };

  // 報價覆核 or 取消覆核
  const quoCheck = () => {
    const values = form.getFieldsValue();
    Modal.confirm({
      // title: `${f_lifeF === "2" ? "取消報價覆核確認" : "報價覆核確認"}`,
      title: `${
        f_lifeF === "2"
          ? t("OMS.util.cancel") + t("OMS.util.quo") + t("util.check") + t("util.ok")
          : t("OMS.util.quo") + t("util.check") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t(`OMS.quo.${f_lifeF === "2" ? "cancel_" : ""}quoCheck_confirm_Text`),
      // okText: t("OMS.util.submit"),
      // cancelText: t("OMS.util.cancel"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_quoCheck_updateQuoHD.request({
          ...values,
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 正式送簽
  const Audit = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      title: t("OMS.quo.Audit_confirm"), // 審查簽核確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("OMS.quo.Audit_confirm_text"),
      // okText: t("OMS.util.submit"), // 確定
      // cancelText: t("OMS.util.cancel"), // 取消
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_Audit_updateQuoHD.request({
          ...values,
          lifeF: "7",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      // message.success("目前資料為第一筆，已無上一筆資料");
      message.success(t("OMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateQuoHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === quo_Ns.length) {
      // message.success("目前資料為最後一筆，已無下一筆資料");
      message.success(t("OMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateQuoHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = quo_Ns.findIndex((item) => item === N);

    return index;
  };

  // 據此條目創建
  const createByThis = () => {
    // 重新 set formData
    const newData = {
      ...mainPage.data,
      exchangeD: dayjs(),
      EDT: dayjs(),
      lifeF: "0",
      lifeFT: dayjs(),
      createT: dayjs(),
    };
    Object.entries(mainPage.data).forEach(([key, value]) => {
      if (isValidDate(value)) {
        newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
      }
    });
    form.setFieldsValue({
      ...newData,
    });

    change_MainPage_disabled(newData);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_tbData(
      s_tbData?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          rejectqty: 0,
          goqty: 0,
          judgeT: null,
        };
      })
    );
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建報價單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        lifeF: "0",
        taxtype: "PPN_11%",
        taxrate: 11,
        isvalid: true,
        quoID: c_userData?.staffID,
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        // 匯率預設1
        exchangerate: 1,
        exchangeD: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getQuoTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      console.log("newData=", newData);
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_tbData?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總稅額
    const tax = (itemtotal * f_taxrate) / 100;
    // 含稅總金額
    const total = itemtotal + tax;

    if (itemtotal === 0) return;

    form.setFieldsValue({ itemtotal, tax, total });
  };

  // 計算表身稅務
  useEffect(() => {
    calculateMoneyInfo();
  }, [f_taxrate, f_exchangerate]);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getQuoHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getQuoHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getQuoTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getQuoTB.status]);

  // 新增報價單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addQuoHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getQuoHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getQuoTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addQuoHD.status]);

  // 編輯報價單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateQuoHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getQuoHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getQuoTB.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateQuoHD.status]);

  // useAPI 報價覆核 or 取消覆核
  useEffect(() => {
    const { status, data, msg } = call_quoCheck_updateQuoHD;
    if (status === "suc") {
      message.success(data.lifeF === "2" ? "覆核成功" : "取消覆核成功");
      // 更新表頭
      call_getQuoHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_quoCheck_updateQuoHD.status]);

  // useAPI 審核正式簽送
  useEffect(() => {
    const { status, data, msg } = call_Audit_updateQuoHD;
    if (status === "suc") {
      message.success("審核簽送成功");
      // 更新表頭
      call_getQuoHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_Audit_updateQuoHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateQuoHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // message.success("取得上一筆資料成功");
      message.success(t("OMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getQuoHD.request({ N: quo_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      // message.success("取得上一筆資料失敗");
      message.success(t("OMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateQuoHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateQuoHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // message.success("取得下一筆資料成功");
      message.success(t("OMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getQuoHD.request({ N: quo_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      // message.success("取得下一筆資料失敗");
      message.success(t("OMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateQuoHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getQuoHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getQuoTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getQuoHD.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
        onValuesChange={(changedValues, _) => {
          if (editMode) {
            // 在編輯模式時紀錄表頭編輯了哪些欄位
            set_is_editForm((prev) => {
              let editFlag = [...prev.editFlag];
              const [key] = Object.keys(changedValues);

              if (!editFlag.includes(key)) {
                editFlag.push(key);
              }
              return {
                isEdit: true,
                editFlag,
              };
            });
          }
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* <PageHeader title={editMode ? "編輯報價主檔" : "新建報價主檔"} /> */}
            <PageHeader
              title={
                (editMode ? t("util.edit") : t("util.add")) + t("OMS.util.quo")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => alert("wait")} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  change_MainPage_disabled(s_oriData.hd);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>

        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="custNM" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="lifeFT" hidden>
            <DatePicker />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="createT" hidden>
            <DatePicker />
          </Form.Item>

          <Form.Item name="itemtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="sumtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="tax" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="total" hidden>
            <Input />
          </Form.Item>
        </div>

        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單別 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="opcode"
              label={<Text disabled={s_disabled.opcode}>{t("OMS.util.opcode")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.opcode") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.opcode")}
                disabled={s_disabled.opcode}
                options={[
                  { label: t("OMS.util.opcode_1110"), value: "1110" }, // 國內報價
                  { label: t("OMS.util.opcode_1120"), value: "1120" }, // 國外報價
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 是否有效 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.isvalid}>{t("OMS.quo.isvalid")}</Text>}
              name="isvalid"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.quo.isvalid") }}
            >
              <Radio.Group name="isvalid" disabled={s_disabled.isvalid}>
                <Radio value={true}>{t("OMS.util.yes")}</Radio>
                <Radio value={false}>{t("OMS.util.no")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* 有效天數 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.validdays}>{t("OMS.quo.validdays")}</Text>}
              name="validdays"
            >
              <InputNumber
                className="w-full"
                disabled={s_disabled.validdays}
                placeholder={t("util.placeholder_input") + t("OMS.quo.validdays")}
              />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 引用計畫單 */}
              <Button className="flex-1 h-full" disabled>
                {t("OMS.util.take")}
                <br />
                {t("OMS.util.plan")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("OMS.util.accord")}
                <br />
                {t("OMS.util.create")}
              </Button>
              {/* 拋轉銷貨單 */}
              <Button
                className="flex-1 h-full pms-btn-blue"
                onClick={() => set_is_showOrd(true)}
                disabled={s_disabled.throwOrd}
              >
                {t("OMS.util.throw")}
                <br />
                {t("OMS.util.ord")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 客戶ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="custID"
              label={<Text disabled={s_disabled.custID}>{t("OMS.util.custID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.custID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.custID}
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                onChange={onCustChange}
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          {/* 客戶簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.custalias")}</Text>} name="custalias">
              <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.custID")} />
            </Form.Item>
          </Col>

          {/* 幣別、匯率 */}
          <Col span={editMode ? 5 : 6} className="flex gap-2">
            <Form.Item
              label={<Text disabled={s_disabled.currencytype}>{t("OMS.util.currencytype")}</Text>}
              name="currencytype"
              className="flex-1"
              rules={[{ required: true }]}
              messageVariables={{ label: t("OMS.util.currencytype") }}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.currencytype")}
                disabled={s_disabled.currencytype}
                onChange={currencytypeChange}
                options={c_currencys.options}
              />
            </Form.Item>
            {/* 幣別、匯率 */}
            <Form.Item dependencies={["currencytype"]} className="flex-1">
              {() => {
                // 如果幣別為『本幣』，匯率要disabled，且匯率為1
                const currencytype = form.getFieldValue("currencytype");
                const ismycurrency =
                  c_currencys.data?.find((item) => {
                    return item.currencyitem === currencytype;
                  })?.ismycurrency ?? true;

                return (
                  <Form.Item
                    label={
                      <Text disabled={s_disabled.exchangerate || ismycurrency}>
                        {t("OMS.util.exchangerate")}
                      </Text>
                    }
                    rules={[{ required: true }]}
                    name="exchangerate"
                    // className="mb-0"
                    disabled={s_disabled.exchangerate || ismycurrency}
                  >
                    <InputNumber
                      // placeholder="請輸入匯率"
                      className="w-full"
                      disabled={s_disabled.exchangerate || ismycurrency}
                      onChange={(value) => calculate_localprice(value)}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          {/* 匯率日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.exchangeD}>{t("OMS.util.exchangeD")}</Text>}
              name="exchangeD"
            >
              <DatePicker disabled={s_disabled.exchangeD} className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button className="flex-1 h-full" disabled>
                {/* 計畫紀錄查詢 */}
                {t("OMS.util.planSearch")}
              </Button>
              <Button
                className="flex-1 h-full pms-btn-blue-outline"
                disabled={s_disabled.ordSearch}
                onClick={() => go_ord()}
              >
                {/* 訂單紀錄查詢 */}
                {t("OMS.util.ordSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 報價人名 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.quoID}>{t("OMS.quo.quoID")}</Text>}
              name="quoID"
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.quo.quoID")}
                disabled={s_disabled.quoID}
                showSearch
                options={c_staffs.options}
              />
            </Form.Item>
          </Col>
          {/* 報價日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.quoT}>{t("OMS.quo.quoT")}</Text>}
              name="quoT"
            >
              <DatePicker disabled={s_disabled.quoT} className="w-full" />
            </Form.Item>
          </Col>
          {/* 稅別、稅率 */}
          <Col span={editMode ? 5 : 6} className="flex gap-2">
            <Form.Item
              label={<Text disabled={s_disabled.taxtype}>{t("OMS.util.taxtype")}</Text>}
              name="taxtype"
              className="flex-1"
              messageVariables={{ label: t("OMS.util.taxtype") }}
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("util.placeholder_select") + t("OMS.util.taxtype")}
                disabled={s_disabled.taxtype}
                onChange={taxtypeChange}
                options={c_taxtypes.options}
              />
            </Form.Item>
            {/* 税率 */}
            <Form.Item dependencies={["taxtype"]} className="flex-1">
              {() => {
                const taxtype = form.getFieldValue("taxtype");
                const isDisabled =
                  c_taxtypes.data.find((item) => item.taxtype === taxtype)?.disabled ?? true;

                return (
                  <Form.Item
                    disabled={s_disabled.taxrate || isDisabled}
                    label={<Text disabled={isDisabled}>{t("OMS.util.taxrate")}</Text>}
                    rules={[{ required: true }]}
                    messageVariables={{ label: t("OMS.util.taxrate") }}
                    name="taxrate"
                  >
                    <InputNumber
                      disabled={s_disabled.taxrate || isDisabled}
                      formatter={(value) => `${value}%`}
                      placeholder={t("util.placeholder_input") + t("OMS.util.taxrate")}
                      className="w-full"
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          {/* 更新日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("OMS.util.updtT")}</Text>} name="updtT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              span={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className={`h-full flex-1 ${f_lifeF === "2" ? "pms-btn-red" : "oms-btn-darkBlue"}`}
                disabled={s_tbData.length > 0 ? s_disabled.quoCheck : true}
                onClick={quoCheck}
              >
                {/* {f_lifeF === "2" ? "取消覆核" : "報價單覆核"} */}
                {f_lifeF === "2"
                  ? t("OMS.util.cancel") + t("util.check")
                  : t("OMS.util.quo") + t("util.check")}
              </Button>
              <Button className="flex-1 h-full pms-btn-blue-outline" disabled>
                {/* 上傳簽呈 */}
                {t("OMS.util.UploadSignature")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 報價註記  */}
          <Col span={editMode ? 20 : 24} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.note}>{t("OMS.quo.note")}</Text>}
              name="note"
            >
              <Input disabled={s_disabled.note} />
            </Form.Item>
          </Col>

          {editMode && (
            <Col span={4} className="border-0 border-l flex border-gray-300 border-solid py-2">
              <Button
                disabled={s_disabled.audit}
                className="flex-1 h-full oms-btn-darkBlue"
                onClick={Audit}
              >
                {/* 審核正式送簽 */}
                {t("OMS.quo.lifeF_7")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        is_editForm={is_editForm}
        set_is_editForm={set_is_editForm}
        hd_values={form.getFieldsValue()}
        call_updateQuoHD={call_updateQuoHD}
        call_getQuoHD={call_getQuoHD}
        call_getQuoTB={call_getQuoTB}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
      />

      {/* 拋轉銷貨單 */}
      <CustomModal
        width={screens.xxl ? "80%" : "90%"}
        title={t("OMS.util.throw") + t("OMS.util.ord")} // 拋轉銷貨單
        open={is_showOrd}
        onCancel={() => set_is_showOrd(false)}
      >
        {is_showOrd ? (
          <ThrowOrdModal
            set_is_showOrd={set_is_showOrd}
            hd_values={form.getFieldsValue()}
            call_getQuoHD={call_getQuoHD}
            call_getQuoTB={call_getQuoTB}
            s_tbData={s_tbData}
          />
        ) : null}
      </CustomModal>
    </div>
  );
};

export default MainPage;
