/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { Grid, Space, Input, message, Modal, Divider, Drawer, Typography, theme } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import {
  CreateButton,
  ClearButton,
  ExcelButton,
  DeleteButton,
  EditButton,
  SeeMoreButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSelect, CustomSearchButton } from "@/components/ERP/TabExtra";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../index";
// component
import DrawerModal from "./Modal/DrawerModal";
import { trans } from "@/util/trans";
// API
import useAPI from "@/hooks/useAPI";
import { getQuoHD, deleteQuoHD } from "@/service/apis/OMS/Quo";
const { Title } = Typography;
const { useToken } = theme;
const HD = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  // useAPI 取得表頭資料
  const call_getQuoHD = useAPI(getQuoHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getQuoHDAd = useAPI(getQuoHD);
  // useAPI 刪除表頭資料
  const call_deleteQuoHD = useAPI(deleteQuoHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
  // 廠商資料、人員資料
  const { c_custs, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    custID: [],
    quoID: [],
    lifeF: [
      { value: "0", label: t("OMS.quo.lifeF_0") }, // 發佈
      { value: "2", label: t("OMS.quo.lifeF_2") }, // 報價覆核
      { value: "7", label: t("OMS.quo.lifeF_7") }, // 審核正式簽送
      { value: "T", label: t("OMS.quo.lifeF_T") }, // 結案
    ],
  });

  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();

  const placeholder_map = {
    N: t("util.placeholder_input") + t("OMS.util.N"), // 請輸入單號
    custID: t("util.placeholder_select") + t("OMS.util.custID"), // 請選擇客戶
    lifeF: t("util.placeholder_select") + t("OMS.util.lifeF"), // 請選擇歷程
    quoID: t("util.placeholder_select") + t("OMS.quo.quoID"), // 請選擇報價人員
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getQuoHD.request({ ...tmp });
    }
  };
  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getQuoHD.request({
      ...searchConfig,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getQuoHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T") {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("OMS.util.quo") + t("OMS.util.del_confirm"), // 報價單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的報價單資料無誤，再進行刪除",
      content: t("OMS.quo.hd_delete_confirm_text"),
      onOk: () => call_deleteQuoHD.request(rowData),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  const ppp = {
    util: {
      "PN": "料号",
      "SN": "序",
      "action": "操作",
      "add": "新增",
      "all": "全部",
      "assetkind": "资产分类",
      "assetkind_TD": "压铸",
      "assetkind_TF": "锻造",
      "assetkind_TI": "射出",
      "assetkind_TS": "冲压",
      "attribute": "属性",
      "attribute_0": "0 原料",
      "attribute_1": "1 物料",
      "attribute_2": "2 非标零件",
      "attribute_3": "3 标准零件",
      "attribute_4": "4 半成品",
      "attribute_5": "5 制成品",
      "attribute_6": "6 客供品",
      "cancel": "取消",
      "check": "覆核",
      "close": "关闭",
      "custID": "客户ID",
      "custPN": "客户料号",
      "data": "资料",
      "del_confirm": "删除确认",
      "del_confirm_msg": "请先确认资料无误，再删除",
      "delete": "删除",
      "dept": "部门",
      "detail": "详情",
      "dqty": "条码扫入",
      "drawno": "工程图号",
      "edit": "编辑",
      "end": "迄",
      "excluding_tax": "(未税)",
      "fileType_DRAW": "工程图面 DWG",
      "fileType_PACK": "包装标准 PACK",
      "fileType_PIC": "照片图档 PIC",
      "fileType_SIP": "检验标准 SIP",
      "fileType_SOP": "作业标准 SOP",
      "header_cancel_create": "取消据此创建",
      "header_create": "据此创建条目",
      "heirPN": "继承料号",
      "heirdealID": "继承对象",
      "hide_detail": "隐藏明细档",
      "idmtype": "過帳型態",
      "idmtype_d": "条码过帐",
      "idmtype_m": "混合过帐",
      "idmtype_s": "人工过帐",
      "inC": "产出数量",
      "inqty": "生产数量",
      "isfocus": "关注",
      "isvalid": "启用",
      "itemID": "项目ID",
      "itemNM": "项目名称",
      "lifeF": "历程",
      "linemix": "混线",
      "linemix_0": "不混线",
      "linemix_1": "右",
      "linemix_2": "左",
      "matl": "原料材质",
      "mgmtcat": "授权分类",
      "moldC": "合模次数",
      "moldID": "模具ID",
      "moldcavity": "模具穴数",
      "mtype": "机种",
      "name": "名称",
      "nav_BDM": "物料资料管理",
      "nav_EDM": "模具资料管理",
      "nav_OMS": "销货管理系统",
      "nav_PMS": "采购管理系统",
      "nav_SDM": "系统资料管理",
      "nav_TDM": "厂区资料管理",
      "nav_WMS": "库存管理系统",
      "nav_bdm_edm": "技术资料维护",
      "nav_bdm_pn-manage": "基本资料维护",
      "nav_edm_mold": "模具资料维护",
      "nav_oms_ap-manage": "应收帐款管理",
      "nav_oms_check": "销货检验查询",
      "nav_oms_fod": "预订作业",
      "nav_oms_ord": "销货作业",
      "nav_oms_outstock": "发货作业",
      "nav_oms_quo": "报价作业",
      "nav_oms_report": "报表查询 ",
      "nav_oms_report-salelist": "销退货明细表",
      "nav_oms_report-salesummary": "销退货汇总表",
      "nav_oms_report-unshipped": "订单未出明细表",
      "nav_oms_return": "销货退回作业",
      "nav_pds_abc-board": "异常管理看板",
      "nav_pds_dashboard": "数位生产看板",
      "nav_pds_overview-lines": "线体稼动总表",
      "nav_pms_ap-manage": "应付帐款管理",
      "nav_pms_gostock": "进料检验查询",
      "nav_pms_instock": "进料作业",
      "nav_pms_plan": "询价计划",
      "nav_pms_pro": "采购作业",
      "nav_pms_report": "报表查询",
      "nav_pms_report-diff": "采购价格调幅表",
      "nav_pms_report-instocksummary": "库存汇总表",
      "nav_pms_report-inventorydiff": "库存异动明细帐",
      "nav_pms_report-psisummary": "进销存汇总表",
      "nav_pms_report-purchaselist": "进退货明细表",
      "nav_pms_report-purchasestatistics": "进退货统计表",
      "nav_pms_report-purchasesummary": "进退货汇总表",
      "nav_pms_report-unpurchase": "采购未进货明细表",
      "nav_pms_req": "请购作业",
      "nav_pms_return": "进料退出作业",
      "nav_sdm_category-manage": "查询分类设定",
      "nav_sdm_client": "客户资料维护",
      "nav_sdm_company": "厂商资料维护",
      "nav_sdm_data-auth-manage": "功能授权设定",
      "nav_sdm_function-params-manage": "系统参数设定",
      "nav_sdm_trading-pattern": "项目字串设定",
      "nav_sdm_user-manage": "员工资料维护",
      "nav_tdm_plant-manage": "厂别主档设定",
      "nav_tdm_plant-params-manage": "",
      "nav_tdm_procedure-manage": "制程规划维护",
      "nav_tdm_production-line-manage": "产线规划维护",
      "nav_tdm_string-manage": "功能字串管理",
      "nav_tdm_warehouse-manage": "库房规划维护",
      "nav_wms_inv-history": "每月库存纪录",
      "nav_wms_plan-in": "库房入库作业",
      "nav_wms_plan-out": "库房出库作业",
      "nav_wms_pro-instock": "代理入库作业",
      "nav_wms_stock-search": "库存状况查询",
      "ngC": "不良数量",
      "no": "否",
      "no_data": "无此资料",
      "note": "注记",
      "ok": "确认",
      "okC": "良品数量",
      "opunitNM": "操作单位",
      "or": "或",
      "order": "单",
      "packtype_0": "操作包装_操作单位使用",
      "packtype_1": "出货包装_打包出货使用",
      "packtype_2": "周转包装_产线周转使用",
      "pdtNM": "品名",
      "pdtclass": "商品",
      "pdtclass_A": "附件",
      "pdtclass_M": "主件",
      "pdtclass_P": "包材",
      "pdtclass_X": "N/A",
      "pdtspec": "规格",
      "placeholder_input": "请输入",
      "placeholder_select": "请选择",
      "planqty": "计划数量",
      "qty": "组数",
      "querycat": "查询分类",
      "render": "选择",
      "search": "查询",
      "showTotal": "共 {{total}} 条 ， 当前显示 {{rangeFirst}} - {{rangeEnd}} 条",
      "sign_out": "登出",
      "situF": "状态",
      "size_big": "特大",
      "size_large": "大尺寸",
      "size_middle": "中尺寸",
      "size_small": "小尺寸",
      "sqty": "人工输入",
      "start": "起",
      "success": "成功",
      "total": "总数",
      "tqty": "当前总数",
      "unitNM": "计量单位",
      "unitwt": "计数单重",
      "updtT": "更新时间",
      "ver": "版码",
      "whID": "仓别",
      "wl": "工线",
      "woN": "工单号",
      "ws": "工站",
      "yes": "是"
    }
  }

  // 第一次渲染取得表頭資料
  useEffect(() => {
    console.log("===== trans =====");
    trans("util",{});
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getQuoHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getQuoHD.request({
      ...searchConfig,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      custID: c_custs.options,
      quoID: c_staffs.options,
    }));
  }, [c_custs, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getQuoHD;
    if (status === "suc") {
      console.log("call_getQuoHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newQuo_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ quo_Ns: newQuo_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getQuoHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getQuoHDAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newQuo_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ quo_Ns: newQuo_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getQuoHDAd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deleteQuoHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getQuoHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteQuoHD.status]);

  useEffect (() => {
    updateState({lightData:{}})
  },[])

  // 表格欄位
  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "6%",
      render: (text) => {
        const map = {
          1110: t("OMS.util.opcode_1110"), // 國內報價
          1120: t("OMS.util.opcode_1120"), // 國外報價
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.custID"), // 客戶ID
      dataIndex: "custID",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
    },
    {
      title: t("OMS.util.currencytype"), // 幣別
      dataIndex: "currencytype",
      align: "center",
      width: "7.1%",
    },
    {
      title: t("OMS.util.exchangerate"), // 匯率
      dataIndex: "exchangerate",
      align: "center",
      width: "5%",
    },
    {
      title: t("OMS.util.taxtype"), // 稅別
      dataIndex: "taxtype",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.taxrate"), // 稅率
      dataIndex: "taxrate",
      align: "center",
      width: "6%",
      render: (text, _, __) => `${text}%`,
    },
    {
      title: t("OMS.quo.quoID"), // 報價人員
      dataIndex: "quoID",
      align: "center",
      width: "9%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.quo.quoT"), // 報價日期
      dataIndex: "quoT",
      align: "center",
      width: "8%",
      render: (text) => (text.includes("0001-01-01") ? null : dayjs(text).format("YYYY-MM-DD")),
    },
    {
      title: t("OMS.quo.validdays"), // 有效天數
      dataIndex: "validdays",
      align: "center",
      width: "7%",
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: "7%",
      render: (text) => {
        const map = {
          "0": t("OMS.quo.lifeF_0"), // 發佈
          "2": t("OMS.quo.lifeF_2"), // 報價覆核
          "7": t("OMS.quo.lifeF_7"), // 審核正式簽送
          "T": t("OMS.quo.lifeF_T"), // 結案
        };
        return map[text];
      },
    },
    {
      title: t("OMS.quo.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text, _, __) => {
        return (
          <div className="checkIcon">
            {text === true ? (
              <CheckCircleFilled style={{ color: token.colorPrimary }} />
            ) : text === false ? (
              <CloseCircleOutlined />
            ) : null}
          </div>
        );
      },
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />

          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <PageHeader
        title={t("OMS.quo.pageHeader")} // 報價作業
        extra={<TourButton />}
      />
      <Space className="flex justify-end mb-2">
        <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
        <CustomRangePicker
          disabled={tabKey === "detailSearch"}
          value={[searchConfig?.startDate, searchConfig?.endDate]}
          onCalendarChange={(dates, _, __) => {
            updateState({
              searchConfig: {
                ...searchConfig,
                startDate: dates[0],
                endDate: dates[1],
              },
            });
          }}
        />

        <Input.Group compact>
          <CustomSelect
            disabled={tabKey === "detailSearch"}
            value={searchConfig.searchFlag}
            placeholder={t('util.search')}
            onChange={(value) => handleChange("searchFlag", value)}
            options={[
              { value: "N", label: t("OMS.util.N") }, // 單號
              { value: "custID", label: t("OMS.util.custID") }, // 客戶ID
              { value: "lifeF", label: t("OMS.util.lifeF") }, // 歷程
              { value: "quoID", label: t("OMS.quo.quoID") }, // 報價人員
            ]}
          />

          {searchConfig.searchFlag === "lifeF" ||
            searchConfig.searchFlag === "custID" ||
            searchConfig.searchFlag === "quoID" ? (
            <CustomSelect
              showSearch
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(value) => handleChange("searchValue", value)}
              // 根據searchFlag判斷現在是哪個下拉選單
              options={s_selectOptions[searchConfig.searchFlag]}
            />
          ) : (
            <Input
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          )}
          <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
        </Input.Group>

        {/* 清除搜詢紀錄 */}
        <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
        {/* 新建 */}
        <CreateButton
          disabled={tabKey === "detailSearch"}
          onClick={() => updateState({ mainPage: { show: true, data: {} } })}
        />
      </Space>

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getQuoHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 報價單主檔 */}
            {t("OMS.quo.hd_drawer_title")}
          </Title>
        }
        placement="right"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default HD;
