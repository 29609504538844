import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";

// 取得表頭資料
export const getReturnHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnHD`, {
    params: data,
  });
};

export const getReturnHDAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnHD`, {
    params: data,
  });
};

// 取得驗退單
export const getOutRtn = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutRtn`, {
    params: data,
  });
};
//-----------------------------------------表身資料----------------------------------------
export const getReturnTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnTB`, {
    params: data,
  });
};

export const deleteReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReturnHD`, data);
};
export const updateReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReturnTB`, data);
};
export const deleteReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReturnTB`, data);
};
export const addReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReturnTB`, data);
};

export const addReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReturnHD`, data);
};
export const updateReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReturnHD`, data);
};
export const takeRtn = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeRtn`, data);
};
