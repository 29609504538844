/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Input, Select, Divider, message, Radio } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// api
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addQmsSipHd, updateQmsSipHd } from "@/service/apis/QMS/QMSManage";

export default function ModalConintaer({
  s_editData,
  set_s_editData,
  set_s_isShowModal,
  s_arrMap,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // 用來判斷現在是新建還是編輯模式
  const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
  const f_ctrlspot = Form.useWatch("ctrlspot", form);
  // 新增表頭資料
  const call_addQmsSipHd = useAPI(addQmsSipHd);
  // 編輯表頭資料
  const call_updateQmsSipHd = useAPI(updateQmsSipHd);
  console.log("s_editData = ", s_editData);
  const onSubmit = (values) => {
    if (type === "create") {
      call_addQmsSipHd.request(values);
    }
    if (type === "edit") {
      call_updateQmsSipHd.request(values);
    }
  };

  // 遠程搜索料號
  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  // useAPI 新建 SIP
  useEffect(() => {
    const { status, msg } = call_addQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_addQmsSipHd.status]);

  // useAPI 修改 SIP
  useEffect(() => {
    const { status, msg } = call_updateQmsSipHd;
    if (status === "suc") {
      message.success(msg);
      set_s_isShowModal(false);
    } else if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateQmsSipHd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
      onFinish={onSubmit}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="sipID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          {/* 料號 */}
          <Form.Item label={t("util.PN")} name="PN" rules={[{ required: true }]}>
            <DebounceSelect
              disabled={type === "edit"}
              fetchOptions={fetchPNList}
              maxTagCount={10}
              showSearch
              onChange={(value) => {
                form.setFieldsValue({ PN: value.value, pdtNM: value.title });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 品名 */}
          <Form.Item label={t("util.pdtNM")} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* 是否有效 */}
          <Form.Item label={t("util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 12]}>
        <Col span={8}>
          <Form.Item label="品檢類型" name="siptype" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "首件檢驗(FAI)", value: "FAI" },
                { label: "進料檢驗(IQC)", value: "IQC" },
                { label: "設備檢測(ESPC)", value: "ESPC" },
                { label: "終品檢驗(FQC)", value: "FQC" },
                { label: "出貨檢驗(FQC)", value: "OQC" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="控點" name="ctrlspot" rules={[{ required: true }]}>
            <Select
              className="w-full"
              allowClear
              options={[
                { label: "製程比對", value: "pf" },
                { label: "工站比對", value: "ws" },
                { label: "工線比對", value: "wl" },
                // { label: "工序比對", value: "wg" },
                // { label: "設備檢驗", value: "we" },
                { label: "全品比對", value: "wp" },
              ]}
              onChange={() => form.setFieldsValue({ spotID: null })}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="控點ID" name="spotID">
            <Select
              disabled={form.getFieldValue("ctrlspot") === "wp"}
              className="w-full"
              allowClear
              options={s_arrMap[f_ctrlspot]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 12]}>
        <Col span={4}>
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
        </Col>
        <Col span={20} className="flex justify-end gap-2">
          <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
          <Button type="primary" htmlType="submit">
            {t("util.ok")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
