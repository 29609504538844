import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

// 新增表頭
export const addReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReturnHD`, data);
};
// 取得表頭資料
export const getReturnHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnHD`, { params: data });
};
export const getReturnHDAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnHDAd`, { params: data });
};
// 更新表頭
export const updateReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReturnHD`, data);
};
// 刪除表頭
export const deleteReturnHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReturnHD`, data);
};
// ==================================================================================
// 取得引用資料(驗退單)
export const getOutRtn = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutRtn`, { params: data });
};
// 引用
export const takeOutRtn = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeOutRtn`, data);
};
// ==================================================================================

// 新增表身資料
export const addReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReturnTB`, data);
};
// 取得表身資料
export const getReturnTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReturnTB`, { params: data });
};
// 更新表身資料
export const updateReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReturnTB`, data);
};
// 刪除表身資料
export const deleteReturnTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReturnTB`, data);
};
