import React, { useState, useEffect } from "react";
import {
  Button,
  Space,
  Modal,
  message,
  Row,
  Col,
  Typography,
  Form,
  InputNumber,
  Input,
} from "antd";

import { updateModelPdt } from "@/service/apis/LMS/LabelManage";
import useAPI from "@/hooks/useAPI";

export default function EditBindPN({ set_s_showModal, s_editData, c_lightData }) {
  const [form] = Form.useForm();

  const call_updateModelPdt = useAPI(updateModelPdt);

  const onSubmit = (values) => {
    let postData = {
      ...s_editData,
      ...c_lightData,
      ...values,
    };
    call_updateModelPdt.request(postData);
  };

  useEffect(() => {
    if (call_updateModelPdt.status === "suc") {
      set_s_showModal(false);
      message.success(call_updateModelPdt.msg);
    } else if (call_updateModelPdt.status === "err") {
      message.error(call_updateModelPdt.msg);
    }
  }, [call_updateModelPdt.status]);

  return (
    <Form
      name="basic"
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ ...c_lightData, ...s_editData }}
    >
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <Form.Item label="ERP料號" name="PN" rules={[{ required: true }]}>
            <Input placeholder="請選擇ERP料號" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="客戶料號" name="custPN">
            <Input placeholder="請選擇客戶料號" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="條碼最大總長" name="length" rules={[{ required: true }]}>
            <InputNumber min={1} className="w-full" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="備註" name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_showModal(false)}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
