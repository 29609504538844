import { Button, Col, Collapse, Form, Input, Radio, Row, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { addRole, updateRole } from "@/service/apis/ADM/userManage";

import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addRole = useAPI(addRole);
  const call_updateRole = useAPI(updateRole);

  const options = [
    { value: true, label: t("util.yes") },
    { value: false, label: t("util.no") },
  ];

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const { itemID, itemNM, ...authData } = values;
    const postData = {
      itemID,
      itemNM,
      auth: JSON.stringify(authData),
    };

    if (type === "create") {
      call_addRole.request(postData);
    }

    if (type === "edit") {
      call_updateRole.request(postData);
    }
  };

  useEffect(() => {
    if (call_addRole.status === "suc") {
      message.success(call_addRole.msg);
      set_s_isShowModal(false);
    } else if (call_addRole.status === "err") {
      message.error(t(`errorCode.${call_addRole.msg}`));
    }
  }, [call_addRole.status]);

  useEffect(() => {
    if (call_updateRole.status === "suc") {
      message.success(call_updateRole.msg);
      set_s_isShowModal(false);
    } else if (call_updateRole.status === "err") {
      message.error(t(`errorCode.${call_updateRole.msg}`));
    }
  }, [call_updateRole.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        // MOM
        A: true,
        "A-1": true,
        "A-2": true,
        "A-3": true,
        B: true,
        "B-1": true,
        "B-2": true,
        "B-3": true,
        "B-4": true,
        C: true,
        "C-1": true,
        "C-2": true,
        "C-3": true,
        "C-4": true,
        "C-5": true,
        "C-6": true,
        "orderCheck": true,
        D: true,
        "D-1": true,
        "D-2": true,
        "D-3": true,
        "D-4": true,
        "D-5": true,
        "D-6": true,
        E: true,
        "E-1": true,
        "E-2": true,
        "E-3": true,
        "E-4": true,
        ...s_editData,
      }}
    >
      <Row gutter={[12, 12]}>
        <Col span={8}>
          {/* 角色ID */}
          <Form.Item label={t("ADM.user-manage.roleID")} name="itemID" rules={[{ required: true }]}>
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 角色名稱 */}
          <Form.Item label={t("ADM.user-manage.roleNM")} name="itemNM" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Col>

        <Col span={8} />

        <Col span={24}>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel forceRender header="MOM" key="1">
              <Collapse defaultActiveKey={["A"]}>
                <Panel forceRender header={`A. ${t("util.nav_A")}`} key="A">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_A")} name="A">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-1")} name="A-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-2")} name="A-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-3")} name="A-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`B. ${t("util.nav_B")}`} key="B">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B")} name="B">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-1")} name="B-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-2")} name="B-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-3")} name="B-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="报价订单作业_报价审批" name="orderCheck">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`C. ${t("util.nav_C")}`} key="C">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C")} name="C">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-1")} name="C-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-2")} name="C-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-3")} name="C-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-4")} name="C-4">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-5")} name="C-5">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-6")} name="C-6">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`D. ${t("util.nav_D")}`} key="D">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D")} name="D">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-1")} name="D-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-2")} name="D-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-3")} name="D-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-4")} name="D-4">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-5")} name="D-5">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-6")} name="D-6">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`E. 库存管理`} key="E">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="库存管理" name="E">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房入库作业" name="E-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房领用作业" name="E-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库存状况查询" name="E-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房盘点作业" name="E-4">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
