import { instance } from "../../handlerAPI";
import { bpm_apiBasic as apiBasic } from "../../APIBasic";

//-----------------------------------------新增簽核權限設定----------------------------------------
export const addSignLevel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addSignLevel`, data);
};

//-----------------------------------------取得簽核權限設定----------------------------------------
export const getSignLevel = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSignLevel`, {
    params: data,
  });
};

//-----------------------------------------更新簽核權限設定----------------------------------------
export const updateSignLevel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateSignLevel`, data);
};

//-----------------------------------------刪除簽核權限設定----------------------------------------
export const deleteSignLevel = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteSignLevel`, data);
};
