import React from "react";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util function
import { formatAmount } from "@/util/format";

const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { data } = s_drawer;

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* ERP料號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.ap-manage.PN")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.PN}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 進料單號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.N24")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.N24}</Text>
        </Col>
        {/* 採購單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.N23")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.N23}</Text>
        </Col>
        {/* 請購單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.N22")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.N22}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 單位類別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.utype")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.utype === "unit" ? t("PMS.util.unit")  : data.utype === "opunit" ? t("PMS.util.opunit") : ""}
          </Text>
        </Col>
        {/* 使用單位 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.ap-manage.punit")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.punit}</Text>
        </Col>
        {/* 移轉數量 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.ap-manage.inqty")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.inqty)}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 採購單價 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.unitprice")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.unitprice)}</Text>
        </Col>
        {/* 本幣單價 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.util.localprice")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.localprice)}</Text>
        </Col>
        {/* 各項金額 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("PMS.ap-manage.itemsum")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.itemsum)}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 各項稅額 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.ap-manage.itemsumtax")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.itemsumtax)}</Text>
        </Col>
        {/* 含稅小記 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.ap-manage.subtotal")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{formatAmount(data.subtotal)}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
