import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//----------------------------------------- 取得模具 ----------------------------------------
export const getMold = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getMold`, {
    params: data,
  });
};

//----------------------------------------- 新增模具  ----------------------------------------
export const addMold = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addMold`, data);
};
//----------------------------------------- 新增模具  ----------------------------------------
export const uploadMold = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/uploadMold`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//----------------------------------------- 編輯模具  ----------------------------------------
export const updateMold = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateMold`, data);
};

//----------------------------------------- 刪除模具  ----------------------------------------
export const deleteMold = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteMold`, data);
};
