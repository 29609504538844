import { Button, Col, Divider, Row, message } from "antd";
import { CancelButton, QuoteButton } from "@/components/ERP/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// util function
import { formatAmount } from "@/util/format";
import { getApHD } from "@/service/apis/OMS/ap";
import { takeAP } from "@/service/apis/OMS/strike";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const TakeApModal = ({ hd_values, call_getStrikeHD, set_is_showTake }) => {
  const { t } = useTranslation();
  // useAPI 取得引用資料
  const call_getApHD = useAPI(getApHD);
  // useAPI 引用對帳資料
  const call_takeAP = useAPI(takeAP);

  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 表格中被選中的明細(key, 給antd用的)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("newSelectedRowKeys = ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  // 確定引用
  const onSubmit = () => {
    if (selectedRowKeys.length === 0) {
      // 請選擇要引用的資料
      message.error(t("PMS.ap-manage.message_chose_take_which_ap"));
      return;
    }
    if (selectedRowKeys.length !== 1) {
      //只可引用一張對帳單
      message.error("只可引用一张对帐单");
      return;
    }
    // 要被引用的明細
    const takeList = selectedRowKeys.map((rowkey) => {
      return {
        ...s_tableData[rowkey],
        HdOpUUID: hd_values.opUUID,
        HdN: hd_values.N,
        // 沖帳總金額=應付含稅總金額
        sumtotal: s_tableData[rowkey].sumtotal,
      };
    });
    console.log("takeList = ", takeList);
    // return;
    call_takeAP.request(takeList[0]);
    // console.log(13)
  };

  // 第一次渲染取得引用資料
  useEffect(() => {
    // 只有歷程為2的才可以被沖帳
    call_getApHD.request({ lifeF: "2" });
  }, []);

  // useAPI 取得引用資料
  useEffect(() => {
    const { status, msg, data } = call_getApHD;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(data);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getApHD.status]);

  // useAPI 確定引用
  useEffect(() => {
    const { status, msg } = call_takeAP;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getStrikeHD.request({ opUUID: hd_values.opUUID });
      set_is_showTake(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_is_showTake(false);
    }
  }, [call_takeAP.status]);

  const tableColumns = [
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.util.custID"), // 客戶ID
      dataIndex: "custID",
      width: "8%",
      align: "center",
    },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
    },
    {
      title: t("OMS.ap-manage.ap_sumtotal"), // 總項金額
      dataIndex: "sumtotal",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
  ];

  return (
    <>
      <CustomTable
        columns={tableColumns}
        rowSelection={rowSelection}
        rowNumber={6}
        dataSource={s_tableData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_is_showTake(false)} />
          <QuoteButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </>
  );
};

export default TakeApModal;
