import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, Radio } from "antd";
import { addFunc, modifyFunc } from "@/service/apis/ADM/systemParamsManage";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();

  const call_addFunc = useAPI(addFunc);
  const call_modifyFunc = useAPI(modifyFunc);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addFunc.request(values);
    }
    if (type === "edit") {
      call_modifyFunc.request(values);
    }
  };

  useEffect(() => {
    if (call_addFunc.status === "suc") {
      message.success(call_addFunc.msg);
      set_s_isShowModal(false);
    } else if (call_addFunc.status === "err") {
      message.error(t(`errorCode.${call_addFunc.msg}`));
    }
  }, [call_addFunc.status]);

  useEffect(() => {
    if (call_modifyFunc.status === "suc") {
      message.success(call_modifyFunc.msg);
      set_s_isShowModal(false);
    } else if (call_modifyFunc.status === "err") {
      message.error(t(`errorCode.${call_modifyFunc.msg}`));
    }
  }, [call_modifyFunc.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={12}>
          {/*組織功能碼*/}
          <Form.Item
            label={t("ADM.function-params-manage.funcode")}
            name="funcode"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length > 0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/*組織功能名稱*/}
          <Form.Item
            label={t("ADM.function-params-manage.funNM")}
            name="funNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
