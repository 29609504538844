/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Input, Button, Divider, message, Space, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
// component
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// API
import useAPI from "@/hooks/useAPI";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addApsOutMoTb, updateApsOutMoTb } from "@/service/apis/APS/apsMange";

const ModalContainer = ({ s_editData, set_s_showModal, c_lightData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const type = Object.keys(s_editData).length === 0 ? "create" : "edit";

  // 新增表身資料
  const call_addApsOutMoTb = useAPI(addApsOutMoTb);
  // 更新表身資料
  const call_updateApsOutMoTb = useAPI(updateApsOutMoTb);

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  const onSubmit = async (values) => {
    type === "create" ? call_addApsOutMoTb.request(values) : call_updateApsOutMoTb.request(values);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...s_editData,
      opUUID: c_lightData.opUUID,
    });
  }, []);

  // useAPI 新增表身
  useEffect(() => {
    const { status, msg } = call_addApsOutMoTb;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_addApsOutMoTb.status]);

  // useAPI 編輯表身
  useEffect(() => {
    const { data, status, msg } = call_updateApsOutMoTb;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_updateApsOutMoTb.status]);

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preopUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemno" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            label={t("util.PN")} // 料號
            rules={[{ required: true, message: t("util.placeholder_select") + t("util.PN") }]}
            name="PN"
          >
            <DebounceSelect
              disabled={type === "edit"}
              key="DebounceSelect"
              placeholder={t("util.placeholder_input") + " " + t("util.PN")}
              allowClear
              fetchOptions={fetchPNList}
              showSearch
              maxTagCount={10}
              onChange={(value, option) => {
                form.setFieldsValue({ PN: option.data.PN, pdtNM: option.data.pdtNM });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="pdtNM"
            label={t("util.pdtNM")} // 品名
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6} className="mb12">
          <Form.Item
            label={t("util.planqty")} // 計畫數量
            name="planqty"
          >
            <InputNumber className="w-full" />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            label={t("APS.util.realqty")} // 實際數量
            name="realqty"
          >
            <InputNumber disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button size="large" onClick={handleCancel}>
              {/* 取消 */}
              {t("util.cancel")}
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              {/* 確定 */}
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
