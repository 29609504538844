import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { Modal, Space, message } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { deleteStaffPerm, getRole, getStaffPerm } from "@/service/apis/ADM/userManage";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const SystemAuth = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getStaffPerm = useAPI(getStaffPerm);
  const call_deleteStaffPerm = useAPI(deleteStaffPerm);
  const call_getRole = useAPI(getRole);

  const tableColumns = [
    {
      title: t("ADM.user-manage.account"), //員工帳號
      dataIndex: "account",
    },
    {
      title: t("ADM.user-manage.peopleNM"), //員工姓名
      dataIndex: "peopleNM",
    },
    {
      title: t("util.nav_A"), // 業務管理
      dataIndex: "A",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_B"), // 項目管理
      dataIndex: "B",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_C"), // 財務管理
      dataIndex: "C",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_D"), // 系統管理
      dataIndex: "D",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "库存管理",
      dataIndex: "E",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"), //操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 找出多個 auth 中 為true的key
  function filterObjects(arr) {
    const result = {};
    for (const item of arr) {
      for (const prop in item) {
        if (item[prop]) {
          result[prop] = true;
        }
      }
    }
    return result;
  }

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => {
    call_getStaffPerm.request();
  };

  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      cancelText: t("util.cancel"),
      onOk: () => call_deleteStaffPerm.request(rowData),
    });
  };

  useEffect(() => {
    call_getRole.request();
  }, []);

  useEffect(() => {
    if (call_getStaffPerm.status === "suc") {
      message.success(call_getStaffPerm.msg);
      // 整理資料
      set_s_tableData(
        call_getStaffPerm.data.map((x, i) => {
          const roles = x.role.split("|").filter((x) => x !== "");
          const authArr = call_getRole.data.reduce((data, curr) => {
            for (const x of roles) {
              curr.itemID === x && data.push(JSON.parse(curr.auth));
            }
            return data;
          }, []);
          const result = filterObjects(authArr);
          return {
            ...x,
            key: i,
            role: roles,
            ...result,
          };
        })
      );
    } else if (call_getStaffPerm.status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${call_getStaffPerm.msg}`));
    }
  }, [call_getStaffPerm.status]);

  useEffect(() => {
    if (call_deleteStaffPerm.status === "suc") {
      message.success(call_deleteStaffPerm.msg);
    } else if (call_deleteStaffPerm.status === "err") {
      message.error(t(`errorCode.${call_deleteStaffPerm.msg}`));
    }
    call_getStaffPerm.request();
  }, [call_deleteStaffPerm.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getStaffPerm.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.user-manage.systemAuth")}`
            : `${t("util.add")}${t("ADM.user-manage.systemAuth")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getStaffPerm.request();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
    </>
  );
});

export default SystemAuth;
