import {
  ClearButton,
  CreateButton,
  DeleteButton,
  DetailButton,
  EditButton,
  ExcelButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSearchButton, CustomSelect } from "@/components/ERP/TabExtra";
import { Divider, Grid, Input, Modal, Space, message } from "antd";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { deleteReqHD, getReqHD } from "@/service/apis/PMS/Req";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import { DataContext } from "../index";
// component
import DetailModal from "./Modal/DetailModal";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// util component
import PageHeader from "@/components/PageHeader";
import { TourButton } from "@/components/ERP/Button";
import dayjs from "dayjs";
// API
import useAPI from "@/hooks/useAPI";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HD = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getReqHD = useAPI(getReqHD);
  // useAPI 詳細搜尋取得表頭資料
  const call_getReqHDAd = useAPI(getReqHD);
  // useAPI 刪除表頭資料
  const call_deleteReqHD = useAPI(deleteReqHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
  // 廠商資料、人員資料
  const { c_supps, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    suppID: [],
    driID: [],
    lifeF: [
      { value: "0", label: t("PMS.req.lifeF_0") }, // 發佈
      { value: "2", label: t("PMS.req.lifeF_2") }, // 請購覆核
      { value: "7", label: t("PMS.req.lifeF_7") }, // 審核送簽完成
      { value: "T", label: t("PMS.req.lifeF_T") }, // 結案
    ],
  });
  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  const [s_detailSearch, set_s_detailSearch] = useState({
    show: false,
    data: {},
  });

  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "2210", label: t("PMS.util.opcode_2210") }, // 國內請購
    { key: "2220", label: t("PMS.util.opcode_2220") }, // 國外請購
    { key: "2230", label: t("PMS.util.opcode_2230") }, // 委外申請
    { key: "detailSearch", label: t("PMS.util.advanceSearch") }, // 進階查詢
  ];
  const placeholder_map = {
    N: t("PMS.util.placeholder_input") + t("PMS.util.N"), // 請輸入單號
    suppID: t("PMS.util.placeholder_select") + t("PMS.util.suppID"), // 請選擇廠商
    lifeF: t("PMS.util.placeholder_select") + t("PMS.util.lifeF"), // 請選擇歷程
    driID: t("PMS.util.placeholder_select") + t("PMS.req.driID"), // 請選擇請購人員
  };
  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    console.log("activeKey = ", activeKey);
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getReqHD.request({ ...tmp, opcode: tabKey });
    }
  };
  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getReqHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getReqHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T") {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("PMS.util.req") + t("PMS.util.delete_confirm"), // 請購單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的請購單資料無誤，再進行刪除",
      content: t("PMS.req.del_hd_confirm_text"),
      // okText: t("PMS.util.submit"), // 確定
      // cancelText: t("PMS.util.cancel"), // 取消
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deleteReqHD.request(rowData),
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getReqHD.request({
        ...searchConfig,
        opcode: tabKey,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getReqHD.request({
      ...searchConfig,
      opcode: opcode,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      suppID: c_supps.options,
      driID: c_staffs.options,
    }));
  }, [c_supps, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getReqHD;
    console.log("data = ", data);
    if (status === "suc") {
      console.log("call_getReqHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newReq_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ req_Ns: newReq_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getReqHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getReqHDAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newReq_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ req_Ns: newReq_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getReqHDAd.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deleteReqHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getReqHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteReqHD.status]);

  // 表格欄位
  const tableColumns = [
    // {
    //   title: t("PMS.util.opcode"),
    //   dataIndex: "opcode",
    //   align: "center",
    //   width: screens.xxl === true ? "10%" : "9%",
    //   render: (text) => t(`PMS.util.opcode_${text}`),
    // },
    {
      // title: "單號",
      title: t("PMS.util.N"),
      dataIndex: "N",
      align: "center",
      width: screens.xxl === true ? "10%" : "10%",
    },
    {
      // title: "交易幣別",
      title: t("PMS.util.currencytype"),
      dataIndex: "currencytype",
      align: "center",
      width: screens.xxl === true ? "10%" : "10%",
    },
    // {
    //   // title: "交易稅別",
    //   title: t("PMS.util.taxtype"),
    //   dataIndex: "taxtype",
    //   align: "center",
    //   width: screens.xxl === true ? "10%" : "10%",
    // },
    {
      // title: "稅率",
      title: t("PMS.util.taxrate"),
      dataIndex: "taxrate",
      align: "center",
      width: screens.xxl === true ? "10%" : "8%",
      render: (text) => `${text}%`,
    },
    {
      // title: "需求日期",
      title: t("PMS.req.demandDate"),
      dataIndex: "demandDate",
      align: "center",
      width: screens.xxl === true ? "10%" : "8%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      // title: "廠商簡稱",
      title: t("PMS.util.suppalias"),
      dataIndex: "suppalias",
      align: "center",
    },
    {
      // title: "負責請購",
      title: t("PMS.req.driID"),
      dataIndex: "driID",
      align: "center",
      width: screens.xxl === true ? "10%" : "11%",
      render: (text) => getStaffName(text),
    },
    {
      // title: "歷程",
      title: t("PMS.util.lifeF"),
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl === true ? "10%" : "9%",
      render: (text) => t(`PMS.req.lifeF_${text}`),
    },
    {
      // title: "歷程時間",
      title: t("PMS.util.lifeFT"),
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl === true ? "9%" : "13%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      // title: "操作",
      title: t("PMS.util.table_operate"),
      dataIndex: "operate",
      align: "center",
      width: screens.xxl === true ? "5%" : "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />

          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      {/* 請購作業 */}
      <PageHeader title={t("PMS.req.pageHeader")} extra={<TourButton />} />
      {/* <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          
        }
      /> */}

      <Space className="flex justify-end">
        <CustomRangePicker
          disabled={tabKey === "detailSearch"}
          value={[searchConfig?.startDate, searchConfig?.endDate]}
          onCalendarChange={(dates, _, __) => {
            updateState({
              searchConfig: {
                ...searchConfig,
                startDate: dates[0],
                endDate: dates[1],
              },
            });
          }}
        />

        <Input.Group compact>
          <CustomSelect
            disabled={tabKey === "detailSearch"}
            value={searchConfig.searchFlag}
            placeholder={t("PMS.util.searchField")}
            onChange={(value) => handleChange("searchFlag", value)}
            options={[
              { value: "N", label: t("PMS.util.N") }, // 單號
              { value: "suppID", label: t("PMS.util.suppalias") }, // 廠商ID
              { value: "lifeF", label: t("PMS.util.lifeF") }, // 歷程
              { value: "driID", label: t("PMS.req.driID") }, // 負責請購
            ]}
          />

          {searchConfig.searchFlag === "lifeF" ||
          searchConfig.searchFlag === "suppID" ||
          searchConfig.searchFlag === "driID" ? (
            <CustomSelect
              showSearch
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(value) => handleChange("searchValue", value)}
              // 根據searchFlag判斷現在是哪個下拉選單
              options={s_selectOptions[searchConfig.searchFlag]}
            />
          ) : (
            <Input
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          )}
          <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
        </Input.Group>

        {/* 清除搜詢紀錄 */}
        <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
        {/* 新建 */}
        <CreateButton
          disabled={tabKey === "detailSearch"}
          onClick={() => updateState({ mainPage: { show: true, data: {} } })}
        />
      </Space>

      <Divider className="my-2" />

      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
          <DetailButton onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: true }))} />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getReqHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("PMS.util.setAdvanced")}
        open={s_detailSearch.show}
        onCancel={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
      >
        {s_detailSearch.show && (
          <DetailModal
            s_selectOptions={s_selectOptions}
            s_detailSearch={s_detailSearch}
            set_s_detailSearch={set_s_detailSearch}
            call_getReqHDAd={call_getReqHDAd}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default HD;
