import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { Button, Divider, Input, Select, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

import Basic from "./Basic";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import PageHeader from "@/components/PageHeader";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function DetectionManage() {
	const { t } = useTranslation();

	const attributeMap = [
		{ label: t("util.attribute_0"), value: "0" },
		{ label: t("util.attribute_1"), value: "1" },
		{ label: t("util.attribute_2"), value: "2" },
		{ label: t("util.attribute_3"), value: "3" },
		{ label: t("util.attribute_4"), value: "4" },
		{ label: t("util.attribute_5"), value: "5" },
		{ label: t("util.attribute_6"), value: "6" },
	];

	const pdtclassMap = [
		// { label: "N/A", value: "N/A" },
		{ label: t("util.pdtclass_X"), value: "X" },
		{ label: t("util.pdtclass_M"), value: "M" },
		{ label: t("util.pdtclass_A"), value: "A" },
		{ label: t("util.pdtclass_P"), value: "P" },
	];

	// 利用ref 子傳父
	const Pack_ref = useRef(null);
	const IdkW_ref = useRef(null);
	const Basic_ref = useRef(null);

	const [s_searchData, set_s_searchData] = useState({
		// PN: null,
		// pdtNM: null,
		// attribute: null,
		// pdtclass: null,
		// querycat: null,
		// mgmtcat: null,
		// pdtspec: null,
		// dealPN: null,
		pdtNM: "", //檢驗項目
		mtype: "", //引用標準
		pdtspec: "", //測試方法
		page: 1,
		pageSize: 100,
	});
	const [s_tabKey, set_s_tabKey] = useState("basic"); // 目前是在哪個Tab上

	const [s_mgmtcat75, set_s_mgmtcat75] = useState([]);
	const [s_mgmtcat74, set_s_mgmtcat74] = useState([]); //管理分類
	const [s_mgmtcat71, set_s_mgmtcat71] = useState([]);

	const call_getMgmtcat75 = useAPI(getMgmtcat);
	const call_getMgmtcat74 = useAPI(getMgmtcat);
	const call_getMgmtcat71 = useAPI(getMgmtcat);

	const resetSearchData = () => {
		set_s_searchData({
			pdtNM: undefined,
			pdtspec: undefined,
			mtype: undefined,
			page: 1,
			pageSize: 100,
		});
	};

	const handleKeyDownEnter = (e) => {
		if (e.key === "Enter") {
			s_tabKey === "basic" && Basic_ref.current.onSearch();
		}
	};

	const handlChange = (type, value) => {
		// 每次變更都重第一頁開始
		// console.log(type, value);
		set_s_searchData((prev) => {
			prev[type] = value;
			return { ...prev, page: 1, pageSize: 100 };
		});
	};

	const onSearch = () => {
		s_tabKey === "basic" && Basic_ref.current.onSearch();
	};
	const onCreate = () => {
		s_tabKey === "basic" && Basic_ref.current.onCreate();
	};

	const onDealSearch = () => {
		Basic_ref.current.onDealSearch();
	};

	const tabsData = [
		{
			key: "basic",
			label: t("ADM.pn-manage.basicProduct"),
			children: (
				<Basic
					ref={Basic_ref}
					s_tabKey={s_tabKey}
					arrMap={{
						attributeMap: attributeMap,
						pdtclassMap: pdtclassMap,
						mgmtcat71: s_mgmtcat71,
						mgmtcat74: s_mgmtcat74,
						mgmtcat75: s_mgmtcat75,
					}}
					s_searchData={s_searchData}
					set_s_searchData={set_s_searchData}
				/>
			),
		},
		// {
		//   key: "dealSearch",
		//   label: t("ADM.pn-manage.dealPNSearch"),
		//   // label: "交易料號查詢",
		//   children: (
		//     <Basic
		//       ref={Basic_ref}
		//       s_tabKey={s_tabKey}
		//       arrMap={{
		//         attributeMap: attributeMap,
		//         pdtclassMap: pdtclassMap,
		//         mgmtcat71: s_mgmtcat71,
		//         mgmtcat74: s_mgmtcat74,
		//         mgmtcat75: s_mgmtcat75,
		//       }}
		//       s_searchData={s_searchData}
		//       set_s_searchData={set_s_searchData}
		//     />
		//   ),
		// },
	];

	const searchBasic = [
		,// <Input
		//   key="s_searchData.PN"
		//   value={s_searchData.PN}
		//   onChange={(e) => handlChange("PN", e.target.value)}
		//   placeholder={`${t("util.placeholder_input")}${t("util.PN")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Input
		//   key="s_searchData.pdtNM"
		//   value={s_searchData.pdtNM}
		//   onChange={(e) => handlChange("pdtNM", e.target.value)}
		//   placeholder={`${t("util.placeholder_input")}${t("util.pdtNM")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Input
		//   key="s_searchData.pdtspec"
		//   value={s_searchData.pdtspec}
		//   onChange={(e) => handlChange("pdtspec", e.target.value)}
		//   placeholder={`${t("util.placeholder_input")}${t("util.pdtspec")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Select
		//   key="s_searchData.attribute"
		//   className="w-[180px]"
		//   options={attributeMap}
		//   value={s_searchData.attribute}
		//   onChange={(e) => handlChange("attribute", e)}
		//   placeholder={`${t("util.placeholder_select")}${t("util.attribute")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Select
		//   key="s_searchData.pdtclass"
		//   className="w-[180px]"
		//   options={pdtclassMap}
		//   value={s_searchData.pdtclass}
		//   onChange={(e) => handlChange("pdtclass", e)}
		//   placeholder={`${t("util.placeholder_select")}${t("util.pdtclass")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Select
		//   key="s_searchData.querycat"
		//   className="w-[180px]"
		//   options={s_mgmtcat74?.reduce((accumulator, option) => {
		//     accumulator.push({
		//       label: option.itemNM,
		//       value: option.mgmtitem,
		//     });
		//     return accumulator;
		//   }, [])}
		//   value={s_searchData.querycat}
		//   onChange={(e) => handlChange("querycat", e)}
		//   placeholder={`${t("util.placeholder_select")}${t("util.querycat")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// <Select
		//   key="s_searchData.mgmtcat"
		//   className="w-[180px]"
		//   options={s_mgmtcat71?.reduce((accumulator, option) => {
		//     accumulator.push({
		//       label: option.itemNM,
		//       value: option.mgmtitem,
		//     });
		//     return accumulator;
		//   }, [])}
		//   value={s_searchData.mgmtcat}
		//   onChange={(e) => handlChange("mgmtcat", e)}
		//   placeholder={`${t("util.placeholder_select")}${t("util.mgmtcat")}`}
		//   onKeyDown={handleKeyDownEnter}
		//   allowClear
		// />,
		// // <Button type="primary" key="search" onClick={onSearch}>
		// //   {t("util.search")}
		// // </Button>,
		// // <Button type="primary" key="create" onClick={onCreate}>
		// //   {t("util.add")}
		// // </Button>,
		// <SearchButton type="primary" key="search" onClick={onSearch} />,
	];

	const searchDeal = [
		// 交易料號查詢
		<Input.Search
			value={s_searchData.dealPN}
			placeholder={t("util.search")}
			enterButton
			onChange={(e) => handlChange("dealPN", e.target.value)}
			onSearch={onDealSearch}
		/>,
	];

	// 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
	const handle_SearchData_Change = (attr, value) => {
		let tmp = {};
		tmp = { ...s_searchData, [attr]: value };
		set_s_searchData(tmp);
	};

	// 搜尋資料
	const handleClick = () => {
		// set_c_lightData({});
		// const tmp = {
		// ...s_searchData,
		// opcode: s_searchData.opcode?.join("|"), //要將資料陣列轉成字串，用直槓隔開
		// custID: s_searchData.custID?.join("|"),
		// lifeF: s_searchData.lifeF?.join("|"),
		// driID: s_searchData.driID?.join("|"),
		// };
		// call_getOrdHD.request(s_searchData);
	};

	useEffect(() => {
		call_getMgmtcat71.request({ pageType: "71", type: "pdt" });
		call_getMgmtcat74.request({ pageType: "74" });
		call_getMgmtcat75.request({ pageType: "75" });
	}, []);

	useEffect(() => {
		if (call_getMgmtcat75.status === "suc") {
			const renderData = call_getMgmtcat75.data?.map((item) => ({
				label: item.itemNM,
				value: item.mgmtitem,
			}));
			// console.log("call_getMgmtcat74.data = ", call_getMgmtcat74.data);
			set_s_mgmtcat75(call_getMgmtcat75.data);
		} else if (call_getMgmtcat74.status === "err") {
			message.error(t(`errorCode.${call_getMgmtcat75.msg}`));
			set_s_mgmtcat75([]);
		}
	}, [call_getMgmtcat75.status]);

	useEffect(() => {
		if (call_getMgmtcat74.status === "suc") {
			const renderData = call_getMgmtcat74.data?.map((item) => ({
				label: item.itemNM,
				value: item.mgmtitem,
			}));
			// console.log("call_getMgmtcat74.data = ", call_getMgmtcat74.data);
			set_s_mgmtcat74(call_getMgmtcat74.data);
		} else if (call_getMgmtcat74.status === "err") {
			message.error(t(`errorCode.${call_getMgmtcat74.msg}`));
			set_s_mgmtcat74([]);
		}
	}, [call_getMgmtcat74.status]);

	useEffect(() => {
		if (call_getMgmtcat71.status === "suc") {
			set_s_mgmtcat71(call_getMgmtcat71.data);
		}
		if (call_getMgmtcat71.status === "err") {
			message.error(t(`errorCode.${call_getMgmtcat71.msg}`));
			set_s_mgmtcat71([]);
		}
	}, [call_getMgmtcat71.status]);

	return (
		<>
			<PageHeader
				title={t("ADM.pn-manage.pageHeader")}
			// extra={s_tabKey === "basic" ? searchBasic : s_tabKey === "dealSearch" && searchDeal}
			/>
			{/* <Space className="w-full flex justify-end">
        {s_tabKey === "basic" ? searchBasic : s_tabKey === "dealSearch" && searchDeal}
      </Space> */}
			<Space className="flex justify-end gap-2 mb-2">
				{/*检验项目*/}
				<Input
					className="max-w-[150px]"
					placeholder="请输入检验项目"
					name="pdtNM"
					onChange={(e) => handle_SearchData_Change("pdtNM", e.target.value)}
					value={s_searchData.pdtNM}
					allowClear
				/>

				{/*客戶選擇*/}
				{/* <Select
          className="min-w-[300px]"
          name="mtype"
          showSearch
          placeholder="请选择引用标准"
          onChange={(value) => handle_SearchData_Change("mtype", value)}
          options={s_mgmtcat75.map((item) => {
            return { value: item.mgmtitem, label: item.mgmtitem + "-" + item.itemNM }
          })}
          allowClear
        /> */}

				{/*客戶單號*/}
				<Input
					className="min-w-[250px]"
					placeholder="请输入检测标准/测试方法"
					name="pdtspec"
					onChange={(e) => handle_SearchData_Change("pdtspec", e.target.value)}
					value={s_searchData.dealN}
					allowClear
				/>

				<SearchButton type="primary" key="search" onClick={onSearch} />
				<AddButton type="primary" key="create" onClick={onCreate} />
			</Space>
			{/* <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      /> */}
			<Divider className="my-2" />
			<Basic
				ref={Basic_ref}
				s_tabKey={s_tabKey}
				arrMap={{
					attributeMap: attributeMap,
					pdtclassMap: pdtclassMap,
					mgmtcat71: s_mgmtcat71,
					mgmtcat74: s_mgmtcat74,
					mgmtcat75: s_mgmtcat75,
				}}
				s_searchData={s_searchData}
				set_s_searchData={set_s_searchData}
			/>
		</>
	);
}
