import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
import { getBom, deleteBom } from "@/service/apis/ADM/bom";
import useAPI from "@/hooks/useAPI";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer";

const Bom = forwardRef((props, ref) => {
  const { s_searchData, set_s_searchData, s_arrMap } = props;
  const { t } = useTranslation();
  const [c_lightData, set_c_lightData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getBom = useAPI(getBom);
  const call_deleteBom = useAPI(deleteBom);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: () => onSearch(),
    onCreate: () => {
      set_s_isShowModal(true);
      set_c_lightData({});
    },
  }));

  const onSearch = () =>
    set_s_tableData(
      filterTableData(
        call_getBom.data?.map((x, i) => ({
          ...x,
          key: i,
        })) || []
      )
    );

  const tableColumns = [
    {
      title: t("util.PN"),
      dataIndex: "cPN",
      width: "33%",
      render: (text, rowData) => `${text} 【${rowData.cpdtNM}】* ${rowData.qty}`,
    },
    {
      title: t("util.ver"),
      dataIndex: "ver",
      align: "center",
      width: "4%",
      render: (text, rowData) => rowData.level === 0 && text,
    },
    {
      title: t("util.qty"),
      dataIndex: "qty",
      align: "center",
      width: "9%",
    },
    {
      title: t("util.attribute"),
      dataIndex: "attribute",
      align: "center",
      render: (text) => s_arrMap.attributeArr?.filter((x) => x.value === text)[0].label,
    },
    {
      title: t("util.unitNM"),
      dataIndex: "punitNM",
      align: "center",
      width: "11%",
    },
    {
      title: t("ADM.edm.level"),
      dataIndex: "level",
      align: "center",
      width: "6%",
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      align: "left",
    },
    {
      title: t("util.updtT"),
      dataIndex: "updtTstr",
      align: "center",
      render: (text, rowData) => rowData.level === 0 && text,
    },
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "7.5%",
      render: (_, rowData) =>
        rowData.level === 0 && (
          <Space>
            <IconPen
              onClick={(e) => {
                set_c_lightData(rowData);
                set_s_isShowModal(true);
                e.stopPropagation();
              }}
            />
            <IconGarbage
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          </Space>
        ),
    },
  ];

  const filterTableData = (data) => {
    const copyData = JSON.parse(JSON.stringify(data));
    let searchArr = copyData.filter((x) => {
      let is_pdtclass = true,
        is_mgmtcat = true,
        is_pdtNM = true,
        is_PN = true,
        is_attribute = true;
      if (s_searchData.attributeArr && s_searchData.attributeArr !== "") {
        is_attribute = s_searchData.attributeArr.join(",").includes(x.attribute);
      }
      if (s_searchData.pdtclass && s_searchData.pdtclass !== "") {
        is_pdtclass = x.pdtclass.includes(s_searchData.pdtclass);
      }
      if (s_searchData.mgmtcat && s_searchData.mgmtcat !== "") {
        is_mgmtcat = x.mgmtcat.includes(s_searchData.mgmtcat);
      }
      if (s_searchData.pdtNM && s_searchData.pdtNM !== "") {
        is_pdtNM = x.ppdtNM.includes(s_searchData.pdtNM);
      }
      if (s_searchData.PN && s_searchData.PN !== "") {
        is_PN = x.cPN.includes(s_searchData.PN);
      }
      return is_pdtclass && is_pdtNM && is_PN && is_mgmtcat && is_attribute;
    });
    return searchArr;
  };

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteBom.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getBom.status === "suc") {
      message.success(call_getBom.msg);
      set_s_tableData(
        call_getBom.data.map((x, i) => ({
          ...x,
          key: i,
        }))
      );
    } else if (call_getBom.status === "err") {
      message.error(t(`errorCode.${call_getBom.msg}`));
      set_s_tableData([]);
    }
  }, [call_getBom.status]);

  useEffect(() => {
    if (call_deleteBom.status === "suc") {
      message.success(call_deleteBom.msg);
    } else if (call_deleteBom.status === "err") {
      message.error(t(`errorCode.${call_deleteBom.msg}`));
    }
    // delete之後 重call表格資料
    call_getBom.request(s_searchData);
  }, [call_deleteBom.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getBom.status === "load"}
        c_lightData={c_lightData}
        indentSize={50}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.edit")}${t("ADM.edm.bom")}`
            : `${t("util.add")}${t("ADM.edm.bom")}`
        }
        width={"90%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getBom.request(s_searchData);
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          s_arrMap={s_arrMap}
        />
      </CustomModal>
    </>
  );
});

export default Bom;
