const INIT_DISABLED = {
  opcode: true,
  dealN: true,
  currencytype: true,
  exchangerate: true,
  exchangeD: true,
  taxtype: true,
  taxrate: true,
  takeReq: true, //引用請購單
  throwInstock: true, //拋轉進料單
  suppID: true,
  payment: true, // 付款方式
  trade: true, // 貿易條件
  reqSearch: true, //請購紀錄查詢
  instockSearch: true, //進料紀錄查詢
  dealaddr: true, //廠商地址
  driID: true, //負責採購
  proT: true,
  planday: true,
  EDT: true,
  startEDT: true, //預計交貨日期(起)
  endEDT: true, //預計交貨日期(迄)
  suppTradeSearch: true, //廠商交易查詢
  dealcontact: true, //廠商業務窗口(窗口)
  dealtel: true, //廠商業務窗口(電話)
  proCheck: true, //採購覆核
  suppCheck: true, //廠商覆核
  contact: true, //廠商交貨窗口(窗口)
  tel: true, //廠商交貨窗口(電話)
  note: true, //採購註記
  completed: true,
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 廠商確認數量
  external: true,
  PN: true,
  tb_delete: true,
  tb_note: true,
  unitprice: true,
  tb_planday: true,
  tb_EDT: true,
  // ----- 條款註記 -----
  adda_statement: true,
  // ----- 加扣款項 -----
  addb_statement: true,
  addb_amount: true,
  // ----- 預付作業 -----
  addc_statement: true,
  addc_amount: true,
};

export default INIT_DISABLED;
