import React, { useRef, useState } from "react";

import HD from "./HD";
import TB from "./TB";

const RunA3 = () => {
  const hdRef = useRef();
  const [c_lightData, set_c_lightData] = useState({});
  return (
    <div className="flex-1 flex flex-col">
      <HD ref={hdRef} c_lightData={c_lightData} set_c_lightData={set_c_lightData} />
      {Object.keys(c_lightData).length > 0 && (
        <TB hdRef={hdRef.current} c_lightData={c_lightData} />
      )}
    </div>
  );
};

export default RunA3;
