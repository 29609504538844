import { forEach } from "lodash";
// 翻譯template
import zh_TW from "@/Language/locales/zh-TW";

export const trans = (sys, _data) => {
  // 創建一個新物件來存放結果，避免修改原始 template 物件
  const filledTemplate = JSON.parse(JSON.stringify(zh_TW[sys]));
  console.log("filledTemplate = ", filledTemplate);
  // 遍歷 data 物件的 key 和 value
  Object.entries(_data).forEach(([key, subData]) => {
    console.log("key = ", key);
    // 檢查 filledTemplate 是否具有相同的 key
    if (filledTemplate.hasOwnProperty(key)) {
      // 遍歷子物件的 key 和 value
      Object.entries(subData).forEach(([subKey, value]) => {
        // 檢查 filledTemplate[key] 是否具有相同的子 key，如果是，則將值填充到 filledTemplate[key][subKey] 中
        if (filledTemplate[key].hasOwnProperty(subKey)) {
          filledTemplate[key][subKey] = value;
        }
      });
    }
  });
  console.log("filledTemplate = ", filledTemplate);
  return filledTemplate;
};

const flattenObject = (obj) => {
  const result = {};

  // 使用 const 宣告內部函式並使用箭頭函式
  const flatten = (obj) => {
    // 使用 Object.entries 與解構賦值 (destructuring assignment) 來迭代物件
    Object.entries(obj).forEach(([key, value]) => {
      // 使用 typeof 檢查值是否為物件並排除陣列，若是物件則遞迴處理
      if (typeof value === "object" && !Array.isArray(value)) {
        flatten(value, key);
      } else {
        // 將處理結果存入 result 物件
        result[key] = value;
      }
    });
  };

  // 開始執行物件扁平化的遞迴處理
  flatten(obj);

  // 回傳扁平化後的物件
  return result;
};
