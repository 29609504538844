import { oms_apiBasic as apiBasic } from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const addRunA3HD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRunA3HD`, data);
};

export const getRunA3HD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunA3HD`, {
    params: data,
  });
};

export const updateRunA3HD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRunA3HD`, data);
};
export const deleteRunA3HD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteRunA3HD`, data);
};

export const addRunA3TB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRunA3TB`, data);
};

export const getRunA3TB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRunA3TB`, {
    params: data,
  });
};

export const updateRunA3TB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRunA3TB`, data);
};
export const deleteRunA3TB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteRunA3TB`, data);
};
