import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

//期間訂單彙總表
export const orderSummary = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/orderSummary`, {
    params: data,
  });
};

//訂單明細表
export const orderDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/orderDetail`, {
    params: data,
  });
};

//銷貨彙總表
export const saleSummary = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/saleSummary`, {
    params: data,
  });
};

//銷貨明細表
export const saleDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/saleDetail`, {
    params: data,
  });
};

//匯出訂單明細表(後端)excel
export const exportOrderDetail = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportOrderDetail`, data);
};

//匯出銷貨彙總表(後端)excel
export const exportSaleSummary = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportSaleSummary`, data);
};

//匯出期間訂單彙總表(後端)excel
export const exportOrderSummary = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/exportOrderSummary`, data);
};
