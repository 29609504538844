import React from "react";
import { useState, useEffect } from "react";
import { message, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// API
import useAPI from "@/hooks/useAPI";
import { getOrdADD, getPostage } from "@/service/apis/OMS/Ord";
// util
import { formatAmount } from "@/util/format";
const { Title } = Typography;
const Expend = ({ s_expendDrawer, set_s_expendDrawer }) => {
  const { t } = useTranslation();
  const call_getOrdADD = useAPI(getOrdADD);
  const call_getPostage = useAPI(getPostage);
  // table 顯示的資料
  // const [s_addcTableData, set_s_tableData] = useState([]);
  const [s_addc, set_s_addc] = useState(0);
  const [s_addcTableData, set_s_addcTableData] = useState([]);

  const [s_postage, set_s_postage] = useState(0);
  const [s_postageTableData, set_s_postageTableData] = useState([]);

  useEffect(() => {
    call_getOrdADD.request({
      "addtype": "addc",
      "opUUID": s_expendDrawer.data.opUUID,
    });
    call_getPostage.request({
      "opUUID": s_expendDrawer.data.opUUID,
    });
  }, [s_expendDrawer.show]);

  // useAPI 取得郵寄費用
  useEffect(() => {
    const { data, msg, status } = call_getPostage;
    if (status === "suc") {
      message.success("取得郵寄費用成功");
      const tmpData = data.map((item, index) => ({ ...item, key: index }));
      let amount = 0;
      console.log("tmpData = ", tmpData);
      tmpData.forEach((item) => (amount = amount + item.amount));
      set_s_postage(amount);
      set_s_postageTableData(tmpData);
    }

    if (status === "err") {
      set_s_postageTableData([]);
      message.error("取得郵寄費用失敗");
    }
  }, [call_getPostage.status]);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { status, msg, data } = call_getOrdADD;
    if (status === "suc") {
      const tmpData = data.addc?.map((item, index) => ({ ...item, key: index })) || [];
      let addc = 0;
      tmpData?.forEach((item) => (addc = addc + item.amount));
      set_s_addc(addc);
      set_s_addcTableData(tmpData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdADD.status]);

  const addcColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "8%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.target"), // 對象
      dataIndex: "target",
      align: "center",
      width: "20%",
    },
    {
      title: t("OMS.ord.statement"), // 付款说明
      dataIndex: "statement",
      align: "center",
    },
    {
      title: t("util.note"), // 註記
      dataIndex: "note",
      align: "center",
      width: "20%",
    },
    {
      title: t("OMS.ord.amount"), // 金額
      dataIndex: "amount",
      align: "center",
      width: "10%",
      className: "bg-[#fadede] text-[#EF4F4F] font-extrabold",
      render: (text) => formatAmount(text),
    },
  ];
  const postageColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "項目",
      dataIndex: "type",
      width: "10%",
      align: "center",
      render: (text) => t(`OMS.ord.type_${text}`),
    },
    {
      title: t("OMS.ord.statement"),
      dataIndex: "statement",
      // width: "15%",
      align: "center",
      render: (text, rowData, _) => {
        if (rowData.type === "0") {
          return <span className="text-[#6989ba]">外测单号: {text}</span>;
        }

        return text;
      },
    },
    {
      title: t("util.note"),
      dataIndex: "note",
      width: "15%",
      align: "center",
    },
    {
      title: t("OMS.ord.amount"), // 金額
      dataIndex: "amount",
      align: "right",
      width: "10%",
      className: "bg-[#fadede] text-[#EF4F4F] font-extrabold",
      render: (text) => formatAmount(text),
    },
  ];
  return (
    <div>
      <CustomTable rowNumber={10} columns={addcColumns} dataSource={s_addcTableData} />
      <div className="flex justify-end mt-3 mb-6">
        <Title className="m-0" level={3}>
          服務費總計:
          <span className="ml-2 text-[#EF4F4F]">{formatAmount(s_addc)}</span>
        </Title>
      </div>

      <CustomTable rowNumber={10} columns={postageColumns} dataSource={s_postageTableData} />
      <div className="flex justify-end mt-3">
        <Title className="m-0" level={3}>
          其他費用總計:
          <span className="ml-2 text-[#EF4F4F]">{formatAmount(s_postage)}</span>
        </Title>
      </div>
    </div>
  );
};

export default Expend;
