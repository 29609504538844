import { useState, useEffect, forwardRef, useImperativeHandle, useContext, useMemo } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconPen, IconGarbage } from "@/components/Icon/Action";
import { useTranslation } from "react-i18next";
import { getNotify, deleteNotify, getFunc } from "@/service/apis/ADM/systemParamsManage";
import useAPI from "@/hooks/useAPI";
import SystemContext from "@/components/Context/SystemContext";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import ModalConintaer from "./ModalContainer/index";
import { multipleIDtoName } from "@/util/format";

const Notify = forwardRef((props, ref) => {
  const { s_searchData } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const { c_systemConfig } = useContext(SystemContext);

  const { t } = useTranslation();

  const [s_arrMap, set_s_arrMap] = useState({
    rptcodeMap: [
      // { value: "A0", label: "人員提報" },
      // { value: "B0", label: "系統提報(WOS)" },
      // { value: "C0", label: "設備提報" },
      // { value: "D0", label: "管理提報_遠端" },
      // { value: "E0", label: "管理提報_雲端" },
      { value: "A0", label: t("ADM.function-params-manage.rptcode_A0") },
      { value: "B0", label:t("ADM.function-params-manage.rptcode_B0") },
      { value: "C0", label: t("ADM.function-params-manage.rptcode_C0") },
      { value: "D0", label: t("ADM.function-params-manage.rptcode_D0") },
      { value: "E0", label: t("ADM.function-params-manage.rptcode_E0") },
    ],
    pusherMap: [
      { value: "WOS", label: "WOS" },
      { value: "PMS", label: "PMS" },
      { value: "OMS", label: "OMS" },
      { value: "WMS", label: "WMS" },
      { value: "PDS", label: "PDS" },
    ],
    situFMap: [
      { label: "黃", value: "Y" },
      { label: "紅", value: "R" },
    ],
    startlvlMap: [
      { label: "全員", value: 0 },
      { label: "班組幹部", value: 1 },
      { label: "課級幹部", value: 2 },
      { label: "理級主管", value: 3 },
      { label: "總級主管", value: 4 },
    ],
    funcodeMap: [],
  });

  const call_getNotify = useAPI(getNotify);
  const call_deleteNotify = useAPI(deleteNotify);
  const call_getFunc = useAPI(getFunc);

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getNotify.request(s_searchData);

  const tableColumns = [
    {
      title: t("ADM.function-params-manage.eventID"), //"事件ID",
      dataIndex: "eventID",
      width: "8%",
    },
    {
      title: t("ADM.function-params-manage.eventNM"), //"事件名稱",
      dataIndex: "eventNM",
    },
    {
      title: t("ADM.function-params-manage.rptcode"), //"提報類別",
      dataIndex: "rptcode",
      align: "center",
      width: "5%",
      // render: (text) => s_arrMap.rptcodeMap.filter((x) => x.value === text)[0].label,
      render: (text) => t(`ADM.function-params-manage.rptcode_${text}`),
    },
    {
      title: t("ADM.function-params-manage.pusher"), //"發報系統",
      dataIndex: "pusher",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.function-params-manage.situF"), //"警訊燈號",
      dataIndex: "situF",
      align: "center",
      width: "5%",
      // render: (text) => s_arrMap.situFMap.filter((x) => x.value === text)[0].label,
      render: (text) => t(`ADM.function-params-manage.situF_${text}`),
    },
    {
      title: t("ADM.function-params-manage.audience"), //"推播受群",
      dataIndex: "funcode",
      render: (text) => multipleIDtoName(s_arrMap.funcodeMap, text, "value", "label"),
    },
    {
      title: t("ADM.function-params-manage.startlvl"), //"起報層級",
      dataIndex: "startlvl",
      align: "center",
      width: "5%",
      // render: (text) => s_arrMap.startlvlMap.filter((x) => x.value === text)[0].label,
      render: (text) => t(`ADM.function-params-manage.startlvl_${text}`),
    },
    {
      title: t("ADM.function-params-manage.setlvl"), //"推播級數",
      dataIndex: "setlvl",
      align: "center",
      width: "5%",
    },
    {
      title: t("ADM.function-params-manage.cruisebot"), //"監控程式",
      dataIndex: "cruisebot",
    },
    {
      title: t("ADM.function-params-manage.msgmodel"), //"推播訊息模版",
      dataIndex: "msgmodel",
      render: (text) => (
        <div
          dangerouslySetInnerHTML={{
            __html: text.replace(/(\n|\r|\r\n)/g, "<br>"),
          }}
        />
      ),
    },
    {
      title: t("util.action"), //"操作",
      dataIndex: "action",
      align: "center",
      width: "80px",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteNotify.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    call_getFunc.request();
  }, []);

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getNotify.status === "suc") {
      message.success(call_getNotify.msg);
      set_s_tableData(
        call_getNotify.data.map((x, i) => {
          // 整理資料
          const funcode = x.funcode.split("|").filter((x) => x !== "");
          return {
            ...x,
            key: i,
            funcode: funcode,
          };
        })
      );
    } else if (call_getNotify.status === "err") {
      message.error(t(`errorCode.${call_getNotify.msg}`));
      set_s_tableData([]);
    }
  }, [call_getNotify.status]);

  useEffect(() => {
    if (call_deleteNotify.status === "suc") {
      message.success(call_deleteNotify.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteNotify.status === "err") {
      message.error(t(`errorCode.${call_deleteNotify.msg}`));
    }
  }, [call_deleteNotify.status]);

  useEffect(() => {
    if (call_getFunc.status === "suc") {
      set_s_arrMap((prev) => {
        prev.funcodeMap = call_getFunc.data.map((x) => ({
          label: x.funNM,
          value: x.funcode,
        }));
        return { ...prev };
      });
    } else if (call_getFunc.status === "err") {
      message.error(t(`errorCode.${call_getFunc.msg}`));
    }
  }, [call_getFunc.status]);

  useEffect(() => {
    console.log("c_systemConfig.locale === ", c_systemConfig.locale);
    call_getFunc.request();
  }, [c_systemConfig.locale]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getNotify.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? t("util.edit") + t("ADM.function-params-manage.pushEvent")
            : t("util.add") + t("ADM.function-params-manage.pushEvent")
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          s_arrMap={s_arrMap}
        />
      </CustomModal>
    </>
  );
});

export default Notify;
