/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Spin,
  message,
  InputNumber,
  DatePicker,
  Divider,
} from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { getOrdHD } from "@/service/apis/OMS/Ord";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../../index";

const { Text } = Typography;

const DetailModal = ({ s_detailSearch, set_s_detailSearch, call_getOrdHDAD }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // useAPI 取得全部銷貨單表頭資料
  const call_getOrdHD = useAPI(getOrdHD);
  // 幣別資料
  const { c_currencys, c_custs, c_taxtypes } = useContext(ERPContext);
  // 全部銷貨單單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      currencytype: values.currencytype?.join("|") ?? "",
      taxtype: values.taxtype?.join("|") ?? "",
      custID: values.custID?.join("|") ?? "",
      lifeF: values.lifeF?.join("|") ?? "",
      total: `${values.totalStart}-${values.totalEnd}`,
      startDate: values.createT ? dayjs(values.createT[0]).format("YYYY-MM-DD") : null,
      endDate: values.createT ? dayjs(values.createT[1]).format("YYYY-MM-DD") : null,
      startEDT: values.EDT ? dayjs(values.EDT[0]).format("YYYY-MM-DD") : null,
      endEDT: values.EDT ? dayjs(values.EDT[1]).format("YYYY-MM-DD") : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getOrdHDAD.request(new_data);
    // 更新資料並關閉跳顯
    set_s_detailSearch({ show: false, data: values });
  };

  // 第一次渲染取得全部銷貨單做下拉選單、賦值初始值
  useEffect(() => {
    call_getOrdHD.request();
    const { data } = s_detailSearch;
    // console.log("data = ", data);
    if (Object.keys(data).length > 0) {
      form.setFieldsValue({
        ...data,
        createT: data.createT ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])] : null,
        EDT: data.EDT ? [dayjs(data.EDT?.[0]), dayjs(data.EDT?.[1])] : null,
      });
    }
  }, []);

  // useAPI 取得全部銷貨單資料
  useEffect(() => {
    const { data, msg, status } = call_getOrdHD;

    if (status === "suc") {
      const allNs = data?.map((proItem) => ({ lable: proItem.N, value: proItem.N })) ?? [];
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getOrdHD.status]);
  console.log(123);
  return (
    <Spin size="large" spinning={call_getOrdHD.status === "load"}>
      <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
        <Row gutter={[12, 0]}>
          {/* 單別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.opcode")}</Text>} name="opcode">
              <Select
                mode="multiple"
                allowClear
                placeholder="請選擇單別"
                options={[
                  { value: "1310", label: t("OMS.util.opcode_1310") }, // 國內訂單
                  { value: "1320", label: t("OMS.util.opcode_1320") }, // 國外訂單
                ]}
              />
            </Form.Item>
          </Col>
          {/* 單號 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.N")}</Text>} name="N">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("util.placeholder_input") + t("OMS.util.N")}
                options={s_allNs}
              />
            </Form.Item>
          </Col>
          {/* 幣別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.currencytype")}</Text>} name="currencytype">
              <Select
                mode="multiple"
                placeholder={t("util.placeholder_select") + t("OMS.util.currencytype")}
                allowClear
                options={c_currencys.options}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 稅別 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.taxtype")}</Text>} name="taxtype">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("util.placeholder_select") + t("OMS.util.taxtype")}
                options={c_taxtypes.options}
              />
            </Form.Item>
          </Col>
          {/* 客戶ID */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.custID")}</Text>} name="custID">
              <Select
                showSearch
                mode="multiple"
                placeholder={t("util.placeholder_select") + t("OMS.util.custID")}
                allowClear
                options={c_custs.options}
              />
            </Form.Item>
          </Col>
          {/* 歷程 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.lifeF")}</Text>} name="lifeF">
              <Select
                mode="multiple"
                allowClear
                placeholder={t("util.placeholder_select") + t("OMS.util.lifeF")}
                options={[
                  { value: "0", label: t("OMS.ord.lifeF_0") }, // 發佈
                  { value: "2", label: t("OMS.ord.lifeF_2") }, // 請購覆核
                  { value: "7", label: t("OMS.ord.lifeF_7") }, // 審核送簽完成
                  { value: "T", label: t("OMS.ord.lifeF_T") }, // 結案
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          {/* 創建日期 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.createT")}</Text>} name="createT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
          {/* 預計交貨日 */}
          <Col span={8}>
            <Form.Item label={<Text>{t("OMS.util.EDT")}</Text>} name="EDT">
              <DatePicker.RangePicker className="w-full" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={[12, 0]}>
          <Col span={24} className="flex justify-end gap-1">
            <CancelButton
              onClick={() => set_s_detailSearch((prev) => ({ ...prev, show: false }))}
            />

            <ConfirmButton type="primary" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default DetailModal;
