import React, { useState } from 'react'
import HD from "./HD";
import Total from './Total/index';


export const DataContext = React.createContext();

const Performance = () => {

  const [c_lightData, set_c_lightData,] = useState({});
  const [s_HD_Data, set_HD_Data] = useState([]);   //表頭資料
  const [s_total, set_s_total] = useState({ itemtotal: 0 });  //累计金额

  return (
    <DataContext.Provider
      value={{
        c_lightData, set_c_lightData,
        set_HD_Data, s_HD_Data,
        s_total, set_s_total
      }}>
      <div className="flex flex-col h-full">
        <div className="flex-1 flex flex-col">
          <HD />
          <Total />
        </div>
      </div>
    </DataContext.Provider>
  )
}

export default Performance
