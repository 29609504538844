import { instance } from "../../handlerAPI";
import { bpm_apiBasic as apiBasic } from "../../APIBasic";

//-----------------------------------------取得簽核權限設定----------------------------------------
export const getBPM = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getBPM`, {
    params: data,
  });
};

//-----------------------------------------取得完成簽核文件----------------------------------------
export const getHistory = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getHistory`, {
    params: data,
  });
};

export const create = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/create`, data);
};

//-----------------------------------------取得簽核權限設定----------------------------------------
export const getCountmonth = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/countmonth`, {
    data,

  });
};
