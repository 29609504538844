import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, Select, message, Space, Radio } from "antd";
import { addPush, modifyPush } from "@/service/apis/ADM/systemParamsManage";
import { useTranslation } from "react-i18next";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const [form] = Form.useForm();

  const call_addPush = useAPI(addPush);
  const call_modifyPush = useAPI(modifyPush);
  const { t } = useTranslation();
  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addPush.request(values);
    }
    if (type === "edit") {
      call_modifyPush.request(values);
    }
  };

  useEffect(() => {
    if (call_addPush.status === "suc") {
      message.success(call_addPush.msg);
      set_s_isShowModal(false);
    } else if (call_addPush.status === "err") {
      message.error(t(`errorCode.${call_addPush.msg}`));
    }
  }, [call_addPush.status]);

  useEffect(() => {
    if (call_modifyPush.status === "suc") {
      message.success(call_modifyPush.msg);
      set_s_isShowModal(false);
    } else if (call_modifyPush.status === "err") {
      message.error(t(`errorCode.${call_modifyPush.msg}`));
    }
  }, [call_modifyPush.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        {/* 層級碼 */}
        {/* 選擇推播層級 */}
        {/* 1(班組)  ,2(課級) , 3(理級) , 4(總級) */}
        <Col span={12}>
          <Form.Item
            label={t("ADM.function-params-manage.lvlcode")}
            name="lvlcode"
            rules={[{ required: true }]}
          >
            <Select
              disabled={Object.keys(s_editData).length > 0}
              placeholder={t("util.placeholder_select") + t("ADM.function-params-manage.pushlayer")}
              allowClear
              options={[
                { label: t("ADM.function-params-manage.team"), value: 1 },
                { label: t("ADM.function-params-manage.class"), value: 2 },
                { label: t("ADM.function-params-manage.management"), value: 3 },
                { label: t("ADM.function-params-manage.total"), value: 4 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {/* 層級名稱 */}
          <Form.Item
            label={t("ADM.function-params-manage.lvlNM")}
            name="lvlNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
