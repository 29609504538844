/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { Row, Col, Space, Divider, Grid, Typography, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton, SuppCheckButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// context
import { MainPageContext } from "../index";
// util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { deleteOrdTB } from "@/service/apis/OMS/Ord";
// component
import TbModal from "../Modal/TbModal";

const { Title } = Typography;

const TbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const {
    hd_values,
    s_disabled,
    s_ordTb,
    call_getOrdHD,
    call_getOrdTB,
    call_updateOrdHd,
    is_editForm,
    set_is_editForm,
  } = useContext(MainPageContext);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteOrdTB = useAPI(deleteOrdTB);

  // 編輯資料
  const editData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("util.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => t(`OMS_ori.ord.${item}`))?.join(",")}
            </span>
            <span>{t("util.util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateOrdHd.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          set_s_tbModal({ show: false, data: rowData });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: rowData });
  };

  // 刪除資料
  const removeData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("util.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => t(`OMS_ori.ord.${item}`))?.join(",")}
            </span>
            <span>{t("util.util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateOrdHd.request(hd_values);
        },
      });
    } else {
      Modal.confirm({
        title: t("OMS_ori.ord.tb") + t("util.util.del_confirm"), // 銷貨明細刪除確認
        icon: <ExclamationCircleOutlined />,
        content: t("util.util.del_confirm_msg"),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 刪除表身
          call_deleteOrdTB.request(rowData);
        },
      });
    }
  };

  // 更新表頭的API
  // useEffect(() => {
  //   const { status, msg } = call_updateOrdHd;
  //   if (status === "suc") {
  //     message.success(msg);
  //     // 更新完成後打開跳顯讓使用者編輯
  //     set_s_tbModal((prev) => ({
  //       show: true,
  //       data: prev.data,
  //     }));
  //     // 清除編輯記錄
  //     set_is_editForm({ isEdit: false, editFlag: [] });
  //   }
  //   if (status === "err") {
  //     message.error(t(`error.error-code.${msg}`));
  //   }
  // }, [call_updateOrdHd.status]);

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteOrdTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_deleteOrdTB.status]);

  const tableColumns = [
    {
      title: t("util.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("util.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "12%",
    },
    {
      title: t("util.util.dealPN"), // 交易料號
      dataIndex: "dealPN",
      align: "center",
      width: "12%",
    },
    {
      title: t("util.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS_ori.ord.unitprice"), // 未稅單價
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("OMS.util.localprice"), // 本幣單價
    //   dataIndex: "localprice",
    //   align: "right",
    //   width: "7%",
    //   onCell: (_) => {
    //     return {
    //       style: {
    //         backgroundColor: "#ecf2fa",
    //       },
    //     };
    //   },
    //   render: (text) => formatAmount(text),
    // },
    {
      title: t("OMS_ori.ord.demandqty"), // 銷貨數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.punit"), // 採購單位
      dataIndex: "punit",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.itemsum") + t("util.util.excluding_tax"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.confirmqty"), // 客戶確認數量
      dataIndex: "confirmqty",
      align: "right",
      width: "8%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.returnqty"), // 退出數量
      dataIndex: "returnqty",
      align: "right",
      width: "6%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS_ori.ord.cancelqty"), // 取消數量
      dataIndex: "cancelqty",
      align: "right",
      width: "6%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.util.action"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 客戶確認數量 */}
          <SuppCheckButton disabled />
          {/*s_disabled.confirmqty*/}
          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />
          {/* 編輯表身資料 */}
          <EditButton
            // onClick={() => set_s_tbModal({ show: true, data: rowData })}
            onClick={() => editData(rowData)}
          />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data)?.length === 0 ? "create" : "edit";
  // console.log("hd_values.total = ", hd_values.total);
  return (
    <div className="flex flex-1 flex-col justify-between">
      <CustomTable
        columns={tableColumns}
        rowNumber={3}
        dataSource={s_ordTb.tb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#4B6EA2]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("util.util.itemno_total")}: </span>
          <span className="self-center">{s_ordTb.tb?.length ?? 0}</span>
        </Col>

        <Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總加扣款項 */}
          <span className="self-center">{t("OMS_ori.ord.charge")}:</span>
          <span className="self-center">{formatAmount(hd_values.charge)}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("OMS_ori.ord.sumtotal")}:</span>
          <span className="self-center">{formatAmount(hd_values.sumtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("util.util.tax")}: </span>
          <span className="self-center">{formatAmount(hd_values.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 本幣含稅總金額 */}
          <span className="self-center">{t("OMS_ori.ord.local_total")}:</span>
          <span className="self-center">
            {formatAmount(hd_values.total * hd_values.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("OMS_ori.ord.total")}:</span>
          <span className="self-center"> {formatAmount(hd_values.total)}</span>
        </Col>
      </Row>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-center mr-5">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯銷貨明細" : "新增銷貨明細"} */}
              {type === "edit"
                ? t("util.util.edit") + t("OMS.ord.tb")
                : t("util.util.add") + t("OMS.ord.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("util.util.opcode")}：{t(`OMS.util.opcode_${hd_values.opcode}`)}{" "}
                {t("util.util.N")}：{hd_values.N} {t("util.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        {s_tbModal.show && (
          <TbModal
            hd_values={hd_values}
            s_disabled={s_disabled}
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
            call_getOrdHD={call_getOrdHD}
            call_getOrdTB={call_getOrdTB}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default TbContainer;
