import { Button, Col, Divider, Row, message } from "antd";
// util component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// util function
import { formatAmount } from "@/util/format";
import { getPayTB } from "@/service/apis/PMS/pay";
import { takeOut } from "@/service/apis/PMS/ap";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const TakePayModal = ({ hd_values, call_getApHD, call_getApTB, set_is_showTake }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  // useAPI 引用對帳資料
  const call_takeOut = useAPI(takeOut);
  // useAPI 取得對帳資料
  const call_getPayTB = useAPI(getPayTB);

  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 表格中被選中的明細(key, 給antd用的)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // 確定引用
  const onSubmit = () => {
    if (selectedRowKeys.length === 0) {
      // 請選擇要引用的對帳資料
      message.error(t("PMS.ap-manage.message_chose_take_which_ap"));
      return;
    }
    // 要被引用的明細
    const takeList = selectedRowKeys.map((rowkey) => {
      return {
        ...s_tableData[rowkey],
        HdOpUUID: hd_values.opUUID,
      };
    });
    console.log("takeList = ", takeList);
    // return;
    call_takeOut.request(takeList);
    // console.log(13)
  };

  // 第一次渲染取得對帳資料
  useEffect(() => {
    call_getPayTB.request({ suppID: hd_values.suppID });
  }, []);

  // useAPI 取得引用資料
  useEffect(() => {
    const { status, msg, data } = call_getPayTB;
    if (status === "suc") {
      message.success(msg);
      const _data = data?.filter((item) => item.lifeF !== "T") || [];
      set_s_tableData(_data);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getPayTB.status]);

  // useAPI 確定引用
  useEffect(() => {
    const { status, msg } = call_takeOut;
    if (status === "suc") {
      message.success(msg);
      call_getApHD.request({ opUUID: hd_values.opUUID });
      call_getApTB.request({ opUUID: hd_values.opUUID });
      set_is_showTake(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_is_showTake(false);
    }
  }, [call_takeOut.status]);

  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 銷貨單號
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      title: t("PMS.util.N23"), // 銷貨單號
      dataIndex: "N23",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      dataIndex: "dealN",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.ord.payment"), // 負責業務
      dataIndex: "payment",
      align: "center",
      width: "10%",
    },
    {
      title: t("PMS.util.suppNM"), // 客戶簡稱
      dataIndex: "suppNM",
      align: "center",
    },
    {
      title: t("PMS.ap-manage.subtotal"), // 金額
      dataIndex: "subtotal",
      align: "right",
      width: "10%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log("newSelectedRowKeys = ", newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <>
      <CustomTable
        columns={tableColumns}
        rowSelection={rowSelection}
        rowNumber={6}
        dataSource={s_tableData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_is_showTake(false)} />
          {/* 確定 */}
          <ConfirmButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </>
  );
};

export default TakePayModal;
