import { useState, forwardRef } from "react";
import HD from "./HD";
import TB from "./TB";

const Run80Run90 = forwardRef((props, ref) => {
  const { c_searchData } = props;

  const [c_lightData, set_c_lightData] = useState({}); // 表頭亮起來的資料

  return (
    <>
      {/* 表頭 */}
      <HD
        ref={ref}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
        c_searchData={c_searchData}
      />

      {/* 表身 */}
      {Object.keys(c_lightData).length > 0 && (
        <TB
          c_searchData={c_searchData}
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
          hdRef={ref.current}
        />
      )}
    </>
  );
});
export default Run80Run90;
