import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//----------------------------------------- 取得全部wpc途程表頭 ----------------------------------------
export const getWpcHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcHd`, {
    params: data,
  });
};

//----------------------------------------- 新增wpc途程表頭  ----------------------------------------
export const addWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWpcHd`, data);
};

//----------------------------------------- 編輯wpc途程表頭  ----------------------------------------
export const updateWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWpcHd`, data);
};

//----------------------------------------- 刪除wpc途程表頭  ----------------------------------------
export const deleteWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWpcHd`, data);
};

//----------------------------------------- 取得全部wpc途程表身 ----------------------------------------
export const getWpcTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcTb`, {
    params: data,
  });
};

//----------------------------------------- 取得全部wpc途程表身 ----------------------------------------
export const getWpcIe = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcIe`);
};

//----------------------------------------- 編輯wpc途程表身  ----------------------------------------
export const updateWpcTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWpcTb`, data);
};

//----------------------------------------- 刪除wpc途程表身  ----------------------------------------
export const deleteWpcTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWpcTb`, data);
};

//----------------------------------------- 新增wpc途程表身  ----------------------------------------
export const addWpcTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWpcTb`, data);
};

//----------------------------------------- 全局項目編輯  ----------------------------------------
export const editWpcTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/editWpcTb`, data);
};

//----------------------------------------- 搜尋倉別資料 ----------------------------------------
export const getWh = (param) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWh`, {
    params: param,
  });
};

//----------------------------------------- 搜尋倉別資料 ----------------------------------------
export const getDoc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDoc`, {
    params: _params,
  });
};
export const addDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addDoc`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateDoc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateDoc`, data);
};
