import React, { useState, useEffect } from "react";
import { message } from "antd";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import useAPI from "@/hooks/useAPI";
import { getModelTb } from "@/service/apis/LMS/LabelManage";

export default function Details({ c_lightData, set_c_lightData }) {
  const [tableData, setTableData] = useState([]);

  const call_getModelTb = useAPI(getModelTb);

  const tableColumns = [
    { title: "標籤ID", dataIndex: "modelID", align: "center" },
    { title: "版本", dataIndex: "ver", align: "center" },
    { title: "標籤名", dataIndex: "modelNM", align: "center" },
    { title: "編碼", dataIndex: "encode" },
    {
      title: "備註",
      dataIndex: "note",
      width: "70%",
    },
  ];

  useEffect(() => {
    call_getModelTb.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    if (call_getModelTb.status === "suc") {
      setTableData(
        call_getModelTb.data.map((x, i) => {
          return { ...x, key: i };
        })
      );
      message.success(call_getModelTb.msg);
    } else if (call_getModelTb.status === "err") {
      message.error(call_getModelTb.msg);
      setTableData([]);
    }
  }, [call_getModelTb.status]);

  return (
    <>
      <CustomTable columns={tableColumns} dataSource={tableData} />
    </>
  );
}
