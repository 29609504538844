/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  message,
  DatePicker,
  Divider,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
// API
import useAPI from "@/hooks/useAPI";
import { getStockHD } from "@/service/apis/PMS/GoStock";
// context
import { DataContext } from "../../index";
const { Text } = Typography;

const DetailModal = ({
  // s_detailSearch,
  // set_s_detailSearch,
  call_getStockHDAd,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { c_state, updateState } = useContext(DataContext);
  const { detailSearch } = c_state;
  // useAPI 取得全部進料檢驗表頭資料
  const call_getStockHD = useAPI(getStockHD);
  // 全部作業單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      jobN: values.jobN?.join("|") ?? "",
      sampleID: values.sampleID?.join("|") ?? "",
      createID: values.createID?.join("|") ?? "",
      suppID: values.suppID?.join("|") ?? "",
      startDate: values.createT
        ? dayjs(values.createT[0]).format("YYYY-MM-DD")
        : null,
      endDate: values.createT
        ? dayjs(values.createT[1]).format("YYYY-MM-DD")
        : null,
      sampleStart: values.sampleT
        ? dayjs(values.sampleT[0]).format("YYYY-MM-DD")
        : null,
      sampleEnd: values.sampleT
        ? dayjs(values.sampleT[1]).format("YYYY-MM-DD")
        : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getStockHDAd.request(new_data);
    // 更新資料並關閉跳顯
    updateState({ detailSearch: { show: false, data: new_data } });
  };

  // 第一次渲染取得全部採購單做下拉選單、賦值初始值
  useEffect(() => {
    // call_getStockHD.request();
    const { data } = detailSearch;
    console.log("data = ", data);
    form.setFieldsValue({
      ...data,
      opcode: data.opcode ?? ["4120", "4230"],
      // createT: data.createT
      //   ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])]
      //   : null,
      // sampleT: data.sampleT
      //   ? [dayjs(data.sampleT?.[0]), dayjs(data.sampleT?.[1])]
      //   : null,
    });
  }, []);

  // useAPI 取得全部進料單資料
  useEffect(() => {
    const { data, msg, status } = call_getStockHD;

    if (status === "suc") {
      const allNs =
        data?.map((proItem) => ({
          lable: proItem.jobN,
          value: proItem.jobN,
        })) ?? [];
      console.log(data);
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getStockHD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.util.opcode")}</Text>} name="opcode">
            <Select
              mode="multiple"
              allowClear
              placeholder={t("PMS.util.opcode_placeholder")}
              options={[
                { value: "4120", label: t("PMS.util.opcode_4120") },
                { value: "4230", label: t("PMS.util.opcode_4230") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.gostock.jobN")}</Text>} name="jobN">
            <Select
              mode="tags"
              placeholder={t("PMS.util.placeholder_select")+t("PMS.gostock.jobN")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.gostock.batchno")}</Text>} name="batchno">
            <Select
              mode="tags"
              placeholder={t("PMS.gostock.batchno_placeholder")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.util.N22")}</Text>} name="N22">
            <Select
              mode="tags"
              placeholder={t("PMS.util.placeholder_select")+t("PMS.util.N22")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.util.N23")}</Text>} name="N23">
            <Select
              mode="tags"
              placeholder={t("PMS.util.placeholder_select")+t("PMS.util.N23")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text>{t("PMS.util.createT")}</Text>} name="createT">
            <DatePicker.RangePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-1">
          <CancelButton
            onClick={() =>
              updateState({ detailSearch: { ...detailSearch, show: false } })
            }
          />
          
          <ConfirmButton type="primary" htmlType="submit"/>
        </Col>
      </Row>
    </Form>
  );
};

export default DetailModal;
