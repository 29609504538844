import { useEffect } from "react";
import { Form, Row, Col, Input, Select, Space, Button, message } from "antd";

import useAPI from "@/hooks/useAPI";
import { addPrinter, updatePrinter } from "@/service/apis/LMS/LabelManage";

export default function ModalContainer({ set_s_showModal, s_editData }) {
  const [form] = Form.useForm();

  const call_addPrinter = useAPI(addPrinter);
  const call_updatePrinter = useAPI(updatePrinter);

  const formMap = [
    {
      title: "標籤機ID",
      dataIndex: "printerID",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤機ID" disabled={Object.keys(s_editData).length > 0} />,
    },
    {
      title: "標籤機名稱",
      dataIndex: "printerNM",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤機名稱" />,
    },
    {
      title: "廠牌",
      dataIndex: "brand",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤機廠牌" />,
    },
    {
      title: "分辨率",
      dataIndex: "dpi",
      align: "center",
      col: 6,
      auth: true,
      type: (
        <Select
          placeholder="請選擇分辨率"
          options={[
            {
              label: "6dpmm (152dpi)",
              value: 152,
            },
            {
              label: "8dpmm (203dpi)",
              value: 203,
            },
            {
              label: "12dpmm (300dpi)",
              value: 300,
            },
            {
              label: "24dpmm (600dpi)",
              value: 600,
            },
          ]}
        />
      ),
    },

    {
      title: "列印方式",
      dataIndex: "encode",
      align: "center",
      col: 6,
      auth: true,
      type: (
        <Select
          placeholder="請選擇列印方式"
          options={[
            {
              label: "圖印",
              value: "img",
            },
            {
              label: "ZPL",
              value: "zpl",
            },
          ]}
        />
      ),
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      col: 24,
      auth: false,
      type: <Input.TextArea rows={6} placeholder="請輸入備註" />,
    },
  ];

  const onSubmit = async (values) => {
    if (Object.keys(s_editData).length === 0) {
      call_addPrinter.request(values);
    } else {
      call_updatePrinter.request(values);
    }
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  useEffect(() => {
    if (call_addPrinter.status === "suc") {
      message.success(call_addPrinter.msg);
      handleCancel();
    } else if (call_addPrinter.status === "err") {
      message.error(call_addPrinter.msg);
    }
  }, [call_addPrinter.status]);

  useEffect(() => {
    if (call_updatePrinter.status === "suc") {
      message.success(call_updatePrinter.msg);
      handleCancel();
    } else if (call_updatePrinter.status === "err") {
      message.error(call_updatePrinter.msg);
    }
  }, [call_updatePrinter.status]);

  return (
    <Form
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{ encode: "img", ...s_editData }}
    >
      <Row gutter={[6, 6]}>
        {formMap.map((x) => {
          return (
            <Col span={x.col} key={x.dataIndex}>
              <Form.Item
                label={x.title}
                name={x.dataIndex}
                rules={
                  x.auth && [
                    {
                      required: true,
                      message: `請輸入${x.title}`,
                    },
                  ]
                }
              >
                {x.type}
              </Form.Item>
            </Col>
          );
        })}
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={handleCancel}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
