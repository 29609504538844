/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
//component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { addStrikeTB, updateStrikeTB } from "@/service/apis/OMS/strike";
const { Text } = Typography;

const TbModal = ({ hd_values, s_disabled, s_tbModal, set_s_tbModal, call_getStrikeTB }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const call_addStrikeTB = useAPI(addStrikeTB);
  const call_updateStrikeTB = useAPI(updateStrikeTB);
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(s_tbModal.data).length > 0;
  // 點擊確認時執行
  const onFinish = async (values) => {
    // 新建進料明細
    if (!editMode) {
      console.log("values = ", values);
      call_addStrikeTB.request(values);
    }

    // 編輯進料明細
    if (editMode) {
      call_updateStrikeTB.request(values);
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    const type = Object.keys(s_tbModal.data).length > 0 ? "edit" : "create";

    if (type === "create") {
      form.setFieldsValue({
        rcvT: dayjs(),
        opUUID: hd_values.opUUID,
      });
    }

    if (type === "edit") {
      console.log("s_tbModal.data = ", s_tbModal.data);
      form.setFieldsValue({
        ...s_tbModal.data,
        rcvT: dayjs(s_tbModal.data?.rcvT),
      });
    }
  }, []);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addStrikeTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getStrikeTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addStrikeTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateStrikeTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getStrikeTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateStrikeTB.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>

        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.rcvtype}>{t("OMS.ap-manage.rcvtype")}</Text>}
            name="rcvtype"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ap-manage.rcvtype") }}
          >
            <Select
              // mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              disabled={s_disabled.rcvtype}
              placeholder={t("util.placeholder_select") + t("OMS.ap-manage.rcvtype")}
              options={[
                { value: "1", label: t("OMS.ap-manage.rcvtype_1") },
                { value: "2", label: t("OMS.ap-manage.rcvtype_2") },
                { value: "3", label: t("OMS.ap-manage.rcvtype_3") },
                { value: "4", label: t("OMS.ap-manage.rcvtype_4") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 收款金額
            label={<Text disabled={s_disabled.rcvtotal}>{t("OMS.ap-manage.rcvtotal")}</Text>}
            name="rcvtotal"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ap-manage.rcvtotal") }}
          >
            <InputNumber
              disabled={s_disabled.rcvtotal}
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.ap-manage.rcvtotal")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 收款時間
            label={<Text disabled={s_disabled.rcvT}>{t("OMS.ap-manage.rcvT")}</Text>}
            name="rcvT"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.ap-manage.rcvT") }}
          >
            <DatePicker
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.ap-manage.rcvT")}
              disabled={s_disabled.rcvT}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={24}>
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS.ap-manage.strike_note")}</Text>}
            name="note"
          >
            <Input.TextArea rows={2} disabled={s_disabled.tb_note} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tb_submit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
