import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//----------------------------------------- 取得委外項目 ----------------------------------------
export const getOemitem = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOemitem`, {
    params: data,
  });
};

//----------------------------------------- 新增委外項目   ----------------------------------------
export const addOemitem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOemitem`, data);
};

//----------------------------------------- 編輯委外項目   ----------------------------------------
export const updateOemitem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOemitem`, data);
};

//----------------------------------------- 刪除委外項目  ----------------------------------------
export const deleteOemitem = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOemitem`, data);
};

//----------------------------------------- 取得委外供應維護 ----------------------------------------
export const getOEM = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOEM`, {
    params: data,
  });
};

//----------------------------------------- 新增委外供應維護   ----------------------------------------
export const addOEM = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOEM`, data);
};

//----------------------------------------- 編輯委外供應維護   ----------------------------------------
export const updateOEM = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOEM`, data);
};

//----------------------------------------- 刪除委外供應維護  ----------------------------------------
export const deleteOEM = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOEM`, data);
};
