/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import PNButton from "@/components/ERP/PNButtonOri";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { addOutstockTB, updateOutstockTB } from "@/service/apis/OMS/Outstock";
const { Text } = Typography;

const TbModal = ({ hd_values, s_disabled, s_tbModal, set_s_tbModal, call_getOutstockTB }) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addOutstockTB = useAPI(addOutstockTB);
  // useAPI 編輯表身
  const call_updateOutstockTB = useAPI(updateOutstockTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(s_tbModal.data).length > 0;

  // 改變料號時
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 點擊確認時執行
  const onFinish = async (values) => {
    // 新建進料明細
    if (!editMode) {
      call_addOutstockTB.request(values);
    }

    // 編輯進料明細
    if (editMode) {
      // [品保判退數量]+[品保放行數量]要等於[進料數量]
      if (hd_values.lifeF === "4" && values.rejectqty + values.goqty !== values.indqty) {
        // message.error("[品保判退數量]+[品保放行數量] 要等於 [進料數量]");
        message.error(t("OMS.outstock.QAcheck_err"));
        return;
      }
      call_updateOutstockTB.request(values);
    }
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    if (!editMode) {
      form.setFieldsValue({
        opUUID: hd_values.opUUID,
        judgeT: dayjs(),
      });
    }

    if (editMode) {
      form.setFieldsValue({
        ...s_tbModal.data,
        opUUID: hd_values.opUUID,
        rejectqty: s_tbModal.data?.rejectqty ?? 0,
        goqty: s_tbModal.data?.goqty ?? 0,
        judgeT:
          s_tbModal.data?.judgeT && !s_tbModal.data?.judgeT.includes("0001-01-01")
            ? dayjs(s_tbModal.data?.judgeT)
            : dayjs(),
      });
    }
  }, []);

  // useAPI 新建表身資料 or useAPI 更新表頭資料
  useEffect(() => {
    const { status, msg } = editMode ? call_updateOutstockTB : call_addOutstockTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getOutstockTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addOutstockTB.status, call_updateOutstockTB.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("PMS.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="preitemUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="unitprice" hidden>
          <InputNumber />
        </Form.Item>
        <Form.Item name="localprice" hidden>
          <InputNumber />
        </Form.Item>
      </div>

      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("OMS.util.PN")}</Text>}
            name="PN"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.util.PN") }}
          >
            <Select
              showSearch
              placeholder={t("util.placeholder_select") + t("OMS.util.PN")}
              disabled={s_disabled.PN}
              onChange={changePN}
              options={c_PNs.options}
            />
          </Form.Item>
        </Col>
        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 品名 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>

        {/* 規格 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>

        {/* 圖號 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.drawno")}</Text>} name="drawno">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 計量單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 操作單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
        {/* 組合數量 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS.util.pbom")}</Text>} name="pbom">
            <Input disabled placeholder={t("util.placeholder_select") + t("OMS.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            label={
              <Text
                disabled={
                  s_disabled.utype ||
                  (f_unit?.length === 0 && f_opunit?.length === 0) ||
                  f_PN?.length === 0
                }
              >
                {t("OMS.util.utype")}
              </Text>
            }
            name="utype"
            onChange={(value) => {
              // 選擇「計量單位」或「操作單位」帶入對應的採購單位
              form.setFieldsValue({
                punit: form.getFieldValue(value),
              });
            }}
          >
            <Select
              placeholder={t("util.placeholder_select") + t("OMS.util.utype")}
              optionFilterProp="children"
              disabled={
                s_disabled.utype ||
                (f_unit?.length === 0 && f_opunit?.length === 0) ||
                f_PN?.length === 0
              }
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && (
                <Select.Option value="unit">{t("OMS.util.unit")}</Select.Option>
              )}
              {f_opunit?.length > 0 && (
                <Select.Option value="opunit">{t("OMS.util.opunit")}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 使用單位 */}
          <Form.Item label={<Text disabled>{t("OMS.outstock.punit")}</Text>} name="punit">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 發貨數量
            label={<Text disabled={s_disabled.indqty}>{t("OMS.outstock.indqty")}</Text>}
            name="indqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS.outstock.indqty") }}
          >
            <InputNumber
              className="w-full"
              disabled={s_disabled.indqty}
              placeholder={t("util.placeholder_input") + t("OMS.outstock.indqty")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item
            // 驗收數量
            label={<Text disabled={s_disabled.goqty}>{t("OMS.outstock.goqty")}</Text>}
            name="goqty"
            rules={[{ required: s_disabled.goqty ? false : true }]}
            messageVariables={{ label: t("OMS.outstock.goqty") }}
          >
            <InputNumber
              className="w-full"
              disabled={s_disabled.goqty}
              placeholder={t("util.placeholder_input") + t("OMS.outstock.goqty")}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 驗退數量
            label={<Text disabled={s_disabled.rejectqty}>{t("OMS.outstock.rejectqty")}</Text>}
            name="rejectqty"
            rules={[{ required: s_disabled.rejectqty ? false : true }]}
            messageVariables={{ label: t("OMS.outstock.rejectqty") }}
          >
            <InputNumber
              className="w-full"
              placeholder={t("util.placeholder_input") + t("OMS.outstock.rejectqty")}
              disabled={s_disabled.rejectqty}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            //品保判定日期
            label={<Text disabled={s_disabled.judgeT}>{t("OMS.outstock.judgeT")}</Text>}
            name="judgeT"
            rules={[{ required: s_disabled.judgeT ? false : true }]}
            messageVariables={{ label: t("OMS.outstock.judgeT") }}
          >
            <DatePicker
              className="w-full"
              placeholder={t("util.placeholder_select") + t("OMS.outstock.judgeT")}
              disabled={s_disabled.judgeT}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 4]}>
        <Col span={24}>
          {/* 註記 */}
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS.outstock.note")}</Text>}
            name="note"
          >
            <Input.TextArea
              rows={2}
              disabled={s_disabled.tb_note}
              placeholder={t("util.placeholder_input") + t("OMS.outstock.note")}
            />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
