import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Upload,
  message,
  Radio,
  Space,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { addDoc, updateDoc } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";

const ModalContainer = ({ s_TabKey, s_editData, set_s_isShowModal, c_lightData, s_pf }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [s_file, set_s_file] = useState(null);

  const call_addDoc = useAPI(addDoc);
  const call_updateDoc = useAPI(updateDoc);

  const props = {
    listType: "picture",
    beforeUpload: () => false,
    name: "test",
    maxCount: 1,
    onChange: ({ file }) => {
      // console.log("file = ", file);
      set_s_file(file);
    },
  };

  // 工程文件通知只能選擇 ECN
  const pdfMap =
    s_TabKey === "ECN"
      ? [{ label: "ECN", value: "ECN" }]
      : [
          { label: "SOP", value: "SOP" },
          { label: "SIP", value: "SIP" },
          { label: "ECR", value: "ECR" },
          { label: "PIC", value: "PIC" },
        ];

  const onSubmit = async (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    const _data = {
      ...s_editData,
      ...values,
    };

    if (type === "create") {
      let formData = new FormData();
      console.log("s_file = ", s_file);
      formData.append("file", s_file);
      formData.append("doctype", values.doctype);
      formData.append("ver", values.ver);
      formData.append("pfID", values.pfID);
      formData.append("isvalid", values.isvalid);
      formData.append("note", values.note || "");
      formData.append("PN", c_lightData.PN);
      call_addDoc.request(formData);
    }
    if (type === "edit") {
      call_updateDoc.request(_data);
    }
  };

  useEffect(() => {
    if (call_addDoc.status === "suc") {
      message.success(call_addDoc.msg);
      set_s_isShowModal(false);
    } else if (call_addDoc.status === "err") {
      message.error(t(`errorCode.${call_addDoc.msg}`));
    }
  }, [call_addDoc.status]);

  useEffect(() => {
    if (call_updateDoc.status === "suc") {
      message.success(call_updateDoc.msg);
      set_s_isShowModal(false);
    } else if (call_updateDoc.status === "err") {
      message.error(t(`errorCode.${call_updateDoc.msg}`));
    }
  }, [call_updateDoc.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={
        Object.keys(s_editData).length === 0
          ? {
              isvalid: true,
              PN: c_lightData.PN,
            }
          : s_editData
      }
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="PN" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[24, 12]}>
        <Col span={8}>
          {/* 文件類別 */}
          <Form.Item label={t("ADM.edm.pdftype")} name="doctype" rules={[{ required: true }]}>
            <Select options={pdfMap} />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 版碼 */}
          <Form.Item label={t("util.ver")} name="ver">
            <Input disabled placeholder="Default"/>
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 有效 */}
          <Form.Item label={t("util.isvalid")} name="isvalid">
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 文件類別 */}
          <Form.Item label={t("ADM.procedure-manage.pf")} name="pfID" rules={[{ required: true }]}>
            <Select  options={s_pf.map((x) => ({ label: `${x.pfID}_${x.pfNM}`, value: x.pfID })) ?? []}/>
          </Form.Item>
        </Col>

        <Col span={16}>
          {/* 有效 */}
          <Typography.Text style={{ fontSize: "17px" }}>
            {t("ADM.edm.docUpload")}：{" "}
          </Typography.Text>
          <Upload disabled={Object.keys(s_editData).length > 0} {...props}>
            <Button
              icon={<UploadOutlined />}
              size="large"
              style={{ width: "250px" }}
              disabled={Object.keys(s_editData).length > 0}
            >
              {t("ADM.edm.Upload")}
            </Button>
          </Upload>
        </Col>

        <Col span={24}>
          {/* 註記 */}
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea row={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
