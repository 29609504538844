import { Input, Form, DatePicker } from "antd";
const HiddenFormItem = () => {
  return (
    <div>
      <Form.Item name="linkType" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="custNM" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="lifeF" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="lifeFT" hidden>
        <DatePicker />
      </Form.Item>

      <Form.Item name="opUUID" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="preopUUID" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="charge" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="itemtotal" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="sumtotal" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="tax" hidden>
        <Input />
      </Form.Item>

      <Form.Item name="total" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};

export default HiddenFormItem;
