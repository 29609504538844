import React, { useContext, useEffect } from "react";
import { Modal, Space, Grid, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util
import { formatAmount } from "@/util/format";
// context
import { MainPageContext } from "../../MainPage/index";
// component
import AddcModal from "../Modal/AddcModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteProADD } from "@/service/apis/PMS/Pro";

const AddcContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled, s_proTb, set_s_proTb, s_Adds, call_getProADD } =
    useContext(MainPageContext);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  // useAPI 刪除預付作業
  const call_deleteProADD = useAPI(deleteProADD);

  // 刪除
  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("PMS.pro.addc") + t("util.delete") + t("util.ok"), // 預付作業刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的預付作業無誤，再進行刪除",
      content: t("PMS.pro.addc_delete_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_deleteProADD.request(rowData);
      },
    });
  };

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.pro.statement"), // 項目
      dataIndex: "statement",
      align: "center",
    },
    {
      title: t("PMS.pro.amount"), // 金額
      dataIndex: "amount",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} />
        </Space>
      ),
    },
  ];

  // 第一次渲染取得條款註記
  useEffect(() => {
    call_getProADD.request({
      "addtype": "addc",
      "opUUID": hd_values.opUUID,
    });
  }, []);

  // useAPI 取得條款註記
  useEffect(() => {
    const { data, msg, status } = call_getProADD;
    if (status === "suc") {
      message.success(msg);
      set_s_proTb((prev) => ({
        ...prev,
        addc: data.addc ?? [],
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProADD.status]);

  // useAPI 刪除條款註記
  useEffect(() => {
    const { status, msg } = call_deleteProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addc",
        "opUUID": hd_values.opUUID,
      });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteProADD.status]);

  const type = Object.keys(s_tbModal.data).length > 0 ? "edit" : "create";

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_proTb.addc?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <CustomModal
        width={screens.xxl ? "20%" : "40%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={t(`util.${type === "edit" ? "編輯" : "新增"}`) + 加扣款項}
        title={t(`util.${type === "edit" ? "edit" : "add"}`) + t("PMS.pro.addc")}
      >
        {s_tbModal.show && <AddcModal s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}
      </CustomModal>
    </>
  );
};

export default AddcContainer;
