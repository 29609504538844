import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { excelDataProduct, getAllInv } from "@/service/apis/WMS/StockSearch";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import dayjs from "dayjs";
import exportExcel from "@/util/exportExcel";
import { formatAmount } from "@/util/format";
import { message } from "antd";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const SearchWh = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_excelData, set_s_excelData] = useState([]);
  const call_excelDataProduct = useAPI(excelDataProduct);
  const call_getAllInv = useAPI(getAllInv);

  //匯出EXCEL資料
  const excel_tableColumns = [
    {
      title: "料号",
      dataIndex: "PN",
      width: "25%",
      isExcel: true,
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      width: "25%",
      isExcel: true,
    },
    {
      title: "规格",
      dataIndex: "pdtspec",
      width: "25%",
      isExcel: true,
    },
    {
      title: "屬性",
      dataIndex: "attribute",
      width: "25%",
      isExcel: true,
    },
    {
      title: "品牌",
      dataIndex: "mtype",
      width: "25%",
      isExcel: true,
    },
    {
      title: "分類",
      dataIndex: "querycat",
      width: "25%",
      isExcel: true,
    },
    {
      title: "庫存数量",
      dataIndex: "tqty",
      width: "25%",
      isExcel: true,
    },
    {
      title: t("util.unitNM"),
      dataIndex: "unit",
      width: "25%",
      isExcel: true,
    },
    {
      title: "面价",
      dataIndex: "unitwt",
      width: "25%",
      isExcel: true,
    },
    {
      title: "安全库存",
      dataIndex: "batchbom",
      width: "25%",
      isExcel: true,
    },
    {
      title: "资产仓别",
      dataIndex: "whNM",
      width: "25%",
      isExcel: true,
    },
  ];

  const tableColumns = [
    {
      title: t("util.PN"),
      dataIndex: "PN",
      width: "25%",
    },
    {
      title: t("util.pdtNM"),
      dataIndex: "pdtNM",
      width: "25%",
    },
    {
      title: t("util.pdtspec"),
      dataIndex: "pdtspec",
      width: "25%",
    },
    {
      title: t("util.whID"),
      dataIndex: "whID",
    },
    {
      title: t("util.sqty"),
      dataIndex: "sumsqty",
      align: "right",

      render: (text) => formatAmount(text),
    },
    {
      title: t("util.dqty"),
      dataIndex: "sumdqty",
      align: "right",

      render: (text) => formatAmount(text),
    },
    {
      title: t("util.tqty"),
      dataIndex: "sumtqty",
      align: "right",

      render: (text) => formatAmount(text),
    },
  ];

  //匯出EXCEL
  const downloadExcel = () => {
    const excelData = s_excelData.map((v, i) => ({
      ...v,
      key: i,
    }));
    exportExcel(excel_tableColumns, excelData, `${dayjs().format("YYYY-MM-DD")}_库存状况.xlsx`);
  };

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    downloadExcel, //匯出EXCEL傳上父層觸發
    onSearch: onSearch,
    onCreate: () => {
      set_s_editData({});
    },
  }));

  //匯出EXCEL 整理QUERY給後端
  const formattedPNs = s_tableData
    ?.map((item) => item.PN) // 取得所有的 PN
    .join("|");
  useEffect(() => {
    call_excelDataProduct.request({ PN: formattedPNs });
  }, [s_tableData]);

  useEffect(() => {
    const { data, status, msg } = call_excelDataProduct;
    if (status === "suc") {
      message.success(msg);
      // //匯出EXCEL 整理資料
      const _data = data?.reduce((prev, curr) => {
        if (curr.PN.charAt(0) !== "A") {
          console.log("curr=", curr.PN);
          prev.push(curr);
        }
        return prev;
      }, []);
      set_s_excelData(_data);
    } else if (status === "err") {
      set_s_excelData([]);
      message.error(msg);
    }
  }, [call_excelDataProduct.status]);

  // const onSearch = (value) => {
  //   let newData = call_getAllInv.data.map((x, i) => ({ ...x, key: i }));
  //   if (!value.PN && value.wh.length === 0) {
  //     set_s_tableData(newData);
  //     return;
  //   }
  //   const Data = newData.filter((item) =>
  //     Object.entries(value).every(([key, val]) =>
  //       key === "PN" ? item[key].includes(val) : key === "whID" ? val.includes(item[key]) : true
  //     )
  //   );
  //   set_s_tableData(Data);
  // };

  // const onSearch = (val) => {
  //   console.log("val=", val);
  //   set_s_tableData(
  //     call_getAllInv.data
  //       .map((x, i) => ({ ...x, key: i }))
  //       .filter((x) => (val.whID ? x.whID.includes(val.whID) : true))
  //   );
  // };

  const onSearch = (val) => {
    console.log("val=", val);
    set_s_tableData(
      call_getAllInv.data
        .map((x, i) => ({ ...x, key: i }))
        .filter(
          (x) =>
            (val.PN ? x.PN.includes(val.PN) : true) && (val.whID ? x.whID.includes(val.whID) : true)
        )
    );
  };

  useEffect(() => {
    call_getAllInv.request(s_searchData);
  }, []);

  useEffect(() => {
    if (call_getAllInv.status === "suc") {
      message.success(call_getAllInv.msg);
      // 整理資料
      set_s_tableData(
        call_getAllInv.data.map((x) => ({
          ...x,
          key: x.PN + x.whID,
        }))
      );
    } else if (call_getAllInv.status === "err") {
      set_s_tableData([]);
      message.error(call_getAllInv.msg);
    }
  }, [call_getAllInv.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getAllInv.status === "load"}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
    </>
  );
});

export default SearchWh;
