import bwipjs from "bwip-js";
import Konva from "konva";

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    case "inch":
      return value * 96;
    default:
      return value;
  }
};

export function formatFloat(num, pos) {
  var size = Math.pow(10, pos);
  return Math.round(num * size) / size;
}

const dragBoundFunc = (pos) => {
  return {
    x: pos.x < 0 ? 0 : pos.x,
    y: pos.y < 0 ? 0 : pos.y,
  };
};

/**
替換字串中的變數的函數
@param {String} text - 要替換的字串
@param {Array} variables - 包含變數的陣列
@returns {String} - 回傳替換後的字串
*/
const replaceVariable = (text, data, variables) => {
  // 使用正則表達式找到所有變數
  const reg_g = /\$\{(.+?)}/g;
  let result = null;
  let list = [];
  do {
    result = reg_g.exec(text);
    result && list.push(result[1]);
  } while (result);

  let newText = text;
  // 將每個變數替換為對應的值
  for (const index of list) {
    // 從變數陣列中取得對應的值
    let variableValue = data[index - 1]?.text || variables[data[index - 1]?.keyText];
    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      variableValue = "${" + index + "}";
    }
    // 將變數替換為對應的值
    newText = newText.replace("${" + index + "}", variableValue);
  }
  return newText;
};

/**
引用其他項目固定字串或關鍵字串的內容的函數
@param {Object} data - 包含替換的數據的物件
@param {Object} item - 要引用的 item 物件
@param {Object} variable - 包含變數的物件
@returns {Object} - 回傳修改後的 item 物件
*/
const quoteRef = (data, item, variable) => {
  if (item.type === "keyText") {
    // 如果是 keyText，則從 variable 中取得對應的值並替換掉 keyText 的值
    let variableValue = variable[item.keyText];
    if (!variableValue) {
      // 如果找不到對應的值，則保留原來的變數名稱
      variableValue = "${" + item.keyText + "}";
    }
    return { ...item, keyText: variableValue };
  }
  // 替換掉該 item 的 quoteText 中的變數
  let replacedQuoteText = replaceVariable(item.quoteText, data, variable);
  // 如果不是 keyText，則將 quoteText 替換為新的值
  return { ...item, quoteText: replacedQuoteText };
};

const createText = (layer, data) => {
  // 計算文字的總寬度
  const calculateTotalTextWidth = (text, fontFamily, bold, fontSize) => {
    const context = document.createElement("canvas").getContext("2d");
    context.font = `${bold} ${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };
  // 根據 data.type 設置要繪製的文字內容
  const text =
    data.type === "keyText" || data.type === "quoteText" ? data.fakeText : data[data.type];

  // 創建 Canvas 元素
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // 計算文字的總寬度
  const totalTextWidth = calculateTotalTextWidth(text, data.fontFamily, data.bold, data.fontSize);
  // 設置 Canvas 大小
  const canvasWidth = totalTextWidth * 10; // 使用文字的總寬度 + x 作為 canvas 寬度
  const canvasHeight = data.fontSize * 10; // 使用 fontSize 的高度作為 canvas 高度
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  // 設置文字渲染的樣式和屬性
  context.font = `${data.bold} ${data.fontSize}px ${data.fontFamily}`;
  context.fillStyle = data.c;

  context.scale(10, 10);

  // 渲染文字到 Canvas
  context.fillText(text, 0, data.fontSize - 1);

  // 創建圖片對象
  const image = new Konva.Image({
    x: data.x,
    y: data.y,
    image: canvas,
    width: totalTextWidth * (data.fontWidth / 100),
    height: data.fontSize,
    draggable: true,
    id: data.id,
  });

  // 將圖片對象添加到層中
  layer.add(image);
};

// 繪製形狀
const createShape = (layer, data) => {
  switch (data.type) {
    case "border":
      let startPoint = { x: data.x, y: data.y };
      let endPoint = {
        x: startPoint.x + data.len * Math.cos((data.angle * Math.PI) / 180),
        y: startPoint.y + data.len * Math.sin((data.angle * Math.PI) / 180),
      };
      const line = new Konva.Line({
        draggable: true,
        // dragBoundFunc: dragBoundFunc,
        id: data.id,
        points: [startPoint.x, startPoint.y, endPoint.x, endPoint.y],
        stroke: data.c,
        strokeWidth: data.b,
      });
      layer.add(line);
      break;
    case "round":
      const circle = new Konva.Circle({
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        id: data.id,
        x: data.x,
        y: data.y,
        radius: data.radius,
        fill: data.c,
      });
      layer.add(circle);
      break;
    default:
      break;
  }
};

// 繪製barcode
const createBarcodeImage = async (layer, data) => {
  let opts = {
    bcid: data.type,
    text: data.fakeText || data.quoteText,
    scale: 3,
    includetext: false,
    // textxalign: "center",
  };
  if (data.type === "datamatrix") {
    // 防止舊模板出錯 預設"20x20"
    opts["version"] = data.datamatrixVer || "20x20";
  }

  if (data.type === "upca" || data.type === "ean13") {
    opts["includetext"] = true;
    // 設定高度須把px 轉換成mm
    opts["height"] = (data.code128height * 25.4) / 96;
    opts["textsize"] = 9;
  }

  // 創建 barcode_canvas
  const barcode_canvas = document.createElement("canvas");
  // 使用 bwipjs 將條碼繪製到 canvas 上
  bwipjs.toCanvas(barcode_canvas, opts);
  // 將繪製好的條碼轉換為 base64 編碼的圖片資料
  const barcodeDataUrl = barcode_canvas.toDataURL("image/png", 1);
  return new Promise((resolve, reject) => {
    const barcode_image = new Image();
    barcode_image.src = barcodeDataUrl;
    barcode_image.onload = () => {
      const barcodeWidth =
        data.type === "datamatrix" || data.type === "qrcode" ? 126 : data.code128width;
      const barcodeHeight =
        data.type === "datamatrix" || data.type === "qrcode" ? barcodeWidth : data.code128height;
      const scaledWidth = barcodeWidth * data.barcodeSize;
      const scaledHeight = barcodeHeight * data.barcodeSize;
      const konvaImg = new Konva.Image({
        id: data.id,
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        x: data.x,
        y: data.y,
        image: barcode_image,
        width: scaledWidth,
        height: scaledHeight,
      });

      // 將圖片繪製到 canvas 上
      layer.add(konvaImg);
      resolve("ok");
    };
    barcode_image.onerror = () => reject("ng");
  });
};

// 繪製圖片指定位置
const createImage = (layer, data) => {
  return new Promise((resolve, reject) => {
    const _image = new Image();
    _image.src = data.imgUrl;
    _image.onload = () => {
      const konvaImg = new Konva.Image({
        id: data.id,
        draggable: true,
        dragBoundFunc: dragBoundFunc,
        x: data.x,
        y: data.y,
        image: _image,
        width: data.boxWidth,
        height: data.len,
      });
      // 將圖片繪製到 canvas 上
      layer.add(konvaImg);
      resolve("ok");
    };
    _image.onerror = () => reject("ng");
  });
};

/**
生成標籤的函數
@param {Array} variables - 要替換的變數
@param {Array} models - 條碼模板
@returns {Promise} - 回傳一個 Promise 物件，內容為生成的標籤圖片的 base64 編碼 URL
*/
const buildLabel = async (ref, variables, models, changeModels) => {
  // 放大倍數
  const scaleFactor = variables.size;
  const stageWidth = unitToPx(variables.width, variables.unit) * scaleFactor;
  const stageHeight = unitToPx(variables.height, variables.unit) * scaleFactor;

  // Create a stage and layer
  const stage = new Konva.Stage({
    container: ref,
    width: stageWidth,
    height: stageHeight,
  });

  stage.scale({
    x: scaleFactor,
    y: scaleFactor,
  });

  stage.on("dragend", function () {
    let shapes = stage.getIntersection({
      x: stage.pointerPos.x,
      y: stage.pointerPos.y,
    });
    if (!shapes) return;
    const newModels = models.reduce((data, current) => {
      const targetID = shapes.id();
      if (current.id === targetID) {
        if (current.type === "border") {
          data.push({
            ...current,
            x: current.x + shapes.attrs.x,
            y: current.y + shapes.attrs.y,
          });
        } else {
          data.push({ ...current, x: shapes.attrs.x, y: shapes.attrs.y });
        }
      } else {
        data.push(current);
      }
      return data;
    }, []);
    // console.log(newModels);
    changeModels(newModels);
  });

  let layer = new Konva.Layer();

  const background = new Konva.Rect({
    x: 0,
    y: 0,
    width: stage.width(),
    height: stage.height(),
    fill: "white",
  });
  layer.add(background);

  const dpi = window.devicePixelRatio * 96; // 螢幕每英寸的像素數
  const width = stage.width(); // 獲取`stage`的寬度
  const height = stage.height(); // 獲取`stage`的高度
  const xNum = (width / dpi) * 25.4; // 將寬度轉換為mm標準尺寸
  const yNum = (height / dpi) * 25.4; // 將高度轉換為mm標準尺寸

  // 繪製X軸標尺
  for (let i = 0; i < xNum; i++) {
    const x = (i / xNum) * width;
    const line = new Konva.Line({
      points: [x, 0, x, height],
      stroke: "#e6e6e6", // 使用RGBA格式設置線條顏色
      strokeWidth: i % 10 === 0 ? 0.5 : 0.2,
    });
    layer.add(line);
  }

  // 繪製y軸標尺
  for (let i = 0; i < yNum; i++) {
    const y = (i / yNum) * height;
    const line = new Konva.Line({
      points: [0, y, width, y],
      stroke: "#e6e6e6", // 使用RGBA格式設置線條顏色
      strokeWidth: i % 10 === 0 ? 0.5 : 0.2,
    });
    layer.add(line);
  }

  stage.add(layer);

  // 遍歷模板中的所有項目
  for (const model of models) {
    // 如果模板無效，則跳過此模板
    if (!model.isvalid) continue;
    let layer = new Konva.Layer();
    stage.add(layer);
    // 替換項目中的變數
    const data = quoteRef(models, model, variables);

    // 根據項目的類型繪製圖形或文字
    switch (data.type) {
      case "text":
      case "keyText":
      case "date":
      case "quoteText":
        createText(layer, data);
        break;
      case "border":
      case "round":
        createShape(layer, data);
        break;
      case "img":
        await createImage(layer, data);
        break;
      default:
        await createBarcodeImage(layer, data);
        break;
    }
  }

  // // 將畫布轉換成 base64 編碼的圖片 URL，並返回
  const dataURL = stage.toDataURL();
  return dataURL;
};

export default {
  buildLabel,
  replaceVariable,
  quoteRef,
};
