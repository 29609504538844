import { Button, Select, Typography } from "antd";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import { formatAmount, formatNumber } from "@/util/format";

import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import Light from "./Light";
import PageHeader from "@/components/PageHeader";
import Pulse from "./pulse";
import SystemContext from "@/components/Context/SystemContext";
import { dashboardSocket } from "@/service/ws/ws";
import dayjs from "dayjs";
import { getPS } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";
import useWebSocket from "@/hooks/useWebSocket";

const { Title } = Typography;

const colorMap = {
  "": "white",
  B: "main",
  G: "success",
  W: "white", // warning
  R: "error",
};

const Box = ({ title, value }) => (
  <div className="flex rounded shadow-lg text-center">
    <span className="rounded-tl-md rounded-bl-md bg-[#c0dcf0] px-4 py-1">
      <Title level={4} className="m-0">
        {title}
      </Title>
    </span>
    <span className="rounded-tr-md rounded-br-md bg-white px-4 py-1 min-w-[80px]">
      <Title level={4} className="m-0">
        {value}
      </Title>
    </span>
  </div>
);

export default function Dashboard() {
  const { t } = useTranslation();
  const { c_systemConfig, set_c_systemConfig } = useContext(SystemContext);
  // 全螢幕state
  const [s_full, set_s_full] = useState(false);
  const [s_tableData, set_s_tableData] = useState([]);
  // header上方data
  const [s_boxData, set_s_boxData] = useState({});
  const [s_psID, set_s_psID] = useState("");
  const call_dashboardSocket = useWebSocket(dashboardSocket);
  const call_getPS = useAPI(getPS);

  function sortTime(time) {
    const formatTime = dayjs(time).format("YYYY-MM-DD HH:mm:ss");
    return formatTime !== "0000-00-00 00:00:00" ? formatTime : null;
  }

  const handle = useFullScreenHandle();

  // 開啟全螢幕
  const openFullScreen = () => {
    set_s_full(true);
    handle.enter();
  };

  const closeFullScreen = () => {
    set_s_full(false);
    handle.exit();
  };

  let psOptions = (call_getPS?.data ?? []).map((x) => {
    return { value: x.psID, label: x.psNM };
  });
  psOptions.push({ value: "", label: t("util.all") });

  const tableColumns = [
    {
      title: t("util.wl"),
      dataIndex: "wlID",
      align: "center",
    },
    {
      title: t("util.woN"),
      dataIndex: "woN",
      align: "left",
      width: "8%",
    },
    {
      title: t("util.situF"),
      dataIndex: "situF",
      // align: "center",
      render: (_, rowData, __) => <Light rowData={rowData} />,
      // render: (_, rowData, __) => (
      //   <div className="flex-center">
      //     {rowData.wlID === "A1003" ? <Pulse /> : <Light rowData={rowData} />}
      //   </div>
      // ),
    },
    {
      title: "計劃達成率",
      dataIndex: "pdtRate",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text, 1) + "%",
    },
    {
      title: "生產效率",
      dataIndex: "PPE",
      align: "right",
      render: (text) => formatAmount(text, 1) + "%",
    },
    {
      title: t("util.PN"),
      dataIndex: "PN",
      align: "left",
      width: "10%",
    },
    {
      title: t("util.pdtNM"),
      dataIndex: "pdtNM",
      align: "left",
      width: "12%",
    },
    {
      title: t("util.planqty"), // 計畫數量
      dataIndex: "allPlanqty",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.moldC"), // 合模次數
      dataIndex: "moldC",
      align: "right",
      className: "bg-[#f9e9d7ac]",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.moldcavity"), // 模具穴數
      dataIndex: "moldcavity",
      align: "right",
      className: "bg-[#f9e9d7ac]",
    },
    {
      title: t("util.inC"), // 產出數量
      dataIndex: "inC",
      align: "right",
      className: "bg-[#f9e9d7ac]",
    },
    {
      title: t("util.okC"), // 良品數量
      dataIndex: "okC",
      align: "right",
      className: "bg-[#d9f5b8ac]",
    },
    {
      title: t("util.ngC"), // 不良數量
      align: "right",
      dataIndex: "ngC",
      className: "bg-[#d9f5b8ac]",
    },
    {
      title: "在線人數",
      dataIndex: "hdC",
      align: "right",
      hidden: s_full,
    },
    {
      title: "實際工時",
      dataIndex: "whC",
      align: "right",
      hidden: s_full,
      render: (text) => text + "h",
    },
    {
      title: "更新時間",
      dataIndex: "lifeFT",
      align: "center",
      width: "6%",
      hidden: s_full,
      render: (text) => (text ? dayjs(text).format("MM-DD:hh-mm") : ""),
    },
  ];

  useEffect(() => {
    call_getPS.request();
  }, []);

  useEffect(() => {
    console.log("s_psID === ", s_psID);
    call_dashboardSocket.changeParams(
      { psID: s_psID }
      // s_psID
    );
    call_dashboardSocket.setIsOpen(true);
  }, [s_psID]);

  useEffect(() => {
    if (call_dashboardSocket.socketData.status) {
      console.log("call_dashboardSocket.socketData.data = ", call_dashboardSocket.socketData.data);
      set_s_tableData(
        call_dashboardSocket.socketData.data?.map((x, i) => {
          return {
            ...x,
            key: i,
            index: i,
            openT: sortTime(x.openT),
            closeT: sortTime(x.closeT),
            lifeFT: sortTime(x.lifeFT),
            sliceT: sortTime(x.sliceT),
            allPlanqty: formatNumber(x.allPlanqty, c_systemConfig.local),
            inC: formatNumber(x.inC, c_systemConfig.local),
            okC: formatNumber(x.okC, c_systemConfig.local),
            ngC: formatNumber(x.ngC, c_systemConfig.local),
            mhC: formatNumber(x.mhC, c_systemConfig.local),
            whC: formatNumber(x.whC, c_systemConfig.local),
          };
        }) || []
      );
      if (call_dashboardSocket.socketData.data && call_dashboardSocket.socketData.data.length > 0) {
        call_dashboardSocket.socketData.data.forEach((item) =>
          console.log("item.situF = ", item.situF !== "")
        );
        // 開線數
        const openLine = call_dashboardSocket.socketData.data.filter(
          (item) => item.situF !== "" && item.situF !== "W"
        ).length;
        console.log("openLine = ", openLine);
        // 總線數
        const allLine = call_dashboardSocket.socketData.data.length;
        // 開線率
        const openLineRate = `${((openLine / allLine) * 100).toFixed(1)}%`;

        set_s_boxData({ openLine, allLine, openLineRate });
      }
    } else {
      set_s_boxData({});
      set_s_tableData([]);
    }
  }, [call_dashboardSocket.socketData]);

  const aaa = (record) => {
    console.log("????????");
    // return "bg-red-400";
    return record?.woN === "TEST-0509-002" ? "bg-red-400" : "bg-blue-300";
  };
  console.log("aaaaaaaaaaaaaaaaa");
  console.log(aaa());
  return (
    <FullScreen handle={handle} onChange={set_s_full}>
      <PageHeader
        title="數位生產看板"
        extra={[
          <Box title="開線率" value={s_boxData.openLineRate || 0} key="開線率" />,
          <Box title="開線數" value={s_boxData.openLine || 0} key="開線數" />,
          <Box title="總線數" value={s_boxData.allLine || 0} key="總線數" />,
          !s_full && (
            <Select
              key="ps"
              className="w-[200px]"
              size="large"
              value={s_psID}
              onChange={(value) => set_s_psID(value)}
              options={psOptions}
            />
          ),
          s_full ? (
            <Button key="close" size="large" onClick={closeFullScreen}>
              <FullscreenExitOutlined />
            </Button>
          ) : (
            <Button key="open" size="large" onClick={openFullScreen}>
              <FullscreenOutlined />
            </Button>
          ),
          ,
        ]}
      />

      <CustomTable
        pagination={false}
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        fullScreen
      />
    </FullScreen>
  );
}
