import { Button, Col, Form, Input, Row, Select, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { login } from "@/service/apis/ADM/publicAPI";
import loginSvg from "@/assets/img/loginbg.svg";
// import logo from "@/assets/img/logo_company.png"; // relative path to image
import logo from "@/assets/img/SAT-logo.png"; // relative path to image
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${loginSvg});
  background-size: cover;
  background-position: center 110px;
  background-position: left;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1440px) {
    width: 100%;
  }
`;
const Box = styled.div`
  position: absolute;
  top: 130px;
  left: 70px;
  width: 650px;
  height: 70%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;
const Title = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 12px;
  font-family: Microsoft JhengHei;
  text-align: center;
`;

function Login() {
  const [form] = Form.useForm();

  const [buttonLodaing, setButtonLoading] = useState(false);

  const onFinish = async (values) => {
    setButtonLoading(true);
    console.log(values);
    Cookies.set("plantID", values.plantID);
    const res = await login(values);
    setButtonLoading(false);
    // Cookies.set("plantID", "ZK");
    // Cookies.set("plantNM", "兆科");
    if (res.status) {
      window.location.assign("/bdm/pn-manage");
    } else {
      console.log("values = ", values);
      console.log("res = ", res);
      message.error(res.msg);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      plantID: "ZK",
    });
  }, []);

  return (
    <Wrapper>
      <Form onFinish={onFinish} form={form} layout="vertical" size="large">
        <Row
          gutter={[12, 24]}
          style={{ backgroundColor: "rgba(255,255,255,0.3)" }}
          className="w-[500px] 2xl:w-[600px] rounded-xl py-24 px-12 ml-[70px]"
        >
          <Col span={24} className="flex-center">
            <h1 className=" text-white text-5xl font-extrabold">兆科检测</h1>
          </Col>
          <Col span={24}>
            <Title>检测中心运营系统</Title>
          </Col>

          <Col span={24}>
            <Form.Item
              name="account" // account
              rules={[{ required: true, message: "請輸入正確的用戶名或郵件" }]}
            >
              <Input
                placeholder="請輸入帳號"
                prefix={<UserOutlined className="text-xl mr-5 text-zinc-400	" />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="pwd" rules={[{ required: true, message: "請輸入正確的密碼" }]}>
              <Input.Password
                placeholder="請輸入密碼"
                prefix={<LockOutlined className="text-xl mr-5 text-zinc-400	" />}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="plantID" rules={[{ required: true, message: "请选择厂别" }]}>
              <Select
                placeholder="请选择厂别"
                options={[
                  { label: "测试区", value: "dev" },
                  { label: "正式区", value: "ZK" },
                ]}
                onChange={(e, data) => {
                  Cookies.set("plantID", data.value);
                  Cookies.set("plantNM", data.label);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#5DC8FF", color: "#FFF", boxShadow: "none" }}
                block
                loading={buttonLodaing}
              >
                Log in
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}
export default Login;
