import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { Button, Input } from "antd";
import { useRef, useState } from "react";

import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import Dept from "./Dept";
import JobTitle from "./JobTitle";
import PageHeader from "@/components/PageHeader";
import Plant from "./Plant";
import Unit from "./Unit";
import { compareLangauge } from "@/util/compareLangauge";
import { useTranslation } from "react-i18next";

export default function PlantManage() {
  const plant_ref = useRef(null);
  const dept_ref = useRef(null);
  const jobTitle_ref = useRef(null);
  const unit_ref = useRef(null);
  const { t } = useTranslation();
  const [s_searchData, set_s_searchData] = useState({
    item: null,
    page: 1,
    pageSize: 100,
  });
  const [s_tabKey, set_s_tabKey] = useState("plant"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({
      item: undefined,
      page: 1,
      pageSize: 100,
    });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    s_tabKey === "plant" && plant_ref.current.onSearch();
    s_tabKey === "unit" && unit_ref.current.onSearch();
    s_tabKey === "dept" && dept_ref.current.onSearch();
    s_tabKey === "jobTitle" && jobTitle_ref.current.onSearch();
  };
  const onCreate = () => {
    s_tabKey === "plant" && plant_ref.current.onCreate();
    s_tabKey === "unit" && unit_ref.current.onCreate();
    s_tabKey === "dept" && dept_ref.current.onCreate();
    s_tabKey === "jobTitle" && jobTitle_ref.current.onCreate();
  };

  const tabsData = [
    {
      key: "plant",
      label: t("ADM.plant-manage.tabPlant"), //"廠區主檔",
      children: (
        <Plant ref={plant_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
    {
      key: "dept",
      label: t("ADM.plant-manage.tabDept"), //"部門主檔",
      children: (
        <Dept ref={dept_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
    {
      key: "jobTitle",
      label: t("ADM.plant-manage.tabPost"), //"職稱主檔",
      children: (
        <JobTitle
          ref={jobTitle_ref}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "unit",
      // label: t("ADM.function-params-manage.unitSetting"), //"計量單位設定",
      label: t("ADM.function-params-manage.unit"), //"計量單位設定",

      children: (
        <Unit ref={unit_ref} s_searchData={s_searchData} set_s_searchData={set_s_searchData} />
      ),
    },
  ];

  const searchBasic = [
    <Input
      key="s_searchData.item"
      value={s_searchData.item}
      onChange={(e) => handlChange("item", e.target.value)}
      placeholder={t("util.search")}
      className="w-[300px]"
    />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   {t("util.search")}
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    //    <PlusOutlined />
    // </Button>,
    <SearchButton type="primary" key="search" onClick={onSearch} />,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];

  return (
    <>
      <PageHeader title={t("util.nav_D-1")} extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
}
