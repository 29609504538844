import React, { useEffect } from "react";
import { Form, Input, Col, Row, Button, Select, message } from "antd";
import { useTranslation } from "react-i18next";

// API
import useAPI from "@/hooks/useAPI";
import { create } from "@/service/apis/BPM/Dsevent"
import { getSignLevel } from "@/service/apis/BPM/SignLevel";
const { TextArea } = Input;

const EditControl = (props) => {
  const { c_lightData, set_is_showEdit } = props;
  const { content, keynote } = c_lightData;

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const f_flowF = Form.useWatch("flowF", form);
  const f_N = Form.useWatch("N", form);

  const call_create = useAPI(create);
  const call_getSignLevel = useAPI(getSignLevel);


  useEffect(() => {
    if (Object.keys(c_lightData).length > 0) {
      form.setFieldsValue(c_lightData)
      return
    } else {
      form.setFieldValue("flowF", "")
    }

  }, [c_lightData])



  useEffect(() => {
    // 取得權限設定
    call_getSignLevel.request();
    form.setFieldsValue({
     isurgent:{ value: false, label: "否" }
    })
  }, []);

  useEffect(() => {
    if (call_create.status === "suc") {
      message.success(call_create.msg);
      set_is_showEdit((previousValue) => !previousValue);
    } else if (call_create.status === "err") {
      message.error(call_create.msg);
    }

  }, [call_create.status])

  console.log(call_create.status);

  const onFinish = (values) => {

    console.log("Success:", values);

    const selectData =
      call_getSignLevel.data.filter
        (data => (data.signlevelUUID === values.run00))

    const newValues = {
      run00: selectData[0],
      run10: values
    }
    console.log(newValues);
    call_create.request(newValues)
  };
  const SelectOption = call_getSignLevel.data.map(data =>
  ({
    label: `${t(`BPM.util.opfunction_${data.opfunction}`)}
    -${t(`BPM.util.optype_${data.optype}`)}`,
    value: data.signlevelUUID
  })
  )
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const buttonBasicStyleStyle = "px-8";


  const handleClick = (type) => {
    form.setFieldValue("flowF", type);
  };

  const renderButtonType = (type) => {
    return f_flowF === type ? "primary" : "default"
  }
  return (
    <>
      <h2 className="my-7">國外採購-廠商名稱</h2>

      <Form
        name="basic"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

        <Form.Item name="flowF" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="N" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="run00" label="簽核權限" labelCol={{ style: { alignSelf: "center" } }} >
          <Select
            disabled={f_N?.length > 0}
            size="large"
            options={SelectOption} />
        </Form.Item>
        <Form.Item
          label="簽呈主旨"
          name="keynote"
          labelCol={{ style: { alignSelf: "center" } }}
        >
          <TextArea
            rows={2}
            placeholder={keynote}
            value={keynote}
            disabled={f_N?.length > 0} />
        </Form.Item>

        <Form.Item
          label="簽呈內容"
          name="content"
          labelCol={{ style: { alignSelf: "center" } }}
        >
          <TextArea
            rows={6}
            placeholder={content}
            value={content}
            disabled={f_N?.length > 0} />
        </Form.Item>

        <Form.Item
          label="簽呈備註"
          name="signedRemark"
          labelCol={{ style: { alignSelf: "center" } }}
        >
          <TextArea
            disabled={Object.keys(c_lightData).length === 0}
            rows={6} />
        </Form.Item>

        <Row gutter={[12, 12]}>

          <Col span={12}>

            <Form.Item
              label="指定對象"
              name="assignedTo"
              labelCol={{ style: { alignSelf: "center" } }}
            >
              <Select
                disabled={f_N === undefined}
                size="large"
                options={[{ value: "lucy", label: "Lucy" }]} />
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item label="是否緊急"
              name="isurgent"
              labelCol={{ style: { alignSelf: "center" } }}
            >

              <Select
                size="large"
                defaultValue="否"
                options={[{ value: true, label: "是" }, { value: false, label: "否" }]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row justify="space-between" gutter={[12, 12]} className="mt-14">
          <Col span={18} className=" flex justify-start gap-2">
            <Button
              type={renderButtonType("create")}
              disabled={f_N?.length > 0}
              danger
              className={`${buttonBasicStyleStyle}`} // 基礎樣式
              onClick={() => handleClick("create")}
            >
              立案
            </Button>
            <Button
              type={
                renderButtonType("agree")
              }
              danger
              className={`${buttonBasicStyleStyle}`}
              onClick={() => handleClick("agree")}
            >
              同意
            </Button>
            <Button
              type={
                renderButtonType("reject")
              }
              danger
              className={`${buttonBasicStyleStyle}`}
              onClick={() => handleClick("reject")}
            >
              退回
            </Button>
            <Button
              type={
                renderButtonType("cancel")
              }
              danger
              className={`${buttonBasicStyleStyle}`}
              onClick={() => handleClick("cancel")}
            >
              撤案
            </Button>

          </Col>

          <Col span={6} className="flex justify-end ">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                確認送出
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditControl;
