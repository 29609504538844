/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, message, Space, Button } from "antd";
import {
	ExclamationCircleOutlined,
	FullscreenOutlined,
	FullscreenExitOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconConfirm } from "@/components/Icon/Action";
import PageHeader from "@/components/PageHeader/index";
import { formatAmount } from "@/util/format";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import useAPI from "@/hooks/useAPI";
import { getTxnTb, deleteTb, confirm } from "@/service/apis/WMS/WMS";
import ModalContainer from "./ModalContainer";

const TB = ({ c_lightData, set_c_lightData, hdRef, c_searchData }) => {
	const { t } = useTranslation();

	const call_getTxnTb = useAPI(getTxnTb);
	const call_deleteTb = useAPI(deleteTb);
	const call_confirm = useAPI(confirm);

	const [s_tableData, set_s_tableData] = useState([]);

	const [s_showModal, set_s_showModal] = useState(false); // 是否開啟 dialog

	const [s_editData, set_s_editData] = useState({});

	const tableColumns = [
		{
			title: t("util.SN"), //序,
			dataIndex: "SN",
			align: "center",
			width: '3%'
		},
		{
			title: t("util.PN"),//料號
			dataIndex: "PN",
			width: "15%",
			align: "center",
		},
		{
			title: t("util.pdtNM"),//品名
			dataIndex: "pdtNM",
			width: "20%",
			align: "center"
		},
		{
			title: t("util.whID"),//倉別
			dataIndex: "whID",
			align: "center",
			render: (_, record) => `${record.whID} -${record.whNM}`,
			width: "10%"
		},
		{
			title: t("util.idmtype"),//過帳型態
			dataIndex: "idmtype",
			align: "center",
			render: (text, rowData, index) => {
				const keyMap = {
					S: t("util.idmtype_s"),
					D: t("util.idmtype_d"),
					M: t("util.idmtype_m"),
				};
				return keyMap[text];
			},
		},
		{
			title: t("OMS.util.batchno"),//批號
			dataIndex: "batchno",
			align: "center",

		},
		{
			title: t("util.planqty"),//計畫數量
			dataIndex: "planqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("util.tqty"),//當前總數
			dataIndex: "tqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("util.sqty"),//人工輸入
			dataIndex: "sqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("util.dqty"),//條碼掃入
			dataIndex: "dqty",
			align: "right",
			render: (text) => formatAmount(text),
		},
		{
			title: t("util.lifeF"),//歷程
			dataIndex: "lifeF",
			align: "center",
			render: (text) => t(`WMS.util.lifeF_${text}`),
			onCell: (rowData) => ({ className: `lifeF-${rowData.lifeF}` }),
		},
		{
			title: t("util.action"),//操作
			dataIndex: "action",
			align: "center",
			render: (_, rowData) => (
				<Space className=" font-size-24">
					<IconConfirm
						disabled={rowData.lifeF !== "1"}
						onClick={() => call_confirm.request({
							...rowData,
							opcode: c_searchData.opcode
						})}
					/>
					<IconPen
						disabled={rowData.lifeF === "2" || rowData.lifeF === "T"}
						onClick={() => editData(rowData)}
					/>
				</Space>
			),
		},
	];
	const editData = (rowData) => {
		set_s_editData(rowData);
		set_s_showModal(true);
	};

	const deleteData = (rowData) => {
		Modal.confirm({
			title: t(`WMS.util.opcode_${c_lightData.opcode}`) + "明細刪除確認",
			icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
			content: "請確認您希望删除此資料",
			okText: t("PMS.util.submit"), // 確定
			cancelText: t("PMS.util.cancel"), // 取消
			onOk: () => call_deleteTb.request({ ...rowData, opcode: c_lightData.opcode }),
		});
	};

	// 關閉明細資料
	const handleClose = () => {
		set_c_lightData({});
	};

	useEffect(() => {
		call_getTxnTb.request(c_lightData);
	}, [c_lightData]);

	// 取得表身資料
	useEffect(() => {
		if (call_getTxnTb.status === "suc") {
			set_s_tableData(call_getTxnTb.data.map((x, i) => ({ ...x, key: i, SN: i + 1 })));

		} else if (call_getTxnTb.status === "err") {
			set_s_tableData([]);
			message.error(call_getTxnTb.msg);
		}
	}, [call_getTxnTb.status]);

	// 刪除表身資料
	useEffect(() => {
		if (call_deleteTb.status === "suc") {

			// 刪除成功後要重抓表身資料
			call_getTxnTb.request(c_lightData);
			hdRef.Call_hd();
		} else if (call_deleteTb.status === "err") {
			message.error(call_deleteTb.msg);
		}
	}, [call_deleteTb.status]);

	useEffect(() => {
		if (call_confirm.status === "suc") {
			call_getTxnTb.request(c_lightData);
			hdRef.Call_hd();
		} else if (call_confirm.status === "err") {
			message.error(call_confirm.msg);
		}
	}, [call_confirm.status]);
	console.log(s_tableData);
	return (
		<>
			<PageHeader
				title={t(`WMS.util.opcode_${c_lightData.opcode}`) + " " + t(`util.detail`)}
				extra={[
					<Button key="hidden" danger icon={<FullscreenExitOutlined />} onClick={handleClose}>
						{t("util.hide_detail")}
					</Button>,
				]}
			/>
			<CustomTable
				columns={tableColumns}
				dataSource={s_tableData}
				tbMode={Object.keys(c_lightData).length > 0}
				pagination={false}
			/>

			<CustomModal
				width="60%"
				title={"編輯" + t(`WMS.util.opcode_${c_lightData.opcode}`) + "明細"}
				open={s_showModal}
				onCancel={() => set_s_showModal(false)}
				afterClose={() => {
					hdRef.Call_hd();
				}}
			>
				<ModalContainer
					set_s_showModal={set_s_showModal}
					s_editData={s_editData}
					c_lightData={c_lightData}
				/>
			</CustomModal>
		</>
	);
};

export default TB;
