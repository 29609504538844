import React, { useContext } from "react";
import { Row, Col, Typography, Divider } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// component
import { PrintText } from "./index";
// logo
// import LogoImg from "@/assets/img/print_logo.png";
import YUJU from "@/assets/icon/PMS/YUJU.svg"


//寬度參照表，有些欄寬是兩個欄位寬度相加
const width = {
  no: 3,
  item_kode: 17,
  specification: 30,
  unit: 6,
  demandqty: 14,
  issuedqty: 14,
  note: 16,
};

const Header = ({ hd_values, currentPage, totalPages }) => {
  const { t } = useTranslation();

  // 人員資料
  const { getStaffName } = useContext(ERPContext);

  return (
    <div className="flex flex-col w-full">

      {/* 頁首 資訊 */}
      <div className="flex justify-start  w-full">
        <div className="flex justify-between items-center w-full ">
          <div className="flex justify-start items-center w-1/2 ">PUR-FM-L01</div>
          <div className="flex justify-end items-center w-1/2 ">No. BPPB  : 033 / PURC / 10 / 2022</div> {/* （請購單號） */}
        </div>
      </div>

      <div className="w-full h-6" />

      <div className="flex justify-between items-center w-full" >
        <div className="flex justify-center items-center" >
          <img src={YUJU} className="w-[30px] h-[30px]" />
          <span className="ml-[10px]">PT. YUJU INDONESIA</span>
        </div>

        <div className="flex justify-center items-center" >
          <PrintText>{`page ${currentPage}/${totalPages}`}</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center w-full text-[8px] mt-[5px]">
        <div className="origin-top-left scale-[.75] whitespace-nowrap">Kawasan Industri NEWTON TECHNO PARK JL. JATI I BLOK J5 - NO. 5 LIPPO CIKARANG - BEKASI Telp ( 899 - 01460 - 62 ) Fax ( 899 - 01763 )</div>
      </div>

      {/* 領料單 標題 */}
      <div className="flex justify-center items-center  mb-4 w-full">
        <PrintText className="text-[28px] tracking-[2px]">生產借出歸還單</PrintText>
      </div>


      {/* 表頭開始 */}
      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>歸還單號:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>20230523001</PrintText>
        </div>
        <div className="flex justify-start items-center w-[14%]">
          <PrintText>借出單號:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >20230524</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>借出日期:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>20230524</PrintText>
        </div>

        <div className="flex justify-start items-center w-[14%]">
          <PrintText>預計歸還日期:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >20230524</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>申請部門(人員):</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>王大明</PrintText>
        </div>
        <div className="flex justify-start items-center w-[14%]">
          <PrintText>實際歸還日期:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >20230525</PrintText>
        </div>
      </div>


      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>借出倉:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>原料倉</PrintText>
        </div>
        <div className="flex justify-start items-center w-[14%]">
          <PrintText>歸還倉:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >原料倉2</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>聯絡人:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>張大俠</PrintText>
        </div>
        <div className="flex justify-start items-center w-[14%]">
          <PrintText>聯絡電話:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >0912345678</PrintText>
        </div>
      </div>


      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[20%]" >
          <PrintText>地址:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[50%]">
          <PrintText>新北市土城區水源路111號</PrintText>
        </div>
        <div className="flex justify-start items-center w-[14%]">
          <PrintText>運送方式:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[16%]">
          <PrintText >貨運</PrintText>
        </div>
      </div>
    </div>
  );
};

export default Header;
