import { Select, Form, InputNumber, Input, Col } from "antd";

const textColor = [
  { label: "黑", value: "#000000" },
  { label: "白", value: "#ffffff" },
];

const borderLabel = (field) => (
  <>
    <Col span={6}>
      <Form.Item label="長度" name={[field.name, "len"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="角度" name={[field.name, "angle"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" max={360} />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="粗度" name={[field.name, "b"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="顏色" name={[field.name, "c"]} rules={[{ required: true }]}>
        <Select options={textColor} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="X" name={[field.name, "x"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>

    <Col span={6}>
      <Form.Item label="Y" name={[field.name, "y"]} rules={[{ required: true }]}>
        <InputNumber min={0} className="w-full" />
      </Form.Item>
    </Col>
  </>
);

const LabelBorder = { borderLabel };

export default LabelBorder;
