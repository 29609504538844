/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Typography,
  Radio,
  message,
  InputNumber,
  Input,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
import { addSignLevel, updateSignLevel } from "@/service/apis/BPM/SignLevel";

const { Text } = Typography;

const ModalContainer = ({ set_is_showModal, s_editData, set_s_editData, arrMap }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const f_signmode = Form.useWatch("signmode", form);

  const call_addSignLevel = useAPI(addSignLevel);
  const call_updateSignLevel = useAPI(updateSignLevel);

  const onFinish = async (values) => {
    const type = Object.keys(s_editData).length === 0 ? "create" : "edit";
    if (values.open && values.open.length > 0) {
      values.open = values.open?.join("|") ?? "";
    }
    if (values.check && values.check.length > 0) {
      values.check = values.check?.join("|") ?? "";
    }
    if (values.review && values.review.length > 0) {
      values.review = values.review?.join("|") ?? "";
    }
    if (values.approve && values.approve.length > 0) {
      values.approve = values.approve?.join("|") ?? "";
    }
    if (type === "create") {
      call_addSignLevel.request(values);
    }

    if (type === "edit") {
      call_updateSignLevel.request(values);
    }
  };

  // useEffect(() => {
  //   form.setFieldsValue(s_editData);
  // }, []);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     "open": [],
  //     "check": [],
  //     "review": [],
  //     "approve": [],
  //   });
  // }, [f_signmode]);

  useEffect(() => {
    const { status, msg, data } = call_addSignLevel;
    if (status === "suc") {
      message.success(msg);
      set_s_editData({});
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_editData({});
      set_is_showModal(false);
    }
  }, [call_addSignLevel.status]);

  useEffect(() => {
    const { status, msg, data } = call_updateSignLevel;
    if (status === "suc") {
      message.success(msg);
      set_s_editData({});
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_editData({});
      set_is_showModal(false);
    }
  }, [call_updateSignLevel.status]);

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      initialValues={{
        isvalid: true,
        "open": [],
        "check": [],
        "review": [],
        "approve": [],
        ...s_editData,
      }}
    >
      {/* 影藏欄位 */}
      <div>
        <Form.Item name="signlevelUUID" hidden>
          <Input />
        </Form.Item>
      </div>
      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("BPM.sign-level.opfunction")}</Text>} // 營運功能
            name="opfunction"
            rules={[{ required: true }]}
            messageVariables={{ label: t("BPM.sign-level.opfunction") }}
          >
            <Select
              allowClear
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.opfunction")}
              options={arrMap.opfunctionMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("BPM.sign-level.optype")}</Text>} // 作業類別
            name="optype"
            rules={[{ required: true }]}
            messageVariables={{ label: t("BPM.sign-level.optype") }}
          >
            <Select
              allowClear
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.optype")}
              options={arrMap.optypeMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("BPM.util.signmode")}</Text>} // 簽核層級
            name="signmode"
            rules={[{ required: true }]}
            messageVariables={{ label: t("BPM.util.signmode") }}
          >
            <Select
              allowClear
              placeholder={t("util.placeholder_select") + t("BPM.util.signmode")}
              onChange={() => {
                form.setFieldsValue({
                  "open": [],
                  "check": [],
                  "review": [],
                  "approve": [],
                });
              }}
              options={[
                { label: `2${t("BPM.util.level")}`, value: 2 },
                { label: `3${t("BPM.util.level")}`, value: 3 },
                { label: `4${t("BPM.util.level")}`, value: 4 },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("BPM.util.permissions") + "_" + t("BPM.util.open")}</Text>} // 立案
            name="open"
            rules={[{ required: true }]}
            messageVariables={{ label: t("BPM.util.open") }}
          >
            <Select
              allowClear
              mode="tags"
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.opfunction")}
              options={arrMap.roleMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Text disabled={f_signmode !== 4}>
                {t("BPM.util.permissions") + "_" + t("BPM.util.check")}
              </Text>
            } // 權限_查權
            name="check"
            rules={[{ required: f_signmode === 4 }]}
            messageVariables={{ label: t("BPM.util.check") }}
          >
            <Select
              disabled={f_signmode !== 4}
              allowClear
              mode="tags"
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.opfunction")}
              options={arrMap.roleMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={
              <Text disabled={f_signmode === 2}>
                {t("BPM.util.permissions") + "_" + t("BPM.util.review")}
              </Text>
            } // 權限_審權
            name="review"
            rules={[{ required: f_signmode !== 2 }]}
            messageVariables={{ label: t("BPM.util.review") }}
          >
            <Select
              disabled={f_signmode === 2}
              allowClear
              mode="tags"
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.opfunction")}
              options={arrMap.roleMap}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label={<Text>{t("BPM.util.permissions") + "_" + t("BPM.util.approve")}</Text>} // 權限_核權
            name="approve"
            rules={[{ required: true }]}
            messageVariables={{ label: t("BPM.util.approve") }}
          >
            <Select
              allowClear
              mode="tags"
              placeholder={t("util.placeholder_select") + t("BPM.sign-level.opfunction")}
              options={arrMap.roleMap}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form.Item label={<Text>{t("util.note")}</Text>} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>

      <Divider className="my-2" />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton
            onClick={() => {
              set_is_showModal(false);
              set_s_editData({});
            }}
          />
          {/* 確定 */}
          <ConfirmButton type="primary" htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
