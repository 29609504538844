import React, { useContext } from "react";
import { Row, Col, Typography, Divider } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// component
import { PrintText } from "./index";
// logo
// import LogoImg from "@/assets/img/print_logo.png";
import YUJU from "@/assets/icon/PMS/YUJU.svg"


//寬度參照表，有些欄寬是兩個欄位寬度相加
const width = {
  no: 4,
  item_kode: 18,
  specification: 30,
  stn: 10,
  price: 10,
  amount: 15,
  remark: 13,
};

const Header = ({ hd_values, currentPage, totalPages }) => {
  const { t } = useTranslation();

  // 人員資料
  const { getStaffName } = useContext(ERPContext);

  return (
    <div className="flex flex-col w-full">

      {/* 頁首 資訊 */}
      <div className="flex justify-start  w-full">
        <div className="flex justify-between items-center w-full ">
          <div className="flex justify-start items-center w-1/2 ">PUR-FM-L01</div>
          <div className="flex justify-end items-center w-1/2 ">No. BPPB  : 033 / PURC / 10 / 2022</div> {/* （請購單號） */}
        </div>
      </div>

      <div className="w-full h-6" />

      <div className="flex justify-between items-center w-full" >
        <div className="flex justify-center items-center" >
          <img src={YUJU} className="w-[30px] h-[30px]" />
          <span className="ml-[10px]">PT. YUJU INDONESIA</span>
        </div>

        <div className="flex justify-center items-center" >
          <PrintText>{`page ${currentPage}/${totalPages}`}</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center w-full text-[8px] mt-[5px]">
          <div className="origin-top-left scale-[.75] whitespace-nowrap">Kawasan Industri NEWTON TECHNO PARK JL. JATI I BLOK J5 - NO. 5 LIPPO CIKARANG - BEKASI Telp ( 899 - 01460 - 62 ) Fax ( 899 - 01763 )</div>
      </div>

      {/* 採購單 標題 */}
      <div className="flex justify-center items-center  mb-4 w-full">
        <PrintText className="text-[28px] tracking-[2px]">PURCHASE ORDER</PrintText>
      </div>


      {/* 表頭開始 */}
      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[22%]" >
          <PrintText>NO. SUPPLIER:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[40%]">
          <PrintText>{hd_values.suppID}</PrintText>
        </div>
        <div className="flex justify-start items-center w-[10%]">
          <PrintText>PO NO.:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[28%]">
          <PrintText >{hd_values.dealN}</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[22%]" >
          <PrintText>SUPPLIER:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[40%]">
          <PrintText>{hd_values.suppNM}</PrintText>
        </div>
        <div className="flex justify-start items-center w-[10%]">
          <PrintText>DATE:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[28%]">
          <PrintText>{dayjs(hd_values.createT).format("YYYY-MM-DD")}</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[22%]" >
          <PrintText>CONTACT PERSON:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[40%]">
          <PrintText>{hd_values.dealcontact}</PrintText>
        </div>
        <div className="flex justify-start items-center w-[10%]">
          <PrintText>DELIVERY:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[28%]">
          <PrintText>{dayjs(hd_values.startEDT).format("YYYY-MM-DD")}~{dayjs(hd_values.endEDT).format("YYYY-MM-DD")}</PrintText>
        </div>
      </div>

      <div className="flex justify-start items-center mb-2 w-full">
        <div className="flex justify-start items-center w-[22%]" >
          <PrintText>TELP / FAX NO:</PrintText>
        </div>
        <div className="flex justify-start items-center w-[40%]">
          <PrintText>{hd_values.dealtel}</PrintText>
        </div>
        <div className="flex justify-start items-center w-[10%]">
          {/* <PrintText>NO. WORK:</PrintText> */}
        </div>
        <div className="flex justify-start items-center w-[28%]">
          {/* <PrintText>???待確認欄位???</PrintText> */}
        </div>
      </div>

      {/* <div className="flex justify-end items-center mb-2 w-full">
        <div className="w-[28%]">
        &nbsp;<PrintText>PO NOVEMBER 2022(?待確認?)</PrintText>
        </div>

      </div> */}

    </div>
  );
};

export default Header;
