import React, { useEffect, useState } from "react";


import { Input, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import PageHeader from "@/components/PageHeader";
import { EditButton } from "@/components/BPM/Button";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomRangePicker from "@/components/BPM/CustomRangePicker";
import CustomSearchButton from "@/components/BPM/CustomSearchButton";
import CustomSelect from "@/components/BPM/CustomSelect";

// useAPI
import useAPI from "@/hooks/useAPI";
import { getHistory } from "@/service/apis/BPM/Dsevent";

const FinishDoc = ({ c_lightData }) => {
  const { t } = useTranslation();

  const call_getHistory = useAPI(getHistory);
  const [s_tableData, set_s_tableData] = useState([
    { opcode: "0000", N: "20230503001", key: 0, system: "PMS", lifeFT: "2023-05-04:12:00" },
    { opcode: "0000", N: "20230503002", key: 1, system: "OMS", lifeFT: "2023-05-04:12:00" },
    { opcode: "0000", N: "20230503003", key: 2, system: "BDM", lifeFT: "2023-05-04:12:00" },
  ]);


  useEffect(() => {
    call_getHistory.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    const { status, data, msg } = call_getHistory;

    if (status === "suc") {
      console.log("data = ", data);
      message.success(msg);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getHistory.status]);

  const tableColumns = [
    {
      title: t("BPM.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "15%",
    },
    {
      title: t("BPM.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "20%",
    },
    {
      title: t("BPM.dsevent.system"), // 系統模組
      dataIndex: "system",
      align: "center",
      width: "20%",
    },
    {
      title: t("BPM.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"), // 操作
      dataIndex: "action",
      width: "10%",
      render: (text, rowData, index) => (
        <div className="flex-center">
          <EditButton />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-col flex-1 p-3 shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] rounded-md">
        <PageHeader title="完成簽核文件" />

        <div className="flex flex-col gap-2">
          <CustomRangePicker
            className="w-full"
          // value={[s_searchConfig?.startDate, s_searchConfig?.endDate]}
          // onChange={(time, _) => handleChange("time", time)}
          />

          <Input.Group className="w-full flex" compact>
            <CustomSelect
              className="w-[150px]"
              // value={s_searchConfig.searchFlag}
              placeholder="搜尋欄位"
            // onChange={(value) => handleChange("searchFlag", value)}
            // options={[
            //   { value: "N", label: t("BPM.util.N") }, // 單號
            //   { value: "signmode", label: t("BPM.util.signmode") }, // 簽核層級
            // ]}
            />

            <CustomSelect
              showSearch
              // value={s_searchConfig.searchValue}
              className="flex-1"
            // placeholder={placeholder_map[s_searchConfig.searchFlag]}
            // 根據searchFlag判斷現在是哪個下拉選單
            // options={s_selectOptions[s_searchConfig.searchFlag]}
            />
            <CustomSearchButton onClick={() => alert("wait")} />
          </Input.Group>
        </div>

        <Divider />

        <CustomTable columns={tableColumns} dataSource={s_tableData} pagination={false} />
      </div>
    </>
  );
};

export default FinishDoc;
