import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const CustomSearchButton = ({ children, ...props }) => {
  return (
    <Button
      className="bg-gray-300 text-white hover:border-transparent"
      icon={<SearchOutlined />}
      {...props}
    />
  );
};

export default CustomSearchButton;
