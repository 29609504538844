/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { Grid, Space, Input, message } from "antd";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// util component
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import { CustomRangePicker, CustomSelect, CustomSearchButton } from "@/components/ERP/TabExtra";
import { ClearButton, EditButton, DetailButton, ExcelButton } from "@/components/ERP/Button";
// component
import DetailModal from "./Modals/DetailModal";
// context
import { DataContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { getCheckHD } from "@/service/apis/OMS/Check";
import { formatAmount } from "@/util/format";

const HD = (props) => {
  const { t } = useTranslation();
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭
  const call_getCheckHD = useAPI(getCheckHD);
  // useAPI 詳細搜尋取得表頭
  const call_getCheckHDAd = useAPI(getCheckHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();
  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG, detailSearch } = c_state;
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // CustomTab 要顯示的選項
  const tabsData = [
    { key: "4220", label: t("OMS.util.opcode_4220") }, // 銷售發貨單
    { key: "4130", label: t("OMS.util.opcode_4130") }, // 銷售退回單
    { key: "detailSearch", label: t("OMS.util.advanceSearch") }, // 進階查詢
  ];

  const placeholder_map = {
    jobN: t("util.placeholder_input") + t("OMS.util.jobN"), // 請輸入作業單號
    N13: t("util.placeholder_input") + t("OMS.util.N13"), // 請輸入銷貨單號
    N12: t("util.placeholder_input") + t("OMS.util.N12"), // 請輸入預訂單號
    batchno: t("util.placeholder_input") + t("OMS.util.batchno"), // 請輸入批號
  };

  // 控制詳細搜尋跳顯開啟、關閉、要搜尋的資料
  // const [s_detailSearch, set_s_detailSearch] = useState({
  //   show: false,
  //   data: {},
  // });

  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    console.log("tmp = ", tmp);
    call_getCheckHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };

  // 切換 Tab 時執行
  const handleChangeTab = (activeKey) => {
    updateState({
      tabKey: activeKey,
      opcode: activeKey === "detailSearch" ? "" : activeKey,
      searchConfig:
        activeKey === "detailSearch"
          ? { ...INIT_SEARCHCONFIG, searchValue: "" }
          : INIT_SEARCHCONFIG,
      lightData: {},
    });

    // 進階查詢會使用 detailConfig 搜尋，所以先清空表格資料
    if (activeKey === "detailSearch") {
      set_s_tableData([]);
    }
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getCheckHD.request({ ...tmp, opcode: tabKey });
    }
  };

  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getCheckHD.request({
      ...searchConfig,
      opcode: tabKey,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };

  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };

  // 第一次渲染取得表頭資料
  useEffect(() => {
    const TabKey = searchParams.get("TabKey");
    const jobN = searchParams.get("jobN");
    if (TabKey === null) {
      // console.log("tabKey = ", tabKey);
      if (tabKey === "detailSearch") {
        const result = {};
        for (const [key, value] of Object.entries(detailSearch.data)) {
          if (value) {
            result[key] = Array.isArray(value) ? value.join("|") : value;
          }
        }
        call_getCheckHDAd.request(result);
      } else {
        call_getCheckHD.request({
          ...searchConfig,
          opcode: tabKey,
          [searchConfig.searchFlag]: searchConfig.searchValue,
        });
      }
    } else {
      call_getCheckHD.request({
        // ...searchConfig,
        opcode: "4220|4130",
        tabKey: "detailSearch",
        jobN: jobN,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      updateState({ tabKey: "detailSearch" });
    }
    // 詳細搜尋 call 另一條 API
    // if (tabKey !== "detailSearch") {
    //   call_getCheckHD.request({
    //     ...searchConfig,
    //     opcode: tabKey,
    //     [searchConfig.searchFlag]: searchConfig.searchValue,
    //   });
    // }
  }, [tabKey]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getCheckHD;
    if (status === "suc") {
      console.log("data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.opUUID })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newCheck_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ check_Ns: newCheck_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCheckHD.status]);

  // useAPI 詳細搜尋取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getCheckHDAd;

    if (status === "suc") {
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.opUUID })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newCheck_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ check_Ns: newCheck_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCheckHDAd.status]);

  // 表格欄位
  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => {
        const map = {
          4220: t("OMS.util.opcode_4220"), // 銷售發貨單
          4130: t("OMS.util.opcode_4130"), // 銷售退回單
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8%" : "8%",
    },
    {
      title: t("OMS.util.custID"), // 客戶ID
      dataIndex: "custID",
      align: "center",
      width: screens.xxl ? "9%" : "9%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
      // width: screens.xxl ? '9%' : '9%',
    },
    {
      title: t("OMS.check.total"), // 移轉數量
      dataIndex: "total",
      align: "right",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.batchno"), // 批號
      dataIndex: "batchno",
      align: "center",
      width: screens.xxl ? "12%" : "12%",
    },
    {
      title: t("OMS.util.batchdate"), // 批號日期
      dataIndex: "batchdate",
      align: "center",
      width: screens.xxl ? "10%" : "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.check.stockinT"), // 移轉時間
      dataIndex: "stockinT",
      align: "center",
      width: screens.xxl ? "9%" : "9%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
      width: screens.xxl ? "7%" : "7%",
      render: (text) => {
        const map = {
          0: t("OMS.check.lifeF_0"), // 發佈
          T: t("OMS.check.lifeF_T"), // 結案
        };
        return map[text];
      },
    },
    {
      title: t("OMS.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
      width: screens.xxl ? "10%" : "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: screens.xxl ? "5%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];

  return (
    <div className="flex-1">
      <PageHeader
        title={t("OMS.check.pageHeader")} // 出貨檢驗查詢
        extra={<TourButton />}
      />
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => handleChangeTab(activeKey)} // 切換 Tab 時觸發
        tabBarExtraContent={
          <Space>
            <CustomRangePicker
              disabled={tabKey === "detailSearch"}
              value={[searchConfig?.startDate, searchConfig?.endDate]}
              onCalendarChange={(dates, _, __) => {
                updateState({
                  searchConfig: {
                    ...searchConfig,
                    startDate: dates[0],
                    endDate: dates[1],
                  },
                });
              }}
            />

            <Input.Group compact>
              <CustomSelect
                disabled={tabKey === "detailSearch"}
                value={searchConfig.searchFlag}
                placeholder={t('util.search')}
                onChange={(value) => handleChange("searchFlag", value)}
                options={[
                  { value: "N", label: t("OMS.check.N") }, // 作業單號
                  { value: "N13", label: t("OMS.util.N13") }, // 銷貨單號
                  { value: "N12", label: t("OMS.util.N12") }, // 預訂單號
                  { value: "batchno", label: t("OMS.util.batchno") }, // 批號
                ]}
              />

              <Input
                disabled={tabKey === "detailSearch"}
                value={searchConfig.searchValue}
                className="w-[250px]"
                placeholder={placeholder_map[searchConfig.searchFlag]}
                onChange={(e) => handleChangeInput(e.target.value)}
              />

              <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
            </Input.Group>

            {/* 清除搜詢紀錄 */}
            <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
          </Space>
        }
      />

      {tabKey === "detailSearch" && (
        <Space className="w-full flex justify-end pb-2">
          <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
          <DetailButton
            onClick={() =>
              // set_s_detailSearch((prev) => ({ ...prev, show: true }))
              updateState({ detailSearch: { ...detailSearch, show: true } })
            }
          />
        </Space>
      )}

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getCheckHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.opUUID === lightData.opUUID ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 進階搜尋條件-跳顯 */}
      <CustomModal
        width="70%"
        title={t("OMS.util.setAdvanced")}
        open={detailSearch.show}
        onCancel={() => updateState({ detailSearch: { ...detailSearch, show: false } })}
      >
        {detailSearch.show && <DetailModal call_getCheckHDAd={call_getCheckHDAd} />}
      </CustomModal>
    </div>
  );
};

export default HD;
