import React, { useState, useEffect, useContext } from "react";
import { message, Select,  Button, Typography, Space,Spin} from "antd";
import * as XLSX from "xlsx";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
//util
import { formatAmount } from '@/util/format' //數字加上千分位
// util component
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import PNButton from "@/components/ERP/PNButton";
// context
import { ERPContext } from "@/components/Context/SystemContext";
//API
import useAPI from "@/hooks/useAPI";
import { getInvPrice } from "@/service/apis/WMS/Report";

const { Text } = Typography;


//庫存彙總表
const InstockSummary = () => {

  const { c_PNs } = useContext(ERPContext);  //從ERPContext拿到廠商資料

  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料

  const [is_spin,set_is_spin] = useState(false)

  const call_getInvPrice = useAPI(getInvPrice);
  const { t } = useTranslation();

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    PN: [], //料號
  });

  const tableColumns = [
    {
      title: "料號",
      dataIndex: "PN",
      align: "center",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
      align: "left",
      width: '20%'
    },
    {
      title: "單位",
      dataIndex: "unit",
      align: "center",
      width: '10%'
    },
    {
      title: "庫存數量",
      dataIndex: "tqty",
      align: "right",
      width: "15%",
      render: (text) => (<div className={`${text < 0 ? 'text-red-500' : null}`}>{formatAmount(text)}</div>)
    },
    {
      title: "平均成本",
      dataIndex: "avgcost",
      align: "right",
      width: '15%',
      render: (text) => formatAmount(text)
    },
    {
      title: "庫存成本",
      dataIndex: "invcost",
      align: "right",
      width: '15%',
      render: (text) => formatAmount(text)
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    console.log("attr = ",attr, "value = ",value);
    let tmp = { ...s_searchData, [attr]: value };
    set_s_searchData(tmp);
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = s_searchData.PN?.join("|"); //要將料號陣列轉成字串，用直槓隔開
    console.log("tmp", tmp);
    call_getInvPrice.request({PN:tmp});
  }

  useEffect(() => {
    console.log("s_searchData",s_searchData);
  },[s_searchData])

  //所有採購調幅資料
  useEffect(() => {
    const { status, msg, data } = call_getInvPrice;
    if (status === "suc") {
      console.log("call_getInvPrice = ", data);
      set_s_TableData(data);
      message.success(msg);
    } else if (status === "err") {
      set_s_TableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getInvPrice.status]);

  useEffect(() => {
    if(call_getInvPrice.status === 'load'){
      set_is_spin(true)
    }else {
      set_is_spin(false)
    }
  },[call_getInvPrice.status])

  //----------------------------EXCEL---------------------------------------------------

  const exportToExcel = () => {

    const excelTable = s_TableData.map((item) => {
      //將輸出excel前把所有數字欄位加上千分位，再將平均成本、庫存成本 取到小數點後第二位，
      // console.log({...item,tqty:formatAmount(item.tqty),avgcost:formatAmount(item.avgcost),invcost:formatAmount(item.invcost)});
      return {...item,tqty:formatAmount(item.tqty),avgcost:formatAmount(item.avgcost),invcost:formatAmount(item.invcost)}
    })

    const ws = XLSX.utils.json_to_sheet(excelTable);   //要轉出excel的資料
    const wb = XLSX.utils.book_new();                  //新增的活頁簿
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");    //活頁簿wb添加資料表Sheet1，資料為ws

    // 计算每列的最大宽度
    const colWidths = s_TableData[0] && Object.keys(s_TableData[0]).map(() => 0);
    s_TableData.forEach(row => {
      Object.keys(row).forEach((key, index) => {
        const cellValue = row[key].toString();
        console.log("cellValue",cellValue);
        console.log("cellValue.length",cellValue.length);
        if (cellValue.length > colWidths[index]) {
          colWidths[index] = cellValue.length ;
        }
      });
    });

    // 设置列宽
    const wscols = colWidths.map(w => ({ wch: w + 7 })); // 根据最大长度调整列宽
    ws['!cols'] = wscols;

    //根據tableColumns的title將表頭寫到excel

    for (let i = 0; i < tableColumns.length; i++) {
      ws[String.fromCharCode(65 + i) + "1"].v = tableColumns[i].title;  //excel從A欄開始，Unicode的65就是A
    }

    const today = dayjs().format("YYYY-MM-DD");
    //輸出excel，活頁簿為wb，輸出檔案名稱:file.xlsx(可自訂義)
    XLSX.writeFile(wb, `採購價格調幅表${today}.xlsx`);

  };

  //----------------------------EXCEL---------------------------------------------------


  return (
    <div className="flex flex-col flex-1 w-full">
      {/*表頭*/}

      <Spin spinning={is_spin} className="fixed top-2/4 left-2/4"/>

      <PageHeader title="庫存彙總表" extra={<TourButton />} />

      <Space className="flex justify-end gap-2 mb-2">

        {/*導出excel*/}

        
          <Button
            type="primary"
            onClick={() => exportToExcel()}
          >
            導出excel
          </Button> 
         

        <Text>料號：</Text>
        <Select
          mode='multiple'
          name='PN'
          className="min-w-[500px]"
          showSearch
          placeholder="請填寫料號"
          onChange={(value) => { handle_SearchData_Change("PN", value) }}
          options={c_PNs.options}
        />

        <PNButton />

        <Button type="primary"
        onClick={handleClick}
        >
          查詢
        </Button>
      </Space>

      {/*表格*/}
      <div className="flex flex-1">
        <CustomTable
          columns={tableColumns}
          dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
        />
      </div>
    </div>
  );
};


export default InstockSummary