import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
} from "@/components/ERP/Button";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import {
  addProHD,
  getAdd,
  getProADD,
  getProHD,
  getProTB,
  updateProHD,
} from "@/service/apis/PMS/Pro";

import ContactButton from "@/components/ERP/PMS/ContactButton";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../index";
// component
import DetailContainer from "./Details/DetailContainer";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FileModal from "./Modal/FileModal";
import HiddenFormItem from "./HiddenFormItem";
import INIT_DISABLED from "./initValues";
import InstockModal from "./Modal/InstockModal";
import PageHeader from "@/components/PageHeader";
import PrintDrawer from "./Modal/JUPrintDrawer/index";
import dayjs from "dayjs";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export const MainPageContext = React.createContext();

const { Text } = Typography;

const MainPage = () => {
  const { t } = useTranslation();
  // useAPI 取得表頭資料
  const call_getProHD = useAPI(getProHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getProHD = useAPI(getProHD);
  // useAPI 取得表身資料
  const call_getProTB = useAPI(getProTB);
  // useAPI 取得條款註記、加扣款項、預付作業
  const call_getProADD = useAPI(getProADD);
  // useAPI 取得下拉選單
  const call_getAdd = useAPI(getAdd);
  // 表身在編輯之前用來更新表頭
  const call_updateProHD = useAPI(updateProHD);
  // useAPI 取得上一筆資料
  const call_prev_updateProHD = useAPI(updateProHD);
  // useAPI 取得下一筆資料
  const call_next_updateProHD = useAPI(updateProHD);
  // 新建表頭資料
  const call_addProHD = useAPI(addProHD);

  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_suppID = Form.useWatch("suppID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  console.log("f_lifeF = ", f_lifeF);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  const f_currencytype = Form.useWatch("currencytype", form);
  const f_outsource = Form.useWatch("outsource", form);
  const f_outReport = Form.useWatch("outReport", form);
  const f_total = Form.useWatch("total", form);
  const f_sumtotal = Form.useWatch("sumtotal", form);
  // 用來紀錄表頭是否編輯過，以及編輯了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  // 廠商資料、人員資料
  const { c_userData } = useContext(SystemContext);
  const { c_supps, c_custs, c_staffs, c_currencys, c_taxtypes } = useContext(ERPContext);
  const { mainPage, pro_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  const [s_fileType, set_s_fileType] = useState("outsource");
  const [s_showFileModal, set_s_showFileModal] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 控制列印的 drawer
  const [is_showPrint, set_is_showPrint] = useState(false);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 是否顯示拋轉跳顯的開關
  const [is_showInstock, set_is_showInstock] = useState(false);
  // 表身資料
  const [s_proTb, set_s_proTb] = useState({
    tb: [],
    adda: [], // 條款註記
    addb: [], // 加扣款項
    addc: [], // 預付作業
  });
  // 下拉選單
  const [s_Adds, set_s_Adds] = useState({
    adda: [], // 條款註記下拉選單
    addb: [], // 加扣款項下拉選單
    addc: [], // 預付作業下拉選單
    addd: [], // 付款方式下拉選單
    adde: [], // 貿易條件下拉選單
  });

  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (editMode) {
      call_updateProHD.request({
        ...values,
        APImethod: "update",
      });
    } else {
      console.log("values = ", values);
      // return;
      call_addProHD.request({
        ...values,
        // 如果是據此創建就要連表身一起給
        tb: Object.keys(s_oriData).length > 0 ? s_proTb.tb : [],
      });
    }

    set_s_oriData({});
  };

  // 選擇幣別
  const currencytypeChange = (value) => {
    const currency_obj = c_currencys.data?.find((item) => {
      return item.currencyitem === value;
    });

    // 幣別如果是本幣，匯率要設成1
    if (currency_obj?.ismycurrency === true) {
      form.setFieldsValue({
        exchangerate: 1,
      });

      if (s_proTb.tb.length === 0) return;

      // 表身的本幣金額要重算
      calculate_localprice(1);
    }
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_proTb.tb.length === 0) return;

    const tb = s_proTb.tb.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_proTb({
      ...s_proTb,
      tb: tb,
    });
  };

  // 選擇稅別
  const taxtypeChange = (value) => {
    const tax_obj = c_taxtypes.data?.find((item) => item.taxtype === value);
    // 只有tax_obj.disabled為false才可以編輯
    set_s_disabled({
      ...s_disabled,
      taxrate: tax_obj.disabled,
    });

    // 將稅別的稅率塞進表單
    form.setFieldsValue({
      taxrate: tax_obj.taxrate,
    });

    // 稅別發生改變時要重新計算表身稅務資訊
    change_tb_tax(tax_obj.taxrate);
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_proTb.tb.map((item) => {
      // 單項金額 = (廠商確認數量-取消數量)*未稅單價，這邊的廠商確認數量預設使用採購數量
      const itemsum = (item.confirmqty - item.cancelqty - item.returnqty) * item.unitprice;
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_proTb({
      ...s_proTb,
      tb: tb,
    });
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      lifeF: "0",
      N: "",
      opUUID: "",
      preopUUID: "",
      proT: dayjs(),
      startEDT: dayjs(),
      endEDT: dayjs(),
      exchangeD: dayjs(),
      lifeFT: null,
      createT: null,
      approveT: null,
      signT: null,
      confirmID: "",
      confirmT: null,
    };
    console.log("new_Hd_Data = ", new_Hd_Data);
    // 重新 set formData
    form.setFieldsValue(new_Hd_Data);
    console.log("mainPage.data = ", mainPage.data);
    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_proTb,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    change_MainPage_disabled(new_Hd_Data);

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_proTb({
      ...s_proTb,
      tb: s_proTb.tb?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          balqty: item.demandqty,
          returnqty: 0,
          cancelqty: 0,
          transferqty: 0,
          lifeF: "0",
        };
      }),
    });
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);

    form.setFieldsValue({
      suppID: supp_obj.suppID,
      suppalias: supp_obj.suppalias,
      dealaddr: supp_obj.addr,
      contact: null, // 選擇新廠商時要清空業務窗口資料
      tel: null, // 選擇新廠商時要清空業務窗口資料
    });
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    console.log("hd_values.lifeF = ", hd_values.lifeF);
    // isNewdoc = true 新建表單
    // const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    // const isCreateByThis = Object.keys(s_oriData).length > 0;
    // 是否有上位資料(請購單)
    // const hasReq = hd_values.preopUUID?.length > 0;
    // 是否有下位資料(進料單)
    // const hasInstock = hd_values.instockCount > 0;
    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        dealN: false,
        payment: false,
        driID: false,
        //------------
        suppID: false,
        planday: false,
        proT: false,
        EDT: false,
        proCheck: false,
        //------------
        dealcontact: false,
        dealtel: false,
        note: false,
        // ------------ 表身 ------------
        tbSubmit: false,
        tb_edit: false, // 所有表身的編輯
        unitprice: false,
        tb_planday: false,
        tb_EDT: false,
      });
    }
    // 外包覆核
    if (hd_values?.lifeF === "1") {
      set_s_disabled({
        ...INIT_DISABLED,
        // proCheck: false, // 外測覆核
        proCheck: true, // 外測覆核
        completed: false, // 外測完成
      });
    }
    // 外測完成
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        // completed: false, // 外測完成
      });
    }
    // 結案
    if (hd_values.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
      });
    }
  };

  // 查詢上位資料
  const go_req = () => {
    const req_opcode = {
      2310: "2210",
      2320: "2220",
      2330: "2230",
    };
    const params_obj = {
      opcode: req_opcode[mainPage.data?.opcode],
      opUUID: mainPage.data?.preopUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/req?${params}`, "_blank");
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      // 目前資料為第一筆，已無上一筆資料
      message.success(t("PMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateProHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === pro_Ns.length) {
      // 目前資料為最後一筆，已無下一筆資料
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateProHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = pro_Ns.findIndex((item) => item === N);

    return index;
  };

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_proTb.tb?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總加扣款項
    const addb = s_proTb.addb?.reduce((prev, curr) => prev + (curr.amount || 0), 0) || 0;
    // 未稅總金額
    const sumtotal = itemtotal + addb;
    // 總稅額
    const tax = (sumtotal * f_taxrate) / 100;
    // 總金額 = 單項金額(未稅) + 總加扣款項 + 總稅額
    const total = itemtotal + addb + tax;

    form.setFieldsValue({
      charge: addb,
      itemtotal,
      sumtotal,
      tax,
      total,
    });
  };

  // 外測覆核 or 取消外測覆核
  const proCheck = () => {
    const values = form.getFieldsValue();

    if (values.suppID?.length === 0) {
      message.error("请选择厂商");
      return;
    }

    // -1: 沒有找到
    const isZero = s_proTb.tb.findIndex((item) => item.itemsum > 0);
    console.log("isZero = ", isZero);
    if (isZero === -1) {
      message.error("请输入检测费用");
      return;
    }

    Modal.confirm({
      title: `${
        f_lifeF === "1"
          ? t("PMS.util.cancel") + t("PMS.pro.lifeF_1") + t("util.ok")
          : t("PMS.pro.lifeF_2") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="color-red-500" />,
      content:
        f_lifeF === "1"
          ? t("PMS.pro.cancel_proCheck_confirm_text")
          : t("PMS.pro.proCheck_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_updateProHD.request({
          ...values,
          lifeF: f_lifeF === "0" ? "1" : "0",
          lifeFT: dayjs(),
          APImethod: f_lifeF === "0" ? "proCheck" : "cancelProCheck",
        });
      },
    });
  };

  // 外側完成
  const completed = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      title: `${
        f_lifeF === "1"
          ? t("PMS.util.cancel") + t("PMS.pro.lifeF_2") + t("util.ok")
          : t("PMS.pro.lifeF_2") + t("util.ok")
      }`,
      icon: <ExclamationCircleOutlined className="color-red-500" />,
      content: f_lifeF === "1" ? "外测完成确认" : "取消外测完成确认",
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_updateProHD.request({
          ...values,
          lifeF: f_lifeF === "1" ? "2" : "1",
          lifeFT: dayjs(),
          APImethod: f_lifeF === "1" ? "completed" : "cancelCompleted",
        });
      },
    });
  };

  const showOutsource = () => {
    const element = document.createElement("a");
    element.href = f_outsource;
    element.target = "_blank";
    // element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 取得下拉選單
    call_getAdd.request();

    // 新建採購單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});

      // 設定表單的起始值
      form.setFieldsValue({
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        // 匯率預設1
        exchangerate: 1,
        exchangeD: dayjs(),
        lifeF: "0",
        taxtype: "PPN_11%",
        taxrate: 11,
        trade: "FOB",
        payment: "銀行轉帳",
        // 預設登入人員
        createID: c_userData?.staffID,
        driID: c_userData?.staffID,
        proT: dayjs(),
        startEDT: dayjs(),
        endEDT: dayjs(),
        createT: dayjs(),
      });
    } else {
      // 取得表身【採購明細】
      call_getProTB.request({ opUUID: mainPage.data?.opUUID });
      // 取得【條款註記】、【加扣款項】、【預付作業】
      call_getProADD.request({
        "opUUID": mainPage.data.opUUID,
        "addtype": "addb",
      });

      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_updateProHD;
    if (status === "load") {
      set_s_spin(true);
    }
    if (status === "suc") {
      if (data.APImethod === "update") {
        // 更新表頭
        call_getProHD.request({ opUUID: data.opUUID });
        // 清空表頭編輯紀錄
        set_is_editForm({ isEdit: false, editFlag: [] });
        return;
      }
      if (data.APImethod === "proCheck") {
        // 更新表頭
        call_getProHD.request({ opUUID: data.opUUID });
        message.success(t("PMS.pro.lifeF_1") + t("util.success"));
        // 清空表頭編輯紀錄
        set_is_editForm({ isEdit: false, editFlag: [] });
        return;
      }
      if (data.APImethod === "cancelProCheck") {
        // 更新表頭
        call_getProHD.request({ opUUID: data.opUUID });
        message.success(t("OMS.util.cancel") + t("PMS.pro.lifeF_1") + t("util.success"));
        return;
      }
      if (data.APImethod === "completed") {
        // 更新表頭
        call_getProHD.request({ opUUID: data.opUUID });
        message.success(t("PMS.pro.lifeF_2") + t("util.success"));
        return;
      }
      if (data.APImethod === "cancelCompleted") {
        // 更新表頭
        call_getProHD.request({ opUUID: data.opUUID });
        message.success(t("util.cancel") + t("PMS.pro.lifeF_2") + t("util.success"));
        return;
      }
      set_s_spin(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_spin(false);
    }
  }, [call_updateProHD.status]);

  useEffect(() => {
    calculateMoneyInfo();
  }, [MainPage.data, f_exchangerate, f_taxrate, s_proTb.tb, s_proTb.addb]);

  // useAPI 取得下拉選單
  useEffect(() => {
    const { status, msg, data } = call_getAdd;
    if (status === "suc") {
      console.log("data = ", data);
      const { adda, addb, addc, addd, adde } = data;
      // format成下拉選單可用的
      const getOptions = (oriData) => {
        return (
          oriData?.map((item) => ({
            label: item.itemNM,
            value: item.itemNM,
          })) ?? []
        );
      };

      set_s_Adds({
        adda: getOptions(adda), // 條款註記下拉選單
        addb: getOptions(addb), // 加扣款項下拉選單
        addc: getOptions(addc), // 預付作業下拉選單
        addd: getOptions(addd), // 付款方式下拉選單
        adde: getOptions(adde), // 貿易條件下拉選單
      });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getAdd.status]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { data, status, msg } = call_getProHD;
    if (status === "load") {
      set_s_spin(true);
    }
    if (status === "suc") {
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      console.log("data.outsource = ", data[0].outsource);
      const newData = { ...data[0] };
      console.log("newData = ", newData);
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
      // 更新mainPage資料
      updateState({ mainPage: { show: true, data: newData } });
      set_s_spin(false);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_spin(false);
    }
  }, [call_getProHD.status]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getProTB;
    if (status === "suc") {
      const new_tb = data?.map((item, i) => ({ ...item, key: i }));
      set_s_proTb((prev) => ({
        ...prev,
        tb: new_tb,
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_proTb((prev) => ({
        ...prev,
        tb: [],
      }));
    }
  }, [call_getProTB.status]);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { status, msg, data } = call_getProADD;
    if (status === "suc") {
      set_s_proTb((prev) => ({
        ...prev,
        adda: data.adda ?? prev.adda, // 條款註記
        addb: data.addb ?? prev.addb, // 加扣款項
        addc: data.addc ?? prev.addc, // 預付作業
      }));
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProADD.status]);

  // useAPI 新建表頭資料
  useEffect(() => {
    const { status, data, msg } = call_addProHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getProHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getProTB.request({ opUUID: data.opUUID });
      }
      // // 清空表頭編輯紀錄
      // set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_addProHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateProHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // 取得上一筆資料成功
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getProHD.request({ N: pro_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      // 取得上一筆資料失敗
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateProHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateProHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      // 取得下一筆資料成功
      message.success(t("PMS.util.get_next_data_suc"));
      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getProHD.request({ N: pro_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      // 取得下一筆資料失敗
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateProHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getProHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getProTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getProHD.status]);

  // useAPI 取得表身資料 =>【採購明細】、【條款註記】、【加扣款項】、【預付作業】
  // useEffect(() => {
  //   const { status, msg, data } = call_getProTB;
  //   console.log("data = ", data);
  //   if (status === "suc") {
  //     set_s_proTb({
  //       tb: data?.tb ?? [],
  //       adda: data.adda ?? [],
  //       addb: data.addb ?? [],
  //       addc: data.addc ?? [],
  //     });
  //     message.success(msg);
  //   }

  //   if (status === "err") {
  //     set_s_proTb({
  //       tb: [],
  //       adda: [],
  //       addb: [],
  //       addc: [],
  //     });
  //     message.error(msg);
  //   }
  // }, [call_getProTB.status]);
  console.log("f_outsource = ", f_outsource);
  console.log("c_supps = ", c_supps.options);
  console.log("c_custs = ", c_custs.options);
  // console.log("c_custs = ", c_custs.options.);
  return (
    <MainPageContext.Provider
      value={{
        call_getProHD,
        call_getProTB,
        call_updateProHD,
        call_getProADD,
        is_editForm,
        set_is_editForm,
        s_proTb,
        set_s_proTb,
        s_disabled,
        s_Adds,
        hd_values: form.getFieldsValue(),
      }}
    >
      <div className="flex flex-col h-full">
        <Spin spinning={s_spin}>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            form={form}
            validateMessages={{
              required: t("PMS.util.placeholder_select") + "${label}",
            }}
            onValuesChange={(changedValues, _) => {
              if (editMode) {
                // 在編輯模式時紀錄表頭編輯了哪些欄位
                set_is_editForm((prev) => {
                  let editFlag = [...prev.editFlag];
                  const [key] = Object.keys(changedValues);

                  if (!editFlag.includes(key)) {
                    editFlag.push(key);
                  }
                  return {
                    isEdit: true,
                    editFlag,
                  };
                });
              }
            }}
          >
            {/* 表頭部分 */}
            <Row gutter={[12, 0]}>
              <Col span={8}>
                {/* 編輯外包檢測單、 新建外包檢測單 */}
                <PageHeader
                  title={
                    editMode
                      ? t("util.edit") + t("PMS.outsourcing.pageHeader")
                      : t("util.add") + t("PMS.outsourcing.pageHeader")
                  }
                />
              </Col>
              <Col span={16} className="flex justify-end">
                {/* 列印 */}
                {/* <PrintButton onClick={() => set_is_showPrint(true)} /> */}
                {/* 儲存 */}
                <SaveButton htmlType="submit" />
                {/* 上一筆 */}
                <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
                {/* 下一筆 */}
                <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
                {Object.keys(s_oriData).length > 0 && (
                  <CancelCreateButton
                    onClick={() => {
                      updateState({ mainPage: { show: true, data: s_oriData.hd } });
                      set_s_proTb(s_oriData.tb);
                      // 如果有日期格式就轉換成 moment
                      const newData = { ...s_oriData.hd };
                      Object.entries(s_oriData.hd).forEach(([key, value]) => {
                        if (isValidDate(value)) {
                          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                        }
                      });

                      form.setFieldsValue({
                        ...newData,
                      });
                      change_MainPage_disabled(newData);
                      set_s_oriData({});
                    }}
                  />
                )}
                {/* 回總表 */}
                <SummaryButton
                  onClick={() => {
                    updateState({ mainPage: { show: false, data: {} } });
                  }}
                />
              </Col>
            </Row>
            {/* 隱藏欄位 */}
            <HiddenFormItem />
            {/* 表單開始區域 */}
            <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
              {/* 單別 */}
              {/* <Col xl={editMode ? 2 : 3} xxl={editMode ? 2 : 3}>
                <Form.Item
                  name="opcode"
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("PMS.util.opcode") }}
                  label={<Text disabled={s_disabled.opcode}>{t("PMS.util.opcode")}</Text>}
                >
                  <Select
                    placeholder={t("PMS.util.opcode_placeholder")}
                    disabled={s_disabled.opcode}
                    options={[
                      { label: "外包項目", value: "2340" }, // 委外採購
                    ]}
                  />
                </Form.Item>
              </Col> */}
              {/* 單號 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="N"
                  label={
                    <Space>
                      <Text disabled>{t("PMS.util.N")}</Text>
                      {/* {mainPage.data.preopUUID && <Light linkType="trans" />} */}
                    </Space>
                  }
                >
                  <Input disabled className="border border-[#6A68A1] border-solid" />
                </Form.Item>
              </Col>
              {/* 廠商單號 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="dealN"
                  label={<Text disabled={s_disabled.dealN}>{t("PMS.pro.dealN")}</Text>}
                >
                  <Input
                    placeholder={t("PMS.pro.dealN_placeholder")}
                    disabled={s_disabled.dealN}
                    className="border border-[#6A68A1] border-solid"
                  />
                </Form.Item>
              </Col>
              {/* 付款方式 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="payment"
                  rules={[{ required: true }]}
                  messageVariables={{ label: t("PMS.pro.payment") }}
                  label={<Text disabled>{t("PMS.pro.payment")}</Text>}
                  // label={<Text disabled={s_disabled.payment}>{t("PMS.pro.payment")}</Text>}
                >
                  <Select
                    placeholder={t("PMS.pro.payment_placeholder")}
                    disabled
                    // disabled={s_disabled.payment}
                    options={s_Adds.addd}
                  />
                </Form.Item>
              </Col>
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="flex gap-3">
                {/* 負責採購 */}
                <Form.Item
                  label={<Text disabled={s_disabled.driID}>{t("PMS.pro.driID")}</Text>}
                  name="driID"
                  className="flex-1"
                >
                  <Select
                    placeholder={t("PMS.pro.driID_placeholder")}
                    disabled={s_disabled.driID}
                    showSearch
                    options={c_staffs.options}
                  />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* 據此創建 */}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    // disabled={editMode ? false : true}
                    disabled
                    onClick={createByThis}
                  >
                    {t("PMS.util.accord") + t("PMS.util.create")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 廠商簡稱 */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.suppID}>{t("PMS.util.suppNM")}</Text>}
                  name="suppNM"
                >
                  <Select
                    showSearch
                    disabled={s_disabled.suppID}
                    placeholder={t("PMS.util.suppID_placeholder")}
                    onChange={onSuppChange}
                    options={c_supps?.options?.concat(c_custs.options)}
                    filterOption={(input, option) => {
                      return ((option?.value ?? "") + (option?.label ?? "")).includes(input);
                    }}
                  />
                </Form.Item>
              </Col>

              {/* 廠商ID */}
              <Col xl={editMode ? 4 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  name="suppID"
                  label={<Text disabled>{t("PMS.util.suppID")}</Text>}
                  messageVariables={{ label: t("PMS.util.suppID") }}
                >
                  <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
                </Form.Item>
              </Col>

              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="flex gap-2">
                {/* 計畫工作日*/}
                <Form.Item
                  label={<Text disabled={s_disabled.startEDT}>{t("OMS.ord.planday")}</Text>}
                  name="planday"
                  className="flex-1"
                >
                  <Input className="w-full" disabled={s_disabled.planday} />
                </Form.Item>
                {/* 外包日期 */}
                <Form.Item
                  label={<Text disabled={s_disabled.proT}>外包日期</Text>}
                  name="proT"
                  className="flex-1"
                >
                  <DatePicker disabled={s_disabled.proT} className="w-full" />
                </Form.Item>
              </Col>
              {/* 預計交貨日 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6}>
                <Form.Item
                  label={<Text disabled={s_disabled.endEDT}>{t("OMS.util.EDT")}</Text>}
                  name="EDT"
                >
                  <DatePicker disabled={s_disabled.EDT} className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button className="w-full h-full" disabled>
                    {/* 廠商交易歷史查詢 */}
                    {t("PMS.pro.supp_history")}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 廠商地址 */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12}>
                <Form.Item
                  label={<Text disabled={s_disabled.dealaddr}>{t("PMS.pro.dealaddr")}</Text>}
                  name="dealaddr"
                >
                  <Input
                    disabled={s_disabled.dealaddr}
                    placeholder={t("PMS.util.suppID_placeholder")}
                  />
                </Form.Item>
              </Col>

              {/* 核准日期 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
                <Form.Item label={<Text disabled>{t("PMS.pro.approveT")}</Text>} name="approveT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {/* 簽回日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100 mt-1">
                <Form.Item label={<Text disabled>{t("PMS.util.signT")}</Text>} name="signT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  <Button
                    onClick={() => {
                      set_s_showFileModal(true);
                      set_s_fileType("outsource");
                    }}
                    className="flex-1 h-full pms-btn-blue-outline"
                  >
                    {/* 上傳簽呈 */}
                    上传外侧资料
                  </Button>

                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "1" ? "pms-btn-orange" : "pms-btn-purple"
                    }`}
                    // 沒有表身時不可以覆核
                    disabled={s_proTb.tb.length === 0 ? true : s_disabled.proCheck}
                    onClick={() => proCheck()}
                  >
                    {f_lifeF === "1" ? "取消外測覆核" : "外测覆核"}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]}>
              {/* 廠商業務窗口 */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="mt-1">
                {/* 廠商業務窗口 */}
                <Form.Item shouldUpdate label={<Text>{t("PMS.pro.dealcontact")}</Text>}>
                  {() => (
                    <Row gutter={[12, 0]}>
                      <Col span={12} className="flex items-center">
                        <ContactButton
                          disabled={s_disabled.dealcontact}
                          suppID={form.getFieldValue("suppID")}
                          chooseContact={(contact_obj) => {
                            form.setFieldsValue({
                              dealcontact: contact_obj?.contact,
                              dealtel: contact_obj?.tel,
                            });
                          }}
                          // title="選擇廠商業務窗口"
                          title={t("PMS.pro.dealcontact_placeholder")}
                        />

                        {/* 窗口 */}
                        <span className="ml-2">{t("PMS.util.window")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealcontact" className="flex-1 mb-0px" shouldUpdate>
                          <Input
                            disabled={s_disabled.dealcontact}
                            className="flex-1"
                            // placeholder={f_suppID ? "請選擇業務窗口" : "請先選擇廠商ID"}
                            placeholder={
                              f_suppID
                                ? t("PMS.pro.dealcontact_placeholder")
                                : t("PMS.util.suppID_placeholder")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="flex items-center">
                        {/* 電話 */}
                        <span>{t("PMS.util.tel")}:&nbsp;&nbsp;</span>

                        <Form.Item name="dealtel" className="flex-1 mb-0px">
                          <Input
                            disabled={s_disabled.dealtel}
                            // placeholder={f_suppID ? "請選擇聯絡人" : "請先選擇廠商ID"}
                            placeholder={
                              f_suppID
                                ? t("PMS.util.tel_placeholder")
                                : t("PMS.util.suppID_placeholder")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Form.Item>
              </Col>

              {/* 覆核人員 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item label={<Text disabled>{t("PMS.util.confirmID")}</Text>} name="confirmID">
                  <Input disabled />
                </Form.Item>
              </Col>
              {/* 覆核日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item label={<Text disabled>{t("PMS.util.confirmT")}</Text>} name="confirmT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>
              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
                >
                  {/* <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    onClick={showOutsource}
                    disabled={f_outsource?.length > 0 ? false : true}
                  >
                    查看外侧资料
                  </Button> */}
                  <Button
                    className="flex-1 h-full pms-btn-blue-outline"
                    onClick={() => {
                      set_s_showFileModal(true);
                      set_s_fileType("outReport");
                    }}
                    // disabled={f_outReport?.length > 0 ? false : true}
                  >
                    上传委外报告
                  </Button>
                  <Button
                    className={`h-full flex-1 ${
                      f_lifeF === "2" ? "pms-btn-red" : "pms-btn-purple"
                    }`}
                    onClick={completed}
                    disabled={s_disabled.completed}
                  >
                    外测完成
                    {/* {f_lifeF === "2" ? "取消外测完成" : "外测完成"} */}
                  </Button>
                </Col>
              )}
            </Row>

            <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
              {/* 採購註記  */}
              <Col xl={editMode ? 8 : 12} xxl={editMode ? 10 : 12} className="pb-2">
                <Form.Item
                  label={<Text disabled={s_disabled.note}>{t("PMS.pro.note")}</Text>}
                  name="note"
                >
                  <Input disabled={s_disabled.note} />
                </Form.Item>
              </Col>

              {/* 創建人員 */}
              <Col xl={editMode ? 6 : 6} xxl={editMode ? 5 : 6} className="pb-2 bg-gray-100">
                <Form.Item label={<Text disabled>{t("PMS.util.createID")}</Text>} name="createID">
                  <Input disabled />
                </Form.Item>
              </Col>

              {/* 創建日期 */}
              <Col xl={editMode ? 5 : 6} xxl={editMode ? 5 : 6} className="bg-gray-100">
                <Form.Item label={<Text disabled>{t("PMS.util.createT")}</Text>} name="createT">
                  <DatePicker disabled className="w-full" />
                </Form.Item>
              </Col>

              {editMode && (
                <Col
                  xl={5}
                  xxl={4}
                  className="border-0 border-l flex border-gray-300 border-solid py-2"
                >
                  <Button disabled={s_disabled.external} className="flex-1 h-full">
                    上传结案资料
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
        <DetailContainer />

        {/* 拋轉進料單-跳顯 */}
        <CustomModal
          width="90%"
          title={t("PMS.util.throw") + t("PMS.util.instock")}
          open={is_showInstock}
          onCancel={() => {
            set_is_showInstock(false);
          }}
        >
          {is_showInstock === true ? (
            <InstockModal
              hd_values={form.getFieldsValue()}
              set_is_showInstock={set_is_showInstock}
              s_proTb={s_proTb}
              call_getProHD={call_getProHD}
              call_getProTB={call_getProTB}
            />
          ) : null}
        </CustomModal>

        {/* 列印抽屜 */}
        {/* <Drawer
          placement="right"
          closable={false}
          width={800}
          onClose={() => set_is_showPrint(false)}
          open={is_showPrint}
        >
          <PrintDrawer hd_values={form.getFieldsValue()} s_proTb={s_proTb} />
        </Drawer> */}

        {/* 列印抽屜 */}
        <Drawer
          placement="right"
          closable={false}
          // width={800}
          width={820} //優如測試
          onClose={() => set_is_showPrint(false)}
          open={is_showPrint}
        >
          <PrintDrawer hd_values={form.getFieldsValue()} s_proTb={s_proTb} />
        </Drawer>
      </div>
      <CustomModal
        title={s_fileType === "outsource" ? "查看外侧资料" : "查看委外报告"}
        width="30%"
        open={s_showFileModal}
        onCancel={() => set_s_showFileModal(false)}
        maskClosable={true}
      >
        <FileModal
          hd_values={form.getFieldsValue()}
          s_fileType={s_fileType}
          set_s_showFileModal={set_s_showFileModal}
          call_getProHD={call_getProHD}
          call_updateProHD={call_updateProHD}
        />
      </CustomModal>
    </MainPageContext.Provider>
  );
};

export default MainPage;
