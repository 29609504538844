export const INIT_DISABLED = {
  opcode: true,
  dealN: true,
  currencytype: true,
  exchangerate: true,
  exchangeD: true,
  taxtype: true,
  taxrate: true,
  takeQuo: true, //引用報價單
  throwOutstock: true, //拋轉發貨單
  custID: true,
  payment: true, // 付款方式
  trade: true, // 貿易條件
  quoSearch: true, //報價紀錄查詢
  fodSearch: true, // 預訂紀錄查詢
  outstockSearch: true, //發貨紀錄查詢
  dealaddr: true, //客戶地址
  driID: true, //負責業務
  startEDT: true, //預計交貨日期(起)
  endEDT: true, //預計交貨日期(迄)
  custTradeSearch: true, //客戶交易查詢
  dealcontact: true, //客戶業務窗口(窗口)
  dealtel: true, //客戶業務窗口(電話)
  orderCheck: true, //銷貨覆核
  custCheck: true, //客戶覆核
  contact: true, //客戶交貨窗口(窗口)
  tel: true, //客戶交貨窗口(電話)
  note: true, //註記
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 客戶確認數量
  tb_delete: true,
  // ----- 條款註記 -----
  adda_statement: true,
  // ----- 加扣款項 -----
  addb_statement: true,
  addb_amount: true,
  // ----- 預付作業 -----
  addc_statement: true,
  addc_amount: true,
};
