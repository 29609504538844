import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { IconPen, IconGarbage, IconFile } from "@/components/Icon/Action";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import ModalConintaer from "./ModalContainer";
import UploadModalContainer from "./ModalContainer/uploadModalContainer";
import useAPI from "@/hooks/useAPI";
import { getCustRec, deleteCustRec } from "@/service/apis/ADM/client";
import dayjs from "dayjs";
import { getStaff } from "@/service/apis/ADM/userManage";
import Preview from "@/components/Preview/index";

// forwardRef: Exposing a DOM node to the parent component
const CustRec = forwardRef(({ s_editData, set_s_editData, c_lightData, t }, ref) => {
  // API
  const call_getCustRec = useAPI(getCustRec);
  const call_deleteCustRec = useAPI(deleteCustRec);
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_isShowPicModal, set_s_isShowPicModal] = useState(false);
  const [s_staff_options, set_s_staff_options] = useState([]);
  const [s_staff_map, set_s_staff_map] = useState({});
  // 取得業務人員
  const call_getStaff = useAPI(getStaff);

  // 表頭
  const tableColumns = [
    {
      title: "窗口ID",
      dataIndex: "itemUUID",
      align: "center",
      width: "4%",
    },
    // {
    //   title: t("ADM.client.itemNM"),
    //   dataIndex: "itemNM",
    //   width: "8%",
    // },
    {
      title: "记录日期",
      dataIndex: "recT",
      width: "10%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "提醒日期",
      dataIndex: "remdT",
      width: "5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "讯息内容",
      dataIndex: "content",
      width: "7%",
    },
    {
      title: "追踪事项",
      dataIndex: "reminder",
      width: "8%",
    },
    {
      title: "辅助图照",
      dataIndex: "addfile",
      width: "7%",
      // 圖片預覽功能
      render: (text) => <Preview url={text} type="Image" />,
    },
    {
      title: "负责业务",
      dataIndex: "mgmtcat",
      align: "center",
      width: "7%",
      render: (text) => s_staff_map[text],
    },

    {
      title: "操作",
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          {/* 檔案上傳 */}
          <IconFile
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowPicModal(true);
              e.stopPropagation();
            }}
          />
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_isShowModal(true),
    // expose給父元件的方法  for搜尋
    Search: () => {
      call_getCustRec.request(c_lightData);
    },
  }));

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: "删除确认",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "请先确认资料无误，再删除",
      okText: "确认",
      onOk: () => call_deleteCustRec.request(rowData),
      cancelText: "取消",
    });
  };

  useEffect(() => {
    call_getStaff.request();
  }, []);
  // useAPI取得員工資料
  useEffect(() => {
    const { status, msg, data } = call_getStaff;

    if (status === "suc") {
      const options =
        data?.map((item) => {
          return { value: item?.staffID, label: item?.peopleNM };
        }) || [];

      // ? 可選串連： promise 可能undefined, ?防止前端爆開
      // 變成想要的資料格式
      const staff_map =
        data?.reduce((prev, curr) => {
          prev[curr.staffID] = curr.peopleNM;
          return prev;
        }, {}) || {};

      /*
            const aa = {
              "S001": "Ariean"
            }
          */

      set_s_staff_map(staff_map);

      set_s_staff_options(options);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getStaff.status]);

  // Q: refer to aa
  useEffect(() => {
    call_getCustRec.request(c_lightData);
  }, [c_lightData]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_deleteCustRec.status === "suc") {
      message.success(call_deleteCustRec.msg);
      call_getCustRec.request(c_lightData);
    } else if (call_deleteCustRec.status === "err") {
      message.error(t(`errorCode.${call_deleteCustRec.msg}`));
    }
  }, [call_deleteCustRec.status]);

  useEffect(() => {
    if (call_getCustRec.status === "suc") {
      message.success(call_getCustRec.msg);
      set_s_tableData(
        call_getCustRec.data
          // ?.filter((item) => item.isvalid)
          ?.map((x, i) => ({
            ...x,
            key: i,
            index: i,
            mgmtcat: x.mgmtcat.split("|").filter((x) => x !== ""),
          }))
      );
    } else if (call_getCustRec.status === "err") {
      message.error(t(`errorCode.${call_getCustRec.msg}`));
      set_s_tableData([]);
    }
  }, [call_getCustRec.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getCustRec.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.client.customerContactInformation")}`
            : "新增客户纪录"
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getCustRec.request(c_lightData);
        }}
      >
        <ModalConintaer
          s_editData={s_editData}
          c_lightData={c_lightData}
          set_s_editData={set_s_editData}
          s_staff_options={s_staff_options}
          set_s_isShowModal={set_s_isShowModal}
        />
      </CustomModal>

      <CustomModal
        title={"上传档案"}
        width={"60%"}
        open={s_isShowPicModal}
        onCancel={() => set_s_isShowPicModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getCustRec.request(c_lightData);
        }}
      >
        <UploadModalContainer
          s_editData={s_editData}
          set_s_isShowPicModal={set_s_isShowPicModal}
          s_isShowPicModal={s_isShowPicModal}
        />
      </CustomModal>
    </>
  );
});

export default CustRec;
