/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Space, theme, Grid, Typography, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// util function
import { formatAmount } from "@/util/format";
// API
import useAPI from "@/hooks/useAPI";
import { deleteFodTB } from "@/service/apis/OMS/Fod";
// component
import TbModal from "../Modal/TbModal";

const { Title } = Typography;
const { useToken } = theme;
const TbContainer = (props) => {
  const {
    hd_values,
    s_disabled,
    s_fodTb,
    set_s_fodTb,
    call_getFodHD,
    call_getFodTB,
    call_updateFodHD,
    is_editForm,
    set_is_editForm,
    s_tbModal, // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
    set_s_tbModal,
  } = props;
  const { t } = useTranslation();
  const { token } = useToken();

  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteFodTB = useAPI(deleteFodTB);

  const flagMap = {
    dealN: t("OMS.util.dealN"),
    custID: t("OMS.util.custID"),
    startEDT: t("OMS.ord.startEDT"),
    endEDT: t("OMS.ord.endEDT"),
    isvalid: t("OMS.quo.isvalid"),
    taxtype: t("OMS.util.taxtype"),
    exchangeD: t("OMS.util.exchangeD"),
    exchangerate: t("PMS.util.exchangerate"),
    currencytype: t("OMS.util.currencytype"),
    driID: t("OMS.ord.driID"),
    note: t("OMS.ord.note"),
    payment: t("OMS.ord.payment"),
    trade: t("OMS.ord.trade"),
  };

  // 編輯資料
  const editData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => flagMap[item] ?? item)?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: t("OMS.util.submit"), // 確定
        onOk: async () => {
          // 更新表頭
          call_updateFodHD.request(hd_values);
          // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
          set_s_tbModal({ show: false, data: rowData });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: rowData });
  };

  // 刪除資料
  const removeData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => flagMap[item] ?? item)?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateFodHD.request(hd_values);
        },
      });
    } else {
      Modal.confirm({
        title: t("OMS.fod.tb") + t("OMS.util.del_confirm"), // 預訂明細刪除確認
        icon: <ExclamationCircleOutlined />,
        content: t("OMS.util.del_tb_confirm_text"),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 刪除表身
          call_deleteFodTB.request(rowData);
        },
      });
    }
  };

  // 更新表頭的API
  useEffect(() => {
    const { status, msg } = call_updateFodHD;
    if (status === "suc") {
      message.success(msg);
      // 更新完成後打開跳顯讓使用者編輯
      set_s_tbModal((prev) => ({
        show: true,
        data: prev.data,
      }));
      // 清除編輯記錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updateFodHD.status]);

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteFodTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getFodHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getFodTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteFodTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("OMS.fod.unitprice"), // 未稅單價
      dataIndex: "unitprice",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.localprice"), // 本幣單價
      dataIndex: "localprice",
      align: "right",
      width: "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.demandqty"), // 預訂數量
      dataIndex: "demandqty",
      align: "right",
      width: "6%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.punit"), // 銷貨單位
      dataIndex: "punit",
      align: "center",
      width: "7%",
    },
    {
      title: t("OMS.util.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.confirmqty"), // 客戶確認數量
      dataIndex: "confirmqty",
      align: "right",
      width: "7%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.returnqty"), // 退出數量
      dataIndex: "returnqty",
      align: "right",
      width: "6%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.fod.cancelqty"), // 取消數量
      dataIndex: "cancelqty",
      align: "right",
      width: "5%",
      onCell: (rowData) => {
        return {
          style: {
            backgroundColor: token.tableBg,
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton
            // onClick={() => set_s_tbModal({ show: true, data: rowData })}
            onClick={() => editData(rowData)}
          />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data)?.length === 0 ? "create" : "edit";
  // console.log("hd_values.total = ", hd_values.total);
  return (
    <div className="flex flex-1 flex-col justify-between">
      <CustomTable
        columns={tableColumns}
        rowNumber={3}
        dataSource={s_fodTb.tb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#4B6EA2]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("OMS.util.itemno_total")}: </span>
          <span className="self-center">{s_fodTb.tb?.length ?? 0}</span>
        </Col>

        <Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">總加扣款項:</span>
          <span className="self-center">{formatAmount(hd_values.charge)}</span>
        </Col>

        <Col span={5} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 未稅總金額 */}
          <span className="self-center">{t("OMS.fod.sumtotal")}:</span>
          <span className="self-center">{formatAmount(hd_values.sumtotal)}</span>
        </Col>

        <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總稅額 */}
          <span className="self-center">{t("OMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(hd_values.tax)}</span>
        </Col>

        <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(本幣) */}
          <span className="self-center">{t("OMS.fod.local_total")}:</span>
          <span className="self-center">
            {formatAmount(hd_values.total * hd_values.exchangerate)}
          </span>
        </Col>

        <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">{t("OMS.fod.total")}:</span>
          <span className="self-center"> {formatAmount(hd_values.total)}</span>
        </Col>
      </Row>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-center mr-5">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯預訂明細" : "新增預訂明細"} */}
              {(type === "edit" ? t("util.edit") : t("util.add")) + t("OMS.fod.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("OMS.util.opcode")}：{t(`OMS.util.opcode_${hd_values.opcode}`)} {t("OMS.util.N")}
                ：{hd_values.N} {t("OMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        {s_tbModal.show && (
          <TbModal
            hd_values={hd_values}
            s_disabled={s_disabled}
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
            call_getFodHD={call_getFodHD}
            call_getFodTB={call_getFodTB}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default TbContainer;
