/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { ERPContext } from "@/components/Context/SystemContext";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import TbModal from "../Modal/TbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteApTB } from "@/service/apis/PMS/ap";
const { useToken } = theme;

const { Title } = Typography;

const TbContainer = ({ call_getApTB, s_tbData, s_disabled }) => {
  const { getStaffName } = useContext(ERPContext);
  const { token } = useToken();
  const { t } = useTranslation();
  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 報價單號
      dataIndex: "N13",
      align: "center",
      width: "8%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "8%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.ord.payment"), // 負責業務
      dataIndex: "payment",
      align: "center",
      width: "8%",
    },
    {
      title: t("PMS.util.suppalias"), // 客戶簡稱
      dataIndex: "suppalias",
      align: "center",
    },
    {
      title: t("PMS.ap-manage.subtotal"), // 金額
      dataIndex: "itemsum",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
  ];

  return (
    <div className="flex flex-col flex-1">
      <div className="flex justify-between items-center py-2">
        {/* 對帳單明細 */}
        <TbTitle>{t("PMS.ap-manage.ap_tb")}</TbTitle>
      </div>

      <CustomTable
        columns={tableColumns}
        rowNumber={6}
        dataSource={s_tbData?.map((item, index) => ({ key: index, ...item })) ?? []}
      />
    </div>
  );
};

export default TbContainer;
