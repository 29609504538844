/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { Grid, Space, Input, message, Modal, Divider, Drawer, Typography, theme } from "antd";
import {
  ExclamationCircleOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import PageHeader from "@/components/PageHeader";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import {
  CreateButton,
  ClearButton,
  ExcelButton,
  DeleteButton,
  EditButton,
  SeeMoreButton,
} from "@/components/ERP/Button";
import { CustomRangePicker, CustomSelect, CustomSearchButton } from "@/components/ERP/TabExtra";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import { DataContext } from "../index";
// component
import DrawerModal from "./Modal/DrawerModal";
// API
import useAPI from "@/hooks/useAPI";
import { getPlanHD, deletePlanHD } from "@/service/apis/PMS/Plan";
// import { getPlanHD, deletePlanHD } from "../moke";

const { Title } = Typography;
const { useToken } = theme;
const HD = () => {
  const { t } = useTranslation();
  const { token } = useToken();
  // useAPI 取得表頭資料
  const call_getPlanHD = useAPI(getPlanHD);
  // useAPI 刪除表頭資料
  const call_deletePlanHD = useAPI(deletePlanHD);
  // 第三方套件用來判斷URL是否帶有資料
  const [searchParams, setSearchParams] = useSearchParams();

  const { c_state, updateState } = useContext(DataContext);
  const { tabKey, searchConfig, lightData, INIT_SEARCHCONFIG } = c_state;
  // 廠商資料、人員資料
  const { c_supps, c_staffs, getStaffName } = useContext(ERPContext);
  // 表格的資料
  const [s_tableData, set_s_tableData] = useState([]);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_drawer, set_s_drawer] = useState({ show: false, data: {} });
  // ㄧ階搜尋的下拉選單選項
  const [s_selectOptions, set_s_selectOptions] = useState({
    suppID: [],
    quoID: [],
    lifeF: [
      { value: "0", label: t("OMS.quo.lifeF_0") }, // 發佈
      { value: "2", label: t("OMS.quo.lifeF_2") }, // 報價覆核
      { value: "7", label: t("OMS.quo.lifeF_7") }, // 審核正式簽送
      { value: "T", label: t("OMS.quo.lifeF_T") }, // 結案
    ],
  });

  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();

  const placeholder_map = {
    N: t("util.placeholder_input") + t("PMS.util.N"), // 請輸入單號
    custID: t("util.placeholder_select") + t("PMS.util.suppID"), // 請選擇客戶
    lifeF: t("util.placeholder_select") + t("PMS.util.lifeF"), // 請選擇歷程
  };

  const handleChange = (type, value) => {
    console.log({ type, value });
    // 創建一個臨時的物件，更新搜尋設定
    const tmp = {
      ...searchConfig,
      // 根據傳入的類型，更新相應的值
      [type]: value,
      // 如果傳入的類型是時間，則格式化日期並更新開始日期和結束日期
      startDate: type === "time" ? dayjs(value[0]).format("YYYY-MM-DD") : searchConfig.startDate,
      endDate: type === "time" ? dayjs(value[1]).format("YYYY-MM-DD") : searchConfig.endDate,
      // 如果傳入的類型是搜尋欄位，則清空搜尋文字
      searchValue:
        type === "searchValue" ? value : type === "searchFlag" ? null : searchConfig.searchValue,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
  };
  // 查看更多
  const openDetail = (event, rowData) => {
    set_s_drawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  const handleChangeInput = (value) => {
    const tmp = {
      ...searchConfig,
      searchValue: value,
      // 如果 '搜尋文字' 為空要清空 '搜尋欄位'
      searchFlag: value === "" ? undefined : searchConfig.searchFlag,
    };
    // 更新狀態
    updateState({ searchConfig: tmp });
    // 如果搜尋文字為空要重新取得表頭資料
    if (value === "") {
      call_getPlanHD.request({ ...tmp });
    }
  };
  // 搜尋(一階搜尋用)
  const handleSearch = () => {
    call_getPlanHD.request({
      ...searchConfig,
      [searchConfig.searchFlag]: searchConfig.searchValue,
    });
    // 更新狀態
    updateState({ c_lightData: {} });
  };
  // 清除搜尋紀錄
  const clearSearch = () => {
    // 清除搜尋紀錄 => 把搜尋紀錄改成預設的搜尋值
    const tmp = {
      ...searchConfig,
      opcode: tabKey,
      startDate: dayjs().startOf("month"), // 開始日期
      endDate: dayjs().endOf("month"), // 結束日期
      searchFlag: undefined, // 要搜尋的欄位名稱
      searchValue: undefined,
    };

    // 清除搜尋紀錄
    updateState({ searchConfig: tmp });

    // 重新取得表頭資料
    // console.log("tmp = ", tmp);
    call_getPlanHD.request(tmp);
    // 清空URL帶的資料
    searchParams.delete("opUUID");
    searchParams.delete("preopUUID");
    searchParams.delete("opcode");
    setSearchParams(searchParams);
  };
  // 刪除表頭資料
  const removeData = (event, rowData) => {
    if (rowData.lifeF === "T") {
      event.stopPropagation();
      return;
    }

    Modal.confirm({
      title: t("PMS.util.plan") + t("PMS.util.del_confirm"), // 詢價單刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的詢價單資料無誤，再進行刪除",
      content: t("PMS.plan.hd_delete_confirm_text"),
      onOk: () => call_deletePlanHD.request(rowData),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
    });
    event.stopPropagation();
  };
  // 編輯
  const editData = (event, rowData) => {
    updateState({ mainPage: { show: true, data: rowData } });
    event.stopPropagation();
  };
  // 第一次渲染取得表頭資料
  useEffect(() => {
    const opcode = searchParams.get("opcode");
    const opUUID = searchParams.get("opUUID");
    const preopUUID = searchParams.get("preopUUID");
    // opcode為null時代表現在不是查詢上下位資料，而是透過navbar or url進來
    if (opcode === null) {
      call_getPlanHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });
      return;
    }

    // 程式往下走代表現在是查詢上下位資料
    updateState({
      searchConfig: {
        ...searchConfig,
        startDate: undefined,
        endDate: undefined,
      },
      tabKey: opcode,
    });

    call_getPlanHD.request({
      ...searchConfig,
      startDate: undefined,
      endDate: undefined,
      [opUUID === null ? "preopUUID" : "opUUID"]: opUUID === null ? preopUUID : opUUID,
    });
  }, [tabKey]);

  // 下拉選單覆值
  useEffect(() => {
    set_s_selectOptions((prev) => ({
      ...prev,
      custID: c_supps.options,
      quoID: c_staffs.options,
    }));
  }, [c_supps, c_staffs]);

  // useAPI 取得表頭資料
  useEffect(() => {
    const { status, data, msg } = call_getPlanHD;
    if (status === "suc") {
      console.log("call_getQuoHD.data = ", data);
      // 把資料塞進表頭的table
      set_s_tableData(data?.map((item) => ({ ...item, key: item.N })));
      // 取得所有單號(用來做上一筆、下一筆)
      const newQuo_Ns = data?.map((item) => item.N) ?? [];
      // 更新資料
      updateState({ quo_Ns: newQuo_Ns });
      // UI顯示API回傳的狀態
      message.success(msg);
    }

    if (status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getPlanHD.status]);

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { msg, status } = call_deletePlanHD;
    if (status === "suc") {
      message.success(msg);
      //刪除完成後要重新取得表頭資料
      call_getPlanHD.request({
        ...searchConfig,
        [searchConfig.searchFlag]: searchConfig.searchValue,
      });

      // 如果有 lightData 要清空
      if (Object.keys(lightData).length > 0) {
        updateState({ lightData: {} });
      }
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deletePlanHD.status]);

  // 表格欄位
  const tableColumns = [
    {
      title: t("PMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: "6%",
      render: (text) => t(`PMS.util.opcode_${text}`),
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: "8%",
    },
    {
      title: t("util.note"), // 註記
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.isvalid"), // 是否有效
      dataIndex: "isvalid",
      align: "center",
      width: "5%",
      render: (text, _, __) => {
        return (
          <div className="checkIcon">
            {text === true ? (
              <CheckCircleFilled style={{ color: token.colorPrimary }} />
            ) : text === false ? (
              <CloseCircleOutlined />
            ) : null}
          </div>
        );
      },
    },
    {
      title: t("util.updtT"), // 更新日期
      dataIndex: "updtT",
      align: "center",
      width: "8%",
      render: (text) => (text.includes("0001-01-01") ? null : dayjs(text).format("YYYY-MM-DD")),
    },
    {
      title: t("PMS.util.updtID"), // 更新人員
      dataIndex: "updtID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },

    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 刪除表頭資料 */}
          <DeleteButton disabled={rowData.lifeF === "T"} onClick={(e) => removeData(e, rowData)} />
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />

          {/* 編輯 */}
          <EditButton onClick={(e) => editData(e, rowData)} />
        </Space>
      ),
    },
  ];
  return (
    <div className="flex-1">
      <PageHeader
        title={t("PMS.plan.pageHeader")} // 詢價計畫
        extra={<TourButton />}
      />
      <Space className="flex justify-end mb-2">
        <ExcelButton onClick={() => alert("wait")} disabled={s_tableData.length === 0} />
        <CustomRangePicker
          disabled={tabKey === "detailSearch"}
          value={[searchConfig?.startDate, searchConfig?.endDate]}
          onCalendarChange={(dates, _, __) => {
            updateState({
              searchConfig: {
                ...searchConfig,
                startDate: dates[0],
                endDate: dates[1],
              },
            });
          }}
        />

        <Input.Group compact>
          <CustomSelect
            disabled={tabKey === "detailSearch"}
            value={searchConfig.searchFlag}
            placeholder={t("util.search")}
            onChange={(value) => handleChange("searchFlag", value)}
            options={[
              { value: "N", label: t("OMS.util.N") }, // 單號
            ]}
          />

          {searchConfig.searchFlag === "lifeF" ? (
            <CustomSelect
              showSearch
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(value) => handleChange("searchValue", value)}
              // 根據searchFlag判斷現在是哪個下拉選單
              options={s_selectOptions[searchConfig.searchFlag]}
            />
          ) : (
            <Input
              disabled={tabKey === "detailSearch"}
              value={searchConfig.searchValue}
              className="w-[250px]"
              placeholder={placeholder_map[searchConfig.searchFlag]}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          )}
          <CustomSearchButton disabled={tabKey === "detailSearch"} onClick={handleSearch} />
        </Input.Group>

        {/* 清除搜詢紀錄 */}
        <ClearButton disabled={tabKey === "detailSearch"} onClick={clearSearch} />
        {/* 新建 */}
        <CreateButton
          disabled={tabKey === "detailSearch"}
          onClick={() => updateState({ mainPage: { show: true, data: {} } })}
        />
      </Space>

      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getPlanHD.status === "load"}
        tbMode={Object.keys(lightData).length > 0}
        lightData={lightData} // 有表頭表身結構的地方要傳進去做rowClass的判斷
        onRow={(record) => {
          return {
            onClick: (e) =>
              updateState({
                lightData: record.N === lightData.N ? {} : record,
              }),
            // 雙擊可進入編輯模式
            onDoubleClick: (event) => {
              event.stopPropagation();
              editData(event, record);
            },
          };
        }}
      />

      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            {/* 詢價計畫 */}
            {t("PMS.plan.pageHeader")}
          </Title>
        }
        placement="right"
        closable={false}
        width={1000}
        onClose={() => set_s_drawer({ show: false, data: {} })}
        open={s_drawer.show}
      >
        <DrawerModal s_drawer={s_drawer} set_s_drawer={set_s_drawer} />
      </Drawer>
    </div>
  );
};

export default HD;
