import { useEffect } from "react";
import { Form, Row, Col, Input, Select, InputNumber, Space, Button, message } from "antd";
import { unitArr } from "../../util/anyMap";
import useAPI from "@/hooks/useAPI";
import { addLabel, updateLabel } from "@/service/apis/LMS/LabelManage";

export default function ModalContainer({ set_s_showModal, s_editData }) {
  const [form] = Form.useForm();

  const call_addLabel = useAPI(addLabel);
  const call_updateLabel = useAPI(updateLabel);

  const ChooseUnit = () => {
    return <Select placeholder="請選擇單位" options={unitArr} />;
  };

  const formMap = [
    {
      title: "標籤紙ID",
      dataIndex: "labelID",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤紙ID" disabled={Object.keys(s_editData).length > 0} />,
    },
    {
      title: "標籤紙名稱",
      dataIndex: "labelNM",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤紙名稱" />,
    },
    {
      title: "標籤紙類型",
      dataIndex: "labelType",
      align: "center",
      col: 6,
      auth: true,
      type: <Input placeholder="請輸入標籤紙類型" />,
    },
    {
      title: "",
      dataIndex: "",
      align: "center",
      nostyle: true,
    },
    {
      title: "標籤寬度",
      dataIndex: "width",
      align: "center",
      col: 6,
      auth: true,
      type: <InputNumber className="w-full" placeholder="請輸入寬度" />,
    },
    {
      title: "標籤寬度單位",
      dataIndex: "widthUnit",
      align: "center",
      col: 6,
      auth: true,
      type: <Select placeholder="請選擇單位" options={unitArr} />,
    },
    {
      title: "標籤高度",
      dataIndex: "high",
      align: "center",
      col: 6,
      auth: true,
      type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    },
    {
      title: "標籤高度單位",
      dataIndex: "highUnit",
      align: "center",
      col: 6,
      auth: true,
      type: <Select placeholder="請選擇單位" options={unitArr} />,
    },
    // {
    //   title: "軸芯",
    //   dataIndex: "coreSize",
    //   align: "center",
    //   col: 6,
    //   // auth: true,
    //   type: <InputNumber className="w-full" placeholder="請輸入軸芯" />,
    // },
    // {
    //   title: "軸芯單位",
    //   dataIndex: "coreUnit",
    //   align: "center",
    //   col: 6,
    //   // auth: true,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    // {
    //   title: "紙捲長度",
    //   dataIndex: "length",
    //   align: "center",
    //   col: 6,
    //   type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    // },
    // {
    //   title: "紙捲長度單位",
    //   dataIndex: "lengthUnit",
    //   align: "center",
    //   col: 6,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    // {
    //   title: "左間隙",
    //   dataIndex: "leftGap",
    //   align: "center",
    //   col: 6,
    //   type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    // },
    // {
    //   title: "左間隙單位",
    //   dataIndex: "leftUnit",
    //   align: "center",
    //   col: 6,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    // {
    //   title: "右間隙",
    //   dataIndex: "rightGap",
    //   align: "center",
    //   col: 6,
    //   type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    // },
    // {
    //   title: "右間隙單位",
    //   dataIndex: "rightUnit",
    //   align: "center",
    //   col: 6,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    // {
    //   title: "上間隙",
    //   dataIndex: "topGap",
    //   align: "center",
    //   col: 6,
    //   type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    // },
    // {
    //   title: "上間隙單位",
    //   dataIndex: "topUnit",
    //   align: "center",
    //   col: 6,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    // {
    //   title: "下間隙",
    //   dataIndex: "bottomGap",
    //   align: "center",
    //   col: 6,
    //   type: <InputNumber className="w-full" placeholder="請輸入數值" />,
    // },
    // {
    //   title: "下間隙單位",
    //   dataIndex: "bottomUnit",
    //   align: "center",
    //   col: 6,
    //   type: <Select placeholder="請選擇單位" options={unitArr} />,
    // },
    {
      title: "紙張間隙",
      dataIndex: "gap",
      align: "center",
      col: 6,
      auth: true,
      type: <InputNumber className="w-full" placeholder="請輸入紙張間隙" />,
    },
    {
      title: "紙張間隙單位",
      dataIndex: "gapUnit",
      align: "center",
      col: 6,
      auth: true,
      type: <Select placeholder="請選擇單位" options={unitArr} />,
    },
    {
      title: "每行紙張",
      dataIndex: "col",
      align: "center",
      col: 6,
      auth: true,
      type: <InputNumber className="w-full" placeholder="請輸入每行紙張" />,
    },
    {
      title: "備註",
      dataIndex: "note",
      align: "center",
      col: 24,
      auth: false,
      type: <Input.TextArea rows={6} placeholder="請輸入備註" />,
    },
  ];

  const onSubmit = async (values) => {
    if (Object.keys(s_editData).length === 0) {
      call_addLabel.request(values);
    } else {
      call_updateLabel.request(values);
    }
  };

  const handleCancel = () => {
    set_s_showModal(false);
  };

  useEffect(() => {
    if (call_addLabel.status === "suc") {
      handleCancel();
    } else if (call_addLabel.status === "err") {
      message.error(call_addLabel.msg);
    }
  }, [call_addLabel.status]);

  useEffect(() => {
    if (call_updateLabel.status === "suc") {
      handleCancel();
    } else if (call_updateLabel.status === "err") {
      message.error(call_updateLabel.msg);
    }
  }, [call_updateLabel.status]);

  return (
    <Form
      name="basic"
      form={form}
      autoComplete="off"
      layout="vertical"
      onFinish={onSubmit}
      initialValues={s_editData}
    >
      <Row gutter={[12, 0]}>
        {formMap.map((x) => {
          return (
            <Col span={x.col} key={x.dataIndex}>
              <Form.Item
                label={x.title}
                name={x.dataIndex}
                rules={
                  x.auth && [
                    {
                      required: true,
                      message: `請輸入${x.title}`,
                    },
                  ]
                }
              >
                {x.type}
              </Form.Item>
            </Col>
          );
        })}
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={handleCancel}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
