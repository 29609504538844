import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { message, Spin, Space, Select } from "antd";
import dragCanvas from "@/pages/LMS/util/dragCanvas";

const { buildLabel } = dragCanvas;

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return ((value / 2.54) * 96).toFixed(2);
    case "mm":
      return ((value / 25.4) * 96).toFixed(2);
    default:
      return (value * 96).toFixed(2);
  }
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const sizeOptions = [
  { label: "100%", value: 1 },
  { label: "200%", value: 2 },
  { label: "300%", value: 3 },
  { label: "400%", value: 4 },
  { label: "500%", value: 5 },
  { label: "600%", value: 6 },
  { label: "700%", value: 7 },
  { label: "800%", value: 8 },
  { label: "900%", value: 9 },
  { label: "1000%", value: 10 },
];

const DrawingArea = forwardRef(({ form_tb_ref, form_hd_ref, openTbCollapseKey }, ref) => {
  const canvasRef = useRef(null);

  const [is_reRender, set_is_reRender] = useState(false);

  const [s_canvasSetting, set_s_canvasSetting] = useState({
    width: 20,
    height: 20,
    unit: "mm",
    size: 5,
  });

  // 將資料 build 在 canvas 中
  const buildCanvasLabel = async () => {
    // 設定 is_rebuild 為 true，表示標籤正在重新建立中
    set_is_reRender(true);

    let formHdData, formTbData;
    try {
      // 使用 Promise.all 並等待 form_hd_ref 和 form_tb_ref 驗證欄位
      [formHdData, formTbData] = await Promise.all([
        form_hd_ref.validateFields(),
        form_tb_ref.validateFields(),
      ]);
    } catch (e) {
      // 若發生錯誤，設定 is_rebuild 為 false 並直接 return
      message.error("請填寫完整資訊");
      // 避免資料未填寫展開輸入框
      if (e?.errorFields) {
        const errN = new Set(e.errorFields.map((x) => x.name[1]));
        // HD的錯誤不打開TB的選單
        openTbCollapseKey(errN ? [...errN] : []);
      }
      set_is_reRender(false);
      return;
    }

    // 將 formHdData 的值設定到 s_canvasSetting 中
    set_s_canvasSetting((prev) => ({ ...prev, ...formHdData }));

    // 格式化 formTbData 中的 details 屬性
    const fomatTbData = await Promise.all(
      formTbData.details.map(async (x, i) => {
        if (x.type === "img" && (!x.imgName || x.imgName === "")) {
          // 若該項目是圖片類型，且沒有設定圖片名稱，則取得圖片的 base64 編碼並回傳 imgName 和 imgUrl
          const imgData = await new Promise((resolve) => {
            getBase64(x.img[0].originFileObj, (imageUrl) => {
              resolve({
                imgName: x.img[0].name,
                imgUrl: imageUrl,
              });
            });
          }); // 回傳格式化後的該項目物件，加上 id 和 imgData 屬性
          return { ...x, id: i + 1, ...imgData };
        }
        // 若不是圖片類型，回傳格式化後的該項目物件，加上 id 屬性
        return { ...x, id: i + 1 };
      })
    );

    // 將格式化後的 fomatTbData 設定回 form_tb_ref 的 details 屬性中
    form_tb_ref.setFieldsValue({ details: fomatTbData });

    // 將 s_canvasSetting、formTbData、formHdData 合併成一個物件 labelParams
    const labelParams = {
      ...s_canvasSetting,
      ...formTbData,
      ...formHdData,
    };
    // 宣告一個名為 changeTbData 的函式，當 buildLabel 完成後會觸發，用來更新 form_tb_ref 的 details 屬性
    const changeTbData = (data) => {
      form_tb_ref.setFieldsValue({ details: data });
      buildCanvasLabel(); // 重新呼叫 buildCanvasLabel，因為 form_tb_ref 的值已被更新
    };

    try {
      // 建立標籤圖形並等待執行完畢
      await buildLabel(canvasRef.current, labelParams, fomatTbData, changeTbData);
    } catch (e) {
      set_is_reRender(false);
      message.error(`條碼字串錯誤請檢查條碼`);
      return;
    }

    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(500);
      }, 500);
    });
    set_is_reRender(false);
  };

  // 傳上去
  useImperativeHandle(ref, () => ({
    renderDraw: async () => {
      await buildCanvasLabel();
    },
  }));

  return (
    <Spin spinning={is_reRender}>
      <div className="border border-solid	border-[#d9d9d9] rounded-md">
        <div className="w-full h-[50px] flex items-center justify-center">
          <Space>
            <span className="text-[18px]">(1網格等於1mm)</span>
            <span style={{ fontSize: "18px" }}>
              {`標籤尺寸(px)：${unitToPx(s_canvasSetting.width, s_canvasSetting.unit)} x ${unitToPx(
                s_canvasSetting.height,
                s_canvasSetting.unit
              )}`}
            </span>
            <Select
              options={sizeOptions}
              style={{ width: 100 }}
              value={s_canvasSetting.size}
              onChange={(e) => {
                set_s_canvasSetting((prev) => ({ ...prev, size: e }));
              }}
            />
          </Space>
        </div>
        <div className="flex items-top justify-center p-4 overflow-scroll  border-x-0 border-y-0 border-t border-solid border-[#d9d9d9] w-full h-[450px] bg-slate-50">
          <div ref={canvasRef} />
        </div>
      </div>
    </Spin>
  );
});

export default DrawingArea;
