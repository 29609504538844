import React, { useRef, useState } from "react";
import { Modal, theme } from "antd";
import Draggable from "react-draggable";
// icon
import { CloseOutlined } from "@ant-design/icons";
const { useToken } = theme;
const CustomModal = (props) => {
  const { token } = useToken();
  const draggleRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  // console.log("token = ", token);
  return (
    <Modal
      maskClosable={false} //点击蒙层是否允许关闭
      destroyOnClose //关闭时销毁 Modal 里的子元素
      footer={null}
      closeIcon={
        <CloseOutlined
          className="mt-0"
          style={{ color: token.colorPrimary, fontSize: token.fontSizeXL }}
        />
      }
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      {...props}
      title={
        <div
          className="w-full cursor-move"
          style={{ fontSize: token.fontSizeXL }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
        >
          {props.title}
        </div>
      }
    >
      {props.children}
    </Modal>
  );
};
export default CustomModal;
