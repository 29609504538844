import { forwardRef, useState } from "react";

import HD from "./HD";
import TB from "./TB";

const Basic = forwardRef((props, ref) => {
	const [c_lightData, set_c_lightData] = useState({});
	console.log("c_lightData = ", c_lightData);
	return (
		<>
			<HD ref={ref} c_lightData={c_lightData} set_c_lightData={set_c_lightData} {...props} />
			{/* {Object.keys(c_lightData).length > 0 && <TB c_lightData={c_lightData} />} */}
		</>
	);
});

export default Basic;
