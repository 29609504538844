/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { Row, Col, Space, Grid, Typography, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util function
import { formatAmount } from "@/util/format";
// context
import { MainPageContext } from "../index";
// API
import useAPI from "@/hooks/useAPI";
import { deleteProTB } from "@/service/apis/PMS/Pro";
// component
import TbModal from "../Modal/TbModal";

const { Title } = Typography;
const TbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_proTb, call_getProHD, call_getProTB, is_editForm, set_is_editForm } =
    useContext(MainPageContext);
  const { t } = useTranslation();

  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteProTB = useAPI(deleteProTB);

  const flagMap = {
    currencytype: t("PMS.util.currencytype"),
    exchangerate: t("PMS.util.exchangerate"),
    driID: t("PMS.pro.driID"),
    proD: t("PMS.pro.proT"),
    startEDT: t("PMS.pro.startEDT"),
    endEDT: t("PMS.pro.endEDT"),
    payment: t("PMS.pro.payment"),
    trade: t("PMS.pro.trade"),
    taxtype: t("PMS.util.taxtype"),
    demandDate: t("PMS.req.demandDate"),
    suppID: t("PMS.util.suppID"),
  };

  // 編輯資料
  const editData = (rowData) => {
    // if (is_editForm.isEdit) {
    //   Modal.confirm({
    //     title: t("PMS.util.sysMsg"), // 系統訊息
    //     icon: <ExclamationCircleOutlined />,
    //     content: (
    //       //2023/03/22 先不加i18n，寫法優化後再加
    //       <div className="flex flex-col">
    //         <span>{t("util.edit_detect_msg1")}</span>
    //         <span className="text-red-500">
    //           {/* {is_editForm.editFlag?.map((item) => item)?.join(",")} */}
    //           {is_editForm.editFlag?.map((item) => flagMap[item])?.join(",")}
    //         </span>
    //         <span>{t("util.edit_detect_msg2")}</span>
    //       </div>
    //     ),
    //     okText: <IconConfirm />, // 確定
    //     cancelText: <IconCancel />, // 取消
    //     onOk: async () => {
    //       // 更新表頭
    //       // call_updateProHD.request(hd_values);
    //       // 先塞要編輯的資料，等更新表頭的API回來再打開跳顯
    //       set_s_tbModal({ show: false, data: rowData });
    //     },
    //   });
    //   return;
    // }

    set_s_tbModal({ show: true, data: rowData });
  };

  // useAPI 刪除表頭資料
  useEffect(() => {
    const { status, msg } = call_deleteProTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getProHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getProTB.request({ opUUID: hd_values.opUUID });
    }
  }, [call_deleteProTB.status]);

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.note"), // 樣品名稱
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 檢驗項目
      dataIndex: "pdtNM",
      align: "center",
      width: "15%",
    },
    // {
    //   title: t("util.PN"), // 料號
    //   dataIndex: "PN",
    //   align: "center",
    //   width: "5%",
    // },
    {
      title: t("util.pdtspec"), // 測試方法/標準
      dataIndex: "pdtspec",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.ord.planday"), // 計畫時間
      dataIndex: "planday",
      align: "center",
      width: "7%",
      render: (text) => (text ? `${text}天` : text),
      // onCell: (_) => {
      //   return {
      //     style: {
      //       backgroundColor: "#ecf2fa",
      //     },
      //   };
      // },
    },
    {
      title: t("OMS.util.EDT"), // 預計交貨日
      dataIndex: "EDT",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.ord.unitprice"), // 檢測費用
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.ord.demandqty"), // 銷貨數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 客戶確認數量 */}
          {/* <SuppCheckButton disabled /> */}
          {/* <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" /> */}
          {/* 編輯表身資料 */}
          <EditButton
            // onClick={() => set_s_tbModal({ show: true, data: rowData })}
            onClick={() => editData(rowData)}
          />
          {/* 刪除表身資料 */}
          {/* <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} /> */}
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data)?.length === 0 ? "create" : "edit";

  console.log("hd_values.sumtotal = ", hd_values.sumtotal);
  return (
    <div className="flex flex-1 flex-col justify-between">
      <CustomTable
        columns={tableColumns}
        rowNumber={3}
        dataSource={s_proTb.tb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#6A68A1]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("OMS.util.itemno_total")}: </span>
          <span className="self-center">{s_proTb.tb?.length ?? 0}</span>
        </Col>
        <Col span={22} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 含稅總金額(交易幣別) */}
          <span className="self-center">含稅總金額:</span>
          <span className="self-center"> {formatAmount(hd_values.sumtotal)}</span>
        </Col>
      </Row>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-center mr-5">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯採購明細" : "新增採購明細"} */}
              {type === "edit" ? t("util.edit") + t("PMS.pro.tb") : t("util.add") + t("PMS.pro.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
                ：{hd_values.N} {t("PMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        {s_tbModal.show && (
          <TbModal
            s_tbModal={s_tbModal}
            call_getProHD={call_getProHD}
            call_getProTB={call_getProTB}
            set_s_tbModal={set_s_tbModal}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default TbContainer;
