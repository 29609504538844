import React, { useState, useContext } from "react";
import { Row, Col, Button } from "antd";
// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { MainPageContext } from "../index";
// API
import apiBasic from "@/service/APIBasic";
const Commission = () => {
  const { hd_values, s_disabled } = useContext(MainPageContext);

  const viewFile = (type) => {
    const element = document.createElement("a");
    // element.href = hd_values[type];
    console.log("hd_values =", hd_values);
    element.href = `${apiBasic.conn}://${apiBasic.url}/${hd_values.outsource}`;
    console.log("element.href =", element.href);
    // `${apiBasic.conn}://${apiBasic.url}/${url}`

    element.target = "_blank";
    // element.download = "myFile.txt";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <div className="mt-5 flex justify-start gap-5">
      {/* 上傳客戶委託 */}
      <Button
        // disabled={s_disabled.quoSign}
        disabled={hd_values.quoSign?.length === 0}
        className="oms-btn-darkBlue h-28 text-2xl flex-center"
        onClick={() => viewFile("quoSign")}
      >
        瀏覽外测资料
      </Button>
    </div>
  );
};

export default Commission;
