/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Form, Row, Col, Button, Select, Typography, message, DatePicker, Divider } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
//component
import { CancelButton, ConfirmButton } from "@/components/ERP/Button"
// API
import useAPI from "@/hooks/useAPI";
import { getStockHD } from "@/service/apis/PMS/GoStock";
// context
import { DataContext } from "../../index";
const { Text } = Typography;

const DetailModal = ({ call_getCheckHDAd }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { c_state, updateState } = useContext(DataContext);
  const { detailSearch } = c_state;
  // useAPI 取得全部進料檢驗表頭資料
  const call_getStockHD = useAPI(getStockHD);
  // 全部作業單號
  const [s_allNs, set_s_allNs] = useState([]);

  const onFinish = async (values) => {
    // alert("wait");
    const new_data = {
      opcode: values.opcode?.join("|") ?? "",
      N: values.N?.join("|") ?? "",
      batchno: values.batchno?.join("|") ?? "",
      N12: values.N12?.join("|") ?? "",
      N13: values.N13?.join("|") ?? "",
      startDate: values.createT ? dayjs(values.createT[0]).format("YYYY-MM-DD") : null,
      endDate: values.createT ? dayjs(values.createT[1]).format("YYYY-MM-DD") : null,
    };
    // 取得詳細搜尋的表頭資料
    call_getCheckHDAd.request(new_data);
    // 更新資料並關閉跳顯
    updateState({ detailSearch: { show: false, data: values } });
  };

  // 第一次渲染取得全部採購單做下拉選單、賦值初始值
  useEffect(() => {
    // call_getStockHD.request();
    const { data } = detailSearch;
    console.log("data = ", data);
    form.setFieldsValue({
      ...data,
      opcode: data.opcode ?? ["4220", "4130"],
      // createT: data.createT
      //   ? [dayjs(data.createT?.[0]), dayjs(data.createT?.[1])]
      //   : null,
      // sampleT: data.sampleT
      //   ? [dayjs(data.sampleT?.[0]), dayjs(data.sampleT?.[1])]
      //   : null,
    });
  }, []);

  // useAPI 取得全部進料單資料
  useEffect(() => {
    const { data, msg, status } = call_getStockHD;

    if (status === "suc") {
      const allNs =
        data?.map((proItem) => ({
          lable: proItem.jobN,
          value: proItem.jobN,
        })) ?? [];
      console.log(data);
      set_s_allNs(allNs);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getStockHD.status]);

  return (
    <Form onFinish={onFinish} name="basic" form={form} layout="vertical">
      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text>{t("OMS.util.opcode")}</Text>} name="opcode">
            <Select
              mode="multiple"
              allowClear
              placeholder="請選擇單別"
              options={[
                { value: "4220", label: t("OMS.util.opcode_4220") }, // 銷售發貨單
                { value: "4130", label: t("OMS.util.opcode_4130") }, // 銷售退回單
              ]}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 作業單號 */}
          <Form.Item label={<Text>{t("OMS.check.N")}</Text>} name="N">
            <Select
              mode="tags"
              placeholder={t("util.placeholder_select") + t("OMS.check.N")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 批號 */}
          <Form.Item label={<Text>{t("OMS.util.batchno")}</Text>} name="batchno">
            <Select
              mode="tags"
              placeholder={t("util.placeholder_select") + t("OMS.util.batchno")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={8}>
          {/* 報價單號 */}
          <Form.Item label={<Text>{t("OMS.util.N12")}</Text>} name="N12">
            <Select
              mode="tags"
              placeholder={t("util.placeholder_select") + t("OMS.util.N12")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 銷貨單號 */}
          <Form.Item label={<Text>{t("OMS.util.N13")}</Text>} name="N13">
            <Select
              mode="tags"
              placeholder={t("util.placeholder_select") + t("OMS.util.N13")}
              allowClear
              tokenSeparators={[","]}
            ></Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          {/* 創建日期 */}
          <Form.Item label={<Text>{t("OMS.util.createT")}</Text>} name="createT">
            <DatePicker.RangePicker className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-1">
          <CancelButton onClick={() => updateState({ detailSearch: { ...detailSearch, show: false } })} />

          <ConfirmButton type="primary" htmlType="submit" />
        </Col>
      </Row>
    </Form>
  );
};

export default DetailModal;
