import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  Divider,
  message,
  Radio,
  Space,
} from "antd";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
import WpcSetting from "@/components/BDM/WpcSetting";
import { getLine, getProductSelect } from "@/service/apis/ADM/publicAPI";
import { addWpcHd, updateWpcHd, getWpcTb } from "@/service/apis/ADM/edm";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ c_lightData, set_c_lightData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const wpcWrapperRef = useRef(null)
  const call_addWpcHd = useAPI(addWpcHd);
  const call_updateWpcHd = useAPI(updateWpcHd);
  const call_getLine = useAPI(getLine);
  const call_getWpcTb = useAPI(getWpcTb);

  const makeWpcs = (wpcData) => {
    let wpcSN = "1", wpcs = [], subWpcs = []
    
    wpcData.forEach((item, index) => {
      // 如果換途程號了要把之前的所有替代途程丟到主途程內
      if(item.wpcSN !== wpcSN){
        wpcs.push({subWpcs:subWpcs})
        subWpcs=[]
        wpcSN = item.wpcSN
      }

      // 塞入替代途程
      subWpcs.push(item)

      // 如果是最後一筆要把之前的所有替代途程丟到主途程內
      if(index === wpcData.length - 1){
        wpcs.push({subWpcs:subWpcs})
      }
    })

    return wpcs
  }

  const onSubmit = (values) => {
    const type = Object.keys(c_lightData).length > 0 ? "edit" : "create";
    
    values.wpcs?.forEach(wpc => wpc.subWpcs.forEach(subwpc => subwpc.pwcID = subwpc.pwcID?.join("|")))

    if (type === "create") {
      call_addWpcHd.request(values);
    }
    if (type === "edit") {
      call_updateWpcHd.request({ ...c_lightData, ...values });
    }
  };

  const handleAccord = (values) => {
    // 據此創建
    call_getWpcTb.request(c_lightData)
    set_c_lightData([])
  };

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            title: x.pdtNM,
          })) || []
      )
      .catch((e) => {
        message.error("查無料號");
        return [];
      });
  };

  useEffect(() => {
    call_getLine.request("");
  }, []);
  
  useEffect(() => {
    if (call_getLine.status === "err") {
      message.error(call_getLine.msg);
    }
  }, [call_getLine.status]);

  useEffect(() => {
    if (call_getWpcTb.status === "suc") {
      let formData = form.getFieldValue();
      const wpcs = makeWpcs(call_getWpcTb.data.map(x=>({
        ...x,
        pwcID: x.pwcID.split("|").filter((x) => x !== ""),
      })))

      formData.wpcs = wpcs

      form.setFieldsValue({ ...formData });
    }
  }, [call_getWpcTb.status]);

  useEffect(() => {
    if (call_addWpcHd.status === "suc") {
      message.success(call_addWpcHd.msg);
      set_s_isShowModal(false);
    } else if (call_addWpcHd.status === "err") {
      message.error(call_addWpcHd.msg);
    }
  }, [call_addWpcHd.status]);

  useEffect(() => {
    if (call_updateWpcHd.status === "suc") {
      message.success(call_updateWpcHd.msg);
      set_s_isShowModal(false);
    } else if (call_updateWpcHd.status === "err") {
      message.error(call_updateWpcHd.msg);
    }
  }, [call_updateWpcHd.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true, // 是否有效
        isrework: false, // 是否重工
        // wpcset: "系統維護",
        wpcs: [{
          subWpcs:[
            {
              wpcSN: "1",
              nextSN: "T",
              stdwh: 20,
              stdmh: 20,
              stdlt: 3600,
              // 'stdcost': 0,
              planhead: 1,
              pfID: null,
              wsID: null,
              pwcID: [],
              itemno: 0,
              batchbom: 0,
              ieUUID: null,
              // wlID: null,
              warnyield: 90,
              alertyield: 80,
              alertspeed: 90,
              alertstop: 1200,
            }
          ]
        }],
        ...c_lightData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={6}>
          <Form.Item label={t("util.PN")} name="PN" rules={[{ required: true }]}>
            {Object.keys(c_lightData).length > 0 ? (
              <Input disabled={Object.keys(c_lightData).length > 0} />
            ) : (
              <DebounceSelect
                fetchOptions={fetchPNList}
                maxTagCount={10}
                showSearch
                onChange={(value) => {
                  form.setFieldsValue({ PN: value.value, pdtNM: value.title });
                }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.pdtNM")} name="pdtNM">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.wpcset")} name="wpcset">
            <Input disabled placeholder="系統維護"/>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.wpcsetNM")} name="wpcsetNM">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("util.note")} name="note">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("ADM.edm.isrework")} name="isrework" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label={t("util.isvalid")} name="isvalid" rules={[{ required: true }]}>
            <Radio.Group
              options={[
                { value: true, label: t("util.yes") },
                { value: false, label: t("util.no") },
              ]}
            />
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Divider className="m-0 p-0" />
        </Col> */}
        {Object.keys(c_lightData).length === 0 && (
          <Col span={24}>
            <Divider className="m-0" />
            <WpcSetting form={form} wpcWrapperRef={wpcWrapperRef}/>
          </Col>
        )}
        <Col span={24} className="flex place-content-between">
          {Object.keys(c_lightData).length > 0 
          ?<Button type="primary" onClick={handleAccord}>{t("util.header_create")}</Button>
          :<div/>}
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
