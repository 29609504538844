import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";
//-----------------------------------------表頭資料----------------------------------------

// 新建表頭資料
export const addPlanHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPlanHD`, data);
};
// 取得表頭資料
export const getPlanHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPlanHD`, {
    params: data,
  });
};
// 更新表頭資料
export const updatePlanHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePlanHD`, data);
};
// 刪除表頭資料
export const deletePlanHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePlanHD`, data);
};

// -----------------

// 新建表身資料
export const addPlanTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPlanTB`, data);
};
// 取得表身資料
export const getPlanTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPlanTB`, {
    params: data,
  });
};
// 更新表身資料
export const updatePlanTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePlanTB`, data);
};
// 刪除表身資料
export const deletePlanTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePlanTB`, data);
};

// -----------------

// 新建規格評估資料
export const addRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addRec`, data);
};
// 取得規格評估資料
export const getRec = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getRec`, {
    params: data,
  });
};
// 更新規格評估資料
export const updateRec = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateRec`, data);
};
