import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";
//---------------------------------------------------------------------------------
export const getSalesHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSalesHD`, {
    params: data,
  });
};

export const getSalesTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSalesTB`, {
    params: data,
  });
};

export const getCustsHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustsHD`, {
    params: data,
  });
};

export const getCustsTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustsTB`, {
    params: data,
  });
};

export const getSalesDetail = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSalesDetail`, {
    params: data,
  });
};
