import { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Input,
  Space,
  Button,
  InputNumber,
  Select,
  Form,
  Typography,
  message,
  Divider,
  Spin,
  Radio,
} from "antd";
import { PlusOutlined, MinusCircleOutlined, ArrowsAltOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import useAPI from "@/hooks/useAPI";
import { addModelHd, updateModelHd, getModelTb, getLabel } from "@/service/apis/LMS/LabelManage";
import dragCanvas from "../../util/dragCanvas";

import FormHD from "./components/FormHD";
import CreateGruup from "./components/CreateGroup";
import DrawingArea from "./components/DrawingArea";
import FormTB from "./components/FormTB";

const unitToPx = (value, unit) => {
  switch (unit) {
    case "cm":
      return (value / 2.54) * 96;
    case "mm":
      return (value / 25.4) * 96;
    default:
      return value * 96;
  }
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export default function CanvasCreate({ c_lightData, set_s_showModal, set_c_lightData }) {
  const { t } = useTranslation();

  const [form_tb_ref] = Form.useForm();
  const [form_hd_ref] = Form.useForm();
  const drawinArea_ref = useRef(null);
  const tb_ref = useRef(null);

  const call_addModelHd = useAPI(addModelHd);
  const call_updateModelHd = useAPI(updateModelHd);
  const call_getModelTb = useAPI(getModelTb);

  const renderDraw = () => drawinArea_ref?.current?.renderDraw();

  const openAllTbCollapseKey = async () => {
    await renderDraw();
    tb_ref?.current?.openAllTbCollapseKey();
  };

  // 關閉彈窗
  const handleCancel = () => set_s_showModal(false);

  const openTbCollapseKey = (arr) => tb_ref?.current?.openTbCollapseKey(arr);

  // 用來儲存標籤設定資料到後端資料庫
  const saveLabel = async () => {
    // 等待 formHd 驗證欄位
    let formHdData = await form_hd_ref.validateFields();
    // 取得 formTb 的欄位值
    let formTbData = await form_tb_ref.getFieldsValue();

    // 將表單資料格式化成後端 API 所需的格式
    const req = {
      hd: { ...formHdData, encode: "canvas" },
      tb: formTbData.details.map((x, i) => {
        return {
          ...x,
          itemID: i + 1 + "",
          modelID: formHdData.modelID,
          variable: JSON.stringify(x),
        };
      }),
    };

    // 若 c_lightData 為空物件，表示是新增資料，使用 addModelHd API
    // 若 c_lightData 不為空物件，表示是更新資料，使用 updateModelHd API
    if (Object.keys(c_lightData).length === 0) {
      call_addModelHd.request(req);
    } else {
      call_updateModelHd.request(req);
    }
  };

  useEffect(() => {
    if (Object.keys(c_lightData).length > 0) {
      form_hd_ref.setFieldsValue(c_lightData);
      call_getModelTb.request(c_lightData);
    } else {
      renderDraw();
    }
  }, []);

  useEffect(() => {
    if (call_getModelTb.status === "suc") {
      const formData = call_getModelTb.data.map((x) => ({ ...JSON.parse(x.variable) }));
      form_tb_ref.setFieldsValue({ details: formData });
      renderDraw();
    } else if (call_getModelTb.status === "err") {
      message.error(call_getModelTb.msg);
    }
  }, [call_getModelTb.status]);

  useEffect(() => {
    if (call_addModelHd.status === "suc") {
      handleCancel();
    } else if (call_addModelHd.status === "err") {
      message.error(call_addModelHd.msg);
    }
  }, [call_addModelHd.status]);

  useEffect(() => {
    if (call_updateModelHd.status === "suc") {
      handleCancel();
    } else if (call_updateModelHd.status === "err") {
      message.error(call_updateModelHd.msg);
    }
  }, [call_updateModelHd.status]);

  return (
    <Row gutter={[0, 12]}>
      <Col span={24} className="bg-[#eff2f5] p-6">
        <FormHD form_hd_ref={form_hd_ref} c_lightData={c_lightData} />
      </Col>
      <Col span={14} className="pl-6 pr-6">
        <CreateGruup form_tb_ref={form_tb_ref} form_hd_ref={form_hd_ref} renderDraw={renderDraw} />
      </Col>
      <Col span={10} className="pl-6 pr-6 flex justify-end">
        <Button
          type="primary"
          size="large"
          icon={<ArrowsAltOutlined />}
          onClick={openAllTbCollapseKey}
        />
      </Col>
      <Col span={14} className="pl-6 pr-6">
        <DrawingArea
          openTbCollapseKey={openTbCollapseKey}
          form_tb_ref={form_tb_ref}
          form_hd_ref={form_hd_ref}
          ref={drawinArea_ref}
        />
      </Col>
      <Col span={10} className="pr-6">
        <FormTB form_tb_ref={form_tb_ref} c_lightData={c_lightData} ref={tb_ref} />
      </Col>
      <Col span={24} className="pl-6 pr-6 pb-6 flex justify-end">
        <Space>
          <Button onClick={() => set_s_showModal(false)}>{t("util.cancel")}</Button>
          <Button type="primary" onClick={saveLabel}>
            {t("util.ok")}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
