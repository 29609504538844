import React, { useState, useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Button, Divider } from "antd";
import useAPI from "@/hooks/useAPI";
import { addOrdDoc } from "@/service/apis/OMS/Ord";

const FileModal = ({
  s_fileType,
  call_getOrdHD,
  s_fileTypeName,
  hd_values,
  call_updateOrdHd,
  set_s_showFileModal,
}) => {
  // 上傳工程圖檔
  const call_addOrdDoc = useAPI(addOrdDoc);
  const [s_files, set_s_files] = useState([]);
  const preview = () => {
    const element = document.createElement("a");
    element.href = hd_values[s_fileType];
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element); // inspcer
  };
  // 不要問
  let count = 0;
  const props = {
    // name: "file",
    multiple: true,
    beforeUpload: () => false,
    fileList: [],
    onChange(info) {
      const files = info.fileList.map((item) => item.originFileObj);
      // console.log(props);
      // console.log("info = ", info);
      // // 新建
      let formData = new FormData();
      formData.append("opUUID", hd_values.opUUID);
      formData.append("N", hd_values.N);
      formData.append("types", s_fileType);
      // console.log("JSON.stringify = ", JSON.stringify(info.fileList.length));
      // formData.append("files", files);
      for (let i = 0; i < info.fileList.length; i++) {
        // console.log("info.fileList[i] = ", info.fileList[i].originFileObj);
        formData.append("files", info.fileList[i].originFileObj);
      }
      console.log("call_addOrdDoc.status = ", call_addOrdDoc.status);
      // 這樣寫是因為當fileList.length=2, onChange就會跑兩次
      if (count === 0) {
        call_addOrdDoc.request(formData);
        console.log(count);
      }
      count += 1;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // console.log("hd_values = ", hd_values);
  useEffect(() => {
    const { status, msg, data } = call_addOrdDoc;
    if (status === "suc") {
      // set_s_showFileModal(false);
      message.success(msg);
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // 上傳報價簽回
      // if (s_fileType === "entrust") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "entrust",
      //     lifeF: "4",
      //   });
      // }
      // // 上傳客戶委託
      // if (s_fileType === "quoSign") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "quoSign",
      //   });
      // }
      // // 上傳正式合同
      // if (s_fileType === "contract") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "contract",
      //     lifeF: "5",
      //   });
      // }
      // // 上傳檢驗證書
      // if (s_fileType === "inspcer") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "inspcer",
      //     lifeF: "7",
      //   });
      // }
      // // 上傳應收帳款
      // if (s_fileType === "AR") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "AR",
      //     lifeF: "8",
      //   });
      //   set_s_showFileModal(false);
      // }
      // // 上傳收款入帳
      // if (s_fileType === "rcv") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "rcv",
      //     lifeF: "9",
      //   });
      //   set_s_showFileModal(false);
      // }
      // // 上傳統一發票
      // if (s_fileType === "bill") {
      //   call_updateOrdHd.request({
      //     ...data,
      //     APImethod: "bill",
      //     lifeF: "10",
      //   });
      //   set_s_showFileModal(false);
      // }
    }
    if (status === "err") {
      message.error(msg);
    }
    console.log("hd_values = ", hd_values);
  }, [call_addOrdDoc.status]);
  // console.log("s_fileType = ", s_fileType);
  // console.log("hd_values = ", hd_values);
  // console.log("hd_values[s_fileType] = ", hd_values[s_fileType]);
  return (
    <div className="flex flex-col">
      {hd_values[s_fileType]?.length > 0 && s_fileType !== "inspcer" && (
        <>
          <Button type="primary" size="large" onClick={preview}>
            查看 {s_fileTypeName} 檔案
          </Button>
          <Divider />
        </>
      )}
      <Upload.Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
      </Upload.Dragger>
    </div>
  );
};

export default FileModal;
