import { instance } from "../../handlerAPI";
import { pms_apiBasic as apiBasic } from "../../APIBasic";
//-----------------------------------------表頭資料----------------------------------------

// 新建表頭資料
export const addReqHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReqHD`, data);
};
// 取得表頭資料
export const getReqHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReqHD`, {
    params: data,
  });
};

export const getReqHDAd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReqHD`, {
    params: data,
  });
};
// 更新表頭資料
export const updateReqHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReqHD`, data);
};
// 刪除表頭資料
export const deleteReqHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReqHD`, data);
};
// 拋轉採購單
export const reqToPro = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/reqToPro`, data);
};

//-----------------------------------------表身資料----------------------------------------
// 新增表身資料
export const addReqTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addReqTB`, data);
};
// 取得表身資料
export const getReqTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getReqTB`, {
    params: data,
  });
};
// 更新表身資料
export const updateReqTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateReqTB`, data);
};
// 刪除表身資料
export const deleteReqTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteReqTB`, data);
};
