import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";

// 新建表頭資料
export const addFodHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addFodHD`, data);
};
// 取得表頭資料
export const getFodHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getFodHD`, { params: data });
};
// 更新表頭資料
export const updateFodHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateFodHD`, data);
};
// 刪除表頭資料
export const deleteFodHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteFodHD`, data);
};
// ==================================================================================
// 拋轉
export const fodToOrd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/fodToOrd`, data);
};
// ==================================================================================
// 新建表身資料
export const addFodTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addFodTB`, data);
};
// 取得表身資料
export const getFodTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getFodTB`, { params: data });
};
// 更新表身資料
export const updateFodTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateFodTB`, data);
};
// 刪除表身資料
export const deleteFodTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteFodTB`, data);
};
