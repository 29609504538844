import React from "react";
import { useContext } from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
// content
import { ERPContext } from "@/components/Context/SystemContext";

const PNSelect = (props) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);

  return (
    <Select
      showSearch
      placeholder={t("util.util.placeholder_input") + t("util.util.PN")}
      options={c_PNs.options}
      allowClear
      filterOption={(input, option) => {
        return ((option?.PN ?? "") + (option?.dealPN ?? "")).includes(input);
      }}
      {...props}
    />
  );
};

export default PNSelect;
