import { Col, Grid, Modal, Row, Space, Typography, message } from "antd";
import { DeleteButton, EditButton, SuppCheckButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// context
import { MainPageContext } from "../index";
// component
import TbModal from "../Modal/TbModal";
import dayjs from "dayjs";
import { deleteOrdTB } from "@/service/apis/OMS/Ord";
// util function
import { formatAmount } from "@/util/format";
import { getMgmtcat } from "@/service/apis/ADM/manage";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const TbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const {
    hd_values,
    s_disabled,
    s_ordTb,
    call_getOrdHD,
    call_getOrdTB,
    call_updateOrdHd,
    is_editForm,
  } = useContext(MainPageContext);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除表身資料
  const call_deleteOrdTB = useAPI(deleteOrdTB);
  const call_getMgmtcat75 = useAPI(getMgmtcat);
  const [s_mgmtcat75, set_s_mgmtcat75] = useState([]);

  // 編輯資料
  const editData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => t(`OMS.ord.${item}`))?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateOrdHd.request({
            ...hd_values,
            APImethod: "update",
          });
        },
      });
      return;
    }

    set_s_tbModal({ show: true, data: rowData });
  };

  // 刪除資料
  const removeData = (rowData) => {
    if (is_editForm.isEdit) {
      Modal.confirm({
        title: t("OMS.util.sysMsg"), // 系統訊息
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="flex flex-col">
            <span>{t("util.edit_detect_msg1")}</span>
            <span className="text-red-500">
              {is_editForm.editFlag?.map((item) => t(`OMS.ord.${item}`))?.join(",")}
            </span>
            <span>{t("util.edit_detect_msg2")}</span>
          </div>
        ),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 更新表頭
          call_updateOrdHd.request({
            ...hd_values,
            APImethod: "update",
          });
        },
      });
    } else {
      Modal.confirm({
        title: t("OMS.ord.tb") + t("OMS.util.del_confirm"), // 銷貨明細刪除確認
        icon: <ExclamationCircleOutlined />,
        content: t("OMS.util.del_tb_confirm_text"),
        okText: <IconConfirm />, // 確定
        cancelText: <IconCancel />, // 取消
        onOk: async () => {
          // 刪除表身
          call_deleteOrdTB.request(rowData);
        },
      });
    }
  };

  useEffect(() => {
    call_getMgmtcat75.request({ pageType: "75" });
  }, []);

  useEffect(() => {
    if (call_getMgmtcat75.status === "suc") {
      const data = call_getMgmtcat75.data?.reduce((prev, curr) => {
        prev[curr.mgmtitem] = curr.itemNM;
        return prev;
      }, {});
      const options = call_getMgmtcat75.data?.reduce((prev, curr) => {
        const option = {
          label: curr.itemNM,
          value: curr.mgmtitem,
        };
        prev.push(option);
        return prev;
      }, []);
      set_s_mgmtcat75({
        data: data,
        option: options,
      });
    } else if (call_getMgmtcat75.status === "err") {
      message.error(t(`errorCode.${call_getMgmtcat75.msg}`));
      set_s_mgmtcat75([]);
    }
  }, [call_getMgmtcat75.status]);

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deleteOrdTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteOrdTB.status]);

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("OMS.ord.note"), // 樣品名稱
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 檢驗項目
      dataIndex: "pdtNM",
      align: "center",
      width: "15%",
    },
    // {
    //   title: t("util.mtype"), // 料號
    //   dataIndex: "mtype",
    //   align: "center",
    //   width: "8%",
    //   render: (text) => s_mgmtcat75?.data?.[text] || "",
    // },
    {
      title: t("util.pdtspec"), // 測試方法/標準
      dataIndex: "pdtspec",
      align: "center",
      width: "15%",
    },
    {
      title: t("OMS.ord.planday"), // 計畫時間
      dataIndex: "planday",
      align: "center",
      width: "7%",
      // render: (text) => (text ? `${text}天` : text),
      // onCell: (_) => {
      //   return {
      //     style: {
      //       backgroundColor: "#ecf2fa",
      //     },
      //   };
      // },
    },
    {
      title: t("OMS.util.EDT"), // 預計交貨日
      dataIndex: "EDT",
      align: "center",
      width: screens.xxl ? "9.5%" : "9.5%",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: t("OMS.ord.unitprice"), // 檢測費用
      dataIndex: "unitprice",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.ord.demandqty"), // 銷貨數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.itemsum"), // 未稅單項金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
          },
        };
      },
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "6%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 客戶確認數量 */}
          {/* <SuppCheckButton disabled /> */}
          {/* <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" /> */}
          {/* 編輯表身資料 */}
          <EditButton
            // onClick={() => set_s_tbModal({ show: true, data: rowData })}
            onClick={() => editData(rowData)}
          />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data)?.length === 0 ? "create" : "edit";

  return (
    <div className="flex flex-1 flex-col justify-between">
      <CustomTable
        columns={tableColumns}
        rowNumber={screens.xxl ? 10 : 6}
        dataSource={s_ordTb.tb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <Row className="bg-[#F8F8F8] p-2 rounded-md shadow-[0px_3px_6px_#dcdcdc] text-[#4B6EA2]">
        <Col span={2} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          {/* 總項數 */}
          <span className="self-center">{t("OMS.util.itemno_total")}: </span>
          <span className="self-center">{s_ordTb.tb?.length ?? 0}</span>
        </Col>

        {/* 總加扣款項 */}
        {/* <Col span={4} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("OMS.ord.charge")}:</span>
          <span className="self-center">{formatAmount(hd_values.charge)}</span>
        </Col> */}

        {/* 未稅總金額 */}
        <Col span={22} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("OMS.ord.sumtotal")}:</span>
          <span className="self-center">{formatAmount(hd_values.sumtotal)}</span>
        </Col>

        {/* 總稅額 */}
        {/* <Col span={3} className={`flex ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("OMS.util.tax")}: </span>
          <span className="self-center">{formatAmount(hd_values.tax)}</span>
        </Col> */}

        {/* 本幣含稅總金額 */}
        {/* <Col span={5} className={`flex justify-center ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("OMS.ord.local_total")}:</span>
          <span className="self-center">
            {formatAmount(hd_values.total * hd_values.exchangerate)}
          </span>
        </Col> */}

        {/* 含稅總金額(交易幣別) */}
        {/* <Col span={5} className={`flex justify-end ${screens.xxl ? "flex-row" : "flex-col"}`}>
          <span className="self-center">{t("OMS.ord.total")}:</span>
          <span className="self-center"> {formatAmount(hd_values.total)}</span>
        </Col> */}
      </Row>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-center mr-5">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯銷貨明細" : "新增銷貨明細"} */}
              {type === "edit" ? t("util.edit") + t("OMS.ord.tb") : t("util.add") + t("OMS.ord.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("OMS.util.N")}：{hd_values.N} {t("OMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        {s_tbModal.show && (
          <TbModal
            s_mgmtcat75={s_mgmtcat75}
            hd_values={hd_values}
            s_disabled={s_disabled}
            s_tbModal={s_tbModal}
            set_s_tbModal={set_s_tbModal}
            call_getOrdHD={call_getOrdHD}
            call_getOrdTB={call_getOrdTB}
          />
        )}
      </CustomModal>
    </div>
  );
};

export default TbContainer;
