/**
 @parmas num 
 @parmas locale 語言
 @parmas options https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
**/
export function formatNumber(num, locale, options = {}) {
  const tmp = typeof num === "string" ? parseFloat(num) : num;
  return new Intl.NumberFormat(locale, options).format(tmp);
}

export function numberWithCommas(x) {
  if (typeof x !== "number" && typeof x !== "string") {
    return;
  } else {
    return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export const formatAmount = (amount, num = 2) => {
  if (amount === null || amount === undefined) return "0";

  amount = parseFloat(amount);
  if (isNaN(amount)) return "0";

  return amount % 1 === 0
    ? // 如果amount是整數，不需要保留小數點
      amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : // 否則，保留小數點至第二位
      amount.toFixed(num).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const multipleIDtoName = (arr, text, id, name) => {
  if (!Array.isArray(text)) text = [text];

  return arr
    ?.filter((x) => new Set(text).has(x[id]))
    .map((x) => (name ? `${x[id]}_${x[name]}` : x[id]))
    .join(`,\n`);
};

export const multipleIDtoNameWithFullWidth = (arr, text, id, name) => {
  return arr
    ?.filter((x) => new Set(text).has(x[id]))
    .map((x) =>
      name ? (
        <div className="w-full">
          {x[id]}_{x[name]}
        </div>
      ) : (
        <div className="w-full">{x[id]}</div>
      )
    );
};

export const formatAmountWithCurrency = (amount, currency = "IDR") => {
  if (amount === null || amount === undefined) return "0";

  amount = parseFloat(amount);
  if (isNaN(amount)) return "0";

  // 如果货币是IDR，则四舍五入到整数
  if (currency === "IDR") {
    amount = Math.round(amount);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  // 对非IDR货币，四舍五入到小数点后三位
  amount = amount.toFixed(3);
  // 去除尾随的0
  amount = amount.replace(/(\.\d*?)0+$/, "$1");

  // 如果结果以小数点结尾，则去除小数点
  if (amount.endsWith(".")) {
    amount = amount.slice(0, -1);
  }
  // 去除小数点后不必要的0
  // if (amount.indexOf(".00") >= 0) {
  //   amount = amount.substring(0, amount.indexOf("."));
  // } else if (amount.endsWith("0")) {
  //   amount = amount.substring(0, amount.length - 1);
  // }

  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//將IDR以外幣別的金額轉為IDR
export const calculateAmount = (amount, currency, exchangeRate) => {
  // 如果货币是 IDR，则直接返回金额
  if (currency === "IDR") {
    return Math.round(amount);
  }
  // 对于其他货币，乘以汇率并四舍五入
  return Math.round(amount * exchangeRate);
};
