import {
  IconBasicData,
  IconDigital,
  IconDigitalKanban,
  IconEquipment,
  IconException,
  IconMold,
  IconOMS,
  IconPMS,
  IconReport,
  IconSystemInformation,
  IconTechData,
  IconWarehouseManager,
} from "@/components/Icon/Navbar";

import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// superman 可以看到全部

// 這個函數接受一個菜單項目的陣列和一個驗證對象，並返回過濾後的菜單項目陣列
const filterMenuItem = (menuItem) =>
  menuItem
    // 過濾出 auth 屬性為  true 的項目
    .filter((item) => item.auth === true)
    // 將每個項目複製一份並將 auth 屬性設為 true
    .map((item) => {
      const { icon, label, ...other } = item;
      let newItem = { ...item, auth: true, icon: <item.icon {...other} /> };

      // newItem.icon.props = newItem;
      // 如果這個項目有子菜單，過濾出子菜單中 auth 屬性為 undefined 或 true 的項目
      if (Array.isArray(item.children)) {
        newItem.children = item.children
          .filter((child) => child.auth === true)
          // 將每個子項目複製一份並將 auth 屬性設為 true
          .map((child) => ({ ...child, auth: true }));
      }
      return newItem;
    });

// 篩選出第一個 auth 屬性為 true 的項目
export const findFirstTrueItem = (menuItem) => {
  for (const item of menuItem) {
    if (item.auth === true) {
      return item.key;
    }

    if (Array.isArray(item.children)) {
      const firstTrueChild = item.children.find((child) => child.auth === true);
      if (firstTrueChild) {
        return firstTrueChild.key;
      }
    }
  }

  return null;
};

const MainItem = ({ auth, c_userData }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  console.log("c_userData = ", c_userData);
  console.log("auth = ", auth);
  let menuItem = [
    {
      // A. 业务管理
      label: <span>{`${t("util.nav_A")}`}</span>,
      key: "A",
      icon: IconBasicData,
      // auth: auth?.A,
      auth: true,
      children: [
        {
          label: (
            <NavLink to="/oms/ord">
              {/* 报价订单作业 */}
              {`${t("util.nav_B-2")}`}
            </NavLink>
          ),
          key: "/oms/ord",
          // auth: auth?.["B-2"],
          auth: true,
        },
        {
          label: (
            <NavLink to="/oms/performance">
              {/* 项目业绩查询 */}
              {`${t("util.nav_A-2")}`}
            </NavLink>
          ),
          key: "/oms/performance",
          auth: true,
        },
        {
          label: (
            <NavLink to="/bdm/contributionraking">
              {/* A-3. 客户贡献查詢 */}
              {`${t("util.nav_A-3")}`}
            </NavLink>
          ),
          key: "/bdm/contributionraking",
          // disabled: true,
          auth: true,
        },
        {
          label: <NavLink to="/bdm/salesranking">业务业绩查询</NavLink>,
          key: "/bdm/salesranking",
          auth: true,
        },
        // {
        //   label: <NavLink to="/oms/payment">订单付款查询</NavLink>,
        //   key: "/oms/payment",
        //   auth: true,
        // },
        {
          // 檢測項目主檔
          label: <NavLink to="/bdm/detection-manage">{`${t("util.nav_D-3")}`}</NavLink>,
          key: "/bdm/detection-manage",
          auth: true,
        },
        {
          // 客戶管理
          label: <NavLink to="bdm/client">{"客户关係管理"}</NavLink>,
          key: "/bdm/client",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },
        {
          // 客戶管理
          label: <NavLink to="/bdm/clientdev">{"客户开发管理"}</NavLink>,
          key: "/bdm/clientdev",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },
        {
          label: (
            <NavLink to="/oms/report-salesummary/during">
              {/* 7-I5&nbsp; */}
              {"业绩汇总表_期间"}
            </NavLink>
          ),
          key: "/oms/report-salesummary/during",
          auth: c_userData.peopleNM === "superman",
        },
        {
          label: (
            <NavLink to="/oms/report-salesummary/custalias">
              {/* 7-I6&nbsp; */}
              {"业绩汇总表_客户"}
            </NavLink>
          ),
          key: "/oms/report-salesummary/custalias",
          auth: c_userData.peopleNM === "superman",
        },
      ],
    },
    {
      // B. 採購管理
      label: <span>{`${t("util.nav_B")}`}</span>,
      key: "B",
      icon: IconBasicData,
      // auth: auth?.A,
      auth: true,
      children: [
        {
          label: (
            <NavLink to="/pms/outsourcing">
              {/* 外测派发作业 */}
              {`${t("util.nav_B-1")}`}
            </NavLink>
          ),
          key: "/pms/outsourcing",
          auth: true,
        },
        {
          label: <NavLink to="/pms/req">一般请购作业</NavLink>,
          key: "/pms/req",
          auth: true,
          disabled: c_userData.peopleNM === "superman" ? false : true,
        },
        {
          label: (
            <NavLink to="/pms/pro">
              {/* 外測派發作業 */}
              {`一般采购作业`}
            </NavLink>
          ),
          key: "/pms/pro",
          auth: true,
          // disabled: c_userData.peopleNM === "superman" ? false : true,
        },
        {
          label: <NavLink to="/pms/instock">采购进货作业</NavLink>,
          key: "/pms/instock",
          auth: true,
          // disabled: c_userData.peopleNM === "superman" ? false : true,
        },
        // {
        //   label: <NavLink to="/pms/gostock">进料检验查询</NavLink>,
        //   key: "/pms/gostock",
        //   auth: true,
        //   // disabled: c_userData.peopleNM === "superman" ? false : true,
        // },
        // {
        //   label: <NavLink to="/pms/return">进货退出作业</NavLink>,
        //   key: "/pms/return",
        //   auth: true,
        //   // disabled: c_userData.peopleNM === "superman" ? false : true,
        // },
      ],
    },
    {
      label: <span>库存管理</span>,
      key: "F",
      icon: IconBasicData,
      auth: auth?.E,
      // auth: true,
      children: [
        // {
        //   // label: <NavLink to="/ims/pro-instock">代理入库作业</NavLink>,
        //   label: <NavLink to="/ims/pro-instock">申请领用作业</NavLink>,
        //   key: "/ims/pro-instock",
        //   auth: true,
        // },
        // {
        //   // label: <NavLink to="/ims/pro-outstock">代理出库作业</NavLink>,
        //   label: <NavLink to="/ims/pro-outstock">申请入库作业</NavLink>,
        //   key: "/ims/pro-outstock",
        //   auth: true,
        // },
        {
          label: <NavLink to="/ims/plan-in">库房入库作业</NavLink>,
          key: "/ims/plan-in",
          auth: auth?.["E-1"],
        },
        {
          label: <NavLink to="/ims/plan-out">库房领用作业</NavLink>,
          key: "/ims/plan-out",
          auth: auth?.["E-2"],
        },
        {
          label: <NavLink to="/ims/stock-search">库存状况查询</NavLink>,
          key: "/ims/stock-search",
          auth: auth?.["E-3"],
        },
        {
          label: <NavLink to="/ims/inv-project">库房盘点作业</NavLink>,
          key: "/ims/inv-project",
          auth: auth?.["E-4"],
        },
      ],
    },
    {
      // label: <span>C. 财务管理</span>,
      label: <span>{`${t("util.nav_C")}`}</span>,
      key: "C",
      icon: IconBasicData,
      // auth: auth?.C,
      auth: true,
      // auth: c_userData.peopleNM === "superman" ? true : false,
      children: [
        {
          label: <NavLink to="/oms/run-a3">订单收款作业</NavLink>,
          key: "/oms/run-a3",
          auth: c_userData.peopleNM === "superman" ? true : false,
        },
        {
          // 應收賬起管理,
          label: <NavLink to="/oms/ap-manage">{`${t("util.nav_C-1")}`}</NavLink>,
          key: "/oms/ap-manage",
          // auth: true,
          auth: auth?.["C-1"],
        },
        {
          label: <NavLink to="/pms/ap-manage">應付帳款管理</NavLink>,
          key: "/c-2",
          auth: auth?.["C-2"],
          disabled: c_userData.peopleNM === "superman" ? false : true,
        },
        {
          label: (
            <NavLink to="/oms/profit-analysis">
              {/* C-5. 每月利润分析 */}
              {`${t("util.nav_C-5")}`}
            </NavLink>
          ),
          disabled: false,
          key: "/c-3",
          auth: auth?.["C-4"],
        },
        {
          label: <NavLink to="/oms/runa1">提成核决表</NavLink>,
          key: "/oms/runa1",
          auth: c_userData.peopleNM === "superman" ? true : false,
        },
        // {
        //   label: (
        //     <NavLink to="/oms/postage">
        //       {`C-5. 邮寄费用管理`}
        //     </NavLink>
        //   ),
        //   key: "/oms/postage",
        //   auth: true,
        // },
      ],
    },
    {
      // label: <span>D. 系統管理</span>,
      label: <span>{`${t("util.nav_D")}`}</span>,
      key: "D",
      icon: IconBasicData,
      auth: true,
      children: [
        {
          // label: <NavLink to="/tdm/plant-manage">D-1. 公司主档(3A)</NavLink>,
          label: <NavLink to="/tdm/plant-manage">{`${t("util.nav_D-1")}`}</NavLink>,
          key: "/tdm/plant-manage",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },
        {
          label: (
            <NavLink to="/tdm/warehouse-manage">
              <span>{t("ADM.warehouse-manage.pageHeader")}</span>
            </NavLink>
          ),
          key: "/tdm/warehouse-manage",
          auth: true,
        },
        {
          label: (
            <NavLink
              to="/bdm/pn-manage"
              // disabled
              // style={{
              //   pointerEvents: "none",
              //   cursor: "not-allowed",
              //   color: "rgba(148, 143, 143, .5) !importnat",
              // }}
            >
              物料主档
            </NavLink>
          ),
          key: "/bdm/pn-manage",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },
        {
          // label: <NavLink to="/sdm/user-manage">D-2. 员工资料(4A)</NavLink>,
          label: <NavLink to="/sdm/user-manage">{`${t("util.nav_D-2")}`}</NavLink>,
          key: "/sdm/user-manage",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },

        // {
        //   // 客戶管理
        //   label: <NavLink to="/sdm/client">{`${t("util.nav_D-4")}`}</NavLink>,
        //   key: "/sdm/client",
        //   // auth: c_userData.peopleNM === "superman",
        //   auth: true,
        // },

        {
          // label: <NavLink to="/sdm/company">D-3. 厂商资料(4B)</NavLink>,
          label: <NavLink to="/sdm/company">{`${t("util.nav_D-5")}`}</NavLink>,
          key: "/sdm/company",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },
        {
          // label: <NavLink to="/sdm/company">D-3. 厂商资料(4B)</NavLink>,
          label: <NavLink to="/sdm/supp">{`${t("util.nav_D-5-1")}`}</NavLink>,
          key: "/sdm/supp",
          // auth: c_userData.peopleNM === "superman",
          auth: true,
        },

        // {
        //   // label: <NavLink to="/sdm/data-auth-manage">D-5. 功能授权(4E)</NavLink>,
        //   label: <NavLink to="/sdm/data-auth-manage">{`D-6. ${t("util.nav_D-7")}`}</NavLink>,
        //   key: "/sdm/data-auth-manage",
        //   // auth: c_userData.peopleNM === "superman",
        //   auth: true,
        // },
        // {
        //   // label: <NavLink to="/sdm/function-params-manage">D-6. 系统参数(4G)</NavLink>,
        //   label: <NavLink to="/sdm/function-params-manage">{`${t("util.nav_D-8")}`}</NavLink>,
        //   key: "/sdm/function-params-manage",
        //   // auth: c_userData.peopleNM === "superman",
        //   auth: true,
        // },
        {
          // label: <NavLink to="/sdm/function-params-manage">D-6. 系统参数(4G)</NavLink>,
          label: (
            <NavLink
              to="test01"
              disabled
              style={{
                pointerEvents: "none",
                cursor: "not-allowed",
                color: "rgba(148, 143, 143, .5) !importnat",
              }}
            >
              <span className="text-gray-400">檢驗項目主檔</span>
            </NavLink>
          ),
          key: "test01",
          disabled: true,
          auth: true,
        },
        // {
        //   // label: <NavLink to="/sdm/function-params-manage">D-6. 系统参数(4G)</NavLink>,
        //   label: <NavLink to="/tdm/production-line-manage">D-8. 产线规划维护</NavLink>,
        //   key: "/tdm/production-line-manage",
        //   // auth: c_userData.peopleNM === "superman",
        //   auth: true,
        // },

        // {
        //   label: <NavLink to="/bdm/edm">D-10. 技术资料维护</NavLink>,
        //   key: "/bdm/edm",
        //   auth: true,
        // },
      ],
    },
    // {
    //   label: <span>销货作业</span>,
    //   key: "E",
    //   icon: IconBasicData,
    //   auth: true,
    //   children: [
    //     {
    //       label: <NavLink to="/oms/ord_ori">销货作业</NavLink>,
    //       key: "/oms/ord_ori",
    //       // auth: auth?.["B-2"],
    //       auth: true,
    //     },
    //     {
    //       label: <NavLink to="/oms/outstock">发货作业</NavLink>,
    //       key: "/oms/outstock",
    //       // auth: auth?.["B-2"],
    //       auth: true,
    //     },
    //     {
    //       label: <NavLink to="/oms/check">销货检验查询</NavLink>,
    //       key: "/oms/check",
    //       // auth: auth?.["B-2"],
    //       auth: true,
    //     },
    //     {
    //       label: <NavLink to="/oms/return">销货退回</NavLink>,
    //       key: "/oms/return",
    //       // auth: auth?.["B-2"],
    //       auth: true,
    //     },
    //   ],
    // },
  ];

  return filterMenuItem(menuItem);
};
export default MainItem;
