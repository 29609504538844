/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { message, Spin } from "antd";
// api
import useAPI from "@/hooks/useAPI";
import { getWh } from "@/service/apis/ADM/publicAPI";
// component
import Project from "./Project";
import HD from "./HD";

const InvProject = () => {
  const call_getWh = useAPI(getWh);
  const projectRef = useRef();

  const [c_lightData, set_c_lightData] = useState({}); // 表頭亮起來的資料

  const [s_spinning, set_s_spinning] = useState(false);

  const [s_wh, set_s_wh] = useState([]);

  const [s_tabKey, set_s_tabKey] = useState("4020");

  useEffect(() => {
    call_getWh.request();
  }, []);

  // useAPI 取得商品類別
  useEffect(() => {
    const { status, msg, data } = call_getWh;
    if (status === "suc") {
      set_s_wh(data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getWh.status]);

  return (
    <Spin spinning={s_spinning}>
      {/*  */}
      <Project
        ref={projectRef}
        s_tabKey={s_tabKey}
        set_s_tabKey={set_s_tabKey}
        s_wh={s_wh}
        c_lightData={c_lightData}
        set_c_lightData={set_c_lightData}
      />

      {/* 表頭 */}
      {Object.keys(c_lightData).length > 0 && (
        <HD
          project_ref={projectRef.current}
          s_tabKey={s_tabKey}
          set_s_tabKey={set_s_tabKey}
          s_spinning={s_spinning}
          set_s_spinning={set_s_spinning}
          c_lightData={c_lightData}
          set_c_lightData={set_c_lightData}
        />
      )}
    </Spin>
  );
};

export default InvProject;
