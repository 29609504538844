/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IconPen, IconGarbage, IconCheck, IconClose } from "@/components/Icon/Action";
// API
import { getAdd, deleteAdd } from "@/service/apis/ADM/functionParamsManage";
import useAPI from "@/hooks/useAPI";
// util component
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// component
import ModalConintaer from "./ModalContainer/index";

const ERPAdded = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { s_searchData, type, addTypeMap } = props;
  const [s_editData, set_s_editData] = useState({});
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getAdd = useAPI(getAdd);
  const call_deleteAdd = useAPI(deleteAdd);

  const tableColumns = [
    {
      title: t("ADM.trading-pattern.addtype"), // 交易條件
      dataIndex: "addtype",
      width: "17%",
      filters: addTypeMap.map((x) => ({
        text: x.label,
        value: x.value,
      })),
      onFilter: (value, record) => record.addtype.indexOf(value) === 0,
      render: (text) => addTypeMap.filter((x) => x.value === text)[0]?.label,
    },
    {
      title: t("ADM.trading-pattern.additem"),
      dataIndex: "additem",
      width: "8%",
      align: "center",
    },
    {
      title: t("util.itemNM"),
      dataIndex: "itemNM",
      width: "20%",
    },
    // {
    //   title: t("ADM.trading-pattern.PMS"),
    //   dataIndex: "PMS",
    //   align: "center",
    //   width: "10%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    // {
    //   title: t("ADM.trading-pattern.OMS"),
    //   dataIndex: "OMS",
    //   align: "center",
    //   width: "10%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    
    {
      title: t("util.note"),
      dataIndex: "note",
      width: "30%",
    },
    {
      title: t("util.isvalid"),
      dataIndex: "isvalid",
      align: "center",
      width: "10%",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"),
      dataIndex: "action",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => call_getAdd.request({ item: s_searchData.query });

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteAdd.request(rowData),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    onSearch();
  }, [s_searchData.page, s_searchData.pageSize]);

  useEffect(() => {
    // 重新拿到資料都把s_editData清空
    set_s_editData({});
    if (call_getAdd.status === "suc") {
      message.success(call_getAdd.msg);
      set_s_tableData(
        call_getAdd.data
          // 只看到所屬tab的資料
          ?.filter((item) => item[type] === true)
          ?.map((x, i) => ({
            ...x,
            key: i,
          })) || []
      );
    } else if (call_getAdd.status === "err") {
      message.error(call_getAdd.msg);
      set_s_tableData([]);
    }
  }, [call_getAdd.status]);

  useEffect(() => {
    if (call_deleteAdd.status === "suc") {
      message.success(call_deleteAdd.msg);
      // delete之後 重call表格資料
      onSearch();
    } else if (call_deleteAdd.status === "err") {
      message.error(call_deleteAdd.msg);
    }
  }, [call_deleteAdd.status]);
  console.log("s_searchData = ", s_searchData);
  console.log(
    "s_searchData.addtype = ",
    s_searchData.addtype === ""
      ? s_tableData
      : s_tableData.filter((item) => item.addtype === s_searchData.addtype)
  );
  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={
          s_searchData.addtype === ""
            ? s_tableData
            : s_tableData.filter((item) => item.addtype === s_searchData.addtype)
        }
        loading={call_getAdd.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
          };
        }}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.trading-pattern.tabAdded")}`
            : `${t("util.add")}${t("ADM.trading-pattern.tabAdded")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          onSearch();
        }}
      >
        <ModalConintaer
          type={type}
          s_editData={s_editData}
          set_s_editData={set_s_editData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={{
            addTypeMap: addTypeMap,
          }}
        />
      </CustomModal>
    </>
  );
});

export default ERPAdded;
