import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";
// 取得客戶資料
export const getCustHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustHD`, {
    params: data,
  });
};

// 取得客戶表身資料
export const getCustTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustTB`, {
    params: data,
  });
};

// 取得幣別資料
export const getCurrency = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getCurrency`, {
    params: data,
  });
};

// 取得員工資料
export const getStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getStaff`, { params: data });
};
