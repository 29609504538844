import { Button, Col, Collapse, Form, Input, Radio, Row, Select, Space, message } from "antd";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { addStaffPerm, getRole, getStaff, modifyStaffPerm } from "@/service/apis/ADM/userManage";

import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addStaffPerm = useAPI(addStaffPerm);
  const call_modifyStaffPerm = useAPI(modifyStaffPerm);
  const call_getRole = useAPI(getRole);
  const call_getStaff = useAPI(getStaff);

  const options = [
    { value: true, label: t("util.yes") },
    { value: false, label: t("util.no") },
  ];

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    values.account = values.staffID;
    if (values.role && values.role.length > 0) {
      values.role = values.role.join("|");
    }

    if (type === "create") {
      call_addStaffPerm.request(values);
    }

    if (type === "edit") {
      call_modifyStaffPerm.request(values);
    }
  };

  // 找出多個 auth 中 為true的key
  function filterObjects(arr) {
    const result = {};
    for (const item of arr) {
      for (const prop in item) {
        if (item[prop]) {
          result[prop] = true;
        } else {
          result[prop] = undefined;
        }
      }
    }
    return result;
  }

  useEffect(() => {
    call_getRole.request();
    call_getStaff.request();
  }, []);

  useEffect(() => {
    if (call_addStaffPerm.status === "suc") {
      message.success(call_addStaffPerm.msg);
      set_s_isShowModal(false);
    } else if (call_addStaffPerm.status === "err") {
      message.error(t(`errorCode.${call_addStaffPerm.msg}`));
    }
  }, [call_addStaffPerm.status]);

  useEffect(() => {
    if (call_modifyStaffPerm.status === "suc") {
      message.success(call_modifyStaffPerm.msg);
      set_s_isShowModal(false);
    } else if (call_modifyStaffPerm.status === "err") {
      message.error(t(`errorCode.${call_modifyStaffPerm.msg}`));
    }
  }, [call_modifyStaffPerm.status]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit} initialValues={{ ...s_editData }}>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          {/* 員工姓名 */}
          <Form.Item
            label={t("ADM.user-manage.peopleNM")}
            name="peopleNM"
            rules={[{ required: true }]}
          >
            <Select
              disabled={Object.keys(s_editData).length > 0}
              onChange={(_, data) => form.setFieldsValue({ ...data.data })}
              options={call_getStaff.data.map((x) => ({
                label: x.peopleNM,
                value: x.peopleNM,
                data: x,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 員工帳號 */}
          <Form.Item label={t("ADM.user-manage.account")} name="staffID">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 密碼 */}
          <Form.Item label={t("ADM.user-manage.pwd")} name="pwd">
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          {/* 角色權限 */}
          <Form.Item label={t("ADM.user-manage.role")} name="role" rules={[{ required: true }]}>
            <Select
              mode="tags"
              options={call_getRole.data.map((x) => ({
                label: x.itemNM,
                value: x.itemID,
                data: x,
              }))}
              onChange={(_, allData) => {
                const authArr = allData.map((x) => JSON.parse(x.data.auth));
                const result = filterObjects(authArr);
                form.setFieldsValue({ ...result });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="MOM" key="1">
              <Collapse defaultActiveKey={["A"]}>
                <Panel forceRender header={`A. ${t("util.nav_A")}`} key="A">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_A")} name="A">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-1")} name="A-1">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-2")} name="A-2">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6}>
                      <Form.Item label={t("util.nav_A-3")} name="A-3">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`B. ${t("util.nav_B")}`} key="B">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B")} name="B">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-1")} name="B-1">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-2")} name="B-2">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_B-3")} name="B-3">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`C. ${t("util.nav_C")}`} key="C">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C")} name="C">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-1")} name="C-1">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-2")} name="C-2">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-3")} name="C-3">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-4")} name="C-4">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-5")} name="C-5">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_C-6")} name="C-6">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="报价订单作业_报价审批" name="orderCheck">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`D. ${t("util.nav_D")}`} key="D">
                  <Row>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D")} name="D">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-1")} name="D-1">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-2")} name="D-2">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-3")} name="D-3">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-4")} name="D-4">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-5")} name="D-5">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={t("util.nav_D-6")} name="D-6">
                        <Radio.Group options={options} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>

                <Panel forceRender header={`E. 库存管理`} key="E">
                  <Row>
                    <Col span={6}>
                      <Form.Item label="库存管理" name="E">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房入库作业" name="E-1">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房领用作业" name="E-2">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库存状况查询" name="E-3">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="库房盘点作业" name="E-4">
                        <Radio.Group options={options} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            </Panel>
          </Collapse>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
