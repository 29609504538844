import apiBasic from "../../APIBasic";
// 存一些全局用的API
import { instance } from "../../handlerAPI";

export const login = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/login`, data);
};

export const logout = () => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/logout`);
};

export const getUserPerm = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getUserPerm`);
};

export const getStaff = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getStaff`, { params: data });
};

//-----------------------------------------料號----------------------------------------
export const getProduct = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProduct`, {
    params: _params,
  });
};

export const getProductSelect = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getProductSelect`, {
    params: _params,
  });
};
export const getDetectionSelect = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getDetectionSelect`, {
    params: _params,
  });
};

//-----------------------------------------廠商資料----------------------------------------
export const getSuppHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getSuppHd`, {
    params: data,
  });
};

//-----------------------------------------聯絡人----------------------------------------
export const getSuppTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getSuppTb`, {
    params: data,
  });
};

//-----------------------------------------客戶資料----------------------------------------
export const getCustHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustHd`, {
    params: data,
  });
};

//-----------------------------------------聯絡人----------------------------------------
export const getCustTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getCustTb`, {
    params: data,
  });
};

//-----------------------------------------幣別----------------------------------------
export const getCurrency = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getCurrency`, {
    params: data,
  });
};

//-----------------------------------------使用單位----------------------------------------
export const getUnitType = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/selector/getUnitType`);
};

//-----------------------------------------取得車間----------------------------------------
export const getPS = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPS`, {
    params: _params,
  });
};

//----------------------------------------- 新增車間資料 ----------------------------------------
export const addPS = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPS`, data);
};

//----------------------------------------- 編輯車間資料 ----------------------------------------
export const modifyPS = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPS`, data);
};

//----------------------------------------- 刪除車間資料 ----------------------------------------
export const deletePS = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePS`, data);
};

//-----------------------------------------異常看版----------------------------------------X
export const getAllAbc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/abc/getAllAbc`, {
    params: _params,
  });
};

export const judge = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/abc/judge`, data);
};

//----------------------------------------- 設備生產狀況 ----------------------------------------
export const getProductionInfo = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/device/getData`, {
    params: _params,
  });
};

export const getWsWl = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWsWl`);
};

//-----------------------------------------測試----------------------------------------
export const getAbcSelect = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/abc/getAbcSelect`);
};

//----------------------------------------- 取得APS資料 ----------------------------------------
export const getAps = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAps`, {
    params: _params,
  });
};

export const modifyAps = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyAps`, data);
};

export const toWos = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/toWos`, data);
};

export const apsClose = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/apsClose`, data);
};

//----------------------------------------- 搜尋工線資料 ----------------------------------------
export const getLine = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getLine`, {
    params: _params,
  });
};

//----------------------------------------- 新增工線資料 ----------------------------------------
export const addLine = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addLine`, data);
};

//----------------------------------------- 刪除工線資料 ----------------------------------------
export const deleteLine = (data) =>
  instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteLine`, data);

//----------------------------------------- 編輯工線資料 ----------------------------------------
export const modifyLine = (data) =>
  instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyLine`, data);

//----------------------------------------- 新增工站資料 ----------------------------------------
export const addStation = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStation`, data);
};

//----------------------------------------- 編輯工站資料 ----------------------------------------
export const updateStation = (data) =>
  instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateStation`, data);

//----------------------------------------- 刪除工站資料 ----------------------------------------
export const deleteStation = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStation`, data);
};

//----------------------------------------- 搜尋工站資料 ----------------------------------------
export const getStation = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStation`, {
    params: _params,
  });
};

//----------------------------------------- 取得全部golden途程表頭 ----------------------------------------
export const getWpcHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcHd`, {
    params: data,
  });
};

//----------------------------------------- 新增golden途程表頭  ----------------------------------------
export const addWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWpcHd`, data);
};

//----------------------------------------- 編輯golden途程表頭  ----------------------------------------
export const updateWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWpcHd`, data);
};

//----------------------------------------- 刪除golden途程表頭  ----------------------------------------
export const deleteWpcHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWpcHd`, data);
};

//----------------------------------------- 取得全部工站ID ----------------------------------------X
export const getAllWsID = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWsID`);
};

//----------------------------------------- 取得在製工站資料 ----------------------------------------X
export const getAllProduction = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllProduction`);
};

//----------------------------------------- 取得工站途單資料 ----------------------------------------
export const getWpcRun = (_params) => {
  const { wsID, PN, woN, tab } = _params;
  // const { wsID, tab } = data;
  // console.log(
  //   "url = ",
  //   `${apiBasic.conn}://${apiBasic.url}/api/getWpcRun?wsID=${wsID}&lifeF=${tab}`
  // );
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/getWpcRun?wsID=${wsID}&lifeF=${tab}&PN=${PN}&woN=${woN}`
  );
  // return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpcRun`, { params: _params});
};

//----------------------------------------- 取得全部工站履歷 ----------------------------------------X
export const getAllResume = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllResume`, {
    params: _params,
  });
};

//----------------------------------------- 取得工站履歷明細 ----------------------------------------
export const getResumeWpc = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getResumeWpc`, {
    params: _params,
  });
};

//----------------------------------------- 取得工線日報查詢資料 ----------------------------------------
export const getReport = (data) => {
  const { s_lifeF, startDate, endDate, wsID, wlID, pageSize, page } = data;
  return instance.get(
    `${apiBasic.conn}://${apiBasic.url}/api/getReport?lifeF=${s_lifeF}&start=${startDate}&end=${endDate}&wsID=${wsID}&wlID=${wlID}&page=${page}&pageSize=${pageSize}`
  );
};

//----------------------------------------- 生產領用 ----------------------------------------
//----------------------------------------- 取得生產領用資料  ----------------------------------------
export const getHd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getHd`, {
    params: data,
  });
};

export const getTb = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getTb`, {
    params: data,
  });
};
//----------------------------------------- 新增生產領用資料 表頭+表身  ----------------------------------------
export const addHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addHd`, data);
};
//----------------------------------------- 刪除生產領用資料 表頭  ----------------------------------------
export const deleteHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteHd`, data);
};

//----------------------------------------- 刪除生產領用資料 表頭----------------------------------------
export const modifyHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyHd`, data);
};

//----------------------------------------- 刪除生產領用資料 表身 ----------------------------------------
export const deleteTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteTb`, data);
};

//----------------------------------------- 編輯生產領用資料  表身----------------------------------------
export const modifyTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyTb`, data);
};

//----------------------------------------- 取得全部倉別資料 ----------------------------------------X
export const getAllWh = () => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getAllWh`);
};

//----------------------------------------- 搜尋倉別資料 ----------------------------------------
export const getWh = (param) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWh`, {
    params: param,
  });
};

//----------------------------------------- 新增倉別資料 ----------------------------------------
export const addWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWh`, data);
};

//----------------------------------------- 編輯倉別資料 ----------------------------------------
export const modifyWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWh`, data);
};

export const closeWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/closeWh`, data);
};

//----------------------------------------- 刪除倉別資料 ----------------------------------------
export const deleteWh = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWh`, data);
};

//----------------------------------------- 取得單一產品的全部途層 ----------------------------------------
export const getWpc = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWpc?PN=${id}`);
};

//----------------------------------------- 庫管條件設定  ----------------------------------------
//----------------------------------------- 取得全部庫管 ----------------------------------------
export const getStock = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStock`, {
    params: data,
  });
};

//----------------------------------------- 新增庫管資料  ----------------------------------------
export const addStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStock`, data);
};

//----------------------------------------- 編輯庫管資料 ----------------------------------------
export const modifyStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyStock`, data);
};

//----------------------------------------- 刪除庫管資料  ----------------------------------------
export const deleteStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStock`, data);
};

//----------------------------------------- 搜尋報工作業權限 ----------------------------------------
export const getWosID = (id) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWosID?staffID=${id}`);
};

//----------------------------------------- 新增報工作業權限 ----------------------------------------
export const addWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWosPerm`, data);
};

//----------------------------------------- 刪除報工作業權限 ----------------------------------------
export const deleteWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWosPerm`, data);
};

//----------------------------------------- 編輯報工作業權限 ----------------------------------------
export const modifyWosPerm = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWosPerm`, data);
};

//----------------------------------------- 取得全部設備資料 ----------------------------------------
export const getWe = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWe`, {
    params: data,
  });
};

//----------------------------------------- 新增設備資料  ----------------------------------------
export const addWe = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWe`, data);
};

//----------------------------------------- 編輯設備資料 ----------------------------------------
export const modifyWe = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWe`, data);
};

//----------------------------------------- 刪除設備資料  ----------------------------------------
export const deleteWe = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWe`, data);
};

//----------------------------------------- 取得檢測資料 ----------------------------------------
export const getWi = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWi`, {
    params: data,
  });
};

//----------------------------------------- 新增檢測資料  ----------------------------------------
export const addWi = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWi`, data);
};

//----------------------------------------- 編輯檢測資料 ----------------------------------------
export const modifyWi = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyWi`, data);
};

//----------------------------------------- 刪除檢測資料  ----------------------------------------
export const deleteWi = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWi`, data);
};

//----------------------------------------- 取得製程資料 ----------------------------------------
export const getPF = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPF`, {
    params: data,
  });
};

//----------------------------------------- 新增製程資料 ----------------------------------------
export const addPF = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPF`, data);
};

//----------------------------------------- 編輯製程資料 ----------------------------------------
export const updatePF = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePF`, data);
};

//----------------------------------------- 刪除製程資料 ----------------------------------------
export const deletePF = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePF`, data);
};

//----------------------------------------- 取得成本中心資料 ----------------------------------------
export const getPCC = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPCC`, {
    params: data,
  });
};

//----------------------------------------- 新增成本中心資料 ----------------------------------------
export const addPCC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPCC`, data);
};

//----------------------------------------- 編輯成本中心資料 ----------------------------------------
export const updatePCC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePCC`, data);
};

//----------------------------------------- 刪除成本中心資料 ----------------------------------------
export const deletePCC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePCC`, data);
};

//----------------------------------------- 取得工作中心資料 ----------------------------------------
export const getPWC = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPWC`, {
    params: data,
  });
};

//----------------------------------------- 新增工作中心資料 ----------------------------------------
export const addPWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPWC`, data);
};

//----------------------------------------- 編輯工作中心資料 ----------------------------------------
export const updatePWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updatePWC`, data);
};

//----------------------------------------- 刪除工作中心資料 ----------------------------------------
export const deletePWC = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePWC`, data);
};
//----------------------------------------- 新增工段資料 ----------------------------------------
export const addWg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWg`, data);
};

//----------------------------------------- 編輯工段資料 ----------------------------------------
export const updateWg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWg`, data);
};

//----------------------------------------- 刪除工段資料 ----------------------------------------
export const deleteWg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWg`, data);
};

//----------------------------------------- 搜尋工段資料 ----------------------------------------
export const getWg = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWg`, {
    params: _params,
  });
};

//----------------------------------------- 編輯採點資料 ----------------------------------------
export const updateWe = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWe`, data);
};

//----------------------------------------- 搜尋參數資料 ----------------------------------------
export const getWp = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getWp`, {
    params: _params,
  });
};
//----------------------------------------- 刪除參數資料 ----------------------------------------
export const deleteWp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteWp`, data);
};
//----------------------------------------- 新增參數資料 ----------------------------------------
export const addWp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addWp`, data);
};

//----------------------------------------- 編輯參數資料 ----------------------------------------
export const updateWp = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateWp`, data);
};
