/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import CustomTabs from "@/components/CustomAntd/ERP/CustomTabs";
// context
import { MainPageContext } from "../index";
// component
import Commission from "../Details/Commission";
import TbContainer from "../Details/TbContainer";
import AddaContainer from "../Details/AddaContainer";
import AddbContainer from "../Details/AddbContainer";
import AddcContainer from "../Details/AddcContainer";

const DetailContainer = () => {
  const { is_editForm, set_is_editForm, call_updateProHD, hd_values } = useContext(MainPageContext);

  const { t } = useTranslation();
  // CustomTabs 目前被選中的選項
  const [s_tabKey, set_s_tabKey] = useState("details");
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });
  const tabsData = [
    { key: "details", label: t("PMS.outsourcing.tb") }, // 檢測明細
    { key: "adda", label: t("PMS.pro.adda") }, // 條款註記
    { key: "Commission", label: t("OMS.ord.commission") }, // 文件上載
    // { key: "addb", label: t("PMS.pro.addb") }, // 加扣款項
    // { key: "addc", label: t("PMS.pro.addc") }, // 預付作業
  ];
  const flagMap = {
    dealN: t("PMS.pro.dealN"),
    currencytype: t("PMS.util.currencytype"),
    exchangeD: t("PMS.util.exchangeD"),
    exchangerate: t("PMS.util.exchangerate"),
    suppID: t("PMS.util.suppID"),
    payment: t("PMS.pro.payment"),
    trade: t("PMS.pro.trade"),
    driID: t("PMS.pro.driID"),
    proD: t("PMS.pro.proT"),
    startEDT: t("PMS.pro.startEDT"),
    endEDT: t("PMS.pro.endEDT"),
    taxtype: t("PMS.util.taxtype"),
    contact: t("PMS.pro.contact"),
    tel: t("PMS.util.tel"),
    dealcontact: t("PMS.pro.dealcontact"),
    dealtel: t("PMS.util.tel"),
  };

  return (
    <div className="flex flex-col flex-1">
      <CustomTabs
        items={tabsData} // CustomTabs 的所有選項
        activeKey={s_tabKey} // 目前亮起來的 Tab
        onChange={(activeKey) => set_s_tabKey(activeKey)} // 切換 Tab 時觸發
      />

      {s_tabKey === "details" ? (
        <TbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}

      {/* 條款註記 */}
      {s_tabKey === "adda" && <AddaContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {/* 加扣款項 */}
      {s_tabKey === "addb" && <AddbContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}

      {s_tabKey === "addc" ? (
        <AddcContainer s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}
      {s_tabKey === "Commission" ? (
        <Commission s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />
      ) : null}
    </div>
  );
};

export default DetailContainer;
