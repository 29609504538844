import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";
// 新建發貨單表頭
export const addOutstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOutstockHD`, data);
};
// 取得發貨單(表頭ㄧ階搜尋)
export const getOutstockHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutstockHD`, {
    params: data,
  });
};
// 取得發貨單(表頭ㄧ階搜尋)
export const getOutstockHDAD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutstockHD`, {
    params: data,
  });
};
// 編輯發貨單表頭
export const updateOutstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOutstockHD`, data);
};
// 刪除發貨單表頭
export const deleteOutstockHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOutstockHD`, data);
};

// ==================================================================================
// 拋轉發貨單
export const leaveStock = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/leaveStock`, data);
};
// ==================================================================================
// 引用銷貨單(取得銷貨資料)
export const getTakeOrd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getTakeOrd`, {
    params: data,
  });
};
// 引用銷貨單(建立)
export const takeOrd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeOrd`, data);
};
// ==================================================================================

// 新建發貨單表身
export const addOutstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOutstockTB`, data);
};
// 取得發貨單表身
export const getOutstockTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOutstockTB`, {
    params: data,
  });
};
// 編輯發貨單表身
export const updateOutstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateOutstockTB`, data);
};
// 刪除發貨單表身
export const deleteOutstockTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOutstockTB`, data);
};
