import { useState } from "react";

const useAPI = (apiFunc) => {
  const [data, setData] = useState([]);
  const [msg, setMsg] = useState("暫無資料");
  const [status, setStatus] = useState("def");

  const request = async (params) => {
    // 只能使用經過axios包裝過後的 api
    setStatus("load");
    const res = await apiFunc(params);
    setMsg(res?.msg);
    setData(res?.data);
    res?.status ? setStatus("suc") : setStatus("err");
  };

  return { data, status, msg, request };
};

export default useAPI;
