import { Col, Divider, Drawer, Grid, Row, Select, Space, Typography, message } from "antd";
import { ExpendButton, SeeMoreButton } from "@/components/ERP/Button";
import React, { useContext, useEffect, useState } from "react";

import { CustomRangePicker } from "@/components/ERP/TabExtra";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { ERPContext } from "@/components/Context/SystemContext";
import Expend from "./Drawer/Expend";
import Income from "./Drawer/Income";
// util component
import PageHeader from "@/components/PageHeader";
import { SearchButton } from "@/components/PDS/Buttons";
import dayjs from "dayjs";
import { formatAmount } from "@/util/format";
import { getOrdAnalysis } from "@/service/apis/OMS/profitAnalysis";
// service
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const ProfitAnalysis = () => {
  const { t } = useTranslation();
  const call_getOrdAnalysis = useAPI(getOrdAnalysis);
  // 客戶資料、人員資料
  const { c_custs, c_staffs, getStaffName } = useContext(ERPContext);
  // 控制抽屜開啟、關閉、抽屜要顯示的資料
  const [s_incomeDrawer, set_s_incomeDrawer] = useState({ show: false, data: {} });
  const [s_expendDrawer, set_s_expendDrawer] = useState({ show: false, data: {} });
  const [s_summary, set_s_summary] = useState({
    income: 0,
    expend: 0,
    profit: 0,
  });
  const [s_tableData, set_s_tableData] = useState([]);
  // 用來判斷目前螢幕尺寸
  const screens = Grid.useBreakpoint();
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    opcode: [],
    custID: [],
    driID: [],
    startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  // try
  const filData = c_custs?.data.filter((item) => item.isValid);
  const Cstaff = filData?.map((item) => {
    return {
      label: item.custID + "_" + item.custalias,
      value: item.custID,
    };
  });

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      console.log("value = ", value);

      tmp = {
        ...s_searchData,
        startDate: value[0],
        endDate: value[1],
        // startDate: dayjs(value[0]).startOf("month"),
        // endDate: dayjs(value[1]).endOf("month"),
      };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };
  // 查看更多
  const openIncomeDetail = (event, rowData) => {
    set_s_incomeDrawer({ show: true, data: rowData });
    event.stopPropagation();
  };
  const openExpendDetail = (event, rowData) => {
    set_s_expendDrawer({ show: true, data: rowData });
    event.stopPropagation();
  };

  // 搜尋資料
  const handleClick = () => {
    console.log("s_searchData = ", s_searchData);
    const tmp = {
      ...s_searchData,
      opcode: s_searchData.opcode?.join("|"), //要將資料陣列轉成字串，用直槓隔開
      custID: s_searchData.custID?.join("|"),
      driID: s_searchData.driID?.join("|"),
      startDate: dayjs(s_searchData.startDate).startOf("month").format("YYYY-MM-DD"), // 開始日期
      endDate: dayjs(s_searchData.endDate).endOf("month").format("YYYY-MM-DD"),
    };
    call_getOrdAnalysis.request(tmp);
  };

  useEffect(() => {
    call_getOrdAnalysis.request(s_searchData);
  }, []);

  useEffect(() => {
    const { status, data, msg } = call_getOrdAnalysis;
    if (status === "suc") {
      message.success(msg);
      const tmpdata = data.map((item, index) => ({
        ...item,
        key: index,
      }));
      let income = 0;
      let profit = 0;
      let expend = 0;
      data.forEach((item) => {
        income = income + item.income;
        profit = profit + item.profit;
        expend = expend + item.expend;
      });
      set_s_summary({
        income,
        profit,
        expend,
      });

      set_s_tableData(tmpdata);
    }
    if (status === "err") {
      set_s_tableData([]);
    }
  }, [call_getOrdAnalysis.status]);

  const tableColumns = [
    {
      title: t("OMS.util.opcode"), // 單別
      dataIndex: "opcode",
      align: "center",
      width: screens.xxl ? "7.5%" : "9%",
      render: (text) => t(`OMS.util.opcode_${text}`),
    },
    {
      title: t("OMS.util.N"), // 單號
      dataIndex: "N",
      align: "center",
      width: screens.xxl ? "8%" : "11%",
    },
    {
      title: t("OMS.util.custNM"), // 客戶簡稱
      dataIndex: "custNM",
      align: "center",
      width: screens.xxl ? "8%" : "11%",
    },
    {
      title: t("OMS.util.dealN"), // 客戶單號
      sorter: (a, b) => a.dealN.localeCompare(b.dealN),
      dataIndex: "dealN",
      align: "center",
      // width: screens.xxl ? "8%" : "11%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: "收益",
      dataIndex: "income",
      align: "center",
      className: "bg-[#e1edf9] text-[#0081FF] font-extrabold",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: "支出",
      dataIndex: "expend",
      align: "center",
      className: "bg-[#fadede] text-[#EF4F4F] font-extrabold",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: "利潤",
      dataIndex: "profit",
      align: "center",
      className: "bg-[#e4fae8] text-[#23A439] font-extrabold",
      width: screens.xxl ? "8.3%" : "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: screens.xxl ? "7%" : "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <SeeMoreButton onClick={(e) => openIncomeDetail(e, rowData)} />

          <Divider type="vertical" className="m-0 border-[#D2D2D2] border-2" />

          <ExpendButton
            disabled={rowData.expend === 0}
            onClick={(e) => openExpendDetail(e, rowData)}
          />
        </Space>
      ),
    },
  ];
  const DIVIDER = "border-0 border-r-2 border-solid border-[#D2D2D2]";
  return (
    <div className="flex-1">
      <PageHeader
        title="每月利润分析"
        extra={[
          <Select
            className="min-w-[200px]"
            name="opcode"
            mode="multiple"
            showSearch
            placeholder="请选择单别"
            onChange={(value) => handle_SearchData_Change("opcode", value)}
            options={[
              { value: "", label: t("util.all") }, // 终端客户
              { value: "1310", label: t("OMS.util.opcode_1310") }, // 终端客户
              { value: "1320", label: t("OMS.util.opcode_1320") }, // 合作伙伴
              { value: "1330", label: t("OMS.util.opcode_1330") }, // 其他檢測机构
            ]}
            allowClear
          />,
          <Select
            className="min-w-[300px]"
            name="custID"
            mode="multiple"
            showSearch
            placeholder="请选择客户"
            onChange={(value) => handle_SearchData_Change("custID", value)}
            options={c_custs?.data
              ?.filter((i) => i.custID.includes("C"))
              ?.map((item) => {
                return {
                  label: item.custID + "_" + item.custalias,
                  value: item.custID,
                };
              })}
            allowClear
          />,
          <Select
            className="min-w-[300px]"
            name="driID"
            mode="multiple"
            showSearch
            placeholder="请选择負責業務"
            onChange={(value) => handle_SearchData_Change("driID", value)}
            options={c_staffs?.options}
            allowClear
          />,
          <CustomRangePicker
            picker="month"
            key="time"
            allowClear={false}
            value={[
              s_searchData.startDate && dayjs(s_searchData.startDate),
              s_searchData.endDate && dayjs(s_searchData.endDate),
            ]}
            //         包含2個元素的陣列, 分別是選中的起始＆結束日期
            onChange={(_, dateStrings) => handle_SearchData_Change("time", dateStrings)}
          />,
          <SearchButton type="primary" key="search" onClick={handleClick} />,
        ]}
      />
      <Row
        className="w-full rounded-md shadow-[0px_3px_6px_#dcdcdc] p-2 mb-5"
        style={{ backgroundColor: "#f5f5f5" }}
      >
        <Col span={3} className="flex-center">
          <Title className="m-0 text-[#4B6EA2]" level={5}>
            總收益
          </Title>
        </Col>
        <Col span={3} className="flex-center bg-[#e1edf9]">
          <Title className="m-0 text-[#0081FF]" level={3}>
            {formatAmount(s_summary.income)}
          </Title>
        </Col>
        <Col span={3} className="flex-center">
          <Title className="m-0 text-[#4B6EA2]" level={5}>
            總支出
          </Title>
        </Col>
        <Col span={3} className="flex-center bg-[#fadede]">
          <Title className="m-0 text-[#EF4F4F]" level={3}>
            {formatAmount(s_summary.expend)}
          </Title>
        </Col>
        <Col span={3} className="flex-center">
          <Title className="m-0 text-[#4B6EA2]" level={5}>
            總利潤
          </Title>
        </Col>
        <Col span={3} className="flex-center bg-[#e4fae8]">
          <Title className="m-0 text-[#23A439]" level={3}>
            {formatAmount(s_summary.profit)}
          </Title>
        </Col>
        <Col span={3} className="flex-center">
          <Title className="m-0 text-[#4B6EA2]" level={5}>
            毛利率
          </Title>
        </Col>
        <Col span={3} className="flex-center bg-[#fde5b8]">
          <Title className="m-0 text-[#fda500]" level={3}>
            {formatAmount((s_summary.profit / s_summary.income) * 100)}%
          </Title>
        </Col>
      </Row>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        // loading={call_getOrdHD.status === "load"}
      />
      {/* 查看更多-抽屜 */}
      <Drawer
        title={
          <Title level={5} className="mb-0">
            收益明細
          </Title>
        }
        placement="right"
        closable={false}
        width={1000}
        onClose={() => set_s_incomeDrawer({ show: false, data: {} })}
        open={s_incomeDrawer.show}
      >
        <Income s_incomeDrawer={s_incomeDrawer} set_s_incomeDrawer={set_s_incomeDrawer} />
      </Drawer>
      <Drawer
        title={
          <Title level={5} className="mb-0">
            支出明細
          </Title>
        }
        placement="right"
        closable={false}
        width={1000}
        onClose={() => set_s_expendDrawer({ show: false, data: {} })}
        open={s_expendDrawer.show}
      >
        <Expend s_expendDrawer={s_expendDrawer} set_s_expendDrawer={set_s_expendDrawer} />
      </Drawer>
    </div>
  );
};

export default ProfitAnalysis;
