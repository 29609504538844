import {
	Button,
	Col,
	DatePicker,
	Divider,
	Form,
	Input,
	Row,
	Spin,
	message,

} from "antd";

import dayjs from "dayjs";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// api
import useAPI from "@/hooks/useAPI";
import { getInvProject, addInvProject, updateInvProject } from "@/service/apis/WMS/Inventory";


const ModalContainer4030 = ({ s_tabKey, set_is_showModal, s_editData }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	// 判斷現在是新建盤點單還是編輯盤點單
	const isCreate = Object.keys(s_editData).length === 0;

	// const call_getInvProject = useAPI(getInvProject); // 取得盤點計劃
	const call_addInvProject = useAPI(addInvProject);
	const call_updateInvProject = useAPI(updateInvProject);

	const [s_spinning, set_s_spinning] = useState(false);
	const [s_invFormData, set_s_invFormData] = useState({
		hd: [{
			"whID": "",
			"attribute": [],
		}]
	})
	const { TextArea } = Input;

	const onSubmit = (values) => {
		if (isCreate) {
			call_addInvProject.request(values);
			form.setFieldValue({
				type: s_tabKey,
				opcode: s_tabKey,
			})
			set_s_invFormData(values)
		} else {
			call_updateInvProject.request(values);
			form.setFieldValue({
				type: s_tabKey,
				opcode: s_tabKey,
			})
		}
	};
	// console.log(s_invFormData)


	// 取得盤點計劃
	// useEffect(() => {
	//   const { status, msg, data } = call_getInvProject
	//   if (status === "suc") {
	//     console.log(data)
	//   }
	// }, [call_getInvProject.status])



	useEffect(() => {
		if (isCreate) {
			// 動態改變表單值
			form.setFieldsValue({
				type: s_tabKey,
				opcode: s_tabKey,
			});

		} else {
			console.log("s_editData = ", s_editData);
			form.setFieldsValue({
				...s_editData,
				plandate: dayjs(s_editData.plandate),
			});
		}
	}, []);



	// useAPI 新增盤點計畫
	useEffect(() => {
		const { status, msg } = call_addInvProject;
		if (status === "suc") {
			set_is_showModal(false);
		}
		if (status === "err") {
			message.error(msg);
			set_is_showModal(false);
		}
	}, [call_addInvProject.status]);

	// useAPI 更新盤點計畫
	useEffect(() => {
		const { status, data, msg } = call_updateInvProject;
		if (status === "suc") {
			set_is_showModal(false);
		}
		if (status === "err") {
			message.error(msg);
			set_is_showModal(false);
		}
	}, [call_updateInvProject.status]);

	return (
		<>
			<Spin spinning={s_spinning}>
				<Form
					form={form}
					onFinish={onSubmit}
					layout="vertical"
				>
					{/* 隱藏欄位 */}
					<div>
						<Form.Item name="opcode" hidden>
							<Input />
						</Form.Item>
					</div>
					{/* 第一行*/}
					<Row gutter={[24, 12]}>
						<Col span={6}>
							<Form.Item
								// 預計盤點日
								label="预计盘点日"
								name="plandate"
								rules={[{ required: true }]}
							>
								<DatePicker className="w-full" />
							</Form.Item>
						</Col>
					</Row>
					{/* 第二行*/}
					<Row gutter={[24]}>
						<Col span={24}>
							<Form.Item
								label="盘点说明"
								name="keynote"
							>
								<TextArea
									rows={4}
									onChange={(e) => (e.target.value)}
									placeholder="请输入盘点说明"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<div className="flex justify-end gap-2">
						<Button size="large" onClick={() => set_is_showModal(false)}>
							{/* 取消 */}
							{t("util.util.cancel")}
						</Button>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							loading={call_addInvProject.status === "load"}
						>
							{/* 確定 */}
							{t("util.util.ok")}
						</Button>
					</div>
				</Form>
			</Spin>
		</>
	);
};

export default ModalContainer4030;
