import { instance } from "../../handlerAPI";
import { ims_apiBasic } from "@/service/APIBasic";

export const getrun8090Hd = (dataObj) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getrun8090Hd`, {
    params: dataObj,
  });
};
export const getrun8090HdSelect = (dataObj) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getrun8090HdSelect`, {
    params: dataObj,
  });
};
export const getrun8090Tb = (dataObj) => {
  return instance.get(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/getTb`, {
    params: dataObj,
  });
};
export const addrun8090Hd = (dataObj) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/addrun8090Hd`, dataObj);
};
export const updaterun8090Hd = (dataObj) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updaterun8090Hd`, dataObj);
};
export const updaterun8090Tb = (dataObj) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/updaterun8090Tb`, dataObj);
};
export const deleterun8090Hd = (dataObj) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleterun8090Hd`, dataObj);
};
export const deleterun8090Tb = (dataObj) => {
  return instance.post(`${ims_apiBasic.conn}://${ims_apiBasic.url}/api/deleterun8090Tb`, dataObj);
};
