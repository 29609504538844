import React from "react";
import styled from "styled-components";
const Wrapper = styled.div`
  //box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  height: 40px;
  width: 80%;
  border-radius: 5px;
  position: relative;
  border: 0.5px solid #c4c4c4;
  box-shadow: inset -0.5px 1px 3px #00000029;
  //z-index: 99;

  .box1 {
    width: 100%;
    height: 100%;
    //background-color: #F0F0F0;
    bottom: 0;
    z-index: 10;
    position: absolute;
    border-radius: 5px;
    //bottom: 0
  }

  .percentage {
    color: ${({ width }) => (width > 60 ? "#FFF" : "#000")};
    border-radius: 5px;
    //text-shadow: -1px 0 #9e9e9e, 0 1px #9e9e9e, 1px 0 #9e9e9e, 0 -1px #9e9e9e;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
  }

  .colorBar {
    //width: 50%;
    height: 100%;
    border-radius: 5px;
    //background: linear-gradient(rgb(96, 211, 149) 0%, rgb(245, 245, 245) 20%, rgb(96, 211, 149) 100%);
    position: relative;
    z-index: 90;
  }
`;
const ColorBar = styled.div`
  width: ${({ width }) => width}%;
  height: 100%;
  border-radius: 5px;

  //border: 0.5px solid #C4C4C4;
  //background: linear-gradient(rgb(96, 211, 149) 0%, rgb(245, 245, 245) 20%, rgb(96, 211, 149) 100%);
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: inset -0.5px 3px 6px #00000029;
  position: relative;
  z-index: 90;
`;

const OvalChart = ({ data }) => {
  const bgColor = data < 0 ? "" : data === 100 ? "#50C9A4" : data >= 70 ? "#F9B27B" : "#FC9191";
  //console.log('data = ', data)
  return (
    <Wrapper width={data}>
      <div className="box1"></div>
      <ColorBar width={data} bgColor={bgColor}></ColorBar>
      <div className="percentage">{`${data}%`}</div>
    </Wrapper>
  );
};

export default OvalChart;
