import apiBasic from "../../APIBasic";
import { instance } from "../../handlerAPI";

export const addA1Hd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addA1Hd`, data);
};

export const getA1Hd = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getA1Hd`, {
    params: data,
  });
};

export const updateA1Hd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateA1Hd`, data);
};
export const deleteA1Hd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteA1Hd`, data);
};
