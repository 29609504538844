/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from "react";
import { Table } from 'antd';
// context
import { DataContext } from "../index";
// API
// util
import { formatAmount } from "@/util/format";

const Total = () => {
  const { s_total } = useContext(DataContext);

  //累计金额在表頭計算
  const tableColumns = [
    {
      title: <span className="text-[#4B6EA2]">累计金额</span>, // 累计金额
      dataIndex: "itemtotal",
      align: "center",
      onCell: (_) => {
        return {
          style: {
            backgroundColor: "#ecf2fa",
            color: "rgb(0, 0, 0)"
          },
        };
      },
      render: (text) => <div className="text-right ">{formatAmount(text)}</div>,
    },
  ];

  return (
    <div className="mt-12 flex-1 flex flex-col justify-between ">
      <Table
        columns={tableColumns}
        dataSource={[s_total]}
        pagination={false}
      />
    </div>
  );
};

export default Total;
