import { useContext } from "react";
import { Row, Col, Typography, theme } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { Text } = Typography;
const { useToken } = theme;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { token } = useToken();
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  const { data } = s_drawer;
  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 單別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.opcode")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{t(`PMS.util.opcode_${data.opcode}`)}</Text>
        </Col>
        {/* 單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.N")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.N}</Text>
        </Col>
      </Row>

      <Row className="h-24 border border-t-0 pms-drawer-border-color">
        {/* 採購註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 是否有效 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.isvalid")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value flex-center">
          {data.isvalid ? (
            <CheckCircleFilled style={{ color: token.colorPrimary }} />
          ) : (
            <CloseCircleOutlined />
          )}
        </Col>
        {/* 更新人員 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.updtID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.updtID)}</Text>
        </Col>
        {/* 更新時間 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("PMS.util.updtT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.updtT).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
