import React, { useContext, useEffect } from "react";
import { Modal, Space, Grid, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import {IconCancel,IconConfirm}from "@/components/Icon/Modal/Button"
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
// util
import { formatAmount } from "@/util/format";
// context
import { MainPageContext } from "../../MainPage/index";
// component
import AddbModal from "../Modal/AddbModal";
// API
import useAPI from "@/hooks/useAPI";
import { deleteProADD } from "@/service/apis/PMS/Pro";

const AddbContainer = ({ s_tbModal, set_s_tbModal }) => {
  const { hd_values, s_disabled, call_getProHD, call_getProADD, s_proTb, set_s_proTb, s_Adds } =
    useContext(MainPageContext);
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 刪除加扣款項
  const call_deleteProADD = useAPI(deleteProADD);

  // 刪除
  const deleteData = (rowData) => {
    Modal.confirm({
      title: t("PMS.pro.addb") + t("util.delete") + t("util.ok"), // 加扣款項刪除確認
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      // content: "請先確認要刪除的款項資料無誤，再進行刪除",
      content: t("PMS.pro.addb_delete_confirm_text"),
      okText: <IconConfirm/>, // 確定
      cancelText: <IconCancel/>, // 取消
      onOk: async () => {
        call_deleteProADD.request(rowData);
      },
    });
  };

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.pro.statement"), // 項目說明
      dataIndex: "statement",
      align: "center",
    },
    {
      title: t("PMS.pro.amount"), // 未稅金額
      dataIndex: "amount",
      align: "center",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("PMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "4%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 編輯表身資料 */}
          <EditButton onClick={() => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={(e) => deleteData(rowData)} />
        </Space>
      ),
    },
  ];

  // 第一次渲染取得加扣款項
  useEffect(() => {
    call_getProADD.request({
      "addtype": "addb",
      "opUUID": hd_values.opUUID,
    });
  }, []);

  // useAPI 取得加扣款項
  useEffect(() => {
    const { data, msg, status } = call_getProADD;
    if (status === "suc") {
      message.success(msg);
      set_s_proTb((prev) => ({
        ...prev,
        addb: data.addb ?? [],
      }));
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getProADD.status]);

  // useAPI 刪除加扣款項
  useEffect(() => {
    const { status, msg } = call_deleteProADD;
    if (status === "suc") {
      message.success(msg);
      call_getProADD.request({
        "addtype": "addb",
        "opUUID": hd_values.opUUID,
      });
      // 加扣款項會動到表頭欄位，所要更新表頭
      call_getProHD.request({ "opUUID": hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deleteProADD.status]);

  const type = Object.keys(s_tbModal.data).length > 0 ? "edit" : "create";

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_proTb.addb?.map((item, index) => ({ key: index, ...item })) ?? []}
      />

      <CustomModal
        width={screens.xxl ? "20%" : "40%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        // title={t(`util.${type === "edit" ? "編輯" : "新增"}`) + 加扣款項}
        title={t(`util.${type === "edit" ? "edit" : "add"}`) + t("PMS.pro.addb")}
      >
        {s_tbModal.show && <AddbModal s_tbModal={s_tbModal} set_s_tbModal={set_s_tbModal} />}
      </CustomModal>
    </>
  );
};

export default AddbContainer;
