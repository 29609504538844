import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import {
  CancelCreateButton,
  NextDataButton,
  PrevDataButton,
  PrintButton,
  SaveButton,
  SummaryButton,
  TourButton,
} from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import { addReqHD, getReqHD, getReqTB, updateReqHD } from "@/service/apis/PMS/Req";
/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// util component
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
// context
import { DataContext } from "../index";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PageHeader from "@/components/PageHeader";
import PrintDrawer from "./Modal/JUPrintDrawer/index";
// component
import TbContainer from "./Details/TbContainer";
import ThrowProModal from "./Modal/ThrowProModal";
import dayjs from "dayjs";
// util
import { isValidDate } from "@/util/Vaild";
import qs from "qs";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const INIT_DISABLED = {
  opcode: true,
  currencytype: true,
  exchangerate: true,
  exchangeD: true,
  throwPro: true, //拋轉採購單
  // --------
  suppID: true,
  driID: true,
  taxtype: true,
  taxrate: true,
  proLogSearch: true, //採購紀錄查詢
  // ----------
  EDT: true,
  demandDate: true, // 需求日期
  reqCheck: true, // 請購覆核準
  // -----------
  note: true,
  audit: true, // 審核正式簽送
  // ----- 表身區域 -----
  createBtn: true, // 新建表身
  confirmqty: true, // 廠商確認數量
  PN: true,
  tb_delete: true,
  tb_submit: true,
  demandqty: true, // 請購數量
  unitprice: true, // 請購單價
  utype: true, // 單位類別
};

const MainPage = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 取得表頭資料
  const call_getReqHD = useAPI(getReqHD);
  // 上一筆、下一筆用來更新表頭
  const call_prev_next_getReqHD = useAPI(getReqHD);
  // useAPI 取得表身資料
  const call_getReqTB = useAPI(getReqTB);
  // 表身在編輯之前用來更新表頭
  const call_updateReqHD = useAPI(updateReqHD);
  // useAPI 新增表頭
  const call_finish_addReqHD = useAPI(addReqHD);
  // useAPI 儲存表頭
  const call_finish_updateReqHD = useAPI(updateReqHD);
  // useAPI 請購覆核 or 取消覆核
  const call_reqCheck_updateReqHD = useAPI(updateReqHD);
  // useAPI 審核正式簽送
  const call_Audit_updateReqHD = useAPI(updateReqHD);
  // useAPI 取得上一筆資料
  const call_prev_updateReqHD = useAPI(updateReqHD);
  // useAPI 取得下一筆資料
  const call_next_updateReqHD = useAPI(updateReqHD);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_opcode = Form.useWatch("opcode", form);
  const f_suppID = Form.useWatch("suppID", form);
  const f_lifeF = Form.useWatch("lifeF", form);
  const f_tax = Form.useWatch("tax", form);
  const f_itemtotal = Form.useWatch("itemtotal", form);
  const f_taxrate = Form.useWatch("taxrate", form);
  const f_exchangerate = Form.useWatch("exchangerate", form);
  // 用來紀錄表頭是否編輯過，以及編輯了哪些欄位
  const [is_editForm, set_is_editForm] = useState({
    isEdit: false,
    editFlag: [],
  });
  const { c_state, updateState } = useContext(DataContext);
  // 廠商資料、人員資料
  const { c_userData } = useContext(SystemContext);
  const { c_supps, c_staffs, c_currencys, c_taxtypes } = useContext(ERPContext);
  const { mainPage, req_Ns } = c_state;
  const [s_spin, set_s_spin] = useState(false);
  // 控制表單的元素是否disabled
  const [s_disabled, set_s_disabled] = useState(INIT_DISABLED);
  // 拋轉採購單的跳顯開關
  const [is_showPro, set_is_showPro] = useState(false);
  // 取消據此創建用
  const [s_oriData, set_s_oriData] = useState({});
  // 表身資料
  const [s_tbData, set_s_tbData] = useState([]);
  // 判斷現在是編輯模式還是新建模式
  const editMode = Object.keys(mainPage.data).length > 0;
  // 控制列印的 drawer
  const [is_showPrint, set_is_showPrint] = useState(false);
  // 點擊儲存執行
  const onFinish = async (values) => {
    if (editMode) {
      call_finish_updateReqHD.request(values);
    } else {
      call_finish_addReqHD.request({
        ...values,
        // 如果是據此創建就要連表身一起給
        tb: Object.keys(s_oriData).length > 0 ? s_tbData : [],
      });
    }

    // 如果是[據此創建]後儲存要清空 s_oriData
    set_s_oriData({});
  };

  // 選擇廠商
  const onSuppChange = (value) => {
    const supp_obj = c_supps.data.find((item) => item.suppID === value);
    form.setFieldsValue({
      suppID: supp_obj.suppID,
      suppalias: supp_obj.suppalias,
      contact: null, // 選擇新廠商時要清空業務窗口資料
      tel: null, // 選擇新廠商時要清空業務窗口資料
    });
  };

  // 選擇幣別
  const currencytypeChange = (value) => {
    const currency_obj = c_currencys.data?.find((item) => {
      return item.currencyitem === value;
    });

    // 幣別如果是本幣，匯率要設成1
    if (currency_obj?.ismycurrency === true) {
      form.setFieldsValue({
        exchangerate: 1,
      });

      // 表身的本幣金額要重算
      calculate_localprice(1);
    }
  };

  // 計算表身本幣
  const calculate_localprice = (exchangerate) => {
    if (s_tbData.length === 0) return;

    const tb = s_tbData.map((item) => {
      return {
        ...item,
        localprice: parseFloat((item.unitprice * exchangerate).toFixed(2)),
      };
    });

    set_s_tbData(tb);
  };

  // 選擇幣別
  const taxtypeChange = (value) => {
    const tax_obj = c_taxtypes.data?.find((item) => item.taxtype === value);
    // 只有tax_obj.disabled為false才可以編輯

    set_s_disabled({
      ...s_disabled,
      taxrate: tax_obj.disabled,
    });

    // 將稅別的稅率塞進表單
    form.setFieldsValue({
      taxrate: tax_obj.taxrate,
    });

    // 稅別發生改變時要重新計算表身稅務資訊
    change_tb_tax(tax_obj.taxrate);
  };

  // 稅率改變時重新計算表身稅務
  const change_tb_tax = (taxrate) => {
    const tb = s_tbData.map((item) => {
      // 單項金額
      const itemsum = item.demandqty * item.unitprice;
      console.log("item.confirmqty = ", item.confirmqty);
      console.log("item.unitprice = ", item.unitprice);
      return {
        ...item,
        // 單項稅額
        itemsumtax: itemsum * taxrate * 0.01,
        // 含稅小計 = 單項金額 + 單項稅額
        subtotal: itemsum + itemsum * taxrate * 0.01,
      };
    });

    set_s_tbData(tb);
  };

  // 改變表單 disabled 狀態
  const change_MainPage_disabled = (hd_values = {}) => {
    // isNewdoc = true 新建表單
    const isNewdoc = hd_values.opUUID?.length > 0 ? false : true;
    // 是否為[據此創建]
    const isCreateByThis = Object.keys(s_oriData).length > 0;

    // 發佈
    if (Object.keys(hd_values).length === 0 || hd_values.lifeF === "0") {
      set_s_disabled({
        ...INIT_DISABLED,
        opcode: !isNewdoc,
        currencytype: false,
        exchangerate: false,
        exchangeD: false,
        taxtype: false,
        suppID: false,
        driID: false,
        demandDate: false,
        reqCheck: false,
        note: false,
        EDT: false,
        taxrate: false,
        // ------------ tb ------------
        // 建立表身的按鈕
        createBtn: isCreateByThis ? false : isNewdoc,
        PN: false,
        utype: false,
        unitprice: false,
        demandqty: false,
        tb_note: false,
        tb_submit: false,
        tb_delete: false,
      });
    }

    // 請購覆核
    if (hd_values?.lifeF === "2") {
      set_s_disabled({
        ...INIT_DISABLED,
        reqCheck: false,
        audit: false,
      });
    }

    // 審核正式送簽
    if (hd_values?.lifeF === "7") {
      set_s_disabled({
        ...INIT_DISABLED,
        throwPro: false,
      });
    }

    // 結案
    if (hd_values?.lifeF === "T") {
      set_s_disabled({
        ...INIT_DISABLED,
        proLogSearch: false,
      });
    }
  };

  // 查詢下位資料進料檢驗
  const go_pro = () => {
    const purchase_opcode = {
      2210: "2310",
      2220: "2320",
      2230: "2330",
    };

    const params_obj = {
      opcode: purchase_opcode[mainPage.data?.opcode],
      preopUUID: mainPage.data?.opUUID,
    };

    const params = qs.stringify(params_obj, { arrayFormat: "indices" });

    const { origin } = window.location;

    window.open(`${origin}/pms/pro?${params}`, "_blank");
  };

  // 請購覆核 or 取消覆核
  const reqCheck = () => {
    const values = form.getFieldsValue();
    Modal.confirm({
      // 取消請購覆核確認、請購覆核確認
      title: `${f_lifeF === "2" ? t("PMS.req.cancel_check_confirm") : t("PMS.req.check_confirm")}`,
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content:
        f_lifeF === "2" ? t("PMS.req.cancel_check_confirm_text") : t("PMS.req.check_confirm_text"),
      // 取消覆核、確定覆核
      // okText:
      //   f_lifeF === "2"
      //     ? t("PMS.req.reqCheck_lifeF_2_okText")
      //     : t("PMS.req.reqCheck_lifeF_0_okText"),
      // cancelText: t("PMS.util.cancel"), // 取消
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_reqCheck_updateReqHD.request({
          ...values,
          lifeF: f_lifeF === "2" ? "0" : "2",
          lifeFT: dayjs(),
          confirmID: c_userData?.staffID,
          // 覆核日期
          confirmT: f_lifeF === "0" ? dayjs() : undefined,
        });
      },
    });
  };

  // 正式送簽
  const Audit = () => {
    const values = form.getFieldsValue();

    Modal.confirm({
      // title: "審查簽核確認",
      title: t("PMS.req.audit_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("PMS.req.audit_confirm_text"),
      // okText: "確定審查",
      // okText: t("PMS.req.audit_okText"),
      // cancelText: t("PMS.util.cancel"), // 取消
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: async () => {
        call_Audit_updateReqHD.request({
          ...values,
          lifeF: "7",
          lifeFT: dayjs(),
        });
      },
    });
  };

  // 編輯上一筆資料
  const edit_prev_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是第一筆
    if (nowIndex === 0) {
      //"目前資料為第一筆，已無上一筆資料"
      message.success(t("PMS.util.prev_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_prev_updateReqHD.request(form.getFieldsValue());
  };

  // 編輯下一筆資料
  const edit_next_data = () => {
    const nowIndex = getIndex(form.getFieldValue("N"));

    // 目前編輯的單子是最後一筆
    if (nowIndex + 1 === req_Ns.length) {
      //"目前資料為最後一筆，已無下一筆資料"
      message.success(t("PMS.util.next_data_errMsg"));
      return;
    }

    // 存擋現在的資料
    call_next_updateReqHD.request(form.getFieldsValue());
  };

  // 取得現在編輯的單子是在陣列中第幾個
  const getIndex = (N) => {
    const index = req_Ns.findIndex((item) => item === N);

    return index;
  };

  // 據此條目創建
  const createByThis = () => {
    const new_Hd_Data = {
      ...mainPage.data,
      N: "",
      opUUID: "",
      preopUUID: "",
      exchangeD: dayjs(),
      createT: null,
      EDT: null,
      lifeFT: dayjs(),
      demandDate: dayjs(mainPage.data?.demandDate),
      // 預設登入人員
      createID: c_userData?.staffID,
      lifeF: "0",
    };
    const newData = { ...new_Hd_Data };
    // console.log("newData = ", Object.entries(data));
    Object.entries(new_Hd_Data).forEach(([key, value]) => {
      if (isValidDate(value)) {
        newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
      }
    });
    console.log("newData = ", newData);
    // 重新 set formData
    form.setFieldsValue(newData);

    // 取消[據此創建]用
    set_s_oriData({
      hd: mainPage.data,
      tb: s_tbData,
    });

    // 讓頁面變成新建模式
    updateState({ mainPage: { show: true, data: {} } });

    change_MainPage_disabled(newData);

    // 清空表身資料，清空時 useEffect 會自動計算金額
    set_s_tbData(
      s_tbData?.map((item) => {
        return {
          ...item,
          opUUID: "",
          itemUUID: "",
          rejectqty: 0,
          goqty: 0,
          judgeT: null,
        };
      })
    );
  };

  // 第一次渲染
  useEffect(() => {
    // 判斷現在是新建模式還是編輯模式
    const type = Object.keys(mainPage.data).length === 0 ? "create" : "edit";

    // 新建請購單
    if (type === "create") {
      // 控制表單disabled狀態
      change_MainPage_disabled({});
      // 設定表單的起始值
      form.setFieldsValue({
        opcode: "2210",
        lifeF: "0",
        lifeFT: dayjs(),
        // 幣別預設本幣
        currencytype: c_currencys?.myCurrency?.currencyitem ?? "",
        taxtype: "PPN_11%",
        // taxrate: 11,
        // 匯率預設1
        exchangerate: 1,
        exchangeD: dayjs(),
        // 預設登入人員
        createID: c_userData?.staffID,
        createT: dayjs(),
      });
    }

    if (type === "edit") {
      // 取得表身資料
      call_getReqTB.request({ opUUID: mainPage.data?.opUUID });
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...mainPage.data };
      Object.entries(mainPage.data).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });

      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
  }, []);

  // 計算表頭全部金額資訊
  const calculateMoneyInfo = () => {
    // 單項金額(未稅)
    const itemtotal = s_tbData?.reduce((prev, curr) => prev + (curr.itemsum || 0), 0) || 0;
    // 總稅額
    const tax = (itemtotal * f_taxrate) / 100;
    // 含稅總金額
    const total = itemtotal + tax;

    if (itemtotal === 0) return;
    // console.log("???", { itemtotal, tax, total });
    form.setFieldsValue({ itemtotal, tax, total });
  };

  // 計算表身稅務
  useEffect(() => {
    calculateMoneyInfo();
  }, [f_taxrate, f_exchangerate]);

  // 取得表頭資料
  useEffect(() => {
    const { data, msg, status } = call_getReqHD;
    if (status === "suc") {
      // message.success(msg);
      // 如果 mainPage.data 有日期格式就轉換成Form能用的日期格式
      const newData = { ...data[0] };
      // console.log("newData = ", Object.entries(data));
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
        }
      });
      form.setFieldsValue({
        ...newData,
      });
      updateState({ mainPage: { show: true, data: newData } });
      // 控制表單disabled狀態
      change_MainPage_disabled(newData);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getReqHD.status]);

  // 取得表身資料
  useEffect(() => {
    const { data, msg, status } = call_getReqTB;
    if (status === "suc") {
      // message.success(msg);
      const new_tb = data?.map((item, i) => ({ ...item, key: i })) ?? [];
      set_s_tbData(new_tb);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tbData([]);
    }
  }, [call_getReqTB.status]);

  // 新增請購單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_addReqHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getReqHD.request({ opUUID: data.opUUID });
      // 更新表身(如果回來的data有tb才需要更新表身)
      if (data.tb?.length > 0) {
        call_getReqTB.request({ opUUID: data.opUUID });
      }
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_addReqHD.status]);

  // 編輯請購單表頭
  useEffect(() => {
    const { data, status, msg } = call_finish_updateReqHD;
    if (status === "suc") {
      message.success(msg);
      // 更新表頭
      call_getReqHD.request({ opUUID: data.opUUID });
      // 更新表身
      call_getReqTB.request({ opUUID: data.opUUID });
      // 清空表頭編輯紀錄
      set_is_editForm({ isEdit: false, editFlag: [] });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_finish_updateReqHD.status]);

  // useAPI 請購覆核 or 取消覆核
  useEffect(() => {
    const { status, data, msg } = call_reqCheck_updateReqHD;
    if (status === "suc") {
      message.success(
        data.lifeF === "2"
          ? t("util.check") + t("util.success")
          : t("util.cancel") + t("util.check") + t("util.success")
      );
      // 更新表頭
      call_getReqHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_reqCheck_updateReqHD.status]);

  // useAPI 審核正式簽送
  useEffect(() => {
    const { status, data, msg } = call_Audit_updateReqHD;
    if (status === "suc") {
      //"審核簽送成功"
      message.success(t("PMS.req.audit_start") + t("util.success"));
      // 更新表頭
      call_getReqHD.request({ opUUID: data.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_Audit_updateReqHD.status]);

  // useAPI 取得上一筆資料
  useEffect(() => {
    const { status } = call_prev_updateReqHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      //取得上一筆資料成功
      message.success(t("PMS.util.get_prev_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getReqHD.request({ N: req_Ns[nowIndex - 1] });
    }

    if (status === "err") {
      //"取得上一筆資料失敗"
      message.success(t("PMS.util.get_prev_data_err"));
      // set_s_spin(false);
    }
  }, [call_prev_updateReqHD.status]);

  // useAPI 取得下一筆資料
  useEffect(() => {
    const { status } = call_next_updateReqHD;

    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      //"取得下一筆資料成功"
      message.success(t("PMS.util.get_next_data_suc"));

      const nowIndex = getIndex(form.getFieldValue("N"));
      // 更新表頭資料(減1是因為要找上一筆資料)
      call_prev_next_getReqHD.request({ N: req_Ns[nowIndex + 1] });
    }

    if (status === "err") {
      //"取得下一筆資料失敗"
      message.success(t("PMS.util.get_next_data_err"));
      // set_s_spin(false);
    }
  }, [call_next_updateReqHD.status]);

  // useAPI 上一筆、下一筆更新表頭表身用
  useEffect(() => {
    const { data, status } = call_prev_next_getReqHD;
    if (status === "load") {
      set_s_spin(true);
    }

    if (status === "suc") {
      const formData = { ...data[0] };
      // 如果有日期格式就轉換成 moment
      Object.entries(data[0]).forEach(([key, value]) => {
        if (isValidDate(value)) {
          formData[key] = dayjs(value);
        }
      });

      form.setFieldsValue({
        ...formData,
      });

      updateState({ mainPage: { show: true, data: data[0] } });
      set_s_spin(false);

      // 控制表單disabled狀態
      change_MainPage_disabled(data[0]);

      // 更新表身資料
      call_getReqTB.request({ opUUID: data[0].opUUID });
    }
  }, [call_prev_next_getReqHD.status]);

  return (
    <div className="flex flex-col h-full">
      <Form
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        form={form}
        validateMessages={{
          required: t("PMS.util.placeholder_select") + "${label}",
        }}
        onValuesChange={(changedValues, _) => {
          if (editMode) {
            // 在編輯模式時紀錄表頭編輯了哪些欄位
            set_is_editForm((prev) => {
              let editFlag = [...prev.editFlag];
              const [key] = Object.keys(changedValues);

              if (!editFlag.includes(key)) {
                editFlag.push(key);
              }
              return {
                isEdit: true,
                editFlag,
              };
            });
          }
        }}
      >
        {/* 表頭部分 */}
        <Row gutter={[12, 0]}>
          <Col span={8}>
            {/* 編輯請購主檔、 新建請購主檔*/}
            <PageHeader
              title={
                editMode
                  ? t("util.edit") + t("PMS.req.pageHeader")
                  : t("util.add") + t("PMS.req.pageHeader")
              }
            />
          </Col>
          <Col span={16} className="flex justify-end">
            {/* 列印 */}
            <PrintButton onClick={() => set_is_showPrint(true)} />
            {/* 儲存 */}
            <SaveButton htmlType="submit" />
            {/* 上一筆 */}
            <PrevDataButton disabled={editMode ? false : true} onClick={edit_prev_data} />
            {/* 下一筆 */}
            <NextDataButton disabled={editMode ? false : true} onClick={edit_next_data} />
            {Object.keys(s_oriData).length > 0 && (
              <CancelCreateButton
                onClick={() => {
                  updateState({ mainPage: { show: true, data: s_oriData.hd } });
                  set_s_tbData(s_oriData.tb);
                  // 如果有日期格式就轉換成 moment
                  const newData = { ...s_oriData.hd };
                  Object.entries(s_oriData.hd).forEach(([key, value]) => {
                    if (isValidDate(value)) {
                      newData[key] = value.includes("0001-01-01") ? undefined : dayjs(value);
                    }
                  });

                  form.setFieldsValue({
                    ...newData,
                  });
                  change_MainPage_disabled(newData);
                  set_s_oriData({});
                }}
              />
            )}
            {/* 回總表 */}
            <SummaryButton
              onClick={() => {
                updateState({ mainPage: { show: false, data: {} } });
              }}
            />
            {/* 回總覽 */}
            <TourButton />
          </Col>
        </Row>

        {/* 隱藏欄位 */}
        <div>
          <Form.Item name="opcode" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="suppNM" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="lifeF" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="lifeFT" hidden>
            <DatePicker />
          </Form.Item>

          <Form.Item name="opUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="preopUUID" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="itemtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="sumtotal" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="tax" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="total" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="iswoodsupp" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="linkType" hidden>
            <Input />
          </Form.Item>
        </div>

        {/* 表單開始區域 */}
        <Row gutter={[12, 0]} className="border-0 border-t border-gray-300 border-solid">
          {/* 單號 */}
          <Col span={editMode ? 10 : 12}>
            <Form.Item label={<Text disabled>{t("PMS.util.N")}</Text>} name="N">
              <Input disabled />
            </Form.Item>
          </Col>

          {/* <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.demandDate}>{t("PMS.req.demandDate")}</Text>}
              name="demandDate"
            >
              <DatePicker format="YYYY-MM-DD" className="w-full" disabled={s_disabled.demandDate} />
            </Form.Item>
          </Col> */}
          {/* 幣別、匯率 */}
          <Col span={editMode ? 5 : 6} className="flex gap-2">
            <Form.Item
              // 幣別
              label={<Text disabled={s_disabled.currencytype}>{t("PMS.util.currencytype")}</Text>}
              messageVariables={{ label: t("PMS.util.currencytype") }}
              name="currencytype"
              className="flex-1"
              rules={[{ required: true }]}
            >
              <Select
                placeholder={t("PMS.util.currencytype_placeholder")}
                disabled={s_disabled.currencytype}
                onChange={currencytypeChange}
                options={c_currencys.options}
              />
            </Form.Item>

            {/* <Form.Item dependencies={["currencytype"]} className="flex-1">
              {() => {
                // 如果幣別為『本幣』，匯率要disabled，且匯率為1
                const currencytype = form.getFieldValue("currencytype");
                const ismycurrency =
                  c_currencys.data?.find((item) => {
                    return item.currencyitem === currencytype;
                  })?.ismycurrency ?? true;

                return (
                  <Form.Item
                    label={
                      <Text disabled={s_disabled.exchangerate || ismycurrency}>
                        {t("PMS.util.exchangerate")}
                      </Text>
                    }
                    rules={[{ required: true }]}
                    messageVariables={{ label: t("PMS.util.exchangerate") }}
                    name="exchangerate"
                    disabled={s_disabled.exchangerate || ismycurrency}
                  >
                    <InputNumber
                      // placeholder="請輸入匯率"
                      className="w-full"
                      disabled={s_disabled.exchangerate || ismycurrency}
                      onChange={(value) => calculate_localprice(value)}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item> */}
          </Col>

          <Col span={editMode ? 5 : 6}>
            {/* 税率 */}
            <Form.Item>
              <Form.Item
                disabled={s_disabled.taxrate}
                label={<Text>{t("PMS.util.taxrate")}</Text>}
                name="taxrate"
                rules={[{ required: true }]}
                messageVariables={{ label: t("PMS.util.taxrate") }}
              >
                <InputNumber className="w-full" min={0} />
                {/* <Select
                  disabled={s_disabled.taxrate}
                  options={[
                    { label: "0%", value: 0 },
                    { label: "11%", value: 11 },
                  ]}
                /> */}
              </Form.Item>
            </Form.Item>
          </Col>

          {/* 匯率日期 */}
          {/* <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.exchangeD}>{t("PMS.util.exchangeD")}</Text>}
              name="exchangeD"
            >
              <DatePicker format="YYYY-MM-DD" className="w-full" disabled={s_disabled.exchangeD} />
            </Form.Item>
          </Col> */}

          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 引用詢價單 */}
              <Button className="flex-1 h-full whitespace-normal break-word" disabled>
                {t("PMS.util.take")}
                <br />
                {t("PMS.util.plan")}
              </Button>
              {/* 據此創建 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline whitespace-normal break-word"
                disabled={editMode ? false : true}
                onClick={createByThis}
              >
                {t("PMS.util.accord")}
                <br />
                {t("PMS.util.create")}
              </Button>
              {/* 拋轉採購單 */}
              <Button
                className="flex-1 h-full pms-btn-blue whitespace-normal break-word"
                onClick={() => set_is_showPro(true)}
                disabled={s_disabled.throwPro}
              >
                {t("PMS.util.throw")}
                <br />
                {t("PMS.util.pro")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 廠商ID */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              name="suppID"
              label={<Text disabled={s_disabled.suppID}>{t("PMS.util.suppID")}</Text>}
              rules={[{ required: true }]}
              messageVariables={{ label: t("PMS.util.suppID") }}
            >
              <Select
                showSearch
                disabled={s_disabled.suppID}
                placeholder={t("PMS.util.suppID_placeholder")}
                onChange={onSuppChange}
                options={c_supps.options}
              />
            </Form.Item>
          </Col>
          {/* 廠商簡稱 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.suppalias")}</Text>} name="suppalias">
              <Input disabled placeholder={t("PMS.util.suppID_placeholder")} />
            </Form.Item>
          </Col>
          {/* 負責請購 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.driID}>{t("PMS.req.driID")}</Text>}
              name="driID"
            >
              <Select
                disabled={s_disabled.driID}
                showSearch
                options={c_staffs.options}
                placeholder={t("PMS.req.driID_placeholder")}
              />
            </Form.Item>
          </Col>
          {/* 稅別、稅率 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item
              label={<Text disabled={s_disabled.demandDate}>{t("PMS.req.demandDate")}</Text>}
              name="demandDate"
            >
              <DatePicker format="YYYY-MM-DD" className="w-full" disabled={s_disabled.demandDate} />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              {/* 詢價紀錄查詢 */}
              <Button
                className="flex-1 h-full pms-btn-blue-outline whitespace-normal break-words"
                // style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                disabled
              >
                {t("PMS.req.planSearch")}
              </Button>
              <Button
                className="flex-1 h-full pms-btn-blue-outline whitespace-normal break-words"
                disabled={s_disabled.proLogSearch}
                onClick={go_pro}
              >
                {t("PMS.util.proSearch")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]}>
          {/* 創建人員 */}
          <Col span={editMode ? 5 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("PMS.util.createID")}</Text>} name="createID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 創建時間 */}
          <Col span={editMode ? 5 : 6} className="pb-2">
            <Form.Item label={<Text disabled>{t("PMS.util.createT")}</Text>} name="createT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {/* 覆核人員 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.confirmID")}</Text>} name="confirmID">
              <Input disabled />
            </Form.Item>
          </Col>
          {/* 覆核日期 */}
          <Col span={editMode ? 5 : 6}>
            <Form.Item label={<Text disabled>{t("PMS.util.confirmT")}</Text>} name="confirmT">
              <DatePicker disabled className="w-full" />
            </Form.Item>
          </Col>
          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l border-gray-300 border-solid flex gap-2 pt-2"
            >
              <Button
                className={`h-full flex-1 whitespace-normal break-words ${
                  f_lifeF === "2" ? "pms-btn-red" : "pms-btn-purple"
                }`}
                disabled={s_tbData.length > 0 ? s_disabled.reqCheck : true}
                onClick={reqCheck}
              >
                {/* 取消覆核、請購單覆核 */}
                {f_lifeF === "2" ? t("PMS.req.cancel_reqCheck") : t("PMS.req.reqCheck")}
              </Button>
              <Button
                className="flex-1 whitespace-normal break-word h-full pms-btn-blue-outline"
                disabled
              >
                {/* 上傳簽呈 */}
                {t("PMS.util.UploadSignature")}
              </Button>
            </Col>
          )}
        </Row>

        <Row gutter={[12, 0]} className="border-0 border-b border-gray-300 border-solid">
          {/* 採購註記  */}
          <Col span={editMode ? 20 : 24} className="pb-2">
            <Form.Item
              label={<Text disabled={s_disabled.note}>{t("PMS.req.hd_note")}</Text>}
              name="note"
            >
              <Input disabled={s_disabled.note} />
            </Form.Item>
          </Col>

          {editMode && (
            <Col
              xl={5}
              xxl={4}
              className="border-0 border-l flex border-gray-300 border-solid py-2"
            >
              <Button
                disabled={s_disabled.audit}
                className="flex-1 h-full pms-btn-purple"
                onClick={Audit}
              >
                {/* {f_lifeF === "7" ? "已完成審查" : "審核正式送簽"} */}
                {f_lifeF === "7" ? t("PMS.req.audit_finish") : t("PMS.req.audit_start")}
              </Button>
            </Col>
          )}
        </Row>
      </Form>

      <TbContainer
        is_editForm={is_editForm}
        set_is_editForm={set_is_editForm}
        hd_values={form.getFieldsValue()}
        call_getReqHD={call_getReqHD}
        call_getReqTB={call_getReqTB}
        call_updateReqHD={call_updateReqHD}
        s_tbData={s_tbData}
        s_disabled={s_disabled}
      />

      {/* 拋轉採購單 */}
      <CustomModal
        width="90%"
        title={t("PMS.util.throw") + t("PMS.util.pro")}
        open={is_showPro}
        onCancel={() => set_is_showPro(false)}
      >
        {is_showPro ? (
          <ThrowProModal
            set_is_showPro={set_is_showPro}
            hd_values={form.getFieldsValue()}
            call_getReqHD={call_getReqHD}
            call_getReqTB={call_getReqTB}
            s_tbData={s_tbData}
          />
        ) : null}
      </CustomModal>

      <Drawer
        placement="right"
        closable={false}
        // width={800}
        width={820} //優如測試
        onClose={() => set_is_showPrint(false)}
        open={is_showPrint}
      >
        <PrintDrawer hd_values={form.getFieldsValue()} s_tbData={s_tbData} />
      </Drawer>
    </div>
  );
};

export default MainPage;
