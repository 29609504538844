import { Button, Col, Divider, Row, Select, Spin, message } from "antd";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import { deleteProduct, getProduct, getSuppHd, searchDeal } from "@/service/apis/ADM/pnManage";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// util component
import CustomModal from "../CustomAntd/ERP/CustomModal";
import { ERPContext } from "../Context/SystemContext";
// context
import SystemContext from "@/components/Context/SystemContext";
import { getMgmtcat } from "../../service/apis/ADM/client";
import { getProductSelect } from "@/service/apis/ADM/publicAPI";
// API
import useAPI from "../../hooks/useAPI";
import { useTranslation } from "react-i18next";

const PNButtonOri = () => {
  const [is_showPNModal, set_is_showPNModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button type="primary" onClick={() => set_is_showPNModal(true)}>
        {/* 搜尋料號 */}
        {t("util.util.searchPN")}
      </Button>
      <CustomModal
        width="30%"
        title={t("util.util.search") + t("util.util.PN")}
        open={is_showPNModal}
        onCancel={() => set_is_showPNModal(false)}
      >
        {is_showPNModal && <PNModal set_visible={set_is_showPNModal} />}
      </CustomModal>
    </>
  );
};

const PNModal = ({ set_visible }) => {
  /*
   * set_visible => 用來關閉跳顯示
   */
  const { t } = useTranslation();
  const { set_c_PNs } = useContext(ERPContext);
  const { c_systemConfig } = useContext(SystemContext);

  // useAPI 取得管理分類
  const call_getQuerycat = useAPI(getMgmtcat);
  // useAPI 取得商品類別
  const call_getMgmtcat = useAPI(getMgmtcat);
  // useAPI 取得料號
  const call_getProduct = useAPI(getProductSelect);

  const [is_spin, set_is_spin] = useState(false);

  // 商品類別
  const [s_mgmtcat, set_s_mgmtcat] = useState([]);

  // 管理分類
  const [s_querycat, set_s_querycat] = useState([]);

  const [s_searchData, set_s_searchData] = useState({
    attribute: undefined,
    pdtclass: undefined,
    querycat: undefined,
    mgmtcat: undefined,
  });

  // 切換基本資料搜尋項目
  const handleSearchDataChange = (type, value) => {
    const tmp = { ...s_searchData, [type]: value };
    console.log("tmp = ", tmp);
    set_s_searchData(tmp);
  };

  // 取得商品類別資料
  useEffect(() => {
    call_getMgmtcat.request({ pageType: "71" });
    call_getQuerycat.request({ pageType: "74" });
  }, []);

  // useAPI 取得商品類別
  useEffect(() => {
    if (call_getMgmtcat.status === "suc") {
      set_s_mgmtcat(call_getMgmtcat.data);
    }
    if (call_getMgmtcat.status === "err") {
      message.error(t(`error.error-code.${call_getMgmtcat.msg}`));
    }
  }, [call_getMgmtcat.status]);

  // useAPI 取得管理分類
  useEffect(() => {
    const { msg, status, data } = call_getQuerycat;
    if (status === "suc") {
      const options = data
        ?.filter((item) => item.isvalid)
        .map((item) => {
          return { label: `${item.mgmtitem}-${item.itemNM}`, value: item.mgmtitem };
        });

      set_s_querycat(options);
    }
    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getQuerycat.status]);

  // useAPI 取得料號
  useEffect(() => {
    const { data, msg, status } = call_getProduct;
    if (status === "load") {
      set_is_spin(true);
    }

    if (status === "suc") {
      console.log("data = ", data);
      const options =
        data?.map((item) => ({
          ...item,
          key: item.PN,
          // label: `${item.PN}(${item.dealPN})`,
          label: (
            <span>
              {item.pdtNM}
              <span style={{ marginLeft: "3px", color: "blue" }}>({item.pdtspec})</span>
            </span>
          ),
          value: item.PN,
        })) ?? [];
      set_c_PNs({
        data: data,
        options: options,
      });
      message.success(msg);
      set_visible(false);
    }

    if (status === "err") {
      message.error(t(`error.error-code.${msg}`));
      set_visible(false);
    }
  }, [call_getProduct.status]);

  // PMS 物料屬性不可選『成品』『半成品』
  const attribute_options = [
    // { label: t("util.util.attribute_0"), value: "0" },
    { label: t("util.util.attribute_1"), value: "1" },
    { label: t("util.util.attribute_2"), value: "2" },
    { label: t("util.util.attribute_3"), value: "3" },
    { label: t("util.util.attribute_4"), value: "4" },
    // { label: t("util.util.attribute_5"), value: "5" },
    // { label: t("util.util.attribute_6"), value: "6" },
    // { label: t("util.util.attribute_7"), value: "7" },
    // { label: t("util.util.attribute_8"), value: "8" },
    // { label: t("util.util.attribute_9"), value: "9" },
  ];
  // PMS 物料屬性不可選『成品』『半成品』
  // const attribute_options =
  //   c_systemConfig.theme === "pms"
  //     ? attribute_base
  //     : [
  //         ...attribute_base,
  //         { label: t("util.util.attribute_4"), value: "4" },
  //         { label: t("util.util.attribute_5"), value: "5" },
  //       ];

  // OMS 商品類別不可選『非銷售出貨料件』
  const pdtclass_base = [
    { label: t("util.util.pdtclass_M"), value: "M" },
    { label: t("util.util.pdtclass_A"), value: "A" },
    { label: t("util.util.pdtclass_P"), value: "P" },
  ];

  const pdtclass_options =
    c_systemConfig.theme === "oms"
      ? pdtclass_base
      : [...pdtclass_base, { label: t("util.util.pdtclass_X"), value: "X" }]; //pdtclass_X 非銷售出貨料件

  return (
    <Spin spinning={is_spin}>
      <Row gutter={[16, 0]}>
        {/* <Col span={12}>
          <Select
            className="w-full"
            placeholder={t("util.util.placeholder_select") + t("OMS.util.attribute")}
            //物料屬性
            allowClear
            name="attribute"
            value={s_searchData.attribute}
            onChange={(value) => handleSearchDataChange("attribute", value)}
            options={attribute_options}
          />
        </Col> */}

        {/* <Col span={12}>
          <Select
            className="w-full"
            placeholder={t("util.util.placeholder_select") + t("OMS.util.pdtclass")}
            //商品類別
            allowClear
            name="pdtclass"
            value={s_searchData.pdtclass}
            onChange={(value) => handleSearchDataChange("pdtclass", value)}
            options={pdtclass_options}
          />
        </Col> */}
      </Row>

      <Row gutter={[16, 16]} className="mt-4">
        <Col span={24}>
          <Select
            className="w-full"
            name="querycat"
            value={s_searchData.querycat}
            onChange={(value) => handleSearchDataChange("querycat", value)}
            placeholder={t("util.util.placeholder_select") + t("util.util.querycat")}
            //"選擇查詢分類"
            allowClear
            // options={s_querycat}
            options={[
              { label: "办公用品", value: "1" },
              { label: "试剂耗材", value: "2" },
              { label: "玻璃器皿", value: "3" },
              { label: "设备配件", value: "4" },
              { label: "其他类别", value: "5" },
            ]}
          />
        </Col>

        {/* <Col span={12}>
          <Select
            className="w-full"
            name="mgmtcat"
            value={s_searchData.mgmtcat}
            onChange={(value) => handleSearchDataChange("mgmtcat", value)}
            placeholder={
              t("util.util.placeholder_select") + t("ADM.client.classificationOfRestrictions")
            }
            //"選擇限權分類"
            allowClear
          >
            {s_mgmtcat
              ?.filter((item) => item.isvalid === true)
              ?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.mgmtitem}>
                    {item.mgmtitem} - {item.itemNM}
                  </Select.Option>
                );
              })}
          </Select>
        </Col> */}
      </Row>

      <Divider />

      <Row gutter={[16, 0]}>
        <Col span={24} className="flex justify-end gap-3">
          <CancelButton onClick={() => set_visible(false)} />
          <ConfirmButton type="primary" onClick={() => call_getProduct.request(s_searchData)} />
        </Col>
      </Row>
    </Spin>
  );
};

export default PNButtonOri;
