import React from "react";
// component
import { QRCode } from "antd";
import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";

const Page = (props) => {
  return (
    <>
      <div className="flex flex-col w-full relative">
        <Header {...props} />
        <Container {...props} />
      </div>
      <Footer {...props} />
    </>
  );
};

export default Page;
