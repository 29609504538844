import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
// uttil component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
import { DeleteButton } from "@/components/ERP/Button";
// context
import { MainPageContext } from "../index";

const InspcerModal = ({ set_s_showInspcer, hd_values }) => {
  const { call_updateOrdHd } = useContext(MainPageContext);
  const { t } = useTranslation();

  const [s_tableData, set_s_tableData] = useState([]);

  const removeData = (rowData) => {
    const _tableData = s_tableData.filter((item) => item.url !== rowData.url);
    set_s_tableData(_tableData);
  };

  const onSubmit = () => {
    const data = s_tableData.map((item) => item.url).join("|");
    // console.log("data = ", typeof data);
    call_updateOrdHd.request({
      ...hd_values,
      inspcer: data,
      APImethod: "inspcer",
    });
    set_s_showInspcer(false);
  };

  useEffect(() => {
    console.log("hd_values.inspcer = ", hd_values.inspcer);
    const _tableData =
      hd_values.inspcer?.split("|")?.map((item, index) => ({
        key: index,
        url: item,
      })) || [];
    set_s_tableData(_tableData);
  }, []);

  const tableColumns = [
    {
      title: t("util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "10%",
      render: (_, __, index) => index + 1,
    },
    {
      title: "瀏覽",
      dataIndex: "url",
      align: "left",
      render: (text) => {
        const renderText = text?.split("/")?.pop() || "";
        return (
          <Typography.Link href={text} target="_blank">
            {renderText}
          </Typography.Link>
        );
      },
      // render: (text) => {
      //   const element = document.createElement("a");
      //   element.href = text;
      //   element.target = "_blank";
      //   // element.download = "myFile.txt";
      //   document.body.appendChild(element);
      //   element.click();
      //   document.body.removeChild(element);
      // },
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "operate",
      align: "center",
      width: "10%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          {/* 查看更多 */}
          <DeleteButton
            disabled={rowData.lifeF === "T" || rowData.apCount > 0}
            onClick={(e) => removeData(rowData)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <CustomTable rowNumber={5} columns={tableColumns} dataSource={s_tableData} />
      <Row gutter={[12, 0]} className="mt-2">
        <Col span={24} className="flex justify-end gap-2">
          <CancelButton onClick={() => set_s_showInspcer(false)} />

          <ConfirmButton type="primary" onClick={onSubmit} />
        </Col>
      </Row>
    </div>
  );
};

export default InspcerModal;
