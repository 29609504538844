import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { addInvProject, getInvProject, updateInvProject } from "@/service/apis/WMS/Inventory";

import dayjs from "dayjs";
import { getAllWhID } from "@/service/apis/WMS/WMS";
// api
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const ModalContainer = ({ s_tabKey, set_is_showModal, s_editData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // 判斷現在是新建盤點單還是編輯盤點單
  const isCreate = Object.keys(s_editData).length === 0;

  const call_getInvProject = useAPI(getInvProject); // 取得盤點計劃
  const call_addInvProject = useAPI(addInvProject);
  const call_updateInvProject = useAPI(updateInvProject);
  const call_getAllWhID = useAPI(getAllWhID); // 取得倉庫ID

  const [s_whID, set_s_whID] = useState([]); // 取得倉庫ID
  const [s_spinning, set_s_spinning] = useState(false);
  const [s_invFormData, set_s_invFormData] = useState({
    hd: [
      {
        "whID": "",
        "attribute": [],
      },
    ],
  });

  const attribute_options = [
    { value: "0|1|2|3|4|5|6|7", label: <span>all</span> },
    { value: "0", label: <span>0 原料</span> },
    { value: "1", label: <span>1 物料</span> },
    { value: "2", label: <span>2 非标零件</span> },
    { value: "3", label: <span>3 标准零件</span> },
    { value: "4", label: <span>4 半成品</span> },
    { value: "5", label: <span>5 制成品</span> },
    { value: "6", label: <span>6 客供品</span> },
    { value: "7", label: <span>7 维修备品</span> },
  ];

  const onSubmit = (values) => {
    if (isCreate) {
      const spilitValue = {
        ...values,
        hd: values.hd.map((a) => {
          return {
            ...a,
            attribute:
              typeof a.attribute === "string" && a.attribute.includes("0|1|2|3|4|5|6|7")
                ? a.attribute.split("|")
                : a.attribute,
          };
        }),
      };
      call_addInvProject.request(spilitValue);
      set_s_invFormData(spilitValue);
    } else {
      call_updateInvProject.request(values);
    }
  };
  // 取得盤點計劃
  useEffect(() => {
    const { status, msg, data } = call_getInvProject;
    if (status === "suc") {
      console.log(data);
    }
  }, [call_getInvProject.status]);

  // 取得倉庫ID
  useEffect(() => {
    const { status, msg, data } = call_getAllWhID;
    if (status === "suc") {
      set_s_whID(data);
    }
  }, [call_getAllWhID.status]);

  useEffect(() => {
    if (isCreate) {
      // 動態改變表單值
      form.setFieldsValue({
        type: s_tabKey,
        opcode: s_tabKey,
        opcodeNM: s_tabKey === "4020" ? "周期盘点" : "专案盘点", // 擷取opcode判斷顯示盤點類別
      });
    } else {
      console.log("s_editData = ", s_editData);
      form.setFieldsValue({
        ...s_editData,
        planDate: dayjs(s_editData.planDate),
        planCloseDate: dayjs(s_editData.planCloseDate),
        opcodeNM: s_tabKey === "4020" ? "周期盘点" : "专案盘点",
      });
    }
    call_getAllWhID.request(); // 發出倉別請求
  }, []);

  // useAPI 取得盤點明細
  // useEffect(() => {
  //   const { status, msg, data } = call_getInvCheckItem;
  //   if (status === "load") {
  //     set_is_spinning(true);
  //   }
  //   if (status === "suc") {
  //     const _data =
  //       data?.map((item, index) => ({
  //         ...item,
  //         key: index + 1,
  //         N: s_editData.N,
  //       })) ?? [];

  //     set_s_tableData(_data);
  //     console.log("_data = ", _data);
  //     set_is_spinning(false);
  //   }
  //   if (status === "err") {
  //     message.error(msg);
  //     set_is_spinning(false);
  //   }
  // }, [call_getInvCheckItem.status]);

  // useAPI 取得盤點資料
  // useEffect(() => {
  //   const { status, msg, data } = call_getInvData;
  //   if (status === "load") {
  //     set_is_spinning(true);
  //   }
  //   if (status === "suc") {
  //     const _data =
  //       data?.map((item, index) => ({
  //         ...item,
  //         key: index + 1,
  //         realqty: 0,
  //         bal: 0,
  //       })) || [];

  //     set_s_tableData(_data);
  //     set_is_spinning(false);
  //     console.log(_data)
  //   }
  //   if (status === "err") {
  //     message.error(msg);
  //     set_is_spinning(false);
  //   }
  // }, [call_getInvData.status]);

  // useAPI 新增盤點計畫
  useEffect(() => {
    const { status, msg } = call_addInvProject;
    if (status === "suc") {
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_is_showModal(false);
    }
  }, [call_addInvProject.status]);

  // useAPI 更新盤點計畫
  useEffect(() => {
    const { status, data, msg } = call_updateInvProject;
    if (status === "suc") {
      set_is_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_is_showModal(false);
    }
  }, [call_updateInvProject.status]);

  return (
    <>
      <Spin spinning={s_spinning}>
        <Form form={form} onFinish={onSubmit} layout="vertical">
          {/* 隱藏欄位 */}
          <div>
            <Form.Item name="type" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="opUUID" hidden>
              <Input />
            </Form.Item>
          </div>
          {/* 第一行*/}
          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item label="单别" name="opcode">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="计划单号" name="jobN">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="盘点单别" name="opcodeNM">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {/* 第二行*/}
          <Row gutter={[24, 12]}>
            <Col span={8}>
              <Form.Item label="盘点说明" name="keynote">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                // 預計盤點日
                label="预计盘点日"
                name="planDate"
                rules={[{ required: true }]}
              >
                <DatePicker className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="预计关帐日期" name="planCloseDate">
                <DatePicker className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item label="注记" name="note">
                <Input.TextArea rows={3} />
              </Form.Item>
            </Col>
          </Row>
          {/* 新增詳情 */}
          {isCreate === true ? (
            <div className="tbWrapper">
              <Divider style={{ margin: "6px" }} />
              <Row gutter={[36, 12]}>
                <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
                  盘点单明细
                </Col>
              </Row>

              <Form.List name="hd" shouldUpdate>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key} gutter={[12, 12]}>
                        <Col span={2} className="mb12">
                          <Form.Item
                            {...field}
                            //序
                            label={t("util.util.SN")}
                            name={[field.name, "itemno"]}
                          >
                            <Input disabled value={index + 1} />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            {...field}
                            // 倉別
                            label={t("util.util.whID")}
                            name={[field.name, "whID"]}
                          >
                            <Select
                              placeholder={t("util.util.placeholder_select") + t("util.util.whID")}
                              options={s_whID?.map((name) => ({
                                value: name.whID,
                                label: name.whNM,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="mb12">
                          <Form.Item
                            {...field}
                            // 屬性
                            label="属性"
                            name={[field.name, "attribute"]}
                          >
                            <Select
                              mode="multiple"
                              placeholder="請選擇屬性"
                              options={attribute_options}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3} className="flex-center">
                          <Form.Item label="有效" name={[field.name, "isvalid"]}>
                            <Radio.Group>
                              <Radio value={true}>{t("util.util.yes")}</Radio>
                              <ConfigProvider
                                theme={{
                                  components: {
                                    Radio: {
                                      colorPrimary: "red",
                                    },
                                  },
                                }}
                              >
                                <Radio value={false}>{t("util.util.no")}</Radio>
                              </ConfigProvider>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={3} className="flex-center">
                          <Form.Item label="关注" name={[field.name, "isfocus"]}>
                            <Radio.Group>
                              <Radio value={true}>{t("util.util.yes")}</Radio>
                              <ConfigProvider
                                theme={{
                                  components: {
                                    Radio: {
                                      colorPrimary: "red",
                                    },
                                  },
                                }}
                              >
                                <Radio value={false}>{t("util.util.no")}</Radio>
                              </ConfigProvider>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col span={3} className="flex-center">
                          <Form.Item label="封存" name={[field.name, "isstock"]}>
                            <Radio.Group>
                              <Radio value={true}>{t("util.util.yes")}</Radio>
                              <ConfigProvider
                                theme={{
                                  components: {
                                    Radio: {
                                      colorPrimary: "red",
                                    },
                                  },
                                }}
                              >
                                <Radio value={false}>{t("util.util.no")}</Radio>
                              </ConfigProvider>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={1} className="flex-center">
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                              let formData = form.getFieldValue();
                              formData.details = formData.details.map((x, index) => {
                                return { ...x, itemno: index + 1 };
                              });
                              form.setFieldsValue(formData);
                            }}
                            style={{ fontSize: "20px", marginLeft: "5px" }}
                          />
                        </Col>
                      </Row>
                    ))}

                    <Form.Item>
                      <Button
                        //新增生產入庫詳情
                        type="dashed"
                        onClick={() => {
                          let formData = form.getFieldsValue();
                          let whID = formData?.whID;
                          add({
                            itemno: fields.length + 1,
                            whID: whID,
                            attribute: "0|1|2|3|4|5|6|7",
                            isvalid: true,
                            isfocus: true,
                            isstock: false,
                          });
                        }}
                        block
                        icon={<PlusOutlined />}
                        size="large"
                      >
                        {t("util.util.add") + t("util.util.detail")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          ) : null}

          <Divider />
          <div className="flex justify-end gap-2">
            <Button size="large" onClick={() => set_is_showModal(false)}>
              {/* 取消 */}
              {t("util.util.cancel")}
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={call_addInvProject.status === "load"}
            >
              {/* 確定 */}
              {t("util.util.ok")}
            </Button>
          </div>
        </Form>
      </Spin>
    </>
  );
};

export default ModalContainer;
