import { useContext } from "react";
import { useTranslation } from "react-i18next";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// util function
import { formatAmount } from "@/util/format";

const Container = ({ hd_values, pageData, currentPage, totalPages }) => {
  // 人員資料
  const { getStaffName } = useContext(ERPContext);
  const { t } = useTranslation();

  const width = {
    no: 4,
    item_kode: 18,
    specification: 30,
    quantity: 10,
    unit: 8,
    price: 10,
    amount: 20,
  };

  return (
    <div className="flex flex-col w-full" >
      {/* 表頭 */}
      <div className="flex border-solid border-y-2 border-x-2 divide-x-2 divide-y-0 divide-solid text-[12px] text-center">
        {/* 序號 */}
        <div className={`flex-center pt-1`} style={{ width: `${width.no}%` }}>NO</div>
        {/* 料號 */}
        <div className={`flex-center pt-1`} style={{ width: `${width.item_kode}%` }}>ITEM KODE</div>
        <div className={`flex-center pt-1`} style={{ width: `${width.specification}%` }}>SPECIFICATION</div>
        <div className={`flex-center pt-1`} style={{ width: `${width.quantity}%` }}>
          <div>QUANTITY</div>
        </div>
        <div className={`flex-center pt-1`} style={{ width: `${width.unit}%` }}>
          <div>UNIT</div>
        </div>
        <div className={`flex-center pt-1`} style={{ width: `${width.price}%` }}>
          <div>PRICE</div>
        </div>
        <div className={`flex flex-col pt-1`} style={{ width: `${width.amount}%` }}>
          <div>AMOUNT</div>
        </div>

      </div>

      <div className="w-full  border-solid border-b-[1px] border-t-[0px] border-r-[0px] border-l-[0px] border h-4" />


      {/* 表格內容 */}
      {pageData?.map((item, index) => (
        <div
          key={index}
          className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-[10px] text-center h-[30px]"
        >
          <div className={`flex-center`} style={{ width: `${width.no}%` }}>{item.itemno}</div>
          <div className={`flex justify-start items-center`} style={{ width: `${width.item_kode}%` }} >
            <div className=" whitespace-nowrap">{item.PN}</div>
          </div>
          <div className={`flex justify-start items-center`} style={{ width: `${width.specification}%` }} >
            <div className="flex h-full flex-col justify-around">
              <div className=" flex items-start">{item.pdtNM}</div>
            </div>
          </div>
          <div className={`flex justify-end items-center pr-1`} style={{ width: `${width.quantity}%` }}>
            <div>{formatAmount(item.demandqty)}</div>
          </div>
          <div className={`flex justify-center items-center`} style={{ width: `${width.unit}%` }}>
            <div>{item.punit}</div>
          </div>
          <div className={`flex justify-end items-center pr-1`} style={{ width: `${width.price}%` }}>
            <div>{formatAmount(item.unitprice)}</div>
          </div>
          <div className={`flex justify-end items-center pr-1`} style={{ width: `${width.amount}%` }}>
            <div>{formatAmount(item.itemsum)}</div>
          </div>
        </div>
      )) ?? undefined}
      {currentPage !== totalPages && (
        <span className="flex justify-end text-[12px] mt-1">see next page...</span>
      )}

      {/* 最後一頁才要顯示 */}
      {currentPage === totalPages && (
        <div className="flex flex-col">
          <div className="flex border border-solid border-t-1 border-b-1  divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`flex-center pt-1`} style={{ width: `${width.no + width.item_kode + width.specification}%` }}>
              SUB TOTAL
            </div>
            <div className={`pt-1`} style={{ width: `${width.quantity}%` }} />
            <div className={`pt-1`} style={{ width: `${width.unit}%` }} />
            <div className={`flex-center`} style={{ width: `${width.price}%` }} />

            <div className={`pt-1 flex justify-end`} style={{ width: `${width.amount}%` }}>
              {formatAmount(hd_values.sumtotal)}
            </div>
          </div>

          <div className="flex border-solid border-t-0 border divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`pt-1 flex-center`} style={{ width: `${width.no + width.item_kode + width.specification}%` }}>
              PPN 11%（pajak）
            </div>
            <div className={`pt-1`} style={{ width: `${width.quantity}%` }} />
            <div className={`pt-1`} style={{ width: `${width.unit}%` }} />
            <div className={`flex-center`} style={{ width: `${width.price}%` }} />

            <div className={`pt-1 flex justify-end`} style={{ width: `${width.amount}%` }} >
              {formatAmount(hd_values.tax)}
            </div>
          </div>

          <div className="flex border-solid border-t-0 border-b-2 border divide-x divide-y-0 divide-solid text-[12px]">
            <div className={`pt-1 flex-center`} style={{ width: `${width.no + width.item_kode + width.specification}%` }}>
              TOTAL
            </div>
            <div className={`pt-1`} style={{ width: `${width.quantity}%` }} />
            <div className={`pt-1`} style={{ width: `${width.unit}%` }} />
            <div className={`flex-center`} style={{ width: `${width.price}%` }} />

            <div className={`pt-1 flex justify-end`} style={{ width: `${width.amount}%` }} >
              {formatAmount(hd_values.total)}
            </div>
          </div>

          <div className="text-[12px] flex flex-col mt-2">
            <span className="leading-5">
              PAYMENT CONDITION : ONE MONTH (45 DAYS AFTER INVOICE )
            </span>
          </div>

          {/* 備註區域要在最後一頁的最下方 */}
          {/* <div className="absolute bottom-[200px] text-[12px] w-full">

            <div className="flex-col justify-center items-start w-full border-solid border-y-[1px]  border-x-[1px]">
              <div className="flex-center w-full text-[12px] h-6  border-solid border-x-[1px] border-y-[1px] border-b-[2px] bg-zinc-300">Note</div>
              <div className="flex-start h-[200px] w-full text-[12px] border-solid border-x-[1px] border-y-[0px] border-b-[1px]">123</div>
            </div>

          </div> */}

          {/* 簽名區域要在最後一頁的最下方 */}

          <div className="absolute bottom-[0px] text-[12px] w-full">

            <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[2px] border-y-[1px] border-solid border-x-[2px] ">
              <div className="flex-center w-[22%] text-[12px] h-6 bg-zinc-300 ">APPROVAL,</div>
              <div className="flex-center  w-[48%] text-[12px] h-6 bg-zinc-300">CHECKED,</div>
              <div className="flex-center w-[30%] text-[12px] h-6 bg-zinc-300">PREPARED,</div>
            </div>

            <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[1px] border-solid border-x-[2px] ">
              <div className="flex-center w-[22%] text-[12px] h-[80px]"></div>
              <div className="flex-center  w-[24%] text-[12px] h-[80px]"></div>
              <div className="flex-center  w-[24%] text-[12px] h-[80px]"></div>
              <div className="flex-center w-[30%] text-[12px] h-[80px]"></div>
            </div>

            <div className="flex justify-center items-start w-full divide-x divide-solid divide-y-0 border-t-[1px] border-y-[2px] border-solid border-x-[2px] ">
              <div className="flex-center w-[22%] text-[10px] h-6 ">
                <div className="text-[12px]">Mr.Zack</div>
              </div>
              <div className="flex-center  w-[24%] text-[10px] h-6 ">
                <div className="text-[12px]">Mr.Philbert</div>
              </div>
              <div className="flex-center  w-[24%] text-[10px] h-6 ">
                <div className="text-[12px]">Mr.Budi</div>
              </div>
              <div className="flex-center w-[30%] text-[10px] h-6 ">
                <div className="text-[12px]">Tria</div>
              </div>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Container;
