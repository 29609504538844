import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AbcBorder from "@/pages/PDS/AbcBoard";
// APS
import ApsManage from "@/pages/APS/ApsManage";
import CategoryManage from "@/pages/ADM/SDM/CategoryManage";
import Check from "@/pages/OMS/Check";

import Company from "@/pages/ADM/SDM/Company";
import ContributionRaking from "@/pages/ADM/BDM/ContributionRaking";
// PDS
import Dashboard from "@/pages/PDS/DashBoard";
import DataAuthManage from "@/pages/ADM/SDM/DataAuthManage";
// BDM
import DetectionManage from "@/pages/ADM/BDM/DetectionManage";
import Diff from "@/pages/PMS/Report/Diff";
import Client from "@/pages/ADM/SDM/Client";
import ClientDev from "@/pages/ADM/SDM/ClientDev";
import EDM from "@/pages/ADM/BDM/EDM";
import ErrorPage from "@/pages/404";
import Event from "@/pages/BPM/Event";
import Fod from "@/pages/OMS/Fod";
import FunctionParamsManage from "@/pages/ADM/SDM/FunctionParamsManage";
import GoStock from "@/pages/PMS/GoStock";
import Instock from "@/pages/PMS/Instock";
import InstockSummary from "@/pages/PMS/Report/InstockSummary";
import InvHistory from "@/pages/WMS/InvHistory/index";
import InventoryDiff from "@/pages/PMS/Report/InventoryDiff";
import LabelDate from "@/pages/LMS/LabelDate";
import LabelMachines from "@/pages/LMS/LabelMachines";
import LabelPaper from "@/pages/LMS/LabelPaper";
import LabelSearch from "@/pages/LMS/LabelSearch";
// LMS
import LabelTemplate from "@/pages/LMS/LabelTemplate";
import Login from "@/pages/Login";
// EDM
import Mold from "@/pages/ADM/EDM/Mold";
import MyLayout from "@/components/Layout";
import OEMItemManage from "@/pages/ADM/SDM/OEMManage/OEMItemManage";
import OEMSuppManage from "@/pages/ADM/SDM/OEMManage/OEMSuppManage";
import { default as OMSApManage } from "@/pages/OMS/ApManage";
import { default as OMSRetutrn } from "@/pages/OMS/Return";
import { default as OMSTour } from "@/pages/OMS/Tour";
import Ord from "@/pages/OMS/Ord";
import { default as OrdOri } from "@/pages/OMS/Ord_ori";
import Outsourcing from "@/pages/PMS/Outsourcing";
import Outstock from "@/pages/OMS/Outstock";
import OverViewLines from "@/pages/PDS/OverViewLines";
import { default as PMSApManage } from "@/pages/PMS/ApManage";
import { default as PMSReturn } from "@/pages/PMS/Return";
import { default as PMSTour } from "@/pages/PMS/Tour";
import PNManage from "@/pages/ADM/BDM/PNManage";
import Payment from "@/pages/OMS/Payment";
import Performance from "@/pages/OMS/Performance";
// PMS
import Plan from "@/pages/PMS/Plan";
// WMS
import PlanIn from "@/pages/WMS/PlanIn";
import PlanOut from "@/pages/WMS/PlanOut";
import PlantManage from "@/pages/ADM/TDM/PlantManage";
import PlantParamsManage from "@/pages/ADM/TDM/PlantParamsManage/index";
import Postage from "@/pages/OMS/Postage";
import Pro from "@/pages/PMS/Pro";
import ProInstock from "@/pages/WMS/ProInstock";
import ProOutstock from "@/pages/WMS/ProOutstock";
import ProcedureManage from "@/pages/ADM/TDM/ProcedureManage/index";
import InvProject from "@/pages/WMS/InvProject/index";
// TDM
import ProductionLineManage from "@/pages/ADM/TDM/ProductionLineManage";
import ProfitAnalysis from "@/pages/OMS/ProfitAnalysis";
import PsiSummary from "@/pages/PMS/Report/PsiSummary";
import PurchaseList from "@/pages/PMS/Report/PurchaseList";
import PurchaseStatistics from "@/pages/PMS/Report/PurchaseStatistics";
import PurchaseSummary from "@/pages/PMS/Report/PurchaseSummary";
// QMS
import QMSManage from "@/pages/QMS/QMSManage";
// OMS
import Quo from "@/pages/OMS/Quo";
import Req from "@/pages/PMS/Req";
import RunA1 from "@/pages/OMS/RunA1/index";
import RunA3 from "@/pages/OMS/RunA3";
import SaleList from "@/pages/OMS/Report/SaleList";
import SaleSummary from "@/pages/OMS/Report/SaleSummary";
import SaleSummaryP from "@/pages/OMS/Report/SaleSummaryP";
import SalesRanking from "@/pages/ADM/BDM/SalesRanking/index";
// BPM
import SignLevel from "@/pages/BPM/SignLevel";
import StockSearch from "@/pages/WMS/StockSearch";
import StringManage from "@/pages/ADM/TDM/StringManage";
import Supp from "@/pages/ADM/SDM/Supp";
import TestMarquee from "@/pages/PDS/TestMarquee/index";
import TradingPattern from "@/pages/ADM/SDM/TradingPattern";
//PMS-Report
import UnPurchase from "@/pages/PMS/Report/UnPurchase";
//OMS-Report
import UnShipped from "@/pages/OMS/Report/UnShipped";
// SDM
import UserManage from "@/pages/ADM/SDM/UserManage";
import WarehouseManage from "@/pages/ADM/TDM/WarehouseManage";
import WpcSearch from "@/pages/PDS/WpcSearch";

export default function MyRoutes() {
	return (
		<BrowserRouter>
			<Routes>
				{/* 斜線導入 */}
				<Route path="/" element={<MyLayout />}>
					<Route path="/404" element={<ErrorPage />} />
					<Route path="/" element={<DetectionManage />} />
					{/* QMS */}
					<Route path="/qms/qms-manage" element={<QMSManage />} />
					{/* LMS */}
					<Route path="/lms/label-template" element={<LabelTemplate />} />
					<Route path="/lms/label-paper" element={<LabelPaper />} />
					<Route path="/lms/label-date" element={<LabelDate />} />
					<Route path="/lms/label-machines" element={<LabelMachines />} />
					<Route path="/lms/label-search" element={<LabelSearch />} />
					{/* PDS */}
					<Route path="/pds/dashboard" element={<Dashboard />} />
					<Route path="/pds/abc-board" element={<AbcBorder />} />
					<Route path="/pds/overview-lines" element={<OverViewLines />} />
					<Route path="/pds/test-marquee" element={<TestMarquee />} />
					{/* WPC */}
					<Route path="/wpc/wpc-search" element={<WpcSearch />} />
					{/* BDM */}
					<Route path="bdm/client" element={<Client />} />
					<Route path="bdm/clientdev" element={<ClientDev />} />

					<Route path="/bdm/detection-manage" element={<DetectionManage />} />
					<Route path="/bdm/pn-manage" element={<PNManage />} />
					<Route path="/bdm/edm" element={<EDM />} />
					<Route path="/bdm/oem-item-manage" element={<OEMItemManage />} />
					<Route path="/bdm/oem-supp-manage" element={<OEMSuppManage />} />
					<Route path="/bdm/salesranking" element={<SalesRanking />} />
					<Route path="/bdm/contributionraking" element={<ContributionRaking />} />
					{/* SDM */}
					<Route path="/sdm/user-manage" element={<UserManage />} />
					<Route path="/sdm/data-auth-manage" element={<DataAuthManage />} />
					<Route path="/sdm/category-manage" element={<CategoryManage />} />
					<Route path="/sdm/trading-pattern" element={<TradingPattern />} />
					<Route path="/sdm/company" element={<Company />} />
					<Route path="/sdm/supp" element={<Supp />} />

					<Route path="/sdm/function-params-manage" element={<FunctionParamsManage />} />
					{/* TDM */}
					<Route path="/tdm/warehouse-manage" element={<WarehouseManage />} />
					<Route path="/tdm/plant-manage" element={<PlantManage />} />
					<Route path="/tdm/production-line-manage" element={<ProductionLineManage />} />
					<Route path="/tdm/string-manage" element={<StringManage />} />
					<Route path="/tdm/procedure-manage" element={<ProcedureManage />} />
					<Route path="/tdm/plant-params-manage" element={<PlantParamsManage />} />
					{/* EDM */}
					<Route path="/edm/mold" element={<Mold />} />
					{/* APS */}
					<Route path="/aps/aps-manage" element={<ApsManage />} />
					{/* IMS */}
					<Route path="/ims/plan-in" element={<PlanIn />} />
					<Route path="/ims/plan-out" element={<PlanOut />} />
					<Route path="/ims/stock-search" element={<StockSearch />} />
					<Route path="/ims/inv-history" element={<InvHistory />} />
					<Route path="/ims/pro-instock" element={<ProInstock />} />
					<Route path="/ims/pro-outstock" element={<ProOutstock />} />
					<Route path="/ims/inv-project" element={<InvProject />} />
					{/* PMS */}
					<Route path="/pms" element={<PMSTour />} />
					<Route path="/pms/plan" element={<Plan />} />
					<Route path="/pms/req" element={<Req />} />
					<Route path="/pms/pro" element={<Pro />} />
					<Route path="/pms/instock" element={<Instock />} />
					<Route path="/pms/gostock" element={<GoStock />} />
					<Route path="/pms/return" element={<PMSReturn />} />
					<Route path="/pms/ap-manage" element={<PMSApManage />} />
					<Route path="/pms/outsourcing" element={<Outsourcing />} />
					{/* OMS */}
					<Route path="/oms" element={<OMSTour />} />
					<Route path="/oms/runa1" element={<RunA1 />} />
					<Route path="/oms/quo" element={<Quo />} />
					<Route path="/oms/fod" element={<Fod />} />
					<Route path="/oms/ord" element={<Ord />} />
					<Route path="/oms/ord_ori" element={<OrdOri />} />
					<Route path="/oms/performance" element={<Performance />} />
					<Route path="/oms/outstock" element={<Outstock />} />
					<Route path="/oms/check" element={<Check />} />
					<Route path="/oms/return" element={<OMSRetutrn />} />
					<Route path="/oms/ap-manage" element={<OMSApManage />} />
					<Route path="/oms/postage" element={<Postage />} />
					<Route path="/oms/profit-analysis" element={<ProfitAnalysis />} />
					<Route path="/oms/payment" element={<Payment />} />
					<Route path="/oms/run-a3" element={<RunA3 />} />
					<Route path="/oms/report-salesummary/:group" element={<SaleSummaryP />} />

					{/* OMS-Report */}
					<Route path="/oms/report-unshipped" element={<UnShipped />} />
					<Route path="/oms/report-salesummary" element={<SaleSummary />} />
					<Route path="/oms/report-salelist" element={<SaleList />} />

					{/* PMS-Report */}
					<Route path="/pms/report-unpurchase" element={<UnPurchase />} />
					<Route path="/pms/report-diff" element={<Diff />} />
					<Route path="/pms/report-instocksummary" element={<InstockSummary />} />
					<Route path="/pms/report-inventorydiff" element={<InventoryDiff />} />
					<Route path="/pms/report-psisummary" element={<PsiSummary />} />
					<Route path="/pms/report-purchasesummary" element={<PurchaseSummary />} />
					<Route path="/pms/report-purchaselist" element={<PurchaseList />} />
					<Route path="/pms/report-purchasestatistics" element={<PurchaseStatistics />} />

					{/* BPM */}
					<Route path="/bpm/sign-level" element={<SignLevel />} />
					<Route path="/bpm/event" element={<Event />} />
				</Route>

				<Route path="/login" element={<Login />} />
				{/* 未匹配 */}
				<Route path="*" element={<Navigate to="/404" />} />
			</Routes>
		</BrowserRouter>
	);
}
