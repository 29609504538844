import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  message,
  Radio,
  Space,
  Divider,
} from "antd";

import { addMgmtcat, modifyMgmtcat } from "@/service/apis/ADM/manage";
import { getCustHd } from "@/service/apis/ADM/client";
import { getSuppHd } from "@/service/apis/ADM/company";
import useAPI from "@/hooks/useAPI";

export default function ModalConintaer({ s_editData, set_s_isShowModal, s_searchData, s_tabKey }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [s_custOptions, set_s_custOptions] = useState([]);
  const [s_suppOptions, set_s_suppOptions] = useState([]);

  const call_getCustHd = useAPI(getCustHd);
  const call_getSuppHd = useAPI(getSuppHd);
  const call_addMgmtcat = useAPI(addMgmtcat);
  const call_modifyMgmtcat = useAPI(modifyMgmtcat);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";
    console.log("values = ", { ...values, ...s_searchData, type: s_tabKey ,mgmtitem:values.SN});
    console.log(values.item);
    if (values.custID && values.custID.length > 0) {
      values.custID = values.custID?.join("|") ?? "";
    }
    if (type === "create") {
      call_addMgmtcat.request({ ...values, ...s_searchData, type: s_tabKey });
    }

    if (type === "edit") {
      call_modifyMgmtcat.request({ ...values, ...s_searchData });
    }
  };

  useEffect(() => {
    call_getCustHd.request();
    call_getSuppHd.request();
   
    }, []);

  useEffect(() => {
    const { data, status, msg } = call_getCustHd;
    if (status === "suc") {
      const _data =
        data?.map((x) => ({
          label: `${x.custID}_${x.custNM}`,
          value: x.custID,
          data: x,
        })) || [];

      set_s_custOptions(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCustHd.status]);

  useEffect(() => {
    const { data, status, msg } = call_getSuppHd;
    if (status === "suc") {
      const _data =
        data?.map((x) => ({
          label: `${x.suppID}_${x.suppNM}`,
          value: x.suppID,
          data: x,
        })) || [];

      set_s_suppOptions(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getSuppHd.status]);

  useEffect(() => {
    if (call_addMgmtcat.status === "suc") {
      message.success(call_addMgmtcat.msg);
      set_s_isShowModal(false);
    } else if (call_addMgmtcat.status === "err") {
      message.error(call_addMgmtcat.msg);
    }
  }, [call_addMgmtcat.status]);

  useEffect(() => {
    if (call_modifyMgmtcat.status === "suc") {
      message.success(call_modifyMgmtcat.msg);
      set_s_isShowModal(false);
    } else if (call_modifyMgmtcat.status === "err") {
      message.error(call_modifyMgmtcat.msg);
    }
  }, [call_modifyMgmtcat.status]);

  console.log("call_getCustHd.data = ", call_getCustHd);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true, // 是否有效
        ...s_editData,
      }}
    >
      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item
            label={t("ADM.category-manage.mgmtitem")}//標準類別
            name="mgmtitem"
            // rules={[{ required: true }]}
            
          >
            <Input
              placeholder={`${t("util.placeholder_input")}${t("ADM.category-manage.mgmtitem")}`}
              disabled
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t("util.itemNM")}//項目名稱
           name="itemNM" rules={[{ required: true }]}>
            <Input placeholder={`${t("util.placeholder_input")}${t("util.itemNM")}`} />
          </Form.Item>
        </Col>

        {/* {s_tabKey === "mtype" && (
          <Col span={8}>
            <Form.Item label={t("util.custID")} name="custID" rules={[{ required: true }]}>
              <Select mode="multiple" options={s_suppOptions.concat(s_custOptions)} />
            </Form.Item>
          </Col>
        )} */}

        <Col span={8}>
          <Form.Item name="isvalid" label={t("util.isvalid")}>
            <Radio.Group>
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>

        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
