import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { Button, Input, Modal, Space, message } from "antd";
import { IconCheck, IconCircle, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { getSuppHd, modifySuppHd } from "@/service/apis/ADM/company";
import { useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer/index";
import PageHeader from "@/components/PageHeader/index";
import { getCurrency } from "@/service/apis/ADM/functionParamsManage";
import { getMgmtcat } from "@/service/apis/ADM/manage";
// util
import { multipleIDtoName } from "@/util/format";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = (props) => {
  const { t } = useTranslation();
  const { c_lightData, set_c_lightData } = props;
  const [s_searchData, set_s_searchData] = useState({
    name: "",
  });
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_tabKey, set_s_tabKey] = useState("valid");
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_mgmtcat71Options, set_s_mgmtcat71Options] = useState([]);
  const [s_mgmtcat72Options, set_s_mgmtcat72Options] = useState([]);
  const [s_currencyMap, set_s_currencyMap] = useState({});
  const call_getSuppHd = useAPI(getSuppHd);
  const call_modifySuppHd = useAPI(modifySuppHd);
  const call_getMgmtcat72 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);
  const call_getCurrency = useAPI(getCurrency);

  const isvalid = s_tabKey === "valid" ? true : false;

  const tableColumns = [
    {
      title: t("ADM.supp.suppID"), // 廠商ID
      dataIndex: "suppID",
      width: "15%",
      align: "center",
      sorter: (a, b) =>
        // 根據字串中的數字進行排序
        parseFloat(a.suppID.match(/\d+/g)[0]) - parseFloat(b.suppID.match(/\d+/g)[0]),
    },
    // {
    //   title: t("ADM.company.suppalias"), // 廠商簡稱
    //   dataIndex: "suppalias",
    //   width: "8%",
    // },
    {
      title: t("ADM.supp.suppNM"), // 廠商名稱
      dataIndex: "suppNM",
      // width: "8%",
    },
    // {
    //   title: t("ADM.company.location"), // 市場區域
    //   dataIndex: "location",
    //   align: "center",
    //   width: "8%",
    // },
    // {
    //   title: t("util.mgmtcat"), // 授權分類
    //   dataIndex: "mgmtcat",
    //   align: "center",
    //   width: "8%",
    //   render: () => {
    //     return s_mgmtcat71Options.map((data) => data.label).join();
    //   },
    // },
    // {
    //   title: t("util.querycat"), // 查詢分類
    //   dataIndex: "querycat",
    //   align: "center",
    //   width: "8%",
    //   render: (text) => s_mgmtcat72Options.filter((x) => x.value === text)[0]?.label,
    // },
    // {
    //   title: t("ADM.company.taxID"), // 統一編號
    //   dataIndex: "taxID",
    //   align: "center",
    //   width: "8%",
    // },
    {
      title: t("ADM.supp.maincurrency"), // 交易幣別
      dataIndex: "maincurrency",
      align: "center",
      width: "10%",
      render: (text) => s_currencyMap[text] || "",
    },
    {
      title: t("ADM.supp.payterm"), // 付款條件
      dataIndex: "payterm",
      width: "8%",
      align: "center",
    },
    {
      title: t("ADM.supp.maxcredit"), // 结算方式
      dataIndex: "maxcredit",
      align: "center",
      width: "8%",
    },
    // {
    //   title: t("util.isvalid"), // 是否有效
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: "5%",

    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },
    {
      title: t("util.action"), // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          {isvalid ? (
            <IconGarbage
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          ) : (
            <IconCircle
              onClick={(e) => {
                cycleData(rowData);
                e.stopPropagation();
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const tabsData = [
    {
      key: "valid",
      label: "启用中",
    },
    {
      key: "invalid",
      label: "停用中",
    },
  ];

  // 啟用客戶
  const cycleData = (rowData) => {
    call_modifySuppHd.request({ ...rowData, isvalid: true });
    // Modal.confirm({
    //   title: t("util.del_confirm"),
    //   icon: <ExclamationCircleOutlined className="text-red-600" />,
    //   content: t("util.del_confirm_msg"),
    //   okText: t("util.ok"),
    //   onOk: () => call_modifyCustHd.request({ ...rowData, isvalid: true }),
    //   cancelText: t("util.cancel"),
    // });
  };

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const onSearch = () => {
    set_c_lightData({});
    call_getSuppHd.request(s_searchData);
  };

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_modifySuppHd.request({ ...rowData, isvalid: false }),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    call_getMgmtcat72.request({ pageType: "72" });
    call_getMgmtcat71.request({ pageType: "71", type: "supp" });
    call_getCurrency.request();
  }, []);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getSuppHd.status === "suc") {
      message.success(call_getSuppHd.msg);
      set_s_tableData(
        call_getSuppHd.data.reduce((prev, curr) => {
          if (curr.suppID.includes("P")) {
            prev.push({
              ...curr,
              key: curr.suppID,
              mgmtcat: curr.mgmtcat.split("|").filter((x) => x !== ""),
            });
          }
          return prev;
        }, [])
        // call_getSuppHd.data.map((x, i) => ({
        //   ...x,
        //   key: x.suppID,
        //   index: i,
        //   mgmtcat: x.mgmtcat.split("|").filter((x) => x !== ""),
        // }))
      );
    } else if (call_getSuppHd.status === "err") {
      message.error(t(`errorCode.${call_getSuppHd.msg}`));
      set_s_tableData([]);
    }
  }, [call_getSuppHd.status]);

  useEffect(() => {
    const { data, status, msg } = call_getCurrency;
    if (status === "suc") {
      console.log(data);
      if (data.length === 0) return;

      const _data = data.reduce((prev, curr) => {
        prev[curr.currencyitem] = curr.itemNM;
        return prev;
      }, {});
      set_s_currencyMap(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCurrency.status]);

  useEffect(() => {
    if (call_modifySuppHd.status === "suc") {
      message.success(call_modifySuppHd.msg);
      // delete之後 重call表格資料
      call_getSuppHd.request();
    } else if (call_modifySuppHd.status === "err") {
      message.error(t(`errorCode.${call_modifySuppHd.msg}`));
      // delete之後 重call表格資料
      call_getSuppHd.request();
    }
    // delete之後 重call表格資料
    call_getSuppHd.request();
  }, [call_modifySuppHd.status]);

  useEffect(() => {
    if (call_getMgmtcat71.status === "suc") {
      set_s_mgmtcat71Options(
        () =>
          call_getMgmtcat71.data?.reduce(
            (data, current) => {
              data.push({
                label: current.itemNM,
                value: current.mgmtitem,
                type: current.type,
              });
              return data;
            },
            [{ label: t("util.all"), value: "All", type: "" }]
          ) || []
      );
    } else if (call_getMgmtcat71.status === "err") {
      // message.error(call_getMgmtcat71.msg);
    }
  }, [call_getMgmtcat71.status]);

  useEffect(() => {
    if (call_getMgmtcat72.status === "suc") {
      set_s_mgmtcat72Options(
        () =>
          call_getMgmtcat72.data?.reduce(
            (data, current) => {
              data.push({
                label: current.itemNM,
                value: current.mgmtitem,
                type: current.type,
              });
              return data;
            },
            [{ label: t("util.all"), value: "All", type: "" }]
          ) || []
      );
    } else if (call_getMgmtcat72.status === "err") {
      // message.error(call_getMgmtcat72.msg);
    }
  }, [call_getMgmtcat72.status]);

  return (
    <>
      <PageHeader
        title={t("ADM.supp.pageHeader")}
        extra={[
          <Input
            key="s_searchData.item"
            value={s_searchData.name}
            onChange={(e) => handlChange("name", e.target.value)}
            className="w-[550px]"
            onPressEnter={onSearch}
            placeholder={`${t("util.placeholder_input")}${t("ADM.supp.suppID")}，${t(
              "ADM.supp.suppalias"
            )}，${t("ADM.supp.suppNM")}`}
          />,
          // <Button type="primary" key="search" onClick={onSearch}>
          //   {t("util.search")}
          // </Button>,
          // <Button
          //   type="primary"
          //   key="create"
          //   onClick={() => {
          //     set_c_lightData({});
          //     set_s_isShowModal(true);
          //   }}
          // >
          //   {t("util.add")}
          // </Button>,

          <SearchButton type="primary" key="search" onClick={onSearch} />,

          <AddButton
            type="primary"
            key="create"
            disabled={s_tabKey === "invalid"}
            onClick={() => {
              set_c_lightData({});
              set_s_isShowModal(true);
            }}
          />,
        ]}
      />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => set_s_tabKey(activeKey)}
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.filter((item) => item.isvalid === isvalid)}
        loading={call_getSuppHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => {
          return {
            onClick: () =>
              record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
          };
        }}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.edit")}${t("ADM.supp.pageHeader")}`
            : `${t("util.add")}${t("ADM.supp.pageHeader")}`
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getSuppHd.request();
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={{
            s_mgmtcat71Options: s_mgmtcat71Options,
            s_mgmtcat72Options: s_mgmtcat72Options,
          }}
        />
      </CustomModal>
    </>
  );
};

export default HD;
