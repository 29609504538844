import { instance } from "../../handlerAPI";
import { oms_apiBasic as apiBasic } from "../../APIBasic";
//-----------------------------------------新增沖帳作業表頭----------------------------------------
export const addStrikeHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStrikeHD`, data);
};
//-----------------------------------------取得沖帳作業表頭----------------------------------------
export const getStrikeHD = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStrikeHD`, {
    params: data,
  });
};
//-----------------------------------------編輯沖帳作業表頭----------------------------------------
export const updateStrikeHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateStrikeHD`, data);
};
//-----------------------------------------刪除沖帳作業表頭----------------------------------------
export const deleteStrikeHD = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStrikeHD`, data);
};
//-----------------------------------------刪除沖帳作業表身----------------------------------------
export const addStrikeTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addStrikeTB`, data);
};
//-----------------------------------------取得沖帳作業表身----------------------------------------
export const getStrikeTB = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getStrikeTB`, {
    params: data,
  });
};
//-----------------------------------------編輯沖帳作業表身----------------------------------------
export const updateStrikeTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateStrikeTB`, data);
};
//-----------------------------------------刪除沖帳作業表身----------------------------------------
export const deleteStrikeTB = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteStrikeTB`, data);
};
//-----------------------------------------引用對帳單----------------------------------------
export const takeAP = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/takeAP`, data);
};
