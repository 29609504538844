/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import {
  Divider,
  Button,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Form,
  InputNumber,
  message,
  Spin,
  Space,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { DeleteButton } from "@/components/ERP/Button";
// API
import useAPI from "@/hooks/useAPI";
// import { getRec } from "../../moke";
import { getRec, updateRec } from "@/service/apis/PMS/Plan";
// content
import { CancelButton, ConfirmButton, CreateButton } from "@/components/ERP/Button";

const { Text } = Typography;

const Wrapper = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
`;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  // 切換是否編輯
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  // 編輯完成執行
  const save = async () => {
    try {
      const values = form.getFieldsValue();
      toggleEdit();
      handleSave(record, values);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     // message: `${title} is required.`,
        //   },
        // ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "100%" }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const RecModal = ({ s_recModal, set_s_recModal }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [s_tableData, set_s_tableData] = useState([]);
  // useAPI 取得規格評估
  const call_getRec = useAPI(getRec);
  // useAPI 更新規格評估
  const call_updateRec = useAPI(updateRec);

  const removeData = (rowData) => {
    const _tableData = s_tableData
      .filter((item) => item.key !== rowData.key)
      .map((item, index) => ({
        ...item,
        key: index + 1,
        itemUUID: s_recModal.data.itemUUID,
        recno: index + 1,
      }));

    set_s_tableData(_tableData);
  };

  const onSubmit = () => {
    call_updateRec.request({ "itemUUID": s_recModal.data.itemUUID, "rec": s_tableData });
  };

  // 第一次渲染
  useEffect(() => {
    call_getRec.request({ itemUUID: s_recModal.data.itemUUID });
  }, []);

  // useAPI 取得規格評估
  useEffect(() => {
    const { data, msg, status } = call_getRec;
    if (status === "suc") {
      message.success(msg);
      set_s_tableData(data?.map((item, index) => ({ ...item, key: index })) || []);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getRec.status]);

  // useAPI 修改規格評估
  useEffect(() => {
    const { msg, status } = call_updateRec;
    if (status === "suc") {
      message.success(msg);
      set_s_recModal({ show: false, data: {} });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_recModal({ show: false, data: {} });
    }
  }, [call_updateRec.status]);

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "5%",
      render: (_, __, index) => index + 1,
    },

    {
      title: <span style={{ color: "#FF850A" }}>{t("util.pdtspec")}</span>,
      dataIndex: "pdtspec", // 規格
      editable: true,
      align: "center",
      width: "30%",
    },
    {
      title: <span style={{ color: "#FF850A" }}>{t("util.note")}</span>,
      dataIndex: "note", // 註記
      editable: true,
      align: "center",
      // width: "10%",
    },
    {
      title: t("util.render"), //"選擇",
      dataIndex: "render",
      align: "center",
      width: "15%",
      render: (_, rowData, __) => (
        <Space>
          {/* 刪除表身資料 */}
          <DeleteButton onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];

  const handleSave = (record, values) => {
    const _tableData = s_tableData.map((item) => {
      if (item.key === record.key) {
        return {
          ...record,
          ...values,
        };
      }
      return item;
    });
    set_s_tableData(_tableData);
  };

  const columns = tableColumns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <Spin spinning={call_getRec.status === "load"}>
      <div className="flex justify-end m-2">
        {/* 新建的 button */}
        <CreateButton
          // disabled={s_disabled.createBtn}
          onClick={() => {
            const newData = [
              {
                recno: s_tableData.length + 1,
                itemno: "",
                itemUUID: s_recModal.data.itemUUID,
                pdtspec: "",
                note: "",
              },
            ];
            const _tableData = s_tableData
              .concat(newData)
              .map((item, index) => ({ ...item, key: index }));
            set_s_tableData(_tableData);
          }}
        />
      </div>
      <CustomTable
        components={components}
        rowClassName={() => "editable-row"}
        columns={columns}
        rowNumber={6}
        dataSource={s_tableData}
      />

      <Divider />

      <div className="flex justify-end gap-3">
        <CancelButton onClick={() => set_s_recModal({ show: false, data: {} })} />
        <ConfirmButton type="primary" onClick={onSubmit} />
      </div>
    </Spin>
  );
};

export default RecModal;
