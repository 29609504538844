import { useContext } from "react";
import { Row, Col, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);

  const { data } = s_drawer;

  const opcode_map = {
    1310: t("OMS.util.opcode_1310"), // 國內訂單
    1320: t("OMS.util.opcode_1320"), // 國外訂單
  };

  const lifeF_map = {
    0: t("OMS.ord.lifeF_0"), // 發佈
    2: t("OMS.ord.lifeF_2"), // 銷貨覆核
    7: t("OMS.ord.lifeF_7"), // 客戶簽回
    T: t("OMS.ord.lifeF_T"), // 結案
  };

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 單別 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.opcode")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{opcode_map[data.opcode]}</Text>
        </Col>
        {/* 單號 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.N")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.N}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 客戶單號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.dealN")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.dealN}</Text>
        </Col>
        {/* 付款方式 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.ord.payment")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.payment}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
       
        {/* 計畫時間 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.ord.planday")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.planday}</Text>
        </Col>
         {/* 未稅總金額 */}
         <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.ord.sumtotal")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{formatAmount(data.sumtotal)}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 客戶ID */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.custID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.custID}</Text>
        </Col>
        {/* 客戶簡稱 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.custalias")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{data.custalias}</Text>
        </Col>
        {/* 預計交貨日 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.fod.EDT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value flex-col">
          <Text>起：{dayjs(data.startEDT).format("YYYY-MM-DD")}</Text>
          <Text>迄：{dayjs(data.endEDT).format("YYYY-MM-DD")}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 客戶地址 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.dealaddr")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.dealaddr}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 客戶業務窗口 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.dealcontact")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.dealcontact}</Text>
        </Col>
        {/* 電話 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.tel")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.dealtel}</Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 創建日期 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.createT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{dayjs(data.createT).format("YYYY-MM-DD")}</Text>
        </Col>
        {/* 覆核日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.confirmT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.confirmT?.includes("0001-01-01") ? "" : dayjs(data.confirmT).format("YYYY-MM-DD")}
          </Text>
        </Col>
        {/* 簽回日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.signT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.signT?.includes("0001-01-01") ? "" : dayjs(data.signT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 創建人員 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.util.createID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.createID)}</Text>
        </Col>
        {/* 覆核人員 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.confirmID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.confirmID)}</Text>
        </Col>
        {/* 核準日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.approveT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.signT?.includes("0001-01-01") ? "" : dayjs(data.signT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

      <Row className="mt-5 border pms-drawer-border-color">
        {/* 負責業務 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.driID")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{getStaffName(data.driID)}</Text>
        </Col>
        {/* 歷程 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("OMS.util.lifeF")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>{lifeF_map[data.lifeF]}</Text>
        </Col>
        {/* 更新日期 */}
        <Col span={4} className="pms-drawer-title border-l border-r">
          <Text>{t("OMS.util.updtT")}</Text>
        </Col>
        <Col span={4} className="pms-drawer-value">
          <Text>
            {data.updT?.includes("0001-01-01") ? "" : dayjs(data.updT).format("YYYY-MM-DD")}
          </Text>
        </Col>
      </Row>

        {/* 註記 */}
      {/* <Row className="border border-t-0 pms-drawer-border-color">
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("OMS.ord.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row> */}
    </div>
  );
};

export default DrawerModal;
