import { Button, DatePicker, Input, Select, Space, Spin, Table, Typography, message } from "antd";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// context
import { ERPContext } from "@/components/Context/SystemContext";
import SystemContext from "@/components/Context/SystemContext";
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import { TourButton } from "@/components/ERP/Button";
import { IconExcelDownload } from "@/components/Icon/Action";
import { SearchButton } from "@/components/PDS/Buttons";
// util component
import PageHeader from "@/components/PageHeader";
import useAPI from "@/hooks/useAPI";
//API
import apiBasic from "@/service/APIBasic";
// 銷貨彙總表 - 路由
import { exportSaleSummary, saleSummary } from "@/service/apis/OMS/Report";
import filterOption from "@/util/filterOption";
//util
import { calculateAmount, formatAmount, formatAmountWithCurrency } from "@/util/format"; //數字加上千分位
import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";

const { Text } = Typography;

//訂單彙總表
const SaleSummaryP = () => {
  const { RangePicker } = DatePicker;

  const { c_custs } = useContext(ERPContext); //從ERPContext拿到客戶資料
  const { c_systemConfig } = useContext(SystemContext);
  const { t } = useTranslation();

  const call_getSaleSummary = useAPI(saleSummary); // useAPI 取得訂單出貨狀態資料
  // const call_getSaleSummary = useAPI(getSalesSummary);

  const [s_OriData, set_s_OriData] = useState([]); //api拿到的原始資料

  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料

  const [is_spin, set_is_spin] = useState(false);

  const [is_expanded_AllRow, set_is_expanded_AllRow] = useState(false); //是否展開所有子表格

  let { group } = useParams(); //拿到路由參數，用來當作組成群組的條件
  // console.log("group", group);

  const call_exportSaleSummary = useAPI(exportSaleSummary);

  const [s_pageHeader_Name, set_pageHeader_Name] = useState(""); //頁面標題名稱

  const [dateRangeType, setDateRangeType] = useState("month"); //搜尋區間: 當日、當週、當月

  const [shouldExport, setShouldExport] = useState(false); //是否要導出excel

  const [s_ExcelData, set_s_ExcelData] = useState([]); //放到excel的資料

  const [expandedRowKeys, setExpandedRowKeys] = useState([]); //子表格展開行,因為展開行的索引會被記住，所以每次拿到資料都要重新設定展開行的索引為空陣列

  const [total_Info, set_total_Info] = useState({
    sumtotal: 0, //總項金額
    tax: 0, //總項稅額
    total: 0, //應收含稅總金額
  }); //所有總計

  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    // opcode: "", //單別
    // dealN: "", //客戶單號
    // pdtspec: "",  //規格
    // dealPN: "", //交易料號
    custID: ["All"], //客戶ID
    // PN: "", //料號
    // pdtNM: "", //品名
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  const [tableHeight, setTableHeight] = useState(0);

  const updateTableHeight = () => {
    setTableHeight(window.innerHeight * 0.7);
  };

  useEffect(() => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [tableHeight]);

  const tableColumns = [
    {
      title: "客户", // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
      width: "20%",
      render: (text, record) => {
        return <span>{`${record.custID} - ${text}`}</span>;
        // return record.hasOwnProperty("opcode") ? <span>{`${record.custID} - ${text}`}</span> : null;
      },
    },
    {
      title: "含税小计",
      dataIndex: "count",
      align: "center",
      width: "20%",
      render: () => {
        return <span className={"text-black font-semibold"}>{t("OMS.util.subtotal")}</span>;
      },
    },
    {
      title: t("OMS.ap-manage.ap_sumtotal"), // 總項金額
      // dataIndex: "itemsum",
      dataIndex: "sumtotal",
      align: "right",
      width: "20%",
      render: (text) => {
        // return <span className={"text-black font-semibold"}>{text}</span>;
        return <span className={"text-black font-semibold"}>{formatAmountWithCurrency(text)}</span>;
      },
    },
    {
      title: t("util.util.tax"), // 總項稅額
      // dataIndex: "itemsumtax",
      dataIndex: "tax",
      align: "right",
      width: "20%",
      render: (text) => {
        // return <span className={"text-black font-semibold"}>{text}</span>;
        return <span className={"text-black font-semibold"}>{formatAmountWithCurrency(text)}</span>;
      },
    },
    {
      title: t("OMS.ap-manage.ap_total"), // 應收含稅總金額
      // dataIndex: "subtotal",
      dataIndex: "total",
      align: "right",
      width: "20%",
      render: (text) => {
        // return <span className={"text-black font-semibold"}>{text}</span>;
        return <span className={"text-black font-semibold"}>{formatAmountWithCurrency(text)}</span>;
      },
    },
  ];

  //   {
  //     title: t("OMS.util.N14"), // 發貨單號
  //     dataIndex: "N14",
  //     align: "center",
  //     width: "10%",
  //   },
  //   {
  //     title: t("OMS.ord.N13"), // 消
  //     dataIndex: "N",
  //     align: "center",
  //     width: "10%",
  //   },
  //   {
  //     title: t("OMS.ap-manage.ap_paytotal"), // 已沖帳金額
  //     dataIndex: "rcvtotal",
  //     align: "right",
  //     width: "10%",
  //     render: (text) => formatAmount(text, 3),
  //   },
  //   {
  //     title: t("OMS.ap-manage.ap_balance"), // 帳差
  //     dataIndex: "balance",
  //     align: "right",
  //     width: "10%",
  //     render: (text) => formatAmount(text, 3),
  //   },
  //   {
  //     title: t("OMS.ap-manage.ap_sumtotal"), // 總項金額
  //     dataIndex: "sumtotal",
  //     align: "right",
  //     width: "20%",
  //     render: (text, record) => {
  //       return (
  //         <span className={`${record.hasOwnProperty("N") ? null : "text-black font-semibold"}`}>
  //           {formatAmount(text)}
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     title: t("util.util.tax"), // 總項稅額
  //     dataIndex: "tax",
  //     align: "right",
  //     width: "20%",
  //     render: (text, record) => {
  //       return (
  //         <span className={`${record.hasOwnProperty("N") ? null : "text-black font-semibold"}`}>
  //           {formatAmount(text)}
  //         </span>
  //       );
  //     },
  //   },
  //   {
  //     title: t("OMS.ap-manage.ap_total"), // 應收含稅總金額
  //     dataIndex: "total",
  //     align: "right",
  //     width: "20%",
  //     render: (text, record) => {
  //       return (
  //         <span className={`${record.hasOwnProperty("N") ? null : "text-black font-semibold"}`}>
  //           {formatAmount(text)}
  //         </span>
  //       );
  //     },
  //   },
  // ];

  const subColumns = [
    {
      title: t("OMS.util.N14"), // 發貨單號
      dataIndex: "N14",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.N13"), // 銷貨單號
      dataIndex: "N13",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.N12"), // 預訂單號
      dataIndex: "N12",
      align: "center",
      width: "10%",
    },
    // {
    //   title: t("OMS.util.N11"), // 報價單號
    //   dataIndex: "N11",
    //   align: "center",
    //   width: "7%",
    // },
    {
      title: t("OMS.util.dealN"),
      dataIndex: "dealN",
      align: "center",
      width: "7%",
    },
    {
      title: t("util.util.PN"), // ERP料號
      dataIndex: "PN",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.dealPN"), // 交易料號
      dataIndex: "dealPN",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.util.currencytype"),
      dataIndex: "currencytype",
      align: "center",
      width: "3%",
    },
    {
      title: t("OMS.ap-manage.unitprice"), // 銷貨單價
      dataIndex: "unitprice",
      align: "right",
      width: "5%",
      render: (text, record) => formatAmountWithCurrency(text, record.currencytype),
    },
    {
      title: t("OMS.ap-manage.rcv_itemsum"), // 各項金額
      dataIndex: "itemsum",
      align: "right",
      width: "5%",
      render: (text, record) => formatAmountWithCurrency(text, record.currencytype),
    },
    {
      title: t("OMS.ap-manage.rcv_itemsumtax"), // 各項稅額
      dataIndex: "itemsumtax",
      align: "right",
      width: "5%",
      render: (text, record) => formatAmountWithCurrency(text, record.currencytype),
    },
    {
      title: t("OMS.ap-manage.rcv_subtotal"), // 含稅小計
      dataIndex: "subtotal",
      align: "right",
      width: "5%",
      render: (text, record) => formatAmountWithCurrency(text, record.currencytype),
    },
  ];

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  // 搜尋資料
  const handleClick = () => {
    const tmp = {
      ...s_searchData,
      custID: s_searchData.custID.join("|"), //要將客戶陣列轉成字串，用直槓隔開
    };
    setDateRangeType(null);
    set_is_expanded_AllRow(false);
    console.log("tmp", tmp);
    call_getSaleSummary.request(tmp);
  };

  const handleDateRangeTypeChange = (type) => {
    setDateRangeType(type);
    call_getSaleSummary.request({
      ...s_searchData,
      custID: s_searchData.custID.join("|"),
      startDate: dayjs().startOf(type).format("YYYY-MM-DD"), // 開始日期
      endDate: dayjs().endOf(type).format("YYYY-MM-DD"), // 結束日期
    });
    set_s_searchData({
      ...s_searchData,
      startDate: dayjs().startOf(type).format("YYYY-MM-DD"),
      endDate: dayjs().endOf(type).format("YYYY-MM-DD"),
    });
  };

  // 將CountArr資料組成要的格式再塞到表格
  const handleTableData = (data) => {
    let new_tableData = [];

    data.forEach((item) => {
      let new_obj = {};
      let childrenItem = item.slice(0, -1); //除了合計以外的資料
      new_obj = { ...item[0], ...item[item.length - 1], childrenItem: [...childrenItem] }; //把合計以外的物件變成childrenItem
      new_tableData.push(new_obj);
    });

    return new_tableData;
  };

  const pageHeader_Name_map = {
    "during": t("util.util.during"),
    "salesPerson": "业务",
    "cust": "客户",
  };

  // 渲染子表格的函数
  const expandedRowRender = (record) => {
    // 使用record的某个属性作为子表格的数据源
    const subData = record.childrenItem; // 假设subItems是包含子表格数据的属性

    return (
      <Table
        rowKey={(record) => record.key}
        columns={subColumns}
        dataSource={subData}
        pagination={false}
      />
    );
  };

  //CALL API從後端導出EXCEL
  const exportToExcel = () => {
    console.log("exportToExcel => ", {
      ...s_searchData,
      pageHeader_Name: pageHeader_Name_map[s_pageHeader_Name],
      custID: s_searchData.custID.join("|"),
      lang: c_systemConfig.locale,
    });

    call_exportSaleSummary.request({
      ...s_searchData,
      pageHeader_Name: pageHeader_Name_map[s_pageHeader_Name],
      custID: s_searchData.custID.join("|"),
      lang: c_systemConfig.locale,
    });
  };

  //設定子表格全部展開或收合
  const handleExpandedRowKeys = () => {
    if (is_expanded_AllRow) {
      setExpandedRowKeys([]);
      set_is_expanded_AllRow(false);
    } else {
      const keys = s_TableData.map((_, index) => index + 1);
      setExpandedRowKeys(keys);
      set_is_expanded_AllRow(true);
    }
  };

  useEffect(() => {
    const { status, msg, data } = call_exportSaleSummary;
    if (status === "suc") {
      message.success(msg);
      console.log("data", data);
      // const fileName = data.split("/")[2];
      const link = document.createElement("a");

      // link.href = `${apiBasic.conn}://${apiBasic.url}${data}`;
      link.target = "_blank";
      link.href = data;
      // link.download = fileName;
      // console.log("fileName = ", fileName);
      console.log("link.href = ", link.href);

      document.body.appendChild(link);
      // return
      link.click();
      document.body.removeChild(link);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_exportSaleSummary.status]);

  useEffect(() => {
    call_getSaleSummary.request(s_searchData);
  }, []);

  //根據group來決定母表跟子表的欄位應該要顯示哪些，以及組成群組的條件
  useEffect(() => {
    if (group === "during") {
      set_pageHeader_Name("during");
    }
    if (group === "driNM") {
      set_pageHeader_Name("salesPerson");
    }
    if (group === "custalias") {
      set_pageHeader_Name("cust");
    }
  }, [group]);

  //所有訂單表頭資料
  useEffect(() => {
    const { status, msg, data } = call_getSaleSummary;
    if (status === "suc") {
      // console.log("call_getSaleSummary = ", data);
      set_s_OriData(data);
      setExpandedRowKeys([]);
      message.success(msg);
    } else if (status === "err") {
      set_s_OriData([]);
      setExpandedRowKeys([]);
      message.error(msg);
    }
  }, [call_getSaleSummary.status]);

  useEffect(() => {
    if (call_getSaleSummary.status === "load") {
      set_is_spin(true);
    } else {
      set_is_spin(false);
    }
  }, [call_getSaleSummary.status]);

  //拿到原始資料後做分組，將資料組成要的格式再塞到表格
  useEffect(() => {
    console.log("s_OriData = ", s_OriData);
    if (s_OriData.length === 0) {
      set_s_TableData([]);
      set_total_Info({
        sumtotal: 0, //總項金額
        tax: 0, //總項稅額
        total: 0, //應收含稅總金額
      });
      return;
    }
    //group 方法.
    const groupResult = s_OriData.reduce((accumulator, currentValue) => {
      // console.log("accumulator = ", accumulator);
      // console.log("currentValue = ", currentValue);
      if (!accumulator[currentValue.custalias]) {
        accumulator[currentValue.custalias] = [];
      }
      accumulator[currentValue.custalias].push(currentValue);
      return accumulator;
    }, {});
    console.log("groupResult = ", groupResult);
    //取出各群組中的值
    const groupArr = Object.values(groupResult);
    console.log("groupArr = ", groupArr);
    //-----------------加上合併欄位數、總計列-------------------------------------
    let count_total_Info = {
      sumtotal: 0, //總項金額
      tax: 0, //總項稅額
      total: 0, //應收含稅總金額
    };
    const CountArr = groupArr.map((item) => {
      const count = item.reduce(
        (accumulator, currentValue) => {
          const sumtotal = calculateAmount(
            currentValue.itemsum,
            currentValue.currencytype,
            currentValue.exchangerate
          );
          const tax = calculateAmount(
            currentValue.itemsumtax,
            currentValue.currencytype,
            currentValue.exchangerate
          );
          const total = calculateAmount(
            currentValue.subtotal,
            currentValue.currencytype,
            currentValue.exchangerate
          );

          accumulator.sumtotal += sumtotal;
          accumulator.tax += tax;
          accumulator.total += total;

          count_total_Info.sumtotal += sumtotal;
          count_total_Info.tax += tax;
          count_total_Info.total += total;
          // accumulator.sumtotal += currentValue.sumtotal;
          // accumulator.tax += currentValue.tax;
          // accumulator.total += currentValue.total;
          // count_total_Info.sumtotal += currentValue.sumtotal;
          // count_total_Info.sumtotal += currentValue.itemsum;
          // count_total_Info.tax += currentValue.tax;
          // count_total_Info.tax += currentValue.itemsumtax;
          // count_total_Info.total += currentValue.total;
          // count_total_Info.total += currentValue.subtotal;

          return accumulator;
        },
        {
          N: "總計",
          sumtotal: 0, //總項金額
          tax: 0, //總項稅額
          total: 0, //應收含稅總金額
        }
      );

      // console.log("count_total_Info = ", count_total_Info);
      set_total_Info({ ...count_total_Info });
      item.push(count); //加上合計欄
      // item[0].rowSpan = item.length; //第一筆資料加上合併欄位數字

      return item;
    });

    const finalArr = [].concat(...CountArr); //CountArr = [arr(2),arr(3),arr(1)]，用contact才可以接成一個陣列
    console.log("finalArr", finalArr);
    //將OPCODE從1710、1720改成國內、國外銷貨單，若是總計欄則保持空值
    const replace_opcode = finalArr.map((item, index) => {
      let new_opcode = null;
      if (item.opcode === "1710") {
        new_opcode = "國內銷貨";
      } else if (item.opcode === "1720") {
        new_opcode = "國外銷貨";
      } else {
        new_opcode = null;
      }
      return { ...item, opcode: new_opcode, key: index };
    }); //加上key值
    console.log("replace_opcode", replace_opcode);
    // set_s_TableData(finalArr);
    set_s_ExcelData(replace_opcode);
    set_s_TableData(handleTableData(CountArr));

    console.log("finalArr", finalArr);
  }, [s_OriData]);

  return (
    <>
      <div className="flex flex-col w-full justify-between relative">
        <Spin spinning={is_spin}>
          <div className="flex flex-col w-full justify-between">
            {/*表頭*/}
            <PageHeader
              title={`7-${
                s_pageHeader_Name === "during" ? "I5业绩汇总表_期间" : "I6业绩汇总表_客户"
              }`}
            />

            <Space className="flex justify-end gap-2 mb-2">
              <Button type="primary" onClick={() => handleExpandedRowKeys()}>
                {is_expanded_AllRow ? <CompressOutlined /> : <ExpandOutlined />}
              </Button>

              {/*導出excel*/}

              {/*客戶選擇*/}
              <Text className={`${group === "custalias" ? null : "hidden"}`}> {"客户"} : </Text>
              <Select
                className={`w-[400px] ${group !== "custalias" ? "hidden" : null}`}
                name="custID"
                mode="multiple"
                defaultValue="All"
                showSearch
                allowClear
                placeholder={t("util.util.placeholder_select")}
                onChange={(value) => handle_SearchData_Change("custID", value)}
                options={c_custs?.options?.reduce(
                  (accumulator, option) => {
                    accumulator.push({
                      label: `${option.value}_${option.label}`,
                      value: option.value,
                    });
                    return accumulator;
                  },
                  [{ value: "All", label: t("util.util.all") }]
                )}
                filterOption={filterOption}
              />

              {/*客戶單號*/}
              {/* <Input
              className="max-w-[150px]"
              placeholder="請輸入客戶單號"
              name="dealN"
              onChange={(e) => handle_SearchData_Change("dealN", e.target.value)}
              value={s_searchData.dealN}
            /> */}

              {/*料號*/}
              {/* <Input
                className="max-w-[150px]"
                placeholder="請輸入料號"
                name="PN"
                onChange={(e) => handle_SearchData_Change("PN", e.target.value)}
                value={s_searchData.PN}
              /> */}

              {/*交易料號*/}
              {/* <Input
              className="max-w-[150px]"
              placeholder="請輸入交易料號"
              name="dealPN"
              onChange={(e) => handle_SearchData_Change("dealPN", e.target.value)}
              value={s_searchData.dealPN}
            /> */}

              {/*品名*/}
              {/* <Input
                className="max-w-[150px]"
                placeholder="請輸入品名"
                name="pdtNM"
                onChange={(e) => handle_SearchData_Change("pdtNM", e.target.value)}
                value={s_searchData.pdtNM}
              /> */}

              {/*規格*/}
              {/* <Input
              className="max-w-[150px]"
              placeholder="請輸入規格"
              name="pdtspec"
              onChange={(e) => handle_SearchData_Change("pdtspec", e.target.value)}
              value={s_searchData.pdtspec}
            /> */}

              <Text>{"日期"} : </Text>
              {/*預交日期起、迄*/}
              <RangePicker
                allowClear={false}
                value={[
                  s_searchData.startDate && dayjs(s_searchData.startDate),
                  s_searchData.endDate && dayjs(s_searchData.endDate),
                ]}
                onChange={(dates, dateStrings) => handle_SearchData_Change("time", dateStrings)}
              />

              <IconExcelDownload
                key="excel"
                className="text-[40px]"
                onClick={() => exportToExcel()}
              />

              <Button
                type={dateRangeType === "day" ? "primary" : "default"}
                onClick={() => handleDateRangeTypeChange("day")}
              >
                {"当日"}
              </Button>

              <Button
                type={dateRangeType === "week" ? "primary" : "default"}
                onClick={() => handleDateRangeTypeChange("week")}
              >
                {"当週"}
              </Button>

              <Button
                type={dateRangeType === "month" ? "primary" : "default"}
                onClick={() => handleDateRangeTypeChange("month")}
              >
                {"当月"}
              </Button>

              <SearchButton
                type="primary"
                key="search"
                onClick={handleClick}
                className="
							bg-sky-500 border-sky-500 text-white
							hover:bg-sky-400 hover:border-sky-400
							focus:bg-sky-500 focus:border-sky-500
							"
              />
            </Space>

            {/*表格*/}
            <CustomTable
              rowClassName=""
              className="customHeaderBG"
              columns={tableColumns}
              dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
              expandedRowRender={expandedRowRender} //子表格
              expandedRowKeys={expandedRowKeys} //子表格展開行
              // //把展開行的索引記住，每次展開都檢查是否被展開過
              onExpand={(expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.key]);
                } else {
                  setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
                }
              }}
              scroll={{ x: false, y: tableHeight }}
            />
          </div>
        </Spin>
      </div>

      <div className="flex absolute bottom-2 justify-between items-center text-[24px] text-[#4B6EA2] p-2 w-[99%] bg-[#F8F8F8] rounded-md shadow-[0px_3px_6px_#dcdcdc] ">
        {/* <div className="flex flex-1 justify-end items-center"> */}
        <div className="flex">
          {t("OMS.ap-manage.ap_sumtotal")} : {formatAmountWithCurrency(total_Info.sumtotal)}
          {/* {t("OMS.ap-manage.ap_sumtotal")} : {total_Info.sumtotal} */}
          {/* 總項金額 */}
        </div>

        <div className="flex">
          {t("util.util.tax")} : {formatAmountWithCurrency(total_Info.tax)}
          {/* {t("util.util.tax")} : {total_Info.tax} */}
          {/* 總項稅額 */}
        </div>

        <div className="flex">
          {t("OMS.ap-manage.ap_total")} : {formatAmountWithCurrency(total_Info.total)}
          {/* {t("OMS.ap-manage.ap_total")} : {total_Info.total} */}
          {/* 應收含稅總金額 */}
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default SaleSummaryP;
