import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Input, message, Radio, Space } from "antd";
import { addPCC, updatePCC, getPF } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addPPC = useAPI(addPCC);
  const call_updatePPC = useAPI(updatePCC);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addPPC.request(values);
    }
    if (type === "edit") {
      call_updatePPC.request(values);
    }
  };

  useEffect(() => {
    if (call_addPPC.status === "suc") {
      message.success(call_addPPC.msg);
      set_s_isShowModal(false);
    } else if (call_addPPC.status === "err") {
      message.error(t(`errorCode.${call_addPPC.msg}`));
    }
  }, [call_addPPC.status]);

  useEffect(() => {
    if (call_updatePPC.status === "suc") {
      message.success(call_updatePPC.msg);
      set_s_isShowModal(false);
    } else if (call_updatePPC.status === "err") {
      message.error(t(`errorCode.${call_updatePPC.msg}`));
    }
  }, [call_updatePPC.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        {/* "製程名稱" */}
        <Col span={8}>
          <Form.Item
            label={t("ADM.procedure-manage.pfNM")}
            name="pfNM"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "工作中心名稱" */}
          <Form.Item
            label={t("ADM.procedure-manage.pwcNM")}
            name="pwcNM"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={8} />
        <Col span={8}>
          {/* "成本中心ID" */}
          <Form.Item
            label={t("ADM.procedure-manage.pccID")}
            name="pccID"
            rules={[{ required: true }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "成本中心名稱" */}
          <Form.Item
            label={t("ADM.procedure-manage.pccNM")}
            name="pccNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.isvalid")} name="isvalid" initialValue={true}>
            <Radio.Group name="isvalid">
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
