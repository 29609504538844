import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

// 新增 Sip 表頭
export const addQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipHd`, data);
};

// 上傳工程圖檔
export const addQmsSipHdImg = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipHdImg`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 取得 Sip 表頭
export const getQmsSipHd = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSipHd`, {
    params: _params,
  });
};

// 更新 Sip 表頭
export const updateQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipHd`, data);
};

// 刪除 Sip 表頭
export const deleteQmsSipHd = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSipHd`, data);
};

// ------------------------------------------------------------------------------------

// 新增 Sip 表身
export const addQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addQmsSipTb`, data);
};

// 取得 Sip 表身
export const getQmsSipTb = (_params) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getQmsSipTb`, {
    params: _params,
  });
};

// 更新 Sip 表身
export const updateQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipTb`, data);
};

// 刪除 Sip 表身
export const deleteQmsSipTb = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteQmsSipTb`, data);
};

// 拖動表身
export const updateQmsSipTbItemno = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/updateQmsSipTbItemno`, data);
};
