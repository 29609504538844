/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Modal, message, Space } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
// util components
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
// component
import ModalContainer from "./ModalContainer";
// icon
import { IconPen, IconGarbage } from "@/components/Icon/Action";
// api
import useAPI from "@/hooks/useAPI";
import { getApsOutWo, deleteApsOutWo } from "@/service/apis/APS/apsMange";

const Wo = forwardRef((props, ref) => {
  const { c_searchData } = props;
  const { t } = useTranslation();

  const [s_showModal, set_s_showModal] = useState(false); // 是否開啟編輯庫房入庫的對話框
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  // 後端回來的資料
  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  // useAPI 取得工單
  const call_getApsOutWo = useAPI(getApsOutWo);
  // useAPI 刪除工單
  const call_deleteApsOutWo = useAPI(deleteApsOutWo);

  const tableColumns = [
    {
      title: t("APS.util.opcode"),
      dataIndex: "opcode",
      align: "center",
      width: "5%",
      render: (text) => t(`APS.util.opcode_${text}`),
    },
    {
      title: t("APS.aps-manage.jobN"), // 工單號
      dataIndex: "jobN",
      width: "8.3%",
    },
    {
      title: t("util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "11.3%",
    },
    {
      title: t("util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "left",
    },
    {
      title: t("APS.util.duestart"), // 預計完成起日
      dataIndex: "duestart",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8.3%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("APS.util.dueend"), // 預計完成迄日
      dataIndex: "dueend",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8.3%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("APS.aps-manage.orderN"), // 客戶訂購單號
      dataIndex: "orderN",
      align: "left",
      width: "8.3%",
    },
    {
      title: t("APS.aps-manage.ETDstart"), // 預計交貨起日
      dataIndex: "ETDstart",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8.3%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("APS.aps-manage.ETDend"), // 預計交貨迄日
      dataIndex: "ETDend",
      className: "bg-[#f9e9d7ac]",
      align: "center",
      width: "8.3%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("APS.aps-manage.issueID"), // 發佈人員
      dataIndex: "issueID",
      align: "center",
      width: "6%",
    },
    {
      title: t("APS.aps-manage.issueday"), // 發佈日期
      dataIndex: "ETDend",
      align: "center",
      width: "7%",
      render: (text) => dayjs(text).format("MM-DD HH:mm"),
    },
    {
      title: t("util.action"),
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData) => (
        <Space>
          <IconPen onClick={(e) => editApsManage(e, rowData)} />
          <IconGarbage onClick={(e) => deleteTxn(e, rowData)} />
        </Space>
      ),
    },
  ];

  const OPCODE_LABELS = [
    { value: "3310", label: `3310 ${t("APS.util.opcode_3310")}` },
    { value: "3320", label: `3320 ${t("APS.util.opcode_3320")}` },
    { value: "3330", label: `3330 ${t("APS.util.opcode_3330")}` },
    { value: "3340", label: `3340 ${t("APS.util.opcode_3340")}` },
  ];

  useImperativeHandle(ref, () => ({
    Create: () => set_s_showModal(true), //開啟新增Modal
    Call_hd: () => call_getApsOutWo.request({ ...c_searchData, page: 1, pageSize: 20 }),
  }));

  const editApsManage = (e, rowData) => {
    set_s_showModal(true);
    set_s_editData(rowData);
    e.stopPropagation(); // 這裡不要開啟表身
  };

  const deleteTxn = (e, rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_deleteApsOutWo.request(rowData),
      cancelText: t("util.cancel"),
    });

    e.stopPropagation(); // 這裡不要開啟表身
  };

  useEffect(() => {
    call_getApsOutWo.request({ ...c_searchData, ...s_pageInfo });
  }, [c_searchData]);

  // useAPI 取得工單資料
  useEffect(() => {
    const { status, msg, data } = call_getApsOutWo;
    if (status === "suc") {
      console.log("call_getApsOutWo = ", call_getApsOutWo);
      message.success(msg);
      const newTableData =
        data.tableData?.map((x) => ({
          ...x,
          key: x.UUID,
          ETDstart: dayjs(x.ETDstart),
          ETDend: dayjs(x.ETDend),
          duestart: dayjs(x.duestart),
          dueend: dayjs(x.dueend),
        })) || [];
      set_s_tableData(newTableData);
      set_s_pageInfo(data.pageInfo);
    }
    if (status === "err") {
      message.error(msg);
      set_s_tableData([]);
      set_s_pageInfo({
        currentPage: 0,
        endItemNumber: 0,
        pageItemNumber: 0,
        startItemNumber: 0,
        totalItems: 0,
      });
    }
  }, [call_getApsOutWo.status]);

  // useAPI 刪除工單資料
  useEffect(() => {
    const { status, msg } = call_deleteApsOutWo;
    if (status === "suc") {
      message.success(msg);
      // 刪除後重新取得工單資料
      call_getApsOutWo.request(c_searchData);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_deleteApsOutWo.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns}
        dataSource={s_tableData}
        loading={call_getApsOutWo.status === "load"}
        // tbMode={Object.keys(c_lightData).length > 0}
        // c_lightData={c_lightData}
        // onRow={(record) => ({
        //   onClick: () =>
        //     record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        // })}
      />

      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("APS.aps-manage.wo")}`
            : `${t("util.add")}${t("APS.aps-manage.wo")}`
        }
        width="60%"
        open={s_showModal}
        onCancel={() => set_s_showModal(false)}
        afterClose={() => {
          call_getApsOutWo.request(c_searchData);
          set_s_editData({});
        }}
      >
        <ModalContainer
          OPCODE_LABELS={OPCODE_LABELS}
          set_s_showModal={set_s_showModal}
          s_editData={s_editData}
        />
      </CustomModal>
    </>
  );
});

export default Wo;
