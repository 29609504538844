/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Select, Spin, Table, message, Button } from "antd";
import { CompressOutlined, ExpandOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "./table.css";
//util
import { formatAmount } from "@/util/format";
//component
import PageHeader from "@/components/PageHeader/index";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { SearchButton } from "@/components/PDS/Buttons";
import DateMonthSwi from "@/components/BDM/DateMonthSwi";
//api
import { getStaff } from "@/service/apis/ADM/userManage";
import { getCustHd } from "@/service/apis/ADM/client";
import { getCustsHD } from "@/service/apis/OMS/sales";
import { getSalesSummary } from "./mock";
//hook
import useAPI from "@/hooks/useAPI";

const ContributionRaking = () => {
  //搜尋資料
  const [s_searchData, set_s_searchData] = useState({
    custID: [], //負責業務
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"), // 開始日期
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"), // 結束日期
  });

  const [s_OriData, set_s_OriData] = useState([]); //api拿到的原始資料
  const [s_TableData, set_s_TableData] = useState([]); //放到表格的資料
  const [c_staffs, set_c_staffs] = useState({ data: [], options: [] });
  const [c_cust, set_c_cust] = useState({ data: [], options: [] });
  const [tableHeight, setTableHeight] = useState(0);
  const [is_spin, set_is_spin] = useState(false);
  const [is_expanded_AllRow, set_is_expanded_AllRow] = useState(false); //是否展開所有子表格
  const [expandedRowKeys, setExpandedRowKeys] = useState([]); //子表格展開行,因為展開行的索引會被記住，所以每次拿到資料都要重新設定展開行的索引為空陣列

  const [total_Info, set_total_Info] = useState({
    sumtotal: 0, //總項金額
  }); //所有群組總計

  const { t } = useTranslation();

  // useAPI 取得員工資料
  const call_getAllStaff = useAPI(getStaff);
  const call_getCustHd = useAPI(getCustHd);
  const call_getSaleSummary = useAPI(getCustsHD);

  const updateTableHeight = () => {
    setTableHeight(window.innerHeight * 0.7);
  };

  const onSearch = () => {
    const tmp = {
      ...s_searchData,
      custID: s_searchData?.custID?.join("|"),
    };

    // console.log(s_searchData.startDate.split("-"));

    // console.log(s_searchData.startDate.split("-").length);

    // if (s_searchData.startDate.split("-").length === 2) {
    //   const resulStartdayArray = [
    //     ...s_searchData.startDate,
    //     dayjs(s_searchData.startDate).startOf("month"),
    //   ];
    //   console.log(resulStartdayArray);
    // }
    set_is_expanded_AllRow(false);
    call_getSaleSummary.request(tmp);
  };

  // 切換搜尋資料 attr:傳入變動的欄位屬性 value:傳入變動的欄位值
  const handle_SearchData_Change = (attr, value) => {
    let tmp = {};
    if (attr === "time") {
      tmp = { ...s_searchData, startDate: value[0], endDate: value[1] };
      set_s_searchData(tmp);
    } else {
      tmp = { ...s_searchData, [attr]: value };
      set_s_searchData(tmp);
    }
  };

  //設定子表格全部展開或收合
  const handleExpandedRowKeys = () => {
    if (is_expanded_AllRow) {
      setExpandedRowKeys([]);
      set_is_expanded_AllRow(false);
    } else {
      const keys = s_TableData.map((_, index) => index + 1);
      setExpandedRowKeys(keys);
      set_is_expanded_AllRow(true);
    }
  };

  // 將CountArr資料組成要的格式再塞到表格
  const handleTableData = (data) => {
    let new_tableData = [];

    data.forEach((item) => {
      let new_obj = {};
      let childrenItem = item.slice(0, -1); //除了合計以外的資料
      new_obj = { ...item[0], ...item[item.length - 1], childrenItem: [...childrenItem] }; //把合計以外的物件變成childrenItem
      new_tableData.push(new_obj);
    });

    return new_tableData;
  };

  // 渲染子表格的函数
  const expandedRowRender = (record) => {
    // 使用record的某个属性作为子表格的数据源
    const subData = record.childrenItem; // 假设subItems是包含子表格数据的属性

    return (
      <Table
        rowKey={(record) => record.key}
        columns={subColumns}
        dataSource={subData}
        pagination={false}
        className="tbchild_cust"
      />
    );
  };

  const tableColumns = [
    {
      title: t("OMS.util.custNM"), // 客戶
      dataIndex: "custID",
      width: "45%",
      key: "custID",
      align: "center",
      render: (text) => c_cust?.options?.find((item) => item.value === text)?.label,
    },
    {
      title: "总业绩",
      dataIndex: "sumtotal",
      width: "25%",
      key: "sumtotal",
      align: "right",
      render: (text) => <span className="text-stone-800">{formatAmount(text)}</span>,
      sorter: {
        compare: (a, b) => a.sumtotal - b.sumtotal,
      },
    },
    {
      title: "",
      dataIndex: "null",
      width: "30%",
      key: "null",
    },
  ];

  const subColumns = [
    {
      title: t("OMS.util.N11"),
      dataIndex: "N",
      align: "center",
      width: "45%",
      key: "N",
    },
    {
      title: "业绩",
      dataIndex: "sales",
      width: "25%",
      key: "sales",
      align: "right",
      render: (text) => <span className="text-stone-800">{formatAmount(text)}</span>,
    },
    {
      title: t("OMS.ord.driID"),
      dataIndex: "driID",
      align: "center",
      width: "30%",
      render: (text) => c_staffs?.options?.find((item) => item.value === text)?.label || text,
    },
  ];

  useEffect(() => {
    call_getAllStaff.request();
    call_getCustHd.request();
    call_getSaleSummary.request(s_searchData);
  }, []);

  useEffect(() => {
    const { status, msg, data } = call_getSaleSummary;
    if (status === "suc") {
      set_s_OriData(data);
      setExpandedRowKeys([]);
      message.success(msg);
    } else if (status === "err") {
      set_s_OriData([]);
      setExpandedRowKeys([]);
      message.error(t(`error.error-code.${msg}`));
    }
  }, [call_getSaleSummary.status]);

  useEffect(() => {
    if (call_getSaleSummary.status === "load") {
      set_is_spin(true);
    } else {
      set_is_spin(false);
    }
  }, [call_getSaleSummary.status]);

  // 取得員工資料
  useEffect(() => {
    const { data, status, msg } = call_getAllStaff;
    if (status === "suc") {
      const options = data?.map((item) => {
        return { value: item?.staffID, label: item?.peopleNM };
      });
      set_c_staffs({ options: options, data: data });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getAllStaff.status]);

  //
  useEffect(() => {
    const { data, status, msg } = call_getCustHd;

    if (status === "suc") {
      console.log("gooooooooo=", data);
      const filData = data.filter((item) => {
        return item.custID.includes("C") && item.isvalid;
      });
      const options = filData.map((item) => {
        return { value: item?.custID, label: item?.custalias };
      });
      set_c_cust({ options: options, data: data });
    } else if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_c_cust([]);
    }
  }, [call_getCustHd.status]);

  useEffect(() => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);
    return () => {
      window.removeEventListener("resize", updateTableHeight);
    };
  }, [tableHeight]);

  useEffect(() => {
    console.log("s_OriData = ", s_OriData);
    if (s_OriData.length === 0) {
      set_s_TableData([]);
      set_total_Info({
        sumtotal: 0, //總項金額
      });
      return;
    }

    const groupResult = s_OriData.reduce((accumulator, currentValue) => {
      if (!accumulator[currentValue.custID]) {
        accumulator[currentValue.custID] = [];
      }
      accumulator[currentValue.custID].push(currentValue);
      return accumulator;
    }, {});

    console.log("groupResult = ", groupResult);

    const groupArr = Object.values(groupResult);
    console.log("groupArr = ", groupArr);

    let count_total_Info = {
      sumtotal: 0, //總項金額
    };

    const CountArr = groupArr.map((item) => {
      const count = item.reduce(
        (accumulator, currentValue) => {
          //每個群組小計
          accumulator.sumtotal += currentValue.sales;
          accumulator.tax += currentValue.itemsumtax;
          accumulator.total += currentValue.subtotal;

          //所有群組合計
          count_total_Info.sumtotal += currentValue.sales;
          count_total_Info.tax += currentValue.itemsumtax;
          count_total_Info.total += currentValue.subtotal;

          return accumulator;
        },
        {
          sumtotal: 0, //總項金額，每個群組要多一個物件放合計資訊
        }
      );

      item.push(count); // 每個群組都要加一個合計物件

      return item; //返回有群族和計資料的新陣列
    });

    const finalArr = CountArr.flat(); // 使用 flat 代替 concat
    console.log("finalArr", finalArr);

    set_s_TableData(handleTableData(CountArr)); // 只更新一次狀態
    set_total_Info({ ...count_total_Info }); // 只更新一次狀態，給所有群組合計
  }, [s_OriData]);

  return (
    <>
      <div className="flex flex-col w-full justify-between relative">
        <Spin spinning={is_spin}>
          <div className="flex flex-col w-full justify-between">
            <PageHeader
              title={t("util.nav_A-3")}
              extra={[
                <Button type="primary" onClick={() => handleExpandedRowKeys()}>
                  {is_expanded_AllRow ? <CompressOutlined /> : <ExpandOutlined />}
                </Button>,
                <Select
                  key="custID"
                  className="min-w-[200px]"
                  name="custID"
                  mode="multiple"
                  showSearch
                  placeholder={"请选择客户"}
                  onChange={(value) => handle_SearchData_Change("custID", value)}
                  options={c_cust.options}
                  allowClear
                  filterOption={(input, option) => {
                    return (
                      (option?.value.toLowerCase() ?? "") + (option?.label.toLowerCase() ?? "")
                    ).includes(input.toLowerCase());
                  }}
                />,
                <DateMonthSwi
                  s_searchData={s_searchData}
                  handle_SearchData_Change={handle_SearchData_Change}
                />,
                <SearchButton type="primary" key="search" onClick={onSearch} />,
              ]}
            />
            <CustomTable
              className="customHeaderBG"
              columns={tableColumns}
              dataSource={s_TableData.map((item, index) => ({ ...item, key: index + 1 }))}
              expandedRowRender={expandedRowRender} //子表格
              expandedRowKeys={expandedRowKeys} //子表格展開行
              //把展開行的索引記住，每次展開都檢查是否被展開過
              onExpand={(expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.key]);
                } else {
                  setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record.key));
                }
              }}
              scroll={{ x: false, y: tableHeight }}
            />
          </div>
        </Spin>
      </div>
      <div className="flex absolute bottom-2 justify-center items-center text-[24px] text-[#4B6EA2] p-2 w-[99%] bg-[#F8F8F8] rounded-md shadow-[0px_3px_6px_#dcdcdc] ">
        <div className="w-[75%] min-w-[75%]"></div>
        <div className="flex min-w-[25%]">总业绩: {formatAmount(total_Info.sumtotal)}</div>
      </div>
    </>
  );
};

export default ContributionRaking;
