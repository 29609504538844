import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Input, message, Radio, Space, Select } from "antd";
import CreateFromItem from "@/components/CreateFromItem/CreateFromItem";
import { addPWC, updatePWC, getPF, getAllWsID } from "@/service/apis/ADM/publicAPI";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

export default function ModalConintaer({ s_editData, set_s_isShowModal, set_s_editData }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const call_addPWC = useAPI(addPWC);
  const call_updatePWC = useAPI(updatePWC);
  const call_getPF = useAPI(getPF);
  const call_getAllWsID = useAPI(getAllWsID);

  const onSubmit = (values) => {
    const type = Object.keys(s_editData).length > 0 ? "edit" : "create";

    if (type === "create") {
      call_addPWC.request(values);
    }
    if (type === "edit") {
      call_updatePWC.request(values);
    }
  };

  useEffect(() => {
    call_getAllWsID.request();
    call_getPF.request();
  }, []);

  useEffect(() => {
    if (call_addPWC.status === "suc") {
      message.success(call_addPWC.msg);
      set_s_isShowModal(false);
    } else if (call_addPWC.status === "err") {
      message.error(t(`errorCode.${call_addPWC.msg}`));
    }
  }, [call_addPWC.status]);

  useEffect(() => {
    if (call_updatePWC.status === "suc") {
      message.success(call_updatePWC.msg);
      set_s_isShowModal(false);
    } else if (call_updatePWC.status === "err") {
      message.error(t(`errorCode.${call_updatePWC.msg}`));
    }
  }, [call_updatePWC.status]);

  useEffect(() => {
    if (call_getPF.status === "err") {
      message.error(t(`errorCode.${call_getPF.msg}`));
    }
  }, [call_getPF.status]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      initialValues={{
        isvalid: true,
        ...s_editData,
      }}
    >
      <Row gutter={[24, 12]}>
        <Col span={8}>
          {/* "工作中心ID" */}
          <Form.Item
            label={t("ADM.procedure-manage.pwcID")}
            name="pwcID"
            rules={[{ required: true }]}
          >
            <Input disabled={Object.keys(s_editData).length !== 0} />
          </Form.Item>
        </Col>
        <Col span={8}>
          {/* "工作中心名稱" */}
          <Form.Item
            label={t("ADM.procedure-manage.pwcNM")}
            name="pwcNM"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8} />
        {/* "製程ID" */}
        <Col span={8}>
          <Form.Item label={t("ADM.procedure-manage.pf")} name="pfID" rules={[{ required: true }]}>
            <Select
              disabled={Object.keys(s_editData).length !== 0}
              options={
                call_getPF.data?.map((x) => ({ label: `${x.pfID}_${x.pfNM}`, value: x.pfID })) ?? []
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t("util.isvalid")} name="isvalid" initialValue={true}>
            <Radio.Group name="isvalid">
              <Radio value={true}>{t("util.yes")}</Radio>
              <Radio value={false}>{t("util.no")}</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t("util.note")} name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
        <Col span={24} className="flex justify-between">
          <CreateFromItem set_s_editData={set_s_editData} s_editData={s_editData} form={form} />
          <Space>
            <Button onClick={() => set_s_isShowModal(false)}>{t("util.cancel")}</Button>
            <Button type="primary" htmlType="submit">
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
}
