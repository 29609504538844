import { useState, useRef, useEffect } from "react";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
// api
import useAPI from "@/hooks/useAPI";
import { getPF, getStation, getLine } from "@/service/apis/ADM/publicAPI";
import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
// component
import SIP from "./SIP";

const QMSMAnage = () => {
  const sip_ref = useRef(null);
  // 製程
  const call_getPF = useAPI(getPF);
  // 工站
  const call_getStation = useAPI(getStation);
  // 工線
  const call_getLine = useAPI(getLine);

  const [s_searchData, set_s_searchData] = useState({});
  const [s_tabKey, set_s_tabKey] = useState("sip"); // 目前是在哪個Tab上

  const resetSearchData = () => {
    set_s_searchData({});
  };

  const onCreate = () => {
    s_tabKey === "sip" && sip_ref.current.onCreate();
  };

  useEffect(() => {
    call_getPF.request();
    call_getStation.request();
    call_getLine.request();
  }, []);

  const s_arrMap = {
    pf: call_getPF?.data?.map((pf) => ({ label: `${pf.pfID}-${pf.pfNM}`, value: pf.pfID })) || [],
    ws:
      call_getStation?.data.map((ws) => ({ label: `${ws.wsID}-${ws.wsNM}`, value: ws.wsID })) || [],
    wl: call_getLine?.data.map((wl) => ({ label: `${wl.wlID}-${wl.wlNM}`, value: wl.wlID })) || [],
  };

  const tabsData = [
    {
      key: "sip",
      label: "檢驗標準 (SIP)",
      children: (
        <SIP
          ref={sip_ref}
          s_arrMap={s_arrMap}
          s_searchData={s_searchData}
          set_s_searchData={set_s_searchData}
        />
      ),
    },
    {
      key: "sop",
      label: "作業標準 (SOP)",
      disabled: true,
    },
    {
      key: "mon",
      label: "監控標準 (MON)",
      disabled: true,
    },
    {
      key: "rcp",
      label: "配方標準 (RCP)",
      disabled: true,
    },
  ];

  const searchBasic = [
    // <Input
    //   key="s_searchData.query"
    //   value={s_searchData.query}
    //   onChange={(e) => handlChange("query", e.target.value)}
    //   placeholder="查詢ID，單位，代號，功能碼，層級碼"
    //   className="w-[300px]"
    // />,
    // <Button type="primary" key="search" onClick={onSearch}>
    //   查詢
    // </Button>,
    // <Button type="primary" key="create" onClick={onCreate}>
    //   {t("util.add")}
    // </Button>,
    <AddButton type="primary" key="create" onClick={onCreate} />,
  ];
  return (
    <>
      <PageHeader title="標準主檔維護" extra={searchBasic} />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => {
          set_s_tabKey(activeKey);
          resetSearchData();
        }}
      />
    </>
  );
};

export default QMSMAnage;
