/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
// context
import { DataContext } from "../../index";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// util
import { formatAmount } from "@/util/format";
import { getAPTB } from "@/service/apis/OMS/ap";
import { message } from "antd";
// API
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const TB = () => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  // useAPI 取得表身
  const call_getAPTB = useAPI(getAPTB);
  const { c_state } = useContext(DataContext);
  const { lightData } = c_state;
  // table 顯示的資料
  const [s_tableData, set_s_tableData] = useState([]);

  // 取得表身資料
  useEffect(() => {
    call_getAPTB.request({ opUUID: lightData.opUUID });
  }, [lightData]);

  // useAPI 取得表身資料
  useEffect(() => {
    const { status, data, msg } = call_getAPTB;
    if (status === "suc") {
      const tmpData =
        data?.map((item, index) => ({
          ...item,
          key: item.itemUUID,
          index: index + 1,
        })) ?? [];
      set_s_tableData(tmpData);
    }

    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
      set_s_tableData([]);
    }
  }, [call_getAPTB.status]);

  const tableColumns = [
    {
      title: t("OMS.util.N13"), // 報價單號
      dataIndex: "N13",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.ord.driID"), // 負責業務
      dataIndex: "driID",
      align: "center",
      width: "10%",
      render: (text) => getStaffName(text),
    },
    {
      title: t("OMS.ord.payment"), // 負責業務
      dataIndex: "payment",
      align: "center",
      width: "10%",
    },
    {
      title: t("OMS.util.custalias"), // 客戶簡稱
      dataIndex: "custalias",
      align: "center",
    },
    {
      title: t("OMS.util.custID"), // 客戶簡稱
      dataIndex: "custID",
      align: "center",
    },
    {
      title: t("OMS.ap-manage.rcv_subtotal"), // 金額
      dataIndex: "itemsum",
      align: "right",
      width: "10%",
      render: (text) => formatAmount(text),
    },
    {
      title: "预收款金额",
      dataIndex: "cumpay",
      align: "right",
      width: "8%",
      render: (text) => formatAmount(text),
    },
    // {
    //   title: t("OMS.util.table_operate"), // 操作
    //   dataIndex: "operate",
    //   align: "center",
    //   width: "3%",
    //   render: (_, rowData, __) => (
    //     <Space className="px-2">
    //       {/* 查看更多 */}
    //       <SeeMoreButton onClick={(e) => openDetail(e, rowData)} />
    //     </Space>
    //   ),
    // },
  ];

  return (
    <div className="flex-1">
      <CustomTable
        type="dark-purple"
        lightData={lightData}
        rowNumber={5}
        columns={tableColumns}
        dataSource={s_tableData}
      />
    </div>
  );
};

export default TB;
