/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Col, message, Space, Modal, Grid, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
// util component
import CustomTable from "@/components/CustomAntd/ERP/CustomTable";
import CustomModal from "@/components/CustomAntd/ERP/CustomModal";
import { CreateButton } from "@/components/ERP/Button";
import TbTitle from "@/components/ERP/PMS/TbTitle";
import { DeleteButton, EditButton } from "@/components/ERP/Button";
import { IconCancel, IconConfirm } from "@/components/Icon/Modal/Button";
// util function
import { formatAmount } from "@/util/format";
// component
import { RecButton } from "@/components/ERP/Button";
import TbModal from "../Modal/TbModal";
import RecModal from "../Modal/RecModal";
// API
import useAPI from "@/hooks/useAPI";
// import { updatePlanTB, deletePlanTB } from "../../moke";
import { updatePlanTB, deletePlanTB } from "@/service/apis/PMS/Plan";

const { Title } = Typography;

const TbContainer = ({ call_getPlanTB, hd_values, s_tbData, s_disabled }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  // useAPI 更新表身資料
  const call_updatePlanTB = useAPI(updatePlanTB);
  // useAPI 刪除表身資料
  const call_deletePlanTB = useAPI(deletePlanTB);
  // 控制編輯畫面的跳顯開啟、關閉、要編輯的資料
  const [s_tbModal, set_s_tbModal] = useState({ show: false, data: {} });
  const [s_recModal, set_s_recModal] = useState({ show: false, data: {} });
  // console.log("s_tbData = ", s_tbData);
  // 新建資料
  const createData = () => {
    set_s_tbModal({ show: true, data: {} });
  };
  // 編輯資料
  const editData = (rowData) => {
    console.log("s_tbData = ", s_tbData);
    set_s_tbModal({ show: true, data: rowData });
  };
  // 編輯資料
  const editRec = (rowData) => {
    set_s_recModal({ show: true, data: rowData });
  };
  // 刪除表身
  const removeData = (rowData) => {
    Modal.confirm({
      // title: "報價明細刪除確認",
      title: t("PMS.plan.tb") + t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-500" />,
      content: t("PMS.util.del_tb_confirm_text"),
      okText: <IconConfirm />, // 確定
      cancelText: <IconCancel />, // 取消
      onOk: () => call_deletePlanTB.request(rowData),
    });
  };

  // useAPI 刪除表身
  useEffect(() => {
    const { status, msg } = call_deletePlanTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getPlanTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_deletePlanTB.status]);

  // useAPI 更新表身
  useEffect(() => {
    const { status, msg } = call_updatePlanTB;
    if (status === "suc") {
      message.success(msg);
      // 更新表身資料
      call_getPlanTB.request({ opUUID: hd_values.opUUID });
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_updatePlanTB.status]);

  // 判斷現在是新增表身還是編輯表身
  const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";

  const tableColumns = [
    {
      title: t("PMS.util.itemno"), // 項序
      dataIndex: "itemno",
      align: "center",
      width: "4%",
      render: (_, __, index) => index + 1,
    },
    {
      title: t("PMS.util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
      width: "12%",
    },

    {
      title: t("PMS.util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
      width: "12%",
    },
    {
      title: t("PMS.util.pdtspec"), // 規格
      dataIndex: "pdtspec",
      align: "center",
      width: "13%",
    },
    {
      title: t("util.attribute"), // 屬性
      dataIndex: "attribute",
      align: "center",
      width: "9%",
      render: (text) => t(`util.attribute_${text}`),
    },
    {
      title: t("util.pdtclass"), // 商品類別
      dataIndex: "pdtclass",
      align: "left",
      width: "11%",
    },
    {
      title: t("util.drawno"), // 工程圖號
      dataIndex: "drawno",
      align: "center",
      width: "10%",
    },
    {
      title: t("util.matl"), // 原料材質
      dataIndex: "matl",
      align: "center",
      width: "7%",
    },
    {
      title: t("PMS.plan.demandqty"), // 需求數量
      dataIndex: "demandqty",
      align: "right",
      width: "7%",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.note"), // 註記
      dataIndex: "note",
      align: "center",
    },
    {
      title: t("OMS.util.table_operate"), // 操作
      dataIndex: "render",
      align: "center",
      width: "5%",
      render: (_, rowData, __) => (
        <Space className="px-2">
          <RecButton onClick={(e) => set_s_recModal({ show: true, data: rowData })} />
          {/* 編輯表身資料 */}
          <EditButton onClick={(e) => set_s_tbModal({ show: true, data: rowData })} />
          {/* 刪除表身資料 */}
          <DeleteButton disabled={s_disabled.tb_delete} onClick={() => removeData(rowData)} />
        </Space>
      ),
    },
  ];
  // console.log("s_tbModal.data = ", s_tbModal.data);

  return (
    <div className="flex flex-col flex-1 justify-between">
      <div className="flex justify-between py-2">
        {/* 報價明細 */}
        <TbTitle>{t("PMS.plan.tb")}</TbTitle>
        {/* 新建的 button */}
        <CreateButton disabled={s_disabled.createBtn} onClick={createData} />
      </div>

      <CustomTable columns={tableColumns} rowNumber={6} dataSource={s_tbData} />

      {/* 新建、編輯規格評估的跳顯 */}
      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_recModal.show}
        onCancel={() => set_s_recModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-end mr-9">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯規格評估" : "新增規格評估"} */}
              {(type === "edit" ? t("util.edit") : t("util.add")) + t("PMS.plan.rec_drawer_title")}
            </Title>
            <Title level={4} className="m-0">
              {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
              {hd_values.N}: {t("PMS.util.itemno")}：{s_recModal.data.key + 1}
            </Title>
          </div>
        }
      >
        <RecModal s_recModal={s_recModal} set_s_recModal={set_s_recModal} />
      </CustomModal>

      <CustomModal
        width={screens.xxl ? "60%" : "80%"}
        open={s_tbModal.show}
        onCancel={() => set_s_tbModal({ show: false, data: {} })}
        title={
          <div className="flex justify-between items-end mr-9">
            <Title level={4} className="m-0">
              {/* {type === "edit" ? "編輯計畫明細" : "新增計畫明細"} */}
              {(type === "edit" ? t("util.edit") : t("util.add")) + t("PMS.plan.tb")}
            </Title>
            {type === "edit" && (
              <Title level={4} className="m-0">
                {t("PMS.util.opcode")}：{t(`PMS.util.opcode_${hd_values.opcode}`)} {t("PMS.util.N")}
                {hd_values.N}: {t("PMS.util.itemno")}：{s_tbModal.data.key + 1}
              </Title>
            )}
          </div>
        }
      >
        <TbModal
          call_getPlanTB={call_getPlanTB}
          hd_values={hd_values}
          s_disabled={s_disabled}
          s_tbModal={s_tbModal}
          set_s_tbModal={set_s_tbModal}
        />
      </CustomModal>
    </div>
  );
};

export default TbContainer;
