import IconBox from "./IconBox";
import {
	CloseCircleOutlined,
	CheckCircleFilled,
	RollbackOutlined,
	CheckCircleOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
	FileImageOutlined,
	DownloadOutlined,
} from "@ant-design/icons";
import { ReactComponent as Pen } from "@/assets/icon/TableIcon/icon-edit.svg";
import { ReactComponent as Garbage } from "@/assets/icon/TableIcon/icon-delete.svg";
import { ReactComponent as Circle } from "@/assets/icon/TableIcon/circle.svg";
import { ReactComponent as Lock } from "@/assets/icon/TableIcon/lock.svg";
import { ReactComponent as Unlock } from "@/assets/icon/TableIcon/unlock.svg";
import { ReactComponent as Shrink } from "@/assets/icon/TableIcon/icon-shrink.svg";
import { ReactComponent as Expand } from "@/assets/icon/TableIcon/icon-expand.svg";
import { ReactComponent as XlsDownload } from "@/assets/icon/Office/icon-xlsDownload.svg";
import { ReactComponent as Instock } from "@/assets/icon/WMS/icon-inStock.svg";
import { ReactComponent as Upload } from "@/assets/icon/TableIcon/upload.svg";
import { ReactComponent as InvZeroCheck } from "@/assets/icon/WMS/icon-zeroCheck.svg";
import { ReactComponent as InvCheck } from "@/assets/icon/WMS/icon-check.svg";
import { ReactComponent as InvDoubleCheck } from "@/assets/icon/WMS/icon-doubleCheck.svg";
import { ReactComponent as InvThirdCheck } from "@/assets/icon/WMS/icon-thirdCheck.svg";
// table or form 新增
export const IconPlus = (props) => <IconBox component={PlusCircleOutlined} {...props} />;
export const IconInstock = (props) => <IconBox component={Instock} {...props} />;
// table or form 減少
export const IconMinus = (props) => <IconBox component={MinusCircleOutlined} {...props} />;
// 檔案上傳
export const IconFile = (props) => <IconBox component={Upload} {...props} />;

// excel下載
export const IconExcelDownload = (props) => <IconBox component={XlsDownload} {...props} />;
// 編輯
export const IconPen = (props) => <IconBox component={Pen} {...props} />;
// 刪除
export const IconGarbage = (props) => <IconBox component={Garbage} {...props} />;
// 圓圈
export const IconCircle = (props) => <IconBox component={Circle} {...props} />;
// 鎖
export const IconLock = (props) => <IconBox component={Lock} {...props} />;
// 解鎖
export const IconUnlock = (props) => <IconBox component={Unlock} {...props} />;
// 勾勾
export const IconConfirm = (props) => <IconBox component={CheckCircleOutlined} {...props} />;
// 藍色勾勾
export const IconCheck = (props) => (
	<IconBox component={CheckCircleFilled} className="text-[#71aff8]" {...props} />
);
// 紅色叉叉
export const IconClose = (props) => <IconBox component={CloseCircleOutlined} {...props} />;
export const IconRollbackOutlined = (props) => <IconBox component={RollbackOutlined} {...props} />;

export const IconExpand = (props) => <IconBox component={Expand} {...props} />;
export const IconShrink = (props) => <IconBox component={Shrink} {...props} />;
//下載
export const IconDownload = (props) => <IconBox component={DownloadOutlined} {...props} />;
// 盤點確認
export const IconInvZeroCheck = (props) => <IconBox component={InvZeroCheck} {...props} />;
// 初盤
export const IconInvCheck = (props) => <IconBox component={InvCheck} {...props} />;
// 複盤
export const IconInvDoubleCheck = (props) => <IconBox component={InvDoubleCheck} {...props} />;
export const IconInvThirdCheckk = (props) => <IconBox component={InvThirdCheck} {...props} />;