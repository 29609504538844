/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  Select,
  Spin,
  Divider,
  message,
  InputNumber,
  DatePicker,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getAllWhID } from "@/service/apis/WMS/WMS";
import DebounceSelect from "@/components/CustomAntd/PDS/DebounceSelect";
// API
import useAPI from "@/hooks/useAPI";
import { getProductSelect, getSuppHd } from "@/service/apis/ADM/publicAPI";
import { getStaff } from "@/service/apis/ADM/userManage";
import { addrun8090Hd, updaterun8090Hd } from "@/service/apis/WMS/ProInstock";

const ModalContainer = ({ opcode, set_s_showModal, s_editData }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const call_getStaff = useAPI(getStaff);
  const call_getAllWhID = useAPI(getAllWhID);
  // 取得廠商資料
  const call_getSuppHd = useAPI(getSuppHd);
  // 新增採購入庫
  const call_addrun8090Hd = useAPI(addrun8090Hd);
  // 編輯採購入庫
  const call_updaterun8090Hd = useAPI(updaterun8090Hd);

  const [s_AllStaff, set_s_AllStaff] = useState([]);

  const handleCancel = () => {
    set_s_showModal(false);
  };

  const onSubmit = async (values) => {
    console.log(values);
    const type = Object.keys(s_editData).length === 0 ? "create" : "modify";
    if (type === "create") {
      // console.log(values);
      call_addrun8090Hd.request({
        opcode: values.opcode,
        hd: values,
        tb: values.details,
      });
    }
    if (type === "modify") {
      call_updaterun8090Hd.request({
        opcode: values.opcode,
        hd: values,
      });
    }
  };
  // console.log("s_editData = ", s_editData);

  const fetchPNList = async (value) => {
    if (value === "" || value === undefined) return [];
    return await getProductSelect({ PN: value })
      .then(
        (e) =>
          e?.data.map((x) => ({
            label: x.PN,
            value: x.PN,
            data: x,
          })) || []
      )
      .catch((e) => {
        message.error(t("util.no_data"));
        return [];
      });
  };

  // 給form起始值 & call api
  useEffect(() => {
    call_getAllWhID.request();
    call_getStaff.request();
    call_getSuppHd.request();

    form.setFieldsValue({
      opcode: opcode,
      ...s_editData,
    });
  }, []);

  // 員工資料
  useEffect(() => {
    // console.log("call_getStaff.data = ", call_getStaff.data);
    if (call_getStaff.status === "suc") {
      set_s_AllStaff(call_getStaff.data);
    } else if (call_getStaff.status === "err") {
      message.error(call_getStaff.msg);
    }
  }, [call_getStaff.status]);

  // 新增採購入庫作業
  useEffect(() => {
    const { data, status, msg } = call_addrun8090Hd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_addrun8090Hd.status]);

  // 編輯採購入庫表頭
  useEffect(() => {
    const { data, status, msg } = call_updaterun8090Hd;
    if (status === "suc") {
      message.success(msg);
      set_s_showModal(false);
    }
    if (status === "err") {
      message.error(msg);
      set_s_showModal(false);
    }
  }, [call_updaterun8090Hd.status]);

  return (
    <Form onFinish={onSubmit} name="basic" form={form} autoComplete="off" layout="vertical">
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Form.Item
            // 作業單別
            label={t("WMS.util.opcode")}
            name="opcode"
          >
            <Input disabled placeholder={t("util.placeholder_select") + t("WMS.util.opcode")} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            // 作業單號
            label={t("WMS.util.jobN")}
            name="jobN"
          >
            <Input disabled placeholder={t("util.placeholder_input") + t("WMS.util.jobN")} />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            // 庫儲作業單號
            label={t("WMS.pro-instock.wmsopUUID")}
            name="wmsopUUID"
          >
            <Input
              disabled
              placeholder={t("util.placeholder_input") + t("WMS.pro-instock.wmsopUUID")}
            />
          </Form.Item>
        </Col>

        <Col span={6}></Col>

        <Col span={6}>
          <Form.Item
            //單號
            label={t(`WMS.util.operN_${opcode}`)}
            name="operN"
            rules={
              Object.keys(s_editData).length === 0
                ? [
                    {
                      required: true,
                      message: t("util.placeholder_input") + t(`WMS.util.operN_${opcode}`),
                    },
                  ]
                : null
            }
          >
            <Input
              name="operN"
              disabled={Object.keys(s_editData).length !== 0}
              placeholder={t("util.placeholder_input") + t(`WMS.util.operN_${opcode}`)}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //負責人員
            label={t("WMS.pro-instock.inchargeID")}
            rules={[{ required: true }]}
            name="inchargeID"
          >
            <Select
              // disabled={Object.keys(s_editData).length === 0 ? false : true}
              // placeholder={`選擇${opcodeInchargeIDmap[opcode]}`}
              allowClear
              name="inchargeID"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {s_AllStaff.map((item) => {
                return (
                  <Select.Option key={item.staffID} value={item.peopleNM}>
                    {item.peopleNM}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //廠商ID
            label={t(`WMS.util.dealID_${opcode}`)}
            name="dealID"
            rules={[
              {
                required: true,
                message: t("util.placeholder_input") + t(`WMS.util.dealID_${opcode}`),
              },
            ]}
          >
            {/* <Input
              name="dealID"
              placeholder={t("util.placeholder_input") + t(`WMS.util.dealID_${opcode}`)}
            /> */}
            <Select
              showSearch
              placeholder={t("util.placeholder_input") + t(`WMS.util.dealID_${opcode}`)}
              options={
                call_getSuppHd.data?.map((x) => ({
                  label: `${x.suppID} (${x.suppalias})`,
                  value: x.suppID,
                })) || []
              }
              onChange={(value) => {
                const deal_obj = call_getSuppHd.data?.find((x) => x.suppID === value) || {};

                form.setFieldsValue({
                  dealalias: deal_obj.suppalias || "",
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //廠商簡稱
            label={t(`WMS.util.dealNM_${opcode}`)}
            name="dealalias"
            rules={[
              {
                required: true,
                message: t("util.placeholder_input") + t(`WMS.util.dealNM_${opcode}`),
              },
            ]}
          >
            <Input
              name="dealalias"
              disabled
              placeholder={t("util.placeholder_input") + t(`WMS.util.dealNM_${opcode}`)}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            //入庫日期
            label={t(`WMS.util.dueday_${opcode}`)}
            name="dueday"
          >
            <DatePicker
              placeholder={t("util.placeholder_input") + t(`WMS.util.dueday_${opcode}`)}
              className="w-full"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            // 倉別
            label={t("util.whID")}
            name="whID"
            shouldUpdate
          >
            <Select
              placeholder={t("util.placeholder_select") + t("util.whID")}
              onChange={(e) => {
                let formData = form.getFieldValue();
                if (formData.details?.length > 0) {
                  formData.details = formData.details.map((x) => {
                    return { ...x, whID: e };
                  });
                  form.setFieldsValue({ ...formData });
                }
              }}
            >
              {call_getAllWhID.data?.map((x) => (
                <Select.Option key={x.whID} value={x.whID}>
                  {x.whNM}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            //註記
            label={t("util.note")}
            name="note"
          >
            <Input.TextArea
              rows={6}
              name="note"
              placeholder={t("util.placeholder_input") + t("util.note")}
            />
          </Form.Item>
        </Col>
      </Row>

      {Object.keys(s_editData).length === 0 && (
        <div className="tbWrapper">
          <Divider style={{ margin: "6px" }} />
          <Row gutter={[36, 12]}>
            <Col span={8} style={{ fontSize: "20px", margin: "10px 0px 10px 0px" }}>
              {t(`WMS.util.opcode_${opcode}`) + "明細"}
            </Col>
          </Row>

          <Form.List name="details" shouldUpdate>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Row key={field.key} gutter={[12, 12]}>
                    <Col span={2} className="mb12">
                      <Form.Item
                        {...field}
                        //序
                        label="序"
                        name={[field.name, "itemno"]}
                      >
                        <Input disabled value={index + 1} />
                      </Form.Item>
                    </Col>

                    <Col span={5} className="mb12">
                      <Form.Item
                        {...field}
                        // 料號
                        label={t("util.PN")}
                        rules={[
                          { required: true, message: t("util.placeholder_select") + t("util.PN") },
                        ]}
                        name={[field.name, "PN"]}
                      >
                        <DebounceSelect
                          key="DebounceSelect"
                          // className="w-[300px]"
                          placeholder={t("util.placeholder_input") + " " + t("util.PN")}
                          allowClear
                          fetchOptions={fetchPNList}
                          showSearch
                          maxTagCount={10}
                          onChange={(value, option) => {
                            let _details = form.getFieldValue("details");
                            _details[field.name].PN = option.data.PN;
                            _details[field.name].idmtype = option.data.idmtype;
                            form.setFieldsValue({ details: _details });
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={3} className="mb12">
                      <Form.Item
                        {...field}
                        // 過帳型態
                        label={t("util.idmtype")}
                        shouldUpdate
                        name={[field.name, "idmtype"]}
                      >
                        <Select disabled>
                          <Select.Option value="S">{t("util.idmtype_s")}</Select.Option>
                          <Select.Option value="D">{t("util.idmtype_d")}</Select.Option>
                          <Select.Option value="M">{t("util.idmtype_m")}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={4}>
                      <Form.Item
                        {...field}
                        // 倉別
                        label={t("util.whID")}
                        name={[field.name, "whID"]}
                      >
                        <Select
                          placeholder={t("util.placeholder_select") + t("util.whID")}
                          onChange={(e) => {
                            let formData = form.getFieldValue();
                            formData["whID"] = undefined;
                            form.setFieldsValue(formData);
                          }}
                        >
                          {call_getAllWhID.data?.map((x) => (
                            <Select.Option key={x.whID} value={x.whID}>
                              {x.whNM}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb12">
                      <Form.Item
                        {...field}
                        //入庫批號
                        label={t(`WMS.util.batchno_${opcode}`)}
                        name={[field.name, "batchno"]}
                      >
                        <Input style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    <Col span={4} className="mb12">
                      <Form.Item
                        {...field}
                        // 計劃數量
                        label={t("util.planqty")}
                        rules={[
                          {
                            required: true,
                            message: "請輸入大於0的數量!",
                            validator: (_, value) => {
                              return new Promise(async (resolve, reject) => {
                                if (!value) {
                                  await reject("請輸入數量");
                                } else if (value - 0 === NaN) {
                                  await reject("請輸入大於0的數字");
                                } else {
                                  await resolve();
                                }
                              });
                            },
                          },
                        ]}
                        name={[field.name, "planqty"]}
                      >
                        <InputNumber min={0} style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>

                    <Col span={2} className="flex-center">
                      <MinusCircleOutlined
                        onClick={() => {
                          remove(field.name);
                          let formData = form.getFieldValue();
                          formData.details = formData.details.map((x, index) => {
                            return { ...x, itemno: index + 1 };
                          });
                          form.setFieldsValue(formData);
                        }}
                        style={{ fontSize: "20px", marginLeft: "5px" }}
                      />
                    </Col>
                  </Row>
                ))}

                <Form.Item>
                  <Button
                    //新增生產入庫詳情
                    type="dashed"
                    onClick={() => {
                      let formData = form.getFieldsValue();
                      let whID = formData?.whID;
                      add({ itemno: fields.length + 1, whID: whID });
                    }}
                    block
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    {t("util.add") + t(`WMS.util.opcode_${opcode}`) + t("util.detail")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      )}

      <Divider style={{ margin: "0px 0px 12px 0px" }} />

      <Row>
        <Col span={8}>
          <Row>
            {/* {Object.keys(s_initialValues).length > 0 ? ( */}
            <Col offset={2} style={{ display: "none" }}>
              <Button
                size="large"
                style={{ marginRight: "20px", borderRadius: "5px" }}
                onClick={() => alert("施工中")}
              >
                {/* 據此創建新條目 */}
                {t("util.header_create")}
              </Button>
            </Col>
            {/* ) : null} */}
          </Row>
        </Col>
        <Col span={24} className="flex justify-end">
          <Space>
            <Button size="large" onClick={handleCancel}>
              {/* 取消 */}
              {t("util.cancel")}
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              {/* 確定 */}
              {t("util.ok")}
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default ModalContainer;
