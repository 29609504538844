import zh_TW from "@/Language/locales/zh-TW";
import en_US from "@/Language/locales/en-US";
import id_ID from "@/Language/locales/id-ID";
import vi_VN from "@/Language/locales/vi-VN";
import zh_CN from "@/Language/locales/zh-CN";

export const compareLangauge = (obj1, obj2) => {
  const diffs = {};
  //比較 obj1 和 obj2 的每個key
  for (let key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      if (!obj2.hasOwnProperty(key)) {
        //如果obj2沒有obj1的key，就把obj2的該key設為undefined
        diffs[key] = { zh_TW: obj1[key], id_ID: "" };
      } else {
        const val1 = obj1[key];
        const val2 = obj2[key];
        //如果兩個物件該key的value都是物件，就跑遞回函式
        if (typeof val1 === "object" && typeof val2 === "object") {
          const nestedDiffs = compareLangauge(val1, val2);
          if (Object.keys(nestedDiffs).length > 0) {
            diffs[key] = nestedDiffs;
          }
        } else if (val1 !== val2) {
          //如果兩個key的value不相等，就加到diff裡面
          diffs[key] = { zh_TW: val1, id_ID: val2 };
        }
      }
    }
  }
  //檢查obj2 中的key是否存在於obj1中，如果不存在，就把key跟value 加到 diff中
  for (let key in obj2) {
    if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
      diffs[key] = { zh_TW: undefined, id_ID: obj2[key] };
    }
  }
  return diffs;
};

const differences = compareLangauge(zh_TW, id_ID);
// console.log(differences);

const OMS = {
  "pageHeader": {
    "zh_TW": "銷貨管理系統",
    "id_ID": "Sistem Manajemen Penjualan",
  },
  "util": {
    "opcode_4220": {
      "zh_TW": "驗出單",
      "id_ID": "銷售發貨單",
    },
    "opcode_4130": {
      "zh_TW": "驗退單",
      "id_ID": "銷售退回單",
    },
    "opcode_1610": {
      "zh_TW": "銷貨退回",
      "id_ID": "銷貨退出",
    },
    "return": {
      "zh_TW": "銷貨退回單",
      "id_ID": "銷售退回單",
    },
    "ap": {
      "zh_TW": "對帳單",
    },
    "N17": {
      "zh_TW": "應收單號",
    },
    "apSearch": {
      "zh_TW": "對帳資料查詢",
    },
    "del_tb_confirm_text": {
      "zh_TW": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
    "opcode_placeholder": {
      "zh_TW": "請選擇單別",
    },
    "N_placeholder": {
      "zh_TW": "請選擇單號",
    },
    "currencytype_placeholder": {
      "zh_TW": "請選擇幣別",
    },
    "custID_placeholder": {
      "zh_TW": "請選擇廠商",
    },
    "taxtype_placeholder": {
      "zh_TW": "請選擇稅別",
    },
    "taxrate_placeholder": {
      "zh_TW": "请输入稅率",
    },
    "PN_placeholder": {
      "zh_TW": "請選擇料號",
    },
    "utype_placeholder": {
      "zh_TW": "請選擇單位類別",
    },
    "tel_placeholder": {
      "zh_TW": "請選擇聯絡人",
    },
    "updtT": {
      "zh_TW": "更新日期",
      "id_ID": "Waktu Pembaruan",
    },
    "pdtspec": {
      "zh_TW": "規格",
      "id_ID": "spesifikasi",
    },
    "attribute": {
      "zh_TW": "物料屬性",
      "id_ID": "Sifat Bahan",
    },
    "pdtclass": {
      "zh_TW": "商品類別",
      "id_ID": "Kategori Produk",
    },
    "unit": {
      "zh_TW": "計量單位",
      "id_ID": "satuan dasar",
    },
    "opunit": {
      "zh_TW": "操作單位",
      "id_ID": "satuan pengoperasian",
    },
    "punit": {
      "zh_TW": "使用單位",
    },
    "quoID": {
      "zh_TW": "報價人員",
    },
    "quoT": {
      "zh_TW": "報價日期",
    },
    "balqty": {
      "zh_TW": "剩餘數量",
    },
  },
  "quo": {
    "isvalid": {
      "zh_TW": "是否有效",
      "id_ID": "validasi",
    },
    "del_tb_confirm_text": {
      "id_ID": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
  },
  "fod": {
    "EDT": {
      "zh_TW": "預計交貨日",
    },
    "balqty": {
      "id_ID": "剩餘數量",
    },
    "del_tb_confirm_text": {
      "id_ID": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
  },
  "ord": {
    "startEDT": {
      "zh_TW": "預計交貨日(起)",
    },
    "endEDT": {
      "zh_TW": "預計交貨日(迄)",
    },
    "balqty": {
      "id_ID": "剩餘數量",
    },
    "del_tb_confirm_text": {
      "id_ID": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
  },
  "outstock": {
    "QAcheck_err": {
      "zh_TW": "發貨明細 [驗收數量]+[驗退數量] 要等於 [發貨數量]",
      "id_ID": "發貨明細 [品保判退數量]+[品保放行數量] 要等於 [發貨數量]",
    },
  },
  "check": {
    "pageHeader": {
      "zh_TW": "出貨檢驗查詢",
      "id_ID": "銷貨檢驗查詢",
    },
  },
  "ap-manage": {
    "contactWindow": {
      "zh_TW": "聯絡窗口資訊",
    },
    "strike_tb": {
      "zh_TW": "沖帳明細",
    },
    "strike_lifeF_0": {
      "zh_TW": "創立",
    },
    "strike_lifeF_1": {
      "zh_TW": "沖帳覆核",
    },
    "strike_lifeF_T": {
      "zh_TW": "完成沖帳",
    },
    "rcvtype": {
      "zh_TW": "收款方式",
    },
    "rcvtype_1": {
      "zh_TW": "銀行滙款(T/T)",
    },
    "rcvtype_2": {
      "zh_TW": "應付票據(支票)",
    },
    "rcvtype_3": {
      "zh_TW": "现金支付(CASH)",
    },
    "rcvtotal": {
      "zh_TW": "收款金額",
    },
    "rcvT": {
      "zh_TW": "收款時間",
    },
    "strike_note": {
      "zh_TW": "沖帳註記",
    },
    "confirmStrike": {
      "zh_TW": "確認沖帳",
    },
    "strikeCheck_confirm_text": {
      "zh_TW": "請先確認要覆核的資料無誤，再進行覆核",
    },
    "cancel_strikeCheck_confirm_text": {
      "zh_TW": "請先確認要取消覆核的資料無誤，再進行取消覆核",
    },
    "confirmStrike_confirm_text": {
      "zh_TW": "確認沖帳後此沖帳單將結案，請確認是否沖帳",
    },
    "strike_tb_remove_text": {
      "zh_TW": "請先確認要刪除的明細資料無誤，再進行刪除",
    },
  },
};
