import { instance } from "../../handlerAPI";
import apiBasic from "../../APIBasic";

//-----------------------------------------組織功能管理----------------------------------------
export const getFunc = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getFunc`, {
    params: data,
  });
};

export const addFunc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addFunc`, data);
};

export const modifyFunc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyFunc`, data);
};

export const deleteFunc = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteFunc`, data);
};

//-----------------------------------------推播層級管理----------------------------------------
export const getPush = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getPush`, {
    params: data,
  });
};

export const addPush = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addPush`, data);
};

export const modifyPush = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyPush`, data);
};

export const deletePush = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deletePush`, data);
};

//-----------------------------------------推播事件設定----------------------------------------
export const getNotify = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getNotify`, {
    params: data,
  });
};

export const addNotify = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addNotify`, data);
};

export const modifyNotify = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyNotify`, data);
};

export const deleteNotify = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteNotify`, data);
};

//-----------------------------------------包裝單位設定----------------------------------------
export const getOpUnit = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getOpUnit`, {
    params: data,
  });
};

export const addOpUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addOpUnit`, data);
};

export const modifyOpUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyOpUnit`, data);
};

export const deleteOpUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteOpUnit`, data);
};

//-----------------------------------------計量單位設定----------------------------------------
export const getUnit = (data) => {
  return instance.get(`${apiBasic.conn}://${apiBasic.url}/api/getUnit`, {
    params: data,
  });
};

export const addUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/addUnit`, data);
};

export const modifyUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/modifyUnit`, data);
};

export const deleteUnit = (data) => {
  return instance.post(`${apiBasic.conn}://${apiBasic.url}/api/deleteUnit`, data);
};
