import { useState, useEffect, useRef } from "react";
import { Space, Button, message, Select, Row, Col, Input } from "antd";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import useAPI from "@/hooks/useAPI";
import { getModelHistory } from "@/service/apis/LMS/LabelManage";
import dayjs from "dayjs";

export default function Record({
  s_editData,
  set_is_showModal,
  set_s_printerShowModal,
  set_s_recordSN,
}) {
  const call_getModelHistory = useAPI(getModelHistory);
  const [s_tableData, set_s_tableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [s_pageInfo, set_s_pageInfo] = useState({
    currentPage: 0,
    endItemNumber: 0,
    pageItemNumber: 0,
    startItemNumber: 0,
    totalItems: 0,
  });

  const [s_searchValue, set_s_searchValue] = useState({
    page: 1,
    pageSize: 10,
    woN: undefined,
    SN: undefined,
    barcode: undefined,
  });

  const handleCancel = () => {
    set_is_showModal(false);
  };

  const handleSetSearchValue = (key, value) => {
    set_s_searchValue((prev) => {
      prev[key] = value;
      return { ...prev, page: 1, pageSize: 10 };
    });
  };

  const onSelectChange = (_, newSelectedRowData) => {
    console.log("selectedRowKeys changed: ", newSelectedRowData);
    setSelectedRowKeys(newSelectedRowData);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys.map((x) => x.key),
    onChange: onSelectChange,
  };

  const tableColumns = [
    {
      title: "流水號",
      dataIndex: "SN",
    },
    {
      title: "條碼",
      dataIndex: "barcode",
    },
    {
      title: "工單號",
      dataIndex: "woN",
    },
    {
      title: "料號",
      dataIndex: "PN",
    },
    {
      title: "品名",
      dataIndex: "pdtNM",
    },
    {
      title: "客戶料號",
      dataIndex: "custPN",
    },
    {
      title: "綁定型態",
      dataIndex: "bindclass",
      align: "center",
      render: (text) => (text === "U" ? "單綁" : "批綁"),
    },
    {
      title: "產生時間",
      dataIndex: "updtT",
      align: "center",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "備註",
      dataIndex: "note",
      width: "25%",
    },
  ];

  useEffect(() => {
    call_getModelHistory.request({ ...s_searchValue, ...s_editData });
  }, [s_searchValue.page, s_searchValue.pageSize]);

  useEffect(() => {
    if (call_getModelHistory.status === "suc") {
      set_s_tableData(
        call_getModelHistory.data.tableData.map((x, i) => {
          return { ...x, key: x.barcode + i };
        })
      );
      set_s_pageInfo(call_getModelHistory.data.pageInfo);
    } else if (call_getModelHistory.status === "err") {
      set_s_tableData([]);
      message.error(call_getModelHistory.msg);
    }
  }, [call_getModelHistory.status]);

  return (
    <Row gutter={[12, 12]}>
      <Col span={24} className="flex justify-end">
        <Space>
          <Input
            className="w-[200px]"
            placeholder="請輸入流水號"
            value={s_searchValue.SN}
            onChange={(e) => handleSetSearchValue("SN", e.target.value)}
          />
          <Input
            className="w-[200px]"
            placeholder="請輸入工單號"
            value={s_searchValue.woN}
            onChange={(e) => handleSetSearchValue("woN", e.target.value)}
          />
          <Input
            className="w-[200px]"
            placeholder="請輸入標籤條碼"
            value={s_searchValue.barcode}
            onChange={(e) => handleSetSearchValue("barcode", e.target.value)}
          />
          <Button
            type="primary"
            onClick={() => call_getModelHistory.request({ ...s_searchValue, ...s_editData })}
          >
            查詢
          </Button>
        </Space>
      </Col>
      <Col span={24}>
        <CustomTable
          columns={tableColumns.filter((x) => !x.hidden)}
          dataSource={s_tableData}
          loading={call_getModelHistory.status === "load"}
          rowSelection={rowSelection}
          pagination={{
            total: s_pageInfo?.totalItems,
            current: s_searchValue.page,
            pageSize: s_searchValue.pageSize,
            onChange: (current, size) =>
              set_s_searchValue((prev) => {
                // 如果有更動pageSize 回第一頁
                let checkPage = prev.pageSize !== size ? 1 : current;
                return { ...prev, page: checkPage, pageSize: size };
              }),
          }}
        />
      </Col>
      <Col span={24}>
        <Space className="flex justify-end">
          <Button onClick={handleCancel}>關閉</Button>
          <Button
            type="primary"
            disabled={selectedRowKeys.length === 0}
            onClick={() => {
              // 先判斷進行補印是否為同一張工單
              const singleArr = new Set(selectedRowKeys.map((x) => x.woN));
              if (singleArr.length > 1) {
                message.error("請選擇相同工單號");
                return;
              }
              console.log(selectedRowKeys);
              set_s_printerShowModal(true);
              set_s_recordSN(selectedRowKeys);
            }}
          >
            補印
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
