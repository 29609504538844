import React from "react";
import styled from "styled-components";
import { Chart, Interval, Tooltip, Coordinate, Legend, View, Annotation } from "bizcharts";

const Wrapper = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 1px #00000029;
  padding: 10px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;
  position: relative;

  .Header {
    color: #3089c7;
    font-weight: 600;
  }

  .Footer {
    position: absolute;
    bottom: 0px;
    padding: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    //flex-direction: column;
    color: ${({ theme }) => theme.text2};
    background-color: #f0f2f5;
    width: 100%;
  }
`;

const content = {
  title: "總開機率",
  percent: "66.66%",
};

const ProgressChart = ({ width, height, s_act, s_AllStation, wsID }) => {
  // 開機數
  const openCount =
    s_act && s_act.length > 0
      ? s_act.filter((item) =>
          item.nightData !== null
            ? item.nightData.slice(-1)[0].situF === "G" ||
              item.nightData.slice(-1)[0].situF === "Y" ||
              item.nightData.slice(-1)[0].situF === "B"
            : item.morningData !== null
            ? item.morningData.slice(-1)[0].situF === "G" ||
              item.morningData.slice(-1)[0].situF === "Y" ||
              item.morningData.slice(-1)[0].situF === "B"
            : false
        ).length
      : 0;
  //alert((1 - (openCount/s_act.length)).toFixed(2))
  const data = [
    { type: "已完成", percent: s_act ? openCount / s_act.length : 0 },
    { type: "待提升", percent: s_act ? 1 - openCount / s_act.length : 0 },
  ];

  const wsData =
    s_AllStation && s_AllStation.length > 0
      ? s_AllStation.find((item) => item.wsID === wsID)
      : null;

  return (
    <Wrapper width={width} height={height}>
      <div className="Header">{wsData && wsData.wsNM}</div>

      <Chart placeholder={false} width={250} height={250} padding="auto" autoFit>
        <Legend visible={false} />
        {/* 绘制图形 */}
        <View
          data={data}
          // scale={{
          //   percent: {
          //     formatter: (val) => {
          //       return (val * 100).toFixed(2) + "%";
          //     },
          //   },
          // }}
        >
          <Coordinate type="theta" innerRadius={0.75} />
          <Interval
            position="percent"
            adjust="stack"
            // color="type"
            // color={["type", ["rgba(100, 100, 255, 0.6)", "#eee"]]}
            color={["type", ["#71AFF8", "#eee"]]}
            size={16}
            // style={{ fillOpacity: 0.6 }}
            // label={['type', {offset: 40}]}
          />
          <Tooltip visible={false} />
          <Annotation.Text
            position={["50%", "35%"]}
            //content={content.siteCode}
            style={{
              lineHeight: "240px",
              fontSize: "16",
              fill: "#000",
              textAlign: "center",
            }}
          />
          <Annotation.Text
            position={["50%", "48%"]}
            content={content.title}
            style={{
              lineHeight: "240px",
              //fontWeight: "bold",
              fontSize: "25",
              fill: "#707070",
              textAlign: "center",
            }}
          />
          <Annotation.Text
            position={["50%", "62%"]}
            content={`${s_act ? Math.round((openCount / s_act.length) * 100) : 0}%`}
            style={{
              lineHeight: "240px",
              fontSize: "24",
              fontWeight: "bold",
              fill: "#707070",
              textAlign: "center",
            }}
          />
        </View>
      </Chart>

      <div className="Footer">
        <div style={{ width: "50%", textAlign: "center" }}>{`開機數：${openCount}`}</div>
        <div style={{ width: "1px", backgroundColor: "#C4C4C4" }} />
        <div style={{ width: "50%", textAlign: "center" }}>{`總機數：${
          s_act ? s_act.length : 0
        }`}</div>
      </div>
    </Wrapper>
  );
};

export default ProgressChart;

/*
 */
