import { useContext } from "react";
import { Row, Col, Typography } from "antd";
import { CheckCircleFilled, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
// util
import { formatAmount } from "@/util/format";
// content
import { ERPContext } from "@/components/Context/SystemContext";
const { Text } = Typography;

const DrawerModal = ({ s_drawer, set_s_drawer }) => {
  const { t } = useTranslation();
  const { getStaffName } = useContext(ERPContext);
  const { data } = s_drawer;

  return (
    <div className="h-full" onClick={() => set_s_drawer({ show: false, data: {} })}>
      <Row className="border pms-drawer-border-color">
        {/* 料號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.PN")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.PN}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 品名 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.pdtNM")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtNM}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 規格 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.util.pdtspec")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.pdtspec}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 屬性 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.attribute")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{t(`util.attribute_${data.attribute}`)}</Text>
        </Col>
        {/* 商品類別 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("util.pdtclass")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.pdtclass}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 工程圖號 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.drawno")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.drawno}</Text>
        </Col>
        {/* 原料材質 */}
        <Col span={4} className="pms-drawer-title border-r border-l">
          <Text>{t("util.matl")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.matl}</Text>
        </Col>
      </Row>

      <Row className="border border-t-0 pms-drawer-border-color">
        {/* 需求數量 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("PMS.plan.demand")}</Text>
        </Col>
        <Col span={8} className="pms-drawer-value">
          <Text>{data.demand}</Text>
        </Col>
      </Row>

      <Row className="h-24 border border-t-0 pms-drawer-border-color">
        {/* 採購註記 */}
        <Col span={4} className="pms-drawer-title border-r">
          <Text>{t("util.note")}</Text>
        </Col>
        <Col span={20} className="pms-drawer-value">
          <Text>{data.note}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default DrawerModal;
