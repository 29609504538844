const zh_CN = {
  util: {
    "itemID": "项目ID",
    "itemno": "項序",
    "sign_out": "登出",
    "add": "新增",
    "edit": "编辑",
    "delete": "删除",
    "search": "查询",
    "cancel": "取消",
    "close": "关闭",
    "ok": "确认",
    "render": "选择",
    "check": "覆核",
    "success": "成功",
    "all": "全部",
    "yes": "是",
    "no": "否",
    "name": "名称",
    "total": "总数",
    "SN": "序",
    "detail": "详情",
    "action": "操作",
    "size_big": "特大",
    "size_large": "大尺寸",
    "size_middle": "中尺寸",
    "size_small": "小尺寸",
    "hide_detail": "隐藏明细档",
    "del_confirm": "删除确认",
    "del_confirm_msg": "请先确认资料无误，再删除",
    "note": "备注",
    "no_data": "无此资料",
    "data": "资料",
    "header_cancel_create": "取消据此创建",
    "header_create": "据此创建条目",
    "placeholder_select": "请选择",
    "placeholder_input": "请输入",
    "or": "或",
    "order": "单",
    "showTotal": "共 {{total}} 条 ， 当前显示 {{rangeFirst}} - {{rangeEnd}} 条",
    "itemNM": "项目名称",
    "excluding_tax": "(未税)",
    "start": "起",
    "end": "迄",
    "wl": "工线",
    "ws": "工站",
    "woN": "工单号",
    "linemix": "混线",
    "situF": "状态",
    "PN": "项目编号",
    "whID": "仓别",
    "idmtype": "过帐型态",
    "custID": "客户ID",
    "custPN": "客户料号",
    "pdtNM": "检测项目",
    "pdtspec": "检测标准/测试方法",
    "planqty": "计划数量",
    "inqty": "生产数量",
    "tqty": "当前总数",
    "sqty": "人工输入",
    "dqty": "条码扫入",
    "okC": "良品数量",
    "inC": "产出数量",
    "ngC": "不良数量",
    "lifeF": "历程",
    "isvalid": "有效",
    "isfocus": "关注",
    "dept": "部门",
    "updtT": "更新时间",
    "pdtclass": "商品",
    "mtype": "引用标准",
    "ver": "版码",
    "qty": "组数",
    "attribute": "属性",
    "unitNM": "计量单位",
    "opunit": "操作单位",
    "unitwt": "计数单重",
    "mgmtcat": "授权分类",
    "querycat": "查询分类",
    "drawno": "工程图号",
    "matl": "原料材质",
    "assetID": "模具ID",
    "heirPN": "继承料号",
    "heirdealID": "继承对象",
    searchPN: "搜尋料號",
    "edit_detect_msg1": "系统侦测到您编辑了以下栏位",
    "edit_detect_msg2": "请先储存系统资料再编辑明细",
    "msg_chose_data": "请选择要引用的资料",
    "msg_chose_whichType_toThrow": "请选择要抛转的单别",
    "msg_chose_atleast_one_Detail": "请至少选择一笔明细",
    "linemix_0": "不混线",
    "linemix_1": "右",
    "linemix_2": "左",
    "idmtype_s": "人工过帐",
    "idmtype_d": "条码过帐",
    "idmtype_m": "混合过帐",
    "attribute_0": "0 检测项目",
    "attribute_1": "1 办公用品",
    "attribute_2": "2 试剂耗材",
    "attribute_3": "3 玻璃器皿",
    "attribute_4": "4 设备配件",
    "attribute_5": "5 其他类别",
    "attribute_6": "6 客供品",
    "pdtclass_X": "N/A",
    "pdtclass_M": "主件",
    "pdtclass_A": "附件",
    "pdtclass_P": "包材",
    "packtype_0": "操作包装_操作单位使用",
    "packtype_1": "出货包装_打包出货使用",
    "packtype_2": "周转包装_产线周转使用",
    "assetkind": "资产分类",
    "assetkind_TI": "射出",
    "assetkind_TS": "冲压",
    "assetkind_TD": "压铸",
    "assetkind_TF": "锻造",
    "fileType_SOP": "作业标准 SOP",
    "fileType_SIP": "检验标准 SIP",
    "fileType_PACK": "包装标准 PACK",
    "fileType_DRAW": "工程图面 DWG",
    "fileType_PIC": "照片图档 PIC",
    "nav_A": "业务管理",
    "nav_A-1": "客户资料维护",
    "nav_A-2": "快速业绩查询",
    "nav_A-3": "客户业绩查询",
    "nav_A-4": "销售业绩排行",
    "nav_B": "采购管理",
    "nav_B-1": "外包检测作业",
    "nav_B-2": "报价订单作业",
    // "nav_B-3": "项目合同管理",
    "nav_B-3": "项目业绩查询",
    "nav_B-4": "委外检验",
    "nav_C": "财务管理",
    "nav_C-1": "应收帐款管理",
    "nav_C-2": "应付帐款管理",
    "nav_C-3": "每月利润分析",
    "nav_C-4": "每月提成计算",
    "nav_C-5": "每月利润分析",
    "nav_C-6": "每月提成计算",
    "nav_D": "系统管理",
    "nav_D-1": "公司主档",
    "nav_D-2": "员工资料",
    "nav_D-3": "检测项目主档",
    "nav_D-4": "客户资料",
    "nav_D-5": "分包商资料",
    "nav_D-5-1": "供应商资料",
    "nav_D-6": "分类设置",
    "nav_D-7": "功能权限",
    "nav_D-8": "系统参数",
    "nav_pds_dashboard": "数位生产看板",
    "nav_pds_abc-board": "异常管理看板",
    "nav_pds_overview-lines": "线体稼动总表",
    "nav_BDM": "物料资料管理",
    "nav_bdm_pn-manage": "基本资料维护",
    "nav_bdm_edm": "技术资料维护",
    "nav_EDM": "模具资料管理",
    "nav_edm_mold": "模具资料维护",
    "nav_TDM": "厂区资料管理",
    "nav_tdm_plant-manage": "公司别主档设定",
    "nav_tdm_procedure-manage": "制程规划维护",
    "nav_tdm_production-line-manage": "产线规划维护",
    "nav_tdm_warehouse-manage": "库房规划维护",
    "nav_tdm_string-manage": "功能字串管理",
    "nav_tdm_plant-params-manage": "公司别参数设定",
    "nav_SDM": "系统资料管理",
    "nav_sdm_user-manage": "员工资料维护",
    "nav_sdm_company": "厂商资料维护",
    "nav_sdm_category-manage": "查询分类设定",
    "nav_sdm_data-auth-manage": "功能授权设定",
    "nav_sdm_trading-pattern": "项目字串设定",
    "nav_sdm_function-params-manage": "系统参数设定",
    "nav_WMS": "库存管理系统",
    "nav_wms_plan-in": "库房入库作业",
    "nav_wms_plan-out": "库房出库作业",
    "nav_wms_stock-search": "库存状况查询",
    "nav_wms_inv-history": "每月库存纪录",
    "nav_wms_pro-instock": "代理入库作业",
    "nav_PMS": "采购管理系统",
    "nav_pms_plan": "询价计划",
    "nav_pms_req": "请购作业",
    "nav_pms_pro": "采购作业",
    "nav_pms_instock": "进料作业",
    "nav_pms_gostock": "进料检验查询",
    "nav_pms_return": "进料退出作业",
    "nav_pms_ap-manage": "应付帐款管理",
    "nav_pms_report": "报表查询",
    "nav_pms_report-unpurchase": "采购未进货明细表",
    "nav_pms_report-diff": "采购价格调幅表",
    "nav_pms_report-instocksummary": "库存汇总表",
    "nav_pms_report-inventorydiff": "库存异动明细帐",
    "nav_pms_report-psisummary": "进销存汇总表",
    "nav_pms_report-purchasesummary": "进退货汇总表",
    "nav_pms_report-purchaselist": "进退货明细表",
    "nav_pms_report-purchasestatistics": "进退货统计表",
    "nav_OMS": "销货管理系统",
    "nav_oms_quo": "报价作业",
    "nav_oms_fod": "预订作业",
    "nav_oms_ord": "销货作业",
    "nav_oms_outstock": "发货作业",
    "nav_oms_check": "销货检验查询",
    "nav_oms_return": "销货退回作业",
    "nav_oms_ap-manage": "应收帐款管理",
    "nav_oms_report": "报表查询",
    "nav_oms_report-unshipped": "订单未出明细表",
    "nav_oms_report-salesummary": "销退货汇总表",
    "nav_oms_report-salelist": "销退货明细表",
    "util": {
      "": "",
      "EDT": "预计交货日",
      "N": "单号",
      "PN": "料号",
      "SN": "序",
      "UploadSign": "上传回签",
      "UploadSignature": "上传签呈",
      "accord": "据此",
      "action": "操作",
      "add": "新增",
      "advanceSearch": "进阶查询",
      "all": "全部",
      "approveT": "核准日期",
      "assetkind": "资产分类",
      "assetkind_TD": "压铸",
      "assetkind_TF": "锻造",
      "assetkind_TI": "射出",
      "assetkind_TS": "冲压",
      "attribute": "属性",
      "attribute_0": "0 原料",
      "attribute_1": "1 办公用品：日常办公用品",
      "attribute_2": "2 易制毒化学品：丙酮、甲苯、硫酸、盐酸",
      "attribute_3": "3 实验试剂耗材：检测过程易耗品",
      "attribute_4": "4 检测仪器及辅助仪器",
      "attribute_5": "5 制成品",
      "attribute_6": "6 客供品",
      "attribute_7": "维修备品",
      "attribute_8": "管理财产",
      "attribute_9": "列管资产",
      "balqty": "剩余数量",
      "batchdate": "批号日期",
      "batchno": "批号",
      "cancel": "取消",
      "cancel_Check": "取消签回",
      "category": "",
      "check": "覆核",
      "close": "关闭",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "contact": "联络人",
      "create": "创建",
      "createID": "创建人员",
      "createT": "创建日期",
      "currencytype": "币别",
      "custID": "客户ID",
      "custNM": "客户名称",
      "custPN": "客户料号",
      "custalias": "客户简称",
      "d_inC": "",
      "d_planqty": "",
      "data": "资料",
      "dealPN": "交易料号",
      "del_confirm": "删除确认",
      "del_confirm_msg": "请先确认资料无误，再删除",
      "delete": "删除",
      "dept": "部门",
      "detail": "详情",
      "dqty": "条码扫入",
      "drawno": "工程图号",
      "edit": "编辑",
      "edit_detect_msg1": "系统侦测到您编辑了以下栏位",
      "edit_detect_msg2": "请先储存系统资料再编辑明细",
      "end": "迄",
      "endEDT": "预计交货日(迄)",
      "exchangeD": "汇率日期",
      "exchangerate": "汇率",
      "excluding_tax": "(未税)",
      "exportExcel": "汇出Excel",
      "fileType_DRAW": "DWG_工程图档",
      "fileType_ECN": "ECN_工程变更通知",
      "fileType_PACK": "PAK_包装作业标准",
      "fileType_PIC": "PIC_照片指导",
      "fileType_SIP": "SIP_检验作业标准",
      "fileType_SOP": "SOP_生产作业标准",
      "get_next_data_err": "取得下一笔资料失败",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_prev_data_suc": "取得上一笔资料成功",
      "header_cancel_create": "取消据此创建",
      "header_create": "据此创建",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "heirPN": "继承料号",
      "heirdealID": "继承对象",
      "hide_detail": "隐藏明细档",
      "idmtype": "過帳型態",
      "idmtype_d": "条码过帐",
      "idmtype_m": "混合过帐",
      "idmtype_s": "人工过帐",
      "inC": "产出数量",
      "inqty": "生产数量",
      "isfocus": "关注",
      "isvalid": "有效",
      "itemID": "项目ID",
      "itemNM": "名称",
      "itemno": "项序",
      "itemno_total": "总项数",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "lineMix": "",
      "linemix": "混线",
      "linemix_0": "不混线",
      "linemix_1": "右",
      "linemix_2": "左",
      "matl": "原料材质",
      "mgmtcat": "授权分类",
      "mold": "",
      "moldC": "合模次数",
      "moldID": "模具ID",
      "moldcavity": "模具穴数",
      "msg_chose_atleast_one_Detail": "请至少选择一笔明细",
      "msg_chose_data": "请选择要引用的资料",
      "msg_chose_whichType_toThrow": "请选择要抛转的单别",
      "mtype": "机种",
      "name": "名称",
      "nav_BDM": "物料资料管理",
      "nav_EDM": "生产设备管理",
      "nav_OMS": "销货管理系统",
      "nav_PMS": "采购管理系统",
      "nav_SDM": "系统资料管理",
      "nav_TDM": "厂区资料管理",
      "nav_WMS": "库存管理系统",
      "nav_bdm_edm": "技术资料维护",
      "nav_bdm_pn-manage": "基本资料维护",
      "nav_edm_logistics-equipment_AGV": "物流设备_AGV",
      "nav_edm_measurement-equipment": "量测设备",
      "nav_edm_mold": "模具资料",
      "nav_edm_plant-maintenance": "厂房维护",
      "nav_edm_production-equipment": "生产设备",
      "nav_edm_transportation-equipment": "交通设备",
      "nav_oms_ap-manage": "应收帐款管理",
      "nav_oms_check": "销货检验查询",
      "nav_oms_fod": "预订作业",
      "nav_oms_ord": "销货作业",
      "nav_oms_outstock": "发货作业",
      "nav_oms_quo": "报价作业",
      "nav_oms_report": "报表查询 ",
      "nav_oms_report-salelist": "销退货明细表",
      "nav_oms_report-salesummary": "销退货汇总表",
      "nav_oms_report-unshipped": "订单未出明细表",
      "nav_oms_return": "销货退回作业",
      "nav_pds_abc-board": "异常管理看板",
      "nav_pds_dashboard": "数位生产看板",
      "nav_pds_overview-lines": "线体稼动总表",
      "nav_pms_ap-manage": "应付帐款管理",
      "nav_pms_gostock": "进料检验查询",
      "nav_pms_instock": "进料作业",
      "nav_pms_plan": "询价计画",
      "nav_pms_pro": "采购作业",
      "nav_pms_report": "报表查询",
      "nav_pms_report-diff": "采购价格调幅表",
      "nav_pms_report-instocksummary": "库存汇总表",
      "nav_pms_report-inventorydiff": "库存异动明细帐",
      "nav_pms_report-psisummary": "进销存汇总表",
      "nav_pms_report-purchaselist": "进退货明细表",
      "nav_pms_report-purchasestatistics": "进退货统计表",
      "nav_pms_report-purchasesummary": "进退货汇总表",
      "nav_pms_report-unpurchase": "采购未进货明细表",
      "nav_pms_req": "请购作业",
      "nav_pms_return": "进料退出作业",
      "nav_sdm_category-manage": "查询分类设定",
      "nav_sdm_client": "客户资料维护",
      "nav_sdm_company": "厂商资料维护",
      "nav_sdm_data-auth-manage": "功能授权设定",
      "nav_sdm_function-params-manage": "系统参数设定",
      "nav_sdm_trading-pattern": "项目字串设定",
      "nav_sdm_user-manage": "员工资料维护",
      "nav_tdm_plant-manage": "公司别主档设定",
      "nav_tdm_plant-params-manage": "公司别参数设定",
      "nav_tdm_procedure-manage": "制程规划维护",
      "nav_tdm_production-line-manage": "产线规划维护",
      "nav_tdm_string-manage": "功能字串管理",
      "nav_tdm_warehouse-manage": "库房规划维护",
      "nav_wms_inv-history": "每月库存纪录",
      "nav_wms_inventory-inquiry": "现有库存查询",
      "nav_wms_plan-in": "库房入库作业",
      "nav_wms_plan-out": "库房出库作业",
      "nav_wms_pro-instock": "代理入库作业",
      "nav_wms_pro-outstock": "代理出库作业",
      "nav_wms_stock-search": "库存状况查询",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "ngC": "不良数量",
      "no": "否",
      "no_data": "无此资料",
      "note": "注记",
      "offfix": "",
      "ok": "确认",
      "okC": "良品数量",
      "opcode": "单别",
      "opunit": "操作单位",
      "or": "或",
      "order": "单",
      "packtype_0": "操作包装(0)_操作单位使用",
      "packtype_1": "出货包装(1)_打包出货使用",
      "packtype_2": "周转包装(2)_产线周转使用",
      "pbom": "组合数量",
      "pdtNM": "品名",
      "pdtclass": "商品",
      "pdtclass_A": "附件",
      "pdtclass_M": "主件",
      "pdtclass_P": "包材",
      "pdtclass_X": "N/A",
      "pdtspec": "规格",
      "placeholder_input": "请输入",
      "placeholder_select": "请选择",
      "planqty": "计画数量",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "punit": "使用单位",
      "qty": "组数",
      "querycat": "查询分类",
      "realtqty": "",
      "register": "",
      "removeSearch": "清除搜寻纪录",
      "render": "选择",
      "result": "",
      "sampleSize": "",
      "samplingT": "",
      "search": "查询",
      "searchPN": "搜寻料号",
      "setAdvanced": "设定进阶筛选条件",
      "setting": "设定",
      "showTotal": "共 {{total}} 条 ， 当前显示 {{rangeFirst}} - {{rangeEnd}} 条",
      "signT": "签回日期",
      "sign_out": "登出",
      "situF": "状态",
      "size_big": "特大",
      "size_large": "大尺寸",
      "size_middle": "中尺寸",
      "size_small": "小尺寸",
      "sqty": "人工输入",
      "start": "起",
      "startEDT": "预计交货日(起)",
      "stockinT": "移转时间",
      "subtotal": "含税小计",
      "success": "成功",
      "suppID": "厂商ID",
      "suppNM": "厂商名称",
      "suppalias": "厂商简称",
      "sysMsg": "系统讯息",
      "table_close": "强制结令",
      "table_more": "查看更多",
      "take": "引用",
      "tax": "总税额",
      "taxrate": "税率",
      "taxtype": "税别",
      "tel": "联络电话",
      "throw": "抛转",
      "total": "总数",
      "tqty": "当前总数",
      "type": "",
      "unit": "计量单位",
      "unitwt": "计数单重",
      "updtID": "更新人员",
      "updtT": "更新时间",
      "utype": "单位类别",
      "ver": "版码",
      "whID": "仓别",
      "window": "窗口",
      "wl": "工线",
      "wlID": "",
      "wo": "",
      "woMix": "",
      "woN": "工单号",
      "ws": "工站",
      "yes": "是",
    },
  },
  WOS: {
    "WOC": "生產控制",
    "MC": "物料作業",
    "QC": "質控作業",
    "TK": "生產看板",
    "partsBinding": "部品掃碼",
    "ppidp": "成品綁定",
    "wpcSearch": "途單查詢",
    "dispachManage": "派單管理",
    "mold": "模具管理",
    "whm": "工時管理",
    "batch": "批號管理",
    "oem": "委外報工",
  },
  WMS: {
    "pageHeader": "庫存管理系統",
    "util": {
      "lifeF_0": "已創建",
      "lifeF_1": "執行中",
      "lifeF_2": "已提交",
      "lifeF_A": "全部",
      "lifeF_W": "待規劃",
      "lifeF_R": "已歸還",
      "lifeF_T": "已異動",
      "opcode_4010": "開帳盤點",
      "opcode_4020": "週期盤點",
      "opcode_4030": "專案盤點",
      "opcode_4110": "生產入庫",
      "opcode_4120": "采购入库",
      "opcode_4130": "銷售退回",
      "opcode_4220": "銷售發貨",
      "opcode_4230": "進料退出",
      "opcode_4141": "庫房調入",
      "opcode_4241": "調撥出庫",
      "opcode_4242": "庫房借出",
      "opcode_4143": "借出歸還",
      "opcode_4142": "庫房借入",
      "opcode_4243": "借入歸還",
      "opcode_4210": "生產領用",
      "opcode_4299": "生管超領",
      "opcode_4150": "工程入庫",
      "opcode_4170": "调帐入库",
      "opcode_4160": "生產退料",
      "opcode_4260": "生產超領",
      "opcode_4270": "调帐出库",
      "opcode_4280": "一般领用",
      "preN_4010": "盤點單號",
      "preN_4020": "盤點單號",
      "preN_4030": "盤點單號",
      "preN_4110": "来源单号",
      "preN_4120": "進料單號",
      "preN_4130": "来源单号",
      "preN_4220": "来源单号",
      "preN_4230": "来源单号",
      "preN_4141": "来源单号",
      "preN_4241": "来源单号",
      "preN_4242": "来源单号",
      "preN_4143": "来源单号",
      "preN_4142": "来源单号",
      "preN_4243": "来源单号",
      "preN_4210": "来源单号",
      "preN_4299": "来源单号",
      "preN_4150": "来源单号",
      "preN_4170": "来源单号",
      "preN_4160": "来源单号",
      "preN_4260": "来源单号",
      "preN_4270": "来源单号",
      "operN_4010": "盤點單號",
      "operN_4020": "盤點單號",
      "operN_4030": "盤點單號",
      "operN_4110": "單號",
      "operN_4120": "採購單號",
      "operN_4130": "銷售單號",
      "operN_4220": "銷售單號",
      "operN_4230": "退出單號",
      "operN_4141": "對倉",
      "operN_4241": "對倉",
      "operN_4242": "借出單號",
      "operN_4143": "借出單號",
      "operN_4142": "借入單號",
      "operN_4243": "借入單號",
      "operN_4210": "領用單號",
      "operN_4299": "領用單號",
      "operN_4160": "借出單號",
      "operN_4260": "領用單號",
      "whID_4010": "盤點單號",
      "whID_4020": "盤點單號",
      "whID_4030": "盤點單號",
      "whID_4110": "單號",
      "whID_4120": "採購單號",
      "whID_4130": "銷售單號",
      "whID_4220": "銷售單號",
      "whID_4230": "退出單號",
      "whID_4141": "本倉",
      "whID_4241": "本倉",
      "whID_4242": "倉別",
      "whID_4143": "倉別",
      "whID_4142": "倉別",
      "whID_4243": "倉別",
      "whID_4160": "倉別",
      "whID_4260": "倉別",
      "dueday_4010": "入庫日期",
      "dueday_4020": "入庫日期",
      "dueday_4030": "入庫日期",
      "dueday_4110": "入庫日期",
      "dueday_4120": "入庫日期",
      "dueday_4130": "入庫日期",
      "dueday_4141": "預計入庫日期",
      "dueday_4220": "預計出庫日期",
      "dueday_4230": "預計出庫日期",
      "dueday_4241": "預計出庫日期",
      "dueday_4242": "預計出庫日期",
      "dueday_4143": "預計歸還日期",
      "dueday_4142": "預計入庫日期",
      "dueday_4243": "預計歸還日期",
      "dueday_4210": "領用日期",
      "dueday_4299": "領用日期",
      "dueday_4160": "預計出庫日期",
      "dueday_4260": "領用日期",
      "batchno_4010": "入庫批號",
      "batchno_4020": "入庫批號",
      "batchno_4030": "入庫批號",
      "batchno_4110": "入庫批號",
      "batchno_4120": "入庫批號",
      "batchno_4130": "入庫批號",
      "batchno_4141": "入庫批號",
      "batchno_4143": "入庫批號",
      "batchno_4142": "入庫批號",
      "batchno_4243": "出庫批號",
      "batchno_4220": "出庫批號",
      "batchno_4242": "出庫批號",
      "batchno_4230": "出庫批號",
      "batchno_4241": "出庫批號",
      "batchno_4210": "出庫批號",
      "batchno_4299": "出庫批號",
      "batchno_4160": "入庫批號",
      "batchno_4260": "出庫批號",
      "dealID_4120": "廠商ID",
      "dealID_4110": "廠商ID",
      "dealID_4220": "客戶ID",
      "dealID_4130": "客戶ID",
      "dealID_4230": "廠商ID",
      "dealID_4241": "出庫批號",
      "dealID_4210": "廠商ID",
      "dealID_4299": "廠商ID",
      "dealID_4260": "廠商ID",
      "dealID_4142": "廠商ID",
      "dealID_4242": "客戶ID",
      "dealNM_4110": "廠商簡稱",
      "dealNM_4120": "廠商簡稱",
      "dealNM_4220": "客戶簡稱",
      "dealNM_4130": "客戶簡稱",
      "dealNM_4230": "廠商簡稱",
      "dealNM_4241": "出庫批號",
      "dealNM_4210": "廠商簡稱",
      "dealNM_4299": "廠商簡稱",
      "dealNM_4142": "廠商簡稱",
      "dealNM_4242": "客戶簡稱",
      "dealNM_4260": "廠商ID",
      "txn_confirm": "異動提交確認",
      "txn_confirm_msg": "請先確認資料無誤，再異動",
      "jobN": "作業單號",
      "lifeF": "歷程",
      "opcode": "作業單別",
      "plantxnqty": "規劃異動量",
      "realtxnqty": "實際異動量",
      "planqty": "計畫數量",
      "realqty": "實際數量",
      "totalqty": "總數",
      "planN": "来源单号碼",
      "updtT": "更新時間",
      "whID": "倉別",
      "pdtNM": "品名",
      "bal": "帳差",
    },
    "plan-in": {
      "pageHeader": "库房入库作业",
    },
    "plan-out": {
      "pageHeader": "库房领用作业",
    },
    "stock-search": {
      "pageHeader": "库存状况查询",
      "searchPN": "料号查询",
      "searchWh": "各仓查询",
      "whDetail": "各倉明細",
      "changeDetail": "異動明細",
      "borrow": "入库数",
      "inTransit": "在途量",
      "loan": "出库数",
      "lockqty": "封控数",
      "onhandqty": "安全库存数",
      "planin": "入库规划",
      "planout": "出库规划",
      "posted": "已过帐",
      "scanin": "点料入库",
      "scanout": "检料出库",
      "timeSeries": "时间序",
      "total": "储位",
      "totalIn": "总入库",
      "totalOut": "总出库",
      "tqty": "库存数",
    },
    "inv-history": {
      "pageHeader": "每月库存纪录",
      "beginCBM": "期初\n(CBM)",
      "endCBM": "期末\n(CBM)",
      "h": "厚",
      "inCBM": "進\n(CBM)",
      "l": "长",
      "level": "等级",
      "suppalias": "供应商",
      "unit": "计量单位",
      "w": "宽",
      "outCBM": "銷\n(CBM)",
      "useCBM": "耗\n(CBM)",
      "stdsale": "單價",
      "beginPrice": "期初\n金額",
      "usePrice": "耗用\n金額",
      "inPrice": "進貨\n金額",
      "outPrice": "銷貨\n金額",
      "endPrice": "期末\n金額",
      "proD": "進料日期",
    },
    "pro-instock": {
      "pageHeader": "申请领用作业",
      "lifeF_T": "已规划",
      "wmsopUUID": "库储作业单号",
      "inchargeID": "负责人员",
    },
  },
  ADM: {
    "oem-manage": {
      "type": "",
      "itemID": "",
      "oem_item_manage": "",
      "oem_item": "",
      "type_A": "",
      "type_B": "",
      "type_C": "",
      "oem_supp_manage": "",
      "oem_supp": "",
      "vendorcode": "",
      "pfNM": "",
      "oemitemNM": "",
    },
    "function-params-manage": {
      "pageHeader": "系统参数设定",
      "searchPlaceholder": "查询ID，单位，代号，功能码，层级名称",
      "unitSetting": "计量单位设定",
      "opUnitSetting": "操作单位设定",
      "currencySetting": "交易币别设定",
      "functions": "推播群组设定",
      "pushlvl": "推播层级设定",
      "notify": "推播事件设定",
      "unit": "计量单位",
      "unitNM1": "单位名称",
      "type": "计量型态",
      "category": "计量类别",
      "countType": "计数型",
      "quantitative": "计量型",
      "count": "計數",
      "weight": "重量",
      "length": "长度",
      "capacity": "容积",
      "area": "面积",
      "volume": "体积",
      "opunit": "操作单位",
      "sort": "排序",
      "currencyitem": "币别代号",
      "itemNM": "币别名称",
      "currency": "交易币别",
      "ismycurrency": "是否本币",
      "funcode": "组织功能码",
      "funNM": "功能名称",
      "function": "推播群组",
      "lvlcode": "层级码",
      "lvlNM": "层级名称",
      "pushlayer": "推播层级",
      "team": "1(班组)",
      "class": "2(课级)",
      "management": "3(理级)",
      "total": "4(总级)",
      "eventID": "事件ID",
      "eventNM": "事件名称",
      "rptcode": "提报类别",
      "pusher": "发报系统",
      "situF": "警讯灯号",
      "audience": "推播受群",
      "startlvl": "起报层级",
      "setlvl": "推播级数",
      "cruisebot": "监控程式",
      "msgmodel": "推播讯息模版",
      "pushEvent": "推播事件",
      "rptcode_A0": "人员提报",
      "rptcode_B0": "系统提报(WOS)",
      "rptcode_C0": "设备提报",
      "rptcode_D0": "管理提报_远端",
      "rptcode_E0": "管理提报_云端",
      "situF_Y": "黄",
      "situF_R": "红",
      "startlvl_0": "全员",
      "startlvl_1": "班组干部",
      "startlvl_2": "课级干部",
      "startlvl_3": "理级主管",
      "startlvl_4": "总级主管",
      "add_string": "新增字串",
      "add_number": "新增数字",
      "add_point": "新增小数点",
      "1_decimal_place": "小数点后1位",
      "2_decimal_place": "小数点后2位",
    },
    "procedure-manage": {
      "pageHeader": "制程规划维护",
      "pccID": "成本中心ID",
      "pccNM": "成本中心名称",
      "pf": "制程",
      "pfID": "制程ID",
      "pfNM": "制程名称",
      "pwc": "工作中心",
      "pwcID": "工作中心ID",
      "pwcNM": "工作中心名称",
      "tabCost": "成本中心设定",
      "tabFlow": "制程主档设定",
      "tabWork": "工作中心设定",
    },
    "user-manage": {
      "dashboard": "数位生产看板",
      "abc-board": "异常管理看板",
      "BDM": "物料资料管理",
      "SDM": "系统资料管理",
      "TDM": "厂区资料管理",
      "EDM": "模具资料管理",
      "WMS": "库存管理系统",
      "OMS": "销售管理系统",
      "PMS": "采购管理系统",
      "WOC": "生产控制",
      "MC": "物料作业",
      "QC": "质控作业",
      "TK": "生产看板",
      "wpcSearch": "途单查询",
      "dispachManage": "派单管理",
      "ppidp": "成品绑定",
      "partsBinding": "部品扫码",
      "account": "员工帐号",
      "pwd": "密码",
      "role": "角色权限",
      "roleID": "角色ID",
      "roleNM": "角色名称",
      "systemAuth": "系统登入权限",
      "autoReport": "接收自动报表",
      "APP": "行动APP",
      "PDA": "PDA权限",
      "dealmgmt": "对象授权分类",
      "pdtmgmt": "物料授权分类",
      "whmgmt": "仓库授权分类",
      "cardID": "卡勤识别码",
      "function": "奖金类型",
      "function_A": "专职业务员",
      "function_B": "非专职业务员",
      "function_C": "兆科全员",
      "level": "推播层级",
      "mail": "邮址",
      "pageHeader": "员工资料维护",
      "peopleNM": "员工姓名",
      "personalID": "身份证号",
      "postID": "职称",
      "proxyNM": "联络人姓名",
      "proxytel": "联络人电话",
      "staffAuth": "角色权限设定",
      "staffData": "员工资料管理",
      "staffID": "员工工号",
      "tel": "电话",
    },
    "trading-pattern": {
      "OMS": "订单系统",
      "PMS": "采购系统",
      "adda": "条款注记",
      "addb": "加扣款项",
      "addc": "预付作业",
      "addd": "付款方式",
      "adde": "贸易条件",
      "addf": "退货作业",
      "addg": "取消订量",
      "addh": "付款条件",
      "addi": "收款条件",
      "additem": "项目ID",
      "addr": "联络住址",
      "addtype": "交易条件",
      "contact": "联络人",
      "contactitem": "联络窗口代号",
      "department": "部门",
      "itemNM": "联络窗口名称",
      "mail": "邮址",
      "mobile": "行动电话",
      "pageHeader": "项目字串设定",
      "tabAdded": "附加项目设定",
      "tabWindow": "对外窗口设定",
      "tabPMSAdded": "采购附加条件",
      "tabOMSAdded": "销货附加条件",
      "tel": "联络电话",
    },
    "data-auth-manage": {
      "mgmtitem": "分类项目",
      "pageHeader": "功能授权设定",
      "tabCust": "客资授权分组",
      "tabPdt": "物料授权分组",
      "tabSupp": "厂资授权分组",
      "tabWh": "库房授权分组",
    },
    "category-manage": {
      "mgmtitem": "项目ID",
      "pageHeader": "查询分类设定",
      "tabCust": "客户查询分类",
      "tabQuery": "物料查询分类",
      "tabSupp": "厂商查询分类",
      "tabMtype": "物料机种分类",
    },
    "warehouse-manage": {
      "cancelClose": "取消关帐",
      "close": "关帐",
      "isClose": "是否关帐",
      "mgmtcat": "库房授权",
      "pageHeader": " 库房规划",
      "pdtClass_0": "原料/耗材",
      "pdtClass_1": "物料",
      "pdtClass_2": "非標零件",
      "pdtClass_3": "通用零件",
      "pdtClass_4": "半成品",
      "pdtClass_5": "成品",
      "pdtclass": "料阶",
      "ps": "车间",
      "tabWareHouse": "仓别设定",
      "whID": "仓别ID",
      "whNM": "仓别名称",
      "psNM": "車間名稱",
    },
    "string-manage": {
      "pageHeader": "功能字串管理",
      "tabPause": "停工项目",
      "tabOkItem": "良品项目",
      "tabNgItem": "不良品项目",
      "tabDri": "责任单位",
      "tabOffLine": "下线项目",
      "driItemID": "单位ID",
      "driItemNM": "单位名称",
    },
    "client": {
      "groupalias": "集团简称",
      "location": "市场区域",
      "account": "银行帐号",
      "addCustomerInformation": "新增客户资料",
      "addr": "公司地址",
      "address": "连络住址",
      "bankAddrOS": "银行地址",
      "bankCodeOS": "分行代号",
      "branchOS": "分行名称",
      "custfax": "客户传真",
      "classificationOfRestrictions": "限权分类",
      "contact": "联络人",
      "country": "国别",
      "custID": "客户ID",
      "custNM": "客户名称",
      "custalias": "客户简称",
      "customerBasicInformation": "客户基本资料",
      "customerContactInformation": "客户联络资料",
      "department": "部门",
      "domesticBankName": "银行名称",
      "domestic_OverseasBankInformation": "开票资料",
      "fullRangeOfFurniture": "全系列家具",
      "item": "10条/页",
      "itemNM": "窗口功能",
      "itemno": "项序",
      "livingRoomFurniture": "客厅家具",
      "mail": "邮件",
      "maincurrency": "交易币别",
      "maxcredit": "结算方式",
      "mobile": "公司电话",
      "modifyContactWindowInformation": "修改联络窗口资料",
      "modifyCustomerInformation": "修改客户资料",
      "no": "否",
      "ordertype": "订货类别",
      "overseasBankName": "海外银行名称",
      "pageHeader": "客户资料维护",
      "pageHeader2": "客户资料管理",
      "payterm": "付款条件",
      "postID": "职务",

      "showTotal": "共 1 项 ， 正在显示第1到1项",
      "swiftCode": "SWIFT CODE",
      "taxID": "统一编号",
      "tel": "移动电话",
      "username": "户名",
      "yes": "是",
    },
    "company": {
      "manufacturerBasicInformation": "分包商基本资料",
      "manufacturerContactInformation": "分包商联络资料",
      "pageHeader": "分包商资料维护",
      "suppID": "分包商ID",
      "suppNM": "分包商名称",
      "suppfax": "公司传真",
      "suppalias": "分包商简称",
      "groupalias": "集团简称",
      "location": "市场区域",
      "account": "银行帐号",
      "addCustomerInformation": "新增客戶資料",
      "addr": "公司地址",
      "address": "連絡住址",
      "bankAddrOS": "银行地址",
      "bankCodeOS": "分行代号",
      "branchOS": "分行名称",
      "classificationOfRestrictions": "限权分类",
      "contact": "联络人",
      "country": "国别",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "部门",
      "domesticBankName": "银行名称",
      "domestic_OverseasBankInformation": "银行资料",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10条/页",
      "itemNM": "窗口功能",
      "itemno": "项序",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "邮件",
      "maincurrency": "交易币别",
      "maxcredit": "结算方式",
      "mobile": "公司电话",
      "modifyContactWindowInformation": "編輯聯絡窗口資料",
      "modifyCustomerInformation": "編輯客戶資料",
      "no": "否",
      "ordertype": "訂貨類別",
      "overseasBankName": "海外银行名称",
      "payterm": "付款条件",
      "postID": "职务",
      "showTotal": "共 1 项 ， 正在显示第1到1项",
      "swiftCode": "SWIFT CODE",
      "taxID": "统一编号",

      "tel": "公司电话",
      "username": "户名",
      "yes": "是",
    },
    "supp": {
      "manufacturerBasicInformation": "供应商基本资料",
      "manufacturerContactInformation": "供应商联络资料",
      "pageHeader": "供应商资料维护",
      "suppID": "供应商ID",
      "suppNM": "供应商名称",
      "suppfax": "公司传真",
      "suppalias": "供应商简称",
      "groupalias": "集团简称",
      "location": "市场区域",
      "account": "银行帐号",
      "addCustomerInformation": "新增客戶資料",
      "addr": "公司地址",
      "address": "連絡住址",
      "bankAddrOS": "银行地址",
      "bankCodeOS": "分行代号",
      "branchOS": "分行名称",
      "classificationOfRestrictions": "限权分类",
      "contact": "联络人",
      "country": "国别",
      "custID": "客戶ID",
      "custNM": "客戶名稱",
      "custalias": "客戶簡稱",
      "customerBasicInformation": "客戶基本資料",
      "customerContactInformation": "客戶聯絡資料",
      "department": "部门",
      "domesticBankName": "银行名称",
      "domestic_OverseasBankInformation": "银行资料",
      "fullRangeOfFurniture": "全系列傢俱",
      "item": "10条/页",
      "itemNM": "窗口功能",
      "itemno": "项序",
      "livingRoomFurniture": "客廳傢俱",
      "mail": "邮件",
      "maincurrency": "交易币别",
      "maxcredit": "结算方式",
      "mobile": "公司电话",
      "modifyContactWindowInformation": "編輯聯絡窗口資料",
      "modifyCustomerInformation": "編輯客戶資料",
      "no": "否",
      "ordertype": "訂貨類別",
      "overseasBankName": "海外银行名称",
      "payterm": "付款条件",
      "postID": "职务",
      "showTotal": "共 1 项 ， 正在显示第1到1项",
      "swiftCode": "SWIFT CODE",
      "taxID": "统一编号",

      "tel": "公司电话",
      "username": "户名",
      "yes": "是",
    },
    "edm": {
      "alertspeed": "产速报警",
      "alertstop": "逾停报警",
      "warnyield": "良率黄灯",
      "alertyield": "良率红灯",
      "attribute": "物料属性",
      "bom": "物料清单维护",
      "errorPleaseFull": "请填写完整资料",
      "ieUUID": "汇算单号",
      "isrework": "重工",
      "level": "料阶",
      "nextSN": "次途程号",
      "pPN": "父件料号",
      "pageHeader": "技术资料维护",
      "pdtclass": "商品类别",
      "pfNM": "制程",
      "planhead": "计划人数",
      "ppdtNM": "父件品名",
      "productionScheduleDetails": "途程明细",
      "pwcNM": "工作中心",
      "qty": "组数",
      "stdlt": "备产时间",
      "stdmh": "标准机时",
      "stdwh": "标准人时",
      "batchbom": "批号满包数",
      "unitNM": "计量单位",
      "updtT": "更新时间",
      "ver": "版码",
      "wpcManage": "生产途程维护",
      "wpc": "生产途程",
      "wpcSN": "途程号",
      "wpcSetting": "途程号设定",
      "wpcset": "途程组",
      "wpcsetNM": "途程组名称",
      "whID": "入库仓别",
      "alternative_wpc": "替代途程",
      "main_wpc": "主途程",
      "edit_all_wpc": "途程全局编辑",
      "Pfc": "工程文件版控",
      "doc": "标准文件发行",
      "ECN": "工程变更通知",
      "pdftype": "文件类别",
      "docUpload": "文件上传",
      "Upload": "上传",
      "docName": "文件名称",
      "docVer": "文件版本",
    },
    "pn-manage": {
      "basicProduct": "检测项目",
      "dealPNSearch": "交易料号查询",
      "boxH": "包装尺寸 (高)",
      "boxL": "包装尺寸 (长)",
      "boxW": "包装尺寸 (宽)",
      "dealID": "对象ID",
      "dealNM": "交易品名",
      "dealPN": "交易料号/SKU",
      "dealalias": "对象简称",
      "dealpid": "条码",
      "idkw": "包装条码关字",
      "inititalcost": "期初程本",
      "isfocus": "是否关注",
      "isvalid": "是否有效",
      "maxcost": "库存金额上限",
      "maxstock": "库存上限",
      "minstock": "库存下限",
      "moq": "最小起购量",
      "newPN": "新版料号",
      "nextpacksn": "外层包装层数",
      "opunit": "操作单位",
      "pack": "包装单位",
      "packsn": "包装层数",
      "packtype": "包装用途",
      "pageHeader": "检测项目主档",
      "palH": "栈板 (高)",
      "palL": "栈板 (长)",
      "palW": "栈板 (宽)",
      "pbom": "包装内层组数",
      "pdtH": "产品尺寸 (高)",
      "pdtL": "产品尺寸 (长)",
      "pdtW": "产品尺寸 (宽)",
      "punit": "内层使用单位",
      "purchasecost": "本期进货成本",
      "purchaseqty": "本期进料量",
      "replacePN": "替代料号",
      "safestock": "安全库存",
      "salecurrency": "标价币别",
      "setbatch": "批号帐管",
      "setidm": "条码设定",
      "setpack": "包装设定",
      "settlemonth": "变动结算月份",
      "shipV": "包装材积CUFT",
      "shipW": "运装重量",
      "sizeunit": "尺寸单位",
      "stdcost": "标准成本",
      "stdsale": "标准价格",
      "stdstock": "标准库存",
      "stocklife": "库存周期",
      "tabCost": "标准成本设定",
      "tabDeal": "交易料号设定",
      "tabExtra": "产品尺寸设定",
      "tabIdkw": "生产绑定设定",
      "tabPack": "包装绑定设定",
      "tabPrice": "标准价格设定",
      "tabStock": "库存条件设定",
      "unitwt": "计数单重",
      "varcost": "变动成本",
      "ver": "包装标准版本",
      "weightunit": "重量单位",
      "mtype": "机种",
    },
    "mold": {
      "pageHeader": "模具资料维护",
      "mold": "模具资料",
      "assetkind": "模具类型",
      "assetID": "模具ID",
      "assetNM": "资产名称",
      "pfID": "制程ID",
      "moldtype": "共模",
      "cavity": "实体穴数",
      "stdcavity": "标准穴数",
      "validcavity": "有效穴数",
      "wsID": "工站",
      "property": "财产归属",
      "custID": "客户ID",
      "company": "公司",
      "gatesystem": "浇口型式",
      "moldlife": "设计寿命",
      "draw": "模具图面",
      "pdtpic": "成品照片",
      "malepic": "公模照片",
      "femalepic": "母模照片",
      "whloepic": "合模照片",
      "advance": "模具进阶资料",
      "length": "模具总长",
      "width": "模具总宽",
      "height": "模具总高",
      "weight": "模具重量",
      "ismrplan": "",
      "ismrcycle": "",
      "setmrcycle": "",
      "ismrperiod": "",
      "setmrperiod": "",
      "premrT": "",
      "premrC": "",
      "nextmrT": "",
      "nextmrC": "",
      "openC": "开帐模次",
      "openT": "开帐日期",
      "cummoldC": "最后累计生产模数",
      "precummoldC": "上次累计生产模数",
      "status": "状况灯号",
      "note": "模具注记",
    },
    "plant-manage": {
      "pageHeader": "公司别主档设定",
      "plantID": "公司别",
      "deptID": "部门别",
      "postID": "职称ID",
      "level": "推播层级",
      "push1": "一级推播时距(分)",
      "push2": "二级推播时距(分)",
      "push3": "三级推播时距(分)",
      "tabDept": "部门主档",
      "tabPlant": "公司资料",
      "tabPost": "职称主档",
    },
    "production-line-manage": {
      "MC": "物料作业",
      "QC": "质控作业",
      "TK": "生产看板",
      "WOC": "生产控制",
      "auth_setting": "权限设置",
      "batch": "批号管理",
      "dispachManage": "派单管理",
      "mold": "模具管理",
      "oem": "委外报工",
      "pageHeader": " 产线规划维护",
      "partsBinding": "部品扫码",
      "pcc": "成本中心",
      "pf": "制程",
      "ppidp": "成品绑定",
      "ps": "车间",
      "psID": "车间ID",
      "psNM": "车间名称",
      "pwc": "工作中心",
      "tabLine": "工线资料设定",
      "tabPS": "车间资料设定",
      "tabStation": "工站资料设定",
      "tbHeader_PS": "工站资料",
      "tbHeader_Station": "工线资料",
      "whm": "工时管理",
      "wlID": "工线ID",
      "wlNM": "工线名称",
      "wpcSearch": "途单查询",
      "ws": "工站",
      "wsID": "工站ID",
      "wsNM": "工站名称",
    },
  },
  APS: {
    "util": {
      "opcode": "作業單別",
      "opcode_3310": "正常工單",
      "opcode_3320": "重工工單",
      "opcode_3330": "試產工單",
      "opcode_3340": "工程工單",
      "duestart": "預計完成起日",
      "dueend": "預計完成迄日",
      "realqty": "實際數量",
      "detail": "明細",
    },
    "aps-manage": {
      "pageHeader": "",
      "wo": "",
      "tabWo": "",
      "mo": "",
      "jobN": "",
      "tabMo": "",
      "orderN": "",
      "ETDstart": "",
      "ETDend": "",
      "issueID": "",
      "issueday": "",
    },
  },
  PMS: {
    "pageHeader": "采购管理系统",
    "util": {
      "opcode_2010": "询价计划",
      "opcode_2210": "国内请购",
      "opcode_2220": "国外请购",
      "opcode_2230": "委外申请",
      "opcode_2310": "国内采购",
      "opcode_2320": "国外采购",
      "opcode_2330": "委外加工",
      "opcode_2340": "委外检验",
      "opcode_2410": "国内进料",
      "opcode_2420": "国外进料",
      "opcode_2430": "委外进料",
      "opcode_2610": "库房退出",
      "opcode_2620": "品保退出",
      "opcode_2710": "对帐单",
      "opcode_2810": "应付冲帐",
      "opcode_4120": "验收单",
      "opcode_4230": "验退单",
      "plan": "询价单",
      "req": "请购单",
      "pro": "采购单",
      "instock": "进料单",
      "gostock": "进料检验单",
      "return": "进料退出",
      "pay": "应付账款查询",
      "ap": "对帐单",
      "strike": "平账记录",
      "advanceSearch": "进阶查询",
      "searchField": "搜索栏位",
      "removeSearch": "清除搜索记录",
      "setAdvanced": "设定进阶筛选条件",
      "exportExcel": "汇出Excel",
      "table_close": "强制结令",
      "table_delete": "删除",
      "table_edit": "编辑",
      "table_more": "查看更多",
      "table_operate": "操作",
      "table_suppCheckNum": "厂商确认数量",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "header_cancel_create": "取消据此创建",
      "take": "引用",
      "throw": "抛转",
      "accord": "据此",
      "create": "创建",
      "submit": "确定",
      "cancel": "取消",
      "sysMsg": "系统讯息",
      "window": "窗口",
      "placeholder_select": "请选择",
      "placeholder_input": "请输入",
      "contact": "联络人",
      "tel": "电话",
      "N22": "请购单号",
      "N23": "采购单号",
      "N24": "进料单号",
      "N27": "对帐单号",
      "N28": "冲帐单号",
      "outN": "验退单号",
      "itemno": "项序",
      "itemno_total": "总项数",
      "itemsum": "单项金额(未税)",
      "UploadSignature": "上传签呈",
      "UploadSign": "上传回签",
      "delete_confirm": "删除确认",
      "reqSearch": "请购纪录查询",
      "proSearch": "采购纪录查询",
      "instockSearch": "进料纪录查询",
      "stockSearch": "验收纪录查询",
      "strikeSearch": "冲帐纪录查询",
      "apSearch": "对帐资料查询",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "get_prev_data_suc": "取得上一笔资料成功",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_next_data_err": "取得下一笔资料失败",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "opcode_placeholder": "请选择单别",
      "currencytype_placeholder": "请选择币别",
      "suppID_placeholder": "请选择厂商",
      "taxtype_placeholder": "请选择税别",
      "taxrate_placeholder": "请输入税率",
      "PN_placeholder": "请选择料号",
      "utype_placeholder": "请选择单位类别",
      "tel_placeholder": "请选择联络人",
      "opcode": "单别",
      "N": "单号",
      "currencytype": "币别",
      "taxtype": "交易税别",
      "taxrate": "税率",
      "suppID": "分包商/供应商ID",
      "suppalias": "分包商/供应商名称",
      "suppNM": "分包商/供应商名称",
      "exchangerate": "汇率",
      "exchangeD": "汇率日期",
      "EDT": "预计交货日",
      "demansDate": "需求日期",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "createID": "创建人员",
      "createT": "创建日期",
      "confirmID": "复核人员",
      "confirmT": "复核日期",
      "signT": "签回日期",
      "updtID": "更新人员",
      "updtT": "更新时间",
      "batchno": "批号",
      "batchdate": "批号日期",
      "stockinT": "移转时间",
      "PN": "料号",
      "pdtNM": "检测项目",
      "pdtspec": "测试方法/标准",
      "idmtype": "过帐型态",
      "drawno": "图号",
      "pbom": "组合数量",
      "utype": "单位类别",
      "punit": "使用单位",
      "unit": "计量单位",
      "opunit": "操作单位",
      "unitprice": "采购单价",
      "localprice": "本币单价",
      "tax": "总税额",
      "balqty": "剩余数量",
    },
    "plan": {
      "pageHeader": "询价计划",
      "hd_delete_confirm_text": "",
      "demandqty": "",
      "tb": "",
      "tb_drawer_title": "",
      "rec_drawer_title": "",
    },
    "req": {
      "demandDate": "需求日期",
      "pageHeader": "一般请购作业",
      "create_header": "新建请购主档",
      "edit_header": "编辑请购主档",
      "hd_note": "请购注记",
      "driID": "负责请购",
      "punit": "请购单位",
      "unitprice": "请购单价(未税)",
      "demandqty": "请购数量",
      // "demandDate": "需求日期",
      "itemsum": "单项金额(未税)",
      "itemsumtax": "单项税额",
      "itemtotal": "未税总金额",
      "planSearch": "询价纪录查询",
      "local_subtotal": "本币含税总金额",
      "subtotal": "单项含税小计",
      "total": "含税总金额(交易币别)",
      "lifeF_0": "发布",
      "lifeF_2": "请购覆核",
      "lifeF_7": "审核送签完成",
      "lifeF_T": "结案",
      "del_hd_confirm_text": "请先确认要删除的请购单资料无误，再进行删除",
      "audit_start": "审核正式送签",
      "audit_finish": "已完成审查",
      "reqCheck": "请购单覆核",
      "cancel_reqCheck": "取消请购覆核",
      "check_confirm": "请购覆核确认",
      "check_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "cancel_check_confirm": "取消请购覆核确认",
      "cancel_check_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "reqCheck_lifeF_0_okText": "确定覆核",
      "reqCheck_lifeF_2_okText": "取消覆核",
      "audit_confirm": "审查签核确认",
      "audit_confirm_text": "审查签核后此请购单将无法编辑，确定要完成审查吗",
      "audit_okText": "确定审查",
      "driID_placeholder": "请选择请购人员",
      "demandqty_placeholder": "请填写请购数量",
      "unitprice_placeholder": "请填写未税单价",
      "tb_delete_confirm": "请购明细删除确认",
      "tb_title": "请购明细",
      "create_tb_title": "新增请购明细",
      "edit_tb_title": "编辑请购明细",
      "throw_modal_submit": "确定抛转【采购单】",
    },
    "pro": {
      "pageHeader": "一般采购作业",
      "create_header": "新建采购主档",
      "edit_header": "编辑采购主档",
      "hd_drawer_title": "采购订单主档",
      "driID": "负责采购",
      "EDT": "预计交货日",
      "startEDT": "预计交货日(起)",
      "endEDT": "预计交货日(迄)",
      "sampleID": "抽样人员",
      "supp_history": "厂商交易历史查询",
      "trade": "贸易条件",
      "dealaddr": "厂商地址",
      "contact": "厂商交货窗口",
      "dealcontact": "厂商业务窗口",
      "payment": "付款方式",
      "sumtotal": "未税总金额",
      "local_subtotal": "本币含税总金额",
      "total": "含税总金额",
      "local_total": "含税总金额(交易币别)",
      "note": "采购注记",
      "demandqty": "采购数量",
      "punit": "采购单位",
      "unitprice": "采购单价",
      "confirmqty": "厂商确认数量",
      "dealN": "厂商单号",
      "cancelqty": "取消数量",
      "returnqty": "退货数量",
      "itemsum": "未税单项金额",
      "itemsumtax": "单项税额",
      "subtotal": "含税小计",
      "approveT": "核准日期",
      "charge": "总加扣款项",
      "ReviewProcess": "审核流程(3)",
      "tb_drawer_title": "采购订单明细",
      "lifeF_0": "发布",
      "lifeF_1": "外测覆核",
      "lifeF_2": "外测完成",
      "lifeF_7": "厂商签回",
      "lifeF_T": "结案",
      "del_hd_confirm_text": "请先确认要删除的采购订单资料无误，再进行删除",
      "hd_close_confirm_text": "请先确认要结令的采购单资料无误，再进行强制结令",
      "proT": "采购日期",
      "dealN_placeholder": "请输入厂商单号",
      "payment_placeholder": "请选择付款方式",
      "trade_placeholder": "请选择贸易条件",
      "dealcontact_placeholder": "选择厂商业务窗口",
      "contact_placeholder": "选择厂商交货窗口",
      "unitprice_placeholder": "请填写未税单价",
      "demandqty_placeholder": "请填写采购数量",
      "note_placeholder": "请输入采购注记",
      "statement_placeholder": "请输入项目说明",
      "amount_placeholder": "请输入金额",
      "proCheck": "采购覆核(1)",
      "cancel_proCheck": "取消覆核",
      "suppCheck": "厂商签回(2)",
      "cancel_suppCheck": "取消签回",
      "proCheck_confirm": "外测覆核确认",
      "proCheck_confirm_text": "请先确认要覆核的资料无误再进行覆核",
      "cancel_proCheck_confirm_text": "请先确认要取消覆核的资料无误再进行取消覆核",
      "suppCheck_confirm": "厂商签回确认",
      "suppCheck_confirm_text": "",
      "cancel_suppCheck_confirm": "取消厂商签回确认",
      "cancel_completed_confirm_text": "请确认是否取消外测完成",
      "tb": "采购明细",
      "adda": "条款注记",
      "adda_delete_confirm_text": "请先确认要删除的条款资料无误，再进行删除",
      "addb": "加扣款项",
      "addb_delete_confirm_text": "请先确认要删除的款项资料无误，再进行删除",
      "addc": "预付作业",
      "addc_delete_confirm_text": "请先确认要删除的预付作业无误，再进行删除",
      "statement": "项目说明",
      "amount": "未税金额",
      "selectstatement": "请选择加扣款项",
      "totalStart": "最小值",
      "totalEnd": "最大值",
      "deal_history": "產品交易歷史查詢",
      "transferqty": "已拋轉數量",
      "throwqty": "本次拋轉數量",
      "message_transfer_small_thsn_0": "拋轉數量不可小於0",
      "exit_Disposal": "退出处置",
    },
    "outsourcing": {
      "pageHeader": "外包检测单",
      "tb": "检测明细",
    },
    "instock": {
      "pageHeader": "采购进货作业",
      "create_header": "新建进料主档",
      "edit_header": "编辑进料主档",
      "hd_drawer_title": "采购进货主档",
      "contact": "聯絡人",
      "tel": "聯絡人電話",
      "sampleID": "抽样人员",
      "sampleT": "抽样日期",
      "note": "进料注记",
      "del_hd_confirm_text": "请先确认要删除的进料单资料无误，再进行删除",
      "tb": "进料明细",
      "goqty": "验收数量",
      "rejectqty": "验退数量",
      "indqty": "暂收数量",
      "inspectID": "进检人员",
      "inspectT": "完验时间",
      "qalID": "品判人员",
      "qalT": "放行时间",
      "lifeF_0": "发布",
      "lifeF_2": "进料覆核",
      "lifeF_3": "IQC抽验",
      "lifeF_4": "IQC完验",
      "lifeF_7": "品保覆核",
      "lifeF_T": "結案",
      "IQCstart": "进检抽样",
      "QAcheck": "品保放行",
      "punit": "进料单位",
      "inbatchno": "進料批號",
      "judgeT": "品保判定日期",
      "instockCheck_confirm": "进料覆核确认",
      "instockCheck_confirm_text": "请先确认要进料覆核的资料无误，再进行进料覆核",
      "cancel_instockCheck_confirm_text": "请先确认要取消进料覆核的资料无误，再进行取消进料覆核",
      "IQCcheck_confirm": "进检抽样确认",
      "IQCcheck_confirm_text": "请先确认要进检抽样的资料无误，再进行进检抽样",
      "cancel_IQCcheck_confirm_text": "请先确认要取消进检抽样的资料无误，再进行取消进检抽样",
      "IQCfinish_confirm": "IQC完验确认",
      "IQCfinish_confirm_text": "请先确认要IQC完验的资料无误，再进行IQC完验",
      "cancel_IQCfinish_confirm_text": "请先确认要取消IQC完验的资料无误，再进行取消IQC完验",
      "QAcheck_confirm": "品保放行确认",
      "QAcheck_confirm_text": "请先确认要品保放行的资料无误，再进行品保放行",
      "cancel_QAcheck_confirm_text": "请先确认要取消品保放行的资料无误，再进行取消品保放行",
      "QAQTYcheck": "进料明细[品保判退数量]+[品保放行数量]要等于[进料数量]",
      "throwStock_message": "请确认是否抛转",
      "dealN": "厂商单号",
      "takeqty": "引用數量",
      "qty": "引用数量",
      "unitprice_placeholder": "请填写未税单价",
      "Tooltip_disable_take_balqty_0": "剩余数量为0不可引用",
      "message_take_before_choose": "请先选择引用再输入引用数量",
      "message_transfer_small_thsn_0": "抛转数量不可小于0",
      "message_take_same_detail": "不可引用相同明细",
    },
    "gostock": {
      "pageHeader": "进料检验查询",
      "4120_header": "验收主档",
      "4230_header": "",
      "total": "移转总量",
      "planqty": "移转数量",
      "jobN": "来源单号",
      "batchno": "批号",
      "batchno_placeholder": "请输入批号",
      "note": "注记",
      "batchdate": "批号日期",
      "tb": "验收明细",
      "punit": "使用单位",
      "S": "人工計數",
      "D": "條碼計數",
      "M": "混合計數",
      "lifeF_0": "發佈",
      "lifeF_T": "結案",
    },
    "ap-manage": {
      "pageHeader": "应付帐款管理",
      "ap_create_header": "新建对帐单主档",
      "ap_edit_header": "编辑对帐单主档",
      "ap_sumtotal": "总项金额",
      "strike_sumtotal": "冲帐总金额",
      "takeAp": "引用对帐单",
      "contactWindow": "联络窗口资讯",
      "chooseWindow": "选择窗口",
      "ap_tb": "对帐单明细",
      "strike_tb": "冲帐单明细",
      "inbatchno": "进料批号",
      "itemtotal": "应付含税金额",
      "hubqty": "入库数量",
      "balance": "帐差",
      "recon": "对帐资料",
      "addr": "客户地址",
      "addr_placeholder": "请输入联络地址",
      "strike_tb_delete_confirm": "冲帐明细删除确认",
      "paytotal": "已冲帐金额",
      "ap_total": "应付含税总金额",
      "apCheck": "对帐覆核",
      "cancel_apCheck": "取消覆核",
      "apCheck_confirm": "对帐覆核确认",
      "apCheck_confirm_text": "即将进入对帐覆核作业，是否储存对帐单资料?",
      "releaseAP": "出对帐单",
      "payment_placeholder": "請選擇付款方式",
      "cancel_releaseAP": "取消出对帐单",
      "releaseAP_confirm": "出对帐单确认",
      "releaseAP_confirm_text": "即将进入出应收单确认，是否储存应收单资料?",
      "strikeCheck": "冲帐覆核",
      "strike_create_header": "新建冲帐单主档",
      "strike_edit_header": "",
      "hd_drawer_title": "对帐资料主档",
      "itemNM": "窗口注记",
      "paytype": "付款方式",
      "strikeCheck_confirm": "冲帐覆核确认",
      "strikeCheck_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "cancel_strikeCheck_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "confirmStrike": "确认冲帐",
      "confirmStrike_confirm": "冲帐确认",
      "confirmStrike_confirm_text": "确认冲帐后此冲帐单将结案，请确认是否冲帐",
      "strike_paytotal": "付款金额",
      "payT": "付款时间",
      "note": "冲帐注记",
      "PN": "ERP料号",
      "punit": "使用单位",
      "inqty": "移转数量",
      "itemsum": "各项金额",
      "itemsumtax": "各项税额",
      "subtotal": "金额",
      "ap_del_hd_confirm_text": "请先确认要删除的对帐单资料无误，再进行删除",
      "strike_del_hd_confirm_text": "请先确认要删除的冲帐单资料无误，再进行删除",
      "message_chose_take": "請選擇要引用的對帳資料",
      "AP_lifeF_0": "创立",
      "AP_lifeF_1": "覆核",
      "AP_lifeF_2": "",
      "AP_lifeF_3": "冲帐(付款)未结案",
      "AP_lifeF_T": "结案",
      "Strike_lifeF_0": "創立",
      "Strike_lifeF_1": "沖帳覆核",
      "Strike_lifeF_T": "完成冲帐",
      "message_chose_take_which_ap": "请选择要引用的对帐资料",
      "message_only_take_one_ap": "只可引用一张对帐单",
    },
    "return": {
      "pageHeader": "进货退出作业",
      "create_header": "新建进料退出主档",
      "edit_header": "编辑进料退出主档",
      "contact": "联络人",
      "tel": "联络电话",
      "addr": "联络地址",
      "del_hd_confirm_text": "请先确认要删除的进料退出单资料无误，再进行删除",
      "rtnqty": "退回数量",
      "punit": "使用单位",
      "inbatchno": "进料批号",
      "note": "进退注记",
      "tb": "进货退出明细",
      "itemNM": "窗口注记",
      "itemNM_placeholder": "请输入窗口注记",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "message_at_least_chose_one": "请至少选择一笔验退单明细",
      "planqty": "驗退數量",
    },
  },
  OMS: {
    "pageHeader": "銷貨管理系統",
    "util": {
      "upload": "上传",
      "browse": "浏览",
      "cust": "客户",
      "entrust": "客户签订",
      "complete": "完成",
      "opcode_1110": "国内报价",
      "opcode_1120": "国外报价",
      "opcode_1210": "预估订单",
      "opcode_1310": "终端客户",
      "opcode_1320": "合作伙伴",
      "opcode_1330": "其他檢測机构",
      "opcode_1410": "国内订单",
      "opcode_1420": "国外订单",
      "opcode_4220": "验出单",
      "opcode_4130": "验退单",
      "opcode_1610": "销货退回",
      "opcode_1710": "对帐单",
      "opcode_1810": "应收冲帐",
      "plan": "计划单",
      "quo": "报价单",
      "fod": "预订单",
      "ord": "項目報价",
      "outstock": "发货单",
      "return": "销货退回单",
      "ap": "对帐单",
      "outN": "验退单号",
      "UploadSignature": "上传签呈",
      "UploadSign": "上传回签",
      "searchField": "搜索栏位",
      "removeSearch": "清除搜索记录",
      "setAdvanced": "设置进阶筛选条件",
      "exportExcel": "导出Excel",
      "table_close": "强制结令",
      "table_delete": "删除",
      "table_edit": "编辑",
      "table_more": "查看更多",
      "table_operate": "操作",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "打印",
      "header_return": "回总表",
      "header_save": "保存",
      "header_tour": "回导览",
      "header_cancel_create": "取消据此创建",
      "take": "引用",
      "throw": "抛转",
      "accord": "据此",
      "create": "创建",
      "add": "新建",
      "submit": "确定",
      "cancel": "取消",
      "signBack": "签回",
      "search": "搜索",
      "sysMsg": "系统讯息",
      "del_confirm": "删除确认",
      "advanceSearch": "进阶查询",
      "ok": "确定",
      "yes": "是",
      "no": "否",
      "window": "窗口",
      "placeholder_select": "请选择",
      "placeholder_input": "请输入",
      "contact": "联系人",
      "tel": "电话",
      "N11": "报价单号",
      "N12": "预订单号",
      "N13": "订单号",
      "N14": "发货单号",
      "N17": "应收单号",
      "N18": "冲帐单号",
      "jobN": "作业单号",
      "batchno": "批号",
      "itemno": "项序",
      "itemno_total": "总项数",
      "planSearch": "计划纪录查询",
      "quoSearch": "报价纪录查询",
      "fodSearch": "预订纪录查询",
      "ordSearch": "销货纪录查询",
      "stockSearch": "验收纪录查询",
      "outstockSearch": "发货纪录查询",
      "apSearch": "对帐资料查询",
      "strikeSearch": "冲帐纪录查询",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "get_prev_data_suc": "取得上一笔资料成功",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_next_data_err": "取得下一笔资料失败",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "opcode_placeholder": "请选择单别",
      "N_placeholder": "请选择单号",
      "currencytype_placeholder": "请选择币别",
      "custID_placeholder": "请选择厂商",
      "taxtype_placeholder": "请选择税别",
      "taxrate_placeholder": "请输入税率",
      "PN_placeholder": "请选择料号",
      "utype_placeholder": "请选择单位类别",
      "tel_placeholder": "请选择联系人",
      "opcode": "单别",
      "N": "单号",
      "custID": "客户ID",
      "custalias": "客户简称",
      "custNM": "客户名称",
      "currencytype": "币别",
      "exchangerate": "汇率",
      "exchangeD": "汇率日期",
      "taxtype": "税别",
      "taxrate": "税率",
      "confirmID": "审批人员",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "createID": "创建人员",
      "createT": "创建日期",
      "confirmT": "审批日期",
      "signT": "完成委托日期",
      "approveT": "客户签订日期",
      "updtID": "更新人员",
      "updtT": "更新日期",
      "EDT": "预计完成时间",
      "startEDT": "预计交货日(起)",
      "endEDT": "预计交货日(迄)",
      "dealN": "客户单号",
      "PN": "料号",
      "pdtNM": "品名",
      "pdtspec": "规格",
      "drawno": "图号",
      "attribute": "物料属性",
      "pdtclass": "商品类别",
      "pdttype": "管理分类",
      "pbom": "组合数量",
      "utype": "单位类别",
      "unit": "计量单位",
      "opunit": "操作单位",
      "punit": "使用单位",
      "localprice": "本币金额",
      "tax": "总税额",
      "itemsum": "金额",
      "itemsumtax": "各项税额",
      "subtotal": "含税小计",
      "batchdate": "批号日期",
      "quoID": "报价人员",
      "quoT": "报价日期",
      "balqty": "剩余数量",
    },
    "runa1": {
      "type_A": "专职业务员",
      "type_B": "非专职业务员",
      "type_C": "公司全员",
    },
    "quo": {
      "pageHeader": "报价作业",
      "quoID": "报价人员",
      "quoT": "报价日期",
      "validdays": "有效天数",
      "isvalid": "是否有效",
      "hd_drawer_title": "报价单主档",
      "tb_drawer_title": "报价单明细",
      "demand": "报价数量",
      "punit": "报价单位",
      "unitprice": "报价金额",
      "isapprove": "单项成交",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "subtotal": "单项含税小计",
      "itemtotal": "未税总金额",
      "local_subtotal": "本币含税总金额",
      "total": "含税总金额(交易币别)",
      "note": "报价注记",
      "hd_delete_confirm_text": "请先确认要删除的报价单资料无误，再进行删除",
      "lifeF_0": "发布",
      "lifeF_2": "报价覆核",
      "lifeF_7": "审核正式签送",
      "lifeF_T": "结案",
      "quoCheck_confirm_Text": "请先确认要覆核的资料无误，再进行覆核",
      "cancel_quoCheck_confirm_Text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "Audit_confirm": "审查签核确认",
      "Audit_confirm_text": "审查签核后此报价单将无法编辑，确定要完成审查吗",
      "tb": "报价明细",
    },
    "fod": {
      "pageHeader": "预订作业",
      "hd_drawer_title": "预订单主档",
      "tb_drawer_title": "预订单明细",
      "driID": "负责业务",
      "trade": "贸易条件",
      "dealcontact": "客户业务窗口",
      "payment": "付款方式",
      "dealaddr": "客户地址",
      "custHistory": "外包资料查询",
      "contact": "客户交货窗口",
      "sumtotal": "未税总金额",
      "tax": "总税额",
      "note": "预订注记",
      "total": "含税总金额",
      "demandqty": "客订数量",
      "punit": "订购单位",
      "unitprice": "订购单价",
      "confirmqty": "确认数量",
      "cancelqty": "取消数量",
      "returnqty": "退货数量",
      "transferqty": "已抛转数量",
      "qty": "预计抛转数量",
      "hd_delete_confirm_text": "请先确认要删除的预订单资料无误，再进行删除",
      "lifeF_0": "发布",
      "lifeF_2": "预订覆核",
      "lifeF_7": "客戶簽回",
      "lifeF_T": "结案",
      "local_total": "含税总金额(本币)",
      "fodCheck_confirm_text": "请先确认要预订覆核的资料无误，再进行预订覆核",
      "cancel_fodCheck_confirm_text": "请先确认要取消预订覆核的资料无误，再进行取消预订覆核",
      "custCheck_confirm": "客户签回确认",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "reviewFlow": "审核流程(3)",
      "tb": "预订明细",
      "EDT": "预计交货日",
      "deal_history": "产品交易历史查询",
    },
    "ord": {
      "pageHeader": "报价订单作业",
      "contract": "正式合同",
      "createAp": "产生对帐单",
      "planday": "计划工作日",
      "finishEntrust": "完成委托",
      "hd_drawer_title": "销货单主档",
      "tb": "報价項目",
      "quoSign": "客戶委託",
      "checkitem": "检验项目",
      "checkmethod": "测试方法/标准",
      "commission": "文件上载",
      "adda": "报价注记",
      "adda_info": "检测报告附加信息",
      "adda_qty": "检测所需样品数量",
      "adda_require": "检测样品邮寄需求",
      "addb": "加扣款項",
      "addc": "预收款项",
      "postage": "其他费用",
      "type_0": "外测费",
      "type_1": "快递费",
      "type_2": "信息费",
      "type_3": "其他",
      // "type_3": "分包费",
      // "type_4": "其他",
      "lifeF_0": "发布",
      "lifeF_2": "报价审批",
      "lifeF_3": "订单确认",
      "lifeF_4": "转委外检",
      "lifeF_5": "完成委托",
      "lifeF_6": "应收帐款",
      "lifeF_7": "转委外检",
      "lifeF_8": "应收帐款",
      "lifeF_9": "收款入帐",
      "lifeF_10": "上传统一发票",
      "driID": "负责业务",
      "sumtotal": "含税总金额",
      "total": "含税总金额(交易币别)",
      "dealaddr": "客户地址",
      "contact": "客户交货窗口",
      "dealcontact": "客户业务窗口",
      "payment": "付款方式",
      "trade": "贸易条件",
      "note": "样品名称",
      "reviewFlow": "审核流程(3)",
      "demandqty": "数量",
      "transferqty": "已抛转数量",
      "qty": "本次抛转数量",
      "punit": "销货单位",
      "unitprice": "检测费用",
      "confirmqty": "客户确认数量",
      "cancelqty": "取消数量",
      "returnqty": "退货数量",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "charge": "总加扣款项",
      "subtotal": "含税小计",
      "statement": "付款说明",
      "amount": "金额",
      "throwOutstock": "转外包项目",
      "local_total": "含税总金额(本币)",
      "startEDT": "预计出货日(起)",
      "endEDT": "预计出货日(迄)",
      "closeData_confirm_text": "请先确认要结令的销货单资料无误，再进行强制结令",
      "removeData_confirm_text": "请先确认要删除的销货单资料无误，再进行删除",
      "orderCheck_text": "请先确认资料无误再进行报价批审",
      "cancel_orderCheck_text": "请先确认资料无误再进行取消报价批审",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "adda_delete_confirm_text": "请先确认要删除的条款资料无误，再进行删除",
      "addb_delete_confirm_text": "請先確認要刪除的款項資料無誤，再進行刪除",
      "addc_delete_confirm_text": "请先确认要删除的分包费用无误，再进行删除",
      "deal_history": "产品交易历史查询",
      "inspcer": "检测报告",
      "AR": "应收帐款",
      "rcv": "收款入帐",
      "bill": "统一发票",
      "target": "对象",
    },
    "outstock": {
      "pageHeader": "发货作业",
      "hd_drawer_title": "发货单主档",
      "tb_drawer_title": "发货单明细",
      "tb": "发货明细",
      "lifeF_0": "发布",
      "lifeF_2": "发货复核",
      "lifeF_3": "IQC抽验",
      "lifeF_4": "IQC完验",
      "lifeF_7": "品保复核",
      "lifeF_T": "结案",
      "contact": "联系人",
      "tel": "联系电话",
      "sampleID": "抽样人员",
      "sampleT": "抽样日期",
      "note": "发货备注",
      "punit": "发货单位",
      "indqty": "发货数量",
      "goqty": "验收数量",
      "unitprice": "销货单价",
      "rejectqty": "验退数量",
      "IQCcheck": "进检抽样",
      "inspectID": "进检人员",
      "inspectT": "完验时间",
      "qalID": "品判人员",
      "qalT": "放行时间",
      "judgeT": "品保判定日期",
      "qty": "数量",
      "choose": "选择",
      "choose_err": "剩余数量为0不可引用",
      "throw_save_msg": "请先选择引用再输入引用数量",
      "throwQty_msg": "拋转数量不可小于0",
      "useDetail_msg": "不可引用相同明细",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "IQCcheck_text": "即将进入进检抽样作业，是否储存发货单资料?",
      "closeData_confirm_text": "请先确认要删除的发货单资料无误，再进行删除",
      "outstockCheck_text": "请先确认要发货复核的资料无误，再进行发货复核",
      "cancel_outstockCheck_text": "请先确认要取消复核的资料无误，再进行取消复核",
      "IQCfinish_text": "即将进入IQC完验作业，是否储存发货单资料?",
      "QAcheck_err": "发货明细 [验收数量]+[验退数量] 要等于 [发货数量]",
      "QAcheck": "品保放行",
      "QAcheck_text": "即将进入品保放行作业，是否储存发货单资料?",
      "throwStock_text": "即将进入拋转发货单作业，是否确定拋转?",
    },
    "check": {
      "pageHeader": "销货检验查询",
      "4220_header": "销售发货单主档",
      "4220_tb": "发货明细",
      "4130_header": "销售退回单主档",
      "4130_tb": "退回明細",
      "N": "作业单号",
      "jobN": "来源单号",
      "batchno": "批号",
      "batchdate": "批号日期",
      "note": "注记",
      "total": "移转总量",
      "planqty": "移转数量",
      "punit": "销货单位",
      "stockinT": "移转时间",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
    },
    "return": {
      "pageHeader": "销货退回作业",
      "tb": "销货退回明细",
      "hd_remove_text": "请先确认要删除的销货退回单资料无误，再进行删除",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "take": "取用",
      "unitprice": "出货单价",
      "rtnqty": "退回数量",
      "inbatchno": "进料批号",
      "contact": "联络人",
      "contact_window": "客户业务窗口",
      "note": "进退注记",
      "tel": "联络电话",
      "addr": "联络地址",
      "itemNM": "窗口注记",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
    },
    "ap-manage": {
      "pageHeader": "应收帐款管理",
      "rcv": "应收查询",
      "rcv_lifeF_0": "创建",
      "rcv_lifeF_1": "已请款",
      "rcv_lifeF_T": "已付款",
      "rcvData": "应收资料",
      "rcv_note": "样品",
      "rcv_drawer_title": "应收帐款主档",
      "inbatchno": "进料批号",
      "contactWindow": "联络窗口资讯",
      "ap": "对帐请款",
      "ap_tb": "对帐单明细",
      "strike": "平账记录",
      "strike_tb": "冲帐单明细",
      "unitprice": "检测费用",
      "rcv_inqty": "数量",
      "rcv_itemsum": "各项金额",
      "rcv_itemsumtax": "各项税额",
      "rcv_subtotal": "金额",
      "ap_remove_text": "请先确认要删除的对帐单资料无误，再进行删除",
      "strike_remove_text": "请先确认要删除的冲帐单资料无误，再进行删除",
      "ap_total": "应收含税总金额",
      "ap_itemtotal": "应收含税金额",
      "ap_paytotal": "已冲帐金额",
      "ap_sumtotal": "总项金额",
      "strike_sumtotal": "冲帐总金额",
      "ap_hubqty": "出库数量",
      "ap_addr": "客戶地址",
      "ap_balance": "帐差",
      "ap_lifeF_0": "创立",
      "ap_lifeF_1": "应收覆核",
      "ap_lifeF_2": "出合同单",
      "ap_lifeF_3": "冲帐(付款)未结案",
      "ap_lifeF_T": "結案",
      "ap_contact": "客户业务窗口",
      "ap_itemNM": "窗口注记",
      "strike_lifeF_0": "創立",
      "strike_lifeF_1": "沖帳覆核",
      "strike_lifeF_T": "完成沖帳",
      "rcvtype": "收款方式",
      "rcvtype_1": "銀行滙款(T/T)",
      "rcvtype_2": "應付票據(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "rcvtype_4": "微信支付",
      "rcvtype_5": "预付款项",
      "rcvtotal": "收款金額",
      "rcvT": "收款時間",
      "strike_note": "冲帐注记",
      "confirmStrike": "确认冲帐",
      "apCheck_confirm_text": "即将进入对帐覆核作业，是否储存对帐单资料?",
      "releaseAP_confirm_text": "即将进入出应收单确认，是否储存应收单资料?",
      "strikeCheck_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "cancel_strikeCheck_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "confirmStrike_confirm_text": "确认冲帐后此冲帐单将结案，请确认是否冲帐",
      "strike_tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
    },
  },
  "OMS_ori": {
    "ap-manage": {
      "N17": "应收单号",
      "N18": "冲帐单号",
      "ap": "对帐作业",
      "apCheck_confirm_text": "即将进入对帐覆核作业，是否储存对帐单资料",
      "apSearch": "对帐资料查询",
      "ap_addr": "客户地址",
      "ap_balance": "帐差",
      "ap_contact": "客户业务窗口",
      "ap_hubqty": "出库数量",
      "ap_itemNM": "窗口注记",
      "ap_itemtotal": "应收含税金额",
      "ap_lifeF_0": "创立",
      "ap_lifeF_1": "应收覆核",
      "ap_lifeF_2": "出应收单",
      "ap_lifeF_3": "冲帐(收款)未结案",
      "ap_lifeF_T": "结案",
      "ap_paytotal": "已冲帐金额",
      "ap_remove_text": "请先确认要删除的对帐单资料无误，再进行删除",
      "ap_sumtotal": "总项金额",
      "ap_tb": "对帐单明细",
      "ap_total": "应收含税总金额",
      "cancel_strikeCheck_confirm_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "confirmStrike": "确认冲帐",
      "confirmStrike_confirm_text": "确认冲帐后此冲帐单将结案，请确认是否冲帐",
      "contactWindow": "联络窗口资讯",
      "inbatchno": "进料批号",
      "opcode_1710": "销售出货",
      "opcode_1810": "应收冲帐",
      "pageHeader": "应收帐款管理",
      "rcv": "应收帐款查询",
      "rcvData": "应收资料",
      "rcvT": "收款时间",
      "rcv_drawer_title": "应收帐款主档",
      "rcv_inqty": "移转数量",
      "rcv_itemsum": "各项金额",
      "rcv_itemsumtax": "各项税额",
      "rcv_subtotal": "含税小计",
      "rcvtotal": "收款金额",
      "rcvtype": "收款方式",
      "rcvtype_1": "银行汇款(T/T)",
      "rcvtype_2": "应付票据(支票)",
      "rcvtype_3": "现金支付(CASH)",
      "releaseAP_confirm_text": "即将进入出应收单确认，是否储存应收单资料?",
      "strike": "冲帐作业",
      "strikeCheck_confirm_text": "请先确认要覆核的资料无误，再进行覆核",
      "strikeSearch": "冲帐纪录查询",
      "strike_lifeF_0": "创立",
      "strike_lifeF_1": "冲帐覆核",
      "strike_lifeF_T": "完成冲帐",
      "strike_note": "冲帐注记",
      "strike_remove_text": "请先确认要删除的冲帐单资料无误，再进行删除",
      "strike_sumtotal": "冲帐总金额",
      "strike_tb": "冲帐单明细",
      "strike_tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "unitprice": "销货单价",
    },
    "check": {
      "4130_header": "销售退回单主档",
      "4130_tb": "退回明细",
      "4220_header": "销售发货单主档",
      "4220_tb": "发货明细",
      "N": "作业单号",
      "batchdate": "批号日期",
      "batchno": "批号",
      "jobN": "来源单号",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "note": "注记",
      "opcode_4130": "销售退回单",
      "opcode_4220": "销售发货单",
      "pageHeader": "销货检验查询",
      "planqty": "移转数量",
      "punit": "销货单位",
      "stockinT": "移转时间",
      "total": "移转总量",
    },
    "fod": {
      "EDT": "预计交货日",
      "N12": "预订单号",
      "UploadSign": "上传回签",
      "approveT": "核准日期",
      "balqty": "剩余数量",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "cancel_fodCheck_confirm_text": "请先确认要取消预订覆核的资料无误，再进行取消预订覆核",
      "cancelqty": "取消数量",
      "confirmID": "覆核人员",
      "confirmT": "覆核日期",
      "confirmqty": "确认数量",
      "contact": "客户交货窗口",
      "contact_placeholder": "选择客户交货窗口",
      "createID": "创建人员",
      "createT": "创建日期",
      "custCheck_confirm": "客户签回确认",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "custHistory": "客户交易历史查询",
      "custNM": "客户名称",
      "dealN": "客户单号",
      "dealN_placeholder": "请输入客户单号",
      "deal_history": "产品交易历史查询",
      "dealaddr": "客户地址",
      "dealcontact": "客户业务窗口",
      "dealcontact_placeholder": "选择客户业务窗口",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "demandqty": "客订数量",
      "driID": "负责业务",
      "driID_placeholder": "请选择负责业务",
      "endEDT": "预计交货日(迄)",
      "fod": "预订单",
      "fodCheck_confirm_text": "请先确认要预订覆核的资料无误，再进行预订覆核",
      "hd_delete_confirm_text": "请先确认要删除的预订单资料无误，再进行删除",
      "hd_drawer_title": "预订单主档",
      "itemsum": "单项金额(未税)",
      "itemsumtax": "各项税额",
      "lifeF_0": "发布",
      "lifeF_2": "预订覆核",
      "lifeF_7": "客户签回",
      "lifeF_T": "结案",
      "local_subtotal": "本币含税总金额",
      "local_total": "含税总金额(本币)",
      "note": "预订注记",
      "opcode_1210": "预估订单",
      "pageHeader": "预订作业",
      "payment": "付款方式",
      "punit": "订购单位",
      "qty": "预计抛转数量",
      "returnqty": "退货数量",
      "reviewFlow": "审核流程(3)",
      "signBack": "签回",
      "signT": "签回日期",
      "startEDT": "预计交货日(起)",
      "subtotal": "含税小计",
      "success": "成功",
      "sumtotal": "未税总金额",
      "tax": "总税额",
      "tb": "预订明细",
      "tb_drawer_title": "预订单明细",
      "tel_placeholder": "请选择联络人",
      "total": "含税总金额",
      "trade": "贸易条件",
      "trade_placeholder": "请选择贸易条件",
      "transferqty": "已抛转数量",
      "unitprice": "订购单价",
    },
    "ord": {
      "EDT": "预计交货日",
      "N": "单号",
      "N13": "销货单号",
      "accord": "據此",
      "add": "新建",
      "adda": "条款注记",
      "adda_delete_confirm_text": "请先确认要删除的条款资料无误，再进行删除",
      "addb": "加扣款项",
      "addb_delete_confirm_text": "请先确认要删除的款项资料无误，再进行删除",
      "addc": "预付作业",
      "addc_delete_confirm_text": "请先确认要删除的预付作业无误，再进行删除",
      "amount": "未税金额",
      "balqty": "剩余数量",
      "boxC": "箱数",
      "cancel": "取消",
      "cancel_custCheck_confirm_text": "请先确认要取消签回的资料无误，再进行取消签回",
      "cancel_orderCheck_text": "请先确认要取消销货覆核的资料无误，再进行取消销货覆核",
      "cancelqty": "取消数量",
      "charge": "总加扣款项",
      "closeData_confirm_text": "请先确认要结令的销货单资料无误，再进行强制结令",
      "confirmqty": "客户确认数量",
      "contact": "客户交货窗口",
      "create": "创建",
      "currencytype": "币别",
      "currencytype_placeholder": "请选择币别",
      "custCheck_confirm_text": "请先确认要签回的资料无误，再进行签回",
      "custID": "客户编号",
      "custalias": "客户简称",
      "deal_history": "产品交易历史查询",
      "dealaddr": "客户地址",
      "dealcontact": "客户业务窗口",
      "del_tb_confirm_text": "请先确认要删除的明细资料无误，再进行删除",
      "demandqty": "销货数量",
      "driID": "负责业务",
      "endEDT": "预计出货日(迄)",
      "exchangeD": "汇率日期",
      "exchangerate": "汇率",
      "exportExcel": "汇出Excel",
      "fodSearch": "预订纪录查询",
      "hd_drawer_title": "销货单主档",
      "header_cancel_create": "取消据此创建",
      "header_next": "下一笔",
      "header_prev": "上一笔",
      "header_print": "列印",
      "header_return": "回总表",
      "header_save": "储存",
      "header_tour": "回导览",
      "itemno_total": "总项数",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "lifeF": "历程",
      "lifeFT": "历程时间",
      "lifeF_0": "发布",
      "lifeF_2": "销货覆核",
      "lifeF_7": "客户签回",
      "lifeF_T": "结令",
      "local_total": "含税总金额(本币)",
      "note": "销货注记",
      "ok": "",
      "opcode": "单别",
      "opcode_1310": "国内订单",
      "opcode_1320": "国外订单",
      "opcode_placeholder": "请选择单别",
      "ord": "销货单",
      "orderCheck_text": "请先确认要销货覆核的资料无误，再进行销货覆核",
      "outstockSearch": "发货纪录查询",
      "pageHeader": "销货作业",
      "payment": "付款方式",
      "placeholder_input": "请输入",
      "placeholder_select": "请选择",
      "punit": "销货单位",
      "qty": "本次抛转数量",
      "quoSearch": "报价纪录查询",
      "removeData_confirm_text": "请先确认要删除的销货单资料无误，再进行删除",
      "returnqty": "退货数量",
      "reviewFlow": "审核流程(3)",
      "setAdvanced": "设定进阶筛选条件",
      "startEDT": "预计出货日(起)",
      "statement": "项目说明",
      "submit": "确定",
      "subtotal": "含税小计",
      "sumtotal": "未税总金额",
      "sysMsg": "系统讯息",
      "table_close": "强制结令",
      "table_delete": "删除",
      "table_edit": "编辑",
      "table_more": "查看更多",
      "table_operate": "操作",
      "take": "引用",
      "taxrate": "税率",
      "taxtype": "税别",
      "tb": "销货明细",
      "tel": "电话",
      "throw": "抛转",
      "total": "含税总金额(交易币别)",
      "trade": "贸易条件",
      "transferqty": "已抛转数量",
      "unitprice": "销货单价",
      "window": "窗口",
    },
    "out-stock": {
      "IQCcheck": "进检抽样",
      "IQCcheck_text": "即将进入进检抽样作业，是否储存发货单资料?",
      "IQCfinish_text": "即将进入IQC完验作业，是否储存发货单资料?",
      "N14": "发货单号",
      "QAcheck": "品保放行",
      "QAcheck_err": "发货明细 [验收数量]+[验退数量] 要等于 [发货数量]",
      "QAcheck_text": "即将进入品保放行作业，是否储存发货单资料?",
      "boxnum": "箱数",
      "cancel_outstockCheck_text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "choose": "选择",
      "choose_err": "剩余数量为0不可引用",
      "closeData_confirm_text": "请先确认要删除的发货单资料无误，再进行删除",
      "contact": "联络人",
      "goqty": "验收数量",
      "hd_drawer_title": "发货单主档",
      "indqty": "发货数量",
      "inspectID": "进检人员",
      "inspectT": "完验时间",
      "judgeT": "品保判定日期",
      "lifeF_0": "发布",
      "lifeF_2": "发货覆核",
      "lifeF_3": "IQC抽验",
      "lifeF_4": "IQC完验",
      "lifeF_7": "品保覆核",
      "lifeF_T": "结案",
      "note": "发货注记",
      "opcode_1410": "国内订单",
      "opcode_1420": "国外订单",
      "outstock": "发货单",
      "outstockCheck_text": "请先确认要发货覆核的资料无误，再进行发货覆核",
      "pageHeader": "发货作业",
      "punit": "发货单位",
      "qalID": "品判人员",
      "qalT": "放行时间",
      "qty": "数量",
      "rejectqty": "验退数量",
      "sampleID": "抽样人员",
      "sampleT": "抽样日期",
      "search": "搜寻",
      "stockSearch": "验收记录查询",
      "tb": "发货明细",
      "tb_drawer_title": "发货单明细",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "tel": "联络电话",
      "throwQty_msg": "抛转数量不可小于0",
      "throwStock_text": "即将进入抛转发货单作业，是否确定抛转?",
      "throw_save_msg": "请先选择引用再输入引用数量",
      "unitprice": "销货单价",
      "useDetail_msg": "不可引用相同明细",
    },
    "plan": { "plan": "" },
    "quo": {
      "Audit_confirm": "审查签核确认",
      "Audit_confirm_text": "审查签核后此报价单将无法编辑，确定要完成审查吗",
      "N11": "报价单号",
      "N_placeholder": "请输入单号",
      "PN": "料号",
      "PN_placeholder": "请选择料号",
      "UploadSignature": "上传签呈",
      "attribute": "物料属性",
      "cancel_quoCheck": "取消覆核",
      "cancel_quoCheck_confirm_Text": "请先确认要取消覆核的资料无误，再进行取消覆核",
      "del_confirm": "删除确认",
      "demand": "报价数量",
      "demand_placeholder": "请填写报价数量",
      "drawno": "图号",
      "get_next_data_err": "取得下一笔资料失败",
      "get_next_data_suc": "取得下一笔资料成功",
      "get_prev_data_err": "取得上一笔资料失败",
      "get_prev_data_suc": "取得上一笔资料成功",
      "hd_delete_confirm_text": "请先确认要删除的报价单资料无误，再进行删除",
      "hd_drawer_title": "报价单主档",
      "isapprove": "单项成交",
      "isvalid": "是否有效",
      "itemno": "项序",
      "itemsum": "单项金额",
      "itemsumtax": "单项税额",
      "itemtotal": "未税总金额",
      "lifeF_0": "发布",
      "lifeF_2": "报价覆核",
      "lifeF_7": "审核正式签送",
      "lifeF_T": "结案",
      "lifeF_placeholder": "请选择历程",
      "local_subtotal": "本币含税总金额",
      "localprice": "本币金额",
      "next_data_errMsg": "目前资料为最后一笔，已无下一笔资料",
      "no": "否",
      "note": "报价注记",
      "opcode_1110": "国内报价",
      "opcode_1120": "国外报价",
      "opcode_placeholder": "请选择单别",
      "opunit": "操作单位",
      "ordSearch": "销货纪录查询",
      "pageHeader": "报价作业",
      "pdtNM": "品名",
      "pdtclass": "商品类别",
      "pdtspec": "规格",
      "pdttype": "管理分类",
      "plan": "计画单",
      "planSearch": "计画纪录查询",
      "prev_data_errMsg": "目前资料为第一笔，已无上一笔资料",
      "punit": "报价单位",
      "quo": "报价单",
      "quoCheck": "",
      "quoCheck_confirm_Text": "请先确认要覆核的资料无误，再进行覆核",
      "quoID": "报价人员",
      "quoT": "报价日期",
      "removeSearch": "清除搜寻纪录",
      "searchField": "搜寻栏位",
      "subtotal": "单项含税小计",
      "tax": "总税额",
      "taxrate_placeholder": "请输入税率",
      "taxtype_placeholder": "请选择税别",
      "tb": "报价明细",
      "tb_drawer_title": "报价单明细",
      "total": "含税总金额(交易币别)",
      "unit": "计量单位",
      "unitprice": "报价金额",
      "unitprice_placeholder": "请填写报价金额",
      "updtT": "更新日期",
      "validdays": "有效天数",
      "validdays_placeholder": "请填写有效天数",
      "yes": "是",
    },
    "return": {
      "addr": "联络地址",
      "contact": "联络人",
      "contact_window": "客户业务窗口",
      "hd_remove_text": "请先确认要删除的销货退回单资料无误，再进行删除",
      "inbatchno": "进料批号",
      "itemNM": "窗口注记",
      "lifeF_0": "发布",
      "lifeF_T": "结案",
      "note": "进退注记",
      "opcode_1610": "销货退回",
      "outN": "验退单号",
      "pageHeader": "销货退回作业",
      "return": "销货退回单",
      "rtnqty": "退回数量",
      "take": "取用",
      "tb": "销货退回明细",
      "tb_remove_text": "请先确认要删除的明细资料无误，再进行删除",
      "tel": "联络电话",
      "unitprice": "出货单价",
    },
    "util": {
      "N11": "报价单号",
      "N12": "预订单号",
      "N13": "销货单号",
      "N14": "发货单号",
      "N17": "应收单号",
      "N18": "冲帐单号",
      "ap": "",
      "apSearch": "对帐资料查询",
      "approveT": "客户签订日期",
      "attribute": "物料属性",
      "dealN": "客户单号",
      "drawno": "图号",
      "entrust": "客户签订",
      "fod": "",
      "fodSearch": "预订纪录查询",
      "localprice": "本币金额",
      "opcode_1110": "",
      "opcode_1120": "",
      "opcode_1210": "",
      "opcode_1310": "",
      "opcode_1320": "",
      "opcode_1410": "",
      "opcode_1420": "",
      "opcode_1610": "",
      "opcode_1710": "",
      "opcode_1810": "",
      "opcode_4130": "",
      "opcode_4220": "",
      "ord": "销货作业",
      "ordSearch": "销货纪录查询",
      "outN": "",
      "outstock": "",
      "outstockSearch": "发货纪录查询",
      "pdtclass": "商品类别",
      "pdttype": "管理分类",
      "plan": "",
      "planSearch": "计画纪录查询",
      "quo": "",
      "quoSearch": "报价纪录查询",
      "return": "",
      "stockSearch": "验收记录查询",
      "strikeSearch": "冲帐纪录查询",
    },
  },
  PDS: {
    "wpc-search": {
      "wlID": "",
      "linemix": "",
      "hdC": "",
      "issueday": "",
    },
    "overview-lines": {
      "pageHeader": "线体稼动总表",
      "wlID": "线别",
      "act": "稼动率",
      "shiftD": "日班",
      "shiftN": "夜班",
      "allDay": "左",
    },
    "dashboard": {
      "pageHeader": "数位生产看板",
    },
    "abc-board": {
      "pageHeader": "异常管理看板",
    },
  },
};

export default zh_CN;
