import { IconCheck, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import { Modal, Space, message } from "antd";
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { deleteRole, getRole } from "@/service/apis/ADM/userManage";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModalConintaer from "./ModalContainer";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const RoleAuth = forwardRef((props, ref) => {
  const { t } = useTranslation();

  const { s_searchData } = props;
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_editData, set_s_editData] = useState({});
  const [s_isShowModal, set_s_isShowModal] = useState(false);

  const call_getRole = useAPI(getRole);
  const call_deleteRole = useAPI(deleteRole);

  const tableColumns = [
    {
      title: t("ADM.user-manage.roleID"), //角色ID
      dataIndex: "itemID",
      align: "center",
    },
    {
      title: t("ADM.user-manage.roleNM"), //角色名稱
      dataIndex: "itemNM",
    },
    {
      title: t("util.nav_A"), // 業務管理
      dataIndex: "A",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_B"), // 項目管理
      dataIndex: "B",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_C"), // 財務管理
      dataIndex: "C",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.nav_D"), // 系統管理
      dataIndex: "D",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: "库存管理",
      dataIndex: "E",
      align: "center",
      render: (text) => (text ? <IconCheck /> : <IconClose />),
    },
    {
      title: t("util.action"), //操作
      dataIndex: "render",
      align: "center",
      width: "8%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_s_editData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          <IconGarbage
            onClick={(e) => {
              removeData(rowData);
              e.stopPropagation();
            }}
          />
        </Space>
      ),
    },
  ];

  // 傳上去查詢用的
  useImperativeHandle(ref, () => ({
    onSearch: onSearch,
    onCreate: () => {
      set_s_isShowModal(true);
      set_s_editData({});
    },
  }));

  const onSearch = () => {
    call_getRole.request();
  };

  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      cancelText: t("util.cancel"),
      onOk: () => call_deleteRole.request(rowData),
    });
  };

  useEffect(() => {
    if (call_getRole.status === "suc") {
      message.success(call_getRole.msg);
      // 整理資料
      set_s_tableData(
        call_getRole.data.map((x, i) => ({
          ...x,
          key: i,
          ...JSON.parse(x.auth),
        }))
      );
    } else if (call_getRole.status === "err") {
      set_s_tableData([]);
      message.error(t(`errorCode.${call_getRole.msg}`));
    }
  }, [call_getRole.status]);

  useEffect(() => {
    if (call_deleteRole.status === "suc") {
      message.success(call_deleteRole.msg);
    } else if (call_deleteRole.status === "err") {
      message.error(t(`errorCode.${call_deleteRole.msg}`));
    }
    call_getRole.request();
  }, [call_deleteRole.status]);

  return (
    <>
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData}
        loading={call_getRole.status === "load"}
        c_lightData={s_editData}
        onRow={(record) => ({
          onClick: () =>
            record.key === s_editData.key ? set_s_editData({}) : set_s_editData(record),
        })}
      />
      <CustomModal
        title={
          Object.keys(s_editData).length > 0
            ? `${t("util.edit")}${t("ADM.user-manage.role")}`
            : `${t("util.add")}${t("ADM.user-manage.role")}`
        }
        width={"60%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_s_editData({});
          call_getRole.request();
        }}
      >
        <ModalConintaer s_editData={s_editData} set_s_isShowModal={set_s_isShowModal} />
      </CustomModal>
    </>
  );
});

export default RoleAuth;
