import React, { useState, useEffect } from "react";
import { Input, message } from "antd";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
// util
import { formatAmount } from "@/util/format";
// API
import { getApsRun, getWpcRun } from "./moke";
import { getWpcSearch } from "@/service/apis/ADM/wpcSearch";
import useAPI from "@/hooks/useAPI";

const ApsRun = () => {
  const { t } = useTranslation();
  const [s_searchData, set_s_searchData] = useState({
    lifeF: "aps",
    woN: "",
    PN: "",
  });
  const [s_tableData, set_s_tableData] = useState([]);

  const call_getApsRun = useAPI(getApsRun);
  // 取的工站途單查詢
  const call_getWpcSearch = useAPI(getWpcSearch);

  // 後端回來的資料
  // const [s_pageInfo, set_s_pageInfo] = useState({
  //   currentPage: 0,
  //   endItemNumber: 0,
  //   pageItemNumber: 0,
  //   startItemNumber: 0,
  //   totalItems: 0,
  // });

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    console.log(type, value);
    const _searchData = {
      ...s_searchData,
      [type]: value,
    };
    set_s_searchData(_searchData);
    call_getWpcSearch.request(_searchData);
  };

  useEffect(() => {
    call_getWpcSearch.request(s_searchData);
  }, []);

  // useAPI 取得工站途單
  useEffect(() => {
    const { status, data, msg } = call_getWpcSearch;
    if (status === "suc") {
      message.success(msg);
      const _data = data.map((item, index) => ({ ...item, key: index, index: index }));
      set_s_tableData(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getWpcSearch.status]);

  const tabsData = [
    {
      key: "aps",
      label: t("PDS.wpc-search.lifeF_aps"), // 生管發佈
    },
    {
      key: "0",
      label: t("PDS.wpc-search.lifeF_0"), // 已發佈
    },
    {
      key: "1",
      label: t("PDS.wpc-search.lifeF_1"), // 已登錄
    },
    {
      key: "B",
      label: t("PDS.wpc-search.lifeF_B"), // 報開工
    },
    {
      key: "2",
      label: t("PDS.wpc-search.lifeF_2"), // 生產中
    },
    {
      key: "3",
      label: t("PDS.wpc-search.lifeF_3"), // 報停工
    },
    {
      key: "4",
      label: t("PDS.wpc-search.lifeF_4"), // 報復工
    },
    {
      key: "7",
      label: t("PDS.wpc-search.lifeF_7"), // 報完工
    },
    {
      key: "L",
      label: t("PDS.wpc-search.lifeF_L"), // 物控覆核
    },
    {
      key: "P",
      label: t("PDS.wpc-search.lifeF_P"), // 質控合格
    },
    {
      key: "G",
      label: t("PDS.wpc-search.lifeF_G"), // 過站
    },
  ];

  const tableColumns = [
    {
      title: t("PMS.util.lifeFT"), // 歷程時間
      dataIndex: "lifeFT",
      align: "center",
    },
    {
      title: t("PDS.wpc-search.wlID"), // 線別
      dataIndex: "wlID",
      align: "center",
    },
    {
      title: t("PDS.wpc-search.linemix"), // 混線
      dataIndex: "linemix",
      align: "center",
      render: (text) => (text === 1 ? "左線" : text === 2 ? "右線" : ""),
    },
    {
      title: t("util.woN"), // 工單
      dataIndex: "woN",
      align: "center",
    },

    {
      title: t("util.PN"), // 料號
      dataIndex: "PN",
      align: "center",
    },
    {
      title: t("util.custPN"), // 交易料號
      dataIndex: "custPN",
      align: "center",
    },
    {
      title: t("util.pdtNM"), // 品名
      dataIndex: "pdtNM",
      align: "center",
    },
    {
      title: t("util.planqty"), // 計畫產量
      dataIndex: "planqty",
      align: "center",
      render: (text) => formatAmount(text),
    },
    {
      title: t("util.inC"), // 投產數量
      hidden: s_searchData.lifeF !== "aps",
      dataIndex: "inC",
      align: "center",
    },
    {
      title: t("util.okC"), // 產出數量
      dataIndex: "okC",
      align: "center",
    },
    {
      title: t("util.ngC"), // 不良數量
      dataIndex: "ngC",
      align: "center",
    },

    {
      title: t("PDS.wpc-search.hdC"), // 高峰人數
      dataIndex: "hdC",
      align: "center",
    },
    {
      title: t("PDS.wpc-search.issueday"), // 發佈時間
      dataIndex: "issueday",
      align: "center",
    },
    {
      title: t("util.lifeF"), // 歷程
      dataIndex: "lifeF",
      align: "center",
    },
  ];

  return (
    <>
      <PageHeader
        title={t("PDS.wpc-search.pageHeader")}
        extra={[
          <Input
            key="woN"
            value={s_searchData.name}
            onChange={(e) => handlChange("woN", e.target.value)}
            // className="w-[550px]"
            placeholder={`${t("util.placeholder_input")}${t("util.woN")}`}
          />,
          <Input
            key="PN"
            value={s_searchData.name}
            onChange={(e) => handlChange("PN", e.target.value)}
            // className="w-[550px]"
            placeholder={`${t("util.placeholder_input")}${t("util.PN")}`}
          />,
        ]}
      />

      <CustomTabs
        items={tabsData}
        activeKey={s_searchData.lifeF}
        onChange={(activeKey) => {
          const _s_searchData = {
            ...s_searchData,
            lifeF: activeKey,
          };
          set_s_searchData(_s_searchData);
          call_getWpcSearch.request(_s_searchData);
        }}
      />

      <CustomTable columns={tableColumns.filter((x) => !x.hidden)} dataSource={s_tableData} />
    </>
  );
};

export default ApsRun;
