/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  Button,
  message,
  Divider,
} from "antd";
import { useTranslation } from "react-i18next";
// util component
import PNSelect from "@/components/ERP/PNSelect";
import PNButton from "@/components/ERP/PNButtonOri";
import { CancelButton, ConfirmButton } from "@/components/ERP/Button";
// context
import { ERPContext } from "@/components/Context/SystemContext";
// API
import useAPI from "@/hooks/useAPI";
import { addOrdTB, updateOrdTB } from "@/service/apis/OMS/Ord";
const { Text } = Typography;

const TbModal = ({
  hd_values,
  s_disabled,
  s_tbModal,
  set_s_tbModal,
  call_getOrdHD,
  call_getOrdTB,
}) => {
  const { t } = useTranslation();
  const { c_PNs } = useContext(ERPContext);
  // useAPI 新建表身
  const call_addOrdTB = useAPI(addOrdTB);
  // useAPI 編輯表身
  const call_updateOrdTB = useAPI(updateOrdTB);
  const [form] = Form.useForm();
  // 用來觸法 re-render
  const f_utype = Form.useWatch("utype", form);
  const f_PN = Form.useWatch("PN", form);
  const f_unit = Form.useWatch("unit", form);
  const f_opunit = Form.useWatch("opunit", form);
  const f_unitprice = Form.useWatch("unitprice", form);
  const f_demandqty = Form.useWatch("demandqty", form);

  // 儲存時執行
  const onFinish = async (values) => {
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    if (type === "create") {
      // step1 call 新建表身的API
      call_addOrdTB.request(values);
    }
    if (type === "edit") {
      // step1 call 編輯表身的API
      call_updateOrdTB.request(values);
    }
  };

  // 改變料號
  const changePN = (value) => {
    const PN_obj = c_PNs.data?.find((item) => item.PN === value) ?? {};
    console.log("PN_obj = ", PN_obj);

    form.setFieldsValue({
      ...PN_obj,
      // note
      note: form.getFieldValue("note"),
      // 改變料號時清空採購單位讓使用者重選
      // utype: undefined,
      // punit: undefined,
      // 預設計量單位
      utype: "unit",
      punit: PN_obj.unit,
    });
  };

  // 第一次渲染賦值表單
  useEffect(() => {
    // 判斷目前是編輯表身還是新建表身
    const type = Object.keys(s_tbModal.data).length === 0 ? "create" : "edit";
    const formValues = {
      opUUID: hd_values.opUUID,
      currencytype: hd_values.currencytype,
      exchangerate: hd_values.exchangerate,
      exchangeD: hd_values.exchangeD,
      ...(type === "create"
        ? {
            returnqty: 0,
            confirmqty: 0,
            cancelqty: 0,
            balqty: 0,
            lifeF: "0",
          }
        : s_tbModal.data),
    };
    form.setFieldsValue(formValues);
  }, []);

  // useAPI 新增表身資料
  useEffect(() => {
    const { status, msg } = call_addOrdTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`error.error-code.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_addOrdTB.status]);

  // useAPI 編輯表身資料
  useEffect(() => {
    const { status, msg } = call_updateOrdTB;
    if (status === "suc") {
      message.success(msg);
      // call 更新表頭資料
      call_getOrdHD.request({ opUUID: hd_values.opUUID });
      // call 更新表身資料
      call_getOrdTB.request({ opUUID: hd_values.opUUID });
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
    if (status === "msg") {
      message.error(t(`error.error-code.${msg}`));
      // 關閉跳顯
      set_s_tbModal({ show: false, data: {} });
    }
  }, [call_updateOrdTB.status]);

  // 改變採購單價、採購數量時重新計算各項金額(itemsum)、各項稅額(itemsumtax)、含稅小記(subtotal)
  // 改變採購單價時重新計算本幣單價
  useEffect(() => {
    const { cancelqty } = form.getFieldsValue();
    // 廠商確認數量預設為採購數量
    // 如果當前確認數量不等於採購數量也沒關係，因為只要表單可編輯就必須重跑流程
    const confirmqty = f_demandqty;
    // 各項金額
    const itemsum = (confirmqty - cancelqty) * f_unitprice;
    // 各項稅額
    const itemsumtax = itemsum * hd_values.taxrate * 0.01;
    // 含稅小記
    const subtotal = itemsum + itemsumtax;

    form.setFieldsValue({
      confirmqty: confirmqty,
      localprice: f_unitprice ? f_unitprice * hd_values.exchangerate : 0,
      itemsum: itemsum,
      itemsumtax: itemsumtax,
      subtotal: subtotal,
      balqty: f_demandqty, // 剩餘數量在拋轉之前都預設為採購數量
    });
  }, [f_unitprice, f_demandqty]);

  // 如果bdm資料沒有 unit、opunit就不可選擇單位類別
  const isDisabled = f_unit?.length === 0 && f_opunit?.length === 0;
  const hasSelectedPN = f_PN?.length > 0;
  const isUTypeDisabled = isDisabled && !hasSelectedPN;

  return (
    <Form
      onFinish={onFinish}
      name="basic"
      form={form}
      layout="vertical"
      validateMessages={{
        required: t("util.util.placeholder_select") + "${label}",
      }}
    >
      {/* 隱藏欄位 */}
      <div>
        <Form.Item name="opUUID" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="itemUUID" hidden>
          <Input />
        </Form.Item>
        {/* 過帳型態 */}
        <Form.Item name="idmtype" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="lifeF" hidden>
          <Input />
        </Form.Item>
        {/* 退出數量 */}
        <Form.Item name="returnqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 取消數量 */}
        <Form.Item name="cancelqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 廠商確認數量 */}
        <Form.Item name="confirmqty" hidden>
          <InputNumber />
        </Form.Item>

        {/* 剩餘數量 */}
        <Form.Item name="balqty" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項金額 */}
        <Form.Item name="itemsum" hidden>
          <InputNumber />
        </Form.Item>
        {/* 各項稅額 */}
        <Form.Item name="itemsumtax" hidden>
          <InputNumber />
        </Form.Item>
        {/* 含稅小計 */}
        <Form.Item name="subtotal" hidden>
          <InputNumber />
        </Form.Item>
      </div>
      {/* 表單開始區域 */}
      <Row gutter={[12, 0]}>
        <Col span={16}>
          <Form.Item
            label={<Text disabled={s_disabled.PN}>{t("util.util.PN")}</Text>}
            name="PN"
            rules={[{ required: true }]}
            messageVariables={{ label: t("util.util.PN") }}
          >
            {/* <Select
              showSearch
              placeholder={t("util.util.placeholder_select") + t("util.util.PN")}
              disabled={s_disabled.PN}
              onChange={changePN}
              filterOption={(input, option) => {
                return ((option?.PN ?? "") + (option?.pdtNM ?? "")).includes(
                  input
                );
              }}
              options={c_PNs.data.map((item) =>{
                return ({...item,label:item.PN,value:item.PN})
              })}
            /> */}
            <PNSelect disabled={s_disabled.PN} onChange={changePN} />
          </Form.Item>
        </Col>

        <Col span={3} className="flex items-end">
          <PNButton />
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.dealPN")}</Text>} name="dealPN">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 品名 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.pdtNM")}</Text>} name="pdtNM">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>

        {/* 規格 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.pdtspec")}</Text>} name="pdtspec">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>

        {/* 圖號 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("OMS_ori.util.drawno")}</Text>} name="drawno">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        {/* 計量單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.unit")}</Text>} name="unit">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>
        {/* 操作單位 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.opunit")}</Text>} name="opunit">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>
        {/* 組合數量 */}
        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.pbom")}</Text>} name="pbom">
            <Input disabled placeholder={t("util.util.placeholder_select") + t("util.util.PN")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={8}>
          {/* 交易幣別 */}
          <Form.Item
            label={<Text disabled>{t("util.util.currencytype")}</Text>}
            name="currencytype"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={<Text disabled>{t("util.util.exchangerate")}</Text>}
            name="exchangerate"
          >
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label={<Text disabled>{t("util.util.exchangeD")}</Text>} name="exchangeD">
            <DatePicker disabled className="w-full" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col span={4}>
          <Form.Item
            // 採購單價(未稅)
            label={<Text disabled={s_disabled.unitprice}>{t("OMS_ori.ord.unitprice")}</Text>}
            name="unitprice"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS_ori.ord.unitprice") }}
          >
            <InputNumber
              disabled={s_disabled.unitprice}
              className="w-full"
              placeholder={t("util.util.placeholder_input") + t("OMS_ori.ord.unitprice")}
              min={0}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label={<Text disabled>{t("OMS_ori.util.localprice")}</Text>} name="localprice">
            <InputNumber
              disabled
              className="w-full"
              placeholder={t("util.util.placeholder_input") + t("OMS_ori.util.localprice")}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label={<Text disabled={isUTypeDisabled}>{t("util.util.utype")}</Text>}
            name="utype"
          >
            <Select
              placeholder={t("util.util.placeholder_select") + t("util.util.utype")}
              optionFilterProp="children"
              disabled={isUTypeDisabled}
              onChange={(value) => {
                // 選擇「計量單位」或「操作單位」帶入對應的採購單位
                form.setFieldsValue({
                  punit: form.getFieldValue(value),
                });
              }}
            >
              {f_unit?.length > 0 && (
                <Select.Option value="unit">{t("util.util.unit")}</Select.Option>
              )}
              {f_opunit?.length > 0 && (
                <Select.Option value="opunit">{t("util.util.opunit")}</Select.Option>
              )}
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label={<Text disabled>{t("OMS_ori.fod.punit")}</Text>} name="punit">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            // 採購數量
            label={<Text disabled={s_disabled.demandqty}>{t("OMS_ori.ord.demandqty")}</Text>}
            name="demandqty"
            rules={[{ required: true }]}
            messageVariables={{ label: t("OMS_ori.ord.demandqty") }}
          >
            <InputNumber
              disabled={s_disabled.demandqty}
              className="w-full"
              placeholder={t("util.util.placeholder_select") + t("OMS_ori.ord.demandqty")}
              min={0}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[12, 0]}>
        <Col span={16}>
          <Form.Item
            label={<Text disabled={s_disabled.tb_note}>{t("OMS_ori.fod.note")}</Text>}
            name="note"
          >
            <Input
              disabled={s_disabled.tb_note}
              placeholder={t("util.util.placeholder_select") + t("OMS_ori.fod.note")}
            />
          </Form.Item>
        </Col>

        <Col span={8} className="flex items-end">
          <Button className="w-full" disabled>
            {t("OMS_ori.ord.deal_history")}
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row gutter={[12, 0]}>
        <Col span={24} className="flex justify-end gap-2">
          {/* 取消 */}
          <CancelButton onClick={() => set_s_tbModal({ show: false, data: {} })} />
          <ConfirmButton type="primary" htmlType="submit" disabled={s_disabled.tbSubmit} />
        </Col>
      </Row>
    </Form>
  );
};

export default TbModal;
