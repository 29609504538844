import { AddButton, SearchButton } from "@/components/PDS/Buttons";
import { DatePicker, Input, Modal, Select, Space, Tag, Tooltip, message } from "antd";
import { IconCheck, IconCircle, IconClose, IconGarbage, IconPen } from "@/components/Icon/Action";
import SystemContext, { ERPContext } from "@/components/Context/SystemContext";
import { getCustHd, modifyCustHd } from "@/service/apis/ADM/client";
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";

import CustomModal from "@/components/CustomAntd/PDS/CustomModal";
import CustomTable from "@/components/CustomAntd/PDS/CustomTable";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { IconExcelDownload } from "@/components/Icon/Action";
import ModalConintaer from "./ModalContainer/index";
import PageHeader from "@/components/PageHeader/index";
import dayjs from "dayjs";
import exportExcel from "@/util/exportExcel";
import { getCurrency } from "@/service/apis/ADM/functionParamsManage";
import { getMgmtcat } from "@/service/apis/ADM/manage";
import { getStaff } from "@/service/apis/ADM/userManage";
// util
import { multipleIDtoName } from "@/util/format";
import useAPI from "@/hooks/useAPI";
import { useTranslation } from "react-i18next";

const HD = (props) => {
  const { t } = useTranslation();
  const { c_userData } = useContext(SystemContext);
  // 取得員工姓名 getStaff

  const { c_lightData, set_c_lightData } = props;
  const [s_searchData, set_s_searchData] = useState({
    name: undefined,
    driID: "",
    custType: "C",
    startDate: "",
    endDate: "",
  });
  const [s_tableData, set_s_tableData] = useState([]);
  const [s_tabKey, set_s_tabKey] = useState("valid");
  const [s_isShowModal, set_s_isShowModal] = useState(false);
  const [s_mgmtcat71Options, set_s_mgmtcat71Options] = useState([]);
  const [s_mgmtcat73Options, set_s_mgmtcat73Options] = useState([]);
  const [s_currencyMap, set_s_currencyMap] = useState({});
  const [s_staff_map, set_s_staff_map] = useState({});
  const [s_staff_options, set_s_staff_options] = useState([]);

  const call_getCustHd = useAPI(getCustHd);
  const call_modifyCustHd = useAPI(modifyCustHd);
  const call_getMgmtcat73 = useAPI(getMgmtcat);
  const call_getMgmtcat71 = useAPI(getMgmtcat);
  const call_getCurrency = useAPI(getCurrency);
  const call_getStaff = useAPI(getStaff);
  // 控制操作圖標的顯示
  const isvalid = s_tabKey === "valid" ? true : false;

  const tableColumns = [
    {
      title: "客户ID", // 客戶ID
      dataIndex: "custID",
      align: "center",
      width: "6%",
      isExcel: true,
      sorter: (a, b) =>
        // 根據字串中的數字進行排序
        parseFloat(a.custID.match(/\d+/g)[0]) - parseFloat(b.custID.match(/\d+/g)[0]),
    },
    // {
    //   title: t("ADM.client.custalias"), // 客戶簡稱
    //   dataIndex: "custalias",
    //   width: "14%",
    // },
    {
      title: "客户名称", // 客戶名稱
      dataIndex: "custNM",
      isExcel: true,
    },
    {
      title: "负责业务",
      dataIndex: "driID",
      isExcel: true,
      render: (text) => {
        return (
          <div className="flex gap-2">
            {text.map((driID) => (
              <Tag key={driID}>{s_staff_map[driID]}</Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "註记",
      dataIndex: "note",
      isExcel: true,
    },
    {
      title: "市场区域", // 市場區域
      dataIndex: "location",
      align: "center",
      width: "8%",
      isExcel: true,
    },

    // {
    //   title: t("util.mgmtcat"), // 授權分類
    //   dataIndex: "mgmtcat",
    //   width: "8%",
    //   align: "center",
    //   render: () => {
    //     return s_mgmtcat71Options.map((data) => data.label).join();
    //   },
    // },
    // {
    //   title: t("util.querycat"), // 查詢分類
    //   dataIndex: "querycat",
    //   align: "center",
    //   width: "8%",
    //   render: (text) => s_mgmtcat73Options.filter((x) => x.value === text)[0]?.label,
    // },
    // {
    //   title: t("ADM.client.taxID"), // 統一編號
    //   dataIndex: "taxID",
    //   width: "8%",
    // },
    // {
    //   title: t("ADM.client.maincurrency"), // 交易幣別
    //   dataIndex: "maincurrency",
    //   align: "center",
    //   width: "5%",
    //   render:(text)=>s_currencyMap[text] || ""
    // },
    // {
    //   title: t("ADM.client.payterm"), // 付款條件
    //   dataIndex: "payterm",
    //   width: "8%",
    //   align: "center",
    // },
    {
      // title: t("ADM.client.maxcredit"), // 信用條件
      title: "信用条件",
      dataIndex: "maxcredit",
      align: "center",
      width: "8%",
      isExcel: true,
    },
    // {
    //   title: t("util.isvalid"), // 是否有效
    //   dataIndex: "isvalid",
    //   align: "center",
    //   width: "3%",
    //   render: (text) => (text ? <IconCheck /> : <IconClose />),
    // },

    {
      // title: t("ADM.client.maxcredit"), // 信用條件
      title: "转正式客户时间",
      dataIndex: "formalT",
      align: "center",
      width: "8%",
      isExcel: true,
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },

    {
      title: "操作", // 操作
      dataIndex: "action",
      align: "center",
      width: "7%",
      render: (_, rowData) => (
        <Space>
          <IconPen
            onClick={(e) => {
              set_c_lightData(rowData);
              set_s_isShowModal(true);
              e.stopPropagation();
            }}
          />
          {isvalid ? (
            <IconGarbage
              onClick={(e) => {
                removeData(rowData);
                e.stopPropagation();
              }}
            />
          ) : (
            <Tooltip title={"转至启用"}>
              <IconCircle
                onClick={(e) => {
                  cycleData(rowData);
                  e.stopPropagation();
                }}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  const tabsData = [
    {
      key: "valid",
      label: "启用中",
    },
    {
      key: "invalid",
      label: "停用中",
    },
  ];
  console.log(s_mgmtcat71Options);

  const handlChange = (type, value) => {
    // 每次變更都重第一頁開始
    set_s_searchData((prev) => {
      prev[type] = value;
      return { ...prev, page: 1, pageSize: 20 };
    });
  };

  const CalendarChange = (dates) => {
    set_s_searchData({ ...s_searchData, startDate: dates[0], endDate: dates[1] });
  };

  // iris
  // const handlChange = (type, value) => {
  //   set_s_searchData((prev) => {
  //     return { ...prev, [type] : value};
  //   });
  // };

  const handleKeyDownEnter = (e) => {
    if (e.key === "Enter") {
      set_c_lightData({});
      call_getCustHd.request(s_searchData);
    }
  };

  const onSearch = () => {
    set_c_lightData({});
    call_getCustHd.request({
      ...s_searchData,
      startDate: !s_searchData.startDate
        ? null
        : dayjs(s_searchData.startDate).format("YYYY-MM-DD"),
      endDate: !s_searchData.endDate ? null : dayjs(s_searchData.endDate).format("YYYY-MM-DD"),
    });
  };

  // excel匯出
  const downloadExcel = () => {
    const excelData = s_tableData
      .filter((item) => item.isvalid === isvalid)
      .map((v, i) => {
        return {
          ...v,
          key: i,
          formalT: dayjs(v.formalT).format("YYYY-MM-DD"),
          // iris 0702
          // driID: s_staff_map[v.driID?.join(",")] || "",
          driID: v.driID?.map((v) => s_staff_map[v]).join(", ") || "",
        };
      });
    console.log("√ = ", excelData);
    exportExcel(tableColumns, excelData, `${dayjs().format("YYYY-MM-DD")}客户关係管理.xlsx`);
  };

  // 確認是否刪資料
  const removeData = (rowData) => {
    Modal.confirm({
      title: t("util.del_confirm"),
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: t("util.del_confirm_msg"),
      okText: t("util.ok"),
      onOk: () => call_modifyCustHd.request({ ...rowData, isvalid: false }),
      cancelText: t("util.cancel"),
    });
  };
  // 啟用客戶
  const cycleData = (rowData) => {
    // call_modifyCustHd.request({ ...rowData, isvalid: true });
    Modal.confirm({
      title: "转换确认",
      icon: <ExclamationCircleOutlined className="text-red-600" />,
      content: "请先确认资料无误, 再进行转换",
      okText: t("util.ok"),
      onOk: () => call_modifyCustHd.request({ ...rowData, isvalid: true }),
      cancelText: t("util.cancel"),
    });
  };

  useEffect(() => {
    call_getMgmtcat73.request({ pageType: "73" });
    call_getMgmtcat71.request({ pageType: "71", type: "cust" });
    call_getCurrency.request();
    call_getCustHd.request(s_searchData);
    call_getCurrency.request();
    call_getStaff.request();
  }, []);

  // 負責業務下拉選單(isValid & 業務部門)
  useEffect(() => {
    // state option
    const { status, data, msg } = call_getStaff;
    if (status === "suc") {
      const filData = data.filter((text) => text.isvalid && text.deptID === "業務");

      const _data = filData.reduce(
        (prev, curr) => {
          // prev[curr.staffID] = curr.peopleNM;
          const tmp = { label: curr.peopleNM, value: curr.staffID, deptNM: curr.deptNM };
          prev.push(tmp);
          return prev;
        },
        [{ label: "All", value: "All" }]
      );
      set_s_staff_options(_data);
    }
    if (status === "err") {
      message.error(msg);
    }
  }, [call_getStaff.status]);

  // 負責業務要自動帶登入人員
  useEffect(() => {
    // set_s_searchData((prev) => ({
    //   ...prev,
    //   driID: c_userData.staffID,
    // }));

    const result = s_staff_options.find((item) => item.value === c_userData?.staffID);
    if (result?.deptNM === "業務部門") {
      call_getCustHd.request({ ...s_searchData, driID: result?.value });
      set_s_searchData({ ...s_searchData, driID: result?.value });
    } else {
      call_getCustHd.request({ ...s_searchData, driID: "All" });
      set_s_searchData({ ...s_searchData, driID: "All" });
    }
  }, [c_userData, s_staff_options]);

  useEffect(() => {
    if (call_getStaff.status === "suc") {
      console.log("call_getStaff data = ", call_getStaff.data);
      const staff_map = call_getStaff.data?.reduce((prev, curr) => {
        prev[curr.staffID] = curr.peopleNM;
        return prev;
      }, {});

      set_s_staff_map(staff_map);
      // {
      //   "ZK23": "Ariean",
      //   "ZK24": "Briean"
      // }
    }
  }, [call_getStaff.status]);

  useEffect(() => {
    const { data, status, msg } = call_getCurrency;
    if (status === "suc") {
      console.log(data);
      if (data.length === 0) return;

      const _data = data.reduce((prev, curr) => {
        prev[curr.currencyitem] = curr.itemNM;
        return prev;
      }, {});
      set_s_currencyMap(_data);
    }
    if (status === "err") {
      message.error(t(`errorCode.${msg}`));
    }
  }, [call_getCurrency.status]);

  useEffect(() => {
    // 重新拿到資料都把c_lightData清空
    set_c_lightData({});
    if (call_getCustHd.status === "suc") {
      message.success(call_getCustHd.msg);
      set_s_tableData(
        call_getCustHd.data
          // ?.filter((item) => item.isvalid)
          ?.map((x, i) => ({
            ...x,
            key: x.custID,
            index: i,
            mgmtcat: x.mgmtcat.split("|").filter((x) => x !== ""),
            // 負責業務
            driID: x.driID.split("|"),
          }))
      );
    } else if (call_getCustHd.status === "err") {
      message.error(t(`errorCode.${call_getCustHd.msg}`));
      set_s_tableData([]);
    }
  }, [call_getCustHd.status]);

  useEffect(() => {
    if (call_modifyCustHd.status === "suc") {
      message.success(call_modifyCustHd.msg);

      call_getCustHd.request(s_searchData);
    } else if (call_modifyCustHd.status === "err") {
      message.error(t(`errorCode.${call_modifyCustHd.msg}`));
      // delete之後 重call表格資料
      call_getCustHd.request();
    }
  }, [call_modifyCustHd.status]);

  useEffect(() => {
    if (call_getMgmtcat71.status === "suc") {
      set_s_mgmtcat71Options(
        () =>
          call_getMgmtcat71.data?.reduce(
            (data, current) => {
              data.push({
                label: current.itemNM,
                value: current.mgmtitem,
                type: current.type,
              });
              return data;
            },
            [{ label: t("util.all"), value: "All", type: "" }]
          ) || []
      );
    } else if (call_getMgmtcat71.status === "err") {
      // message.error(call_getMgmtcat71.msg);
    }
  }, [call_getMgmtcat71.status]);

  useEffect(() => {
    if (call_getMgmtcat73.status === "suc") {
      set_s_mgmtcat73Options(
        () =>
          call_getMgmtcat73.data?.reduce((data, current) => {
            data.push({
              label: current.itemNM,
              value: current.mgmtitem,
              type: current.type,
            });
            return data;
          }, []) || []
      );
    } else if (call_getMgmtcat73.status === "err") {
      // message.error(call_getMgmtcat73.msg);
    }
  }, [call_getMgmtcat73.status]);

  return (
    <>
      <PageHeader title="客户关係管理" />
      <CustomTabs
        items={tabsData}
        activeKey={s_tabKey}
        onChange={(activeKey) => set_s_tabKey(activeKey)}
        tabBarExtraContent={
          <div className="flex justify-end items-center gap-[5px]">
            <div className="text-base">{"转正式客户:"}</div>
            <DatePicker.RangePicker
              allowClear
              value={[s_searchData?.startDate, s_searchData?.endDate]}
              className="w-[270px]"
              onCalendarChange={(dates) => {
                console.log("dates =", dates);
                set_s_searchData({
                  ...s_searchData,
                  startDate: dates ? dates[0] : null,
                  endDate: dates ? dates[1] : null,
                });
              }}
            />
            <Input
              key="s_searchData.item"
              value={s_searchData.name}
              onPressEnter={onSearch}
              className="w-[230px]"
              onChange={(e) => handlChange("name", e.target.value)}
              placeholder={`${t("util.placeholder_input")}${t("ADM.client.custID")}，${t(
                "ADM.client.custalias"
              )}，${t("ADM.client.custNM")}`}
            />
            <SearchButton type="primary" key="search" onClick={onSearch} />
            <div className="text-base">{"负责业务:"}</div>
            <Select
              key="s_searchData.driID"
              className="w-[150px]"
              allowClear
              showSearch
              options={s_staff_options || []}
              onKeyDown={handleKeyDownEnter}
              value={s_searchData.driID}
              onChange={(e) => handlChange("driID", e)}
              placeholder={"负责业务"}
            />

            {/* excel匯出 */}
            {/*<IconExcelDownload key="excel" className="text-[38px]" onClick={downloadExcel} />*/}
            <AddButton
              type="primary"
              key="create"
              disabled={s_tabKey === "invalid"}
              onClick={() => {
                set_c_lightData({});
                set_s_isShowModal(true);
              }}
            />
          </div>
        }
      />
      <CustomTable
        columns={tableColumns.filter((x) => !x.hidden)}
        dataSource={s_tableData.filter((item) => item.isvalid === isvalid)}
        loading={call_getCustHd.status === "load"}
        tbMode={Object.keys(c_lightData).length > 0}
        c_lightData={c_lightData}
        onRow={(record) => ({
          onClick: () =>
            record.key === c_lightData.key ? set_c_lightData({}) : set_c_lightData(record),
        })}
      />

      <CustomModal
        title={
          Object.keys(c_lightData).length > 0
            ? `${t("util.edit")}${t("ADM.client.pageHeader")}`
            : `${t("util.add")}${t("ADM.client.pageHeader")}`
        }
        width={"80%"}
        open={s_isShowModal}
        onCancel={() => set_s_isShowModal(false)}
        afterClose={() => {
          set_c_lightData({});
          call_getCustHd.request(s_searchData);
        }}
      >
        <ModalConintaer
          s_editData={c_lightData}
          set_s_editData={set_c_lightData}
          set_s_isShowModal={set_s_isShowModal}
          arrMap={{
            s_mgmtcat71Options: s_mgmtcat71Options,
            s_mgmtcat73Options: s_mgmtcat73Options,
          }}
        />
      </CustomModal>
    </>
  );
};

export default HD;
