import { useState, useRef, useEffect } from "react";
import { Button, Input, Select, message, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PageHeader from "@/components/PageHeader";
import CustomTabs from "@/components/CustomAntd/PDS/CustomTabs";
import useAPI from "@/hooks/useAPI";
import { getWh } from "@/service/apis/ADM/publicAPI";
import { SearchButton } from "@/components/PDS/Buttons";
import SearchPN from "./SearchPN";
import SearchWh from "./SearchWh";
import SearchBatchNo from "./SearchBatchNo";
import { IconExcelDownload } from "@/components/Icon/Action";

export default function StockSearch() {
	const { t } = useTranslation();
	const DATE_FORMAT = "YYYY-MM-DD";
	// 利用ref 子傳父
	const searchPN_ref = useRef(null);
	const searchWh_ref = useRef(null);
	const searchBatchNo_ref = useRef(null);

	const [s_searchData, set_s_searchData] = useState({
		query: null,
		wh: [],
		page: 1,
		pageSize: 100,
		batchno: "",
		startDate: dayjs().startOf("month").format(DATE_FORMAT),
		endDate: dayjs().endOf("month").format(DATE_FORMAT),
		whID: null,
		PN: null,
		searchSelect: "PN",
		batchnoOrPN: "",
	});
	// 目前是在哪個Tab上
	const [s_tabKey, set_s_tabKey] = useState("searchPN");

	const call_getWh = useAPI(getWh);

	const resetSearchData = () => {
		set_s_searchData({
			wh: [],
			PN: null,
			page: 1,
			pageSize: 100,
			batchno: "",
			startDate: dayjs().startOf("month").format(DATE_FORMAT),
			endDate: dayjs().endOf("month").format(DATE_FORMAT),
			whID: null,
			searchSelect: "PN",
			batchnoOrPN: "",
		});
	};
	//在 tabKey === BatchNo 修改下拉選擇事件

	const handlChange = (type, value) => {
		// 每次變更都重第一頁開始
		set_s_searchData((prev) => {
			if (type === "time") {
				prev.startDate = dayjs(value[0]).format(DATE_FORMAT);
				prev.endDate = dayjs(value[1]).format(DATE_FORMAT);
			} else {
				prev[type] = value;
			}
			return { ...prev, page: 1, pageSize: 20 };
		});
	};
	const onSearch = () => {
		s_tabKey === "searchPN" && searchPN_ref.current.onSearch(s_searchData);
		s_tabKey === "searchWh" && searchWh_ref.current.onSearch(s_searchData);
		s_tabKey === "searchBatchNo" && searchBatchNo_ref.current.onSearch(s_searchData);
	};

	const tabsData = [
		{
			key: "searchPN",
			label: t("WMS.stock-search.searchPN"),
			children: (
				<SearchPN
					ref={searchPN_ref}
					s_searchData={s_searchData}
					set_s_searchData={set_s_searchData}
				/>
			),
		},
		{
			key: "searchWh",
			label: t("WMS.stock-search.searchWh"),
			children: (
				<SearchWh
					ref={searchWh_ref}
					s_searchData={s_searchData}
					set_s_searchData={set_s_searchData}
				/>
			),
		},
		// {
		//   key: "searchBatchNo",
		//   label: t("WMS.stock-search.searchBatchno"),
		//   children: (
		//     <SearchBatchNo
		//       ref={searchBatchNo_ref}
		//       s_searchData={s_searchData}
		//       set_s_searchData={set_s_searchData}
		//     />
		//   ),
		// },
	];

	const searchBasic = [
		<Input
			key="s_searchData.query"
			value={s_searchData[`${s_searchData.batchnoOrPN}`]}
			onChange={(e) =>
				handlChange(s_tabKey === "searchBatchNo" ? "batchnoOrPN" : "PN", e.target.value)
			}
			placeholder={
				s_tabKey === "searchBatchNo"
					? t("util.placeholder_input") + " " + t(`util.PN`) + " 或 " + t(`util.batchno`)
					: t("util.placeholder_input") + " " + t("util.PN")
			}
		/>,
		(s_tabKey === "searchBatchNo" || s_tabKey === "searchWh") && (
			<Select
				key="s_searchData.whID"
				value={s_searchData.whID}
				className="w-[400px]"
				allowClear
				placeholder={t("util.placeholder_select") + " " + t("util.whID")}
				options={
					call_getWh.data?.map((x) => ({ label: x.whID + "-" + x.whNM, value: x.whID })) || []
				}
				onChange={(e) => handlChange("whID", e)}
			/>
		),

		<SearchButton type="primary" key="search" onClick={onSearch} />,
	];

	useEffect(() => {
		call_getWh.request();
	}, []);

	useEffect(() => {
		if (call_getWh.status === "err") {
			message.error(call_getWh.msg);
		}
	}, [call_getWh.status]);

	const exportExcel = {
		right:
			<IconExcelDownload
				key="excel"
				className="text-[38px]"
				onClick={() => {
					searchWh_ref.current.downloadExcel();
				}}
			/>,
	};

	return (
		<>
			<PageHeader
				title={t("WMS.stock-search.pageHeader")}
				extra={[

					s_tabKey === "searchBatchNo" && (
						<>
							<DatePicker.RangePicker
								key="time"
								allowClear={false}
								value={[dayjs(s_searchData.startDate), dayjs(s_searchData.endDate)]}
								format={DATE_FORMAT}
								onChange={(dates) => handlChange("time", dates)}
							/>
						</>
					),
					searchBasic,
				]}
			/>
			<CustomTabs
				items={tabsData}
				activeKey={s_tabKey}
				tabBarExtraContent={s_tabKey === "searchWh" ? exportExcel : null}
				onChange={(activeKey) => {
					set_s_tabKey(activeKey);
					resetSearchData();
				}}
			/>

		</>
	);
}
